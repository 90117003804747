import { Container, Loader, Spacer, TitleBar, Select } from '@expressable/ui-library';
import useTherapists from '../hooks/use-therapists';
import React, { useContext, useEffect } from 'react';
import Search from 'components/search';
import List from 'components/list';
import { Link } from 'react-router-dom';
import 'twin.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet';
import smeList from 'utils/sme.json';
import usStatesOptions from 'utils/us-states.json';
import { specialtyOptions } from 'hooks/common/useTherapistFilters/options';
import usePermissions from 'hooks/use-permissions';
import {
  TherapistActivityStatus,
  TherapistsStatusContext,
  withTherapistStatusContext,
} from '../utils/therapistsStatusContext';
import { capitalizeFirst } from 'utils/helpers';
interface Props {
  searchTerm: string;
  setSearchTerm(searchTerm: string): void;
  filteredItems: [];
}

interface Therapist {
  therapistName: string;
  therapistEmail: string;
  sme?: string[];
}

export const availabilityOptions = [
  { value: 'All', label: 'All' },
  { value: true, label: 'Accepting new patients' },
  { value: false, label: 'Not accepting new patients' },
];

const statusOptions = [
  { value: 'all', label: 'All' },
  { value: 'active', label: 'Active' },
  { value: 'inactive', label: 'Inactive' },
];

const TherapistsView = ({ searchTerm, setSearchTerm, filteredItems }: Props) => {
  const [status, setStatus] = useContext(TherapistsStatusContext);
  const { isRefetching } = useTherapists(status);
  const { isAdminOrClinicalManager } = usePermissions();
  const smeOptions = [{ value: 'Any', label: 'Any' }, ...smeList];
  const stateOptions = [{ value: 'Any', label: 'Any' }, ...usStatesOptions];
  const clinicalCompetenciesOptions = [{ value: 'Any', label: 'Any' }, ...specialtyOptions];
  const [smeSelected, setSmeSelected] = React.useState<{ [key: string]: string }>({ value: 'Any', label: 'Any' });
  const [stateSelected, setStateSelected] = React.useState<{ [key: string]: string }>({ value: 'Any', label: 'Any' });
  const [statusSelected, setStatusSelected] = React.useState<{ [key: string]: string }>({
    value: status,
    label: capitalizeFirst(status),
  });
  const [specialtySelected, setSpecialtySelected] = React.useState<{ [key: string]: string }>({
    value: 'Any',
    label: 'Any',
  });
  const [availabilitySelected, setAvailabilitySelected] = React.useState<{ [key: string]: string }>({
    value: 'All',
    label: 'All',
  });

  // eslint-disable-next-line
  const filteredItemsBySelection = (therapists?: any) => {
    const filteredItems = therapists
      ?.filter(
        (item: { [key: string]: string }) =>
          stateSelected.value === 'Any' ||
          (stateSelected && item.statesLicensed && item.statesLicensed.includes(stateSelected.value)),
      )
      .filter(
        (item: { [key: string]: string }) =>
          smeSelected.value === 'Any' || (smeSelected && item.sme && item.sme.includes(smeSelected.value)),
      )
      .filter(
        (item: { [key: string]: string }) =>
          specialtySelected.value === 'Any' ||
          (specialtySelected && item.specialties && item.specialties.includes(specialtySelected.value)),
      )
      .filter(
        (item: { [key: string]: string }) =>
          availabilitySelected.value === 'All' ||
          (availabilitySelected && item.acceptingNewPatients === availabilitySelected.value),
      );
    return filteredItems;
  };

  const therapistsFiltered = filteredItemsBySelection(filteredItems);

  return (
    <>
      <div tw="max-w-sm relative">
        <input
          tw="p-3 outline-none border focus:border-indigo-300"
          css={{ width: '100%', paddingRight: 50 }}
          onChange={event => setSearchTerm(event.target.value)}
          type="text"
          placeholder={`Search ${therapistsFiltered.length} Records`}
          aria-label="Search Clients"
          value={searchTerm}
          autoFocus
        />
        <div
          css={{
            position: 'absolute',
            right: 14,
            top: '50%',
            transform: 'translateY(-50%)',
            opacity: 0.6,
            fontSize: '0.8rem',
          }}
        >
          {therapistsFiltered.length}
        </div>
      </div>
      <div tw="mt-5 items-baseline lg:flex lg:mb-0" style={{ right: '2rem' }}>
        {isAdminOrClinicalManager && (
          <div tw="w-full lg:w-80 mt-2">
            <label tw="block mr-2 mb-2 font-semibold text-sm">Status</label>
            <div tw="flex-1">
              <Select
                tw="mr-4"
                id="status"
                required
                value={statusSelected}
                options={statusOptions}
                onChange={(selectOption: { [key: string]: string }) => {
                  setStatusSelected(selectOption);
                  setStatus(selectOption.value as TherapistActivityStatus);
                }}
              />
            </div>
          </div>
        )}

        <div tw="w-full lg:w-80 mt-2">
          <label tw="block mr-2 mb-2 font-semibold text-sm">State</label>
          <div tw="flex-1">
            <Select
              tw="mr-4"
              id="state"
              required
              placeholder="Select..."
              value={stateSelected}
              options={stateOptions}
              onChange={(selectOption: { [key: string]: string }) => {
                setStateSelected(selectOption);
              }}
            />
          </div>
        </div>
        <div tw="w-full lg:w-128">
          <label tw="block mr-2 mb-2 font-semibold text-sm">Availability</label>
          <div tw="flex-1">
            <Select
              tw="mr-4"
              id="availability"
              required
              placeholder="Select..."
              value={availabilitySelected}
              options={availabilityOptions}
              onChange={(selectOption: { [key: string]: string }) => {
                setAvailabilitySelected(selectOption);
              }}
            />
          </div>
        </div>
        <div tw="w-full lg:w-128">
          <label tw="block mr-2 mb-2 font-semibold text-sm">Clinical Competencies</label>
          <div tw="flex-1">
            <Select
              tw="mr-4"
              id="clinicalCompetencies"
              required
              placeholder="Select..."
              value={specialtySelected}
              options={clinicalCompetenciesOptions}
              onChange={(selectOption: { [key: string]: string }) => {
                setSpecialtySelected(selectOption);
              }}
            />
          </div>
        </div>
        <div tw="w-full lg:w-128">
          <label tw="block mr-2 mb-2 font-semibold text-sm">SME</label>
          <div tw="flex-1">
            <Select
              tw="mr-4"
              id="sme"
              required
              placeholder="Select..."
              value={smeSelected}
              options={smeOptions}
              onChange={(selectOption: { [key: string]: string }) => {
                setSmeSelected(selectOption);
              }}
            />
          </div>
        </div>
      </div>
      <div
        css={{
          marginTop: 20,
        }}
      >
        {isRefetching ? (
          <div tw="flex justify-center items-center">
            <Loader type="ring" />
          </div>
        ) : (
          <div>
            {therapistsFiltered.length ? (
              <List
                data={therapistsFiltered}
                render={(element: Therapist, index) => (
                  <li key={index}>
                    <Link tw="block p-4 hover:bg-indigo-100" to={{ pathname: `/therapists/${element.therapistEmail}` }}>
                      {element.therapistName}
                      {element.sme && (
                        <span tw="pl-4 md:pl-8 text-gray-400 text-sm font-normal">SME: {element.sme.join(', ')}</span>
                      )}
                    </Link>
                  </li>
                )}
              />
            ) : (
              'No therapists'
            )}
          </div>
        )}
      </div>
    </>
  );
};

const Therapists = () => {
  const [status] = useContext(TherapistsStatusContext);

  const { data, isLoading, refetch } = useTherapists(status);
  const { isAdminOrClinicalManager } = usePermissions();

  useEffect(() => {
    refetch();
  }, [status]);

  if (isLoading) {
    return (
      <div tw="h-screen flex justify-center items-center">
        <Loader type="ring" />
      </div>
    );
  }

  if (isAdminOrClinicalManager) {
    return (
      <div data-testid="therapists" css={{ marginBottom: 28 }}>
        <Helmet title="Therapists" />
        <div tw="relative">
          <TitleBar
            title="Therapists"
            action={
              <Link tw="text-indigo-700" to="/therapist/create">
                <FontAwesomeIcon tw="text-2xl align-middle mr-3" icon={['far', 'plus']} />
                <span tw="align-middle font-bold">Create New Therapist</span>
              </Link>
            }
          />
        </div>
        <Spacer size="md" />
        <Container tw="relative" size="large">
          {data && (
            <Search
              render={TherapistsView}
              data={data}
              options={{
                keys: ['therapistName', 'therapistEmail'],
                threshold: 0.1,
              }}
            />
          )}
        </Container>
      </div>
    );
  }
  return (
    <div css={{ marginBottom: 28 }}>
      <Helmet title="Therapists" />
      <div tw="relative">
        <TitleBar title="Therapists" />
      </div>
      <Spacer size="md" />
      <Container tw="relative" size="large">
        {data && (
          <Search
            render={TherapistsView}
            data={data}
            options={{
              keys: ['therapistName', 'therapistEmail'],
              threshold: 0.1,
            }}
          />
        )}
      </Container>
    </div>
  );
};

export default withTherapistStatusContext(Therapists);
