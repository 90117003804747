import { useCompletableWizard } from 'hooks/common/useCompletableWizard';
import { BillingInformationFormData } from 'pages/new-client-create/BillingInformation';
import { ClientInformationFormData } from 'pages/new-client-create/ClientInformation';
import { ScheduledSessions } from 'pages/new-client-create/SchedulingSessions/schedule-sessions';
import { useCallback, useState } from 'react';

// @TODO(juan)
export type StepData = ScheduledSessions | ClientInformationFormData | BillingInformationFormData | undefined;

export enum StepId {
  scheduleSessions,
  clientInfo,
  billingInfo,
  reviewData,
}

export type ClientWizardStepsData = {
  [StepId.scheduleSessions]: ScheduledSessions;
  [StepId.clientInfo]: ClientInformationFormData;
  [StepId.billingInfo]: BillingInformationFormData;
  [StepId.reviewData]: undefined;
};

export type UseTherapistMatcher = () => {
  currentStep: number;
  stepsState: boolean[];
  stepsData: Partial<ClientWizardStepsData>;
  /** Will submit the step data and mark the step as complete */
  onSubmitStep: (stepId: StepId, data: StepData) => void;

  /** Will set the step as invalid and clear its state */
  onInvalidateStep: (stepIndex: StepId) => void;

  /** Will jump to a specific step */
  onNavigateToStep: (toStepIndex: StepId) => void;
};

export const CLIENT_CREATE_WIZARD_STEPS_TITLES = [
  'Schedule Sessions',
  'Confirm Client Information',
  'Confirm Billing Information',
  'Review Summary',
];

export const useTherapistMatcher: UseTherapistMatcher = () => {
  const [stepsData, setStepsData] = useState<Partial<ClientWizardStepsData>>({});

  const { currentStep, onNavigateToStep, stepsState, onInvalidateStep, onCompleteStep } = useCompletableWizard(
    CLIENT_CREATE_WIZARD_STEPS_TITLES.length,
  );

  const onSubmitStep = useCallback(
    (stepId: StepId, data: StepData) => {
      setStepsData(currentData => ({ ...currentData, [stepId]: data }));
      onCompleteStep(stepId);
    },
    [currentStep],
  );

  return {
    onSubmitStep,
    currentStep,
    stepsState,
    stepsData,
    onNavigateToStep,
    onInvalidateStep,
  };
};
