import { useEffect, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { ContentfulDropdownContent, ContentfulStringContent, SelectOption } from 'types';
import { useClient } from './use-client';
import {
  EvaluationEntriesIds,
  useEvaluationContentfulEntry,
  useEvaluationContentfulSelectOptions,
} from './use-evaluation-contentful-provider';
import { FormYesNoDetails } from 'domain/shared/types';

export default function useEvaluationMedicalHistory(clientId: string) {
  const { data: referrals } = useEvaluationContentfulEntry<ContentfulDropdownContent>(EvaluationEntriesIds.Referrals);
  const { data: nursingOptions } = useEvaluationContentfulEntry<ContentfulDropdownContent>(
    EvaluationEntriesIds.nursingOtherOptionsResponse,
  );
  const { data: birthHistoryDefaultResponse } = useEvaluationContentfulEntry<ContentfulStringContent>(
    EvaluationEntriesIds.birthHistoryDefaultResponse,
  );

  const { data: hospitalizationHistoryDefaultResponse } = useEvaluationContentfulEntry<ContentfulStringContent>(
    EvaluationEntriesIds.hospitalizationHistoryResponse,
  );

  const { data: allergiesHistoryDefaultResponse } = useEvaluationContentfulEntry<ContentfulStringContent>(
    EvaluationEntriesIds.allergiesHistoryResponse,
  );

  const { data: medicalDiagnosesDefaultResponse } = useEvaluationContentfulEntry<ContentfulStringContent>(
    EvaluationEntriesIds.medicalDiagnosesResponse,
  );

  const { data: medicationsDefaultResponse } = useEvaluationContentfulEntry<ContentfulStringContent>(
    EvaluationEntriesIds.medicationsResponse,
  );

  const { data: hearingHistoryDefaultResponse } = useEvaluationContentfulEntry<ContentfulStringContent>(
    EvaluationEntriesIds.hearingHistoryResponse,
  );

  const { data: visionHistoryDefaultResponse } = useEvaluationContentfulEntry<ContentfulStringContent>(
    EvaluationEntriesIds.visionHistoryResponse,
  );

  const { data: otherHistoryDefaultResponse } = useEvaluationContentfulEntry<ContentfulStringContent>(
    EvaluationEntriesIds.otherHistoryResponse,
  );

  const { data: previousSpeechTreatmentResponse } = useEvaluationContentfulEntry<ContentfulStringContent>(
    EvaluationEntriesIds.previousSpeechTreatment,
  );

  const { data: developmentalHistory } = useEvaluationContentfulEntry<ContentfulStringContent>(
    EvaluationEntriesIds.DevelopmentalHistory,
  );

  const { data: painResponse } = useEvaluationContentfulEntry<ContentfulStringContent>(
    EvaluationEntriesIds.PainResponse,
  );

  const { data: functionalLimitations } = useEvaluationContentfulEntry<ContentfulStringContent>(
    EvaluationEntriesIds.FunctionalLimitationsResponse,
  );
  const sourceOfMedicalHistory = useEvaluationContentfulSelectOptions(EvaluationEntriesIds.SourceOfMedicalHistory);

  const { data } = useClient(clientId);

  const watchBirthHistory = useWatch({ name: 'birthHistory' });
  const watchHospitalizationHistory = useWatch({ name: 'hospitalizationHistory' });
  const watchAllergiesHistory = useWatch({ name: 'allergiesHistory' });
  const watchMedicalDiagnoses = useWatch({ name: 'medicalDiagnosesHistory' });
  const watchMedications = useWatch({ name: 'medications' });
  const watchHearingHistory = useWatch({ name: 'hearingHistory' });
  const watchVisionHistory = useWatch({ name: 'visionHistory' });
  const watchOtherHistory = useWatch({ name: 'otherHistory' });
  const watchPreviousSpeechTreatment = useWatch({ name: 'previousSpeechTreatment' });
  const watchNursingSupportType = useWatch({ name: 'nursingOtherSupportsType' });
  const watchDevelopmentalHistory = useWatch({ name: 'developmentalHistory' });
  const watchPain = useWatch({ name: 'pain' });
  const watchMentalStatus = useWatch({ name: 'mentalStatus' });
  const watchFunctionalLimitations = useWatch({ name: 'functionalLimitations' });

  const { setValue, reset, getValues } = useFormContext();

  const personsPresentForEvaluationOptions = useMemo(() => {
    return data?.contactInformation
      ?.filter((person: any) => person.relationshipToClient.toLowerCase() !== 'client')
      .map((person: any) => ({
        label: `${person.contactFirstName} ${person.contactLastName} ${
          person.relationshipToClient ? `(${person.relationshipToClient})` : ''
        }`,
        value: `${person.contactFirstName} ${person.contactLastName} ${
          person.relationshipToClient ? `(${person.relationshipToClient})` : ''
        }`,
      })) as SelectOption[];
  }, [data?.contactInformation?.length]);

  // # region - memoized values setters
  // if one of the radio RadioFreeText has the value of no, we will set the default contentful response
  // into the [value].details property

  const setRadioFreeTextAnswer = (name: string, watchedValue: FormYesNoDetails, contentfulResponse: string) => {
    if (watchedValue?.relevant === 'no' || !watchedValue?.relevant) {
      setValue(name, { relevant: 'no', details: contentfulResponse });
    }

    if (watchedValue?.relevant === 'yes' && watchedValue?.details === contentfulResponse) {
      setValue(name, { relevant: 'yes', details: '' });
      reset({ ...getValues() });
    }
  };

  const setMentalStatusRadio = (name: string, watchedValue: FormYesNoDetails) => {
    if (watchedValue?.relevant === 'yes' || !watchedValue?.relevant) {
      setValue(name, { relevant: 'yes', details: '' });
    }
  };

  useEffect(() => {
    setRadioFreeTextAnswer('birthHistory', watchBirthHistory, birthHistoryDefaultResponse?.content);
  }, [birthHistoryDefaultResponse?.content, watchBirthHistory?.relevant]);

  useEffect(() => {
    setRadioFreeTextAnswer(
      'hospitalizationHistory',
      watchHospitalizationHistory,
      hospitalizationHistoryDefaultResponse?.content,
    );
  }, [hospitalizationHistoryDefaultResponse?.content, watchHospitalizationHistory?.relevant]);

  useEffect(() => {
    setRadioFreeTextAnswer('allergiesHistory', watchAllergiesHistory, allergiesHistoryDefaultResponse?.content);
  }, [allergiesHistoryDefaultResponse?.content, watchAllergiesHistory?.relevant]);

  useEffect(() => {
    setRadioFreeTextAnswer('medicalDiagnosesHistory', watchMedicalDiagnoses, medicalDiagnosesDefaultResponse?.content);
  }, [medicalDiagnosesDefaultResponse?.content, watchMedicalDiagnoses?.relevant]);

  useEffect(() => {
    setRadioFreeTextAnswer('medications', watchMedications, medicationsDefaultResponse?.content);
  }, [medicationsDefaultResponse?.content, watchMedications?.relevant]);

  useEffect(() => {
    setRadioFreeTextAnswer('hearingHistory', watchHearingHistory, hearingHistoryDefaultResponse?.content);
  }, [hearingHistoryDefaultResponse?.content, watchHearingHistory?.relevant]);

  useEffect(() => {
    setRadioFreeTextAnswer('visionHistory', watchVisionHistory, visionHistoryDefaultResponse?.content);
  }, [visionHistoryDefaultResponse?.content, watchVisionHistory?.relevant]);

  useEffect(() => {
    setRadioFreeTextAnswer('otherHistory', watchOtherHistory, otherHistoryDefaultResponse?.content);
  }, [otherHistoryDefaultResponse?.content, watchOtherHistory?.relevant]);

  useEffect(() => {
    setRadioFreeTextAnswer(
      'previousSpeechTreatment',
      watchPreviousSpeechTreatment,
      previousSpeechTreatmentResponse?.content,
    );
  }, [previousSpeechTreatmentResponse?.content, watchPreviousSpeechTreatment?.relevant]);

  useEffect(() => {
    setRadioFreeTextAnswer('developmentalHistory', watchDevelopmentalHistory, developmentalHistory?.content);
  }, [developmentalHistory?.content, watchDevelopmentalHistory?.relevant]);

  useEffect(() => {
    setRadioFreeTextAnswer('pain', watchPain, painResponse?.content);
  }, [painResponse?.content, watchPain?.relevant]);

  useEffect(() => {
    setMentalStatusRadio('mentalStatus', watchMentalStatus);
  }, [watchMentalStatus?.relevant]);

  useEffect(() => {
    setRadioFreeTextAnswer('functionalLimitations', watchFunctionalLimitations, functionalLimitations?.content);
  }, [functionalLimitations?.content, watchFunctionalLimitations?.relevant]);

  const includeOtherNursingDetails = useMemo(() => {
    return watchNursingSupportType?.some((option: SelectOption) => option.value === 'Other') ?? false;
  }, [watchNursingSupportType]);

  return {
    personsPresentForEvaluationOptions,
    referrals,
    nursingOptions,
    includeOtherNursingDetails,
    sourceOfMedicalHistory,
  };
}
