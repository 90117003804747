import { HomeworkContentfulQueryFilter } from 'types';

export const ageRangesOptions = [
  { label: '0-3', value: '0-3' },
  { label: '3-6', value: '3-6' },
  { label: '7-10', value: '7-10' },
  { label: '11-13', value: '11-13' },
  { label: '14+', value: '14+' },
  { label: 'Adult', value: 'Adult' },
];

export const timeRequiredOptions = [
  { label: '1-5 Minutes', value: '1-5 minutes' },
  { label: '5-10 Minutes', value: '5-10 minutes' },
  { label: '15+ Minutes', value: '15+ minutes' },
];

export const complexityOptions = [
  { label: 'Beginner', value: 'Beginner' },
  { label: 'Intermediate', value: 'Intermediate' },
  { label: 'Advanced', value: 'Advanced' },
];

// builder function that creates a homework params object based on filters
export const buildContentfulHomeworksQuery = ({
  ageFilter,
  timeRequiredFilter,
  complexityFilter,
  typeFilter,
  goalsFilter,
  shortTermGoalsEntries,
  caregiverFilter,
  treatmentAreaFilter,
  keywords,
  hasMaterials,
}: HomeworkContentfulQueryFilter) => {
  //This will help us to know if we need to use the "in" operator or not in the query params for the short term goals filter when we have duplicated stg
  const shouldMakeShortTermGoalInclusion =
    shortTermGoalsEntries.filter(goal => goalsFilter.includes(goal.fields.shortTermGoalLabel)).map(goal => goal.sys.id)
      .length > 1;

  return {
    [ageFilter.length > 1 ? 'fields.ageRange[in]' : 'fields.ageRange']: ageFilter.join(','),
    [timeRequiredFilter.length > 1 ? 'fields.timeRequired[in]' : 'fields.timeRequired']: timeRequiredFilter.join(','),
    [complexityFilter.length > 1 ? 'fields.complexity[in]' : 'fields.complexity']: complexityFilter.join(','),
    [typeFilter.length > 1 ? 'fields.hwType[in]' : 'fields.hwType']: typeFilter.join(','),
    [shouldMakeShortTermGoalInclusion ? 'fields.shortTermGoal.sys.id[in]' : 'fields.shortTermGoal.sys.id']:
      shortTermGoalsEntries
        .filter(goal => goalsFilter.includes(goal.fields.shortTermGoalLabel))
        .map(goal => goal.sys.id)
        .join(','),

    [Boolean(treatmentAreaFilter && treatmentAreaFilter?.length > 1)
      ? 'fields.treatmentArea.sys.id[in]'
      : 'fields.treatmentArea.sys.id']: treatmentAreaFilter?.join(','),
    ['query']: Boolean(keywords && keywords?.split(' ').length > 1) ? `"${keywords}"` : keywords ?? '',
    ['fields.caregiverRequired']: caregiverFilter,
    ['fields.associatedMaterialsIfRequired[exists]']: hasMaterials,
  };
};

export const homeworkType = [
  { label: 'Daily Routine (Generalization)', value: 'Daily Routine (Generalization)' },
  { label: 'Game', value: 'Game' },
  { label: 'Movement Activity', value: 'Movement Activity' },
  { label: 'On the Go', value: 'On the Go' },
  { label: 'Speech Sound Practice', value: 'Speech Sound Practice' },
  { label: 'Structured Worksheet', value: 'Structured Worksheet' },
  { label: 'Toy Play Time', value: 'Toy Play Time' },
  { label: 'Word Play Time', value: 'Word Play Time' },
];

export const getViewActivityUrl = () => {
  if (process.env.REACT_APP_NODE_ENV === 'production') {
    return process.env.REACT_APP_PROD_HOME_PROGRAMS!;
  } else {
    return process.env.REACT_APP_HOMEWORK_URL!;
  }
};
