import React, { useState } from 'react';
import { Button, TitleBar, Container, Card, Spacer, Loader, Link } from '@expressable/ui-library';
import { animated, Spring } from 'react-spring/renderprops';
import 'twin.macro';
import { RouteComponentProps } from 'react-router-dom';
import { useContactMeta } from 'hooks/use-contacts';
import ContactSidebar from './components/contact-sidebar';
import AddClientContact from './components/add-client-contact';
import { Helmet } from 'react-helmet';
import getInitials from 'utils/name-initials';

type TParams = { contactId: string };

const Contact = (props: RouteComponentProps<TParams>) => {
  const {
    params: { contactId },
  } = props.match;

  const { data: contactMetadata, isLoading } = useContactMeta(contactId);
  const [showClientContactForm, setShowClientContactForm] = useState(false);
  if (isLoading) {
    return (
      <div tw="h-screen flex justify-center items-center">
        <Loader type="ring" />
      </div>
    );
  }

  const contactData = contactMetadata?.contactData;
  const contactInitials = getInitials(
    `${contactData?.contactFirstName ?? ''}
    ${contactData?.contactLastName ?? ''}`,
  );

  return (
    <div data-testid="contact" css={{ marginBottom: 28 }}>
      {contactMetadata && (
        <>
          <Helmet title={`${contactInitials}'s Contact Profile`} />
          <TitleBar title={`${contactData?.contactFirstName} ${contactData?.contactLastName}`} />
        </>
      )}
      {contactMetadata && (
        <Container size="large">
          <Spacer size="md" />
          <div tw="md:flex">
            <div tw="md:w-3/4 md:pr-5">
              <div tw="font-semibold text-2xl mb-6">Associated Clients</div>
              {contactMetadata.associatedClients.length > 0 ? (
                <div>
                  {contactMetadata.associatedClients.map((client, index: number) => (
                    <Card tw="my-2.5 pl-6" key={index}>
                      <Link to={`/clients/${client.clientInfo.clientID}`}>
                        <div tw="text-lg text-indigo-700 font-medium">{`${client.clientInfo.clientFirstName} ${client.clientInfo.clientLastName}`}</div>
                      </Link>
                      <div tw="text-base text-gray font-medium">{`Relationship to Client: ${client.relationshipToClient}`}</div>
                    </Card>
                  ))}
                </div>
              ) : (
                <div>This contact does not have any associated clients</div>
              )}

              {/* {!showClientContactForm && (
                <Link
                  tw="text-sm px-4"
                  to={'#'}
                  onClick={() => {
                    setShowClientContactForm((showClientContactForm: boolean) => !showClientContactForm);
                  }}
                >
                  Add New Client
                </Link>
              )} */}
              <Spring
                config={{ friction: 60, tension: 700 }}
                native
                to={{
                  opacity: showClientContactForm ? 1 : 0,
                  transform: showClientContactForm ? 'scaleY(1)' : 'scaleY(0)',
                  height: showClientContactForm ? 'auto' : 0,
                }}
              >
                {animatedProps => (
                  //don't hide the overflow on an expanded form
                  <animated.div style={{ ...animatedProps }}>
                    <div>
                      <AddClientContact
                        contactData={contactMetadata.contactData}
                        toogleClientContactForm={() => {
                          setShowClientContactForm((showClientContactForm: boolean) => !showClientContactForm);
                        }}
                      />
                    </div>
                  </animated.div>
                )}
              </Spring>
            </div>
            <div tw="md:w-1/4 px-5">
              <ContactSidebar {...contactMetadata.contactData} />
            </div>
          </div>
        </Container>
      )}
      {!contactMetadata && !isLoading && (
        <div tw="relative">
          <TitleBar title="Contacts" />
          <Container size="large" tw="pt-12">
            <div tw="bg-gray-200 m-auto p-28">
              <div tw="text-center">
                <p tw="text-3xl text-gray-400 mb-4">We cannot find a contact with that ID</p>
                <Button variant="secondary" onClick={() => props.history.push(`/contacts`)}>
                  Back to Contacts
                </Button>
              </div>
            </div>
          </Container>
        </div>
      )}
    </div>
  );
};

export default Contact;
