import 'twin.macro';

import React from 'react';
import { textWithLineBreaks } from 'utils/helpers';

import { Link } from '@expressable/ui-library';

import { CompleteTherapistInformation } from '../types';
import Mfa from './mfa';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useResetCacheCalendar } from 'hooks/use-appointments';

const Heading = ({ children }: { children: React.ReactNode }) => (
  <div tw="mb-2 mt-8 text-base leading-none capitalize font-semibold">{children}</div>
);

const SubHeading = ({ children }: { children: React.ReactNode }) => (
  <div tw="mt-5 text-sm leading-none capitalize font-semibold">{children}</div>
);

interface SidebarProps {
  therapistData: CompleteTherapistInformation;
  setIndex: (index: number) => void;
}

export default function Sidebar(props: SidebarProps) {
  const { therapistData, setIndex } = props;
  const {
    firstName = '',
    lastName = '',
    therapistEmail = '',
    statesLicensed = [],
    licenseNumbers = '',
    specialties = [],
    sme = [],
    npi = '',
    sequoiaID,
    isPRN,
    acuityCalendarID,
    therapistState = '',
  } = therapistData;

  statesLicensed.sort();
  specialties.sort();

  const { resetAcuityCache } = useFlags();

  const { mutate: resetCache, isLoading: resetCacheIsLoading } = useResetCacheCalendar();


  return (
    <div tw="text-sm" data-testid="therapist-sidebar">
      <Heading>
        Therapist Information
        <Link
          onClick={() => {
            setIndex(1);
            window.scrollTo(0, document.body.scrollHeight);
          }}
          tw="ml-3 text-xs font-medium"
          to="#therapist-info"
        >
          Edit
        </Link>
      </Heading>

      <Mfa therapistFullName={`${firstName} ${lastName}`} therapistEmail={therapistEmail} />

      {therapistState && (
        <div tw="mb-8">
          <SubHeading>Therapist State Of Residence</SubHeading>
          {therapistState}
        </div>
      )}

      {statesLicensed.length > 0 && (
        <div tw="mb-8">
          <SubHeading>Licensed States</SubHeading>
          <ul>
            {statesLicensed.map((license, index) => (
              <li key={index}>{license}</li>
            ))}
          </ul>
        </div>
      )}

      {licenseNumbers && (
        <div tw="mb-8">
          <SubHeading>License Numbers</SubHeading>
          {textWithLineBreaks(licenseNumbers)}
        </div>
      )}

      {sme.length > 0 && (
        <div tw="mb-8">
          <SubHeading>SME</SubHeading>
          <ul>
            {sme.map((smeArea, index) => (
              <li key={index}>{smeArea}</li>
            ))}
          </ul>
        </div>
      )}

      {specialties.length > 0 && (
        <div tw="mb-8">
          <SubHeading>Clinical Competencies</SubHeading>
          <ul>
            {specialties.map((specialtyName, index) => (
              <li key={index}>{specialtyName}</li>
            ))}
          </ul>
        </div>
      )}

      {sequoiaID && (
        <div tw="mb-8">
          <SubHeading>Sequoia ID</SubHeading>
          {sequoiaID}
        </div>
      )}

      {npi && (
        <div tw="mb-8">
          <SubHeading>NPI</SubHeading>
          {npi}
        </div>
      )}

      {isPRN === true ? (
        <div tw="mb-8">
          <SubHeading>PRN</SubHeading>
          Yes
        </div>
      ) : (
        <div tw="mb-8">
          <SubHeading>PRN</SubHeading>
          No
        </div>
      )}

      <div tw="mb-8">
        <SubHeading>Acuity Calendar ID</SubHeading>
        <div>{acuityCalendarID}</div>

        {resetAcuityCache && acuityCalendarID && (
          <Link
            to="#"
            tw="text-xs font-medium"
            onClick={() => resetCache(Number(acuityCalendarID))}
            loading={resetCacheIsLoading}
          >
            Reset Cache
          </Link>
        )}
      </div>
    </div>
  );
}
