import { Dispatch, SetStateAction, useState } from 'react';

/**
 * Returns a stateful selected values list, and a function to set the
 * checked state of a single value.
 *
 * If `allValues` is specified, it's assumed that the first value of
 * `allValues` will be used to toggle between selecting all and none
 * of the values (eg: "Select all"). If this parameter is not specified,
 * then every value is treated the same (just a common value that can
 * be checked and unchecked).
 *
 * @param initialSelectedValues The list of initially selected values
 * @param allValues The list of all values that may be selected
 */
export default function useCheckboxesState<T>(
  initialSelectedValues: Array<T>,
  allValues?: Array<T>,
): [Array<T>, (value: T, checked: boolean) => void, Dispatch<SetStateAction<T[]>>] {
  const [selectedValues, setSelectedValues] = useState(initialSelectedValues);

  const setSelectedValue = (value: T, checked: boolean) => {
    if (allValues !== undefined && value === allValues[0]) {
      if (checked) {
        setSelectedValues([...allValues]);
      } else {
        setSelectedValues([]);
      }
    } else {
      if (checked) {
        setSelectedValues([...selectedValues, value]);
      } else {
        setSelectedValues(selectedValues.filter(v => v !== value));
      }
    }
  };

  return [selectedValues, setSelectedValue, setSelectedValues];
}
