import { useMutation, useQueryClient } from 'react-query';
import { http } from '@expressable/utils';
import { CompleteTherapistInformation } from '../types';
import { useToasts } from 'react-toast-notifications';
import { presentMutationError } from '@expressable/ui-library';

export interface ModifyTherapistPayload {
  therapistInformation: CompleteTherapistInformation;
  therapistId: string;
}

const modifyTherapist = async (modifyTherapistPayload: ModifyTherapistPayload) => {
  const { therapistId, therapistInformation: therapistInfo } = modifyTherapistPayload;
  return http.put(`/therapists/${therapistId}`, therapistInfo).then(res => res.data);
};

export default function useModifyTherapist() {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(modifyTherapist, {
    onSuccess: async (_, newTherapistInformation) => {
      await Promise.all([
        queryClient.invalidateQueries(['modify-therapist', newTherapistInformation.therapistId]),
        queryClient.invalidateQueries(['therapist', newTherapistInformation.therapistId]),
        queryClient.invalidateQueries('therapists'),
        queryClient.invalidateQueries('therapist-weekly-summary'),
      ]);
      addToast('Therapist Information Successfully Updated', { appearance: 'success', autoDismiss: true });
    },
    onError: async (_, newTherapistInformation) => {
      const hasSpecialty = newTherapistInformation.therapistInformation.specialties.length > 0;
      if (!hasSpecialty) {
        addToast('At least one Clinical Competency is required', { appearance: 'error', autoDismiss: true });
      } else {
        await presentMutationError;
      }
    },
  });
}
