import { useMutation, useQueryClient } from 'react-query';
import { http } from '@expressable/utils';
import { useToasts } from 'react-toast-notifications';
import toastMessages from '../utils/toast-messages';
import { presentMutationError } from '@expressable/ui-library';

export interface ClientStatusPayload {
  clientId: string;
  status: 'Active' | 'Inactive';
  reason?: string;
  reasonOther?: string;
}
const editClientStatus = async (clientStatusPayload: ClientStatusPayload) => {
  const { clientId, status, reason, reasonOther } = clientStatusPayload;
  return http.post(`/clients/${clientId}/status`, { status, reason, reasonOther }).then(res => res.data);
};

export default function useEditClientStatus() {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(editClientStatus, {
    onSuccess: (_, payload) => {
      // Query Invalidations
      addToast(toastMessages.UPDATE_CLIENT_STATUS_SUCCESS, { appearance: 'success', autoDismiss: true });
      queryClient.invalidateQueries(['client', payload.clientId]);
      queryClient.invalidateQueries(['care-plan-goals', payload.clientId]);
      queryClient.invalidateQueries(['care-plan-additional-goals', payload.clientId]);
    },
    onError: presentMutationError,
  });
}
