import dayjs from 'dayjs';
import React, { useMemo, useEffect } from 'react';
import {
  CompleteTherapistInformation,
  HourlyReportingDefaults,
  HourlyReportingParameters,
  ReportingWeek,
  SelectOption,
  TherapistTimesheetZeroHours,
} from 'types';
import { generateWeekPeriods } from 'utils/generate-week-periods';
import {
  formatHoursAndMinutes,
  getHoursRange,
  roundNumberByDecimal,
  splitNumberByDecimal,
} from 'utils/helpers';
import { useGetTherapistPayrollWeek } from './use-therapist-payroll';
import { useLocation } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { SessionTypeEnum } from 'domain/admin/constants';

export const HUB_FEATURE_RELEASE_DATE = '2023-05-28';

export function useTherapistHoursReportingHub(therapistData: CompleteTherapistInformation) {
  const { hash } = useLocation();
  const queryClient = useQueryClient();
  const reportingWeeks = useMemo(
    () => {
      const weekPeriods = generateWeekPeriods(HUB_FEATURE_RELEASE_DATE);
      return weekPeriods.map((weekPeriod, index) => {
        const [startOfWeek, endOfWeek] = weekPeriod;
        return {
          label: `${startOfWeek.format('MM/DD/YYYY')} - ${endOfWeek.format('MM/DD/YYYY')}`,
          value: {
            start: startOfWeek.format('YYYY-MM-DD'),
            end: endOfWeek.format('YYYY-MM-DD'),
          },
        }
      });
    },
    [],
  );

  const [selectedReportingWeek, setSelectedReportingWeek] = React.useState<
    SelectOption<{ start: string; end: string; }>
  >(reportingWeeks[0]);

  const { data: therapistNotes, isFetching } = useGetTherapistPayrollWeek(therapistData.therapistEmail, selectedReportingWeek?.value.start);

  const { committedHours = 0, acceptingNewPatients } = therapistData;

  useEffect(() => {
    // invalidate queries on hash change
    if (hash === '#therapist-time-hub') {
      queryClient.invalidateQueries('therapist-payroll');
    }
  }, [hash]);

  const hourlyReporting = useMemo(() => {
    if (!therapistNotes
      || therapistNotes.length === 0
      || !therapistNotes.find(note => note.sessionType === SessionTypeEnum.session || note.sessionType === SessionTypeEnum.evaluation)
    ) {
      return HourlyReportingDefaults;
    }

    const {
      clientAttendances,
      differentialHours,
      directTimeMinutes,
      evalsCompletedCount,
      sessionsCompletedCount,
    } = therapistNotes.reduce((accumulator: any, note) => {
      if (note.sessionType !== SessionTypeEnum.session && note.sessionType !== SessionTypeEnum.evaluation) {
        return accumulator;
      }

      const appointmentOn = dayjs(note.appointmentOn).startOf('week').format('YYYY-MM-DD');

      if (note.eventType === 'note_deleted') {
        accumulator.differentialHours -= note.differentialHours ?? 0;
        accumulator.directTimeMinutes -= note.adjustedDurationHours * 60;
        accumulator.evalsCompletedCount -= note.sessionType === SessionTypeEnum.evaluation ? 1 : 0;
        accumulator.sessionsCompletedCount -= note.sessionType === SessionTypeEnum.session ? 1 : 0;

        if (accumulator.clientAttendances[appointmentOn]) {
          const index = accumulator.clientAttendances[appointmentOn].findIndex((clientID: string) => clientID === note.clientID)
          accumulator.clientAttendances[appointmentOn].splice(index, 1); // delete the attendance

          if (accumulator.clientAttendances[appointmentOn].length === 0) {
            delete accumulator.clientAttendances[appointmentOn]; // if no more ids in the array, then delete the key.
          }
        }

      } else {
        accumulator.differentialHours += note.differentialHours ?? 0;
        accumulator.directTimeMinutes += note.adjustedDurationHours * 60;
        accumulator.evalsCompletedCount += note.sessionType === SessionTypeEnum.evaluation ? 1 : 0;
        accumulator.sessionsCompletedCount += note.sessionType === SessionTypeEnum.session ? 1 : 0;

        if (!accumulator.clientAttendances[appointmentOn]) {
          accumulator.clientAttendances[appointmentOn] = [];
        }

        if (!accumulator.clientAttendances[appointmentOn].includes(note.clientID)) {
          accumulator.clientAttendances[appointmentOn].push(note.clientID);
        }
      }

      return accumulator;
    }, {
      clientAttendances: {},
      differentialHours: 0,
      directTimeMinutes: 0,
      evalsCompletedCount: 0,
      sessionsCompletedCount: 0,
    });

    // for scenarios where notes were deleted -> direct time is 0.
    if (directTimeMinutes === 0) {
      return HourlyReportingDefaults;
    }

    const uniqueClientsPerWeekCount = Object.values(clientAttendances)
      .reduce((accumulator: number, attendances: any) => accumulator + attendances.length, 0);

    // hourly reporting calculations:
    const documentationMinutesLow = (evalsCompletedCount * HourlyReportingParameters.EvalDocumentationMinutesLow)
      + (sessionsCompletedCount * HourlyReportingParameters.SessionDocumentationMinutesLow);
    const documentationMinutesHigh = (evalsCompletedCount * HourlyReportingParameters.EvalDocumentationMinutesHigh)
      + (sessionsCompletedCount * HourlyReportingParameters.SessionDocumentationMinutesHigh);
    const otherIndirectMinutesLow = (uniqueClientsPerWeekCount * HourlyReportingParameters.OtherPerClientMinutesLow)
      + HourlyReportingParameters.OtherPerSlpMinutesLow;
    const otherIndirectMinutesHigh = (uniqueClientsPerWeekCount * HourlyReportingParameters.OtherPerClientMinutesHigh)
      + HourlyReportingParameters.OtherPerSlpMinutesHigh;
    const anticipatedMinutesWorkedLow = directTimeMinutes + documentationMinutesLow + otherIndirectMinutesLow;
    const anticipatedMinutesWorkedHigh = directTimeMinutes + documentationMinutesHigh + otherIndirectMinutesHigh;

    // hourly reporting transformation from minutes to hours and ranges:
    const anticipatedHoursWorkedLow = anticipatedMinutesWorkedLow / 60;
    const anticipatedHoursWorkedHigh = anticipatedMinutesWorkedHigh / 60;
    const anticipatedHoursWorkedRange = getHoursRange(anticipatedHoursWorkedLow, anticipatedHoursWorkedHigh);
    const documentationHoursLow = documentationMinutesLow / 60;
    const documentationHoursHigh = documentationMinutesHigh / 60;
    const documentationHoursRange = getHoursRange(documentationHoursLow, documentationHoursHigh);
    const otherIndirectHoursLow = otherIndirectMinutesLow / 60;
    const otherIndirectHoursHigh = otherIndirectMinutesHigh / 60;
    const otherIndirectHoursRange = getHoursRange(otherIndirectHoursLow, otherIndirectHoursHigh);

    // direct and differential hours:
    const totalDirectHours = directTimeMinutes / 60;
    const totalDirectHoursAndMinutes = formatHoursAndMinutes(
      ...splitNumberByDecimal(totalDirectHours),
    ) || TherapistTimesheetZeroHours;
    const differentialHoursAndMinutes = formatHoursAndMinutes(
      ...splitNumberByDecimal(differentialHours),
    ) || TherapistTimesheetZeroHours;

    // legacy anticipated hours:
    const RATE_MULTIPLIER = 1.7;
    const isLegacyAnticipatedHours = !!selectedReportingWeek
      && selectedReportingWeek.value.start < ReportingWeek.LegacyAnticipatedHours;
    const legacyAnticipatedHoursRaw = acceptingNewPatients
      ? Math.max(committedHours, totalDirectHours * RATE_MULTIPLIER)
      : totalDirectHours * RATE_MULTIPLIER;
    const legacyAnticipatedHours = roundNumberByDecimal(legacyAnticipatedHoursRaw);
    const legacyAnticipatedMinutes = legacyAnticipatedHours * 60;

    return {
      anticipatedHoursWorkedRange,
      anticipatedMinutesWorkedLow,
      anticipatedMinutesWorkedHigh,
      differentialHoursAndMinutes,
      documentationHoursRange,
      isLegacyAnticipatedHours,
      legacyAnticipatedHours,
      legacyAnticipatedMinutes,
      otherIndirectHoursRange,
      totalDirectHoursAndMinutes,
    };
  }, [therapistData, therapistNotes]);

  const onCompleteTimesheet = () => {
    const beginning = selectedReportingWeek ? dayjs(selectedReportingWeek.value.start) : dayjs().startOf('week');
    const end = selectedReportingWeek ? dayjs(selectedReportingWeek.value.end) : dayjs().endOf('week');

    const params = new URLSearchParams({
      email: therapistData.therapistEmail,
      committedHours: committedHours.toString(),
      'beginningOf[month]': (beginning.get('month') + 1).toString(),
      'beginningOf[day]': beginning.get('date').toString(),
      'beginningOf[year]': beginning.get('year').toString(),
      'endOf[month]': (end.get('month') + 1).toString(),
      'endOf[day]': end.get('date').toString(),
      'endOf[year]': end.get('year').toString(),
      'acceptingnew': therapistData.acceptingNewPatients ? 'yes' : 'no',
      'prn': therapistData.isPRN ? 'yes' : 'no',
      'minslow': hourlyReporting.isLegacyAnticipatedHours
        ? hourlyReporting.legacyAnticipatedMinutes.toString()
        : hourlyReporting.anticipatedMinutesWorkedLow.toString(),
      'minshigh': hourlyReporting.isLegacyAnticipatedHours
        ? hourlyReporting.legacyAnticipatedMinutes.toString()
        : hourlyReporting.anticipatedMinutesWorkedHigh.toString(),
    });

    window.open(`https://forms.expressable.com/240293838473969?${params.toString()}`, '_blank');
  };

  return {
    hourlyReporting,
    isFetching,
    onCompleteTimesheet,
    reportingWeeks,
    selectedReportingWeek,
    setSelectedReportingWeek,
    therapistNotes,
    therapistType: therapistData.therapistType ?? 'Hourly',
  };
}
