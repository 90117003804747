import React from 'react';
import { Input, Label, Textarea, FormGroup } from '@expressable/ui-library';
import { Appointment, SoapNoteContent } from 'types';
import { produce } from 'immer';
import 'twin.macro';

const subjectiveComplaintControls = [
  { label: 'Alert', value: 'alert' },
  { label: 'Engaged', value: 'engaged' },
  { label: 'Cooperative', value: 'cooperative' },
  { label: 'Tired', value: 'tired' },
  { label: 'Disengaged', value: 'disengaged' },
  { label: 'Non-compliant', value: 'non-compliant' },
];

interface SoapNoteFormContentProps {
  formContent: SoapNoteContent;
  setActivity: React.Dispatch<React.SetStateAction<Appointment>>;
  focusElementRef?: React.MutableRefObject<HTMLTextAreaElement | null>;
}

export const SoapNoteFormContent = (props: SoapNoteFormContentProps) => {
  const { formContent, setActivity, focusElementRef } = props;

  const handleSoapContentChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = event.currentTarget;
    type Keys = keyof Omit<SoapNoteContent, 'subjectiveComplaint'>;
    setActivity(
      produce(activity => {
        const content = activity.note.content as SoapNoteContent;
        content[name as Keys] = value;
      }),
    );
  };

  const handleSubjectiveComplaintChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.currentTarget;
    setActivity(
      produce(activity => {
        const content = activity.note.content as SoapNoteContent;
        content.subjectiveComplaint[name] = checked;
      }),
    );
  };

  return (
    <>
      <h4 tw="mb-2 text-sm font-semibold">Subjective Complaint</h4>
      {subjectiveComplaintControls.map(({ value, label }) => {
        return (
          <div key={value}>
            <FormGroup type="inline" tw="items-baseline">
              <Input
                spacing="tight"
                checked={formContent.subjectiveComplaint[value]}
                id={value}
                type="checkbox"
                name={value}
                onChange={handleSubjectiveComplaintChange}
                tw="mb-0"
              />
              <Label tw="font-normal mb-0 ml-2 text-sm" htmlFor={value}>
                {label}
              </Label>
            </FormGroup>
          </div>
        );
      })}
      <h4 tw="mt-6 font-semibold text-sm">Objective Findings</h4>
      <Textarea
        data-testid="objectiveFindings"
        name="objectiveFindings"
        value={formContent.objectiveFindings}
        ref={focusElementRef}
        onChange={handleSoapContentChange}
        tw="mt-2 w-full"
        rows={4}
      />
      <h4 tw="mt-6 font-semibold text-sm">Client/Parent Education</h4>
      <Textarea
        data-testid="parentEducationConducted"
        name="parentEducationConducted"
        id="parentEducationConducted"
        value={formContent.parentEducationConducted}
        tw="mt-2 w-full"
        rows={4}
        onChange={handleSoapContentChange}
      />
      <h4 tw="mt-6 font-semibold text-sm"> Response to Treatment/Assessment of Progress</h4>
      <Textarea
        data-testid="assessmentOfProgress"
        name="assessmentOfProgress"
        value={formContent.assessmentOfProgress}
        onChange={handleSoapContentChange}
        tw="mt-2 w-full"
        rows={4}
      />
      <h4 tw="mt-6 font-semibold text-sm"> Plans for Next Session</h4>
      <Textarea
        data-testid="plansForNextSession"
        name="plansForNextSession"
        value={formContent.plansForNextSession}
        onChange={handleSoapContentChange}
        tw="mt-2 w-full"
        rows={4}
      />
    </>
  );
};
