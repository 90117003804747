import { Card, CloseButton } from '@expressable/ui-library';

export interface ScheduleTipsProps {
  isClosed: boolean;
  onClose: () => void;
}

export const ScheduleTips = (props: ScheduleTipsProps) => {
  const { isClosed, onClose } = props;

  if (isClosed) {
    return null;
  }

  return (
    <Card className="p-6 pt-4 pb-4 mb-12 relative">
      <CloseButton onClick={onClose} />
      <h4 className="font-semibold mb-1">Scheduling Tips</h4>
      <ul className="list-disc list-inside pl-3">
        <li>
          <span className="relative right-2">
            Focus on finding the best therapist match for weekly recurring sessions first, so that it is more likely
            that the client will consistently attend.
          </span>
        </li>
        <li>
          <span className="relative right-2">
            A frequency of 2 sessions a week is recommended so that clients may reach their goals faster.
          </span>
        </li>
        <li>
          <span className="relative right-2">
            It may be easier to schedule the client’s initial evaluation on a day and time that isn’t the same as their
            recurring sessions.
          </span>
        </li>
        <li>
          <span className="relative right-2">
            A different therapist may conduct the initial evaluation based on availability or credentialing.
          </span>
        </li>
      </ul>
    </Card>
  );
};
