import { dynamicHttp } from '@expressable/utils';
import { FirehoseEvents } from 'domain/admin/types';
import { useQuery } from 'react-query';

const httpHealthRecordProxy = dynamicHttp('health-record-proxy');

const getFirehoseEvents = async (
  fromDate: string,
  toDate: string,
  eventTypes?: string,
  createdBy?: string,
  startKeyPK?: string,
  startKeySK?: string,
) => {
  const eventTypesUrl = eventTypes === 'All' ? '' : `&eventTypes=${eventTypes}`;
  const createdByUrl = createdBy === 'All' ? '' : `&createdBy=${createdBy}`;

  const { data } = await httpHealthRecordProxy.get<FirehoseEvents>(
    `/firehose/events?fromDate=${fromDate}&toDate=${toDate}${eventTypesUrl}${createdByUrl}`,
    {
      params: {
        startKeyPK: startKeyPK,
        startKeySK: startKeySK,
      },
    },
  );
  return data;
};

export function useFirehoseEvents(
  fromDate: string,
  toDate: string,
  eventTypes?: string,
  createdBy?: string,
  startKeyPK?: string,
  startKeySK?: string,
) {
  return useQuery(['firehose-events', fromDate, toDate, eventTypes, createdBy, startKeyPK, startKeySK], () =>
    getFirehoseEvents(fromDate, toDate, eventTypes, createdBy, startKeyPK, startKeySK),
  );
}
