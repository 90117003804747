import { useMutation, useQuery, useQueryClient } from 'react-query';
import { dynamicHttp } from '@expressable/utils';
import { useToasts } from 'react-toast-notifications';
import { presentMutationError } from '@expressable/ui-library';

const httpHealthRecordProxy = dynamicHttp('health-record-proxy');

export interface GetStartDatePayload {
  date: string;
}

export interface SetStartDate {
  clientId: string;
  details: string;
  shouldLogEvent: boolean;
}

const getStartDate = async (clientId: string) => {
  const { data } = await httpHealthRecordProxy.get(`/care-plans/start-date/${clientId}`);
  return data;
};

export function useGetPlanOfCareStartDate({ clientId }: { clientId: string }) {
  return useQuery<GetStartDatePayload | null>(['care-plan-start-date', clientId], () => getStartDate(clientId));
}

const setStartDate = async ({ clientId, ...rest }: SetStartDate) => {
  return httpHealthRecordProxy
    .post(`/care-plans/start-date/${clientId}`, { date: rest.details, shouldLogEvent: rest.shouldLogEvent })
    .then(res => res.data);
};

export function useSetStartDate() {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(setStartDate, {
    onSuccess: (_, payload) => {
      addToast('Start Date Successfully Updated', { appearance: 'success', autoDismiss: true });
      queryClient.invalidateQueries(['care-plan-start-date', payload.clientId]);
    },
    onError: presentMutationError,
  });
}
