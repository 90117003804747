import { useQuery, useMutation, useQueryClient } from 'react-query';
import { http, dynamicHttp } from '@expressable/utils';
import { useToasts } from 'react-toast-notifications';
import {
  ISingleAppointment,
  BadgeAppointmentNote,
  AppointmentDetail,
  UpcomingAppointments,
  UnlockedNoteInfo,
  RecurringAppointment,
  RescheduledBy,
} from 'types';
import { presentMutationError } from '@expressable/ui-library';
import { DismissReason } from 'components/client-attendance-modals/log-dismiss-attendance-modal';
import { RescheduleDetailDTO } from 'domain/log-attedances/types';
import { invalidateAttendanceQueries } from './shared/attendance-utils';
import { Moment } from 'moment';

const httpHealthRecordProxy = dynamicHttp('health-record-proxy');
const httpHealthRecordApi = dynamicHttp('health-record');
const getAppointments = async (clientID: string) => {
  const { data } = await http.get<ISingleAppointment[]>(`/clients/${clientID}/appointments`);
  return data;
};

export function useAppointments(clientID: string) {
  return useQuery(['appointments', clientID], () => getAppointments(clientID));
}

const getUpcomingAppointments = async (email?: string) => {
  if (!email) {
    return null;
  }
  const { data } = await http.get<UpcomingAppointments>(`/${email}/upcoming`);
  return data;
};

export function useUpcomingAppointments(isTherapist: boolean, email?: string) {
  return useQuery(['upcoming-appointments', isTherapist, email], () => getUpcomingAppointments(email), {
    enabled: isTherapist,
  });
}

const getOpenAppointments = async () => {
  const { data } = await httpHealthRecordProxy.get<BadgeAppointmentNote[]>(
    '/client-attendances/dashboard/open-clients',
  );
  return data;
};

export function useOpenAppointments(isTherapist: boolean) {
  return useQuery(['open-appointments'], () => getOpenAppointments(), {
    enabled: isTherapist,
  });
}

const getTodayAppointments = async () => {
  const { data } = await httpHealthRecordProxy.get<AppointmentDetail[]>('/client-attendances/dashboard/today');
  return data;
};

export function useTodayAppointments(isTherapist: boolean) {
  return useQuery(['today-appointments'], () => getTodayAppointments(), {
    enabled: isTherapist,
  });
}

const getUnlockedAppointments = async (type: 'evaluation-note' | 'session-note' | 'discharge-note') => {
  const { data } = await httpHealthRecordApi.get<UnlockedNoteInfo[]>(
    `/dashboard/therapist-unlocked-notes?noteType=${type}`,
  );
  return data;
};

export function useUnlockedAppointments() {
  const dischargeNotesQuery = useQuery(['unlocked-appointments-discharge'], () =>
    getUnlockedAppointments('discharge-note'),
  );
  const sessionNotesQuery = useQuery(['unlocked-appointments-session'], () => getUnlockedAppointments('session-note'));
  const evaluationNoteQuery = useQuery(['unlocked-appointments-evaluations'], () =>
    getUnlockedAppointments('evaluation-note'),
  );

  return {
    sessionNotes: sessionNotesQuery.data ?? [],
    evaluationNotes: evaluationNoteQuery.data ?? [],
    dischargeNotes: dischargeNotesQuery.data ?? [],
  };
}

export interface CreateAppointmentPayload {
  clientID: string;
  dateTime: string | Moment;
  clientFirstName: string;
  preferredFirstName?: string;
  clientLastName: string;
  contactTimeZone: string;
  userTimezone: string;
  contactEmail: string;
  therapistEmail: string;
  contactPhone: string;
  duration: number;
  recurring?: boolean;
  override?: boolean;
  recurringForever?: boolean;
  daysOfWeek?: Array<number>;
  recurrenceFrequency?: string;
  recurrenceTimes?: number;
}

export interface BulkRescheduleAppointmentsPayload {
  clientID: string;
  therapistEmail: string;
  contactTimeZone: string;
  appointments: {
    appointmentID: number;
    dateTime: Date;
  }[];
  rescheduleDetail: {
    rescheduledBy: RescheduledBy | '';
    rescheduledReason: string;
    rescheduledReasonOther?: string;
  };
  override?: boolean;
}
const createAppointment = async (createAppointmentPayload: CreateAppointmentPayload) => {
  const { clientID, ...appointmentData } = createAppointmentPayload;
  return http.put(`/clients/${clientID}/appointments`, appointmentData).then(res => res.data);
};

const bulkRescheduleAppointments = async (bulkRescheduleAppointmentsPayload: BulkRescheduleAppointmentsPayload) => {
  const { clientID, ...appointmentData } = bulkRescheduleAppointmentsPayload;
  return http.post(`/clients/${clientID}/appointments/reschedule`, appointmentData).then(res => res.data);
};

export function useBulkRescheduleAppointments() {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(bulkRescheduleAppointments, {
    onSuccess: async (_, payload) => {
      addToast('Appointments Successfully Rescheduled', { appearance: 'success', autoDismiss: true });
      await queryClient.invalidateQueries(['appointments', payload.clientID]);
      setTimeout(() => {
        queryClient.invalidateQueries(['client-activity', payload.clientID]);
      }, 3000);
    },
    onError: presentMutationError,
  });
}

export function useCreateAppointment() {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(createAppointment, {
    onSuccess: async (_, payload) => {
      addToast('Appointment Successfully Created', { appearance: 'success', autoDismiss: true });
      await queryClient.invalidateQueries(['appointments', payload.clientID]);
    },
  });
}

export interface EditAppointmentPayload {
  appointmentData: {
    acuityId: string | undefined;
    appointmentDate: string | undefined;
    contactTimeZone: string;
    duration?: number;
  };
  clientID: string;
  rescheduleDetail: {
    rescheduledBy: 'client' | 'therapist' | '';
    rescheduledReason: string;
    rescheduledReasonOther?: string;
    therapistEmail?: string;
  };
  override?: boolean;
}

const editAppointment = async (editAppointmentPayload: EditAppointmentPayload) => {
  const { appointmentData, clientID, override } = editAppointmentPayload;
  if (appointmentData) {
    const { acuityId, appointmentDate, contactTimeZone, duration } = appointmentData;
    return http
      .post(`/clients/${clientID}/appointments/${acuityId}`, {
        ...editAppointmentPayload.rescheduleDetail,
        rescheduleDetail: editAppointmentPayload.rescheduleDetail,
        dateTime: appointmentDate,
        contactTimeZone,
        duration,
        override,
      })
      .then(res => res.data);
  } else {
    return false;
  }
};

export function useEditAppointment() {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(editAppointment, {
    onSuccess: _ => {
      addToast('Appointment Successfully Updated', { appearance: 'success', autoDismiss: true });
      queryClient.invalidateQueries(['appointments']);
    },
  });
}

const editClientAttendance = async (editAppointmentPayload: {
  data: RescheduleDetailDTO;
  clientID: string;
  acuityId: string;
}) => {
  const { acuityId, data, clientID } = editAppointmentPayload;
  return httpHealthRecordProxy.put(`/client-attendances/${clientID}/${acuityId}/status`, data).then(res => res.data);
};

export function useClientAttendanceRescheduleAppointment() {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(editClientAttendance, {
    onSuccess: async _ => {
      addToast('Appointment Successfully Updated', { appearance: 'success', autoDismiss: true });
      setTimeout(() => {
        invalidateAttendanceQueries(queryClient);
      }, 1000);
    },
    onError: () => {
      invalidateAttendanceQueries(queryClient);
    },
  });
}

const deleteClientAttendance = async (editAppointmentPayload: {
  data: DismissReason;
  clientID: string;
  acuityId: string;
}) => {
  const { acuityId, clientID } = editAppointmentPayload;
  return httpHealthRecordProxy.delete(`/client-attendances/${clientID}/${acuityId}`).then(res => res.data);
};

export function useClientAttendanceDismissAppointment() {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(deleteClientAttendance, {
    onSuccess: _ => {
      addToast('Appointment Successfully Deleted', { appearance: 'success', autoDismiss: true });
      setTimeout(() => {
        invalidateAttendanceQueries(queryClient);
      }, 1000);
    },
    onError: () => {
      invalidateAttendanceQueries(queryClient);
    },
  });
}

export interface DeleteAppointmentPayload {
  appointmentData: {
    acuityId: string | undefined;
    appointmentDate: string | undefined;
  };
  clientID: string;
  cancelDetail: {
    canceledBy: 'client' | 'therapist' | '';
    cancelationReason: string;
    cancelationReasonOther?: string;
    rescheduleAttempted: boolean;
    additionalText?: string;
    wasLateCancel: boolean;
  };
}

const deleteAppointment = async (deleteAppointmentPayload: DeleteAppointmentPayload) => {
  const { appointmentData, clientID } = deleteAppointmentPayload;
  if (appointmentData) {
    const { acuityId } = appointmentData;
    return http
      .delete(`/clients/${clientID}/appointments/${acuityId}`, {
        data: {
          ...deleteAppointmentPayload.cancelDetail,
          cancelDetail: deleteAppointmentPayload.cancelDetail,
        },
      })
      .then(res => res.data);
  }
};

export function useDeleteAppointment() {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(deleteAppointment, {
    onSuccess: (_, payload) => {
      addToast('Appointment Successfully Deleted', { appearance: 'success', autoDismiss: true });
      queryClient.invalidateQueries(['appointments', payload.clientID]);
      setTimeout(() => {
        queryClient.invalidateQueries();
      }, 1000);
    },
    onError: presentMutationError,
  });
}

export interface EditMultipleAppointmentsPayload {
  appointmentsData: {
    appointments: Array<string>;
  };
  clientId: string;
}

const editMultipleAppointments = async (editMultipleAppointmentsPayload: EditMultipleAppointmentsPayload) => {
  const { appointmentsData: appointmentsData, clientId } = editMultipleAppointmentsPayload;
  if (appointmentsData.appointments.length) {
    return http
      .delete(`/clients/${clientId}/appointments`, {
        data: appointmentsData,
      })
      .then(res => res.data);
  } else {
    return false;
  }
};

export function useEditMultipleAppointments() {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(editMultipleAppointments, {
    onSuccess: (_, payload) => {
      addToast('Appointments Successfully Canceled', { appearance: 'success', autoDismiss: true });
      queryClient.invalidateQueries(['appointments', payload.clientId]);
    },
    onError: presentMutationError,
  });
}

const resetCacheClient = async (clientID: string) => {
  const response = await httpHealthRecordProxy.post(`/acuity-cache/clients/${clientID}/reset`);
  return response.data;
};

export function useResetCacheClient() {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(resetCacheClient, {
    onSuccess: (data, clientID) => {
      addToast(`Cache was reset (${data.deleted} deleted, ${data.inserted} inserted/updated)`, {
        appearance: 'success',
        autoDismiss: true,
      });
      queryClient.invalidateQueries(['appointments', clientID]);
    },
    onError: presentMutationError,
  });
}

const getRecurringAppointments = async (clientID: string) => {
  const { data } = await http.get<RecurringAppointment[]>(`/clients/${clientID}/recurring-appointments`);
  return data;
};

export function useRecurringAppointments(clientID: string) {
  return useQuery(['recurring-appointments', clientID], () => getRecurringAppointments(clientID));
}

const getUpcomingRecurringAppointments = async (clientID: string, recurringAppointmentID: string) => {
  const { data } = await http.get<RecurringAppointment[]>(
    `/clients/${clientID}/recurring-appointments/${recurringAppointmentID}/upcoming`,
  );
  return data;
};

export function useUpcomingRecurringAppointments(clientID: string, recurringAppointmentID: string) {
  return useQuery(['upcoming-recurring-appointments', clientID, recurringAppointmentID], () =>
    getUpcomingRecurringAppointments(clientID, recurringAppointmentID),
  );
}

export interface ReassignRecurringAppointmentPayload {
  therapistID: string;
  clientID: string;
  recurringAppointmentID: string;
}

const reassignRecurringAppointment = async (
  reassignRecurringAppointmentPayload: ReassignRecurringAppointmentPayload,
) => {
  const { therapistID, clientID, recurringAppointmentID } = reassignRecurringAppointmentPayload;
  return http
    .post(`/clients/${clientID}/recurring-appointments/${recurringAppointmentID}/reassign`, {
      therapistID: therapistID,
    })
    .then(res => res.data);
};

export function useReassignRecurringAppointment() {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(reassignRecurringAppointment, {
    onSuccess: _ => {
      addToast('Recurring Appointment Successfully Reassigned', { appearance: 'success', autoDismiss: true });
      queryClient.invalidateQueries(['reassign-recurring-appointments']);
    },
  });
}

export interface RescheduleRecurringAppointmentPayload {
  dayOfWeek: number;
  time: string;
  clientID: string;
  recurringAppointmentID: string;
  rescheduleDetail: {
    rescheduledBy: RescheduledBy | '';
    rescheduledReason: string;
    rescheduledReasonOther?: string;
  };
  override?: boolean;
}

const rescheduleRecurringAppointment = async (
  rescheduleRecurringAppointmentPayload: RescheduleRecurringAppointmentPayload,
) => {
  const { clientID, dayOfWeek, recurringAppointmentID, rescheduleDetail, time, override } =
    rescheduleRecurringAppointmentPayload;
  return http
    .post(`/clients/${clientID}/recurring-appointments/${recurringAppointmentID}/reschedule`, {
      dayOfWeek: dayOfWeek,
      rescheduleDetail,
      time: time,
      override,
    })
    .then(res => res.data);
};

export function useRescheduleRecurringAppointment() {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(rescheduleRecurringAppointment, {
    onSuccess: (_, payload) => {
      addToast('Recurring Appointment Successfully Rescheduled', { appearance: 'success', autoDismiss: true });
      queryClient.invalidateQueries(['rescheduled-recurring-appointments']);
      setTimeout(() => {
        queryClient.invalidateQueries(['client-activity', payload.clientID]);
      }, 3000);
    },
  });
}

export interface CancelRecurringAppointmentPayload {
  canceledBy: string;
  cancelationReason: string;
  cancelationReasonOther: string | undefined;
  rescheduleAttempted: boolean;
  clientID?: string;
  recurringAppointmentID?: string;
  additionalText: string | undefined;
}

const cancelRecurringAppointment = async (cancelRecurringAppointmentPayload: CancelRecurringAppointmentPayload) => {
  const { clientID, recurringAppointmentID, canceledBy, cancelationReason, cancelationReasonOther, additionalText } =
    cancelRecurringAppointmentPayload;
  return http
    .delete(`/clients/${clientID}/recurring-appointments/${recurringAppointmentID}/cancel`, {
      data: {
        canceledBy: canceledBy,
        cancelationReason: cancelationReason,
        cancelationReasonOther: cancelationReasonOther ? cancelationReasonOther : undefined,
        additionalText: additionalText,
        rescheduleAttempted: false,
      },
    })
    .then(res => res.data);
};

export function useCancelRecurringAppointment() {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(cancelRecurringAppointment, {
    onSuccess: _ => {
      addToast('Recurring Appointment Successfully Deleted', { appearance: 'success', autoDismiss: true });
      queryClient.invalidateQueries(['cancel-recurring-appointments']);
    },
  });
}

const resetCacheCalendar = async (calendarID: number) => {
  const response = await httpHealthRecordProxy.post(`/acuity-cache/calendars/${calendarID}/reset`);
  return response.data;
};

export function useResetCacheCalendar() {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(resetCacheCalendar, {
    onSuccess: data => {
      addToast(`Cache was reset (${data.deleted} deleted, ${data.inserted} inserted/updated)`, {
        appearance: 'success',
        autoDismiss: true,
      });
      queryClient.invalidateQueries(['appointments']);
    },
    onError: presentMutationError,
  });
}
