import { SelectOption } from 'types';

// @TODO(juan): confirm these options, I copied these values from dev API response for one of diego's test users that contains a lot of languages
export const LANGUAGE_OPTIONS = {
  ENGLISH: {
    label: 'English',
    value: 'en',
  },
  HAITIAN: {
    label: 'Haitian Creole',
    value: 'ht',
  },
  RUSSIAN: {
    label: 'Russian',
    value: 'ru',
  },
  SPANISH: {
    label: 'Spanish',
    value: 'es',
  },
};

export const languageOptions: SelectOption[] = Object.values(LANGUAGE_OPTIONS);

export type EvaluationOption = SelectOption<number>;

export const EVALUATION_OPTIONS = {
  STANDARD: {
    value: 60,
    label: '60 Minute Standard Evaluation',
  },
  SHORT: {
    value: 45,
    label: '45 Minute Evaluation',
  },
};

export const evaluationTypeOptions: EvaluationOption[] = Object.values(EVALUATION_OPTIONS);

export const specialties = [
  'Accent Modification',
  'Acquired Apraxia',
  'Adult Articulation',
  'Adult Cognition',
  'Adult Dysphagia',
  'Adult Fluency/Stuttering',
  'Adult Social-Pragmatics',
  'Aphasia',
  'Auditory Processing Disorder',
  'Augmentative and Alternative Communication (AAC)',
  'Aural Rehabilitation',
  'Autism Spectrum Disorder',
  'Childhood Apraxia of Speech',
  'Cleft Palate/Cleft Lip',
  'Down’s Syndrome',
  'Dysarthria',
  'Early Language Development',
  'Gender Affirming Voice',
  'Orofacial Myofunctional Disorders',
  'Parkinson’s',
  'Pediatric Articulation/Phonological Disorder',
  'Pediatric Cognition',
  'Pediatric Dysphagia/Sensory Feeding',
  'Pediatric Fluency/Stuttering',
  'Pediatric Receptive-Expressive Language Disorder',
  'Pediatric/Adolescent Social-Pragmatics',
  'Professional/Public Speaking',
  'Selective Mutism',
  'Traumatic Brain Injury',
  'Voice',
  'Written Language Disorders',
];

export const specialtyOptions: SelectOption[] = specialties.map(specialty => ({
  label: specialty,
  value: specialty,
}));
