import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { Button, CloseButton, Modal, ModalBody, ModalContent, ModalHeader } from '@expressable/ui-library';
import { AppointmentDetail } from 'types';
import ModalHeadline from 'components/modal-headline';
import 'twin.macro';
import { useClientAttended } from 'hooks/use-pending-appointments';
import { useForm } from 'react-hook-form';
import { clientFullName } from 'utils/appointments-utils';
import { useBillingInfo } from 'hooks/use-billing-information';
import { useGetCredits } from 'hooks/use-credits';
import { formatCurrencyDollars } from '../../utils/helpers';
import { getCueError } from 'utils/error';
import { shouldSkipAttendanceError } from 'hooks/shared/attendance-utils';

export type ClientAttendedPayload = {
  status: 'client-attended';
};

export interface LogAttendanceModalProps {
  isOpen: boolean;
  onClose: () => void;
  activity: AppointmentDetail;
  clientId: string;
}

const CLIENT_ATTENDED_INITIAL_STATE: ClientAttendedPayload = {
  status: 'client-attended',
};

export function LogAttendanceModal(props: LogAttendanceModalProps) {
  const { isOpen, onClose, activity, clientId } = props;
  const { mutateAsync: logClientAttendedMutation, isLoading: isClientAttendedLoading } = useClientAttended();
  const [errorMessage, setErrorMessage] = useState<string>();
  const { data: billingInfoData } = useBillingInfo(clientId);
  const { data: creditsData } = useGetCredits(clientId);
  const { handleSubmit } = useForm<ClientAttendedPayload>({
    defaultValues: CLIENT_ATTENDED_INITIAL_STATE,
  });

  const onSubmit = async (data: ClientAttendedPayload) => {
    await logClientAttendedMutation(
      {
        data: data,
        clientId: activity.clientID,
        acuityId: activity.acuityAppointmentID ?? '',
      },
      {
        onError: err => {
          if (shouldSkipAttendanceError(err)) {
            onClose();
            return;
          }
          const error = getCueError(err);
          setErrorMessage(error.message ?? 'An unknown error occurred while logging attendance.');
          return;
        },
      },
    );

    onClose();
  };

  const isNoCharge =
    activity?.appointmentTypeID === 'evaluation' ||
    billingInfoData?.selfPay?.automaticPayments?.pricingMonthlyMembership?.price === 229 ||
    billingInfoData?.primaryInsurance?.coPay === 0;

  const resetModalState = () => {
    setErrorMessage(undefined);
  };
  useEffect(resetModalState, [isOpen]);

  const isLoading = isClientAttendedLoading;

  return (
    <Modal isOpen={isOpen} tw="max-w-lg">
      <ModalContent>
        <ModalHeader>
          <div tw="absolute top-0 right-0 pt-4 pr-4">
            <CloseButton testId="close-log-attendance-modal" onClick={onClose} />
          </div>
        </ModalHeader>
        <ModalBody>
          <React.Fragment>
            <div tw="flex items-center justify-center w-12 h-12 mx-auto bg-indigo-100 rounded-full">
              <FontAwesomeIcon tw="text-2xl text-indigo-700" icon={faCalendar} />
            </div>
            <form data-testid="log-attendance-form" tw="my-3 sm:mt-5" onSubmit={handleSubmit(onSubmit)}>
              <ModalHeadline text={`Log Attendance for ${clientFullName(activity)}`} />
              <React.Fragment>
                {errorMessage && <p tw="text-center text-red-600 font-semibold mb-2">{errorMessage}</p>}
                {isNoCharge ? (
                  <p tw="text-center">
                    Log your client&apos;s session attendance. A note will be started automatically for this client
                  </p>
                ) : (
                  <>
                    {creditsData && creditsData?.credits > 0 ? (
                      <p tw="text-center">
                        {`Log your client's session attendance and reedem 1 credit. A note will be started automatically for this client`}
                      </p>
                    ) : (
                      <>
                        {billingInfoData?.selfPay?.automaticPayments?.pricingForService?.price && (
                          <p tw="text-center">
                            {`Log your client's session attendance and charge $${billingInfoData.selfPay.automaticPayments.pricingForService.price.toFixed(
                              2,
                            )}. A note will be started
                        automatically for this client`}
                          </p>
                        )}
                        {billingInfoData?.selfPay?.automaticPayments?.pricingMonthlyMembership?.price === 240 && (
                          <p tw="text-center">
                            {`Log your client's session attendance and charge $60.00. A note will be started
                        automatically for this client`}
                          </p>
                        )}
                        {billingInfoData?.primaryInsurance?.coPay && (
                          <p tw="text-center">
                            {`Log your client's session attendance and charge co-pay ${formatCurrencyDollars(
                              billingInfoData?.primaryInsurance?.coPay,
                            )}. A note will be started
                        automatically for this client`}
                          </p>
                        )}
                      </>
                    )}
                  </>
                )}
              </React.Fragment>
              <div data-testid="note-message" tw="text-base font-normal leading-5 mb-7 flex justify-center"></div>
              <Button data-testid="log-attendance-onsave" variant="primary" fullWidth loading={isLoading}>
                Log Attendance
              </Button>
              <div tw="my-3">
                <Button data-testid="log-attendance-close" variant="secondary" fullWidth onClick={onClose}>
                  Exit Without Logging
                </Button>
              </div>
            </form>
          </React.Fragment>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
