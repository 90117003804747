export const firehoseEventsArray = [
  { value: 'note_created', label: 'Note Created' },
  { value: 'note_locked', label: 'Note Locked' },
  { value: 'note_unlocked', label: 'Note Unlocked' },
  { value: 'note_deleted', label: 'Note Deleted' },
  { value: 'appointment_created', label: 'Appointment Created' },
  { value: 'appointment_cancelled', label: 'Appointment Cancelled' },
  { value: 'appointment_late_cancelled', label: 'Appointment Late Cancelled' },
  { value: 'appointment_no_showed', label: 'Appointment No Showed' },
  { value: 'appointment_rescheduled', label: 'Appointment Rescheduled' },
  { value: 'acuity_appointment_added', label: 'Acuity Appointment Added' },
  { value: 'acuity_appointment_cancelled', label: 'Acuity Appointment Cancelled' },
  { value: 'acuity_appointment_rescheduled', label: 'Acuity Appointment Rescheduled' },
  { value: 'client_created', label: 'Client Created' },
  { value: 'client_archived', label: 'Client Archived' },
  { value: 'client_information_modified', label: 'Client Information Modified' },
  { value: 'client_reassigned', label: 'Client Reassigned' },
  { value: 'client_billing_information_added', label: 'Client Billing Information Added' },
  { value: 'client_billing_information_modified', label: 'Client Billing Information Modified' },
  { value: 'client_unarchived', label: 'Client Unarchived' },
  { value: 'evaluation_review_sent', label: 'Evaluation Review Sent' },
  { value: 'session_review_sent', label: 'Session Review Sent' },
  { value: 'evaluation_review_received', label: 'Evaluation Review Received' },
  { value: 'session_review_received', label: 'Session Review Received' },
  { value: 'discharge_review_received', label: 'Discharge Review Received' },
  { value: 'provider_added', label: 'Provider Added' },
  { value: 'claim_finalized', label: 'Claim Finalized' },
  { value: 'claim_finalized_email_sent', label: 'Claim Email Sent' },
  // { value: 'claim_payment_successful', label: 'Claim Payment Successful' },
  // { value: 'claim_payment_failed', label: 'Claim Payment Failed' },
  { value: 'message_sent', label: 'SMS Messages - Sent' },
  { value: 'message_received', label: 'SMS Messages - Received' },
  { value: 'pending_reschedule_created', label: 'Pending Reschedule Created' },
  { value: 'pending_reschedule_rescheduled', label: 'Pending Reschedule Rescheduled' },
  { value: 'pending_reschedule_cancelled', label: 'Pending Reschedule Cancelled' },
  { value: 'pending_reschedule_reminder_sent', label: 'Pending Reschedule Reminder Sent' },
  { value: 'client_assignment_assigned', label: 'Client Assignment Assigned' },
  { value: 'client_assignment_reassigned', label: 'Client Assignment Reassigned' },
  { value: 'client_assignment_completed', label: 'Client Assignment Completed' },
  { value: 'client_assignment_unassigned', label: 'Client Assignment Unassigned' },
  { value: 'jotform_submission_received', label: 'Jotform Submission Received' },
  // { value: 'analytics_active_clients_calculated', label: 'Analytics Active Clients Calculated' },
  // { value: 'intake_initial_sent', label: 'Intake Initial Sent' },
  // { value: 'intake_reminder_sent', label: 'Intake Reminder Sent' },
  { value: 'quality_review_discharge_status_updated', label: 'Quality Review Discharge Status Updated' },
  { value: 'quality_review_evaluation_status_updated', label: 'Quality Review Evaluation Status Updated' },
  // { value: 'long_term_goal_added', label: 'Long Term Goal Added' },
  // { value: 'long_term_goal_modified', label: 'Long Term Goal Modified' },
  // { value: 'long_term_goal_removed', label: 'Long Term Goal Removed' },
  // { value: 'long_term_goal_met', label: 'Long Term Goal Met' },
  // { value: 'long_term_goal_partially_met', label: 'Long Term Goal Partially Met' },
  // { value: 'long_term_goal_discontinued', label: 'Long Term Goal Discontinued' },
  // { value: 'short_term_goal_added', label: 'Short Term Goal Added' },
  // { value: 'short_term_goal_modified', label: 'Short Term Goal Modified' },
  // { value: 'short_term_goal_removed', label: 'Short Term Goal Removed' },
  // { value: 'short_term_goal_met', label: 'Short Term Goal Met' },
  // { value: 'short_term_goal_partially_met', label: 'Short Term Goal Partially Met' },
  // { value: 'short_term_goal_discontinued', label: 'Short Term Goal Discontinued' },
  { value: 'caregiver_goal_added', label: 'Caregiver Goal Added' },
  { value: 'caregiver_goal_modified', label: 'Caregiver Goal Modified' },
  { value: 'caregiver_goal_removed', label: 'Caregiver Goal Removed' },
  { value: 'caregiver_goal_met', label: 'Caregiver Goal Met' },
  { value: 'caregiver_goal_discontinued', label: 'Caregiver Goal Discontinued' },
  { value: 'personal_goal_added', label: 'Personal Goal Added' },
  { value: 'personal_goal_modified', label: 'Personal Goal Modified' },
  { value: 'personal_goal_removed', label: 'Personal Goal Removed' },
  { value: 'personal_goal_met', label: 'Personal Goal Met' },
  { value: 'personal_goal_discontinued', label: 'Personal Goal Discontinued' },
  { value: 'visit_frequency_added', label: 'Visit Frequency Added' },
  { value: 'visit_frequency_modified', label: 'Visit Frequency Modified' },
  { value: 'claim_pre_collection_adjustment', label: 'Claim Adjustment' },

  //From this to below, the list does not match with the BE list
  //
  // { value: 'child_intake_sent', label: 'Child Intake Sent' },
  // { value: 'adult_intake_sent', label: 'Adult Intake Sent' },
  // { value: 'child_intake_completed', label: 'Child Intake Completed' },
  // { value: 'adult_intake_completed', label: 'Adult Intake Completed' },
  { value: 'contact_created', label: 'Contact Created' },
  { value: 'contact_modified', label: 'Contact Modified' },
  { value: 'contact_archived', label: 'Contact Archived' },
  { value: 'contact_unarchived', label: 'Contact Unarchived' },
  { value: 'home_program_completed', label: 'Home Program Completed' },
  // { value: 'therapist_created', label: 'Therapist Created' },
  // { value: 'therapist_modified', label: 'Therapist Modified' },
  // { value: 'diagnosis_added', label: 'Diagnosis Added' },
  // { value: 'diagnosis_removed', label: 'Diagnosis Removed' },
  // { value: 'claim_submitted', label: 'Claim Submitted' },
  { value: 'client_files_uploaded', label: 'File Uploaded' },
  { value: 'client_files_deleted', label: 'File Deleted' },
  { value: 'stripe_invoice_created', label: 'Stripe Invoice Created' },
  { value: 'stripe_charge_succeeded', label: 'Stripe Charge Succeeded' },
  { value: 'stripe_invoice_finalized', label: 'Stripe Invoice Finalized' },
  { value: 'stripe_payment_method_updated', label: 'Stripe Payment Method Updated' },
  { value: 'stripe_invoice_deleted', label: 'Stripe Invoice Deleted' },
  { value: 'stripe_charge_failed', label: 'Stripe Charge Failed' },
  { value: 'stripe_invoice_payment_failed', label: 'Stripe Invoice Payment Failed' },
  { value: 'stripe_payment_method_attached', label: 'Stripe Payment Method Attached' },
  { value: 'stripe_invoice_marked_uncollectible', label: 'Stripe Invoice Marked Uncollectible' },
  { value: 'stripe_invoice_overdue', label: 'Stripe Invoice Overdue' },
  { value: 'stripe_invoice_paid', label: 'Stripe Invoice Paid' },
  { value: 'stripe_refund_created', label: 'Stripe Refund Created' },
];
