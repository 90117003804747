import { calculateClassName } from '@expressable/ui-library';
import React from 'react';
import { Theme, Props as SelectProps } from 'react-select';
import CreatableSelect from 'react-select/creatable';

interface Props extends SelectProps {
  spacing?: 'tight' | 'small' | 'normal';
  maxWidth?: 'small' | 'large';
  readOnly?: boolean;
}

const Error = ({ error }: { error: { [key: string]: string } }) => (
  <p className="absolute text-red-500 text-xs italic">
    {error.message.length ? error.message : 'Please fill out this field.'}
  </p>
);

const customTheme = (defaultTheme: Theme) => {
  defaultTheme.colors.primary = '#4c51bf';
  defaultTheme.colors.primary75 = '#4c51bf';
  defaultTheme.colors.primary50 = '#667eea';
  defaultTheme.colors.primary25 = '#c3dafe';
  return defaultTheme;
};

const maxWidthToClass = {
  small: `max-w-sm`,
  large: `max-w-lg`,
  full: `max-w-full`,
};

type SelectRef = CreatableSelect<any, any>;

export const SelectCreatable = React.forwardRef<SelectRef, Props>(function Select(
  { error, className, spacing, maxWidth = 'small', disabled, readOnly, ...props }: Props,
  ref,
) {
  const spacingStyle = {
    tight: `mb-0`,
    small: `mb-2`,
    normal: `mb-5`,
  };
  const errorStyle = `border rounded border-red-500 focus:border-red-500 focus:bg-white`;

  const maxWidthCss = maxWidthToClass[maxWidth];

  const readOnlyProps =
    readOnly === true
      ? {
          isClearable: false,
          isSearchable: false,
          openMenuOnClick: false,
          openMenuOnFocus: false,
          menuIsOpen: false,
        }
      : null;

  return (
    <div
      className={calculateClassName([`relative`, error ? errorStyle : '', spacingStyle[spacing ?? 'tight'], className])}
    >
      <CreatableSelect
        ref={ref}
        {...props}
        theme={customTheme}
        className={maxWidthCss}
        isDisabled={disabled}
        {...readOnlyProps}
      ></CreatableSelect>
      {error && <Error error={error} />}
    </div>
  );
});

CreatableSelect.defaultProps = {
  spacing: 'normal',
};
