import { QueryClient } from 'react-query';
import { AttendanceErrorKey } from 'domain/log-attedances/constants';
import { getCueError } from 'utils/error';

export const invalidateAttendanceQueries = (queryClient: QueryClient) => {
  queryClient.invalidateQueries(['adminPendingReschedules']);
  queryClient.invalidateQueries(['appointments']);
  queryClient.invalidateQueries(['billingInfo']);
  queryClient.invalidateQueries(['client-activity']);
  queryClient.invalidateQueries(['clientPendingReschedules']);
  queryClient.invalidateQueries(['credits']);
  queryClient.invalidateQueries(['pending-appointments']);
  queryClient.invalidateQueries(['pending-reschedules']);
  queryClient.invalidateQueries(['therapistPendingReschedules']);
  queryClient.invalidateQueries(['therapistSlots']);
  queryClient.invalidateQueries(['today-appointments']);
  queryClient.invalidateQueries(['upcoming-appointments']);
}

const DEFAULT_SKIPPABLE_ERRORS = [AttendanceErrorKey.AlreadyLogged, AttendanceErrorKey.NotFound];

export const shouldSkipAttendanceError = (err: any, attendanceErrorKeys = DEFAULT_SKIPPABLE_ERRORS) => {
  const error = getCueError(err);
  if (attendanceErrorKeys.includes(error.keyword as AttendanceErrorKey)) {
    return true;
  }
  return false;
}
