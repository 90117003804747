import React from 'react';
import { BadgeAppointmentNote } from 'types';
import BadgeAppointment from 'components/appointments/badge-appointment';
import 'twin.macro';

const OpenAppointments = ({ openAppointments }: { openAppointments: BadgeAppointmentNote[] }) => {
  openAppointments.sort((first, second) => second.count - first.count);
  return openAppointments.length > 0 ? (
    <div tw="mb-8" data-testid="open-appointment-notes">
      <div tw="mb-2 text-base leading-none font-semibold text-lg">Attendance Verification</div>
      {openAppointments.map(appointment => (
        <BadgeAppointment key={appointment.clientID} appointment={appointment} />
      ))}
    </div>
  ) : null;
};

export default OpenAppointments;
