export enum SlackErrorCode {
  CONTACT_NOT_FOUND = 'CONTACT_NOT_FOUND',
  SLACK_CHANNEL_NOT_FOUND = 'SLACK_CHANNEL_NOT_FOUND',
  EMPTY_REQUESTER = 'EMPTY_REQUESTER',
  UNKNOWN_SLACK_ACCOUNT = 'UNKNOWN_SLACK_ACCOUNT',
  ENV_NOT_AUTHORIZED = 'ENV_NOT_AUTHORIZED',
  UNKNOWN_CONTACT = 'SLACK_GET_CONTACT_NOT_FOUND',
  MISSING_MOBILE_NUMBER = 'SLACK_GET_MISSING_MOBILE_NUMBER',
  SLACK_RATE_LIMITED = 'SLACK_RATE_LIMITED',
}

export const getSlackError = (code: string): string => {
  switch (code) {
    case SlackErrorCode.CONTACT_NOT_FOUND:
      return 'No contact found.';
    case SlackErrorCode.SLACK_CHANNEL_NOT_FOUND:
      return 'Pending creation.';
    case SlackErrorCode.EMPTY_REQUESTER:
      return 'Unknown requester.';
    case SlackErrorCode.UNKNOWN_SLACK_ACCOUNT:
      return 'Your Cue account does not have access to join this Slack workspace.';
    case SlackErrorCode.ENV_NOT_AUTHORIZED:
      return 'This environment is not permitted to make this request.';
    case SlackErrorCode.UNKNOWN_CONTACT:
      return 'This contact is unknown.';      
    case SlackErrorCode.MISSING_MOBILE_NUMBER:
      return 'This contact is missing a mobile number.';
    case SlackErrorCode.SLACK_RATE_LIMITED:
      return 'Unable to verify this channel at the moment. Please try again shortly.';
    default:
      return 'An error occurred while processing this request.';
  }
};
