import { Label, LoadingError, LoadingText, Select, Textarea } from '@expressable/ui-library';
import { Form, FormField } from 'components/Forms';
import DayJsDatePicker from 'components/Forms/DayjsPicker';
import { ControlledFormField } from 'components/Forms/FormFieldControlled';
import { RadioGroup } from 'components/Forms/RadioGroup';
import { AppointmentNoteFormErrorBoundary } from 'components/appointment-note-form-error-boundary';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { EvaluationContentfulProvider } from 'hooks/use-evaluation-contentful-provider';
import React, { useMemo } from 'react';
import { Event, ICompleteClientInformation, serverValidationErrors } from 'types';
import { BasicNoteFormProps } from '..';
import { dischargeFormContentSchema } from '../note-form-content/discharge/schema';
import { autoSize } from 'utils/auto-h-size';
import FormFieldRadioFreeText from 'components/Forms/RadioFreeText';
import { getGoalDetails } from 'utils/care-plans';
import { GoalStatusInput } from './blocks/goal-status-input';
import 'twin.macro';
import { SaveToolbar } from './blocks/discharge-save-toolbar';
import { useDischargeNote } from './use-discharge-note';

dayjs.extend(utc);
dayjs.extend(timezone);

export interface DischargeNoteFormProps extends BasicNoteFormProps {
  lastAppointmentNote: Event | undefined;
  lastSessionNote?: Event;
  canLoadPrevious: boolean;
  clientData: ICompleteClientInformation | undefined;
  remoteValidationErrors?: serverValidationErrors;
  setValidationErrors: React.Dispatch<React.SetStateAction<serverValidationErrors | undefined>>;
}

export const DischargeNoteForm = (props: DischargeNoteFormProps) => {
  const {
    form,
    dischargeReasonOptions,
    dischargeOn,
    isReasonGoalMet,
    mayResumeWatcher,
    save,
    serverErrors: originalServerErrors,
    upcomingAppointments,
    areUpcomingAppointmentsLoading,
    contactEmail,
    lastAutoSavedAt,
    createNoteMutation,
    editNoteMutation,
    activeGoals,
    activeAdditionalGoals,
    dismissPendingAppointmentMutation,
  } = useDischargeNote(props);

  const serverErrors = useMemo(() => {
    const againstRecommendationDetails = originalServerErrors?.againstRecommendationDetails;
    if (againstRecommendationDetails) {
      return {
        ...originalServerErrors,
        againstRecommendation: againstRecommendationDetails,
      };
    }

    return originalServerErrors;
  }, [originalServerErrors]);

  return (
    <AppointmentNoteFormErrorBoundary>
      <EvaluationContentfulProvider>
        <Form
          onSubmit={(data, e) => {
            if (!e?.target?.className?.includes('discharge-form')) return;
            save({ autoSaving: false, currentFormValues: data });
          }}
          data-testid="discharge-note-form"
          form={form}
          schema={dischargeFormContentSchema}
          className="space-y-6 mt-6 discharge-form"
          serverErrors={serverErrors}
          hideOptional
        >
          <div id="discharge-note-form" className="px-4 flex flex-col gap-6">
            <div className="flex flex-col gap-2">
              <Label>
                Discharge Date
                <span tw="text-gray-400 font-normal ml-3">
                  This will automatically default to 7 days after the note is locked
                </span>
              </Label>
              <span>{dayjs(dischargeOn).format('MM/DD/YYYY')}</span>
            </div>
            <ControlledFormField
              containerClass="flex flex-col w-1/2 justify-start gap-2"
              component={Select}
              spacing="tight"
              name="dischargeReason"
              label="Discharge Reason"
              options={dischargeReasonOptions ?? []}
              isSearchable
            />
            <RadioGroup
              containerClass="space-y-2"
              inputClass="w-full inline-flex space-x-6"
              name="mayResume"
              label="Did the client express interest or does the SLP recommend resuming services at a future date?"
              options={[
                { label: 'No', value: 'no' },
                { label: 'Yes', value: 'yes' },
              ]}
            />
            {mayResumeWatcher === 'yes' && (
              <ControlledFormField
                id="anticipatedReturn"
                name="anticipatedReturn"
                label="Anticipated Return"
                component={DayJsDatePicker}
                containerClass="flex flex-col w-1/4 justify-start gap-2"
              />
            )}

            <FormField
              containerClass="flex flex-col gap-2"
              className="w-full"
              spacing="tight"
              component={Textarea}
              rows={3}
              name="treatmentSummary"
              label="Please provide a summary of treatment and progress toward goals"
              onChange={autoSize}
            />
            {!isReasonGoalMet && (
              <FormFieldRadioFreeText
                when="yes"
                name="againstRecommendation"
                label="Is the client discharging from services against SLP recommendations?"
                detailsHint="Please share as much context/detail as possible"
              />
            )}

            {(activeGoals?.length > 0 || activeAdditionalGoals?.length > 0) && (
              <div tw="flex flex-col gap-6">
                <h2 className="font-semibold text-xl mt-4">Please Address the Following Goals for Discharge</h2>
                <hr className="border-gray-200" />
                <div tw="text-gray-400 text-sm flex flex-col gap-2">
                  <p>Each active goal needs to be set as one of the following:</p>
                  <ul tw="ml-4">
                    <li tw="list-disc">Met: Goal has been met.</li>
                    <li tw="list-disc">
                      Partially Met: Partial progress towards goals had been made, but discharge was unexpected. (Ex.
                      Client hospitalized, insurance barrier, Client requesting to cancel services, etc.) Select this
                      option if you hope to continue with the goal if client can resume services.
                    </li>
                    <li tw="list-disc">
                      Discontinued: Goals were not met. (Ex. Client unresponsive, Non-compliance with attendance policy,
                      Inconsistent participation in intervention)
                    </li>
                  </ul>
                </div>

                <div tw="flex flex-col gap-4">
                  {activeGoals?.length > 0 && (
                    <div tw="flex flex-col gap-4">
                      {activeGoals
                        .filter(goal => goal.goalType === 'ltg')
                        .map(currentLtg => {
                          const { goalDescription: currentLtgDescription } = getGoalDetails(currentLtg);

                          const currentShortTermGoals = activeGoals.filter(
                            goal => goal.goalType === 'stg' && currentLtg.ltgID === goal.ltgID,
                          );

                          return (
                            <div key={currentLtg.ltgID} tw="flex flex-col gap-4">
                              <div tw="flex flex-col gap-2">
                                <div>{currentLtgDescription}</div>
                                <GoalStatusInput goal={currentLtg} />
                              </div>

                              <div tw="flex flex-col gap-4">
                                {currentShortTermGoals.map(currentStg => {
                                  const { goalDescription: currentStgDescription } = getGoalDetails(currentStg);

                                  return (
                                    <div key={currentStg.stgID} tw="flex gap-4">
                                      <div tw="border-l-4 border-gray-DEFAULT"></div>
                                      <div tw="flex flex-col gap-2">
                                        <div>{currentStgDescription}</div>
                                        <GoalStatusInput goal={currentStg} />
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  )}

                  {activeAdditionalGoals?.length > 0 && (
                    <div tw="flex flex-col gap-4">
                      {activeAdditionalGoals.map(currentGoal => {
                        return (
                          <div key={currentGoal.goalID} tw="flex flex-col gap-4">
                            <div tw="flex flex-col gap-2">
                              <div>{currentGoal.detail.goalDescription}</div>
                              <GoalStatusInput goal={currentGoal} />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}

                  {serverErrors?.goalsStatuses && (
                    <div tabIndex={0} tw="text-red-500 text-xs italic">
                      {serverErrors?.goalsStatuses}
                    </div>
                  )}
                </div>
              </div>
            )}

            <h2 className="font-semibold text-xl mt-4">Cancel All Appointments</h2>
            <hr className="border-gray-200" />
            {upcomingAppointments ? (
              upcomingAppointments.length > 0 ? (
                <div>
                  When this note is locked, Cue will automatically cancel all following appointments for this client (
                  {upcomingAppointments.length}).
                </div>
              ) : (
                <div tw="text-gray-400">No upcoming appointments were found.</div>
              )
            ) : areUpcomingAppointmentsLoading ? (
              <LoadingText />
            ) : (
              <LoadingError />
            )}
            <h2 className="font-semibold text-xl mt-4">Discharge Review</h2>
            <hr className="border-gray-200" />
            <div tw="text-gray-400">
              A discharge review will be sent to the primary contact {contactEmail} 24 hours after locking this note
            </div>
          </div>
          <SaveToolbar
            loading={
              dismissPendingAppointmentMutation.isLoading || editNoteMutation.isLoading || createNoteMutation.isLoading
            }
            lastAutoSavedAt={lastAutoSavedAt}
          />
        </Form>
      </EvaluationContentfulProvider>
    </AppointmentNoteFormErrorBoundary>
  );
};
