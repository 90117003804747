import { FormYesNoDetails, YesNoDetails } from 'domain/shared/types';
import {
  DischargeNoteFormData,
} from 'pages/client/components/client-notes/note-form-content/discharge/schema';
import { convertGoalStatusesToFormData } from './goals';
import { DischargeNoteDTO } from './types';
import { calculateDischargeOn } from './helpers';
import { convertBooleanToYesOrNo, convertStringToSelectOption } from '../mapHelpers';

export function mapDischargeNoteDTOToFormData({
  note: { content },
  dischargeOn,
}: DischargeNoteDTO): DischargeNoteFormData {
  const againstRecommendation = transformYesOrNoDetailToYesOrNoFormData(
    typeof content.againstRecommendation === 'boolean'
      ? { relevant: content.againstRecommendation, details: content.againstRecommendationDetails ?? '' }
      : null,
  );

  const result: DischargeNoteFormData = {
    // When editing, we always want to update the date
    dischargeOn: calculateDischargeOn(),

    dischargeReason: convertStringToSelectOption(content.dischargeReason),
    mayResume: convertBooleanToYesOrNo(content.mayResume),
    anticipatedReturn: content.anticipatedReturn ? new Date(content.anticipatedReturn) : null,
    againstRecommendation,
    treatmentSummary: content.treatmentSummary,
    dischargeMessage: content.dischargeMessage,
    goalsStatuses: convertGoalStatusesToFormData(content.goalsStatuses),
    additionalGoalsStatuses: convertGoalStatusesToFormData(content.additionalGoalsStatuses),
  };

  return result;
}

function transformYesOrNoDetailToYesOrNoFormData(value?: YesNoDetails | null): FormYesNoDetails {
  if (!value) return { relevant: '', details: '' };
  return {
    relevant: convertBooleanToYesOrNo(value.relevant),
    details: value.details,
  };
}
