import { useEffect, useMemo, useState } from 'react';
import Table from 'components/Table';
import { Card, Container, FormGroup, Label, Link, Loader, Select, Input } from '@expressable/ui-library';
import { faExclamationTriangle, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SelectOption } from 'types';
import dayjs from 'dayjs';
import cx from 'classnames';
import { usePagination } from 'hooks/use-pagination';
import { EMPTY_CLIENT_ID, useScheduleConflicts } from 'hooks/use-therapists';
import { ScheduleConflict } from 'domain/therapist/therapistTypes';
import { TotalConflicts } from 'pages/admin/components/schedule-conflicts';
import { useFlags } from 'launchdarkly-react-client-sdk';
import 'twin.macro';

export interface ScheduleConflictsProps {
  therapistId?: string;
}

const ScheduleConflicts = ({ therapistId }: ScheduleConflictsProps) => {
  const [selectedClient, setSelectedClient] = useState<SelectOption>(DEFAULT_CLIENT_FILTER);
  const [selectedDate, setSelectedDate] = useState<SelectOption>(DEFAULT_DATE_FILTER);
  const [onlySessions, setOnlySessions] = useState(false);

  const {
    data: scheduleConflicts = [],
    isFetching: isLoading,
    isError,
    refetch,
  } = useScheduleConflicts(therapistId!, {
    includeTypes: onlySessions
      ? ['speech-therapy-session', 'evaluation', 'free-consultation', 'initial-consult']
      : undefined,
  });
  const { cueEnableScheduleConflicts } = useFlags();

  const resetFilters = () => {
    setSelectedClient(DEFAULT_CLIENT_FILTER);
    setSelectedDate(DEFAULT_DATE_FILTER);
    setOnlySessions(false);
  };

  useEffect(() => {
    // Force reload anytime "Hide conflicts" change
    refetch();
  }, [onlySessions]);

  const filteredResults = useMemo(() => {
    return [...scheduleConflicts]?.filter(
      conflict =>
        (!selectedDate.value ||
          conflict.details?.some(
            detail =>
              dayjs(detail.appointmentOn).startOf('day').valueOf() ===
              dayjs(selectedDate.value).startOf('day').valueOf(),
          )) &&
        conflict.details?.some(detail => detail.clientID === (selectedClient.value || detail.clientID)),
    );
  }, [scheduleConflicts, selectedClient, selectedDate]);

  const paginatedResult = usePagination(filteredResults, { perPage: 10 });

  const clientsOptions = [DEFAULT_CLIENT_FILTER, ...getUniqueClients(scheduleConflicts)];
  const datesOptions = [DEFAULT_DATE_FILTER, ...getUniqueDates(scheduleConflicts, selectedClient)];
  const hasAnyFilter = !!selectedClient.value || !!selectedDate.value || onlySessions;

  if (!cueEnableScheduleConflicts) {
    return <div>This feature is not allowed for this user.</div>;
  }

  if (isError) {
    return (
      <Container data-testid="schedule-conflicts-error" className="relative pl-0" size="large">
        <div data-testid="schedule-conflicts-error-title" className="flex justify-between items-center flex-wrap">
          <h2 className="text-2xl font-semibold mb-3">Schedule Conflicts</h2>
        </div>
        <Card className="mb-4 px-8 mt-8">
          <FormGroup className="w-full sm:w-1/2 md:w-1/3">
            <Label>
              <FontAwesomeIcon icon={faExclamationTriangle} className="text-red-500 mr-2" />
              <span className="text-red-500">Schedule conflicts are not available at this time.</span>
            </Label>
          </FormGroup>
        </Card>
      </Container>
    );
  }

  return (
    <div>
      <div className="md:pr-5" data-testid="schedule-conflicts">
        <div className="flex justify-between items-center flex-wrap">
          <h2 className="text-2xl font-semibold mb-3">Schedule Conflicts</h2>
        </div>
        <div className="mt-2">
          <div className="mb-4 sm:mb-0">
            <div className="flex flex-col lg:flex-row mb-4">
              <div className="flex items-center mt-4 font-normal">
                <Select
                  className="w-full sm:w-96"
                  id="selectedClient"
                  placeholder=""
                  spacing="tight"
                  value={selectedClient}
                  options={clientsOptions}
                  onChange={(selectOption: SelectOption) => {
                    setSelectedClient(selectOption);
                  }}
                />
              </div>
              <div className="flex items-center lg:ml-2 mt-4 font-normal">
                <Select
                  className="w-full sm:w-96"
                  id="selectedDate"
                  placeholder=""
                  spacing="tight"
                  value={selectedDate}
                  options={datesOptions}
                  onChange={(selectOption: SelectOption) => {
                    setSelectedDate(selectOption);
                  }}
                />
              </div>
              <div className="flex gap-2 font-normal items-center lg:ml-3 mt-3">
                <Input
                  data-testid="only-sessions"
                  name="only-sessions"
                  id="only-sessions"
                  type="checkbox"
                  spacing="tight"
                  checked={onlySessions}
                  onChange={e => setOnlySessions(e.currentTarget.checked)}
                  disabled={isLoading}
                  tw="mb-1"
                />
                <Label htmlFor="only-sessions" className="font-semibold text-sm mb-0">
                  Hide conflicts due to admin blocks/PTO
                </Label>
              </div>

              <span
                onClick={() => resetFilters()}
                className={cx('text-sm lg:ml-6 mt-4 lg:mt-6 text-indigo-700 font-normal', {
                  'opacity-100': hasAnyFilter,
                  'opacity-50': !hasAnyFilter,
                  'cursor-pointer': hasAnyFilter,
                })}
              >
                Clear Filters
              </span>
            </div>
          </div>

          {isLoading ? (
            <div data-testid="schedule-conflicts-loader" className="flex-1 text-center py-20">
              <Loader type="ring" />
            </div>
          ) : (
            paginatedResult?.items?.length ? (
              <div className="mt-10">
                {paginatedResult.items.map((item, index) => (
                  <Card key={`${item.appointmentOn}-${index}`} className="mb-8 pb-8">
                    <div className="flex ml-2 mb-4 items-center text-xs sm:text-sm md:text-left">
                      <span className="font-semibold mr-4">
                        {dayjs(item.appointmentOn).format('dddd, MM/DD/YYYY')}
                        &nbsp;at {dayjs(item.appointmentOn).format('h:mm A')}
                      </span>
                      <TotalConflicts value={item.totalConflicts} />
                    </div>
                    <Table className="text-xs sm:text-sm md:text-left">
                      <Table.Header>
                        <Table.Row>
                          <Table.Cell className="w-1/2 sm:w-1/3">Appointment/Event</Table.Cell>
                          <Table.Cell className="hidden sm:table-cell">Appointment Created</Table.Cell>
                          <Table.Cell className="text-right sm:text-left">Client</Table.Cell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {item.details.map((detail, index) => (
                          <Table.Row key={`${detail.appointmentCreatedAt}-${index}`}>
                            <Table.Cell>
                              {detail.appointmentDetail} at {dayjs(detail.appointmentOn).format('h:mm A')}
                            </Table.Cell>
                            <Table.Cell className="hidden sm:table-cell">
                              {dayjs(detail.appointmentCreatedAt).format('MM/DD/YYYY')}
                            </Table.Cell>
                            <Table.Cell>
                              {detail.clientID === EMPTY_CLIENT_ID ? (
                                <span className="font-semibold text-right sm:text-left">{detail.clientName}</span>
                              ) : (
                                <Link
                                  target="_blank"
                                  to={{ pathname: `/clients/${detail.clientID}` }}
                                  className="text-indigo-700 font-semibold text-right sm:text-left"
                                >
                                  {detail.clientName}
                                </Link>
                              )}
                            </Table.Cell>
                          </Table.Row>
                        ))}
                      </Table.Body>
                    </Table>
                  </Card>
                ))}
                {paginatedResult.totalPages > 1 && (
                  <div className="font-medium flex justify-center sm:justify-end mb-20">
                    <span className="mr-2">{`Showing ${paginatedResult.pageStartIdx + 1} to ${
                      paginatedResult.pageEndIdx
                    } of ${paginatedResult.totalItems} results`}</span>
                    {paginatedResult.page !== 0 && (
                      <span data-testid="previous-page" onClick={paginatedResult.prev}>
                        <FontAwesomeIcon
                          className="text-indigo-700 ml-2 mr-2 cursor-pointer flex mt-2 text-xs"
                          icon={faChevronLeft}
                        />
                      </span>
                    )}
                    {paginatedResult.page !== paginatedResult.totalPages - 1 && (
                      <span data-testid="next-page" onClick={paginatedResult.next}>
                        <FontAwesomeIcon
                          className="text-indigo-700 ml-2 mr-2 cursor-pointer flex mt-2 text-xs"
                          icon={faChevronRight}
                        />
                      </span>
                    )}
                  </div>
                )}
              </div>
            ) : (
              <span className="ml-2">There are no schedule conflicts to show.</span>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default ScheduleConflicts;

const DEFAULT_CLIENT_FILTER: SelectOption = {
  value: '',
  label: 'All Clients',
};

const DEFAULT_DATE_FILTER: SelectOption = {
  value: '',
  label: 'All Dates',
};

const getUniqueClients = (scheduleConflicts: ScheduleConflict[]): SelectOption[] => {
  const availableClientsMap = [
    ...new Map(
      scheduleConflicts
        .map(item => item.details)
        .flat()
        .map(detail => [detail.clientID, detail.clientName]),
    ).entries(),
  ];

  const availableClients = availableClientsMap.map(([clientID, clientName]) => {
    return {
      label: clientName,
      value: clientID,
    };
  });

  availableClients.sort((a, b) => a.label.localeCompare(b.label));

  return availableClients;
};

const getUniqueDates = (scheduleConflicts: ScheduleConflict[], selectedClient: SelectOption): SelectOption[] => {
  const availableDates = [
    ...new Set(
      scheduleConflicts
        .map(item => item.details)
        .flat()
        .filter(item => item.clientID === (selectedClient.value || item.clientID))
        .map(item => {
          return dayjs(item.appointmentOn).startOf('day').toISOString();
        }),
    ).values(),
  ];

  availableDates.sort((a, b) => dayjs(a).diff(dayjs(b)));

  return availableDates.map(date => ({
    label: dayjs(date).format('MM/DD/YYYY'),
    value: date,
  }));
};
