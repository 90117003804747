import { TimeSlot } from 'components/therapistMatcher/data';
import { getMinDateForSecondRecurring } from 'domain/therapist-matcher/scheduling';
import OptionRadio from 'pages/new-client-create/option-radio';
import React, { useCallback, useMemo } from 'react';
import 'twin.macro';
import NotSchedulingReason from './not-scheduling-reason';
import SameTherapistSession from './same-therapist-session';

export enum SecondRecurringOption {
  SameTherapist = 'SameTherapist',
  NotSchedulingSession = 'NotSchedulingSession',
}

export interface SecondRecurringSessionInfo {
  recurringOption: SecondRecurringOption | null;
  timestamp?: TimeSlot | null;
  notSchedulingReason?: string | null;
  notSchedulingDescription?: string | null;
}

interface SecondRecurringSessionProps {
  value: SecondRecurringSessionInfo | null;
  onChange: (value: SecondRecurringSessionInfo) => void;
  onSkip?: () => void;
  firstRecurringSession: TimeSlot;

  onValidationStatus?: ({ valid }: { valid: boolean }) => void;
  submitted?: boolean;
}

interface InternalState {
  recurringOption: SecondRecurringOption | null;
  sameTherapistTime?: TimeSlot | null;
  notSchedulingReason?: string | null;
  notSchedulingDescription?: string | null;
}

const SecondRecurringSession = ({
  value,
  onChange,
  onSkip,
  firstRecurringSession,
  submitted,
  onValidationStatus,
}: SecondRecurringSessionProps) => {
  const defaultInternalState = {
    recurringOption: null,
    sameTherapistTime: null,
    notSchedulingReason: null,
    notSchedulingDescription: null,
  };

  const { recurringOption, sameTherapistTime, notSchedulingReason, notSchedulingDescription } =
    useMemo((): InternalState => {
      if (!value || !value.recurringOption) {
        return defaultInternalState;
      }

      switch (value.recurringOption) {
        case SecondRecurringOption.SameTherapist:
          return {
            recurringOption: SecondRecurringOption.SameTherapist,
            sameTherapistTime: value.timestamp,
            notSchedulingReason: value.notSchedulingReason,
            notSchedulingDescription: value.notSchedulingDescription,
          };

        case SecondRecurringOption.NotSchedulingSession:
          return {
            recurringOption: SecondRecurringOption.NotSchedulingSession,
            sameTherapistTime: null,
            notSchedulingReason: value.notSchedulingReason,
            notSchedulingDescription: value.notSchedulingDescription,
          };
      }
    }, [value]);

  const setRecurringOption = useCallback(
    (value: SecondRecurringOption) => {
      onChange?.({
        recurringOption: value,
        timestamp: null,
      });

      onValidationStatus?.({ valid: value !== SecondRecurringOption.NotSchedulingSession });
    },
    [onChange, onValidationStatus],
  );

  return (
    <div tw="flex flex-col gap-4">
      <div className="flex flex-col gap-4 w-max">
        <OptionRadio
          option={SecondRecurringOption.SameTherapist}
          selectedOption={recurringOption}
          onChange={() => setRecurringOption(SecondRecurringOption.SameTherapist)}
        >
          Schedule a second recurring session with {firstRecurringSession.therapist.therapistName} SLP
        </OptionRadio>
        <OptionRadio
          option={SecondRecurringOption.NotSchedulingSession}
          selectedOption={recurringOption}
          onChange={() => setRecurringOption(SecondRecurringOption.NotSchedulingSession)}
        >
          Client is not scheduling a second recurring session
        </OptionRadio>
      </div>
      {recurringOption === SecondRecurringOption.SameTherapist && (
        <SameTherapistSession
          value={sameTherapistTime}
          onChange={newTimestamp =>
            onChange?.({
              ...(value ?? defaultInternalState),
              timestamp: newTimestamp,
            })
          }
          therapist={firstRecurringSession.therapist}
          minDateAllowed={getMinDateForSecondRecurring(firstRecurringSession.timestamp)}
          dateCount={6}
          allowStartDateChange={false}
          onSkip={onSkip}
        />
      )}

      {recurringOption === SecondRecurringOption.NotSchedulingSession && (
        <NotSchedulingReason
          value={{
            notSchedulingReason,
            notSchedulingDescription,
          }}
          onChange={newReason =>
            onChange?.({
              ...(value ?? defaultInternalState),
              notSchedulingReason: newReason.notSchedulingReason,
              notSchedulingDescription: newReason.notSchedulingDescription,
            })
          }
          submitted={submitted}
          onValidationStatus={onValidationStatus}
        />
      )}
    </div>
  );
};

export default SecondRecurringSession;
