import React from 'react';
import { NavLink } from 'react-router-dom';
import tw from 'twin.macro';

interface NavbarItemProps {
  label: string;
  to: string;
  onClick: React.MouseEventHandler<HTMLAnchorElement>;
}

const NavbarItem = ({ label, to, onClick }: NavbarItemProps) => {
  return (
    <div tw="md:ml-8 flex">
      <NavLink
        onClick={onClick}
        exact
        to={to}
        tw="mb-1 px-3 py-2 rounded-md text-sm font-medium text-indigo-200 hover:text-white hover:bg-indigo-600"
        activeStyle={tw`bg-indigo-800 text-white focus:outline-none focus:text-white`}
      >
        {label}
      </NavLink>
    </div>
  );
};

export default NavbarItem;
