import { Button, CloseButton, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader } from '@expressable/ui-library';
import 'twin.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNotesMedical } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import { Form } from 'components/Forms';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ControlledFormField } from 'components/Forms/FormFieldControlled';
import DayJsDatePicker from 'components/Forms/DayjsPicker';
import { useMemo } from 'react';
import { useSetEndDate } from 'hooks/use-care-plan-end-date';
import { useGetPlanOfCareStartDate } from 'hooks/use-care-plan-start-date';
import dayjs from 'dayjs';

interface PlanOfCareEndDateModalProps {
  isOpen: boolean;
  onClose: () => void;
  clientId: string;
  date?: string | Date;
  shouldLogEvent: boolean;
}

const schema = yup.object({
  endDate: yup.date().required('Start Date is required'),
});

export function PlanOfCareEndDateModal(props: PlanOfCareEndDateModalProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isOpen, onClose, clientId, shouldLogEvent, date } = props;
  const defaultValues = useMemo(() => ({ endDate: date }), [date]);
  const { mutateAsync: setEndDate, isLoading } = useSetEndDate();

  const form = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });
  
  const { data: startDate } = useGetPlanOfCareStartDate({ clientId });
  const watchEndDate = form.watch('endDate');

  const isDateValid = useMemo(() => {
    if (startDate?.date) {
      const startDateValue = dayjs(startDate.date, 'MM/DD/YYYY');
      const endDateValue = dayjs(watchEndDate, 'MM/DD/YYYY');
      if (startDateValue.isValid() && endDateValue.isValid()) {
        if (startDateValue.isSame(endDateValue)) {
          return false;
        }
        if (endDateValue.isBefore(startDateValue)) {
          return false;
        }
      } else if (startDateValue.isValid()) {
        return false;
      }
    }
    return true;
  }, [startDate, watchEndDate]);
  
  const onSubmit = async (data: any) => {
    if (!isDateValid) {
      form.setError('endDate', {
        type: 'manual',
        message: 'End date must be after start date',
      });
      return;
    }
    await setEndDate({ clientId, details: data.endDate, shouldLogEvent });
    onClose();
  };

  return (
    <Modal portal isOpen={isOpen}>
      <ModalContent>
        <Form form={form as any} onSubmit={onSubmit} schema={schema}>
          <ModalHeader>
            <CloseButton onClick={onClose} />
          </ModalHeader>
          <ModalBody>
            <div>
              <div tw="flex items-center justify-center w-12 h-12 mx-auto bg-indigo-100 rounded-full">
                <FontAwesomeIcon tw="text-2xl text-indigo-700" icon={faNotesMedical} data-testid="icon" />
              </div>
              <div tw="mt-3 sm:mt-5">
                <h3 tw="text-lg text-center font-medium text-gray-900 leading-6" id="modal-headline">
                  End Date
                </h3>
                <div tw="mt-10">
                  <ControlledFormField
                    containerClass="w-full"
                    id="endDate"
                    name="endDate"
                    label="Date"
                    component={DayJsDatePicker}
                  />
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div tw="mt-5 sm:mt-6 sm:grid sm:grid-cols-1 sm:gap-3 sm:grid-flow-row-dense">
              <span tw="flex w-full rounded-md shadow-sm">
                <Button
                  type="submit"
                  data-testid="applicable-conditions-submit"
                  variant="primary"
                  loading={isLoading}
                  disabled={isLoading}
                  tw="inline-flex justify-center w-full px-4 py-2 text-base font-medium leading-6 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                >
                  Save
                </Button>
              </span>
            </div>
          </ModalFooter>
        </Form>
      </ModalContent>
    </Modal>
  );
}
