import { FormGroup, Label } from '@expressable/ui-library';
import TherapistsTimeSelector from 'components/therapistMatcher/TherapistTimeGrid';
import dayjs, { Dayjs } from 'dayjs';
import moment from 'moment';
import { useState } from 'react';
import { SingleDatePicker } from 'react-dates';
import { Therapist } from 'types';
import 'twin.macro';
import { TimeSlot } from 'components/therapistMatcher/data';

 type ClientMatchingTherapistTimeSelectorProps = {
  therapists: Therapist[];
  value?: TimeSlot | null;
  onChange?: (value: TimeSlot | null) => void;
  type?: 'session' | 'evaluation';
}

const ClientMatchingTherapistTimeSelector = ({
  therapists,
  value,
  onChange,
  type = 'session',
}: ClientMatchingTherapistTimeSelectorProps) => {
  const [startDateFocused, setStartDateFocused] = useState(false);
  const [startDate, setStartDate] = useState<Dayjs | null>(dayjs());
  
  return (
    <div tw="mb-8 flex flex-col gap-4">
      <div>
        <FormGroup tw="flex-grow md:flex-grow-0">
          <Label htmlFor="startDate" tw="font-semibold text-sm mb-2">
            Start Date
          </Label>
          <div tw="w-40">
            <SingleDatePicker
              noBorder
              block
              openDirection="down"
              numberOfMonths={1}
              small
              date={startDate ? moment(startDate.toISOString()) : null}
              onDateChange={dateValue => {
                if (dateValue) {
                  setStartDate(dayjs(dateValue.toISOString()));
                }
              }}
              hideKeyboardShortcutsPanel
              focused={startDateFocused}
              onFocusChange={({ focused }) => setStartDateFocused(focused)}
              id="startDate"
              isOutsideRange={day =>day.isBefore(moment().startOf('day'))}
            />
          </div>
        </FormGroup>
      </div>
      {startDate && (
        <div>
          <TherapistsTimeSelector
            type={type}
            duration={30}
            therapists={therapists}
            dateFilter={startDate}
            value={value}
            onChange={onChange}
          />
        </div>
      )}
    </div>
  );
};

export default ClientMatchingTherapistTimeSelector;
