import React from 'react';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { EventProps } from 'domain/admin/types';
import { removePrefixFromID } from 'utils/helpers';
import 'twin.macro';

const SessionReviewSent = (event: EventProps) => {
  const firehoseEvent = event?.event;
  const isLastEvent = event?.isLastEvent;
  const contactId = removePrefixFromID(firehoseEvent?.primaryContactID);
  const clientId = removePrefixFromID(firehoseEvent?.clientID);

  return (
    <div data-testid="firehose-session-review-sent" tw="relative flex mt-7 px-2">
      {!isLastEvent && <span tw="absolute -z-1 top-7 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>}
      <div tw="flex items-baseline">
        <div tw="flex items-center bg-gray-200 rounded-full h-6 w-6">
          <FontAwesomeIcon icon={faStar} tw="mx-[5px] h-3 text-indigo-700" />
        </div>
        <div tw="pl-4 text-sm">
          <div tw="lg:flex font-semibold pb-1 text-indigo-700">
            <Link to={{ pathname: `/therapists/${firehoseEvent?.createdBy}` }}>
              {firehoseEvent?.createdByFirstName} {firehoseEvent?.createdByLastName}&nbsp;
            </Link>
            {contactId !== clientId ? (
              <span tw="text-gray-500">
                {`sent a session review to`}
                &nbsp;
                <Link tw="text-indigo-700" to={{ pathname: `/contacts/${contactId}` }}>
                  {firehoseEvent.primaryContactFirstName ?? firehoseEvent.contactFirstName}{' '}
                  {firehoseEvent.primaryContactLastName ?? firehoseEvent.contactLastName}&nbsp;
                </Link>
                for
              </span>
            ) : (
              <span tw="text-gray-500">{`sent a session review to`}</span>
            )}
            &nbsp;
            <Link
              tw="text-indigo-700"
              to={{ pathname: `/clients/${removePrefixFromID(firehoseEvent.clientID)}` }}
            >
              {firehoseEvent?.clientFirstName} {firehoseEvent?.clientLastName}&nbsp;
            </Link>
            <span tw="text-gray-500">{`at ${dayjs(firehoseEvent?.createdAt as string).format('h:mm A')}, ${dayjs(
              firehoseEvent?.createdAt as string,
            ).format('MM/DD/YYYY')} `}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SessionReviewSent;
