import React from 'react';
import { Card } from '@expressable/ui-library';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ErrorBoundary } from '@sentry/react';

const Fallback = () => (
  <Card className="mb-4">
    <FontAwesomeIcon icon={faExclamationTriangle} className="text-red-500 mr-2" />
    There was an error while trying to load this view. Please try again or contact support.
  </Card>
);

export default function ClientCasesErrorBoundary(props: { children: React.ReactNode }) {
  return <ErrorBoundary key="client-cases-view" fallback={Fallback}>{props.children}</ErrorBoundary>
}
