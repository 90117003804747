import { FC } from 'react';
import { ClientMatchingState } from '../client-matching-ui';
import ReadOnlyField from 'pages/new-client-create/ReadOnlyField';
import { describeRecurringSessionTime, describeSessionTime } from 'pages/new-client-create/ScheduleSummary';
import { useDisplayTimezone } from 'hooks/common/useDisplayTimezone';

import { Button, Card } from '@expressable/ui-library';
import { AppointmentType, SessionType } from 'domain/appointment/constants';
import { useWatch } from 'react-hook-form';
import useConflicts from 'hooks/use-conflicts';
import Conflicts from 'components/appointment-modals/conflicts';
import { RECURRING_FOREVER } from 'domain/recurring-appointments/constants';

type Props = {
  timeSlot: ClientMatchingState;
  isLoading: boolean;
  contactTimezone?: string;
};

export const ClientMatchingSummary: FC<Props> = ({ timeSlot, isLoading, contactTimezone }) => {
  const { displayTimezone } = useDisplayTimezone();
  const sessionType = useWatch({ name: 'sessionType' });
  const appointmentType = useWatch({ name: 'appointmentType' });
  const isRecurring = sessionType === SessionType.Recurring;

  const { isLoading: firstRecurringSessionIsLoading, conflicts: firstRecurringSessionConflicts} = useConflicts({
    calendarID: timeSlot?.slot.timeSlot.therapist.acuityCalendarID,
    dateTime: timeSlot?.slot.timeSlot.timestamp?.toISOString(),
    duration: timeSlot?.slot.timeSlot.duration,
    type: appointmentType === AppointmentType.Therapy ? 'session' : 'evaluation',
    recurringTimes: appointmentType === AppointmentType.Therapy && isRecurring ? RECURRING_FOREVER : 1,
    isAvailableTimes: !timeSlot?.slot.timeSlot.override,
  });

  return (
    <Card id="client-matching-summary" className="flex flex-col gap-y-4 mt-8">
      <div tw="font-semibold text-lg">Schedule Summary</div>

      {appointmentType === AppointmentType.Therapy && (
        <ReadOnlyField label={`Scheduled ${isRecurring ? 'Recurring' : 'Single'} Session`}>
          {isRecurring
            ? describeRecurringSessionTime(timeSlot?.slot.timeSlot, displayTimezone, 'recurring session', null)
            : describeSessionTime(timeSlot?.slot.timeSlot, displayTimezone, 'session')}
        </ReadOnlyField>
      )}

      {appointmentType === AppointmentType.Evaluation && (
        <ReadOnlyField label="Initial Evaluation">
          {describeSessionTime(timeSlot?.slot.timeSlot, displayTimezone, 'evaluation')}
        </ReadOnlyField>
      )}

      {timeSlot?.slot.timeSlot && !firstRecurringSessionIsLoading && firstRecurringSessionConflicts.length > 0 && (
        <Conflicts
          conflicts={firstRecurringSessionConflicts}
          availableTimes={!timeSlot.slot.timeSlot.override}
          displayTimezone={displayTimezone.value}
        />
      )}

      <Button disabled={isLoading} loading={isLoading} variant="primary" className="sm:w-1/6 lg:w-[10%]" type="submit">
        Schedule
      </Button>
    </Card>
  );
};
