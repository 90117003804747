import React from 'react';
import relationshipToClientOptions from 'utils/relationship-to-client-options';
import planTypeOptions from 'utils/plan-type-options';
import { SelectOption } from 'types';
import { ReadonlyInsuranceGenderEnum } from 'domain/billing-information/constants';
import { ReadonlyInsuranceInterface } from 'domain/billing-information/types';
import { capitalizeFirst } from 'utils/helpers';
import 'twin.macro';

export type ReadonlyInsuranceProps = {
  insurance: ReadonlyInsuranceInterface;
  type?: string;
};

const getRelationshipToClientLabel = (relationshipToClientValue: string) => {
  const relationshipToClient = relationshipToClientOptions.find(
    ({ value }) => value === relationshipToClientValue,
  ) as SelectOption;
  return relationshipToClient.label;
};

const getPayerTypeLabel = (payerTypeValue: string) => {
  const payerType = planTypeOptions.find(({ value }) => value === payerTypeValue) as SelectOption;
  return payerType.label;
};

const ReadonlyInsurance = ({ insurance, type = 'primary' }: ReadonlyInsuranceProps) => {
  return (
    <div data-testid="billing-info-read-only-insurance">
      {insurance.insuredFirstName && (
        <>
          <div tw="text-lg font-semibold mb-7 mt-8">{`${capitalizeFirst(type)} Insurance Information `}</div>
          <div>
            {insurance?.priorAuthorizationNumber && (
              <div tw="mt-8">
                <p tw="font-semibold text-sm">Prior Authorization Number</p>
                <p
                  data-testid="billing-info-read-only-insurance-priorAuthorizationNumber"
                  tw="font-normal text-sm pt-2"
                >
                  {insurance.priorAuthorizationNumber}
                </p>
              </div>
            )}
            {insurance?.insuredRelationshipToClient && (
              <div tw="mt-8">
                <p tw="font-semibold text-sm">Client&apos;s Relationship to Insured</p>
                <p
                  data-testid="billing-info-read-only-insurance-insuredRelationshipToClient"
                  tw="font-normal text-sm pt-2"
                >
                  {getRelationshipToClientLabel(insurance?.insuredRelationshipToClient)}
                </p>
              </div>
            )}
            <div tw="flex flex-wrap gap-4">
              {insurance?.insuredFirstName && (
                <div tw="mt-8">
                  <p tw="font-semibold text-sm">Insured&apos;s First Name</p>
                  <p data-testid="billing-info-read-only-insurance-insuredFirstName" tw="font-normal text-sm pt-2">
                    {insurance.insuredFirstName}
                  </p>
                </div>
              )}
              {insurance?.insuredMiddleName && (
                <div tw="mt-8">
                  <p tw="font-semibold text-sm">Insured&apos;s Middle Name</p>
                  <p data-testid="billing-info-read-only-insurance-insuredMiddleName" tw="font-normal text-sm pt-2">
                    {insurance.insuredMiddleName}
                  </p>
                </div>
              )}
              {insurance?.insuredLastName && (
                <div tw="mt-8">
                  <p tw="font-semibold text-sm">Insured&apos;s Last Name</p>
                  <p data-testid="billing-info-read-only-insurance-insuredLastName" tw="font-normal text-sm pt-2">
                    {insurance.insuredLastName}
                  </p>
                </div>
              )}
            </div>
            {insurance?.insuredDob && (
              <div tw="mt-8">
                <p tw="font-semibold text-sm">Insured&apos;s Date of Birth</p>
                <p data-testid="billing-info-read-only-insurance-insuredDob" tw="font-normal text-sm pt-2">
                  {insurance.insuredDob.substring(0, 10)}
                </p>
              </div>
            )}
            {insurance?.insuredGender && (
              <div tw="mt-8">
                <p tw="font-semibold text-sm">Insured&apos;s Gender</p>
                <p data-testid="billing-info-read-only-insurance-insuredGender" tw="font-normal text-sm pt-2">
                  {`${insurance?.insuredGender === ReadonlyInsuranceGenderEnum.male ? 'Male' : 'Female'}`}
                </p>
              </div>
            )}
            {insurance?.insuredAddress && (
              <div>
                {insurance?.insuredAddress?.address1 && (
                  <div tw="mt-8">
                    <p tw="font-semibold text-sm">Insured&apos;s Address</p>
                    <p
                      data-testid="billing-info-read-only-insurance-insuredAddress-address1"
                      tw="font-normal text-sm pt-2"
                    >
                      {insurance.insuredAddress.address1}
                    </p>
                  </div>
                )}
                {insurance?.insuredAddress?.address2 && (
                  <div tw="mt-8">
                    <p tw="font-semibold text-sm">Apt/Suite/Other</p>
                    <p
                      data-testid="billing-info-read-only-insurance-insuredAddress-address2"
                      tw="font-normal text-sm pt-2"
                    >
                      {insurance.insuredAddress.address2}
                    </p>
                  </div>
                )}
                <div tw="flex flex-wrap gap-8">
                  {insurance?.insuredAddress?.city && (
                    <div tw="mt-8">
                      <p tw="font-semibold text-sm">Insured&apos;s City</p>
                      <p
                        data-testid="billing-info-read-only-insurance-insuredAddress-city"
                        tw="font-normal text-sm pt-2"
                      >
                        {insurance.insuredAddress.city}
                      </p>
                    </div>
                  )}
                  {insurance?.insuredAddress?.state && (
                    <div tw="mt-8">
                      <p tw="font-semibold text-sm">Insured&apos;s State</p>
                      <p
                        data-testid="billing-info-read-only-insurance-insuredAddress-state"
                        tw="font-normal text-sm pt-2"
                      >
                        {insurance.insuredAddress.state}
                      </p>
                    </div>
                  )}
                  {insurance?.insuredAddress?.zipCode && (
                    <div tw="mt-8">
                      <p tw="font-semibold text-sm">Insured&apos;s Zip Code</p>
                      <p
                        data-testid="billing-info-read-only-insurance-insuredAddress-zipCode"
                        tw="font-normal text-sm pt-2"
                      >
                        {insurance.insuredAddress.zipCode}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            )}
            {insurance?.insurancePayerName && (
              <div tw="mt-8">
                <p tw="font-semibold text-sm">Insurance Payer for Claims</p>
                <p data-testid="billing-info-read-only-insurance-insurancePayerName" tw="font-normal text-sm pt-2">
                  {insurance.insurancePayerName}
                </p>
              </div>
            )}
            {insurance?.insurancePlanEligibilityName && (
              <div tw="mt-8">
                <p tw="font-semibold text-sm">Insurance Plan</p>
                <p data-testid="billing-info-read-only-insurance-insurancePlanEligibilityName" tw="font-normal text-sm pt-2">
                  {insurance.insurancePlanEligibilityName}
                </p>
              </div>
            )}
            {insurance?.insuredPayerType && (
              <div tw="mt-8">
                <p tw="font-semibold text-sm">Payer Type</p>
                <p data-testid="billing-info-read-only-insurance-insuredPayerType" tw="font-normal text-sm pt-2">
                  {getPayerTypeLabel(insurance.insuredPayerType)}
                </p>
              </div>
            )}
            {insurance?.insuredMemberID && (
              <div tw="mt-8">
                <p tw="font-semibold text-sm">Member ID</p>
                <p data-testid="billing-info-read-only-insurance-insuredMemberID" tw="font-normal text-sm pt-2">
                  {insurance.insuredMemberID}
                </p>
              </div>
            )}
            {insurance?.insuredGroupID && (
              <div tw="mt-8">
                <p tw="font-semibold text-sm">Group ID</p>
                <p data-testid="billing-info-read-only-insurance-insuredGroupID" tw="font-normal text-sm pt-2">
                  {insurance.insuredGroupID}
                </p>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ReadonlyInsurance;
