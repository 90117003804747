import dayjs, { Dayjs } from 'dayjs';
import moment from 'moment';
import { useState } from 'react';
import { SingleDatePicker, SingleDatePickerShape } from 'react-dates';
import 'twin.macro';

type Props = Omit<SingleDatePickerShape, 'date' | 'focused' | 'onDateChange' | 'onFocusChange' | 'renderMonthText'> & {
  allowPastDates?: boolean;
  error?: { message?: string };
  onChange?: (value?: Dayjs) => void;
  value?: Dayjs | null;
};
export default function DayJsDatePicker({ value, onChange, id, allowPastDates, ...rest }: Props) {
  const [focused, setFocused] = useState<boolean>(false);

  return (
    <div>
      <SingleDatePicker
        date={value ? moment(value?.toISOString()) : null}
        noBorder
        block
        openDirection="down"
        small
        numberOfMonths={1}
        isOutsideRange={!allowPastDates ? day => day.isBefore(moment().startOf('day')) : () => false}
        focused={focused} // Local state focused will take precedence
        onDateChange={date => onChange?.(date ? dayjs(date?.toISOString()) : undefined)}
        onFocusChange={({ focused }) => setFocused(focused)}
        id={id}
        {...rest}
      />
      <div className="text-xs italic text-red-500">{rest.error?.message}</div>
    </div>
  );
}
