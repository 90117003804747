import { StepperProps, StepProps } from 'components/Stepper/types';

import cx from 'classnames';
import { StepperContext, useStepperContext } from 'components/Stepper/context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import 'twin.macro';

export const Stepper: React.FC<StepperProps> = ({
  currentStep,
  className,
  allowNavigation = false,
  onNavigate,
  children,
  ...rest
}) => {
  return (
    <ol {...rest} tw="min-h-[40px]" className={cx('flex w-full gap-6', className)}>
      <StepperContext.Provider
        value={{
          allowNavigation,
          currentStep,
          onNavigate,
        }}
      >
        {children}
      </StepperContext.Provider>
    </ol>
  );
};

export const Step: React.FC<StepProps> = ({ isCompleted, className, children, index, ...rest }) => {
  const { currentStep, allowNavigation, onNavigate } = useStepperContext();

  const clickable = allowNavigation && currentStep !== index;

  return (
    <li
      className={cx(
        'flex items-end flex-1 gap-2 border-t-4 text-indigo-700 font-semibold',
        {
          'border-indigo-700': index <= currentStep,
          'hover:border-indigo-300 cursor-pointer': clickable,
        },
        className,
      )}
      onClick={() => clickable && onNavigate?.(index)}
    >
      {children}
      {isCompleted && <FontAwesomeIcon icon={faCheckCircle} tw="text-green-700 mb-[2px]" />}
    </li>
  );
};
