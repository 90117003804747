import { useMutation, useQuery, useQueryClient } from 'react-query';
import { dynamicHttp } from '@expressable/utils';
import { useToasts } from 'react-toast-notifications';
import { presentMutationError } from '@expressable/ui-library';

const httpHealthRecordProxy = dynamicHttp('health-record-proxy');

export interface GetDischargePlanPayload {
  details: string;
}

export interface SetDischargePlanPayload {
  clientId: string;
  details: string;
  shouldLogEvent: boolean;
}

const getDischargePlan = async (clientId: string) => {
  const { data } = await httpHealthRecordProxy.get(`/care-plans/discharge-plan/${clientId}`);
  return data;
};

export function useGetDischargePlan({ clientId }: { clientId: string }) {
  return useQuery<GetDischargePlanPayload | null>(['care-plan-discharge-plan', clientId], () =>
    getDischargePlan(clientId),
  );
}

const setDischargePlan = async ({ clientId, ...rest }: SetDischargePlanPayload) => {
  return httpHealthRecordProxy.post(`/care-plans/discharge-plan/${clientId}`, rest).then(res => res.data);
};

export function useSetDischargePlan() {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(setDischargePlan, {
    onSuccess: (_, payload) => {
      addToast('Discharge Plan Successfully Updated', { appearance: 'success', autoDismiss: true });
      queryClient.invalidateQueries(['care-plan-discharge-plan', payload.clientId]);
    },
    onError: presentMutationError,
  });
}
