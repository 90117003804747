import React from 'react';
import { Button, Modal, ModalBody, ModalContent, ModalFooter } from '@expressable/ui-library';
import 'twin.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/pro-solid-svg-icons';

export interface DeleteSecondaryInsuranceModal {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
}

export function DeleteSecondaryInsuranceModal(props: DeleteSecondaryInsuranceModal) {
  const { isOpen, onClose, onDelete } = props;

  return (
    <Modal portal isOpen={isOpen}>
      <ModalContent>
        <div
          id="delete-secondary-insurance-modal"
          data-testid="delete-secondary-insurance-modal"
        >
          <ModalBody>
            <div>
              <div tw="flex items-center justify-center w-12 h-12 mx-auto bg-indigo-100 rounded-full">
                <FontAwesomeIcon tw="text-2xl text-indigo-700" icon={faFileAlt} />
              </div>
              <div tw="mt-3">
                <h3 tw="text-lg text-center font-medium">Delete Secondary Insurance Information</h3>
                <div tw="mt-2">
                  <p tw="text-sm text-center">Are you sure you want to delete this secondary insurance information?</p>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter tw="mt-8 flex-none md:flex justify-center">
            <Button
              type="submit"
              data-testid="delete-secondary-insurance-submit"
              variant="danger"
              tw="w-full inline-flex justify-center items-end text-base font-medium leading-6 sm:text-sm sm:leading-5 order-last"
              onClick={onDelete}
            >
              Delete
            </Button>
            <Button
              data-testid="delete-secondary-insurance-close"
              type="button"
              variant="secondary"
              tw="w-full inline-flex justify-center items-end text-base font-medium leading-6 sm:text-sm sm:leading-5 mr-3 mt-3 md:mt-0"
              onClick={onClose}
            >
              Cancel
            </Button>
          </ModalFooter>
        </div>
      </ModalContent>
    </Modal>
  );
}
