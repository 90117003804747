/* eslint-disable react/jsx-props-no-spreading */
import { Label } from '@expressable/ui-library';
import { HelperText } from 'components/Forms/HelperText';
import { useFormValidation } from 'domain/form-validation';
import React, { useEffect, useMemo } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';
import { FormFieldProps } from 'components/Forms/types/form-field';
import { getSchemaFieldAttributes } from 'utils/fieldAttributes';
import { callAll } from 'utils/function';
import useOptional from 'domain/form-validation/useOptional';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const FormField = <C extends React.ComponentType<any>>({
  name,
  component: Component,
  disabled,
  onChange,
  onBlur,
  label,
  optional: manualOptional,
  hint,
  children,
  containerClass,
  componentContainerClass,
  ...props
}: FormFieldProps<C>): JSX.Element => {
  const { setError, register, unregister } = useFormContext();
  const { errors, isSubmitting } = useFormState({ name });
  const { schema, disabled: formDisabled, serverErrors } = useFormValidation();
  const schemaFieldAttributes = useMemo(() => getSchemaFieldAttributes(schema, name), [schema, name]);
  const serverError = serverErrors[name];

  useEffect(() => {
    if (serverError) {
      setError(name, { type: 'server', message: serverError });
    }
  }, [serverError, name, setError]);

  useEffect(() => {
    return () => {
      unregister(name);
    };
  }, [unregister, name]);

  const { onChange: formOnChange, onBlur: formOnBlur, ...formProps } = register(name);

  const callbacks = {
    onChange: callAll(onChange, formOnChange),
    onBlur: callAll(onBlur, formOnBlur),
  };

  const optional = useOptional(name, manualOptional);

  return (
    <div className={containerClass}>
      <Label optional={optional}>{label}</Label>
      <div className={componentContainerClass}>
        <Component
          {...props}
          {...formProps}
          {...callbacks}
          {...schemaFieldAttributes}
          id={name}
          disabled={formDisabled || isSubmitting || disabled || false}
          error={
            errors[name] && {
              message: errors[name]?.message || '',
            }
          }
        />
        {children}
      </div>
      {hint && <HelperText>{hint}</HelperText>}
    </div>
  );
};
