import { LearningPathContentful } from 'domain/clinical-pathways/types';
import { ContentfulSpaces, useContentfulEntries } from 'hooks/use-contentful';

export const useLearningPaths = () => {
  return useContentfulEntries<LearningPathContentful[]>({
    contentType: 'learningPaths',
    space: ContentfulSpaces.ClinicalCareExperience,

    order: ['fields.title'],
  });
};
