import { useEffect } from 'react';

//eslint-disable-next-line
export const useCallDataOnTruthy = (dep: boolean, callback: () => any) => {
  useEffect(() => {
    if (dep) {
      callback();
    }
  }, [dep]);
};
