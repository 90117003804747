import { useMutation, useQueryClient } from 'react-query';
import { http } from '@expressable/utils';
import { useToasts } from 'react-toast-notifications';
import { presentMutationError } from '@expressable/ui-library';
import { IFile } from './use-files';

export interface FileUpload extends IFile {
  sharableLink: string;
}

export interface CreateCustomSAPayload {
  learningPathID?: string;
  title: string;
  practiceOverview: string;
  activityGoals: string;
  timeRequired: string;
  whatClientNeedForActivity?: string;
  steps: { title: string; description: string }[];
  whatShouldClientLookFor: string;
  whatShouldClientTryIfDifficult: string;
  userEmail: string;
  uploadedFile?: FileUpload;
}

interface AssociatedMaterial {
  title: string
  description: string
  file: File
  id: string
}

interface File {
  url: string
  details: Details
  fileName: string
  contentType: string
}

interface Details {
  size: number
  image: Image
}

interface Image {
  width: number
  height: number
}

export interface CreateCustomSAResponse {
  id: string
  associatedMaterials: AssociatedMaterial[]
}

const createCustomStructuredActivity = async (payload: CreateCustomSAPayload) => {
  return http.post<CreateCustomSAResponse>(`/contentful/structured-activities`, payload).then(res => res.data);
};

export function useCreateCustomStructuredActivity() {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(createCustomStructuredActivity, {
    onSuccess: (response, payload) => {
      addToast('Custom Structured Activity Successfully Created', { appearance: 'success', autoDismiss: true });
      
      queryClient.invalidateQueries(['contentful', 'customStructuredActivity']);
    },
    onError: presentMutationError,
  });
}
