import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { dynamicHttp } from '@expressable/utils';
import { presentMutationError, useToast } from '@expressable/ui-library';
import { MedicalDiagnosis } from 'types';

const httpHealthRecordProxy = dynamicHttp('health-record-proxy');

const getMedicalDiagnoses = async (clientId: string) => {
  const { data } = await httpHealthRecordProxy.get(`/care-plans/medical-diagnoses/${clientId}`);
  return data;
};

export function useGetMedicalDiagnoses(
  { clientId }: { clientId: string },
  options?: UseQueryOptions<MedicalDiagnosis[], unknown, MedicalDiagnosis[]>,
) {
  return useQuery<MedicalDiagnosis[]>(['medical-diagnoses', clientId], () => getMedicalDiagnoses(clientId), options);
}

export interface SetMedicalDiagnosesPayload {
  clientId: string;
  medicalDiagnoses: MedicalDiagnosis[];
  shouldLogEvent: boolean;
}

const setMedicalDiagnoses = async (payload: SetMedicalDiagnosesPayload) => {
  const { clientId, ...rest } = payload;
  return httpHealthRecordProxy.post(`/care-plans/medical-diagnoses/${clientId}`, rest).then(res => res.data);
};

export function useSetMedicalDiagnoses() {
  const queryClient = useQueryClient();
  const { successToast } = useToast();

  return useMutation(setMedicalDiagnoses, {
    onSuccess: (_, payload) => {
      successToast('Medical Diagnoses successfully updated.');
      queryClient.invalidateQueries(['medical-diagnoses', payload.clientId]);
    },
    onError: presentMutationError,
  });
}

export interface RemoveMedicalDiagnosisPayload {
  clientId: string;
  code: string;
  shouldLogEvent: boolean;
}

const removeMedicalDiagnosis = async ({ clientId, code, shouldLogEvent }: RemoveMedicalDiagnosisPayload) => {
  return httpHealthRecordProxy
    .delete(
      `/care-plans/medical-diagnoses/${clientId}?code=${encodeURIComponent(code)}&shouldLogEvent=${shouldLogEvent}`,
    )
    .then(res => res.data);
};

export function useRemoveMedicalDiagnosis() {
  const queryClient = useQueryClient();
  const { successToast } = useToast();

  return useMutation(removeMedicalDiagnosis, {
    onSuccess: (_, payload) => {
      successToast('Medical Diagnosis successfully removed.');
      queryClient.invalidateQueries(['medical-diagnoses', payload.clientId]);
    },
    onError: presentMutationError,
  });
}
