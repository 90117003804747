export default [
  { value: '01', label: '01 - Spouse' },
  { value: '04', label: '04 - Grandparent' },
  { value: '05', label: '05 - Grandchild' },
  { value: '07', label: '07 - Niece or nephew' },
  { value: '10', label: '10 - Foster child' },
  { value: '15', label: '15 - Ward of the court' },
  { value: '17', label: '17 - Stepchild' },
  { value: '18', label: '18 - Self' },
  { value: '19', label: '19 - Child' },
  { value: '20', label: '20 - Employee' },
  { value: '21', label: '21 - Unknown' },
  { value: '22', label: '22 - Handicapped dependent' },
  { value: '23', label: '23 - Sponsored dependent' },
  { value: '24', label: '24 - Dependent of minor dependent' },
  { value: '29', label: '29 - Significant other' },
  { value: '32', label: '32 - Mother' },
  { value: '33', label: '33 - Father' },
  { value: '36', label: '36 - Emancipated minor' },
  { value: '39', label: '39 - Organ Donor' },
  { value: '40', label: '40 - Cadaver Donor' },
  { value: '41', label: '41 - Injured plaintiff' },
  { value: '43', label: '43 - Natural Child, insured does not have financial responsibility' },
  { value: '53', label: '53 - Life Partner' },
  { value: 'G8', label: 'G8 - Other Relationship' },
];
