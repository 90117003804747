import React from 'react';
import { Card, Container, Link } from '@expressable/ui-library';
import { CompleteTherapistInformation } from 'types';
import 'twin.macro';
import { textWithLineBreaks } from 'utils/helpers';
import { isNumber } from 'lodash';
import { SchedulingLimitPerWeek } from './edit-therapist-info-form';

export type TherapistInfoReadOnlyProps = {
  therapistData: CompleteTherapistInformation;
};

const TherapistInfoReadOnly = ({ therapistData }: TherapistInfoReadOnlyProps) => {
  const languages = therapistData.languages?.map(language => (language as { code: string; name: string }).name);

  return (
    <div data-testid="therapist-info-read-only">
      <Container tw="relative pl-0" size="large">
        <Card tw="mb-4 px-8">
          <div tw="text-2xl font-semibold mb-7 mt-4">Therapist Information</div>
          <div className="text-sm font-normal">
            <div tw="mt-8 flex items-end">
              {therapistData.firstName && (
                <div tw="mr-16">
                  <p tw="font-semibold">First Name</p>
                  <p data-testid="therapist-info-read-only-first-name" tw="pt-2">
                    {therapistData.firstName}
                  </p>
                </div>
              )}
              {therapistData.preferredFirstName && (
                <div tw="mr-16">
                  <p tw="font-semibold">Preferred Name</p>
                  <p data-testid="therapist-info-read-only-preferred-name" tw="pt-2">
                    {therapistData.preferredFirstName}
                  </p>
                </div>
              )}
              {therapistData.lastName && (
                <div tw="mr-16">
                  <p tw="font-semibold">Last Name</p>
                  <p data-testid="therapist-info-read-only-last-name" tw="pt-2">
                    {therapistData.lastName}
                  </p>
                </div>
              )}
            </div>
            {therapistData.therapistTimeZone && (
              <div tw="mt-8">
                <p tw="font-semibold">Therapist Time Zone</p>
                <p data-testid="therapist-info-read-only-therapist-time-zone" tw="pt-2">
                  {therapistData.therapistTimeZone}
                </p>
              </div>
            )}
            {therapistData.therapistEmail && (
              <div tw="mt-8">
                <p tw="font-semibold">Expressable Email</p>
                <p data-testid="therapist-info-read-only-email" tw="pt-2">
                  {therapistData.therapistEmail}
                </p>
              </div>
            )}
            {therapistData.sequoiaID && (
              <div tw="mt-8">
                <p tw="font-semibold">Sequoia ID</p>
                <p data-testid="therapist-info-read-only-sequoia-id" tw="pt-2">
                  {therapistData.sequoiaID}
                </p>
              </div>
            )}
            {therapistData?.therapistState && (
              <div className="mt-8">
                <>
                  <p className="font-semibold text-sm">Therapist State Of Residence</p>
                  <p data-testid="therapist-info-read-only-slack-user-id" className="font-normal text-sm pt-2">
                    {therapistData.therapistState}
                  </p>
                </>
              </div>
            )}
            {therapistData.statesLicensed && (
              <div tw="mt-8">
                <p tw="font-semibold">Licensed States</p>
                <div data-testid="therapist-info-read-only-licenses" tw="pt-2">
                  {therapistData.statesLicensed.map(licenses => (
                    <p key={licenses}>{licenses} </p>
                  ))}
                </div>
              </div>
            )}
            {therapistData.licenseNumbers && (
              <div tw="mt-8">
                <p tw="font-semibold">License Numbers</p>
                <p data-testid="therapist-info-read-only-email" tw="pt-2">
                  {textWithLineBreaks(therapistData.licenseNumbers)}
                </p>
              </div>
            )}
            {therapistData.languages && (
              <div tw="mt-8">
                <p tw="font-semibold">Languages</p>
                <div data-testid="therapist-info-read-only-languages" tw="pt-2">
                  {languages.map(language => (
                    <p key={language}>{language} </p>
                  ))}
                </div>
              </div>
            )}
            {therapistData.therapistProfile && (
              <div tw="mt-8">
                <p tw="font-semibold">Therapist Profile</p>
                <p data-testid="therapist-info-read-only-therapist-profile" tw="pt-2">
                  {therapistData.therapistProfile}
                </p>
                <Link to={{ pathname: `${therapistData.therapistProfile}` }} target="_blank">
                  View Therapist Profile
                </Link>
              </div>
            )}
            {therapistData.zoomLink && (
              <div tw="mt-8">
                <p tw="font-semibold">Zoom Link</p>
                <p data-testid="therapist-info-read-only-acuity-zoom-link" tw="pt-2">
                  {therapistData.zoomLink}
                </p>
                <Link to={{ pathname: `${therapistData.zoomLink}` }} target="_blank">
                  View Therapist Zoom
                </Link>
              </div>
            )}
            {therapistData.acuityCalendarID && (
              <div tw="mt-8">
                <p tw="font-semibold">Acuity Calendar ID</p>
                <p data-testid="therapist-info-read-only-acuity-calendar" tw="pt-2">
                  {therapistData.acuityCalendarID}
                </p>
              </div>
            )}
            {therapistData.npi && (
              <div tw="mt-8">
                <p tw="font-semibold">NPI</p>
                <p data-testid="therapist-info-read-only-npi" tw="pt-2">
                  {therapistData.npi}
                </p>
              </div>
            )}
            {therapistData.specialties.length > 0 && (
              <div tw="mt-8">
                <p tw="font-semibold">Clinical Competencies</p>
                <div data-testid="therapist-info-read-only-specialties" tw="pt-2">
                  {therapistData.specialties.map(specialties => (
                    <p key={specialties}>{specialties} </p>
                  ))}
                </div>
              </div>
            )}
            {therapistData.status && (
              <div tw="mt-8">
                <p tw="font-semibold">Status</p>
                <p data-testid="therapist-info-read-only-status" tw="pt-2">
                  {therapistData.status}
                </p>
              </div>
            )}
            <div tw="mt-8">
              <p tw="font-semibold">Guaranteed Hours</p>
              <p data-testid="therapist-info-read-only-committed-hours" tw="pt-2">
                {therapistData.committedHours} Hours
              </p>
            </div>
            <div tw="mt-8">
              <p tw="font-semibold">Therapist Type</p>
              <p data-testid="therapist-info-read-only-therapist-type" tw="pt-2">
                {therapistData.therapistType}
              </p>
            </div>
            <div tw="mt-8">
              <p tw="font-semibold">PRN</p>
              <p data-testid="therapist-info-read-only-isPRN" tw="pt-2">
                {therapistData.isPRN ? 'Yes' : 'No'}
              </p>
            </div>
            {therapistData?.slackUserId && (
              <div className="mt-8">
                <>
                  <p className="font-semibold text-sm">Slack User ID</p>
                  <p data-testid="therapist-info-read-only-slack-user-id" className="font-normal text-sm pt-2">
                    {therapistData.slackUserId}
                  </p>
                </>
              </div>
            )}
            <div className="mt-8">
              <p className="font-semibold">Slack Conversations Enabled</p>
              <p data-testid="therapist-info-read-only-is-slack-conversation-enabled" className="pt-2">
                {therapistData.useSlackConversations ? 'Yes' : 'No'}
              </p>
            </div>

            <div tw="text-xl font-semibold mt-8">Rate Limiting</div>

            <div tw="mt-8">
              <p tw="font-semibold">Accepting New Clients</p>
              <p data-testid="therapist-info-read-only-new-clients" tw="pt-2">
                {therapistData.acceptingNewPatients ? 'Yes' : 'No'}
              </p>
            </div>

            <p tw="font-semibold mt-8">Initial Evaluation Limits</p>
            <div tw="mt-2">
              <p data-testid="therapist-info-read-only-evaluation-limit-per-day" tw="pt-2">
                {`${therapistData.evaluationLimitPerDay ?? 'No limit'} per day maximum`}
              </p>
            </div>
            <div tw="mt-2">
              <p data-testid="therapist-info-read-only-evaluation-limit-per-week" tw="pt-2">
                {`${therapistData.evaluationLimitPerWeek ?? 'No limit'} per week maximum`}
              </p>
            </div>

            <p tw="font-semibold mt-8">Direct Hour Limits</p>
            <div tw="text-gray-500 mt-2">
              Direct hours are a combination of evaluation and sessions, taking the duration of all appointments
              scheduled into account. Attendance rate assumptions are also included.
            </div>
            <div tw="mt-2">
              <p data-testid="therapist-info-read-only-direct-hour-goals" tw="pt-2">
                {`${therapistData.directHourGoals ?? 'No limit'} direct hour goals`}
              </p>
            </div>
            <div tw="mt-2">
              <p data-testid="therapist-info-read-only-direct-hour-attendance-rate" tw="pt-2">
                {`${
                  isNumber(therapistData.directHourAttendanceRate)
                    ? `${therapistData.directHourAttendanceRate}%`
                    : 'No limit'
                } projected attendance rate`}
              </p>
            </div>
            <SchedulingLimitPerWeek
              directHourGoals={therapistData.directHourGoals}
              directHourAttendanceRate={therapistData.directHourAttendanceRate}
            />

            <p tw="font-semibold mt-8">Consecutive Hour Limits</p>
            <p tw="mt-2 text-gray-500">
              How many consecutive appointments (by duration) can be booked without a break?
            </p>
            <div tw="mt-2">
              <p data-testid="therapist-info-read-only-consecutive-appts-limit" tw="font-normal text-sm pt-2">
                {isNumber(therapistData.consecutiveApptsLimit)
                  ? `${therapistData.consecutiveApptsLimit} hours`
                  : 'No limit'}
              </p>
            </div>
          </div>
        </Card>
      </Container>
    </div>
  );
};

export default TherapistInfoReadOnly;
