import React from 'react';
import { Card, Container, FormGroup, Label, Link, Loader } from '@expressable/ui-library';
import { capitalizeFirst } from 'utils/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useBillingInfo } from 'hooks/use-billing-information';
import { ReferringProviderTypeEnum } from 'domain/billing-information/constants';
import ReadonlyInsurance from './billing-info-read-only-insurance';
import { authorizationStatusOptions } from 'utils/authorization-status-options';
import 'twin.macro';
import moment from 'moment';

export type BillingInfoReadOnlyProps = {
  isLoading: boolean;
  isError: boolean;
  clientId: string;
};

export const getAuthStatusLabel = (appointmentTypeStatus: string) => {
  const authStatusLabel = authorizationStatusOptions.find(
    appointmentType => appointmentType.value === appointmentTypeStatus,
  );
  return authStatusLabel?.label;
};

const BillingInfoReadOnly = ({ isLoading, isError, clientId }: BillingInfoReadOnlyProps) => {
  const { data: billingInfoData } = useBillingInfo(clientId);

  if (isLoading) {
    return (
      <div data-testid="billing-info-loading" tw="flex-1 text-center py-20">
        <Loader type="ring" />
      </div>
    );
  }

  if (isError) {
    return (
      <Container data-testid="billing-info-error" tw="relative pl-0" size="large">
        <Card tw="mb-4 px-8">
          <div tw="text-2xl font-semibold mb-7 mt-4">Billing Information</div>
          <FormGroup tw="w-full sm:w-1/2 md:w-1/3">
            <Label htmlFor="stripeCustomerID">
              <FontAwesomeIcon icon={faExclamationTriangle} tw="text-red-500 mr-2" />
              There was an error while loading this billing information read only
            </Label>
          </FormGroup>
        </Card>
      </Container>
    );
  }

  if (billingInfoData === null) {
    return (
      <Container data-testid="billing-info-no-data" tw="relative pl-0" size="large">
        <Card tw="mb-4 px-8">
          <div tw="text-2xl font-semibold mb-7 mt-4">Billing Information</div>
          <FormGroup tw="w-full sm:w-1/2 md:w-1/3">
            <Label htmlFor="stripeCustomerID">
              <FontAwesomeIcon icon={faExclamationTriangle} tw="text-red-500 mr-2" />
              No billing information is available
            </Label>
          </FormGroup>
        </Card>
      </Container>
    );
  }

  return (
    <div data-testid="billing-info-read-only">
      <Container tw="relative pl-0" size="large">
        <Card tw="mb-4 px-8">
          <div tw="text-2xl font-semibold mb-7 mt-4">Billing Information</div>

          {billingInfoData?.billingNotes && (
            <div>
              <p tw="font-semibold text-sm">Billing Notes</p>
              <p data-testid="billing-info-read-only-billing-notes" tw="font-normal text-sm pt-2">
                {billingInfoData?.billingNotes}
              </p>
            </div>
          )}
          {billingInfoData?.stripeCustomerID && (
            <div className='mt-8'>
              <p tw="font-semibold text-sm">Stripe Customer ID</p>
              <p data-testid="billing-info-read-only-stripe-customer-id" tw="font-normal text-sm pt-2">
                {billingInfoData?.stripeCustomerID}
              </p>
              <Link
                to={{ pathname: `https://dashboard.stripe.com/customers/${billingInfoData?.stripeCustomerID}` }}
                target="_blank"
                tw="text-sm"
              >
                View in Stripe
              </Link>
            </div>
          )}
          {billingInfoData?.billingType && (
            <div tw="mt-8">
              <p tw="font-semibold text-sm">Billing Type</p>
              <p data-testid="billing-info-read-only-billing-type" tw="font-normal text-sm pt-2">
                {capitalizeFirst(billingInfoData?.billingType)}
              </p>
            </div>
          )}
          {/* SelfPay */}
          {billingInfoData?.selfPay?.promptPay && (
            <div tw="mt-8">
              <p tw="font-semibold text-sm">Prompt Pay</p>
              <p data-testid="billing-info-read-only-prompt-pay" tw="font-normal text-sm pt-2">
                Automatic Payments
              </p>
            </div>
          )}
          {billingInfoData?.selfPay?.automaticPayments?.membershipStatus && (
            <div tw="mt-8">
              <p tw="font-semibold text-sm">Membership Status</p>
              <p data-testid="billing-info-read-only-membership-status" tw="font-normal text-sm pt-2">
                {`${
                  billingInfoData?.selfPay?.automaticPayments?.membershipStatus === 'monthly-membership'
                    ? 'Monthly Membership'
                    : 'Fee-for-Service'
                }`}
              </p>
            </div>
          )}
          {billingInfoData?.selfPay?.automaticPayments?.membershipStatus === 'fee-for-service' && (
            <>
              <div tw="mt-8">
                <p tw="font-semibold text-sm">Evaluation Pricing</p>
                <p data-testid="billing-info-read-only-pricing" tw="font-normal text-sm pt-2">
                  ${billingInfoData?.selfPay?.automaticPayments?.pricingForService?.evaluationPrice}
                </p>
              </div>
              <div tw="mt-8">
                <p tw="font-semibold text-sm">Session Pricing</p>
                <p data-testid="billing-info-read-only-pricing" tw="font-normal text-sm pt-2">
                  ${billingInfoData?.selfPay?.automaticPayments?.pricingForService?.sessionPrice}
                </p>
              </div>
            </>
          )}

          {billingInfoData?.selfPay?.automaticPayments?.membershipStatus === 'monthly-membership' && (
            <div>
              {billingInfoData?.selfPay.automaticPayments.pricingMonthlyMembership.price === 229 ? (
                <div tw="mt-8">
                  <p tw="font-semibold text-sm">Pricing</p>
                  <p data-testid="billing-info-read-only-pricing" tw="font-normal text-sm pt-2">
                    Legacy Monthly Subscription
                  </p>
                </div>
              ) : (
                <div tw="mt-8">
                  <p tw="font-semibold text-sm">Pricing</p>
                  <p data-testid="billing-info-read-only-pricing" tw="font-normal text-sm pt-2">
                    ${billingInfoData?.selfPay?.automaticPayments?.pricingMonthlyMembership?.price} a Month
                  </p>
                </div>
              )}
            </div>
          )}

          {billingInfoData?.selfPay?.automaticPayments?.membershipStatus === 'monthly-membership' && (
            <div>
              {billingInfoData?.selfPay.automaticPayments.pricingMonthlyMembership.price === 229 ? (
                <div tw="mt-8">
                  <p tw="font-semibold text-sm">Price for Extra Sessions</p>
                  <p data-testid="billing-info-read-only-price-extra-sessions" tw="font-normal text-sm pt-2">
                    $0
                  </p>
                </div>
              ) : (
                <div tw="mt-8">
                  <p tw="font-semibold text-sm">Price for Extra Sessions</p>
                  <p data-testid="billing-info-read-only-price-extra-sessions" tw="font-normal text-sm pt-2">
                    $60
                  </p>
                </div>
              )}
            </div>
          )}

          {/* Insurance */}
          {billingInfoData?.billingType === 'insurance' && (
            <>
              <div tw="mt-8">
                <p tw="font-semibold text-sm">Co-pay</p>
                <p data-testid="billing-info-read-only-primaryInsurance-coPay" tw="font-normal text-sm pt-2">
                  ${billingInfoData.primaryInsurance.coPay}
                </p>
              </div>
              <ReadonlyInsurance insurance={billingInfoData?.primaryInsurance} />
              <ReadonlyInsurance insurance={billingInfoData?.secondaryInsurance} type="secondary" />
              {billingInfoData?.insuranceAuthorizations && (
                <>
                  <div tw="text-lg font-semibold mb-7 mt-8">Authorizations</div>
                  <div>
                    {billingInfoData?.insuranceAuthorizations.evaluationAuthStatus && (
                      <div tw="mt-8">
                        <p tw="font-semibold text-sm">Evaluation Authorization Status</p>
                        <p
                          data-testid="billing-info-read-only-authorizations-evaluationAuthStatus"
                          tw="font-normal text-sm pt-2"
                        >
                          {getAuthStatusLabel(billingInfoData?.insuranceAuthorizations.evaluationAuthStatus)}
                        </p>
                      </div>
                    )}
                    {billingInfoData?.insuranceAuthorizations.sessionAuthStatus && (
                      <div tw="mt-8">
                        <p tw="font-semibold text-sm">Session Authorization Status</p>
                        <p
                          data-testid="billing-info-read-only-authorizations-sessionAuthStatus"
                          tw="font-normal text-sm pt-2"
                        >
                          {getAuthStatusLabel(billingInfoData?.insuranceAuthorizations.sessionAuthStatus)}
                        </p>
                      </div>
                    )}

                    {billingInfoData?.insuranceAuthorizations.visitLimits && (
                      <div tw="mt-8">
                        <p tw="font-semibold text-sm">Are there any visit limits?</p>
                        <p
                          data-testid="billing-info-read-only-authorizations-visitLimits"
                          tw="font-normal text-sm pt-2"
                        >
                          {billingInfoData?.insuranceAuthorizations.visitLimits ? 'Yes' : 'No'}
                        </p>
                      </div>
                    )}
                    {billingInfoData?.insuranceAuthorizations.visitLimits && (
                      <div tw="mt-8">
                        <p tw="font-semibold text-sm">Remaining Visits left</p>
                        <p
                          data-testid="billing-info-read-only-authorizations-remainingVisitsLeft"
                          tw="font-normal text-sm pt-2"
                        >
                          {billingInfoData?.insuranceAuthorizations.remainingVisitsLeft}
                        </p>
                      </div>
                    )}
                    {billingInfoData?.insuranceAuthorizations.dateLimits && (
                      <div tw="mt-8">
                        <p tw="font-semibold text-sm">Are there any date limits?</p>
                        <p data-testid="billing-info-read-only-authorizations-dateLimits" tw="font-normal text-sm pt-2">
                          {billingInfoData?.insuranceAuthorizations.dateLimits ? 'Yes' : 'No'}
                        </p>
                      </div>
                    )}
                    {billingInfoData?.insuranceAuthorizations.visitsCompletedBy && (
                      <div tw="mt-8">
                        <p tw="font-semibold text-sm">Visits Completed By</p>
                        <p
                          data-testid="billing-info-read-only-authorizations-visitsCompletedBy"
                          tw="font-normal text-sm pt-2"
                        >
                          {billingInfoData?.insuranceAuthorizations.visitsCompletedBy}
                        </p>
                      </div>
                    )}

                    {billingInfoData?.insuranceAuthorizations?.reEvaluationRequired && (
                      <div tw="mt-8">
                        <p tw="font-semibold text-sm">Re-evaluation Required</p>
                        <p
                          data-testid="billing-info-read-only-authorizations-reEvaluationRequired"
                          tw="font-normal text-sm pt-2"
                        >
                          {billingInfoData?.insuranceAuthorizations?.reEvaluationRequired ? 'Yes' : 'No'}
                        </p>
                      </div>
                    )}
                    {billingInfoData?.insuranceAuthorizations?.reEvaluationDueBy && (
                      <div tw="mt-8">
                        <p tw="font-semibold text-sm">Re-evaluation Due By</p>
                        <p
                          data-testid="billing-info-read-only-authorizations-reEvaluationDueBy"
                          tw="font-normal text-sm pt-2"
                        >
                          {moment(billingInfoData?.insuranceAuthorizations?.reEvaluationDueBy).format('YYYY-MM-DD')}
                        </p>
                      </div>
                    )}
                  </div>
                </>
              )}
            </>
          )}
          {/* Referring Provider */}
          {billingInfoData?.referringProvider && (
            <>
              <div tw="text-lg font-semibold mb-7 mt-8">Referring Provider Information</div>
              <div>
                {billingInfoData?.referringProvider.referringType && (
                  <div tw="mt-8">
                    <p tw="font-semibold text-sm">Type</p>
                    <p data-testid="billing-info-read-only-referringProvider-type" tw="font-normal text-sm pt-2">
                      {capitalizeFirst(billingInfoData?.referringProvider.referringType)}
                    </p>
                  </div>
                )}
                {billingInfoData?.referringProvider?.referringType === ReferringProviderTypeEnum.individual && (
                  <div tw="flex flex-wrap gap-4">
                    {billingInfoData?.referringProvider?.firstName && (
                      <div tw="mt-8">
                        <p tw="font-semibold text-sm">First Name</p>
                        <p
                          data-testid="billing-info-read-only-referringProvider-firstName"
                          tw="font-normal text-sm pt-2"
                        >
                          {billingInfoData.referringProvider.firstName}
                        </p>
                      </div>
                    )}
                    {billingInfoData?.referringProvider.lastName && (
                      <div tw="mt-8">
                        <p tw="font-semibold text-sm">Last Name</p>
                        <p
                          data-testid="billing-info-read-only-referringProvider-lastName"
                          tw="font-normal text-sm pt-2"
                        >
                          {billingInfoData.referringProvider.lastName}
                        </p>
                      </div>
                    )}
                  </div>
                )}
                {billingInfoData?.referringProvider?.npi && (
                  <div tw="mt-8">
                    <p tw="font-semibold text-sm">NPI</p>
                    <p data-testid="billing-info-read-only-referringProvider-npi" tw="font-normal text-sm pt-2">
                      {billingInfoData.referringProvider.npi}
                    </p>
                  </div>
                )}
                {billingInfoData?.referringProvider?.taxonomyCode && (
                  <div tw="mt-8">
                    <p tw="font-semibold text-sm">Taxonomy Code</p>
                    <p
                      data-testid="billing-info-read-only-referringProvider-taxonomyCode"
                      tw="font-normal text-sm pt-2"
                    >
                      {billingInfoData.referringProvider.taxonomyCode}
                    </p>
                  </div>
                )}
                {billingInfoData?.referringProvider?.referringType === ReferringProviderTypeEnum.organization && (
                  <div>
                    {billingInfoData?.referringProvider?.organizationName && (
                      <div tw="mt-8">
                        <p tw="font-semibold text-sm">Organization Name</p>
                        <p
                          data-testid="billing-info-read-only-referringProvider-organizationName"
                          tw="font-normal text-sm pt-2"
                        >
                          {billingInfoData.referringProvider.organizationName}
                        </p>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </>
          )}
        </Card>
      </Container>
    </div>
  );
};

export default BillingInfoReadOnly;
