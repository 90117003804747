import dayjs from 'dayjs';
import moment from 'moment';
import { EvaluationNoteFormData } from 'pages/client/components/client-notes/note-form-content/evaluation/schema';
import { SelectOption } from 'types';
import { EvaluationNoteDTO } from './types';
import { hhmmTimeOptions } from 'utils/time-options';
import { formatCptCode } from 'pages/client/components/cpt-codes';
import { ICD10 } from 'domain/shared/types';
import {
  convertBooleanToYesOrNo,
  convertStringArrayToSelectOptions,
  convertStringToSelectOption,
  transformYesOrNoDetailToYesOrNoFormData,
} from 'domain/notes/mapHelpers';

export function mapEvaluationNoteDTOToFormData(appt: EvaluationNoteDTO): EvaluationNoteFormData {
  const { note } = appt;
  const appointmentOnHour = moment(appt.appointmentOn).format('HH:mm');
  const result: EvaluationNoteFormData = {
    // basic data
    appointmentOnDate: dayjs(appt.appointmentOn).toDate(),
    appointmentOnHour: hhmmTimeOptions.find((time: SelectOption) => time.value === appointmentOnHour)!,
    cpt: !!appt.cpt?.length
      ? appt.cpt.map(untypedAppointmentCpt => {
          const cpt = untypedAppointmentCpt as ICD10;
          return { label: formatCptCode(cpt), value: cpt };
        })
      : null,
    evaluationDiagnosis: note.content.evaluationDiagnosis
      ? note?.content?.evaluationDiagnosis?.map(convertSelectOptionToICD10DTO)
      : null,
    // overview
    adjustedAge: note?.content?.adjustedAge,

    // language
    clientsPrimaryLanguage: note?.content?.clientsPrimaryLanguage
      ? getPrimaryLanguage(note?.content?.clientsPrimaryLanguage)
      : null,
    additionalLanguageExposures: transformArrayResponseToSelectOption(note?.content?.additionalLanguageExposures),
    evaluationInPrimaryLanguage: convertBooleanToYesOrNo(note?.content?.evaluationInPrimaryLanguage),
    evaluationWithInterpreter: convertBooleanToYesOrNo(note?.content?.evaluationWithInterpreter),
    sourceOfLanguages: convertStringToSelectOption(note?.content?.sourceOfLanguages),
    evaluationLanguageJustification: note?.content?.evaluationLanguageJustification ?? '',

    // medical history
    referralType: convertStringToSelectOption(note?.content?.referralType),
    referredBy: note?.content?.referredBy ?? '',
    referralReason: note?.content?.referralReason ?? '',
    personsPresent: transformArrayResponseToSelectOption(note?.content?.personsPresent),
    medicalHistorySource: transformArrayResponseToSelectOption(note?.content?.medicalHistorySource),
    birthHistory: transformYesOrNoDetailToYesOrNoFormData(note?.content?.birthHistory),
    hospitalizationHistory: transformYesOrNoDetailToYesOrNoFormData(note?.content?.hospitalizationHistory),
    allergiesHistory: transformYesOrNoDetailToYesOrNoFormData(note?.content?.allergiesHistory),
    medicalDiagnosesHistory: transformYesOrNoDetailToYesOrNoFormData(note?.content?.medicalDiagnosesHistory),
    medications: transformYesOrNoDetailToYesOrNoFormData(note?.content?.medications),
    hearingHistory: transformYesOrNoDetailToYesOrNoFormData(note?.content?.hearingHistory),
    visionHistory: transformYesOrNoDetailToYesOrNoFormData(note?.content?.visionHistory),
    otherHistory: transformYesOrNoDetailToYesOrNoFormData(note?.content?.otherHistory),
    developmentalHistory: transformYesOrNoDetailToYesOrNoFormData(note?.content?.developmentalHistory),
    pain: transformYesOrNoDetailToYesOrNoFormData(note?.content?.pain),
    mentalStatus: transformYesOrNoDetailToYesOrNoFormData(note?.content?.mentalStatus),
    functionalLimitations: transformYesOrNoDetailToYesOrNoFormData(note?.content?.functionalLimitations),
    previousSpeechTreatment: transformYesOrNoDetailToYesOrNoFormData(note?.content?.previousSpeechTreatment),
    nursingOtherSupportsType: transformArrayResponseToSelectOption(note?.content?.nursingOtherSupportsType ?? []),
    nursingOtherSupportsDetails: note?.content?.nursingOtherSupportsDetails ?? '',
    // social history
    educationStatus: note?.content?.educationStatus
      ? convertStringToSelectOption(note?.content?.educationStatus)
      : null,
    gradeLevel: note?.content?.gradeLevel ? convertStringToSelectOption(note?.content?.gradeLevel) : null,
    academicConcerns: transformYesOrNoDetailToYesOrNoFormData(note?.content?.academicConcerns),
    livingWith: convertStringArrayToSelectOptions(note?.content?.livingWith),

    // behavioral observations
    acknowledgesSpeaker: convertStringToSelectOption(note?.content?.acknowledgesSpeaker),
    attentionToTasks: convertStringToSelectOption(note?.content?.attentionToTasks),
    topicMaintenance: convertStringToSelectOption(note?.content?.topicMaintenance),
    returnsGreeting: convertStringToSelectOption(note?.content?.returnsGreeting),
    transitionsIntoTreatment: convertStringToSelectOption(note?.content?.transitionsBetweenTasks),
    transitionsBetweenTasks: convertStringToSelectOption(note?.content?.transitionsBetweenTasks),
    awarenessOthersEnvironment: convertStringToSelectOption(note?.content?.awarenessOthersEnvironment),
    cooperation: convertStringToSelectOption(note?.content?.cooperation),
    responseRate: convertStringToSelectOption(note?.content?.responseRate),

    // clinical areas
    receptiveLanguageHowWasAssessed: transformArrayResponseToSelectOption(
      note?.content?.receptiveLanguageHowWasAssessed,
    ),
    receptiveLanguageAssessmentUsed: transformArrayResponseToSelectOption(
      note?.content?.receptiveLanguageAssessmentUsed,
    ),
    receptiveLanguageAssessmentDescription: note?.content?.receptiveLanguageAssessmentDescription ?? '',
    receptiveLanguageConclusions: note?.content?.receptiveLanguageConclusions ?? '',

    expressiveLanguageHowWasAssessed: transformArrayResponseToSelectOption(
      note?.content?.expressiveLanguageHowWasAssessed,
    ),
    expressiveLanguageAssessmentUsed: transformArrayResponseToSelectOption(
      note?.content?.expressiveLanguageAssessmentUsed,
    ),
    expressiveLanguageAssessmentDescription: note?.content?.expressiveLanguageAssessmentDescription ?? '',
    expressiveLanguageConclusions: note?.content?.expressiveLanguageConclusions ?? '',

    fluencyHowWasAssessed: transformArrayResponseToSelectOption(note?.content?.fluencyHowWasAssessed),
    fluencyAssessmentUsed: transformArrayResponseToSelectOption(note?.content?.fluencyAssessmentUsed),
    fluencyAssessmentDescription: note?.content?.fluencyAssessmentDescription ?? '',
    fluencyConclusions: note?.content?.fluencyConclusions ?? '',

    voiceResonanceHowWasAssessed: transformArrayResponseToSelectOption(note?.content?.voiceResonanceHowWasAssessed),
    voiceResonanceAssessmentUsed: transformArrayResponseToSelectOption(note?.content?.voiceResonanceAssessmentUsed),
    voiceResonanceAssessmentDescription: note?.content?.voiceResonanceAssessmentDescription ?? '',
    voiceResonanceConclusions: note?.content?.voiceResonanceConclusions ?? '',

    articulationPhonologyHowWasAssessed: transformArrayResponseToSelectOption(
      note?.content?.articulationPhonologyHowWasAssessed,
    ),
    articulationPhonologyAssessmentUsed: transformArrayResponseToSelectOption(
      note?.content?.articulationPhonologyAssessmentUsed,
    ),
    articulationPhonologyAssessmentDescription: note?.content?.articulationPhonologyAssessmentDescription ?? '',
    articulationPhonologyConclusions: note?.content?.articulationPhonologyConclusions ?? '',

    oralMotorAbilitiesHowWasAssessed: transformArrayResponseToSelectOption(
      note?.content?.oralMotorAbilitiesHowWasAssessed,
    ),
    oralMotorAbilitiesAssessmentUsed: transformArrayResponseToSelectOption(
      note?.content?.oralMotorAbilitiesAssessmentUsed,
    ),
    oralMotorAbilitiesAssessmentDescription: note?.content?.oralMotorAbilitiesAssessmentDescription ?? '',
    oralMotorAbilitiesConclusions: note?.content?.oralMotorAbilitiesConclusions ?? '',

    feedingSwallowingHowWasAssessed: transformArrayResponseToSelectOption(
      note?.content?.feedingSwallowingHowWasAssessed,
    ),
    feedingSwallowingAssessmentUsed: transformArrayResponseToSelectOption(
      note?.content?.feedingSwallowingAssessmentUsed,
    ),
    feedingSwallowingAssessmentDescription: note?.content?.feedingSwallowingAssessmentDescription ?? '',
    feedingSwallowingConclusions: note?.content?.feedingSwallowingConclusions ?? '',

    pragmaticsHowWasAssessed: transformArrayResponseToSelectOption(note?.content?.pragmaticsHowWasAssessed),
    pragmaticsAssessmentUsed: transformArrayResponseToSelectOption(note?.content?.pragmaticsAssessmentUsed),
    pragmaticsAssessmentDescription: note?.content?.pragmaticsAssessmentDescription ?? '',
    pragmaticsConclusions: note?.content?.pragmaticsConclusions ?? '',

    // conclusions
    areFurtherAssessmentsRecommended: transformYesOrNoDetailToYesOrNoFormData({
      relevant: note?.content?.areFurtherAssessmentsRecommended ?? null,
      details: note?.content?.recommendedAssessments ?? '',
    }),

    areSpeechTherapyServicesRecommended: transformYesOrNoDetailToYesOrNoFormData({
      relevant: note?.content?.areSpeechTherapyServicesRecommended ?? null,
      details: '',
    }),

    isTeletherapyAppropriate: convertStringToSelectOption(note?.content?.isTeletherapyAppropriate),
    clinicalRecommendations: note?.content?.clinicalRecommendations ?? '',
    conclusions: note?.content?.conclusions ?? '',
  };

  return result;
}

function convertSelectOptionToICD10DTO(option: ICD10): SelectOption {
  return {
    label: option.description,
    value: option.code,
  };
}

function getPrimaryLanguage(option?: string | null): SelectOption {
  if (!option) return { label: '', value: '' };
  return { label: option, value: option };
}

function transformArrayResponseToSelectOption(value: string[] | null): SelectOption[] | Array<never> {
  if (Array.isArray(value)) {
    return value.map(v => ({ label: v, value: v }));
  }

  return [];
}
