/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from 'react';
import { createClient, Entry, EntryCollection } from 'contentful';
import * as Sentry from '@sentry/react';

// Data from Contentful
// Read-only access to content-delivery API. Not sensitive
const SPACE_ID = 'o4loakmn752b';
const ACCESS_TOKEN = '4reeeSiwbJltOiU0S6y6hgcj64DSZpL21FjNAxKwOr8';
const INSURANCES_ACCEPTED_ENTRY_ID = '7aE5pWzzG0d7ef9ZVsa01R';

export const client = createClient({
  space: SPACE_ID,
  accessToken: ACCESS_TOKEN,
});

export function getCollectionFields<T>(collection: EntryCollection<T> | undefined) {
  if (collection) {
    return collection?.items?.map(item => {
      return item?.fields;
    });
  }
}

type QueryParam = { [x: string]: string | boolean | null | undefined | any[] | number };

export function useContentfulData<T>(
  contentType: string,
  limit = 1000,
  order = '',
  params: QueryParam = {},
): [EntryCollection<T> | undefined, boolean, (params: QueryParam) => void] {
  const [data, setData] = useState<EntryCollection<T> | undefined>();
  const [loading, setLoading] = useState<boolean>(true);

  //async function for fetching again
  const fetchContentfulData = (currentParams: QueryParam) => {
    client
      .getEntries<T>({ content_type: contentType, limit: limit, order: order, ...currentParams })
      .then(entries => {
        setData(entries);
      })
      .catch(error => {
        Sentry.captureException(error);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    client.getEntries<T>({ content_type: contentType, limit: limit, order: order, ...params }).then(entries => {
      setData(entries);
      setLoading(false);
    });
  }, []);
  return [data, loading, fetchContentfulData];
}

export const getAllContentfulDataFromContentType = async (
  { allEntries = [], skip = 0, order = '' }: Record<string, any> = {},
  content_type: string,
  currentParams: QueryParam = {},
): Promise<any> => {
  const results = await client.getEntries({
    content_type,
    limit: 1000,
    order: order,
    skip: skip,
    ...currentParams,
  });
  const entries = [...allEntries, ...results.items];
  return entries.length < results.total
    ? getAllContentfulDataFromContentType({ allEntries: entries, skip: skip + 1000 }, content_type, currentParams)
    : entries;
};

export const useGetAllContentfulDataFromContentType = (content_type: string) => {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  const onSuccess = (response: any) => setData(response);
  const onError = (error: any) => Sentry.captureException(error);
  useEffect(() => {
    getAllContentfulDataFromContentType({}, content_type)
      .then(onSuccess, onError)
      .finally(() => setLoading(false));
  }, []);

  return [data, loading];
};

/**
 * @desc a fetch hook for getting a single entry from contentful based on the entry id
 * @param entryId
 * @param limit
 * @param order
 * @param params
 * @returns
 */
export function useContentfulEntry<T>(entryId: string): [Entry<T> | undefined, boolean, () => void] {
  const [data, setData] = useState<Entry<T> | undefined>();
  const [loading, setLoading] = useState<boolean>(true);

  //eslint-disable-next-line
  const catchException = (error: any) => Sentry.captureException(error);
  const fetchContentfulData = () => {
    client
      .getEntry<T>(entryId)
      .then(entry => {
        setData(entry);
      })
      .catch(catchException)
      .finally(() => setLoading(false));
  };

  return [data, loading, fetchContentfulData];
}

export { INSURANCES_ACCEPTED_ENTRY_ID };
