import { useQuery } from 'react-query';
import { dynamicHttp } from '@expressable/utils';
import { InsurancePayer } from 'domain/insurance/types';
import { InsurancePayerOption } from 'types';

const httpHealthRecord = dynamicHttp('health-record');

export const getClientInsurancePayersOptions = async (): Promise<InsurancePayerOption[]> => {
  const { data } = await httpHealthRecord.get('/clients/insurance-payers/');

  const options = data.map((item: InsurancePayer) => ({
    id: item.payerUUID,
    label: item.payerName,
    value: item.payerID,
  }));

  return options;
};

export function useClientInsurancePayers() {
  return useQuery(
    ['clients-insurance-payers'],
    () => getClientInsurancePayersOptions(),
    { refetchOnWindowFocus: false }
  );
}
