import { useMutation, useQuery, useQueryClient } from 'react-query';
import { dynamicHttp } from '@expressable/utils';
import { useToasts } from 'react-toast-notifications';
import { presentMutationError } from '@expressable/ui-library';

const httpHealthRecordProxy = dynamicHttp('health-record-proxy');

export interface GetDevelopmentalDelaysPayload {
  present: boolean;
  severity?: string;
}

export interface SetDevelopmentalDelaysPayload {
  clientId: string;
  present: boolean;
  severity?: string;
  shouldLogEvent: boolean;
}

const getDevelopmentalDelays = async (clientId: string) => {
  const { data } = await httpHealthRecordProxy.get(`/care-plans/delays-disabilities/${clientId}`);
  return data;
};

export function useGetDevelopmentalDelays({ clientId }: { clientId: string }) {
  return useQuery<GetDevelopmentalDelaysPayload | null>(['care-plan-delays-disabilities', clientId], () =>
    getDevelopmentalDelays(clientId),
  );
}

const setDevelopmentalDelays = async ({ clientId, ...rest }: SetDevelopmentalDelaysPayload) => {
  return httpHealthRecordProxy.post(`/care-plans/delays-disabilities/${clientId}`, rest).then(res => res.data);
};

export function useSetDevelopmentalDelays() {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(setDevelopmentalDelays, {
    onSuccess: (_, payload) => {
      addToast('Delays/Disabilities Successfully Updated', { appearance: 'success', autoDismiss: true });
      queryClient.invalidateQueries(['care-plan-delays-disabilities', payload.clientId]);
    },
    onError: presentMutationError,
  });
}
