import { formatFrequency } from 'pages/client/components/client-plan-of-care-form';
import { useEffect, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useGetPlanOfCareTotalVisits } from './use-care-plan-total-visits';
import { useGetVisitFrequency } from './use-care-plan-visit-frequency';
import {
  EvaluationEntriesIds,
  useEvaluationContentfulSelectOptions,
  useEvaluationContentfulString,
} from './use-evaluation-contentful-provider';
import { useGetPlanOfCareVisitDuration } from './use-care-plan-duration-visits';
import { useQueryClient } from 'react-query';

export default function useEvaluationConclusions() {
  const { setValue } = useFormContext();
  const { clientId } = useParams<{ clientId: string }>();
  const queryClient = useQueryClient();

  const { data: visitFrequency, isLoading: isLoadingFrequencies } = useGetVisitFrequency({ clientId });
  const { data: durationVisits } = useGetPlanOfCareVisitDuration({ clientId });
  const { data: totalVisits } = useGetPlanOfCareTotalVisits({ clientId });

  const teletherapyAppropiateOptions = useEvaluationContentfulSelectOptions(EvaluationEntriesIds.TeletherapyAppropiate);
  const noRecommendedAssessments = useEvaluationContentfulString(EvaluationEntriesIds.NoFurtherAssessmentConclusions);

  const watchAreSpeechTherapyServicesRecommended = useWatch({
    name: 'areSpeechTherapyServicesRecommended',
  });

  const watchAreFurtherAssessmentsRecommended = useWatch({
    name: 'areFurtherAssessmentsRecommended',
  });

  const watchTeletherapyAppropiateOptions = useWatch({ 
    name: 'isTeletherapyAppropriate' 
  });

  const isSpeechTherapyRecommended = useMemo(() => {
    return watchAreSpeechTherapyServicesRecommended?.relevant === 'yes';
  }, [watchAreSpeechTherapyServicesRecommended]);

  const isTeletherapyTherapyRecommended = useMemo(() => {
    return watchTeletherapyAppropiateOptions?.value === 'Teletherapy services have been deemed appropriate for this client.';
  }, [watchTeletherapyAppropiateOptions]);    

  const recommendations = useMemo(() => {
    if (isLoadingFrequencies) return '';
    
    if (watchTeletherapyAppropiateOptions?.value === "Teletherapy services have been deemed inappropriate for this client.") {
      return watchTeletherapyAppropiateOptions?.value;
    }
    
    return `We recommend that client receive skilled speech-language intervention ${formatFrequency(
      visitFrequency!,
    )} for ${durationVisits?.duration ?? 'N/A'} minutes session for a total of ${
      totalVisits?.totalVisits ?? 'N/A'
    } during the Plan of Care in order to effectively communicate wants and needs and participate in activities of daily living. Services provided by Expressable will be delivered via telepractice by a certified and licensed speech-language pathologist. ${watchTeletherapyAppropiateOptions?.value ?? 'N/A'}`;
  }, [isSpeechTherapyRecommended, visitFrequency, durationVisits, totalVisits, watchTeletherapyAppropiateOptions]);

  useEffect(() => {
    if (isSpeechTherapyRecommended) {
      queryClient.invalidateQueries(['care-plan-start-date', clientId]);
      queryClient.invalidateQueries(['care-plan-end-date', clientId]);
      queryClient.invalidateQueries(['care-plan-visit-duration', clientId]);
      if (!watchTeletherapyAppropiateOptions) {
        setValue('isTeletherapyAppropriate', { value: 'Teletherapy services have been deemed appropriate for this client.', label: 'Teletherapy services have been deemed appropriate for this client.' });
      }      
    }
  }, [isSpeechTherapyRecommended]);

  useEffect(() => {
    setValue('clinicalRecommendations', recommendations);
  }, [visitFrequency, durationVisits, totalVisits, watchTeletherapyAppropiateOptions]);

  useEffect(() => {
    if (watchAreFurtherAssessmentsRecommended?.relevant === 'no') {
      setValue('recommendedAssessments', noRecommendedAssessments);
    }
  }, [watchAreFurtherAssessmentsRecommended]);

  return {
    isSpeechTherapyRecommended,
    recommendations,
    teletherapyAppropiateOptions,
    isTeletherapyTherapyRecommended,
  };
}
