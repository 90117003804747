import { useMutation, useQueryClient } from 'react-query';
import { http } from '@expressable/utils';
import { useToasts } from 'react-toast-notifications';
import { presentMutationError } from '@expressable/ui-library';

export interface CareTeamPayload {
  careTeam: {
    primary?: {
      therapistName: string;
      therapistEmail: string;
    };
  };
  team?: [] | null;
  clientId: string;
}

const updateCareTeam = async (careTeamPayload: CareTeamPayload) => {
  const { clientId, careTeam } = careTeamPayload;
  if (typeof careTeam.primary === 'undefined') {
    console.error(' Primary care team should be defined ');
    return false;
  }
  return http.post(`/clients/${clientId}/care-team`, careTeam).then(res => res.data);
};

export default function useUpdateCareTeam() {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(updateCareTeam, {
    onSuccess: (_, UpdatedCareTeam) => {
      // Query Invalidations
      addToast('Client Information Successfully Updated', { appearance: 'success', autoDismiss: true });
      queryClient.invalidateQueries(['client-team', UpdatedCareTeam.clientId]);
      queryClient.invalidateQueries(['client', UpdatedCareTeam.clientId]);
    },
    onError: presentMutationError,
  });
}
