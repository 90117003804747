import React from 'react';
import { DefaultToast } from 'react-toast-notifications';

enum CustomToastSize {
  Small = 'small',
  Large = 'large',
}

enum CustomToastWidth {
  Small = 320,
  Large = 420,
}

interface ContentWithLinkProps {
  content: { start: string, end: string };
  link: string;
}

interface CustomToastProps {
  size?: CustomToastSize;
}

const ContentWithLink = ({ link, content }: ContentWithLinkProps) => (
  <>
    {content.start}
    <a
      href={link}
      rel="noreferrer"
      style={{ textDecoration: 'underline' }}
      target='_blank'
    >
      {link}
    </a>
    {content.end}
  </>
);

export const CustomToast: React.FC<CustomToastProps> = ({ children, size, ...props }) => {
  const width = size == 'small' ? CustomToastWidth.Small : ( size == 'large' ? CustomToastWidth.Large : undefined );
  const widthPx = width && `${width}px`;
  const contentWithLinkProps = {
    content: {
      start: '',
      end: '',
    },
    link: '',
  };
  let includesLink = false;

  if (children && typeof children == 'string') {
    const content = children?.toString();

    if (content.includes('http')) {
      const indexStart = content.indexOf('http');
      const indexEnd = content.indexOf(' ', indexStart);

      includesLink = true;
      contentWithLinkProps.content = {
        start: content.slice(0, indexStart),
        end: content.slice(indexEnd),
      };
      contentWithLinkProps.link = content.substring(indexStart, indexEnd);
    }
  }

  return (
    <DefaultToast {...props} style={{ width: widthPx }}>
      {includesLink ? <ContentWithLink {...contentWithLinkProps} /> : children}
    </DefaultToast>
  );
};
