import React, { SyntheticEvent } from 'react';
import { Textarea, Button } from '@expressable/ui-library';
import { useCreateNote, useEditNote } from 'hooks/use-notes';
import 'twin.macro';
import { useDismissPendingAppointment } from 'hooks/use-pending-appointments';
import { onNoteSubmit, BasicNoteFormProps } from '.';
import { Chart } from 'types';

export interface ChartNoteFormProps extends BasicNoteFormProps {}

export const ChartNoteForm = (props: ChartNoteFormProps) => {
  const { setActivity, focusElementRef } = props;
  const activity = props.activity as Chart;
  const createNoteMutation = useCreateNote();
  const { mutateAsync: createNote } = createNoteMutation;
  const editNoteMutation = useEditNote();
  const { mutateAsync: editNote } = editNoteMutation;
  const dismissPendingAppointmentMutation = useDismissPendingAppointment();
  const { mutateAsync: dismissPendingAppointment } = dismissPendingAppointmentMutation;

  const noteContent = activity.note.content || '';

  return (
    <form
      onSubmit={(event: SyntheticEvent) => {
        event.preventDefault();
        onNoteSubmit(createNote, editNote, props.activity!, props, dismissPendingAppointment, { autoSaving: false });
      }}
      data-testid="chart-note-form"
    >
      <div tw="px-4" data-testid="chart-note-form-text-area">
        <Textarea
          ref={focusElementRef}
          tw="w-full mt-4"
          rows={4}
          value={noteContent}
          onChange={e => setActivity({ ...activity, note: { content: e.target.value } })}
          required
          placeholder="Enter your note here"
        />
      </div>
      <div tw="py-3 px-4 bottom-0 bg-white border-t border-gray-200">
        <Button
          data-testid="chart-note-form-save"
          variant="primary"
          loading={
            dismissPendingAppointmentMutation.isLoading || editNoteMutation.isLoading || createNoteMutation.isLoading
          }
          type="submit"
        >
          Save
        </Button>
      </div>
    </form>
  );
};
