import React from 'react';
import Table from 'components/Table';
import { Card, Container, FormGroup, Label, Link, Loader, Select, useDisclosure } from '@expressable/ui-library';
import { faChevronLeft, faChevronRight, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  useAdminPendingReschedules,
  useClientPendingReschedules,
  useTherapistPendingReschedules,
} from 'hooks/use-pending-reschedule';
import { SelectOption } from 'types';
import { usePagination } from 'hooks/use-pagination';
import { CancelPendingRescheduleModal } from './pending-reschedules-modals/cancel-pending-reschedules-modal';
import { ReschedulePendingRescheduleModal } from './pending-reschedules-modals/reschedule-pending-reschedules-modal';
import { PendingReschedule, PendingReschedulesProps } from 'domain/pending-reschedule/types';
import useTherapists from 'hooks/use-therapists';
import dayjs from 'dayjs';
import cx from 'classnames';
import 'twin.macro';
import { useFlags } from 'launchdarkly-react-client-sdk';

const PendingReschedules = (props: PendingReschedulesProps) => {
  const { isAdminPage, isClientPage, isTherapistPage, clientId, therapistId } = props;
  const [currentPendingReschedule, setCurrentPendingReschedule] = React.useState<PendingReschedule>();
  const [therapistSelected, setTherapistSelected] = React.useState<{ [key: string]: string }>({
    value: 'all',
    label: 'All Therapists',
  });
  const {
    data: adminPendingReschedules,
    isLoading: isLoadingAdminPendingReschedules,
    isError: isAdminPendingReschedulesError,
  } = useAdminPendingReschedules(isAdminPage, therapistSelected.value);

  const {
    data: clientPendingReschedules,
    isLoading: isLoadingClientPendingReschedules,
    isError: isClientPendingReschedulesError,
  } = useClientPendingReschedules(clientId as string);

  const {
    data: therapistPendingReschedules,
    isLoading: isLoadingTherapistPendingReschedules,
    isError: isTherapistPendingReschedulesError,
  } = useTherapistPendingReschedules(therapistId as string);

  const { data: therapistsList } = useTherapists();
  const therapistsSelectOptions: SelectOption[] | undefined = therapistsList?.map(therapist => ({
    label: therapist.therapistName,
    value: therapist.therapistID,
  }));

  const therapistsOptions = therapistsSelectOptions
    ? [{ value: 'all', label: 'All Therapists' }, ...therapistsSelectOptions]
    : [{ value: 'all', label: 'All Therapists' }];

  const getPendingReschedulesByRole = (isAdminPage: boolean, isClientPage: boolean, isTherapistPage: boolean) => {
    if (isAdminPage) {
      return adminPendingReschedules;
    }
    if (isClientPage) {
      return clientPendingReschedules;
    }
    if (isTherapistPage) {
      return therapistPendingReschedules;
    }
  };

  const paginatedEntries = usePagination(getPendingReschedulesByRole(isAdminPage, isClientPage, isTherapistPage), {
    perPage: 20,
  });

  const {
    isOpen: CancelPendingRescheduleModalIsOpen,
    onClose: CancelPendingRescheduleOnClose,
    onOpen: CancelPendingRescheduleOnOpen,
  } = useDisclosure();

  const {
    isOpen: ReschedulePendingRescheduleModalIsOpen,
    onClose: ReschedulePendingRescheduleOnClose,
    onOpen: ReschedulePendingRescheduleOnOpen,
  } = useDisclosure();

  const { cueSchedulingControl: displayBookingCapability } = useFlags();

  if (isAdminPendingReschedulesError || isClientPendingReschedulesError || isTherapistPendingReschedulesError) {
    return (
      <Container data-testid="pending-reschedules-error" tw="relative pl-0" size="large">
        <div data-testid="pending-reschedules-error-title" tw="flex justify-between items-center flex-wrap">
          <h2 tw="text-2xl font-semibold mb-3">Pending Reschedules</h2>
        </div>
        <Card tw="mb-4 px-8 mt-8">
          <FormGroup tw="w-full sm:w-1/2 md:w-1/3">
            <Label>
              <FontAwesomeIcon icon={faExclamationTriangle} tw="text-red-500 mr-2" />
              <span tw="text-red-500">Pending Reschedules are not available at this time.</span>
            </Label>
          </FormGroup>
        </Card>
      </Container>
    );
  }

  return (
    <div>
      <div tw="md:pr-5" data-testid="pending-reschedules">
        <div tw="flex justify-between items-center flex-wrap">
          <h2 tw="text-2xl font-semibold mb-3">Pending Reschedules</h2>
        </div>
        <Card tw="mt-2">
          {isLoadingAdminPendingReschedules ||
          isLoadingClientPendingReschedules ||
          isLoadingTherapistPendingReschedules ? (
            <div data-testid="pending-reschedules-loader" tw="flex-1 text-center py-20">
              <Loader type="ring" />
            </div>
          ) : (
            <>
              {isAdminPage && (
                <div tw="mb-4 sm:mb-0">
                  <div tw="flex flex-col sm:flex-row mb-4">
                    <>
                      <span tw="text-sm font-semibold mt-6 ml-2 hidden sm:block">View</span>
                      <div tw="flex items-center sm:items-stretch justify-items-stretch mr-2 ml-2 mt-4 font-normal">
                        <span tw="text-sm font-semibold mr-2 block sm:hidden">View</span>
                        <Select
                          tw="w-full sm:w-96"
                          id="pendingRescheduleTherapist"
                          placeholder=""
                          spacing="tight"
                          value={therapistSelected}
                          options={therapistsOptions}
                          onChange={(selectOption: { [key: string]: string }) => {
                            setTherapistSelected(selectOption);
                          }}
                        />
                      </div>
                      <span
                        onClick={() => {
                          const defaultOption = { value: 'all', label: 'All Therapists' };
                          setTherapistSelected(defaultOption);
                        }}
                        className={cx('text-sm ml-3 mt-6 text-indigo-700 font-normal hidden sm:block', {
                          'opacity-100': therapistSelected.value !== 'all',
                          'opacity-50': therapistSelected.value === 'all',
                          'cursor-pointer': therapistSelected.value !== 'all',
                        })}
                      >
                        View All Therapists
                      </span>
                    </>
                  </div>
                </div>
              )}

              {paginatedEntries.items?.length ? (
                <>
                  <Table>
                    <Table.Header>
                      <Table.Row>
                        <Table.Cell>Task Added</Table.Cell>
                        {!isClientPage && <Table.Cell>Client Name</Table.Cell>}
                        <Table.Cell tw="hidden sm:table-cell">Original Appointment Date</Table.Cell>
                        {!isTherapistPage && <Table.Cell>Therapist</Table.Cell>}
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {paginatedEntries.items?.map(pendingReschedule => (
                        <Table.Row key={pendingReschedule.pendingRescheduleID}>
                          <Table.Cell tw="text-sm md:text-base">
                            {dayjs(pendingReschedule.createdAt).format('MM/DD/YYYY')}&nbsp;
                            <span tw="ml-0 md:ml-2 hidden lg:inline">-</span>
                            <span tw="ml-0 md:ml-2 hidden lg:inline">
                              {dayjs(pendingReschedule.createdAt).format('h:mma')}
                            </span>
                          </Table.Cell>
                          {!isClientPage && (
                            <Table.Cell tw="text-indigo-700 font-semibold text-sm md:text-base">
                              <Link
                                target="_blank"
                                to={{ pathname: `/clients/${pendingReschedule.clientID}/#pending-reschedules` }}
                              >
                                {pendingReschedule.clientFirstName} {pendingReschedule.clientLastName}&nbsp;
                              </Link>
                            </Table.Cell>
                          )}
                          <Table.Cell tw="text-sm md:text-base hidden sm:table-cell">
                            {dayjs(pendingReschedule.originalAcuityAppointment.datetime).format('MM/DD/YYYY')}
                            <span tw="ml-0 md:ml-2 hidden lg:inline">-</span>
                            <span tw="ml-0 md:ml-2 hidden lg:inline">
                              {pendingReschedule.originalAcuityAppointment.time}
                            </span>
                          </Table.Cell>
                          {!isTherapistPage && (
                            <Table.Cell tw="text-indigo-700 font-semibold text-sm md:text-base">
                              <Link
                                target="_blank"
                                to={{
                                  pathname: `/therapists/${pendingReschedule?.therapistEmail}/#pending-reschedules`,
                                }}
                              >
                                {pendingReschedule?.therapistFirstName} {pendingReschedule?.therapistLastName}&nbsp;
                              </Link>
                            </Table.Cell>
                          )}
                          {displayBookingCapability && (
                            <Table.Cell tw="text-indigo-700 cursor-pointer font-medium text-xs md:text-sm flex flex-col lg:flex-row justify-end">
                              <span
                                onClick={() => {
                                  ReschedulePendingRescheduleOnOpen();
                                  setCurrentPendingReschedule(pendingReschedule);
                                }}
                                tw="mr-6"
                              >
                                Reschedule
                              </span>
                              <span
                                onClick={() => {
                                  CancelPendingRescheduleOnOpen();
                                  setCurrentPendingReschedule(pendingReschedule);
                                }}
                              >
                                Cancel
                              </span>
                            </Table.Cell>
                          )}
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                  <div tw="font-medium flex mt-5 justify-center sm:justify-end">
                    <span tw="mr-2">{`Showing ${paginatedEntries.pageStartIdx + 1} to ${
                      paginatedEntries.pageEndIdx
                    } of ${paginatedEntries.totalItems} results`}</span>
                    {paginatedEntries.page !== 0 && (
                      <span onClick={paginatedEntries.prev}>
                        <FontAwesomeIcon
                          tw="text-indigo-700 ml-2 mr-2 cursor-pointer flex mt-2 text-xs"
                          icon={faChevronLeft}
                        />
                      </span>
                    )}
                    {paginatedEntries.page !== paginatedEntries.totalPages - 1 && (
                      <span>
                        <FontAwesomeIcon
                          tw="text-indigo-700 ml-2 mr-2 cursor-pointer flex mt-2 text-xs"
                          icon={faChevronRight}
                          onClick={paginatedEntries.next}
                        />
                      </span>
                    )}
                  </div>
                </>
              ) : (
                <span tw="ml-2">There are no pending reschedules to show.</span>
              )}
            </>
          )}
        </Card>
      </div>
      {CancelPendingRescheduleModalIsOpen && (
        <CancelPendingRescheduleModal
          isOpen={CancelPendingRescheduleModalIsOpen}
          onClose={() => {
            CancelPendingRescheduleOnClose();
          }}
          currentPendingReschedule={currentPendingReschedule}
        />
      )}
      {ReschedulePendingRescheduleModalIsOpen && (
        <ReschedulePendingRescheduleModal
          isOpen={ReschedulePendingRescheduleModalIsOpen}
          onClose={() => {
            ReschedulePendingRescheduleOnClose();
          }}
          currentPendingReschedule={currentPendingReschedule}
        />
      )}
    </div>
  );
};

export default PendingReschedules;
