import { AppointmentOption } from 'components/appointment-modals';

export const appointmentLengthOptions = [
  { label: '15 Minutes', value: '15' },
  { label: '30 Minutes', value: '30' },
  { label: '45 Minutes', value: '45' },
  { label: '60 Minutes', value: '60' },
];

export const initialEvaluationLengthOptions = [
  { label: '45 Minutes', value: '45' },
  { label: '60 Minutes', value: '60' },
];

export const appointmentOptions: AppointmentOption[] = [
  { label: '15 Minutes', value: 15 },
  { label: '30 Minutes', value: 30 },
  { label: '45 Minutes', value: 45 },
  { label: '60 Minutes', value: 60 },
];

export const initialEvaluationOptions: AppointmentOption[] = [
  { label: '45 Minutes', value: 45 },
  { label: '60 Minutes', value: 60 },
];

export enum AppointmentType {
  Therapy = 'STS',
  Evaluation = 'IE',
}

export const appointmentTypeOptions = [
  { label: 'Speech Therapy Session', value: AppointmentType.Therapy },
  { label: 'Initial Evaluation', value: AppointmentType.Evaluation },
];

export enum SessionType {
  Recurring = 'recurring',
  Single = 'single',
}

export enum CancelledAppSource {
  Portal = 'portal',
  Cue = 'cue',
}

export const sessionTypeOptions = [
  { label: 'Recurring', value: SessionType.Recurring },
  { label: 'Single', value: SessionType.Single },
];

export enum ScheduleMethod {
  Link = 'link',
  Schedule = 'schedule',
}

export const scheduleMethodOptions = [
  { label: 'Send schedule link to client', value: ScheduleMethod.Link },
  { label: 'Schedule for client', value: ScheduleMethod.Schedule },
]

export enum AppointmentRecurrence {
  Recurring = 'RECURRING',
  NonRecurring = 'NON-RECURRING',
}

export enum User {
  Expressabot = 'Expressabot',
}
