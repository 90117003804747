import { Badge, Link, LoadingError, LoadingText, useDisclosure } from '@expressable/ui-library';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import React, { useEffect, useState } from 'react';
import tw from 'twin.macro';
import { Address, Contact, ICompleteClientInformation, Phone, ISingleAppointment, RecurringAppointment } from 'types';
import { IntakeShareModal, IntakeLinkModal, IntakeProvider } from 'components/intake-modals';
import {
  AddAppointmentsModal,
  EditAppointmentsModal,
  EditMultipleAppointmentsModal,
} from 'components/appointment-modals';
import { getMicroserviceBaseUrl, useCurrentUserContext, useFileToShare } from '@expressable/utils';
import { useFiles, useGenerateUploadUrl, useUploadFile } from 'hooks/use-files';
import { useAppointments, useResetCacheClient, useRecurringAppointments } from 'hooks/use-appointments';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { faShare } from '@fortawesome/pro-regular-svg-icons';

import { ShareLinkModal } from 'components/share-link-modal';
import useShareLink from 'hooks/use-share-link';
import { useToasts } from 'react-toast-notifications';
import { useGetDiagnoses } from 'hooks/use-care-plan-diagnoses';
import { useGetVisitFrequency } from 'hooks/use-care-plan-visit-frequency';
import { useBillingInfo } from 'hooks/use-billing-information';
import { useGetGoals } from 'hooks/use-care-plan-goals';
import usePermissions from 'hooks/use-permissions';
import { useGetCredits } from 'hooks/use-credits';
import { useTherapist } from 'hooks/use-therapists';
import { fieldIDforPatientID, scheduleUrlDomain, sessionTypeId } from 'domain/client/constants';
import * as fileHelper from 'domain/shared/file-helper';
import { getAmericanFormatDate } from 'utils/helpers';
import { useQueryClient } from 'react-query';
import { RescheduleMultipleAppointmentsModal } from 'components/appointment-modals/reschedule-multiple-appointments';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { EditRecurringAppointmentsModal } from 'components/appointment-modals/edit-recurring-appointments';
import CancelRecurringAppointmentsModal from 'components/appointment-modals/cancel-recurring-appointments';
import SlackClientSidebar from 'pages/contact/components/slack-client-sidebar';
import { ClientTab } from 'pages/client/client';
import { getAuthStatusLabel } from '../billing-info-read-only';
import { DeleteFileModal } from '../client-file-modal';
import { formatFrequency } from '../client-plan-of-care-form';
import { RecurringAppointments } from './recurring-appointments';
import { UpcomingAppointments } from './upcoming-appointments';
import { noop } from 'lodash';

/* eslint-disable */
// Cannot import this package. At some point, when compatable, we will import this package and remove the lint ignore.
const PhoneNumber = require('awesome-phonenumber');
/* eslint-enable */

dayjs.extend(utc);
dayjs.extend(timezone);

export const Heading = ({ children }: { children: React.ReactNode }) => (
  <div tw="mb-2 mt-8 text-base leading-none capitalize font-semibold">{children}</div>
);

export const SubHeading = ({ children }: { children: React.ReactNode }) => (
  <div tw="mt-5 text-sm leading-none capitalize font-semibold">{children}</div>
);

interface SidebarProps extends ICompleteClientInformation {
  clientId: string;
  setIndex: (index: ClientTab) => void;
  onAddDischarge: () => void;
}

export const getPrimaryContact = (contactInformation: Contact[] | undefined, clientId: string) => {
  let primaryContact = contactInformation?.find(contact => contact.primaryContact);
  if (typeof primaryContact == 'undefined') {
    if (typeof contactInformation != 'undefined') {
      primaryContact = contactInformation[0];
    }
  }
  return primaryContact;
};

const getSecondayContacts = (contactInformation: Contact[] | undefined) => {
  let secondaryContacts = contactInformation?.filter(contact => !contact.primaryContact);
  if (secondaryContacts?.length == contactInformation?.length) {
    secondaryContacts = contactInformation?.slice(1) ?? [];
  }
  return secondaryContacts;
};

const portalClientAppBaseUrl = process.env.REACT_APP_PORTAL_CLIENT_APP_URL ?? 'https://app.expressable.io';

export default function Sidebar(props: SidebarProps) {
  const {
    clientFirstName,
    clientLastName,
    careTeam,
    contactInformation,
    stripeCustomerID,
    clientId,
    setIndex,
    genderIdentity,
    preferredFirstName,
    sex,
    onAddDischarge,
  } = props;

  const queryClient = useQueryClient();
  const currentUser = useCurrentUserContext();
  const { data: billingInfoData, isError } = useBillingInfo(clientId);
  const { data: creditsData } = useGetCredits(clientId);
  const { data: upcomingAppointments, isLoading: areUpcomingAppointmentsLoading } = useAppointments(clientId);
  const { data: recurringAppointments } = useRecurringAppointments(clientId);
  const { data: diagnoses, isLoading: areDiagnosesLoading, isError: areDiagnosesError } = useGetDiagnoses({ clientId });
  const { data: goals } = useGetGoals({ clientId });
  const { data: therapistData } = useTherapist(careTeam.primary.therapistEmail);
  const { isAdminOrClinicalManager } = usePermissions();

  const futureUpcomingAppointments = upcomingAppointments?.filter(appointment =>
    dayjs().isBefore(appointment.appointmentDateTime),
  );

  const hasRecurringForever = recurringAppointments?.some(appointment => !appointment.endDate);
  const candidUrl = `https://app.joincandidhealth.com/b246e055-b861-4027-8581-634bab8df7ce/patients?patient_search_term=${billingInfoData?.clientID}`;

  const {
    data: visitFrequency,
    isLoading: isVisitFrequencyLoading,
    isError: isVisitFrequencyError,
  } = useGetVisitFrequency({ clientId });
  const primaryContact = getPrimaryContact(contactInformation, clientId);
  const secondaryContacts = getSecondayContacts(contactInformation);

  let contactTimeZone;
  let contactEmail!: string;
  let contactPhone!: string;
  let contactState!: string;

  if (primaryContact) {
    contactTimeZone = primaryContact.contactTimeZone;
    contactState = primaryContact?.address?.state ?? '';
    contactEmail = primaryContact.email;
    contactPhone = Object.values(primaryContact.phone ?? [])[0];
  }

  const {
    isOpen: AddAppointmentsModalIsOpen,
    onClose: AddAppointmentsModalOnClose,
    onOpen: AddAppointmentsModalOnOpen,
  } = useDisclosure();

  const {
    isOpen: IntakeShareModalIsOpen,
    onClose: IntakeShareModalOnClose,
    onOpen: IntakeShareModalOnOpen,
  } = useDisclosure();

  const {
    isOpen: EditAppointmentsModalIsOpen,
    onClose: EditAppointmentsModalOnClose,
    onOpen: EditAppointmentsModalOnOpen,
  } = useDisclosure();

  const {
    isOpen: EditMultipleAppointmentsModalIsOpen,
    onClose: EditMultipleAppointmentsModalOnClose,
    onOpen: EditMultipleAppointmentsModalOnOpen,
  } = useDisclosure();

  const {
    isOpen: isBulkRescheduleModalOpen,
    onClose: onCloseBulkRescheduleModal,
    onOpen: onOpenBulkRescheduleModal,
  } = useDisclosure();

  const {
    isOpen: EditRecurringAppointmentsModalIsOpen,
    onClose: EditRecurringAppointmentsModalOnClose,
    onOpen: EditRecurringAppointmentsModalOnOpen,
  } = useDisclosure();

  const {
    isOpen: CancelRecurringAppointmentsModalIsOpen,
    onClose: CancelRecurringAppointmentsModalOnClose,
    onOpen: CancelRecurringAppointmentsModalOnOpen,
  } = useDisclosure();

  const [editAppointmentData, setEditAppointmentData] = useState<ISingleAppointment | undefined>();
  const [editRecurringAppointmentData, setEditRecurringAppointmentData] = useState<RecurringAppointment | undefined>();
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [uploadedFileKey, setUploadedFileKey] = useState<string>('');
  const { data: uploadedFilesData } = useFiles(clientId);
  const { mutate: generateUploadUrl } = useGenerateUploadUrl();
  const { mutate: uploadFile } = useUploadFile();
  const { bulkCancelationsEnabled: isBulkActionsEnabled, cueSchedulingControl: displayBookingCapability } = useFlags();

  const { cueSlackConversation: isSlackConversationAvailable } = useFlags();

  const uploadEncodedFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsUploading(true);

    const filesSelected = event.target.files;

    if (filesSelected!.length > 0) {
      const fileToUpload = filesSelected![0];

      // it checks file content type
      if (!fileHelper.ALLOWED_CONTENT_TYPES.includes(fileToUpload.type)) {
        addToast(`The content type "${fileToUpload.type}" is not allowed.`, { appearance: 'error', autoDismiss: true });
        setIsUploading(false);
        return;
      }

      const fileReader = new FileReader();
      fileReader.onload = fileLoadedEvent => {
        const srcData = fileLoadedEvent.target!.result;
        const [mimeData, file] = (srcData as string).split(';');
        const [, mime] = mimeData.split(':');

        const generateUploadUrlPayload = {
          clientID: clientId,
          mime,
          fileName: fileHelper.getFileNameWithoutExtension(fileToUpload.name),
          fileExtension: fileHelper.getFileExtension(fileToUpload.name),
        };
        generateUploadUrl(generateUploadUrlPayload, {
          onSuccess: ({ key, url }) => {
            const imageData = file.substr(7, file.length);
            const buffer = Buffer.from(imageData, 'base64');
            uploadFile(
              { url, mime, buffer, clientID: clientId },
              {
                onSettled: () => {
                  setIsUploading(false);
                },
                onSuccess: () => {
                  setUploadedFileKey(key);
                },
              },
            );
          },
        });
      };

      fileReader.readAsDataURL(fileToUpload);
    }
  };

  const {
    isOpen: ShareLinkModalIsOpen,
    onClose: ShareLinkModalOnClose,
    onOpen: ShareLinkModalOnOpen,
  } = useDisclosure();

  const [fileSharableLink, setFileSharableLink] = useState<string>('');
  const [fireShareableError, setFileShareableError] = useState<boolean>(false);

  const { mutateAsync: shareLink, isLoading } = useShareLink();

  const { addToast } = useToasts();

  const shareFile = async (s3Link: string) => {
    setFileSharableLink('');
    ShareLinkModalOnOpen();

    try {
      const response = await shareLink(s3Link);
      const baseUrl = getMicroserviceBaseUrl('link-sharing');
      setFileSharableLink(`${baseUrl}/${response.key}`);
      setFileShareableError(false);
    } catch (error) {
      setFileSharableLink(s3Link);
      setFileShareableError(true);
      addToast('Something Went Wrong', { appearance: 'error', autoDismiss: true });
    }
  };

  if (uploadedFileKey && uploadedFilesData && uploadedFilesData.length > 0) {
    const uploadedFileData = uploadedFilesData.find(file => file.key === uploadedFileKey);
    if (uploadedFileData) {
      setUploadedFileKey('');
      shareFile(uploadedFileData.link);
    }
  }

  const [fileKeyToDelete, setFileKeyToDelete] = useState<string>('');

  const {
    isOpen: DeleteFileModalIsOpen,
    onClose: DeleteFileModalOnClose,
    onOpen: DeleteFileModalOnOpen,
  } = useDisclosure();

  // #region File to share
  const [fileToShare, setFileToShare] = useFileToShare();

  useEffect(() => {
    if (!fileToShare) {
      return;
    }

    if (!uploadedFilesData) {
      return;
    }
    const fileData = uploadedFilesData.find(f => f.file === fileToShare);
    if (!fileData) {
      return;
    }

    shareFile(fileData.link);
    setFileToShare(false);
  }, [fileToShare, uploadedFilesData]);
  // #endregion

  const yellowWarningAppointment =
    futureUpcomingAppointments?.length &&
    futureUpcomingAppointments?.length < 6 &&
    futureUpcomingAppointments?.length > 3;
  const redWarningAppointment =
    (futureUpcomingAppointments?.length && futureUpcomingAppointments?.length <= 3) ||
    futureUpcomingAppointments?.length == 0;

  const shouldDisplayYellowWarningOnVisitsLeft =
    billingInfoData?.insuranceAuthorizations?.remainingVisitsLeft &&
    billingInfoData?.insuranceAuthorizations?.remainingVisitsLeft < 6 &&
    billingInfoData?.insuranceAuthorizations?.remainingVisitsLeft > 3;
  const shouldDisplayRedWarningOnVisitsLeft =
    (billingInfoData?.insuranceAuthorizations?.remainingVisitsLeft &&
      billingInfoData?.insuranceAuthorizations?.remainingVisitsLeft <= 3) ||
    billingInfoData?.insuranceAuthorizations?.remainingVisitsLeft == 0;
  const shouldDisplayRedWarningOnOverdueDates = dayjs().isAfter(
    billingInfoData?.insuranceAuthorizations?.visitsCompletedBy,
  );

  const shouldDisplayRedWarningOnReEvaluationRequired =
    billingInfoData?.insuranceAuthorizations?.reEvaluationRequired &&
    billingInfoData.insuranceAuthorizations?.reEvaluationDueBy &&
    dayjs().isAfter(billingInfoData?.insuranceAuthorizations?.reEvaluationDueBy);

  const renderContact = (contact: Contact, key: number, contacts: Contact[]) => {
    const { address, faxPhone = '' } = contact;
    return (
      <div css={[contacts.length > 1 ? tw`border-b pb-2 border-gray-300` : ``]} key={key}>
        <SubHeading>
          <Link to={`/contacts/${contact.contactID}`} tw="text-sm font-semibold">
            {`${contact.contactFirstName} ${contact.contactLastName}`}
          </Link>
          <span>
            <Link onClick={() => setIndex(ClientTab.Contacts)} to="#contacts" tw="ml-2 text-xs font-medium">
              Edit
            </Link>
          </span>
        </SubHeading>
        <div>{contact.relationshipToClient}</div>
        {contact.phone && (contact.phone.mobile || contact.phone.work || contact.phone.home) && (
          <>
            <SubHeading>Phone</SubHeading>
            <PhoneRender phone={contact.phone} />
          </>
        )}
        {faxPhone && (
          <div className="mt-2 mb-2">
            <p className="font-semibold">Fax</p>
            <div> {new PhoneNumber(faxPhone).toJSON().number.national}</div>
          </div>
        )}
        {contact.email && (
          <>
            <SubHeading>Email</SubHeading>
            <div>{contact.email}</div>
          </>
        )}
        {primaryContact?.email == contact.email && primaryContact?.contactTimeZone && (
          <>
            <SubHeading>Time Zone</SubHeading>
            <span>{primaryContact.contactTimeZone}</span>
          </>
        )}
        {address && (
          <>
            <SubHeading>Address</SubHeading>
            <AddressRender {...address} />
          </>
        )}
        {isSlackConversationAvailable && (
          <SlackClientSidebar
            contactId={contact.contactID}
            useSlackConversations={!!therapistData?.useSlackConversations}
          />
        )}
      </div>
    );
  };
  const currentShortTermGoals = goals?.filter(
    goal => goal.goalType === 'stg' && (goal.status === 'progressing' || goal.status === 'modified'),
  );
  const currentLongTermGoals = goals?.filter(
    goal => goal.goalType === 'ltg' && (goal.status === 'progressing' || goal.status === 'modified'),
  );

  const onClickCopyScheduleLink = () => {
    navigator.clipboard
      .writeText(
        `https://${scheduleUrlDomain}/schedule.php?calendarID=${
          therapistData?.acuityCalendarID
        }&appointmentType=${sessionTypeId}&field:${fieldIDforPatientID}=${clientId}&firstName=${clientFirstName}&lastName=${clientLastName}&phone=${contactPhone}&email=${encodeURIComponent(
          contactEmail as string,
        )}`,
      )
      .then(() =>
        addToast('Schedule link successfully copied', {
          appearance: 'success',
          autoDismiss: true,
        }),
      )
      .catch(() =>
        addToast('Something Went Wrong when copying to clipboard', {
          appearance: 'error',
          autoDismiss: true,
        }),
      );
  };

  useEffect(() => {
    queryClient.invalidateQueries();
  }, [careTeam]);

  const { mutate: resetCache, isLoading: resetCacheIsLoading } = useResetCacheClient();

  return (
    <div tw="text-sm">
      <Heading>
        Care Team
        <Link
          onClick={() => {
            setIndex(ClientTab.ClientInfo);
            window.scrollTo(0, document.body.scrollHeight);
          }}
          tw="ml-3 text-xs font-medium"
          to="#client-info"
        >
          Edit
        </Link>
      </Heading>
      <Link to={`/therapists/${careTeam?.primary?.therapistEmail}`} tw="text-sm font-semibold">
        {careTeam?.primary?.therapistName}
      </Link>
      <Badge tw="ml-2" variant="success">
        Primary
      </Badge>
      <Heading tw="mt-8">Client Information</Heading>
      {preferredFirstName && (
        <>
          <SubHeading> Preferred First Name </SubHeading>
          <div>{preferredFirstName}</div>
        </>
      )}
      {sex && (
        <>
          <SubHeading>Sex </SubHeading>
          <div>{`${sex == 'F' ? 'Female' : 'Male'}`}</div>
        </>
      )}
      {genderIdentity && (
        <>
          <SubHeading>Gender Identity</SubHeading>
          <div>{`${genderIdentity}`}</div>
        </>
      )}

      {creditsData && (
        <>
          <SubHeading>Credits Remaining</SubHeading>
          <div tw="mt-2">{creditsData.credits}</div>
        </>
      )}

      {billingInfoData?.insuranceAuthorizations && (
        <>
          <Heading tw="mt-8">
            Authorizations
            {isAdminOrClinicalManager && (
              <span>
                <Link onClick={() => setIndex(ClientTab.BillingInfo)} to="#billing-info" tw="ml-3 text-xs font-medium">
                  Edit
                </Link>
              </span>
            )}
          </Heading>
          {billingInfoData?.insuranceAuthorizations?.evaluationAuthStatus && (
            <>
              <SubHeading>
                <span
                  css={[
                    billingInfoData?.insuranceAuthorizations?.evaluationAuthStatus === 'denied' &&
                      tw`text-red-500 font-semibold`,
                  ]}
                  tw="leading-none font-semibold"
                >
                  Evaluation Authorization Status
                </span>
              </SubHeading>
              <div tw="mt-2">
                <span
                  css={[
                    billingInfoData?.insuranceAuthorizations?.evaluationAuthStatus === 'denied' && tw`text-red-500`,
                  ]}
                >
                  {getAuthStatusLabel(billingInfoData?.insuranceAuthorizations?.evaluationAuthStatus)}
                </span>
              </div>
            </>
          )}
          {billingInfoData?.insuranceAuthorizations?.sessionAuthStatus && (
            <>
              <SubHeading>
                <span
                  css={[
                    billingInfoData?.insuranceAuthorizations?.sessionAuthStatus === 'denied' &&
                      tw`text-red-500 font-semibold`,
                  ]}
                  tw="leading-none font-semibold"
                >
                  Session Authorization Status
                </span>
              </SubHeading>
              <div tw="mt-2">
                <span
                  css={[billingInfoData?.insuranceAuthorizations?.sessionAuthStatus === 'denied' && tw`text-red-500`]}
                >
                  {getAuthStatusLabel(billingInfoData?.insuranceAuthorizations?.sessionAuthStatus)}
                </span>
              </div>
            </>
          )}
          {billingInfoData?.insuranceAuthorizations?.visitLimits && (
            <>
              {billingInfoData?.insuranceAuthorizations?.remainingVisitsLeft === 0 ? (
                <SubHeading>
                  <span tw="leading-none font-semibold text-red-500">Visits Remaining</span>
                </SubHeading>
              ) : (
                <SubHeading>Visits Remaining</SubHeading>
              )}
              <div tw="mt-2">
                <span
                  css={[
                    (shouldDisplayYellowWarningOnVisitsLeft &&
                      tw`px-2 text-yellow-700 bg-yellow-100 font-semibold rounded-full pl-2`) ||
                      (shouldDisplayRedWarningOnVisitsLeft &&
                        tw`px-2 text-red-500  bg-red-100 font-semibold rounded-full pl-2`),
                  ]}
                >
                  {billingInfoData?.insuranceAuthorizations?.remainingVisitsLeft} left
                </span>
              </div>
            </>
          )}
          {billingInfoData?.insuranceAuthorizations?.visitsCompletedBy && (
            <>
              {shouldDisplayRedWarningOnOverdueDates ? (
                <SubHeading>
                  <span tw="leading-none font-semibold text-red-500">Visits Completed By</span>
                </SubHeading>
              ) : (
                <SubHeading>Visits Completed By</SubHeading>
              )}
              <div tw="mt-2">
                <span css={[shouldDisplayRedWarningOnOverdueDates && tw`leading-none text-red-500`]}>
                  {getAmericanFormatDate(billingInfoData?.insuranceAuthorizations?.visitsCompletedBy)}
                </span>
              </div>
            </>
          )}
          {billingInfoData?.insuranceAuthorizations?.reEvaluationRequired && (
            <>
              {shouldDisplayRedWarningOnReEvaluationRequired ? (
                <SubHeading>
                  <span tw="leading-none font-semibold text-red-500">Re-Evaluation Required</span>
                </SubHeading>
              ) : (
                <SubHeading>Re-Evaluation Required</SubHeading>
              )}
              <div tw="mt-2">
                <span css={[shouldDisplayRedWarningOnReEvaluationRequired && tw`leading-none text-red-500`]}>
                  {getAmericanFormatDate(billingInfoData?.insuranceAuthorizations?.reEvaluationDueBy)}
                </span>
              </div>
            </>
          )}
        </>
      )}

      <div className="mb-5">
        {redWarningAppointment ? (
          <Heading tw="mt-3">
            <div tw="mb-2 mt-8 text-base leading-none capitalize font-semibold text-red-500 mr-2">
              Client Schedule
              <span tw=" px-2 bg-red-500 rounded-full text-white ml-2">!</span>
            </div>

            {displayBookingCapability && (
              <Link
                to="#"
                data-testid="appointment"
                className="cursor-pointer text-indigo-600 inline-block sm:mt-2 font-medium  text-xs normal-case"
                onClick={AddAppointmentsModalOnOpen}
              >
                <span className="text-lg ">+</span> Add new appointment
              </Link>
            )}
          </Heading>
        ) : (
          <Heading tw="mt-3">
            <span className="mr-8 lg:mr-4">Client Schedule</span>
            {displayBookingCapability && (
              <Link
                to="#"
                className="cursor-pointer text-indigo-600 inline-block sm:mt-2 font-medium  text-xs normal-case"
                onClick={AddAppointmentsModalOnOpen}
              >
                <span className="text-lg ">+</span> Add new appointment
              </Link>
            )}
          </Heading>
        )}
      </div>
      {AddAppointmentsModalIsOpen && (
        <AddAppointmentsModal
          clientFirstName={clientFirstName}
          preferredFirstName={preferredFirstName}
          clientLastName={clientLastName}
          contactEmail={contactEmail}
          contactPhone={contactPhone}
          contactTimeZone={contactTimeZone}
          contactState={contactState}
          careTeam={careTeam}
          clientId={clientId}
          onClose={AddAppointmentsModalOnClose}
          isOpen={AddAppointmentsModalIsOpen}
        />
      )}
      {futureUpcomingAppointments && EditMultipleAppointmentsModalIsOpen && (
        <EditMultipleAppointmentsModal
          onClose={EditMultipleAppointmentsModalOnClose}
          isOpen={EditMultipleAppointmentsModalIsOpen}
          upcomingAppointments={futureUpcomingAppointments}
          clientId={clientId}
        />
      )}
      {futureUpcomingAppointments && isBulkRescheduleModalOpen && (
        <RescheduleMultipleAppointmentsModal
          onClose={onCloseBulkRescheduleModal}
          isOpen={isBulkRescheduleModalOpen}
          upcomingAppointments={futureUpcomingAppointments}
          clientId={clientId}
          onAddDischarge={onAddDischarge}
          therapistData={therapistData}
        />
      )}
      {EditRecurringAppointmentsModalIsOpen && editRecurringAppointmentData && futureUpcomingAppointments && (
        <EditRecurringAppointmentsModal
          onClose={EditRecurringAppointmentsModalOnClose}
          isOpen={EditRecurringAppointmentsModalIsOpen}
          editRecurringAppointmentData={editRecurringAppointmentData}
          contactState={contactState}
          openCancelModal={CancelRecurringAppointmentsModalOnOpen}
          isCancelAllowed={displayBookingCapability}
        />
      )}

      {CancelRecurringAppointmentsModalIsOpen && editRecurringAppointmentData && (
        <CancelRecurringAppointmentsModal
          onClose={CancelRecurringAppointmentsModalOnClose}
          isOpen={CancelRecurringAppointmentsModalIsOpen}
          recurringAppointmentData={editRecurringAppointmentData}
          clientFirstName={clientFirstName}
          clientLastName={clientLastName}
        />
      )}

      {futureUpcomingAppointments ? (
        <>
          <ul className="list-none text-indigo-600 text-xs font-semibold space-y-2 mb-5">
            {displayBookingCapability && isBulkActionsEnabled && futureUpcomingAppointments.length > 1 && (
              <li
                className="cursor-pointer"
                data-testid="bulk-reschedule-appointment"
                onClick={onOpenBulkRescheduleModal}
                onMouseOver={noop}
              >
                Reschedule multiple appointments
              </li>
            )}
            {displayBookingCapability && isBulkActionsEnabled && futureUpcomingAppointments.length > 1 && (
              <li className="cursor-pointer" onClick={EditMultipleAppointmentsModalOnOpen}>
                Cancel multiple appointments
              </li>
            )}
          </ul>
          {recurringAppointments && recurringAppointments?.length > 0 && (
            <div tw="max-w-[320px] mb-10">
              <div className="mb-8">
                <SubHeading>Recurring Appointment Series</SubHeading>
              </div>
              <RecurringAppointments
                appointments={recurringAppointments}
                onEditRecurringAppointment={setEditRecurringAppointmentData}
                onOpenRecurringAppointmentsModal={EditRecurringAppointmentsModalOnOpen}
              />
            </div>
          )}

          <div tw="max-w-[320px]" id="upcoming-appointments-container">
            <SubHeading>
              <>
                <span tw="md:mr-0 lg:mr-4">Upcoming Appointments</span>
                <Badge
                  variant="success"
                  css={[
                    tw`text-green-800 bg-green-200 rounded-full px-2 pl-2 mt-2 xl:mt-0 md:ml-0 md:inline-block`,
                    (yellowWarningAppointment &&
                      tw`px-2 text-yellow-700 bg-yellow-100 font-semibold  rounded-full pl-2`) ||
                      (redWarningAppointment && tw`px-2 text-red-500  bg-red-100 font-semibold rounded-full pl-2 ml-4`),
                  ]}
                >
                  {futureUpcomingAppointments.length && hasRecurringForever
                    ? `${futureUpcomingAppointments.length}+ left`
                    : `${futureUpcomingAppointments.length <= 30 ? futureUpcomingAppointments.length : '30+'} left`}
                </Badge>
              </>
            </SubHeading>
            {futureUpcomingAppointments && futureUpcomingAppointments.length > 0 && (
              <UpcomingAppointments
                clientId={clientId}
                appointments={futureUpcomingAppointments}
                setEditAppointmentData={setEditAppointmentData}
                editAppointment={EditAppointmentsModalOnOpen}
                resetCache={resetCache}
                isResetCacheLoading={resetCacheIsLoading}
              />
            )}
          </div>
        </>
      ) : areUpcomingAppointmentsLoading ? (
        <LoadingText />
      ) : (
        <LoadingError />
      )}

      {primaryContact && (
        <>
          <Heading tw="mt-3">Primary Contact</Heading>
          <div>{[primaryContact].map(renderContact)}</div>
        </>
      )}

      {secondaryContacts && secondaryContacts.length > 0 && (
        <>
          <Heading tw="mt-3">
            Contacts
            <Link
              onClick={() => {
                setIndex(ClientTab.Contacts);
                window.scrollTo(0, document.body.scrollHeight);
              }}
              tw="ml-3 text-xs font-medium"
              to="#contacts"
            >
              Add
            </Link>
          </Heading>
          <div>{secondaryContacts.map(renderContact)}</div>
        </>
      )}

      <Heading tw="mt-8">
        Billing Information
        {isAdminOrClinicalManager && (
          <span>
            <Link onClick={() => setIndex(ClientTab.BillingInfo)} to="#billing-info" tw="ml-3 text-xs font-medium">
              Edit
            </Link>
          </span>
        )}
      </Heading>
      {billingInfoData === null && <div tw="bg-yellow-100 p-4 rounded-md">No billing information is available</div>}
      {isError ? (
        <div tw="bg-yellow-100 p-4 rounded-md">There was an error loading this content</div>
      ) : (
        <>
          {billingInfoData?.stripeCustomerID && (
            <>
              <SubHeading> Stripe Customer ID </SubHeading>
              <div tw="mt-2">
                <Link
                  to={{ pathname: `https://dashboard.stripe.com/customers/${billingInfoData?.stripeCustomerID}` }}
                  target="_blank"
                  tw="text-sm"
                >
                  {billingInfoData?.stripeCustomerID}
                </Link>
              </div>
            </>
          )}

          {billingInfoData?.billingType === 'insurance' && (
            <>
              <SubHeading> Candid Customer ID </SubHeading>
              <div tw="mt-2">
                <Link to={{ pathname: candidUrl }} target="_blank" tw="text-sm">
                  {billingInfoData?.clientID}
                </Link>
              </div>
            </>
          )}

          {billingInfoData?.billingType === 'self-pay' && billingInfoData?.selfPay?.promptPay === 'automatic-payments' && (
            <>
              <SubHeading>Billing</SubHeading>
              <div tw="mt-2 capitalize">{`${billingInfoData?.billingType}  ${
                billingInfoData?.selfPay?.automaticPayments?.membershipStatus === 'monthly-membership'
                  ? '(Membership)'
                  : '(Fee-for-Service)'
              }`}</div>
            </>
          )}
          {billingInfoData?.billingType === 'insurance' && (
            <>
              <SubHeading>Billing</SubHeading>
              <div tw="mt-2 capitalize">{billingInfoData?.billingType}</div>
              {!billingInfoData.primaryInsurance.insurancePayerName && (
                <div tw="bg-yellow-100 p-4 rounded-md mt-4">Insufficient Insurance information</div>
              )}
            </>
          )}

          {billingInfoData?.selfPay?.automaticPayments?.membershipStatus === 'fee-for-service' && (
            <>
              <SubHeading>Pricing</SubHeading>
              <div tw="mt-2">
                ${billingInfoData?.selfPay?.automaticPayments?.pricingForService?.sessionPrice} per{' '}
                {billingInfoData?.selfPay?.automaticPayments?.pricingForService.sessionMinutes} Min Session
              </div>
            </>
          )}
          {billingInfoData?.selfPay?.automaticPayments?.membershipStatus === 'monthly-membership' && (
            <div>
              {billingInfoData?.selfPay.automaticPayments.pricingMonthlyMembership.price === 229 ? (
                <></>
              ) : (
                <>
                  <SubHeading>Pricing</SubHeading>
                  <div tw="mt-2">
                    ${billingInfoData?.selfPay?.automaticPayments?.pricingMonthlyMembership?.price} a Month
                  </div>
                </>
              )}
            </div>
          )}
        </>
      )}

      {editAppointmentData && (
        <EditAppointmentsModal
          appointmentData={editAppointmentData}
          contactTimeZone={contactTimeZone}
          clientId={clientId}
          onClose={EditAppointmentsModalOnClose}
          isOpen={EditAppointmentsModalIsOpen}
          onOpen={EditAppointmentsModalOnOpen}
          therapistData={therapistData}
          contactState={contactState}
        />
      )}

      <Heading tw="mt-3">
        Plan of Care
        <span tw="ml-3">
          <Link
            data-testid="edit-plan-of-care"
            to="#plan-of-care"
            onClick={() => {
              setIndex(ClientTab.PlanOfCare);
              window.scrollTo(0, document.body.scrollHeight);
            }}
            tw="text-xs font-medium"
          >
            Edit
          </Link>
        </span>
      </Heading>

      <SubHeading>Diagnoses</SubHeading>
      <div data-testid="sidebar-diagnoses" tw="mt-2">
        {diagnoses && diagnoses.length > 0 ? (
          <>
            {diagnoses.map((diagnosis, diagnosisIndex) => (
              <div key={diagnosisIndex}>{diagnosis.description}</div>
            ))}
          </>
        ) : areDiagnosesLoading ? (
          <LoadingText />
        ) : areDiagnosesError ? (
          <LoadingError />
        ) : (
          <div tw="text-sm text-gray-400">No diagnoses have been added</div>
        )}
      </div>

      <SubHeading>Current Long-Term Goals</SubHeading>
      {currentLongTermGoals && currentLongTermGoals.length > 0 ? (
        <div tw="mt-2">
          {currentLongTermGoals.map((goal, index) => <div key={index}>{goal.detail.goalDescription}</div>) ?? (
            <div tw="text-sm text-gray-400">No current long-term goals</div>
          )}
        </div>
      ) : (
        <div tw="mt-2 text-sm text-gray-400">No current long-term goals</div>
      )}

      <SubHeading>Current Short-Term Goals</SubHeading>
      {currentShortTermGoals && currentShortTermGoals.length > 0 ? (
        <div tw="mt-2">
          {currentShortTermGoals.map((goal, index) => <div key={index}>{goal.detail.goalDescription}</div>) ?? (
            <div key="1" tw="text-sm text-gray-400">
              No short term goals have been added
            </div>
          )}
        </div>
      ) : (
        <div tw="mt-2 text-sm text-gray-400">No current short-term goals</div>
      )}

      <SubHeading>Visit Frequency</SubHeading>
      <div data-testid="sidebar-visit-frequency" tw="mt-2">
        {visitFrequency ? (
          <div>{formatFrequency(visitFrequency)}</div>
        ) : isVisitFrequencyLoading ? (
          <LoadingText />
        ) : isVisitFrequencyError ? (
          <LoadingError />
        ) : (
          <div tw="text-sm text-gray-400">No visit frequency has been added</div>
        )}
      </div>

      {stripeCustomerID && (
        <>
          <Heading tw="mt-3">Billing ID</Heading>
          <a
            rel="noopener noreferrer"
            target="_blank"
            tw="text-indigo-700"
            href={`https://dashboard.stripe.com/customers/${stripeCustomerID}`}
          >
            {stripeCustomerID}
          </a>
        </>
      )}
      <Heading>Client Forms</Heading>
      <Link
        data-testid="roi-form"
        to={{
          pathname: `${portalClientAppBaseUrl}/release-of-information`,
        }}
        target="_blank"
      >
        Release of Information Form
      </Link>
      <br />
      <Link
        data-testid="file-sharing-form"
        to={{
          pathname: 'https://app.expressable.com/file-upload-form',
        }}
        target="_blank"
      >
        Client File Sharing Form
      </Link>
      <br />
      <Link
        data-testid="transfmer-request-form"
        to={{
          pathname: `https://forms.expressable.com/231284689936068?client=${clientId}&user=${encodeURIComponent(
            currentUser?.email,
          )}`,
        }}
        target="_blank"
      >
        Transfer Request Form
      </Link>

      {/* <Heading>Scheduling Link</Heading> */}
      <Link
        tw="text-xs"
        data-testid="scheduling-link"
        to={{
          pathname: `https://${scheduleUrlDomain}/schedule.php?calendarID=${
            therapistData?.acuityCalendarID
          }&appointmentType=${sessionTypeId}&field:${fieldIDforPatientID}=${clientId}&firstName=${clientFirstName}&lastName=${clientLastName}&phone=${contactPhone}&email=${encodeURIComponent(
            contactEmail as string,
          )}`,
        }}
        target="_blank"
      >
        {/* {`Scheduling Link for ${clientFirstName} ${clientLastName}`} */}
      </Link>
      <span tw="ml-2 text-indigo-700 font-semibold cursor-pointer text-xs" onClick={() => onClickCopyScheduleLink()}>
        {/* Copy Link */}
      </span>

      <Heading>Intake</Heading>
      <Link data-testid="intake" to="#" tw="text-xs text-indigo-700" onClick={IntakeShareModalOnOpen}>
        Share New
      </Link>
      <IntakeProvider>
        <IntakeLinkModal />
        <IntakeShareModal
          isOpen={IntakeShareModalIsOpen}
          onClose={IntakeShareModalOnClose}
          clientId={clientId}
          therapistName={careTeam.primary.therapistName}
        />
      </IntakeProvider>
      <ShareLinkModal
        isOpen={ShareLinkModalIsOpen}
        onClose={ShareLinkModalOnClose}
        isError={fireShareableError}
        sharableLink={fileSharableLink}
        isLoading={isLoading}
        shareFile={shareFile}
      />
      <Heading>Uploaded Files</Heading>
      {uploadedFilesData &&
        uploadedFilesData.map(({ file, link, key: fileKey }, index) => (
          <div key={index} tw="flex pt-1">
            <div tw="w-44 truncate">
              <Link data-testid="display-file-link" to={{ pathname: link }} target="_blank">
                {fileHelper.getFileNameWithoutExtension(file)}
              </Link>
            </div>
            <Link data-testid="share-file-link" to="#" onClick={() => shareFile(link)}>
              <FontAwesomeIcon tw="text-sm ml-2" icon={faShare} />
            </Link>
            <Link
              data-testid="delete-file-link"
              to="#"
              onClick={() => {
                setFileKeyToDelete(fileKey);
                DeleteFileModalOnOpen();
              }}
            >
              <FontAwesomeIcon tw="text-sm ml-2" icon={['far', 'trash']} />
            </Link>
          </div>
        ))}

      <div css={[uploadedFilesData && uploadedFilesData.length > 0 ? tw`pt-5` : tw`pt-0.5`]}>
        <div tw="relative h-4 overflow-hidden mb-20">
          {isUploading ? (
            <div tw="text-gray-400 -ml-2 text-xs leading-3">
              <FontAwesomeIcon tw="text-xs ml-2 text-gray-400" icon={faCircleNotch} spin /> Uploading
            </div>
          ) : (
            <>
              <label data-testid="upload-file-label" tw="absolute text-xs text-indigo-700">
                Upload New <span tw="text-xs text-gray-600"> (250 MB Max) </span>
              </label>
              <input
                data-testid="upload-file-input"
                type="file"
                accept={fileHelper.ALLOWED_CONTENT_TYPES.join(', ')}
                tw="absolute -left-48 w-64 opacity-0 cursor-pointer"
                onChange={uploadEncodedFile}
                title=""
              />
            </>
          )}
        </div>
      </div>

      {DeleteFileModalIsOpen && (
        <DeleteFileModal
          isOpen={DeleteFileModalIsOpen}
          onClose={() => {
            setFileKeyToDelete('');
            DeleteFileModalOnClose();
          }}
          clientId={clientId}
          fileKey={fileKeyToDelete}
        />
      )}
    </div>
  );
}

export const AddressRender = ({ street, line2, city, state, postalCode }: Address) => {
  const addressParts = [street, line2, city].filter(Boolean).join(' ');
  const stateAndPostalCode = [state, postalCode].filter(Boolean).join(' ');

  return (
    <span>
      {addressParts}
      {addressParts && stateAndPostalCode ? ', ' : ''}
      {stateAndPostalCode}
    </span>
  );
};

export const PhoneRender = ({ phone }: { phone: Phone }) => {
  return (
    <div>
      {Object.keys(phone).map((key, index) => (
        <div key={index}>
          {PhoneNumber(phone[key]).toJSON().number.national || phone[key]}{' '}
          <span tw="capitalize text-gray-400 text-sm">({key})</span>
        </div>
      ))}
    </div>
  );
};
