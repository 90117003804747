import { TreatmentAreaCategoryContentful } from 'domain/clinical-pathways/types';
import { ContentfulSpaces, useContentfulEntries } from 'hooks/use-contentful';

/** Will give new treatment areas from contentful CCE space */
export const useTreatmentAreas = () => {
  return useContentfulEntries<TreatmentAreaCategoryContentful[]>({
    contentType: 'treatmentArea',
    space: ContentfulSpaces.ClinicalCareExperience,
    order: ['fields.treatmentAreaCategory'],
  });
};
