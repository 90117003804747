import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, CloseButton, Modal, ModalBody, ModalContent, ModalHeader } from '@expressable/ui-library';

import ModalHeadline from 'components/modal-headline';

import 'twin.macro';
import { faCoin } from '@fortawesome/pro-solid-svg-icons';

export interface AddCreditModalProps {
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  onSave: () => void;
}

export function AddCreditModal(props: AddCreditModalProps) {
  const { isOpen, isLoading, onClose, onSave } = props;
  return (
    <Modal isOpen={isOpen} tw="max-w-lg">
      <ModalContent>
        <ModalHeader>
          <div tw="absolute top-0 right-0 pt-4 pr-4">
            <CloseButton testId="close-log-attendance-modal" onClick={onClose} />
          </div>
        </ModalHeader>
        <ModalBody>
          <React.Fragment>
            <div tw="flex items-center justify-center w-12 h-12 mx-auto bg-indigo-100 rounded-full">
              <FontAwesomeIcon tw="text-2xl text-indigo-700" icon={faCoin} />
            </div>
            <div tw="my-3 sm:mt-5">
              <ModalHeadline text="Add Credit" />
              <div tw="text-base font-normal leading-5 mb-7 flex justify-center">
                <div tw="text-center w-[75%]">One credit will be added to the client’s account immediately.</div>
              </div>
              <Button
                disabled={isLoading}
                loading={isLoading}
                data-testid="log-attendance-onsave"
                onClick={() => onSave()}
                variant="primary"
                fullWidth
              >
                Add Credit
              </Button>
              <div tw="my-3">
                <Button variant="secondary" fullWidth onClick={onClose}>
                  Exit
                </Button>
              </div>
            </div>
          </React.Fragment>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
