import React from 'react';

interface ListProps<D> {
  data: D[];
  render: (value: D, index: number, array: D[]) => React.ReactNode;
}

export default function CardList<D>({ data, render }: ListProps<D>) {
  return <ul>{data.map(render)}</ul>;
}
