import Conflicts from 'components/appointment-modals/conflicts';
import { TimeSlot } from 'components/therapistMatcher/data';
import { useDisplayTimezone } from 'hooks/common/useDisplayTimezone';
import { TimezoneOption } from 'hooks/common/useDisplayTimezone/options';
import useConflicts from 'hooks/use-conflicts';
import 'twin.macro';
import ReadOnlyField from '../ReadOnlyField';
import { ScheduledSessions } from '../SchedulingSessions/schedule-sessions';
import { RECURRING_FOREVER } from 'domain/recurring-appointments/constants';

interface ScheduleSummaryProps {
  scheduledSessionsInfo: ScheduledSessions;
}

export function describeSessionTime(
  sessionTime: TimeSlot | null | undefined,
  displayTimezone: TimezoneOption,
  sessionName: string,
) {
  const therapistName = sessionTime?.therapist.therapistName;
  const timestamp = sessionTime?.timestamp?.tz(displayTimezone.value);

  if (!sessionTime || !timestamp) {
    return `No ${sessionName} has been scheduled`;
  }

  return `${sessionTime?.duration} minute ${sessionName} with ${therapistName} SLP at ${timestamp?.format('h:mm A')} (${
    displayTimezone.label
  }) on ${timestamp?.format('dddd, M/D/YYYY')}`;
}

export function describeRecurringSessionTime(
  sessionTime: TimeSlot | null | undefined,
  displayTimezone: TimezoneOption,
  sessionName: string,
  notSchedulingReason: string | null,
) {
  const therapistName = sessionTime?.therapist.therapistName;
  const timestamp = sessionTime?.timestamp?.tz(displayTimezone.value);

  if (!sessionTime || !timestamp) {
    const reasonText = notSchedulingReason ? ` (${notSchedulingReason})` : '';
    return `No ${sessionName} has been scheduled${reasonText}`;
  }

  return `${sessionTime?.duration} minute ${sessionName} with ${therapistName} SLP on ${timestamp.format(
    'dddd',
  )}s at ${timestamp?.format('h:mm A')} (${displayTimezone.label}) starting on ${timestamp?.format('M/D/YYYY')}`;
}

const ScheduleSummary = ({ scheduledSessionsInfo }: ScheduleSummaryProps) => {
  const {
    evaluationTime = null,
    firstRecurringSessionInfo = null,
    secondRecurringSessionInfo = null,
  } = scheduledSessionsInfo.skipped ? {} : scheduledSessionsInfo;

  const { displayTimezone } = useDisplayTimezone();

  const { isLoading: evaluationIsLoading, conflicts: evaluationConflicts} = useConflicts({
    calendarID: evaluationTime?.therapist.acuityCalendarID,
    dateTime: evaluationTime?.timestamp?.toISOString(),
    duration: evaluationTime?.duration,
    type: 'evaluation',
    recurringTimes: 1,
    isAvailableTimes: !evaluationTime?.override,
  });

  const { isLoading: firstRecurringSessionIsLoading, conflicts: firstRecurringSessionConflicts} = useConflicts({
    calendarID: firstRecurringSessionInfo?.timestamp?.therapist.acuityCalendarID,
    dateTime: firstRecurringSessionInfo?.timestamp?.timestamp?.toISOString(),
    duration: firstRecurringSessionInfo?.timestamp?.duration,
    type: 'session',
    recurringTimes: RECURRING_FOREVER,
    isAvailableTimes: !firstRecurringSessionInfo?.timestamp?.override,
  });

  const { isLoading: secondRecurringSessionIsLoading, conflicts: secondRecurringSessionConflicts} = useConflicts({
    calendarID: secondRecurringSessionInfo?.timestamp?.therapist.acuityCalendarID,
    dateTime: secondRecurringSessionInfo?.timestamp?.timestamp?.toISOString(),
    duration: secondRecurringSessionInfo?.timestamp?.duration,
    type: 'session',
    recurringTimes: RECURRING_FOREVER,
    isAvailableTimes: !secondRecurringSessionInfo?.timestamp?.override,
  });

  return (
    <div tw="flex flex-col gap-6">
      <ReadOnlyField label="Initial Evaluation">
        <span>{describeSessionTime(evaluationTime, displayTimezone, 'evaluation')}</span>
      </ReadOnlyField>

      {evaluationTime && !evaluationIsLoading && evaluationConflicts.length > 0 && (
        <Conflicts
          conflicts={evaluationConflicts}
          availableTimes={!evaluationTime.override}
          displayTimezone={displayTimezone.value}
        />
      )}

      <ReadOnlyField label="Recurring Session 1">
        <span>
          {describeRecurringSessionTime(firstRecurringSessionInfo?.timestamp, displayTimezone, 'session', null)}
        </span>
      </ReadOnlyField>

      {firstRecurringSessionInfo?.timestamp && !firstRecurringSessionIsLoading && firstRecurringSessionConflicts.length > 0 && (
        <Conflicts
          conflicts={firstRecurringSessionConflicts}
          availableTimes={!firstRecurringSessionInfo?.timestamp.override}
          displayTimezone={displayTimezone.value}
        />
      )}

      <ReadOnlyField label="Recurring Session 2">
        <span>
          {describeRecurringSessionTime(
            secondRecurringSessionInfo?.timestamp,
            displayTimezone,
            'session',
            secondRecurringSessionInfo?.notSchedulingReason ?? null,
          )}
        </span>
      </ReadOnlyField>

      {secondRecurringSessionInfo?.timestamp && !secondRecurringSessionIsLoading && secondRecurringSessionConflicts.length > 0 && (
        <Conflicts
          conflicts={secondRecurringSessionConflicts}
          availableTimes={!secondRecurringSessionInfo?.timestamp.override}
          displayTimezone={displayTimezone.value}
        />
      )}
    </div>
  );
};

export default ScheduleSummary;
