import 'twin.macro';
import dayJs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useTherapistWeeklySummaries } from 'hooks/use-therapists';
import { Loader } from '@expressable/ui-library';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppointmentItem, WeekSummaryStatus, WeekSummary, AppointmentSummary } from 'domain/therapist/therapistTypes';
import { useState } from 'react';

dayJs.extend(utc);
dayJs.extend(timezone);

export interface TherapistWeeklyRateLimitsProps {
  therapistEmail: string;
}

const TherapistRateLimiting = ({ therapistEmail }: TherapistWeeklyRateLimitsProps) => {
  const { data: weeklySummaries, isLoading } = useTherapistWeeklySummaries(therapistEmail);

  if (isLoading) {
    return <Loader type="ring" />;
  }

  return (
    <div className="text-sm ml-8">
      <div className="text-xl font-semibold mb-6 mt-10">Current Rate Limiting</div>

      {weeklySummaries?.length &&
        weeklySummaries.map(week => (
          <div
            className="flex flex-col mt-8 gap-3"
            key={week.startOfWeek}
            data-testid={`week-group-${formatDate(week.startOfWeek)}`}
          >
            <div className="flex items-center mb-1">
              <IconStatus status={[...week.evaluations.status, ...week.sessions.status]} />
              <div className="text-base font-semibold ml-2">
                {formatDate(week.startOfWeek)} - {formatDate(week.endOfWeek)}
              </div>
            </div>
            <EvaluationDetails startOfWeek={week.startOfWeek} evaluations={week.evaluations} />
            <SessionDetails startOfWeek={week.startOfWeek} sessions={week.sessions} />
            <DirectHoursText weekSummary={week} />
          </div>
        ))}
    </div>
  );
};

export default TherapistRateLimiting;

const statusColors = {
  red: 'text-red-500',
  yellow: 'text-yellow-400',
  purple: 'text-indigo-500',
};

const IconStatus = ({ status }: { status: WeekSummaryStatus[] }) => {
  if (status.includes(WeekSummaryStatus.RateLimitDirectHours)) {
    return <FontAwesomeIcon data-testid={statusColors.red} className={statusColors.red} icon={faCircle} />;
  }
  if (status.includes(WeekSummaryStatus.RateLimitWeek)) {
    return <FontAwesomeIcon data-testid={statusColors.red} className={statusColors.red} icon={faCircle} />;
  }
  if (status.includes(WeekSummaryStatus.RateLimitDay)) {
    return <FontAwesomeIcon data-testid={statusColors.yellow} className={statusColors.yellow} icon={faCircle} />;
  }
  return <FontAwesomeIcon data-testid={statusColors.purple} className={statusColors.purple} icon={faCircle} />;
};

const formatDate = (date: string) => dayJs.utc(date).format('MM/DD/YYYY');

const getTotalEvaluationText = (totalEvaluations: number) => {
  // english is weird sometimes. Zero should be considered plural
  if (totalEvaluations === 0) {
    return `${totalEvaluations} evaluations`;
  }
  return `${totalEvaluations} ${totalEvaluations > 1 ? 'evaluations' : 'evaluation'}`;
};

const toHoursAndMinutes = (totalMinutes: number) => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  const formattedHours = hours > 0 ? `${hours.toString()}h` : '';
  const formattedMinutes = minutes > 0 ? `${minutes.toString()}m` : '';

  if (!formattedHours && !formattedMinutes) {
    return '0h';
  }

  return `${formattedHours}${formattedMinutes}`;
};

const EvaluationDetails = ({ startOfWeek, evaluations }: { startOfWeek: string; evaluations: AppointmentSummary }) => {
  const [displayMore, setDisplayMore] = useState(false);
  return (
    <>
      <div className="flex">
        <WeeklyEvaluationText evaluations={evaluations} data-testid={`weekly-eval-text-${formatDate(startOfWeek)}`} />
        {evaluations.total > 0 && (
          <a className="text-indigo-700 cursor-pointer ml-1" onClick={() => setDisplayMore(!displayMore)}>
            {displayMore ? 'See less' : 'See more'}
          </a>
        )}
      </div>
      {evaluations?.items?.length > 0 &&
        displayMore &&
        evaluations?.items?.map(item => (
          <DailyEvaluationText
            key={item.appointmentOn}
            item={item}
            data-testid={`daily-eval-text-${formatDate(item.appointmentOn)}`}
          />
        ))}
    </>
  );
};

const WeeklyEvaluationText = ({ evaluations, ...props }: { evaluations: AppointmentSummary }) => {
  const { isOverLimitForTheWeek, total, totalDuration } = evaluations;
  return (
    <div className="flex" {...props}>
      <span>{`${getTotalEvaluationText(total)} scheduled this week (${toHoursAndMinutes(totalDuration)} total).`}</span>
      &nbsp;
      {isOverLimitForTheWeek ? (
        <span className="font-bold">No additional evals can be scheduled this week</span>
      ) : (
        <span>No rate limits for weekly evals</span>
      )}
    </div>
  );
};

const DailyEvaluationText = ({ item, ...props }: { item: AppointmentItem }) => {
  const { total, totalDuration, appointmentOn, isRateLimited } = item;
  return (
    <div className="flex" {...props}>
      <span>{`- ${getTotalEvaluationText(total)} scheduled on ${formatDate(appointmentOn)} (${toHoursAndMinutes(
        totalDuration,
      )} total).`}</span>
      &nbsp;
      {isRateLimited ? (
        <span className="font-bold">No additional evals can be scheduled on this day</span>
      ) : (
        <span>No rate limits for this day</span>
      )}
    </div>
  );
};

const getTotalSessionText = (totalSessions: number) => {
  // english is weird sometimes. Zero should be considered plural
  if (totalSessions === 0) {
    return `${totalSessions} sessions`;
  }
  return `${totalSessions} ${totalSessions > 1 ? 'sessions' : 'session'}`;
};

const SessionDetails = ({ startOfWeek, sessions }: { startOfWeek: string; sessions: AppointmentSummary }) => {
  const [displayMore, setDisplayMore] = useState(false);
  return (
    <>
      <div className="flex">
        <WeeklySessionText sessions={sessions} data-testid={`weekly-session-text-${formatDate(startOfWeek)}`} />
        {sessions.total > 0 && (
          <a className="text-indigo-700 cursor-pointer ml-1" onClick={() => setDisplayMore(!displayMore)}>
            {displayMore ? 'See less' : 'See more'}
          </a>
        )}
      </div>
      {sessions?.items?.length > 0 &&
        displayMore &&
        sessions?.items?.map(item => (
          <DailySessionText
            key={item.appointmentOn}
            item={item}
            data-testid={`daily-session-text-${formatDate(item.appointmentOn)}`}
          />
        ))}
    </>
  );
};

const WeeklySessionText = ({ sessions, ...props }: { sessions: AppointmentSummary }) => {
  const { total, totalDuration } = sessions;
  return (
    <div {...props}>{`${getTotalSessionText(total)} scheduled this week (${toHoursAndMinutes(
      totalDuration,
    )} total).`}</div>
  );
};

const DailySessionText = ({ item, ...props }: { item: AppointmentItem }) => {
  const { total, totalDuration, appointmentOn } = item;
  return (
    <div className="flex" {...props}>
      <span>{`- ${getTotalSessionText(total)} scheduled on ${formatDate(appointmentOn)} (${toHoursAndMinutes(
        totalDuration,
      )} total).`}</span>
    </div>
  );
};

const DirectHoursText = ({ weekSummary }: { weekSummary: WeekSummary }) => {
  const { startOfWeek, totalDuration, isOverLimitForDirectHours } = weekSummary;
  return (
    <div data-testid={`weekly-direct-hours-${formatDate(startOfWeek)}`}>
      <span>{`${toHoursAndMinutes(totalDuration)} direct hours are currently scheduled.`}</span>&nbsp;
      {isOverLimitForDirectHours ? (
        <span className="font-bold">No additional evals and sessions can be scheduled for this week</span>
      ) : (
        <span>No rate limits for direct hours</span>
      )}
    </div>
  );
};
