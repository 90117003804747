import { Moment } from 'moment';
import React, { useState } from 'react';
import { SingleDatePicker } from 'react-dates';
import { Control, Controller } from 'react-hook-form';
import 'twin.macro';

interface Props {
  control: Control<any>; //eslint-disable-line
  name: string;
  currentDate: Moment | string;
  required?: boolean;
  disabled?: boolean;
  allowPastDates?: boolean;
}
export default function RHFSingleDatePicker({ control, name, disabled, currentDate, required, allowPastDates }: Props) {
  const [state, setState] = useState<any>({ date: currentDate }); //eslint-disable-line

  return (
    <Controller
      render={({ field }) => {
        return (
          <SingleDatePicker
            date={state.date}
            ref={field.ref}
            noBorder
            block
            openDirection="down"
            small
            placeholder="--/--/----"
            numberOfMonths={1}
            focused={state.focused}
            onDateChange={date => {
              setState((p: any) => ({ ...p, date })); //eslint-disable-line
              field.onChange(date);
            }}
            onFocusChange={
              ({ focused }) => setState((p: any) => ({ ...p, focused })) //eslint-disable-line
            }
            isOutsideRange={allowPastDates ? () => false : undefined}
            id={name}
            disabled={disabled}
          />
        );
      }}
      control={control}
      name={name}
      defaultValue={currentDate}
      rules={{ required: required }}
    />
  );
}
