import TherapistSearchUi, { TherapistSearchProps } from 'components/therapistFilter/ui';
import { INSURANCES_ACCEPTED_ENTRY_ID } from 'hooks/use-ContentfulData';
import {  useMemo } from 'react';
import { SelectOption } from 'types';
import usStatesOptions from 'utils/us-states.json';
import statesWithMultipleTimezones from 'utils/us-states-with-multiple-timezones.json';
import { useContentfulEntry } from 'hooks/use-contentful';

type Props = Omit<TherapistSearchProps, 'insuranceOptions'>;

const TherapistSearch = (props: Props) => {
  const {
    data: { insurances = [] } = {},
    isSuccess: insurancesSuccess,
    isLoading: isInsuranceLoading
  } = useContentfulEntry({
    entryId: INSURANCES_ACCEPTED_ENTRY_ID,
  });


  const usStatesOptionsWithMultipleTimezones = usStatesOptions.map(state => {
    if (statesWithMultipleTimezones.includes(state.value)) {
      return { ...state, hasMultipleTimeZone: true };
    }
    return state;
  });

  const insuranceOptions = useMemo<SelectOption[]>(() => {
    if (insurances?.length && insurancesSuccess && !isInsuranceLoading) {
      return insurances.map((plan: string) => ({
        value: plan.trim(),
        label: plan.trim(),
      }));
    } else {
      return [];
    }
  }, [isInsuranceLoading, insurancesSuccess]);

  return (
    <TherapistSearchUi
      {...props}
      insuranceOptions={insuranceOptions}
      usStatesOptionsWithMultipleTimezones={usStatesOptionsWithMultipleTimezones}
    />
  );
};

export default TherapistSearch;
