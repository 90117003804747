import React from 'react';
import { AppointmentTypesNames, PendingAppointment } from 'types';
import 'twin.macro';
import { Button, Link, Menu, MenuButton, MenuItem, MenuList } from '@expressable/ui-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useViewport } from '@expressable/utils';
import dayjs from 'dayjs';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import {
  ReschedulePendingAppointmentPayload,
  NoShowPendingAppointmentPayload,
  useReschedulePendingAppointment,
  useNoShowPendingAppointment,
} from 'hooks/use-pending-appointments';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface PendingAppointmentCardProps {
  pendingAppointment: PendingAppointment;
  onReschedule: (
    reschedule: (payload: ReschedulePendingAppointmentPayload) => Promise<void>,
    acuityId: string,
    appointmentDateTime: string,
  ) => void;
  onCancel: () => void;
  onNoShow: (noShow: (payload: NoShowPendingAppointmentPayload) => Promise<void>, acuityId: string) => void;
  onDismiss: () => void;
  onClientAttended: () => void;
  canDismissAppointment: boolean;
}

export default function PendingAppointmentCard(props: PendingAppointmentCardProps) {
  const { pendingAppointment, onReschedule, onCancel, onNoShow, onDismiss, canDismissAppointment, onClientAttended } =
    props;
  const { therapistName, appointmentDateTime, acuityAppointmentID, appointmentDuration, appointmentTypeID } =
    pendingAppointment;
  const breakpoint = 1024;

  const { viewportWidth } = useViewport();

  const { mutateAsync: reschedulePendingAppointment, isLoading: isReschedulePendingAppointmentLoading } =
    useReschedulePendingAppointment();
  const { mutateAsync: noShowPendingAppointment, isLoading: isNoShowPendingAppointmentLoading } =
    useNoShowPendingAppointment();

  const reschedule = () => {
    onReschedule(reschedulePendingAppointment, acuityAppointmentID, appointmentDateTime);
  };

  const cancel = () => {
    onCancel();
  };

  const clientAttended = () => {
    onClientAttended();
  };

  const noShow = () => {
    onNoShow(noShowPendingAppointment, acuityAppointmentID);
  };

  const dismiss = () => {
    onDismiss();
  };

  const { cueSchedulingControl: displayBookingCapability } = useFlags();

  const MobileBtnActions = () => (
    <Menu tw="ml-4">
      <MenuButton tw="border-gray-200 flex items-center justify-between" variant="secondary">
        Actions
        <FontAwesomeIcon tw="text-gray-700 text-sm ml-2" icon="caret-down" />
      </MenuButton>
      <MenuList>
        {(displayBookingCapability ||
          (!displayBookingCapability && appointmentTypeID === 'speech-therapy-session')) && (
          <MenuItem data-testid={`client-reschedule-button-${appointmentDateTime}`} onClick={reschedule}>
            Rescheduled
          </MenuItem>
        )}
        {displayBookingCapability && (
          <MenuItem data-testid={`client-canceled-button-${appointmentDateTime}`} onClick={cancel}>
            Canceled
          </MenuItem>
        )}
        <MenuItem data-testid={`client-noshowed-button-${appointmentDateTime}`} onClick={noShow}>
          No-Showed
        </MenuItem>
        {canDismissAppointment && <MenuItem onClick={dismiss}>Dismiss</MenuItem>}
      </MenuList>
    </Menu>
  );

  return (
    <>
      <div tw="mb-4 bg-white p-5 rounded-lg shadow relative" data-testid="client-pending-appointment">
        <div tw="ml-1 text-base font-semibold">
          {AppointmentTypesNames[appointmentTypeID] ?? 'Speech Therapy Appointment'}
        </div>
        {appointmentDuration === undefined ? (
          <div tw="ml-1 font-semibold text-gray-500 uppercase text-sm">
            {` WITH ${therapistName} AT ${dayjs(appointmentDateTime).format('h:mm A - MMMM D')}`}
          </div>
        ) : (
          <div tw="ml-1 font-semibold text-gray-500 uppercase text-sm">
            {`${appointmentDuration} MINUTES WITH ${therapistName} AT ${dayjs(appointmentDateTime).format(
              'h:mm A - MMMM D',
            )}`}
          </div>
        )}
        <div tw="flex justify-between mt-6 mb-2">
          <div tw="flex w-full">
            <Button
              variant="primary"
              data-testid={`client-attended-button-${appointmentDateTime}`}
              onClick={clientAttended}
            >
              Client Attended
            </Button>
            {viewportWidth < breakpoint ? null : (
              <>
                {(displayBookingCapability ||
                  (!displayBookingCapability && appointmentTypeID === 'speech-therapy-session')) && (
                  <Link
                    data-testid={`client-reschedule-button-${appointmentDateTime}`}
                    tw="ml-6 mt-2 font-semibold"
                    onClick={reschedule}
                    to="#"
                  >
                    Rescheduled
                  </Link>
                )}
                {displayBookingCapability && (
                  <Link
                    data-testid={`client-canceled-button-${appointmentDateTime}`}
                    tw="ml-6 mt-2 font-semibold"
                    onClick={cancel}
                    to="#"
                  >
                    Canceled
                  </Link>
                )}
                <Link
                  data-testid={`client-noshowed-button-${appointmentDateTime}`}
                  tw="ml-6 mt-2 font-semibold"
                  onClick={noShow}
                  to="#"
                >
                  No-Showed
                </Link>
                {canDismissAppointment && (
                  <Link tw=" mt-2 font-semibold ml-auto mr-7" onClick={dismiss} to="#">
                    Dismiss
                  </Link>
                )}
              </>
            )}
          </div>
          {viewportWidth < breakpoint && <MobileBtnActions />}
        </div>
        {isReschedulePendingAppointmentLoading || isNoShowPendingAppointmentLoading ? (
          <div tw="absolute inset-0 bg-white opacity-50 flex justify-center items-center">
            <FontAwesomeIcon tw="text-2xl text-gray-800" icon={faCircleNotch} spin />
          </div>
        ) : null}
      </div>
    </>
  );
}
