import React from 'react';
import 'twin.macro';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Card, Label, Link } from '@expressable/ui-library';
import { getCustomStructuredActivityLink, getStructuredActivityLink } from 'domain/clinical-pathways/contants';
import { useToasts } from 'react-toast-notifications';
import { ClientAssignment } from 'domain/clinical-pathways/types';

dayjs.extend(utc);
dayjs.extend(timezone);

type Props = {
  clientAssignment: ClientAssignment;
  shouldDisplayDate?: boolean;
  onRemove: () => void;
};

export const StructuredActivityCard = ({ clientAssignment, shouldDisplayDate, onRemove }: Props): JSX.Element => {
  const { addToast } = useToasts();

  const isCustomSA = clientAssignment.assignmentType === 'custom-structured-activity';

  const previewLink = isCustomSA ?
    getCustomStructuredActivityLink(clientAssignment.customStructuredActivityContentfulEntryID!) :
    getStructuredActivityLink(clientAssignment.structuredActivityContentfulEntryID!);

  const handleLinkCopy = () => {
    navigator.clipboard
      .writeText(previewLink)
      .then(() => {
        addToast('Link successfully copied', { appearance: 'success', autoDismiss: true });
      })
      .catch(err => {
        addToast('Something Went Wrong when copying to clipboard', { appearance: 'error', autoDismiss: true });
      });
  };

  return (
    <Card tw="p-6 border border-gray-300 w-[340px] mr-5 h-full min-h-[40px] last:mr-0 mb-5">
      <div tw="flex flex-col">
        <div tw="flex items-center justify-between">
          <Label tw="font-medium text-sm">{clientAssignment.assignmentTitle}</Label>
          <FontAwesomeIcon
            tw="text-gray-400 cursor-pointer"
            icon={faTimes}
            onClick={onRemove}
            data-testid="remove-structured-activity"
          />
        </div>
        <div tw="mt-3 text-sm">{clientAssignment.assignmentDescription}</div>
        {clientAssignment.materialContentfulEntryID && (
          <div tw="flex mt-3">
            <div tw="text-sm overflow-hidden overflow-ellipsis" title={clientAssignment.materialContentfulTitle}>
              <span tw="font-medium text-sm mr-1">Material:</span>
              {clientAssignment.materialContentfulTitle}
            </div>
          </div>
        )}
        {shouldDisplayDate && (
          <div tw="mt-6 text-gray-400 text-sm">
            {`Assigned: ${dayjs(clientAssignment.createdAt).tz(dayjs.tz.guess()).format('MM/DD/YYYY (dddd)')}`}
          </div>
        )}
      </div>
      <div tw="flex items-center justify-between mt-6">
        <a
          tw="text-indigo-700 font-medium text-sm"
          href={previewLink}
          target="_blank"
          rel="noreferrer"
        >
          Preview
        </a>
        <Link to="#" tw="text-indigo-700 font-medium text-sm" onClick={handleLinkCopy}>
          Copy Link
        </Link>
      </div>
    </Card>
  );
};
