import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { ContentfulStringContent } from 'types';
import { EvaluationEntriesIds, useEvaluationContentfulEntry } from './use-evaluation-contentful-provider';
import { FormYesNoDetails } from 'domain/shared/types';

export function useRadioFreeTextDefaultAnswer(name: string, defaultAnswer: string | null | undefined) {
  const { setValue, reset, getValues } = useFormContext();

  const watchValue = useWatch({ name });

  const setRadioFreeTextAnswer = (name: string, watchedValue: FormYesNoDetails, contentfulResponse: string) => {
    if (watchedValue?.relevant === 'no') {
      setValue(name, { relevant: 'no', details: contentfulResponse });
    }

    if (watchedValue?.relevant === 'yes' && watchedValue?.details === contentfulResponse) {
      setValue(name, { relevant: 'yes', details: '' });
      reset({ ...getValues() });
    }
  };

  useEffect(() => {
    if (defaultAnswer) {
      setRadioFreeTextAnswer(name, watchValue, defaultAnswer);
    }
  }, [defaultAnswer, watchValue?.relevant]);
}

export function useRadioFreeTextEvaluationDefaultAnswer(name: string, defaultAnswerId: EvaluationEntriesIds) {
  const { data } = useEvaluationContentfulEntry<ContentfulStringContent>(defaultAnswerId);
  useRadioFreeTextDefaultAnswer(name, data?.content);
}
