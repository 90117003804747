import { AddressRender, PhoneRender } from 'pages/client/components/client-sidebar';
import React from 'react';
import { Contact } from 'types';
import 'twin.macro';
import PhoneNumber from 'awesome-phonenumber';

const Heading = ({ children }: { children: React.ReactNode }) => (
  <div tw="mb-2 mt-8 text-base leading-none capitalize font-semibold">{children}</div>
);

const SubHeading = ({ children }: { children: React.ReactNode }) => (
  <div tw="mt-5 text-sm leading-none capitalize font-semibold">{children}</div>
);

interface ContactSidebarProps extends Contact {
  contactID: string;
}

export default function ContactSidebar(props: ContactSidebarProps) {
  const { contactFirstName, contactLastName, email, address, phone, faxPhone } = props;

  return (
    <div data-testid="contact-sidebar" tw="text-sm">
      <Heading>Contact Information</Heading>
      <>
        <SubHeading>Name</SubHeading>
        {`${contactFirstName} ${contactLastName}`}
      </>
      {phone && (phone.mobile || phone.work || phone.home) && (
        <>
          <SubHeading> Phone </SubHeading>
          <PhoneRender phone={phone} />
        </>
      )}

      {faxPhone && (
        <div className="mt-4 mb-4">
          <p className="font-semibold">Fax</p>
          <div> {new PhoneNumber(faxPhone).toJSON().number.national}</div>
        </div>
      )}
      {email && (
        <>
          <SubHeading> Email </SubHeading>
          {email}
        </>
      )}
      {address && (
        <>
          <SubHeading> Address </SubHeading>
          <AddressRender {...address} />
        </>
      )}
    </div>
  );
}
