import { Goal } from 'hooks/use-care-plan-goals';

export const getGoalDetails = (goal: Goal) => {
  const goalDetail = goal.detail;
  const {
    goalTime,
    goalsTimeUnit,
    goalDescription: goalDetailDescription,
    goalCues,
    goalOpportunity,
    goalOpportunityUnit,
    goalPeriod,
    goalPeriodUnit,
    goalOutcome,
  } = goalDetail;
  const goalOpportunityUnitDivider = goalOpportunityUnit === '% of opportunities' ? '' : ' ';
  const goalDescription =
    `Within ` +
    `${goalTime} ${goalsTimeUnit},
            client will ${goalDetailDescription} given ${goalCues}
            at ${goalOpportunity}${goalOpportunityUnitDivider}${goalOpportunityUnit}
            across ${goalPeriod} ${goalPeriodUnit}
            in order to ${goalOutcome}.`.toLowerCase();

  return { goalOpportunityUnit, goalOpportunityUnitDivider, goalDescription };
};
