import { Button } from '@expressable/ui-library';
import dayjs from 'dayjs';

export interface SaveToolbarProps {
  loading: boolean;
  lastAutoSavedAt: dayjs.Dayjs | null;
}

export const SaveToolbar = (props: SaveToolbarProps) => {
  const { loading, lastAutoSavedAt } = props;
  return (
    <div className="sticky py-3 px-4 bottom-0 bg-white border-t border-gray-200 flex gap-4 items-center">
      <Button data-testid="dischargeNoteFormSave" variant="primary" loading={loading} type="submit">
        Finish Editing
      </Button>
      {lastAutoSavedAt && (
        <span className="text-gray-400 text-xs">
          Last auto-saved at {lastAutoSavedAt.tz(dayjs.tz.guess()).format('hh:mm A')}
        </span>
      )}
    </div>
  );
};
