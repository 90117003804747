import { useQuery } from 'react-query';
import { dynamicHttp } from '@expressable/utils';
import {
  AwaitingAuthorization,
  ExpiringByVisitCountAuthorization,
  ExpiringSoonAuthorization,
} from 'domain/authorizations/types';

const httpHealthRecordProxy = dynamicHttp('health-record');

export const getAwaitingAuthorizations = async (type: 'sessions' | 'evaluations', therapistEmail?: string) => {
  const encodeEmail = encodeURIComponent(therapistEmail || '');
  const therapistEmailParam = therapistEmail === 'all' ? '' : `&therapistEmail=${encodeEmail}`;

  const { data } = await httpHealthRecordProxy.get<AwaitingAuthorization[]>(
    `/admin/authorizations?reportType=awaiting-${type}${therapistEmailParam}`,
  );
  return data;
};

export function useAwaitingAuthorizations(type: 'sessions' | 'evaluations', therapistEmail?: string) {
  return useQuery([`awaitingAuthorizations-${type}`, therapistEmail], () =>
    getAwaitingAuthorizations(type, therapistEmail),
  );
}

export const getExpiringSoonAuthorizations = async (therapistEmail?: string) => {
  const therapistEmailParam = therapistEmail === 'all' ? '' : `&therapistEmail=${therapistEmail}`;

  const { data } = await httpHealthRecordProxy.get<ExpiringSoonAuthorization[]>(
    `/admin/authorizations?reportType=expiring-on-visit-date${therapistEmailParam}`,
  );
  return data;
};

export function useExpiringSoonAuthorizations(therapistEmail?: string) {
  return useQuery(['expiringSoonAuthorizations', therapistEmail], () => getExpiringSoonAuthorizations(therapistEmail));
}

export const getExpiringByVisitCountAuthorizations = async (therapistEmail?: string) => {
  const therapistEmailParam = therapistEmail === 'all' ? '' : `&therapistEmail=${therapistEmail}`;

  const { data } = await httpHealthRecordProxy.get<ExpiringByVisitCountAuthorization[]>(
    `/admin/authorizations?reportType=expiring-by-visit-count${therapistEmailParam}`,
  );
  return data;
};

export function useExpiringByVisitCountAuthorizations(therapistEmail?: string) {
  return useQuery(['expiringByVisitAuthorizations', therapistEmail], () =>
    getExpiringByVisitCountAuthorizations(therapistEmail),
  );
}
