import { useEffect, useMemo } from 'react';
import {
  Button,
  CloseButton,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
} from '@expressable/ui-library';
import 'twin.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNotesMedical } from '@fortawesome/free-solid-svg-icons';
import { Controller, useForm } from 'react-hook-form';
import { BillingTypeEnum, Diagnosis } from 'types';
import { compareByCode, useSetDiagnoses } from 'hooks/use-care-plan-diagnoses';
import { useContentfulEntry } from 'hooks/use-ContentfulData';
import * as Sentry from '@sentry/react';
import { useBillingInfo } from 'hooks/use-billing-information';

export interface ClientDiagnosesModalProps {
  isOpen: boolean;
  onClose: () => void;
  clientId: string;
  diagnoses: Diagnosis[];
  shouldLogEvent: boolean;
}

interface ClientDiagnosesForm {
  diagnoses: Diagnosis;
  code: string;
  description: string;
}

export interface ContentfulDiagnosis {
  icd10Code: string;
  icd10Description: string;
}

export interface ContentfulDiagnoses {
  fields: {
    icd10Code: string;
    icd10Description: string;
  };
  icd10Diagnosis: ContentfulDiagnosis;
  icd10ListTitle: string;
  electiveDiagnoses: string[];
}

const DIAGNOSES_CONTENTFUL_ENTRY_ID = '1lEFftlrLOoyW9KilNafkp';

export function ClientDiagnosesModal(props: ClientDiagnosesModalProps) {
  const { isOpen, onClose, clientId, shouldLogEvent, diagnoses } = props;
  const [diagnosesEntries, , fetchDiagnoses] = useContentfulEntry<any>(DIAGNOSES_CONTENTFUL_ENTRY_ID); // eslint-disable-line
  const { mutateAsync: setDiagnoses, isLoading } = useSetDiagnoses();

  let diagnosesContentfulArray: ContentfulDiagnoses[] = [];
  let electivesContentfulArray: ContentfulDiagnoses[] = [];
  const allDiagnoses: ContentfulDiagnosis[] = [];

  useEffect(fetchDiagnoses, []);

  // Here we ware mapping the whole data pulled from contentful
  diagnosesContentfulArray = [...(diagnosesEntries?.fields.icd10Diagnosis ?? [])];
  electivesContentfulArray = [...(diagnosesEntries?.fields.electiveDiagnoses ?? [])];
  diagnosesContentfulArray.forEach(diagnoses => {
    const diagnosesFields = diagnoses.fields;
    allDiagnoses?.push(diagnosesFields);
  });

  let diagnosesArray: Diagnosis[] = [];

  try {
    diagnosesArray = allDiagnoses
      .filter(icd => icd)
      .map(({ icd10Code: code, icd10Description: description, ...rest }) => ({
        code,
        description,
        ...rest,
      }));
  } catch (err) {
    Sentry.captureException(err as Error);
    console.error(err);
  }

  //Here we are deleting the option from the array when the option is already selected
  const diagnosesRemainingOptions = diagnosesArray.filter(
    item1 => !diagnoses?.some(item2 => item1.code === item2.code),
  );

  // Here we are adding the description and code key to the electives options
  const electivesOptions = electivesContentfulArray.map((description, number) => ({
    description,
    code: '#-' + number,
  }));

  // This is the Diagnoses options Array with the electives options
  const diagnosesOptions = [
    ...diagnosesRemainingOptions?.sort(compareByCode),
    {
      label: 'Elective',
      options: electivesOptions,
    },
  ];

  const {
    formState: { errors },
    handleSubmit,
    control,
    reset,
    watch,
  } = useForm<ClientDiagnosesForm>({
    defaultValues: {
      code: '',
      description: '',
    },
  });

  const watchSelectedDiagnose = watch('diagnoses');

  const onSubmit = async (data: ClientDiagnosesForm) => {
    const trimmedDiagnosis = {
      code: data.diagnoses.code.trim(),
      description: data.diagnoses.description.trim(),
    };
    await setDiagnoses({ clientId, diagnoses: [...diagnoses, trimmedDiagnosis], shouldLogEvent });
    onClose();
  };

  useEffect(() => {
    reset();
  }, [diagnoses, isLoading]);

  const { data: billingInformation, isLoading: isLoadingBillingInfo } = useBillingInfo(clientId);

  const isNotEligibleForElectiveDiagnosis = useMemo(() => {
    if (!billingInformation || isLoadingBillingInfo) return true;
    const isElective = watchSelectedDiagnose?.code?.includes('#-');

    if (!isElective) {
      return false;
    }

    // Don't allow elective diagnoses to be added if billing type is insurance
    return billingInformation?.billingType === BillingTypeEnum.insurance;
  }, [isLoadingBillingInfo, billingInformation, watchSelectedDiagnose]);

  useEffect(() => {
    reset();
  }, [isOpen]);

  return (
    <Modal portal isOpen={isOpen}>
      <ModalContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader>
            <CloseButton onClick={onClose} />
          </ModalHeader>
          <ModalBody>
            <div>
              <div tw="flex items-center justify-center w-12 h-12 mx-auto bg-indigo-100 rounded-full">
                <FontAwesomeIcon tw="text-2xl text-indigo-700" icon={faNotesMedical} data-testid="icon" />
              </div>
              <div tw="mt-3 sm:mt-5">
                <h3 tw="text-lg text-center font-medium text-gray-900 leading-6" id="modal-headline">
                  New Diagnosis
                </h3>
                <div tw="mt-10">
                  <FormGroup>
                    <Label htmlFor="diagnoses">Common Speech Diagnoses</Label>

                    <div tw="w-full" data-testid="diagnoses">
                      <Controller
                        name="diagnoses"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={diagnosesOptions}
                            isSearchable
                            id="diagnoses-select"
                            error={errors.diagnoses}
                            getOptionLabel={(d: Diagnosis) =>
                              d.code.includes('#-') ? d.description : `${d.code} - ${d.description}`
                            }
                            getOptionValue={(d: Diagnosis) => d.code.trim()}
                          />
                        )}
                        rules={{ required: true }}
                      />
                    </div>
                  </FormGroup>
                </div>
              </div>
            </div>
            {isNotEligibleForElectiveDiagnosis && billingInformation && (
              <p tw="text-gray-400 text-xs" data-testid="diagnosis-not-eligible">
                Elective diagnoses will not be covered by insurance and will lead to denials. Please submit a request
                via help desk for the client to be transitioned to private pay. To lock an evaluation note, please use
                the reason/diagnosis the client was being evaluated for to lock the note then remove it once complete.
                After the client has been transitioned to private pay, you may add the elective diagnosis.
              </p>
            )}

            {isNotEligibleForElectiveDiagnosis && !billingInformation && (
              <p tw="text-gray-400 text-xs" data-testid="diagnosis-missing-billing">
                This client is missing billing information. Please complete billing information for this client before
                completing their care plan.
              </p>
            )}
          </ModalBody>
          <ModalFooter>
            <div tw="mt-5 sm:mt-6 sm:grid sm:grid-cols-1 sm:gap-3 sm:grid-flow-row-dense">
              <span tw="flex w-full rounded-md shadow-sm">
                <Button
                  type="submit"
                  data-testid="client-diagnoses-submit"
                  variant="primary"
                  loading={isLoading}
                  disabled={isLoading || isNotEligibleForElectiveDiagnosis}
                  tw="inline-flex justify-center w-full px-4 py-2 text-base font-medium leading-6 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                >
                  Save
                </Button>
              </span>
            </div>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
