import { dynamicHttp } from '@expressable/utils';
import { MutableRefObject } from 'react';
import { AvailableTimesV2 } from 'domain/therapist-matcher/types';

/**
 * The highest cache age we accept without a refresh, in seconds.
 */
const CACHE_MAX_AGE = 5 * 60;

export type AvailabilityType = 'evaluation' | 'session';

export interface TherapistAvailableTimesPayloadV2 {
  calendarID: string;
  date?: string;
  reccuringNo?: number;
  duration: number;
  type: AvailabilityType;
  refreshCache?: MutableRefObject<boolean>;
  isAvailableNextWeek?: boolean;
}

export const getTherapistAvailableTimesV2 = ({
  calendarID,
  date,
  reccuringNo,
  duration,
  type,
  refreshCache,
  isAvailableNextWeek,
}: TherapistAvailableTimesPayloadV2): Promise<AvailableTimesV2> => {
  return dynamicHttp('health-record')
    .get<AvailableTimesV2>('/therapists/v2/available-times', {
      params: {
        calendarID,
        date,
        reccuringNo,
        duration,
        type,
        maxAge: refreshCache?.current ?? true ? undefined : CACHE_MAX_AGE,
      },
    })
    .then(response => {
      if (isAvailableNextWeek) {
        return {
          ...response.data,
          items: response.data.items?.filter(item => item.isAvailableNextWeek),
        };
      }
      return response.data;
    });
};

export interface ValidateTherapistTimePayload {
  calendarID: string;
  datetime: string;
  duration: number;
  timezone?: string;
}

export type ValidateTherapistTimeResponse = {
  datetime: string;
  errors: {
    keyword: string;
    message: string;
  }[];
}[];

export const validateTherapistTime = ({
  calendarID,
  datetime,
  duration,
  timezone,
}: ValidateTherapistTimePayload): Promise<ValidateTherapistTimeResponse> => {
  const a = dynamicHttp('health-record')
    .get('/therapists/validate-time', {
      params: {
        calendarID,
        datetime,
        duration,
        timezone,
      },
    })
    .then(r => {
      return r.data;
    });

  return a;
};
