import { useQuery } from 'react-query';
import { dynamicHttp } from '@expressable/utils';
import { DischargeQualityReview, EvaluationQualityReview } from 'domain/admin/quality-reviews/types';

const httpHealthRecordProxy = dynamicHttp('health-record-proxy');

export const getEvaluationQualityReviews = async (status: string) => {
  const { data } = await httpHealthRecordProxy.get<EvaluationQualityReview[]>(
    `/care-plans/quality-reviews/evaluation?status=${status}`,
  );
  return data;
};

export function useEvaluationQualityReviews(status: string) {
  return useQuery(['evaluationQualityReviews', status], () => getEvaluationQualityReviews(status));
}

export const getDischargeQualityReviews = async (status: string) => {
  const { data } = await httpHealthRecordProxy.get<DischargeQualityReview[]>(
    `/care-plans/quality-reviews/discharge?status=${status}`,
  );
  return data;
};

export function useDischargeQualityReviews(status: string) {
  return useQuery(['dischargeQualityReviews', status], () => getDischargeQualityReviews(status));
}
