import React from 'react';
import 'twin.macro';
import { Input, Label } from '@expressable/ui-library';
import cx from 'classnames';

// @TODO(juan): move this somewhere else, make it reusable

export interface OptionRadioProps<TOption> {
  /**
   * The value selected among all radios in this option group.
   */
  selectedOption: TOption;

  /**
   * Triggered when this option is selected.
   */
  onChange: (value: TOption) => void;

  /**
   * The value represented by this radio.
   */
  option: TOption;

  disabled?: boolean;

  children: React.ReactFragment;

  testId?: string;
}

function OptionRadio<TOption>({ selectedOption, onChange, option, disabled, children, testId }: OptionRadioProps<TOption>) {
  return (
    <Label tw="flex gap-2" data-testid={testId}>
      <Input
        type="radio"
        spacing="tight"
        checked={selectedOption === option}
        disabled={disabled}
        onChange={event => {
          if (event.target.checked) {
            onChange(option);
          }
        }}
      />

      <div
        className={cx({
          'text-gray-500 cursor-not-allowed': disabled,
        })}
      >
        {children}
      </div>
    </Label>
  );
}

export default OptionRadio;
