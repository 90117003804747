import { dynamicHttp } from '@expressable/utils';
import { useQuery } from 'react-query';

type AdminIntake = {
  clientID: string;
  clientCreatedAt: string;
  clientFirstName: string;
  clientLastName: string;
  evaluationDate: string | null;
  evaluationAcuityId: string;
  therapistFirstName: string;
  therapistLastName: string;
  therapistID: string;
};

const httpHealthRecordApi = dynamicHttp('health-record');

const getAdminPendingIntake = async (therapistID?: string) => {
  const param = therapistID ? `?therapistID=${therapistID}` : '';
  const { data } = await httpHealthRecordApi.get<AdminIntake[]>(`/admin/intake/require${param}`);

  return data;
};

export function useAdminPendingIntake(therapistID?: string) {
  return useQuery(['admin-pending-intake', therapistID], () => getAdminPendingIntake(therapistID));
}
