import dayjs from 'dayjs';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import {
  Card,
  Container,
  FormGroup,
  Label,
  Link,
  LoadingText,
  Select,
} from '@expressable/ui-library';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Table from 'components/Table';
import {
  EligibilityLabel,
  EligibilityStatus,
  EligibilityViewOptions,
  ReadableDateTimeFormat,
  UnknownEligibilityError,
} from 'domain/eligibility/constants';
import { Client } from 'domain/eligibility/types';
import { useClientsEligibility, useModifyClientEligibility } from 'hooks/use-clients-eligibility';
import usePermissions from 'hooks/use-permissions';
import 'twin.macro';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useHistory } from 'react-router-dom';

const Eligibility = () => {
  /** check permissions */
  const { isAdminOrClinicalManager } = usePermissions();
  const { push } = useHistory();

  if (!isAdminOrClinicalManager) {
    return (
      <Container data-testid="eligibility-restricted-access-error" tw="relative pl-0" size="large">
        <Card tw="mb-4 px-8">
          <FormGroup tw="w-full sm:w-1/2 md:w-1/3">
            <Label>
              <FontAwesomeIcon icon={faExclamationTriangle} tw="text-red-500 mr-2" />
              Only users in Admin Groups can access this section.
            </Label>
          </FormGroup>
        </Card>
      </Container>
    );
  }

  /** state hooks */
  const [clients, setClients] = useState<Client[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedView, setSelectedView] = useState(EligibilityViewOptions.Inactive);
  const [selectedClientId, setSelectedClientId] = useState('');

  /** custom hooks */
  const { addToast } = useToasts();
  const { manualElegibility: manualEligibility } = useFlags();
  const modifyClientEligiblityMutation = useModifyClientEligibility({
    onSuccess: useCallback(
      async (data, payload) => {
        const _clients = _.clone(clients).map(_client => {
          if (_client.id === payload.id) {
            _client.currentInsuranceEligibility = data.currentInsuranceEligibility;
            _client.eligibilityRunDateTime = data.eligibilityRunDateTime;
          }

          return _client;
        });

        setClients(_clients);
        setSelectedClientId('');
      },
      [clients],
    ),
    onError: useCallback(() => {
      setSelectedClientId('');
    }, []),
  });
  const { mutateAsync: modifyClientEligibility } = modifyClientEligiblityMutation;
  const {
    data: clientsEligibility,
    isError: isClientsEligibilityError,
    isFetching: isClientsEligibilityFetching,
  } = useClientsEligibility(selectedView.value === EligibilityStatus.Active);

  /** effects */
  useEffect(() => {
    if (isClientsEligibilityError) {
      setClients([]); // clean data
      addToast(
        `Something happened trying to retrieve data for the "${selectedView.label}" view. Please try again or contact support.`,
        { appearance: 'error', autoDismiss: true },
      );
    }
  }, [isClientsEligibilityError]);

  useEffect(() => {
    setIsLoading(isClientsEligibilityFetching);
  }, [isClientsEligibilityFetching]);

  useEffect(() => {
    if (Array.isArray(clientsEligibility)) {
      setClients(clientsEligibility);
    }
  }, [clientsEligibility]);

  return (
    <div tw="md:pr-5" data-testid="eligibility-container">
      <div tw="flex justify-between items-center flex-wrap">
        <h2 tw="text-2xl font-semibold mb-3">Eligibility</h2>

        {manualEligibility && (
          <Link tw="text-indigo-700" to="#" onClick={() => push('/admin/manual-eligibility')}>
            <span tw="align-middle font-bold">Check Eligibility</span>
          </Link>
        )}
      </div>
      <Card tw="mt-2 p-2 sm:px-4 sm:py-6">
        <div tw="flex items-baseline w-full lg:w-80 px-2 sm:px-3.5 sm:my-2">
          <label tw="block mr-2 mb-2 font-semibold text-sm">View</label>
          <div tw="flex-1">
            <Select
              tw="mr-4"
              id="eligibility-view-select"
              required
              value={selectedView}
              options={Object.values(EligibilityViewOptions)}
              onChange={(selectedOption: { value: EligibilityStatus; label: EligibilityLabel }) => {
                setSelectedView(selectedOption);
              }}
            />
          </div>
        </div>
        <div tw="flex flex-wrap md:flex-nowrap px-2 gap-3 sm:px-4 sm:gap-6">
          <Table data-testid="eligibility-table">
            <Table.Header>
              <Table.Row>
                <Table.Cell tw="p-1 sm:p-2 sm:py-3">Client Name</Table.Cell>
                <Table.Cell tw="hidden lg:table-cell">Payer Name</Table.Cell>
                <Table.Cell tw="hidden sm:table-cell">Eligibility Run</Table.Cell>
                <Table.Cell tw="hidden sm:table-cell">Eligibility Status</Table.Cell>
                {selectedView.value === EligibilityStatus.Inactive ? (
                  <Table.Cell tw="p-1 sm:p-2 sm:py-3">Errors</Table.Cell>
                ) : null}
                <Table.Cell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {isLoading ? ( // if it is loading / fetching data...
                <Table.Cell colSpan={6} tw="text-center py-5">
                  <LoadingText />
                </Table.Cell>
              ) : // if network call failed or no data
              clients.length === 0 ? (
                <Table.Cell colSpan={6} tw="font-semibold text-center py-5">
                  {isClientsEligibilityError
                    ? 'Something happened. Please try again or contact support.'
                    : `No clients found under the "${selectedView.label}" view.`}
                </Table.Cell>
              ) : (
                clients.map((client: Client, index: number) => (
                  <Table.Row key={index}>
                    <Table.Cell tw="text-indigo-700 font-semibold text-sm md:text-base p-1 sm:p-2 sm:py-3">
                      <Link target="_blank" to={{ pathname: `/clients/${client.id}` }}>
                        {client.clientFirstName} {client.clientLastName}
                      </Link>
                    </Table.Cell>
                    <Table.Cell tw="text-sm md:text-base hidden lg:table-cell">{client.currentPayer}</Table.Cell>
                    <Table.Cell tw="text-sm md:text-base hidden sm:table-cell">
                      {client.eligibilityRunDateTime &&
                        dayjs(client.eligibilityRunDateTime).format(ReadableDateTimeFormat)}
                    </Table.Cell>
                    <Table.Cell tw="text-sm md:text-base hidden sm:table-cell">
                      {client.currentInsuranceEligibility}
                    </Table.Cell>
                    {selectedView.value === EligibilityStatus.Inactive ? (
                      <Table.Cell tw="text-sm md:text-base p-1 sm:p-2 sm:py-3 break-words">
                        {client.eligibilityErrors ?? UnknownEligibilityError}
                      </Table.Cell>
                    ) : null}
                    <Table.Cell tw="text-indigo-700 cursor-pointer font-medium text-xs md:text-sm text-right">
                      {selectedClientId === client.id ? (
                        <LoadingText />
                      ) : (
                        <span
                          data-testid="re-run-eligibility"
                          onClick={async (e: React.SyntheticEvent) => {
                            if (selectedClientId) {
                              addToast('There is already an eligibility re-run in queue. Please wait.', {
                                appearance: 'warning',
                                autoDismiss: true,
                              });

                              return e.preventDefault();
                            }

                            setSelectedClientId(client.id);
                            await modifyClientEligibility(client);
                          }}
                        >
                          Re-run Eligibility
                        </span>
                      )}
                    </Table.Cell>
                  </Table.Row>
                ))
              )}
            </Table.Body>
          </Table>
        </div>
      </Card>
    </div>
  );
};

export default Eligibility;
