import React from 'react';
import clsx from 'classnames';

type HeaderProps = React.ComponentPropsWithRef<'thead'>;
type BodyProps = React.ComponentPropsWithRef<'tbody'>;
type FooterProps = React.ComponentPropsWithRef<'tfoot'>;
type RowProps = React.ComponentPropsWithRef<'tr'>;
type CellProps = React.ComponentPropsWithRef<'td'> & {
  isHeader?: boolean;
};

type TableProps = React.ComponentPropsWithRef<'table'>;

const Header: React.FC<HeaderProps> = ({ className, children, ...rest }) => (
  <thead className={clsx(className, 'text-black-500 font-medium')} {...rest}>
    {children}
  </thead>
);

const Body: React.FC<BodyProps> = ({ className, children, ...rest }) => (
  <tbody className={clsx(className)} {...rest}>
    {children}
  </tbody>
);

const Footer: React.FC<FooterProps> = ({ className, children, ...rest }) => (
  <tfoot className={clsx(className, 'text-black-500 font-medium flex mt-5 justify-end')} {...rest}>
    {children}
  </tfoot>
);

const Row: React.FC<RowProps> = ({ className, children, ...rest }) => (
  <tr className={clsx(className, 'border-b min-h-max')} {...rest}>
    {children}
  </tr>
);

const Cell: React.FC<CellProps> = ({ className, children, isHeader, ...rest }) => {
  if (isHeader)
    return (
      <th className={clsx(className, 'p-8 font-medium')} {...rest}>
        {children}
      </th>
    );
  return (
    <td className={clsx(className, 'p-2 py-3')} {...rest}>
      {children}
    </td>
  );
};
type TableComposition = {
  Header: typeof Header;
  Body: typeof Body;
  Footer: typeof Footer;
  Row: typeof Row;
  Cell: typeof Cell;
};

const Table: React.FC<TableProps> & TableComposition = ({ className, children, ...rest }) => (
  <table className={clsx(className, 'table-fixed w-full')} {...rest}>
    {children}
  </table>
);

Table.Header = Header;
Table.Body = Body;
Table.Footer = Footer;
Table.Row = Row;
Table.Cell = Cell;

export default Table;
