/* eslint-disable react/prop-types */
import { Button, Modal, ModalBody, ModalContent, ModalHeader, CloseButton } from '@expressable/ui-library';
import tw from 'twin.macro';

// A simple confirmation modal that can be reused. Meant for scenarios where certain action needs to be confirmed by the user before taking place
// Use together with the useConfirmModal hook

type Props = {
  className?: string;
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  children: React.ReactNode;

  confirmText?: string;
  cancelText?: string;
};

const ConfirmActionModal: React.FC<Props> = ({
  isOpen,
  className,
  onCancel,
  onConfirm,
  children,
  cancelText = 'Cancel',
  confirmText = 'Confirm',
}) => {
  return (
    <Modal isOpen={isOpen}>
      <ModalContent>
        <ModalHeader>
          <div tw="top-0 right-0 pb-8 pr-4">
            <CloseButton onClick={onCancel} />
          </div>
        </ModalHeader>
        <ModalBody css={[className, tw`flex flex-col text-center`]}>
          {children}
          <Button variant="primary" onClick={onConfirm} className="mt-6 mb-4">
            {confirmText}
          </Button>

          <Button variant="secondary" onClick={onCancel}>
            {cancelText}
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmActionModal;
