import TherapistSearch from 'components/therapistFilter';
import { TherapistSearchFilters } from 'hooks/common/useTherapistFilters/use-filtered-therapists';
import SubStep from 'pages/new-client-create/sub-step';
import { AppointmentType } from 'domain/appointment/constants';
import { FC, Dispatch, SetStateAction } from 'react';

export const FilterTherapistsStep: FC<{
  canGoNext: boolean;
  filters: TherapistSearchFilters;
  onChangeFilters: Dispatch<SetStateAction<TherapistSearchFilters>>;
  autoSelectTimezone: boolean;
  setAutoSelectTimezone: Dispatch<SetStateAction<boolean>>;
  appointmentType: string;
}> = ({ canGoNext, filters, onChangeFilters, autoSelectTimezone, setAutoSelectTimezone, appointmentType }) => {
  const title = `${appointmentType === AppointmentType.Therapy ? '3' : '2'}. Filter therapists`;

  return canGoNext ? (
    <SubStep title={title} expanded>
      <div className="flex flex-wrap gap-6 mb-12">
        <TherapistSearch
          filters={filters}
          onChange={onChangeFilters}
          autoSelectTimezone={autoSelectTimezone}
          setAutoSelectTimezone={setAutoSelectTimezone}
        />
      </div>
    </SubStep>
  ) : null;
};
