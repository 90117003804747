import { useDisclosure } from '@expressable/ui-library';
import { mapEntryToSelectOption } from 'components/client-attendance-modals/log-cancelation-modal';
import { findTimezoneOptionsByUtcOffset } from 'components/client-attendance-modals/log-reschedule-modal';
import { useTherapistAvailableTimesV2 } from 'components/therapistMatcher/data';
import { useContentfulEntry } from 'hooks/use-ContentfulData';
import { useCallDataOnTruthy } from 'hooks/use-call-data-on-truthy';
import moment, { Moment } from 'moment';
import { useState, useMemo, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useToasts } from 'react-toast-notifications';
import { ISingleAppointment, ReasonsContentfulEntry, SelectOption, Therapist } from 'types';
import { APPOINTMENT_TIME_10_AM, displayTimezoneOptions } from './add-appointments';
import { getSelectedTimeDayJs } from 'utils/time-options';
import { EditAppointmentPayload, useEditAppointment } from 'hooks/use-appointments';
import { envValues } from 'domain/client/constants';
import { isUndefined } from 'lodash';
import { appointmentLengthOptions, initialEvaluationLengthOptions } from 'domain/appointment/constants';
import { useCreatePendingReschedule } from 'hooks/use-pending-reschedule';
import { PendingRescheduleDTO } from 'domain/pending-reschedule/types';
import { EntryId } from 'hooks/use-contentful';
import useTherapists from 'hooks/use-therapists';
import dayjs from 'dayjs';
import { getCueError } from 'utils/error';

const isInitialEvaluationAppointment = (id: number | undefined) =>
  process.env.REACT_APP_NODE_ENV === 'production'
    ? id === envValues.production.evaluationTypeId
    : id === envValues.development.evaluationTypeId;

type UseEditAppointmentModalParams = {
  isOpen: boolean;
  clientId: string;
  appointmentData: ISingleAppointment | undefined;
  contactTimeZone?: string;
  onClose: () => void;
  onOpen: () => void;
  therapistData: Therapist;
  contactState?: string;
};
export default function useEditAppointmentModal({
  isOpen,
  onClose,
  onOpen,
  appointmentData,
  clientId,
  contactTimeZone = '',
  therapistData,
  contactState,
}: UseEditAppointmentModalParams) {
  const [cancelationReasonsEntry, , fetchContentfulData] = useContentfulEntry<ReasonsContentfulEntry>(
    EntryId.RescheduleReasons,
  );

  const cancelationReasons = cancelationReasonsEntry?.fields?.dropdownContent.map(mapEntryToSelectOption);
  const [date, setDate] = useState<Moment | null>(moment());
  const [time, setTime] = useState<{ label: string; value: number }>();
  const [displayTimezone, setDisplayTimezone] = useState<Record<string, string>>(displayTimezoneOptions[0]);
  const [rescheduleReasonOther, setRescheduleReasonOther] = useState<string>('');
  const [rescheduleReason, setRescheduleReason] = useState<string>('');
  const [appointmentLength, setAppointmentLength] = useState<SelectOption>(appointmentLengthOptions[1]);
  const [initialEvaluationLength, setInitialEvaluationLength] = useState<SelectOption>(
    initialEvaluationLengthOptions[0],
  );

  const [availableTimes, setAvailableTimes] = useState(true);
  const [isGuessTimezoneReady, setIsGuessTimezoneReady] = useState<boolean>(false);
  const [isSidebarAppointment, setIsSidebarAppointment] = useState(false);
  const [datePickerFocused, setDatePickerFocused] = useState(false);

  const [rescheduleBy, setRescheduleBy] = useState<'client' | 'therapist' | ''>('');
  const [rescheduleType, setRescheduleType] = useState<'link' | 'self-reschedule' | ''>('');

  const editAppointmentMutation = useEditAppointment();
  const createPendingRescheduleMutation = useCreatePendingReschedule();
  const { mutate: editAppointment } = editAppointmentMutation;
  const { mutateAsync: createPendingReschedule } = createPendingRescheduleMutation;
  const { data: therapists } = useTherapists('active');

  const { addToast } = useToasts();

  const isCurrentApptEvaluation = isInitialEvaluationAppointment(appointmentData?.appointmentTypeID);

  const duration = useMemo(() => {
    return isCurrentApptEvaluation ? Number(initialEvaluationLength.value) : Number(appointmentLength.value);
  }, [isCurrentApptEvaluation, appointmentLength.value, initialEvaluationLength.value]);

  const type = useMemo(() => {
    return isCurrentApptEvaluation ? 'evaluation' : 'session';
  }, [isCurrentApptEvaluation]);

  const therapist = useMemo(() => {
    return therapistData;
  }, [therapistData]);

  const { data: availableTimesV2, isLoading } = useTherapistAvailableTimesV2({
    calendarID: therapist?.acuityCalendarID,
    date: date?.format('YYYY-MM-DD'),
    duration,
    type,
  });

  const availableTimesOptions = availableTimesV2?.items?.map(availableTime => ({
    label: dayjs(availableTime.time).format('h:mm A'),
    value: getSelectedTimeDayJs(dayjs(availableTime.time)).value,
  }));

  const [selectedTherapist, setSelectedTherapist] = useState<SelectOption>({
    label: `${therapistData?.firstName} ${therapistData?.lastName}`,
    value: therapistData?.therapistID,
  });

  const therapistSelectOptions: SelectOption[] | undefined = useMemo(() => {
    if (!contactState)
      return therapists?.map(therapist => ({ label: therapist.therapistName, value: therapist.therapistEmail }));
    return therapists
      ?.filter(therapist => therapist.statesLicensed.includes(contactState))
      .map(therapist => ({ label: therapist.therapistName, value: therapist.therapistEmail }));
  }, [contactState, therapists]);

  useCallDataOnTruthy(isOpen, fetchContentfulData);

  const queryClient = useQueryClient();
  //We will set the 10:00am time to the dropdown when the user uncheck the "Only Show Available Times" checkbox
  useEffect(() => {
    if (availableTimesOptions?.length && availableTimes) {
      setTime(availableTimesOptions[0]);
    } else {
      setTime(APPOINTMENT_TIME_10_AM);
    }
  }, [availableTimesOptions?.length, availableTimes]);

  useEffect(() => {
    setDisplayTimezone(() => {
      const guessTimezone = displayTimezoneOptions.find(findTimezoneOptionsByUtcOffset);
      setIsGuessTimezoneReady(Boolean(guessTimezone ?? displayTimezoneOptions[1])); // if we can't guess the timezone, default to the first option in the list
      return guessTimezone ?? displayTimezoneOptions[1];
    });
  }, []);

  // append time to existing date every time the time changes or every time the date changes
  useEffect(() => {
    if (date && isGuessTimezoneReady) {
      date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).tz(displayTimezone.value);
      date.add(time?.value, 'hours');
    }
  }, [time, date, isGuessTimezoneReady]);

  useEffect(() => {
    const appointmentMomentDate = moment(appointmentData?.appointmentDateTime);
    const appointmentDuration = appointmentData?.appointmentDuration as number | string;

    const appointmentLengthDefaultValue =
      appointmentLengthOptions.find(option => option.value == appointmentDuration) ?? appointmentLengthOptions[1];
    setAppointmentLength(appointmentLengthDefaultValue);

    const initialEvaluationLengthDefaultValue =
      initialEvaluationLengthOptions.find(option => option.value == appointmentDuration) ??
      initialEvaluationLengthOptions[0];
    setInitialEvaluationLength(initialEvaluationLengthDefaultValue);

    setDate(appointmentMomentDate);
  }, [appointmentData]);

  useEffect(() => {
    if (rescheduleReason === 'other' || rescheduleReasonOther) {
      setRescheduleReasonOther('');
    }
    setAvailableTimes(true);
    setRescheduleReason('');
    setRescheduleType('');
    setSelectedTherapist({
      label: `${therapistData?.firstName} ${therapistData?.lastName}`,
      value: therapistData?.therapistID,
    });
  }, [isOpen]);

  const {
    isOpen: isLogCancelationModalOpen,
    onClose: onCloseLogCancelationModal,
    onOpen: onOpenLogCancelationModal,
  } = useDisclosure();

  const onSwitchToEditModal = () => {
    onOpen();
    onCloseLogCancelationModal();
  };

  const submit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    let formError = false;

    if (rescheduleType === 'link') {
      if (rescheduleReason === '') {
        formError = true;
        addToast('Reschedule Reason is missing', { appearance: 'error', autoDismiss: true });
        return;
      }
      const dto: PendingRescheduleDTO = {
        acuityAppointmentID: appointmentData?.acuityAppointmentID ?? '',
        rescheduledBy: rescheduleBy,
        rescheduleReason: rescheduleReason,
        clientID: clientId,
        rescheduleSourceType: 'rescheduling',
      };

      if (rescheduleReason.toLowerCase() === 'other') {
        dto.rescheduleReasonOther = rescheduleReasonOther;
      }
      await createPendingReschedule(dto);
      onClose();
      return;
    }
    if (!isUndefined(time) && date) {
      date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      date.add(time.value, 'hours');

      if (isUndefined(contactTimeZone)) {
        formError = true;
        const contactInfoErrorMessage = 'Primary contact information is missing';
        addToast(contactInfoErrorMessage, { appearance: 'error', autoDismiss: true });
      }

      if (rescheduleReason === '') {
        formError = true;
        addToast('Reschedule Reason is missing', { appearance: 'error', autoDismiss: true });
      }

      if (!formError) {
        const payload: EditAppointmentPayload = {
          appointmentData: {
            acuityId: appointmentData?.acuityAppointmentID,
            appointmentDate: date.toISOString(),
            contactTimeZone: displayTimezone.value,
            duration: !isCurrentApptEvaluation
              ? parseInt(appointmentLength.value)
              : parseInt(initialEvaluationLength.value),
          },
          clientID: clientId,
          rescheduleDetail: {
            rescheduledBy: rescheduleBy,
            rescheduledReason: rescheduleReason,
            therapistEmail: selectedTherapist.value,
          },
          override: !availableTimes,
        };

        if (rescheduleReason.toLowerCase() === 'other') {
          payload.rescheduleDetail.rescheduledReasonOther = rescheduleReasonOther;
        }
        editAppointment(payload, {
          onSuccess: () => {
            onClose();
            queryClient.invalidateQueries();
          },
          onError: err => {
            queryClient.invalidateQueries();
            const error = getCueError(err);
            addToast(error?.message || 'Something Went Wrong', { appearance: 'error', autoDismiss: true });
          },
        });
      }
    }
  };

  const handleOnChangeRescheduledBy = (e: { target: { checked: boolean; value: string } }) => {
    const isChecked = e.target.checked;
    const value = e.target.value;
    setRescheduleBy(isChecked && value === 'client' ? 'client' : 'therapist');
  };

  const handleOnChangeRescheduleType = (value: 'link' | 'self-reschedule') => {
    setRescheduleType(value);
  };

  return {
    date,
    setDate,
    time,
    setTime,
    datePickerFocused,
    setDatePickerFocused,
    isSidebarAppointment,
    setIsSidebarAppointment,
    displayTimezone,
    setDisplayTimezone,
    isGuessTimezoneReady,
    setIsGuessTimezoneReady,
    cancelationReasons,
    rescheduleReasonOther,
    setRescheduleReasonOther,
    rescheduleReason,
    setRescheduleReason,
    rescheduleBy,
    setRescheduleBy,
    availableTimes,
    setAvailableTimes,
    editAppointmentMutation,
    createPendingRescheduleMutation,
    editAppointment,
    addToast,
    appointmentLength,
    setAppointmentLength,
    initialEvaluationLength,
    setInitialEvaluationLength,
    isCurrentApptEvaluation,
    duration,
    therapist,
    availableTimesV2,
    isLoading,
    availableTimesOptions,
    queryClient,
    isLogCancelationModalOpen,
    onCloseLogCancelationModal,
    onOpenLogCancelationModal,
    onSwitchToEditModal,
    submit,
    handleOnChangeRescheduledBy,
    appointmentLengthOptions,
    initialEvaluationLengthOptions,
    rescheduleType,
    setRescheduleType,
    handleOnChangeRescheduleType,
    setSelectedTherapist,
    selectedTherapist,
    therapistSelectOptions,
  };
}
