/* eslint-disable react/display-name */
import React from 'react';
import { Event } from 'types';
import 'twin.macro';
import SimpleEvent from 'utils/event-renderers/simple-event';
import { faCalendarEdit } from '@fortawesome/pro-solid-svg-icons';
import { upperFirst } from 'lodash';

export interface UpdateSingleValueRendererOptions {
  fieldName: string;

  formatValue?: (value: any) => any;

  description: string;
}

export interface UpdateSingleValueProps<T> {
  event: Event<T>;
  isLastEvent: boolean;
}

export default function updateSingleValueRenderer<T>(
  versionToOptions: Record<string, UpdateSingleValueRendererOptions>,
) {
  return {
    default: (version: string) => {
      const { fieldName, formatValue, description } = versionToOptions[version];

      return (props: UpdateSingleValueProps<T>) => {
        const oldFieldName = `old${upperFirst(fieldName)}`;
        const newFieldName = `new${upperFirst(fieldName)}`;
        const oldValue = (props.event.detail as any)[oldFieldName];
        const newValue = (props.event.detail as any)[newFieldName];

        return (
          <SimpleEvent {...props} description={`updated ${description}`} icon={faCalendarEdit}>
            <div>{formatValue ? formatValue(newValue) : newValue}</div>
            <div tw="line-through">{formatValue ? formatValue(oldValue) : oldValue}</div>
          </SimpleEvent>
        );
      };
    },
  };
}
