import { Label, Select } from '@expressable/ui-library';
import { ControlledFormField } from 'components/Forms/FormFieldControlled';
import { hhmmTimeOptions } from 'utils/time-options';
import DayJsDatePicker from 'components/Forms/DayjsPicker';
import {
  EvaluationEntriesIds,
  useEvaluationContentfulEntry,
  useEvaluationCPTs,
} from 'hooks/use-evaluation-contentful-provider';
import { EvaluationDiagnoseCPT, mapContentfulDiagnosesToSelectOptions } from '../helpers';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { CptCode, SelectOption } from 'types';

type EvaluationContentfulDiagnoses = {
  electiveDiagnoses: string[];
  icd10Diagnosis: EvaluationDiagnoseCPT[];
};

export default function AppointmentEvaluationSection(content: any) {
  const form = useFormContext();
  const duration = content?.content?.note?.content?.duration;

  // #region Evaluation diagnoses
  const { data: evaluationDiagnoses } = useEvaluationContentfulEntry<EvaluationContentfulDiagnoses>(
    EvaluationEntriesIds.EvaluationDiagnoses,
  );

  const evaluationDiagnosesOptions = useMemo(() => {
    if (!evaluationDiagnoses) {
      return [];
    }

    return [
      ...(evaluationDiagnoses.icd10Diagnosis
        ? mapContentfulDiagnosesToSelectOptions(evaluationDiagnoses.icd10Diagnosis)
        : []),
      ...(evaluationDiagnoses.electiveDiagnoses
        ? evaluationDiagnoses.electiveDiagnoses.map((ed: any) => ({ label: ed, value: ed }))
        : []),
    ];
  }, [evaluationDiagnoses]);
  // #endregion

  // #region CPT
  const { evaluationCPTsOptions } = useEvaluationCPTs();
  const cpt = (form.watch('cpt') || []) as SelectOption<CptCode>[];
  // #endregion

  return (
    <div className="space-y-6 mb-4">
      <div className="flex gap-6 w-full">
        <div className="flex flex-col w-1/4">
          <ControlledFormField
            id="appointmentOnDate"
            name="appointmentOnDate"
            label="Appointment On"
            component={DayJsDatePicker}
          />
        </div>
        <ControlledFormField
          containerClass="flex flex-col w-1/5 justify-end"
          component={Select}
          maxWidth="large"
          spacing="tight"
          tw={'min-w-[550px]'}
          name="appointmentOnHour"
          label=""
          options={hhmmTimeOptions}
        />

        <div className="flex flex-col w-1/6">
          <Label>Duration</Label>
          <span className="mt-2">{`${duration} minutes`}</span>
        </div>
      </div>
      <div className="flex gap-6 w-full">
        <ControlledFormField
          label="CPT"
          containerClass="flex flex-col w-1/2 justify-start"
          component={Select}
          spacing="tight"
          tw={'w-full'}
          name="cpt"
          isSearchable
          options={evaluationCPTsOptions.filter(
            option => !cpt.some(selectedCpt => selectedCpt.value.code === option.value.code),
          )}
          isMulti
        />
      </div>

      <div className="flex gap-6 w-full">
        <ControlledFormField
          containerClass="flex flex-col w-1/2 justify-start"
          component={Select}
          spacing="tight"
          name="evaluationDiagnosis"
          label="Evaluation Diagnosis"
          isMulti
          options={evaluationDiagnosesOptions}
          isSearchable
        />
      </div>
    </div>
  );
}
