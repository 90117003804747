import React, { useEffect, useMemo, useState, useRef } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useDebouncedCallback } from 'use-debounce';
import CardList from 'components/card-list';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  CloseButton,
  Label,
  ModalFooter,
  Button,
  Select,
  Loader,
  Input,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Card,
  Textarea,
  Link,
} from '@expressable/ui-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'twin.macro';
import { SelectOption } from 'types';
import { ContentfulSpaces, useContentfulEntries } from 'hooks/use-contentful';
import { getCustomStructuredActivityLink, timeRequiredOptions } from 'domain/clinical-pathways/contants';
import {
  ClientAssignment,
  CustomStructuredActivityContentful,
  CustomSAMaterialContentful,
} from 'domain/clinical-pathways/types';
import * as fileHelper from 'domain/shared/file-helper';
import { useLearningPaths } from 'hooks/use-learning-paths';
import { getMicroserviceBaseUrl, useCurrentUserContext } from '@expressable/utils';
import { FileUpload, useCreateCustomStructuredActivity } from 'hooks/use-clinical-pathways';
import { useGenerateCustomStructuredActivity } from 'hooks/use-generate-structured-activity';
import { IFile, useDeleteFile, useFiles, useGenerateUploadUrl, useUploadFile } from 'hooks/use-files';
import useShareLink from 'hooks/use-share-link';
import { useToasts } from 'react-toast-notifications';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { Badge } from '@expressable/ui-library';
import { useTherapist } from 'hooks/use-therapists';
import * as Sentry from '@sentry/react';

export const buildContentfulQuery = ({
  searchKey,
  ownerTherapistId,
}: {
  searchKey: string;
  ownerTherapistId: string;
}) => {
  return {
    ['query']: searchKey ?? '',
    ['fields.ownerTherapistId']: ownerTherapistId,
  };
};

enum ModalTab {
  NewCustom = 0,
  AuthoredByYou = 1,
}

const MAXIMUM_STEPS = 5;

interface FormContent {
  therapyStrategy?: SelectOption<string>;
  title: string;
  practiceOverview: string;
  activityGoals: string;
  timeRequired: SelectOption<string>;
  whatClientNeedForActivity?: string;
  steps: {
    title: string;
    description: string;
  }[];
  whatShouldClientLookFor: string;
  whatShouldClientTryIfDifficult: string;
  fileInstructions?: string;
  extraInstructions?: string;
}

export interface Props {
  clientId: string;
  currentCustomSAs: ClientAssignment[];
  isOpen: boolean;
  onBrowseStructuredActivity: () => void;
  onClose: () => void;
  onSave: ({ customSAs }: { customSAs: Partial<CustomStructuredActivityContentful>[] }) => void;
}

export function AddCustomStructuredActivityModal({
  clientId,
  currentCustomSAs = [],
  isOpen,
  onClose,
  onSave,
  onBrowseStructuredActivity,
}: Props) {
  const [currentTab, setCurrentTab] = useState<ModalTab>(ModalTab.NewCustom);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedItems, setSelectedItems] = useState<CustomStructuredActivityContentful[]>([]);
  const [selectedMaterials, setSelectedMaterials] = useState<CustomSAMaterialContentful[]>([]);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [uploadedFileKey, setUploadedFileKey] = useState<string>();
  const [uploadedFile, setUploadedFile] = useState<FileUpload>();

  const {
    handleSubmit,
    reset,
    formState: { errors },
    register,
    setValue,
    getValues,
    setError,
    clearErrors,
    control,
  } = useForm<FormContent>({
    defaultValues: {
      therapyStrategy: undefined,
      title: undefined,
      practiceOverview: undefined,
      activityGoals: undefined,
      timeRequired: undefined,
      whatClientNeedForActivity: undefined,
      steps: [{ title: '', description: '' }],
      whatShouldClientLookFor: undefined,
      whatShouldClientTryIfDifficult: undefined,
      fileInstructions: undefined,
      extraInstructions: undefined,
    },
  });

  const {
    fields: currentSteps,
    append: appendStep,
    remove: removeStep,
  } = useFieldArray({
    control,
    name: 'steps',
  });

  const resetContent = () => {
    reset({
      therapyStrategy: undefined,
      title: undefined,
      practiceOverview: undefined,
      activityGoals: undefined,
      timeRequired: undefined,
      whatClientNeedForActivity: undefined,
      steps: [{ title: '', description: '' }],
      whatShouldClientLookFor: undefined,
      whatShouldClientTryIfDifficult: undefined,
      fileInstructions: undefined,
      extraInstructions: undefined,
    });

    setCurrentTab(ModalTab.NewCustom);
    setSearchTerm('');
    setSelectedItems([]);
    setSelectedMaterials([]);
    setIsUploading(false);
    setUploadedFileKey(undefined);
    setUploadedFile(undefined);
  };

  const onCloseHandler = () => {
    resetContent();
    onClose();
  };

  const currentUser = useCurrentUserContext();
  const { data: therapistData, isLoading: isTherapistDataLoading } = useTherapist(currentUser?.email);
  const titleRef = useRef<HTMLInputElement>(null);
  const { data: learningPathsData, isLoading: isLoadingLearningPath } = useLearningPaths();
  const { mutateAsync: createCustomStructuredActivity, isLoading: isCreatingCustomSA } =
    useCreateCustomStructuredActivity();
  const { mutateAsync: generateCustomStructuredActivity, isLoading: isGeneratingCustomStructuredActivity } =
    useGenerateCustomStructuredActivity();
  const supportedBedrockFormats = ['application/pdf', 'image/png', 'image/jpeg'];

  const learningPathOptions: SelectOption[] | undefined = useMemo(() => {
    if (isLoadingLearningPath) {
      return undefined;
    } else {
      return learningPathsData?.map(({ id, title, category }) => ({
        label: `${title} (${category?.[0]?.treatmentAreaCategory})`,
        value: id,
      }));
    }
  }, [learningPathsData]);

  // it filters custom structured activities from Contentful
  const {
    data: customSAContentful,
    isLoading: isCustomSALoading,
    refetch: refetchCustomSAContentful,
  } = useContentfulEntries<CustomStructuredActivityContentful[]>({
    space: ContentfulSpaces.ClinicalCareExperience,
    contentType: 'customStructuredActivity',
    limit: 400,
    order: ['fields.title'],
    params: buildContentfulQuery({
      searchKey: searchTerm,
      ownerTherapistId: currentUser?.email,
    }),
  });

  const onSubmitNewCustomSAHandler = async (formContent: FormContent) => {
    const createdCustomSAContentful = await createCustomStructuredActivity({
      learningPathID: formContent.therapyStrategy?.value,
      title: formContent.title,
      practiceOverview: formContent.practiceOverview,
      activityGoals: formContent.activityGoals,
      timeRequired: formContent.timeRequired.value,
      whatClientNeedForActivity: formContent.whatClientNeedForActivity,
      steps: [...formContent.steps],
      whatShouldClientLookFor: formContent.whatShouldClientLookFor,
      whatShouldClientTryIfDifficult: formContent.whatShouldClientTryIfDifficult,
      userEmail: currentUser?.email,
      uploadedFile: uploadedFile,
    });

    const customSAToSave = {
      id: createdCustomSAContentful.id,
      title: formContent.title,
      practiceOverview: formContent.practiceOverview,
      selectedMaterial: createdCustomSAContentful.associatedMaterials?.length
        ? {
            customSAId: createdCustomSAContentful.id,
            materialId: createdCustomSAContentful.associatedMaterials[0].id,
            materialTitle: createdCustomSAContentful.associatedMaterials[0].title,
            materialUrl: createdCustomSAContentful.associatedMaterials[0].file.url,
          }
        : undefined,
    } as CustomStructuredActivityContentful;

    onSave({ customSAs: [{ ...customSAToSave }] });

    onCloseHandler();
  };

  const onSubmitSelectedCustomSAHandler = (formContent: FormContent) => {
    const customSAsToSave = selectedItems.map(customSA => {
      const targetMaterial = selectedMaterials?.find(item => item.customSAId === customSA.id);
      return {
        ...customSA,
        selectedMaterial: targetMaterial,
      } as CustomStructuredActivityContentful;
    });

    onSave({ customSAs: [...customSAsToSave] });
    onCloseHandler();
  };

  useEffect(() => {
    resetContent();
  }, [isOpen]);

  useEffect(() => {
    refetchCustomSAContentful();
  }, [searchTerm]);

  const setSearchTermDebounced = useDebouncedCallback(value => {
    setSearchTerm(value);
  }, 500);

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    customSA: CustomStructuredActivityContentful,
  ) => {
    if (event.target.checked) {
      setSelectedItems([...selectedItems, { ...customSA }]);

      // if contains material
      if (customSA.associatedMaterials?.length) {
        const wasMaterialSelected = selectedMaterials?.find(item => item.customSAId === customSA.id);
        if (!wasMaterialSelected) {
          // when the document was not selected, it means we need to pick the first option available in the dropdown
          setSelectedMaterials([
            ...selectedMaterials,
            {
              customSAId: customSA.id,
              materialId: customSA.associatedMaterials[0].id,
              materialTitle: customSA.associatedMaterials[0].title,
              materialUrl: customSA.associatedMaterials[0].file?.url,
            },
          ]);
        }
      }
    } else {
      setSelectedItems([...selectedItems.filter(item => item.id !== customSA.id)]);
    }
  };

  const handleMaterialChange = (
    selectedMaterial: SelectOption<string>,
    customSA: CustomStructuredActivityContentful,
  ) => {
    const targetUploadedMaterial = customSA.associatedMaterials?.find(
      material => material.id === selectedMaterial.value,
    );
    if (!targetUploadedMaterial) {
      // safe checker to make sure nobody makes unexpected changes
      console.error('Something went wrong. Selected Material not found!');
    }

    // we filter out the custom SA id from the list (if that exists). We don't want to include duplicated items
    const filteredMaterials = selectedMaterials.filter(item => item.customSAId !== customSA.id);

    setSelectedMaterials([
      ...filteredMaterials,
      {
        customSAId: customSA.id,
        materialId: targetUploadedMaterial!.id,
        materialTitle: targetUploadedMaterial!.title,
        materialUrl: targetUploadedMaterial!.file?.url,
      },
    ]);
  };

  const { mutate: generateUploadUrl } = useGenerateUploadUrl();
  const { mutate: uploadFile } = useUploadFile();
  const { mutate: deleteFile, isLoading: isDeletingFile } = useDeleteFile();
  const { data: uploadedFilesData } = useFiles(clientId);
  const { mutateAsync: shareLink } = useShareLink();
  const { addToast } = useToasts();

  const shareFile = async (s3File: IFile) => {
    setUploadedFile(undefined);

    try {
      const response = await shareLink(s3File.link);
      const baseUrl = getMicroserviceBaseUrl('link-sharing');
      setUploadedFile({
        ...s3File,
        sharableLink: `${baseUrl}/${response.key}`,
      });
    } catch (error) {
      addToast('Something Went Wrong', { appearance: 'error', autoDismiss: true });
    } finally {
      setIsUploading(false);
    }
  };

  if (uploadedFileKey && uploadedFilesData && uploadedFilesData.length > 0) {
    const uploadedFileData = uploadedFilesData.find(file => file.key === uploadedFileKey);
    if (uploadedFileData) {
      setUploadedFileKey('');
      shareFile(uploadedFileData);
    }
  }

  const uploadEncodedFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsUploading(true);

    const filesSelected = event.target.files;

    if (filesSelected!.length > 0) {
      const fileContent = filesSelected![0];

      // it checks file content type
      if (!fileHelper.ALLOWED_CONTENT_TYPES.includes(fileContent.type)) {
        addToast(`The content type "${fileContent.type}" is not allowed.`, { appearance: 'error', autoDismiss: true });
        setIsUploading(false);
        return;
      }

      const fileReader = new FileReader();
      fileReader.onload = fileLoadedEvent => {
        const fileData = fileLoadedEvent.target!.result as string;
        const [mimeData, file] = fileData.split(';');
        const [, mime] = mimeData.split(':');

        const generateUploadUrlPayload = {
          clientID: clientId,
          mime,
          fileName: fileHelper.getFileNameWithoutExtension(fileContent.name),
          fileExtension: fileHelper.getFileExtension(fileContent.name),
        };

        generateUploadUrl(generateUploadUrlPayload, {
          onSuccess: ({ key, url }) => {
            const imageData = file.substr(7, file.length);
            const buffer = Buffer.from(imageData, 'base64');
            uploadFile(
              { url, mime, buffer, clientID: clientId },
              {
                onError: () => {
                  setIsUploading(false);
                },
                onSuccess: () => {
                  setUploadedFileKey(key);
                },
              },
            );
          },
        });
      };

      fileReader.readAsDataURL(fileContent);
    }
  };

  const handleDeleteFile = () => {
    if (uploadedFile) {
      deleteFile({ clientID: clientId, key: uploadedFile.key });
      setUploadedFile(undefined);
      setUploadedFileKey(undefined);
    }
  };

  if (isCustomSALoading) {
    return (
      <div tw="flex justify-center items-center">
        <Loader type="ring" />
      </div>
    );
  }

  const totalSelected = selectedItems?.length || 0;

  const currentCustomSAIDs = currentCustomSAs.map(item => item.customStructuredActivityContentfulEntryID) || [];
  const filteredCustomSAContentful = customSAContentful?.filter(item => !currentCustomSAIDs.includes(item.id));

  const isUploadingOrDeletingFile = isUploading || isDeletingFile;

  const handleGenerateCustomStructuredActivity = async () => {
    clearErrors();

    const titleValue = getValues('title');
    const fileInstructions = getValues('fileInstructions');
    const extraInstructions = getValues('extraInstructions');
    const therapyStrategy = getValues('therapyStrategy');

    if (!titleValue) {
      setError('title', {
        type: 'manual',
        message: 'Please enter a title for this Structured Activity',
      });

      titleRef.current?.focus();
      return;
    }

    if (uploadedFile) {
      if (!supportedBedrockFormats.includes(uploadedFile.contentType)) {
        addToast(`File format '${uploadedFile.contentType}' not supported for SA suggestion`, {
          appearance: 'error',
          autoDismiss: true,
        });
        return;
      }
    }

    type FieldKey =
      | 'practiceOverview'
      | 'activityGoals'
      | 'steps'
      | 'timeRequired'
      | 'whatClientNeedForActivity'
      | 'whatShouldClientLookFor'
      | 'whatShouldClientTryIfDifficult';

    try {
      const generatedCustomStructuredActivity = await generateCustomStructuredActivity({
        clientId,
        title: titleValue,
        fileUrl: uploadedFile?.link,
        fileContentType: uploadedFile?.contentType,
        fileInstructions: uploadedFile?.link ? fileInstructions : undefined,
        extraInstructions: extraInstructions ? extraInstructions : undefined,
        therapyStrategyId: therapyStrategy ? therapyStrategy.value : undefined,
        therapyStrategyName: therapyStrategy ? therapyStrategy.label : undefined,
      });

      const content = JSON.parse(generatedCustomStructuredActivity?.content);

      const contentSteps = content.steps.map((step: { title: string; description: string }) => ({
        title: step?.title,
        description: step?.description,
      }));

      const fieldsToUpdate: Record<FieldKey, any> = {
        practiceOverview: content?.practiceOverview,
        activityGoals: content?.activityGoals,
        steps: contentSteps,
        timeRequired: content?.timeRequired,
        whatClientNeedForActivity: content?.whatClientNeedForActivity,
        whatShouldClientLookFor: content?.whatShouldClientLookFor,
        whatShouldClientTryIfDifficult: content?.whatShouldClientTryIfDifficult,
      };

      (Object.entries(fieldsToUpdate) as [FieldKey, any][]).forEach(([field, value]) => {
        setValue(field, value);
      });
    } catch (error) {
      Sentry.captureException(error);
      console.error(`Error while generating a custom SA. ${error}`);
    }
  };

  const isFormDisabled =
    isUploadingOrDeletingFile || isCustomSALoading || isCreatingCustomSA || isGeneratingCustomStructuredActivity;

  return (
    <Modal portal isOpen={isOpen}>
      <ModalContent>
        <ModalHeader>
          <div tw="flex flex-col items-center justify-center">
            <div tw="flex items-center justify-center w-12 h-12 mx-auto bg-indigo-100 rounded-full mb-5">
              <FontAwesomeIcon tw="text-2xl text-indigo-700" icon="file-alt" />
            </div>
            <h3 tw="text-lg text-center font-medium text-gray-900 leading-6 mb-5" id="modal-headline">
              Add Custom Structured Activity
            </h3>
            <h4 tw="text-gray-900 text-center mb-5">
              Create a custom structured activity for your client to practice or assign an activity that you have
              authored in the past.
            </h4>
            <Link onClick={onBrowseStructuredActivity} to="#" tw="text-indigo-700">
              Browse available structured activities
              <FontAwesomeIcon tw="text-xs ml-2" icon="chevron-right" />
            </Link>
          </div>
          <CloseButton onClick={() => onCloseHandler()} testId="close-custom-sa-top" />
        </ModalHeader>
        <ModalBody>
          <Tabs index={currentTab} onChange={setCurrentTab} defaultIndex={ModalTab.NewCustom} tw="mt-4">
            <TabList>
              <Tab id="newCustom">New Custom</Tab>
              <Tab id="authoredByYou">Authored By You ({filteredCustomSAContentful?.length || 0})</Tab>
            </TabList>
            <TabPanels tw="mt-7">
              <TabPanel>
                <form onSubmit={handleSubmit(onSubmitNewCustomSAHandler)} data-testid="form-add-custom-sa">
                  <Label optional={true} tw="font-medium text-sm mt-5">
                    Therapy Strategy
                  </Label>
                  <Controller
                    name="therapyStrategy"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        id="therapy-strategy-custom-sa"
                        options={learningPathOptions}
                        isSearchable
                        isClearable
                        error={errors.therapyStrategy}
                        tw="mt-2"
                        className={`${isFormDisabled ? 'cursor-not-allowed' : ''}`}
                        disabled={isFormDisabled}
                      />
                    )}
                  />

                  <Label tw="font-medium text-sm">Structured Activity Title</Label>

                  <div tw="text-xs text-gray-400 mb-1 mt-1">
                    Example: Communication Temptations for Making Ice Cream Sundaes
                  </div>
                  <Input
                    {...register('title', { required: currentTab === ModalTab.NewCustom })}
                    data-testid="title-custom-sa"
                    type="text"
                    error={errors.title}
                    tw="mt-1 mb-1 w-full"
                    maxLength={250}
                    ref={titleRef}
                    className={`${isFormDisabled ? 'cursor-not-allowed' : ''}`}
                    disabled={isFormDisabled}
                  />

                  <Label optional={true} tw="font-medium text-sm">
                    Attachments/Materials
                  </Label>

                  {isUploadingOrDeletingFile ? (
                    <div tw="text-gray-400 text-sm leading-3 -ml-2 mt-1 mb-4">
                      <FontAwesomeIcon tw="text-xs ml-2 text-gray-400" icon={faCircleNotch} spin />
                    </div>
                  ) : (
                    <div tw="flex flex-col mb-4">
                      {uploadedFile && (
                        <>
                          <div tw="flex justify-between">
                            <Link
                              to={{ pathname: uploadedFile.sharableLink }}
                              target="_blank"
                              tw="max-w-[70%] whitespace-nowrap overflow-hidden overflow-ellipsis"
                              title={uploadedFile.file}
                            >
                              {uploadedFile.file}
                            </Link>
                            <Link onClick={handleDeleteFile} to="#" tw="text-indigo-700">
                              Remove
                            </Link>
                          </div>
                          {!isTherapistDataLoading &&
                            therapistData?.aiTranscriptProcessing &&
                            supportedBedrockFormats.includes(uploadedFile.contentType) && (
                              <div>
                                <Label optional={true} tw="mt-5 font-medium text-sm">
                                  File Instructions
                                </Label>
                                <div tw="text-xs text-gray-400 mb-1 mt-1">
                                  Briefly describe this attachment and any instructions you would like included.
                                </div>
                                <Textarea
                                  {...register('fileInstructions')}
                                  data-testid="file-instructions-custom-sa"
                                  tw="w-full mt-2 mb-1"
                                  rows={3}
                                  maxLength={500}
                                  className={`${isFormDisabled ? 'cursor-not-allowed' : ''}`}
                                  disabled={isFormDisabled}
                                />
                              </div>
                            )}
                        </>
                      )}

                      {!uploadedFile && (
                        <label
                          data-testid="upload-custom-sa-upload-label"
                          tw="text-sm relative"
                          className={`${isFormDisabled ? 'cursor-not-allowed text-gray-400' : 'text-indigo-700'}`}
                        >
                          Upload file
                          <input
                            data-testid="upload-custom-sa-upload-input"
                            type="file"
                            accept={fileHelper.ALLOWED_CONTENT_TYPES.join(', ')}
                            tw="absolute -left-24 w-56 opacity-0"
                            onChange={uploadEncodedFile}
                            title=""
                            className={`${isFormDisabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                            disabled={isFormDisabled}
                          />
                        </label>
                      )}
                    </div>
                  )}

                  {!isTherapistDataLoading && therapistData?.aiTranscriptProcessing && (
                    <div>
                      <Label optional={true} tw="mt-5 font-medium text-sm">
                        Prompt Instructions
                      </Label>
                      <div tw="text-xs text-gray-400 mb-1 mt-1">
                        Briefly describe the activity and include any optional client interests to incorporate.
                      </div>
                      <Textarea
                        {...register('extraInstructions')}
                        data-testid="extra-instructions-custom-sa"
                        tw="w-full mt-2 mb-1"
                        rows={3}
                        maxLength={500}
                        className={`${isFormDisabled ? 'cursor-not-allowed' : ''}`}
                        disabled={isFormDisabled}
                      />
                      <button
                        type="button"
                        onClick={handleGenerateCustomStructuredActivity}
                        className={`mt-4 mb-6 w-full py-2 font-semibold border rounded-lg shadow-md border-gray-200 ${
                          isFormDisabled ||
                          (uploadedFile && !supportedBedrockFormats.includes(uploadedFile.contentType))
                            ? 'cursor-not-allowed bg-gray-300 text-gray-400'
                            : 'bg-white text-indigo-700 focus:border-indigo-700'
                        }`}
                        disabled={
                          isFormDisabled ||
                          (uploadedFile && !supportedBedrockFormats.includes(uploadedFile.contentType))
                        }
                        data-testid="generate-custom-sa-submit"
                      >
                        {isGeneratingCustomStructuredActivity ? (
                          <div tw="text-gray-400 py-1 text-sm leading-3 -ml-2">
                            Hang tight…
                            <FontAwesomeIcon tw="text-sm ml-2 text-gray-400" icon={faCircleNotch} spin />
                          </div>
                        ) : (
                          <div>
                            Generate Structured Activity
                            <span>
                              <Badge variant="waiting" tw="uppercase h-4 ml-2">
                                beta
                              </Badge>
                            </span>
                            {uploadedFile && !supportedBedrockFormats.includes(uploadedFile.contentType) && (
                              <div tw="inline-block relative">
                                <div className="group">
                                  <FontAwesomeIcon tw="text-gray-700 text-sm ml-2" icon="exclamation-circle" />
                                  <div tw="border border-solid bg-white rounded-lg absolute border-gray-200 left-0 shadow-md w-72 text-xs p-3 z-10 invisible group-hover:visible text-black font-normal">
                                    <p>
                                      Only PDF and images (.png and .jpeg) are currently supported with generated
                                      structured activities.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </button>
                    </div>
                  )}

                  <Label tw="font-medium text-sm">Practice Overview</Label>
                  <div tw="text-xs text-gray-400 mb-1 mt-1">
                    What is the activity? Describe what you want your client to practice. Example: In this activity you
                    will help your child produce three word phrases as you present communication temptation
                    opportunities while making ice cream sundaes together. Communication temptations are scenarios that
                    are highly motivating and encourage children to communicate.
                  </div>
                  {isGeneratingCustomStructuredActivity ? (
                    <div className="w-full py-8 mt-2 mb-2 border border-1 border-gray-300 rounded bg-gray-200 animate-pulse" />
                  ) : (
                    <Textarea
                      {...register('practiceOverview', { required: currentTab === ModalTab.NewCustom })}
                      data-testid="practice-overview-custom-sa"
                      error={errors.practiceOverview}
                      tw="w-full mt-2"
                      rows={5}
                      maxLength={1000}
                      className={`${isFormDisabled ? 'cursor-not-allowed' : ''}`}
                      disabled={isFormDisabled}
                    />
                  )}

                  <Label tw="font-medium text-sm">Activity Goals</Label>
                  <div tw="text-xs text-gray-400 mb-1 mt-1">
                    Why is this activity important? Describe what your client should accomplish practicing this
                    activity. Example: This activity helps support skills for increasing phrase length and ability to
                    communicate wants and needs.
                  </div>

                  {isGeneratingCustomStructuredActivity ? (
                    <div className="w-full py-8 mt-2 mb-2 border border-1 border-gray-300 rounded bg-gray-200 animate-pulse" />
                  ) : (
                    <Textarea
                      {...register('activityGoals', { required: currentTab === ModalTab.NewCustom })}
                      data-testid="activity-goals-custom-sa"
                      error={errors.activityGoals}
                      tw="w-full mt-2"
                      rows={5}
                      maxLength={5000}
                      className={`${isFormDisabled ? 'cursor-not-allowed' : ''}`}
                      disabled={isFormDisabled}
                    />
                  )}

                  <Label tw="font-medium text-sm">Time Required</Label>

                  {isGeneratingCustomStructuredActivity ? (
                    <div className="w-full py-8 mt-2 mb-2 border border-1 border-gray-300 rounded bg-gray-200 animate-pulse" />
                  ) : (
                    <Controller
                      name="timeRequired"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          id="time-required-custom-sa"
                          options={timeRequiredOptions}
                          isSearchable
                          isClearable
                          error={errors.timeRequired}
                          tw="mt-2"
                          className={`${isFormDisabled ? 'cursor-not-allowed' : ''}`}
                          disabled={isFormDisabled}
                        />
                      )}
                      rules={{ required: currentTab === ModalTab.NewCustom }}
                    />
                  )}

                  <Label optional={true} tw="font-medium text-sm">
                    What does your client need for this activity?
                  </Label>

                  <div tw="text-xs text-gray-400 mb-1 mt-1">
                    Example: Grab your favorite ingredients for ice cream sundaes.
                  </div>

                  {isGeneratingCustomStructuredActivity ? (
                    <div className="w-full py-8 mt-2 mb-2 border border-1 border-gray-300 rounded bg-gray-200 animate-pulse" />
                  ) : (
                    <Textarea
                      {...register('whatClientNeedForActivity')}
                      data-testid="what-client-need-for-activity-custom-sa"
                      error={errors.whatClientNeedForActivity}
                      tw="w-full mt-2 mb-0"
                      rows={5}
                      maxLength={5000}
                      className={`${isFormDisabled ? 'cursor-not-allowed' : ''}`}
                      disabled={isFormDisabled}
                    />
                  )}

                  {currentSteps?.map((field, index) => {
                    return (
                      <div key={field.id} tw="mt-4">
                        <Label tw="font-medium text-sm">Step {index + 1}</Label>
                        <div tw="text-xs text-gray-400 mb-1 mt-1">Example: Make ice cream sundaes together</div>

                        {isGeneratingCustomStructuredActivity ? (
                          <div className="w-full py-8 mt-2 mb-2 border border-1 border-gray-300 rounded bg-gray-200 animate-pulse" />
                        ) : (
                          <Input
                            {...register(`steps.${index}.title`, { required: currentTab === ModalTab.NewCustom })}
                            data-testid={`step${index + 1}-title-custom-sa`}
                            type="text"
                            placeholder={`Step ${index + 1} Title`}
                            error={errors.steps?.[index]?.title}
                            maxLength={250}
                            tw="mt-2 !mb-0 w-full"
                            className={`${isFormDisabled ? 'cursor-not-allowed' : ''}`}
                            disabled={isFormDisabled}
                          />
                        )}
                        <div tw="text-xs text-gray-400 mb-1 mt-1">
                          Example: Plan a day to make ice cream sundaes together with your child. Gather all their
                          favorite toppings and set them out where your child can see. Make the sundae together, one
                          step at a time.
                        </div>
                        {isGeneratingCustomStructuredActivity ? (
                          <div className="w-full py-8 mt-2 mb-2 border border-1 border-gray-300 rounded bg-gray-200 animate-pulse" />
                        ) : (
                          <Textarea
                            {...register(`steps.${index}.description`, { required: currentTab === ModalTab.NewCustom })}
                            data-testid={`step${index + 1}-description-custom-sa`}
                            placeholder={`Step ${index + 1} Description`}
                            error={errors.steps?.[index]?.description}
                            tw="w-full mb-0 mt-2"
                            rows={5}
                            className={`${isFormDisabled ? 'cursor-not-allowed' : ''}`}
                            disabled={isFormDisabled}
                          />
                        )}
                        {index > 0 && (
                          <div tw="flex justify-end">
                            <Link onClick={() => removeStep(index)} to="#" tw="text-indigo-700">
                              Remove step
                              <FontAwesomeIcon tw="text-xs ml-2" icon="trash-undo-alt" />
                            </Link>
                          </div>
                        )}
                      </div>
                    );
                  })}

                  {currentSteps?.length < MAXIMUM_STEPS && (
                    <div tw="mt-3">
                      <Link
                        onClick={() => appendStep({ title: '', description: '' })}
                        to="#"
                        tw="text-indigo-700 mt-5"
                        data-testid="add-another-step"
                      >
                        <FontAwesomeIcon tw="text-xs mr-2" icon="plus-circle" />
                        Add another step
                      </Link>
                    </div>
                  )}

                  <Label tw="font-medium text-sm mt-4">
                    What should your client or caregiver look for during this activity?
                  </Label>
                  <div tw="text-xs text-gray-400 mb-1 mt-1">
                    Example: Your child is able to produce three word phrases.
                  </div>

                  {isGeneratingCustomStructuredActivity ? (
                    <div className="w-full py-8 mt-2 mb-2 border border-1 border-gray-300 rounded bg-gray-200 animate-pulse" />
                  ) : (
                    <Textarea
                      {...register('whatShouldClientLookFor', { required: currentTab === ModalTab.NewCustom })}
                      data-testid="what-should-client-look-for-custom-sa"
                      error={errors.whatShouldClientLookFor}
                      tw="w-full mt-2"
                      rows={5}
                      maxLength={5000}
                      className={`${isFormDisabled ? 'cursor-not-allowed' : ''}`}
                      disabled={isFormDisabled}
                    />
                  )}

                  <Label tw="font-medium text-sm">
                    If your client or caregiver finds this activity difficult, what should they try?
                  </Label>
                  <div tw="text-xs text-gray-400 mb-1 mt-1">
                    Example: Provide a three word phrase and prompt your child to imitate it if they are not producing
                    phrases on their own.
                  </div>

                  {isGeneratingCustomStructuredActivity ? (
                    <div className="w-full py-8 mt-2 mb-2 border border-1 border-gray-300 rounded bg-gray-200 animate-pulse" />
                  ) : (
                    <Textarea
                      {...register('whatShouldClientTryIfDifficult', { required: currentTab === ModalTab.NewCustom })}
                      data-testid="what-should-client-try-if-difficult-custom-sa"
                      error={errors.whatShouldClientTryIfDifficult}
                      tw="w-full mt-2"
                      rows={5}
                      maxLength={5000}
                      className={`${isFormDisabled ? 'cursor-not-allowed' : ''}`}
                      disabled={isFormDisabled}
                    />
                  )}

                  <div className="sticky w-full py-3 space-y-3 bottom-0 bg-white border-t border-gray-200 gap-4 items-center">
                    <Button
                      type="submit"
                      variant="primary"
                      className={`inline-flex justify-center w-full ${isFormDisabled ? 'cursor-not-allowed' : ''}`}
                      tw="mt-2"
                      disabled={isFormDisabled}
                      data-testid="add-custom-sa-submit"
                    >
                      {isCreatingCustomSA ? (
                        <div tw="text-gray-400 text-sm leading-3 -ml-2">
                          <FontAwesomeIcon tw="text-sm ml-2 text-gray-400" icon={faCircleNotch} spin />
                        </div>
                      ) : (
                        'Add Custom Structured Activity'
                      )}
                    </Button>
                  </div>
                </form>
              </TabPanel>
              <TabPanel>
                <form
                  onSubmit={handleSubmit(onSubmitSelectedCustomSAHandler)}
                  data-testid="form-add-selected-custom-sa"
                >
                  <div tw="mb-5">
                    <div tw="ml-1 mr-1 mb-5">
                      <input
                        tw="p-3 outline-none w-full border border-gray-300 rounded-md focus:border-indigo-300"
                        onChange={event => setSearchTermDebounced(event.target.value)}
                        type="text"
                        placeholder={'Search'}
                        aria-label="Search Custom Structured Activity"
                        autoFocus
                        defaultValue={searchTerm}
                        data-testid="search-bar-input"
                      />
                    </div>
                    {filteredCustomSAContentful?.length ? (
                      <div tw="w-full overflow-auto h-96 border-none pb-2" data-testid="custom-sa-search">
                        <CardList
                          data={filteredCustomSAContentful ?? []}
                          render={customSA => {
                            const foundMaterial = selectedMaterials?.find(item => item.customSAId === customSA.id);
                            return (
                              <div key={customSA.id} tw="ml-1 mr-1 mb-5 last:mb-0">
                                <Card tw="p-6 border border-gray-300">
                                  <div tw="flex">
                                    <Input
                                      type="checkbox"
                                      id={`custom-sa-${customSA.id}`}
                                      onChange={e => handleCheckboxChange(e, customSA)}
                                      checked={!!selectedItems?.find(item => item.id === customSA.id)}
                                      data-testid="custom-sa-card"
                                      spacing="tight"
                                      tw="flex mr-2 mt-0.5 cursor-pointer"
                                    />
                                    <Label htmlFor={`custom-sa-${customSA.id}`} tw="font-medium text-sm cursor-pointer">
                                      {customSA.title}
                                    </Label>
                                  </div>
                                  <div className="mt-3 mb-3">{customSA.practiceOverview}</div>
                                  <div>
                                    {customSA.associatedMaterials?.length && customSA.associatedMaterials.length > 0 && (
                                      <div>
                                        <div tw="xl:flex md:items-center mb-2">
                                          <Label tw="font-medium text-sm">Materials</Label>
                                        </div>
                                        <div tw="sm:flex sm:items-center mb-3">
                                          <Select
                                            tw="w-full"
                                            spacing="tight"
                                            id="custom-sa-material"
                                            options={customSA.associatedMaterials?.map(material => {
                                              return {
                                                value: material?.id,
                                                label: material?.title,
                                              } as SelectOption<string>;
                                            })}
                                            value={
                                              {
                                                value: foundMaterial?.materialId || customSA.associatedMaterials[0]?.id,
                                                label:
                                                  foundMaterial?.materialTitle ||
                                                  customSA.associatedMaterials[0]?.title,
                                              } as SelectOption<string>
                                            }
                                            onChange={(selectedMaterial: SelectOption<string>) =>
                                              handleMaterialChange(selectedMaterial, customSA)
                                            }
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                  <a
                                    className="text-indigo-700 font-medium"
                                    href={getCustomStructuredActivityLink(customSA.id)}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    Preview
                                  </a>
                                </Card>
                              </div>
                            );
                          }}
                        />
                      </div>
                    ) : (
                      <div tw="text-sm text-gray-400">No custom structured activity match your current filters</div>
                    )}
                  </div>

                  <ModalFooter>
                    <Button
                      type="submit"
                      variant="primary"
                      className={`inline-flex justify-center w-full ${!totalSelected ? 'cursor-not-allowed' : ''}`}
                      disabled={!totalSelected || isUploadingOrDeletingFile}
                      data-testid="add-selected-custom-sa-submit"
                    >
                      {totalSelected
                        ? `Add ${totalSelected} Custom Structured ${totalSelected > 1 ? 'Activities' : 'Activity'}`
                        : 'Please select at least one'}
                    </Button>
                  </ModalFooter>
                </form>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
