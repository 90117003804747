import React, { useState } from 'react';
import {
  Button,
  FormInline,
  Input,
  Label,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@expressable/ui-library';
import useCheckboxesState from 'hooks/use-checkboxes-state';
import { useEditMultipleAppointments } from 'hooks/use-appointments';
import { ISingleAppointment } from 'types';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'twin.macro';

dayjs.extend(utc);
dayjs.extend(timezone);

export interface EditMultipleAppointmentsModalProps {
  upcomingAppointments: ISingleAppointment[];
  isOpen: boolean;
  onClose: () => void;
  clientId: string;
}

export function EditMultipleAppointmentsModal(props: EditMultipleAppointmentsModalProps) {
  const { isOpen, onClose, clientId, upcomingAppointments } = props;
  const [selectedAppointments, setSelectedAppointment, setSelectedAppointments] =
    useCheckboxesState<ISingleAppointment>([]);
  const [bulkActionLinkState, setBulkActionLinkState] = useState<'Select All' | 'De-select all'>('Select All');
  const editMultipleAppointmentsMutation = useEditMultipleAppointments();
  const { mutate: editMultipleAppointments } = editMultipleAppointmentsMutation;

  return (
    <Modal isOpen={isOpen}>
      <ModalContent>
        <form
          onSubmit={e => {
            e.preventDefault();
            if (selectedAppointments.length == 0) {
              alert('at least one appointment should be selected');
              return false;
            } else {
              editMultipleAppointments(
                {
                  appointmentsData: {
                    appointments: selectedAppointments.map(appointment => appointment.acuityAppointmentID.toString()),
                  },
                  clientId: clientId,
                },
                {
                  onSuccess: () => {
                    onClose();
                  },
                },
              );
            }
          }}
        >
          <ModalHeader>
            <div tw="text-right">
              <Link data-testid="edit-appointment-close" to="#" onClick={onClose}>
                <FontAwesomeIcon tw="text-2xl text-gray-600" icon={['far', 'times']} />
              </Link>
            </div>
          </ModalHeader>
          <ModalBody>
            <div>
              <div tw="flex items-center justify-center w-12 h-12 mx-auto bg-indigo-100 rounded-full">
                <FontAwesomeIcon tw="text-2xl text-indigo-700" icon="calendar-check" />
              </div>
              <div tw="mt-3 sm:mt-5">
                <h3 tw="text-lg text-center font-medium text-gray-900 leading-6" id="modal-headline">
                  Upcoming Appointments
                </h3>
                <div tw="flex flex-col mt-6 max-h-80 overflow-y-scroll">
                  <Link
                    to="#"
                    tw="mb-3 text-sm"
                    onClick={() => {
                      const selectAllCheckboxes = bulkActionLinkState == 'Select All';
                      setSelectedAppointments(selectAllCheckboxes ? upcomingAppointments : []);
                      setBulkActionLinkState(selectAllCheckboxes ? 'De-select all' : 'Select All');
                    }}
                  >
                    <span tw="mr-2">{bulkActionLinkState}</span>(
                    {bulkActionLinkState == 'Select All' ? upcomingAppointments.length : selectedAppointments.length})
                  </Link>
                  {upcomingAppointments.map((appointment, index) => (
                    <FormInline key={index} tw="items-baseline mb-0.5">
                      <Input
                        checked={selectedAppointments.includes(appointment)}
                        onChange={e => {
                          setSelectedAppointment(appointment, e.target.checked);
                        }}
                        id={`appointment-${index}`}
                        type="checkbox"
                        spacing="tight"
                        name="appointments"
                        value={appointment.acuityAppointmentID}
                      />
                      <Label font="normal" htmlFor={`appointment-${index}`} tw="ml-2">
                        {dayjs(appointment.appointmentDateTime).tz(dayjs.tz.guess()).format('MM/DD/YYYY - hh:mm A')}
                      </Label>
                    </FormInline>
                  ))}
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div tw="mt-5 sm:mt-6">
              <span tw="flex w-full rounded-md shadow-sm sm:col-start-2">
                <Button
                  data-testid="edit-appointment-submit"
                  type="submit"
                  variant="secondary"
                  loading={editMultipleAppointmentsMutation.isLoading}
                  disabled={editMultipleAppointmentsMutation.isLoading}
                  tw="inline-flex items-baseline justify-center w-full px-4 py-2  font-medium leading-6 transition ease-in-out duration-150 sm:text-sm sm:leading-5 text-red-600"
                >
                  <FontAwesomeIcon tw="text-sm mr-2" icon={['far', 'trash']} />
                  Cancel
                </Button>
              </span>
            </div>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
