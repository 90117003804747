import dayjs, { Dayjs } from 'dayjs';
import {
  TherapistAvailableTimesPayloadV2,
  getTherapistAvailableTimesV2,
  ValidateTherapistTimePayload,
  validateTherapistTime,
} from 'hooks/common/useTherapistSchedule';
import { useQuery } from 'react-query';
import { Therapist } from 'types';

export type TimeSlot = {
  timestamp: Dayjs;
  duration: number;
  isAvailableNextWeek: boolean;
  therapist: Therapist;
  override?: boolean;
  cardIndex?: number;
};

export const areTimeslotsEqual = (t1?: TimeSlot | null, t2?: TimeSlot | null): boolean => {
  if (!t1 || !t2) return false;
  return t1.timestamp.isSame(t2.timestamp) && t1.therapist.therapistID === t2.therapist.therapistID;
};

// How long until we re-fetch the same calendar, in milliseconds
const SCHEDULE_CACHE_LIFETIME = 100000;

const REFETCH_INTERVAL = 60000;

const therapistDayScheduleKeyV2 = ({ calendarID, date, reccuringNo, duration }: TherapistAvailableTimesPayloadV2) => [
  'therapist-slot-v2',
  calendarID,
  date,
  reccuringNo,
  duration,
];

const therapistValidateTimeKey = ({ calendarID, datetime, duration }: ValidateTherapistTimePayload) => [
  'therapist-validated-time',
  calendarID,
  datetime,
  duration,
];

export const useTherapistAvailableTimesV2 = (input: TherapistAvailableTimesPayloadV2) => {
  return useQuery(therapistDayScheduleKeyV2(input), () => getTherapistAvailableTimesV2(input), {
    staleTime: SCHEDULE_CACHE_LIFETIME,
    refetchInterval: REFETCH_INTERVAL,
    refetchIntervalInBackground: true,
    retry: 2,
    refetchOnWindowFocus: true,
    enabled: Boolean(dayjs(input?.date)?.isValid() && input?.calendarID),
  });
};

export const useTherapistValidateTime = (input: ValidateTherapistTimePayload & { enabled?: boolean }) => {
  return useQuery(therapistValidateTimeKey(input), () => validateTherapistTime(input), {
    staleTime: SCHEDULE_CACHE_LIFETIME,
    retry: 2,
    refetchOnWindowFocus: false,
    enabled: input.enabled,
  });
};