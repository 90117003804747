import React, { useEffect } from 'react';
import { Input, Label, Textarea, Select, FormGroup, FormInline } from '@expressable/ui-library';
import { Appointment, EvaluationNoteContent, ArticulationPhonologySkill } from 'types';
import languagesOptions from 'utils/languages.json';
import { produce } from 'immer';
import 'twin.macro';
import { Diagnoses, Goals, Frequency } from 'pages/client/components/client-plan-of-care-form';
import { noteDuration } from './session-note-form-content';

export const referralOptions = [
  { label: 'Self-Referral', value: 'self-referral' },
  { label: 'Referral', value: 'referral' },
];

const articulations = [
  '/p/',
  '/b/',
  '/m/',
  '/n/',
  '/h/',
  '/w/',
  '/j/"y" sound',
  '"NG"',
  '/t/',
  '/d/',
  '/k/',
  '/g/',
  '/f/',
  '/v/',
  '/s/',
  '/st/',
  '/sp/',
  '/sk/',
  '/sl/',
  '/sw/',
  '/z/',
  '/l/',
  '/bl/',
  '/pl/',
  '/fl/',
  '/kl/',
  '"sh"',
  '"ch"',
  '"J"',
  '"Th" voiced',
  '"Th" voiceless',
  '/r/',
  "'er'",
  '/ar/',
  '/or/',
  "'ear'",
  "'air'",
  "'ire'",
];

export interface EvaluationNoteFormContentProps {
  formContent: EvaluationNoteContent;
  setActivity: React.Dispatch<React.SetStateAction<Appointment>>;
  clientID: string;
  // eslint-disable-next-line
  registerField: (instancePath: string) => { ref: any; error: { message: string | undefined } | undefined };
  onChange?: () => void;
}

export const EvaluationNoteFormContent = (props: EvaluationNoteFormContentProps) => {
  const { formContent, setActivity, clientID, registerField, onChange } = props;
  const languageSkillsRegister = registerField('/note/content/languageSkills');
  const areFurtherAssessmentsRecommendedRegister = registerField('/note/content/areFurtherAssessmentsRecommended');
  const areSpeechTherapyServicesRecommendedRegister = registerField(
    '/note/content/areSpeechTherapyServicesRecommended',
  );

  const handleEvaluationContentChange = (key: string, value: string) => {
    type Keys = keyof Omit<
      EvaluationNoteContent,
      | 'languageSkills'
      | 'areFurtherAssessmentsRecommended'
      | 'areSpeechTherapyServicesRecommended'
      | 'diagnoses'
      | 'carePlanGoalsProgress'
      | 'visitFrequency'
      | 'duration'
    >;

    setActivity(
      produce(activity => {
        const content = activity.note.content as EvaluationNoteContent;
        content[key as Keys] = value;
      }),
    );

    if (onChange) {
      onChange();
    }
  };

  const handleFurtherAssessmentsContentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setActivity(
      produce(activity => {
        const content = activity.note.content as EvaluationNoteContent;
        content.areFurtherAssessmentsRecommended = value === 'true';
      }),
    );

    if (onChange) {
      onChange();
    }
  };

  const handleSpeechTherapyContentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setActivity(
      produce(activity => {
        const content = activity.note.content as EvaluationNoteContent;
        content.areSpeechTherapyServicesRecommended = value === 'true';
      }),
    );

    if (onChange) {
      onChange();
    }
  };

  const handleEvaluationContentSkillsChange = (skillName: string, skillObservation: string | boolean) => {
    setActivity(
      produce(activity => {
        const content = activity.note.content as EvaluationNoteContent;
        // this conditional hits when the language skills checkboxes change
        if (typeof skillObservation == 'boolean') {
          if (skillObservation) {
            let emptyLanguageSkill;
            if (skillName === 'articulation_phonology') {
              emptyLanguageSkill = {
                languageSkillType: skillName,
                observations: '',
                articulations: [],
              } as ArticulationPhonologySkill;
            } else {
              emptyLanguageSkill = {
                languageSkillType: skillName,
                observations: '',
              };
            }
            if (content.languageSkills) {
              content.languageSkills.push(emptyLanguageSkill);
            } else {
              content.languageSkills = [emptyLanguageSkill];
            }
          } else {
            const languageSkills = content.languageSkills;
            content.languageSkills = languageSkills?.filter(({ languageSkillType }) => languageSkillType !== skillName);
          }
        }
        // this conditional hits when the language skills text areas change
        else if (typeof skillObservation == 'string') {
          const languageSkillIndex = content.languageSkills?.findIndex(
            ({ languageSkillType }) => languageSkillType === skillName,
          );
          if (content.languageSkills && typeof languageSkillIndex === 'number' && languageSkillIndex != -1) {
            content.languageSkills[languageSkillIndex].observations = skillObservation;
          }
        }
      }),
    );

    if (onChange) {
      onChange();
    }
  };

  const handleArticulationChange = (
    articulationName: string,
    articulationDetails:
      | { position: 'initialPosition' | 'medialPosition' | 'finalPosition'; observation: string }
      | boolean,
  ) => {
    setActivity(
      produce(activity => {
        const content = activity.note.content as EvaluationNoteContent;
        const articulationPhonologySkill = content.languageSkills?.find(
          skill => skill.languageSkillType == 'articulation_phonology',
        ) as ArticulationPhonologySkill;
        if (typeof articulationDetails == 'boolean') {
          if (articulationDetails) {
            articulationPhonologySkill?.articulations.push({
              articulation: articulationName,
              initialPosition: '',
              medialPosition: '',
              finalPosition: '',
            });
          } else {
            articulationPhonologySkill.articulations = articulationPhonologySkill.articulations.filter(
              articulation => articulation.articulation !== articulationName,
            );
          }
        } else {
          const articulationIndex = articulationPhonologySkill.articulations.findIndex(
            articulation => articulation.articulation === articulationName,
          );
          articulationPhonologySkill.articulations[articulationIndex][articulationDetails.position] =
            articulationDetails.observation;
        }
      }),
    );

    if (onChange) {
      onChange();
    }
  };

  //every time the evaluation note form its opened we set the duration time defaultValue equal to 45 minutes
  useEffect(() => {
    if (!formContent.duration) {
      let duration = 0;
      const filteringDurations = noteDuration.filter(option => option.value === 45);
      filteringDurations.forEach(durationValue => (duration = durationValue.value));
      setActivity(
        produce(activity => {
          const content = activity.note.content as EvaluationNoteContent;
          content.duration = duration;
        }),
      );
    }
  }, []);

  return (
    <div data-testid="evaluation-note">
      <div tw="w-full" data-testid="evaluationDuration">
        <Label htmlFor="sessionDuration" tw="font-semibold text-sm">
          Duration
        </Label>
        <div tw="w-40 mt-2">
          <div tw="w-40 mt-2" data-testid="evaluationDurationTime">{`${formContent.duration} minutes`}</div>
        </div>
      </div>
      <div tw="mt-9 mb-5 font-semibold text-lg border-b-2 border-gray-100 pb-1">Overview</div>
      <label tw="mt-6 mb-2 font-semibold text-sm">Reason for Referral</label>
      <FormInline tw="items-baseline mb-4">
        <div data-testid="referralType">
          <Select
            id="referralType"
            name="referralType"
            tw="w-44"
            options={referralOptions}
            value={referralOptions.find(referralOption => referralOption.value == formContent.referralType)}
            onChange={(event: { [key: string]: string }) => {
              handleEvaluationContentChange('referralType', event.value);
            }}
            {...registerField('/note/content/referralType')}
          />
        </div>
        <span tw="mx-3">By</span>
        <div data-testid="referredBy" tw="mt-2">
          <Input
            name="referredBy"
            id="referredBy"
            value={formContent.referredBy}
            onChange={e => {
              handleEvaluationContentChange('referredBy', e.target.value);
            }}
            {...registerField('/note/content/referredBy')}
          />
        </div>
      </FormInline>
      <label tw="font-semibold text-sm mt-6"> Client&apos;s Primary Language</label>
      <div data-testid="clientsPrimaryLanguage" tw="mt-2 w-48 mb-10">
        <Select
          id="clientsPrimaryLanguage"
          name="clientsPrimaryLanguage"
          value={languagesOptions.find(language => language.value == formContent.clientsPrimaryLanguage)}
          options={languagesOptions}
          onChange={(event: { [key: string]: string }) => {
            handleEvaluationContentChange('clientsPrimaryLanguage', event.value);
          }}
          {...registerField('/note/content/clientsPrimaryLanguage')}
        />
      </div>
      <label tw="font-semibold text-sm mt-6">Additional Language Exposures</label>
      <div data-testid="additionalLanguageExposures" tw="mt-2 mb-4 flex">
        <Input
          name="additionalLanguageExposures"
          id="additionalLanguageExposures"
          value={formContent.additionalLanguageExposures}
          onChange={e => {
            handleEvaluationContentChange('additionalLanguageExposures', e.target.value);
          }}
          {...registerField('/note/content/additionalLanguageExposures')}
        />
      </div>
      <label tw="font-semibold text-sm mt-6">Pertinent Background / Medical History Provided By</label>
      <div data-testid="intakeFormBy" tw="mt-2 mb-4 flex">
        <Input
          name="intakeFormBy"
          id="intakeFormBy"
          value={formContent.intakeFormBy}
          onChange={e => {
            handleEvaluationContentChange('intakeFormBy', e.target.value);
          }}
          {...registerField('/note/content/intakeFormBy')}
        />
      </div>
      <label htmlFor="medicalHistory" tw="font-semibold text-sm mt-6">
        Medical History
      </label>
      <div tw="mt-2 w-full mb-8">
        <Textarea
          data-testid="medicalHistory"
          name="medicalHistory"
          value={formContent.medicalHistory}
          onChange={e => {
            handleEvaluationContentChange('medicalHistory', e.target.value);
          }}
          rows={4}
          {...registerField('/note/content/medicalHistory')}
        />
      </div>
      <label htmlFor="clinicalObservations" tw="font-semibold text-sm mt-6">
        Clinical Observations
      </label>
      <div tw="mt-2 w-full mb-8">
        <Textarea
          data-testid="clinicalObservations"
          name="clinicalObservations"
          value={formContent.clinicalObservations}
          onChange={e => {
            handleEvaluationContentChange('clinicalObservations', e.target.value);
          }}
          rows={4}
          {...registerField('/note/content/clinicalObservations')}
        />
      </div>
      <label htmlFor="behavioralObservations" tw="font-semibold text-sm mt-6">
        Behavioral Observations
      </label>
      <div tw="mt-2 w-full mb-7 overflow-hidden">
        <Textarea
          id="behavioralObservations"
          data-testid="behavioralObservations"
          name="behavioralObservations"
          value={formContent.behavioralObservations}
          onChange={e => {
            handleEvaluationContentChange('behavioralObservations', e.target.value);
          }}
          {...registerField('/note/content/behavioralObservations')}
          rows={4}
        />
      </div>
      <div tw="mt-6 mb-4">
        <div tw="font-semibold text-lg border-b-2 border-gray-100 pb-1">Clinical Assessment Areas</div>
        <div ref={languageSkillsRegister.ref} tabIndex={0} tw="text-red-500 text-xs italic">
          {languageSkillsRegister.error?.message ?? ''}
        </div>
      </div>
      <FormInline tw="items-baseline">
        <Input
          type="checkbox"
          id="receptive_language"
          spacing="tight"
          checked={formContent.languageSkills?.some(
            ({ languageSkillType }) => languageSkillType === 'receptive_language',
          )}
          onChange={e => {
            handleEvaluationContentSkillsChange('receptive_language', e.target.checked);
          }}
        />
        <FormGroup tw="w-full">
          <Label htmlFor="receptive_language" tw="ml-2 max-w-max">
            Receptive Language
          </Label>
          {formContent.languageSkills?.some(({ languageSkillType }) => languageSkillType === 'receptive_language') && (
            <div tw="my-2">
              <Textarea
                name="receptive_language"
                onChange={e => {
                  handleEvaluationContentSkillsChange('receptive_language', e.target.value);
                }}
                value={
                  formContent.languageSkills?.find(
                    ({ languageSkillType }) => languageSkillType === 'receptive_language',
                  )?.observations
                }
                tw="w-full"
                rows={4}
              />
            </div>
          )}
        </FormGroup>
      </FormInline>
      <FormInline tw="items-baseline">
        <Input
          type="checkbox"
          id="expressive_language"
          spacing="tight"
          checked={formContent.languageSkills?.some(
            ({ languageSkillType }) => languageSkillType === 'expressive_language',
          )}
          onChange={e => {
            handleEvaluationContentSkillsChange('expressive_language', e.target.checked);
          }}
        />
        <FormGroup tw="w-full">
          <Label htmlFor="expressive_language" tw="ml-2 max-w-max">
            Expressive Language
          </Label>
          {formContent.languageSkills?.some(({ languageSkillType }) => languageSkillType === 'expressive_language') && (
            <div tw="my-2">
              <Textarea
                name="expressive_language"
                onChange={e => {
                  handleEvaluationContentSkillsChange('expressive_language', e.target.value);
                }}
                value={
                  formContent.languageSkills?.find(
                    ({ languageSkillType }) => languageSkillType === 'expressive_language',
                  )?.observations
                }
                tw="w-full"
                rows={4}
              />
            </div>
          )}
        </FormGroup>
      </FormInline>
      <FormInline tw="items-baseline">
        <Input
          type="checkbox"
          id="articulation_phonology"
          spacing="tight"
          checked={formContent.languageSkills?.some(
            ({ languageSkillType }) => languageSkillType === 'articulation_phonology',
          )}
          onChange={e => {
            handleEvaluationContentSkillsChange('articulation_phonology', e.target.checked);
          }}
        />
        <FormGroup tw="w-full">
          <Label htmlFor="articulation_phonology" tw="ml-2 max-w-max">
            Articulation / Phonology
          </Label>
          {formContent.languageSkills?.some(
            ({ languageSkillType }) => languageSkillType === 'articulation_phonology',
          ) && (
            <div>
              <div tw="my-2 ">
                <Textarea
                  name="articulation_phonology"
                  onChange={e => {
                    handleEvaluationContentSkillsChange('articulation_phonology', e.target.value);
                  }}
                  value={
                    formContent.languageSkills?.find(
                      ({ languageSkillType }) => languageSkillType === 'articulation_phonology',
                    )?.observations
                  }
                  tw="w-full"
                  rows={4}
                />
              </div>
              {articulations.map(currentArticulation => {
                const articulationPhonologySkill = formContent.languageSkills?.find(
                  ({ languageSkillType }) => languageSkillType === 'articulation_phonology',
                ) as ArticulationPhonologySkill;
                const existingArticulation = articulationPhonologySkill.articulations.find(
                  articulation => articulation.articulation == currentArticulation,
                );
                const currentArticulationId = `${currentArticulation.replace(/[/"' ]/g, '')}-articulation`;
                return (
                  <FormGroup key={currentArticulation}>
                    <FormInline tw="items-baseline">
                      <Input
                        id={currentArticulationId}
                        onChange={e => {
                          handleArticulationChange(currentArticulation, e.target.checked);
                        }}
                        checked={existingArticulation ? true : false}
                        type="checkbox"
                        spacing="tight"
                      />
                      <Label htmlFor={currentArticulationId} tw="ml-2">
                        {currentArticulation}
                      </Label>
                    </FormInline>
                    {articulationPhonologySkill.articulations.some(
                      articulation => articulation.articulation === currentArticulation,
                    ) && (
                      <div tw="mt-2 ml-12 lg:flex lg:gap-2">
                        <FormGroup>
                          <Label>Initial Position</Label>
                          <Input
                            value={existingArticulation?.initialPosition}
                            onChange={e => {
                              handleArticulationChange(currentArticulation, {
                                position: 'initialPosition',
                                observation: e.target.value,
                              });
                            }}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label>Medial Position</Label>
                          <Input
                            value={existingArticulation?.medialPosition}
                            onChange={e => {
                              handleArticulationChange(currentArticulation, {
                                position: 'medialPosition',
                                observation: e.target.value,
                              });
                            }}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label>Final Position</Label>
                          <Input
                            value={existingArticulation?.finalPosition}
                            onChange={e => {
                              handleArticulationChange(currentArticulation, {
                                position: 'finalPosition',
                                observation: e.target.value,
                              });
                            }}
                          />
                        </FormGroup>
                      </div>
                    )}
                  </FormGroup>
                );
              })}
            </div>
          )}
        </FormGroup>
      </FormInline>
      <FormInline tw="items-baseline">
        <Input
          type="checkbox"
          id="fluency_resonance"
          spacing="tight"
          checked={formContent.languageSkills?.some(
            ({ languageSkillType }) => languageSkillType === 'fluency_resonance',
          )}
          onChange={e => {
            handleEvaluationContentSkillsChange('fluency_resonance', e.target.checked);
          }}
        />
        <FormGroup tw="w-full">
          <Label htmlFor="fluency_resonance" tw="ml-2 max-w-max">
            Fluency / Resonance
          </Label>
          {formContent.languageSkills?.some(({ languageSkillType }) => languageSkillType === 'fluency_resonance') && (
            <div tw="my-2">
              <Textarea
                name="fluency_resonance"
                onChange={e => {
                  handleEvaluationContentSkillsChange('fluency_resonance', e.target.value);
                }}
                value={
                  formContent.languageSkills?.find(({ languageSkillType }) => languageSkillType === 'fluency_resonance')
                    ?.observations
                }
                tw="w-full"
                rows={4}
              />
            </div>
          )}
        </FormGroup>
      </FormInline>
      <FormInline tw="items-baseline">
        <Input
          type="checkbox"
          id="oral_motor_feeding_skills"
          checked={formContent.languageSkills?.some(
            ({ languageSkillType }) => languageSkillType === 'oral_motor_feeding_skills',
          )}
          spacing="tight"
          onChange={e => {
            handleEvaluationContentSkillsChange('oral_motor_feeding_skills', e.target.checked);
          }}
        />
        <FormGroup tw="w-full">
          <Label htmlFor="oral_motor_feeding_skills" tw="ml-2 mb-4 max-w-max">
            Oral Motor Skills and Feeding Skills
          </Label>
          {formContent.languageSkills?.some(
            ({ languageSkillType }) => languageSkillType === 'oral_motor_feeding_skills',
          ) && (
            <div tw="my-2 w-full">
              <Textarea
                name="oral_motor_feeding_skills"
                onChange={e => {
                  handleEvaluationContentSkillsChange('oral_motor_feeding_skills', e.target.value);
                }}
                value={
                  formContent.languageSkills?.find(
                    ({ languageSkillType }) => languageSkillType === 'oral_motor_feeding_skills',
                  )?.observations
                }
                rows={4}
              />
            </div>
          )}
        </FormGroup>
      </FormInline>
      <FormGroup tw="mt-4 mb-8">
        <div tw="text-base font-semibold mb-2">Are further assessments recommended?</div>
        <FormGroup type="inline">
          <FormInline tw="items-baseline">
            <div data-testid="falseAssessmentsRecommended">
              <Input
                id="falseAssessmentsRecommended"
                type="radio"
                name="areFurtherAssessmentsRecommended"
                defaultChecked={
                  formContent.areFurtherAssessmentsRecommended !== undefined &&
                  !formContent.areFurtherAssessmentsRecommended
                }
                spacing="tight"
                value="false"
                onChange={handleFurtherAssessmentsContentChange}
              />
            </div>
            <Label font="normal" htmlFor="falseAssessmentsRecommended" tw="ml-2">
              No
            </Label>
          </FormInline>
          <FormInline tw="ml-3 items-baseline">
            <div data-testid="trueAssessmentsRecommended">
              <Input
                id="trueAssessmentsRecommended"
                type="radio"
                name="areFurtherAssessmentsRecommended"
                defaultChecked={
                  formContent.areFurtherAssessmentsRecommended !== undefined &&
                  formContent.areFurtherAssessmentsRecommended
                }
                spacing="tight"
                value="true"
                onChange={handleFurtherAssessmentsContentChange}
              />
            </div>
            <Label font="normal" htmlFor="trueAssessmentsRecommended" tw="ml-2">
              Yes
            </Label>
          </FormInline>
        </FormGroup>
        <div ref={areFurtherAssessmentsRecommendedRegister.ref} tabIndex={0} tw="text-red-500 text-xs italic">
          {areFurtherAssessmentsRecommendedRegister.error?.message ?? ''}
        </div>
      </FormGroup>
      {formContent.areFurtherAssessmentsRecommended && (
        <FormGroup>
          <label htmlFor="recommendedAssessments" tw="font-semibold text-sm -mt-2">
            Recommended Assessments
          </label>
          <div tw="mt-2 w-full overflow-hidden">
            <Textarea
              id="recommendedAssessments"
              data-testid="recommendedAssessments"
              name="recommendedAssessments"
              value={formContent.recommendedAssessments}
              onChange={e => {
                handleEvaluationContentChange('recommendedAssessments', e.target.value);
              }}
              {...registerField('/note/content/recommendedAssessments')}
              rows={4}
            />
          </div>
        </FormGroup>
      )}
      <FormGroup tw="mt-4 mb-8">
        <div tw="text-base font-semibold mb-2">Are speech therapy services recommended?</div>
        <FormGroup type="inline">
          <FormInline tw="items-baseline">
            <div data-testid="falseSpeechTherapyRecommended">
              <Input
                id="falseSpeechTherapyRecommended"
                type="radio"
                name="areSpeechTherapyServicesRecommended"
                defaultChecked={
                  formContent.areSpeechTherapyServicesRecommended !== undefined &&
                  !formContent.areSpeechTherapyServicesRecommended
                }
                spacing="tight"
                value="false"
                onChange={handleSpeechTherapyContentChange}
              />
            </div>
            <Label font="normal" htmlFor="falseSpeechTherapyRecommended" tw="ml-2">
              No
            </Label>
          </FormInline>
          <FormInline tw="ml-3 items-baseline">
            <div data-testid="trueSpeechTherapyRecommended">
              <Input
                id="trueSpeechTherapyRecommended"
                type="radio"
                name="areSpeechTherapyServicesRecommended"
                defaultChecked={
                  formContent.areSpeechTherapyServicesRecommended !== undefined &&
                  formContent.areSpeechTherapyServicesRecommended
                }
                spacing="tight"
                value="true"
                onChange={handleSpeechTherapyContentChange}
              />
            </div>
            <Label font="normal" htmlFor="trueSpeechTherapyRecommended" tw="ml-2">
              Yes
            </Label>
          </FormInline>
        </FormGroup>
        <div ref={areSpeechTherapyServicesRecommendedRegister.ref} tabIndex={0} tw="text-red-500 text-xs italic">
          {areSpeechTherapyServicesRecommendedRegister.error?.message ?? ''}
        </div>
      </FormGroup>
      {formContent.areSpeechTherapyServicesRecommended === true && (
        <FormGroup tw="mt-6 mb-8">
          <div tw="mt-6 mb-8 font-semibold text-lg border-b-2 border-gray-100 pb-1">Initial Care Plan</div>
          <div>
            <Diagnoses
              clientId={clientID}
              validation={registerField('/note/content/diagnoses/items')}
              shouldLogEvent={false}
            />
          </div>
          <div tw="-mt-3">
            <label htmlFor="conclusions" tw="font-semibold text-sm mt-6">
              Conclusions
            </label>
            <div tw="mt-2 mb-6">
              <Textarea
                data-testid="conclusions"
                name="conclusions"
                value={formContent.diagnoses.additionalComments}
                onChange={e => {
                  setActivity(
                    produce(activity => {
                      const content = activity.note.content as EvaluationNoteContent;
                      content.diagnoses.additionalComments = e.target.value;
                    }),
                  );

                  if (onChange) {
                    onChange();
                  }
                }}
                tw="w-full "
                {...registerField('/note/content/diagnoses')}
                rows={4}
              />
            </div>
          </div>
          <div>
            <Frequency
              clientId={clientID}
              validation={registerField('/note/content/visitFrequency/frequency')}
              shouldLogEvent={false}
            />
          </div>
          <div tw="-mt-3">
            <label htmlFor="clinicalRecommendations" tw="font-semibold text-sm">
              Clinical Recommendations
            </label>
            <div tw="mt-2">
              <Textarea
                data-testid="clinicalRecommendations"
                name="clinicalRecommendations"
                value={formContent.visitFrequency.additionalComments}
                {...registerField('/note/content/visitFrequency/additionalComments')}
                onChange={e => {
                  setActivity(
                    produce(activity => {
                      const content = activity.note.content as EvaluationNoteContent;
                      content.visitFrequency.additionalComments = e.target.value;
                    }),
                  );

                  if (onChange) {
                    onChange();
                  }
                }}
                tw="w-full"
                rows={4}
              />
            </div>
          </div>
          <div tw="mt-6">
            <Goals
              clientId={clientID}
              validation={registerField('/note/content/carePlanGoalsProgress')}
              shouldLogEvent={false}
              withGoalsFilters={false}
            />
          </div>
        </FormGroup>
      )}
      {formContent.areSpeechTherapyServicesRecommended === false && (
        <FormGroup tw="mt-8">
          <div tw="mt-6 mb-8 font-semibold text-lg border-b-2 border-gray-100 pb-1">Final Recommendations</div>
          <div tw="-mt-3">
            <label htmlFor="conclusions" tw="font-semibold text-sm mt-6">
              Conclusions
            </label>
            <div tw="mt-2 mb-6">
              <Textarea
                data-testid="conclusions"
                name="conclusions"
                value={formContent.diagnoses.additionalComments}
                onChange={e => {
                  setActivity(
                    produce(activity => {
                      const content = activity.note.content as EvaluationNoteContent;
                      content.diagnoses.additionalComments = e.target.value;
                    }),
                  );

                  if (onChange) {
                    onChange();
                  }
                }}
                tw="w-full "
                {...registerField('/note/content/diagnoses')}
                rows={4}
              />
            </div>
          </div>
        </FormGroup>
      )}
    </div>
  );
};
