import { Card } from '@expressable/ui-library';
import { Form } from 'components/Forms';
import { Unvalidated } from 'domain/form-validation';
import { ClientInformationFormData } from 'pages/new-client-create/ClientInformation';
import { DefaultValues, SubmitHandler } from 'react-hook-form';
import { clientInformationSchema } from './schema';
import statesWithMultipleTimezones from 'utils/us-states-with-multiple-timezones.json';
import { findTimezoneOptionByLabel, timezoneOptions } from 'hooks/common/useDisplayTimezone/options';

import 'twin.macro';
import { ClientInformationFormUi } from 'pages/new-client-create/ClientInformation/ui';
import { FormChangeWatcher } from 'components/Forms/FormChangeWatcher';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SelectOption } from 'types';
import genderOptions from 'utils/gender-options';
import usStatesOptions from 'utils/us-states.json';
import usCodeStatesOptions from 'utils/us-code-states';
import { intakeTypeOptions } from '../BillingInformation/options';
import { contactRelationshipOptions, phoneTypeOptions } from './options';
import KSUID from 'ksuid';
import dayjs, { Dayjs } from 'dayjs';

type Props = {
  defaultValues?: DefaultValues<Unvalidated<ClientInformationFormData>>;
  onSubmit: (data: ClientInformationFormData) => void;

  onInvalidateStep: () => void;
  isStepCompleted: boolean;

  schedulingState: SelectOption | null;

  isIntakePossible: boolean;
  searchParams: URLSearchParams,
};

export const isAdult = (dateOfBirth: Dayjs) => {
  const todayDate = dayjs();
  const ageInYears = todayDate.diff(dateOfBirth, 'years');
  return ageInYears >= 18;
};

export const getSearchParam = (searchParams: URLSearchParams) => (name: string) => searchParams.get(name) ?? undefined;

export const mapSearchParams = (searchParams: URLSearchParams) => {
  const getParam = getSearchParam(searchParams);
  const codeState = usCodeStatesOptions.find(({ value }) => value === getParam('leadPrimaryAddressState'));
  const state = usStatesOptions.find(({ value }) => value === codeState?.label);
  const params = {
    clientFirstName: getParam('firstName'),
    clientMiddleName: getParam('midName'),
    clientLastName: getParam('lastName'),
    clientNameSuffix: getParam('leadSuffix'),
    dateOfBirth: searchParams.has('leadDOB') ? dayjs(getParam('leadDOB')) : undefined,
    sex: genderOptions.find(({ value, label }) => value === getParam('leadSex') || label === getParam('leadSex') ),
    genderIdentity: getParam('leadGenderId'),
    phone: getParam('leadPhone')?.replace(/-/g, ''), // is mobile phone
    phoneType: phoneTypeOptions[1], // mobile
    crmID: getParam('prospectId'),
    relationshipToClient: contactRelationshipOptions.find(({ value }) => value === getParam('leadRelationshipToClient')),
    contactName: getParam('leadContactName'),
    contactMiddleName: getParam('leadContactMiddleName'),
    contactLastName: getParam('leadContactLastName'),
    email: getParam('leadEmail'),
    address1: getParam('leadPrimaryContactAddressStreet'),
    city: getParam('leadPrimaryAddressCity'),
    state,
    contactTimezone: timezoneOptions.find(({ value }) => value === getParam('leadTimeZone')) ?? findTimezoneOptionByLabel(state?.timezone ?? ''),
    zipCode: getParam('leadPrimaryAddressZipCode'),
    /** TODO:
     * these following params are related to insurance. Not yet there.
     */
    // coPay: getParam('leadCoPay'),
    // relationshipToInsured: getParam('leadClientRelationshipToInsured'),
    // insuredFirstName: getParam('leadInsuredFirstName'),
    // insuredLastName: getParam('leadInsuredLastName'),
    // insuredDOB: getParam('leadInsuredDOB'),
    // insuredGender: getParam('leadInsuredGender'),
    // insuredStreet: getParam('leadInsuredStreet'),
    // insuredCity: getParam('leadInsuredCity'),
    // insuredState: getParam('leadInsuredState'),
    // insuredZipCode: getParam('leadInsuredZipCode'),
    // insuredPayerforClaims: getParam('leadInsuredPayerforClaims'),
    // memberID: getParam('leadMemberID'),
    // groupID: getParam('leadGroupID'),
    // evalAuthStatus: getParam('leadEvalAuthStatus'),
    // sessionAuthStatus: getParam('leadSessionAuthStatus'),
    // areThereAnyVisitLimits: getParam('leadArethereanyvisitlimits'),
    // remainingVisitsLeft: getParam('leadRemainingVisitsLeft'),
    // referringProviderFirstName: getParam('leadReferringProviderFirstName'),
    // referringProviderLastName: getParam('leadReferringProviderLastName'),
  };

  return params;
};

export const ClientInformationForm = ({
  defaultValues: _defaultValues,
  onSubmit,
  isStepCompleted,
  onInvalidateStep,

  schedulingState,
  isIntakePossible,
  searchParams,
}: Props): JSX.Element => {
  const [generatedContactID] = useState(KSUID.randomSync().string);

  const onSubmitForm: SubmitHandler<ClientInformationFormData> = result => {
    const resultWithIDs: ClientInformationFormData = {
      ...result,
      contactID: result.crmID || generatedContactID,
    };
    onSubmit(resultWithIDs);
  };

  const onChangeInvalidate = useCallback(() => {
    if (isStepCompleted) {
      onInvalidateStep();
    }
  }, [isStepCompleted]);

  const defaultValues = useMemo(
    () => ({
      ...clientInformationSchema.getDefault(),
      ..._defaultValues,
      ...mapSearchParams(searchParams),
    }),
    [searchParams],
  );

  const form = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(clientInformationSchema),
    shouldFocusError: false,
  });

  const { setValue } = form;

  const usStatesOptionsWithMultipleTimezones = usStatesOptions.map(state => {
    if (statesWithMultipleTimezones.includes(state.value)) {
      return { ...state, hasMultipleTimeZone: true };
    }
    return state;
  });

  // Update the timezone option with the one from context, invalidate the step
  useEffect(() => {
    if (schedulingState) {
      setValue('state', schedulingState);
    }
    onInvalidateStep();
  }, [schedulingState]);

  // Force no intake when there's no evaluation time, invalidate the step
  useEffect(() => {
    if (!isIntakePossible) {
      setValue(
        'intakeType',
        intakeTypeOptions.find(x => x.value === 'no_intake'),
      );
      onInvalidateStep();
    }
  }, [isIntakePossible]);

  return (
    <Card className="p-8 flex flex-col gap-4">
      <h2 className="font-semibold text-xl">Client Information</h2>
      <Form
        onSubmit={onSubmitForm}
        className="flex flex-col gap-5 items-start"
        form={form}
        schema={clientInformationSchema}
      >
        <FormChangeWatcher onChange={onChangeInvalidate} />
        <ClientInformationFormUi
          isIntakePossible={isIntakePossible}
          setValue={setValue}
          usStatesOptionsWithMultipleTimezones={usStatesOptionsWithMultipleTimezones}
        />
      </Form>
    </Card>
  );
};
