import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { faCalendarXmark } from '@fortawesome/pro-solid-svg-icons';
import { EventProps } from 'domain/admin/types';
import { capitalizeFirst, removePrefixFromID } from 'utils/helpers';
import { ActionByEnum, SessionTypeEnum } from 'domain/admin/constants';
import 'twin.macro';
import { getRescheduleReasonDisplay } from 'domain/appointment/rescheduleReasons';

const AppointmentCancelled = (event: EventProps) => {
  const firehoseEvent = event?.event;
  const isLastEvent = event?.isLastEvent;

  return (
    <div data-testid="firehose-appointment-cancelled" tw="relative flex mt-7 px-2">
      {!isLastEvent && <span tw="absolute -z-1 top-7 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>}
      <div tw="flex items-baseline">
        <div tw="flex items-center bg-gray-200 rounded-full h-6 w-6">
          <FontAwesomeIcon icon={faCalendarXmark} tw="mx-1.5 h-3 text-indigo-700" />
        </div>
        <div tw="pl-4 text-sm">
          {firehoseEvent.cancelledBy === ActionByEnum.therapist ? (
            <div tw="lg:flex font-semibold pb-1 text-indigo-700">
              <Link to={{ pathname: `/therapists/${firehoseEvent?.therapistEmail}` }}>
                {firehoseEvent?.therapistFirstName} {firehoseEvent?.therapistLastName}&nbsp;
              </Link>
              <span tw="text-gray-500">{`logged a cancellation for the client`}</span>
              &nbsp;
              <Link
                tw="text-indigo-700"
                to={{ pathname: `/clients/${removePrefixFromID(firehoseEvent?.clientID)}` }}
              >
                {firehoseEvent?.clientFirstName} {firehoseEvent?.clientLastName}&nbsp;
              </Link>
              <span tw="text-gray-500">{`at ${dayjs(firehoseEvent?.createdAt as string).format('h:mm A')}, ${dayjs(
                firehoseEvent?.createdAt as string,
              ).format('MM/DD/YYYY')} `}</span>
            </div>
          ) : (
            <div tw="lg:flex font-semibold pb-1 text-indigo-700">
              <Link
                tw="text-indigo-700"
                to={{ pathname: `/clients/${removePrefixFromID(firehoseEvent?.clientID)}` }}
              >
                {firehoseEvent?.clientFirstName} {firehoseEvent?.clientLastName}&nbsp;
              </Link>
              <span tw="text-gray-500">{`(Client) canceled 1 ${
                firehoseEvent.sessionType === SessionTypeEnum.session ? 'speech therapy session' : 'evaluation'
              }`}</span>
              &nbsp;
              <span tw="text-gray-500">{`at ${dayjs(firehoseEvent?.createdAt as string).format('h:mm A')}, ${dayjs(
                firehoseEvent?.createdAt as string,
              ).format('MM/DD/YYYY')} `}</span>
            </div>
          )}

          <div tw="line-through">{`Appointment: ${dayjs(firehoseEvent?.appointmentOn as string).format(
            'MM/DD/YYYY - h:mm A',
          )}`}</div>
          {(firehoseEvent.detail?.clientAttendance || firehoseEvent.detail?.cancelDetail) && (
            <div>
              {firehoseEvent.detail?.clientAttendance?.cancelDetail?.cancelationReason !== 'other' &&
              firehoseEvent.detail?.cancelDetail?.cancelationReason !== 'other' ? (
                <span tw="capitalize">{`Cancelation Reason: ${
                  getRescheduleReasonDisplay(firehoseEvent.detail?.clientAttendance?.cancelDetail?.cancelationReason) ??
                  getRescheduleReasonDisplay(firehoseEvent.detail?.cancelDetail?.cancelationReason)
                } `}</span>
              ) : (
                <span>{`Cancelation Reason: ${capitalizeFirst(
                  firehoseEvent.detail?.clientAttendance?.cancelDetail?.cancelationReason ??
                    firehoseEvent.detail?.cancelDetail?.cancelationReason,
                )} - ${capitalizeFirst(
                  firehoseEvent.detail?.clientAttendance?.cancelDetail?.cancelationReasonOther ??
                    firehoseEvent.detail?.cancelDetail?.cancelationReasonOther,
                )} `}</span>
              )}
            </div>
          )}
          <div>
            {(firehoseEvent.detail?.clientAttendance?.cancelDetail?.additionalText ||
              firehoseEvent.detail?.cancelDetail?.additionalText) && (
              <span>{`Additional Information: ${capitalizeFirst(
                firehoseEvent.detail?.clientAttendance?.cancelDetail.additionalText ??
                  firehoseEvent.detail?.cancelDetail?.additionalText,
              )} `}</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppointmentCancelled;
