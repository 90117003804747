import { ManualEligibilityForm } from 'domain/eligibility/types';
import * as yup from 'yup';
import moment from 'moment';

const subscriberSchema = yup.object().shape({
  memberID: yup.string().required('Member ID is required'),
  dateOfBirth: yup.date().required('Date of Birth is required'),
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  middleName: yup.string().nullable().notRequired().optional(),
})

export const EligibilityFormSchema: yup.ObjectSchema<ManualEligibilityForm> = yup.object({
  dateOfService: yup.date().required('Date of Service is required').test('future-date', 'Date of Service must be in the future', function (date) {
    const { createError, path } = this;
    const isFutureDate = moment(date).isAfter(moment(), 'day');

    if (moment(date).isSame(moment(), 'day'))  return true;

    return isFutureDate || createError({ path, message: 'Date of Service must be in the future' });
  }),
  payerID: yup.string().required('Payer ID is required'),
  npi: yup.string().required('NPI is required').matches(/^\d{10}$/, 'NPI must be 10 digits'),
  memberID: yup.string().required('Member ID is required'),
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  middleName: yup.string().optional(),
  dateOfBirth: yup.date(),
  dependents: yup.array().of(subscriberSchema).test('at-least-one-required', 'At least one dependent must be filled out', function (dependents) {
    const { path, createError } = this;
    const hasDependents = dependents && dependents.length > 0;
    const isValid = !hasDependents || (hasDependents && dependents.every(dep => (
      dep.memberID &&
      dep.firstName &&
      dep.lastName &&
      dep.dateOfBirth
    )));

    return isValid || createError({ path, message: 'All fields are required when at least one dependent is provided.' });
  })
});
