import {
  Button,
  CloseButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
} from '@expressable/ui-library';
import 'twin.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNotesMedical } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import { Form } from 'components/Forms';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ControlledFormField } from 'components/Forms/FormFieldControlled';
import { useMemo } from 'react';
import { UseQueryResult } from 'react-query';
import { useSetVisitDuration } from 'hooks/use-care-plan-duration-visits';
import { selectOptionSchema } from 'pages/new-client-create/ClientInformation';

interface PlanOfCareStartDateModalProps {
  isOpen: boolean;
  onClose: () => void;
  clientId: string;
  duration?: string | null;
  shouldLogEvent: boolean;
  durationOfVisits?: UseQueryResult<any, unknown>;
}

const schema = yup.object({
  duration: selectOptionSchema.required('Duration is required'),
});

export function PlanOfCareDurationVisitsModal(props: PlanOfCareStartDateModalProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isOpen, onClose, clientId, shouldLogEvent, duration } = props;
  const { mutateAsync: setDuration, isLoading } = useSetVisitDuration();

  const defaultValues = useMemo(
    () => ({
      duration: {
        label: duration,
        value: duration,
      },
    }),
    [duration],
  );

  const durationVisitsOptions = props.durationOfVisits?.data;

  const form = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: any) => {
    await setDuration({
      clientId: clientId,
      duration: data.duration,
      shouldLogEvent: shouldLogEvent,
    });

    onClose();
  };

  return (
    <Modal portal isOpen={isOpen}>
      <ModalContent>
        <Form form={form as any} onSubmit={onSubmit} schema={schema}>
          <ModalHeader>
            <CloseButton onClick={onClose} />
          </ModalHeader>
          <ModalBody>
            <div>
              <div tw="flex items-center justify-center w-12 h-12 mx-auto bg-indigo-100 rounded-full">
                <FontAwesomeIcon tw="text-2xl text-indigo-700" icon={faNotesMedical} data-testid="icon" />
              </div>
              <div tw="mt-3 sm:mt-5">
                <h3 tw="text-lg text-center font-medium text-gray-900 leading-6" id="modal-headline">
                  Duration of visit
                </h3>
                <div tw="mt-10">
                  <ControlledFormField
                    containerClass="w-full"
                    id="durationOfVisits"
                    name="duration"
                    label="Duration"
                    component={Select}
                    options={durationVisitsOptions?.dropdownContent?.map((e: string) => ({ label: e, value: e }))}
                  />
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div tw="mt-5 sm:mt-6 sm:grid sm:grid-cols-1 sm:gap-3 sm:grid-flow-row-dense">
              <span tw="flex w-full rounded-md shadow-sm">
                <Button
                  type="submit"
                  data-testid="applicable-conditions-submit"
                  variant="primary"
                  loading={isLoading}
                  disabled={isLoading}
                  tw="inline-flex justify-center w-full px-4 py-2 text-base font-medium leading-6 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                >
                  Save
                </Button>
              </span>
            </div>
          </ModalFooter>
        </Form>
      </ModalContent>
    </Modal>
  );
}
