interface SkipSchedulingLinkProps {
  onClick?: () => void;
}

const SkipSchedulingLink = ({ onClick }: SkipSchedulingLinkProps) => (
  <a onClick={onClick} className="my-4 block max-w-max text-indigo-700 cursor-pointer">
    Not finding a time? Skip scheduling sessions
  </a>
);

export default SkipSchedulingLink;
