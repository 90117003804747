/* eslint-disable react/display-name */
import React from 'react';
import { Event } from 'types';
import 'twin.macro';
import SimpleEvent from 'utils/event-renderers/simple-event';
import { faCalendarEdit } from '@fortawesome/pro-solid-svg-icons';

export interface AddValueListRendererOptions {
  fieldName?: string;

  formatValue?: (value: any) => any;

  singularDescription: string;

  pluralDescription: string;
}

export interface AddValueListProps<T> {
  event: Event<T>;
  isLastEvent: boolean;
}

export default function addValueListRenderer<T>(versionToOptions: Record<string, AddValueListRendererOptions>) {
  return {
    default: (version: string) => {
      const { fieldName, formatValue, singularDescription, pluralDescription } = versionToOptions[version];

      return (props: AddValueListProps<T>) => {
        const valueList = [...(fieldName ? (props.event.detail as any)[fieldName] : props.event.detail)];
        valueList.sort();
        const formattedValueList = formatValue ? valueList.map(formatValue) : valueList;

        const eventDescription =
          formattedValueList.length > 1 ? `added multiple ${pluralDescription}` : `added a new ${singularDescription}`;

        return (
          <SimpleEvent {...props} description={eventDescription} icon={faCalendarEdit}>
            {formattedValueList.map((value, valueIndex) => (
              <div tw="mb-2 last:mb-0" key={valueIndex}>
                {value}
              </div>
            ))}
          </SimpleEvent>
        );
      };
    },
  };
}
