import { useState } from 'react';
import Table from 'components/Table';
import { Card, Container, FormGroup, Label, Link, Loader, Select } from '@expressable/ui-library';
import { faChevronLeft, faChevronRight, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SelectOption } from 'types';
import useTherapists from 'hooks/use-therapists';
import dayjs from 'dayjs';
import cx from 'classnames';
import 'twin.macro';
import { usePagination } from 'hooks/use-pagination';
import { useAdminPendingIntake } from 'hooks/use-admin-intakes';

const isTodayOrBefore = (date: string) => {
  return dayjs(date).isSame(dayjs(), 'day') || dayjs(date).isBefore(dayjs(), 'day');
};

const AwaitingIntake = () => {
  const { data: therapistsList } = useTherapists();

  const [therapistSelected, setTherapistSelected] = useState<Record<string, string>>({
    value: 'all',
    label: 'All Therapists',
  });

  const {
    data = [],
    isLoading,
    isError,
  } = useAdminPendingIntake(therapistSelected.value === 'all' ? '' : therapistSelected.value);

  const therapistsSelectOptions: SelectOption[] | undefined = therapistsList?.map(therapist => ({
    label: therapist.therapistName,
    value: therapist.therapistID,
  }));

  const therapistsOptions = therapistsSelectOptions
    ? [{ value: 'all', label: 'All Therapists' }, ...therapistsSelectOptions]
    : [{ value: 'all', label: 'All Therapists' }];

  const paginatedEntries = usePagination(data, { perPage: 20 });

  if (isError) {
    return (
      <Container data-testid="awaiting-intake-error" tw="relative pl-0" size="large">
        <div data-testid="awaiting-intake-error-title" tw="flex justify-between items-center flex-wrap">
          <h2 tw="text-2xl font-semibold mb-3">Awaiting Intake</h2>
        </div>
        <Card tw="mb-4 px-8 mt-8">
          <FormGroup tw="w-full sm:w-1/2 md:w-1/3">
            <Label>
              <FontAwesomeIcon icon={faExclamationTriangle} tw="text-red-500 mr-2" />
              <span tw="text-red-500">Intakes are not available at this time.</span>
            </Label>
          </FormGroup>
        </Card>
      </Container>
    );
  }

  return (
    <div>
      <div tw="md:pr-5" data-testid="awaiting-intake">
        <div tw="flex justify-between items-center flex-wrap">
          <h2 tw="text-2xl font-semibold mb-3">Intake</h2>
        </div>
        <Card tw="mt-2">
          {isLoading ? (
            <div data-testid="awaiting-intake-loader" tw="flex-1 text-center py-20">
              <Loader type="ring" />
            </div>
          ) : (
            <>
              <div tw="mb-4 sm:mb-0">
                <div tw="flex flex-col sm:flex-row mb-4">
                  <>
                    <span tw="text-sm font-semibold mt-6 ml-2 hidden sm:block">View</span>
                    <div tw="flex items-center sm:items-stretch justify-items-stretch mr-2 ml-2 mt-4 font-normal">
                      <span tw="text-sm font-semibold mr-2 block sm:hidden">View</span>
                      <Select
                        tw="w-full sm:w-96"
                        id="adminIntakeTherapist"
                        placeholder=""
                        spacing="tight"
                        value={therapistSelected}
                        options={therapistsOptions}
                        onChange={(selectOption: { [key: string]: string }) => {
                          setTherapistSelected(selectOption);
                        }}
                      />
                    </div>
                    <span
                      onClick={() => {
                        const defaultOption = { value: 'all', label: 'All Therapists' };
                        setTherapistSelected(defaultOption);
                      }}
                      className={cx('text-sm ml-3 mt-6 text-indigo-700 font-normal hidden sm:block', {
                        'opacity-100': therapistSelected.value !== 'all',
                        'opacity-50': therapistSelected.value === 'all',
                        'cursor-pointer': therapistSelected.value !== 'all',
                      })}
                    >
                      View All Therapists
                    </span>
                  </>
                </div>
              </div>

              {paginatedEntries.items?.length ? (
                <>
                  <Table>
                    <Table.Header>
                      <Table.Row>
                        <Table.Cell tw="hidden sm:table-cell">Client Created</Table.Cell>
                        <Table.Cell tw="w-auto">Client Name</Table.Cell>
                        <Table.Cell tw="w-auto">Evaluation Date</Table.Cell>
                        <Table.Cell tw="w-auto ">Therapist</Table.Cell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {paginatedEntries.items.map((intake, index) => (
                          <Table.Row key={index}>
                            <Table.Cell tw="text-sm md:text-base hidden sm:table-cell">
                              {dayjs(intake.clientCreatedAt).format('MM/DD/YYYY - hh:mm A')}
                            </Table.Cell>

                            <Table.Cell tw="text-indigo-700 font-semibold text-sm md:text-base">
                              <Link target="_blank" to={{ pathname: `/clients/${intake.clientID}/` }}>
                                {intake.clientFirstName} {intake.clientLastName}&nbsp;
                              </Link>
                            </Table.Cell>
                            <Table.Cell
                              className={`text-sm md:text-base ${
                                intake.evaluationDate !== null && isTodayOrBefore(intake.evaluationDate)
                                  ? 'text-red-500'
                                  : ''
                              }`}
                            >
                              {intake.evaluationDate !== null
                                ? dayjs(intake.evaluationDate).format('MM/DD/YYYY - hh:mm A')
                                : '-'}
                            </Table.Cell>
                            <Table.Cell tw="text-indigo-700  font-semibold text-sm md:text-base">
                              <Link target="_blank" to={{ pathname: `/therapists/${intake.therapistID}` }}>
                                {intake.therapistFirstName} {intake.therapistLastName}
                              </Link>
                            </Table.Cell>
                          </Table.Row>
                        ))}
                    </Table.Body>
                  </Table>
                  <div tw="font-medium flex mt-5 justify-center sm:justify-end">
                    <span tw="mr-2">{`Showing ${paginatedEntries.pageStartIdx + 1} to ${
                      paginatedEntries.pageEndIdx
                    } of ${paginatedEntries.totalItems} results`}</span>
                    {paginatedEntries.page !== 0 && (
                      <span data-testid="awaiting-intake-previous-page" onClick={paginatedEntries.prev}>
                        <FontAwesomeIcon
                          tw="text-indigo-700 ml-2 mr-2 cursor-pointer flex mt-2 text-xs"
                          icon={faChevronLeft}
                        />
                      </span>
                    )}
                    {paginatedEntries.page !== paginatedEntries.totalPages - 1 && (
                      <span data-testid="awaiting-intake-next-page" onClick={paginatedEntries.next}>
                        <FontAwesomeIcon
                          tw="text-indigo-700 ml-2 mr-2 cursor-pointer flex mt-2 text-xs"
                          icon={faChevronRight}
                        />
                      </span>
                    )}
                  </div>
                </>
              ) : (
                <span tw="ml-2">There are no intakes to show.</span>
              )}
            </>
          )}
        </Card>
      </div>
    </div>
  );
};

export default AwaitingIntake;
