// @ts-nocheck

import { ManualEligibilityForm } from 'domain/eligibility/types';
import { useEffect } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';

export const useSetDependents = (form: UseFormReturn<ManualEligibilityForm>, defaultValues: Record<string, any>) => {
  // dev-note: this is a dirty hack. set default values for dependents, useFieldArray is instantiated right before
  // the form register all fields so they remain empty by passing as default values.
  const {
    setValue,
  } = form;
  useEffect(() => {
    if (defaultValues?.dependents?.length > 0) {
      defaultValues.dependents.forEach((dependent, index) => {
        setValue(`dependents[${index}].dateOfBirth`, dependent.dateOfBirth);
        setValue(`dependents[${index}].memberID`, dependent.memberID);
        setValue(`dependents[${index}].firstName`, dependent.firstName);
        setValue(`dependents[${index}].lastName`, dependent.lastName);
        setValue(`dependents[${index}].middleName`, dependent.middleName);
      });
    }
  }, [defaultValues]);

  const dependents = useWatch({ name: 'dependents', control: form.control });

  // revalidate dependents when they change as they is triggered in manual mode
  useEffect(() => {
    form.trigger('dependents');
  }, [dependents]);
};
