import { FC } from 'react';
import { UseFormRegister } from 'react-hook-form';
import { FormGroup, FormInline, Input, Label } from '@expressable/ui-library';
import { useGetClientCommunicationPreferences } from 'hooks/use-communication-preferences';
import { format } from 'libphonenumber-js';
import { Contact } from 'types';
import { getPhoneNumber } from 'utils/helpers';
import { ContactFormInputs } from './client-contact-form';

import 'twin.macro';

type Props = {
  contact: Contact;
  clientId: string;
  register: UseFormRegister<ContactFormInputs>;
};

type CommunicationPreferenceInputProps = {
  id: string;
  label: string;
  isChecked: boolean;
  isDisabled: boolean;
  register: UseFormRegister<ContactFormInputs>;
};

const CommunicationPreferenceInput: FC<CommunicationPreferenceInputProps> = ({
  id,
  label,
  isChecked,
  isDisabled,
  register,
}) => (
  <FormInline tw="mb-3 flex flex-col md:flex-row">
    <div tw="flex items-baseline">
      <Input
        id={id}
        {...register(id as keyof ContactFormInputs)}
        type="checkbox"
        spacing="tight"
        disabled={isDisabled}
        defaultChecked={isChecked}
      />
      <Label htmlFor={id} tw="ml-2 text-base font-normal">{label}</Label>
    </div>
  </FormInline>
);

export const ContactCommunicationPreferences: FC<Props> = ({ contact, clientId, register }) => {
  const { data: preferences } = useGetClientCommunicationPreferences(clientId);
  const contactPreferences = preferences?.find(preference => preference.contactID === contact.contactID);

  const phoneNumber = getPhoneNumber(contact?.phone);
  const formattedPhoneNumber = phoneNumber ? format(phoneNumber, 'INTERNATIONAL') : 'No number provided';

  return (
    <div className="mb-12">
      <div tw="mb-2 mt-2 text-lg leading-none font-semibold">Communication Preferences</div>
      <div tw="text-base mb-5">Only selected communications will be sent to the designated contact method.</div>
      <div tw="mb-5">
        {Boolean(phoneNumber) && (
          <FormGroup>
            <CommunicationPreferenceInput
              id="enableSMSApptReminderNotifications"
              label={`Receive SMS appointment reminders (${formattedPhoneNumber})`}
              isChecked={contactPreferences?.enableSMSApptReminderNotifications ?? false}
              isDisabled={false}
              register={register}
            />
          </FormGroup>
        )}

        {Boolean(contact?.email) && (
          <CommunicationPreferenceInput
            id="enableEmailApptReminderNotifications"
            label={`Receive email appointment reminders (${contact?.email ?? 'No email provided'})`}
            isChecked={contactPreferences?.enableEmailApptReminderNotifications ?? false}
            isDisabled={!contact?.email}
            register={register}
          />
        )}

        {Boolean(phoneNumber) && (
          <CommunicationPreferenceInput
            id="enableSmsNotifications"
            label={`Receive SMS session and evaluation notes (${formattedPhoneNumber})`}
            isChecked={contactPreferences?.enableSmsNotifications ?? false}
            isDisabled={!Boolean(phoneNumber)}
            register={register}
          />
        )}

        {Boolean(contact?.email) && (
          <CommunicationPreferenceInput
            id="enableEmailNotifications"
            label={`Receive email session and evaluation notes (${contact?.email ?? 'No email provided'})`}
            isChecked={contactPreferences?.enableEmailNotifications ?? false}
            isDisabled={!contact?.email}
            register={register}
          />
        )}
      </div>
    </div>
  );
};
