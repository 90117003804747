import React, { useEffect, useState } from 'react';
import {
  Button,
  CloseButton,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@expressable/ui-library';
import 'twin.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/pro-solid-svg-icons';
import { Contact } from 'types';
import {
  GrantPortalAccessResponse,
  useGetClientsGrantedPortalAccess,
  useGrantPortalAccess,
} from 'hooks/use-client-contacts';
import { useToast } from '@expressable/ui-library';

export interface GrantPortalAccessModalProps {
  isOpen: boolean;
  onClose: () => void;
  clientId: string;
  contact: Contact;
}

const portalClientAppBaseUrl = process.env.REACT_APP_PORTAL_CLIENT_APP_URL ?? 'https://app.expressable.io';

const getPortalInvitationLink = (isEmailConfirmed: boolean, email: string, password: string) => {
  if (isEmailConfirmed) {
    return portalClientAppBaseUrl;
  }
  return `${portalClientAppBaseUrl}/invitation?email=${encodeURIComponent(email)}&code=${encodeURIComponent(password)}`;
};

export function GrantPortalAccessModal(props: GrantPortalAccessModalProps) {
  const { isOpen, onClose, contact, clientId } = props;
  const { mutateAsync: grantPortalAccess, isLoading: isGrantPortalAccessLoading } = useGrantPortalAccess();
  const { data: clientsGrantedPortalAccess, isLoading: isClientsGrantedPortalAccessLoading } =
    useGetClientsGrantedPortalAccess(contact.email);
  const [portalResponse, setPortalResponse] = useState<GrantPortalAccessResponse | null>(null);
  const [portalInvitationLink, setPortalInvitationLink] = useState<string | null>();
  const { successToast } = useToast();

  const contactFullName = `${contact.contactFirstName} ${contact.contactLastName}`;

  const handleCloseModal = async () => {
    setPortalResponse(null);
    setPortalInvitationLink(null);
    onClose();
  };

  const handleGrantClick = async () => {
    const response = await grantPortalAccess({
      clientID: clientId,
      contactID: contact.contactID,
    });

    setPortalResponse(response);

    const invitationLink = getPortalInvitationLink(
      response.isEmailConfirmed,
      contact.email,
      response.temporaryPassword,
    );
    setPortalInvitationLink(invitationLink);
  };

  const handleCopyClick = async () => {
    successToast('Invitation link successfully copied.');
    if (portalResponse) {
      await navigator.clipboard.writeText(portalInvitationLink!);
    }
    handleCloseModal();
  };

  const loading = isGrantPortalAccessLoading || isClientsGrantedPortalAccessLoading;

  useEffect(() => {
    // after the first grant, we don't want to display the username/temporaryPassword
    if (portalResponse?.isEmailConfirmed) {
      handleCloseModal();
    }
  }, [portalResponse]);

  return (
    <Modal portal isOpen={isOpen}>
      <ModalContent>
        <form>
          <ModalHeader>
            <CloseButton onClick={handleCloseModal} />
          </ModalHeader>
          <ModalBody>
            <div>
              <div tw="flex items-center justify-center w-12 h-12 mx-auto bg-indigo-100 rounded-full">
                <FontAwesomeIcon tw="text-2xl text-indigo-700" icon={faKey} />
              </div>
              <div tw="mt-3">
                <h3 tw="text-lg text-center font-medium">Grant Portal Access</h3>

                {portalResponse === null ? (
                  <div tw="mt-2">
                    <p tw="text-sm text-center">
                      Do you want to give <b>{contactFullName}</b> access to the Client Portal?
                    </p>
                    {Boolean(clientsGrantedPortalAccess?.length) && (
                      <div tw="text-xs text-center mt-5 bg-yellow-100 rounded-md p-2">
                        This contact is already associated with another client. <br />
                        By granting access this contact will have access to these clients:
                        <ul tw="mt-2">
                          {clientsGrantedPortalAccess!.map(item => (
                            <li tw="mt-1" key={item.clientID}>
                              <Link
                                tw="hover:bg-indigo-100"
                                target="_blank"
                                to={{ pathname: `/clients/${item.clientID}` }}
                              >
                                {`${item.clientName} (${contact.email})`}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                ) : (
                  <div tw="text-sm">
                    <div tw="mt-2">
                      <p tw="font-semibold">Invitation Link:</p>
                      <a href={portalInvitationLink!} className="text-indigo-700 break-words" target="_blank" rel="noreferrer">
                        {portalInvitationLink!}
                      </a>
                    </div>
                    <div tw="mt-2">
                      <p tw="font-semibold">Username:</p>
                      <p>{contact.email}</p>
                    </div>
                    <div tw="mt-2">
                      <p tw="font-semibold">Temporary Password:</p>
                      <p>{portalResponse.temporaryPassword}</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            {portalResponse === null ? (
              <div tw="mt-8 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <span tw="flex rounded-md shadow-sm sm:col-start-2">
                  <Button
                    type="button"
                    variant="primary"
                    loading={loading}
                    tw="w-full py-2 sm:text-sm"
                    onClick={handleGrantClick}
                    data-testid="grant-access"
                  >
                    Grant Access
                  </Button>
                </span>
                <span tw="flex mt-3 rounded-md shadow-sm sm:mt-0 sm:col-start-1">
                  <Button
                    type="button"
                    variant="secondary"
                    tw="w-full py-2 sm:text-sm"
                    onClick={handleCloseModal}
                    disabled={loading}
                    data-testid="cancel"
                  >
                    Cancel
                  </Button>
                </span>
              </div>
            ) : (
              <div tw="mt-8 sm:grid sm:grid-cols-1 sm:grid-flow-row-dense">
                <span tw="flex rounded-md shadow-sm">
                  <Button
                    type="button"
                    variant="primary"
                    tw="w-full py-2 sm:text-sm"
                    data-testid="copy-to-clipboard"
                    onClick={handleCopyClick}
                  >
                    Copy Invitation Link to Clipboard
                  </Button>
                </span>
              </div>
            )}
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
