import tw from 'twin.macro';

export const CLIENT_RESOURCES_CONFIG = {
  PAGINATION_SIZE: 18,
  DEFAULT_FIELD_ORDER: 'fields.title',
  ARTICULATION_ENTRY_ID: 'mkjnRglVc1AqCe0ufI637',
  MOBILE_BREAKPOINT: 768,
  FILTERS_DEFAULT_VALUES: {
    filterState: {
      keywords: '',
      ageRange: [],
      complexity: [],
      type: [],
      timeRequired: [],
      treatmentAreas: [],
      caregiverRequired: '',
      hasMaterials: '',
      contentType: 'all',
    },
  },
};

export const resultContainerStyles = {
  mobile: tw`w-full ml-0 mt-0`,
  desktop: tw`w-full ml-6`,
};
