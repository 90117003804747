import { Input, Label } from '@expressable/ui-library';
import { useFormValidation } from 'domain/form-validation';
import useOptional from 'domain/form-validation/useOptional';
import React, { forwardRef, useMemo } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';
import { getSchemaFieldAttributes } from 'utils/fieldAttributes';

interface RadioGroupProps {
  name: string;
  label?: string;
  optional?: boolean;
  options: { value: string; label: string }[];
  spacing?: 'tight' | 'loose';
  containerClass?: string;
  labelClass?: string;
  inputClass?: string;
  errorClass?: string;
}

export const RadioGroup = forwardRef<HTMLDivElement, RadioGroupProps>(
  (
    {
      name,
      label,
      optional: manualOptional,
      options,
      spacing = 'loose',
      containerClass,
      labelClass,
      inputClass,
      errorClass,
      ...rest
    },
    ref,
  ) => {
    const { register } = useFormContext();
    const { errors } = useFormState({ name });
    const { schema, serverErrors } = useFormValidation();
    const schemaFieldAttributes = useMemo(() => getSchemaFieldAttributes(schema, name), [schema, name]);
    const serverError = serverErrors[name];
    const optional = useOptional(name, manualOptional);

    return (
      <>
        <div ref={ref} className={containerClass}>
          {label && (
            <div className="flex">
              <Label className={labelClass} optional={optional}>
                {label}
              </Label>
            </div>
          )}
          <div className={`${inputClass}`}>
            {options.map(option => {
              // creates a unique id for each radio button
              const id = `${name}-${option.value}`;
              return (
                <div className="flex space-x-2" key={option.value}>
                  <Input
                    id={id}
                    type="radio"
                    spacing="tight"
                    {...rest}
                    {...schemaFieldAttributes}
                    {...register(name)}
                    key={option.value}
                    value={option.value}
                  />
                  <label htmlFor={id}>{option.label}</label>
                </div>
              );
            })}
          </div>
        </div>
        {errors[name] && <span className="flex text-xs text-red-500 italic mt-0">{errors[name].message}</span>}
        {serverError && <span className={errorClass ?? 'flex text-xs text-red-500 italic mt-0'}>{serverError}</span>}
      </>
    );
  },
);

RadioGroup.displayName = 'RadioGroup';
