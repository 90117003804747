import { useEffect, useMemo } from 'react';
import { Card, Label, FormGroup, Select } from '@expressable/ui-library';
import { useState } from 'react';
import { SelectOption } from 'types';

import 'twin.macro';
import { AssociatedMaterialsIfRequiredContentful } from 'domain/clinical-pathways/types';
import {
  getCustomStructuredActivityLink,
  getLearningPathLink,
  getStructuredActivityLink,
} from 'domain/clinical-pathways/contants';
import { ClientResourcesProgram } from 'domain/client-resources/types';

export type HomeProgramCardProps = {
  program: ClientResourcesProgram;
};

const RESOURCE_MAPPING = {
  'academy-homework': getStructuredActivityLink,
  'custom-structured-activity': getCustomStructuredActivityLink,
  'learning-path': getLearningPathLink,
};

export const ResourceCard = ({ program }: HomeProgramCardProps) => {
  const [materials, setSelectedMaterial] = useState<SelectOption | undefined>(undefined);

  const getMaterialsOptions = (assocMaterials: AssociatedMaterialsIfRequiredContentful) => {
    const uploadMaterials = assocMaterials?.uploadMaterial
      .filter(material => Boolean(material?.file?.url && material?.title))
      ?.map(material => {
        return { label: material?.title, value: material?.file?.url };
      });

    return uploadMaterials;
  };

  const setDefaultMaterial = () => {
    if (program?.associatedMaterialsIfRequired?.length)
      setSelectedMaterial(program?.associatedMaterialsIfRequired?.map(getMaterialsOptions).flat()[0]);
  };

  useEffect(setDefaultMaterial, []);

  const cardLink = useMemo(() => {
    const link = RESOURCE_MAPPING[program.type];
    return link ? link(program.id) : '';
  }, [program]);

  if (program.associatedMaterialsIfRequired?.map(getMaterialsOptions).flat().length === 0) return null;

  return (
    <div tw="my-1 mb-5 px-1 w-full lg:my-4 lg:px-4 lg:w-1/3 min-h-[285px]  min-w-[322px] max-w-[550px] ">
      <Card tw="shadow-md h-full flex flex-col justify-between space-y-2 p-8">
        <Label tw="md:text-base text-sm">
          {program.title}{' '}
          {program.type === 'learning-path' &&
            `(${program.category?.[0]?.treatmentAreaCategory || 'MISSING CATEGORY'})`}
        </Label>
        <p tw="pt-3 pb-5 text-sm md:text-sm  flex flex-1">{program.description}</p>
        {Boolean(program.associatedMaterialsIfRequired?.length === 1) && (
          <div tw="flex min-h-[78px]">
            <FormGroup tw="text-sm md:text-sm font-semibold block">
              Material: <span tw="font-normal">{materials?.label}</span>
            </FormGroup>
          </div>
        )}
        {Boolean((program?.associatedMaterialsIfRequired?.length ?? 0) > 1) && (
          <FormGroup tw="w-full text-sm md:text-sm">
            <Label htmlFor="materials-dropdown" tw="font-semibold text-xs md:text-sm">
              Materials
            </Label>
            <Select
              data-testid={`client-resources__materials-dropdown__${program.title}`}
              name="materials-dropdown"
              title={`You selected ${materials?.label ?? 'nothing'}`}
              options={program.associatedMaterialsIfRequired?.map(getMaterialsOptions).flat() ?? []}
              defaultValue={program.associatedMaterialsIfRequired?.map(getMaterialsOptions).flat()[0]}
              onChange={(e: { label: string; value: string }) =>
                setSelectedMaterial({ label: e.label, value: e.value })
              }
            />
          </FormGroup>
        )}
        <div tw="flex space-x-4 items-end">
          <>
            <a
              title="Preview the home Program in a new tab"
              tw="text-indigo-700 font-medium text-sm md:text-sm"
              href={cardLink}
              target="_blank"
              rel="noreferrer"
              data-testid={`client-resources__preview-${program.title}`}
            >
              View{' '}
              {program.type === 'academy-homework' || program.type === 'custom-structured-activity'
                ? 'Activity'
                : 'Learning Path'}
            </a>
            {Boolean(program.associatedMaterialsIfRequired?.length) && (
              <a
                title="Preview the home Program in a new tab"
                tw="text-indigo-700 font-medium text-sm md:text-sm"
                href={materials?.value}
                target="_blank"
                rel="noreferrer"
                data-testid={`client-resources__preview-${program.title}`}
              >
                View Material
              </a>
            )}
          </>
        </div>
      </Card>
    </div>
  );
};
