export default [
  { value: 'PAS01', label: 'PROVIDENCE ADMINISTRATIVE SERVICES (PAS01)' },
  { value: 'KGA15', label: 'KG ADMINISTRATIVE SERVICES (KGA15)' },
  { value: '35115', label: 'INTOTAL HEALTH (35115)' },
  { value: '61115', label: 'HUMANA LONG TERM CARE (61115)' },
  { value: 'HALCY', label: 'HALCYON BEHAVIORAL HEALTH (HALCY)' },
  { value: '95271', label: 'PINNACLE MEDICAL GROUP (95271)' },
  { value: 'MPM26', label: 'INTEGRATED HEALTH PARTNERS (IHP)  (MPM26)' },
  { value: '76079', label: 'MIDWEST HEALTH PARTNERS (76079)' },
  { value: 'MP340', label: 'THREE RIVERS PREFERRED (MP340)' },
  { value: '14966', label: 'PARTNERS HEALTH PLAN (14966)' },
  { value: '53226', label: 'PENN BEHAVIORAL HEALTH (53226)' },
  { value: 'EC999', label: 'EXCLUSIVE CARE - COUNTY OF RIVERSIDE (EC999)' },
  { value: 'EC999', label: 'EXCLUSIVE CARE (EC999)' },
  { value: '86083', label: 'SUMMIT ADMINISTRATION SERVICES, INC. (86083)' },
  { value: '76031', label: 'NEW ERA EMPLOYEE WELFARE BENEFIT TRUST (76031)' },
  { value: '45302', label: 'INTEGRA MANAGED LONG TERM CARE (45302)' },
  { value: '89890', label: 'KERN LEGACY HEALTH PLAN (89890)' },
  { value: '14163', label: 'EASY CHOICE HEALTH PLAN OF CA (14163)' },
  { value: 'CEC', label: 'COMMUNITY EYE CARE (CEC)' },
  { value: 'SAMG1', label: 'SOUTH ATLANTIC MEDICAL GROUP (SAMG1)' },
  { value: 'SWPG1', label: 'SOUTHWEST PHYSICIANS GROUP LTD (SWPG1)' },
  { value: 'AAMG1', label: 'ASIAN AMERICAN MEDICAL GROUP (AAMG) (AAMG1)' },
  { value: 'ECMSO', label: 'EASY CARE MSO LLC (ECMSO)' },
  { value: 'ECMSO', label: 'SUPERIOR CHOICE MEDICAL GROUP (ECMSO)' },
  { value: 'ECMSO', label: 'BEVERLY HOSPITAL - IMPERIAL HEALTH HOLDINGS (ECMSO)' },
  { value: 'ECMSO', label: 'ANGEL MEDICAL GROUP (ECMSO)' },
  { value: 'ECMSO', label: 'UNITED PHYSICIANS INTERNATIONAL (UPI) (ECMSO)' },
  { value: 'ECMSO', label: 'SOUTHERN CALIFORNIA CHILDRENS HEALTH CARE NETWORK (ECMSO)' },
  { value: 'WHLTH', label: 'WILLOW HEALTH (WHLTH)' },
  { value: '11889', label: 'INSURANCE SYSTEMS, INC. (11889)' },
  { value: '63240', label: 'ALTERNATIVE RISK MANAGEMENT (ARM, LTD) (63240)' },
  { value: 'PCU01', label: 'POINT COMFORT UNDERWRITERS (PCU01)' },
  { value: '48123', label: 'VALIR PACE (48123)' },
  { value: '56001', label: 'UCS (THE CITY OF EAST CHICAGO) (56001)' },
  { value: 'CM001', label: 'CHIROMETRICS (CM001)' },
  { value: 'SNTMC', label: 'SANTE COMMUNITY PHYSICIANS MEDICAL GROUP CORP (SNTMC)' },
  { value: 'TRSEL', label: 'SELMAN TRICARE SUPPLEMENT (TRSEL)' },
  { value: '91171', label: 'PHYSICIANS OF SOUTHWEST WASHINGTON (91171)' },
  { value: '6294', label: 'SOUTH FLORIDA MUSCULOSKELETAL CARE (6294)' },
  { value: '31149', label: 'MCGREGOR PACE (31149)' },
  { value: 'PROV1', label: 'PROVIDENCE CARE NETWORK (PROV1)' },
  { value: 'PCMSO', label: 'PREMIER CARE IPA (PCMSO)' },
  { value: 'RCHN1', label: 'CHILDRENS PHYSICIANS MEDICAL GROUP (RCHN1)' },
  { value: 'RCHN1', label: 'PHYSICIANS MANAGEMENT GROUP (RCHN1)' },
  { value: 'RCHN1', label: 'RADY CHILDRENS HEALTH NETWORK (RCHN1)' },
  { value: '97691', label: 'SILVER STAR PACE (97691)' },
  { value: '74256', label: 'NORTH CAROLINA DEPARTMENT OF PUBLIC SAFETY (74256)' },
  { value: '251CC', label: "CHILDREN'S COMMUNITY HEALTH PLAN (251CC)" },
  { value: '45114', label: 'PACE OF SOUTHWEST MICHIGAN (45114)' },
  { value: 'LWMC1', label: 'LEISURE WORLD MANAGED CARE (LWMC1)' },
  { value: 'PA331', label: 'PAYER COMPASS (PA331)' },
  { value: 'CPNAV', label: 'CHOICE PHYSICIANS NETWORK (ANTELOPE VALLEY MEDICAL (CPNAV)' },
  { value: '64270', label: 'CORPORATE PLAN MANAGEMENT (64270)' },
  { value: 'NSIPA', label: 'NETWORK SOLUTIONS IPA (NSIPA)' },
  { value: 'MP001', label: 'MEDPAY FIRST ONE (MP001)' },
  { value: '46156', label: 'ASPIRE HEALTH PLAN (46156)' },
  { value: '11329', label: 'INTERNATIONAL BENEFITS ADMINISTRATORS (11329)' },
  { value: '59279', label: 'BAYCARE LIFE MANAGEMENT (59279)' },
  { value: '90210', label: 'KEMPTON GROUP ADMINISTRATORS (USC) (90210)' },
  { value: '53534', label: 'PACE SOUTHWEST IOWA (53534)' },
  { value: '72436', label: 'PACE CENTRAL IOWA (72436)' },
  { value: '35416', label: 'PACE NEBRASKA (35416)' },
  { value: 'DCHPMCAID', label: "DELL CHILDREN'S HEALTH PLAN (DCHPMCAID)" },
  { value: 'LA-DHH-MEDICAID', label: 'LOUISIANA MEDICAID MEDICARE ADVANTAGE CROSSOVERS (LA-DHH-MEDICAID)' },
  { value: 'CX100', label: 'SOUTHWEST SERVICE ADMINISTRATORS, INC. (CX100)' },
  { value: '26097', label: 'ML HEALTHCARE (26097)' },
  { value: 'CB457', label: 'WELLSPAN EMPLOYEE ASSISTANCE PROGRAM (CB457)' },
  { value: 'CB231', label: 'INDEPENDENCE AMERICAN INSURANCE COMPANY (CB231)' },
  { value: '30031', label: 'PRO-CLAIM PLUS, INC. (30031)' },
  { value: 'GACS1', label: 'CARESOURCE OF GEORGIA (GACS1)' },
  { value: 'CMSEB', label: 'COVENANT MANAGEMENT SYSTEMS EMPLOYEE BENEFIT PLAN (CMSEB)' },
  { value: 'CSSD2', label: "CHILDREN'S SPECIALISTS OF SAN DIEGO (CSSD2)" },
  { value: '48888', label: 'EMPLOYER DIRECT HEALTHCARE (48888)' },
  { value: 'SHPM1', label: 'SCRIPPS HEALTH PLAN MSO (SHPM1)' },
  { value: '57721', label: 'CAREATC (57721)' },
  { value: '12113', label: "WOMEN'S HEALTH CHECK (12113)" },
  { value: '12113', label: 'CHILDRENS SPECIAL HEALTH PROGRAM (12113)' },
  { value: '12113', label: 'IDAHO WOMEN AND CHILD PROGRAMS (12113)' },
  { value: '75309', label: 'PACIFIC SOUTHWEST ADMINISTRATORS (75309)' },
  { value: '41204', label: 'TCC BASIC PLUS (41204)' },
  { value: 'LWA01', label: 'LIFEWORKS ADVANTAGE (ISNP PLAN) (LWA01)' },
  { value: 'DVMC1', label: 'DESERT VALLEY MEDICAL GROUP (DVMC1)' },
  { value: '84041', label: 'LOCAL 137 OPERATING ENGINEERS WELFARE FUND (84041)' },
  { value: 'ALPHA', label: 'ALPHACARE (ALPHA)' },
  { value: 'UIC67', label: 'CAMPUSCARE (UIC67)' },
  { value: 'UIC67', label: 'UNIVERSITY OF ILLINOIS\t (UIC67)' },
  { value: 'FLPAC', label: 'FLORIDA PACE CENTERS (FLPAC)' },
  { value: 'NHC01', label: 'NHC ADVANTAGE (NHC01)' },
  { value: 'ARA01', label: 'AGERIGHT ADVANTAGE HEALTH PLAN (ARA01)' },
  { value: 'INDPM', label: 'INDEPENDENT PHYSICIANS AT MERCY (INDPM)' },
  { value: 'BCTF1', label: 'BAKERY AND CONFECTIONERY UNION AND INDUSTRY INTERN (BCTF1)' },
  { value: '19753', label: 'BENEFIT PLAN ADMINISTRATORS - HYLTON PAYROLL (19753)' },
  { value: '22286', label: 'BRITCAY MEDICAL (22286)' },
  { value: '88092', label: 'BENEFIT MANAGEMENT LLC/VBA (88092)' },
  { value: '99433', label: 'CONTESSA HEALTH (99433)' },
  { value: '67788', label: 'NAHGA CLAIM SERVICES (67788)' },
  { value: '85036', label: 'NEW MEXICO PUBLIC SCHOOLS INSURANCE AUTHORITY (85036)' },
  { value: 'CB637', label: 'AEGIS ADMINISTRATIVE SERVICES (CB637)' },
  { value: '97802', label: 'SUPERIOR INSURANCE SERVICES (97802)' },
  { value: 'IPA99', label: 'ILLINOIS PHYSICIANS ALLIANCE IPA (IPA99)' },
  { value: '36312', label: 'MANAGED HEALTH CARE ASSOCIATES (36312)' },
  { value: '43123', label: 'IMAGINE HEALTH (43123)' },
  { value: '84131', label: 'DENVER HEALTH MEDICAL PLAN, INC. - MEDICARE CHOICE (84131)' },
  { value: '37237', label: 'UMR LEXINGTON (37237)' },
  { value: 'PSKW0', label: 'BMS ONCOLOGY COPAY PROGRAM (PSKW0)' },
  { value: '88622', label: 'QUALITY HEALTH PLANS OF NEW YORK (88622)' },
  { value: 'MC721', label: 'BLUE CROSS COMMUNITY CENTENNIAL (MC721)' },
  { value: 'ASHC1', label: 'ASSURANT SUPPLEMENTAL INSURANCE (ASHC1)' },
  { value: 'ASHC1', label: 'NATIONAL GENERAL (ASHC1)' },
  { value: '77130', label: 'YAMHILL COUNTY CCO (MENTAL HEALTH) (77130)' },
  { value: '77111', label: 'WASHINGTON COUNTY GENERAL FUND (77111)' },
  { value: '77122', label: 'HEALTH SHARE OF OREGON (MENTAL HEALTH) (77122)' },
  { value: 'AVA02', label: 'AVALON ADMINISTRATIVE SERVICES - BCBSNC (AVA02)' },
  { value: 'WVS01', label: 'WEST VIRGINIA SENIOR ADVANTAGE (WVS01)' },
  { value: '75604', label: 'JPS CONNECTION (75604)' },
  { value: 'KP', label: 'BLUE CROSS COMPLETE - JVHL (KP)' },
  { value: 'MD', label: 'AMERIHEALTH CARITAS VIP CARE PLUS - JVHL (MD)' },
  { value: 'GHAHSS', label: 'GLOBAL HEALTHCARE ALLIANCE (GHAHSS)' },
  { value: '25849', label: 'EAST BOSTON NEIGHBORHOOD PACE (25849)' },
  { value: '88221', label: 'TEXAS HEALTH AETNA (88221)' },
  { value: 'HUMANARC', label: 'HUMAN ARC (HUMANARC)' },
  { value: '15682', label: 'SENIOR NETWORK HEALTH - UTICA (15682)' },
  { value: '21614', label: 'PACE GREATE NEW ORLEANS (NORLN) (21614)' },
  { value: '43185', label: 'MERCY PROVIDER NETWORK (43185)' },
  { value: '43619', label: 'TEAMSTERS MEDICARE TRUST FOR RETIRED EMPLOYEES (TM (43619)' },
  { value: '81502', label: 'PRIME HEALTH CHOICE (81502)' },
  { value: '82694', label: 'THE MACALUSO GROUP (82694)' },
  { value: '86087', label: 'MERCHANTS BENEFIT ADMINISTRATION (86087)' },
  { value: '95164', label: 'CEDARS SINAI MEDICAL CENTER (95164)' },
  { value: 'CB212', label: 'LUTHER CARE (CB212)' },
  { value: 'HCH01', label: 'HEALTHCARE HIGHWAYS (HCH01)' },
  { value: '61325', label: 'PASSPORT HEALTH PLAN DOS BEFORE 1/1/2021 (61325)' },
  { value: 'RUTMB', label: 'UTMB CARE (RUTMB)' },
  { value: '71084', label: 'HEALTHSCOPE BENEFITS (71084)' },
  { value: '5415M', label: 'SENTARA MENTAL HEALTH (5415M)' },
  { value: 'UTMB3', label: 'UTMB 3 SHARE (UTMB3)' },
  { value: '20090', label: 'UNITED CLAIM SOLUTIONS (20090)' },
  { value: '94316', label: 'BROWN AND TOLAND MEDICAL GROUP (94316)' },
  { value: '77505', label: 'UMPQUA HEALTH ALLIANCE (77505)' },
  { value: '7661S', label: 'OSU SOONERCARE SECONDARY (7661S)' },
  { value: 'NDX99', label: 'NEW DIRECTIONS BEHAVIORAL HEALTH (NDX99)' },
  { value: 'SBMED', label: 'SAN BERNARDINO MEDICAL GROUP (SBMED)' },
  { value: 'IHHMG', label: 'IMPERIAL HEALTH HOLDINGS MEDICAL GROUP (IHHMG)' },
  { value: 'MCCVA', label: 'MAGELLAN COMPLETE CARE OF VIRGINIA (MCCVA)' },
  { value: 'KOVA1', label: 'KOVA HEALTHCARE INC (KOVA1)' },
  { value: '68069', label: 'AMBETTER OF NORTH CAROLINA (68069)' },
  { value: '', label: 'QUALMED OREGON HEALTH PLANS ()' },
  { value: '', label: 'MICHIGAN HLTH BENEFITS CLAIM REVIEW FORM ()' },
  { value: '', label: 'WEST VIRGINIA BC BS OF  PARKERSBURG ()' },
  { value: '', label: "TEXAS WORKERS' COMPENSATION ()" },
  { value: 'KELSE', label: 'KELSEY-SEYBOLD (KELSE)' },
  { value: '60509', label: 'ELMCO (60509)' },
  { value: '58202', label: 'MEDADMIN SOLUTIONS (ADS) (58202)' },
  { value: '41124', label: 'EMPLOYEE BENEFIT SERVICES OF LOUISIANA, INC. (EBS) (41124)' },
  { value: '41124', label: 'MERITAIN HEALTH MINNEAPOLIS (41124)' },
  { value: '-', label: 'HEALTH SERVICE MANAGEMENT (-)' },
  { value: '59298', label: 'ASCENSION BENEFITS (JACKSONVILLE, FL) (59298)' },
  { value: '11328', label: 'TOUCHSTONE HEALTH (PRESTIGE PLAN) (11328)' },
  { value: '11328', label: 'TOUCHSTONE HEALTH/HEALTH NET SMART CHOICE (11328)' },
  { value: '11328', label: 'HEALTHCARE PARTNERS IPA HERITAGE NY MEDICAL GROUP (11328)' },
  { value: '11328', label: 'HEATLHCARE PARTNERS, IPA  (11328)' },
  { value: '11328', label: 'LIBERTY HEALTH ADVANTAGE  (11328)' },
  { value: '11328', label: 'TOUCHSTONE HEALTH PSO (11328)' },
  { value: '57080', label: 'CWI BENEFITS, INC. (57080)' },
  { value: '57080', label: 'CLAIMSWARE (57080)' },
  { value: '14180', label: 'CARECORE OXFORD RADIOLOGY (14180)' },
  { value: '24735', label: 'WESTERN GROWERS ASSURANCE TRUST (24735)' },
  { value: '24735', label: 'PINNACLE CLAIMS MANAGEMENT, INC. (24735)' },
  { value: '24735', label: 'WESTERN GROWERS INSURANCE COMPANY (24735)' },
  { value: '47080', label: 'MIDLANDS BENEFITS ADMINISTRATORS (47080)' },
  { value: '47080', label: 'MIDLANDS CHOICE (47080)' },
  { value: '91136', label: 'ALASKA CARPENTERS TRUST (91136)' },
  { value: '91136', label: 'ALASKA HOTEL EMPLOYEES RESTAURANT & CAMP EMPLOYEES (91136)' },
  { value: '91136', label: 'ALLIED METAL CRAFTS SECURITY PLAN TRUST FUND (91136)' },
  { value: '91136', label: 'FAIRBANKS NORTH STAR BOROUGH SCHOOL DIST PLAN A (91136)' },
  { value: '91136', label: 'NW ROOFERS/EMPLOYERS HEALTH & SECURITY TRUST FUND (91136)' },
  { value: '91136', label: 'PUGET SOUND BENEFITS TRUST (91136)' },
  { value: '91136', label: 'WELFARE PENSION ADMIN SERVICES (WPAS) (91136)' },
  { value: '91136', label: 'CEMENT MASONS & PLASTERERS HLTH/WEL TRUS (91136)' },
  { value: '91136', label: 'GLASSWORKERS HEALTH & WELFARE FUND (91136)' },
  { value: '91136', label: 'N.W. IRONWORKERS HLTH & SECURITY TRUST (91136)' },
  { value: '91136', label: 'N.W.TEXTILE PROCESSORS (91136)' },
  { value: '91136', label: 'AK UNITED FOOD AND COMMERCIAL WORKERS (AK UFCW) (91136)' },
  { value: '91136', label: 'FAIRBANKS NORTH STAR BOROUGH (91136)' },
  { value: '91136', label: 'NORTHWEST IRONWORKERS HEALTH & SECURITY FUND (91136)' },
  { value: '91136', label: 'SEATTLE WASHINGTON PLANS (91136)' },
  { value: '91136', label: 'FAIRBANKS NORTH STAR BOROUGH SCHOOL DIST PLAN B (91136)' },
  { value: '91136', label: 'ALASKA PIPE TRADES LOCAL 375 (91136)' },
  { value: '91136', label: 'N.W. ROOFERS & EMP HLTH & SECURITY TRUST (91136)' },
  { value: '91136', label: 'AGC INTERNATIONAL UNION OF OPERATING ENGINEERS LOC (91136)' },
  { value: '91136', label: 'NORTHWEST TEXTILE PROCESSORS AND SERVICE TRADES (91136)' },
  { value: '91136', label: 'HOTEL EMP & RESTAURANT EMP HEALTH TRUST (91136)' },
  { value: '91136', label: 'OPERATING ENG. LOCALS 302/612 HLT & SEC (91136)' },
  { value: '91136', label: 'ALASKA UNITED FOOD & COMM WRKRS HLTH/WEL (91136)' },
  { value: '91136', label: "PLASTERERS' & CEMENT MASONS' HEALTH & WELFARE FUND (91136)" },
  { value: '91136', label: 'UW GRADUATE APPOINTEE PLAN (91136)' },
  { value: '91136', label: 'ALASKA LABORERS CONST. INDUSTRY GROUP (91136)' },
  { value: '91136', label: 'PUGET SOUND ELECTRICAL WORKERS TRUST (91136)' },
  { value: '91136', label: 'SOUTH BAY HOTEL RESTAURANT EMPLOYEES HEALTH TRUST (91136)' },
  { value: '91136', label: 'WA STATE COUNCIL OF COUNTY & CITY EMPLOYEES WSCCC (91136)' },
  { value: '91136', label: 'ALASKA PUBLIC EMPLOYEES ASSOCIATION  (91136)' },
  { value: '91136', label: 'NW INTERNATIONAL ASSOCIATION MACHINISTS (NW IAM) (91136)' },
  { value: '91136', label: 'NW PLUMBERS & PIPEFITTERS HEALTH & WELFARE TRUST (91136)' },
  { value: '91136', label: 'ALASKA MACHINISTS HEALTH AND WELFARE TRUST (91136)' },
  { value: '91136', label: 'LOCAL 302 & 612 OF INTL UNION OPERATING ENGINEERS (91136)' },
  { value: '91136', label: 'ALASKA CHILDRENS SERVICES, INC. (91136)' },
  { value: '91136', label: 'ENSTAR NATURAL GAS (91136)' },
  { value: 'BTHS1', label: 'BROWN AND TOLAND PHYSICIANS (BTHS1)' },
  { value: '35161', label: 'COMMUNITY HEALTH DIRECT (35161)' },
  { value: '75234', label: 'HEALTH FIRST TPA (TYLER, TEXAS) (75234)' },
  { value: '57117', label: 'QVI RISK SOLUTIONS (57117)' },
  { value: '36338', label: 'UNIVERSAL STANDARD HEALTHCARE, INC. (36338)' },
  { value: '36338', label: 'GROUP ADMINISTRATORS LTD. (36338)' },
  { value: 'E3510', label: 'PRIMECARE HEALTH PLAN (E3510)' },
  { value: 'E3510', label: 'PRIMECARE OF RIVERSIDE (E3510)' },
  { value: 'E3510', label: 'PRIMECARE OF CHINO VALLEY (E3510)' },
  { value: 'E3510', label: 'PRIMECARE OF HEMET VALLEY (E3510)' },
  { value: 'E3510', label: 'PRIMECARE OF SUN CITY (E3510)' },
  { value: 'E3510', label: 'ALTA SENIOR CARE (HLTHNET SR. AND SECURE HORIZONS) (E3510)' },
  { value: 'E3510', label: 'NORTH AMERICAN MEDICAL MANAGEMENT (NAMM - N CA) (E3510)' },
  { value: 'E3510', label: 'PRIMECARE OF REDLANDS (E3510)' },
  { value: 'E3510', label: 'NORTH AMERICAN MEDICAL MANAGEMENT (NAMM) - S0.CA (E3510)' },
  { value: 'E3510', label: 'PRIMECARE OF TEMECULA (E3510)' },
  { value: 'E3510', label: 'ABMA (ALTA BATEES MEDICAL ASSOCIATES) MEDICAL CORP (E3510)' },
  { value: 'E3510', label: 'OPTUMCARE NETWORK OF CONNECTICUT (E3510)' },
  { value: 'E3510', label: 'CENTRAL VALLEY MEDICAL GROUP (E3510)' },
  { value: 'E3510', label: 'MERCY PHYSICIANS MEDICAL GROUP (E3510)' },
  { value: 'E3510', label: 'PRIMECARE OF CORONA (E3510)' },
  { value: 'E3510', label: 'VALLEY PHYSICIANS NETWORK (E3510)' },
  { value: 'E3510', label: 'NORTH AMERICAN MEDICAL MGMT (NAMM)-N CALIFORNIA (E3510)' },
  { value: 'E3510', label: 'PRIMECARE OF INLAND VALLEY (E3510)' },
  { value: 'E3510', label: 'PRIMECARE OF MARENO VALLEY (E3510)' },
  { value: '46045', label: 'AVERA HEALTH PLANS (46045)' },
  { value: '73145', label: 'PREFERRED COMMUNITY CHOICE/PCCSELECT/COMPMED (73145)' },
  { value: '56190', label: 'ENVOLVE BENEFIT OPTIONS (56190)' },
  { value: '61146', label: 'MIDWEST GROUP BENEFITS (61146)' },
  { value: '84132', label: 'UNITED MEDICAL ALLIANCE (84132)' },
  { value: '62061', label: 'FISERV HEALTH (KANSAS AND TENNESSEE) (62061)' },
  { value: '96475', label: 'HEALTHLINK HMO (96475)' },
  { value: '62160', label: 'MEDSOLUTIONS, INC. (62160)' },
  { value: '88019', label: 'TEACHERS HEALTH TRUST (88019)' },
  { value: '51037', label: 'BROKERAGE CONCEPTS (51037)' },
  { value: '35112', label: 'EMPLOYEE PLANS (35112)' },
  { value: '25133', label: 'COVENTRY HEALTH CARE NATIONAL NETWORK (25133)' },
  { value: '25133', label: 'COVENTRY HEALTH CARE COVENTRY CARES (25133)' },
  { value: '25133', label: 'PERSONAL CARE (25133)' },
  { value: '25133', label: 'COVENTRY ADVANTRA (TX, NM, AZ ONLY) (25133)' },
  { value: '25133', label: 'CARPENTERS HEALTH AND WELFARE FUND (25133)' },
  { value: '25133', label: 'HEALTH CARE USA (25133)' },
  { value: '25133', label: 'COVENTRY HEALTH CARE  (25133)' },
  { value: '25133', label: 'PREFERRED BENEFITS ADMINISTRATOR - WICHITA, KS (25133)' },
  { value: '25133', label: 'PREFERRED HEALTH SYSTEMS - KANSAS (25133)' },
  { value: '25133', label: 'ALTIUSONE (25133)' },
  { value: '25133', label: 'MERCY HEALTH PLAN (MHP) OF MISSOURI (25133)' },
  { value: '25133', label: 'PERSONAL PHYSICIAN CARE (25133)' },
  { value: '25133', label: 'COVENTRYCARES OF WEST VIRGINIA (25133)' },
  { value: '25133', label: 'COVENTRY DIAMOND PLAN (25133)' },
  { value: '25133', label: 'CARELINK HEALTH PLAN - WV AND OH (25133)' },
  { value: '25133', label: 'COVENTRYCARES OF VIRGINIA (25133)' },
  { value: '25133', label: 'COVENTRY HEALTH CARE CARELINK MEDICAID (25133)' },
  { value: '25133', label: 'COVENTRY CARES PENNSYLVANIA (25133)' },
  { value: '25133', label: 'COVENTRY HEALTH CARE USA (25133)' },
  { value: '25133', label: 'COVENTRYCARES OF KENTUCKY (25133)' },
  { value: '25133', label: 'CARELINK ADVANTRA - WV AND OH (25133)' },
  { value: '25133', label: 'COVENTRY HEALTHCARE OF FLORIDA (25133)' },
  { value: '25133', label: 'MOUNTAIN HEALTH CO-OP (25133)' },
  { value: '25133', label: 'RURAL CARRIER BENEFIT PLAN (25133)' },
  { value: '25133', label: 'FAMILY HEALTH PARTNERS MC + MISSOURI (25133)' },
  { value: '25133', label: 'COVENTRY HEALTH CARE OF THE CAROLINAS (25133)' },
  { value: '25133', label: 'HEALTHAMERICA ONE (25133)' },
  { value: '25133', label: 'COVENTRYCARES HEALTH PLAN (25133)' },
  { value: '25133', label: 'COVENTRYCARES OF MICHIGAN (25133)' },
  { value: '25133', label: 'VISTA MEDICAID FLORIDA (25133)' },
  { value: '25133', label: 'COVENTRY HEALTH CARE OMNICARE (25133)' },
  { value: '72467', label: 'ACS BENEFIT SERVICES, INC. (72467)' },
  { value: '31118', label: 'CENTRAL BENEFITS NATIONAL (31118)' },
  { value: '31118', label: 'CENTRAL BENEFITS LIFE (31118)' },
  { value: '31118', label: 'CENTRAL BENEFITS MUTUAL (31118)' },
  { value: '37116', label: 'LAKE COUNTY PHYSICIANS ASSOCIATION (37116)' },
  { value: '31625', label: 'ELDERPLAN, INC. (31625)' },
  { value: '31625', label: 'HOMEFIRST, INC., A PRODUCT OF ELDERPLAN (31625)' },
  { value: '75232', label: 'FIRST INTEGRATED HEALTH (75232)' },
  { value: '34189', label: 'OHIO HEALTH CHOICE, PPO (34189)' },
  { value: '31127', label: 'INTEGRA GROUP (31127)' },
  { value: '72099', label: 'NEW ORLEANS DIVISION OF MULTIPLAN (72099)' },
  { value: '35162', label: 'MANAGED CARE SERVICES (35162)' },
  { value: '42137', label: 'SELECT BENEFIT ADMINISTRATORS (DES MOINES, IA) (42137)' },
  { value: '58204', label: 'PODI CARE MANAGED CARE (58204)' },
  { value: '58204', label: 'PHYSICIAN ASSOCIATES OF LOUISIANA (58204)' },
  { value: '58204', label: 'GI INNOVATIVE MANAGEMENT (58204)' },
  { value: '58204', label: 'MEDADMIN SOLUTIONS (PHC) (58204)' },
  { value: '58204', label: 'PARITY HEALTHCARE LLC (58204)' },
  { value: '58204', label: 'COORDINATED MEDICAL SPECIALISTS (58204)' },
  { value: '22240', label: 'SAINT BARNABAS SYSTEM HEALTH PLAN (22240)' },
  { value: '41212', label: 'FREEDOM HEALTH (41212)' },
  { value: '82056', label: 'CUSTOM DESIGN BENEFITS, INC. (82056)' },
  { value: 'HESUN', label: 'HEALTHSUN (HESUN)' },
  { value: 'PHCS1', label: 'PHYSICIANS HEALTH CHOICE (PHCS1)' },
  { value: 'WCAS24101', label: 'AIG (WCAS24101)' },
  { value: 'SX173', label: 'BEACON HEALTH OPTNS COLORADO HLTH NTWRKS PRTNSHIP (SX173)' },
  { value: 'SX173', label: 'BEACON HEALTH OPTIONS - SOUTHWEST PENNSYLVANIA  (SX173)' },
  { value: 'SX173', label: 'VALUE OPTIONS (SX173)' },
  { value: 'SX173', label: 'VALUE OPTIONS BEHAVIORAL HEALTH OF PA (SX173)' },
  { value: 'SX173', label: 'BEACON HEALTH OPTIONS FKA VALUE OPTIONS (SX173)' },
  { value: 'MRIPA', label: 'ALLCARE HEALTH PLAN (MRIPA)' },
  { value: 'MRIPA', label: 'MID ROGUE OREGON HEALTH PLAN (MRIPA)' },
  { value: 'MRIPA', label: 'MID ROGUE IPA (MRIPA)' },
  { value: '95340', label: 'ADVENTIST HEALTH SYSTEM (95340)' },
  { value: 'KENT1', label: 'KENT COUNTY HEALTH PLAN (KENT1)' },
  { value: '403', label: 'BLUECHOICE SOUTH CAROLINA MEDICAID (403)' },
  { value: '403', label: 'BLUECHOICE HEALTHPLAN SC (403)' },
  { value: '953327434', label: 'SAMARITAN HEALTH PLANS (953327434)' },
  { value: '953327434', label: 'INTERCOMMUNITY HEALTH PLAN (953327434)' },
  { value: '953327434', label: 'SAMARITAN ADVANTAGE HEALTH PLAN HMO  (953327434)' },
  { value: '953327434', label: 'SAMARITAN CHOICE PLANS (953327434)' },
  { value: '953327434', label: 'SAMARITAN EMPLOYER GROUP PLANS (953327434)' },
  { value: 'HM067', label: 'MILLS PENINSULA MEDICAL GROUP, INC. (HM067)' },
  { value: '68195', label: 'PROVIDENT LIFE INSURANCE COMPANY (68195)' },
  { value: '62324', label: 'FREEDOM LIFE INSURANCE COMPANY (62324)' },
  { value: 'AMF11', label: 'AMERICAN FAMILY INSURANCE (AMF11)' },
  { value: '65063', label: 'FLORIDA NETPASS (65063)' },
  { value: '62176', label: 'ARKANSAS MANAGED CARE ORGANIZATION (62176)' },
  { value: '41178', label: 'AMERICAS TPA (41178)' },
  { value: '41178', label: 'HEALTHEZ (41178)' },
  { value: 'MADME', label: 'MEDICARE ADVANTAGE DME (DMENSION) (MADME)' },
  { value: 'EMP01', label: 'EMPIRE PHYSICIANS MEDICAL GROUP (EMP01)' },
  { value: 'HCDPB', label: 'HEALTH CARE DISTRICT PALM BEACH COUNTY (HCDPB)' },
  { value: '94321', label: 'CHILDRENS FIRST MEDICAL GROUP (94321)' },
  { value: 'HT005054001', label: 'STATE FARM INSURANCE PROPERTY AND CASUALTY (HT005054001)' },
  { value: '59064', label: 'COMMUNITY CARE PLAN (COMMERCIAL) (59064)' },
  { value: 'OMDBH', label: 'COLORADO HEALTH PARTNERSHIPS (OMDBH)' },
  { value: 'OMDBH', label: 'MARYLAND BEHAVIORAL HEALTH (OMDBH)' },
  { value: 'OMDBH', label: 'FOOTHILLS BEHAVIORAL HEALTH (OMDBH)' },
  { value: 'OMDBH', label: 'OPTUM MARYLAND BEHAVIOR HEALTH (OMDBH)' },
  { value: '11334', label: 'NEW YORK NETWORK MANAGEMENT (NYNM) (11334)' },
  { value: '51028', label: '21ST CENTURY INSURANCE AND FINANCIAL SERVICES (51028)' },
  { value: '95327', label: 'ALAMEDA ALLIANCE COMPLETE CARE (95327)' },
  { value: '95327', label: 'ALAMEDA ALLIANCE (95327)' },
  { value: '56731', label: 'HEALTHCARE RESOURCES NORTHWEST (56731)' },
  { value: '39182', label: 'INSURANCE TPA.COM ADMINISTRATORS (39182)' },
  { value: '64084', label: 'MISSISSIPPI PHYSICIANS CARE NETWORK (64084)' },
  { value: 'IP097', label: 'MERITAGE MEDICAL NETWORK (IP097)' },
  { value: 'IP097', label: 'MARIN SONOMA IPA (IP097)' },
  { value: 'IP097', label: 'MARIN IPA (IP097)' },
  { value: 'HMLIC', label: 'HORACE MANN (HMLIC)' },
  { value: '22603', label: 'NEW JERSEY CARPENTERS HEALTH FUND (22603)' },
  { value: '87068', label: 'P5 HEALTH PLAN SOLUTIONS OF UTAH (87068)' },
  { value: '75233', label: 'EMPLOYERS DIRECT HEALTH - SF (75233)' },
  { value: '38238', label: 'BENESYS (38238)' },
  { value: '38238', label: 'NORTHERN CA SHEET METAL WORKERS HEALTH CARE PLAN (38238)' },
  { value: '38238', label: 'BENESYS SHEET METAL WORKERS (38238)' },
  { value: '38238', label: 'SHEET METAL WORKERS LOCAL 104 (38238)' },
  { value: '16600', label: 'CHAUTAUQUA COUNTY HEALTHCARE PLAN - MAYVILLE, NY (16600)' },
  { value: '39126', label: 'COMMUNITY CARE ORGANIZATION (39126)' },
  { value: 'DMG01', label: 'DUPAGE MEDICAL GROUP (DMG01)' },
  { value: 'DMG01', label: 'NAPERVILLE HEALTHCARE (DMG01)' },
  { value: 'DMG01', label: 'ILLINOIS HEALTH PARTNERS (DMG01)' },
  { value: '91078', label: 'TRUSTEED PLANS SERVICE CORPORATION (91078)' },
  { value: '93092', label: 'US BENEFITS (93092)' },
  { value: 'TKFMC', label: 'FOUNDATION FOR MEDICAL CARE - TULARE AND KING CO. (TKFMC)' },
  { value: '93975', label: 'COLUMBIA PACIFIC CCO (93975)' },
  { value: '93975', label: 'CAREOREGON (93975)' },
  { value: '93975', label: 'HEALTH SHARE OF OREGON CCO (93975)' },
  { value: '93975', label: 'YAMHILL CCO (93975)' },
  { value: '93975', label: 'JACKSON CARE CONNECT (93975)' },
  { value: '37301', label: 'SUMMIT AMERICA INSURANCE SERVICES, INC. (37301)' },
  { value: '75138', label: 'FIRST CHOICE OF MIDWEST - PPO (75138)' },
  { value: '52563', label: 'MERIDIAN HEALTH PLAN OF MICHIGAN (52563)' },
  { value: '13383', label: 'ORTHONET - AETNA (13383)' },
  { value: '87065', label: 'PRODEGI CORPORATE BENEFIT SERVICES  (87065)' },
  { value: '87065', label: 'MBA OF UTAH CDO TECHNOLOGIES (87065)' },
  { value: '87065', label: 'MBA OF UTAH AND WYOMING  (87065)' },
  { value: '36334', label: 'MACNEAL HEALTH PROVIDERS (36334)' },
  { value: '2273', label: 'STONES RIVER IPA FORTN BLUE CROSS BLUE SHIELD ONLY (2273)' },
  { value: '77078', label: 'BLUE CROSS BLUE SHIELD ARIZONA ADVANTAGE (77078)' },
  { value: 'JJ', label: 'BCN - NON JVHL NETWORK - JVHL (JJ)' },
  { value: 'CLFR2', label: 'CL FRATES (CLFR2)' },
  { value: 'CLFR2', label: 'OKLAHOMA STATE MEDICAL ASSOCIATION (OSMA  HEALTH) (CLFR2)' },
  { value: '48330', label: 'HAWAII WESTERN MANAGEMENT GROUP (HWMG) (48330)' },
  { value: '48330', label: 'HMAA - HAWAI MEDICAL ASSURANCE ASSOCIATION (48330)' },
  { value: '48330', label: 'HAWAII MANAGEMENT ALLIANCE (48330)' },
  { value: '48330', label: 'HMAA - HAWAII MANAGEMENT ALLIANCE ASSOCIATION (48330)' },
  { value: '77038', label: 'SANTE HEALTH SYSTEM (77038)' },
  { value: '37316', label: 'LEON MEDICAL CENTER HEALTH PLAN (37316)' },
  { value: '11695', label: 'ICARE, INDEPENDENT CARE HEALTH (11695)' },
  { value: '36347', label: 'NORTHERN ILLINOIS HEALTH PLAN (36347)' },
  { value: '35186', label: 'DUNN AND ASSOCIATES (35186)' },
  { value: '34171', label: 'FRONTPATH HEALTH COALITION (34171)' },
  { value: '', label: "FLORIDA WORKERS' COMPENSATION ()" },
  { value: '', label: "MICHIGAN WORKERS' COMPENSATION ()" },
  { value: 'PHP00', label: 'PROVIDENCE PREFERRED (PHP00)' },
  { value: 'PHP00', label: 'PROVIDENCE PREFERRED SELECT (PHP00)' },
  { value: 'PHP00', label: 'PROVIDENCE PREFERRED VANTAGE SELECT (PHP00)' },
  { value: 'PHP00', label: 'PROVIDENCE PREFERRED PPO (PHP00)' },
  { value: 'PHP00', label: 'PROVIDENCE PREFERRED VANTAGE (PHP00)' },
  { value: 'PHP00', label: 'PROVIDENCE PPO  (PHP00)' },
  { value: 'PHP00', label: 'PUBLIC EMPLOYEE BENEFITS BOARD (PEBB) PPO (PHP00)' },
  { value: '52103', label: 'NCTA - PLANVISTA CORPORATION (52103)' },
  { value: '52103', label: 'NATIONAL TELECOMMUNICATIONS COOPERATIVE ASSOC. (52103)' },
  { value: '00060', label: 'CONNECTICUT BLUECARE FAMILY PLAN (60)' },
  { value: '84133', label: 'DENVER HEALTH AND HOSPITAL AUTHORITY (84133)' },
  { value: '84133', label: 'DENVER HEALTH MEDICAID CHOICE PLAN (84133)' },
  { value: '84133', label: 'DENVER HEALTH MEDICAL PLAN (84133)' },
  { value: '95348', label: 'OHIO CHOICECARE HMO (95348)' },
  { value: 'IP095', label: 'MONARCH IPA (IP095)' },
  { value: '36396', label: 'FAMILY MEDICAL NETWORK (36396)' },
  { value: '75228', label: 'TEXAS CHILDRENS HEALTH PLAN (MEDICAID) (75228)' },
  { value: '86304', label: 'INSURERS ADMINISTRATIVE CORPORATION (86304)' },
  { value: 'DHS01', label: 'P5 HEALTH PLAN SOLUTIONS OF UTAH (DHS01)' },
  { value: 'DHS01', label: 'DELTA HEALTH SYSTEMS (DHS01)' },
  { value: '48123', label: 'VALIR PACE (48123)' },
  { value: 'FABOH', label: 'FOND DU LAC AREA BUSINESS ON HEALTH - FABOH (FABOH)' },
  { value: 'ALLIANCE', label: 'ALLIANCE (THE) - WI AND SURROUNDING STATES (ALLIANCE)' },
  { value: '363353243', label: 'HFN, INC. (363353243)' },
  { value: '363353243', label: 'PPOPLUS, LLC  (363353243)' },
  { value: '7689', label: 'GLOBAL CARE (7689)' },
  { value: '7689', label: 'CYPRESS BENEFIT ADMINISTRATORS (7689)' },
  { value: 'HSPC1', label: 'HEALTH SMART PREFERRED CARE (HSPC1)' },
  { value: 'HSPC1', label: 'NORTH TEXAS HEALTHCARE (HSPC1)' },
  { value: '9830', label: 'UNIVERSITY FAMILY CARE - HEALTHCARE GROUP (9830)' },
  { value: '9830', label: 'UNIVERSITY FAMILY CARE/PHYSICIAN CARE ADVANTAGE (9830)' },
  { value: '82048', label: 'PRIMARY HEALTH NETWORK (82048)' },
  { value: 'IP059', label: 'CALIFORNIA CARE (HUMBOLT DEL NORTE) (IP059)' },
  { value: 'J9', label: 'BLUE CARE COMPLETE - JVHL (J9)' },
  { value: 'J9', label: 'BCN - JVHL (J9)' },
  { value: '37282', label: 'SELECT BENEFIT ADMINISTRATORS OF AMERICA (37282)' },
  { value: '41154', label: 'MAYO MANAGEMENT SOUTH COUNTRY (41154)' },
  { value: '41154', label: 'HEALTH TRADITION (41154)' },
  { value: '41154', label: 'SOUTH COUNTRY HEALTH ALLIANCE (SCHA) (41154)' },
  { value: '41154', label: 'MAYO MANAGEMENT SERVCES, INC (MMSI) (41154)' },
  { value: 'J8', label: 'AETNA BETTER HEATLH OF MI - JVHL (J8)' },
  { value: 'J1', label: 'AETNA - US HEALTHCARE - J1 - JVHL (J1)' },
  { value: 'JB', label: 'HAP EMPOWERED (MIDWEST) - JVHL (JB)' },
  { value: 'JG', label: 'HEALTH ALLIANCE PLAN (CAPITATED CONTRACTS) - JVHL (JG)' },
  { value: 'TRINPACE', label: 'MERCY LIFE NORRISTOWN (TRINPACE)' },
  { value: 'TRINPACE', label: 'LIFE ST. JOSEPH OF THE PINES (TRINPACE)' },
  { value: 'TRINPACE', label: 'MERCY LIFE - WEST PHILADELPHIA (TRINPACE)' },
  { value: 'TRINPACE', label: 'ST. JOSEPH PACE OF INDIANA (TRINPACE)' },
  { value: 'TRINPACE', label: 'MERCY LIFE VALLEY VIEW (TRINPACE)' },
  { value: 'TRINPACE', label: 'MERCY LIFE (TRINPACE)' },
  { value: 'TRINPACE', label: 'ST. FRANCIS LIFE (TRINPACE)' },
  { value: 'TRINPACE', label: 'LIFE ST. FRANCIS (TRINPACE)' },
  { value: 'TRINPACE', label: 'LIFE ST. MARY (TRINPACE)' },
  { value: 'TRINPACE', label: 'TRINITY HEALTH PACE (TRINPACE)' },
  { value: 'TRINPACE', label: 'MERCY LIFE OF ALABAMA (TRINPACE)' },
  { value: 'TRINPACE', label: 'MERCY LIFE NORTH HANCOCK (TRINPACE)' },
  { value: 'TRINPACE', label: 'MERCY LIFE SHARON HILL (TRINPACE)' },
  { value: '84109', label: 'PINNACOL ASSURANCE (84109)' },
  { value: '65093', label: 'ADVOCATE CHRIST HOSPITAL PARTNERS (65093)' },
  { value: '65093', label: 'ADVOCATE HEALTH PARTNERS (65093)' },
  { value: '65093', label: 'ADVOCATE BETHANY HLTH PARTNERS (65093)' },
  { value: '65093', label: 'ADVOCATE TRINITY HLTH PARTNERS (65093)' },
  { value: '65093', label: 'ADVOCATE SOUTH SUBURBAN PARTNERS (65093)' },
  { value: '65093', label: 'ADVOCATE GOOD SAMARITAN PARTNERS (65093)' },
  { value: '65093', label: 'SILVER CROSS HEALTH CONNECTION (65093)' },
  { value: '65093', label: 'ADVOCATE GOOD SHEPHERD PARTNERS (65093)' },
  { value: '65093', label: 'ADVOCATE LUTHERAN GENERAL PARTNERS (65093)' },
  { value: '7205', label: 'HEALTHPLUS OF LOUISIANA (7205)' },
  { value: '7205', label: 'GILSBAR INC. (7205)' },
  { value: '86047', label: 'UNITEDHEALTHCARE COMMUNITY PLAN OF NJ MEDICAID (86047)' },
  { value: '86047', label: 'HIP-NY STATE (86047)' },
  { value: '86047', label: 'GHI (GROUP HEALTH INC.) (86047)' },
  { value: '86047', label: 'AMERICHOICE OF NJ (MEDICAID NJ) (86047)' },
  { value: '68011', label: 'CAPITOL ADMINISTRATORS (68011)' },
  { value: '4320', label: 'PREFERRED HEALTHCARE SYSTEM - PPO - ALTOONA PA (4320)' },
  { value: '4320', label: 'INNOVATED HEALTHWARE SERVICES (4320)' },
  { value: '86048', label: 'UNITEDHEALTHCARE COMMUNITY PLAN OF NY MEDICAID (86048)' },
  { value: '37121', label: 'MASHANTUCKET PEQUOT TRIBAL NAT. (37121)' },
  { value: '35206', label: 'HEALTHCARE GROUP (THE) (35206)' },
  { value: '35206', label: 'ENCIRCLE PPO (35206)' },
  { value: '35206', label: 'ENCORE HEALTH NETWORK (35206)' },
  { value: '86049', label: 'UNITEDHEALTHCARE COMMUNITY PLAN OF PA MEDICAID (86049)' },
  { value: '43160', label: 'CORRECTIONAL MEDICAL SERVICES, INC. (43160)' },
  { value: 'ARISE ', label: 'ARISE HEALTH PLAN (ARISE )' },
  { value: '14179', label: 'CARECORE AETNA RADIOLOGY (14179)' },
  { value: '37216', label: 'EMPLOYEE BENEFIT SERVICES - FT MILL, SC (37216)' },
  { value: '36345', label: 'PHYSICIANS CARE NETWORK (ROCKFORD, IL ONLY) (36345)' },
  { value: '59331', label: 'ASCENSION BENEFITS (STUART, FL) (59331)' },
  { value: '46', label: 'HILL PHYSICIANS MEDICAL GROUP (46)' },
  { value: '23708', label: 'C AND O EMPLOYEES HOSPITAL ASSN. (23708)' },
  { value: '37114', label: 'H.E.R.E.I.U. WELFARE PENSION PLAN (37114)' },
  { value: '13315', label: 'INSURANCE DESIGN ADMINISTRATORS (13315)' },
  { value: '59230', label: 'HERITAGE CONSULTANTS (59230)' },
  { value: '39081', label: 'BENEFIT PLAN ADMINISTRATORS CO. (EUA CLAIRE, WI) (39081)' },
  { value: '39081', label: 'CUSTOM BENEFIT ADMINISTRATORS (39081)' },
  { value: '34158', label: 'CONTIGO HEALTH (34158)' },
  { value: '37242', label: 'PROFESSIONAL CLAIMS MANAGEMENT (CANTON, OH) (37242)' },
  { value: '37242', label: 'AULTRA ADMINISTRATIVE GROUP (CANTON, OH) (37242)' },
  { value: 'CHCN1', label: 'COMMUNITY HEALTH CENTER NETWORK (CHCN1)' },
  { value: '76066', label: 'RENAISSANCE PHYSICIANS ORGANIZATION (76066)' },
  { value: '53476', label: 'PREFERRED BENEFITS ADMINISTRATION (53476)' },
  { value: 'NPN11', label: 'NORTHWEST PHYSICIANS NETWORK DOS BEFORE 1/1/2021 (NPN11)' },
  { value: '19', label: 'CIGNA ENCOUNTERS (19)' },
  { value: 'WELM2', label: 'WELLMED (WELM2)' },
  { value: '77080', label: 'WPP ELDERCARE (77080)' },
  { value: '31074', label: 'EMPLOYEE BENEFIT MANAGEMENT CORPORATION (EBMC) (31074)' },
  { value: '31074', label: 'BIG LOTS ASSOCIATES BENEFIT PLAN (31074)' },
  { value: '59227', label: 'MEGA LIFE AND HEALTH INSURANCE COMPANY - OKC (59227)' },
  { value: '990263440', label: 'UNIVERSITY HEALTH ALLIANCE - HAWAII (990263440)' },
  { value: '59354', label: 'SELECTCARE OF TEXAS (KELSEY-SEYBOLD) (59354)' },
  { value: '59354', label: 'WELLCARE HEALTH PLAN ENCOUNTERS (59354)' },
  { value: '59354', label: 'NORTHWEST DIAGNOSTIC (59354)' },
  { value: '59354', label: 'MISSOURI CARE ENCOUNTERS  DOS>04/30/13 (59354)' },
  { value: '59354', label: 'GOLDEN TRIANGLE / GTPA (59354)' },
  { value: '59354', label: 'KATY MEDICAL GROUP (59354)' },
  { value: '59354', label: 'TEXAS FIRST HEALTH PLAN (59354)' },
  { value: '59354', label: 'CENTRAL SENIOR CARE (59354)' },
  { value: '59354', label: 'CMS TITLE 19 REFORM (59354)' },
  { value: '59354', label: 'WELLCARE LIBERTY (MEDICAID ADVANTAGE PLAN) (59354)' },
  { value: '59354', label: 'WELLCARE PFFS (59354)' },
  { value: '59354', label: 'SELECTCARE OF TX (HPN) HERITAGE PHYSICIAN NETWORK (59354)' },
  { value: '59354', label: 'SELECTCARE OF TEXAS (MEMORIAL CLINICAL ASSOCIATES) (59354)' },
  { value: '59354', label: 'CMS TITLE 21 (59354)' },
  { value: '59354', label: 'TODAYS OPTIONS (59354)' },
  { value: '59354', label: 'SELECTCARE OF TEXAS (BEAUMONT) (59354)' },
  { value: '59354', label: 'SELECTCARE OF TX (HOUSTON) (59354)' },
  { value: '59354', label: 'WELLCARE OF SC (59354)' },
  { value: 'J2', label: 'MERIDIAN HEATLH PLAN OF MI - JVHL (J2)' },
  { value: 'J5', label: 'UNITED HEALTHCARE (NON-GOLDEN RULE) - JVHL (J5)' },
  { value: 'JR', label: 'UNITED HEALTHCARE COMMUNITY - JVHL (JR)' },
  { value: 'PFIPA', label: 'PREFERRED IPA (PFIPA)' },
  { value: '23550', label: 'MIDLAND MANAGEMENT / MFC (23550)' },
  { value: 'MBA01', label: 'MEDICAL BENEFIT ADMINISTRATORS (MBA01)' },
  { value: 'MBA01', label: 'SIERRA NEVADA MEDICAL ASSOCIATES (MBA01)' },
  { value: '37279', label: 'INSURANCE ADMINISTRATORS OF AMERICA (IAA) (37279)' },
  { value: '59266', label: 'VOLUSIA HEALTH NETWORK (59266)' },
  { value: '22175', label: 'I E SHAFFER (22175)' },
  { value: '75235', label: 'EMPLOYERS DIRECT HEALTH FI (75235)' },
  { value: '37230', label: 'TR PAUL INC. (37230)' },
  { value: '20333', label: 'WORKSITE BENEFIT SERVICES (20333)' },
  { value: '37266', label: 'CROY-HALL MANAGEMENT INC. (37266)' },
  { value: '37266', label: 'SOUTHWEST SERVICE LIFE (37266)' },
  { value: '75600', label: 'CITY OF ODESSA (THE) (75600)' },
  { value: '55489', label: 'PATIENT ADVOCATES (55489)' },
  { value: '37284', label: 'TRANSCHOICE KEY BENEFIT ADMINISTRATORS (37284)' },
  { value: '42150', label: 'BUTLER BENEFIT (42150)' },
  { value: '33033', label: 'FCE BENEFIT ADMINISTRATORS (33033)' },
  { value: '43178', label: 'BENEFIT MANAGEMENT, INC.  (43178)' },
  { value: '43178', label: 'JOPLIN CLAIMS (43178)' },
  { value: '23249', label: 'SENTINEL MANAGEMENT SERVICES (23249)' },
  { value: '37278', label: 'RESOURCEONE ADMINISTRATORS (37278)' },
  { value: '37269', label: 'INTERNATIONAL UNION OF OPERATING ENGINEERS LOC 15 (37269)' },
  { value: '47076', label: 'REGIONAL CARE, INC. (47076)' },
  { value: '82018', label: 'HEALTH CLAIM SERVICES (82018)' },
  { value: '74212', label: 'FRINGE BENEFIT GROUP - HOUSTON (74212)' },
  { value: '95604', label: 'BEST LIFE AND HEALTH INSURANCE COMPANY (95604)' },
  { value: '59231', label: 'MEDCOM (59231)' },
  { value: '37322', label: 'AMERICAN WORKER HEALTH PLAN (37322)' },
  { value: '10010', label: 'CAREWORKS (10010)' },
  { value: 'GSMPA', label: 'GOOD SAMARITAN MEDICAL PRACTICE ASSOC. (GSMPA) (GSMPA)' },
  { value: 'UAGBT', label: 'UNITED AGRICULTURE BENEFIT TRUST (UAGBT)' },
  { value: 'SPOHN', label: 'CHRISTUS SPOHN (SPOHN)' },
  { value: 'PPM01', label: 'MISSION MEDICAL GROUP (PPM01)' },
  { value: 'PPM01', label: 'DESERT FAMILY PRACTICE ASSOCIATION (PPM01)' },
  { value: 'PPM01', label: 'JFK HOSPITAL (PPM01)' },
  { value: 'PPM01', label: 'PRIMARY PROVIDER MANAGEMENT (PPM01)' },
  { value: 'PPM01', label: 'FAMILY CHOICE MEDICAL (PPM01)' },
  { value: 'PPM01', label: 'DESERT HOSPITAL (PPM01)' },
  { value: 'PPM01', label: 'VANTAGE MEDICAL GROUP (PPM01)' },
  { value: 'DESRT', label: 'OASIS IPA (DESRT)' },
  { value: 'DESRT', label: 'DESERT MEDICAL GROUP (DESRT)' },
  { value: 'PROSP', label: 'UPLAND MEDICAL GROUP (PROSP)' },
  { value: 'PROSP', label: 'PROSPECT MEDICAL SYSTEMS (PROSP)' },
  { value: 'PROSP', label: 'GATEWAY IPA (PINNACLE HEALTH RESOURCES) (PROSP)' },
  { value: 'PROSP', label: 'AMVI / PROSPECT HEALTH NETWORK (PROSP)' },
  { value: 'PROSP', label: 'GENESIS HEALTHCARE (EMG) (PROSP)' },
  { value: 'PROSP', label: 'PROCARE (PROSPECT) (PROSP)' },
  { value: 'PROSP', label: 'PROSPECT HEALTH NETWORK (PROSP)' },
  { value: 'PROSP', label: 'APAC (PINNACLE HEALTH RESOURCES) (PROSP)' },
  { value: 'PROSP', label: 'PROSPECT MEDICAL GROUP (PROSP)' },
  { value: 'PROSP', label: 'PINNACLE HEALTH RESOURCES (PROSP)' },
  { value: 'PROSP', label: 'NORTH WEST ORANGE COUNTY MEDICAL GROUP (PROSP)' },
  { value: 'PROSP', label: 'PROSPECT PROVIDER GROUP OF RHODE ISLAND (PROSP)' },
  { value: 'PROSP', label: 'MAVERICK MEDICAL GROUP (PROSP)' },
  { value: 'PROSP', label: 'NUESTRA FAMILIA MEDICAL GROUP (PROSP)' },
  { value: 'PROSP', label: 'PROSPECT HEALTH SERVICES OF TEXAS (PROSP)' },
  { value: 'PROSP', label: 'PROSPECT SHERMAN OAKS MEDICAL GROUP (PROSP)' },
  { value: 'PROSP', label: 'PACIFIC HEALTHCARE IPA (PROSP)' },
  { value: 'PROSP', label: 'AMVI MEDICAL GROUP (PROSP)' },
  { value: 'PROSP', label: 'CLINICA MEDICA SAN MIGUEL (PROSP)' },
  { value: 'PROSP', label: 'GATEWAY IPA (PROSP)' },
  { value: 'PROSP', label: 'POMONA VALLEY MEDICAL GROUP (PVMG) (PROSP)' },
  { value: 'HDMDG', label: 'HIGH DESERT MEDICAL GROUP (HDMDG)' },
  { value: 'REGAL', label: 'HERITAGE PROVIDER NETWORK (REGAL)' },
  { value: 'REGAL', label: 'REGAL (REGAL)' },
  { value: 'REGAL', label: 'GLENDALE PHYSICIANS ALLIANCE (REGAL)' },
  { value: 'REGAL', label: 'CADUCEUS MEDICAL GROUP (REGAL) (REGAL)' },
  { value: 'REGAL', label: 'REGAL MEDICAL GROUP (REGAL)' },
  { value: 'LNDMK', label: 'LANDMARK INSURANCE (LNDMK)' },
  { value: '95166', label: 'CEDARS SINAI MEDICAL NETWORK (95166)' },
  { value: '93044', label: 'A AND I BENEFIT PLAN ADMINISTRATORS (93044)' },
  { value: 'Block Vision', label: 'VERSANT HEALTH (Block Vision)' },
  { value: 'STJOE', label: 'ST JOSEPH HOSPITAL AFFILIATED (STJOE)' },
  { value: 'STJOE', label: 'HDPC PREMIER HEALTHCARE (STJOE)' },
  { value: 'STJOE', label: 'ST JUDE HERITAGE MEDICAL GROUP (STJOE)' },
  { value: 'STJOE', label: 'ST  MARY HIGH DESERT (STJOE)' },
  { value: 'STJOE', label: 'HDPC - PREMIER CARE (HIGH DESERT PRIMARY CARE PREM (STJOE)' },
  { value: 'STJOE', label: 'HIGH DESERT PRIMARY CARE\t (STJOE)' },
  { value: 'STJOE', label: 'MISSION HOSPITAL AFFILIATED PHYSICIANS (STJOE)' },
  { value: 'STJOE', label: 'ST. JOSEPH HERITAGE (STJOE)' },
  { value: 'STJOE', label: 'INTERVALLEY (STJOE)' },
  { value: 'STJOE', label: 'ST JUDE AFFILIATED PHYSICIANS (STJOE)' },
  { value: 'STJOE', label: 'MISSION HERITAGE MEDICAL GROUP (STJOE)' },
  { value: 'STJOE', label: 'ST JOSEPH HERITAGE MEDICAL GROUP (STJOE)' },
  { value: 'STJOE', label: 'HOAG AFFILIATED PHYSICIANS (STJOE)' },
  { value: '590', label: 'FLORIDA BLUE SHIELD HEALTH OPTIONS HMO (590)' },
  { value: '60', label: 'CONNECTICUT FEDERAL EMP HLTH BENEFITS (60)' },
  { value: '60', label: 'CONNECTICUT MEDICARE BLUE(MEDICARE RISK) (60)' },
  { value: 'AHS01', label: 'ACCESS ADMINISTRATORS (AHS01)' },
  { value: 'EXC01', label: 'PHYSICIANS MEDICAL GROUP OF SAN JOSE (EXC01)' },
  { value: '16146', label: 'TOTAL CARE (16146)' },
  { value: '16146', label: 'MOLINA HEALTHCARE OF NEW YORK (16146)' },
  { value: '1256', label: 'POPULYTICS (1256)' },
  { value: '1256', label: 'SPECTRUM ADMINISTRATORS (1256)' },
  { value: '14315', label: 'COMMONWEALTH CARE ALLIANCE (14315)' },
  { value: 'AZ FOUNDATION', label: 'ARIZONA FOUNDATION FOR MEDICAL CARE (AZ FOUNDATION)' },
  { value: 'AZ FOUNDATION', label: 'ANCILLARY BENEFITS SYSTEM (AZ FOUNDATION)' },
  { value: '37275', label: 'MISSOULA COUNTY MEDICAL BENEFITS PLAN (37275)' },
  { value: '84137', label: 'INTERWEST HEALTH - ACCEPTIUS GATEWAY PAYOR (84137)' },
  { value: 'RENGQ', label: 'GULF QUEST RENAISSANCE (RENGQ)' },
  { value: '93050', label: 'WILLIAM C. EARHART (93050)' },
  { value: '24818', label: 'AMIDA CARE (24818)' },
  { value: '2057', label: 'SMITH ADMINISTRATORS (2057)' },
  { value: '95167', label: 'CEDARS SINAI MEDICAL ENCOUNTER (95167)' },
  { value: '94302', label: 'CHINESE COMMUNITY HEALTH PLAN (94302)' },
  { value: 'MHHNP', label: 'MEMORIAL HERMANN (MHHNP)' },
  { value: 'MHHNP', label: 'MEMORIAL HERMANN HEALTH INSURANCE COMPANY (MHHNP)' },
  { value: 'MHHNP', label: 'MEMORIAL HERMANN HEALTH SOLUTIONS (MHHNP)' },
  { value: 'MMPHB', label: 'MASTER, MATES, AND PILOTS (MMPHB)' },
  { value: '36434', label: 'EARLY INTERVENTION CENTRAL BILLING (36434)' },
  { value: '36320', label: 'ADVOCATE HEALTH CENTERS (36320)' },
  { value: '38241', label: 'EMPLOYEE BENEFIT CONCEPTS (FARMINGTON HILLS, MI) (38241)' },
  { value: '58200', label: 'INTEGRITY BENEFIT GROUP, INC. (THE) (58200)' },
  { value: '88027', label: 'NORTHERN NEVADA TRUST FUND (88027)' },
  { value: '36346', label: 'NORTHWEST SUBURBAN IPA (ILLINOIS) (36346)' },
  { value: '03036', label: 'BLUE BENEFIT ADMINISTRATORS OF MA (3036)' },
  { value: '03036', label: 'CBA BLUE (3036)' },
  { value: '03036', label: 'COMPREHENSIVE BENFITS ADMINISTRATORS, INC. (3036)' },
  { value: '03036', label: 'EMPLOYEE BENEFITS PLAN ADMIN, INC. (E.B.P.A.) (3036)' },
  { value: '31441', label: 'PIEDMONT COMMUNITY HEALTH PLAN (31441)' },
  { value: '31441', label: 'SOUTHERN PIEDMONT COMMUNITY CARE (31441)' },
  { value: '31441', label: 'NORTHERN PIEDMONT COMMUNITY CARE (31441)' },
  { value: '31441', label: 'S&S HEALTHCARE STRATEGIES (31441)' },
  { value: '37135', label: 'CONSOCIATE GROUP (37135)' },
  { value: '37105', label: 'SAGE TECHNOLOGIES (37105)' },
  { value: '37105', label: 'PROGRESS HEALTH HMO (37105)' },
  { value: '95321', label: 'MEDFOCUS (95321)' },
  { value: '37136', label: 'PHYSICIANS HEALTH ASSOCIATES / MIDLAND MGMT (37136)' },
  { value: '34136', label: 'JP FARLEY CORPORATION (34136)' },
  { value: '48145', label: 'COMMUNITY HEALTH CHOICE (48145)' },
  { value: '28530', label: 'SECURE HEALTH PLANS OF GEORGIA, LLC (28530)' },
  { value: '41161', label: 'OPTUM PHYSICAL HEALTH (41161)' },
  { value: '41161', label: 'AMERICAN CHIROPRACTIC NETWORK IPA OF NY (ACNIPA) (41161)' },
  { value: '64088', label: 'MISSISSIPPI SELECT HEALTHCARE (64088)' },
  { value: '64088', label: 'SELECT ADMINISTRATIVE SERVICES (SAS) (64088)' },
  { value: '38338', label: 'MCLAREN ABW - MEDICAL ACCESS PROGRAM (38338)' },
  { value: '38338', label: 'MCLAREN ABW - NORTHERN HEALTH PLAN (38338)' },
  { value: '38338', label: 'MCLAREN ABW - NORTHERN HEALTH PLAN NE (38338)' },
  { value: '38338', label: 'WASHTENAW HEALTH PLAN ENCOUNTERS - MI (MEDICAID) (38338)' },
  { value: '38338', label: 'MCLAREN BCCCP (38338)' },
  { value: '38338', label: 'MCLAREN ABW - MID-MICHIGAN HEALTH PLAN (38338)' },
  { value: '38338', label: 'MCLAREN ABW -  WASHTENAW COUNTY PLAN (38338)' },
  { value: '38338', label: 'MCLAREN ABW - MONROE COUNTY HEALTH PLAN (38338)' },
  { value: '38338', label: 'MCLAREN ABW - STUDENT HEALTH SUBSIDY PROGRAM (38338)' },
  { value: '38338', label: 'MCLAREN MEDICAID (38338)' },
  { value: '38338', label: 'HEALTH ADVANTAGE BCCCP (38338)' },
  { value: '38338', label: 'ABW - BERRIEN COUNTY  (38338)' },
  { value: '38338', label: 'MCLAREN HEALTH PLANS (38338)' },
  { value: '38338', label: 'MCLAREN ABW  - BRANCH, HILLSDALE, ST. JOSEPH HEALT (38338)' },
  { value: '38338', label: 'MCLAREN HEALTH ADVANTAGE (38338)' },
  { value: '38338', label: 'MCLAREN ABW - INGHAM HEALTH PLAN (38338)' },
  { value: '38338', label: 'MCLAREN ABW - KENT HEALTH PLAN (38338)' },
  { value: '38338', label: 'MCLAREN ABW - BE HEALTHY PROGRAM  (38338)' },
  { value: '38338', label: 'MCLAREN ABW - CALHOUN HEALTH PLAN (38338)' },
  { value: '38338', label: 'MCLAREN ABW - IONIA HEALTH PLAN (38338)' },
  { value: '38338', label: 'MCLAREN ABW - JACKSON HEALTH PLAN (38338)' },
  { value: '38338', label: 'MCLAREN ABW - KALAMAZOO COUNTY HEALTH PLAN (38338)' },
  { value: '38338', label: 'MCLAREN ABW - LIVINGSTON HEALTH PLAN (38338)' },
  { value: '38338', label: 'MCLAREN ABW - TENCON HEALTH PLAN CORP (38338)' },
  { value: '38338', label: 'MCLAREN ABW - COALITION HEALTH ACCESS PROGRAM (38338)' },
  { value: '77039', label: 'KERN FAMILY HEALTH CARE (77039)' },
  { value: '77039', label: 'KERN HEALTH SYSTEMS (77039)' },
  { value: '65085', label: 'NORTH AMERICAN ADMINISTRATORS, LP (NAA) (NASHVILLE (65085)' },
  { value: '34196', label: 'APEX BENEFIT SERVICES (34196)' },
  { value: '35193', label: 'COMMUNITY HEALTH ALLIANCE (35193)' },
  { value: 'HEALTHEOS', label: 'HEALTHEOS WISCONSIN PREFERRED PROVIDER NETWORKS (HEALTHEOS)' },
  { value: '37247', label: 'WESTERN MUTUAL INSURANCE (37247)' },
  { value: '58102', label: 'SELF INSURED BENEFITS ADMINISTRATORS (CLEARWATER, (58102)' },
  { value: '58102', label: 'COVENANT ADMINISTATORS, INC. (ATLANTA, GA) (58102)' },
  { value: '36401', label: 'PREFERRED NETWORK ACCESS, INC. (36401)' },
  { value: '33001', label: 'UNIVERSAL CARE - CALIFORNIA (33001)' },
  { value: '35198', label: 'UNIFIED GROUP SERVICES (35198)' },
  { value: '37299', label: 'NYHART (37299)' },
  { value: '41136', label: 'LIFE TRAC (41136)' },
  { value: '12X42', label: 'BANNER HEALTH SELECT 500 PPO (12X42)' },
  { value: '12X42', label: 'BANNER HEALTH ARIZONA (12X42)' },
  { value: '12X42', label: 'BANNER HEALTH CHOICE PLUS HMO (12X42)' },
  { value: '75280', label: 'SHASTA ADMINISTRATIVE SERVICES (75280)' },
  { value: '75280', label: 'NESIKA HEALTH GROUP (75280)' },
  { value: '38201', label: 'TOTAL HEALTH CARE OF MI (38201)' },
  { value: '38201', label: 'TOTAL HEALTHCARE, INC. (38201)' },
  { value: '37300', label: 'FACS GROUP (37300)' },
  { value: '37264', label: 'MERCY BENEFIT ADMINISTRATORS (37264)' },
  { value: '37264', label: 'ST. JOHNS CLAIMS ADMINISTRATION (37264)' },
  { value: '58231', label: 'CORE ADMINISTRATIVE SERVICES (58231)' },
  { value: '31129', label: 'INTEGRA GROUP - CHA (31129)' },
  { value: '62030', label: 'AMERICAN GENERAL LIFE AND ACCIDENT (62030)' },
  { value: '91176', label: 'RBMS, LLC (91176)' },
  { value: '25145', label: 'BENEFIT COORDINATORS CORPORATION (PITTSBURGH, PA) (25145)' },
  { value: '37233', label: 'MISSISSIPPI PUBLIC ENTITY EMPLOYEE BENEFIT TRUST (37233)' },
  { value: '37233', label: 'MPEEBT (37233)' },
  { value: '37233', label: 'MPE SERVICES INC. (37233)' },
  { value: '75191', label: 'NCAS - CHARLOTTE (75191)' },
  { value: '37280', label: 'AUTOMATED GROUP ADMINISTRATION (37280)' },
  { value: '37280', label: 'AGA (37280)' },
  { value: '76343', label: 'SIERRA HEALTH SERVICES, INC. (ENCOUNTERS) (76343)' },
  { value: '37287', label: 'PAI (37287)' },
  { value: '37287', label: 'PLANNED ADMINISTRATORS, INC. (37287)' },
  { value: '84076', label: 'SINCLAIR HEALTH SERVICES (84076)' },
  { value: '23266', label: 'PRIMESOURCE HEALTH NETWORK PPO - HARRISBURG, PA (23266)' },
  { value: '23266', label: 'SOUTH CENTRAL PREFERRED (23266)' },
  { value: '61102', label: 'HUMANA ENCOUNTERS (61102)' },
  { value: '36373', label: 'PRAIRIE STATES ENTERPRISES, INC. (36373)' },
  { value: '14182', label: 'CARECORE  NATIONAL (14182)' },
  { value: '1066', label: 'ACCLAIM REPRICING (1066)' },
  { value: '1066', label: 'AMERICAN HEALTHCARE ALLIANCE (1066)' },
  { value: '40585', label: 'INDECS CORPORATION (40585)' },
  { value: 'HEALTHEOS', label: 'HEALTHEOS HEALTHCARE NETWORK OF WISCONSIN (HCN) (HEALTHEOS)' },
  { value: 'MRCHP', label: 'ALLCARE ADVANTAGE (MRCHP)' },
  { value: '91164', label: 'HMSO - HIGHLINE MEDICAL SERVICE ORGANIZATION (91164)' },
  { value: 'BOLL1', label: 'BOLLINGER INC. (BOLL1)' },
  { value: 'INET1', label: 'INTEGRANET HEALTH (INET1)' },
  { value: 'MMLI3', label: 'MONUMENTAL LIFE INSURANCE COMPANY (HURST, TX) (MMLI3)' },
  { value: 'PRI01', label: 'PRINCETON PREMIER IPA (PRI01)' },
  { value: 'STM01', label: 'ST THOMAS MEDICAL NETWORK (GULFQUEST) (STM01)' },
  { value: '36389', label: 'RUSH PRUDENTIAL HEALTH PLANS (HMO ONLY) (36389)' },
  { value: 'IP083', label: 'KEY MEDICAL GROUP - MEDICARE ADVANTAGE (IP083)' },
  { value: 'IP116', label: 'DOWNEY SELECT IPA (APPLECARE MEDICAL MANAGEMENT) (IP116)' },
  { value: 'IP116', label: 'APPLECARE MEDICAL MANAGEMENT (IP116)' },
  { value: 'IP116', label: 'ST FRANCIS IPA (IP116)' },
  { value: 'NMM01', label: 'GREATER ORANGE MEDICAL GROUP (NMM01)' },
  { value: 'NMM01', label: 'ARCADIA METHODIST IPA (NMM01)' },
  { value: 'NMM01', label: 'ALLIED PHYSICIANS MEDICAL GROUP (NMM01)' },
  { value: 'NMM01', label: 'UNITED PHYSICIAN INTERNATIONAL (NMM01)' },
  { value: 'NMM01', label: 'PHYSICIANS HEALTHWAYS IPA - DOS >3/31/15 (NMM01)' },
  { value: 'NMM01', label: 'ADVANTAGE MEDICAL GROUP (NMM01)' },
  { value: 'NMM01', label: 'PHYSICIANS HEALTHWAYS IPA DOS > 3/31/15 (NMM01)' },
  { value: 'NMM01', label: 'DIAMOND BAR MEDICAL GROUP (NMM01)' },
  { value: 'NMM01', label: 'GREATER ORANGE COUNTY MEDICAL GROUP IPA (NMM01)' },
  { value: 'NMM01', label: 'ARROYO VISTA FAMILY HEALTH CENTER (NMM01)' },
  { value: 'NMM01', label: 'GREATER SAN GABRIEL MEDICAL GROUP (NMM01)' },
  { value: 'NMM01', label: 'NETWORK MEDICAL MANAGEMENT (NMM01)' },
  { value: 'NMM01', label: 'GREATER ORANGE COUNTY MEDICAL GROUP IPA (NMM01)' },
  { value: 'PDT01', label: 'HOLLYWOOD PRESBYTERIAN (PDT01)' },
  { value: 'PDT01', label: 'TRICITIES IPA (PDT01)' },
  { value: 'PDT01', label: 'XIMED IPA (PDT01)' },
  { value: 'PDT01', label: "PHYSICIAN'S DATA TRUST (PDT01)" },
  { value: 'PDT01', label: 'NOBLE AMA SELECT IPA (PDT01)' },
  { value: 'PDT01', label: 'GREATER TRI-CITIES IPA (PDT01)' },
  { value: 'PDT01', label: 'EMPIRE HEALTHCARE IPA (PDT01)' },
  { value: 'PDT01', label: 'GOLDEN PHYSICIANS MEDICAL GROUP (PDT01)' },
  { value: 'PDT01', label: 'CITRUS VALLEY PHYSICIANS GROUP IPA (PDT01)' },
  { value: 'PDT01', label: 'ST VINCENT IPA (PDT01)' },
  { value: 'PDT01', label: 'EQUALITY HEALTHCARE (PDT01)' },
  { value: '81949', label: 'AMERICAN INSURANCE COMPANY OF TEXAS (81949)' },
  { value: 'IP082', label: 'KEY MEDICAL GROUP (IP082)' },
  { value: 'IP115', label: 'EMPIRE MEDICAL GROUP (IP115)' },
  { value: 'PCFCI', label: 'PACIFIC IPA (PCFCI)' },
  { value: 'CX025', label: 'BIG LOTS ASSOCIATES BENEFITS PLANS (CX025)' },
  { value: 'DAKCH', label: 'CHIROPRACTIC ASSOCIATION OF SOUTH DAKOTA (DAKCH)' },
  { value: 'THIPA', label: 'TORRANCE HOSPITAL IPA (THIPA)' },
  { value: '20133', label: 'OPTIMUM HEALTHCARE SERVICES (20133)' },
  { value: 'BKRFM', label: 'BAKERSFIELD FAMILY MEDICAL CENTER (BKRFM)' },
  { value: 'MHM01', label: 'INDEPENDENCE MEDICAL GROUP (MHM01)' },
  { value: '95216', label: 'COMMUNITY CARE BHO (95216)' },
  { value: '95216', label: 'COMMUNITY CARE BEHAVIORAL HEALTH ORGANIZATION (95216)' },
  { value: 'POD1ST', label: 'PODIATRY FIRST (POD1ST)' },
  { value: 'PRESA', label: 'PRESBYTERIAN SALUD (PRESA)' },
  { value: 'PRESA', label: 'PRESBYTERIAN HEALTH PLAN (PRESA)' },
  { value: '22321', label: 'ONE CALL MEDICAL (22321)' },
  { value: 'SVIPA', label: 'OJAI VALLEY COMMUNITY MEDICAL GROUP (SVIPA)' },
  { value: 'SVIPA', label: 'SEAVIEW IPA (SVIPA)' },
  { value: 'JZ', label: 'PRIORITY HEALTH - JVHL (JZ)' },
  { value: 'JW', label: 'COMMUNITY CARE ASSOCIATES (HEALTHCHOICE) - JVHL (JW)' },
  { value: '99999', label: 'OFFICE ALLY - PROFESSIONAL (99999)' },
  { value: '74240', label: 'ASSURED BENEFITS ADMINISTRATORS (74240)' },
  { value: 'MDCOR', label: 'MEDCORE HP (MDCOR)' },
  { value: '73147', label: 'HEALTHCARE SOLUTIONS GROUP (73147)' },
  { value: 'JQ', label: 'BCN REIMBURSABLE LABS - JVHL (JQ)' },
  { value: '53085', label: 'ANCHOR BENEFIT CONSULTING (53085)' },
  { value: 'RPPG1', label: 'RESURRECTION PHYSICIAN PROVIDERS GROUP (RPPG1)' },
  { value: '68013', label: 'JOHN MUIR TRAUMA PHYSICIANS (68013)' },
  { value: 'IP130', label: 'EAST BAY MEDICAL NETWORK (IP130)' },
  { value: 'IP130', label: 'SUTTER CONNECT - EAST BAY MEDICAL FOUNDATION\t (IP130)' },
  { value: '86067', label: 'NEVADACARE (86067)' },
  { value: 'GCVCP', label: 'CARDIOVASCULAR CARE PROVIDERS INC. (CVCP) (GCVCP)' },
  { value: '27008', label: 'EMPLOYERS COALITION ON HEALTH (ECOH) (27008)' },
  { value: 'EPF02', label: 'EL PASO HEALTH-STAR (EPF02)' },
  { value: '65113', label: 'METCARE HEALTH PLANS (65113)' },
  { value: 'CMG01', label: 'CHOICE MEDICAL GROUP (CMG01)' },
  { value: '36149', label: 'BENEFIT ADMINISTRATIVE SYSTEMS, LLC (36149)' },
  { value: '38310', label: 'JOHN MORRELL (38310)' },
  { value: '38265', label: 'ASR PHYSICIANS CARE (38265)' },
  { value: '31138', label: 'AHPO (31138)' },
  { value: '13343', label: 'AMALGAMATED LIFE AKA ALICARE KOP PA LOC (13343)' },
  { value: '38219', label: 'AMERAPLAN (38219)' },
  { value: 'NYL11', label: 'NEW YORK LIFE LONG TERM CARE (NYL11)' },
  { value: '916001069', label: 'WASHINGTON WORKERS COMPENSATION (916001069)' },
  { value: '35202', label: 'SECURITY ADMINISTRATIVE SERVICES (35202)' },
  { value: '35092', label: 'MORRIS ASSOCIATES (35092)' },
  { value: '75256', label: 'VERITY NATIONAL GROUP (75256)' },
  { value: '75236', label: 'EMPLOYERS DIRECT EMP (75236)' },
  { value: '59313', label: 'EVOLUTIONS HEALTHCARE SYSTEMS, INC. (59313)' },
  { value: '60280', label: 'INTERFACE EAP IEAP (60280)' },
  { value: '85256', label: 'WABASH MEMORIAL HOSPITAL ASSOCIATION (85256)' },
  { value: '37309', label: 'PROCTECTIVE LIFE INSURANCE (37309)' },
  { value: 'IMSMS', label: 'INSURANCE MANAGEMENT SERVICES TX\t (IMSMS)' },
  { value: '90001', label: 'HEALTHLINK PPO (90001)' },
  { value: '90001', label: 'ARIZONA CARE NETWORK (90001)' },
  { value: '90001', label: 'LAND OF LINCOLN FOR DOS PRIOR TO 1/1/2015 ONLY (90001)' },
  { value: '31478', label: 'PREFERRED HEALTH PROFFESIONAL (31478)' },
  { value: '31478', label: 'PHP KANSAS CITY (31478)' },
  { value: '35149', label: 'COOK GROUP HEALTH PLAN (35149)' },
  { value: 'HM006', label: 'CALIFORNIA CARE ENCOUNTERS (HM006)' },
  { value: '88067', label: 'TALL TREE ADMINISTRATORS (88067)' },
  { value: '20029', label: 'CBCA ADMINISTRATORS (20029)' },
  { value: '20029', label: 'AMERICAS CHOICE HEALTHPLAN (20029)' },
  { value: '15752', label: 'STONES RIVER IPA - WINDSOR (15752)' },
  { value: 'SX179', label: 'ASURIS NORTHWEST MEDADVANTAGE REGENCE (SX179)' },
  { value: 'USFHP', label: 'CHRISTUS HEALTH - USFHP (USFHP)' },
  { value: 'HM057', label: 'SUTTER CONNECT - SANTA ROSA ENCOUNTERS (HM057)' },
  { value: 'IP090', label: 'SUTTER CONNECT - SANTA ROSA CLAIMS (IP090)' },
  { value: 'IP100', label: 'SUTTER CONNECT - DELTA CLAIMS (IP100)' },
  { value: '77306', label: 'SUTTER CONNECT - SIP, SMG, SWMG ENCOUNTERS (77306)' },
  { value: '77306', label: 'SUTTER CONNECT - SIP, SMG, SWMG CLAIMS (77306)' },
  { value: 'HM062', label: 'SUTTER CONNECT - SOLANO ENCOUNTERS (HM062)' },
  { value: '77306', label: 'SUTTER CONNECT - SOLANO CLAIMS (77306)' },
  { value: 'HM058', label: 'SUTTER CONNECT - GOULD ENCOUNTERS (HM058)' },
  { value: '77302', label: 'SUTTER GOULD MEDICAL FOUNDATION (77302)' },
  { value: 'OCN01', label: 'GREATER VALLEY (OCN01)' },
  { value: 'OCN01', label: 'TALBERT MEDICAL GRROUP  (OCN01)' },
  { value: 'OCN01', label: 'HEALTHCARE PARTNERS (OCN01)' },
  { value: 'OCN01', label: 'UNIFIED IPA (OCN01)' },
  { value: 'OCN01', label: 'MEMORIAL MEDICAL GROUP (OCN01)' },
  { value: 'OCN01', label: 'ALLIANCE IPA (OCN01)' },
  { value: 'OCN01', label: 'HEALTHCARE PARTNERS OF CALIFORNIA (OCN01)' },
  { value: 'OCN01', label: 'HEALTHCARE PARTNERS MEDICAL GROUP (CA) (OCN01)' },
  { value: 'OCN01', label: 'HARRIMON JONES (OCN01)' },
  { value: 'OCN01', label: 'MAGAN MEDICAL CLINIC (OCN01)' },
  { value: '25160', label: 'MCA ADMINISTRATORS (25160)' },
  { value: '25160', label: 'DGA DIVERSIFIED (25160)' },
  { value: '58213', label: 'HEALTHWAYS WHOLEHEALTH NETWORKS (58213)' },
  { value: '43324', label: 'BEACON HEALTH STRATEGIES (43324)' },
  { value: '43324', label: 'PYSCHCARE LLC (43324)' },
  { value: '37137', label: 'HSHS MEDICAL GROUP IPA (37137)' },
  { value: '37137', label: 'SAGE TECHNOLOGIES - PBS (37137)' },
  { value: 'CCHP9', label: 'COOK CHILDRENS STAR PLAN (CCHP9)' },
  { value: '6161', label: 'PA PREFERRED HEALTH NETWORK - PPHN (6161)' },
  { value: '20270', label: 'HEALTH PAYMENT SYSTEMS, INC. (20270)' },
  { value: '31182', label: 'INNOVAGE (31182)' },
  { value: '31182', label: 'TOTAL COMMUNITY CARE (31182)' },
  { value: '10207', label: 'CITRUS HEALTH (10207)' },
  { value: '391910147', label: 'WISCONSIN DEPARTMENT OF CORRECTIONS (391910147)' },
  { value: 'HM066', label: 'SUTTER CONNECT - CENTRAL VALLEY MEDICAL GROUP-ENC (HM066)' },
  { value: 'IP131', label: 'SUTTER CONNECT - CENTRAL VALLEY MEDICAL GROUP-CLAI (IP131)' },
  { value: 'VAICE', label: 'DIVISION OF IMMIGRATOIN HEALTH SERVICES (DIHS) (VAICE)' },
  { value: 'BCMHODH', label: 'OHIO BCMH (BCMHODH)' },
  { value: 'K7', label: 'MCLAREN HEALTH PLAN - JVHL (K7)' },
  { value: '170148', label: 'WILLIAMETTE VALLEY COMMUNITY HEALTH CCO (170148)' },
  { value: 'ATRIO', label: 'ATRIO HEALTH PLANS (ATRIO)' },
  { value: 'ATRIO', label: 'PHYSICIANS CHOICE ADVANTAGE (PC ADVANTAGE) (ATRIO)' },
  { value: 'ATRIO', label: 'ATRIO HEALTH PLANS FKA MARION POLK COMMUNITY HLTH (ATRIO)' },
  { value: '14188', label: 'CARECORE NATIONAL WELLCARE HEALTH PLAN (14188)' },
  { value: 'ASH01', label: 'AMERICAN SPECIALTY HEALTH (ASH01)' },
  { value: 'IMGIN', label: 'IMG - INTERNATIONAL MEDICAL GROUP (IMGIN)' },
  { value: '37268', label: 'PALLIDIAN MUSCULAR SKELETAL HEALTH (37268)' },
  { value: '62180', label: 'HEALTH CHOICE PATHWAY (62180)' },
  { value: '77052', label: 'COASTAL TPA (77052)' },
  { value: 'CMGWV', label: 'COMMUNITY MEDICAL GROUP OF THE WEST VALLEY (CMGWV)' },
  { value: '23296', label: 'COMPFIRST, LLC (23296)' },
  { value: '84134', label: 'DENVER HEALTH - INDIGENT (84134)' },
  { value: '95432', label: 'FACEY MEDICAL FOUNDATION (95432)' },
  { value: '52193', label: 'ALLEGEANT (52193)' },
  { value: '37267', label: 'LOMA LINDA UNIVERSITY ADVENTIST (37267)' },
  { value: '35605', label: 'EVERENCE (35605)' },
  { value: '25404', label: 'SEVEN CORNERS (25404)' },
  { value: '65018', label: 'PHYSICIANS CORPORATION OF AMERICA (FLORIDA ONLY) (65018)' },
  { value: '6607', label: 'CARDINAL INNOVATIONS (6607)' },
  { value: '31144', label: 'TRIHEALTH PHYSICIAN SERVICES (31144)' },
  { value: '31144', label: 'ARCHCARE SENIOR LIFE (31144)' },
  { value: '31144', label: 'ARCHCARE COMMUNITY LIFE (31144)' },
  { value: '37315', label: 'PRISM UNIVERA (37315)' },
  { value: '75289', label: 'HEALTH FIRST TPA - AUSTIN (75289)' },
  { value: '50749', label: 'ALLIANZ GLOBAL ASSISTANCE (50749)' },
  { value: '50749', label: 'MONDIAL ASSISTANCE (50749)' },
  { value: 'TLC01', label: 'TLC ADVANTAGE IN SIOUX FALLS (TLC01)' },
  { value: 'WP481', label: 'WPPA-PROVIDRS CARE NETWORK (WP481)' },
  { value: '37086', label: 'PEKIN INSURANCE (37086)' },
  { value: '56215', label: 'CAROLINA BEHAVORIAL HEALTH ALLIANCE - CBHA (56215)' },
  { value: '90956', label: 'NATIONAL FINANCIAL INSURANCE COMPANY (90956)' },
  { value: '98205', label: 'NATIONAL FOUNDATION LIFE INSURANCE COMPANY (98205)' },
  { value: '95288', label: 'EMPLOYEE BENEFIT ADMINISTRATORS MANAGEMENT (95288)' },
  { value: '65101', label: 'HAA PREFERRED PARTNERS (65101)' },
  { value: '46594', label: 'AFFINITY MEDICAL GROUP (46594)' },
  { value: '68063', label: 'CELTIC INSURANCE (68063)' },
  { value: 'WCAS99252', label: 'UNIMED - CITY OF CARROLLTON/TRISTAR - P2P LINK (WCAS99252)' },
  { value: '65063', label: 'EYE MANAGEMENT, INC. (65063)' },
  { value: '65063', label: 'THERAPY PLAN OF GA (65063)' },
  { value: '65062', label: 'CARE ACCESS PSN (65062)' },
  { value: '65063', label: 'HS1 MEDICAL MANAGEMENT (65063)' },
  { value: 'ASRM1', label: 'ASRM CORPORATION (ASRM1)' },
  { value: 'LA-DHH-MEDICAID', label: 'LOUISIANA MEDICAID - DME (LA-DHH-MEDICAID)' },
  { value: 'NCHCA', label: 'NEW CENTURY HEALTH - CAREPLUS CARDIOLOGY (NCHCA)' },
  { value: 'NCHCA', label: 'NEW CENTURY HEALTH - VISTA CARDIOLOGY - SUMMIT (NCHCA)' },
  { value: 'NCHCA', label: 'NEW CENTURY HEALTH - VISTA CARDIOLOGY\t (NCHCA)' },
  { value: 'NCHCA', label: 'NEW CENTURY HEALTH - SIMPLY HEALTHCARE CARDIOLOGY (NCHCA)' },
  { value: 'NCHCA', label: 'NEW CENTURY HEALTH - CAC CENTERS (NCHCA)' },
  { value: 'NCHCA', label: 'NEW CENTURY HEALTH-BASCOM PALMER EYE CARE NETWORK (NCHCA)' },
  { value: '88050', label: 'NATIONAL HEALTH BENEFITS CORPORATION (88050)' },
  { value: '4423', label: 'UNMSCI (MOLINA HEALTHCARE OF UNM SCI) (4423)' },
  { value: '5003', label: 'PRESBYTERIAN HEALTH PLAN (5003)' },
  { value: '63103', label: 'AMERICAN BEHAVIOR (63103)' },
  { value: '52461', label: 'MARCH VISION CARE (52461)' },
  { value: '59204', label: 'FRINGE BENEFITS COORDINATORS (59204)' },
  { value: '33898', label: 'PREFERRED HEALTH CARE OF PENNSYLVANIA (33898)' },
  { value: '52104', label: 'NTCA STAFF (52104)' },
  { value: '64158', label: 'MERITAIN HEALTH / AGENCY SERVICES (64158)' },
  { value: '16109', label: 'LANCASTER GENERAL HEALTH GROUP (16109)' },
  { value: '11345', label: 'GENTIVE CARE CENTRIX (11345)' },
  { value: 'JH', label: 'HEALTH ALLIANCE PLAN (FEE FOR SERVICE) - JVHL (JH)' },
  { value: 'KC', label: 'BCBS MICHIGAN MEDICARE PLUS BLUE PPO - JVHL (KC)' },
  { value: '10378', label: 'SANTA CLARA COUNTY IPA HMO (10378)' },
  { value: '75237', label: 'HEALTHSMART ACCEL NETWORK (75237)' },
  { value: 'REHN', label: 'REHN AND ASSOCIATES (REHN)' },
  { value: '14829', label: 'CO-ORDINATED BENEFIT PLAN, LLC (14829)' },
  { value: 'NCH05', label: 'NEW CENTURY HEALTH-CALIFORNIA EYE CARE (NCH05)' },
  { value: '62177', label: 'MEDICAL REIMBURSEMENT ASSOCIATES (62177)' },
  { value: '37281', label: 'MID-AMERICA ASSOCIATES (37281)' },
  { value: '37281', label: 'LIBERTY UNION (37281)' },
  { value: 'SCUFW', label: 'UNITED FOOD AND COMMERCIAL WORKERS UNION (SCUFW)' },
  { value: '95426', label: 'AFFORDABLE BENEFIT ADMINISTATORS, INC (95426)' },
  { value: '39114', label: 'MERCYCARE HMO (39114)' },
  { value: '39114', label: 'MERCY CARE (39114)' },
  { value: '26054', label: 'ICARE HEALTH OPTIONS TPA (26054)' },
  { value: '13550', label: 'AMALGAMATED LIFE INS CO (13550)' },
  { value: '13550', label: 'ALICARE (13550)' },
  { value: '38254', label: 'BENEFIT ADMINISTRATORS (38254)' },
  { value: '62170', label: 'UNIFIED HEALTH SERVICES (62170)' },
  { value: '34145', label: 'KLAIS AND COMPANY (34145)' },
  { value: '64071', label: 'ACCLAIM (64071)' },
  { value: '64069', label: 'FOX EVERETT, INC. (64069)' },
  { value: '86033', label: 'CIGNA MEDICARE ACCESS (PFFS) (86033)' },
  { value: '86033', label: 'CIGNA MEDICARE SELECT HMO (86033)' },
  { value: '86033', label: 'CIGNA HEALTHCARE FOR SENIORS (86033)' },
  { value: '86033', label: 'ACCENTURE (86033)' },
  { value: '86033', label: 'CIGNA MEDICARE ARIZONA (86033)' },
  { value: 'FVMCH', label: 'FOX VALLEY MEDICINE (FVMCH)' },
  { value: '62157', label: 'HEALTH PARTNERS - JACKSON, TN (62157)' },
  { value: '56195', label: 'AMERICAN TRUST ADMINISTRATORS INC\t (56195)' },
  { value: '56195', label: 'CAROLINA SUMMIT HEALTHCARE, INC. (56195)' },
  { value: '36404', label: 'SELF INSURED PLANS LLC (36404)' },
  { value: '48116', label: 'FLORIDA HOSPITAL WATERMAN (48116)' },
  { value: '64068', label: 'MISSISSIPPI HEALTH PARTNERS (64068)' },
  { value: '64068', label: 'MHP SYSTEMS (64068)' },
  { value: '64068', label: 'CREATIVE MEDICAL SYSTEMS (64068)' },
  { value: '95444', label: 'IU HEALTH PLAN MEDICARE ADVANTAGE (95444)' },
  { value: '23287', label: 'INTERGROUP SERVICES CORP. (23287)' },
  { value: '-', label: 'VERSANT HEALTH (-)' },
  { value: '13413', label: 'WIN HEALTHCARE (13413)' },
  { value: '75130', label: 'UMC HEALTH PLAN (75130)' },
  { value: '87843', label: 'CONSOLIDATED HEALTH PLANS (87843)' },
  { value: '37331', label: 'RIGHTCHOICE BENEFITS ADMINISTRATORS (37331)' },
  { value: '48603', label: 'INTERNATIONAL BROTHERHOOD OF BOILERMAKERS EMP HLTH (48603)' },
  { value: '27004', label: 'CARE WISCONSIN FIRST HEALTH PLAN (27004)' },
  { value: '27004', label: 'CARE WISCONSIN HEALTH PLAN (27004)' },
  { value: 'ADOCS', label: 'AFFILIATED DOCTORS OF ORANGE COUNTY (ADOC) (ADOCS)' },
  { value: '22284', label: 'COLONIAL MEDICAL BERMUDA (22284)' },
  { value: '73172', label: 'SCAN HEALTH PLAN ARIZONA (73172)' },
  { value: 'GHOKC', label: 'GLOBAL HEALTH OKC (GHOKC)' },
  { value: 'BTSS1', label: 'BROWN AND TOLAND SUTTER SELECT (BTSS1)' },
  { value: '31165', label: 'EYEMED VISION CARE (31165)' },
  { value: '91064', label: 'WENATCHEE VALLEY MEDICAL CENTER (91064)' },
  { value: '46050', label: 'GENERATIONS HEALTH (46050)' },
  { value: '36878', label: 'ENTRUST (36878)' },
  { value: 'SCP01', label: 'SOUTHERN CAL PHYSICIANS MANAGED CARE (SCP01)' },
  { value: 'SCP01', label: 'SAN DIEGO PHYSICIANS MEDICAL GROUP (SCP01)' },
  { value: 'SCP01', label: 'MID-COUNTY PHYSICIANS MEDICAL GROUP (SCP01)' },
  { value: 'SCP01', label: 'IMPERIAL COUNTY PHYSICIANS MEDICAL GROUP (SCP01)' },
  { value: 'SCP01', label: 'UCSD PHYSICIAN NETWORK (SCP01)' },
  { value: '71057', label: 'CANNON COCHRAN MANAGEMENT SERVICES METAIRIE, LA (71057)' },
  { value: '59324', label: 'PODIATRY NETWORK OF FLORIDA (59324)' },
  { value: '62777', label: 'TRILOGY HEALTH NETWORK (TRLHN/EB) (62777)' },
  { value: '62777', label: 'TRILOGY HEALTH PLANS (62777)' },
  { value: 'EPF10', label: 'PREFERRED ADMINISTRATORS (EPF10)' },
  { value: '41095', label: 'WILSON MCSHANE (41095)' },
  { value: 'MPM06', label: 'HEALTH CARE LA IPA (MPM06)' },
  { value: '20212', label: 'TETHYS HEALTH VENTURES (20212)' },
  { value: 'WELMD', label: 'WELLMED ENCOUNTERS (WELMD)' },
  { value: '17902', label: 'COMMUNITY CARE ASSOCIATES (17902)' },
  { value: '33010', label: 'GREATER NEWPORT PHYSICIANS MEDICAL GROUP (33010)' },
  { value: 'A0701', label: 'ALTA BATES MEDICAL GROUP (ABMG) (DOS >=7/1/10) (A0701)' },
  { value: 'A0701', label: 'SUTTER CONNECT - ABMG ENCOUNTERS (A0701)' },
  { value: 'CAPHP', label: 'CAPROCK HEALTH PLANS (CAPHP)' },
  { value: 'CAPHP', label: 'GROUP ADMINISTRATORS SELF-FUNDED ALTERNATIVE (CAPHP)' },
  { value: '41205', label: 'BENEFIT ADMINISTRATION SERVICES, LTD (BAS LTD) (41205)' },
  { value: 'POP02', label: 'FUTURE CARE MEDICAL ASSOCIATES (POP02)' },
  { value: '47083', label: 'GMS INC (47083)' },
  { value: '94998', label: 'FIRSTCARE STAR MEDICAID MCO AND ADVANTAGE HMO (94998)' },
  { value: 'KD', label: 'CIGNA - NON-HAP AND CIGNA-HAP - JVHL (KD)' },
  { value: 'SBIPA', label: 'SANTA BARBARA SELECT IPA (SBIPA)' },
  { value: '-', label: 'CHANGE HEALTHCARE COMP BILLING (-)' },
  { value: 'CCIH', label: 'CORRECTCARE INTEGRATED HEALTH (CCIH)' },
  { value: '14142', label: 'NEW YORK STATE DEPT OF HEALTH UNCOMPENSATED CARE (14142)' },
  { value: '00290', label: 'INDIAN HEALTH SERVICES (290)' },
  { value: 'MPM03', label: 'CENTINELA VALLEY IPA MEDICAL (MPM03)' },
  { value: 'MPM04', label: 'EL PROYECTO DEL BARRIO IPA (MPM04)' },
  { value: 'MPM05', label: 'GLOBAL CARE MEDICAL GROUP IPA (MPM05)' },
  { value: 'PHM10', label: 'MISSION COMMUNITY IPA MEDICAL GROUP (PHM10)' },
  { value: 'MPM09', label: 'WATTS HEALTHCARE CORPORATION IPA (MPM09)' },
  { value: 'MPM10', label: 'BELLA VISTA MEDICAL GROUP IPA (MPM10)' },
  { value: '14140', label: 'FARM FAMILY (14140)' },
  { value: '42149', label: 'EMPLOYEE BENEFIT SYSTEMS (42149)' },
  { value: '74234', label: 'ERISA (74234)' },
  { value: '74234', label: 'UTAH LABORERS (74234)' },
  { value: '74234', label: 'INTERMOUNTAIN IRONWORKERS (74234)' },
  { value: '74234', label: 'ADVANSTAFF, INC. (74234)' },
  { value: '74234', label: 'IBEW 640 AZ NECA (74234)' },
  { value: '74234', label: '8TH DISTRICT ELECTRICAL (74234)' },
  { value: '74234', label: 'COMPUSYS ERISA GROUP AZ (74234)' },
  { value: '74234', label: 'ARIZONA PIPE TRADES (74234)' },
  { value: '74234', label: 'UTAH CARPENTERS (74234)' },
  { value: '74234', label: 'NEW MEXICO WEST TEXAS MULTICRAFT (74234)' },
  { value: '74234', label: 'IBEW WESTERN UTILITIES LOCAL 57 (74234)' },
  { value: '74234', label: 'ERISA ADMINISTRATIVE SERVICES INCORPORATED (74234)' },
  { value: '74234', label: 'CITY OF AUSTIN (74234)' },
  { value: '74234', label: 'TECHSTEEL INC (74234)' },
  { value: '74234', label: 'NEW MEXICO DISTRICT COUNCIL OF CARPENTERS (74234)' },
  { value: '74234', label: 'UTAH PIPE TRADES (74234)' },
  { value: '74234', label: 'NEW MEXICO PAINTERS AND ALLIED TRADES LOCAL NO 823 (74234)' },
  { value: '74234', label: 'COMPUSYS ERISA GROUP NM (74234)' },
  { value: '74234', label: 'COMPUSYS INC NM (74234)' },
  { value: '74234', label: 'COMPUSYS ERISA GROUP TX (74234)' },
  { value: '74234', label: 'COMPUSYS INC AZ (74234)' },
  { value: '74234', label: 'TRANSWESTERN GENERAL AGENCY (74234)' },
  { value: '74234', label: 'OPERATING ENGINEERS LOCAL 53 (74234)' },
  { value: '74234', label: 'COMPUSYS INC UT (74234)' },
  { value: '37321', label: 'KEY SELECT (37321)' },
  { value: 'KCIPA', label: 'KANE COUNTY IPA (KCIPA)' },
  { value: '56071', label: 'AMERICAN FAMILY ADMINISTERED BY AMERICAN REPUBLIC (56071)' },
  { value: 'LMG01', label: 'VERDUGO HILLS MEDICAL GROUP (LMG01)' },
  { value: 'LMG01', label: 'KEYSTONE HEALTH (LAKESIDE - GLENDALE, CA) (LMG01)' },
  { value: 'LMG01', label: 'WEST COVINA MEDICAL GROUP (LMG01)' },
  { value: 'LMG01', label: 'GLENDALE MEMORIAL GROUP (LMG01)' },
  { value: 'LMG01', label: 'LAKESIDE MEDICAL GROUP (REGAL LAKESIDE) (LMG01)' },
  { value: 'LMG01', label: 'EASTLAND MEDICAL GROUP (LMG01)' },
  { value: 'LMG01', label: 'COMMUNITY MEDICAL GROUP OF THE WEST VALLEY (LMG01)' },
  { value: 'LMG01', label: 'LAKESIDE MEDICAL GROUP (LMG01)' },
  { value: 'LMG01', label: 'EASTLAND MEDICAL GROUP IPA (LMG01)' },
  { value: '00773', label: 'SPECTERA (773)' },
  { value: '00773', label: 'SPECTERA EYECARE NETWORKS (773)' },
  { value: '00773', label: 'UNITEDHEALTHCARE VISION (773)' },
  { value: '18247', label: 'REDLANDS YUCAIPA MEDICAL GROUP (18247)' },
  { value: 'MCS03', label: 'GEMCARE (GOLDEN EMPIRE MANAGED CARE SYSTEM)\t (MCS03)' },
  { value: 'MCS03', label: 'MANAGED CARE SYSTEMS (DELANGO REGIONAL MEDICAL) (MCS03)' },
  { value: 'MCS03', label: 'GEMCARE - KERN COUNTY (MCS03)' },
  { value: '06226', label: 'NOVANET (6226)' },
  { value: 'D00111', label: 'BREAST AND CERVICAL CANCER CONTROL PROGRAM (BCCCP) (D00111)' },
  { value: '60995', label: 'FAMILY CARE MILWAUKEE, WISCONSIN (60995)' },
  { value: 'NMM02', label: 'LASALLE MEDICAL GROUP IPA (NMM02)' },
  { value: 'IMG01', label: 'INDEPENDENCE MEDICAL GROUP - KERN COUNTY (IMG01)' },
  { value: 'IMG02', label: 'INDEPENDENCE MEDICAL GROUP - TULARE COUNTY (IMG02)' },
  { value: '83077', label: 'ADVANTEK BENEFIT ADMINISTRATORS (83077)' },
  { value: 'OHMS', label: 'OREGON HEALTH MANAGEMENT SERVICES (OHMS) (OHMS)' },
  { value: 'OHMS', label: 'PRIMARY HEALTH OF JOSEPHINE COUNTY CCO (OHMS)' },
  { value: '43307', label: 'VALUE OPTIONS MBHP (43307)' },
  { value: '43307', label: 'VALUE OPTIONS - BEHAVIORAL HEALTH (43307)' },
  { value: '77160', label: 'GOLD COAST HEALTH PLAN (77160)' },
  { value: '65054', label: 'PREMIER EYE CARE (65054)' },
  { value: '34638', label: 'UNIFIED PHYSICIANS NETWORK (34638)' },
  { value: 'MPM40', label: 'FAMILY CARE SPECIALISTS IPA (MPM40)' },
  { value: '48100', label: 'PROVIDRS CARE NETWORK (48100)' },
  { value: '31172', label: 'WORLD TRADE CENTER HEALTH PROGRAM/FDNY (31172)' },
  { value: '31172', label: 'INNOVANTE BENEFIT ADMINISTRATOR (31172)' },
  { value: '87098', label: 'AVESIS TPA (87098)' },
  { value: '80942', label: 'WEST SUBURBAN HEALTH PROVIDERS (80942)' },
  { value: '59374', label: 'ADVANTICA BENEFITS (59374)' },
  { value: 'ACIPA', label: 'ADVANTAGE CARE IPA (ACIPA)' },
  { value: '25172', label: 'DH EVANS AND ASSOCIATES (25172)' },
  { value: '25172', label: 'PATH ADMINISTRATORS (25172)' },
  { value: '34186', label: 'CSI NETWORK SERVICES (34186)' },
  { value: '412MP', label: 'MEDPARTNERS ADMINISTRATIVE SERVICES 412MP (412MP)' },
  { value: '74431', label: 'OHIO PPO CONNECT (OPPOC) (74431)' },
  { value: '89461', label: 'QUALITY CARE PARTNERS (89461)' },
  { value: '68423', label: 'CARE NETWORK/THE SAVANNAH BUSINESS (THE) (68423)' },
  { value: '52429', label: 'HEALTH SCOPE BENEFITS (AKA EHC) (52429)' },
  { value: '20050', label: 'INTEGRATED MEDICAL SOLUTIONS (20050)' },
  { value: 'FS802', label: 'BRIDGEVIEW (FS802)' },
  { value: '45210', label: 'CHRISTUS HEALTH PLAN (45210)' },
  { value: 'KR', label: 'UNITED HEALTHCARE (GOLDEN RULE) - JVHL (KR)' },
  { value: 'KQ', label: 'CIGNA (HEALTH PARTNERS) - JVHL (KQ)' },
  { value: 'MSO33', label: 'SAN DIEGO COUNTY RYAN WHITE CARE ACT (MSO33)' },
  { value: '20501', label: 'HEALTHCARE PARTNERS OF NEVADA (20501)' },
  { value: '35600', label: 'INDIANA DEPARTMENT OF HEALTH - CHILDRENS HEALTH (35600)' },
  { value: 'DREYR', label: 'DREYER MEDICAL (DREYR)' },
  { value: 'GEM01', label: 'GLOBAL EXCEL MANAGEMENT INC (GEM01)' },
  { value: '54138', label: 'VHN (54138)' },
  { value: '48026', label: 'NORTHSHORE PHYSICIAN ASSOCIATES (48026)' },
  { value: 'CB752', label: 'LOCKARD AND WILLIAMS (CB752)' },
  { value: 'MSO11', label: 'SAN DIEGO COUNTY MEDICAL SERVICES (CMS) (MSO11)' },
  { value: '133741352', label: 'VERSANT HEALTH (133741352)' },
  { value: 'CHCPI', label: 'CENTRAL HEALTH MEDICAL PLAN (CHCPI)' },
  { value: '41202', label: 'TOTAL PLAN SERVICES (41202)' },
  { value: 'CHP01', label: 'COMPLEMENTARY HEALTHCARE PLAN (CHP01)' },
  { value: '38221', label: 'REGENCY EMPLOYEE BENEFITS (38221)' },
  { value: '36337', label: 'WEISS HEALTH PROVIDERS (36337)' },
  { value: '37363', label: 'COMPSYCH (37363)' },
  { value: '44219', label: 'QUEST BEHAVIORAL HEALTH  (44219)' },
  { value: '58182', label: 'NAPHCARE (58182)' },
  { value: 'TLINS', label: 'TRANSAMERICA FINANCIAL LIFE INSURANCE COMPANY (TLINS)' },
  { value: 'TLINS', label: 'TRANSAMERICA PREMIER LIFE INSURANCE COMPANY (TLINS)' },
  { value: 'TLINS', label: 'MONUMENTAL LIFE INSURANCE COMPANY (TLINS)' },
  { value: 'TLINS', label: 'TRANSAMERICA LIFE SPECIALIZED CLAIMS (TLINS)' },
  { value: '57492', label: 'STONES RIVER IPA - AMERIVANTAGE (57492)' },
  { value: 'MVMM1', label: 'INLAND FACULTY MEDICAL GROUP (MVMM1)' },
  { value: '27154', label: 'ARIZONA PRIORITY CARE PLUS (27154)' },
  { value: '13382', label: 'US FAMILY HEALTH PLAN ORTHONET (13382)' },
  { value: 'SCACO', label: 'SANTA CLARA COUNTY IPA PPO (SCACO)' },
  { value: 'SCACO', label: 'SANTA CLARA COUNTY IPA ACO (SCACO)' },
  { value: 'PCN12', label: 'PHYSICIANS CARE NETWORK - THE POLYCLINIC (PCN12)' },
  { value: '20172', label: 'PENNSYLVANIA PACE (20172)' },
  { value: '00965', label: 'US VIRGIN ISLANDS BLUE SHIELD (965)' },
  { value: '45289', label: 'FRINGE BENEFIT GROUP - AUSTIN (45289)' },
  { value: '75126', label: 'STONES RIVER IPA - HIPA/AMERIVANTAGE (75126)' },
  { value: '10145', label: 'FAMILY PRACTICE MEDICAL GROUP (10145)' },
  { value: '13010', label: 'VAYA HEALTH (13010)' },
  { value: '36393', label: 'CENTURY PHO (36393)' },
  { value: 'VHP02', label: 'VALLEY HEALTH PLAN (MEDI-CAL) (VHP02)' },
  { value: 'PHPMC', label: 'CORRECTION HEALTH PARTNERS (PHPMC)' },
  { value: 'OBA18', label: 'OGDEN BENEFITS ADMINISTRATORS (OBA18)' },
  { value: 'EBC18', label: 'EMPLOYEE BENEFIT CONCEPTS (EBC18)' },
  { value: 'VPA18', label: 'VARIABLE PROTECTION ADMINISTRATORS (VPA18)' },
  { value: 'RMC01', label: 'RIVERSIDE MEDICAL CLINIC (RMC01)' },
  { value: 'MESVISION', label: 'MEDICAL EYE SERVICES, INC. (MESVISION)' },
  { value: '1757', label: 'AMFIRST INSURANCE (1757)' },
  { value: '1759', label: 'MONITOR LIFE INSURANCE COMPANY (1759)' },
  { value: '83028', label: 'CDO TECHNOLOGIES (83028)' },
  { value: '83028', label: 'MERRILL BOSTROM ASSOCIATES (83028)' },
  { value: '65026', label: 'BAPTIST HEALTH SOUTH FLORIDA (65026)' },
  { value: '68068', label: 'CENPATICO BEHAVIORAL HEALTH - NEW HAMPSHIRE (68068)' },
  { value: '41206', label: 'ULTRABENEFITS INC. (41206)' },
  { value: '11752', label: 'CLAIMSBRIDGE HIGH PERFORMANCE NETWORK (11752)' },
  { value: 'AMM03', label: 'CUSTODY MEDICAL SERVICES (AMM03)' },
  { value: 'HPIPA', label: 'HISPANIC PHYSICIANS IPA (HPIPA)' },
  { value: '76870', label: 'LIFEPATH HOSPICE (76870)' },
  { value: 'IHORG', label: 'INLAND HEALTH ORGANIZATION (IHORG)' },
  { value: '45967', label: 'BEAVER MEDICAL GROUP (45967)' },
  { value: '27215', label: 'KENTUCKY HEALTH ADMINISTRATORS (27215)' },
  { value: '96385', label: 'UNITED HEALTHCARE COMMUNITY PLAN OF KANSAS (96385)' },
  { value: '56089', label: 'EAST CAROLINA BEHAVIORAL HEALTH (56089)' },
  { value: '56089', label: 'EAST CAROLINA BEHAVIORAL HEALTH AKA ECBH (56089)' },
  { value: '76923', label: 'GOOD SHEPHERD HOSPICE (76923)' },
  { value: '56213', label: 'CRESCENT HEALTH SOLUTIONS (56213)' },
  { value: 'JAI01', label: 'JAI MEDICAL SYSTEMS (JAI01)' },
  { value: 'MNDH1', label: 'SAGE MINNESOTA DEPARTMENT OF HEALTH (MNDH1)' },
  { value: 'RCMG1', label: 'RIVER CITY MEDICAL GROUP (RCMG1)' },
  { value: 'SHC30', label: 'SANDHILLS COMMUNITY CARE NETWORK (SHC30)' },
  { value: 'SHC30', label: 'GUILFORD CENTER FOR BEHAVIORAL (SHC30)' },
  { value: 'SHC30', label: 'SANDHILLS CENTER (SHC30)' },
  { value: '64246', label: 'GUARDIAN (64246)' },
  { value: '4317M', label: 'GATEWAY TO BETTER HEALTH (4317M)' },
  { value: '28943', label: 'STONES RIVER IPA TP-ACO (28943)' },
  { value: 'AKDOH', label: 'ALASKA BREAST AND CERVICAL HEALTH CHECK (AKDOH)' },
  { value: 'RVC01', label: 'REVCLAIMS (RVC01)' },
  { value: '22823', label: 'MID-AMERICAN BENEFITS (ELITE CHOICE) (22823)' },
  { value: '36609', label: 'BOILERMAKERS NATIONAL HEALTH AND WELFARE (36609)' },
  { value: '99999-0910', label: 'EAST POINTE HUMAN SERVICES (99999-0910)' },
  { value: '99999-0910', label: 'EASTPOINTE - BEHAVIORAL HEALTH (99999-0910)' },
  { value: '22285', label: 'ATLANTIC MEDICAL (22285)' },
  { value: 'HVMG1', label: 'HORIZON VALLEY MEDICAL GROUP (HVMG1)' },
  { value: '13285', label: 'CLOVER HEALTH (13285)' },
  { value: '20805', label: 'MARRICK MEDICAL FINANCE (20805)' },
  { value: '50383', label: 'US IMAGING NETWORK (50383)' },
  { value: '91131', label: 'FIRST CHOICE HEALTH NETWORK (91131)' },
  { value: '91131', label: 'NORTHWEST ADM INC (91131)' },
  { value: '91131', label: 'CARPENTERS TRUSTS OF WESTERN WASHINGTON (91131)' },
  { value: '43471', label: 'INETICO, INC. (43471)' },
  { value: '23071', label: 'ALLIANCE BEHAVIORAL HEALTHCARE (23071)' },
  { value: '35164', label: 'SAGAMORE HEALTH NETWORK (35164)' },
  { value: '56162', label: 'MEDCOST, INC. (56162)' },
  { value: '59087', label: 'HEALTHHELP NETWORKS, INC. (HHNI) (59087)' },
  { value: '59087', label: 'HHNI (59087)' },
  { value: 'HEALTHEOS', label: 'HEALTHEOS ASSOCIATES FOR HEALTH CARE, INC. (HEALTHEOS)' },
  { value: '34131', label: 'SELF FUNDED PLANS (IL, PA, OH) (34131)' },
  { value: '42140', label: 'CENTURION MANAGED CARE (42140)' },
  { value: '13141', label: 'PARTNERS BEHAVIORAL HEALTH (13141)' },
  { value: '13141', label: 'PARTNERS BEHAVIORAL HLTH MGMT (13141)' },
  { value: 'PPM02', label: 'VANTAGE MEDICAL GROUP ENCOUNTERS (PPM02)' },
  { value: 'CAPMN', label: 'MEDICINA FAMILIAR MEDICAL GROUP (CAPMN)' },
  { value: 'CAPMN', label: 'HOLLYWOOD PRESBYTERIAN MEDICAL GROUP (CAPMN)' },
  { value: 'CAPMN', label: 'HUNTINGTON PARK MEDICAL GROUP (CAPMN)' },
  { value: 'CAPMN', label: 'PRUDENT MEDICAL GROUP  (CAPMN)' },
  { value: 'CAPMN', label: 'NOBLE COMMUNITY (CAPMN)' },
  { value: 'CAPMN', label: 'PALOMAR POMERADO (CAPMN)' },
  { value: 'CAPMN', label: 'FAMILY HEALTH ALLIANCE (CAPMN)' },
  { value: 'CAPMN', label: 'AKM MEDICAL GROUP (CAPMN)' },
  { value: 'CAPMN', label: 'EXCEPTIONAL CARE MEDICAL (CAPMN)' },
  { value: 'CAPMN', label: 'MABUHAY MEDICAL GROUP (CAPMN)' },
  { value: 'CAPMN', label: 'HUNTINGTON PARK MISSION (CAPMN)' },
  { value: 'CAPMN', label: 'AVALON IPA (CAPMN)' },
  { value: 'CAPMN', label: 'PREMIER PHYSICIAN NETWORK & VALLEY DIVISION (PPN) (CAPMN)' },
  { value: 'CAPMN', label: 'GREATER L.A. HEALTH (CAPMN)' },
  { value: 'CAPMN', label: 'CANOPY HEALTH (CAPMN)' },
  { value: 'CAPMN', label: 'MID-CITIES IPA (CAPMN)' },
  { value: 'CAPMN', label: 'GARDEN GROVE HOSPITAL (CAPMN)' },
  { value: 'CAPMN', label: 'PASADENA PRIMARY CARE PHYSICIANS (CAPMN)' },
  { value: 'CAPMN', label: 'CONIFER HEALTH SOLUTIONS (CAPMN)' },
  { value: 'CAPMN', label: 'PRIMARY CARE & PEDIATRICS IPA (PCPIPA) (CAPMN)' },
  { value: 'CAPMN', label: 'FREEMAN IPA (CAPMN)' },
  { value: 'CAPMN', label: 'CAP MANAGEMENT (CAPMN)' },
  { value: 'CAPMN', label: 'PREMIER CARE OF NORTHERN CALIFORNIA (CAPMN)' },
  { value: 'CAPMN', label: 'AVALON MEDICAL GROUP (CAPMN)' },
  { value: 'CAPMN', label: 'CAP MANAGEMENT SYSTEMS (CAPMN)' },
  { value: 'CAPMN', label: 'ALTA MED HEALTH SERVICES (CAPMN)' },
  { value: 'CAPMN', label: 'AMERICAN FAMILY HEALTH PROVIDERS (CAPMN)' },
  { value: 'CAPMN', label: 'GREATER SAN GABRIEL (CAPMN)' },
  { value: 'CAPMN', label: 'ALTAMED SENIOR BUENACARE/PACE (CAPMN)' },
  { value: 'CAPMN', label: 'FAMILY CHOICE MEDICAL (CAPMN)' },
  { value: 'CAPMN', label: 'COMMUNITY FAMILY CARE AND CFC SAN FERNANDO VALLEY (CAPMN)' },
  { value: 'MVCV1', label: 'PRIME COMMUNITY CARE OF CENTRAL VALLEY (PCCCV) (MVCV1)' },
  { value: 'HCP02', label: 'HEALTHCARE PARTNERS ARIZONA (HCP02)' },
  { value: 'OLRMC', label: 'OLOLRMC UNINSURED PATIENT SERVICES PROGRAM (OLRMC)' },
  { value: 'CARDON', label: 'CARDON OUTREACH (CARDON)' },
  { value: 'PPNZZ', label: 'PPN PIONEER HEALTHY FAMILIES BY MEDPOINT (PPNZZ)' },
  { value: 'PPNZZ', label: 'PPN HEALTHY FAMILIES BY MEDPOINT (PPNZZ)' },
  { value: 'PPNZZ', label: 'PIONEER PROV NETWORK HEALTHY FAMILIES BY MEDPOINT (PPNZZ)' },
  { value: 'PPNZZ', label: 'PPN MEDI-CAL BY MEDPOINT (PPNZZ)' },
  { value: 'PPNZZ', label: 'PIONEER PROVIDER NETWORK MEDI-CAL BY MEDPOINT (PPNZZ)' },
  { value: 'L0230', label: 'TRUSTED HEALTH PLAN (L0230)' },
  { value: 'MPM17', label: 'REDWOOD COMMUNITY HEALTH COALITION (MPM17)' },
  { value: 'CCPN1', label: 'COASTAL COMMUNITIES PHYSICIAN NETWORK (CCPN) (CCPN1)' },
  { value: 'MPM13', label: 'EAST LA DOCTORS HOSPITAL (MEDPOINT) (MPM13)' },
  { value: 'MPM16', label: 'MPM PROSPECT (MPM16)' },
  { value: 'MPM12', label: 'MEMORIAL HOSPITAL OF GARDENA (MPM12)' },
  { value: 'KV', label: 'HUMANA - JVHL (KV)' },
  { value: '88057', label: 'NATIONAL HEALTH BENEFIT (88057)' },
  { value: '3833C', label: 'MCLAREN HEALTH PLAN MEDICAID (3833C)' },
  { value: '88848MC', label: 'IOWA BLUE SHIELD MEDICARE CROSSOVER (88848MC)' },
  { value: '3833A', label: 'MCLAREN HEALTH ADVANTAGE (3833A)' },
  { value: '3833N', label: 'NORTHERN HEALTH PLAN (3833N)' },
  { value: '3833T', label: 'TENCON HEALTH PLAN (3833T)' },
  { value: '3833R', label: 'MCLAREN ADVANTAGE SNP (3833R)' },
  { value: '-', label: 'AKAMAI ADVANTAGE (-)' },
  { value: '27005', label: 'MEDOVA HEALTHCARE (27005)' },
  { value: '27005', label: 'LIFESTYLE HEALTH PLANS (27005)' },
  { value: 'JMH01', label: 'JOHN MUIR MT. DIABLO HEALTH SYSTEM (JMH01)' },
  { value: '45281', label: 'UNIVERSITY OF MARYLAND HEALTH PARTNERS (45281)' },
  { value: 'MMFMC', label: 'MEMORIALCARE MEDICAL FOUNDATION (MMFMC)' },
  { value: '51020', label: 'INTEGRA ADMINISTRATIVE GROUP (51020)' },
  { value: 'SKVI0', label: 'VIRGIN ISLANDS MEDICAID (SKVI0)' },
  { value: '76619', label: 'OSU SOONERCARE (76619)' },
  { value: '50701', label: 'VGM HOMELINK (50701)' },
  { value: '34167', label: 'HEALTH SERVICES PREFERRED (HSP) EMERALD HEALTH (34167)' },
  { value: '93235', label: 'ELECTRICAL WORKERS INSURANCE FUND LOCAL 5800 (93235)' },
  { value: '65449', label: 'HEALTH SPECIAL RISK (65449)' },
  { value: '31650', label: 'WAGNER MEINERT (31650)' },
  { value: '660194027', label: 'ASSOCIATION DE MAESTROS PUERTO RICO (660194027)' },
  { value: '72-0281240', label: 'PANAMERICAN (PALIC) (72-0281240)' },
  { value: '45399', label: 'HEALTH CHOICE UTAH (45399)' },
  { value: '63740', label: 'EYEQUEST (63740)' },
  { value: 'CPHL', label: 'CPHL (CPHL)' },
  { value: 'CPHL', label: 'CENTERS PLAN FOR HEALTHY LIVING (CPHL)' },
  { value: '77170', label: 'COMMON GROUND HEALTHCARE COOPERATIVE (77170)' },
  { value: 'BSHS1', label: 'BIENVIVIR SENIOR HEALTH SERVICES (BSHS1)' },
  { value: '17516', label: 'NORTH SHORE LIJ (EMPLOYEE PLAN) (17516)' },
  { value: '33065', label: 'CHOC-CHILDRENS HOSPITAL OF ORANGE CO HEALTH - RADY (33065)' },
  { value: 'CCHP', label: 'CONTRA COSTA HEALTH PLAN (CCHP)' },
  { value: 'HCCMI', label: 'TOKIO MARINE HCC  (HCCMI)' },
  { value: 'HCCMI', label: 'HCC LIFE INSURANCE COMPANY (HCCMI)' },
  { value: 'HTHTX', label: 'HEALTH TEXAS MEDICAL GROUP (HTHTX)' },
  { value: 'HT000004-005', label: 'UTAH MEDICAID CROSSOVER (HT000004-005)' },
  { value: '94312', label: 'CENTER FOR ELDERS INDEPENDENCE (94312)' },
  { value: 'DOCSO', label: 'ADVANCED HEALTH (DOCSO)' },
  { value: '95213', label: 'HEALTHEDGE ADMINISTRATORS (95213)' },
  { value: '04326', label: 'ELEMENT CARE (4326)' },
  { value: '65074', label: 'OLYMPUS MANAGED HEALTH CARE (65074)' },
  { value: 'SC028', label: 'SUTTER SENIOR CARE (SC028)' },
  { value: '45321', label: 'CONSUMERS CHOICE OF SOUTH CAROLINA/HEALTHSCOPE BEN (45321)' },
  { value: '22149', label: 'KERN HEALTH CARE NETWORK (KERN COUNTY) (22149)' },
  { value: '49718', label: 'COLORADO HEALTHOP (49718)' },
  { value: '92805', label: 'MAXOR ADMINISTRATIVE SERVICES (92805)' },
  { value: 'VHP01', label: 'VALLEY HEALTH PLAN (COMMERCIAL) (VHP01)' },
  { value: 'CB624', label: 'GULF STREAM GENERAL DYNAMICS (CB624)' },
  { value: '13360', label: 'COMPREHENSIVE CARE MANAGEMENT CORPORATION (13360)' },
  { value: '13360', label: 'CENTERLIGHT HEALTHCARE (13360)' },
  { value: '13360', label: 'CENTERLIGHT HEALTHCARE SELECT (13360)' },
  { value: '13360', label: 'CCM SELECT (13360)' },
  { value: '13360', label: 'CENTERLIGHT HEALTHCARE PACE (13360)' },
  { value: '46166', label: 'EXTENDED MLTC, LLC AKA ROYA HEALTH EXTENDED CARE (46166)' },
  { value: '46166', label: 'ROYAL HEALTH EXTENDED CARE MLTC (46166)' },
  { value: '45341', label: 'MAINE COMMUNITY HEALTH OPTIONS (45341)' },
  { value: '45129', label: 'NEW MEXICO HEALTH CONNECTIONS (45129)' },
  { value: '26545', label: 'VILLAGECAREMAX (26545)' },
  { value: 'VAPCCC3', label: 'VA PATIENT CENTERED COMMUNITY CARE REGION 3 (VAPCCC3)' },
  { value: 'KEE01', label: 'KEENAN ASSOCIATES (KEE01)' },
  { value: 'ADCUC', label: 'UNITED CARE MEDICAL GROUP (ADCUC)' },
  { value: '31626', label: 'NASCENTIA HEALTH OPTIONS (31626)' },
  { value: '236003113', label: '1-888-OHIOCOMP (236003113)' },
  { value: '33036', label: 'LOMA LINDA UNIVERSITY HEALTH CARE (33036)' },
  { value: '96107', label: 'NEIGHBORHOOD HEALTH FLORIDA (96107)' },
  { value: '36411', label: 'SWEDISH COVENANT HOSPITAL (36411)' },
  { value: 'UGP19', label: 'UNITED GROUP PROGRAMS (UGP19)' },
  { value: '46161', label: 'MONTEFIORE HMO (46161)' },
  { value: 'H0657', label: 'FRIDAY HEALTH PLANS (H0657)' },
  { value: 'CDCR1', label: 'CLINICAS DEL CAMINO REAL (CDCR1)' },
  { value: '27048', label: 'PAYER FUSION (27048)' },
  { value: 'SMIPA', label: 'ST MARY IPA (SMIPA)' },
  { value: 'MPM22', label: 'PREMIER PHYSICIANS NETWORK (MEDPOINT MANAGEMENT) (MPM22)' },
  { value: '660567825', label: 'APS HEALTHCARE OF PUERTO RICO (660567825)' },
  { value: 'IP132', label: 'PALO ALTO MEDICAL FOUNDATION (IP132)' },
  { value: 'AMM13', label: 'MEDICAL SAFETY NET (AMM13)' },
  { value: '27034', label: 'PEAK PACE SOLUTIONS (27034)' },
  { value: 'CB268', label: 'CORNERSTONE PREFERRED RESOURCES (CB268)' },
  { value: '59065', label: 'COMMUNITY CARE PLAN (MEDICAID) (59065)' },
  { value: '57549', label: 'STONES RIVER REGIONAL IPA HUMAN CLAIMS (57549)' },
  { value: 'BTHS1', label: 'BROWN AND TOLAND HEALTH SERVICES (BTHS1)' },
  { value: '40025', label: 'INNOVATION HEALTH (40025)' },
  { value: 'CHIPA', label: 'COLLEGE HEALTH IPA (CHIPA)' },
  { value: '94056', label: 'CALIFORNIA PACIFIC MEDICAL CENTER (94056)' },
  { value: 'UCDMG', label: 'UC DAVIS MEDICAL GROUP  (UCDMG)' },
  { value: '19191', label: 'NATIVE CARE HEALTH (19191)' },
  { value: 'VESTA', label: 'RHA VESTACARE (VESTA)' },
  { value: 'PAN01', label: 'PATIENT ACCESS NETWORK (PAN01)' },
  { value: '22251', label: 'HEALTHY YORK NETWORK (22251)' },
  { value: '95411', label: 'POSITIVE HEALTHCARE (95411)' },
  { value: 'HT006885-001', label: 'OPTUM SALT LAKE COUNTY PUBLIC SECTOR (HT006885-001)' },
  { value: '15243', label: 'COMPMANAGEMENT HEALTH (15243)' },
  { value: 'CB987', label: 'BLACK HAWK (CB987)' },
  { value: 'RPAWC', label: 'RAVENSWOOD PHYSICIAN ASSOCIATES, INC. (RPAWC)' },
  { value: '34108', label: 'ES BEVERIDGE AND ASSOCIATES (34108)' },
  { value: 'SHPS1', label: 'SCRIPPS COASTAL MEDICAL GROUP (SHPS1)' },
  { value: '45552', label: 'SATELLITE HEALTH PLAN (45552)' },
  { value: '4245', label: 'HARVARD COMMUNITY HEALTH PLAN (4245)' },
  { value: '88058', label: 'MED PAY (88058)' },
  { value: '92135', label: 'EMPLOYEE BENEFIT LOGISTICS (92135)' },
  { value: '10002', label: 'SHEAKLEY UNICOMP (10002)' },
  { value: '953766170', label: 'COMMUNITY HEALTH GROUP (953766170)' },
  { value: 'MVMM2', label: 'INLAND FACULTY MEDICAL GROUP ENCOUNTERS (MVMM2)' },
  { value: '46478', label: 'CONTINUUM HEALTH SOLUTIONS, LLC (46478)' },
  { value: '41222', label: 'CARE TO CARE (41222)' },
  { value: '64300', label: 'TRIPLEFIN LLC (64300)' },
  { value: '80241', label: 'GROUP BENEFIT SERVICES (80241)' },
  { value: '75068', label: 'ASSURANT HEALTH SELF FUNDED (75068)' },
  { value: 'AH001', label: 'AVANTE HEALTH (AH001)' },
  { value: 'NCHON', label: 'NEW CENTURY HEALTHSOLUTIONS- VISTA RADIATION ONC (NCHON)' },
  { value: 'NCHON', label: 'NEW CENTURY HEALTH- SIMPLY HEALTH CARE ONCOLOGY (NCHON)' },
  { value: 'NCHON', label: 'NEW CENTURY HEALTH- ARIZONA INTEGRATED PHYSICIAN (NCHON)' },
  { value: 'NCHON', label: 'NEW CENTURY HEALTH- CIGNA MEDICAL GROUP AZ (NCHON)' },
  { value: 'NCHON', label: 'NEW CENTURY HEALTH- SIMPLY HEALTH CARE RADIATION (NCHON)' },
  { value: 'NCHON', label: 'NEW CENTURY HEALTH - VISTA ONCOLOGY (NCHON)' },
  { value: 'NCHON', label: 'NEW CENTURY HEALTH SOLUTIONS- VISTA ONCOLOGY (NCHON)' },
  { value: 'NCHON', label: 'NEW CENTURY HEALTH SOLUTIONS- CAREPLUS ONCOLGY (NCHON)' },
  { value: 'NCHON', label: 'NEW CENTURY HEALTH- HUMANA ONCOLOGY (NCHON)' },
  { value: 'NCHON', label: 'NEW CENTURY HEALTH SOLUTIONS- CAREPLUS RADIATION (NCHON)' },
  { value: 'NCHON', label: 'NEW CENTURY HEALTH- HUMANA RADIATION ONCOLOGY (NCHON)' },
  { value: 'NCHON', label: 'NEW CENTURY HEALTH ONCOLOGY (NCHON)' },
  { value: 'NCHON', label: 'NEW CENTURY HEALTH - RADIATION ONCOLOGY (NCHON)' },
  { value: 'BPA01', label: 'OPEIU LOCALS 30& 537 (BPA01)' },
  { value: 'BPA01', label: 'OPEIU LOCALS 30 AND 537 (BPA01)' },
  { value: 'BPA01', label: 'SOUTHERN CALIFORNIA DAIRY (BPA01)' },
  { value: 'BPA01', label: 'SOUTHERN CALIFORNIA LUMBER (BPA01)' },
  { value: 'BPA01', label: 'TCU-LA MTA (BPA01)' },
  { value: 'BPA01', label: 'OPERATING ENGINEERS LOCAL 501 (BPA01)' },
  { value: 'BPA01', label: 'LAUNDRY WORKERS (BPA01)' },
  { value: 'MHCM2', label: 'PHYSICIAN HEALTH NETWORK (MHCM2)' },
  { value: 'VCIPA', label: 'VALLEY CARE IPA (VCIPA)' },
  { value: 'VSIPA', label: 'VALLEY CARE SELECT IPA (VSIPA)' },
  { value: 'BIPAZ', label: 'BROOKSHIRE IPA (BIPAZ)' },
  { value: 'AIPAZ', label: 'ALAMITOS IPA COASTAL HEALTHCARE MANAGEMENT (AIPAZ)' },
  { value: 'LIPAZ', label: 'LAKEWOOD IPA COASTAL HEALTHCARE MANAGEMENT (LIPAZ)' },
  { value: 'PCACZ', label: 'PRIMARY CARE ASSOCIATES OF CALIFORNIA (PCACZ)' },
  { value: '99320', label: 'BENEFIT AND RISK MANAGEMENT SERVICES (99320)' },
  { value: '33632', label: 'CONCORDIA CARE INC. (33632)' },
  { value: '30891', label: 'SIERRA MEDICAL GROUP (30891)' },
  { value: 'MDXHI', label: 'MDX HAWAII QUEENS HEALTHCARE (MDXHI)' },
  { value: 'SLOS1', label: 'PHYSICIANS CHOICE GROUP OF SAN LUIS OBISPO (SLOS1)' },
  { value: 'MCI01', label: 'PHYSICIANS CHOICE MEDICAL GROUP OF SANTA MARIA (MCI01)' },
  { value: '79966', label: 'AMIDA CARE MEDICARE (79966)' },
  { value: '56756', label: 'LOWER KUSKOKWIM SCHOOL DISTRICT (56756)' },
  { value: 'CLFM1', label: 'CAMP LEJEUNE FAMILY MEMBER PROGRAM (CLFM1)' },
  { value: 'ECOMP', label: 'ENABLECOMP (ECOMP)' },
  { value: 'PHM11', label: 'SOUTHLAND SAN GABRIEL VALLEY MEDICAL GROUP (PHM11)' },
  { value: '59355', label: 'CHRISTIAN CARE MINISTRIES (59355)' },
  { value: '27905', label: 'COMMUNITY HEALTH ALLIANCE TENNESSEE (27905)' },
  { value: '29018', label: 'THE HEALTH PLAN OF WESTERN ILLINOIS (29018)' },
  { value: '42558', label: 'CEDAR VALLEY COMMUNITY HEALTHCARE (42558)' },
  { value: '40437', label: 'QUAD CITY COMMUNITY HEALTHCARE (40437)' },
  { value: '23861', label: 'EASTERN IOWA COMMUNITY HEALTHCARE (23861)' },
  { value: '78353', label: 'NORTH IOWA COMMUNITY HEALTHCARE (78353)' },
  { value: '52511', label: 'LAKES AREA COMMUNITY HEALTH PLAN (52511)' },
  { value: '57610', label: 'KUSPUK SCHOOL DISTRICT (57610)' },
  { value: '26158', label: 'ALLCARE PEBB (26158)' },
  { value: '88055', label: 'FIRST AGENCY (88055)' },
  { value: '88051', label: 'PREMIER HEALTHCARE EXCHANGE (PHX) (88051)' },
  { value: '96240', label: 'NEIGHBORHOOD HEALTH PLAN RI - EXCHANGE,UNITY,INTEG (96240)' },
  { value: '3699', label: 'SIEBA LTD (3699)' },
  { value: '84562', label: 'QUESTCARE MEDICAL SERVICES (84562)' },
  { value: 'DCHSF', label: 'DCHS MEDICAL FOUNDATION (DCHSF)' },
  { value: 'DCHSF', label: 'NORTHERN CAL ADVANTAGE MEDICAL GROUP (DCHSF)' },
  { value: 'DCHSF', label: 'SAN JOSE MEDICAL GROUP (DCHSF)' },
  { value: '7A-DHH-MEDICAID', label: 'LOUISIANA MEDICAID REHAB (7A-DHH-MEDICAID)' },
  { value: 'ARGUS', label: 'ARGUS DENTAL AND VISION INC. (ARGUS)' },
  { value: 'OPCAU', label: 'OPTICARE OF UTAH (OPCAU)' },
  { value: '75318', label: 'HMC HEALTHWORKS (75318)' },
  { value: '06603', label: 'AETNA ASA/GEHA (6603)' },
  { value: '72091', label: 'INSURANCE MANAGEMENT ADMINISTRATORS (72091)' },
  { value: '43056', label: 'CLAIMS DEVELOPMENT CORPORATION (43056)' },
  { value: '43056', label: 'DERMATOLOGY NETWORK SOLUTIONS (43056)' },
  { value: 'ICRCL', label: 'ICIRCLE CARE OF NEW YORK (ICRCL)' },
  { value: 'EBSRM', label: 'LIFETIME BENEFIT SOLUTIONS (EBSRM)' },
  { value: 'EBSRM', label: 'RMSCO, INC. (EBSRM)' },
  { value: '88090', label: 'MAYO CLINIC FLORIDA/GEORGIA (88090)' },
  { value: 'TLC79', label: 'TLC BENEFIT SOLUTIONS, INC. (TLC79)' },
  { value: 'HSH02', label: 'HEALTH NETWORK SOLUTIONS SELECT HEALTH SOUTH CAROL (HSH02)' },
  { value: 'HMC01', label: 'HEALTH NETWORK SOLUTIONS MEDCOST NORTH CAROLINA (HMC01)' },
  { value: 'HMC02', label: 'HEALTH NETWORK SOLUTIONS MEDCOST SOUTH CAROLINA (HMC02)' },
  { value: 'HSH01', label: 'HEALTH NETWORK SOLUTIONS SELECT HEALTH NORTH CAROL (HSH01)' },
  { value: 'HHS02', label: 'HEALTH NETWORK SOLUTIONS HEALTHSPRING SOUTH CAROLI (HHS02)' },
  { value: 'HHG02', label: 'HEALTH NETWORK SOLUTIONS HEALTHGRAM SOUTH CAROLINA (HHG02)' },
  { value: 'HCI01', label: 'HEALTH NETWORK SOLUTIONS CIGNA NORTH CAROLINA (HCI01)' },
  { value: 'HBC01', label: 'HEALTH NETWORK SOLUTIONS BCBS NORTH CAROLINA (HBC01)' },
  { value: 'HAT02', label: 'HEALTH NETWORK SOLUTIONS ABSOLUTE TOTAL CARE SC (HAT02)' },
  { value: 'HHS01', label: 'HEALTH NETWORK SOLUTIONS HEALTHSPRING NORTH CAROLI (HHS01)' },
  { value: 'HCI02', label: 'HEALTH NETWORK SOLUTIONS CIGNA SOUTH CAROLINA (HCI02)' },
  { value: '30360', label: 'INDIVIDUAL ASSURANCE COMPANY (30360)' },
  { value: '61453', label: 'KEMPER BENEFITS (61453)' },
  { value: '46407', label: 'UNIVERSITY HEALTH CARE ADVANTAGE (46407)' },
  { value: '80095', label: 'PROMINENCE HEALTH PLAN OF TEXAS (80095)' },
  { value: '660537624', label: 'FIRST MEDICAL HEALTH PLAN INC. (IMC) (660537624)' },
  { value: 'MCS04', label: 'CALIFORNIA DEPARTMENT OF CORRECTIONS (MCS04)' },
  { value: 'AMM15', label: 'COUNTY MEDICAL SERVICES PROGRAM (AMM15)' },
  { value: 'SCMG1', label: 'SHARP COMMUNITY MEDICAL GROUP (SCMG1)' },
  { value: 'SRS83', label: 'SHARP REES-STEALY MEDICAL GROUP (SRS83)' },
  { value: 'MMFUC', label: 'UC IRVINE HEALTH (MMFUC)' },
  { value: 'GHP660537624', label: 'FIRST MEDICAL HEALTH PLAN (REFORM) (GHP660537624)' },
  { value: 'CCHPC', label: 'ALIGNMENT HEALTH CARE (CCHPC)' },
  { value: 'AMM07', label: 'HAWAII SENIOR MEDICAL GROUP (AMM07)' },
  { value: 'AMM07', label: 'SEOUL MEDICAL GROUP (AMM07)' },
  { value: 'FCMG1', label: 'FIRSTCHOICE MEDICAL GROUP (FCMG1)' },
  { value: 'KHMSI', label: 'KEY HEALTH MEDICAL SOLUTIONS (KHMSI)' },
  { value: 'HSM01', label: 'HEALTHSMART MSO, INC. (HSM01)' },
  { value: 'HSM01', label: 'NOBLE COMMUNITY MED ASSOC INC OF MID-ORANGE CTY (HSM01)' },
  { value: 'HSM01', label: 'ORANGE COUNTY PHYSICIANS IPA MEDICAL GROUP, INC. (HSM01)' },
  { value: 'HSM01', label: 'NEW LIFE MEDICAL GROUP (HSM01)' },
  { value: 'HSM01', label: 'ASIAN COMMUNITY MEDICAL GROUP (HSM01)' },
  { value: 'HSM01', label: 'HEALTHY NEW LIFE (HSM01)' },
  { value: 'HSM01', label: 'ANGELES IPA (HSM01)' },
  { value: 'HSM01', label: 'REGENT MEDICAL GROUP (HSM01)' },
  { value: 'HSM01', label: 'NOBLE MID ORANGE COUNTY (HSM01)' },
  { value: 'HSM01', label: 'ORANGE COUNTY ADVANTAGE MEDICAL GROUP (HSM01)' },
  { value: 'HSM01', label: 'SAIGON MEDICAL GROUP (HSM01)' },
  { value: 'HSM01', label: 'ST. PETER MEDICAL GROUP (HSM01)' },
  { value: 'HSM01', label: 'ASSOCIATED DIGNITY MEDICAL GROUP PROFESSIONAL CORP (HSM01)' },
  { value: 'HSM01', label: 'ASSOCIATED PHYSICIANS MEDICAL GROUP (HSM01)' },
  { value: 'HSM01', label: 'KOREAN AMERICAN MEDICAL GROUP (HSM01)' },
  { value: 'HSM01', label: 'SOUTHLAND ADVANTAGE MEDICAL GROUP (HSM01)' },
  { value: 'HSM01', label: 'CALIFORNIA PACIFIC PHYSICIANS MEDICAL GROUP (HSM01)' },
  { value: 'HSM01', label: 'KARING PHYSICIANS MEDICAL GROUP (HSM01)' },
  { value: '55349', label: 'AMERICAS 1ST CHOICE OF SOUTH CAROLINA, INC. (55349)' },
  { value: '36400', label: 'OAK WEST PHYSICIAN ASSOCIATION (36400)' },
  { value: '441MP', label: 'MEDPARTNERS ADMINISTRATIVE SERVICES 441MP (441MP)' },
  { value: 'AC101', label: 'ALLCARE IPA (AC101)' },
  { value: '34009', label: 'MYNEXUS, INC. (34009)' },
  { value: 'SIHCA', label: 'SOUTHERN ILLINOIS HEALTH CARE ASSOCIATION (SIHCA)' },
  { value: '38343', label: 'INGHAM HEALTH PLAN (38343)' },
  { value: 'JLSFE', label: 'JL5 FAMILY ENTERPRISES DBA LEAGUE MEDICAL CONCEPTS (JLSFE)' },
  { value: '50653', label: 'OCCUPATIONAL EYEWEAR NETWORK, INC. (50653)' },
  { value: 'IP084', label: 'KAWEAH DELTA MEDICARE ADVANTAGE (IP084)' },
  { value: 'MPM25', label: 'PRUDENT MEDICAL GROUP (MPM25)' },
  { value: 'USMBP', label: 'UCLA MEDICAL GROUP (USMBP)' },
  { value: 'M5', label: 'AETNA BETTER HEALTH PREMIER PLAN - JVHL (M5)' },
  { value: 'CPN01', label: 'CHOICE PHYSICIANS NETWORK (CPN01)' },
  { value: 'AXM01', label: 'AXMINSTER MEDICAL GROUP (AXM01)' },
  { value: 'AHIPA', label: 'ACCOUNTABLE HEALTHCARE IPA (AHIPA)' },
  { value: 'VVMG1', label: 'HERITAGE VICTOR VALLEY MEDICAL GROUP (VVMG1)' },
  { value: 'IP055', label: 'CAL-NET PHYSICIANS IPA (IP055)' },
  { value: 'IP055', label: 'CITRUS VALLEY PHYSICIANS GROUP (IP055)' },
  { value: '66055', label: 'NETCARE LIFE AND HEALTH INSURANCE (66055)' },
  { value: '35245', label: 'FUTUREPLAN BY ASCENSUS (35245)' },
  { value: '86711', label: 'PACE SOUTHEAST MICHIGAN (86711)' },
  { value: 'MMS01', label: 'MISSOURI MEDICARE SELECT (MMS01)' },
  { value: 'PH001', label: 'PRUITTHEALTH PREMIER (PH001)' },
  { value: 'SA001', label: 'SIGNATURE ADVANTAGE (SA001)' },
  { value: 'ATHAL', label: 'HOLISTA LLC (ATHAL)' },
  { value: '47738', label: 'HAMASPIK CHOICE (47738)' },
  { value: '45048', label: 'INDEPENDENT LIVING SYSTEMS (45048)' },
  { value: '90753', label: 'LIBERTY HEALTHSHARE (90753)' },
  { value: 'LADOC', label: 'CORRECTCARE INTEGRATED HEALTH-LOUISIANA (LADOC)' },
  { value: '41201', label: 'CLIENT FIRST INC. (41201)' },
  { value: 'EV001', label: 'EBERLE VIVIAN (EV001)' },
  { value: '46187', label: 'SEASIDE HEALTH PLAN (46187)' },
  { value: 'TAYLR', label: 'TAYLOR BENEFIT RESOURCE (TAYLR)' },
  { value: '66001', label: 'BLUE CROSS BLUE SHIELD OF TEXAS MEDICAID (66001)' },
  { value: '32002', label: 'BLUE CROSS COMPLETE OF MICHIGAN (32002)' },
  { value: '00320', label: 'NORTH DAKOTA WORKFORCE SAFETY (320)' },
  { value: '00050', label: 'COLORADO HMO (50)' },
  { value: '56000522', label: 'RHODE ISLAND MEDICAID (56000522)' },
  { value: '16120', label: 'AMERICAS PPO ARAZ GROUP\t (16120)' },
  { value: 'ISL11', label: 'INSURANCE SERVICES OF LUBBOCK (ISL11)' },
  { value: '94999', label: 'FIRSTCARE CHIP (94999)' },
  { value: '94999', label: 'FIRSTCARE (94999)' },
  { value: '94999', label: 'VALLEY BAPTIST HEALTH PLAN (94999)' },
  { value: 'TBGNE', label: 'THE BENEFIT GROUP (TBGNE)' },
  { value: '95192', label: 'COMPCARE HEALTH SERVICES (95192)' },
  { value: '95192', label: 'ATRIUM HEALTH PLAN INC - MEDICAID HMO (95192)' },
  { value: '95192', label: 'GROUP HEALTH COOPERATIVE - MEDICAID HMO (95192)' },
  { value: '95192', label: 'GROUP HEALTH COOPERATIVE OF EAU CLAIRE (95192)' },
  { value: '95192', label: 'COMPCARE (WISCONSIN BADGERCARE ONLY) (95192)' },
  { value: '95192', label: 'GROUP HEALTH COOPERATIVE OF EAU CLAIRE (95192)' },
  { value: '87265', label: 'BENESIGHT (87265)' },
  { value: '24585', label: 'ACTIN CARE GROUPS (24585)' },
  { value: '54750', label: 'HURON VALLEY PACE (54750)' },
  { value: 'IICTX', label: 'IMPERIAL INSURANCE COMPANY OF TEXAS (IICTX)' },
  { value: 'AVA01', label: 'AVALON ADMINISTRATIVE SERVICES (AVA01)' },
  { value: 'AVA01', label: 'AVALON HEALTHCARE SOLUTIONS (AVA01)' },
  { value: '85578', label: 'INGALLS PROVIDER GROUP (85578)' },
  { value: '45282', label: 'UNIVERSITY OF MARYLAND HEALTH ADVANTAGE (45282)' },
  { value: 'POP01', label: 'CENTER IPA (POP01)' },
  { value: 'AWNY6', label: 'AGEWELL NEW YORK (AWNY6)' },
  { value: 'HCMG1', label: 'HEMET COMMUNITY MEDICAL GROUP (HCMG1)' },
  { value: 'HPFFS', label: 'HISPANIC PHYSICIANS IPA (FEE FOR SERVICE ONLY) (HPFFS)' },
  { value: '25924', label: 'LIFE LAWRENCE COUNTY (25924)' },
  { value: '25924', label: 'VIECARE LIFE BEAVER AND LIFE LAWRENCE COUNTIES (25924)' },
  { value: '25924', label: 'LIFE BEAVER COUNTY (25924)' },
  { value: '131595091', label: 'NEW YORK HOTEL FUND (131595091)' },
  { value: '58169', label: 'NORTHEAST GEORGIA HEALTH SERVICES (58169)' },
  { value: '66727', label: 'MIDWEST PHYSICIANS ADMINISTRATIVE SERVICES (MPAS) (66727)' },
  { value: '60230', label: 'MASON INDUSTRY TRUST ADMINISTRATION, INC. (60230)' },
  { value: '30005', label: 'GALVESTON COUNTY INDIGENT HEALTHCARE (30005)' },
  { value: 'SNT01', label: 'SANTE COMMUNITY PHYSICIANS (SNT01)' },
  { value: '95998', label: 'NEW AVENUES INC (95998)' },
  { value: '66003', label: 'HOPKINS HEALTH ADVANTAGE (66003)' },
  { value: 'QCI01', label: 'QUALCARE IPA (QCI01)' },
  { value: 'VCHCP', label: 'VENTURA COUNTY HEALTH CARE PLAN (VCHCP)' },
  { value: '99485', label: 'ON LOK SENIOR HEALTH SERVICES (99485)' },
  { value: '40137', label: 'KALOS HEALTH (40137)' },
  { value: '67668', label: 'BAPTIST HOSPITAL SOUTH FLORIDA INTERNATIONAL (67668)' },
  { value: '99588', label: 'ALLEGIANCE HEALTH MANAGEMENT (99588)' },
  { value: 'AMM11', label: 'MEDICHOICE IPA (AMM11)' },
  { value: '25059', label: 'FOREST COUNTY POTAWATOMI INSURANCE (25059)' },
  { value: 'DHFIE', label: 'DIGNITY HEALTH MEDICAL GROUP - INLAND EMPIRE (DHFIE)' },
  { value: '22344', label: 'EXCEEDENT LLC (22344)' },
  { value: 'VMF01', label: 'VERITY MEDICAL FOUNDATION (VMF01)' },
  { value: 'HT007632-001', label: 'MOTIVHEALTH INSURANCE COMPANY (HT007632-001)' },
  { value: 'HT006201-001', label: 'DIRECT CARE ADMINISTRATORS (HT006201-001)' },
  { value: 'C1048', label: 'NEW JERSEY MANUFACTURERS (NJM) AUTO (C1048)' },
  { value: '00930', label: 'RIVERSIDE UNIVERSITY HEALTH SYSTEM MEDICAL CENTER (930)' },
  { value: 'MPM32', label: 'ALPHACARE MEDICAL GROUP (MPM32)' },
  { value: 'PHPMSI', label: 'PHP TPA SERVICES (PHPMSI)' },
  { value: 'CHA01', label: 'CASCADE COMPREHENSIVE CARE (CHA01)' },
  { value: '67895', label: 'BANNER HEALTH AND AETNA HEALTH INSURANCE COMPANY (67895)' },
  { value: '81312', label: 'APOSTROPHE (81312)' },
  { value: 'MB', label: 'GENESEE COUNTY HEALTH PLAN - JVHL (MB)' },
  { value: '12122', label: 'NEW JERSEY MANUFACTURERS (NJM) WC (12122)' },
  { value: '65418', label: 'FIRSTNATION HEALTH (65418)' },
  { value: '25712', label: 'ESURANCE INSURACE COMPANY (25712)' },
  { value: 'L0140', label: 'OCULAR BENEFITS (L0140)' },
  { value: 'CHP02', label: 'COLUMBINE HEALTH PLAN (CHP02)' },
  { value: 'UHNDC', label: 'PPOONE (UHNDC)' },
  { value: 'ACCOM ', label: 'ACCESS COMMUNITY HEALTH NETWORK (ACCOM )' },
  { value: '8354', label: 'NX HEALTH NETWORK (8354)' },
  { value: '89237', label: 'PLEXIS HEALTHCARE SYSTEM (89237)' },
  { value: '54398', label: 'ALLINA HEALTH AND AETNA INSURANCE COMPANY (54398)' },
  { value: '60624', label: 'SUTTER AND AETNA INSURANCE COMPANY (60624)' },
  { value: '71066', label: 'KANSAS HEALTH ADVANTAGE (71066)' },
  { value: 'EPF37', label: 'EL PASO FIRST HEALTH PLANS - HEALTH CARE OPTIONS (EPF37)' },
  { value: 'HSMBS', label: 'IOWA SCREENING PROGRAMS (HSMBS)' },
  { value: '38416', label: 'SPENCER STUART (ARM LTD) (38416)' },
  { value: '86753', label: 'FLORIDA HEALTH ADMINISTRATORS, INC. (86753)' },
  { value: '86753', label: 'FHA TPA (86753)' },
  { value: '86753', label: 'THE ASSISTANCE FUND (86753)' },
  { value: 'PCU02', label: 'POINT COMFORT UNDERWRITERS (REFUGEE MED ASST PROG) (PCU02)' },
  { value: 'MPM44', label: 'ASSOCIATED HISPANIC PHYSICIANS IPA (MPM44)' },
  { value: 'ADSL1', label: 'ALLIANCE ALPHA GOLD CARE (ADSL1)' },
  { value: 'ADSL1', label: 'TEAM CHOICE - ALPHA CARE GOLD (ADSL1)' },
  { value: 'PROH1', label: 'DIGNITY HEALTH - MERCY AND WOODLAND MEDICAL GROUP (PROH1)' },
  { value: 'MPM28', label: 'DIGNITY HCLA (MPM28)' },
  { value: '06941', label: 'BAY BRIDGE ADMINISTRATORS LLC (6941)' },
  { value: 'AGL02', label: 'COPC SENIOR CARE ADVANTAGE (AGL02)' },
  { value: 'MPM35', label: 'CROWN CITY MEDICAL GROUP (CCMG) (MPM35)' },
  { value: 'TMMC1', label: 'TORRANCE MEMORIAL MEDICAL CENTER (TMMC1)' },
  { value: 'AMM19', label: 'HOLLYWOOD PRESBYTERIAN MEDICAL CNTR-ST VINCENT IPA (AMM19)' },
  { value: 'GPNE1', label: 'GREAT PLAINS MEDICARE ADVANTAGE OF NEBRASKA (GPNE1)' },
  { value: 'MPM27', label: 'CALMED GLOBAL (MPM27)' },
  { value: '20538', label: 'TRI-VALLEY MEDICAL GROUP (20538)' },
  { value: 'UMHCJ', label: 'UMASS MEDICAL SCHOOL HLTH - CRIMINAL JUSTICE PROG (UMHCJ)' },
  { value: '560894904', label: 'BLUE MEDICARE PPO/HMP OF NORTH CAROLINA (560894904)' },
  { value: 'SIM01', label: 'SIMPRA ADVANTAGE INC. (SIM01)' },
  { value: '87765', label: 'INTERACTIVE MEDICAL SYSTEMS (87765)' },
  { value: '56155', label: 'INTERACTIVE MEDICAL SYSTEMS TRIALCARD (56155)' },
  { value: 'VB001', label: 'FLORIDA HOSPITAL VBR (VB001)' },
  { value: 'HLPHN', label: 'HILL PHYSICIANS HEALTH NET PPO (HLPHN)' },
  { value: 'HLPUH', label: 'HILL PHYSICIANS UNITED HEALTHCARE PPO (HLPUH)' },
  { value: 'SNY01', label: 'SUNRISE HEALTH PLAN OF NEW YORK (SNY01)' },
  { value: 'VAPRM', label: 'VIRGINIA PREMIER ELITE PLUS (MLTSS) (VAPRM)' },
  { value: 'VAPRM', label: 'VIRGINIA PREMIER ELITE (VAPRM)' },
  { value: 'VAPRM', label: 'VIRGINIA PREMIER MEDICARE ADVANTAGE (MAPD) (VAPRM)' },
  { value: 'VAPRM', label: 'VIRGINIA PREMIER MEDALLION 4 (VAPRM)' },
  { value: 'VAPRM', label: 'VIRGINIA PREMIER HEALTH PLANS\t (VAPRM)' },
  { value: 'VAPRM', label: 'VIRGINIA PREMIER MEDALLION 3 (VAPRM)' },
  { value: 'VAPRM', label: 'VIRGINIA PREMIER COMPLETE CARE (VAPRM)' },
  { value: 'MPM39', label: 'ALVARADO IPA (MPM39)' },
  { value: 'SIL01', label: 'SUNRISE HEALTH PLAN OF ILLINOIS (SIL01)' },
  { value: 'TRP1E', label: 'TRANSAMERICA LIFE INSURANCE COMPANY IA, MD, PA (TRP1E)' },
  { value: '11346', label: 'CARECENTRIX CIGNA (11346)' },
  { value: '11348', label: 'CARECENTRIX HORIZON (11348)' },
  { value: '47394', label: 'COASTAL CARE SERVICES INC. (47394)' },
  { value: 'CX101', label: 'CARPENTERS HEALTH AND WELFARE FUND OF PHILADELPHIA (CX101)' },
  { value: '33373', label: 'MOLINA HEALTHCARE OF CALIFORNIA (ENCOUNTERS ONLY) (33373)' },
  { value: '95937', label: 'WPS OPTUM HEALTH (95937)' },
  { value: 'HLPBS', label: 'HILL PHYSICIANS BLUE SHIELD PPO (HLPBS)' },
  { value: 'NEMSS', label: 'NORTHEAST MEDICAL SERVICES (NEMS) (NEMSS)' },
  { value: 'SPA01', label: 'SUNRISE ADVANTAGE PLAN (PA) (SPA01)' },
  { value: '88250', label: 'HEALTHTEAM ADVANTAGE (88250)' },
  { value: 'LCM10', label: 'LITTLE COMPANY OF MARY HEALTH PROVIDERS (LCM10)' },
  { value: '55544', label: 'CONVERSION PLAN - APWU (55544)' },
  { value: '00995', label: 'HEALTH SAFETY NET (995)' },
  { value: '36397', label: 'TRELLIS HEALTH PARTNERS (36397)' },
  { value: '31125', label: 'TRIBUTE HEALTH PLAN OF OKLAHOMA (31125)' },
  { value: '86600', label: 'COMPREHENSIVE MEDICAL AND DENTAL PROGRAM (86600)' },
  { value: '98543', label: 'WYOMING PACE (98543)' },
  { value: 'NWOOD', label: 'NORTHWOOD (NWOOD)' },
  { value: 'COCHA', label: 'COLORADO COMMUNITY HEALTH ALLIANCE (COCHA)' },
  { value: '71498', label: 'LIFECIRCLES PACE (71498)' },
  { value: '81600', label: 'SOUTH COUNTRY HEALTH ALLIANCE (81600)' },
  { value: '85664', label: 'GMR HEALTH (85664)' },
  { value: '93658', label: 'ALLIANCE COAL HEALTH PLAN (93658)' },
  { value: 'UC001', label: 'BRAND NEW DAY (UC001)' },
  { value: '20572', label: 'MSA CAREGUARD (20572)' },
  { value: 'OSFC9', label: 'OSF HEALTHCARE CENTRAL (OSFC9)' },
  { value: '37050', label: 'MENTAL HEALTH CONSULTANTS INC. (37050)' },
  { value: 'HBI01', label: 'HUMAN BEHAVIOR INSTITUTE  (HBI01)' },
  { value: '77010', label: 'MOLINA HEALTHCARE OF  MISSISSIPPI (77010)' },
  { value: '251VA', label: 'VIRGINIA PREMIER INDIVIDUAL PLANS (251VA)' },
  { value: '54823', label: 'CALCARE IPA (54823)' },
  { value: '54823', label: 'SEQUOIA HEALTH IPA (54823)' },
  { value: '54823', label: 'LOS ANGELES MEDICAL CENTER (LAMC) (54823)' },
  { value: 'HLTHT', label: 'HEALTH TRADITION HEALTH PLAN (HLTHT)' },
  { value: 'MPM19', label: 'LACH HEALTHNET BY MEDPOINT (MPM19)' },
  { value: '60818', label: 'FENIX MEDICAL GROUP (60818)' },
  { value: 'MPM30', label: 'ST. FRANCIS - HCLA BY MEDPOINT (MPM30)' },
  { value: 'MPM20', label: 'SOUTHERN CA HEALTHCARE SYSTEM ALTA POD BY MEDPOINT (MPM20)' },
  { value: 'MPM33', label: 'ADVENTIST WHITE MEMORIAL-CROWN CITY MEDICAL GROUP (MPM33)' },
  { value: '42636', label: 'VILLAGEMD OF GEORGIA (42636)' },
  { value: '77946', label: 'YAMHILL COUNTY CCO (PHYSICAL HEALTH) (77946)' },
  { value: 'TCMAP', label: 'MEDIVIEW TRAVIS COUNTY MAP (TCMAP)' },
  { value: '61184', label: 'SUPERIOR SELECT HEALTH PLAN (61184)' },
  { value: 'E3287', label: 'OPTUMCARE NETWORK OF CONNECTICUT (E3287)' },
  { value: 'ABLPY', label: 'ABLEPAY HEALTH (ABLPY)' },
  { value: '44423', label: 'ALEXIAN BROTHERS COMMUNITY SERVICES OF TENNESSEE (44423)' },
  { value: '88008', label: 'UFCW LOCAL 880 MEDICARE (88008)' },
  { value: '03964', label: 'ELDERWOOD HEALTH (3964)' },
  { value: 'OSFE9', label: 'OSF HEALTHCARE EAST (OSFE9)' },
  { value: 'MV440', label: 'IDEALCARE (MV440)' },
  { value: 'GPSD1', label: 'GREAT PLAINS MEDICARE ADVANTAGE OF SOUTH DAKOTA (GPSD1)' },
  { value: 'GPND1', label: 'GREAT PLAINS MEDICARE ADVANTAGE OF NORTH DAKOTA (GPND1)' },
  { value: 'TRTLC', label: 'TRANSAMERICA LIFE INSURANCE COMPANY - PLANO AND HU (TRTLC)' },
  { value: 'SH777', label: 'SOLIDARITY HEALTHSHARE (SH777)' },
  { value: '60221', label: 'EMPLOYEE BENEFIT SERVICES, INC. (EBSI) (60221)' },
  { value: 'A5143', label: 'MINNESOTA BLUE CROSS BLUE SHIELD HEALTH CARE PROGR (A5143)' },
  { value: 'PROGY', label: 'PROGYNY (PROGY)' },
  { value: 'INTEG', label: 'ACCESS INTEGRA (INTEG)' },
  { value: 'CB951', label: 'GROUP BENEFIT SERVICE (CB951)' },
  { value: 'UTMBC', label: 'UTMB CORRECTIONAL MANAGED CARE (UTMBC)' },
  { value: '40043', label: 'FIRE DEPARTMENT CITY OF NEW YORK (40043)' },
  { value: 'MCC01', label: 'MAGELLAN COMPLETE CARE OF ARIZONA (MCC01)' },
  { value: 'PAI01', label: 'POOL ADMINISTRATORS, INC. (PAI01)' },
  { value: 'FLCCR', label: 'FLORIDA COMMUNITY CARE (FLCCR)' },
  { value: '11789', label: 'SGIC (11789)' },
  { value: 'PAI02', label: 'POOL ADMIN INC (PAI) - CDPH SECONDARY CLAIMS (PAI02)' },
  { value: '43259', label: 'VALOR HEALTH PLAN (43259)' },
  { value: '99284', label: 'GREATER COVINA MEDICAL GROUP (99284)' },
  { value: 'DRHCP', label: 'DOCTORS HEALTHCARE PLANS  (DRHCP)' },
  { value: 'TRAN1', label: 'TRANSWESTERN INSURANCE ADMINISTRATORS, INC (TRAN1)' },
  { value: '39640', label: 'GREAT LAKES PACE (39640)' },
  { value: 'MPM31', label: 'ST FRANCIS - ALLCARE BY MEDPOINT  (MPM31)' },
  { value: '66010', label: 'CARE N CARE INSURANCE CO (66010)' },
  { value: 'PBHD1', label: 'COMMUNITY CARE PLAN PALM BEACH HEALTH DISTRICT (PBHD1)' },
  { value: '45564', label: 'CENTIVO  (45564)' },
  { value: '47262', label: 'IU HEALTH TRANSPLANT (47262)' },
  { value: '13079', label: 'STONES RIVER IPA CLOVER HEALTH (13079)' },
  { value: 'ALTAM', label: 'ALTAMED (ALTAM)' },
  { value: '10550', label: 'LASSO HEALTHCARE (10550)' },
  { value: '72187', label: 'VARIPRO (72187)' },
  { value: '58379', label: 'P3 HEALTH PARTNERS NEVADA (58379)' },
  { value: '58375', label: 'P3 HEALTH PARTNERS ARIZONA (58375)' },
  { value: '45979', label: 'MIDWEST OPERATING ENGINEERS FRINGE BENEFIT FUNDS (45979)' },
  { value: '68756', label: 'BAY BRIDGE ADMINISTRATORS PROTECTOR 360 (68756)' },
  { value: '19450', label: 'HOTSTART (19450)' },
  { value: '38260', label: 'UNITED BENEFIT ADVISORS (38260)' },
  { value: '50114', label: 'SANA BENEFITS  (50114)' },
  { value: 'UC002', label: 'BRAND NEW DAY ENCOUNTERS (UC002)' },
  { value: 'OPNC1', label: 'ONCOLOGY PHYSICIANS NETWORK (OPNC1)' },
  { value: 'MJ', label: 'BCN CRITICAL ACCESS SMALL VOLUME - JVHL (MJ)' },
  { value: '84365', label: 'USC COEUR: LEONARD HOLDING COMPANY (84365)' },
  { value: '73581', label: 'SOLIS HEALTH PLANS (73581)' },
  { value: 'MPM29', label: 'HOLLYWOOD PRESBYTERIAN MEDICAL CENTER (MPM29)' },
  { value: '16307', label: 'CAREPARTNERS OF CONNECTICUT (16307)' },
  { value: '43036', label: 'VILLAGEMD OF WESTERN KENTUCKY (43036)' },
  { value: 'MPM46', label: 'EMANATE HEALTH MED CENTER NMM (MPM46)' },
  { value: 'MPM47', label: 'EMANATE HEALTH MED CENTER PDT MSO (MPM47)' },
  { value: 'SJHC1', label: 'SAINT JOHNS HEALTH CLINIC (SJHC1)' },
  { value: '60801', label: 'AMERICAN PUBLIC LIFE (APL) (60801)' },
  { value: '82435', label: 'DIALYSIS TPA (82435)' },
  { value: 'MPM45', label: 'EMANATE HEALTH MED CENTER ALTAMED (MPM45)' },
  { value: 'PHPC1', label: 'PRUITTHEALTH PREMIER NC/SC (PHPC1)' },
  { value: 'L0200', label: 'HUMANA PUERTO RICO  (L0200)' },
  { value: '83413', label: 'TRU BLUE TPA  (83413)' },
  { value: '77124', label: 'DELANO IPA  (77124)' },
  { value: '46891', label: 'UCS BENEFIT PLAN ADMINISTRATORS OF EAU CLAIRE (46891)' },
  { value: '27133', label: 'DIGNITY HEALTH MEDICAL NETWORK - CENTRAL CALIFORNI (27133)' },
  { value: '27133', label: 'GEMCARE IPA  (27133)' },
  { value: '27133', label: 'MANAGED CARE SYSTEMS (27133)' },
  { value: '61185', label: 'KALOS GOLD HEALTH PLAN (61185)' },
  { value: '64556', label: 'IMA, INC. (64556)' },
  { value: '28680', label: 'PHCS MULTIPLAN - GROUP RESOURCES (28680)' },
  { value: '89245', label: 'UCS BASI: TENOWO INC. (89245)' },
  { value: '44412', label: 'ROBERT F. KENNEDY MEDICAL PLAN (44412)' },
  { value: 'Z1412', label: 'DMENSION BENEFIT MANAGEMENT (Z1412)' },
  { value: 'ROJW1', label: "RELIGIOUS ORDER OF JEHOVAH'S WITNESSES (ROJW1)" },
  { value: 'AVA03', label: 'AVALON - CAPITAL BLUE CROSS (AVA03)' },
  { value: '80900', label: 'TOTAL PLAN CONCEPTS (80900)' },
  { value: 'JHHCG', label: 'JOHNS HOPKINS HOME CARE GROUP (JHHCG)' },
  { value: '617591011CMSP', label: 'TEXAS MEDICAID LTC (617591011CMSP)' },
  { value: 'CTPL1', label: 'CALIFORNIA IPA (CTPL1)' },
  { value: '17929', label: 'HPH HOSPICE (17929)' },
  { value: 'HPU22', label: 'HILL UHC PPO (HPU22)' },
  { value: 'FCC20', label: 'FALLING COLORS (BHSD STAR)  (FCC20)' },
  { value: '85016', label: 'INTEGRATED EYE CARE NETWORK (85016)' },
  { value: 'HLPBC', label: 'HILL PHYSICIANS BLUE CROSS PPO (HLPBC)' },
  { value: '43174', label: 'MOLINA HEALTHCARE OF WASHINGTON (ENCOUNTERS ONLY) (43174)' },
  { value: 'HT008053-001', label: 'SAMERA HEALTH (HT008053-001)' },
  { value: '81810', label: 'INDUSTRY BUYING GROUP (IBG) (81810)' },
  { value: '2020E', label: '2020 EYECARE (2020E)' },
  { value: 'HLSTA', label: 'NOVANT HEALTH DIRECT-TO-EMPLOYER EPISODES (HLSTA)' },
  { value: 'VB002', label: 'CERNER BARIATRIC PROGRAM (VB002)' },
  { value: 'OMNIA', label: 'LEADING EDGE ADMINISTRATORS (OMNIA)' },
  { value: '76253', label: 'UCS BENVEO (76253)' },
  { value: 'L0110', label: 'NAMCI/GLOBAL CARE (L0110)' },
  { value: '31400', label: 'PROVIDER PARTNERS HEALTH PLAN OF PENNSYLVANIA (31400)' },
  { value: 'AMM18', label: 'HOLLYWOOD PRESBYTERIAN MEDICAL CENTER - PREFERRED  (AMM18)' },
  { value: 'MPM42', label: 'BEVERLY HOSPITAL BEVAHISP (MPM42)' },
  { value: '85502', label: 'ISLAND HOME INSURANCE COMPANY (85502)' },
  { value: 'MPM48', label: 'COMMUNITY CARE IPA (MPM48)' },
  { value: 'BHPP1', label: 'COMMUNITY CARE PLAN (BROWARD HEALTH PPUC) (BHPP1)' },
  { value: 'BHP01', label: 'PIH HEALTH (BHP01)' },
  { value: '34924', label: 'UCS CEMENT MASONS (34924)' },
  { value: 'CHERO', label: 'CHEROKEE NATION COMPREHENSIVE CARE AGENCY (CHERO)' },
  { value: 'HOSH1', label: 'DIGNITY HEALTH SACRAMENTO HOSPITAL (HOSH1)' },
  { value: '77032', label: 'MISSISSPPI MEDICAID MEDICARE ADVANTAGE CROSSOVERS (77032)' },
  { value: '65432', label: 'BEACON OF LIFE (65432)' },
  { value: '84566', label: 'UCS BENEFIT MANAGEMENT ADMINISTRATORS (84566)' },
  { value: 'GLOBAL1', label: 'GLOBAL ONE VENTURES, LLC (GLOBAL1)' },
  { value: '86242', label: 'UCS SECUREONE PREFERRED UNITED PLANS (86242)' },
  { value: '75545', label: 'VILLAGEMD OF NEW HAMPSHIRE (75545)' },
  { value: 'TCC93', label: 'TCC BENEFITS ADMINISTRATOR (TCC93)' },
  { value: 'MPM37', label: 'ADVENTIST HEALTH PLAN (MPM37)' },
  { value: 'HSICS', label: 'HSICS (HSICS)' },
  { value: '75838', label: 'VILLAGEMD OF CHICAGO (75838)' },
  { value: 'GRAVI', label: 'GRAVIE ADMINISTRATIVE SERVICES (GRAVI)' },
  { value: 'NMM04', label: 'ALPHA CARE MEDICAL GROUP NMM (NMM04)' },
  { value: '93170', label: 'VILLAGEMD OF HOUSTON (93170)' },
  { value: '75386', label: 'VILLAGEMD OF NORTHERN INDIANA (75386)' },
  { value: '10956', label: 'QUEST EAP (10956)' },
  { value: '96400', label: 'SAN DIEGO PACE (96400)' },
  { value: '66701', label: 'GROUP MARKETING SERVICES (66701)' },
  { value: 'PARTH', label: 'PARTNERS IN HEALTH (PARTH)' },
  { value: 'BBS19', label: 'BRITTON BENEFIT SERVICES LLC  (BBS19)' },
  { value: 'SHM01', label: 'SAUDI HEALTH MISSION (SHM01)' },
  { value: 'VACCN', label: 'VETERANS AFFAIRS COMMUNITY CARE NETWORK RGN 1 2 3 (VACCN)' },
  { value: '70454', label: 'PACE CNY (70454)' },
  { value: 'AVA04', label: 'AVALON ADMINISTRATIVE SERVICES - BCBSVT (AVA04)' },
  { value: 'LNY01', label: 'LONGEVITY HEALTH PLAN OF NEW YORK (LNY01)' },
  { value: '96436', label: 'UCS INSIGHT BENEFIT ADMINISTRATORS (96436)' },
  { value: '83867', label: 'QUAL-LYNX (83867)' },
  { value: 'EGPIN', label: 'ENTERPRISE GROUP PLANNING (EGPIN)' },
  { value: 'TU127', label: 'VITALITY HEALTH PLAN OF CALIFORNIA (TU127)' },
  { value: 'GMGSA', label: 'GONZABA MEDICAL GROUP (GMGSA)' },
  { value: 'MPM38', label: 'AHP PROVIDER NETWORK (MPM38)' },
  { value: 'LFL01', label: 'LONGEVITY HEALTH PLAN OF FLORIDA (LFL01)' },
  { value: '65456', label: 'DENVER HEALTH MEDICAL PLAN, INC (65456)' },
  { value: 'LIL01', label: 'LONGEVITY HEALTH PLAN OF ILLINOIS  (LIL01)' },
  { value: 'CAS89', label: 'COASTAL ADMINISTRATIVE SERVICES (CAS89)' },
  { value: 'FH205', label: 'FLUME HEALTH, INC. (FH205)' },
  { value: 'CB531', label: 'PREZZO (CB531)' },
  { value: 'EHPSC', label: 'ESSENTIAL HEALTH PARTNERS (EHPSC)' },
  { value: '95606', label: 'AMERICAN PLAN ADMINISTRATORS  (95606)' },
  { value: '85824', label: 'TRUE HEALTH NEW MEXICO (SS&C) (85824)' },
  { value: '451NM', label: 'NEW MEXICO HEALTH CONNECTIONS DOS AFTER 12/31/19 (451NM)' },
  { value: '53483', label: 'PAINTERS UNION INSURANCE FUND (53483)' },
  { value: 'VALHLTH', label: 'PENNSYLVANIA HEALTH CARE PLAN (VALHLTH)' },
  { value: '96277', label: 'OPTIMED HEALTH  (96277)' },
  { value: '63985', label: 'UCS CAM ADMINISTRATIVE SERVICES, INC (63985)' },
  { value: '4273', label: 'HALIFAX CHARITY CLAIMS (4273)' },
  { value: '42049', label: 'COFINITY - GROUP RESOURCES (42049)' },
  { value: '12268', label: 'TOTAL SENIOR CARE (12268)' },
  { value: '25181', label: 'LIFE PITTSBURGH (25181)' },
  { value: 'SCOA1', label: 'SOUTHERN CALIFORNIA ONCOLOGY ASSOCIATES (SCOA) (SCOA1)' },
  { value: 'NMM05', label: 'COMMUNITY FAMILY CARE (NMM05)' },
  { value: 'HPPZZ', label: 'HOAG (HPPZZ)' },
  { value: '38232', label: 'WEYCO, INC. (38232)' },
  { value: '36342', label: 'INSURANCE PROGRAM MANAGERS GROUP (36342)' },
  { value: '31401', label: 'PROVIDER PARTNERS HEALTH PLAN OF ILLINOIS (31401)' },
  { value: 'CB122', label: 'SALVASEN HEALTH (CB122)' },
  { value: 'CB159', label: 'ADVISORY HEALTH ADMINISTRATORS (CB159)' },
  { value: '34159', label: 'NORTH AMERICAN BENEFITS NETWORK (CLEVELAND, OH) (34159)' },
  { value: '34159', label: 'E-V BENEFITS MANAGEMENT, INC. (COLUMBUS, OH) (34159)' },
  { value: '34159', label: 'NABN (CLEVELAND, OH) (34159)' },
  { value: '59140', label: 'CULINARY HEALTH & WELFARE FUND (LAS VEGAS, NV) (59140)' },
  { value: '59140', label: 'HEALTH PLAN SERVICES (TAMPA ONLY) (59140)' },
  { value: '59140', label: 'CONSOLIDATED GROUP (59140)' },
  { value: '59140', label: 'CULINARY HEALTH FUND (59140)' },
  { value: '59140', label: 'AMERICAN BENEFITS PLAN ADMINISTRATORS (LAS VEGAS,  (59140)' },
  { value: 'RHP01', label: 'RELIANCE MEDICARE ADVANTAGE (RHP01)' },
  { value: '660636242PSG', label: 'PLAN DE SALUD MENONITA VITAL (660636242PSG)' },
  { value: 'CB369', label: 'BARINET (CB369)' },
  { value: '16755', label: 'INTEGRATED CARE NETWORK (ICN) BY EMERALD HEALTH (16755)' },
  { value: '16755', label: 'HEALTH SERVICES PREFERRED (HSP) BY EMERALD HEALTH (16755)' },
  { value: '16755', label: 'EMERALD HEALTH NETWORK, INC. (ALL PPO BUSINESS) (16755)' },
  { value: 'LOK01', label: 'LONGEVITY HEALTH PLAN OF OKLAHOMA (LOK01)' },
  { value: 'ASMI1', label: 'ALIGN SENIOR CARE OF MICHIGAN (ASMI1)' },
  { value: 'L0241', label: 'ENABLECOMPMVA (L0241)' },
  { value: 'L0242', label: 'ENABLECOMPVET (L0242)' },
  { value: 'ASVA1', label: 'ALIGN SENIOR CARE OF VIRGINIA (ASVA1)' },
  { value: 'LNJ01', label: 'LONGEVITY HEALTH PLAN - NEW JERSEY (LNJ01)' },
  { value: 'KCMD1', label: 'KEYCARE (KCMD1)' },
  { value: 'PTX01', label: 'PROCARE ADVANTAGE OF TX (PTX01)' },
  { value: '4YC01', label: '4 YOUR CHOICE (4YC01)' },
  { value: '31403', label: 'TEXAS INDEPENDENCE HEALTH PLAN, INC (31403)' },
  { value: 'FHKC1', label: 'COMMUNITY CARE PLAN (FHKC1)' },
  { value: 'NWCHP', label: 'NORTHWEST COMMUNITY HEALTH PARTNERS (NWCHP)' },
  { value: '37118', label: 'BENEFIT PLAN ADMINISTRATORS, INC. (37118)' },
  { value: '73331', label: 'HPMC - SAN JUDAS IPA (73331)' },
  { value: 'TRP1P', label: 'TRANSAMERICA LIFE INSURANCE COMPANY W PLANO PRKWY (TRP1P)' },
  { value: 'VLGMD', label: 'VILLAGE MD (VLGMD)' },
  { value: 'MSUPP', label: 'AETNA SSI MEOB (MSUPP)' },
  { value: 'AGL03', label: 'CONNECTED SENIOR CARE ADVANTAGE (AGL03)' },
  { value: 'VAEXC', label: 'AETNA SSI VA EXCLUSIONS (VAEXC)' },
  { value: 'CB695', label: 'CPR SHARE PLANS (CB695)' },
  { value: '81508', label: 'SOMOS ANTHEM (81508)' },
  { value: 'EIPA9', label: 'ESSENTIAL HEALTH PARTNERS IPA (EIPA9)' },
  { value: '89789', label: 'UCHEALTH PLAN ADMINISTRATORS (89789)' },
  { value: 'HCHHP', label: 'HEALTHCARE HIGHWAYS HEALTH PLAN (HCHHP)' },
  { value: 'C1037', label: 'ALLSTATE - EXCEPT NEW JERSEY (C1037)' },
  { value: '66698', label: 'CENTRACARE (66698)' },
  { value: '13054', label: 'HEALTH CHOICE GENERATIONS UTAH (13054)' },
  { value: '62480', label: 'KEMBERTON HEALTHCARE SERVICES (62480)' },
  { value: 'GSMHP', label: 'GOLDEN STATE MEDICARE HEALTH PLAN (GSMHP)' },
  { value: 'AMG02', label: 'ACCESS MEDICAL GROUP (AMG02)' },
  { value: 'BPSLLC', label: 'VANDERBILT HEALTH (BPSLLC)' },
  { value: '20446', label: '6 DEGREES HEALTH (20446)' },
  { value: 'HSMSO', label: 'HEALTH SOURCE MSO (HSMSO)' },
  { value: 'BLRDE', label: 'MN BCBS HEALTH PROGRAMS NON EMERGENT TRANSPORTATIO (BLRDE)' },
  { value: '97661', label: 'IEC GROUP AMERIBEN (97661)' },
  { value: 'OAKST', label: 'OAK STREET HEALTH (OAKST)' },
  { value: '68037', label: 'MEDCORE OMNI IPA (68037)' },
  { value: '83173', label: 'UNITE HEALTH SHARE MINISTRIES (UHSM) (83173)' },
  { value: 'MPM43', label: 'GOLDEN PHYSICIANS MEDICAL GROUP  (MPM43)' },
  { value: 'GSH01', label: 'GOOD SAMARITAN HOSPITAL (GSH01)' },
  { value: '78535', label: 'POKAGON HEALTH SERVICE (78535)' },
  { value: '78702', label: 'SEMINOLE TRIBE OF FLORIDA  (78702)' },
  { value: '23283', label: 'PITTSBURGH CARE PARTNERSHIP, INC. (23283)' },
  { value: '88350', label: 'HEALTHTEAM ADVANTAGE DIABETES AND HEART CARE PLAN (88350)' },
  { value: '660202379', label: 'PLAN DE BIENESTAR UTM-PRSSA (660202379)' },
  { value: '57604', label: 'AETNA AFFORDABLE CHOICES (SM) - SRC (57604)' },
  { value: '99999-0748', label: 'ACCELERATED CLAIMS, INC. (99999-0748)' },
  { value: 'TRIN1', label: 'TRINITY HEALTHSHARE (TRIN1)' },
  { value: 'ONOCI', label: 'ONCOLOGY NETWORK OF ORANGE COUNTY (ONOCI)' },
  { value: 'ATNET', label: 'ALIVI THERAPY NETWORK (ATNET)' },
  { value: 'ALIVI', label: 'ALIVI HEALTH (ALIVI)' },
  { value: 'SM325', label: 'SAMARITAN GIVEN  (SM325)' },
  { value: '95964', label: 'COVID19 HRSA UNINSURED TESTING AND TREATMENT FUND (95964)' },
  { value: '30103', label: 'HEALTH POINTE OF NEW YORK (30103)' },
  { value: '11370', label: 'A G ADMINISTRATORS LLC (11370)' },
  { value: 'IH400', label: 'IMPACT HEALTH SHARE INC (IH400)' },
  { value: '75296', label: 'THE ZERO CARD LLC (75296)' },
  { value: '83247', label: 'DIGNITY HEALTH PLAN (83247)' },
  { value: '59314', label: 'ADVANZEON SOLUTIONS (59314)' },
  { value: 'CXARC', label: 'ARC ADMINISTRATORS (CXARC)' },
  { value: '95352', label: 'LOMA LINDA UNIVERSITY MEDICAL CENTER (LLUMC) (95352)' },
  { value: 'MM', label: 'WELLCARE - JVHL (MM)' },
  { value: 'MK', label: 'RELIANCE HMO - JVHL (MK)' },
  { value: '56724', label: 'PROFESSIONAL BENEFIT SERVICES, INC. (56724)' },
  { value: 'USN01', label: 'U.S. NETWORKS AND ADMINISTRATIVE SERVICES (USN01)' },
  { value: '68069', label: 'YOUTHCARE HEALTHCHOICE ILLINOIS (68069)' },
  { value: 'GSHTX', label: 'GREAT STATES HEALTH ADMINISTRATORS (IICT) (GSHTX)' },
  { value: '11347', label: 'CARECENTRIX FLORIDA BLUE (11347)' },
  { value: 'NCHUR', label: 'NEW CENTURY HEALTH CAREPLUS UROLOGY (NCHUR)' },
  { value: '22287', label: 'COLONIAL MEDICAL EASTERN CARIBBEAN (22287)' },
  { value: '37320', label: 'CREATIVE PLAN ADMINISTRATORS (37320)' },
  { value: 'SBMCO', label: 'STAFF BENEFITS MANAGEMENT & ADMINISTRATORS (SBMA) (SBMCO)' },
  { value: 'PCU03', label: 'POINT COMFORT UNDERWRITERS (PCU03) (PCU03)' },
  { value: '05178', label: 'ELDERSERVE HEALTH INC (5178)' },
  { value: '45529', label: 'NASCENTIA HEALTH PLUS (45529)' },
  { value: 'HLPCG', label: 'HILL PHYSICIANS CIGNA PPO (HLPCG)' },
  { value: 'PCU04', label: 'POINT COMFORT UNDERWRITERS - TRAVEL (PCU04)' },
  { value: 'SFHP', label: 'SAN FRANCISCO HEALTH PLAN ENCOUNTERS (SFHP)' },
  { value: '92006', label: 'UDELHOVEN HEALTH PLAN (92006)' },
  { value: 'LIB01', label: 'LIBERTY ADVANTAGE HEALTH PLAN (LIB01)' },
  { value: 'PHMD1', label: 'NP PROVIDENCE HEALTH PLAN COMMERCIAL (PHMD1)' },
  { value: 'PHMD2', label: 'NP PROVIDENCE HEALTH PLAN MEDICARE (PHMD2)' },
  { value: 'PHMD3', label: 'NP PROVIDENCE HEALTH PLAN OHP (PHMD3)' },
  { value: '37601', label: 'UNIV OF IL AT CHIG, DIV OF SPECIALIZED CARE CHLDRN (37601)' },
  { value: '25711', label: 'WELLNET HEALTH PLANS (25711)' },
  { value: 'TH088', label: 'BONCURA HEALTH SOLUTIONS (TH088)' },
  { value: '99943', label: 'GULF GUARANTY (99943)' },
  { value: '40753', label: 'INVICTA HEALTH SOLUTIONS (40753)' },
  { value: 'CB733', label: 'TRIADA ASSURANCE (CB733)' },
  { value: '23212', label: 'INNOVATIVE PAYER 2 (23212)' },
  { value: '23243', label: 'BENEBAY (23243)' },
  { value: 'IHP01', label: 'IMPERIAL HEALTH PLAN OF CALIFORNIA (IHP01)' },
  { value: 'IHCS1', label: 'INTEGRATED HOMECARE SERVICES (IHCS1)' },
  { value: 'MN', label: 'JVHL - PHYSICIANS HEALTH PLAN  (MN)' },
  { value: '81336', label: 'SOMOS EMBLEM (81336)' },
  { value: '59144', label: 'UNITE HERE LAS VEGAS (59144)' },
  { value: '84213', label: 'VMD PRIMARY PROVIDERS OF ARIZONA (84213)' },
  { value: '73117', label: 'TRIBUTE/SELECT CARE OF OKLAHOMA (73117)' },
  { value: '77005', label: 'BAKERSFIELD FAMILY MEDICAL GROUP (77005)' },
  { value: 'MP330', label: 'LUTHERAN PREFERRED (MP330)' },
  { value: '98022', label: 'TAKECARE INSURANCE COMPANY (98022)' },
  { value: 'PODIA', label: 'PODIATRY PLAN (PODIA)' },
  { value: 'HT000004-801', label: 'NEW CHOICES WAIVER (HT000004-801)' },
  { value: '36483', label: 'ASPIRUS MEDICARE ADVANTAGE (36483)' },
  { value: 'TRULI', label: 'TRULI FOR HEALTH (TRULI)' },
  { value: '65482', label: 'PREMIER PHYSICIAN ALLIANCE (65482)' },
  { value: 'UHP01', label: 'UHP MANAGEMENT (UHP01)' },
  { value: '84227', label: 'CLEVER CARE HEALTH PLAN, INC. (84227)' },
  { value: '77350', label: 'PROVIDENCE HEALTH ASSURANCE MEDICAID AFTER 123120 (77350)' },
  { value: '59299', label: 'EMI-KP AMBULANCE (59299)' },
  { value: '44006', label: 'DESERT OASIS HEALTHCARE (44006)' },
  { value: '99105', label: 'BLUE SHIELD OF CALIFORNIA - MEDICARE ADVANTAGE ENC (99105)' },
  { value: '38828', label: 'HPMC - ALTA MED CLINICS (HPMC - AMC) (38828)' },
  { value: '48008', label: 'PHYSMETRICS (48008)' },
  { value: '8422E', label: 'CLEVER CARE HEALTH PLAN ENCOUNTERS (8422E)' },
  { value: '68024', label: 'AETNA BETTER HEALTH OF ILLINOIS - MEDICAID (68024)' },
  { value: 'STFMC', label: 'APPLECARE MEDICAL GROUP (STFMC)' },
  { value: '55892', label: 'NEXTBLUE OF NORTH DAKOTA (55892)' },
  { value: 'UNITE', label: 'UNITE HERE (UNITE)' },
  { value: 'CB404', label: 'PREFERRED HEALTH PLAN OF THE CAROLINAS (CB404)' },
  { value: '20510', label: 'PATIENT PHYSICIAN COOPERATIVES (20510)' },
  { value: '75133', label: 'TEAM CHOICE PNS (75133)' },
  { value: 'A0102', label: 'VIVIDA HEALTH PLAN DOS AFTER 12/31/20 (A0102)' },
  { value: '77500', label: 'PROVIDENCE HEALTH ASSURANCE MEDICARE SUPPLEMENT (77500)' },
  { value: '83352', label: 'PROMINENCE HEALTHFIRST OF FLORIDA (83352)' },
  { value: '31140', label: 'GEORGIA HEALTH ADVANTAGE (31140)' },
  { value: '95111', label: 'POMONA VALLEY HOSPITAL MEDICAL CENTER (95111)' },
  { value: 'DSCYF', label: 'DEPARTMENT SERVICE CHILDREN YOUTH & THEIR FAMILIES (DSCYF)' },
  { value: '84320', label: 'ASTIVA HEALTH (84320)' },
  { value: '84423', label: 'BRANDMAN HEALTH PLAN (84423)' },
  { value: '46311', label: 'PRESENCE ERC (46311)' },
  { value: '85468', label: 'CLEAR SPRING HEALTH (85468)' },
  { value: '85468', label: 'COMMUNITY CARE ALLIANCE OF ILLINOIS (85468)' },
  { value: '83063', label: 'CLAIMCHOICE (83063)' },
  { value: '44602', label: 'IBEW LOCAL 1 (44602)' },
  { value: 'NWEST', label: 'NORTHWESTERN MEDICINE PHYSICIANS NETWORK (NWEST)' },
  { value: 'Z90080', label: 'NORTHCOAST HEALTH CARE MANAGEMENT (Z90080)' },
  { value: 'IHS01', label: 'EMPOWER 360 (IHS01)' },
  { value: 'IHS02', label: 'INNOVATIVE HEALTHWARE SOLUTIONS 2 (IHS02)' },
  { value: 'IHS03', label: 'INNOVATIVE HEALTHWARE SOLUTIONS 3 (IHS03)' },
  { value: '93158', label: 'FRINGE BENEFIT GROUP (93158)' },
  { value: 'IHS04', label: 'INNOVATIVE HEALTHWARE SOLUTIONS 4 (IHS04)' },
  { value: 'PHMD4', label: 'NP YAMHILL COUNTY CCO (PHMD4)' },
  { value: 'IHS05', label: 'INNOVATIVE HEALTHWARE SOLUTIONS 5 (IHS05)' },
  { value: 'IHS06', label: 'INNOVATIVE HEALTHWARE SOLUTIONS 6 (IHS06)' },
  { value: '55962', label: 'CORECIVIC (55962)' },
  { value: 'IHS07', label: 'INNOVATIVE HEALTHWARE SOLUTIONS 7 (IHS07)' },
  { value: 'IHS08', label: 'INNOVATIVE HEALTHWARE SOLUTIONS 8 (IHS08)' },
  { value: 'IHS09', label: 'INNOVATIVE HEALTHWARE SOLUTIONS 9 (IHS09)' },
  { value: 'IHS10', label: 'INNOVATIVE HEALTHWARE SOLUTIONS 10 (IHS10)' },
  { value: '14043', label: 'NHI BILLING SERVICES (14043)' },
  { value: '42561', label: 'SECURE HEALTH DOS AFTER 12/31/20 (42561)' },
  { value: 'SWHNY', label: 'SENIOR WHOLE HEALTH NEW YORK (SWHNY)' },
  { value: '31155', label: 'AMERICAN HEALTH ADVANTAGE OF TEXAS (31155)' },
  { value: 'MHPMI', label: 'MERIDIANCOMPLETE MICHIGAN (MHPMI)' },
  { value: '50664', label: 'RIVERSIDE SAN BERNARDINO COUNTY INDIAN HEALTH INC (50664)' },
  { value: '36477', label: 'VILLAGE PRACTICE MANAGEMENT COMPANY  (36477)' },
  { value: 'PAOH1 ', label: 'PERENNIAL ADVANTAGE (PAOH1 )' },
  { value: '67011', label: 'STATIONARY ENGINEERS LOCAL 670 (67011)' },
  { value: '31404', label: 'PROVIDER PARTNERS HEALTH PLAN OF MISSOURI (31404)' },
  { value: '76451', label: 'UCS COEUR: CONNECTIVITY SOURCE (76451)' },
  { value: 'FBM01', label: 'FLORIDA BLUE MEDICARE (FBM01)' },
  { value: 'STR01', label: 'ALWAYSCARE (STR01)' },
  { value: 'FC002', label: 'MERITAGE FIRST CHOICE (FC002)' },
  { value: 'CPIPA', label: 'CAREPLAN IPA (CPIPA)' },
  { value: 'VS402', label: 'VAULT ADMINISTRATIVE SERVICES (VS402)' },
  { value: '64884', label: 'AITHER HEALTH PLAN (64884)' },
  { value: '31135', label: 'AMERICAN HEALTH ADVANTAGE OF MISSISSIPPI (31135)' },
  { value: '34525', label: 'COMMUNICARE ADVANTAGE (34525)' },
  { value: 'IDWH01', label: 'IDAHO WOMENS HEALTH CHECK (IDWH01)' },
  { value: '53684', label: 'UNIVERSAL HEALTHSHARE (53684)' },
  { value: '65241', label: 'PLANSTIN (65241)' },
  { value: 'LAWND', label: 'LAWNDALE CHRISTIAN HEALTH CENTER (LAWND)' },
  { value: '99999-0AQS', label: 'HRSA COVID-19 COVERAGE ASSISTANCE FUND (99999-0AQS)' },
  { value: 'GCMSO', label: 'GOLDEN COAST MSO (GCMSO)' },
  { value: 'DBA02', label: 'SANTA MONICA UNITE HERE HEALTH BENEFIT FUND (DBA02)' },
  { value: '25922', label: 'VIECARE LIFE ARMSTRONG (25922)' },
  { value: '25923', label: 'VIECARE LIFE BUTLER (25923)' },
  { value: '772', label: 'BLUE MEDICARE ADVANTAGE (772)' },
  { value: 'RAIPA', label: 'RAPHA IPA INC (RAIPA)' },
  { value: '77684', label: 'LAS VEGAS FIREFIGHTERS HEALTH AND WELLNESS FUND (77684)' },
  { value: '82357', label: 'KENTUCKY HEALTH ADMINISTRATORS (82357)' },
  { value: '58174', label: 'PARAGON BENEFITS, INC (58174)' },
  { value: '12090', label: 'BYWATER (12090)' },
  { value: '68069', label: 'ALLWELL FROM SILVER SUMMIT HEALTH (68069)' },
  { value: '30506', label: 'IBEW LOCAL 26 (30506)' },
  { value: 'DOLMG', label: 'DOLTON MEDICAL GROUP (DOLMG)' },
  { value: '13162', label: '1199 NATIONAL BENEFIT FUND (13162)' },
  { value: '00720', label: 'BEHAVIORAL HEALTH SERV. INC. (BHSI) (720)' },
  { value: '00720', label: 'FIRST PLAN OF MINNESOTA (720)' },
  { value: '00720', label: 'MINNESOTA BLUE CROSS AND BLUE SHIELD (720)' },
  { value: '00720', label: 'MINNESOTA CC SYSTEMS (720)' },
  { value: '00720', label: 'MINNESOTA BLUE PLUS (720)' },
  { value: '00720', label: 'MINNESOTA BLUE CROSS/BLUE SHIELD HMO (720)' },
  { value: '411674742', label: 'MINNESOTA MEDICAID (411674742)' },
  { value: 'NDDHSMED', label: 'NORTH DAKOTA MEDICAID (NDDHSMED)' },
  { value: '36273', label: 'AARP MEDICARE SUPPLEMENT PLANS (36273)' },
  { value: '36273', label: 'AARP HOSPITAL INDEMNITY PLANS (36273)' },
  { value: '36273', label: 'AARP (36273)' },
  { value: '128CA', label: 'AETNA BETTER HEALTH OF CALIFORNIA (128CA)' },
  { value: '128FL', label: 'AETNA BETTER HEALTH OF FLORIDA (128FL)' },
  { value: '26337', label: 'AETNA BETTER HEALTH OF ILLINOIS (26337)' },
  { value: '26337', label: 'AETNA BETTER HEALTH OF ILLINOIS - MMAI (26337)' },
  { value: '26337', label: 'AETNA BETTER HEALTH - IL MEDICAID (26337)' },
  { value: '128KS', label: 'AETNA BETTER HEALTH OF KANSAS (128KS)' },
  { value: '128LA', label: 'AETNA BETTER HEALTH OF LOUISIANA (128LA)' },
  { value: '128MD', label: 'AETNA BETTER HEALTH OF MARYLAND (128MD)' },
  { value: '128MI', label: 'AETNA BETTER HEALTH OF MIGHIGAN (128MI)' },
  { value: '46320', label: 'AETNA BETTER HEALTH OF NEW JERSEY (46320)' },
  { value: '34734', label: 'AETNA BETTER HEALTH OF NEW YORK (34734)' },
  { value: '50023', label: 'OHIO DUALS (50023)' },
  { value: '23228', label: 'AETNA BETTER HEALTH OF PENNSYLVANIA (23228)' },
  { value: '23228', label: 'AETNA BETTER HEALTH OF PENNSYLVANIA\t (23228)' },
  { value: '23228', label: 'AETNA BETTER HEALTH - PENNSYLVANIA MEDICAID (23228)' },
  { value: '23228', label: 'AETNA BETTER HEALTH KIDS (23228)' },
  { value: '38692', label: 'AETNA BETTER HEALTH OF TEXAS (38692)' },
  { value: '38692', label: 'AETNA BETTER HEALTH TX MEDICAID CHIP (38692)' },
  { value: '38692', label: 'AETNA BETTER HEALTH OF TEXAS CHIP (38692)' },
  { value: '38692', label: 'AETNA BETTER HEALTH OF TEXAS (38692)' },
  { value: '61108', label: 'AUTHOR BY HUMANA (61108)' },
  { value: '128WV', label: 'AETNA BETTER HEALTH OF WEST VIRGINIA (128WV)' },
  { value: '38259', label: 'ASCENSION (38259)' },
  { value: '38259', label: 'SMARTHEALTH (38259)' },
  { value: '38259', label: 'AUTOMATED BENEFIT SERVICES (38259)' },
  { value: '38259', label: 'US HEALTH AND LIFE (38259)' },
  { value: '77013', label: 'AMERIHEALTH CARITAS VIP CARE PLUS MICHIGAN (77013)' },
  { value: '22384', label: 'ADMINISTRATIVE CONCEPTS (22384)' },
  { value: '59141', label: 'ADMINISTRATIVE SERVICES INC. (59141)' },
  { value: '59141', label: 'ZENITH AMERICAN SOLUTIONS (59141)' },
  { value: '60054', label: 'CITY OF AMARILLO (60054)' },
  { value: '60054', label: 'AETNA - GOLDEN CHOICE (MEDICARE ADVANTAGE PLAN) (60054)' },
  { value: '60054', label: 'AETNA AFFORDABLE HEALTH CHOICES (60054)' },
  { value: '60054', label: 'AETNA - PPO (60054)' },
  { value: '60054', label: 'AETNA  - AEXEL (60054)' },
  { value: '60054', label: 'AETNA - TRADITIONAL CHOICE (TC) (60054)' },
  { value: '60054', label: 'AETNA LEAP (60054)' },
  { value: '60054', label: 'INNOVATION HEALTH LEAP (60054)' },
  { value: '60054', label: 'AETNA - ELECT CHOICE (EC) (60054)' },
  { value: '60054', label: 'AETNA LIFE AND CASUALTY (60054)' },
  { value: '60054', label: 'AETNA GOLDEN MEDICARE PLAN (60054)' },
  { value: '60054', label: 'AETNA - HMO (60054)' },
  { value: '60054', label: 'AETNA - OPEN ACCESS HMO (60054)' },
  { value: '60054', label: 'AETNA - GOLDEN MEDICARE (MEDICARE ADVANTAGE PLAN) (60054)' },
  { value: '60054', label: 'AETNA - QPOS (60054)' },
  { value: '60054', label: 'AETNA (60054)' },
  { value: '60054', label: 'AETNA - INDEMNITY (60054)' },
  { value: '60054', label: 'AETNA - MANAGED CHOICE (MC) (60054)' },
  { value: '60054', label: 'AETNA - CHOICE POS II (60054)' },
  { value: '60054', label: 'AETNA - EPO (60054)' },
  { value: '60054', label: 'AETNA HEALTH FUND (AHF) (60054)' },
  { value: '60054', label: 'AETNA - OPEN CHOICE (OC) (60054)' },
  { value: '60054', label: 'AETNA - USACCESS (60054)' },
  { value: '60054', label: 'AETNA AFFORDABLE HEALTH CHOICES - SRC (60054)' },
  { value: '13334', label: 'AFFINITY HEALTH PLAN MEDICAID (13334)' },
  { value: '13334', label: 'AFFINITY HEALTH PLAN (13334)' },
  { value: '13334', label: 'AFFINITY HEALTH PLAN FAMILY HEALTH PLUS (13334)' },
  { value: '13334', label: 'AFFINITY HEALTH PLAN CHILD HEALTH PLUS (13334)' },
  { value: '13334', label: 'AFFINITY ENRICHED HEALTH HARP (13334)' },
  { value: '13334', label: 'AFFINITY ESSENTIAL PLAN (13334)' },
  { value: '77002', label: 'AMERIHEALTH CARITAS DISTRICT OF COLUMBIA (77002)' },
  { value: '77799', label: 'AMERIHEALTH CARITAS DELAWARE (77799)' },
  { value: '77001', label: 'AMERIHEALTH CARITAS NORTHEAST (77001)' },
  { value: 'AKMEDICAID FHSC', label: 'DENALI CARE (AKMEDICAID FHSC)' },
  { value: 'AKMEDICAID FHSC', label: 'ALASKA MEDICAID (AKMEDICAID FHSC)' },
  { value: '00510', label: 'ALABAMA BLUE SHIELD (510)' },
  { value: '37308', label: 'ALLIED BENEFIT SYSTEM (37308)' },
  { value: '37308', label: 'PHYSICIAN BENEFIT TRUST (37308)' },
  { value: '37308', label: 'ALLIED BENEFIT SYSTEM, INC. (37308)' },
  { value: '752548221', label: 'ALABAMA MEDICAID (752548221)' },
  { value: '81040', label: 'ALLEGIANCE BENEFIT PLAN MANAGEMENT INC. (81040)' },
  { value: '58234', label: 'ALLIANT HEALTH PLANS OF GEORGIA (58234)' },
  { value: '990309519', label: 'ALOHACARE (990309519)' },
  { value: '990309519', label: 'ALOHACARE QUEST (990309519)' },
  { value: '85600', label: 'ALBUQUERQUE PUBLIC SCHOOLS (85600)' },
  { value: '25133', label: 'ALTIUS (25133)' },
  { value: '77062', label: 'AMERIHEALTH CARITAS PA CHC (77062)' },
  { value: '77062', label: 'AMERIHEALTH CARITAS VIP CARE PENNSYLVANIA (77062)' },
  { value: '75137', label: 'AMERIBEN SOLUTIONS, INC. (75137)' },
  { value: '00661', label: 'HEALTHY BLUE LOUISIANA (661)' },
  { value: '54763', label: 'AMERIHEALTH ADMINISTRATORS, INC. (54763)' },
  { value: '22248', label: "AMERIHEALTH/PERFORMCARE NJ (CHILDREN'S BEHAVIORAL  (22248)" },
  { value: '22248', label: 'AMERIHEALTH CARITAS PENNSYLVANIA (22248)' },
  { value: '660650587', label: 'AMERICAN HEALTH MEDICARE (660650587)' },
  { value: '81400', label: 'ALL SAVERS ALTERNATE FUNDING (81400)' },
  { value: '81400', label: 'UNITEDHEALTHCARE LIFE INSURANCE COMPANY (81400)' },
  { value: '81400', label: 'ALL SAVERS INSURANCE (81400)' },
  { value: '87716', label: 'AMERIHEALTH CARITAS NEW HAMPSHIRE (87716)' },
  { value: '74048', label: 'AMERICAN NATIONAL INSURANCE COMPANY (ANICO) (74048)' },
  { value: '44444', label: 'AMERICAN POSTAL WORKERS UNION (44444)' },
  { value: '26375', label: 'AMERIKIDS - DALLAS/FT. WORTH (26375)' },
  { value: '26375', label: 'AMERIGROUP (26375)' },
  { value: '26375', label: 'ANTHEM HEALTHKEEPERS (26375)' },
  { value: '26375', label: 'AMERIGROUP COMMUNITY CARE (26375)' },
  { value: '26375', label: 'AMERIGROUP NJ (26375)' },
  { value: '26375', label: 'AMERIGROUP FLORIDA INC (26375)' },
  { value: '26375', label: 'AMERIGROUP CORPORATION (26375)' },
  { value: '26375', label: 'AMERICAID COMMUNITY CARE (MD) (26375)' },
  { value: '26375', label: 'AMERIGROUP IL (26375)' },
  { value: '26375', label: 'AMERIGROUP IOWA (26375)' },
  { value: '26375', label: 'AMERIGROUP CORPORATION (HOUSTON) (26375)' },
  { value: '26375', label: 'AMERIGROUP CORPORATION (FT WORTH) (26375)' },
  { value: '26375', label: 'UNIVERSITY HEALTH PLAN (UHP) OF NJ DOS >03/01/2010 (26375)' },
  { value: '26375', label: 'AMERIGROUP OF KANSAS (26375)' },
  { value: '42011', label: 'AMERICAN REPUBLIC INSURANCE (MAP) (42011)' },
  { value: '34192', label: 'ANTARES MANAGEMENT SOLUTIONS (34192)' },
  { value: '34192', label: 'MUTUAL HEALTH SERVICES (34192)' },
  { value: '00958', label: 'ANTHEM MAINEHEALTH (958)' },
  { value: '00520', label: 'ARKANSAS BLUE SHIELD (520)' },
  { value: '00520', label: 'USABLE MUTUAL INSURANCE COMPANY (520)' },
  { value: '00520', label: 'HEALTH ADVANTAGE (520)' },
  { value: '00520', label: 'ACCESS ONLY (REPRICER) (520)' },
  { value: '716007869', label: 'ARKANSAS MEDICAID (716007869)' },
  { value: '99999', label: 'ARCHBOLD MEDICAL CENTER HEALTH PLAN (99999)' },
  { value: 'AMC5001', label: 'ARCHBOLD ED UNINSURED (AMC5001)' },
  { value: '93221', label: 'ASURIS NORTHWEST REGENCE (93221)' },
  { value: '62118', label: 'AETNA SSI (62118)' },
  { value: '341488123', label: 'PRIMETIME HEALTH PLAN (341488123)' },
  { value: '341488123', label: 'AULTRA ADMINISTRATIVE SERVICES (341488123)' },
  { value: '341488123', label: 'AULTCARE OF OHIO (341488123)' },
  { value: '341488123', label: 'PRIMETIME MEDICAL INSURANCE CO. (341488123)' },
  { value: '341488123', label: 'MCKINLEY LIFE INSURANCE CO. (341488123)' },
  { value: '341488123', label: 'AULTCARE HMO (341488123)' },
  { value: '59274', label: 'AVMED, INC. (59274)' },
  { value: '53589', label: 'ARIZONA BLUE SHIELD FEP (53589)' },
  { value: '53589', label: 'ARIZONA BLUE SHIELD (53589)' },
  { value: '866004791', label: 'ARIZONA MEDICAID (866004791)' },
  { value: '62179', label: 'HEALTH CHOICE ARIZONA (62179)' },
  { value: '86052', label: 'MERCY CARE PLAN OF ARIZONA (86052)' },
  { value: '86052', label: 'MERCY CARE PLAN - LTC (86052)' },
  { value: '03432', label: 'UNITEDHEALTHCARE COMMUNITY PLAN FKA APIPA (3432)' },
  { value: '03432', label: 'ARIZONA PHYSICIANS IPA (3432)' },
  { value: '81079', label: 'BAYCARE PLUS MEDICARE ADVANTAGE (81079)' },
  { value: '66005', label: 'BLUE CROSS COMMUNITY HEALTH PLANS (66005)' },
  { value: '84555', label: 'HMO LOUISIANA BLUE ADVANTAGE (84555)' },
  { value: 'BRIDG', label: 'BRIDGESPAN (BRIDG)' },
  { value: '63100', label: 'BEHAVIORAL HEALTH SYSTEMS (63100)' },
  { value: '25463', label: 'BIND (25463)' },
  { value: '00922', label: 'SOUTH CAROLINA BLUECHOICE HEALTH PLAN (922)' },
  { value: '66006', label: 'BLUE CROSS MEDICARE ADVANTAGE PPO/HMO (66006)' },
  { value: '84323', label: 'BANNER MEDICARE ADVANTAGE PRIME HMO (BMAH) (84323)' },
  { value: '84324', label: 'BANNER MEDICARE ADVANTAGE PLUS PPO (BMAP) (84324)' },
  { value: '13337', label: 'WELL SENSE HEALTH PLAN (13337)' },
  { value: '13337', label: 'BOSTON MEDICAL CENTER HEALTH PLAN (13337)' },
  { value: '13337', label: 'BMC HEALTHNET PLAN  (13337)' },
  { value: '74238', label: 'BOON CHAPMAN ADMINISTRATORS (74238)' },
  { value: '48611', label: 'BENEFIT MANAGEMENT OF KANSAS (48611)' },
  { value: 'BOONG', label: 'BOON GROUP (THE) (BOONG)' },
  { value: '84367', label: 'BRAVEN HEALTH (84367)' },
  { value: 'BRT01', label: 'BRIGHT HEALTH - MEDICARE ADVANTAGE (BRT01)' },
  { value: 'CB186', label: 'BRIGHT HEALTH PLAN (CB186)' },
  { value: '66901', label: 'BANNER UNIVERSITY FAMILY CARE (UNIVER OF AZ HLTH) (66901)' },
  { value: '00246', label: 'BCBS OF WESTERN NEW YORK MEDICAID / CHP (246)' },
  { value: '47198', label: 'ANTHEM BLUE CROSS CALIFORNIA (47198)' },
  { value: '47198', label: 'CALIFORNIA ANTHEM BLUE CROSS (47198)' },
  { value: '47198', label: 'ANTHEM BLUE CROSS (47198)' },
  { value: '47198', label: 'WRITERS GUILD INDUSTRY HEALTH PLAN (47198)' },
  { value: '47198', label: 'DIRECTORS GUILD OF AMERICA  (47198)' },
  { value: '47198', label: 'MOTION PICTURE INDUSTRY PENSIONS AND HEALTH PLAN (47198)' },
  { value: '47198', label: 'ANTHEM BC PARTNERSHIP (47198)' },
  { value: '47198', label: 'CALIFORNIA FIELD IRONWORKERS TRUST FUNDS (47198)' },
  { value: '47198', label: 'CAL MEDICONNECT (47198)' },
  { value: '94036', label: 'CALIFORNIA BLUE SHIELD (94036)' },
  { value: '94032', label: 'CALIFORNIA BLUE SHIELD - HMO ENCOUNTERS (94032)' },
  { value: '57115', label: 'CARE 1ST PARTNER PLAN LLC (57115)' },
  { value: '57115', label: 'BLUE SHIELD OF CALIFORNIA PROMISE HEALTH PLAN (57115)' },
  { value: '610442', label: 'CALIFORNIA MEDI-CAL (610442)' },
  { value: '610442', label: 'CALIFORNIA CHILDRENS SERVICES (610442)' },
  { value: '953865941', label: 'CENCAL HEALTH (953865941)' },
  { value: '953865941', label: 'SANTA BARBARA REGIONAL HEALTH AUTHORITY (953865941)' },
  { value: 'NVMED', label: 'NEVADA MEDICAID (NVMED)' },
  { value: 'CALOP', label: 'CAL OPTIMA DIRECT (CALOP)' },
  { value: '38333', label: 'MOLINA HEALTHCARE OF CALIFORNIA (38333)' },
  { value: '38333', label: 'MOLINA DUAL OPTIONS (38333)' },
  { value: '95112', label: 'CAPITAL HEALTH PLAN (95112)' },
  { value: '38308', label: 'CHRISTIAN BROTHERS SERVICES (38308)' },
  { value: 'CCA01', label: 'CENTRAL COAST ALLIANCE FOR HEALTH (CCA01)' },
  { value: 'CCA01', label: 'CENTRAL CALIFORNIA ALLIANCE FOR HEALTH (CCAH) (CCA01)' },
  { value: '95491', label: 'CAPITAL DISTRICT PHYSICIANS HEALTH PLAN (CDPHP) (95491)' },
  { value: '31441', label: 'CDS GROUP HEALTH (31441)' },
  { value: '88022', label: 'PROMINENCE ADMINISTRATIVE SERVICES (88022)' },
  { value: '68068', label: 'CENPATICO BEHAVIORAL HEALTH - OHIO (68068)' },
  { value: '68068', label: 'CENPATICO BEHAVIORAL HEALTH - FLORIDA (68068)' },
  { value: '68068', label: 'GROUP PRACTICE AFFILIATES (68068)' },
  { value: '68068', label: 'CENPATICO BEHAVIORAL HEALTH - WISCONSIN (68068)' },
  { value: '68068', label: 'CENPATICO BEHAVIORAL HEALTH - INDIANA (68068)' },
  { value: '68068', label: 'CENPATICO BEHAVIORAL HEALTH - GEORGIA (68068)' },
  { value: '68068', label: 'INTEGRATED MENTAL HEALTH SERVICES  (68068)' },
  { value: '68068', label: 'INTEGRATED MENTAL HEALTH SERVICES (IMHS) (68068)' },
  { value: '68068', label: 'CENPATICO BEHAVIORAL HEALTH - TEXAS (68068)' },
  { value: '68068', label: 'CENPATICO BEHAVIORAL HEALTH - KANSAS (68068)' },
  { value: '68068', label: 'CENPATICO BEHAVIORAL HEALTH - AZ DOS BEFORE 10/18 (68068)' },
  { value: '68068', label: 'CENPATICO BEHAVIORAL HEALTH - SOUTH CAROLINA (68068)' },
  { value: '68068', label: 'CENPATICO BEHAVIORAL HEALTH - ILLINOIS (68068)' },
  { value: '68068', label: 'CENPATICO BEHAVIORAL HEALTH - MISSOURI (68068)' },
  { value: '68068', label: 'CENPATICO BEHAVIORAL HEALTH - MISSISSIPPI (68068)' },
  { value: '75190', label: 'CAREFIRST ADMINISTRATORS / NCAS (75190)' },
  { value: '25133', label: 'COVENTRY ADVANTRA - AZ, NM, TX ONLY (25133)' },
  { value: '25133', label: 'CARELINK MEDICAID (25133)' },
  { value: '128VA', label: 'AETNA BETTER HEALTH OF VIRGINIA (128VA)' },
  { value: '25133', label: 'COVENTRYONE (25133)' },
  { value: '25133', label: 'COVENTRY HEALTH CARE FEDERAL (25133)' },
  { value: '25133', label: 'GROUP HEALTH PLAN (GHP)/ADVANTRA (25133)' },
  { value: '25133', label: 'HEALTH AMERICA/HEALTH ASSURANCE (PA AND OH) (25133)' },
  { value: '25133', label: 'COVENTRY HEALTH CARE OF MISSOURI (25133)' },
  { value: '25133', label: 'SOUTHERN HEALTH ADVANTRA (25133)' },
  { value: '25133', label: 'COVENTRY HEALTH CARE UNIVERSITY OF MISSOURI (25133)' },
  { value: '10629', label: 'CHRISTUS HEALTH MEDICARE ADVANTAGE (10629)' },
  { value: '10629', label: 'CHRISTUS HEALTH PLAN GENERATIONS PLUS (HMO) (10629)' },
  { value: '10629', label: 'CHRISTUS HEALTH PLAN GENERATIONS (10629)' },
  { value: '52106', label: 'CHRISTUS HEALTH PLAN TEXAS HIX (52106)' },
  { value: 'PA041', label: 'THE CHESTERFIELD COMPANIES  (PA041)' },
  { value: 'CBH', label: 'CIGNA BEHAVIORAL HEALTH (CBH)' },
  { value: '62308', label: 'HEALTHSOURCE OF SOUTH CAROLINA (62308)' },
  { value: '62308', label: 'EQUICOR/EQUITABLE (62308)' },
  { value: '62308', label: 'ALBUQUERQUE PPO - NEW MEXICO (62308)' },
  { value: '62308', label: 'CIGNA HEALTH PLAN - COLUMBUS (62308)' },
  { value: '62308', label: 'CIGNA HEALTH PLAN - DELAWARE (62308)' },
  { value: '62308', label: 'CIGNA HEALTH PLAN - PENNSYLVANIA (62308)' },
  { value: '62308', label: 'CIGNA HEALTH PLAN - TUCSON (62308)' },
  { value: '62308', label: 'JACKSON PPO (62308)' },
  { value: '62308', label: 'CIGNA HEALTH PLAN - ATLANTA (62308)' },
  { value: '62308', label: 'HEALTHSOURCE OF ARKANSAS (MEDICARE) (62308)' },
  { value: '62308', label: 'HEALTHSOURCE OF OHIO (62308)' },
  { value: '62308', label: 'CIGNA PPO (62308)' },
  { value: '62308', label: 'CIGNA HEALTH PLAN - CONNECTICUT (62308)' },
  { value: '62308', label: 'CIGNA HEALTH PLAN - DAYTON (62308)' },
  { value: '62308', label: 'CIGNA HEALTH PLAN - DENVER (62308)' },
  { value: '62308', label: 'CIGNA HEALTH PLAN - GEORGIA (62308)' },
  { value: '62308', label: 'CIGNA HEALTH PLAN - MASSACHUSETTS (62308)' },
  { value: '62308', label: 'CIGNA HEALTH PLAN - ST LOUIS (62308)' },
  { value: '62308', label: 'CIGNA HEALTH PLAN - TAMPA (62308)' },
  { value: '62308', label: 'CIGNA HEALTH PLAN - TULSA (62308)' },
  { value: '62308', label: 'OCALA PPO - FLORIDA (62308)' },
  { value: '62308', label: 'PORTLAND PPO - OREGON (62308)' },
  { value: '62308', label: 'SACRAMENTO PPO (62308)' },
  { value: '62308', label: 'GHC INDIVIDUAL PLANS (62308)' },
  { value: '62308', label: 'CIGNA HEALTH PLANS (62308)' },
  { value: '62308', label: 'SMARTHEALTH EMPLOYEE PLAN ONLY (62308)' },
  { value: '62308', label: 'COLLECTIVE HEALTH (62308)' },
  { value: '62308', label: 'CIGNA - DENTAL (62308)' },
  { value: '62308', label: 'HEALTHSOURCE OF MASSACHUSETTS (62308)' },
  { value: '62308', label: 'CIGNA HEALTH PLAN - HMO (CHP) (62308)' },
  { value: '62308', label: 'CIGNA HEALTH PLAN - CLEVELAND (62308)' },
  { value: '62308', label: 'CIGNA HEALTH PLAN - KANSAS CITY (62308)' },
  { value: '62308', label: 'CIGNA HEALTH PLAN - NORTHERN LOUISIANA (62308)' },
  { value: '62308', label: 'CIGNA HEALTH PLAN - SALINAS (62308)' },
  { value: '62308', label: 'JACKSONVILLE PPO (62308)' },
  { value: '62308', label: 'APWU HEALTH PLAN (ALL STATES EXCEPT USVI) (62308)' },
  { value: '62308', label: 'HEALTHSOURCE OF MAINE (62308)' },
  { value: '62308', label: 'CIGNA HEALTH PLAN - ORLANDO (62308)' },
  { value: '62308', label: 'CIGNA HEALTH PLAN - SAN FRANCISCO (62308)' },
  { value: '62308', label: 'CIGNA HEALTH PLAN - WASHINGTON (62308)' },
  { value: '62308', label: 'CIGNA HEALTH PLAN - RICHMOND (62308)' },
  { value: '62308', label: 'UFCW LOCAL 1529 (62308)' },
  { value: '62308', label: 'CIGNA (CONNECTICUT GENERAL) (62308)' },
  { value: '62308', label: 'CGLIC, MILWAUKEE (62308)' },
  { value: '62308', label: 'CIGNA HEALTH PLAN - CINCINNATI (62308)' },
  { value: '62308', label: 'CIGNA HEALTH PLAN - OKLAHOMA (62308)' },
  { value: '62308', label: 'CIGNA HEALTH PLAN - PHOENIX (62308)' },
  { value: '62308', label: 'CIGNA HEALTH PLAN - SALT LAKE CITY (62308)' },
  { value: '62308', label: 'CIGNA HEALTH PLAN - SAN DIEGO (62308)' },
  { value: '62308', label: 'KERN PPO - CALIFORNIA (62308)' },
  { value: '62308', label: 'CIGNA HEALTH PLAN - KNOXVILLE PPO (62308)' },
  { value: '62308', label: 'CIGNA INTERNATIONAL EXPATRIATE BENEFITS (62308)' },
  { value: '62308', label: 'ARKANSAS BEST CORPORATION - CHOICE BENEFITS (62308)' },
  { value: '62308', label: 'HEALTHSOURCE OF TENNESSEE (62308)' },
  { value: '62308', label: 'CIGNA HEALTH PLAN - CIGNA PREMIER PLUS (62308)' },
  { value: '62308', label: 'HEALTHSOURCE OF NEW YORK (62308)' },
  { value: '62308', label: 'EQUICOR PPO (62308)' },
  { value: '62308', label: 'CIGNA HEALTH PLAN - CALIFORNIA (62308)' },
  { value: '62308', label: 'CIGNA HEALTH PLAN - CHICAGO (62308)' },
  { value: '62308', label: 'CIGNA HEALTH PLAN - DALLAS (62308)' },
  { value: '62308', label: 'CIGNA HEALTH PLAN - HOUSTON (62308)' },
  { value: '62308', label: 'CIGNA HEALTH PLAN - LOS ANGELES (62308)' },
  { value: '62308', label: 'CIGNA HEALTH PLAN - MEMPHIS (62308)' },
  { value: '62308', label: 'CIGNA HEALTH PLAN - NORTHERN CALIFORNIA (62308)' },
  { value: '62308', label: 'CIGNA HEALTH PLAN - SOUTHERN CALIFORNIA (62308)' },
  { value: '62308', label: 'HEALTHSOURCE PROVIDENT (62308)' },
  { value: '62308', label: 'SETON HEALTH PLAN (62308)' },
  { value: '62308', label: 'HEALTHSOURCE OF NORTH CAROLINA (62308)' },
  { value: '62308', label: 'CIGNA HEALTH PLAN - COMED (62308)' },
  { value: '62308', label: 'HEALTHSOURCE OF KENTUCKY (62308)' },
  { value: '62308', label: 'CIGNA HEALTH PLAN - MID ATLANTIC (62308)' },
  { value: '62308', label: 'CIGNA HEALTH PLAN - NORTHERN NEW JERSEY (62308)' },
  { value: '62308', label: 'CIGNA HEALTH PLAN - NASHVILLE (62308)' },
  { value: '62308', label: 'CIGNA HEALTH PLAN - NEW YORK (62308)' },
  { value: '62308', label: 'CIGNA HEALTH PLAN - SOUTHERN FLORIDA (62308)' },
  { value: '62308', label: 'CIGNA HEALTH PLAN - SOUTHERN NEW JERSEY (62308)' },
  { value: '62308', label: 'CIGNA HEALTH PLAN - WICHITA (62308)' },
  { value: '62308', label: 'EQUICOR HEALTH PLAN - HOUSTON (62308)' },
  { value: '62308', label: 'SPECIAL AGENT MUTUAL BENEFIT ASSOCIATION (62308)' },
  { value: '62308', label: 'HEALTHSOURCE OF INDIANA (62308)' },
  { value: '62308', label: 'HEALTHSOURCE OF NEW HAMPSHIRE (62308)' },
  { value: '62308', label: 'CIGNA HEALTH PLAN - CHARLOTTE (62308)' },
  { value: '62308', label: 'CIGNA HEALTH PLAN - LOUISIANA (62308)' },
  { value: '62308', label: 'CIGNA HEALTH PLAN - RALEIGH (62308)' },
  { value: '62308', label: 'NALC/AFFORDABLE (62308)' },
  { value: '62308', label: 'SAMBA (62308)' },
  { value: '62308', label: 'HEALTHSOURCE OF NORTH TEXAS (62308)' },
  { value: '35456', label: 'CLARION HEALTH (35456)' },
  { value: 'CLEAR', label: 'CLEAR HEALTH ALLIANCE (CLEAR)' },
  { value: '06302', label: 'WISCONSIN MEDICARE (6302)' },
  { value: '06202', label: 'MINNESOTA MEDICARE (6202)' },
  { value: '06102', label: 'ILLINOIS MEDICARE (6102)' },
  { value: '05102', label: 'IOWA MEDICARE (5102)' },
  { value: '04412', label: 'TEXAS MEDICARE (4412)' },
  { value: '05302', label: 'MISSOURI MEDICARE EASTERN (5302)' },
  { value: '11302', label: 'VIRGINIA MEDICARE (11302)' },
  { value: 'C00882', label: 'RETIRED RAILROAD MEDICARE (C00882)' },
  { value: '01182', label: 'SOUTHERN CALIFORNIA MEDICARE (1182)' },
  { value: '08102', label: 'INDIANA MEDICARE (8102)' },
  { value: '01312', label: 'NEVADA MEDICARE (1312)' },
  { value: '15202', label: 'OHIO MEDICARE (15202)' },
  { value: '09102', label: 'FLORIDA MEDICARE (9102)' },
  { value: '04112', label: 'COLORADO MEDICARE (4112)' },
  { value: '11402', label: 'WEST VIRGINIA MEDICARE (11402)' },
  { value: '05402', label: 'NEBRASKA MEDICARE (5402)' },
  { value: '13102', label: 'CONNECTICUT MEDICARE (13102)' },
  { value: '10312', label: 'TENNESSEE MEDICARE (10312)' },
  { value: '02102', label: 'ALASKA MEDICARE (2102)' },
  { value: '03102', label: 'ARIZONA MEDICARE (3102)' },
  { value: '04212', label: 'NEW MEXICO MEDICARE (4212)' },
  { value: '04312', label: 'OKLAHOMA MEDICARE (4312)' },
  { value: '02302', label: 'OREGON MEDICARE (2302)' },
  { value: '07202', label: 'LOUISIANA MEDICARE (7202)' },
  { value: '08202', label: 'MICHIGAN MEDICARE (8202)' },
  { value: '02402', label: 'WASHINGTON MEDICARE (2402)' },
  { value: '11502', label: 'NORTH CAROLINA MEDICARE  (11502)' },
  { value: '12402', label: 'NEW JERSEY MEDICARE (12402)' },
  { value: '10212', label: 'GEORGIA MEDICARE (10212)' },
  { value: '07302', label: 'MISSISSIPPI MEDICARE (7302)' },
  { value: '11202', label: 'SOUTH CAROLINA MEDICARE (11202)' },
  { value: '03302', label: 'NORTH DAKOTA MEDICARE (3302)' },
  { value: '03402', label: 'SOUTH DAKOTA MEDICARE (3402)' },
  { value: '07102', label: 'ARKANSAS MEDICARE (7102)' },
  { value: '12102', label: 'DELAWARE MEDICARE (12102)' },
  { value: '12502', label: 'PENNSYLVANIA MEDICARE (12502)' },
  { value: '03502', label: 'UTAH MEDICARE (3502)' },
  { value: '12202', label: 'DISTRICT OF COLUMBIA METROPOLITAN AREA MEDICARE (12202)' },
  { value: '12202', label: 'VIRGINIA MEDICARE (ALEX, ARLGTN, FAIRFAX (12202)' },
  { value: '15102', label: 'KENTUCKY MEDICARE (15102)' },
  { value: '05202', label: 'KANSAS MEDICARE (5202)' },
  { value: '12202', label: 'MARYLAND MEDICARE (MONTG, PRINCE GEORGE) (12202)' },
  { value: '02202', label: 'IDAHO MEDICARE (2202)' },
  { value: '03602', label: 'WYOMING MEDICARE (3602)' },
  { value: '01212', label: 'HAWAII MEDICARE (1212)' },
  { value: '10112', label: 'ALABAMA MEDICARE (10112)' },
  { value: '14212', label: 'MASSACHUSETTS MEDICARE (14212)' },
  { value: '14312', label: 'NEW HAMPSHIRE MEDICARE (14312)' },
  { value: '14512', label: 'VERMONT MEDICARE (14512)' },
  { value: '14112', label: 'MAINE MEDICARE (14112)' },
  { value: '09202', label: 'PUERTO RICO MEDICARE (9202)' },
  { value: '13202', label: 'MEDICARE OF NEW YORK DOWNSTATE (13202)' },
  { value: '13202', label: 'NEW YORK EMPIRE MEDICARE (13202)' },
  { value: '05302', label: 'MISSOURI NORTHWEST MEDICARE - KANSAS CITY, MO (5302)' },
  { value: '09302', label: 'VIRGIN ISLANDS MEDICARE (9302)' },
  { value: '03202', label: 'MONTANA MEDICARE (3202)' },
  { value: '13292', label: 'NEW YORK MEDICARE GHI (13292)' },
  { value: '12302', label: 'MARYLAND MEDICARE (12302)' },
  { value: '14412', label: 'RHODE ISLAND MEDICARE (14412)' },
  { value: '16013', label: 'MEDICARE DME MAC JURISDICTION A (16013)' },
  { value: '17013', label: 'MEDICARE DME MAC JURISDICTION B (17013)' },
  { value: '18003', label: 'MEDICARE DME MAC JURISDICTION C (18003)' },
  { value: '19003', label: 'MEDICARE DME MAC JURISDICTION D (19003)' },
  { value: '75284', label: 'CONSOLIDATED ASSOCIATES RAILROAD (75284)' },
  { value: '68069', label: 'NEBRASKA TOTAL CARE (68069)' },
  { value: '68069', label: 'SILVER SUMMIT HEALTH PLAN (68069)' },
  { value: 'HM001', label: 'HEALTH NET OF CALIFORNIA - ENCOUNTERS (HM001)' },
  { value: '68069', label: 'PEACH STATE HEALTH PLAN (68069)' },
  { value: '68069', label: 'SUPERIOR HEALTH PLAN - TEXAS (68069)' },
  { value: '68069', label: 'SUPERIOR MEDICARE ADVANTAGE PLAN (68069)' },
  { value: '68069', label: 'MHS HEALTH WISCONSIN (68069)' },
  { value: '68069', label: 'HOOSIER CARE CONNECT (68069)' },
  { value: '68069', label: 'MANAGED HEALTH SERVICES - INDIANA (68069)' },
  { value: '95567', label: 'HEALTH NET OF CALIFORNIA AND OREGON (CLAIMS) (95567)' },
  { value: '95567', label: 'HEALTH NET COMMUNITY SOLUTIONS/CALVIVA HEALTH (95567)' },
  { value: '68069', label: 'MARYLAND PHYSICIANS CARE DOS PRIOR TO 1/1/2021 (68069)' },
  { value: '68069', label: 'TOTAL CAROLINA CARE (68069)' },
  { value: '68069', label: 'ABSOLUTE TOTAL CARE (68069)' },
  { value: '68069', label: 'BRIDGEWAY ARIZONA (68069)' },
  { value: '68069', label: 'BUCKEYE COMMUNITY HEALTH - OHIO (68069)' },
  { value: '68069', label: 'MICHIGAN COMPLETE HEALTH (68069)' },
  { value: '68069', label: 'ADVANTAGE BY BUCKEYE (68069)' },
  { value: '68069', label: 'MAGNOLIA HEALTH PLAN - MISSISSIPPI (68069)' },
  { value: '95567', label: 'HEALTH NET OREGON (95567)' },
  { value: '68069', label: 'ILLINICARE HEALTH PLAN (68069)' },
  { value: '68069', label: 'LOUISIANA HEALTH CONNECTIONS (68069)' },
  { value: '68069', label: 'HOMESTATE HEALTH PLAN (68069)' },
  { value: '68069', label: 'COORDINATED CARE (68069)' },
  { value: '68069', label: 'SUNFLOWER STATE (68069)' },
  { value: '68069', label: 'GRANITE STATE HEALTH PLAN (68069)' },
  { value: '68069', label: 'NEW HAMPSHIRE HEALTHY FAMILIES (68069)' },
  { value: '68069', label: 'CALIFORNIA HEALTH AND WELLNESS (68069)' },
  { value: '68069', label: 'AMBETTER OF ARKANSAS (68069)' },
  { value: '68069', label: 'AMBETTER FROM SUNSHINE HEALTH (68069)' },
  { value: '68069', label: 'AMBETTER FROM PEACH STATE HEALTH PLAN (68069)' },
  { value: '68069', label: 'AMBETTER FROM MANAGED HEALTH SERVICES (68069)' },
  { value: '68069', label: 'AMBETTER FROM MAGNOLIA HEALTH (68069)' },
  { value: '68069', label: 'AMBETTER FROM BUCKEYE COMMUNITY HEALTH PLAN (68069)' },
  { value: '68069', label: 'AMBETTER FROM SUPERIOR HEALTH (68069)' },
  { value: '68069', label: 'AMBETTER ILLINICARE HEALTH (68069)' },
  { value: '68069', label: 'AMBETTER NEW HAMPSHIRE HEALTHY FAMILIES (68069)' },
  { value: '68069', label: 'TRILLIUM COMMUNITY HEALTH PLAN (68069)' },
  { value: '68069', label: 'PA HEALTH AND WELLNESS (68069)' },
  { value: '68069', label: 'HEALTH NET INDIVIDUAL FAMILY PLAN - DOS >12/31/17 (68069)' },
  { value: '68069', label: 'HEALTH NET OF AZ, CA, OR - DOS >12/31/17 (68069)' },
  { value: '68069', label: 'HEALTH NET INDIVIDUAL MEDICARE ADVANTAGE PLAN (68069)' },
  { value: '68069', label: 'ALLWELL FROM HEALTH NET (68069)' },
  { value: '68069', label: 'ALLWELL FROM ARKANSAS HEALTH AND WELLNESS (68069)' },
  { value: '68069', label: 'ALLWELL FROM SUNSHINE HEALTH (68069)' },
  { value: '68069', label: 'ALLWELL FROM PEACH STATE HEALTH PLAN (68069)' },
  { value: '68069', label: 'ALLWELL FROM MANAGED HEALTH SERVICES (68069)' },
  { value: '68069', label: 'ALLWELL FROM SUNFLOWER HEALTH PLAN (68069)' },
  { value: '68069', label: 'ALLWELL FROM LOUISIANA HEALTHCARE CONNECTIONS (68069)' },
  { value: '68069', label: 'ALLWELL FROM MAGNOLIA HEALTH (68069)' },
  { value: '68069', label: 'ALLWELL FROM HOME STATE HEALTH (68069)' },
  { value: '68069', label: 'ALLWELL FROM BUCKEYE HEALTH PLAN (68069)' },
  { value: '68069', label: 'ALLWELL FROM PA HEALTH AND WELLNESS (68069)' },
  { value: '68069', label: 'ALLWELL FROM ABSOLUTE TOTAL CARE (68069)' },
  { value: '68069', label: 'ALLWELL FROM SUPERIOR HEALTHPLAN (68069)' },
  { value: '68069', label: 'ALLWELL FROM MANAGED HEALTH SERVICES HEALTH WI (68069)' },
  { value: '68069', label: 'AMBETTER FROM HEALTH NET (68069)' },
  { value: '68069', label: 'AMBETTER FROM HOMESTATE HEALTH (68069)' },
  { value: '68069', label: 'ARIZONA COMPLETE HEALTH - DOS AFTER 10/1/18 (68069)' },
  { value: '68069', label: 'ALLWELL FROM WESTERN SKY COMMUNITY CARE (68069)' },
  { value: '68069', label: 'WESTERN SKY COMMUNITY CARE (68069)' },
  { value: '68069', label: 'AMBETTER FROM SUNFLOWER HEALTH PLAN (68069)' },
  { value: '68069', label: 'ARKANSAS TOTAL CARE (68069)' },
  { value: '68069', label: 'IOWA TOTAL CARE (68069)' },
  { value: '68069', label: 'ASCENSION COMPLETE (68069)' },
  { value: '68069', label: 'AMBETTER OF TENNESSEE (68069)' },
  { value: '68069', label: 'AMBETTER FROM PENNSYLVANIA HEALTH AND WELLNESS (68069)' },
  { value: '84129', label: 'BEHAVIORAL HEALTHCARE (84129)' },
  { value: '84129', label: 'ACCESS BEHAVIORAL CARE (84129)' },
  { value: '84129', label: 'COLORADO ACCESS (84129)' },
  { value: '84129', label: 'ACCOUNTABLE CARE COLLABORATIVE (84129)' },
  { value: '84129', label: 'CHILD HEALTH PLAN PLUS (CHP+) (84129)' },
  { value: '84129', label: 'NORTHEAST BEHAVIORAL HEALTH PARTNERSHIP (84129)' },
  { value: '00050', label: 'ANTHEM MEDIBLUE PLUS (HMO) (50)' },
  { value: '00050', label: 'COLORADO BLUE SHIELD (50)' },
  { value: '00050', label: 'ANTHEM DUAL ADVANTAGE (HMO SNP) (50)' },
  { value: '77016', label: 'HEALTH FIRST COLORADO FKA COLORADO MEDICAID (77016)' },
  { value: '77016', label: 'HEALTH FIRST COLORADO FKA COLORADO MEDICAID\t (77016)' },
  { value: 'RH003', label: 'KAISER PERMANENTE (RH003)' },
  { value: 'RH003', label: 'KAISER PERMANENTE OF COLORADO (RH003)' },
  { value: '60495', label: 'COMMUNITY HEALTH CHOICE TEXAS - HIM (60495)' },
  { value: 'COMMF', label: 'COMMUNITY FIRST HEALTH PLAN (COMMF)' },
  { value: '78375', label: 'CONNECTICARE VIP MEDICARE ADVANTAGE (78375)' },
  { value: '06105', label: 'CONNECTICARE, INC. (6105)' },
  { value: 'CCHP1', label: 'COOK CHILDRENS HEALTH PLAN (CCHP1)' },
  { value: '60065', label: 'COOK MEDICAL GROUP (60065)' },
  { value: '128KY', label: 'AETNA BETTER HEALTH OF KENTUCKY (128KY)' },
  { value: '00019', label: 'COX HEALTH PLAN (19)' },
  { value: '56116', label: 'CORPORATE BENEFITS SERVICES (56116)' },
  { value: 'CREA8', label: 'CREATE (CREA8)' },
  { value: '14163', label: 'CARE 1ST HEALTH PLAN OF ARIZONA (14163)' },
  { value: '14163', label: 'ONECARE MEDICARE ADVANTAGE (14163)' },
  { value: 'CARMO', label: 'CAREMORE HEALTH PLAN (CARMO)' },
  { value: '65031', label: 'CAREPLUS HEALTH PLANS, INC. (65031)' },
  { value: '31114', label: 'CARESOURCE THE COMMUNITY TRANSITION PLAN (31114)' },
  { value: '31114', label: 'CARESOURCE OF OHIO (31114)' },
  { value: '31114', label: 'CARESOURCE ADVANTAGE (31114)' },
  { value: '46430', label: 'CRYSTAL RUN HEALTH PLANS  (46430)' },
  { value: '38225', label: 'TRUSTMARK HEALTH BENEFITS (CORESOURCE DETROIT) (38225)' },
  { value: '35183', label: 'TRUSTMARK HEALTH BENEFITS (CORESOURCE OH) (35183)' },
  { value: '35182', label: 'TRUSTMARK HEALTH (CORESOURCE AZ IL IN MD MN NC PA) (35182)' },
  { value: '75136', label: 'TRUSTMARK HEALTH BENEFITS (CORESOURCE LITTLE ROCK) (75136)' },
  { value: '35187', label: 'TRUSTMARK HEALTH BENEFITS (CORESOURCE INTERNAL) (35187)' },
  { value: '48117', label: 'TRUSTMARK HEALTH BENEFITS (CORESOURCE KC) (48117)' },
  { value: 'INCS1', label: 'CARESOURCE OF INDIANA (INCS1)' },
  { value: 'INCS1', label: 'CARESOURCE HEALTHY INDIANA PLAN (HIP) (INCS1)' },
  { value: 'INCS1', label: 'CARESOURCE HOOSIER HEALTHWISE (HHW) (INCS1)' },
  { value: 'KYCS1', label: 'CARESOURCE OF KENTUCKY (KYCS1)' },
  { value: 'KYCS1', label: 'HUMANA CARESOURCE OF KY (KYCS1)' },
  { value: 'WVCS1', label: 'CARESOURCE OF WEST VIRGINIA (WVCS1)' },
  { value: '36215', label: 'CENTRAL STATES HEALTH & WELFARE FUNDS (36215)' },
  { value: '36215', label: 'TEAMCARE (36215)' },
  { value: '00060', label: 'CONNECTICUT BLUE SHIELD (60)' },
  { value: '61274678', label: 'AETNA BETTER HEALTH PLAN (61274678)' },
  { value: '61274678', label: 'CONNECTICUT MEDICAID (61274678)' },
  { value: '06541', label: 'COUNTYCARE HEALTH PLAN (6541)' },
  { value: '25133', label: 'COVENTRY HEALTH AND LIFE OKLAHOMA (25133)' },
  { value: '25133', label: 'COVENTRY HEALTH OF TENNESSEE (25133)' },
  { value: '88056', label: 'LUCENT HEALTH (88056)' },
  { value: 'DAKOTACARE', label: 'MICRON TECH (DAKOTACARE)' },
  { value: 'DAKOTACARE', label: 'CAREWEST (DAKOTACARE)' },
  { value: 'DAKOTACARE', label: 'DAKOTACARE (DAKOTACARE)' },
  { value: '00580', label: 'BLUE SHIELD OF NATIONAL CAPITOL AREA - CAREFIRST (580)' },
  { value: '77033', label: 'DISTRICT OF COLUMBIA MEDICAID (77033)' },
  { value: 'DCMED', label: 'MEDSTAR DC (DCMED)' },
  { value: '39113', label: 'WELLFIRST HEALTH (39113)' },
  { value: '39113', label: "CHILDREN'S COMMUNITY HEALTH PLAN (39113)" },
  { value: '39113', label: 'DEANCAREHMO (39113)' },
  { value: '39113', label: 'DEAN HEALTH PLANS (39113)' },
  { value: '00570', label: 'DELAWARE BLUE SHIELD - HIGHMARK (570)' },
  { value: '345724166', label: 'DIAMOND PLAN (345724166)' },
  { value: '345724166', label: 'DELAWARE MEDICAID (345724166)' },
  { value: '25133', label: 'COVENTRY HEALTH CARE DELAWARE (25133)' },
  { value: 'DEVOT', label: 'DEVOTED HEALTH (DEVOT)' },
  { value: 'HT000006-001', label: 'DESERET MUTUAL BENEFIT ASSOCIATES (DMBA) (HT000006-001)' },
  { value: '84135', label: 'DENVER HEALTH MEDICAL PLAN (84135)' },
  { value: '74284', label: 'DRISCOLL CHILDRENS HEALTH PLAN (74284)' },
  { value: '06102', label: 'DIVERSIFIED ADMINISTRATION CORPORATION (DAC) (6102)' },
  { value: '06102', label: 'DIVERSIFIED GROUP BROKERAGE (6102)' },
  { value: '81039', label: 'EMPLOYEE BENEFIT MANAGEMENT SERV (EBMS) HEALTHEWEB (81039)' },
  { value: '81039', label: 'EBMS HEALTHEWEB (81039)' },
  { value: '37257', label: 'EBSO, INC. (37257)' },
  { value: '52192', label: 'CIGNA HEALTHSPRING MARYLAND/PENNSYLNAVIA (52192)' },
  { value: 'EPF03', label: 'EL PASO HEALTH-CHIP (EPF03)' },
  { value: '25531', label: 'EMBLEMHEALTH PLAN INC HMO (25531)' },
  { value: '55247', label: 'EMBLEMHEALTH MANAGED LTC (55247)' },
  { value: '55247', label: 'EMBLEM HEALTH/HEALTH INSURANCE PLAN OF GREATER NY (55247)' },
  { value: '55247', label: 'EMBLEM HEALTH MEDICARE CHOICE VALUE (55247)' },
  { value: '55247', label: 'HIP-HEALTH INSURANCE PLAN OF GREATER NY (55247)' },
  { value: '13551', label: 'EMBLEMHEALTH PLAN INC MCR (13551)' },
  { value: '22264', label: 'VYTRA HEALTHCARE (22264)' },
  { value: '00803', label: 'EMPIRE HEALTHCHOICE HMO NY (803)' },
  { value: '00803', label: 'EMPIRE BCBS HEALTHPLUS FIDA ADVANTAGE PLAN (803)' },
  { value: '00803', label: 'EMPIRE BCBS HEALTHPLUS (803)' },
  { value: '00803', label: 'NEW YORK EMPIRE BLUE SHIELD - HEALTHNET (803)' },
  { value: '00803', label: 'NEW YORK EMPIRE BLUE SHIELD (803)' },
  { value: '16565', label: 'EMHS EMPLOYEE HEALTH PLAN (16565)' },
  { value: 'HT000214-001', label: 'EMI HEALTH (HT000214-001)' },
  { value: 'HT000214-001', label: 'EDUCATORS MUTUAL INSURANCE ASSOCIATION (HT000214-001)' },
  { value: '12956', label: 'EMPOWER HEALTHCARE SOLUTIONS (12956)' },
  { value: '23250', label: 'SIGNIFICA BENEFIT SERVICES, INC. (23250)' },
  { value: '20818', label: 'ESSENCE (20818)' },
  { value: '22254', label: 'FALLON COMMUNITY HEALTH PLAN (22254)' },
  { value: '22254', label: 'FALLON TOTAL CARE (22254)' },
  { value: '39167', label: 'GROUP HEALTH COOPERATIVE SOUTH CENTRAL WISCONSIN (39167)' },
  { value: 'FCC01', label: 'FIRSTCAROLINACARE - NEW (FCC01)' },
  { value: 'FCC01', label: 'FIRSTMEDICARE - DIRECT  (FCC01)' },
  { value: '56196', label: 'FIRSTCAROLINACARE (56196)' },
  { value: '77009', label: 'FIRST CHOICE VIP CARE PLUS SOUTH CAROLINA (77009)' },
  { value: '11315', label: 'FIDELIS MEDICAID ADVANTAGE PLUS (11315)' },
  { value: '11315', label: 'NEW YORK STATE CATHOLIC HEALTH PLAN (11315)' },
  { value: '11315', label: 'CENTERCARE (11315)' },
  { value: '11315', label: 'FIDELIS DUAL ADVANTAGE NYC (11315)' },
  { value: '11315', label: 'FIDELIS CARE AT HOME (11315)' },
  { value: '11315', label: 'FIDELIS CARE OF NEW YORK (11315)' },
  { value: '00590', label: 'FLORIDA BLUE SHIELD (590)' },
  { value: '77027', label: 'FLORIDA MEDICAID (77027)' },
  { value: '25133', label: 'COVENTRY HEALTH CARE FLORIDA/VISTA/SUMMIT (25133)' },
  { value: '59322', label: 'FLORIDA HEALTH CARE PLAN (59322)' },
  { value: '51062', label: 'MOLINA HEALTHCARE OF FLORIDA (51062)' },
  { value: '43197', label: 'FRESENIUS HEALTH PARTNERS (43197)' },
  { value: '43197', label: 'FRESENIUS TOTAL HEALTH (PPO SNP) (43197)' },
  { value: '73159', label: 'FIRST HEALTH NETWORK (73159)' },
  { value: '00601', label: 'GEORGIA BLUE CHOICE (601)' },
  { value: '00601', label: 'GEORGIA BLUE SHIELD (601)' },
  { value: '00601', label: 'GEORGIA BANKERS (601)' },
  { value: '00601', label: 'STATE MERIT (601)' },
  { value: '77034', label: 'GEORGIA MEDICAID (77034)' },
  { value: '77034', label: 'GEORGIA MEDICAID ANESTHESIA (77034)' },
  { value: '25133', label: 'COVENTRY HEALTH CARE OF GEORGIA, INC. (25133)' },
  { value: 'RH008', label: 'KAISER FOUNDATION HEALTH PLAN OF GEORGIA (RH008)' },
  { value: '60550', label: 'GATEWAY HEALTH PLAN - MEDICARE ASSURED PENNSYLVANI (60550)' },
  { value: '91741', label: 'GATEWAY HEALTH PLAN - MEDICARE ASSURED (91741)' },
  { value: '25169', label: 'GATEWAY HEALTH PLAN - MEDICAID PENNSYLVANIA (25169)' },
  { value: '44054', label: 'GEHA STANDARD OPTION (44054)' },
  { value: '44054', label: 'GEHA CONNECTION DENTAL FEDERAL (44054)' },
  { value: '44054', label: 'PRE-EXISTING CONDITION INSURANCE PLAN (PCIP) (44054)' },
  { value: '44054', label: 'GEHA HIGH DEDUCTIBLE HEALTH PLAN (HDHP) (44054)' },
  { value: '44054', label: 'FEDERAL EMPLOYEES DENTAL AND VISION (FEDVIP) (44054)' },
  { value: '44054', label: 'FEDERAL EMPLOYEES HEALTH BENEFIT PROGRAM (FEHB) (44054)' },
  { value: '44054', label: 'STATE OF TEXAS DENTAL (44054)' },
  { value: '44054', label: 'GEHA CONNECTION DENTAL PLUS (44054)' },
  { value: '44054', label: 'GEHA HIGH OPTION (44054)' },
  { value: '75273', label: 'GEISINGER HEALTH PLAN (75273)' },
  { value: '47181', label: 'HIGHMARK BCBS DELAWARE HEALTH OPTIONS (47181)' },
  { value: '37602', label: 'GOLDEN RULE INSURANCE (37602)' },
  { value: '37602', label: 'ROONEY LIFE, INC. (37602)' },
  { value: '23641', label: 'GMP EMPLOYERS RETIREE TRUST (23641)' },
  { value: '48143', label: 'GROUP AND PENSION ADMINISTRATORS (48143)' },
  { value: '91051', label: 'KAISER FOUNDATION HEALTH PLAN OF WASHINGTON (91051)' },
  { value: '91051', label: 'KAISER FOUNDATION HEALTH PLAN OF WASHINGTON OPTION (91051)' },
  { value: '39180', label: 'QUARTZ BENEFITS UNDERWRITTEN BY GUNDERSEN HEALTH  (39180)' },
  { value: '39180', label: 'GUNDERSEN LUTHERAN HEALTH PLAN (39180)' },
  { value: '62308', label: 'ONE HEALTH PLAN OF FLORIDA (62308)' },
  { value: '62308', label: 'ONE HEALTH PLAN OF GEORGIA (62308)' },
  { value: '62308', label: 'ONE HEALTH PLAN OF OHIO (62308)' },
  { value: '62308', label: 'ONE HEALTH PLAN OF WASHINGTON (62308)' },
  { value: '62308', label: 'ONE HEALTH PLAN OF WYOMING (62308)' },
  { value: '62308', label: 'GENERAL AMERICAN LIFE OF ST. LOUIS (62308)' },
  { value: '62308', label: 'GREAT-WEST HEALTHCARE AND ANNUITY INSURANCE CO. (62308)' },
  { value: '62308', label: 'ALTA HEALTH & LIFE INSURANCE COMPANY (62308)' },
  { value: '62308', label: 'ONE HEALTH PLAN OF OREGON (62308)' },
  { value: '62308', label: 'WESTERN (62308)' },
  { value: '62308', label: 'ONE HEALTH PLAN OF KANSAS (62308)' },
  { value: '62308', label: 'NEW ENGLAND FINANCIAL (62308)' },
  { value: '62308', label: 'ONE HEALTH PLAN OF COLORADO (62308)' },
  { value: '62308', label: 'ONE HEALTH PLAN OF PENNSYLVANIA (62308)' },
  { value: '62308', label: 'INDIANA HEALTH NETWORK (62308)' },
  { value: '62308', label: 'CIGNA SELECT GREAT-WEST HEALTHCARE (62308)' },
  { value: '62308', label: 'ONE HEALTH PLAN OF MAINE (62308)' },
  { value: '62308', label: 'ONE HEALTH PLAN OF MASSACHUSETTS (62308)' },
  { value: '62308', label: 'CROWN LIFE (62308)' },
  { value: '62308', label: 'GWL (GREAT-WEST LIFE) (62308)' },
  { value: '62308', label: 'ONE HEALTH PLAN OF ARIZONA (62308)' },
  { value: '62308', label: 'CIGNA SELECT (FORMERLY GREAT WEST HEALTHCARE) (62308)' },
  { value: '62308', label: 'ONE HEALTH PLAN OF CALIFORNIA (62308)' },
  { value: '62308', label: 'ONE HEALTH PLAN OF ILLINOIS (62308)' },
  { value: '62308', label: 'ONE HEALTH PLAN OF NEW HAMPSHIRE (62308)' },
  { value: '62308', label: 'ONE HEALTH PLAN OF SOUTH CAROLINA (62308)' },
  { value: '62308', label: 'GREAT-WEST LIFE (62308)' },
  { value: '62308', label: 'ONE HEALTH PLAN OF INDIANA (62308)' },
  { value: '62308', label: 'ONE HEALTH PLAN OF NEW JERSEY (62308)' },
  { value: '62308', label: 'ONE HEALTH PLAN OF TEXAS (62308)' },
  { value: '62308', label: 'ONE HEALTH PLAN OF WISCONSIN (62308)' },
  { value: '62308', label: 'ONE HEALTH PLAN OF NORTH CAROLINA (62308)' },
  { value: '62308', label: 'ONE HEALTH PLAN OF NEVADA (62308)' },
  { value: '62308', label: 'ONE HEALTH PLAN OF TENNESSEE (62308)' },
  { value: '62308', label: 'ONE HEALTH PLAN OF VERMONT (62308)' },
  { value: '62308', label: 'ONE HEALTH PLAN OF VIRGINIA (62308)' },
  { value: '62308', label: 'GREAT WEST CARE (62308)' },
  { value: '62308', label: 'GREAT-WEST LIFE ASSURANCE CO (GWL) (62308)' },
  { value: '62308', label: 'ONE HEALTH PLAN OF ALASKA (62308)' },
  { value: '382242827', label: 'MEDICAL VALUE PLAN - OHIO (MVP) (382242827)' },
  { value: '382242827', label: 'HAP/AHL/CURANET (382242827)' },
  { value: '00551', label: 'HEALTHY BLUE DUAL ADVANTAGE LOUISIANA (551)' },
  { value: '00541', label: 'HEALTHY BLUE MISSOURI (541)' },
  { value: '00602', label: 'HEALTHY BLUE NORTH CAROLINA (602)' },
  { value: '00544', label: 'HEALTHY BLUE NEBRASKA (544)' },
  { value: '25133', label: 'COVENTRY HEALTH CARE PROMINA (25133)' },
  { value: '62111', label: 'HEALTH COST SOLUTIONS (62111)' },
  { value: '56144', label: 'PRIMARY PHYSICIAN CARE, INC. (56144)' },
  { value: '56144', label: 'HEALTHGRAM (56144)' },
  { value: '86602', label: 'OPERATING ENGINEERS LOCAL 428 (86602)' },
  { value: '996001089', label: 'HAWAII MEDICAID (996001089)' },
  { value: 'RH0011', label: 'KAISER PERMANENTE OF HAWAII (RH0011)' },
  { value: 'RH0011', label: 'KAISER ADDED CHOICE (RH0011)' },
  { value: '85729', label: 'HEALTHCOMP INC. (85729)' },
  { value: '95019', label: 'HEALTH FIRST HEALTH PLAN (95019)' },
  { value: '95019', label: 'FLORIDA HOSPITAL CARE ADVANTAGE (95019)' },
  { value: '44273', label: 'BENEFIT PLAN MANAGEMENT, INC. (44273)' },
  { value: '44273', label: 'HEALTH PLANS, INC. (44273)' },
  { value: 'HEALTHPARTNERS', label: 'HEALTHPARTNERS UNITYPOINT HEALTH (HEALTHPARTNERS)' },
  { value: 'HEALTHPARTNERS', label: 'HEALTHPARTNERS OF MINNESOTA (HEALTHPARTNERS)' },
  { value: 'HEALTH ALLIANCE MEDICAL ', label: 'HEALTH ALLIANCE MEDICAL PLAN OF ILLINOIS (HEALTH ALLIANCE MEDICAL )' },
  { value: 'HEALTH ALLIANCE MEDICAL ', label: 'HCH ADMINISTRATION (HEALTH ALLIANCE MEDICAL )' },
  { value: '71064', label: 'EDS HEALTHCHOICE (71064)' },
  { value: '71064', label: 'OKLAHOMA EMPLOYEES GROUP INSURANCE DIVISION (71064)' },
  { value: '71064', label: 'HEALTHCHOICE OKLAHOMA (71064)' },
  { value: '71065', label: 'OKLAHOMA DEPARTMENT OF CORRECTIONS (71065)' },
  { value: '71065', label: 'OKLAHOMA DEPARTMENT OF REHABILITATION SERVICES (71065)' },
  { value: '20356', label: 'HEALTH EXCHANGE (THE) (CERNER CORPORATION) (20356)' },
  { value: '04286', label: 'HEALTH NEW ENGLAND (4286)' },
  { value: '55204', label: 'HEALTHNOW NEW YORK BLUE SHIELD (55204)' },
  { value: '95677', label: 'HEALTH PLAN OF THE UPPER OHIO VALLEY (95677)' },
  { value: '95677', label: 'HEALTH PLAN (THE) (95677)' },
  { value: '71063', label: 'HEALTHSCOPE BENEFITS, INC. (71063)' },
  { value: '86066', label: 'HAWAII MAINLAND ADMINISTRATORS (86066)' },
  { value: '86066', label: 'HMA HAWAII (86066)' },
  { value: 'HMA01', label: 'HEALTHCARE MGMT ADMINISTRATORS - WESTERN REGION (HMA01)' },
  { value: 'HMA01', label: 'REGENCE GROUP ADMINISTRATORS (RGA) (HMA01)' },
  { value: 'HMA01', label: 'HMA - HEALTHCARE MANAGEMENT ADMINISTRATORS (HMA01)' },
  { value: '990040115', label: 'AKAMAI ADVANTAGE (MEDICARE ADVANTAGE PLAN)  (990040115)' },
  { value: '990040115', label: 'HAWAII HMSA (990040115)' },
  { value: '990040115', label: 'HAWAII QUEST (990040115)' },
  { value: '22771', label: 'MANAGED HEALTH NETWORK (22771)' },
  { value: '88023', label: 'HOMETOWN HEALTH (88023)' },
  { value: 'HPSM1', label: 'CARE ADVANTAGE (HPSM1)' },
  { value: 'HPSM1', label: 'HEALTH PLAN OF SAN MATEO (HPSM1)' },
  { value: 'HPSM1', label: 'HEALTH PLAN OF SAN MAETO CARE ADVANTAGE (HPSM1)' },
  { value: '80142', label: 'HEALTH PARTNERS OF PHILADELPHIA INC (80142)' },
  { value: '80142', label: 'HEALTH PARTNERS, PA (80142)' },
  { value: '94320', label: 'KAISER SELF FUNDED PLAN - HARRINGTON/FISERV (94320)' },
  { value: '59143', label: 'HARRINGTON HEALTH (59143)' },
  { value: 'HPHC0001', label: 'HARVARD PILGRIM HEALTH CARE (HPHC0001)' },
  { value: '37290', label: 'HEALTH SERVICES FOR CHILDREN WITH SPECIAL NEEDS (37290)' },
  { value: '37283', label: 'AAG AMERICAN ADMINISTRATIVE GROUP (37283)' },
  { value: '37283', label: 'GALLAGHER BENEFITS ADMINISTRATOR / AAG (37283)' },
  { value: '37283', label: 'CINCINNATI FINANCIAL CORPORTATION (37283)' },
  { value: '37283', label: 'PITTMAN & ASSOCIATES (37283)' },
  { value: '37283', label: 'JSL ADMINISTRATORS (37283)' },
  { value: '37283', label: 'HEALTHSMART BENEFIT SOLUTIONS (37283)' },
  { value: '37283', label: 'AAG GALLAGHER BENEFITS (37283)' },
  { value: 'HSTN', label: 'CIGNA HEALTHSPRING TENNESSEE/ALABAMA (HSTN)' },
  { value: 'HSTN', label: 'HEALTHSPRING TENNESSEE (HSTN)' },
  { value: 'HSTN', label: 'CIGNA HEALTHSPRING BRAVO OF TN, AL (HSTN)' },
  { value: 'HSTN', label: 'OATH (THE) - A HEALTH PLAN OF ALABAMA INC.  (HSTN)' },
  { value: 'HSTN', label: 'HEALTHSPRING OF ALABAMA (HSTN)' },
  { value: 'HSTN', label: 'HEALTHSPRING INC. (HSTN)' },
  { value: 'THS01', label: 'CIGNA HEALTHSPRING BRAVO HEALTH TEXAS (THS01)' },
  { value: 'THS01', label: 'CIGNA HEALTHSPRING STAR+PLUS (THS01)' },
  { value: '61101', label: 'ARCADIAN MANAGEMENT SERVICES (61101)' },
  { value: '61101', label: 'HUMANA HEALTH PLAN - HMO (61101)' },
  { value: '61101', label: 'HUMANA MEDICAL PLAN - CA (61101)' },
  { value: '61101', label: 'MICHAEL REESE HMO PLAN (61101)' },
  { value: '61101', label: 'HUMANA GOLD PLAN (61101)' },
  { value: '61101', label: 'HUMANA GROUP HEALTH PLAN (61101)' },
  { value: '61101', label: 'VENCOR (61101)' },
  { value: '61101', label: 'HUMANA HEALTH (61101)' },
  { value: '61101', label: 'HUMANA HEALTH CHICAGO INS CO (61101)' },
  { value: '61101', label: 'PRIME HEALTH KANSAS CITY (61101)' },
  { value: '61101', label: 'OSF HEALTH PLANS (61101)' },
  { value: '61101', label: 'GROUP HEALTH INSURANCE (61101)' },
  { value: '61101', label: 'HMPK, INC (61101)' },
  { value: '61101', label: 'HPLAN, INC (61101)' },
  { value: '61101', label: 'HUMANA (61101)' },
  { value: '61101', label: 'HUMANA INSURANCE CO (61101)' },
  { value: '61101', label: 'KENTUCKY KARE (61101)' },
  { value: '61101', label: 'PRIME HEALTH MANAGEMENT SERVICES (61101)' },
  { value: '61101', label: 'PRIME HEALTH OF KANSAS (61101)' },
  { value: '61101', label: 'GROUP HEALTH INSURANCE - DELEWARE (61101)' },
  { value: '61101', label: 'HUMANA INSURANCE AGENCY (61101)' },
  { value: '61101', label: 'HUMANA MEDICAL PLAN - KY (61101)' },
  { value: '61101', label: 'PRIME BENEFIT SYSTEMS, INC (61101)' },
  { value: '61101', label: 'MOTOROLA, INC. (61101)' },
  { value: '61101', label: 'HEALTH VALUE MGMT - DELEWARE (61101)' },
  { value: '61101', label: 'HUMANA HEALTH PLAN (61101)' },
  { value: '61101', label: 'CARITEN SENIOR HEALTH\t (61101)' },
  { value: '61101', label: 'HEALTHY VALUE (61101)' },
  { value: '61101', label: 'HUMANA CARE PLAN (61101)' },
  { value: '61101', label: 'LINCOLN NATIONAL - PHOENIX, AZ (61101)' },
  { value: '61101', label: 'WISCONSIN HEALTH (61101)' },
  { value: '61101', label: 'OCHSNER HEALTH PLAN (61101)' },
  { value: '61101', label: 'IBA SELF FUNDED GROUP (61101)' },
  { value: '61101', label: 'CARE NETWORK (61101)' },
  { value: '22326', label: 'HORIZON NJ HEALTH (22326)' },
  { value: '88848', label: 'IOWA BLUE SHIELD (88848)' },
  { value: '88848', label: 'SOUTH DAKOTA BLUE SHIELD (88848)' },
  { value: '18049', label: 'IOWA MEDICAID (18049)' },
  { value: '25133', label: 'COVENTRY HEALTH CARE OF IOWA, INC. (25133)' },
  { value: '611', label: 'IDAHO BLUE SHIELD REGENCE (611)' },
  { value: 'BLUEC', label: 'IDAHO BLUE CROSS BOISE (BLUEC)' },
  { value: 'BLUEC', label: 'CAREPOINT (BLUEC)' },
  { value: 'BLUEC', label: 'BLUE CROSS OF IDAHO (BLUEC)' },
  { value: 'ID_MMIS_4_DXCMS', label: 'IDAHO MEDICAID (ID_MMIS_4_DXCMS)' },
  { value: 'IEHP1', label: 'INLAND EMPIRE HEALTH PLAN - DOS BEFORE 4/1/18 (IEHP1)' },
  { value: 'IEHP1', label: 'INLAND EMPIRE HEALTH PLAN - DOS AFTER 3/31/18 (IEHP1)' },
  { value: '00621', label: 'BLUE CROSS COMMUNITY FAMILY HEALTH PLAN (621)' },
  { value: '00621', label: 'HEALTH CARE SERVICE CORPORATION DBA BCBS OF IL (621)' },
  { value: '00621', label: 'ILLINOIS BLUE SHIELD (621)' },
  { value: '37-1320188', label: 'ILLINOIS MEDICAID (37-1320188)' },
  { value: '36331', label: 'PROFESSIONAL BENEFIT ADMINISTRATORS (OAK BROOK,IL) (36331)' },
  { value: '41600', label: 'ITASCA MEDICAL CARE (41600)' },
  { value: '41600', label: 'IMCARE (41600)' },
  { value: '00630', label: 'INDIANA BLUE SHIELD (630)' },
  { value: '00630', label: 'ANTHEM - CENTRAL, NORTHERN, SOUTHERN (630)' },
  { value: '56139', label: 'MAESTRO HEALTH (56139)' },
  { value: 'HP', label: 'INDIANA MEDICAID (HP)' },
  { value: '3519M', label: 'MDWISE HOOSIER HEALTHWISE (3519M)' },
  { value: '3135M', label: 'MDWISE HEALTHY INDIANA PLAN  (3135M)' },
  { value: 'TA720', label: 'INDEPENDENCE ADMINISTRATORS (TA720)' },
  { value: 'IUHPLNS', label: 'IU HEALTH PLANS (PAYER ID IUHPLNS) (IUHPLNS)' },
  { value: '00334', label: 'INTER VALLEY HEALTH PLAN (334)' },
  { value: '87726', label: 'TENNESSEE UNITEDHEALTHCARE DUAL COMPLETE (87726)' },
  { value: '87726', label: 'UNITED HEALTHCARE COMMUNITY PLAN - TENNCARE ONLY (87726)' },
  { value: '72189', label: 'JOHNS HOPKINS HEALTHCARE (72189)' },
  { value: '72189', label: 'PRIORITY PARTNERS MCO, EMPLOYEE HEALTH PLAN PPO (72189)' },
  { value: '52123', label: 'JOHNS HOPKINS USFHP (52123)' },
  { value: 'RH002', label: 'HEALTH SHARE OF OREGON CCO - KAISER PERMANENTE NW (RH002)' },
  { value: 'RH002', label: 'KAISER FOUNDATION HEALTH PLAN OF THE NORTHWEST (RH002)' },
  { value: 'RH002', label: 'KAISER PERMANENTE OF THE NORTHWEST (RH002)' },
  { value: '47171', label: 'MISSOURI BLUE SHIELD OF KANSAS CITY (47171)' },
  { value: '25133', label: 'COVENTRY HEALTH CARE KANSAS (25133)' },
  { value: '73100', label: 'KEMPTOM COMPANY (73100)' },
  { value: '73100', label: 'KEMPTOM GROUP ADMINISTRATORS (73100)' },
  { value: '37217', label: 'KEY BENEFIT ADMINISTRATORS, INC. (37217)' },
  { value: '37323', label: 'KEY SOLUTIONS (37323)' },
  { value: '23284', label: 'KEYSTONE FIRST (23284)' },
  { value: 'KPIC1', label: 'KAISER PERMANENTE INSURANCE CO SELF FUNDED (DELL) (KPIC1)' },
  { value: '47163', label: 'KANSAS BLUE SHIELD - TOPEKA (47163)' },
  { value: '481124839', label: 'KANSAS MEDICAID (481124839)' },
  { value: '42344', label: 'KEYSTONE FIRST COMMUNITY HEALTHCHOICES (42344)' },
  { value: 'RH010', label: 'KAISER FOUNDATION HEALTH PLAN MID-ATLANTIC STATES (RH010)' },
  { value: 'RH009', label: 'KAISER PERMANENTE OF N CA REGION (RH009)' },
  { value: 'RH009', label: 'KAISER PERMANENTE (RH009)' },
  { value: '94134', label: 'KAISER PERMANENTE OF S CA REGION (94134)' },
  { value: '94134', label: 'KAISER PERMANENTE (94134)' },
  { value: '77741', label: 'KEYSTONE FIRST VIP CHOICE (77741)' },
  { value: '00660', label: 'KY BCBS MEDICAID MANAGED CARE (660)' },
  { value: '00660', label: 'ANTHEM BLUE PREFERRED (660)' },
  { value: '00660', label: 'ANTHEM KY MEDICAID (660)' },
  { value: '00660', label: 'ANTHEM BLUE PREFERRED PLUS (660)' },
  { value: '00660', label: 'ANTHEM KENTUCKY BCBS (660)' },
  { value: '00660', label: 'KENTUCKY BLUE SHIELD (660)' },
  { value: '00660', label: 'FEP (660)' },
  { value: '00660', label: 'ANTHEM BLUE ACCESS PRODUCTS (660)' },
  { value: 'KYMEDICAID', label: 'KENTUCKY MEDICAID (KYMEDICAID)' },
  { value: '72107', label: 'LA BLUE ADVANTAGE (72107)' },
  { value: '53120', label: 'STATE EMPLOYEES GROUP BENEFITS PROGRAM (53120)' },
  { value: '53120', label: 'LOUISIANA BLUE SHIELD (53120)' },
  { value: 'LA-DHH-MEDICAID', label: 'LOUISIANA MEDICAID (LA-DHH-MEDICAID)' },
  { value: 'LA-DHH-MEDICAID', label: 'COMMUNITY HEALTH SOLUTIONS (LA-DHH-MEDICAID)' },
  { value: 'LA-DHH-MEDICAID', label: 'LOUISIANA MEDICAID - AMBULANCE (LA-DHH-MEDICAID)' },
  { value: '27357', label: 'AMERIHEALTH CARITAS LOUISIANA (27357)' },
  { value: '25133', label: 'COVENTRY HEALTH CARE OF LOUISIANA, INC (25133)' },
  { value: 'LACAR', label: 'LA CARE HEALTH PLAN (LACAR)' },
  { value: '13193', label: 'LOYAL AMERICAN LIFE INSURANCE/MEDICARE SUPPLEMENT (13193)' },
  { value: '13193', label: 'GREAT AMERICAN LIFE INS CO (MEDICARE SUPPLEMENT) (13193)' },
  { value: '13193', label: 'LOYAL AMERICAN LIFE INSUR CO MEDICARE SUPPLEMENT (13193)' },
  { value: '13193', label: 'PROVIDENT AMERICAN LIFE & HEALTH INS CO (CARE SUP) (13193)' },
  { value: '13193', label: 'UNITED TEACHER ASSOC INS CO (MEDICARE SUPPLEMENT) (13193)' },
  { value: '13193', label: 'CIGNA SUPPLEMENTAL BENEFITS (13193)' },
  { value: '13193', label: 'CONTINENTAL GENERAL INS CO (MEDICARE SUPPLEMENT) (13193)' },
  { value: '13193', label: 'AMERICAN RETIREMENT LIFE INS CO (MEDICARE SUPP) (13193)' },
  { value: '13193', label: 'CENTRAL RESERVE LIFE INS CO (MEDICARE SUPPLEMENT) (13193)' },
  { value: 'LIFE1', label: 'OPTUM MEDICAL NETWORK (LIFE1)' },
  { value: 'LIFE1', label: 'LIFEPRINT (LIFE1)' },
  { value: '31828', label: 'LIGHTHOUSE HEALTH PLAN (31828)' },
  { value: '23223', label: 'LOOMIS COMPANY (THE) (23223)' },
  { value: '00200', label: 'MASSACHUSETTS BLUE SHIELD (200)' },
  { value: 'DMA7384', label: 'MASSACHUSETTS MEDICAID (FORMAT 9) (DMA7384)' },
  { value: 'DMA7384', label: 'MASSACHUSETTS MEDICAID (FORMAT 5) (DMA7384)' },
  { value: '01260', label: 'MEDICAID MAGELLAN PSYCH NETWORK (1260)' },
  { value: '01260', label: 'MAGELLAN COMPLETE CARE OF ARIZONA (1260)' },
  { value: '01260', label: 'MAGELLAN COMPLETE CARE (1260)' },
  { value: '01260', label: 'MAGELLAN HEALTH SERVICES (MAGELLAN BEHAVIORAL HLTH (1260)' },
  { value: '01260', label: 'MAGELLAN HEALTHCARE (1260)' },
  { value: '11303', label: 'MAGNACARE (11303)' },
  { value: '11303', label: 'OSCAR HEALTH PLAN OF NEW YORK - DOS <1/1/17 (11303)' },
  {
    value: 'MEDICAL ASSOCIATES HEALTH',
    label: 'MEDICAL ASSOCIATES HEALTH PLANS - FEE FOR SERVICE (MEDICAL ASSOCIATES HEALTH)',
  },
  { value: 'MEDICAL ASSOCIATES HEALTH', label: 'MEDICAL ASSOC CLINIC HEALTH PLAN OF WI (MEDICAL ASSOCIATES HEALTH)' },
  { value: '4600012', label: 'MAPFRE (4600012)' },
  { value: '', label: 'MARICOPA CARE ADVANTAGE - ARIZONA ()' },
  { value: '9908', label: 'MARICOPA HEALTH PLAN - MARICOPA CARE ADVANTAGE (9908)' },
  { value: 'MHPIL', label: 'MERIDIANCOMPLETE ILLINOIS (MHPIL)' },
  { value: 'MCC02', label: 'MAGELLAN COMPLETE CARE OF VIRGINIA MEDICARE (MCC02)' },
  { value: '660396197P', label: 'MEDICAL CARD SYSTEMS (MCS) REFORMA (660396197P)' },
  { value: '930989307', label: 'TUALITY HEALTH ALLIANCE DOS AFTER 12/31/2019 (930989307)' },
  { value: '930989307', label: 'EASTERN OREGON CCO (MODA/ODS) (930989307)' },
  { value: '930989307', label: 'MULTI COUNTY CAPCARE (930989307)' },
  { value: '930989307', label: 'OREGON HEALTH PLAN (930989307)' },
  { value: '930989307', label: 'ODS HEALTH (930989307)' },
  { value: '930989307', label: 'MODA HEALTH (930989307)' },
  { value: '930989307', label: 'PROVIDENCE BEHAVIORAL (930989307)' },
  { value: '00690', label: 'MARYLAND BLUE SHIELD - CAREFIRST (690)' },
  { value: '00690', label: 'LOCAL 486 PLUMBERS AND STEAMFITTERS (690)' },
  { value: '74323', label: 'MEDICAL BENEFITS COMPAINES (NEWARK, OH) (74323)' },
  { value: '74323', label: 'MEDICAL BENEFITS MUTUAL LIFE INSURANCE COMPANY (74323)' },
  { value: '74323', label: 'MEDICAL BENEFITS MUTUAL (NEWARK, OH) (74323)' },
  { value: '74323', label: 'MEDICAL BENEFITS ADMINISTATORS, INC. (NEWARK, OH) (74323)' },
  { value: '526002033MCP', label: 'MARYLAND MEDICAID (526002033MCP)' },
  { value: '23160', label: 'MEDICO INSURANCE COMPANY (23160)' },
  { value: '76498', label: 'MARYLAND PHYSICIANS CARE DOS AFTER 12/31/2020 (76498)' },
  { value: '00680', label: 'MAINE BLUE SHIELD (680)' },
  { value: 'ME_MMIS_4_DXCMS', label: 'MAINE MEDICAID (ME_MMIS_4_DXCMS)' },
  { value: '56205', label: 'MEDCOST BENEFIT SERVICES (MBS) (56205)' },
  { value: '56205', label: 'PIEDMONT ADMINISTRATORS (56205)' },
  { value: '95655', label: 'MT. CARMEL  (95655)' },
  { value: '95655', label: 'MEDIGOLD HMO (95655)' },
  { value: '13123', label: 'MEDIGOLD PPO (13123)' },
  { value: 'MEDM1', label: 'MEDICA GOVERNMENT PROGRAMS (MEDM1)' },
  { value: '12422', label: 'MEDICA IFB (12422)' },
  { value: '12422', label: 'MEDICA CONNECT (12422)' },
  { value: '12422', label: 'ALTRU PRIME BY MEDICA (12422)' },
  { value: '431318969', label: 'MED-PAY, INC. (431318969)' },
  { value: '251MS', label: 'MEDSTAR SELECT (251MS)' },
  { value: '251MS', label: 'MEDSTAR MEDICARE CHOICE (251MS)' },
  { value: '74289', label: 'MENTAL HEALTH NETWORK - MHNET COVENTRY PSYCH (74289)' },
  { value: '13189', label: 'MERIDIAN ADVANTAGE PLAN OF ILLINOIS (13189)' },
  { value: '13189', label: 'MERIDIAN HEALTH PLAN (13189)' },
  { value: '', label: 'HENNEPIN HEALTH ()' },
  { value: '13265', label: 'METROPOLITAN HEALTH PLUS (13265)' },
  { value: '13265', label: 'METRO PLUS HEALTH PLAN (13265)' },
  { value: '13265', label: 'METROPLUS MA ADVANTAGE (13265)' },
  { value: '71890', label: 'MAYO MEDICAL PLAN (71890)' },
  { value: '71890', label: 'MEDICA HEALTH PLAN SOLUTIONS (71890)' },
  { value: '82832', label: 'MIAMI CHILDRENS HEALTH PLAN (82832)' },
  { value: '00710', label: 'BLUE CROSS COMPLETE OF MI (MIBCN) (710)' },
  { value: '00710', label: 'MICHIGAN BLUE SHIELD FEP (710)' },
  { value: '00710', label: 'MICHIGAN BLUE SHIELD (710)' },
  { value: '00710', label: 'MEDICARE PLUS BLUE - MEDICARE ADVANTAGE PLAN (710)' },
  { value: '00710', label: 'BLUE CARE NETWORK HMO (710)' },
  { value: '00710', label: 'MCARE (710)' },
  { value: '00710', label: 'BCBS COMPLETE (710)' },
  { value: 'D00111', label: 'MICHIGAN MEDICAID (D00111)' },
  { value: 'D00111', label: 'MICHIGAN CHILD (D00111)' },
  { value: '38337', label: 'UPPER PENINSULA HEALTH PLAN (38337)' },
  { value: 'JV', label: 'MOLINA HEALTHCARE OF MICHIGAN NON PAR - JVHL (JV)' },
  { value: '38334', label: 'MOLINA HEALTHCARE OF MICHIGAN (38334)' },
  { value: '38334', label: 'CAPE HEALTH PLAN (38334)' },
  { value: 'ABRI1', label: 'MOLINA HEALTHCARE OF WISCONSIN (ABRI1)' },
  { value: 'ABRI1', label: 'ABRI HEALTH PLAN (ABRI1)' },
  { value: '61799', label: 'MOLINA HEALTHCARE OF  IDAHO (61799)' },
  { value: '61325', label: 'PASSPORT HEALTH PLAN BY MOLINA HEALTHCARE (61325)' },
  { value: '33628', label: 'MERCY MARICOPA INTEGRATED CARE (33628)' },
  { value: '33628', label: 'MARICOPA HEALTH PLAN (33628)' },
  { value: '33628', label: 'MERCY CARE RBHA (33628)' },
  { value: 'MMMFL', label: 'MMM OF FLORIDA (MMMFL)' },
  { value: '660588600', label: 'MMM HEALTHCARE (660588600)' },
  { value: '660653763', label: 'MMM MULTI HEALTH (REFORM) (660653763)' },
  { value: '29076', label: 'MEDICAL MUTUAL OF OHIO - PROLINK (29076)' },
  { value: '29076', label: 'CAROLINA CARE PLAN, INC. (29076)' },
  { value: '81883', label: 'MUNICIPAL HEALTH BENEFIT FUND (81883)' },
  { value: '00562', label: 'MINNESOTA BLUE CROSS BLUE SHIELD HEALTH CARE PROGR (562)' },
  { value: '52629', label: 'UCARE CONNECT (52629)' },
  { value: '52629', label: 'UCARE PREPAID MEDICAL ASSISTANCE PROGRAM (PMAP) (52629)' },
  { value: '52629', label: 'UCARE CONNECT + MEDICARE  (52629)' },
  { value: '52629', label: 'UCARE MINNESOTA SENIOR HEALTH OPTIONS (MSHO) (52629)' },
  { value: '52629', label: 'UCARE MINNESOTA SENIOR CARE PLUS (MSC+) (52629)' },
  { value: '52629', label: 'UCARE OF MINNESOTA (52629)' },
  { value: '52629', label: 'UCARE MINNESOTACARE (MNCARE) (52629)' },
  { value: '55413', label: 'UCARE MEDICARE (55413)' },
  { value: '55413', label: 'ESSENTIACARE (55413)' },
  { value: '55413', label: 'UCARE SELECT (55413)' },
  { value: '55413', label: 'UCARE INDIVIDUAL & FAMILY PLANS M HEALTH FAIRVIEW (55413)' },
  { value: '55413', label: 'UCARE INDIVIDUAL & FAMILY PLANS  (55413)' },
  { value: '55413', label: 'UCARE MEDICARE M HEALTH FAIRVIEW NORTH MEMORIAL HE (55413)' },
  { value: '00241', label: 'MISSOURI BLUE SHIELD - ST LOUIS MO (241)' },
  { value: '431754897', label: 'MISSOURI MEDICAID (431754897)' },
  { value: '13174', label: 'MONTEFIORE CMO (13174)' },
  { value: '13174', label: 'MONTEFIORE HMO (13174)' },
  { value: '20934', label: 'MOLINA HEALTHCARE OF ILLINOIS (20934)' },
  { value: '81794', label: 'MOLINA HEALTHCARE OF PUERTO RICO (REFORM) (81794)' },
  { value: '65465', label: 'MORECARE (65465)' },
  { value: 'MPHC2', label: 'MARTINS POINT HEALTH CARE USFHP/GENERATIONS ADV (MPHC2)' },
  { value: '83269', label: 'MARY WASHINGTON HEALTH PLAN (83269)' },
  { value: '00230', label: 'MISSISSIPPI BLUE SHIELD (230)' },
  { value: '00230', label: 'MS BCBS - STATE EMPLOYEES (230)' },
  { value: '77032', label: 'MISSISSIPPI MEDICAID (77032)' },
  { value: '521995799', label: 'MEDSTAR FAMILY CHOICE (521995799)' },
  { value: 'BCBS', label: 'MONTANA BLUE SHIELD (BCBS)' },
  { value: 'BCBS', label: 'BLUE FOCUS POS (BCBS)' },
  { value: 'BCBS', label: 'BLUE PREFERRED PPO (BCBS)' },
  { value: '77039', label: 'MONTANA MEDICAID (77039)' },
  { value: '77039', label: 'MONTANA HELP PLAN (77039)' },
  { value: '82275', label: 'MUTUAL OF OMAHA MEDICARE ADVANTAGE (82275)' },
  { value: '71412', label: 'UNITED OF OMAHA (71412)' },
  { value: '71412', label: 'NATIONAL RURAL LETTER CARRIERS ASSOC (71412)' },
  { value: '71412', label: 'MUTUAL OF OMAHA (71412)' },
  { value: '71412', label: 'EXCLUSICARE (71412)' },
  { value: '71412', label: 'ASSURED LIFE ASSOCIATION (71412)' },
  { value: '71412', label: 'GPM LIFE (71412)' },
  { value: '71412', label: 'MUTUAL OF OMAHA (71412)' },
  { value: '71412', label: 'MUTUALLY PREFERRED (71412)' },
  { value: '53011', label: 'NATIONAL ASSOCIATION OF LETTER CARRIERS (53011)' },
  { value: '75190', label: 'NATIONAL CLAIMS ADMINISTRATION SERVICES (NCAS) (75190)' },
  { value: '560894904', label: 'NORTH CAROLINA BLUE SHIELD (560894904)' },
  { value: 'DNC00', label: 'NORTH CAROLINA MEDICAID (DNC00)' },
  { value: '560894904', label: 'BLUE MEDICARE PPO/HMO OF NORTH CAROLINA (560894904)' },
  { value: '00820', label: 'NORTH DAKOTA BLUE SHIELD (820)' },
  { value: '00760', label: 'NEBRASKA BLUE SHIELD (760)' },
  { value: 'NEMEDICAID', label: 'NEBRASKA MEDICAID (NEMEDICAID)' },
  { value: '25133', label: 'COVENTRY HEALTH CARE OF NEBRASKA, INC. (25133)' },
  { value: '25133', label: 'COVENTRY HEALTH CARE NEBRASKA MEDICAID (25133)' },
  { value: 'NHP', label: 'ALLWAYS HEALTH PARTNERS (NHP)' },
  { value: '95123', label: 'NEIGHBORHOOD HEALTH PARTNERSHIP OF FLORIDA (95123)' },
  { value: '00770', label: 'NEW HAMPSHIRE BLUE SHIELD (770)' },
  { value: '26000618', label: 'NEW HAMPSHIRE MEDICAID (26000618)' },
  { value: '77076', label: 'NETWORK HEALTH INSURANCE CORP MEDICARE (77076)' },
  { value: '22099', label: 'HORIZON BLUE SHIELD OF NJ FEP (22099)' },
  { value: '22099', label: 'HORIZON BLUE SHIELD OF NEW JERSEY (22099)' },
  { value: '22099', label: 'OMNIA HEALTH PLANS (22099)' },
  { value: '22099', label: 'NEW JERSEY BLUE CHOICE (22099)' },
  { value: '22099', label: 'HORIZON BLUE SHIELD OF NJ OUT OF AREA (22099)' },
  { value: '22099', label: 'NEW JERSEY BLUE SELECT (22099)' },
  { value: '610515', label: 'NEW JERSEY MEDICAID (610515)' },
  { value: '81264', label: 'NIPPON LIFE INSURANCE COMPANY OF AMERICA (81264)' },
  { value: '00790', label: 'BLUE CROSS MEDICARE ADVANTAGE (790)' },
  { value: '00790', label: 'NEW MEXICO BLUE SHIELD (790)' },
  { value: '00790', label: 'LOVELACE SANDIA HEALTH PLAN DOS >5/31/2014 (790)' },
  { value: '00790', label: 'LOVELACE MEDICARE ADVANTAGE (790)' },
  { value: '00790', label: 'BLUE SALUD (790)' },
  { value: 'NMMAD', label: 'NEW MEXICO MEDICAID (NMMAD)' },
  { value: '09824', label: 'MOLINA HEALTHCARE OF NEW MEXICO (9824)' },
  { value: '09824', label: 'LOVELACE SALUD (9824)' },
  { value: '09824', label: 'MOLINA SALUD (9824)' },
  { value: '16644', label: 'NOVA HEALTHCARE ADMINISTRATORS, INC. (GRAND ISLAND (16644)' },
  { value: '25133', label: 'NATIONAL LETTER CARRIERS ASSOCIATION (25133)' },
  { value: '170558746', label: 'NETWORK HEALTH OF MASSACHUSETTS (170558746)' },
  { value: '170558746', label: 'TUFTS HEALTH TOGETHER  (170558746)' },
  { value: '170558746', label: 'TUFTS HEALTH UNIFY (170558746)' },
  { value: '170558746', label: 'TUFTS HEALTH DIRECT (170558746)' },
  { value: '170558746', label: 'TUFTS HEALTH PUBLIC PLANS (170558746)' },
  { value: '39144', label: 'NETWORK HEALTH PLAN OF WISCONSIN (39144)' },
  { value: '39144', label: 'NETWORK HEALTH PLAN - AFFINITY HEALTH SYSTEM (39144)' },
  { value: '71080', label: 'NOVASYS HEALTH NETWORK (71080)' },
  { value: '00265', label: 'NEVADA BLUE SHIELD (265)' },
  { value: 'NWADM', label: 'TEAMSTERS MISCELLANEOUS SECURITY TRUST FUND  (NWADM)' },
  { value: 'NWADM', label: 'LAS VEGAS FIRE FIGHTERS LOCAL NO. 1285 SECURITY FU (NWADM)' },
  { value: 'NWADM', label: 'TEAMSTERS LOCAL 631 SECURITY FUND FOR SOUTHERN NEV (NWADM)' },
  { value: 'NWADM', label: 'SOUTHERN CALIFORNIA BAKERY DRIVERS SECURITY FUND   (NWADM)' },
  { value: 'NWADM', label: 'CENTRAL CALIFORNIA ELECTRICAL WORKERS HEALTH AND W (NWADM)' },
  { value: 'NWADM', label: 'NORTHWEST ADMINISTRATORS, INC. (NWADM)' },
  { value: '98798', label: 'NEW ERA LIFE INSURANCE COMPANY OF THE MIDWEST (98798)' },
  { value: '98798', label: 'NEW ERA LIFE INSURANCE COMPANY (98798)' },
  { value: '98798', label: 'PHILADELPHIA AMERICAN LIFE INSURANCE COMPANY (98798)' },
  { value: '00801', label: 'NEW YORK COMMUNITY BLUE HMO (801)' },
  { value: '00801', label: 'NEW YORK BLUE SHIELD WESTERN (801)' },
  { value: '00800', label: 'NEW YORK BLUE SHIELD OF NORTHEASTERN NEW YORK (800)' },
  { value: '141797357', label: 'NEW YORK MEDICAID - PHASE II (141797357)' },
  { value: '00805', label: 'NEW YORK BLUE SHIELD  CENTRAL REGION (805)' },
  { value: '00804', label: 'NEW YORK BLUE SHIELD OF ROCHESTER (804)' },
  { value: '00806', label: 'HMO BLUE (806)' },
  { value: '00806', label: 'NEW YORK BLUE CROSS UTICA/WATERTOWN (806)' },
  { value: '80141', label: 'HEALTHFIRST HEALTH PLAN OF NEW JJERSEY (80141)' },
  { value: '80141', label: 'HEALTHFIRST FAMILY HEALTH PLUS (FHP) (80141)' },
  { value: '80141', label: 'HEALTHFIRST COMPLETE CARE (80141)' },
  { value: '80141', label: 'SENIOR HEALTH PARTNERS A HEALTHFIRST COMPANY (80141)' },
  { value: '80141', label: 'HEALTHFIRST 65 PLUS (80141)' },
  { value: '80141', label: 'HEALTHFIRST PHSP (80141)' },
  { value: '80141', label: 'HEALTHFIRST OF NEW YORK (80141)' },
  { value: '80141', label: 'HEALTHFIRST MAXIMUM (80141)' },
  { value: '80141', label: 'HEALTHFIRST, INC. (NEW YORK) (80141)' },
  { value: '80141', label: 'CHILDHEALTH PLUS BY HEALTHFIRST (CHP) (80141)' },
  { value: '80141', label: 'HEALTHFIRST NEW YORK (COMMERCIAL) (80141)' },
  { value: '95308', label: 'NEW YORK INDEPENDENT HEALTH ASSOC (IHA) - WESTERN (95308)' },
  { value: '14165', label: 'MVP HEALTH CARE (14165)' },
  { value: '14165', label: 'MVP CHILD HEALTH PLUS (14165)' },
  { value: '14165', label: 'MVP MEDICAID MANAGED CARE (14165)' },
  { value: '14165', label: 'HUDSON HEALTH PLAN (14165)' },
  { value: '14165', label: 'NEW YORK PREFERRED CARE HMO (14165)' },
  { value: '00834', label: 'QUALCHOICE OF OHIO (834)' },
  { value: '00834', label: 'OHIO BLUE SHIELD (834)' },
  { value: '00834', label: 'UFCW - CENTRAL OHIO (834)' },
  { value: 'MMISODJFS', label: 'OHIO MEDICAID (MMISODJFS)' },
  { value: '20149', label: 'MOLINA HEALTHCARE OF OHIO (20149)' },
  { value: '00840', label: 'OKLAHOMA BLUE SHIELD (840)' },
  { value: '00840', label: 'LOVELACE MEDICARE ADVANTAGE OK (840)' },
  { value: '731476619', label: 'OKLAHOMA MEDICAID (731476619)' },
  { value: '73143', label: 'COMMUNITY CARE MANAGED HEALTHCARE PLANS OF OK (73143)' },
  { value: '25133', label: 'COVENTRYCARES OF MICHIGAN (25133)' },
  { value: '54154', label: 'OPTIMA HEALTH PLANS (54154)' },
  { value: '54154', label: 'SENTARA HEALTH MANAGEMENT (54154)' },
  { value: '54154', label: 'OPTIMA INSURANCE COMPANY (54154)' },
  { value: '54154', label: 'OPTIMA FAMILY CARE (54154)' },
  { value: 'ORDHS', label: 'OREGON MEDICAID (ORDHS)' },
  { value: '00851', label: 'OREGON BLUE SHIELD REGENCE (851)' },
  { value: 'OSCAR', label: 'OSCAR HEALTH (OSCAR)' },
  { value: 'OSCAR', label: 'OSCAR HEALTH PLAN OF NEW YORK - DOS >12/31/16 (OSCAR)' },
  { value: 'SX163', label: 'TRICARE OVERSEAS PROGRAM (SX163)' },
  { value: '06111', label: 'OXFORD HEALTH PLANS (6111)' },
  { value: '54771', label: 'CONNECT BLUE EPO (54771)' },
  { value: '54771', label: 'PENINSULA HEALTH CARE (54771)' },
  { value: '54771', label: 'HEALTH KEEPERS, INC. (54771)' },
  { value: '54771', label: 'PENNSYLVANIA BLUE CHOICE (54771)' },
  { value: '54771', label: 'PENNSYLVANIA PERSONAL CHOICE (54771)' },
  { value: '54771', label: 'PENNSYLVANIA BLUE SHIELD (54771)' },
  { value: '54771', label: 'PRIORITY HEALTH CARE (54771)' },
  { value: '54771V', label: 'CONNECT BLUE EPO (54771V)' },
  { value: '54771V', label: 'PENNSYLVANIA BLUE SHIELD CLARITY VISION (54771V)' },
  { value: '15460', label: 'COMMUNITY BLUE MEDICARE PPO (15460)' },
  { value: '15460', label: 'COMMUNITY BLUE MEDICARE PLUS PPO (15460)' },
  { value: '15460', label: 'PENNSYLVANIA FREEDOM BLUE MEDICARE ADVANTAGE (15460)' },
  { value: '236003113', label: 'PENNSYLVANIA MEDICAID (236003113)' },
  { value: '00361', label: 'CAPITAL BLUE CROSS - FACILITY CONTRACTED PROVIDERS (361)' },
  { value: '23045', label: 'CAPITAL BLUE CROSS - PHYSICIAN CONTRACTED PROVIDER (23045)' },
  { value: '54704', label: 'PENNSYLVANIA INDEPENDENCE BLUE CROSS (54704)' },
  { value: '95044', label: 'AMERIHEALTH NEW JERSEY/DELAWARE - HMO (95044)' },
  { value: '93688', label: 'PENNSYLVANIA INDEPENDENCE AMERIHEALTH DE PPO (93688)' },
  { value: '60061', label: 'PENNSYLVANIA INDEPENDENCE AMERIHEALTH NJ PPO (60061)' },
  { value: '95056', label: 'KEYSTONE EAST HMO (95056)' },
  { value: 'PASSE', label: 'SUMMIT COMMUNITY CARE (PASSE)' },
  { value: '660636242', label: 'PLAN DE SALUD HOSPITAL MENONITA (660636242)' },
  { value: 'HT000158-001', label: 'PUBLIC EMPLOYEE HEALTH PLAN (PEHP) (HT000158-001)' },
  { value: '72126', label: 'PEOPLES HEALTH NETWORK (72126)' },
  { value: '65391', label: 'PERFORMCARE (65391)' },
  { value: '65391', label: 'PERFORMCARE FKA CPHNP - BEHAVIORAL HEALTH MANAGED (65391)' },
  { value: '65391', label: 'CBHNP HEALTH CHOICES (65391)' },
  { value: '77003', label: 'PRESTIGE HEALTH CHOICE (77003)' },
  { value: '37330', label: 'PHP-FAMILY CARE OF MI (37330)' },
  { value: '37330', label: 'PHYSICIANS HEALTH PLAN MID-MICHIGAN / PHP (37330)' },
  { value: '83276', label: 'PHYSICIANS HEALTH PLAN OF MICHIGAN MEDICARE (83276)' },
  { value: '12399', label: 'PHYSICIAN HEALTH PLAN OF NORTHERN INDIANA - PHP (12399)' },
  { value: '39156', label: 'PHYSICIANS PLUS INSURANCE CORPORATION (39156)' },
  { value: '47027', label: 'PHYSICIANS MUTUAL INSURANCE COMPANY (47027)' },
  { value: '95397', label: 'PERSONAL INSURANCE ADMINISTRATORS (95397)' },
  { value: '660592131', label: 'PREFERRED MEDICARE CHOICE (660592131)' },
  { value: '660592131GHP', label: 'PREFERRED MEDICARE CHOICE (REFORM) (660592131GHP)' },
  { value: '04218', label: 'PAN AMERICAN LIFE INSURANCE GROUP (4218)' },
  { value: '411479744', label: 'PREFERRED ONE (411479744)' },
  { value: '411479744', label: 'PREFERRED ONE CORPORATION (411479744)' },
  { value: '411479744', label: 'PREFERRED ONE COMMUNITY HEALTH PLAN (411479744)' },
  { value: '93082', label: 'PROMINENCE HEALTH PLAN OF NEVADA (93082)' },
  { value: 'PRIORITY', label: 'PRIORITY HEALTH OF MICHIGAN (PRIORITY)' },
  { value: 'PRIORITY', label: 'CARE CHOICES HMO/PREFERRED CHOICES OF MICHIGAN PPO (PRIORITY)' },
  { value: '66917', label: 'PARKLAND HEALTHFIRST (66917)' },
  { value: '66917', label: 'PARKLAND COMMUNITY HEALTH PLAN (66917)' },
  { value: '66917', label: 'PARKLAND KIDSFIRST (66917)' },
  { value: '4198872500', label: 'PARAMOUNT (4198872500)' },
  { value: '4198872500', label: 'PARAMOUNT ADVANTAGE (4198872500)' },
  { value: '11678', label: 'PRIME WEST (11678)' },
  { value: '11678', label: 'PRIME HEALTH COMPLETE (11678)' },
  { value: 'SX133', label: 'HEALTH REPUBLIC INSURANCE COMPANY (SX133)' },
  { value: 'SX133', label: 'HEALTH SHARE OF OREGON CCO - PROVIDENCE HEALTH (SX133)' },
  { value: 'SX133', label: 'PROVIDENCE HEALTH PLAN (SX133)' },
  { value: 'SX133', label: 'PUBLIC EMPLOYEE BENEFITS BOARD (PEBB) (SX133)' },
  { value: 'SX133', label: 'OREGON SELECTCARE (SX133)' },
  { value: '25133', label: 'COVENTRY HEALTH CARE OF ILLINOIS (25133)' },
  { value: '973MA', label: 'TRIPLE-S ADVANVTAGE (973MA)' },
  { value: '00973', label: 'TRIPLE-S SALUD (973)' },
  { value: 'PHC100680301406', label: 'PARTNERSHIP HEALTHPLAN OF CALIFORNIA (PHC100680301406)' },
  { value: '20416', label: 'PACIFICSOURCE CCO (20416)' },
  { value: '20416', label: 'PACIFICSOURCE CCO - MEDICAID (20416)' },
  { value: '20416', label: 'PACIFICSOURCE COMMUNITY HEALTH SOLUTIONS (20416)' },
  { value: '20416', label: 'GORGE REGION (20416)' },
  { value: '93029', label: 'PACIFICSOURCE HEALTH PLANS (93029)' },
  { value: '93031', label: 'PACIFICSOURCE ADMINISTRATORS (PSA) (93031)' },
  { value: '20377', label: 'PACIFICSOURCE MEDICARE (20377)' },
  { value: '20377', label: 'PACIFICSOURCE COMMUNITY HEALTH PLAN INC. (20377)' },
  { value: '66008', label: 'PASSPORT ADVANTAGE (66008)' },
  { value: '73067', label: 'QUIKTRIP CORPORATION (73067)' },
  { value: 'QUARTZASO', label: 'QUARTZ ASO (QUARTZASO)' },
  { value: '35174', label: 'QUALCHOICE OF ARKANSAS (35174)' },
  { value: '35174', label: 'QCA HEALTH PLAN, INC. (35174)' },
  { value: '73066', label: 'RESERVE NATIONAL INSURANCE (73066)' },
  { value: 'RGA01', label: 'REGENCE GROUP ADMINISTRATORS (RGA01)' },
  { value: 'RHA01', label: 'RESOLVE - ST. REGIS MOWHAWK CONTRACT HEALTH SERVIC (RHA01)' },
  { value: 'RHA01', label: 'RESOLVE HEALTH PLAN ADMINISTRATORS (RHA01)' },
  { value: '00870', label: 'RHODE ISLAND WORKERS COMPENSATION (870)' },
  { value: '00870', label: 'RHODE ISLAND BLUE SHIELD (870)' },
  { value: '00870', label: 'BLUE CHIP (870)' },
  { value: '5047', label: 'NEIGHBORHOOD HEALTH PLAN OF RHODE ISLAND (5047)' },
  { value: '5047', label: 'RHODY HEALTH OPTIONS/NEIGHBORHOOD HEALTH PLAN RI (5047)' },
  { value: '840614905', label: 'ROCKY MOUNTAIN HMO (840614905)' },
  { value: '840614905', label: 'ROCKY MOUNTAIN PACE (840614905)' },
  { value: '74205', label: 'RIGHTCARE SCOTT & WHITE HEALTH PLAN (74205)' },
  { value: 'RX001', label: 'TRANSACTRX INFUSION AND SPECIALTY (RX001)' },
  { value: 'PARTD', label: 'TRANSACTRX PART D (PARTD)' },
  { value: '88030', label: 'SCOTT AND WHITE HEALTH PLAN (88030)' },
  { value: '00401', label: 'EMPLOYERS LIFE INSURANCE (401)' },
  { value: '00401', label: 'SC BCBS STATE EMPLOYEE HEALTH PLAN (401)' },
  { value: '00401', label: 'BLUE CHOICE HEALTH PLAN (401)' },
  { value: '00401', label: 'PLANNED ADMINISTRATORS (401)' },
  { value: '00401', label: 'CONSOLIDATED BENEFITS INC. (401)' },
  { value: '00401', label: '\tSOUTH CAROLINA BLUE CROSS BLUE SHIELD STATE EMPLO (401)' },
  { value: '00401', label: 'SC BCBS PREFERRED HEALTH SYSTEMS - HMO BLUE (401)' },
  { value: '00401', label: 'MEDICAL CLAIMS MANAGEMENT CORPORATION (401)' },
  { value: '00401', label: '\tFEDERAL EMPLOYEE HEALTH BENEFITS PROGRAM (FEP) (401)' },
  { value: '00401', label: 'PREFERRED BLUE (401)' },
  { value: '00401', label: 'CAROLINA BENEFIT ADMINISTRATORS (401)' },
  { value: '00401', label: 'MEDICARE BLUE (401)' },
  { value: '00401', label: 'EMPLOYEE BENEFIT SERVICES DBA KEY BENEFIT ADMIN (401)' },
  { value: '00401', label: 'SOUTH CAROLINA BLUE SHIELD (401)' },
  { value: '00401', label: 'THOMAS COOPER AGENCY (401)' },
  { value: 'SCXIX', label: 'SOUTH CAROLINA MEDICAID (SCXIX)' },
  { value: '24077', label: 'SANTA CLARA FAMILY HEALTH PLAN (24077)' },
  { value: '46299', label: 'MOLINA HEALTHCARE OF SOUTH CAROLINA (46299)' },
  { value: 'SCAN1', label: 'SCAN HEALTH PLAN (SCAN1)' },
  { value: '23285', label: 'FIRST CHOICE BY SELECT HEALTH OF SC (23285)' },
  { value: '23285', label: 'SELECT HEALTH OF SOUTH CAROLINA (23285)' },
  { value: 'SD48MED', label: 'SOUTH DAKOTA MEDICAID (SD48MED)' },
  { value: '39045', label: 'SECURITY HEALTH PLAN (39045)' },
  { value: '39045', label: 'SECURITY HEALTH PLAN OF WISCONSIN (39045)' },
  { value: '52214', label: 'SELMAN AND COMPANY (52214)' },
  { value: '943226498', label: 'HEALTHY WORKERS HMO (943226498)' },
  { value: '943226498', label: 'SAN FRANCISCO HEALTH PLAN (943226498)' },
  { value: '943226498', label: 'HEALTHY KIDS HMO (943226498)' },
  { value: 'SHP01', label: 'SHARP HEALTH PLAN (SHP01)' },
  { value: '76342', label: 'SIERRA HEALTH SERVICES, INC. (CLAIMS) (76342)' },
  { value: '77153', label: 'IU HEALTH PLAN COMMERCIAL BUSINESS (77153)' },
  { value: '77153', label: 'SOUTHEASTERN INDIANA HEALTH ORGANIZATION - SIHO (77153)' },
  { value: 'SISCO', label: 'SISCO (SISCO)' },
  { value: '68035', label: 'HEALTH PLAN OF SAN JOAQUIN (68035)' },
  { value: '73099', label: 'STANDARD LIFE AND ACCIDENT INS CO-SECONDARY CLAIMS (73099)' },
  { value: 'SMPLY', label: 'SIMPLY HEALTHCARE PLANS, INC. (SMPLY)' },
  { value: 'SHP', label: 'SANFORD HEALTH PLAN (SHP)' },
  { value: 'SHP', label: 'SOUTH DAKOTA CHIROPRACTIC (SANFORD HEALTH PLAN) (SHP)' },
  { value: '83035', label: 'SENIOR WHOLE HEALTH OF NY (83035)' },
  { value: '83035', label: 'SENIOR WHOLE HEALTH OF NY MLTC (83035)' },
  { value: '83035', label: 'SENIOR WHOLE HEALTH (83035)' },
  { value: '83035', label: 'SENIOR WHOLE HEALTH OF NY MEDICAID ADVANTAGE PLUS (83035)' },
  { value: '87020', label: 'CATHOLIC LIFE INSURANCE (87020)' },
  { value: '87020', label: 'NATIONAL GUARDIAN LIFE INSURANCE COMPANY (87020)' },
  { value: '87020', label: 'RENAISSANCE LIFE AND HEALTH INSURANCE COMPANY OF A (87020)' },
  { value: '87020', label: 'POLISH FALCONS OF AMERICA (87020)' },
  { value: '87020', label: 'CATHOLIC UNITED FINANCIAL (87020)' },
  { value: '87020', label: 'SENTINEL SECURITY LIFE (87020)' },
  { value: '87020', label: 'PAN-AMERICAN LIFE INSURANCE COMPANY (87020)' },
  { value: '74227', label: 'CHESAPEAKE LIFE INSURANCE CO-STUDENT INSURANCE (74227)' },
  { value: '74227', label: 'STUDENT INSURANCE-MIDWEST NATIONAL LIFE INS OF TN (74227)' },
  { value: '74227', label: 'MEGA LIFE & HEALTH INSURANCE CO-STUDENT INSURANCE (74227)' },
  { value: '74227', label: 'MIDWEST NATIONAL LIFE INS OF TN-STUDENT INSURANCE (74227)' },
  { value: '74227', label: 'STUDENT INSURANCE-MEGA LIFE & HEALTH INSURANCE CO (74227)' },
  { value: '31053', label: 'STATE FARM INSURANCE COMPANIES (31053)' },
  { value: '31053', label: 'INDIVIDUAL HEALTH INSURANCE COMPANIES (31053)' },
  { value: '88029', label: 'PROMINENCE HEALTH PLAN PPO/HMO (88029)' },
  { value: '88029', label: 'SAINT MARYS HEALTH PLAN (88029)' },
  { value: '88029', label: 'ST. MARYS HEALTH PLAN (88029)' },
  { value: '95202', label: 'SUMMACARE HEALTH PLAN (95202)' },
  { value: '13407', label: 'ST. VINCENT CATHOLIC MEDICAL CENTER NEW YORK (13407)' },
  { value: 'TRCLF', label: 'TRANSAMERICA PREMIER LIFE INSURANCE COMPANY (TRCLF)' },
  { value: 'THRIV', label: 'THRIVENT FINANCIAL (THRIV)' },
  { value: '30167', label: 'THRIVENT FINANCIAL AID ASSOC FOR LUTHERAN MEDICARE (30167)' },
  { value: '30166', label: 'THRIVENT FINANCIAL LUTHERAN BROTHERHOOD MEDICARE (30166)' },
  { value: '00890', label: 'TENNESSEE BLUE SHIELD (890)' },
  { value: '00890', label: 'VOLUNTEER STATE HEALTH PLAN (890)' },
  { value: '00890', label: 'BLUECARE - BCBSTN (890)' },
  { value: '00890', label: 'TENNESSEE BLUECARE (890)' },
  { value: '00890', label: 'TENNCARE SELECT (890)' },
  { value: 'TDFIC', label: 'TRICARE FOR LIFE (TDFIC)' },
  { value: 'SCWI0', label: 'TRICARE - WEST REGION (SCWI0)' },
  { value: 'TREST', label: 'TRICARE - EAST REGION (TREST)' },
  { value: '61425', label: 'TRUSTMARK SMALL BUSINESS BENEFITS (61425)' },
  { value: '82288', label: 'TRUE HEALTH NEW MEXICO (82288)' },
  { value: '170558746', label: 'TUFTS HEALTH PLANS (170558746)' },
  { value: '170558746', label: 'TUFTS MEDICARE PREFERRED MEDICARE ADVANTAGE (170558746)' },
  { value: '170558746', label: 'SECURE HORIZONS - TUFTS HEALTH PLAN FOR SENIORS (170558746)' },
  { value: '170558746', label: 'TOTAL HEALTH PLAN INC (170558746)' },
  { value: '170558746', label: 'CAREPARTNERS OF CONNECTICUT (170558746)' },
  { value: '170558746', label: 'TUFTS ASSOCIATED HEALTH PLAN (170558746)' },
  { value: '170558746', label: 'US FAMILY HEALTH PLAN (170558746)' },
  { value: '84980', label: 'TEXAS BLUE SHIELD (84980)' },
  { value: '84980', label: 'BRYAN INDEPENDENT SCHOOL (84980)' },
  { value: '617591011C21P', label: 'CSHCN (CHILDREN WITH SPECIAL HEALTH CARE NEEDS) (617591011C21P)' },
  { value: '617591011C21P', label: 'TEXAS MEDICAID (617591011C21P)' },
  { value: '617591011C21P', label: 'RIGHT CARE FROM SCOTT & WHITE HEALTH PLAN (617591011C21P)' },
  { value: '76048', label: 'TEXAS CHILDRENS HEALTH PLAN - CHIP (76048)' },
  { value: '20554', label: 'MOLINA HEALTHCARE OF TEXAS (20554)' },
  { value: '94265', label: 'MEDICA HEALTH PLANS (ACCESSABILITY SOLUTION) (94265)' },
  { value: '94265', label: 'ALTRU & YOU (94265)' },
  { value: '94265', label: 'MEDICA HEALTLH PLAN (94265)' },
  { value: '94265', label: 'RIDGEVIEW COMMUNITY NETWORK (94265)' },
  { value: '65088', label: 'CAREFLORIDA (65088)' },
  { value: '65088', label: 'PREFERRED CARE PARTNERS (65088)' },
  { value: '87726', label: 'UNITEDHEALTHCARE COMMUNITY PLAN FLORIDA (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE COMMUNITY PLAN LOUISIANA (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE COMMUNITY PLAN TEXAS  (87726)' },
  { value: '87726', label: 'OPTUMHEALTH BEHAVIORAL SOLUTIONS (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE COMMUNITY PLAN MS CAN (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE COMMUNITY PLAN - MS CHIP (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE COMMUNITY PLAN NEBRASKA (87726)' },
  { value: '87726', label: 'HARVARD PILGRIM PASSPORT CONNECT (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE COMMUNITY PLAN HAWAII (87726)' },
  { value: '87726', label: 'AARP MEDICARE COMPLETE (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE DUAL COMPLETE PLAN OF MISSOURI (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE MEDICARE GOLD (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE MEDICARE GOLD (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE DUAL COMPLETE CHOICE (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE DUAL COMPLETE CHOICE (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE DUAL COMPLETE CHOICE (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE MEDICARECOMPLETE ASSIST (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE MEDICARECOMPLETE OPEN PREMIER (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE COMMUNITY PLAN NEW MEXICO (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE MEDICARE COMPLETE (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE COMMUNITY PLAN MARYLAND (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE DUAL COMPLETE (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE COMMUNITY PLAN TENNESSEE (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE DUAL COMPLETE CHOICE (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE DUAL COMPLETE CHOICE (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE MEDICARECOMPLETE CHOICE (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE MEDICARECOMPLETE OPEN (87726)' },
  { value: '87726', label: 'UNITED HEALTHCARE (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE DUAL COMPLETE PLAN OF FLORIDA (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE DUAL COMPLETE PLAN OF TEXAS (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE COMMUNITY PLAN NEW YORK (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE DUAL COMPLETE PLAN OF NEW MEXICO (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE COMMUNITY PLAN NEW JERSEY (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE MEDICARECOMPLETE CHOICE (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE MEDICARECOMPLETE CHOICE (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE MEDICARE SILVER (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE DEFINITY HEALTH PLAN (87726)' },
  { value: '87726', label: 'DEFINITY HEALTH (87726)' },
  { value: '87726', label: 'UNITED HEALTHCARE COMMUNITY PLAN OF KENTUCKY (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE COMMUNITY PLAN OHIO (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE COMMUNITY PLAN IOWA (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE COMMUNITY PLAN MASSACHUSETTS SCO (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE COMMUNITY PLAN RHODE ISLAND (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE MEDICARE SOLUTIONS FKA SECUREHORI (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE COMMUNITY PLAN MISSISSIPPI (87726)' },
  { value: '87726', label: 'PHYSICIAN HEALTH PARTNERS MEDICARE (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE COMMUNITY PLAN OF MASSACHUSETTS (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE COMMUNITY PLAN OF NEW MEXICO CENT (87726)' },
  { value: '87726', label: 'UNITED HEALTHCARE OF THE MID-ATLANTIC (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE MEDICARECOMPLETE CHOICE (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE MEDICARE SILVER (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE MEDICARE SILVER (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE LONG TERM CARE (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE DUAL COMPLETE PLAN OF WASHINGTON (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE CHRONIC COMPLETE (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE COMMUNITY PLAN DELAWARE (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE EMPIRE PLAN (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE COMMUNITY PLAN PENNSYLVANIA (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE MEDICARECOMPLETE CHOICE (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE MEDICARE SILVER (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE MEDICARE GOLD (87726)' },
  { value: '87726', label: 'CARE IMPROVEMENT PLUS (87726)' },
  { value: '87726', label: 'OPTUMHEALTH BEHAVIORAL SOLUTIONS OF NEW MEXICO (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE WEST (AZ,CA,CO,NV,OK,OR,TX,WA) (87726)' },
  { value: '87726', label: 'ARNETT HEALTH PLAN (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE DUAL COMPLETE PLAN OF HAWAII (87726)' },
  { value: '87726', label: 'NEW MEXICO CENTENNIAL CARE PLAN (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE MEDICARE GOLD (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE MAHP-MD IPA, OPTIMUM CHOICE (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE NURSING HOME PLAN (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE DUAL COMPLETE PLAN OF OHIO (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE GROUP MEDICARE ADVANTAGE (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE COMMUNITY PLAN WISCONSIN (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE COMMUNITY PLAN WASHINGTON (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE DUAL COMPLETE PLAN OF WISCONSIN (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE COMMUNITY PLAN OF HAWAII QUEST IN (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE MEDICARE SILVER (87726)' },
  { value: '87726', label: 'UNITEDHEALTHCARE MEDICARE GOLD (87726)' },
  { value: '78857', label: 'MEDICA HEALTHCARE PLAN OF FLORIDA (78857)' },
  { value: '95958', label: 'UNITEDHEALTHCARE WEST - PACIFICARE OF WASHINGTON (95958)' },
  { value: '95467', label: 'UNITEDHEALTHCARE COMMUNITY PLAN OF MICHIGAN (95467)' },
  { value: '95467', label: 'GREAT LAKES HEALTH PLAN (95467)' },
  { value: '86050', label: 'UNITEDHEALTHCARE COMMUNITY PLAN OF MISSOURI (86050)' },
  { value: '77022', label: 'ULTIMATE HEALTH PLANS (77022)' },
  { value: '39026', label: 'UMR ORLANDO HEALTH NETWORK (39026)' },
  { value: '39026', label: 'WAUSAU INSURANCE COMPANIES (39026)' },
  { value: '39026', label: 'GREEN BAY HEALTH PROTECTION PLAN (39026)' },
  { value: '39026', label: 'HEY HEALTH PLAN (39026)' },
  { value: '39026', label: 'LA CROSSE CARE PLUS (39026)' },
  { value: '39026', label: 'UMP - MIDWEST SECURITIES (39026)' },
  { value: '39026', label: 'WAUSAU PREFERRED HEALTH PLANS (39026)' },
  { value: '39026', label: 'MIDWEST PREFERRED (39026)' },
  { value: '39026', label: 'BENEFIT PLANNERS, INC. (39026)' },
  { value: '39026', label: 'UNITED MEDICAL RESOURCES ONALASKA (39026)' },
  { value: '39026', label: 'EAU CLAIRE PROVIDERS CHOICE (39026)' },
  { value: '39026', label: 'UICI - ADMINISTRATORS - STATE OF NEVADA\t (39026)' },
  { value: '39026', label: 'OSHKOSH AREA HEALTH PROTECTION PLAN (39026)' },
  { value: '39026', label: 'UMR FKA UMR ONALASKA (39026)' },
  { value: '39026', label: 'UMR-HARRINGTON HEALTH (39026)' },
  { value: '39026', label: 'TRANS HEALTH PROTECTION PLAN (THE) (39026)' },
  { value: '39026', label: 'EAU CLAIRE HEALTH PROTECTION PLAN (39026)' },
  { value: '39026', label: 'GREATER LA CROSSE CARE 65+ (39026)' },
  { value: '39026', label: 'GREATER WISCONSIN RAPIDS PROVIDER CHOICE (39026)' },
  { value: '39026', label: 'EMPLOYERS INSURANCE OF WAUSAU (39026)' },
  { value: '39026', label: 'UMR FKA UMR WAUSAU (39026)' },
  { value: '39026', label: 'COMMUNITY PREFERRED HEALTH PLAN (39026)' },
  { value: '39026', label: 'UNITED MEDICAL RESOURCES (39026)' },
  { value: '39026', label: 'UMR CINCINNATI REPRICING VIA HEALTHSPAN (39026)' },
  { value: '39026', label: 'ECOH (39026)' },
  { value: '39026', label: 'HARRINGTON BENEFIT SERVICES, INC. (39026)' },
  { value: '39026', label: 'KEY HEALTH PLAN (39026)' },
  { value: '39026', label: 'EHC (39026)' },
  { value: '52180', label: 'UMWA HEALTH AND RETIREMENT FUNDS (52180)' },
  { value: '80314', label: 'UNICARE (80314)' },
  { value: '80314', label: 'GIC INDEMNITY PLAN (80314)' },
  { value: '45437', label: 'UNIVERSITY HEALTHCARE MARKETPLACE (45437)' },
  { value: 'UNINW', label: 'UNIVERA (UNINW)' },
  { value: 'UNINW', label: 'UNIVERA UNINW (UNINW)' },
  { value: 'UNINW', label: 'UNIVERA WNY (UNINW)' },
  { value: 'UNINW', label: 'UNIVERA ENY (UNINW)' },
  { value: 'UNINW', label: 'UNIVERA SNY (UNINW)' },
  { value: 'UNINW', label: 'RMSCO UNIVERA (UNINW)' },
  { value: 'UNINW', label: 'UNIVERA COMMUNITY HEALTH (UNINW)' },
  { value: '41194', label: 'OPTUMHEALTH CARE SOLUTIONS (41194)' },
  { value: '1UNTY', label: 'QUARTZ BENEFITS UNDERWRITTEN BY UNITY HEALTH PLANS (1UNTY)' },
  { value: '1UNTY', label: 'UNITY HEALTH PLANS (1UNTY)' },
  { value: '1UNTY', label: 'UNITY HEALTH INSURANCE (1UNTY)' },
  { value: '1UNTY', label: 'SENIOR PREFERRED (1UNTY)' },
  { value: 'HT001755-300', label: 'ADVANTAGE U MEDICARE (HT001755-300)' },
  { value: 'HT001755-300', label: 'UNIVERSITY OF UTAH HEALTHY U (HT001755-300)' },
  { value: 'HT001755-300', label: 'MONTANA AND MOUNTAIN HEALTH CO-OPS (MHC) (HT001755-300)' },
  { value: 'HT001755-300', label: 'UNIVERSITY OF UTAH HEALTH PLAN (HT001755-300)' },
  { value: '95216', label: 'UPMC FOR BEST HEALTH (95216)' },
  { value: '95216', label: 'UPMC HEALTH PLAN (95216)' },
  { value: '95216', label: 'UPMC FOR LIFE (95216)' },
  { value: '87042', label: 'IRON ROAD HEALTHCARE (87042)' },
  { value: '74095', label: 'USAA (74095)' },
  { value: '74095', label: 'UNITED SERVICES AUTOMOBILE ASSOCIATION ( (74095)' },
  { value: 'SKUT0', label: 'UTAH MEDICAID (SKUT0)' },
  { value: 'HT000001-100', label: 'UTAH BLUE SHIELD - FEP (HT000001-100)' },
  { value: 'SX109', label: 'HEALTHY ADVANTAGE PLUS (SX109)' },
  { value: 'SX109', label: 'HEALTHY ADVANTAGE (SX109)' },
  { value: 'SX109', label: 'MOLINA HEALTHCARE OF UTAH (SX109)' },
  { value: 'HT000001-100', label: 'UTAH REGENCE BLUE CROSS BLUE SHIELD (HT000001-100)' },
  { value: 'HT000015-001', label: 'SELECTHEALTH COMMUNITY CARE (HT000015-001)' },
  { value: 'HT000015-001', label: 'SELECTHEALTH (HT000015-001)' },
  { value: '00423', label: 'VIRGINIA BLUE SHIELD (423)' },
  { value: '00423', label: 'HEALTHKEEPERS INC. (423)' },
  { value: '00423', label: 'ANTHEM HEALTHKEEPERS PLUS (423)' },
  { value: 'VMAP FHSC FA', label: 'VIRGINIA MEDICAID (VMAP FHSC FA)' },
  { value: 'VMAP FHSC FA', label: 'VIRGINIA MEDICAID LAB (VMAP FHSC FA)' },
  { value: '26176', label: 'MOLINA HEALTHCARE OF VIRGINIA (26176)' },
  { value: '72128', label: 'VANTAGE HEALTH PLAN (72128)' },
  { value: '12115', label: 'VETERANS ADMINISTRATION FEE BASIS PROGRAMS (12115)' },
  { value: '84146', label: 'CHILDREN OF WOMEN VIETNAM VETERANS - VA HAC (84146)' },
  { value: '84146', label: 'CHAMPVA - HAC (84146)' },
  { value: '84146', label: 'SPINA BIFIDA - VA HAC (84146)' },
  { value: '15976', label: 'VIBRA HEALTH PLAN (15976)' },
  { value: '63114', label: 'VIVA HEALTH CARE AND VIVA MEDICARE (63114)' },
  { value: '45488', label: 'VIVIDA HEALTH PLAN DOS BEFORE 1/1/2021 (45488)' },
  { value: '77073', label: 'VISITING NURSE SERVICE OF NEW YORK (77073)' },
  { value: '77073', label: 'VNSNY CHOICE (77073)' },
  { value: '77073', label: 'VNSNY CHOICE TOTAL (77073)' },
  { value: '77073', label: 'NY PRESBYTERIAN SELECT HEALTH (77073)' },
  { value: '77073', label: 'VISITING NURSE SERVICE OF NEW YORK/CHOICE SNP (77073)' },
  { value: 'BCBSVT', label: 'VERMONT BLUE SHIELD (BCBSVT)' },
  { value: '752548221', label: 'VERMONT MEDICAID (752548221)' },
  { value: '77045', label: 'WASHINGTON STATE DEPARTMENT OF CORRECTIONS (77045)' },
  { value: '77045', label: 'WASHINGTON MEDICAID (77045)' },
  { value: '77045', label: 'APPLE HEALTH AKA WASHINGTON MEDICAID (77045)' },
  { value: 'CHPWA', label: 'COMMUNITY HEALTH PLAN OF WA (CHPWA) (CHPWA)' },
  { value: 'CHPWA', label: 'HEALTHY OPTIONS (CHPWA)' },
  { value: 'CHPWA', label: 'BASIC HEALTH (CHPWA)' },
  { value: '38336', label: 'MOLINA HEALTHCARE OF WASHINGTON (38336)' },
  { value: '93093', label: 'LIFEWISE HEALTHPLAN OF OREGON (93093)' },
  { value: '00430', label: 'BLUE CROSS WA/AK PREMERA BLUE CROSS (430)' },
  { value: '00430', label: 'LIFEWISE OF WASHINGTON (430)' },
  { value: '00430', label: 'BLUE CROSS WA/AK PREMERA BLUE CROSS (430)' },
  { value: '00932', label: 'UMP PLUS (932)' },
  { value: '00932', label: 'WASHINGTON BLUE SHIELD REGENCE (932)' },
  { value: '39151', label: 'WISCONSIN EDUCATORS ASSOCIATION (WEA) (39151)' },
  { value: '75261', label: 'CENTURY HEALTHCARE (75261)' },
  { value: '75261', label: 'WATERSTONE BENEFIT ADMINISTRATORS (75261)' },
  { value: '75261', label: 'COMMUNITY HEALTH ELECTRONIC CLAIMS -CHEC (75261)' },
  { value: '75261', label: 'TEAM CHOICE PINS (75261)' },
  { value: '75261', label: 'TEAM CHOICE UMC (75261)' },
  { value: '75261', label: 'TEAM CHOICE GOLD (75261)' },
  { value: '75261', label: 'ALPHA DATA SYSTEMS (75261)' },
  { value: '75261', label: 'CHEC - A SUBSIDIARY OF SPRINT (75261)' },
  { value: '14163', label: 'WELLCARE OF KENTUCKY (14163)' },
  { value: '14163', label: 'WELLCARE PFFS (14163)' },
  { value: '14163', label: 'STERLING MEDICARE ADVANTAGE DOS AFTER 04/30/14 (14163)' },
  { value: '14163', label: 'HARMONY HEALTH PLAN LTSS  (14163)' },
  { value: '14163', label: 'SELECTCARE OF TEXAS (MEMORIAL CLINICAL ASSOCIATES) (14163)' },
  { value: '14163', label: 'KATY MEDICAL GROUP (14163)' },
  { value: '14163', label: 'HEALTHEASE FLORIDA (14163)' },
  { value: '14163', label: 'HEALTHEASE KIDS FLORIDA (14163)' },
  { value: '14163', label: 'SELECTCARE OF TEXAS (BEAUMONT) (14163)' },
  { value: '14163', label: 'SELECTCARE OF TEXAS (KELSEY-SEYBOLD) (14163)' },
  { value: '14163', label: 'TODAYS OPTIONS (14163)' },
  { value: '14163', label: 'WELLCARE OF  CT, FL, GA, LA, NJ, NY, OH, TX, SC (14163)' },
  { value: '14163', label: 'OHANA HEALTH PLAN HAWAII (14163)' },
  { value: '14163', label: 'WELLCARE LIBERTY (HMO SNP) (14163)' },
  { value: '14163', label: 'STAYWELL KIDS FLORIDA (14163)' },
  { value: '14163', label: 'WELLCARE ADVOCATE (14163)' },
  { value: '14163', label: 'WINDSOR HEALTH PLAN (14163)' },
  { value: '14163', label: 'EASY CHOICE HEALTH PLAN OF CALIFORNIA (14163)' },
  { value: '14163', label: 'STAYWELL FLORIDA (14163)' },
  { value: '14163', label: 'SELECTCARE OF TX (HOUSTON) (14163)' },
  { value: '14163', label: 'CMS TITLE 19 REFORM (14163)' },
  { value: '14163', label: 'HARMONY HEALTH PLAN OF ILLINOIS, INDIANA, MISSOURI (14163)' },
  { value: '14163', label: 'WELLCARE HEALTH PLAN (14163)' },
  { value: '14163', label: 'SELECTCARE OF TX (HPN) HERITAGE PHYSICIAN NETWORK (14163)' },
  { value: '14163', label: 'WELLCARE OF SC (14163)' },
  { value: '14163', label: 'CENTRAL SENIOR CARE (14163)' },
  { value: '14163', label: 'NORTHWEST DIAGNOSTIC (14163)' },
  { value: '14163', label: 'CMS TITLE 21 (14163)' },
  { value: '14163', label: 'TEXAS FIRST HEALTH PLAN (14163)' },
  { value: '14163', label: 'GOLDEN TRIANGLE / GTPA (14163)' },
  { value: '00950', label: 'WISCONSIN BLUE SHIELD (950)' },
  { value: '00950', label: 'COMMUNITYCONNECT HEALTH PLAN (950)' },
  { value: '00950', label: 'CLAIMS MANAGEMENT SERVICES, INC. (950)' },
  { value: 'WISC_TXIX', label: 'WISCONSIN MEDICAID (WISC_TXIX)' },
  { value: 'WISC_WCDP', label: 'WISCONSIN CHRONIC DISEASE PROGRAM (WISC_WCDP)' },
  { value: 'WISC_WWWP', label: 'WISCONSIN WELL WOMENS PROGRAM (WISC_WWWP)' },
  { value: '25133', label: 'COVENTRY HEALTH AND LIFE WELLPATH (25133)' },
  { value: 'WPS', label: 'RAKE (WPS)' },
  { value: 'WPS', label: 'HIRSP HEALTH INSURANCE RISK SHARING PLAN (WPS)' },
  { value: 'WPS', label: 'WPS COMMERCIAL / REGULAR BUSINESS (WPS)' },
  { value: 'WPS', label: 'MCDFC - MILWAUKEE COUNTY DEPT OF FAMILY CARE (WPS)' },
  { value: 'WPS', label: 'MCFC - MY CHOICE FAMILY CARE (WPS)' },
  { value: 'WPS', label: 'WPS HEALTHYCHOICES ILLINOIS (WPS)' },
  { value: 'WPS', label: 'CLTS - CHILDRENS LONGTERM SUPPORT WAIVER (WPS)' },
  { value: 'WPS', label: 'WPS COMERCIAL / REGULAR BUSINESS (WPS)' },
  { value: 'WPS', label: 'EPIC LIFE INSURANCE (WPS)' },
  { value: 'WPS', label: 'CCCW - COMMUNITY CARE OF CENTRAL WISCONSIN (WPS)' },
  { value: 'WPS', label: 'ASPIRUS ARISE HEALTH PLAN (WPS)' },
  { value: 'WPS', label: 'CONTINUUS FKA SFCA (WPS)' },
  { value: 'WPS', label: 'LAKELAND CARE DISTRICT (WPS)' },
  { value: 'WPS', label: 'WPS HEALTH PLAN (WPS)' },
  { value: '68039', label: 'WESTERN HEALTH ADVANTAGE (68039)' },
  { value: '31048', label: 'WESTERN SOUTHERN LIFE GROUP (31048)' },
  { value: '15459', label: 'WEST VIRGINIA FREEDOM BLUE MEDICARE ADVANTAGE (15459)' },
  { value: '15459', label: 'HIGHMARK SENIOR SOLUTIONS COMPANY (15459)' },
  { value: '54828', label: 'MOUNTAIN STATE BLUE SHIELD (WEST VIRGINIA) (54828)' },
  { value: 'WV_MMIS_4MOLINA', label: 'WEST VIRGINIA MEDICAID (WV_MMIS_4MOLINA)' },
  { value: '00960', label: 'WYOMING BLUE SHIELD (960)' },
  { value: '77046', label: 'WYOMING MEDICAID (77046)' },
  { value: '15003', label: 'YOURCARE HEALTH PLAN (DOS PRIOR TO 07012020) (15003)' },
  { value: '83248', label: 'ZING HEALTH (83248)' },
  { value: '81671', label: 'AMERIHEALTH CARITAS NORTH CAROLINA (81671)' },
  { value: '32691', label: 'VITORI HEALTH (32691)' },
  { value: 'NC_TRACKS_PAPER', label: 'NC TRACKS (NC_TRACKS_PAPER)' },
  { value: '0002937', label: 'ANTHEM OHIO MEDICAID (0002937)' },
  { value: '61103', label: 'HUMANA OHIO MEDICAID (61103)' },
  { value: '0003150', label: 'CARESOURCE OHIO MEDICAID (0003150)' },
  { value: '73160', label: 'MOLINA OHIO MEDICAID (73160)' },
  { value: '88337', label: 'UNITED HEALTHCARE OHIO MEDICAID (88337)' },
  { value: '0004202', label: 'BUCKEYE OHIO MEDICAID (0004202)' },
  { value: '37077-NOCD', label: 'COLONIAL PENN LIFE INSURANCE COMPANY (37077-NOCD)' },
  { value: '52189', label: 'JOHNS HOPKINS HEALTHCARE (52189)' },
  { value: '95378', label: 'UNITEDHEALTHCARE COMMUNITY PLAN - MS CHIP (93578)' },
];
