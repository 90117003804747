import React, { SyntheticEvent, useState } from 'react';
import { Textarea, Button, Input, Label } from '@expressable/ui-library';
import { useCreateNote, useEditNote } from 'hooks/use-notes';
import 'twin.macro';
import { useDismissPendingAppointment } from 'hooks/use-pending-appointments';
import { onNoteSubmit, BasicNoteFormProps } from '.';
import { Admin, ICompleteClientInformation } from 'types';
import usePermissions from 'hooks/use-permissions';
import { Badge } from '@expressable/ui-library';
import { MAX_SMS_CHARACTERS } from 'utils/sms-params';

export interface AdminNoteFormProps extends BasicNoteFormProps {}

export const AdminNoteForm = ({ props, clientData }: { props: AdminNoteFormProps, clientData: ICompleteClientInformation }) => {
  const [smsOrEmailChosen, setSmsOrEmailChosen] = useState<boolean>(false);
  const [clientCommunicationLength, setClientCommunicationLength] = useState<number>(0);
  const [isSmsTextLimitExceeded, setTextLimitExceeded] = useState<boolean>(false);
  const { setActivity, focusElementRef } = props;
  const activity = props.activity as Admin;
  const createNoteMutation = useCreateNote();
  const { mutateAsync: createNote } = createNoteMutation;
  const editNoteMutation = useEditNote();
  const { mutateAsync: editNote } = editNoteMutation;
  const dismissPendingAppointmentMutation = useDismissPendingAppointment();
  const { mutateAsync: dismissPendingAppointment } = dismissPendingAppointmentMutation;
  const { isAdmin } = usePermissions();

  const isEditMode = !!props.activityId;

  const noteContent = activity.note.content || '';
  const hasMobilePhone = !!clientData?.contactInformation?.find(item => item.phone['mobile']);
  const noteClientCommunication = activity.note.clientCommunication || '';
  const noteShouldSendSms = activity.note.shouldSendNoteSms && hasMobilePhone;
  const noteShouldSendEmail = activity.note.shouldSendNoteEmail;

  const shouldRequireSmsOrEmail = !!noteClientCommunication && (!noteShouldSendSms && !noteShouldSendEmail);

  const handleClientCommunicationChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value;
    const newLength = newValue.length;
    const shouldSendSms = newLength <= MAX_SMS_CHARACTERS;
    
    setClientCommunicationLength(newLength);
    setTextLimitExceeded(newLength > MAX_SMS_CHARACTERS);

    setActivity({
      ...activity,
      note: { ...activity.note, clientCommunication: newValue, shouldSendNoteSms: shouldSendSms }
    });

    if (!isEditMode && !smsOrEmailChosen) {
      setActivity({
        ...activity,
        note: {
          ...activity.note,
          clientCommunication: newValue,
          shouldSendNoteSms: shouldSendSms,
          shouldSendNoteEmail: true,
        }
      });
      setSmsOrEmailChosen(true);
    }
  };

  const handleSmsCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    if (clientCommunicationLength <= MAX_SMS_CHARACTERS) {
      setActivity({ ...activity, note: { ...activity.note, shouldSendNoteSms: isChecked } });
    }
    setSmsOrEmailChosen(true);
  };

  return (
    <form
      onSubmit={(event: SyntheticEvent) => {
        event.preventDefault();

        const payloadContent = {
          ...props.activity!, note: { ...props.activity!.note, shouldSendNoteSms: noteShouldSendSms }
        } as Admin;

        onNoteSubmit(createNote, editNote, payloadContent, props, dismissPendingAppointment, { autoSaving: false });
      }}
      data-testid="admin-note-form"
    >
      <div tw="px-4" data-testid="admin-note-form-text-area">
        <Textarea
          ref={focusElementRef}
          tw="w-full mt-3"
          rows={4}
          value={noteContent}
          onChange={e => setActivity({ ...activity, note: { ...activity.note, content: e.target.value } })}
          required
          placeholder="Enter your note here"
        />
      </div>
      <div tw="px-4 mb-4" data-testid="admin-note-form-client-communication">
        <h4 tw="text-sm font-semibold">Client Communication</h4>
        <Textarea
          ref={focusElementRef}
          tw="w-full mt-4 mb-0"
          rows={3}
          value={noteClientCommunication}
          onChange={handleClientCommunicationChange}
          required={noteShouldSendSms || noteShouldSendEmail}
          placeholder="Enter your communication to the client here"
          disabled={!isAdmin}
        />
        <div className={`text-right text-sm text-gray-500`}>
          {clientCommunicationLength}/{`${MAX_SMS_CHARACTERS}`} characters
        </div>
      </div>

      <div tw="px-4 items-baseline md:flex" data-testid="admin-note-form-should-send-sms">
        <Input
          id="shouldSendSms"
          data-testid="shouldSendSms"
          type="checkbox"
          checked={noteShouldSendSms}
          onChange={handleSmsCheckboxChange}
          spacing="tight"
          required={shouldRequireSmsOrEmail}
          disabled={!hasMobilePhone || !isAdmin || isSmsTextLimitExceeded}
        />
        <Label htmlFor="shouldSendSms" tw="ml-2 font-normal" className={`${isSmsTextLimitExceeded ? 'cursor-not-allowed' : ''}`}>
          Send note as SMS to primary contact
          {isSmsTextLimitExceeded && (
            <Badge variant="warning" tw="ml-2 uppercase">
              {`Exceeded ${MAX_SMS_CHARACTERS} characters limit`}
            </Badge>
          )}
        </Label>
      </div>
      <div tw="px-4 items-baseline md:flex" data-testid="admin-note-form-should-send-email">
        <Input
          id="shouldSendEmail"
          data-testid="shouldSendEmail"
          type="checkbox"
          checked={noteShouldSendEmail}
          onChange={e => {
            setActivity({ ...activity, note: { ...activity.note, shouldSendNoteEmail: e.target.checked } });
            setSmsOrEmailChosen(true);
          }}
          spacing="tight"
          required={shouldRequireSmsOrEmail}
          disabled={!isAdmin}
        />
        <Label htmlFor="shouldSendEmail" tw="ml-2 font-normal">
          Send note as email to primary contact 
        </Label>
      </div>
      {shouldRequireSmsOrEmail && 
        <div tw="px-4 text-red-500 text-xs italic mt-1">Please select any of these options.</div>
      }
      <div tw="py-3 mt-6 px-4 bottom-0 bg-white border-t border-gray-200">
        <Button
          data-testid="admin-note-form-save"
          variant="primary"
          loading={
            dismissPendingAppointmentMutation.isLoading || editNoteMutation.isLoading || createNoteMutation.isLoading
          }
          type="submit"
        >
          Save
        </Button>
      </div>
    </form>
  );
};
