import { dynamicHttp } from '@expressable/utils';
import { useQuery } from 'react-query';
import { UnlockedNoteInfo } from 'types';

type AdminUnlockedNote = {
  therapistName: string;
  totalCount: number;
  list: UnlockedNoteInfo[];
};

type AdminNoteTypeValues = 'session-note' | 'evaluation-note' | 'discharge-note';

const httpHealthRecordApi = dynamicHttp('health-record');

const getAdminUnlockedAppointmentsByNoteType = async (type: AdminNoteTypeValues) => {
  const { data } = await httpHealthRecordApi.get<AdminUnlockedNote[]>(
    `/admin/dashboard/unlocked-notes?noteType=${type}`,
  );
  return data;
};

export function useAdminUnlockedSessionNotes() {
  return useQuery(['admin-unlocked-session-notes'], () => getAdminUnlockedAppointmentsByNoteType('session-note'));
}

export function useAdminUnlockedEvaluationNotes() {
  return useQuery(['admin-unlocked-evaluation-notes'], () => getAdminUnlockedAppointmentsByNoteType('evaluation-note'));
}

export function useAdminUnlockedDischargeNotes() {
  return useQuery(['admin-unlocked-discharge-notes'], () => getAdminUnlockedAppointmentsByNoteType('discharge-note'));
}

const findAndRemoveLeanne = (therapistList: AdminUnlockedNote[]) => {
  const findLeanneTherapistIndex = therapistList?.findIndex(array => array.therapistName === 'Leanne Sherred');

  if (findLeanneTherapistIndex === -1) return therapistList;
  therapistList?.splice(findLeanneTherapistIndex as number, 1);
  return therapistList;
};

export function useAdminUnlockedNotes() {
  const { data: sessionNotes = [], isLoading: isLoadingSessionNotes } = useAdminUnlockedSessionNotes();
  const { data: evaluationNotes = [], isLoading: isLoadingEvaluationNotes } = useAdminUnlockedEvaluationNotes();
  const { data: dischargeNotes = [], isLoading: isLoadingDischargeNotes } = useAdminUnlockedDischargeNotes();

  const unlockedSessionNotes = sessionNotes
    ?.map(therapist => {
      therapist.list?.sort((firstNote, secondNote) => secondNote.overdueDays - firstNote.overdueDays);
      return {...therapist, list: therapist.list.filter(note => note.overdueDays >= 1)};
    })
    .sort((firstTherapist, secondTherapist) => secondTherapist.totalCount - firstTherapist.totalCount);

  const unlockedEvaluationNotes = evaluationNotes
    ?.map(therapist => {
      therapist.list?.sort((firstNote, secondNote) => secondNote.overdueDays - firstNote.overdueDays);
      return {...therapist, list: therapist.list.filter(note => note.overdueDays >= 1)};
    })
    .sort((firstTherapist, secondTherapist) => secondTherapist.totalCount - firstTherapist.totalCount);

    const unlockedDischargeNotes = dischargeNotes
    ?.map(therapist => {
      therapist.list?.sort((firstNote, secondNote) => secondNote.overdueDays - firstNote.overdueDays);
      return {...therapist, list: therapist.list.filter(note => note.overdueDays >= 1)};
    })
    .sort((firstTherapist, secondTherapist) => secondTherapist.totalCount - firstTherapist.totalCount);

  findAndRemoveLeanne(unlockedSessionNotes);
  findAndRemoveLeanne(unlockedEvaluationNotes);
  findAndRemoveLeanne(unlockedDischargeNotes);

  return {
    sessionNotes: unlockedSessionNotes.filter(note => note.list.length),
    evaluationNotes: unlockedEvaluationNotes.filter(note => note.list.length),
    dischargeNotes: unlockedDischargeNotes.filter(note => note.list.length),
    isLoading: isLoadingSessionNotes || isLoadingEvaluationNotes || isLoadingDischargeNotes,
  };
}
