// this is a data layer hook to transform

import dayjs from "dayjs"

export const useEligibilityData = (data: any) => {
  console.log(data)
  return {
    insuranceEligibility: data?.insuranceEligibility.toLowerCase(),
    planStatus: data?.eligibilityDetails?.planStatus,
    planDates: {
      start: dayjs(data?.eligibilityDetails?.planDateInformation.planBegin).format('MM/DD/YYYY'),
      end: dayjs(data?.eligibilityDetails?.planDateInformation.planEnd).format('MM/DD/YYYY'),
      eligibilityBegin: dayjs(data?.eligibilityDetails?.planDateInformation.eligibilityBegin).format('MM/DD/YYYY'),
    },
    benefits: data?.eligibilityDetails?.benefitsInformation,
    estimates: data?.estimates,
  }
}