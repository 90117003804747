import { ISingleAppointment } from 'types';
import { formatAppointmentCalendarName } from 'utils/helpers';
import { buildAppointmentScheduleTimeMessage } from './hooks/use-appointments-data';
import { UpcomingAppointmentStatus } from './upcoming-appointment-status';
import 'twin.macro';
import { Badge } from '@expressable/ui-library';

export type Props = {
  appointment: ISingleAppointment;
  shouldShowRecurringInformation: boolean;
  index: number;
  displayBookingCapability: boolean;
  evaluationTypeId: number;
  clientId: string;
  isRecurringAppointmentsBadgesEnabled: boolean;
  setEditAppointmentData?: (data: any) => void;
  onClickAppointment: (e: React.MouseEvent<HTMLAnchorElement>) => void;
};

const UpcomingAppointmentItem: React.FC<Props> = ({
  appointment,
  index,
  displayBookingCapability,
  evaluationTypeId,
  clientId,
  isRecurringAppointmentsBadgesEnabled,
  onClickAppointment,
}) => {
  return (
    <div tw="hover:bg-[rgba(82, 75, 202, 0.05)] rounded p-4 w-full h-full" key={index}>
      <li
        id={`upcoming-appointment-${index}`}
        className={` ${
          !displayBookingCapability && appointment.appointmentTypeID === evaluationTypeId
            ? 'text-gray-900 cursor-not-allowed'
            : 'text-indigo-700'
        }`}
      >
        <a
          href="#"
          data-testid="upcoming-appointment"
          data-id={appointment.acuityAppointmentID}
          data-date={appointment.appointmentDateTime}
          data-duration={appointment.appointmentDuration}
          data-type-id={appointment.appointmentTypeID}
          className={`w-full h-full ${
            !displayBookingCapability && appointment.appointmentTypeID === evaluationTypeId
              ? 'cursor-not-allowed pointer-events-none text-gray-900'
              : 'cursor-pointer text-indigo-700'
          }`}
          onClick={onClickAppointment}
        >
          <span
            className="font-semibold text-md"
            dangerouslySetInnerHTML={{
              __html: buildAppointmentScheduleTimeMessage(appointment),
            }}
          />

          <p className="font-medium text-black">
            {appointment.appointmentTypeID === evaluationTypeId && <Badge variant="warning" tw="mr-2">EVALUATION</Badge>}
            {`with ${
              appointment.calendarName ? formatAppointmentCalendarName(appointment.calendarName) : 'No calendar name'
            }`}
          </p>

          <UpcomingAppointmentStatus
            appointment={appointment}
            shouldShowRecurringInformation={isRecurringAppointmentsBadgesEnabled }
          />
        </a>
      </li>
    </div>
  );
};

export default UpcomingAppointmentItem;
