export enum TherapistTypes {
  Salary = 'Salary',
  Hourly = 'Hourly',
}

export default [
  { label: TherapistTypes.Salary, value: TherapistTypes.Salary },
  { label: TherapistTypes.Hourly, value: TherapistTypes.Hourly },
];

export type MfaInputNullableRef = React.MutableRefObject<HTMLInputElement | null> | null;

export interface WeekSummary {
  startOfWeek: string;
  endOfWeek: string;
  totalAppointment: number;
  totalDuration: number;
  hasAnyRateLimit: boolean;
  isOverLimitForDirectHours: boolean;
  evaluations: AppointmentSummary;
  sessions: AppointmentSummary;
}

export enum WeekSummaryStatus {
  RateLimitWeek = 'rate-limit-week',
  RateLimitDay = 'rate-limit-day',
  RateLimitDirectHours = 'rate-limit-direct-hours',
}

export interface AppointmentSummary {
  total: number;
  totalDuration: number;
  hasAnyRateLimit: boolean;
  isOverLimitForTheWeek: boolean;
  status: WeekSummaryStatus[];
  items: AppointmentItem[];
}

export interface AppointmentItem {
  appointmentOn: string;
  total: number;
  totalDuration: number;
  isRateLimited: boolean;
}

export interface ScheduleConflictDetail {
  appointmentOn: string;
  appointmentType: string;
  appointmentDetail: string;
  appointmentCreatedAt: string;
  appointmentConflictedAt: string;
  clientID: string;
  clientName: string;
}

export type ScheduleConflict = {
  appointmentOn: string;
  totalConflicts: number;
  details: ScheduleConflictDetail[]
};