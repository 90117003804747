import {
  Event,
  Note,
  Chart,
  Admin,
  Appointment,
  Discharge,
  SoapNoteContent,
  EvaluationNoteContent,
  SessionNoteContent,
  ScreeningNoteContent,
  DischargeNoteContent,
} from 'types';

import { referralOptions, inappropriateFluencyOptions } from '../../components/client-notes';
import { Moment } from 'moment';
import languagesOptions from 'utils/languages.json';
import { Articulation, CptCode } from 'types';
import { defaultCptCodes } from 'pages/client/components/cpt-codes';

type Response = {
  questionText: string;
  questionResponse: string | Array<string> | { observations: string; articulations: Articulation[] };
};

const languageSkillsQuestionsText = [
  'Oral Motor and Feeding Skills',
  'Fluency / Resonance',
  'Receptive Language',
  'Expressive Language',
];

const ArticulationMap: Record<string, Record<string, string>> = {
  Intelligibility: {
    Good: 'good',
    Fair: 'fair',
    Poor: 'poor',
  },
  'Sound Production': {
    'No errors': 'no_errors',
    'Errors with normal limits': 'errors_with_normal_limits',
    'Errors inappropriate for age': 'errors_inappropriate_for_age',
  },
  'Oral Motor Functioning': {
    Normal: 'normal',
    Suspect: 'suspect',
  },
};

const NormalInappropiateMap: Record<string, string> = {
  'Within normal limits': 'within_normal_limits',
  'Inappropriate for age': 'inappropriate_for_age',
};

const RecommendationsMap: Record<string, Record<string, string>> = {
  'Overall Impressions': {
    'Appears to be within normal limits for age': 'normal_limits_for_age',
    'Possible disorder or delay': 'possible_disorder_or_delay',
    'Difference may be related to other factors': 'difference_may_be_related_to_other_factors',
    'Unable to make a judgement': 'unable_to_make_a_judgement',
  },
  Recommendation: {
    'No follow-up necessary': 'no_follow_up',
    'Further speech-language assessment recommended': 'further_speech_language_assessment_recommended',
    Other: 'other',
  },
};

export function responsesArrayToObject(
  Responses: Response[],
  keysMap: Record<string, string>,
  eventType: string,
): SoapNoteContent | EvaluationNoteContent | SessionNoteContent | ScreeningNoteContent {
  const content = Responses.reduce((accu, current: Response) => {
    const { questionResponse, questionText } = current;

    if (typeof questionResponse === 'string' || questionResponse === null) {
      if (eventType === 'evaluation-note') {
        // from Yes/No to true/false
        if (questionText === 'Are Further Assessments Recommended?') {
          return Object.assign(accu, {
            areFurtherAssessmentsRecommended: questionResponse === 'Yes',
          });
        } else if (questionText === 'Are Speech Therapy Services Recommended?') {
          return Object.assign(accu, {
            areSpeechTherapyServicesRecommended: questionResponse === 'Yes',
          });
        }
        // from further evaluation label to value
        else if (questionText === 'Recommended Assessments') {
          return Object.assign(accu, {
            recommendedAssessments: questionResponse,
          });
        }
        // from referral label to referral value
        else if (questionText === 'Referral Type') {
          return Object.assign(accu, {
            referralType: referralOptions.find(option => option.label == questionResponse)?.value,
          });
        }
        // from language label to language value
        else if (questionText === "Client's Primary Language") {
          return Object.assign(accu, {
            clientsPrimaryLanguage: languagesOptions.find(option => option.label == questionResponse)?.value,
          });
        } else if (languageSkillsQuestionsText.includes(questionText)) {
          if (accu.languageSkills) {
            accu.languageSkills.push({
              languageSkillType: keysMap[questionText],
              observations: questionResponse,
            });
          } else {
            accu.languageSkills = [
              {
                languageSkillType: keysMap[questionText],
                observations: questionResponse,
              },
            ];
          }
          return accu;
        }
      }
      if (eventType === 'session-note') {
        if (['Duration'].includes(questionText)) {
          return Object.assign(accu, {
            [keysMap[questionText]]: '',
          });
        }

        if ('Location of visit address' === questionText) {
          return Object.assign(accu, {
            [keysMap[questionText]]: questionResponse,
          });
        }
      }
      if (eventType === 'screening-note') {
        if (['Intelligibility', 'Sound Production', 'Oral Motor Functioning'].includes(questionText)) {
          const articulation = {
            [keysMap[questionText]]: ArticulationMap[questionText][questionResponse],
            ...accu?.articulation,
          };
          return Object.assign(accu, { articulation });
        } else if (['Receptive Language', 'Expressive Language'].includes(questionText)) {
          const language = { [keysMap[questionText]]: NormalInappropiateMap[questionResponse], ...accu?.language };
          return Object.assign(accu, { language });
        } else if (['Overall Impressions', 'Recommendation'].includes(questionText)) {
          const recommendations = {
            [keysMap[questionText]]: RecommendationsMap[questionText][questionResponse],
            ...accu?.recommendations,
          };
          return Object.assign(accu, { recommendations });
        } else if (['Clinical Rationale for Recommendations', 'Additional Comments'].includes(questionText)) {
          const recommendations = { [keysMap[questionText]]: questionResponse, ...accu?.recommendations };
          return Object.assign(accu, { recommendations });
        } else if (['Fluency', 'Voice'].includes(questionText)) {
          return Object.assign(accu, {
            [keysMap[questionText]]: NormalInappropiateMap[questionResponse],
          });
        }
      }
      return Object.assign(accu, {
        [keysMap[questionText]]: questionResponse || '',
      });
    } else if (Array.isArray(questionResponse)) {
      if (eventType === 'soap-note' || eventType === 'session-note') {
        if (
          (questionText === 'Subjective Report' || questionText === 'Subjective Complaint') &&
          eventType === 'session-note'
        ) {
          return Object.assign(accu, {
            [keysMap[questionText]]: questionResponse.reduce((accu, current) => {
              return Object.assign(accu, {
                [['engaged', 'alert', 'disengaged', 'cooperative', 'tired', 'non-compliant'].includes(current)
                  ? current
                  : 'other']: ['engaged', 'alert', 'tired', 'disengaged', 'cooperative', 'non-compliant'].includes(
                  current,
                )
                  ? true
                  : current,
              });
            }, {}),
          });
        }
        return Object.assign(accu, {
          [keysMap[questionText]]: questionResponse.reduce((accu, current) => {
            return Object.assign(accu, { [current]: true });
          }, {}),
        });
      }
      if (eventType === 'screening-note') {
        if (questionText === 'Inappropriate Fluency Detail') {
          return Object.assign(accu, {
            [keysMap[questionText]]: questionResponse.map(
              item => inappropriateFluencyOptions.find(option => option.label == item)?.value,
            ),
          });
        } else if (questionText === 'Specialties Required') {
          const recommendations = { [keysMap[questionText]]: questionResponse, ...accu?.recommendations };
          return Object.assign(accu, { recommendations });
        }
      }
    } else if (typeof questionResponse === 'object') {
      return Object.assign(accu, {
        languageSkills: [
          {
            languageSkillType: 'articulation_phonology',
            observations: questionResponse.observations,
            articulations: questionResponse.articulations,
          },
        ],
      });
    } else if (typeof questionResponse === 'boolean') {
      return Object.assign(accu, {
        [keysMap[questionText]]: questionResponse,
      });
    }
  }, {} as any); // eslint-disable-line
  return content;
}

export function fromEventToChartActivity(event: Event): Chart {
  const Responses = event.detail.responses as Array<{ questionResponse: string }>;

  return {
    noteType: 'chart',
    note: {
      content: Responses[0].questionResponse,
    },
    cpt: event.detail.cpt as CptCode[],
  };
}

export function fromEventToAdminActivity(event: Event): Admin {
  const Responses = event.detail.responses as Array<{ questionResponse: string }>;

  return {
    noteType: 'admin',
    note: {
      content: event.detail.content || Responses[0].questionResponse,
      clientCommunication: event.detail.clientCommunication,
      shouldSendNoteSms: event.detail.shouldSendNoteSms,
      shouldSendNoteEmail: event.detail.shouldSendNoteEmail,
    },
    cpt: event.detail.cpt as CptCode[],
  };
}

export function fromEventToFreeTextActivity(event: Event): Appointment {
  const Responses = event.detail.responses as Array<{ questionResponse: string }>;

  return {
    noteType: 'appointment',
    note: {
      appointmentNoteType: 'free-text',
      content: Responses[0].questionResponse,
    },
    appointmentOn: event.detail.appointmentOn as Moment,
    cpt: event.detail.cpt as CptCode[],
  };
}

export function fromEventToSoapActivity(event: Event): Appointment {
  const keysMap: Record<string, string> = {
    'Subjective Complaint': 'subjectiveComplaint',
    'Objective Findings': 'objectiveFindings',
    // TODO: remove wrong spelling after table data is corrected
    'Response to Treatment/Assessment of Progress': 'assessmentOfProgress',
    'Response to Treatment/Assesment of Progress': 'assessmentOfProgress',
    'Plans For Next Session': 'plansForNextSession',
    'Was Parent Education Conducted?': 'parentEducationConducted',
    'Plans for Next Sessions': 'plansForNextSession',
  };
  const Responses = event.detail.responses as Array<Response>;

  return {
    noteType: 'appointment',
    note: {
      appointmentNoteType: 'SOAP',
      content: responsesArrayToObject(Responses, keysMap, event.eventType) as SoapNoteContent,
    },
    appointmentOn: event.detail.appointmentOn as Moment,
    cpt: (event.detail.cpt as CptCode[]) ?? [defaultCptCodes],
  };
}

export function fromEventToEvaluationActivity(event: Event): Appointment {
  const keysMap: Record<string, string> = {
    'Referral Type': 'referralType',
    'Referred By': 'referredBy',
    "Client's Primary Language": 'clientsPrimaryLanguage',
    'Additional Language Exposures': 'additionalLanguageExposures',
    'Intake Form By': 'intakeFormBy',
    'Clinical Observations': 'clinicalObservations',
    'Behavioral Observations': 'behavioralObservations',
    'Receptive Language': 'receptive_language',
    'Expressive Language': 'expressive_language',
    'Fluency / Resonance': 'fluency_resonance',
    'Oral Motor and Feeding Skills': 'oral_motor_feeding_skills',
    'Are Further Assessments Recommended?': 'areFurtherAssessmentsRecommended',
  };
  const Responses = event.detail.responses as Array<Response>;
  const content = responsesArrayToObject(Responses, keysMap, event.eventType) as EvaluationNoteContent;

  // Activity feed doesnt return language skills array but its required in the edit evaluation activity
  content.languageSkills = content.languageSkills ?? [];

  // fallback values for Evaluation notes 1.0
  content.diagnoses = content.diagnoses ?? { items: [], additionalComments: '' };
  content.carePlanGoalsProgress = content.carePlanGoalsProgress ?? [];
  content.visitFrequency = content.visitFrequency ?? { frequency: null, sessionsCount: 0, additionalComments: '' };

  return {
    noteType: 'appointment',
    note: {
      appointmentNoteType: 'evaluation-note',
      content,
    },
    appointmentOn: event.detail.appointmentOn as Moment,
    cpt: event.detail.cpt as CptCode[],
    lastEditedOn: event.detail.lastEditedOn as string,
  };
}

export function fromEventToSessionActivity(event: Event): Appointment {
  const keysMap: Record<string, string> = {
    'Subjective Complaint': 'subjectiveComplaint',
    'Plans for Next Session': 'plansForNextSession',
    'Response to Treatment / Assessment': 'responseToTreatment',
    'Feedback for Client or Caregiver': 'feedbackForClient',
    'Did Parent or Caregiver Attend Session?': 'parentOrCaregiverAttendSession',
    'Objective Findings': 'carePlanGoalsProgress',
    'Subjective Report': 'subjectiveComplaint',
    'Location of visit address': 'locationVisitAddress',
  };

  const Responses = event.detail.responses as Array<Response>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const content = responsesArrayToObject(Responses, keysMap, event.eventType) as any;

  // fields to be defaulted to empty when loading the form or loading previous note
  content.parentOrCaregiverAttendSession = '';
  content.feedbackForClient = '';
  content.parentOrCaregiverAttendSession = '';
  content.carePlanGoalsProgress = [];

  Object.keys(content).forEach(key => {
    if (key === 'undefined') {
      delete content[key];
    }
  });

  //replace empty string on key with  value "other"
  Object.keys(content.subjectiveComplaint).forEach(key => {
    if (!key) {
      // const otherValue = content.subjectiveComplaint[key];
      // content.subjectiveComplaint['other'] = otherValue;
      delete content.subjectiveComplaint[key];
    }
  });

  return {
    noteType: 'appointment',
    note: {
      appointmentNoteType: 'session-note',
      content: content as SessionNoteContent,
    },
    appointmentOn: event.detail.appointmentOn as Moment,
    cpt: (event.detail.cpt as CptCode[]) ?? defaultCptCodes,
  };
}

export function fromEventToScreeningActivity(event: Event): Appointment {
  const keysMap: Record<string, string> = {
    Intelligibility: 'intelligibility',
    'Sound Production': 'soundProduction',
    'Oral Motor Functioning': 'oralMotorFunctioning',
    'Receptive Language': 'receptive',
    'Expressive Language': 'expressive',
    Fluency: 'fluency',
    'Inappropriate Fluency Detail': 'inappropriateFluencyDetail',
    Voice: 'voice',
    'Overall Impressions': 'overallImpressions',
    Recommendation: 'recommendation',
    'Clinical Rationale for Recommendations': 'clinicalRationaleForRecommendations',
    'Additional Comments': 'additionalComments',
    'Specialties Required': 'specialtiesRequired',
  };
  const Responses = event.detail.responses as Array<Response>;

  return {
    noteType: 'screening',
    note: {
      appointmentNoteType: undefined,
      content: responsesArrayToObject(Responses, keysMap, event.eventType) as ScreeningNoteContent,
    },
    appointmentOn: event.detail.appointmentOn as Moment,
  };
}

export function fromNoteToEvaluationActivity(note: Note): Appointment {
  return {
    noteType: 'appointment',
    note: {
      appointmentNoteType: 'evaluation-note',
      content: note.detail.content as EvaluationNoteContent,
    },
    appointmentOn: note.detail.appointmentOn as Moment,
    cpt: (note.detail.cpt as CptCode[]) ?? [],
    version: note.version,
  };
}

export function fromNoteToSessionActivity(note: Note): Appointment {
  return {
    noteType: 'appointment',
    note: {
      appointmentNoteType: 'session-note',
      content: note.detail.content as SessionNoteContent,
    },
    appointmentOn: note.detail.appointmentOn as Moment,
    cpt: (note.detail.cpt as CptCode[]) ?? defaultCptCodes,
  };
}

export function fromNoteToDischargeActivity(note: Note): Discharge {
  return {
    noteType: 'discharge',
    note: {
      content: note.detail.content as DischargeNoteContent,
    },
    dischargeOn: note.detail.dischargeOn as string,
  };
}
