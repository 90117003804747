import { useState } from 'react';
import { useToast } from '@expressable/ui-library';

export const useCopyToClipboard = () => {
  const [copied, setCopied] = useState(false);
  const { errorToast } = useToast();

  const copy = (text: string) => {
    if (!navigator.clipboard) {
      errorToast('Clipboard API not supported');
      return;
    }

    navigator.clipboard.writeText(text).then(
      () => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 3000);
      },
      () => {
        errorToast(`Schedule link could not be copied`);
      }
    );
  };

  return { copied, copy };
};
