import React from 'react';
import { SelectOption } from 'types';
import { FirehoseEvents } from 'domain/admin/types';
import dayjs from 'dayjs';
import FirehoseEventAnalytics from './firehose-analytics/firehose-event-analytics';
import 'twin.macro';

export const VIEW_DAYS_SELECT_OPTIONS: SelectOption[] = [
  { label: 'Last 60 Days', value: '60' },
  { label: 'Last 30 Days', value: '30' },
  { label: 'Last 14 Days', value: '14' },
  { label: 'Last 7 Days', value: '7' },
];

export const getClientEventAnalytics = (firehoseEventArray?: FirehoseEvents) => {
  const clientEventCounts = firehoseEventArray?.items
    ?.map((events: { clientID: string; clientFirstName: string; clientLastName: string }) => ({
      ...events,
      count: firehoseEventArray?.items?.filter((count: { clientID: string; cancelledBy?: string }) => {
        if (count.cancelledBy) {
          return count.clientID === events.clientID && count.cancelledBy === 'client';
        } else {
          return count.clientID === events.clientID;
        }
      }).length,
    }))
    .filter((times: { count: number }) => times.count > 1)
    .sort((a: { count: number }, b: { count: number }) => b.count - a.count);

  const arrayWithCounts = clientEventCounts?.filter((element: { clientID: string }, index: number, arr: any[]) => {
    return arr.findIndex(item => item.clientID === element.clientID) === index;
  });

  return arrayWithCounts;
};

export const getTherapistEventAnalytics = (firehoseEventArray?: FirehoseEvents) => {
  const therapistEventCounts = firehoseEventArray?.items
    ?.map((events: { therapistEmail: string; therapistFirstName: string; therapistLastName: string }) => ({
      ...events,
      count: firehoseEventArray?.items?.filter(
        (count: { therapistEmail: string; cancelledBy: string }) =>
          count.therapistEmail === events.therapistEmail && count.cancelledBy === 'therapist',
      ).length,
    }))
    .filter((times: { count: number }) => times.count > 1)
    .sort((a: { count: number }, b: { count: number }) => b.count - a.count);

  const arrayWithCounts = therapistEventCounts?.filter(
    (element: { therapistEmail: string }, index: number, arr: any[]) => {
      return arr.findIndex(item => item.therapistEmail === element.therapistEmail) === index;
    },
  );

  return arrayWithCounts;
};

export const getFromDate = (dateState?: string) => {
  const days = dateState;

  if (days === '30') {
    const date = dayjs().startOf('day').subtract(1, 'month').toISOString();
    return date;
  }
  if (days === '60') {
    const date = dayjs().startOf('day').subtract(2, 'month').toISOString();
    return date;
  }
  if (days === '7' || days === '14') {
    const date = dayjs().startOf('day').subtract(Number(days), 'day').toISOString();
    return date;
  }
};

const Attendances = () => {
  return (
    <div data-testid="attendances">
      <div>
        <FirehoseEventAnalytics
          title={'Late Cancels'}
          subTitle={'Clients with Multiple Late Cancels'}
          eventType={'appointment_late_cancelled'}
          hasTherapistEvent={true}
          therapistEventTitle={'Therapists With Multiple Late Cancellations'}
        />
      </div>
      <div tw="mt-8">
        <FirehoseEventAnalytics
          title={'Cancels'}
          subTitle={'Clients with Multiple Cancellations'}
          eventType={'appointment_cancelled'}
          hasTherapistEvent={true}
          therapistEventTitle={'Therapists With Multiple Cancellations'}
        />
      </div>
      <div tw="mt-8">
        <FirehoseEventAnalytics
          title={'No Shows'}
          subTitle={'Clients with Multiple No Shows'}
          eventType={'appointment_no_showed'}
        />
      </div>
      <div tw="mt-8">
        <FirehoseEventAnalytics
          title={'Reschedules'}
          subTitle={'Clients with Multiple Reschedules'}
          eventType={'appointment_rescheduled'}
        />
      </div>
    </div>
  );
};

export default Attendances;
