import { groupBy } from 'lodash';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { RecurringAppointment } from 'types';
import moment from 'moment';

dayjs.extend(timezone);

export const useRecurringAppointmentsData = (appointments?: RecurringAppointment[]) => {
  if (!appointments) return {appointments: []};
  const timezoneAbbr = moment.tz(moment(), moment.tz.guess()).zoneAbbr();

  const groupedAppointments = groupBy(appointments, (appointment) =>
    dayjs(appointment.startDate).tz(dayjs.tz.guess()).format('dddd')
  );

  const daysOfWeek = [
    'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'
  ];

  const sortedAppointments = daysOfWeek.flatMap(day => {
    const appointmentsForDay = groupedAppointments[day] || [];
    
    const sortedAppointmentsForDay = appointmentsForDay.sort((a, b) => {
      const aTimeFromThatDay = dayjs(a.startDate).tz(dayjs.tz.guess()).format('HH:mm');
      const bTimeFromThatDay = dayjs(b.startDate).tz(dayjs.tz.guess()).format('HH:mm');

      return aTimeFromThatDay.localeCompare(bTimeFromThatDay);
    });
  
    return sortedAppointmentsForDay;
  });

  return {appointments: sortedAppointments ?? [], timezone: timezoneAbbr};
};

/**
 * 
 * @desc builds the date message for recurring appointments that is displayed in the sidebar
 * @example from 08/21/2024 to 08/21/2025
 * @example Starts 08/21/2024
 * @example Ends on 08/21/2025
 */
export const buildRecurringAppointmentScheduleTimeMessage = (recurringAppointment: RecurringAppointment): string => {
  const startDate = dayjs(recurringAppointment.startDate).tz(dayjs.tz.guess());
  const endDate = recurringAppointment.endDate ? dayjs(recurringAppointment.endDate).tz(dayjs.tz.guess()) : null;
  const now = dayjs();

  if (!endDate) {
    if (startDate.isAfter(now)) {
      return `(Starts ${startDate.format('MM/DD/YY')})`;
    }
    return '';
  }

  if (startDate.isAfter(now)) {
    return `(${startDate.format('MM/DD/YY')}-${endDate.format('MM/DD/YY')})`;
  }

  return `(Ends on ${endDate.format('MM/DD/YY')})`;
};