import React from 'react';
import { Event } from 'types';
import 'twin.macro';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from '@expressable/ui-library';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export interface SimpleEventProps<TDetail> {
  event: Event<TDetail>;
  description: string;
  icon?: IconProp;
  isLastEvent: boolean;
  children?: React.ReactNode;
}

export default function SimpleEvent<TDetail>(props: SimpleEventProps<TDetail>) {
  const { event, isLastEvent, icon, description, children } = props;

  return (
    <div tw="relative flex flex-col mt-7 px-2">
      {!isLastEvent && <span tw="absolute -z-1 top-7 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>}
      <div tw="flex items-baseline">
        <div tw="flex items-center bg-gray-200 rounded-full h-6 w-6">
          {icon && <FontAwesomeIcon icon={icon} tw="mx-1.5 h-3 text-indigo-700" />}
        </div>
        <div tw="pl-4 text-sm">
          <div tw="lg:flex font-semibold pb-1">
            <div tw="pb-1">
              <Link to={{ pathname: `/therapists/${event.createdByUsername}` }}>
                {event.createdByDisplayName || event.createdByUsername}&nbsp;
              </Link>
            </div>
            {event.createdOn && (
              <div tw="text-gray-500">
                {`${description} at ${dayjs(event.createdOn as string).format('h:mm A, MM/DD/YYYY')}`}
              </div>
            )}
          </div>
        </div>
      </div>
      <div tw="pl-10 text-sm">{children}</div>
    </div>
  );
}
