import { SelectOption } from 'types';

export type EvaluationDiagnoseCPT = {
  id: string;
  icd10Code: string;
  icd10Description: string;
};

export function mapContentfulDiagnosesToSelectOptions(diagnoses: EvaluationDiagnoseCPT[]): SelectOption[] {
  return diagnoses.map(diagnosis => ({
    value: diagnosis.icd10Code,
    label: `${diagnosis.icd10Code} - ${diagnosis.icd10Description}`,
  }));
}
