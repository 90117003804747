import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { FirehoseFilesDeleted } from 'domain/admin/types';
import { Link } from 'react-router-dom';
import { removePrefixFromID } from 'utils/helpers';
import { faFileSlash } from '@fortawesome/pro-solid-svg-icons';

const FilesDeleted = (event: FirehoseFilesDeleted) => {
  const isLastEvent = event.isLastEvent;
  const firehoseEvent = event.event;

  return (
    <div data-testid="firehose-files-deleted" className="relative flex mt-7 px-2">
      {!isLastEvent && (
        <span className="absolute -z-1 top-7 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
      )}
      <div className="flex items-baseline">
        <div className="flex items-center bg-gray-200 rounded-full h-6 w-6">
          <FontAwesomeIcon icon={faFileSlash} className="mx-1.5 h-3 text-indigo-700" />
        </div>
        <div className="pl-4 text-sm">
          <div className="lg:flex font-semibold pb-1">
            {firehoseEvent?.isTherapist ? (
              <Link
                className="text-indigo-700"
                to={{ pathname: `/therapists/${removePrefixFromID(firehoseEvent?.createdBy)}` }}
              >
                {firehoseEvent?.createdByFirstName} {firehoseEvent?.createdByLastName}&nbsp;
              </Link>
            ) : (
              <span className="text-gray-500">{firehoseEvent?.createdBy}&nbsp;</span>
            )}
            <span className="text-gray-500">deleted a file for the client&nbsp;</span>
            <Link
              className="text-indigo-700"
              to={{ pathname: `/clients/${removePrefixFromID(firehoseEvent?.clientID)}` }}
            >
              {firehoseEvent?.clientFirstName} {firehoseEvent?.clientLastName}&nbsp;
            </Link>
            <span className="text-gray-500">{`at ${dayjs(firehoseEvent?.createdAt as string).format('h:mm A')}, ${dayjs(
              firehoseEvent?.createdAt as string,
            ).format('MM/DD/YYYY')} `}</span>
          </div>
          <div className="flex flex-col text-sm">
            {firehoseEvent?.fileNames.map(fileName => (
              <span key={fileName}>{fileName}</span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilesDeleted;
