import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  CloseButton,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
} from '@expressable/ui-library';
import { AppointmentDetail, ReasonsContentfulEntry } from 'types';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import ModalHeadline from 'components/modal-headline';
import { useContentfulEntry } from 'hooks/use-ContentfulData';
import { mapEntryToSelectOption } from './log-cancelation-modal';

import FormGroupTitle from 'components/form-group-title';
import RHFSelect from 'components/RHFSelectBase';
import { useForm } from 'react-hook-form';
import { useClientAttendanceDismissAppointment } from 'hooks/use-appointments';
import { clientFullName } from 'utils/appointments-utils';

import 'twin.macro';
import { useCallDataOnTruthy } from 'hooks/use-call-data-on-truthy';
import { getCueError } from 'utils/error';
import { shouldSkipAttendanceError } from 'hooks/shared/attendance-utils';

export interface LogDismissAttendanceProps {
  isOpen: boolean;
  onClose: () => void;
  activity: AppointmentDetail;
}

export type DismissReason = {
  reason: string;
  dismissReasonOther: string;
};

const DELETION_REASONS_INITIAL_STATE: DismissReason = {
  reason: '',
  dismissReasonOther: '',
};

export function LogDismissAttendanceModal({ isOpen, onClose, activity }: LogDismissAttendanceProps) {
  const {
    handleSubmit,
    control,
    reset,
    register,
    watch,
    formState: { errors, isValid },
  } = useForm<DismissReason>({ defaultValues: DELETION_REASONS_INITIAL_STATE });
  const [errorMessage, setErrorMessage] = useState<string>();

  const selectedDeletionReasonValue = watch('reason');
  const [deletionReasonsEntry, isContentfulLoading, fetchContentfulData] =
    useContentfulEntry<ReasonsContentfulEntry>('3pFetCQw5hkJp3g6uq3SY4');
  const deletionReasons = deletionReasonsEntry?.fields?.dropdownContent.map(mapEntryToSelectOption);
  const { mutateAsync: dismissAppointment, isLoading: isDismissAppointmentLoading } = useClientAttendanceDismissAppointment();

  const resetModalState = () => {
    reset(DELETION_REASONS_INITIAL_STATE);
    setErrorMessage(undefined);
  };
  useEffect(resetModalState, [isOpen]);

  const onSubmitDeletion = async (data: DismissReason) => {
    await dismissAppointment({
      data,
      acuityId: activity.acuityAppointmentID,
      clientID: activity.clientID,
    }, {
      onError: (err) => {
        if (shouldSkipAttendanceError(err)) {
          onClose();
          return;
        }
        const error = getCueError(err);
        setErrorMessage(error.message);
        return;
      },
    });

    onClose();
  };

  useCallDataOnTruthy(isOpen, fetchContentfulData);

  const isLoading = isDismissAppointmentLoading || isContentfulLoading;

  return (
    <Modal isOpen={isOpen} tw="max-w-lg">
      <ModalContent tw="w-56 sm:w-full md:w-full lg:w-full">
        <ModalHeader>
          <div tw="absolute top-0 right-0 pt-4 pr-4">
            <CloseButton testId="close-log-delete-session-modal" onClick={onClose} />
          </div>
        </ModalHeader>
        <ModalBody>
          <React.Fragment>
            <div tw="flex items-center justify-center w-12 h-12 mx-auto bg-indigo-100 rounded-full">
              <FontAwesomeIcon tw="text-2xl text-indigo-700" icon={faDollarSign} />
            </div>
            <div tw="my-3 sm:mt-5">
              <ModalHeadline text={`Dismiss Session for ${clientFullName(activity)}`} />
              <form tw="mt-5" data-testid="cancelation-form" onSubmit={handleSubmit(onSubmitDeletion)}>
                <div tw="space-y-2">
                  <FormGroup tw="font-normal" data-testid="note-deletion-reason">
                    <FormGroupTitle title="Dismiss Reason" fontSize="small" fontWeight="semi" spacing="normal" />
                    <RHFSelect
                      name="reason"
                      control={control}
                      required
                      options={deletionReasons}
                      error={errors?.reason}
                      data-testid="deletion-reason"
                    />
                  </FormGroup>
                  {Boolean(selectedDeletionReasonValue === 'other') && (
                    <FormGroup tw="font-normal">
                      <FormGroupTitle
                        title="What was the reason?"
                        fontSize="small"
                        fontWeight="semi"
                        spacing="normal"
                      />
                      <Input
                        tw="w-full"
                        {...register('dismissReasonOther', { required: true })}
                        error={errors?.dismissReasonOther}
                      />
                    </FormGroup>
                  )}
                </div>
                {errorMessage && (
                  <p tw="text-center text-red-600 font-semibold mb-2">{errorMessage}</p>
                )}
                <FormGroup tw="mt-2">
                  <div tw="sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                    <span tw="flex rounded-md shadow-sm sm:col-start-2">
                      <Button
                        type="submit"
                        data-testid="delete-session-submit"
                        variant="danger"
                        tw="w-full py-2 sm:text-sm"
                        loading={isLoading}
                        disabled={isLoading}
                      >
                        Delete
                      </Button>
                    </span>
                    <span tw="flex mt-3 rounded-md shadow-sm sm:mt-0 sm:col-start-1">
                      <Button
                        disabled={!isValid}
                        data-testid="delete-session-cancel"
                        type="button"
                        variant="secondary"
                        tw="w-full py-2 sm:text-sm"
                        onClick={onClose}
                      >
                        Cancel
                      </Button>
                    </span>
                  </div>
                </FormGroup>
              </form>
            </div>
          </React.Fragment>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
