import React, { useEffect, useState } from 'react';
import useClients from '../hooks/use-clients';
import { Container, Spacer, TitleBar, Loader } from '@expressable/ui-library';
import Search from '../components/search';
import 'twin.macro';
import { Link } from 'react-router-dom';
import List from '../components/list';
import { useCurrentUserContext } from '@expressable/utils';
import { Select } from '@expressable/ui-library';
import useTherapists from '../hooks/use-therapists';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet';
import { Therapist } from 'types';
import { isCurrentUserActiveTherapist } from 'utils/helpers';

interface Props {
  searchTerm: string;
  setSearchTerm(searchTerm: string): void;
  filteredItems: [];
}

interface Client {
  id: string;
  clientFirstName: string;
  clientLastName: string;
}

const status = [
  {
    label: 'Active Clients',
    value: 'false',
  },
  {
    label: 'Inactive Clients',
    value: 'true',
  },
];

const ClientsView = ({ searchTerm, setSearchTerm, filteredItems }: Props) => (
  <>
    <div tw="max-w-sm relative">
      <input
        tw="p-3 outline-none border focus:border-indigo-300"
        css={{ width: '100%', paddingRight: 50 }}
        onChange={event => setSearchTerm(event.target.value)}
        type="text"
        placeholder={`Search ${filteredItems.length} Records`}
        aria-label="Search Clients"
        value={searchTerm}
        autoFocus
      />
      <div
        css={{
          position: 'absolute',
          right: 14,
          top: '50%',
          transform: 'translateY(-50%)',
          opacity: 0.6,
          fontSize: '0.8rem',
        }}
      >
        {filteredItems.length}
      </div>
    </div>
    <div
      data-testid="clients-list"
      css={{
        marginTop: 20,
      }}
    >
      <List
        data={filteredItems}
        render={(element: Client, index) => (
          <li key={index}>
            <Link
              tw="block p-4 hover:bg-indigo-100"
              to={{ pathname: `/clients/${element.id}` }}
            >{`${element.clientFirstName} ${element.clientLastName}`}</Link>
          </li>
        )}
      />
    </div>
  </>
);

const toSelectOptions = (therapist: Therapist) => ({
  label: therapist.therapistName,
  value: therapist.therapistEmail,
});

const Clients = () => {
  const currentUser = useCurrentUserContext();

  const [therapistEmail, setTherapistEmail] = useState<undefined | string>();
  const [clientStatus, setClientStatus] = useState<undefined | string>('false');

  const { data: clientsList, isLoading: clientsIsLoading, isRefetching } = useClients(therapistEmail, clientStatus);
  const { data: therapistsData, isLoading } = useTherapists();
  const selectOptions = (therapistsData && therapistsData.map(toSelectOptions)) ?? [];
  const [flagFirst, setFlagFirst] = useState(true);

  const isCurrentUserInTherapistData = isCurrentUserActiveTherapist(therapistsData, currentUser);

  useEffect(() => {
    if (therapistsData?.length && clientsList?.length == 0 && flagFirst) {
      setTherapistEmail('all');
      setFlagFirst(false);
    }
  }, [therapistsData, clientsList]);

  useEffect(() => {
    if (!isCurrentUserInTherapistData && flagFirst) {
      setTherapistEmail('all');
      setFlagFirst(true);
    } else {
      setTherapistEmail(currentUser?.email);
    }
  }, [isCurrentUserInTherapistData, currentUser]);

  if (isLoading || clientsIsLoading || isRefetching)
    return (
      <div tw="h-screen flex justify-center items-center" data-testid="therapist-clients">
        <Loader type="ring" />
      </div>
    );

  return (
    <div data-testid="clients" css={{ marginBottom: 28 }}>
      <Helmet title="Clients" />
      <div tw="relative">
        <TitleBar
          title="Clients"
          action={
            <Link tw="text-indigo-700" to="/client/create">
              <FontAwesomeIcon tw="text-2xl align-middle mr-3" icon={['far', 'plus']} />
              <span tw="align-middle font-bold">Create New Client</span>
            </Link>
          }
        />
      </div>
      <Spacer size="md" />
      <Container tw="relative" size="large">
        <div tw="xl:absolute flex md:justify-end mb-5 md:mb-0  items-baseline" style={{ right: '2rem' }}>
          <label tw="block mr-5 font-bold">Clients for</label>
          {therapistsData && !isLoading && (
            <div tw="flex-1 grid gap-x-4  grid-cols-2" data-testid="therapist">
              <Select
                tw="flex-1 md:w-80"
                id="therapist"
                required
                isSearchable
                value={
                  selectOptions.filter(therapist => therapist.value == therapistEmail && therapistEmail)[0] ?? {
                    label: 'All',
                    value: 'all',
                  }
                }
                options={[
                  {
                    label: 'All',
                    value: 'all',
                  },
                  ...selectOptions,
                ]}
                onChange={(selectOption: { [key: string]: string }) => {
                  setTherapistEmail(selectOption.value);
                  setFlagFirst(false);
                }}
              />
              <Select
                tw="flex-1 md:w-80"
                id="status"
                required
                isSearchable
                defaultValue={status[0]}
                value={status.filter(status => status.value == clientStatus && clientStatus)[0]}
                options={status}
                onChange={(selectOption: { [key: string]: string }) => {
                  setClientStatus(selectOption.value);
                  setFlagFirst(false);
                }}
              />
            </div>
          )}
        </div>

        {clientsList && clientsList.length > 0 && !isLoading ? (
          <Search
            render={ClientsView}
            data={clientsList.map((client: { [key: string]: string }) => ({
              ...client,
              clientFirstName: client.clientFirstName?.trim(),
              clientLastName: client.clientLastName?.trim(),
              clientFullName: `${client.clientFirstName?.trim()} ${client.clientLastName?.trim()}`,
            }))}
            options={{
              keys: ['clientFirstName', 'clientLastName', 'clientFullName'],
              threshold: 0.1,
            }}
          />
        ) : (
          <>
            {!therapistEmail && 'No therapist selected'}
            {!isLoading && therapistEmail && clientStatus == 'false' ? (
              <div tw="pt-16">
                <div tw="flex bg-gray-200 text-gray-400 font-semibold  text-xl py-36 justify-center items-center">
                  <h3>There are currently no assigned clients for this therapist</h3>
                </div>
              </div>
            ) : (
              <div tw="pt-16">
                <div tw="flex bg-gray-200 text-gray-400 font-semibold  text-xl py-36 justify-center items-center">
                  <h3>There are currently no inactive clients for this therapist</h3>
                </div>
              </div>
            )}
          </>
        )}
      </Container>
    </div>
  );
};

export default Clients;
