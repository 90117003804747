import React from 'react';
import { Button, Modal, ModalBody, ModalContent, ModalFooter } from '@expressable/ui-library';
import 'twin.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/pro-solid-svg-icons';
import { useDeleteFile, DeleteFilePayload } from 'hooks/use-files';

export interface DeleteFileModalProps {
  isOpen: boolean;
  onClose: () => void;
  clientId: string;
  fileKey: string;
}

export function DeleteFileModal(props: DeleteFileModalProps) {
  const { isOpen, onClose, clientId, fileKey } = props;
  const deleteFileMutation = useDeleteFile();
  const { mutate: deleteFile } = deleteFileMutation;

  return (
    <Modal isOpen={isOpen}>
      <ModalContent>
        <form
          onSubmit={event => {
            event.preventDefault();
            const payload: DeleteFilePayload = {
              clientID: clientId,
              key: fileKey,
            };
            deleteFile(payload, {
              onSuccess: () => {
                onClose();
              },
            });
          }}
        >
          <ModalBody>
            <div>
              <div tw="flex items-center justify-center w-12 h-12 mx-auto bg-indigo-100 rounded-full">
                <FontAwesomeIcon tw="text-2xl text-indigo-700" icon={faFileExcel} />
              </div>
              <div tw="mt-3">
                <h3 tw="text-lg text-center font-medium">Delete File</h3>
                <div tw="mt-2">
                  <p tw="text-sm text-center">Are you sure you want to delete this file?</p>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div tw="mt-8 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
              <span tw="flex rounded-md shadow-sm sm:col-start-2">
                <Button
                  type="submit"
                  data-testid="delete-file-submit"
                  variant="danger"
                  loading={deleteFileMutation.isLoading}
                  tw="w-full py-2 sm:text-sm"
                >
                  Delete
                </Button>
              </span>
              <span tw="flex mt-3 rounded-md shadow-sm sm:mt-0 sm:col-start-1">
                <Button
                  data-testid="delete-file-close"
                  type="button"
                  variant="secondary"
                  tw="w-full py-2 sm:text-sm"
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </span>
            </div>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
