import { Input, Label, Select, useDisclosure } from '@expressable/ui-library';
import ConfirmActionModal from 'components/confirmActionModal';
import TherapistSearch from 'components/therapistFilter';
import { TimeSlot } from 'components/therapistMatcher/data';
import SkipSchedulingLink from 'components/therapistMatcher/SkipSchedulingLink';
import TherapistsTimeSelector from 'components/therapistMatcher/TherapistTimeGrid';
import ToggleLink from 'components/toggle-link';
import useConfirmChangeClearDependents from 'hooks/common/use-confirm-change-clear-dependents';
import useConfirmModal from 'hooks/common/use-confirm-modal';
import useInvalidateOnChange from 'hooks/common/use-invalidate-on-change';
import {
  useFilteredTherapists,
  DEFAULT_SEARCH_FILTERS,
  TherapistSearchFilters,
} from 'hooks/common/useTherapistFilters/use-filtered-therapists';
import useTherapists from 'hooks/use-therapists';
import ScheduleSummaryForm from 'pages/new-client-create/ScheduleSummaryForm';
import SubStep from 'pages/new-client-create/sub-step';
import { Dispatch, SetStateAction, useCallback, useEffect, useState, useMemo } from 'react';
import tw from 'twin.macro';
import { SelectOption, Therapist } from 'types';
// import { createFakeTimeSlot } from './fakes';
import FirstRecurringSession, { FirstRecurringSessionInfo } from './first-recurring-session';
import InitialEvaluationOptions, { createEmptyInitialEvaluationOptions } from './initial-evaluation-options';
import { ScheduleTips } from './schedule-tips';
import SecondRecurringSession, { SecondRecurringSessionInfo } from './second-recurring-session';
import SessionAvailability from './session-availability';

export interface EvaluationTime {
  duration: number;
  isAvailableNextWeek: boolean;
  therapist: Therapist | null;
}

export interface SkippedScheduledSessions {
  skipped: true;
  therapist: Therapist | null;
  evaluationTime?: EvaluationTime;
  insurancePlan?: null;
  filters?: TherapistSearchFilters | null;
}

export interface NotSkippedScheduledSessions {
  skipped: false;
  evaluationTime: TimeSlot;
  firstRecurringSessionInfo: FirstRecurringSessionInfo;
  secondRecurringSessionInfo: SecondRecurringSessionInfo;
  insurancePlan?: string | null;
  filters?: TherapistSearchFilters | null;
}

export type ScheduledSessions = SkippedScheduledSessions | NotSkippedScheduledSessions;

interface ScheduleSessionsProps {
  onConfirm: (value: ScheduledSessions) => void;
  onInvalidateStep: () => void;
  onChangeState: Dispatch<SetStateAction<SelectOption | null>>;
  onSkip?: () => void;
  onResetForm?: boolean;
}

const ScheduleSessions = ({ onChangeState, onResetForm, ...props }: ScheduleSessionsProps) => {
  const { filters, isLoading: isLoadingTherapists, onChangeFilters, filteredTherapists } = useFilteredTherapists();
  const [initialEvaluationOptions, setInitialEvaluationOptions] = useState(createEmptyInitialEvaluationOptions());
  const [evaluationTime, setEvaluationTime] = useState<TimeSlot | null>(null /* createFakeTimeSlot() */);
  const [firstRecurringSessionInfo, setFirstRecurringSessionInfo] = useState<FirstRecurringSessionInfo | null>(null);
  const [secondRecurringSessionInfo, setSecondRecurringSessionInfo] = useState<SecondRecurringSessionInfo | null>(null);
  const [skipped, setSkipped] = useState(false);
  const [autoSelectTimezone, setAutoSelectTimezone] = useState<boolean>(false);

  const { submitted, setSubmitted, wrapSetter } = useInvalidateOnChange(props.onInvalidateStep);
  const onChangeFiltersInvalidate = wrapSetter(onChangeFilters);
  const setEvaluationTimeInvalidate = wrapSetter(setEvaluationTime);
  const setFirstRecurringSessionInfoInvalidate = wrapSetter(setFirstRecurringSessionInfo);
  const setSecondRecurringSessionInfoInvalidate = wrapSetter(setSecondRecurringSessionInfo);
  const setInitialEvaluationOptionsInvalidate = wrapSetter(setInitialEvaluationOptions);

  const { confirmModalProps: confirmFiltersModalProps, onChangeWithConfirmation: onChangeFiltersWithConfirmation } =
    useConfirmChangeClearDependents(
      onChangeFiltersInvalidate,
      [evaluationTime, firstRecurringSessionInfo, secondRecurringSessionInfo],
      [setEvaluationTimeInvalidate, setFirstRecurringSessionInfoInvalidate, setSecondRecurringSessionInfoInvalidate],
    );

  const { confirmModalProps: confirmChangeEvalOptionsProps, onChangeWithConfirmation: onChangeEvalOptions } =
    useConfirmChangeClearDependents(
      setInitialEvaluationOptionsInvalidate,
      [evaluationTime, firstRecurringSessionInfo, secondRecurringSessionInfo],
      [setEvaluationTimeInvalidate, setFirstRecurringSessionInfoInvalidate, setSecondRecurringSessionInfoInvalidate],
    );

  const {
    confirmModalProps: confirmEvaluationModalProps,
    onChangeWithConfirmation: onChangeEvaluationWithConfirmation,
  } = useConfirmChangeClearDependents(
    setEvaluationTimeInvalidate,
    [firstRecurringSessionInfo, secondRecurringSessionInfo],
    [setFirstRecurringSessionInfoInvalidate, setSecondRecurringSessionInfoInvalidate],
  );

  const {
    confirmModalProps: confirmFirstRecurringModalProps,
    onChangeWithConfirmation: onChangeFirstRecurringWithConfirmation,
  } = useConfirmChangeClearDependents(
    setFirstRecurringSessionInfoInvalidate,
    [secondRecurringSessionInfo],
    [setSecondRecurringSessionInfoInvalidate],
  );

  const [showAvailability, setShowAvailability] = useState(false);

  const expandSubStep4 =
    !!evaluationTime && !!filteredTherapists && !!firstRecurringSessionInfo && !!firstRecurringSessionInfo.timestamp;

  useEffect(() => {
    onChangeState(filters.state);
  }, [filters.state]);

  const resetFormState = () => {
    setInitialEvaluationOptions(createEmptyInitialEvaluationOptions());
    setEvaluationTime(null);
    setFirstRecurringSessionInfo(null);
    setSecondRecurringSessionInfo(null);
    setShowAvailability(false);
    props.onInvalidateStep();
  };

  useEffect(() => {
    resetFormState();
  }, [onResetForm]);

  const { onTrigger: onSkip, ...confirmSkipModalProps } = useConfirmModal(
    useCallback(() => {
      onChangeFilters(DEFAULT_SEARCH_FILTERS);
      resetFormState();
      setSkipped(true);
    }, [props.onSkip]),
  );

  const { data: therapistsData = [] } = useTherapists();
  const therapistOptions = useMemo(() => therapistsData.map(x => ({ label: x.therapistName, value: x.therapistEmail }))
    .sort((a, b) => a.label.localeCompare(b.label)), [therapistsData]);
  const [therapist, setTherapist] = useState<Therapist | null>(null);

  const scheduledSessionsInfo: ScheduledSessions = skipped
    ? {
        skipped: true,
        therapist,
      }
    : {
        skipped: false,
        evaluationTime: evaluationTime!,
        firstRecurringSessionInfo: firstRecurringSessionInfo!,
        secondRecurringSessionInfo: secondRecurringSessionInfo!,
      };

  const [schedulingValid, setSchedulingValid] = useState(true);
  const secondRecurringSessionOnValidationStatus = useCallback(
    ({ valid }) => setSchedulingValid(valid),
    [setSchedulingValid],
  );

  const { isOpen: isScheduleTipsClosed, onOpen: onScheduleTipsClose } = useDisclosure();

  return (
    <div>
      <div tw="text-2xl font-semibold mb-4">Schedule sessions</div>

      <div tw="mb-6 flex gap-2">
        <Input
          type="checkbox"
          id="skipScheduling"
          spacing="tight"
          checked={skipped}
          onChange={event => {
            setSkipped(event.target.checked);
            props.onInvalidateStep();
          }}
        />
        <Label htmlFor="skipScheduling" className="font-normal text-base" spacing="tight">
          Opt out of scheduling sessions
        </Label>
      </div>

      {skipped && (
        <div>
          <Label htmlFor="therapist">Primary Therapist</Label>
          <Select
            className="w-80"
            name="therapist"
            options={therapistOptions}
            value={therapist ? therapistOptions?.find(option => option.value === therapist?.therapistEmail) : null}
            onChange={(option: SelectOption) => {
              setTherapist(therapistsData?.find(t => t.therapistEmail === option.value) ?? null);
            }}
          />
        </div>
      )}

      {!skipped && (
        <>
          <ConfirmActionModal
            {...confirmSkipModalProps}
            confirmText="Yes, Continue"
            cancelText="No, Return to Schedule Sessions"
          >
            Skipping scheduling sessions will clear all schedule selections you have made. Are you sure you want to
            continue?
          </ConfirmActionModal>

          <ScheduleTips isClosed={isScheduleTipsClosed} onClose={onScheduleTipsClose} />
          <SubStep title="1. Filter therapists by criteria relevant to the new client" expanded>
            <div tw="flex flex-wrap gap-6">
              <TherapistSearch
                filters={filters}
                onChange={onChangeFiltersWithConfirmation}
                autoSelectTimezone={autoSelectTimezone}
                setAutoSelectTimezone={setAutoSelectTimezone}
              />
            </div>
            {filteredTherapists?.length === 0 && (
              <div className="my-16 text-center">
                <div className="font-semibold">No available therapists match your criteria</div>
                <a onClick={() => onSkip()} className="text-indigo-700 cursor-pointer">
                  Skip scheduling sessions
                </a>
              </div>
            )}
          </SubStep>
          <SubStep
            title="2. Schedule initial 60 minute evaluation"
            expanded={!!filteredTherapists?.length && !isLoadingTherapists && autoSelectTimezone}
          >
            <InitialEvaluationOptions value={initialEvaluationOptions} onChange={onChangeEvalOptions} />

            <ConfirmActionModal
              {...confirmChangeEvalOptionsProps}
              confirmText="Yes, Continue"
              cancelText="No, Return to Schedule Sessions"
            >
              Changing this will clear all schedule selections you have made. Are you sure you want to continue?
            </ConfirmActionModal>
            {initialEvaluationOptions.availableDate &&
              initialEvaluationOptions.evaluationType &&
              filteredTherapists &&
              autoSelectTimezone && (
                <>
                  <SkipSchedulingLink onClick={onSkip} />
                  <TherapistsTimeSelector
                    type="evaluation"
                    duration={initialEvaluationOptions.evaluationType.value}
                    therapists={filteredTherapists}
                    value={evaluationTime}
                    onChange={onChangeEvaluationWithConfirmation}
                    dateFilter={initialEvaluationOptions.availableDate!}
                  />
                </>
              )}
          </SubStep>
          <ConfirmActionModal
            {...confirmFiltersModalProps}
            confirmText="Yes, Continue"
            cancelText="No, Return to Schedule Sessions"
          >
            Changing therapist criteria will clear all schedule selections you have made. Are you sure you want to
            continue?
          </ConfirmActionModal>
          <SubStep
            title={
              <div tw="flex items-center gap-4">
                <div css={[!evaluationTime?.timestamp && tw`text-gray-400`]}>3. Schedule first recurring session</div>
                {!evaluationTime && filters.state && autoSelectTimezone && (
                  <ToggleLink
                    offLabel="View Session Availability"
                    onLabel="Hide Session Availability"
                    value={showAvailability}
                    onChange={value => setShowAvailability(value)}
                    tw="text-base font-normal"
                  />
                )}
              </div>
            }
            expanded={showAvailability || !!evaluationTime}
          >
            {showAvailability && !evaluationTime && filteredTherapists && (
              <SessionAvailability therapists={filteredTherapists} onSkip={onSkip} />
            )}
            {!!evaluationTime && !!filteredTherapists && evaluationTime?.timestamp && (
              <FirstRecurringSession
                evaluationTime={evaluationTime}
                therapists={filteredTherapists}
                value={firstRecurringSessionInfo}
                onChange={onChangeFirstRecurringWithConfirmation}
                onSkip={onSkip}
              />
            )}
          </SubStep>
          <ConfirmActionModal
            {...confirmEvaluationModalProps}
            confirmText="Yes, Continue"
            cancelText="No, Return to Schedule Sessions"
          >
            Changing the evaluation session will clear all recurring session selections you have made. Are you sure you
            want to continue?
          </ConfirmActionModal>
          <SubStep title="4. Schedule second recurring session" expanded={expandSubStep4}>
            {expandSubStep4 && (
              <SecondRecurringSession
                value={secondRecurringSessionInfo}
                onChange={setSecondRecurringSessionInfoInvalidate}
                firstRecurringSession={firstRecurringSessionInfo.timestamp!}
                onSkip={onSkip}
                submitted={submitted}
                onValidationStatus={secondRecurringSessionOnValidationStatus}
              />
            )}
          </SubStep>
          <ConfirmActionModal
            {...confirmFirstRecurringModalProps}
            confirmText="Yes, Continue"
            cancelText="No, Return to Schedule Sessions"
          >
            Changing the first recurring session will clear all second recurring session selections you have made. Are
            you sure you want to continue?
          </ConfirmActionModal>
          <hr tw="mb-8" />
        </>
      )}

      <ScheduleSummaryForm
        scheduledSessionsInfo={scheduledSessionsInfo}
        onConfirm={() => {
          setSubmitted();
          if (schedulingValid) {
            props.onConfirm({...scheduledSessionsInfo, filters: filters});
          }
        }}
      />
    </div>
  );
};

export default ScheduleSessions;
