import { faAngleDown, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UseInfiniteQueryResult } from 'react-query';
import 'twin.macro';

export const InfinitePaginationButton = ({
  isFetchingNextPage,
  fetchNextPage,
}: Pick<UseInfiniteQueryResult, 'isFetchingNextPage' | 'fetchNextPage'>) =>
  isFetchingNextPage ? (
    <div tw="flex items-center gap-1 justify-center mt-10">
      <FontAwesomeIcon tw="text-base mr-2 text-gray-400 text-center" icon={faCircleNotch} spin />
      <span tw="font-semibold text-gray-400">Loading</span>
    </div>
  ) : (
    <div tw="mt-10 text-center">
      <FontAwesomeIcon icon={faAngleDown} tw="text-base mr-2 text-indigo-700" />
      <span onClick={() => fetchNextPage()} tw="font-semibold text-indigo-700 cursor-pointer">
        Load more results
      </span>
    </div>
  );
