import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Button, useToast } from '@expressable/ui-library';
import { Event, Appointment, ICompleteClientInformation, serverValidationErrors } from 'types';
import { BasicNoteFormProps, onNoteSubmit } from '.';
import { useCreateNote, useEditNote } from 'hooks/use-notes';
import { useDismissPendingAppointment } from 'hooks/use-pending-appointments';
import { produce } from 'immer';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { AppointmentNoteFormErrorBoundary } from 'components/appointment-note-form-error-boundary';
import * as Sentry from '@sentry/react';
import { Form } from 'components/Forms';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { evaluationFormContentSchema } from './note-form-content/evaluation/schema';
import { mapEvaluationNoteDTOToFormData } from 'domain/notes/evaluation/3.0/mapEvaluationNoteDTOToFormData';
import { EvaluationNoteDTO } from 'domain/notes/evaluation/3.0/types';
import mapEvaluationDataToEvaluationDTO from 'domain/notes/evaluation/3.0/mapEvaluationFormDataToEvaluationDTO';
import { EvaluationContentfulProvider } from 'hooks/use-evaluation-contentful-provider';
import AppointmentEvaluationSection from './note-form-content/evaluation/sections/appointment-evaluation-section';
import EvaluationOverviewSection from './note-form-content/evaluation/sections/evaluation-overview-section';
import EvaluationLanguageSection from './note-form-content/evaluation/sections/evaluation-language.section';
import SocialHistorySection from './note-form-content/evaluation/sections/social-history-section';
import EvaluationMedicalHistorySection from './note-form-content/evaluation/sections/evaluation-medical-history-section';
import BehavioralObservations from './note-form-content/evaluation/sections/behavioral-observations';
import ClinicalArea from './note-form-content/evaluation/sections/clinical-area';
import ConclusionsSection from './note-form-content/evaluation/sections/conclusions-section';
import EvaluationPlanOfCare from './note-form-content/evaluation/sections/evaluation-plan-of-care';
import { isEqual } from 'lodash';

dayjs.extend(utc);
dayjs.extend(timezone);

export interface SaveToolbarProps {
  loading: boolean;
  lastAutoSavedAt: dayjs.Dayjs | null;
}

export const SaveToolbar = (props: SaveToolbarProps) => {
  const { loading, lastAutoSavedAt } = props;
  return (
    <div className="sticky py-3 px-4 bottom-0 bg-white border-t border-gray-200 flex gap-4 items-center">
      <Button data-testid="appointmentNoteFormSave" variant="primary" loading={loading} type="submit">
        Finish Editing
      </Button>
      {lastAutoSavedAt && (
        <span className="text-gray-400 text-xs">
          Last auto-saved at {lastAutoSavedAt.tz(dayjs.tz.guess()).format('hh:mm A')}
        </span>
      )}
    </div>
  );
};

export interface EvaluationNote3FormProps extends BasicNoteFormProps {
  lastAppointmentNote: Event | undefined;
  lastSessionNote?: Event;
  canLoadPrevious: boolean;
  clientData: ICompleteClientInformation | undefined;
  remoteValidationErrors?: serverValidationErrors;
  setValidationErrors: React.Dispatch<React.SetStateAction<serverValidationErrors | undefined>>;
}

export const createFormFieldValidationMap = (localValidationErrors: serverValidationErrors | undefined) => {
  if (!localValidationErrors) return new Map<string, string>();
  if (!Array.isArray(localValidationErrors)) {
    Sentry.captureException(new Error('localvalidation is not an array, unknown error from BE'), {
      data: {
        localValidationErrors,
      },
    });
    return new Map<string, string>().set('UNKNOWN_ERROR', JSON.stringify(localValidationErrors));
  }

  return localValidationErrors?.reduce((previousValidationError, currentValidationError) => {
    const { keyword, message, params } = currentValidationError;

    if (keyword === 'errorMessage' && params && params.errors) {
      const subfieldsMap = createFormFieldValidationMap(params.errors as serverValidationErrors);
      subfieldsMap?.forEach((_subfieldMessage, instancePath) => previousValidationError.set(instancePath, message));
    }

    const instancePath =
      keyword === 'required'
        ? `${currentValidationError.instancePath}/${params?.missingProperty}`
        : currentValidationError.instancePath;

    previousValidationError.set(instancePath, message);

    return previousValidationError;
  }, new Map<string, string>());
};

export const EvaluationNote3Form = (props: EvaluationNote3FormProps) => {
  const { clientId, remoteValidationErrors, setValidationErrors, setAutoSaveActivityId } = props;
  const [localValidationErrors, setLocalValidationErrors] = useState<serverValidationErrors | undefined>();
  const activity = props.activity as Appointment;
  const [isMounted, setIsMounted] = React.useState(false);
  const createNoteMutation = useCreateNote();
  const { mutateAsync: createNote } = createNoteMutation;
  const editNoteMutation = useEditNote();
  const { mutateAsync: editNote } = editNoteMutation;
  const dismissPendingAppointmentMutation = useDismissPendingAppointment();
  const { mutateAsync: dismissPendingAppointment } = dismissPendingAppointmentMutation;
  const validationRefsMap = useRef(new Map());

  const defaultValues = useMemo(() => {
    return activity
      ? mapEvaluationNoteDTOToFormData(activity as unknown as EvaluationNoteDTO)
      : evaluationFormContentSchema.getDefault();
  }, [activity?.id]);

  const form = useForm({
    defaultValues,
    resolver: yupResolver(evaluationFormContentSchema),
  });

  const formFieldValidationMap = React.useMemo(
    () => createFormFieldValidationMap(localValidationErrors),
    [localValidationErrors],
  );
  useEffect(() => {
    if (formFieldValidationMap && isMounted && validationRefsMap && validationRefsMap.current) {
      const refsMap = validationRefsMap.current;

      // Get the first element of the instancePath to validation messages map
      // that actually exists
      const firstValidationInstancePath = Array.from(formFieldValidationMap.keys()).find(k => refsMap.has(k));

      if (firstValidationInstancePath) {
        // Get the Ref using the instancePath index and focus the field
        const fieldRef = refsMap.get(firstValidationInstancePath).current;
        if (fieldRef) {
          fieldRef.focus();
        }
      }
    }

    setIsMounted(true);
  }, [formFieldValidationMap]);

  //Detect when server validations errors are ready and add them to the local state
  useEffect(() => {
    if (remoteValidationErrors) {
      setLocalValidationErrors(remoteValidationErrors);
    }
  }, [remoteValidationErrors]);

  const autoSaveInterval = 4 * 1000;
  const savedFormValuesRef = useRef({});
  const autoSaveTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const clearAutoSaveTimeout = () => {
    if (autoSaveTimeoutRef.current !== null) {
      clearTimeout(autoSaveTimeoutRef.current);
    }
  };

  useEffect(() => {
    if (!isMounted) {
      return;
    }

    const currentFormValues = form.watch();

    clearAutoSaveTimeout();

    autoSaveTimeoutRef.current = setTimeout(() => {
      if (
        !isEqual(currentFormValues, savedFormValuesRef.current) &&
        Object.keys(savedFormValuesRef.current).length > 0
      ) {
        save({ autoSaving: true, currentFormValues });
        savedFormValuesRef.current = currentFormValues;
      } else {
        savedFormValuesRef.current = currentFormValues;
      }
    }, autoSaveInterval);

    return clearAutoSaveTimeout;
  }, [isMounted, form]);

  const [lastAutoSavedAt, setLastAutoSavedAt] = useState<dayjs.Dayjs | null>(null);

  const isSaving = useRef(false);
  const { errorToast } = useToast();

  const save = async ({ autoSaving, currentFormValues }: { autoSaving: boolean; currentFormValues: any }) => {
    if (isSaving.current) {
      if (!autoSaving) {
        // User clicked the button when autosaving was ongoing, so we
        // inform the user why the button click was ignored
        errorToast('Please wait while the note is auto-saved');
      }
      return;
    }

    isSaving.current = true;
    try {
      const submissionAppointment = produce(activity, draft => {
        const dto = mapEvaluationDataToEvaluationDTO(currentFormValues, activity, clientId);
        Object.assign(draft, dto);
      });

      await onNoteSubmit(createNote, editNote, submissionAppointment, props, dismissPendingAppointment, { autoSaving });

      setLastAutoSavedAt(dayjs());
      if (!autoSaving) {
        // Reset validation errors as we're closing the form and if
        // it opens again for Edit it shouldn't show the Lock errors
        setLocalValidationErrors(undefined);
        setValidationErrors(undefined);

        setAutoSaveActivityId!(null);
      }
    } finally {
      isSaving.current = false;
    }
  };

  const serverErrors = useMemo(() => {
    if (!remoteValidationErrors) {
      return;
    }

    return Object.fromEntries(
      remoteValidationErrors.map(({ field, message }: any) => {
        const key = field?.split('.').at(-1) ?? 'code';
        return [key, message];
      }),
    );
  }, [remoteValidationErrors]);

  return (
    <AppointmentNoteFormErrorBoundary>
      <EvaluationContentfulProvider>
        <Form
          onSubmit={(data, e) => {
            // dev-note(gian): since plan of care has inner forms, we need to make sure that the
            // submit event is not triggered by the inner forms
            // because of js-event bubbling
            if (!e?.target?.className?.includes('eval-form')) return;

            save({ autoSaving: false, currentFormValues: data });
          }}
          data-testid="appointment-note-form"
          form={form}
          schema={evaluationFormContentSchema}
          className="space-y-6 mt-6 eval-form"
          serverErrors={serverErrors}
          hideOptional
        >
          <div className="px-4 flex flex-col gap-6">
            <AppointmentEvaluationSection content={activity} />
            <EvaluationOverviewSection />
            <EvaluationLanguageSection />
            <EvaluationMedicalHistorySection clientId={clientId} />
            <SocialHistorySection />
            <BehavioralObservations />
            <ClinicalArea
              name="receptiveLanguage"
              sectionName="Receptive Language"
              assessedQuestion="How was receptive language assessed?"
            />
            <ClinicalArea
              name="expressiveLanguage"
              sectionName="Expressive Language"
              assessedQuestion="How was expressive language assessed?"
            />
            <ClinicalArea name="fluency" sectionName="Fluency" assessedQuestion="How was fluency assessed?" />
            <ClinicalArea
              name="voiceResonance"
              sectionName="Voice/Resonance"
              assessedQuestion="How was voice/resonance assessed?"
            />
            <ClinicalArea
              name="articulationPhonology"
              sectionName="Articulation/Phonology"
              assessedQuestion="How was articulation/phonology assessed?"
            />
            <ClinicalArea
              name="oralMotorAbilities"
              sectionName="Oral Motor Abilities"
              assessedQuestion="How were oral motor abilities assessed?"
            />
            <ClinicalArea
              name="feedingSwallowing"
              sectionName="Feeding/Swallowing"
              assessedQuestion="How was feeding/swallowing assessed?"
            />
            <ClinicalArea name="pragmatics" sectionName="Pragmatics" assessedQuestion="How were pragmatics assessed?" />
            <ConclusionsSection />
            <EvaluationPlanOfCare clientId={clientId} errors={remoteValidationErrors} />
          </div>
          <SaveToolbar
            loading={
              dismissPendingAppointmentMutation.isLoading || editNoteMutation.isLoading || createNoteMutation.isLoading
            }
            lastAutoSavedAt={lastAutoSavedAt}
          />
        </Form>
      </EvaluationContentfulProvider>
    </AppointmentNoteFormErrorBoundary>
  );
};
