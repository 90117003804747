import React from 'react';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from '@expressable/ui-library';
import { faUserGroup } from '@fortawesome/free-solid-svg-icons';
import { EventProps } from 'domain/admin/types';
import { removePrefixFromID } from 'utils/helpers';
import 'twin.macro';

export default function ClientReassigned(event: EventProps) {
  const firehoseEvent = event?.event;
  const isLastEvent = event?.isLastEvent;

  return (
    <div data-testid="firehose-client-reassigned" tw="relative flex mt-7 px-2">
      {!isLastEvent && <span tw="absolute -z-1 top-7 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>}
      <div tw="flex items-baseline">
        <div tw="flex items-center bg-gray-200 rounded-full h-6 w-6">
          <FontAwesomeIcon icon={faUserGroup} tw="mx-1.5 h-3 text-indigo-700" />
        </div>
        <div tw="pl-4 text-sm">
          <div tw="lg:flex items-baseline font-semibold pb-1">
            <Link to={{ pathname: `/therapists/${firehoseEvent.createdBy}` }}>
              {firehoseEvent.createdByFirstName && firehoseEvent.createdByLastName ? (
                <span>
                  {firehoseEvent.createdByFirstName} {firehoseEvent.createdByLastName}
                </span>
              ) : (
                <span>{firehoseEvent.createdBy}</span>
              )}
              &nbsp;
            </Link>
            <div tw="text-gray-500">
              {`reassigned the client`}&nbsp;
              <Link
                tw="text-indigo-700"
                to={{ pathname: `/clients/${removePrefixFromID(firehoseEvent?.clientID)}` }}
              >
                {firehoseEvent?.detail.clientFirstName} {firehoseEvent?.detail.clientLastName}&nbsp;
              </Link>
              {`at ${dayjs(firehoseEvent.createdAt as string).format('h:mm A, MM/DD/YYYY')}`}
            </div>
          </div>
          <div>
            Original Therapist:&nbsp;
            <Link to={{ pathname: `/therapists/${firehoseEvent.previousTherapistEmail}` }}>
              {firehoseEvent.previousTherapistFirstName && firehoseEvent.previousTherapistLastName ? (
                <span>
                  {firehoseEvent.previousTherapistFirstName} {firehoseEvent.previousTherapistLastName}
                </span>
              ) : (
                <span>{firehoseEvent.previousTherapistEmail}</span>
              )}
            </Link>
            &nbsp;
          </div>
          <div tw="mt-1">
            New Therapist:&nbsp;
            <Link to={{ pathname: `/therapists/${firehoseEvent.therapistEmail}` }}>
              {firehoseEvent.therapistFirstName && firehoseEvent.therapistLastName ? (
                <span>
                  {firehoseEvent.therapistFirstName} {firehoseEvent.therapistLastName}
                </span>
              ) : (
                <span>{firehoseEvent.therapistEmail}</span>
              )}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
