import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Button, FormInline, Input, Label, Loader } from '@expressable/ui-library';
import Select from 'react-select';
import { Contact, ICompleteClientInformation } from 'types';
import { useForm, useWatch } from 'react-hook-form';
import { phoneTypeSelectOptions } from 'domain/client/constants';
import { useDuplicatedContacts } from 'hooks/use-contacts';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DuplicatedContactsModal from './duplicated-contacts-modal';
import { SelectOption } from './client-contact-form';

interface ErrorResponse {
  response: {
    data: Array<{
      field: string;
    }>;
  };
}

export interface DuplicateContactsFinderProps {
  setContacts: Dispatch<SetStateAction<Contact[]>>;
  setShowEditForm: Dispatch<SetStateAction<boolean>>;
  setShowDuplicateFinder: Dispatch<SetStateAction<boolean>>;
  index: number;
  clientData: ICompleteClientInformation | undefined;
  contacts: Contact[];
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
  setFinderDefaultPhone: Dispatch<SetStateAction<string>>;
  setFinderDefaultEmail: Dispatch<SetStateAction<string>>;
  setFinderDefaultPhoneType: Dispatch<SetStateAction<SelectOption | undefined>>;
  clientId: string;
  setIsPhoneSearch: Dispatch<SetStateAction<boolean>>;
  setIsEmailSearch: Dispatch<SetStateAction<boolean>>;
}

const DuplicateContactsFinder = (props: DuplicateContactsFinderProps) => {
  const {
    setContacts,
    index,
    setShowEditForm,
    setShowDuplicateFinder,
    clientData,
    contacts,
    isOpen,
    onClose,
    onOpen,
    setFinderDefaultPhone,
    setFinderDefaultEmail,
    setFinderDefaultPhoneType,
    clientId,
    setIsPhoneSearch,
    setIsEmailSearch,
  } = props;
  const [isPhoneNumberNotProvided, setIsPhoneNumberNotProvided] = useState(false);
  const [isEmailAddressNotProvided, setIsEmailAddressNotProvided] = useState(false);
  const { setValue, setValue: setFormValue, control } = useForm();
  const watchPhone = useWatch({ control, name: 'phone' });
  const watchEmail = useWatch({ control, name: 'email' });
  const watchPhoneType = useWatch({ control, name: 'phoneType' });
  const duplicatedContactsQuery = useDuplicatedContacts(
    isPhoneNumberNotProvided,
    isEmailAddressNotProvided,
    watchPhone?.trim(),
    watchEmail,
    watchPhoneType?.value,
  );
  const {
    data: duplicatedContacts,
    mutate: findDuplicatedContacts,
    isLoading,
    isError,
    error = {} as ErrorResponse,
  } = duplicatedContactsQuery;

  const searchContactsDisabledState =
    isLoading ||
    (!watchPhone && !isPhoneNumberNotProvided) ||
    (!watchEmail && !isEmailAddressNotProvided) ||
    (isPhoneNumberNotProvided && isEmailAddressNotProvided);

  const onChangePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValue('phone', e.target.value);
  };

  const onChangePhoneType = (option: SelectOption) => {
    setFormValue('phoneType', option);
  };

  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValue('email', e.target.value);
  };

  const searchDuplicatedContacts = (e: React.FormEvent) => {
    e.preventDefault();
    findDuplicatedContacts({ watchPhone, watchEmail, watchPhoneType });
  };

  useEffect(() => {
    setValue('phoneType', phoneTypeSelectOptions[1]);
  }, []);

  useEffect(() => {
    if (duplicatedContacts && duplicatedContacts.length > 0) {
      onOpen();
    }
    if (duplicatedContacts && duplicatedContacts.length === 0) {
      setFinderDefaultPhone(watchPhone ?? '');
      setFinderDefaultEmail(watchEmail ?? '');
      const replacePhoneWord = watchPhoneType?.value.replace('Phone', '');
      setFinderDefaultPhoneType({ label: replacePhoneWord, value: replacePhoneWord } ?? '');
      setShowEditForm(true);

      if (watchPhone) {
        setIsPhoneSearch(true);
      }
      if (watchEmail) {
        setIsEmailSearch(true);
      }
    }
  }, [duplicatedContacts]);

  useEffect(() => {
    if (isPhoneNumberNotProvided) {
      setValue('phone', '');
    }
    if (isEmailAddressNotProvided) {
      setValue('email', '');
    }
  }, [isPhoneNumberNotProvided, isEmailAddressNotProvided]);

  const isPhoneError =
    (error as ErrorResponse)?.response?.data.some((errorItem: { field: string }) => {
      return errorItem.field === 'homePhone' || errorItem.field === 'mobilePhone' || errorItem.field === 'workPhone';
    }) || false;

  const isEmailError =
    (error as ErrorResponse)?.response?.data.some((errorItem: { field: string }) => {
      return errorItem.field === 'email';
    }) || false;

  if (isLoading) {
    return (
      <div className="flex justify-center items-center mx-auto" data-testid="duplicate-contacts-finder-loader">
        <Loader type="ring" />
      </div>
    );
  }

  if (duplicatedContacts && duplicatedContacts.length > 0) {
    return (
      <>
        <DuplicatedContactsModal
          duplicatedContacts={duplicatedContacts}
          searchedPhone={watchPhone}
          searchedEmail={watchEmail}
          clientData={clientData}
          isOpen={isOpen}
          onClose={onClose}
          setShowEditForm={setShowEditForm}
          setContacts={setContacts}
          setShowDuplicateFinder={setShowDuplicateFinder}
          contacts={contacts}
          clientId={clientId}
        />
      </>
    );
  }

  return (
    <div data-testid="duplicate-contacts-finder-form">
      <div className="flex justify-between items-center">
        <h2 className="font-semibold text-xl">Add New Contact</h2>
      </div>
      <h5 className="text-sm mt-8">
        Please enter a phone number or email address to search existing contact records. If no phone number or email
        address is provided, you may save other contact information.
      </h5>
      <div className="mt-8">
        <FormInline className="flex-wrap md:flex items-end">
          <div>
            <Label htmlFor="phone">Phone</Label>
            <Input
              type="tel"
              name="phone"
              id="phone"
              data-testid="duplicate-contacts-form-finder-phone"
              placeholder={isPhoneNumberNotProvided ? '' : '(512) 234-5890'}
              className={isPhoneNumberNotProvided ? 'text-gray-800 opacity-50 pointer-events-none mr-3' : 'mr-3'}
              readOnly={isPhoneNumberNotProvided}
              value={watchPhone ?? ''}
              onChange={onChangePhone}
            />
          </div>
          <div className="mb-5 w-36">
            <Label htmlFor="phone-type">Type</Label>
            <Select
              name="phoneType"
              id="phoneType"
              data-testid="duplicate-contacts-form-finder-phone-type"
              spacing="tight"
              options={phoneTypeSelectOptions}
              defaultValue={phoneTypeSelectOptions[1]}
              value={watchPhoneType ?? ''}
              onChange={onChangePhoneType}
              className={isPhoneNumberNotProvided ? 'text-gray-800 opacity-50 pointer-events-none mr-3' : 'mr-3'}
              readOnly={isPhoneNumberNotProvided}
            />
          </div>
          <div>
            <div className="flex items-baseline mb-7 ml-2 md:ml-6">
              <Input
                name="no-phone-number-provided"
                id="no-phone-number-provided"
                data-testid="duplicate-contacts-finder-no-phone-number-provided"
                type="checkbox"
                spacing="tight"
                checked={isPhoneNumberNotProvided}
                onChange={e => setIsPhoneNumberNotProvided(e.currentTarget.checked)}
                className="mr-2"
              />
              <Label className="font-normal text-base">No phone number provided</Label>
            </div>
          </div>
        </FormInline>

        <div className="md:flex items-end block">
          <div>
            <Label htmlFor="email">Email</Label>
            <Input
              type="email"
              name="email"
              id="email"
              data-testid="duplicate-contacts-form-finder-email"
              value={watchEmail ?? ''}
              onChange={onChangeEmail}
              size={41}
              className={isEmailAddressNotProvided ? 'text-gray-800 opacity-50 pointer-events-none' : ''}
              readOnly={isEmailAddressNotProvided}
            />
          </div>
          <div className="flex items-baseline mb-7 ml-2 md:ml-6">
            <Input
              data-testid="duplicate-contacts-finder-no-email-address-provided"
              name="no-email-address-provided"
              id="no-email-address-provided"
              type="checkbox"
              spacing="tight"
              checked={isEmailAddressNotProvided}
              onChange={e => setIsEmailAddressNotProvided(e.currentTarget.checked)}
              className="mr-2"
            />
            <Label className="font-normal text-base">No email address provided</Label>
          </div>
        </div>
        {isError && !isLoading && (
          <>
            {!isPhoneNumberNotProvided && isPhoneError && (
              <FormInline className="text-gray-400">
                <div className="text-[1em] mr-2">
                  <FontAwesomeIcon icon={faClose} className="text-red-500" />
                </div>
                Invalid phone number. Please enter a valid phone number.
              </FormInline>
            )}
            {!isEmailAddressNotProvided && isEmailError && (
              <FormInline className="text-gray-400">
                <div className="text-[1em] mr-2">
                  <FontAwesomeIcon icon={faClose} className="text-red-500" />
                </div>
                Invalid email address. Please enter a valid email address.
              </FormInline>
            )}
          </>
        )}
      </div>
      <div className="flex justify-between items-center mt-8">
        <div className="flex justify-between">
          <Button
            variant="primary"
            data-testid="duplicate-contacts-finder-search-contacts"
            onClick={e => {
              searchDuplicatedContacts(e);
            }}
            disabled={searchContactsDisabledState}
          >
            Search Contacts
          </Button>
          {isPhoneNumberNotProvided && isEmailAddressNotProvided && (
            <Button
              className="ml-6"
              type="button"
              variant="secondary"
              data-testid="duplicate-contacts-finder-start-new-form"
              onClick={() => {
                setShowEditForm(true);
              }}
            >
              Continue without contact methods
            </Button>
          )}
          <Button
            className="ml-6"
            onClick={() => {
              setContacts(contacts => {
                return contacts.filter((_, filterIndex) => {
                  return filterIndex != index;
                });
              });
              setShowDuplicateFinder(false);
            }}
            type="button"
            variant="secondary"
            data-testid="duplicate-contacts-finder-cancel"
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DuplicateContactsFinder;
