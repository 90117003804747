import {
  Button,
  CloseButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Textarea,
} from '@expressable/ui-library';
import 'twin.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNotesMedical } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import { Form, FormField } from 'components/Forms';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useMemo } from 'react';
import { GetDischargePlanPayload, useSetDischargePlan } from 'hooks/use-care-plan-discharge-plan';

interface PlanOfCareDischargePlanModalProps {
  isOpen: boolean;
  onClose: () => void;
  clientId: string;
  dischargePlan?: GetDischargePlanPayload | null;
  shouldLogEvent: boolean;
}

const schema = yup.object({
  details: yup.string().trim().required('Discharge Plan is required'),
});

const DEFAULT_DISCHARGE_PLAN = `The client will be discharged when therapy services are no longer deemed medically necessary, when the client is no longer progressing, when all goals are met, upon caregiver request, and/or when attendance or HEP (Home Educational Program) compliance drops below acceptable parameters established upon SOC.`;

export function PlanOfCareDischargePlanModal(props: PlanOfCareDischargePlanModalProps) {
  const { isOpen, onClose, clientId, dischargePlan } = props;
  const { mutateAsync: setDischargePlan, isLoading } = useSetDischargePlan();

  const defaultValues = useMemo(() => ({ details: dischargePlan?.details || DEFAULT_DISCHARGE_PLAN }), [dischargePlan]);
  const { shouldLogEvent } = props;
  const form = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: any) => {
    await setDischargePlan({
      clientId,
      details: data.details,
      shouldLogEvent,
    });
    onClose();
  };

  const handleEdit = () => {
    form.reset({ details: DEFAULT_DISCHARGE_PLAN });
  };
  return (
    <Modal portal isOpen={isOpen}>
      <ModalContent>
        <Form form={form as any} onSubmit={onSubmit} schema={schema}>
          <ModalHeader>
            <CloseButton onClick={onClose} />
          </ModalHeader>
          <ModalBody>
            <div>
              <div tw="flex items-center justify-center w-12 h-12 mx-auto bg-indigo-100 rounded-full">
                <FontAwesomeIcon tw="text-2xl text-indigo-700" icon={faNotesMedical} data-testid="icon" />
              </div>
              <div tw="mt-3 sm:mt-5">
                <h3 tw="text-lg text-center font-medium text-gray-900 leading-6" id="modal-headline">
                  Discharge Plan
                </h3>
                <div tw="mt-10">
                  <span className="font-semibold">Discharge Plan</span>
                  <span className="ml-4 text-indigo-700 cursor-pointer" onClick={handleEdit}>
                    Reset Default
                  </span>
                  <FormField component={Textarea} name="details" label="" rows={5} />
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div tw="mt-5 sm:mt-6 sm:grid sm:grid-cols-1 sm:gap-3 sm:grid-flow-row-dense">
              <span tw="flex w-full rounded-md shadow-sm">
                <Button
                  type="submit"
                  data-testid="applicable-conditions-submit"
                  variant="primary"
                  loading={isLoading}
                  disabled={isLoading}
                  tw="inline-flex justify-center w-full px-4 py-2 text-base font-medium leading-6 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                >
                  Save
                </Button>
              </span>
            </div>
          </ModalFooter>
        </Form>
      </ModalContent>
    </Modal>
  );
}
