import { Menu, MenuItem, MenuLink, MenuList } from '@expressable/ui-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import 'twin.macro';
import { displayTimezoneOptions } from './appointment-modals';
import { UseFormSetValue } from 'react-hook-form';
import { SelectOption } from 'types';
import { RescheduleDetailFormPayload } from 'domain/log-attedances/types';

export type Props = {
  displayTimezone: Record<string, string>;
  setDisplayTimezone:
    | UseFormSetValue<RescheduleDetailFormPayload>
    | React.Dispatch<React.SetStateAction<Record<string, string>>>;
  name: string;
  disabled?: boolean;
};
export default function TimezoneSelect({ name, displayTimezone, disabled, setDisplayTimezone }: Props) {
  if (disabled) {
    return (
      <div tw="text-sm text-center text-gray-500 leading-5">
        Times are shown in - <span tw="font-semibold">{displayTimezone?.label} Time Zone</span>
      </div>
    );
  }
  return (
    <div tw="mt-2">
      <div tw="text-sm text-center text-gray-500 leading-5">
        Times are shown in -
        <Menu
          onChange={MenuItem => {
            const selectedMenuItem = MenuItem as Record<string, string>;
            setDisplayTimezone(name as any, selectedMenuItem as unknown as SelectOption); // eslint-disable-line
          }}
          tw="px-1 inline"
        >
          <MenuLink to="#">
            {displayTimezone?.label} Time Zone
            <FontAwesomeIcon tw="text-sm ml-1" icon="caret-down" />
          </MenuLink>
          <MenuList tw="w-full">
            {displayTimezoneOptions.map((option, index) => (
              <MenuItem value={option} key={index}>
                {option.label}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </div>
    </div>
  );
}
