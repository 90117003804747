type CurrencyFormatOptions = {
  prefix?: string;
  decimals?: number;
};

const defaultOptions = { prefix: '$', decimals: 2 };

export const formatCurrencyDollars = (amount: number, options: CurrencyFormatOptions = defaultOptions): string => {
  const opts = { ...defaultOptions, ...options };

  let amountString = amount.toFixed(opts.decimals);

  if (opts.decimals === 0) {
    amountString = String(Math.round(amount));
  }

  return `${opts.prefix}${amountString}`;
};

export const formatCurrencyCents: typeof formatCurrencyDollars = (amount, ...rest) => {
  return formatCurrencyDollars(amount / 100, ...rest);
};
