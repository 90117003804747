import ReadOnlyField from "../ReadOnlyField";

type ReadyOnlyFieldWithValueProps = {
  label: string;
};

const ReadOnlyFieldWithValue: React.FC<ReadyOnlyFieldWithValueProps> = ({ label, children }) => {
  if (!children) {
    return <></>;
  }
  return <ReadOnlyField label={label}>{children}</ReadOnlyField>;
};

export default ReadOnlyFieldWithValue;
