import Amplify from 'aws-amplify';

const { Auth } = Amplify;

export const getUserAccessToken = async () => {
  const currentSession = await Auth.currentSession();
  return currentSession.getAccessToken().getJwtToken();
};

export const getUserEmail = async () => {
  try {
    const currentSession = await Auth.currentSession();
    const userAttributes = currentSession.getIdToken().payload;

    return userAttributes ? userAttributes.email : null;
  } catch (error) {
    throw error;
  }
};
