import cx from 'classnames';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  className?: string;
  error?: boolean;
};

export const HelperText = ({ children, error, className }: Props): JSX.Element => {
  return (
    <span
      aria-live={error ? 'polite' : undefined}
      className={cx(className, 'text-xs block leading-tight mt-2', {
        'text-gray-600': !error,
        'text-error-900 mt-4': error,
      })}
    >
      {children}
    </span>
  );
};
