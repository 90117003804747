import { dynamicHttp } from '@expressable/utils';
import { useQuery } from 'react-query';
import { ClientAttendanceVerification } from 'types';
import * as Sentry from '@sentry/react';
const httpHealthRecordProxy = dynamicHttp('health-record-proxy');

const getAdminAttendanceVerification = async () => {
  try {
    const { data } = await httpHealthRecordProxy.get<ClientAttendanceVerification[]>(
      '/client-attendances/attendance-verification',
    );
    return data;
  } catch (error) {
    Sentry.captureException(error);
  }
};

export function useAdminAttendanceVerification() {
  return useQuery(['attendance-verification'], () => getAdminAttendanceVerification());
}
