import { useState, useEffect } from 'react';
import {
  Button,
  CloseButton,
  FormGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@expressable/ui-library';
import 'twin.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import { useGenerateAuthorizationMessage } from 'hooks/use-care-plan-generate-authorization-message';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

export interface ClientGenerateAuthorizationMessageProps {
  isOpen: boolean;
  onClose: () => void;
  clientId: string;
}

interface ClientGenerateAuthorizationForm {}

export function ClientGenerateAuthorizationModal(props: ClientGenerateAuthorizationMessageProps) {
  const { isOpen, onClose, clientId } = props;
  const { mutateAsync: generateAuthorizationMessage, isLoading } = useGenerateAuthorizationMessage();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [authorizationMessage, setAuthorizationMessage] = useState<string | null>(null);
  const [copyButtonLabel, setCopyButtonLabel] = useState<string>('Copy Message to Clipboard');

  const { handleSubmit, reset } = useForm<ClientGenerateAuthorizationForm>();

  const onSubmit = async () => {
    setErrorMessage(null);
    try {
      const res = await generateAuthorizationMessage({ clientId });
      setAuthorizationMessage(res.message);
    } catch (error: any) {
      if (error?.response?.data?.errors) {
        try {
          setErrorMessage(error.response.data.errors);
        } catch (parseError) {
          setErrorMessage('An unexpected error occurred. Please try again later.');
        }
      }
    }
  };

  useEffect(() => {
    reset();
  }, [isOpen]);

  const handleCopy = () => {
    if (authorizationMessage) {
      navigator.clipboard.writeText(authorizationMessage);
      setCopyButtonLabel('Copied!');

      setTimeout(() => {
        setCopyButtonLabel('Copy Message to Clipboard');
      }, 3000);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      customClassName="inline-block px-4 pt-5 pb-4 text-left align-bottom bg-white rounded-lg shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-1/2 sm:p-6 relative"
    >
      <ModalContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader>
            <CloseButton onClick={onClose} />
          </ModalHeader>
          <ModalBody>
            <div>
              <div tw="flex items-center justify-center w-12 h-12 mx-auto bg-indigo-100 rounded-full">
                <FontAwesomeIcon tw="text-2xl text-indigo-700" icon={faClipboardList} data-testid="icon" />
              </div>
              <div tw="mt-3">
                <h3 tw="text-lg text-center font-medium text-gray-900 leading-6" id="modal-headline">
                  Authorization Message
                </h3>
                <div tw="mt-3">
                  <FormGroup>
                    {isLoading ? (
                      <div tw="w-full h-80 flex items-center justify-center border-2 border-gray-300 rounded bg-gray-200 animate-pulse mb-5" />
                    ) : (
                      <textarea
                        id="authorization-message"
                        data-testid="authorization-message"
                        className={`w-full px-3 py-2 border-2 border-gray-300 rounded shadow-inner outline-none focus:border-indigo-700 mb-5 ${
                          authorizationMessage ? 'cursor-text' : 'cursor-none bg-gray-200'
                        }`}
                        readOnly={true}
                        style={{ minHeight: '320px' }}
                        rows={15}
                        value={authorizationMessage || ''}
                        disabled={!authorizationMessage}
                      />
                    )}
                  </FormGroup>
                </div>
                {errorMessage && (
                  <div tw="inline-flex justify-center w-full text-base font-medium leading-6 text-red-500 text-xs mb-6">
                    {errorMessage}
                  </div>
                )}
                <div tw="flex items-center justify-center w-full text-xs text-gray-400 mb-4">
                  Generates the message using care plans, the latest evaluation, and billing info for insurance clients.
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div tw="flex justify-items-center space-x-2">
              <Button
                type="submit"
                data-testid="generate-authorization-message-submit"
                variant="primary"
                loading={isLoading}
                disabled={isLoading}
                tw="inline-flex justify-center w-1/2 px-4 py-2 text-base font-medium leading-6 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                className={isLoading ? 'bg-gray-300 cursor-not-allowed' : ''}
              >
                Generate Authorization Message
              </Button>
              <Button
                type="button"
                data-testid="generate-authorization-message-copy-to-clipboard"
                variant="secondary"
                loading={false}
                disabled={!authorizationMessage || isLoading}
                className={`${authorizationMessage && !isLoading ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                tw="inline-flex justify-center w-1/2 px-4 py-2 text-base font-medium leading-6 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                onClick={handleCopy}
              >
                {copyButtonLabel === 'Copy Message to Clipboard' && <FontAwesomeIcon icon={faCopy} tw="mr-2" />}
                {copyButtonLabel}
              </Button>
            </div>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
