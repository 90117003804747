import { useQuery, UseQueryResult, useQueryClient, useMutation } from 'react-query';
import { dynamicHttp } from '@expressable/utils';
import { useToasts } from 'react-toast-notifications';
import { presentMutationError } from '@expressable/ui-library';

const http = dynamicHttp('health-record-proxy');

const DefaultQueryOptions = {
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  staleTime: 1000 * 60 * 10, // 10 minutes
  retry: false, // Disable retries on failure
  useErrorBoundary: true,
};

const SalesforceURL =
  process.env.REACT_APP_NODE_ENV === 'production'
    ? 'https://expressablehc2022.lightning.force.com'
    : 'https://expressablehc2022--sandbox.sandbox.lightning.force.com';

const SalesforceCasesURL = `${SalesforceURL}/lightning/r/Case/{{caseId}}/view`;

export const getSalesforceCaseURL = (caseId: string) => {
  return SalesforceCasesURL.replace('{{caseId}}', caseId);
};

export interface ClientCasesCountPayload {
  clientId: string;
}

export interface CasesByTherapistPayload {
  email: string;
  status: ClientCasesStatus;
}

const getCasesCountByClient = async (payload: ClientCasesCountPayload) => {
  const { clientId } = payload;
  return http.get(`/salesforce/cases/count/${clientId}`).then(res => res.data);
};

export enum ClientCasesStatus {
  Open = 'open',
  Closed = 'closed',
  All = 'all',
}

export interface ClientCasesDetailPayload {
  clientId: string;
  status: ClientCasesStatus;
}

export type TherapistCase = {
  casesByClient: CasesByClient[];
  therapistEmail: string;
};

const getCasesByClient = async (payload: ClientCasesDetailPayload) => {
  const { clientId, status } = payload;
  const statusQueryParam = status !== 'all' ? `?status=${status}` : '';
  return http.get(`/salesforce/cases/client/${clientId}${statusQueryParam}`).then(res => res.data);
};

const getCasesByTherapist = async (payload: CasesByTherapistPayload): Promise<TherapistCase> => {
  const { email, status } = payload;
  const params = new URLSearchParams();

  if (status !== ClientCasesStatus.All) {
    params.append('status', status);
  }
  const { data } = await http.get(`/salesforce/cases/therapist/${email}?${params.toString()}`);
  return data;
};

export interface ClientCasesCountResult {
  all: number;
  closed: number;
  open: number;
}

export enum ClientCaseActivitiesField {
  Created = 'created',
}

export enum ClientCaseActivitiesType {
  Comment = 'Comment',
  FeedItem = 'Feed Item',
  History = 'History',
}

export interface CasesByClient {
  caseActivities: Record<string, any>[];
  caseRecord: Record<string, any>;
  caseStatusValues: string[];
}

export interface ClientCaseRecordsResult {
  clientID: string;
  casesByClient: CasesByClient[];
}

export default function useClientCases(enabled: boolean) {
  const useClientCasesCount = (payload: ClientCasesCountPayload): UseQueryResult<ClientCasesCountResult> => {
    return useQuery(['clientCasesCount', payload.clientId], () => getCasesCountByClient(payload), {
      ...DefaultQueryOptions,
      enabled,
      useErrorBoundary: false,
    });
  };

  const useClientCasesRecords = (payload: ClientCasesDetailPayload): UseQueryResult<ClientCaseRecordsResult> => {
    return useQuery(['clientCasesDetail', payload.clientId], () => getCasesByClient(payload), {
      ...DefaultQueryOptions,
      enabled,
    });
  };

  return {
    useClientCasesCount,
    useClientCasesRecords,
  };
}

type UseTherapistCasesParams = {
  email: string;
  enabled: boolean;
  status: ClientCasesStatus;
  therapistCasesSelector: (data: TherapistCase) => any;
};

export function useTherapistCases(config: UseTherapistCasesParams) {
  return useQuery(
    ['therapistCases', config.email, config.status],
    () => getCasesByTherapist({ email: config.email, status: config.status }),
    {
      ...DefaultQueryOptions,
      enabled: config.enabled,
      select: config?.therapistCasesSelector,
    },
  );
}

export interface AddClientCaseCommentPayload {
  caseNumber: string;
  clientId: string;
  Body: string;
}

const addClientCaseComment = async (addClientCaseCommentPayload: AddClientCaseCommentPayload) => {
  const { caseNumber, clientId, ...addClientCaseCommentData } = addClientCaseCommentPayload;
  return http
    .post(`/salesforce/cases/${caseNumber}/client/${clientId}/feed`, addClientCaseCommentData)
    .then(res => res.data);
};

export function useAddClientCaseComment() {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(addClientCaseComment, {
    onSuccess: async (_, payload) => {
      addToast('Client Case Comment Successfully Added', { appearance: 'success', autoDismiss: true });
      await queryClient.invalidateQueries(['clientCasesDetail', payload.clientId]);
    },
    onError: presentMutationError,
  });
}

export interface CreateClientCasePayload {
  RecordTypeId: string;
  Subject: string;
  Description?: string;
  Cue_Client_ID__c: string;
}

const createClientCase = async (createClientCasePayload: CreateClientCasePayload) => {
  const { ...createClientCaseData } = createClientCasePayload;
  return http.post(`/salesforce/cases`, createClientCaseData).then(res => res.data);
};

export function useCreateClientCase() {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(createClientCase, {
    onSuccess: async (_, payload) => {
      addToast('Client Case Successfully Created', { appearance: 'success', autoDismiss: true });
      await Promise.all([
        queryClient.invalidateQueries(['clientCasesDetail', payload.Cue_Client_ID__c]),
        queryClient.invalidateQueries(['clientCasesCount', payload.Cue_Client_ID__c]),
      ]);
    },
    onError: presentMutationError,
  });
}
