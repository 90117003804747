import React, { useEffect, useState } from 'react';
import {
  Button,
  CloseButton,
  FormGroup,
  FormInline,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
} from '@expressable/ui-library';
import 'twin.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNotesMedical } from '@fortawesome/free-solid-svg-icons';
import { Controller, useForm } from 'react-hook-form';
import { MedicalDiagnosis, SelectOption } from 'types';
import { useSetMedicalDiagnoses } from 'hooks/use-care-plan-medical-diagnoses';
import { EntryId, useContentfulEntry } from 'hooks/use-contentful';
import { sortBy } from 'lodash';
import { useQueryClient } from 'react-query';

interface ClientMedicalDiagnosesModalProps {
  isOpen: boolean;
  onClose: () => void;
  clientId: string;
  medicalDiagnoses: MedicalDiagnosis[];
  shouldLogEvent: boolean;
}

interface ClientMedicalDiagnosesForm {
  medicalDiagnoses: SelectOption[];
  code: string;
  description: string;
  isAcknowledged: boolean;
}

interface ContentfulMedicalDiagnosis {
  icd10Code: string;
  icd10Description: string;
}

interface ContentfulEntry {
  icd10Diagnosis: ContentfulMedicalDiagnosis[];
}

const mapToOption = (item: MedicalDiagnosis): SelectOption<string> => {
  return {
    value: item.code,
    label: item.code.includes('#-') ? item.description : `${item.code} - ${item.description}`,
  } as SelectOption;
};

export function ClientMedicalDiagnosesModal(props: ClientMedicalDiagnosesModalProps) {
  const { isOpen, onClose, clientId, shouldLogEvent, medicalDiagnoses } = props;
  const { data: contentfulEntries, isLoading: isLoadingContentful } = useContentfulEntry<ContentfulEntry>({
    entryId: EntryId.MedicalDiagnoses,
  });
  const [defaultMedicalDiagnoses, setDefaultMedicalDiagnoses] = useState<MedicalDiagnosis[]>();
  const medicalDiagnosesSorted = sortBy(medicalDiagnoses, ['code']);
  const { mutateAsync: setMedicalDiagnoses, isLoading } = useSetMedicalDiagnoses();
  const queryClient = useQueryClient();
  const medicalDiagnosesOptions: MedicalDiagnosis[] = [];
  const uniqueICDCodes = new Set<string>();

  (contentfulEntries?.icd10Diagnosis ?? []).forEach(item => {
    if (item && !uniqueICDCodes.has(item.icd10Code)) {
      uniqueICDCodes.add(item.icd10Code);
      medicalDiagnosesOptions.push({
        code: item.icd10Code,
        description: item.icd10Description,
      });
    }
  });

  const medicalDiagnosesOptionsSorted = sortBy(medicalDiagnosesOptions, ['code']);

  const {
    formState: { errors },
    handleSubmit,
    control,
    watch,
    reset,
    getValues,
    setValue,
  } = useForm<ClientMedicalDiagnosesForm>({
    defaultValues: {
      code: '',
      description: '',
      isAcknowledged: false,
    },
  });

  const watchIsAcknowledged = watch('isAcknowledged');
  const watchMedicalDiagnoses = watch('medicalDiagnoses');

  const onSubmit = async (data: ClientMedicalDiagnosesForm) => {
    const mappedItems = medicalDiagnosesOptionsSorted.filter(item =>
      data.medicalDiagnoses.map(m => m.value).includes(item.code),
    );

    await setMedicalDiagnoses({ clientId, medicalDiagnoses: mappedItems, shouldLogEvent });

    await queryClient.invalidateQueries('medical-diagnoses');
    setValue('isAcknowledged', false);
    onClose();
  };

  useEffect(() => {
    setDefaultMedicalDiagnoses(medicalDiagnosesSorted);
    reset({ ...getValues() });
  }, [medicalDiagnoses, isLoading, isOpen, medicalDiagnoses.length]);

  return (
    <Modal portal isOpen={isOpen}>
      <ModalContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader>
            <CloseButton
              onClick={() => {
                setValue('isAcknowledged', false);
                onClose();
              }}
            />
          </ModalHeader>
          <ModalBody>
            <div>
              <div tw="flex items-center justify-center w-12 h-12 mx-auto bg-indigo-100 rounded-full">
                <FontAwesomeIcon tw="text-2xl text-indigo-700" icon={faNotesMedical} data-testid="icon" />
              </div>
              <div tw="mt-3 sm:mt-5">
                <h3 tw="text-lg text-center font-medium text-gray-900 leading-6" id="modal-headline">
                  New Medical Diagnoses
                </h3>
                <div tw="mt-10">
                  <FormGroup>
                    <Label htmlFor="medical-diagnoses">Common Medical Diagnoses</Label>

                    <div tw="w-full" data-testid="medical-diagnoses">
                      <Controller
                        name="medicalDiagnoses"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            id="medicalDiagnoses"
                            isSearchable
                            isMulti
                            error={errors.medicalDiagnoses}
                            options={medicalDiagnosesOptionsSorted?.map(item => mapToOption(item))}
                          />
                        )}
                        defaultValue={defaultMedicalDiagnoses?.map(item => mapToOption(item))}
                        rules={{ required: true }}
                      />
                    </div>
                  </FormGroup>
                </div>
              </div>
            </div>

            <div className="text-gray-400 text-xs">
              <p className="mb-4">
                Only add a medical diagnosis if there is a documented previous diagnosis. Self-reported diagnoses from
                the client/contact should not be added.
              </p>
              <p className="mb-6">
                Medical diagnoses require clinical documentation to be uploaded to Cue for insurance and other medical
                documentation requests. Without this documentation in place, the client is at a higher risk of claims
                denials.
              </p>
              <FormInline>
                <Input
                  id="isAcknowledged"
                  type="checkbox"
                  {...control.register('isAcknowledged', { required: true })}
                />
                <Label htmlFor="isAcknowledged" className="text-black text-xs font-normal ml-2 cursor-pointer">
                  Clinical documentation has been added to Cue
                </Label>
              </FormInline>
            </div>
          </ModalBody>
          <ModalFooter>
            <div tw="mt-2 sm:mt-2 sm:grid sm:grid-cols-1 sm:gap-3 sm:grid-flow-row-dense">
              <span tw="flex w-full rounded-md shadow-sm">
                <Button
                  type="submit"
                  data-testid="medical-diagnoses-submit"
                  variant="primary"
                  disabled={watchIsAcknowledged === false || !watchMedicalDiagnoses?.length}
                  loading={isLoading || isLoadingContentful}
                  tw="inline-flex justify-center w-full px-4 py-2 text-base font-medium leading-6 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                >
                  Save
                </Button>
              </span>
            </div>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
