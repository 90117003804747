import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { EvaluationEntriesIds, useEvaluationContentfulSelectOptions } from './use-evaluation-contentful-provider';

export default function useBehavioralObservations() {
  const { setValue } = useFormContext();
  const behavioralObservations = useEvaluationContentfulSelectOptions(EvaluationEntriesIds.BehavioralObservations);
  const skilledInterventions = useEvaluationContentfulSelectOptions(EvaluationEntriesIds.SkilledInterventions);

  const watchAcknowledgeSpeaker = useWatch({ name: 'acknowledgesSpeaker' });
  const watchAttentionToTasks = useWatch({ name: 'attentionToTasks' });
  const watchTopicMaintenance = useWatch({ name: 'topicMaintenance' });
  const watchReturnsGreeting = useWatch({ name: 'returnsGreeting' });
  const watchTransitionsIntoTreatment = useWatch({ name: 'transitionsIntoTreatment' });
  const watchTransitionsBetweenTasks = useWatch({ name: 'transitionsBetweenTasks' });
  const watchAwarenessOthersEnvironment = useWatch({ name: 'awarenessOthersEnvironment' });
  const watchCooperation = useWatch({ name: 'cooperation' });
  const watchResponseRate = useWatch({ name: 'responseRate' });

  useEffect(() => {
    if (!watchAcknowledgeSpeaker)
      setValue('acknowledgesSpeaker', { value: 'Within normal limits', label: 'Within normal limits' });
    if (!watchAttentionToTasks)
      setValue('attentionToTasks', { value: 'Within normal limits', label: 'Within normal limits' });
    if (!watchTopicMaintenance)
      setValue('topicMaintenance', { value: 'Within normal limits', label: 'Within normal limits' });
    if (!watchReturnsGreeting)
      setValue('returnsGreeting', { value: 'Within normal limits', label: 'Within normal limits' });
    if (!watchTransitionsIntoTreatment)
      setValue('transitionsIntoTreatment', { value: 'Within normal limits', label: 'Within normal limits' });
    if (!watchTransitionsBetweenTasks)
      setValue('transitionsBetweenTasks', { value: 'Within normal limits', label: 'Within normal limits' });
    if (!watchAwarenessOthersEnvironment)
      setValue('awarenessOthersEnvironment', { value: 'Within normal limits', label: 'Within normal limits' });
    if (!watchCooperation) setValue('cooperation', { value: 'Within normal limits', label: 'Within normal limits' });
    if (!watchResponseRate) setValue('responseRate', { value: 'Within normal limits', label: 'Within normal limits' });
  }, [
    watchAcknowledgeSpeaker,
    watchAttentionToTasks,
    watchTopicMaintenance,
    watchReturnsGreeting,
    watchTransitionsIntoTreatment,
    watchTransitionsBetweenTasks,
    watchAwarenessOthersEnvironment,
    watchCooperation,
    watchResponseRate,
  ]);

  return {
    behavioralObservations,
    skilledInterventions,
  };
}
