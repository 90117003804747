import { FormGroup, Label } from '@expressable/ui-library';
import TherapistsTimeSelector from 'components/therapistMatcher/TherapistTimeGrid';
import dayjs, { Dayjs } from 'dayjs';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { SingleDatePicker } from 'react-dates';
import { Therapist } from 'types';
import 'twin.macro';
import { TimeSlot } from 'components/therapistMatcher/data';
import { getMinDateForFirstRecurring, MAX_DAYS_FOR_RECURRING_SESSION } from 'domain/therapist-matcher/scheduling';

export interface DifferentTherapistSessionProps {
  therapists: Therapist[];
  value?: TimeSlot | null;
  onChange?: (value: TimeSlot | null) => void;
  onSkip?: () => void;
  evaluationTime: TimeSlot;
}

const DifferentTherapistSession = ({
  therapists,
  value,
  onChange,
  onSkip,
  evaluationTime,
}: DifferentTherapistSessionProps) => {
  const minDateAllowed = getMinDateForFirstRecurring(evaluationTime.timestamp);
  const [startDateFocused, setStartDateFocused] = useState(false);
  const [startDate, setStartDate] = useState<Dayjs | null>(minDateAllowed);

  const filteredTherapists = useMemo(
    () => therapists.filter(t => t.therapistID !== evaluationTime.therapist.therapistID),
    [therapists, evaluationTime],
  );

  return (
    <div tw="mb-8 flex flex-col gap-4">
      <div>
        <FormGroup tw="flex-grow md:flex-grow-0">
          <Label htmlFor="startDate" tw="font-semibold text-sm mb-2">
            Start Date
          </Label>
          <div tw="w-40">
            <SingleDatePicker
              noBorder
              block
              openDirection="down"
              numberOfMonths={1}
              small
              date={startDate ? moment(startDate.toISOString()) : null}
              onDateChange={dateValue => {
                if (dateValue) {
                  setStartDate(dayjs(dateValue.toISOString()));
                }
              }}
              hideKeyboardShortcutsPanel
              focused={startDateFocused}
              onFocusChange={({ focused }) => setStartDateFocused(focused)}
              id="startDate"
              isOutsideRange={day =>
                day.isBefore(minDateAllowed.toISOString(), 'days') ||
                day.isAfter(moment().add(MAX_DAYS_FOR_RECURRING_SESSION, 'days'))
              }
            />
          </div>
        </FormGroup>
      </div>
      {startDate && (
        <div>
          <TherapistsTimeSelector
            type="session"
            duration={30}
            therapists={filteredTherapists}
            dateFilter={startDate}
            value={value}
            onChange={onChange}
          />
        </div>
      )}
    </div>
  );
};

export default DifferentTherapistSession;
