import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  CloseButton,
  //FormGroup,
  Input,
  Label,
  LoadingText,
  Menu,
  MenuItem,
  MenuLink,
  MenuList,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
} from '@expressable/ui-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APPOINTMENT_TIME_10_AM, displayTimezoneOptions } from 'components/appointment-modals/add-appointments';
import ModalHeadline from 'components/modal-headline';
import { timeOptions as appointmentTimeOptions, getSelectedTimeDayJs } from 'utils/time-options';
import { SingleDatePicker } from 'react-dates';
import { Moment } from 'moment';
import moment from 'moment';
import { useTherapist } from 'hooks/use-therapists';
import { useTherapistAvailableTimesV2 } from 'components/therapistMatcher/data';
import { useToasts } from 'react-toast-notifications';
import { useClient } from 'hooks/use-client';
import { ReschedulePendingReschedulePayload, ReschedulePendingRescheduleProps } from 'domain/pending-reschedule/types';
import { useReschedulePendingReschedule } from 'hooks/use-pending-reschedule';
import 'twin.macro';
import dayjs from 'dayjs';
import { IConflict } from 'utils/appointments/conflicts';
import Conflicts from 'components/appointment-modals/conflicts';
import { getWeekSummaryStatusDescription } from 'domain/therapist/constants';
import { useQueryClient } from 'react-query';
import { getCueError } from 'utils/error';
// import FormGroupTitle from 'components/form-group-title';
// import { uniqBy } from 'lodash';
// import { SelectOption } from 'types';

export function ReschedulePendingRescheduleModal(props: ReschedulePendingRescheduleProps) {
  const { isOpen, onClose, currentPendingReschedule } = props;
  const [date, setDate] = useState<Moment | null>(moment());
  const [datePickerFocused, setDatePickerFocused] = useState(false);
  const [displayTimezone, setDisplayTimezone] = useState<Record<string, string>>(displayTimezoneOptions[0]);
  const [time, setTime] = useState<{ label: string; value: number; nonTzValue: number }>(APPOINTMENT_TIME_10_AM);
  const [availableTimes, setAvailableTimes] = useState<boolean>(true);
  const [isGuessTimezoneReady, setIsGuessTimezoneReady] = useState<boolean>(false);
  const reschedulePendingRescheduleMutation = useReschedulePendingReschedule();
  const { mutate: reschedulePendingReschedule } = reschedulePendingRescheduleMutation;
  const { data: therapistData, isLoading: isTherapistDateLoading } = useTherapist(
    currentPendingReschedule?.therapistEmail as string,
  );
  const { data: clientData } = useClient(currentPendingReschedule?.clientID as string);
  const [conflicts, setConflicts] = useState<IConflict[]>([]);
  //const { data: therapists } = useTherapists('active');

  const contacts = clientData?.contactInformation ?? [];
  const primaryContact = contacts.find(contact => contact.primaryContact);

  const queryClient = useQueryClient();

  //const contactState = primaryContact?.address?.state;

  const { addToast } = useToasts();

  // const therapistSelectOptions: SelectOption[] | undefined = useMemo(() => {
  //   if (!contactState)
  //     return therapists?.map(therapist => ({ label: therapist.therapistName, value: therapist.therapistEmail }));
  //   return therapists
  //     ?.filter(therapist => therapist.statesLicensed.includes(contactState))
  //     .map(therapist => ({ label: therapist.therapistName, value: therapist.therapistEmail }));
  // }, [contactState, therapists]);

  // const [selectedTherapist, setSelectedTherapist] = useState<SelectOption>({
  //   label: `${currentPendingReschedule?.therapistFirstName} ${currentPendingReschedule?.therapistLastName}`,
  //   value: currentPendingReschedule?.therapistEmail ?? '',
  // });

  const therapist = useMemo(() => {
    return therapistData;
  }, [therapistData]);

  const type = 'session';
  const duration = Number(currentPendingReschedule?.originalAcuityAppointment.duration);

  const { data: availableTimesV2, isLoading } = useTherapistAvailableTimesV2({
    calendarID: therapist?.acuityCalendarID,
    date: date?.format('YYYY-MM-DD'),
    duration,
    type,
  });

  const availableTimesOptions = availableTimesV2?.items?.map(availableTime => ({
    label: dayjs(availableTime.time).tz(displayTimezone.value).format('h:mm A'),
    value: getSelectedTimeDayJs(dayjs(availableTime.time).tz(displayTimezone.value)).value,
    nonTzValue: getSelectedTimeDayJs(availableTime.time).value,
  }));

  useEffect(() => {
    setDate(moment());
    setAvailableTimes(true);
    setDisplayTimezone(displayTimezoneOptions[1]);
  }, [isOpen]);

  useEffect(() => {
    if (availableTimesOptions?.length && availableTimes) {
      setTime(availableTimesOptions[0]);
    } else {
      setTime(APPOINTMENT_TIME_10_AM);
    }
  }, [availableTimesOptions?.length, availableTimes]);

  useEffect(() => {
    setDisplayTimezone(() => {
      const guessTimezone =
        displayTimezoneOptions.find(timezone => {
          // for some reason mutating the moment to use the tz makes dst detection effective
          const timezoneArrayOffset = moment.tz(date, timezone.value).utcOffset();
          const appointmentTimeOffset = date?.utcOffset();
          return appointmentTimeOffset === timezoneArrayOffset;
        }) ?? displayTimezoneOptions[1];
      if (guessTimezone) {
        setIsGuessTimezoneReady(true);
      }
      return guessTimezone;
    });
  }, []);

  // append time to existing date every time the time changes or every time the date changes
  useEffect(() => {
    if (date && isGuessTimezoneReady) {
      date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).tz(displayTimezone.value);
      date.add(time?.value, 'hours');
    }
  }, [time, date, isGuessTimezoneReady]);

  // useEffect(() => {
  //   setSelectedTherapist({
  //     label: `${currentPendingReschedule?.therapistFirstName} ${currentPendingReschedule?.therapistLastName}`,
  //     value: currentPendingReschedule?.therapistEmail ?? '',
  //   });
  // }, [isOpen, clientData]);

  const submit = (event: React.SyntheticEvent) => {
    let formError = false;
    event.preventDefault();

    if (typeof time !== 'undefined' && date) {
      if (
        typeof primaryContact?.email === 'undefined' ||
        typeof primaryContact?.phone === 'undefined' ||
        typeof primaryContact?.contactTimeZone === 'undefined'
      ) {
        formError = true;
        const contactInfoErrorMessage = 'Primary contact information is missing';
        addToast(contactInfoErrorMessage, { appearance: 'error', autoDismiss: true });
      }
      if (!formError) {
        const payload = {
          clientID: currentPendingReschedule?.clientID ?? '',
          pendingRescheduleID: currentPendingReschedule?.pendingRescheduleID ?? '',
        };
        const reschedulePendingReschedulePayload: ReschedulePendingReschedulePayload = {
          ...payload,
          timeZone: displayTimezone.local ?? '',
          dateTime: date.toISOString(),
          therapistEmail: therapistData?.therapistID,
          duration,
          override: !availableTimes,
        };
        reschedulePendingReschedule(reschedulePendingReschedulePayload, {
          onSuccess: () => {
            onClose();
          },
          onError: err => {
            queryClient.invalidateQueries();
            const error = getCueError(err);
            if ('conflicts' in error) setConflicts((error as any)?.conflicts);
            addToast(error?.message || 'Something Went Wrong', { appearance: 'error', autoDismiss: true });
          },
        });
      }
    }
  };

  useEffect(() => {
    const newTime = availableTimesOptions?.find(availableTime => {
      return availableTime.nonTzValue === time?.nonTzValue;
    });

    if (newTime && availableTimes) {
      setTime(newTime);
    }
  }, [displayTimezone.value, availableTimes]);

  useEffect(() => {
    if (isLoading || !date) {
      setConflicts([]);
      return;
    }

    const mainDateHours = moment(date).hour();
    const mainDateMinutes = moment(date).minute();
    const dateTime = mainDateHours + mainDateMinutes / 60;
    const selectedDateFormatted = date?.toISOString();

    if (time?.value > dateTime) return;

    const isDateInAvailableTimes = (item: any) => {
      const itemTime = moment(item.time).tz(displayTimezone.value).toISOString();
      return itemTime === selectedDateFormatted;
    };

    const handleOverride = () => {
      const isAvailable = availableTimesV2?.items?.some(isDateInAvailableTimes);
      const conflictKey = selectedDateFormatted;
      const conflictMap = new Map<string, IConflict>();

      if (!isAvailable) {
        conflictMap.set(conflictKey, {
          datetime: selectedDateFormatted,
          type: availableTimes ? 'appointmentExists' : 'futureConflict',
        });
      }

      setConflicts(Array.from(conflictMap.values()));
    };

    const handleNonOverride = () => {
      const isAvailable =
        availableTimesV2?.items?.length === 0 || availableTimesV2?.items?.some(isDateInAvailableTimes);
      const conflictKey = selectedDateFormatted;
      const conflictMap = new Map<string, IConflict>();

      if (!isAvailable) {
        conflictMap.set(conflictKey, {
          datetime: selectedDateFormatted,
          type: availableTimes ? 'appointmentExists' : 'futureConflict',
        });
      }

      const selectedItem = availableTimesV2?.items?.find(isDateInAvailableTimes);
      selectedItem?.conflicts?.forEach((conflict: { datetime: string; type: string }) => {
        conflictMap.set(conflict?.datetime, {
          datetime: conflict?.datetime,
          type: availableTimes ? conflict?.type : 'futureConflict',
        });
      });

      setConflicts(Array.from(conflictMap.values()));
    };

    if (!availableTimes) handleOverride();
    else handleNonOverride();
  }, [isLoading, availableTimes, availableTimesV2, date, time, displayTimezone, setConflicts]);

  return (
    <Modal isOpen={isOpen}>
      <ModalContent>
        <ModalHeader>
          <div tw="absolute top-0 right-0 pt-4 pr-4">
            <CloseButton testId="close-reschedule-pending-reschedule-modal" onClick={onClose} />
          </div>
        </ModalHeader>
        <form onSubmit={submit}>
          <ModalBody>
            <div>
              <div tw="flex items-center justify-center w-12 h-12 mx-auto bg-indigo-100 rounded-full">
                <FontAwesomeIcon tw="text-2xl text-indigo-700" icon="calendar-check" />
              </div>
              <div tw="mt-3 sm:mt-5">
                <ModalHeadline
                  text={`Reschedule Appointment for ${currentPendingReschedule?.clientFirstName} ${currentPendingReschedule?.clientLastName} with ${currentPendingReschedule?.therapistFirstName} ${currentPendingReschedule?.therapistLastName}`}
                />
                <div tw="mt-2">
                  <div tw="text-sm text-center text-gray-500 leading-5">
                    Times are shown in -
                    <Menu
                      onChange={MenuItem => {
                        const selectedMenuItem = MenuItem as Record<string, string>;
                        setDisplayTimezone(selectedMenuItem);
                        if (date && selectedMenuItem) {
                          //true at the end of tz preserve the time already picked up
                          setDate(date.tz(selectedMenuItem.value, true));
                        }
                      }}
                      tw="px-1 inline"
                    >
                      <MenuLink to="#" data-testid="reschedule-pending-reschedule-time-zone">
                        {displayTimezone?.label} Time Zone
                        <FontAwesomeIcon tw="text-sm ml-1" icon="caret-down" />
                      </MenuLink>
                      <MenuList tw="w-full">
                        {displayTimezoneOptions.map((option, index) => (
                          <MenuItem value={option} key={index}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </Menu>
                  </div>
                </div>
                <div tw="mt-5 flex justify-center text-center">
                  <Input
                    data-testid="reschedule-pending-reschedule-available-times"
                    name="available-times"
                    id="available-times"
                    type="checkbox"
                    tw="mr-2"
                    checked={availableTimes}
                    onChange={e => setAvailableTimes(e.currentTarget.checked)}
                  />
                  <Label font="normal" htmlFor="available-times">
                    Only Show Available Times
                  </Label>
                </div>
                {!availableTimes && (
                  <div tw="bg-[#FEE2E2] p-3 rounded-md mt-1 mb-5">
                    Please note that viewing unavailable times removes all calendar protections and may result in a
                    schedule conflict.
                  </div>
                )}
                {/* dev-note(Gian): adding another therapist will be supported on another ticket due to a big refactor that should be included in the backend */}
                {/* <FormGroup tw="">
                  <FormGroupTitle title="Therapist" fontSize="small" fontWeight="semi" spacing="normal" />
                  <Select
                    aria-label="appointment-therapist-dropdown"
                    name="appointment-therapist-dropdown"
                    data-testid="add-appointment-therapist"
                    id="appointment-therapist-dropdown"
                    value={selectedTherapist}
                    disabled={!contactState}
                    options={uniqBy(
                      [
                        ...(therapistSelectOptions ?? []),
                        {
                          label: `${therapistData?.firstName} ${therapistData?.lastName}`,
                          value: therapistData?.therapistID,
                        },
                        {
                          label: `${currentPendingReschedule?.therapistFirstName} ${currentPendingReschedule?.therapistLastName}`,
                          value: currentPendingReschedule?.therapistEmail,
                        },
                      ],
                      'value',
                    )}
                    onChange={(e: SelectOption) => setSelectedTherapist(e)}
                    tw="mb-2"
                  />
                  <div className="text-xs text-gray-500 mb-3">
                    {contactState
                      ? `Only therapists with a state licensure of ${contactState} are displayed`
                      : 'Please add a state to the primary contact to be able to add to another therapist'}
                  </div>
                </FormGroup> */}
                <div tw="flex">
                  <div tw="w-1/2 mr-2">
                    <div data-testid="add-appointment-date">
                      <div tw="mb-1">Date</div>
                      <SingleDatePicker
                        id="add-appointment-date"
                        block
                        small
                        noBorder
                        openDirection="up"
                        numberOfMonths={1}
                        date={date}
                        hideKeyboardShortcutsPanel
                        focused={datePickerFocused}
                        onFocusChange={({ focused }) => setDatePickerFocused(focused)}
                        onDateChange={date => {
                          setDate(date);
                        }}
                      />
                    </div>
                  </div>
                  {availableTimes ? (
                    <div tw="w-1/2 ml-2">
                      {isLoading ? (
                        <div data-testid="reschedule-pending-reschedules-loading" tw="flex-1 text-center mt-8">
                          <LoadingText />
                        </div>
                      ) : (
                        <div>
                          {!availableTimesV2?.isRateLimited && availableTimesV2?.items?.length ? (
                            <>
                              <div data-testid="reschedule-pending-reschedules-available-time">
                                <div tw="mb-1">Time</div>
                                <Select
                                  aria-label="time-dropdown"
                                  name="time-dropdown"
                                  spacing="small"
                                  options={availableTimesOptions}
                                  value={time}
                                  onChange={(timeOption: { label: string; value: number; nonTzValue: number }) => {
                                    setTime(timeOption);
                                  }}
                                />
                              </div>
                            </>
                          ) : (
                            <div
                              tw="flex items-center justify-center text-gray-500 mt-5 ml-4"
                              data-testid="not-available-times"
                            >
                              {availableTimesV2?.isRateLimited ? (
                                getWeekSummaryStatusDescription(availableTimesV2.rateLimitStatus)
                              ) : (
                                <>
                                  <span className="mt-3 ml-4">No times available today</span>
                                </>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  ) : (
                    <div tw="w-1/2 ml-2">
                      <div data-testid="reschedule-pending-reschedules-time">
                        <div tw="mb-1">Time</div>
                        <Select
                          aria-label="time-dropdown"
                          name="time-dropdown"
                          spacing="small"
                          value={time}
                          options={appointmentTimeOptions}
                          onChange={(timeOption: { label: string; value: number; nonTzValue: number }) => {
                            setTime(timeOption);
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
                {!isLoading && conflicts.length > 0 && (
                  <div className="mt-3">
                    <Conflicts
                      conflicts={conflicts}
                      availableTimes={availableTimes}
                      displayTimezone={displayTimezone.value}
                    />
                  </div>
                )}
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div tw="mt-4 sm:mt-5 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
              <span tw="flex w-full rounded-md shadow-sm sm:col-start-2">
                <Button
                  data-testid="reschedule-pending-reschedules-appointment-submit"
                  type="submit"
                  variant="primary"
                  loading={reschedulePendingRescheduleMutation.isLoading || isTherapistDateLoading}
                  tw="inline-flex justify-center w-full px-4 py-2 text-base font-medium leading-6 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                  disabled={
                    isTherapistDateLoading ||
                    (availableTimes && !availableTimesOptions?.length) ||
                    (availableTimes && availableTimesV2?.isRateLimited)
                  }
                >
                  Reschedule
                </Button>
              </span>
              <span tw="flex w-full mt-3 rounded-md shadow-sm sm:mt-0 sm:col-start-1">
                <Button
                  data-testid="reschedule-pending-reschedules-close"
                  type="button"
                  variant="secondary"
                  tw="inline-flex justify-center w-full px-4 py-2 text-base font-medium leading-6 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </span>
            </div>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
