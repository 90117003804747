import React, { useEffect, useState } from 'react';
import { useContacts } from '../hooks/use-contacts';
import { Container, Loader, Select, Spacer, TitleBar } from '@expressable/ui-library';
import Search from '../components/search';
import 'twin.macro';
import { Link } from 'react-router-dom';
import List from '../components/list';
import useTherapists from 'hooks/use-therapists';
import { useCurrentUserContext } from '@expressable/utils';
import { Helmet } from 'react-helmet';
import { Therapist } from 'types';
import { isCurrentUserActiveTherapist } from 'utils/helpers';

interface Props {
  searchTerm: string;
  setSearchTerm(searchTerm: string): void;
  filteredItems: [];
}

interface Contact {
  contactID: string;
  contactFirstName: string;
  contactLastName: string;
}

const ContactsView = ({ searchTerm, setSearchTerm, filteredItems }: Props) => (
  <>
    <div tw="max-w-sm relative">
      <input
        tw="p-3 outline-none border focus:border-indigo-300"
        css={{ width: '100%', paddingRight: 50 }}
        onChange={event => setSearchTerm(event.target.value)}
        type="text"
        placeholder={`Search ${filteredItems.length} Records`}
        aria-label="Search Clients"
        value={searchTerm}
        autoFocus
      />
      <div
        css={{
          position: 'absolute',
          right: 14,
          top: '50%',
          transform: 'translateY(-50%)',
          opacity: 0.6,
          fontSize: '0.8rem',
        }}
      >
        {filteredItems.length}
      </div>
    </div>
    <div
      css={{
        marginTop: 20,
      }}
    >
      <List
        data={filteredItems}
        render={(element: Contact, index) => (
          <li key={index}>
            <Link tw="block p-4 hover:bg-indigo-100" to={{ pathname: `/contacts/${element.contactID}` }}>
              {`${element.contactFirstName}  ${element.contactLastName}`}
            </Link>
          </li>
        )}
      />
    </div>
  </>
);

export const toSelectOptions = (therapist: Therapist) => ({
  label: therapist.therapistName,
  value: therapist.therapistEmail,
});

const allOption = { label: 'All', value: '' };

const Contacts = () => {
  const currentUser = useCurrentUserContext();

  const [therapistEmail, setTherapistEmail] = useState<undefined | string>();
  const { data: contactsList, isLoading: contactsIsLoading, isRefetching } = useContacts(therapistEmail);
  const { data: therapistsData, isLoading } = useTherapists();
  const [flagFirst, setFlagFirst] = useState(true);

  const isCurrentUserInTherapistData = isCurrentUserActiveTherapist(therapistsData, currentUser);

  let selectOptions = (therapistsData && therapistsData.map(toSelectOptions)) ?? [];
  selectOptions = [{ ...allOption }, ...selectOptions];

  useEffect(() => {
    if (therapistsData?.length && contactsList?.length == 0 && flagFirst) {
      setTherapistEmail('');
      setFlagFirst(false);
    }
  }, [therapistsData, contactsList]);

  useEffect(() => {
    if (!isCurrentUserInTherapistData && flagFirst) {
      setTherapistEmail('');
      setFlagFirst(true);
    } else {
      setTherapistEmail(currentUser?.email);
    }
  }, [isCurrentUserInTherapistData, currentUser]);

  if (isLoading || contactsIsLoading || isRefetching) {
    return (
      <div tw="h-screen flex justify-center items-center" data-testid="therapist-contacts">
        <Loader type="ring" />
      </div>
    );
  }

  return (
    <div data-testid="contacts" css={{ marginBottom: 28 }}>
      <Helmet title="Contacts" />
      <div tw="relative">
        <TitleBar title="Contacts" />
      </div>
      <Spacer size="md" />
      <Container tw="relative" size="large">
        <div tw="md:absolute flex md:justify-end mb-5 md:mb-0 md:w-80 items-baseline" style={{ right: '2rem' }}>
          <label tw="block mr-5 font-bold">Contacts for</label>
          {therapistsData && (
            <div tw="flex-1" data-testid="therapist">
              <Select
                id="therapist"
                required
                isSearchable
                value={selectOptions.filter(therapist => therapist.value == therapistEmail)[0] ?? { ...allOption }}
                options={selectOptions}
                onChange={(selectOption: { [key: string]: string }) => {
                  setTherapistEmail(selectOption.value);
                  setFlagFirst(false);
                }}
              />
            </div>
          )}
        </div>

        {!isLoading && contactsList && contactsList.length > 0 ? (
          <Search
            render={ContactsView}
            data={contactsList.map((contact: { [key: string]: string }) => ({
              ...contact,
              contactFirstName: contact.contactFirstName?.trim(),
              contactLastName: contact.contactLastName?.trim(),
              contactFullName: `${contact.contactFirstName?.trim()} ${contact.contactLastName?.trim()}`,
            }))}
            options={{
              includeScore: false,
              keys: ['contactFirstName', 'contactLastName', 'contactFullName'],
              threshold: 0.1,
            }}
          />
        ) : (
          <>
            {!therapistEmail && 'No contact selected'}
            {!isLoading && therapistEmail && (
              <div tw="pt-16">
                <div tw="flex bg-gray-200 text-gray-400 font-semibold  text-xl py-36 justify-center items-center">
                  <h3>There are currently no assigned contacts for this therapist</h3>
                </div>
              </div>
            )}
          </>
        )}
      </Container>
    </div>
  );
};

export default Contacts;
