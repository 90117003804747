import { useClient } from 'hooks/use-client';
import { getPrimaryContact } from '../../client-sidebar';
import { createEmptyInitialEvaluationOptions } from 'pages/new-client-create/SchedulingSessions/initial-evaluation-options';
import { TimeSlot } from 'components/therapistMatcher/data';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { CreateAppointmentPayload, useCreateAppointment } from 'hooks/use-appointments';
import { CreateEvaluationPayload, useCreateEvaluation } from 'hooks/use-evaluations';
import { SessionType } from 'domain/appointment/constants';
import { getPhoneNumber } from 'utils/helpers';
import * as Sentry from '@sentry/react';
import { useToast } from '@expressable/ui-library';
import useModifyClient from 'hooks/use-modify-client-info';
import { useDisplayTimezone } from 'hooks/common/useDisplayTimezone';
import { usStatesOptionsWithMultipleTimezones } from '../helpers';
import { findTimezoneOptionByLabel, TIMEZONE_OPTIONS } from 'hooks/common/useDisplayTimezone/options';

export const useClientMatching = (clientId: string) => {
  const toast = useToast();
  const { setDisplayTimezone } = useDisplayTimezone();

  const { mutateAsync: createAppointmentAsync, isLoading: isLoadingAppointment } = useCreateAppointment();
  const { mutateAsync: createEvaluationAsync, isLoading: isLoadingEvaluation } = useCreateEvaluation();
  const { mutateAsync: updateClientAsync, isLoading: isLoadingUpdateClient } = useModifyClient();

  const [autoSelectTimezone, setAutoSelectTimezone] = useState(true);
  const [initialEvaluationOptions, setInitialEvaluationOptions] = useState(createEmptyInitialEvaluationOptions());
  const [timeSlot, setTimeSlot] = useState<TimeSlot | null>(null);
  const [startDate, setStartDate] = useState<Dayjs | null>(dayjs());

  const { data: clientData } = useClient(clientId);
  const { contactInformation = [] } = clientData ?? {};

  const isLoadingMutation = isLoadingAppointment || isLoadingEvaluation || isLoadingUpdateClient;
  const primaryContact = getPrimaryContact(contactInformation, clientId);

  const onChangeEvalOptions = (value: any) => setInitialEvaluationOptions(value);

  useEffect(() => {
    if (autoSelectTimezone && primaryContact?.address?.state) {
      const state = usStatesOptionsWithMultipleTimezones.find(state => state.value === primaryContact?.address?.state);

      if (state) {
        setAutoSelectTimezone(true);
        setDisplayTimezone(findTimezoneOptionByLabel(state.timezone) ?? TIMEZONE_OPTIONS.EASTERN);
      }
    } else {
      Sentry.captureException('WARNING ::: unable to auto capture timezone. Client is missing primary contact state', {
        extra: {
          clientId,
          source: 'client-matching',
        },
      });
    }
  }, [autoSelectTimezone, primaryContact?.address?.state]);

  const onAppointmentCreationFailure = (reason: any) => {
    window.scrollTo(0, 0);
    Sentry.captureException('ERROR :: Failed to create appointment', {
      extra: {
        clientId,
        reason,
        source: 'client-matching',
      },
    });
  };

  const createAppointmentSession = async (
    timeSlot: TimeSlot | null,
    sessionType: SessionType,
    userTimezone: string,
    onSuccess: () => void,
  ) => {
    if (clientData && primaryContact && timeSlot) {
      const primaryContactPhoneNumber = getPhoneNumber(primaryContact.phone);

      if (!primaryContactPhoneNumber) {
        Sentry.captureException('FATAL ::: Primary contact phone number is missing', {
          extra: {
            clientId,
            source: 'client-matching',
          },
        });

        toast.errorToast(
          'This contact is missing phone number information. Please update the contact information and try again.',
        );
        return;
      }

      const payload: CreateAppointmentPayload = {
        clientID: clientId,
        clientFirstName: clientData.clientFirstName,
        clientLastName: clientData.clientLastName,
        duration: timeSlot.duration ?? 30,
        dateTime: timeSlot.timestamp.tz(userTimezone).toISOString(),
        therapistEmail: timeSlot?.therapist.therapistEmail,
        contactTimeZone: primaryContact.contactTimeZone,
        contactEmail: primaryContact.email,
        contactPhone: primaryContactPhoneNumber,
        userTimezone,
        override: !!timeSlot?.override,

        recurring: sessionType === SessionType.Recurring,
        recurringForever: sessionType === SessionType.Recurring,
      };

      await createAppointmentAsync(payload, {
        onSuccess,
        onError: onAppointmentCreationFailure,
      });
    }
  };

  const createAppointmentEvaluation = async (
    timeSlot: TimeSlot | null,
    userTimezone: string,
    onSuccess: () => void,
  ) => {
    if (clientData && primaryContact && timeSlot) {
      const primaryContactPhoneNumber = getPhoneNumber(primaryContact.phone);

      if (!primaryContactPhoneNumber) {
        Sentry.captureException('FATAL ::: Primary contact phone number is missing', {
          extra: {
            clientId,
            source: 'client-matching',
          },
        });

        toast.errorToast(
          'This contact is missing phone number information. Please update the contact information and try again.',
        );
        return;
      }

      const payload: CreateEvaluationPayload = {
        clientID: clientId,
        acuityCalendarID: Number(timeSlot.therapist.acuityCalendarID),
        clientFirstName: clientData.clientFirstName,
        clientLastName: clientData.clientLastName,
        duration: timeSlot.duration ?? 30,
        dateTime: timeSlot.timestamp.toISOString(),
        contactTimeZone: primaryContact.contactTimeZone,
        contactEmail: primaryContact.email,
        contactPhone: primaryContactPhoneNumber,
      };

      await createEvaluationAsync(payload, {
        onSuccess,
        onError: onAppointmentCreationFailure,
      });
    }
  };

  return {
    client: clientData,
    primaryContact,
    autoSelectTimezone,
    initialEvaluationOptions,
    timeSlot,
    startDate,
    isLoadingMutation,
    setTimeSlot,
    setStartDate,
    onChangeEvalOptions,
    setAutoSelectTimezone,
    createAppointmentAsync,
    createEvaluationAsync,
    setInitialEvaluationOptions,
    createAppointmentSession,
    createAppointmentEvaluation,
    updateClientAsync,
  };
};
