import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faCalendarXmark } from '@fortawesome/pro-solid-svg-icons';
import { EventProps } from 'domain/admin/types';
import { removePrefixFromID } from 'utils/helpers';
import dayjs from 'dayjs';
import 'twin.macro';

const AcuityAppointmentCancelled = (event: EventProps) => {
  const firehoseEvent = event?.event;
  const isLastEvent = event?.isLastEvent;

  return (
    <div data-testid="firehose-acuity-appointment-cancelled" tw="relative flex mt-7 px-2">
      {!isLastEvent && <span tw="absolute -z-1 top-7 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>}
      <div tw="flex items-baseline">
        <div tw="flex items-center bg-gray-200 rounded-full h-6 w-6">
          <FontAwesomeIcon icon={faCalendarXmark} tw="mx-1.5 h-3 text-indigo-700" />
        </div>
        <div tw="pl-4 text-sm">
          <div tw="xl:flex font-semibold pb-1 text-indigo-700">
            <span tw="text-gray-500">{`Acuity cancelled an`}</span>&nbsp;
            <span tw="text-gray-500 lowercase">{`${firehoseEvent.appointmentType}`}</span>
            &nbsp;
            {firehoseEvent.clientFirstName && firehoseEvent.clientLastName && (
              <div>
                <span tw="text-gray-500">for</span>&nbsp;
                {firehoseEvent.clientID ? (
                  <>
                    <Link
                      tw="text-indigo-700"
                      to={{ pathname: `/clients/${removePrefixFromID(firehoseEvent?.clientID)}` }}
                    >
                      {firehoseEvent?.clientFirstName} {firehoseEvent?.clientLastName}&nbsp;
                    </Link>
                  </>
                ) : (
                  <span>
                    {firehoseEvent?.clientFirstName} {firehoseEvent?.clientLastName}
                  </span>
                )}
              </div>
            )}
            <span tw="text-gray-500">on</span>&nbsp;
            <Link to={{ pathname: `/therapists/${firehoseEvent?.therapistEmail}` }}>
              {firehoseEvent?.therapistFirstName} {firehoseEvent?.therapistLastName}
            </Link>
            <span tw="text-gray-500">&apos;s calendar</span>&nbsp;
            <span tw="text-gray-500">{`at ${dayjs(firehoseEvent?.createdAt as string).format('h:mm A')}, ${dayjs(
              firehoseEvent?.createdAt as string,
            ).format('MM/DD/YYYY')} `}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AcuityAppointmentCancelled;
