/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { Card, Container, Input, Label, Select, Spacer, TitleBar, Button, Textarea } from '@expressable/ui-library';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import tw from 'twin.macro';
import usStatesOptions from 'utils/us-code-states';
import relationshipToClientOptions from 'utils/relationship-to-client-options';
import planTypeOptions from 'utils/plan-type-options';
import modifierOptions from 'utils/modifier-options';
import { IClaimForm, SelectOption, Therapist } from '../types';
import useTherapists from 'hooks/use-therapists';
import { SingleDatePicker } from 'react-dates';
import { CreateClaimPayload, useClaim, useCreateClaim } from '../hooks/use-claim';
import { months, getDaysByEndDay, getDaysByYearAndMonth, years } from '@expressable/utils';
import { Helmet } from 'react-helmet';
import { validateZipcode, onlyLettersSpecialVowelsWhiteSpacesValidator } from '../utils/helpers';
import moment, { Moment } from 'moment-timezone';

const FormGroup = tw.div`mb-0`;
const FormInline = tw.div`md:flex`;

const genderOptions = [
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' },
  { value: 'other', label: 'Other' },
  { value: 'not_given', label: 'Not Given' },
];

const therapistsToSelectOptions = (therapist: Therapist) => ({
  label: therapist.therapistName,
  value: therapist.therapistEmail,
});

const DEFAULT_MODIFIER_CODE = '95';
const RELATIONSHIP_SELF_CODE = '18';
const DEFAULT_DIAGNOSIS_CODE_TYPE = 'ABF';
const DEFAULT_SERVICE_QUANTITY = '1';
const DEFAULT_SERVICE_UNITS = 'UN';

const convertClaimToFormFields = (
  data: CreateClaimPayload,
  therapistsSelectOptions: Array<{ label: string; value: string }>,
): IClaimForm => {
  const formFields: IClaimForm = {
    ...data,
    renderingProvider: therapistsSelectOptions.find(({ label }) =>
      label.includes(`${data.renderingProvider.firstName} ${data.renderingProvider.lastName}`),
    ) as SelectOption,
    npi: data.renderingProvider.npi,
    client: {
      ...data.client,
      monthBirth: months.find(({ value }) => value === data.client!.dob?.slice(5, 7)) as SelectOption,
      dayBirth: getDaysByEndDay(31).find(({ value }) => value === data.client!.dob?.slice(8, 10)) as SelectOption,
      yearBirth: years.find(({ value }) => value === data.client!.dob?.slice(0, 4)) as SelectOption,
      gender: genderOptions.find(({ value }) => value === data.client!.gender) as SelectOption,
      address: {
        ...data.client.address,
        state: usStatesOptions.find(({ value }) => value === data.client!.address.state) as SelectOption,
      },
    },
    subscriberPrimary: {
      ...data.subscriberPrimary,
      monthBirth: months.find(({ value }) => value === data.subscriberPrimary!.dob?.slice(5, 7)) as SelectOption,
      dayBirth: getDaysByEndDay(31).find(
        ({ value }) => value === data.subscriberPrimary!.dob?.slice(8, 10),
      ) as SelectOption,
      yearBirth: years.find(({ value }) => value === data.subscriberPrimary!.dob?.slice(0, 4)) as SelectOption,
      gender: genderOptions.find(({ value }) => value === data.subscriberPrimary!.gender) as SelectOption,
      relationshipToClient: relationshipToClientOptions.find(
        ({ value }) => value === data.subscriberPrimary!.relationshipToClient,
      ) as SelectOption,
      address: {
        ...data.subscriberPrimary.address,
        state: usStatesOptions.find(({ value }) => value === data.subscriberPrimary!.address.state) as SelectOption,
      },
    },
    insuranceInformation: {
      ...data.insuranceInformation,
      planType: planTypeOptions.find(({ value }) => value === data.insuranceInformation!.planType) as SelectOption,
    },
    diagnoses: [...data.diagnoses],
    serviceLines: [
      ...data.serviceLines.map(service => ({
        ...service,
        modifiers: service.modifiers.map(
          modifier => modifierOptions.find(({ value }) => value === modifier) as SelectOption,
        ),
        chargeAmountCents: service.chargeAmountCents / 100,
      })),
    ],
    billingNotes: [...data.billingNotes],
  };

  return formFields;
};

const convertFormFieldsToCreateClaimPayload = (
  formData: IClaimForm,
  isClientSubscriber: boolean,
  dateOfService: Moment,
) => {
  const { npi, ...data } = formData;
  const { yearBirth: clientYearBirth, monthBirth: clientMonthBirth, dayBirth: clientDayBirth, ...client } = data.client;
  const {
    yearBirth: subscriberYearBirth,
    monthBirth: subscriberMonthBirth,
    dayBirth: subscriberDayBirth,
    ...subscriberPrimaryData
  } = data.subscriberPrimary;
  let subscriberPrimary = { ...subscriberPrimaryData };

  client.dob = `${clientYearBirth.value}-${clientMonthBirth.value}-${clientDayBirth.value}`;

  if (isClientSubscriber) {
    const { clientID, ...subscriberData } = client;
    subscriberPrimary = {
      ...subscriberData,
      relationshipToClient: relationshipToClientOptions.find(({ value }) => value === RELATIONSHIP_SELF_CODE)!,
    };
  } else {
    subscriberPrimary.dob = `${subscriberYearBirth.value}-${subscriberMonthBirth.value}-${subscriberDayBirth.value}`;
  }

  const [firstName, lastName] = data.renderingProvider.label.split(' ');

  const claim: CreateClaimPayload = {
    ...data,
    dos: dateOfService!.format('YYYY-MM-DD'),
    renderingProvider: {
      firstName,
      lastName,
      npi,
    },
    client: {
      ...client,
      gender: client.gender.value,
      address: {
        ...client.address,
        state: client.address.state.value,
      },
    },
    subscriberPrimary: {
      ...subscriberPrimary,
      gender: subscriberPrimary.gender.value,
      relationshipToClient: subscriberPrimary.relationshipToClient.value,
      address: {
        ...subscriberPrimary.address,
        state: subscriberPrimary.address.state.value,
      },
    },
    insuranceInformation: {
      ...data.insuranceInformation,
      planType: data.insuranceInformation.planType.value,
    },
    diagnoses: [
      {
        name: undefined,
        code: data.diagnoses[0].code,
        codeType: DEFAULT_DIAGNOSIS_CODE_TYPE,
      },
    ],
    serviceLines: [
      {
        procedureCode: data.serviceLines[0].procedureCode,
        quantity: DEFAULT_SERVICE_QUANTITY,
        units: DEFAULT_SERVICE_UNITS,
        modifiers: [data.serviceLines[0].modifiers[0].value],
        chargeAmountCents: Number(data.serviceLines[0].chargeAmountCents) * 100,
        diagnosisPointers: [0],
      },
    ],
    billingNotes: data.billingNotes[0]?.text ? data.billingNotes : [],
  };

  return claim;
};

const ClaimCreate = (props: RouteComponentProps) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    setValue,
    getValues,
  } = useForm<IClaimForm>();

  const { history } = props;
  const claimId = new URLSearchParams(useLocation().search).get('id');

  const { data: therapistsData } = useTherapists();
  const { data: claimData, isLoading: isLoadingGet } = useClaim(claimId);
  const { mutate: createClaim, isLoading: isLoadingSubmit } = useCreateClaim();

  const [dateOfService, setDateOfService] = useState<Moment | null>(null);
  const [datePickerFocused, setDatePickerFocused] = useState(false);
  const [emptyDateOfServiceError, setEmptyDateOfServiceError] = useState<boolean>(false);

  const [isClientSubscriber, setIsClientSubscriber] = useState(false);

  const therapistsSelectOptions = therapistsData && therapistsData.map(therapistsToSelectOptions);

  useWatch({ control, name: ['client.yearBirth', 'client.monthBirth'] });
  const clientDays = getDaysByYearAndMonth(
    parseInt(getValues('client.yearBirth')?.value),
    parseInt(getValues('client.monthBirth')?.value),
  );

  useWatch({ control, name: ['subscriberPrimary.yearBirth', 'subscriberPrimary.monthBirth'] });
  const subscriberDays = getDaysByYearAndMonth(
    parseInt(getValues('subscriberPrimary.yearBirth')?.value),
    parseInt(getValues('subscriberPrimary.monthBirth')?.value),
  );

  const submitClaim = (data: IClaimForm) => {
    const payload = convertFormFieldsToCreateClaimPayload(data, isClientSubscriber, dateOfService!);

    createClaim(payload, {
      onSuccess: () => {
        history.push('/clients');
      },
    });
  };

  let claimForm: IClaimForm | null = null;
  if (!isLoadingGet && claimData.message && therapistsSelectOptions) {
    claimForm = convertClaimToFormFields(claimData.message, therapistsSelectOptions);
  }
  const isNewClaim = !claimForm || !claimForm.encounterID;

  React.useEffect(() => {
    setValue(
      'serviceLines.0.modifiers.0',
      claimForm?.serviceLines[0].modifiers[0] ?? modifierOptions.find(({ value }) => value === DEFAULT_MODIFIER_CODE)!,
    );
    setDateOfService(moment(claimData?.message?.dos ? new Date(`${claimData.message.dos} 12:00:00`) : new Date()));
  }, [isLoadingGet]);

  if (isLoadingGet) {
    return null;
  }

  return (
    <div data-testid="claim-create">
      <Helmet title="Claim Submission" />
      <TitleBar title="Claim Submission" />
      <Spacer size="md" />
      <Container tw="relative" size="large">
        <Card tw="mb-4 px-8">
          <form onSubmit={handleSubmit(submitClaim)}>
            <div tw="mb-12">
              <div tw="text-2xl font-bold mb-7">{`${isNewClaim ? 'New' : 'Submitted'} Claim`}</div>
              <FormGroup tw="w-full sm:w-1/2 md:w-1/3">
                <Label htmlFor="claimID">Claim ID</Label>
                <Input
                  type="text"
                  id="claimID"
                  tw="w-full"
                  {...register('claimID', { required: true })}
                  defaultValue={claimForm?.claimID}
                  error={errors.claimID}
                  disabled={!isNewClaim}
                  autoFocus
                />
              </FormGroup>
              <FormGroup tw="w-full sm:w-1/2 md:w-1/4 mb-5">
                <Label htmlFor="dos">Date of Service</Label>
                <SingleDatePicker
                  id="dos"
                  block
                  small
                  noBorder
                  openDirection="up"
                  placeholder=""
                  numberOfMonths={1}
                  isOutsideRange={() => false}
                  date={dateOfService}
                  hideKeyboardShortcutsPanel
                  focused={datePickerFocused}
                  onFocusChange={({ focused }) => setDatePickerFocused(focused)}
                  onDateChange={date => {
                    setDateOfService(date);
                    setEmptyDateOfServiceError(!date);
                  }}
                  disabled={!isNewClaim}
                />
                {emptyDateOfServiceError && (
                  <div tw="mb-6">
                    <p tw="text-xs italic text-red-500">Please fill out this field.</p>
                  </div>
                )}
              </FormGroup>
              <FormGroup tw="w-full sm:w-1/2 md:w-1/3">
                <Label htmlFor="renderingProvider">Rendering Provider</Label>
                {therapistsData && (
                  <Controller
                    name="renderingProvider"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        id="renderingProvider"
                        options={therapistsSelectOptions}
                        isSearchable
                        placeholder="–"
                        error={errors.renderingProvider}
                        isDisabled={!isNewClaim}
                      />
                    )}
                    defaultValue={claimForm?.renderingProvider}
                    rules={{ required: true }}
                  />
                )}
              </FormGroup>
              <FormGroup tw="w-full sm:w-1/2 md:w-1/4">
                <Label htmlFor="npi">NPI</Label>
                <Input
                  type="text"
                  id="npi"
                  tw="w-full"
                  {...register('npi', { required: true })}
                  defaultValue={claimForm?.npi}
                  error={errors.npi}
                  disabled={!isNewClaim}
                />
              </FormGroup>
            </div>
            <div tw="mb-12">
              <div tw="text-xl font-semibold mb-7">Client Information</div>
              <FormGroup tw="w-full sm:w-1/2 md:w-1/3">
                <Label htmlFor="clientID">Client ID</Label>
                <Input
                  type="text"
                  id="clientID"
                  tw="w-full"
                  {...register('client.clientID', { required: true })}
                  defaultValue={claimForm?.client.clientID}
                  error={errors.client?.clientID}
                  disabled={!isNewClaim}
                />
              </FormGroup>
              <FormGroup tw="w-full sm:w-1/2 md:w-1/3">
                <Label htmlFor="clientFirstName">First Name</Label>
                <Input
                  type="text"
                  id="clientFirstName"
                  tw="w-full"
                  {...register('client.firstName', {
                    required: true,
                    validate: { onlyLettersSpecialVowelsWhiteSpacesValidator },
                  })}
                  defaultValue={claimForm?.client.firstName}
                  error={errors.client?.firstName}
                  disabled={!isNewClaim}
                />
              </FormGroup>
              <FormGroup tw="w-full sm:w-1/2 md:w-1/3">
                <Label htmlFor="clientLastName">Last Name</Label>
                <Input
                  type="text"
                  id="clientLastName"
                  tw="w-full"
                  {...register('client.lastName', {
                    required: true,
                    validate: { onlyLettersSpecialVowelsWhiteSpacesValidator },
                  })}
                  defaultValue={claimForm?.client.lastName}
                  error={errors.client?.lastName}
                  disabled={!isNewClaim}
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="clientMonthBirth">Date of Birth</Label>
                <FormInline tw="flex">
                  <div tw="w-32 mr-3">
                    <Controller
                      name="client.monthBirth"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          id="clientMonthBirth"
                          options={months}
                          placeholder="–"
                          error={errors.client?.monthBirth}
                          isDisabled={!isNewClaim}
                        />
                      )}
                      defaultValue={claimForm?.client.monthBirth}
                      rules={{ required: true }}
                    />
                  </div>
                  <div tw="w-20 mr-3">
                    <Controller
                      name="client.dayBirth"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={clientDays}
                          placeholder="–"
                          error={errors.client?.dayBirth}
                          isDisabled={!isNewClaim}
                        />
                      )}
                      defaultValue={claimForm?.client.dayBirth}
                      rules={{ required: true }}
                    />
                  </div>
                  <div tw="w-24">
                    <Controller
                      name="client.yearBirth"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={years}
                          placeholder="–"
                          error={errors.client?.yearBirth}
                          isDisabled={!isNewClaim}
                        />
                      )}
                      defaultValue={claimForm?.client.yearBirth}
                      rules={{ required: true }}
                    />
                  </div>
                </FormInline>
              </FormGroup>
              <FormGroup tw="w-full sm:w-1/2 md:w-1/4">
                <Label htmlFor="clientGender">Gender</Label>
                <div tw="w-52">
                  <Controller
                    name="client.gender"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        id="clientGender"
                        options={genderOptions}
                        placeholder="–"
                        error={errors.client?.gender}
                        isDisabled={!isNewClaim}
                      />
                    )}
                    defaultValue={claimForm?.client.gender}
                    rules={{ required: true }}
                  />
                </div>
              </FormGroup>
              <FormGroup tw="w-full sm:w-1/2 md:w-1/3">
                <Label htmlFor="clientAddress1">Address</Label>
                <Input
                  type="address"
                  tw="w-full"
                  id="clientAddress1"
                  error={errors.client?.address?.address1}
                  {...register('client.address.address1', { required: true })}
                  defaultValue={claimForm?.client.address.address1}
                  disabled={!isNewClaim}
                />
                <Input
                  type="address"
                  tw="w-full mt-2"
                  id="clientAddress2"
                  error={errors.client?.address?.address2}
                  {...register('client.address.address2')}
                  defaultValue={claimForm?.client.address.address2}
                  disabled={!isNewClaim}
                />
              </FormGroup>
              <FormInline tw="md:flex">
                <FormGroup tw="block md:inline-block">
                  <Label htmlFor="clientCity">City</Label>
                  <Input
                    type="text"
                    id="clientCity"
                    tw="block mr-3"
                    {...register('client.address.city', {
                      required: true,
                      validate: { onlyLettersSpecialVowelsWhiteSpacesValidator },
                    })}
                    defaultValue={claimForm?.client.address.city}
                    error={errors.client?.address?.city}
                    disabled={!isNewClaim}
                  />
                </FormGroup>
                <FormGroup tw="block md:inline-block">
                  <Label htmlFor="clientState">State</Label>
                  <div tw="w-40 mr-3">
                    <Controller
                      name="client.address.state"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          id="clientState"
                          options={usStatesOptions}
                          isSearchable
                          placeholder="–"
                          error={errors.client?.address?.state}
                          isDisabled={!isNewClaim}
                        />
                      )}
                      defaultValue={claimForm?.client.address.state}
                      rules={{ required: true }}
                    />
                  </div>
                </FormGroup>
                <FormGroup tw="block md:inline-block">
                  <Label htmlFor="clientZipCode">Zip Code</Label>
                  <Input
                    type="text"
                    id="clientZipCode"
                    tw="block"
                    {...register('client.address.zip_code', {
                      required: true,
                      validate: { validateZipcode },
                    })}
                    defaultValue={claimForm?.client.address.zip_code}
                    error={errors.client?.address?.zip_code}
                    disabled={!isNewClaim}
                  />
                </FormGroup>
              </FormInline>
            </div>
            <div tw="mb-12">
              <div tw="text-xl font-semibold mb-7">Insurance Subscriber Information</div>
              <FormGroup tw="mb-5">
                <div tw="font-semibold mb-2">Is the subscriber also the client?</div>
                <FormInline tw="flex items-baseline">
                  <Input
                    type="checkbox"
                    id="isClientSubscriber"
                    data-testid="isClientSubscriber"
                    name="isClientSubscriber"
                    checked={isClientSubscriber}
                    onChange={e => {
                      setIsClientSubscriber(e.target.checked);
                    }}
                    spacing="tight"
                    disabled={!isNewClaim}
                  />
                  <Label htmlFor="isClientSubscriber" tw="ml-2 font-normal">
                    Yes
                  </Label>
                </FormInline>
              </FormGroup>
              {!isClientSubscriber && (
                <>
                  <FormGroup tw="w-full sm:w-1/2 md:w-1/3">
                    <Label htmlFor="subscriberPrimaryFirstName">First Name</Label>
                    <Input
                      type="text"
                      id="subscriberPrimaryFirstName"
                      tw="w-full"
                      {...register('subscriberPrimary.firstName', {
                        required: true,
                        validate: { onlyLettersSpecialVowelsWhiteSpacesValidator },
                      })}
                      defaultValue={claimForm?.subscriberPrimary.firstName}
                      error={errors.subscriberPrimary?.firstName}
                      disabled={!isNewClaim}
                    />
                  </FormGroup>
                  <FormGroup tw="w-full sm:w-1/2 md:w-1/3">
                    <Label htmlFor="subscriberPrimaryLastName">Last Name</Label>
                    <Input
                      type="text"
                      id="subscriberPrimaryLastName"
                      tw="w-full"
                      {...register('subscriberPrimary.lastName', {
                        required: true,
                        validate: { onlyLettersSpecialVowelsWhiteSpacesValidator },
                      })}
                      defaultValue={claimForm?.subscriberPrimary.lastName}
                      error={errors.subscriberPrimary?.lastName}
                      disabled={!isNewClaim}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="subscriberPrimaryMonthBirth">Date of Birth</Label>
                    <FormInline tw="flex">
                      <div tw="w-32 mr-3">
                        <Controller
                          name="subscriberPrimary.monthBirth"
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              id="subscriberPrimaryMonthBirth"
                              options={months}
                              placeholder="–"
                              error={errors.subscriberPrimary?.monthBirth}
                              isDisabled={!isNewClaim}
                            />
                          )}
                          defaultValue={claimForm?.subscriberPrimary.monthBirth}
                          rules={{ required: true }}
                        />
                      </div>
                      <div tw="w-20 mr-3">
                        <Controller
                          name="subscriberPrimary.dayBirth"
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              options={subscriberDays}
                              placeholder="–"
                              error={errors.subscriberPrimary?.dayBirth}
                              isDisabled={!isNewClaim}
                            />
                          )}
                          defaultValue={claimForm?.subscriberPrimary.dayBirth}
                          rules={{ required: true }}
                        />
                      </div>
                      <div tw="w-24">
                        <Controller
                          name="subscriberPrimary.yearBirth"
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              options={years}
                              placeholder="–"
                              error={errors.subscriberPrimary?.yearBirth}
                              isDisabled={!isNewClaim}
                            />
                          )}
                          defaultValue={claimForm?.subscriberPrimary.yearBirth}
                          rules={{ required: true }}
                        />
                      </div>
                    </FormInline>
                  </FormGroup>
                  <FormGroup tw="w-full sm:w-1/2 md:w-1/4">
                    <Label htmlFor="subscriberPrimaryGender">Gender</Label>
                    <div tw="w-52">
                      <Controller
                        name="subscriberPrimary.gender"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            id="subscriberPrimaryGender"
                            options={genderOptions}
                            placeholder="–"
                            error={errors.subscriberPrimary?.gender}
                            isDisabled={!isNewClaim}
                          />
                        )}
                        defaultValue={claimForm?.subscriberPrimary.gender}
                        rules={{ required: true }}
                      />
                    </div>
                  </FormGroup>
                  <FormGroup tw="w-full sm:w-1/2 md:w-1/3">
                    <Label htmlFor="subscriberPrimaryAddress1">Address</Label>
                    <Input
                      type="address"
                      tw="w-full"
                      id="subscriberPrimaryAddress1"
                      {...register('subscriberPrimary.address.address1', { required: true })}
                      defaultValue={claimForm?.subscriberPrimary.address.address1}
                      error={errors.subscriberPrimary?.address?.address1}
                      disabled={!isNewClaim}
                    />
                    <Input
                      type="address"
                      tw="w-full mt-2"
                      id="subscriberPrimaryAddress2"
                      {...register('subscriberPrimary.address.address2')}
                      defaultValue={claimForm?.subscriberPrimary.address.address2}
                      error={errors.subscriberPrimary?.address?.address2}
                      disabled={!isNewClaim}
                    />
                  </FormGroup>
                  <FormInline tw="md:flex">
                    <FormGroup tw="block md:inline-block">
                      <Label htmlFor="subscriberPrimaryCity">City</Label>
                      <Input
                        type="text"
                        id="subscriberPrimaryCity"
                        tw="block mr-3"
                        {...register('subscriberPrimary.address.city', {
                          required: true,
                          validate: { onlyLettersSpecialVowelsWhiteSpacesValidator },
                        })}
                        defaultValue={claimForm?.subscriberPrimary.address.city}
                        error={errors.subscriberPrimary?.address?.city}
                        disabled={!isNewClaim}
                      />
                    </FormGroup>
                    <FormGroup tw="block md:inline-block">
                      <Label htmlFor="subscriberPrimaryState">State</Label>
                      <div tw="w-40 mr-3">
                        <Controller
                          name="subscriberPrimary.address.state"
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              id="subscriberPrimaryState"
                              options={usStatesOptions}
                              isSearchable
                              placeholder="–"
                              error={errors.subscriberPrimary?.address?.state}
                              isDisabled={!isNewClaim}
                            />
                          )}
                          defaultValue={claimForm?.subscriberPrimary.address.state}
                          rules={{ required: true }}
                        />
                      </div>
                    </FormGroup>
                    <FormGroup tw="block md:inline-block">
                      <Label htmlFor="subscriberPrimaryZipCode">Zip Code</Label>
                      <Input
                        type="text"
                        id="subscriberPrimaryZipCode"
                        tw="block"
                        {...register('subscriberPrimary.address.zip_code', {
                          required: true,
                          validate: { validateZipcode },
                        })}
                        defaultValue={claimForm?.subscriberPrimary.address.zip_code}
                        error={errors.subscriberPrimary?.address?.zip_code}
                        disabled={!isNewClaim}
                      />
                    </FormGroup>
                  </FormInline>
                  <FormGroup tw="w-full sm:w-1/2 md:w-1/3">
                    <Label htmlFor="subscriberPrimaryRelationshipToClient">Relationship to Client</Label>
                    <Controller
                      name="subscriberPrimary.relationshipToClient"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          id="subscriberPrimaryRelationshipToClient"
                          options={relationshipToClientOptions}
                          isSearchable
                          placeholder="–"
                          error={errors.subscriberPrimary?.relationshipToClient}
                          isDisabled={!isNewClaim}
                        />
                      )}
                      defaultValue={claimForm?.subscriberPrimary.relationshipToClient}
                      rules={{ required: true }}
                    />
                  </FormGroup>
                </>
              )}
            </div>
            <div tw="mb-12">
              <div tw="text-xl font-semibold mb-7">Insurance Information</div>
              <FormGroup tw="w-full sm:w-1/2 md:w-1/3">
                <Label htmlFor="insuranceInformationMemberID">Member ID</Label>
                <Input
                  type="text"
                  id="insuranceInformationMemberID"
                  tw="w-full"
                  {...register('insuranceInformation.memberID', { required: true })}
                  defaultValue={claimForm?.insuranceInformation.memberID}
                  error={errors.insuranceInformation?.memberID}
                  disabled={!isNewClaim}
                />
              </FormGroup>
              <FormGroup tw="w-full sm:w-1/2 md:w-1/3">
                <Label htmlFor="insuranceInformationGroupNumber">Group Number</Label>
                <Input
                  type="text"
                  id="insuranceInformationGroupNumber"
                  tw="w-full"
                  {...register('insuranceInformation.groupNumber', { required: true })}
                  defaultValue={claimForm?.insuranceInformation.groupNumber}
                  error={errors.insuranceInformation?.groupNumber}
                  disabled={!isNewClaim}
                />
              </FormGroup>
              <FormGroup tw="w-full sm:w-1/2 md:w-1/3">
                <Label htmlFor="insuranceInformationPlanName">Plan Name</Label>
                <Input
                  type="text"
                  id="insuranceInformationPlanName"
                  tw="w-full"
                  {...register('insuranceInformation.planName', { required: true })}
                  defaultValue={claimForm?.insuranceInformation.planName}
                  error={errors.insuranceInformation?.planName}
                  disabled={!isNewClaim}
                />
              </FormGroup>
              <FormGroup tw="w-full sm:w-1/2 md:w-1/3">
                <Label htmlFor="insuranceInformationPlanType">Plan Type</Label>
                <Controller
                  name="insuranceInformation.planType"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      id="insuranceInformationPlanType"
                      options={planTypeOptions}
                      isSearchable
                      placeholder="–"
                      error={errors.insuranceInformation?.planType}
                      isDisabled={!isNewClaim}
                    />
                  )}
                  defaultValue={claimForm?.insuranceInformation.planType}
                  rules={{ required: true }}
                />
              </FormGroup>
              <FormGroup tw="w-full sm:w-1/2 md:w-1/3">
                <Label htmlFor="insuranceInformationPayerName">Payer Name</Label>
                <Input
                  type="text"
                  id="insuranceInformationPayerName"
                  tw="w-full"
                  {...register('insuranceInformation.payerName', { required: true })}
                  defaultValue={claimForm?.insuranceInformation.payerName}
                  error={errors.insuranceInformation?.payerName}
                  disabled={!isNewClaim}
                />
              </FormGroup>
              <FormGroup tw="w-full sm:w-1/2 md:w-1/3">
                <Label htmlFor="insuranceInformationPayerID">Payer ID</Label>
                <Input
                  type="text"
                  id="insuranceInformationPayerID"
                  tw="w-full"
                  {...register('insuranceInformation.payerID', { required: true })}
                  defaultValue={claimForm?.insuranceInformation.payerID}
                  error={errors.insuranceInformation?.payerID}
                  disabled={!isNewClaim}
                />
              </FormGroup>
            </div>
            <div tw="mb-12">
              <div tw="text-xl font-semibold mb-7">Services</div>
              <FormGroup tw="w-full sm:w-1/2 md:w-1/3">
                <Label htmlFor="diagnosisCode">Diagnosis</Label>
                <Input
                  type="text"
                  id="diagnosisCode"
                  tw="w-full"
                  {...register('diagnoses.0.code', { required: true })}
                  defaultValue={claimForm?.diagnoses[0].code}
                  disabled={!isNewClaim}
                />
              </FormGroup>
              <Card tw="mb-4 px-8 w-2/3">
                <FormGroup tw="w-full">
                  <Label htmlFor="procedureCode">Associated Procedure</Label>
                  <Input
                    type="text"
                    id="procedureCode"
                    tw="w-full"
                    {...register('serviceLines.0.procedureCode', { required: true })}
                    defaultValue={claimForm?.serviceLines[0].procedureCode}
                    disabled={!isNewClaim}
                  />
                </FormGroup>
                <FormGroup tw="w-full">
                  <Label htmlFor="serviceLineModifier">Modifier</Label>
                  <Controller
                    name="serviceLines.0.modifiers.0"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        id="serviceLineModifier"
                        options={modifierOptions}
                        isSearchable
                        isDisabled={!isNewClaim}
                      />
                    )}
                    defaultValue={claimForm?.serviceLines[0].modifiers[0]}
                    rules={{ required: true }}
                  />
                </FormGroup>
                <FormGroup tw="w-1/2">
                  <Label htmlFor="chargeAmountCents">Charge Amount in Dollars</Label>
                  <Input
                    type="text"
                    id="chargeAmountCents"
                    tw="w-full"
                    {...register('serviceLines.0.chargeAmountCents', { required: true })}
                    defaultValue={claimForm?.serviceLines[0].chargeAmountCents}
                    disabled={!isNewClaim}
                  />
                </FormGroup>
              </Card>
            </div>
            <div tw="mb-12">
              <label tw="text-xl font-semibold mb-7" htmlFor="billingNotes">
                Billing Notes
              </label>
              <span tw="ml-3 text-sm text-gray-400">OPTIONAL</span>
              <FormGroup tw="w-full sm:w-2/3">
                <Textarea
                  id="billingNotes"
                  {...register('billingNotes.0.text')}
                  defaultValue={claimForm?.billingNotes[0]?.text}
                  tw="mt-2 w-full"
                  rows={4}
                  disabled={!isNewClaim}
                />
              </FormGroup>
            </div>
            <div tw="mt-3">
              <Button tw="mr-5" variant="primary" type="submit" loading={isLoadingSubmit} disabled={!isNewClaim}>
                Submit Claim
              </Button>
              <Button variant="secondary" onClick={() => history.push('/clients')}>
                Cancel
              </Button>
            </div>
          </form>
        </Card>
      </Container>
    </div>
  );
};

export default ClaimCreate;
