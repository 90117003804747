import { useMutation, useQueryClient } from 'react-query';
import { http } from '@expressable/utils';
import { IClientInformation } from '../types';
import { useToasts } from 'react-toast-notifications';
import { presentMutationError } from '@expressable/ui-library';

export interface ModifyClientPayload {
  clientInformation: IClientInformation;
  clientId: string;
}

const modifyClient = async (modifyClientPayload: ModifyClientPayload) => {
  const { clientId, clientInformation: clientInfo } = modifyClientPayload;
  return http.post(`/clients/${clientId}/client-information`, clientInfo).then(res => res.data);
};

export default function useModifyClient() {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(modifyClient, {
    onSuccess: (_, NewClientInformation) => {
      // Query Invalidations
      addToast('Client Information Successfully Updated', { appearance: 'success', autoDismiss: true });
      queryClient.invalidateQueries(['modify-client', NewClientInformation.clientId]);
      queryClient.invalidateQueries(['client', NewClientInformation.clientId]);
    },
    onError: presentMutationError,
  });
}
