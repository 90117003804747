import { useCurrentUserContext } from '@expressable/utils';

const usePermissions = () => {
  const { userGroups } = useCurrentUserContext() || {};

  return {
    isAdmin: Boolean(userGroups?.includes('admin')),
    isClinicalManager: Boolean(userGroups?.includes('clinical-manager')),
    isAdminOrClinicalManager: Boolean(userGroups?.some(group => ['admin', 'clinical-manager'].includes(group))),
  };
};

export default usePermissions;
