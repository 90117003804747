import { ReferringProviderInformation } from './types';

export enum ReferringProviderTypeEnum {
  individual = 'individual',
  organization = 'organization',
}

export enum ReadonlyInsuranceGenderEnum {
  male = 'M',
  female = 'F',
}

export const REFERRING_PROVIDER_INITIAL_STATE: ReferringProviderInformation = {
  firstName: '',
  lastName: '',
  npi: '',
  taxonomyCode: undefined,
  organizationName: '',
  referringType: ReferringProviderTypeEnum.individual,
};
