/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch, SetStateAction, useCallback } from 'react';
import useConfirmModal from './use-confirm-modal';

export default function useConfirmChangeClearDependents<P>(
  onChange: Dispatch<SetStateAction<P>>,
  dependentValues: any[],
  dependentSetters: Dispatch<SetStateAction<any>>[],
) {
  const onChangeClearDependents: typeof onChange = useCallback(
    value => {
      onChange(value);
      dependentSetters.forEach(setter => setter(null));
    },
    [onChange, ...dependentSetters],
  );

  const { onTrigger, ...confirmModalProps } = useConfirmModal(onChangeClearDependents);

  // "Wrap" the state update to check if the confirm modal needs to be shown
  const onChangeWithConfirmation: typeof onChange = useCallback(
    value => {
      if (dependentValues.some(x => !!x)) {
        onTrigger(value);
      } else {
        onChange(value);
      }
    },
    [onTrigger, ...dependentValues],
  );

  return { confirmModalProps, onChangeWithConfirmation };
}
