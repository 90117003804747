import 'twin.macro';
import { Badge } from './badge-appointment';
import { Link } from 'react-router-dom';
import { FC } from 'react';
import { ClientCasesStatus, TherapistCase, useTherapistCases } from 'hooks/use-client-cases';
import {  groupBy, map } from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCurrentUserContext } from '@expressable/utils';

export type BadgeCase = {
  totalCases: number;
  clientID: string;
  clientName: string;
};

export const getNormalizesCasesCountSelector = (cases: TherapistCase): BadgeCase[] => {
  const { casesByClient } = cases;
  const groupedCases = groupBy(casesByClient, caseItem => caseItem.caseRecord['Cue_Client_ID__c']);

  return map(groupedCases, (clientCases, clientId) => {
    const firstCase = clientCases[0].caseRecord;

    return {
      totalCases: clientCases.length,
      clientID: clientId,
      clientName: firstCase['ClientName'] ?? clientId,
    };
  }).sort((a, b) => b.totalCases - a.totalCases);
};

const BadgeCase: FC<BadgeCase> = props => {
  const { clientID, clientName, totalCases } = props;

  return (
    <div tw="mb-2">
      <Badge count={totalCases} />
      &nbsp;
      <Link tw="text-indigo-700 text-sm font-semibold" to={{ pathname: `/clients/${clientID}#client-cases` }}>
        {clientName ?? 'Client'}
      </Link>
    </div>
  );
};

const OpenCases: FC = () => {
  const { viewClientCases: isClientCasesEnabled } = useFlags();
  const user = useCurrentUserContext();
  const { data: openCases, isLoading } = useTherapistCases({
    email: user?.email,
    status: ClientCasesStatus.Open,
    enabled: isClientCasesEnabled,
    therapistCasesSelector: getNormalizesCasesCountSelector,
  });

  if (!isClientCasesEnabled || (openCases && openCases.length <= 0)) {
    return null;
  }


  return (
    <div className='mb-8' data-testid="open-cases">
      <div tw="mb-2 leading-none font-semibold text-lg">Open Cases</div>
      {openCases && openCases?.length > 0 &&
        openCases.map((openCase: BadgeCase) => <BadgeCase key={openCase.clientID} {...openCase} />)}

        {isLoading && (
          <div className="animate-pulse text-gray-500 italic">
            Loading...
          </div>
        )}
    </div>
  );
};

export default OpenCases;
