import { Input } from '@expressable/ui-library';
import { createGoalStatusName } from 'domain/notes/discharge/goals';
import { GetAdditionalGoalsPayload } from 'hooks/use-care-plan-additional-goals';
import { Goal } from 'hooks/use-care-plan-goals';
import { useFormContext } from 'react-hook-form';
import 'twin.macro';

interface GoalStatusInputProps {
  goal: Goal | GetAdditionalGoalsPayload;
}

export const GoalStatusInput = ({ goal }: GoalStatusInputProps) => {
  const name = createGoalStatusName(goal);
  const form = useFormContext();
  
  return (
    <div tw="flex gap-6">
      <label tw="flex gap-2">
        <Input type="radio" value="discontinued" spacing="tight" {...form.register(name)} />
        Discontinued
      </label>

      <label tw="flex gap-2">
        <Input type="radio" value="partially-met" spacing="tight" {...form.register(name)} />
        Partially Met
      </label>

      <label tw="flex gap-2">
        <Input type="radio" value="met" spacing="tight" {...form.register(name)} />
        Met
      </label>
    </div>
  );
};
