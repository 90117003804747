import { Select } from '@expressable/ui-library';
import { ControlledFormField } from 'components/Forms/FormFieldControlled';
import useBehavioralObservations from 'hooks/use-behavioral-observations';

export default function BehavioralObservations() {
  const { behavioralObservations } = useBehavioralObservations();

  return (
    <div className="flex-col mb-4">
      <h2 className="font-semibold text-xl">Behavioral Observations</h2>
      <hr className="mt-1 mb-6 border-gray-200" />
      <div className="flex flex-col gap-5">
        <ControlledFormField
          containerClass="flex flex-col w-1/3"
          component={Select}
          spacing="tight"
          tw={'min-w-[550px]'}
          name="acknowledgesSpeaker"
          label="Acknowledges Speaker"
          options={behavioralObservations}
        />
        <ControlledFormField
          containerClass="flex flex-col w-1/3"
          component={Select}
          spacing="tight"
          tw={'min-w-[550px]'}
          name="attentionToTasks"
          label="Attention to Tasks"
          options={behavioralObservations}
        />
        <ControlledFormField
          containerClass="flex flex-col w-1/3"
          component={Select}
          spacing="tight"
          tw={'min-w-[550px]'}
          name="topicMaintenance"
          label="Topic Maintenance"
          options={behavioralObservations}
        />
        <ControlledFormField
          containerClass="flex flex-col w-1/3"
          component={Select}
          spacing="tight"
          tw={'min-w-[550px]'}
          name="returnsGreeting"
          label="Returns a Greeting"
          options={behavioralObservations}
        />
        <ControlledFormField
          containerClass="flex flex-col w-1/3"
          component={Select}
          spacing="tight"
          tw={'min-w-[550px]'}
          name="transitionsIntoTreatment"
          label="Transitions Into Treatment"
          options={behavioralObservations}
        />
        <ControlledFormField
          containerClass="flex flex-col w-1/3"
          component={Select}
          spacing="tight"
          tw={'min-w-[550px]'}
          name="transitionsBetweenTasks"
          label="Transitions Between Tasks"
          options={behavioralObservations}
        />
        <ControlledFormField
          containerClass="flex flex-col w-1/3"
          component={Select}
          spacing="tight"
          tw={'min-w-[550px]'}
          name="awarenessOthersEnvironment"
          label="Awareness of Others and Environment"
          options={behavioralObservations}
        />
        <ControlledFormField
          containerClass="flex flex-col w-1/3"
          component={Select}
          spacing="tight"
          tw={'min-w-[550px]'}
          name="cooperation"
          label="Cooperation"
          options={behavioralObservations}
        />
        <ControlledFormField
          containerClass="flex flex-col w-1/3"
          component={Select}
          spacing="tight"
          tw={'min-w-[550px]'}
          name="responseRate"
          label="Response Latency"
          options={behavioralObservations}
        />
      </div>
    </div>
  );
}
