import React, { useState, createContext, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Button,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalFooter,
  FormInline,
  FormGroup,
} from '@expressable/ui-library';
import 'twin.macro';
import { useCreateBillingRecord } from 'hooks/use-billing-information';
import useSendIntakeEmail, { SendIntakeEmailPayload } from 'hooks/use-send-intake-email';
import formatZoomLink from 'utils/zoom-link';
import { getBillingIntakeUrl } from 'utils/billing-intake';

const defaultIntakeContext: {
  intakeData: false | SendIntakeEmailPayload;
  setIntakeData: (data: false | SendIntakeEmailPayload) => void;
} = {
  intakeData: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setIntakeData: (data: false | SendIntakeEmailPayload) => {},
};

export const Context = createContext(defaultIntakeContext);

export function IntakeProvider({ children }: { children: React.ReactNode }) {
  const [intakeDataState, setIntakeDataState] = useState<false | SendIntakeEmailPayload>(false);
  return (
    <Context.Provider
      value={{
        intakeData: intakeDataState,
        setIntakeData: (data: false | SendIntakeEmailPayload) => {
          setIntakeDataState(data);
        },
      }}
    >
      {children}
    </Context.Provider>
  );
}

export function IntakeLinkModal() {
  const { intakeData, setIntakeData } = useContext(Context);
  const sendIntakeEmailMutation = useSendIntakeEmail();
  const { mutate: sendIntakeEmail } = sendIntakeEmailMutation;

  return (
    <Modal isOpen={intakeData !== false}>
      <ModalContent>
        <ModalHeader>
          <div tw="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
            <button
              type="button"
              tw="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={() => setIntakeData(false)}
            >
              <span tw="sr-only">Close</span>
              <svg
                tw="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </ModalHeader>
        <ModalBody>
          <div>
            <div tw="flex items-center justify-center w-12 h-12 mx-auto bg-indigo-100 rounded-full">
              {/* <!-- Heroicon name: check --> */}
              <FontAwesomeIcon tw="text-2xl text-indigo-700" icon="file-alt" />
            </div>
            <div tw="my-3 sm:mt-5">
              <h3 tw="text-lg text-center font-medium text-gray-900 leading-6" id="modal-headline">
                Send Intake Email
              </h3>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div tw="mt-5 sm:mt-6">
            <span tw="flex w-full rounded-md shadow-sm">
              <Button
                data-testid="intake-email-submit"
                type="submit"
                variant="primary"
                loading={sendIntakeEmailMutation.isLoading}
                onClick={() => {
                  if (intakeData) {
                    const variables = { ...intakeData };
                    const options = {
                      onSuccess: () => {
                        setIntakeData(false);
                      },
                    };

                    sendIntakeEmail(variables, options);
                  }
                }}
                tw="inline-flex justify-center w-full px-4 py-2 text-base font-medium leading-6 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
              >
                Send Intake Email to Primary Contact
              </Button>
            </span>
            <span tw="flex w-full rounded-md shadow-sm mt-5">
              {!sendIntakeEmailMutation.isLoading && <Button
                data-testid="intake-email-cancel"
                type="submit"
                variant="secondary"
                loading={sendIntakeEmailMutation.isLoading}
                onClick={() => {
                  setIntakeData(false);
                }}
                tw="inline-flex justify-center w-full px-4 py-2 text-base font-medium leading-6 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
              >
                Exit Without Sending
              </Button>}
            </span>
          </div>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export interface IntakeShareModalProps {
  clientId: string;
  isOpen: boolean;
  onClose: () => void;
  therapistName: string;
}

export function IntakeShareModal(props: IntakeShareModalProps) {
  const { isOpen, onClose, clientId } = props;
  const [intake, setIntake] = useState('child');
  const createBillingRecordMutation = useCreateBillingRecord();
  const { mutate: createBillingRecord } = createBillingRecordMutation;
  const { setIntakeData } = useContext(Context);
  const billingIntakeUrl = getBillingIntakeUrl();

  return (
    <Modal isOpen={isOpen}>
      <ModalContent>
        <form
          onSubmit={e => {
            e.preventDefault();
            createBillingRecord(
              {
                id: clientId,
                stripeAccount: 'expressable.io',
                intake,
              },
              {
                onSuccess: () => {
                  onClose();
                  setIntakeData({
                    clientId,
                    intakeLink: `${billingIntakeUrl}/${clientId}/ehr/${intake}`,
                    zoomLink: formatZoomLink(props.therapistName),
                    intake: intake,
                  });
                },
              },
            );
          }}
        >
          <ModalHeader>
            <div tw="absolute top-0 right-0 pt-4 pr-4">
              <button
                type="button"
                tw="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={onClose}
              >
                <span tw="sr-only">Close</span>
                <svg
                  tw="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
          </ModalHeader>
          <ModalBody>
            <div>
              <div tw="flex items-center justify-center w-12 h-12 mx-auto bg-indigo-100 rounded-full">
                {/* <!-- Heroicon name: check --> */}
                <FontAwesomeIcon tw="text-2xl text-indigo-700" icon="file-alt" />
              </div>
              <div tw="my-3 sm:mt-5">
                <h3 tw="text-lg text-center font-medium text-gray-900 leading-6" id="modal-headline">
                  Share Intake Document
                </h3>
              </div>
              <FormGroup tw="mb-4">
                <div tw="font-semibold mb-2">Intake Form</div>
                <FormInline tw="items-baseline">
                  <Input
                    id="child"
                    type="radio"
                    name="intake"
                    spacing="tight"
                    value="child"
                    checked={intake == 'child'}
                    onChange={e => {
                      setIntake(e.target.value);
                    }}
                  />
                  <Label htmlFor="child" tw="ml-2">
                    Child
                  </Label>
                </FormInline>
                <FormInline tw="items-baseline">
                  <Input
                    id="adult"
                    type="radio"
                    name="intake"
                    spacing="tight"
                    value="adult"
                    checked={intake == 'adult'}
                    onChange={e => {
                      setIntake(e.target.value);
                    }}
                  />
                  <Label htmlFor="adult" tw="ml-2">
                    Adult
                  </Label>
                </FormInline>
              </FormGroup>
            </div>
          </ModalBody>
          <ModalFooter>
            <div tw="mt-5 sm:mt-6">
              <span tw="flex w-full rounded-md shadow-sm">
                <Button
                  type="submit"
                  data-testid="intake-billing-submit"
                  variant="primary"
                  loading={createBillingRecordMutation.isLoading}
                  disabled={createBillingRecordMutation.isLoading}
                  tw="inline-flex justify-center w-full px-4 py-2 text-base font-medium leading-6 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                >
                  Save
                </Button>
              </span>
            </div>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
