import { http } from '@expressable/utils';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ClientContactsPayload } from './use-client-contacts';
import { presentMutationError } from '@expressable/ui-library';
import { useToasts } from 'react-toast-notifications';

const getClientCommunicationPreferences = async (clientID: string) => {
  const { data } = await http.get<ClientContactsPayload[]>(`/clients/${clientID}/communication-preferences`);
  return data;
};

export function useGetClientCommunicationPreferences(clientID: string) {
  return useQuery(['client-communication-preferences', clientID], () => getClientCommunicationPreferences(clientID));
}

export interface SaveClientCommunicationPreferencesPayload {
  communicationPreferences: {
    contactID: string;
    enableEmailNotifications: boolean;
    enableEmailApptReminderNotifications: boolean;
    enableSMSApptReminderNotifications: boolean;
    enableSmsNotifications: boolean;
  };
  clientID: string;
}

const saveCommunicationPreferences = async (
  saveClientCommunicationPreferences: SaveClientCommunicationPreferencesPayload,
) => {
  const { communicationPreferences, clientID } = saveClientCommunicationPreferences;
  if (communicationPreferences) {
    const {
      contactID,
      enableEmailNotifications,
      enableEmailApptReminderNotifications,
      enableSMSApptReminderNotifications,
      enableSmsNotifications,
    } = communicationPreferences;
    return http
      .post(`/clients/${clientID}/communication-preferences`, {
        communicationPreferences: [
          {
            contactID,
            enableEmailNotifications,
            enableEmailApptReminderNotifications,
            enableSMSApptReminderNotifications,
            enableSmsNotifications,
          },
        ],
      })
      .then(res => res.data);
  } else {
    return false;
  }
};

export function useSaveCommunicationPreferences(options: { notifications?: boolean } = {}) {
  const { notifications = true } = options;
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(saveCommunicationPreferences, {
    onSuccess: _ => {
      if (notifications) {
        addToast('Communication Preferences Successfully Updated', { appearance: 'success', autoDismiss: true });
        queryClient.invalidateQueries(['client-communication-preferences']);
      }
    },
    onError: notifications ? presentMutationError : undefined,
  });
}
