import React from 'react';
import { Card, Input, Label, Select, Button, FormInline, FormGroup } from '@expressable/ui-library';
import 'twin.macro';
import { Controller, useForm } from 'react-hook-form';
import { useCreateClient } from 'hooks/use-client';
import useTherapists from 'hooks/use-therapists';
import { months, getDays, getYears, getLastDayDate } from '@expressable/utils';
import { Contact, Therapist } from 'types';
import {
  onlyLettersSpecialVowelsWhiteSpacesValidator,
  alphabeticAndParenthesesValidator,
} from '../../../utils/helpers';
import dayjs from 'dayjs';
import { isAdult } from 'pages/new-client-create/ClientInformation/form';

interface CreateClientContactFormInputs {
  contactID: string;
  clientFirstName: string;
  clientMiddleName: string;
  clientLastName: string;
  suffix: string;
  preferredFirstName: string;
  monthBirth: { [value: string]: string };
  dayBirth: { [value: string]: string };
  yearBirth: { [value: string]: string };
  genderIdentity: string;
  contactFirstName: string;
  contactMiddleName: string;
  contactLastName: string;
  contactTimeZone: string;
  relationshipToClient: { [key: string]: string };
  sex: { [value: string]: string };
  isClientMinor: boolean;
  phoneKey: string;
  phone: string;
  email: string;
  street: string;
  line2: string;
  postalCode: string;
  state: string;
  city: string;
  therapist: {
    label: string;
    value: string;
  };
}

const relationshipsOptions = [
  { label: 'Parent', value: 'Parent' },
  { label: 'Dependent', value: 'Dependent' },
  { label: 'Family Member', value: 'Family Member' },
  { label: 'Legal Guardian', value: 'Legal Guardian' },
  { label: 'Physician', value: 'Physician' },
  { label: 'Spouse', value: 'Spouse' },
  { label: 'Other', value: 'Other' },
];

const therapistsToSelectOptions = (therapist: Therapist) => ({
  label: therapist.therapistName,
  value: therapist.therapistEmail,
});

interface AddClientContactProps {
  contactData: Contact;
  toogleClientContactForm: () => void;
}

export default function addClientContact(props: AddClientContactProps) {
  const { contactData, toogleClientContactForm } = props;
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    getValues,
    watch,
  } = useForm<CreateClientContactFormInputs>();
  const createClientMutation = useCreateClient();
  const { mutate: createClient } = createClientMutation;
  const { data: therapistsData } = useTherapists();
  const therapistsSelectOptions = therapistsData && therapistsData.map(therapistsToSelectOptions);
  const selectedYear = parseInt(getValues('yearBirth')?.value);
  const selectedMonth = parseInt(getValues('monthBirth')?.value);

  const dayOfBirth = watch('dayBirth')?.value;
  const monthOfBirth = watch('monthBirth')?.value;
  const yearOfBirth = watch('yearBirth')?.value;

  const dateOfBirthString = monthOfBirth?.concat('-', dayOfBirth)?.concat('-', yearOfBirth);
  const dateOfBirth = dayjs(dateOfBirthString);

  const endDay = getLastDayDate(selectedYear, selectedMonth);

  const days = getDays(endDay);
  const years = getYears();

  const phoneKey = Object.keys(contactData.phone ?? {}).reduce((_, current) => current, '');

  return (
    <Card tw="mb-4 px-8">
      <div tw="text-2xl font-semibold mb-7 mt-4">Add New Client</div>
      <form
        onSubmit={handleSubmit(data => {
          const dob =
            data?.yearBirth?.value && data?.monthBirth?.value && data?.dayBirth?.value
              ? `${data.yearBirth.value}-${data.monthBirth.value}-${data.dayBirth.value}`
              : undefined;

          const createdClientPayload = {
            payload: {
              client: {
                clientFirstName: data.clientFirstName,
                clientMiddleName: data.clientMiddleName,
                clientLastName: data.clientLastName,
                suffix: data?.suffix == '' ? undefined : data?.suffix,
                preferredFirstName: data?.preferredFirstName == '' ? undefined : data?.preferredFirstName,
                dob: dob,
                sex: data?.sex?.value,
                genderIdentity: data?.genderIdentity == '' ? undefined : data?.genderIdentity,
                therapist: {
                  therapistEmail: data.therapist.value,
                  therapistName: data.therapist.label,
                },
                isClientMinor: !isAdult(dateOfBirth),
              },
              primaryContact: {
                contactID: data.contactID,
                contactFirstName: data.contactFirstName,
                contactMiddleName: data.contactMiddleName,
                contactLastName: data.contactLastName,
                contactTimeZone: data.contactTimeZone,
                relationshipToClient: data.relationshipToClient.label,
                address: {
                  street: data.street,
                  line2: data.line2,
                  postalCode: data.postalCode,
                  state: data.state,
                  city: data.city,
                },
                email: data.email,
                phone: {
                  [data.phoneKey]: data.phone,
                },
              },
            },
          };
          createClient(createdClientPayload, {
            onSuccess: () => {
              {
                toogleClientContactForm();
              }
            },
          });
        })}
      >
        <FormInline tw="flex-wrap">
          <FormGroup>
            <Label htmlFor="clientFirstName">First Name</Label>
            <Input
              type="text"
              id="clientFirstName"
              tw="mr-3"
              {...register('clientFirstName', {
                required: 'first name is required',
                validate: { onlyLettersSpecialVowelsWhiteSpacesValidator },
              })}
              error={errors.clientFirstName}
              autoFocus
            />
          </FormGroup>
          <FormGroup>
            <Label optional htmlFor="clientMiddleName">
              Middle Name
            </Label>
            <Input
              type="text"
              id="clientMiddleName"
              tw="mr-3"
              {...register('clientMiddleName', { validate: { onlyLettersSpecialVowelsWhiteSpacesValidator } })}
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="clientLastName">Last Name</Label>
            <Input
              type="text"
              id="clientLastName"
              {...register('clientLastName', {
                required: 'last name is required',
                validate: { onlyLettersSpecialVowelsWhiteSpacesValidator },
              })}
              error={errors.clientLastName}
              tw="mr-3"
            />
          </FormGroup>
          <FormGroup>
            <Label optional htmlFor="suffix">
              Suffix
            </Label>
            <Input
              type="text"
              id="suffix"
              tw="mr-3"
              {...register('suffix', { validate: { onlyLettersSpecialVowelsWhiteSpacesValidator } })}
            />
          </FormGroup>
        </FormInline>
        <FormGroup>
          <Label optional htmlFor="preferredFirstName">
            Preferred First Name
          </Label>
          <Input
            type="text"
            id="preferredFirstName"
            {...register('preferredFirstName', { validate: { onlyLettersSpecialVowelsWhiteSpacesValidator } })}
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="dateOfBirth">Date of Birth</Label>
          <FormInline tw="flex">
            <div tw="w-32 mr-3">
              <Controller
                name="monthBirth"
                control={control}
                render={({ field }) => <Select {...field} options={months} placeholder="–" error={errors.monthBirth} />}
                rules={{ required: true }}
              />
            </div>
            <div tw="w-20 mr-3">
              <Controller
                name="dayBirth"
                control={control}
                render={({ field }) => <Select {...field} options={days} placeholder="–" error={errors.dayBirth} />}
                rules={{ required: true }}
              />
            </div>
            <div tw="w-24">
              <Controller
                name="yearBirth"
                control={control}
                render={({ field }) => <Select {...field} options={years} placeholder="–" error={errors.yearBirth} />}
                rules={{ required: true }}
              />
            </div>
          </FormInline>
        </FormGroup>
        <FormGroup>
          <Label htmlFor="sex" optional>
            Sex
          </Label>
          <div tw="w-52">
            <Controller
              name="sex"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  options={[
                    { value: 'M', label: 'Male' },
                    { value: 'F', label: 'Female' },
                  ]}
                  placeholder="–"
                />
              )}
            />
          </div>
        </FormGroup>
        <FormGroup tw="mb-12">
          <Label optional htmlFor="genderIdentity">
            Gender Identity
          </Label>
          <Input
            type="text"
            id="genderIdentity"
            {...register('genderIdentity', { validate: { alphabeticAndParenthesesValidator } })}
          />
          <p tw="text-gray-300 -mt-4 text-sm">Add client&apos;s gender identity, pronouns, etc.</p>
        </FormGroup>
        <FormGroup>
          <Label htmlFor="relationshipToClient">Relationship to Client</Label>
          <div tw="w-40">
            <Controller
              name="relationshipToClient"
              control={control}
              render={({ field }) => <Select {...field} options={relationshipsOptions} isSearchable placeholder="–" />}
              defaultValue={relationshipsOptions[0]}
            />
          </div>
        </FormGroup>
        <div tw="text-2xl font-semibold mb-7">Care Team</div>
        <div tw="max-w-xs mb-3">
          <FormGroup tw="mb-12">
            <Label htmlFor="therapist">Primary Therapist</Label>
            {therapistsData && (
              <Controller
                name="therapist"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={therapistsSelectOptions}
                    isSearchable
                    placeholder="–"
                    error={errors.therapist}
                  />
                )}
                rules={{ required: true }}
              />
            )}
          </FormGroup>
        </div>
        <input type="hidden" {...register('contactFirstName')} value={contactData.contactFirstName} />
        <input type="hidden" {...register('contactLastName')} value={contactData.contactLastName} />
        <input type="hidden" {...register('contactMiddleName')} value={contactData.contactMiddleName} />
        <input type="hidden" {...register('phoneKey')} value={phoneKey} />
        <input type="hidden" {...register('phone')} value={contactData.phone[phoneKey]} />
        <input type="hidden" {...register('contactTimeZone')} value={contactData.contactTimeZone} />
        <input type="hidden" {...register('city')} value={contactData.address?.city} />
        <input type="hidden" {...register('state')} value={contactData.address?.state} />
        <input type="hidden" {...register('street')} value={contactData.address?.street} />
        <input type="hidden" {...register('line2')} value={contactData.address?.line2} />
        <input type="hidden" {...register('postalCode')} value={contactData.address?.postalCode} />
        <input type="hidden" {...register('email')} value={contactData.email} />
        <input type="hidden" {...register('contactID')} value={contactData.contactID} />
        <div tw="mt-3">
          <Button
            type="submit"
            variant="primary"
            tw="mr-5"
            loading={createClientMutation.isLoading}
            disabled={createClientMutation.isLoading}
          >
            Save
          </Button>
          <Button variant="secondary" onClick={() => toogleClientContactForm()}>
            Cancel
          </Button>
        </div>
      </form>
    </Card>
  );
}
