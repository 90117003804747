import { IBillingInfoInsuranceForm, SelectOption } from 'types';
import * as yup from 'yup';

export type BillingInformationFormData = {
  skipped: boolean;
  billingLinkType?: SelectOption;
  billingPriceTier?: SelectOption;
  insuranceContent?: IBillingInfoInsuranceForm;
};

const REQUIRED_FIELD_FEEDBACK_TEXT = 'This field is required';

// @TODO(juan): extract this
const selectOptionSchema = yup
  .object()
  .shape({
    value: yup.string().required(),
    label: yup.string().required(),
  })
  .default(undefined);

export const billingInformationSchema: yup.ObjectSchema<BillingInformationFormData> = yup
  .object({
    skipped: yup.boolean().required(REQUIRED_FIELD_FEEDBACK_TEXT),
    billingLinkType: selectOptionSchema.when('skipped', {
      is: false,
      then: (schema) => schema.required(REQUIRED_FIELD_FEEDBACK_TEXT),
      otherwise: (schema) => schema.optional(),
    }),
    billingPriceTier: selectOptionSchema.required(REQUIRED_FIELD_FEEDBACK_TEXT),
  })
  .required();
