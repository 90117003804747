import React, { useMemo } from 'react';
import { Card, Loader } from '@expressable/ui-library';
import BadgeAppointment, { UnlockedNoteBadge } from 'components/appointments/badge-appointment';
import 'twin.macro';
import { useAdminAttendanceVerification } from 'hooks/use-admin-attendance-verification';
import { BadgeAppointmentNote, ClientAttendanceVerification } from 'types';
import { useAdminUnlockedNotes } from 'hooks/use-admin-unlocked-notes';
import useTherapists from 'hooks/use-therapists';

const sortByListCount = (first: BadgeAppointmentNote, second: BadgeAppointmentNote) => second.count - first.count;
const sortByTotalCount = (first: ClientAttendanceVerification, second: ClientAttendanceVerification) =>
  second.totalCount - first.totalCount;

function UnlockedNotes() {
  const {
    sessionNotes: sessionNotesData,
    evaluationNotes: evaluationNotesData,
    dischargeNotes: dischargeNotesData,
    isLoading,
  } = useAdminUnlockedNotes();
  const { data: attendanceVerificationData, isLoading: isLoadingAttendanceVerificationData } =
    useAdminAttendanceVerification();
  const { data: therapists, isLoading: isLoadingTherapists } = useTherapists();

  const therapistToStatus = useMemo(() => {
    return new Map((therapists ?? []).map(therapist => [therapist.therapistName, therapist.status]));
  }, [therapists]);

  const shouldIncludeTherapist = (therapistName: string) =>
    therapistName !== 'Leanne Sherred' && therapistToStatus.get(therapistName) === 'Active';

  const attendances = useMemo(() => {
    return (attendanceVerificationData ?? [])
      .filter(attendance => shouldIncludeTherapist(attendance.therapistName))
      .map(attendance => {
        attendance.list?.sort(sortByListCount);
        return attendance;
      })
      .sort(sortByTotalCount);
  }, [attendanceVerificationData, therapistToStatus]);

  const sessionNotes = useMemo(() => {
    return (sessionNotesData ?? []).filter(note => shouldIncludeTherapist(note.therapistName));
  }, [sessionNotesData, therapistToStatus]);

  const evaluationNotes = useMemo(() => {
    return (evaluationNotesData ?? []).filter(note => shouldIncludeTherapist(note.therapistName));
  }, [evaluationNotesData, therapistToStatus]);

  const dischargeNotes = useMemo(() => {
    return (dischargeNotesData ?? []).filter(note => shouldIncludeTherapist(note.therapistName));
  }, [dischargeNotesData, therapistToStatus]);

  return (
    <div tw="mb-12">
      <div tw="mb-10" data-testid="attendance-verification">
        <div>
          <div tw="mt-8 ml-9">
            <h1 tw="text-xl font-bold">Attendance Verification</h1>
          </div>
        </div>
        <div tw="ml-9 py-8 flex flex-wrap gap-4">
          {Boolean(isLoading || isLoadingAttendanceVerificationData || isLoadingTherapists) ? (
            <div tw="flex justify-center items-center w-full">
              <Loader type="ring" />
            </div>
          ) : attendances?.length ? (
            attendances.map(({ therapistName, totalCount, list }, index) => (
              <Card key={`attendances-${therapistName}-${index}`} tw="relative w-48">
                <div tw="font-semibold mb-3">
                  {therapistName ? `${therapistName} (${totalCount})` : `Therapist (${totalCount})`}
                </div>
                {list?.map(note => (
                  <BadgeAppointment key={note.clientID} appointment={note} />
                ))}
              </Card>
            ))
          ) : (
            <span tw="text-gray-500">No attendances!</span>
          )}
        </div>
      </div>
      <div data-testid="unlocked-evals">
        <div>
          <div tw="mt-8 ml-9">
            <h1 tw="text-xl font-bold">Unlocked Evaluation Notes</h1>
          </div>
          <div tw="ml-9 py-8 flex flex-wrap gap-4">
            {Boolean(isLoading || isLoadingAttendanceVerificationData || isLoadingTherapists) ? (
              <div tw="flex justify-center items-center w-full">
                <Loader type="ring" />
              </div>
            ) : /* Since this is only presentational and we don't have a field with unique values, we can use index here  */
            evaluationNotes && evaluationNotes.length > 0 ? (
              evaluationNotes?.map(({ therapistName, totalCount, list }, index) => (
                <Card key={index} tw="relative w-64">
                  <div tw="font-semibold mb-3">
                    {therapistName ? `${therapistName} (${totalCount})` : `Therapist (${totalCount})`}
                  </div>
                  {list?.map(note => (
                    <>
                      <UnlockedNoteBadge key={`${note.clientID}-${therapistName}`} appointment={note} />
                    </>
                  ))}
                </Card>
              ))
            ) : (
              <span tw="text-gray-500">No unlocked evaluations, good work team!</span>
            )}
          </div>
        </div>
      </div>
      <div data-testid="unlocked-notes">
        <div>
          <div tw="mt-8 ml-9">
            <h1 tw="text-xl font-bold">Unlocked Session Notes</h1>
          </div>
          <div tw="ml-9 py-8 flex flex-wrap gap-4">
            {Boolean(isLoading || isLoadingAttendanceVerificationData || isLoadingTherapists) ? (
              <div tw="flex justify-center items-center w-full">
                <Loader type="ring" />
              </div>
            ) : sessionNotes && sessionNotes.length > 0 ? (
              sessionNotes?.map(({ therapistName, totalCount, list }, index) => (
                <Card key={index} tw="relative w-64">
                  <div tw="font-semibold mb-3">
                    {therapistName ? `${therapistName} (${totalCount})` : `Therapist (${totalCount})`}
                  </div>
                  {list?.map(note => (
                    <>
                      <UnlockedNoteBadge key={`${note.clientID}-${therapistName}`} appointment={note} />
                    </>
                  ))}
                </Card>
              ))
            ) : (
              <span tw="text-gray-500">No unlocked session notes, good work team!</span>
            )}
          </div>
        </div>
      </div>
      <div data-testid="unlocked-discharge">
        <div>
          <div tw="mt-8 ml-9">
            <h1 tw="text-xl font-bold">Unlocked Discharge Notes</h1>
          </div>
          <div tw="ml-9 py-8 flex flex-wrap gap-4">
            {Boolean(isLoading || isLoadingAttendanceVerificationData || isLoadingTherapists) ? (
              <div tw="flex justify-center items-center w-full">
                <Loader type="ring" />
              </div>
            ) : dischargeNotes && dischargeNotes.length > 0 ? (
              dischargeNotes?.map(({ therapistName, totalCount, list }, index) => (
                <Card key={index} tw="relative w-64">
                  <div tw="font-semibold mb-3">
                    {therapistName ? `${therapistName} (${totalCount})` : `Therapist (${totalCount})`}
                  </div>
                  {list?.map(note => (
                    <>
                      <UnlockedNoteBadge key={`${note.clientID}-${therapistName}`} appointment={note} />
                    </>
                  ))}
                </Card>
              ))
            ) : (
              <span tw="text-gray-500">No unlocked discharge notes, good work team!</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UnlockedNotes;
