import { CptCode, SelectOption } from 'types';
import * as yup from 'yup';
import { LANGUAGE_OPTIONS } from 'hooks/common/useTherapistFilters/options';
import { REQUIRED_FIELD_FEEDBACK_TEXT } from 'domain/notes/evaluation/3.0/constants';
import dayjs from 'dayjs';
import { isDate, isUndefined } from 'lodash';
import { selectOptionSchema } from 'pages/new-client-create/ClientInformation';
import { hhmmTimeOptions } from 'utils/time-options';
import { FormYesNoDetails } from 'domain/shared/types';

interface EvaluationBasicData {
  appointmentOnDate: Date;
  appointmentOnHour: SelectOption;
  cpt?: SelectOption<CptCode>[] | null;
  evaluationDiagnosis?: SelectOption<string>[] | null;
}

interface EvaluationOverview {
  adjustedAge?: string;
}

interface EvaluationLanguage {
  clientsPrimaryLanguage?: SelectOption | null;
  additionalLanguageExposures?: SelectOption[];
  sourceOfLanguages?: SelectOption | null;
  evaluationInPrimaryLanguage?: string | null;
  evaluationWithInterpreter?: string | null;
  evaluationLanguageJustification?: string | null;
}

interface EvaluationMedicalHistory {
  referralType: SelectOption | null;
  referredBy: string;
  referralReason: string;
  personsPresent: SelectOption[];
  medicalHistorySource: SelectOption[];
  birthHistory: FormYesNoDetails;
  hospitalizationHistory: FormYesNoDetails;
  allergiesHistory: FormYesNoDetails;
  medicalDiagnosesHistory: FormYesNoDetails;
  medications: FormYesNoDetails;
  hearingHistory: FormYesNoDetails;
  visionHistory: FormYesNoDetails;
  otherHistory: FormYesNoDetails;
  previousSpeechTreatment: FormYesNoDetails;
  developmentalHistory: FormYesNoDetails;
  pain: FormYesNoDetails;
  mentalStatus: FormYesNoDetails;
  functionalLimitations: FormYesNoDetails;
  nursingOtherSupportsType?: SelectOption[] | null;
  nursingOtherSupportsDetails?: string | null;
}

interface SocialHistory {
  educationStatus?: SelectOption | null;
  gradeLevel?: SelectOption | null;
  academicConcerns: FormYesNoDetails;
  livingWith?: SelectOption[] | null;
}

interface BehavioralObservations {
  acknowledgesSpeaker: SelectOption | null;
  attentionToTasks: SelectOption | null;
  topicMaintenance: SelectOption | null;
  returnsGreeting: SelectOption | null;
  transitionsIntoTreatment: SelectOption | null;
  transitionsBetweenTasks: SelectOption | null;
  awarenessOthersEnvironment: SelectOption | null;
  cooperation: SelectOption | null;
  responseRate: SelectOption | null;
}

interface ReceptiveLanguage {
  receptiveLanguageHowWasAssessed: SelectOption[] | null;
  receptiveLanguageAssessmentUsed: SelectOption[] | null;
  receptiveLanguageAssessmentDescription: string | null;
  receptiveLanguageConclusions: string | null;
}

interface ExpressiveLanguage {
  expressiveLanguageHowWasAssessed: SelectOption[] | null;
  expressiveLanguageAssessmentUsed: SelectOption[] | null;
  expressiveLanguageAssessmentDescription: string | null;
  expressiveLanguageConclusions: string | null;
}

interface Fluency {
  fluencyHowWasAssessed: SelectOption[] | null;
  fluencyAssessmentUsed: SelectOption[] | null;
  fluencyAssessmentDescription: string | null;
  fluencyConclusions: string | null;
}

interface VoiceResonance {
  voiceResonanceHowWasAssessed: SelectOption[] | null;
  voiceResonanceAssessmentUsed: SelectOption[] | null;
  voiceResonanceAssessmentDescription: string | null;
  voiceResonanceConclusions: string | null;
}

interface ArticulationPhonology {
  articulationPhonologyHowWasAssessed: SelectOption[] | null;
  articulationPhonologyAssessmentUsed: SelectOption[] | null;
  articulationPhonologyAssessmentDescription: string | null;
  articulationPhonologyConclusions: string | null;
}

interface OralMotorAbilities {
  oralMotorAbilitiesHowWasAssessed: SelectOption[] | null;
  oralMotorAbilitiesAssessmentUsed: SelectOption[] | null;
  oralMotorAbilitiesAssessmentDescription: string | null;
  oralMotorAbilitiesConclusions: string | null;
}

interface FeedingSwallowing {
  feedingSwallowingHowWasAssessed: SelectOption[] | null;
  feedingSwallowingAssessmentUsed: SelectOption[] | null;
  feedingSwallowingAssessmentDescription: string | null;
  feedingSwallowingConclusions: string | null;
}

interface Pragmatics {
  pragmaticsHowWasAssessed: SelectOption[] | null;
  pragmaticsAssessmentUsed: SelectOption[] | null;
  pragmaticsAssessmentDescription: string | null;
  pragmaticsConclusions: string | null;
}

interface Conclusions {
  areFurtherAssessmentsRecommended: FormYesNoDetails;
  areSpeechTherapyServicesRecommended: FormYesNoDetails;
  isTeletherapyAppropriate: SelectOption | null;
  clinicalRecommendations: string | null; // (Recommendation)
  conclusions: string | null;
}

export type EvaluationNoteFormData = EvaluationBasicData &
  EvaluationOverview &
  EvaluationLanguage &
  EvaluationMedicalHistory &
  SocialHistory &
  BehavioralObservations &
  ReceptiveLanguage &
  ExpressiveLanguage &
  Fluency &
  VoiceResonance &
  ArticulationPhonology &
  OralMotorAbilities &
  FeedingSwallowing &
  Pragmatics &
  Conclusions;

export const cptSelectOptionSchema = yup.array().of(
  yup
    .object()
    .shape({
      value: yup.object().shape({
        code: yup.string(),
        description: yup.string(),
      }),
      label: yup.string(),
    })
    .optional(),
);

export const nullableSelectOptionSchema = yup
  .object()
  .shape({
    value: yup.string(),
    label: yup.string(),
  })
  .nullable()
  .optional();

export const yesNoDetailSchema = yup.object().shape({
  relevant: yup.string().default('').nullable(),
  details: yup.string().default('').nullable(),
});

// @ts-ignore
export const evaluationFormContentSchema: yup.ObjectSchema<EvaluationNoteFormData> = yup.object({
  // #region appointment section schema
  appointmentOnDate: yup
    .date()
    .test(v => isDate(v) || isUndefined(v))
    .required(REQUIRED_FIELD_FEEDBACK_TEXT)
    .default(dayjs().toDate()),
  appointmentOnHour: selectOptionSchema.default(hhmmTimeOptions.find(time => time.value === '10:00')),
  cpt: cptSelectOptionSchema.nullable().notRequired(),
  evaluationDiagnosis: yup.array().of(selectOptionSchema).nullable(),
  // #endregion

  // #region Overview section schemad
  adjustedAge: yup.string().default(''),
  // #endregion

  // #region Language section schema
  clientsPrimaryLanguage: nullableSelectOptionSchema,
  additionalLanguageExposures: yup.array().of(selectOptionSchema).nullable(),
  sourceOfLanguages: nullableSelectOptionSchema,
  evaluationInPrimaryLanguage: yup
    .string()
    .nullable()
    .when('clientsPrimaryLanguage', {
      is: (clientsPrimaryLanguage: SelectOption) =>
        clientsPrimaryLanguage?.value && clientsPrimaryLanguage.value !== LANGUAGE_OPTIONS.ENGLISH.label,
      then: schema => schema.notRequired(),
    }),
  evaluationWithInterpreter: yup
    .string()
    .nullable()
    .when('clientsPrimaryLanguage', {
      is: (clientsPrimaryLanguage: SelectOption) =>
        clientsPrimaryLanguage?.value && clientsPrimaryLanguage?.value !== LANGUAGE_OPTIONS.ENGLISH.label,
      then: schema => schema.notRequired(),
    }),
  evaluationLanguageJustification: yup
    .string()
    .nullable()
    .when('evaluationInPrimaryLanguage', {
      is: 'no',
      then: schema => schema.nonNullable().notRequired(),
    }),
  // #endregion

  // #region Medical History section schema
  referralType: nullableSelectOptionSchema,
  referredBy: yup.string().nullable(),
  referralReason: yup.string().nullable(),
  medicalHistorySource: yup.array().of(selectOptionSchema).nullable(),
  personsPresent: yup.array().of(selectOptionSchema).nullable(),

  birthHistory: yesNoDetailSchema.notRequired().nullable(),
  hospitalizationHistory: yesNoDetailSchema.notRequired().nullable(),
  allergiesHistory: yesNoDetailSchema.notRequired().nullable(),
  medicalDiagnosesHistory: yesNoDetailSchema.notRequired().nullable(),
  medications: yesNoDetailSchema.notRequired().nullable(),
  hearingHistory: yesNoDetailSchema.notRequired().nullable(),
  visionHistory: yesNoDetailSchema.notRequired().nullable(),
  otherHistory: yesNoDetailSchema.notRequired().nullable(),
  developmentalHistory: yesNoDetailSchema.notRequired().nullable(),
  pain: yesNoDetailSchema.notRequired().nullable(),
  mentalStatus: yesNoDetailSchema.notRequired().nullable(),
  functionalLimitations: yesNoDetailSchema.notRequired().nullable(),
  previousSpeechTreatment: yesNoDetailSchema.notRequired().nullable(),
  nursingOtherSupportsType: yup.array().of(selectOptionSchema).nullable().default([]),
  nursingOtherSupportsDetails: yup.string().nullable().default(''),
  // #endregion

  // #region Social History
  educationStatus: nullableSelectOptionSchema,
  gradeLevel: nullableSelectOptionSchema,
  academicConcerns: yesNoDetailSchema.notRequired().nullable(),
  livingWith: yup.array().of(selectOptionSchema).nullable().default([]),
  // #endregion

  //# region Behavioral Observations
  acknowledgesSpeaker: nullableSelectOptionSchema,
  attentionToTasks: nullableSelectOptionSchema,
  topicMaintenance: nullableSelectOptionSchema,
  returnsGreeting: nullableSelectOptionSchema,
  transitionsIntoTreatment: nullableSelectOptionSchema,
  transitionsBetweenTasks: nullableSelectOptionSchema,
  awarenessOthersEnvironment: nullableSelectOptionSchema,
  cooperation: nullableSelectOptionSchema,
  responseRate: nullableSelectOptionSchema,
  // #endregion

  //# region receptive language
  receptiveLanguageHowWasAssessed: yup.array().of(nullableSelectOptionSchema).nullable(),
  receptiveLanguageAssessmentDescription: yup.string().nullable(),
  receptiveLanguageAssessmentUsed: yup.array().of(nullableSelectOptionSchema).nullable(),
  receptiveLanguageConclusions: yup.string().nullable(),
  // #endregion

  // #region expressive language
  expressiveLanguageHowWasAssessed: yup.array().of(nullableSelectOptionSchema).nullable(),
  expressiveLanguageAssessmentDescription: yup.string().nullable(),
  expressiveLanguageAssessmentUsed: yup.array().of(nullableSelectOptionSchema).nullable(),
  expressiveLanguageConclusions: yup.string().nullable(),
  // #endregion

  // #region fluency
  fluencyHowWasAssessed: yup.array().of(nullableSelectOptionSchema).nullable(),
  fluencyAssessmentDescription: yup.string().nullable(),
  fluencyAssessmentUsed: yup.array().of(nullableSelectOptionSchema).nullable(),
  fluencyConclusions: yup.string().nullable(),
  // #endregion

  // #region voice/resonance
  voiceResonanceHowWasAssessed: yup.array().of(nullableSelectOptionSchema).nullable(),
  voiceResonanceAssessmentDescription: yup.string().nullable(),
  voiceResonanceAssessmentUsed: yup.array().of(nullableSelectOptionSchema).nullable(),
  voiceResonanceConclusions: yup.string().nullable(),
  // #endregion

  // #region articulation/phonology
  articulationPhonologyHowWasAssessed: yup.array().of(nullableSelectOptionSchema).nullable(),
  articulationPhonologyAssessmentDescription: yup.string().nullable(),
  articulationPhonologyAssessmentUsed: yup.array().of(nullableSelectOptionSchema).nullable(),
  articulationPhonologyConclusions: yup.string().nullable(),
  // #endregion

  // #region oral motor abilities
  oralMotorAbilitiesHowWasAssessed: yup.array().of(nullableSelectOptionSchema).nullable(),
  oralMotorAbilitiesAssessmentDescription: yup.string().nullable(),
  oralMotorAbilitiesAssessmentUsed: yup.array().of(nullableSelectOptionSchema).nullable(),
  oralMotorAbilitiesConclusions: yup.string().nullable(),
  // #endregion

  // #region feeding/swallowing
  feedingSwallowingHowWasAssessed: yup.array().of(nullableSelectOptionSchema).nullable(),
  feedingSwallowingAssessmentDescription: yup.string().nullable(),
  feedingSwallowingAssessmentUsed: yup.array().of(nullableSelectOptionSchema).nullable(),
  feedingSwallowingConclusions: yup.string().nullable(),
  // #endregion

  // #region pragmatics
  pragmaticsHowWasAssessed: yup.array().of(nullableSelectOptionSchema).nullable(),
  pragmaticsAssessmentDescription: yup.string().nullable(),
  pragmaticsAssessmentUsed: yup.array().of(nullableSelectOptionSchema).nullable(),
  pragmaticsConclusions: yup.string().nullable(),
  // #endregion

  // #region conclusion
  areFurtherAssessmentsRecommended: yesNoDetailSchema.notRequired().nullable(),
  areSpeechTherapyServicesRecommended: yesNoDetailSchema.notRequired().nullable(),
  clinicalRecommendations: yup.string().nullable(),
  isTeletherapyAppropriate: nullableSelectOptionSchema,
  conclusions: yup.string().nullable(),
});
