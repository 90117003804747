import React from 'react';
import Table from 'components/Table';
import { Badge, Card, Container, FormGroup, Label, Loader, Select, useDisclosure } from '@expressable/ui-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ClientManageAssignmentsSidebar from './client-manage-assignments-sidebar';
import { UnassignLearningPathModal } from './client-assignments-modals/unassign-learning-path-modal';
import { UnassignHomePracticeActivityModal } from './client-assignments-modals/unassign-home-practice-activity-modal';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { AssignmentStatus, ClientAssignment, ClientManageAssignmentsProps } from 'domain/clinical-pathways/types';
import {
  AssignmentStatusOptions,
  BADGE_VARIANTS,
  getAdlLink,
  getCustomStructuredActivityLink,
  getLearningPathLink,
  getStructuredActivityLink,
} from 'domain/clinical-pathways/contants';
import { useGetClientAssignments } from 'hooks/use-client-assignments';
import dayjs from 'dayjs';
import cx from 'classnames';
import 'twin.macro';

const AssigmentBadge = ({ status }: { status: AssignmentStatus }) => {
  const variant = BADGE_VARIANTS[status];

  if (!variant) return null;

  return (
    <Badge variant={variant.variant} className={`${variant.color}`}>
      {variant.text}
    </Badge>
  );
};

const ClientManageAssignmentsDescription = () => {
  return (
    <div tw="mt-0 sm:mt-4" data-testid="client-manage-assignments-description">
      <p>Track and manage your client&apos;s assignments as well as view submissions.</p>
    </div>
  );
};

export function ClientManageAssignments({ clientId, clientData }: ClientManageAssignmentsProps) {
  const [learningPathStatusSelected, setLearningPathStatusSelected] = React.useState<{ [key: string]: string }>({
    value: '',
    label: 'All Assignments',
  });
  const [homePracticeActivitySelected, setHomePracticeActivitySelected] = React.useState<{ [key: string]: string }>({
    value: '',
    label: 'All Assignments',
  });
  const [currentAssignment, setCurrentAssignment] = React.useState<ClientAssignment>();

  const {
    data: clientLearningPathAssignments,
    isLoading: isLoadingLearningPath,
    isError: isLearningPathError,
  } = useGetClientAssignments({
    clientId: clientId,
    assignmentTypes: ['learning-path'],
    status: learningPathStatusSelected.value ? [learningPathStatusSelected.value as AssignmentStatus] : undefined,
  });

  const {
    data: clientActivityAssignments,
    isLoading: isLoadingActivities,
    isError: isActivityError,
  } = useGetClientAssignments({
    clientId: clientId,
    assignmentTypes: ['activity-of-daily-living', 'structured-activity', 'custom-structured-activity'],
    status: homePracticeActivitySelected.value ? [homePracticeActivitySelected.value as AssignmentStatus] : undefined,
  });

  const {
    isOpen: RemoveLearningPathModalIsOpen,
    onClose: RemoveLearningPathOnClose,
    onOpen: RemoveLearningPathModalOnOpen,
  } = useDisclosure();

  const {
    isOpen: RemoveHomePracticeActivityModalIsOpen,
    onClose: RemoveHomePracticeActivityOnClose,
    onOpen: RemoveHomePracticeActivityModalOnOpen,
  } = useDisclosure();

  if (isLearningPathError || isActivityError) {
    return (
      <Container data-testid="client-assignments-error" tw="relative pl-0" size="large">
        <div data-testid="client-manage-assignments" tw="flex justify-between items-center flex-wrap">
          <h2 tw="text-2xl font-semibold mb-3">Manage Assignments</h2>
        </div>
        <ClientManageAssignmentsDescription />
        <Card tw="mb-4 px-8 mt-8">
          <FormGroup tw="w-full sm:w-1/2 md:w-1/3">
            <h2 tw="text-lg mt-2 mb-6 font-semibold ml-2">Client Learning Paths</h2>
            <Label>
              <FontAwesomeIcon icon={faExclamationTriangle} tw="text-red-500 mr-2" />
              <span tw="text-red-500">Assignment information is not available at this time.</span>
            </Label>
          </FormGroup>
        </Card>
      </Container>
    );
  }

  return (
    <div tw="md:flex">
      <div tw="md:w-3/4 md:pr-5" data-testid="client-manage-assignments">
        <div tw="flex justify-between items-center flex-wrap">
          <h2 tw="text-2xl font-semibold mb-3">Manage Assignments</h2>
        </div>
        <ClientManageAssignmentsDescription />

        <Card tw="mt-8">
          {isLoadingLearningPath ? (
            <div data-testid="learning-path-loading" tw="flex-1 text-center py-20">
              <Loader type="ring" />
            </div>
          ) : (
            <>
              <div tw="mb-4 sm:mb-0">
                <div tw="flex flex-col sm:flex-row">
                  <h2 tw="text-lg mt-0 sm:mt-5 mb-0 sm:mb-9 font-semibold ml-2">Client Learning Paths</h2>
                  <>
                    <span tw="text-sm font-semibold mt-6 ml-6 hidden sm:block">View</span>
                    <div tw="flex items-center sm:items-stretch justify-items-stretch mr-2 ml-2 mt-4 font-normal">
                      <span tw="text-sm font-semibold mr-2 block sm:hidden">View</span>
                      <Select
                        tw="w-full sm:w-48"
                        id="learningPathStatus"
                        placeholder="All Assignments"
                        spacing="tight"
                        value={learningPathStatusSelected}
                        options={AssignmentStatusOptions}
                        onChange={(selectOption: { [key: string]: string }) => {
                          setLearningPathStatusSelected(selectOption);
                        }}
                      />
                    </div>
                    <span
                      onClick={() => {
                        const defaultOption = { value: '', label: 'All Assignments' };
                        setLearningPathStatusSelected(defaultOption);
                      }}
                      className={cx('text-sm ml-3 mt-6 text-indigo-700 font-normal hidden sm:block', {
                        'opacity-100': learningPathStatusSelected.value !== '',
                        'opacity-50': learningPathStatusSelected.value === '',
                        'cursor-pointer': learningPathStatusSelected.value !== '',
                      })}
                    >
                      View All
                    </span>
                  </>
                </div>
              </div>
              {clientLearningPathAssignments?.items.length ? (
                <>
                  <Table>
                    <Table.Header>
                      <Table.Row>
                        <Table.Cell tw="w-40 sm:w-auto xl:w-[375px]">Therapy Strategy</Table.Cell>
                        <Table.Cell tw="hidden sm:table-cell">Date Assigned</Table.Cell>
                        <Table.Cell tw="hidden sm:table-cell">Assigned By</Table.Cell>
                        <Table.Cell tw="w-40 sm:w-auto">Completion Status</Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {clientLearningPathAssignments?.items.map((assignment: ClientAssignment) => (
                        <Table.Row key={assignment.assignmentID}>
                          <Table.Cell tw="text-sm md:text-base">
                            <a
                              tw="text-indigo-700 font-medium"
                              href={getLearningPathLink(assignment.learningPathContentfulEntryID!)}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {assignment.assignmentTitle}{' '}
                            </a>
                            {`(${assignment.learningPathContentfulTreatmentArea || 'MISSING CATEGORY'})`}
                          </Table.Cell>
                          <Table.Cell tw="text-sm md:text-base hidden sm:table-cell">
                            {dayjs(assignment.createdAt).format('MM/DD/YYYY')}
                          </Table.Cell>
                          <Table.Cell tw="text-sm md:text-base hidden sm:table-cell">
                            {assignment.therapistName}
                          </Table.Cell>
                          {assignment.status === 'incomplete' && (
                            <>
                              <Table.Cell>
                                <AssigmentBadge status={'incomplete'} />
                              </Table.Cell>
                              <Table.Cell
                                tw="text-indigo-700 cursor-pointer font-medium text-xs md:text-sm"
                                onClick={() => {
                                  RemoveLearningPathModalOnOpen();
                                  setCurrentAssignment(assignment);
                                }}
                              >
                                <span tw="flex justify-end">Unassign</span>
                              </Table.Cell>
                            </>
                          )}
                          {assignment.status === 'complete' && (
                            <>
                              <Table.Cell tw="flex flex-col md:flex-row">
                                <div>
                                  <AssigmentBadge status={'complete'} />
                                </div>
                                <div>
                                  <span tw="ml-0 md:ml-2 text-gray-500 font-normal text-xs md:text-sm hidden sm:inline">
                                    {dayjs(assignment.updatedAt).format('MM/DD/YYYY')}
                                  </span>
                                </div>
                              </Table.Cell>
                            </>
                          )}
                          {assignment.status === 'unassigned' && (
                            <>
                              <Table.Cell tw="flex flex-col md:flex-row">
                                <div>
                                  <AssigmentBadge status={'unassigned'} />
                                </div>
                                <div>
                                  <span tw="ml-0 md:ml-2 text-gray-500 font-normal text-xs md:text-sm hidden sm:inline">
                                    {dayjs(assignment.updatedAt).format('MM/DD/YYYY')}
                                  </span>
                                </div>
                              </Table.Cell>
                            </>
                          )}
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                  <div tw="font-medium flex mt-5 justify-center sm:justify-end">
                    <span tw="mr-2">{`Showing ${clientLearningPathAssignments.items.length} result${
                      clientLearningPathAssignments.items.length > 1 ? 's' : ''
                    }`}</span>
                  </div>
                </>
              ) : (
                <div>
                  {learningPathStatusSelected.value === '' ? (
                    <span tw="ml-2">
                      Your client has not been assigned any Learning Paths. Make assignments in your Session Note
                      documentation.
                    </span>
                  ) : (
                    <span tw="ml-2">No assignments match these filters.</span>
                  )}
                </div>
              )}
            </>
          )}
        </Card>

        <Card tw="mt-8">
          {isLoadingActivities ? (
            <div data-testid="activities-loading" tw="flex-1 text-center py-20">
              <Loader type="ring" />
            </div>
          ) : (
            <>
              <div tw="mb-4 sm:mb-0">
                <div tw="flex flex-col sm:flex-row">
                  <h2 tw="text-lg mt-0 sm:mt-5 mb-0 sm:mb-9 font-semibold ml-2">Client Home Practice Activities</h2>
                  <>
                    <span tw="text-sm font-semibold mt-6 ml-6 hidden sm:block">View</span>
                    <div tw="flex items-center sm:items-stretch justify-items-stretch mr-2 ml-2 mt-4 font-normal">
                      <span tw="text-sm font-semibold mr-2 block sm:hidden">View</span>
                      <Select
                        tw="w-full sm:w-48"
                        id="homePracticeStatus"
                        placeholder="All Assignments"
                        spacing="tight"
                        value={homePracticeActivitySelected}
                        options={AssignmentStatusOptions}
                        onChange={(selectOption: { [key: string]: string }) => {
                          setHomePracticeActivitySelected(selectOption);
                        }}
                      />
                    </div>
                    <span
                      onClick={() => {
                        const defaultOption = { value: '', label: 'All Assignments' };
                        setHomePracticeActivitySelected(defaultOption);
                      }}
                      className={cx('text-sm ml-3 mt-6 text-indigo-700 font-normal hidden sm:block', {
                        'opacity-100': homePracticeActivitySelected.value !== '',
                        'opacity-50': homePracticeActivitySelected.value === '',
                        'cursor-pointer': homePracticeActivitySelected.value !== '',
                      })}
                    >
                      View All
                    </span>
                  </>
                </div>
              </div>

              {clientActivityAssignments?.items.length ? (
                <>
                  <Table>
                    <Table.Header>
                      <Table.Row>
                        <Table.Cell tw="w-40 sm:w-auto xl:w-[375px]">Practice Activity</Table.Cell>
                        <Table.Cell tw="hidden sm:table-cell">Date Assigned</Table.Cell>
                        <Table.Cell tw="hidden sm:table-cell">Assigned By</Table.Cell>
                        <Table.Cell tw="w-40 sm:w-auto">Completion Status</Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {clientActivityAssignments?.items.map((assignment: ClientAssignment) => {
                        const isCustomSA = assignment.assignmentType === 'custom-structured-activity';
                        const isActivityOfDailyLiving = assignment.assignmentType === 'activity-of-daily-living';
                        const previewLink = isCustomSA
                          ? getCustomStructuredActivityLink(assignment.customStructuredActivityContentfulEntryID!)
                          : isActivityOfDailyLiving
                          ? getAdlLink(
                              assignment.learningPathContentfulEntryID!,
                              assignment.activityOfDailyLivingContentfulEntryID!,
                            )
                          : getStructuredActivityLink(assignment.structuredActivityContentfulEntryID!);

                        return (
                          <Table.Row key={assignment.assignmentID}>
                            <Table.Cell tw="text-sm md:text-base">
                              <a tw="text-indigo-700 font-medium" href={previewLink} target="_blank" rel="noreferrer">
                                {assignment.assignmentTitle}{' '}
                              </a>
                            </Table.Cell>
                            <Table.Cell tw="text-sm md:text-base hidden sm:table-cell">
                              {dayjs(assignment.createdAt).format('MM/DD/YYYY')}
                            </Table.Cell>
                            <Table.Cell tw="text-sm md:text-base hidden sm:table-cell">
                              {assignment.therapistName}
                            </Table.Cell>
                            {assignment.status === 'incomplete' && (
                              <>
                                <Table.Cell>
                                  <AssigmentBadge status={'incomplete'} />
                                </Table.Cell>
                                <Table.Cell
                                  tw="text-indigo-700 cursor-pointer font-medium text-xs md:text-sm"
                                  onClick={() => {
                                    RemoveHomePracticeActivityModalOnOpen();
                                    setCurrentAssignment(assignment);
                                  }}
                                >
                                  <span tw="flex justify-end">Unassign</span>
                                </Table.Cell>
                              </>
                            )}
                            {assignment.status === 'complete' && (
                              <>
                                <Table.Cell tw="flex flex-col md:flex-row">
                                  <div>
                                    <AssigmentBadge status={'complete'} />
                                  </div>
                                  <div>
                                    <span tw="ml-0 md:ml-2 text-gray-500 font-normal text-xs md:text-sm hidden sm:inline">
                                      {dayjs(assignment.updatedAt).format('MM/DD/YYYY')}
                                    </span>
                                  </div>
                                </Table.Cell>
                              </>
                            )}
                            {assignment.status === 'unassigned' && (
                              <>
                                <Table.Cell tw="flex flex-col md:flex-row">
                                  <div>
                                    <AssigmentBadge status={'unassigned'} />
                                  </div>
                                  <div>
                                    <span tw="ml-0 md:ml-2 text-gray-500 font-normal text-xs md:text-sm hidden sm:inline">
                                      {dayjs(assignment.updatedAt).format('MM/DD/YYYY')}
                                    </span>
                                  </div>
                                </Table.Cell>
                              </>
                            )}
                          </Table.Row>
                        );
                      })}
                    </Table.Body>
                  </Table>
                  <div tw="font-medium flex mt-5 justify-center sm:justify-end">
                    <span tw="mr-2">{`Showing ${clientActivityAssignments.items.length} result${
                      clientActivityAssignments.items.length > 1 ? 's' : ''
                    }`}</span>
                  </div>
                </>
              ) : (
                <div>
                  {homePracticeActivitySelected.value === '' ? (
                    <span tw="ml-2">
                      Your client has not been assigned any Home Practice Activities. Make assignments in your Session
                      Note documentation.
                    </span>
                  ) : (
                    <span tw="ml-2">No assignments match these filters.</span>
                  )}
                </div>
              )}
            </>
          )}
        </Card>
      </div>

      <ClientManageAssignmentsSidebar clientData={clientData} clientId={clientId} />

      {currentAssignment && (
        <UnassignLearningPathModal
          isOpen={RemoveLearningPathModalIsOpen}
          onClose={() => {
            RemoveLearningPathOnClose();
          }}
          clientId={clientId}
          currentAssignment={currentAssignment}
        />
      )}

      {currentAssignment && (
        <UnassignHomePracticeActivityModal
          isOpen={RemoveHomePracticeActivityModalIsOpen}
          onClose={() => {
            RemoveHomePracticeActivityOnClose();
          }}
          clientId={clientId}
          currentAssignment={currentAssignment}
        />
      )}
    </div>
  );
}
