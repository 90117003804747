import React, { useState } from 'react';
import { Card, Label, Button } from '@expressable/ui-library';
import tw from 'twin.macro';
import useTherapists from 'hooks/use-therapists';
import useClientsTeam, { CareTeamPayload } from 'hooks/use-clients-team';
import { useClient } from 'hooks/use-client';
import { Select } from '@expressable/ui-library';
import { Therapist } from 'types';

export interface ClientTeamFormProps {
  clientId: string;
}
const FormGroup = tw.div`mb-0`;

const therapistsToSelectOptions = (therapist: Therapist) => ({
  label: therapist.therapistName,
  value: therapist.therapistEmail,
});

export default function ClientTeamForm({ clientId }: ClientTeamFormProps) {
  const updateCareTeamMutation = useClientsTeam();
  const { mutate: updateCareTeam } = updateCareTeamMutation;

  const { data: clientData } = useClient(clientId);
  const clientTeam = clientData?.careTeam;

  const emptyCareTeam = {
    primary: clientTeam?.primary,
  };

  const [careTeam, setCareTeam] = useState(emptyCareTeam);
  const { data } = useTherapists();
  const selectOptions = data && data.map(therapistsToSelectOptions);
  const primaryCareTeam = clientTeam?.primary;
  return (
    <>
      <Card tw="mb-4 px-8">
        <div data-testid="client-team-form" tw="text-2xl font-semibold mb-6">
          Care Team
        </div>
        <form
          onSubmit={e => {
            e.preventDefault();
            const payload: CareTeamPayload = { clientId, careTeam };
            updateCareTeam(payload);
          }}
        >
          <FormGroup>
            <Label htmlFor="first-name">Primary Therapist</Label>
            {data && (
              <div data-testid="therapist">
                <Select
                  tw="max-w-xs my-2 mb-6"
                  id="therapist"
                  required
                  isSearchable
                  defaultValue={primaryCareTeam && therapistsToSelectOptions(primaryCareTeam as Therapist)}
                  options={selectOptions}
                  onChange={(selectOption: { [key: string]: string }) => {
                    setCareTeam({
                      ...careTeam,
                      primary: {
                        therapistName: selectOption.label,
                        therapistEmail: selectOption.value,
                      },
                    });
                  }}
                />
              </div>
            )}
          </FormGroup>
          <Button variant="primary" loading={updateCareTeamMutation.isLoading} type="submit" data-testid="team-save">
            Save
          </Button>
        </form>
      </Card>
    </>
  );
}
