import { useTherapistAvailableTimesV2 } from 'components/therapistMatcher/data';
import { RECURRING_FOREVER } from 'domain/recurring-appointments/constants';
import { useDisplayTimezone } from 'hooks/common/useDisplayTimezone';
import moment from 'moment';
import { useEffect, useState } from 'react';
import 'twin.macro';
import { IConflict } from 'utils/appointments/conflicts';
import { AvailabilityType } from './common/useTherapistSchedule';

export interface UseConflictsProps {
  calendarID?: string;
  dateTime?: string;
  duration?: number;
  type: AvailabilityType;
  recurringTimes: number | typeof RECURRING_FOREVER;
  isAvailableTimes: boolean;
}

const useConflicts = ({
  calendarID = '',
  dateTime = '',
  duration = 0,
  type,
  recurringTimes,
  isAvailableTimes,
}: UseConflictsProps) => {
  const { displayTimezone } = useDisplayTimezone();
  const [conflicts, setConflicts] = useState<IConflict[]>([]);

  const appointmentRepeats = recurringTimes === RECURRING_FOREVER || recurringTimes > 1;

  const { data: availableTimesV2, isLoading } = useTherapistAvailableTimesV2({
    calendarID,
    date: dateTime ? moment(dateTime).format('YYYY-MM-DD') : '',
    ...(appointmentRepeats && {
      reccuringNo: recurringTimes !== RECURRING_FOREVER ? Number(recurringTimes) : 10,
    }),
    duration,
    type,
  });

  useEffect(() => {
    if (isLoading || !dateTime) {
      setConflicts([]);
      return;
    }

    const isDateInAvailableTimes = (item: any) => {
      const itemTime = moment(item.time).tz(displayTimezone.value).toISOString();
      return itemTime === dateTime;
    };

    const generateRecurringDates = () => {
      const numberOfWeeks = recurringTimes !== RECURRING_FOREVER ? Number(recurringTimes) + 1 : 10;
      return Array.from({ length: numberOfWeeks }, (_, i) => {
        const dateMoment = moment(dateTime).add(i * 7, 'days');
        return dateMoment.toISOString();
      });
    };

    const checkFutureConflicts = (conflicts: IConflict[]) => {
      if (!availableTimesV2?.future) return;

      const recurringDates = generateRecurringDates();
      const recurringConflicts = new Map<string, IConflict>();

      conflicts.forEach(conflict => {
        recurringConflicts.set(conflict?.datetime, conflict);
      });

      availableTimesV2.future.forEach((report: any, reportIndex: number) => {
        const recurringDate = recurringDates[reportIndex];
        const dateMoment = moment(recurringDate);
        const isInWeek = dateMoment.isBetween(moment(report?.start), moment(report?.end), null, '[)');

        if (isInWeek) {
          const isAvailable = report.availability?.some((availableDate: string) => {
            const dateToCompare = moment(availableDate);
            return dateToCompare.toISOString() === recurringDate;
          });

          const isConflict = report.conflicts || (!isAvailableTimes && !isAvailable);

          if (isConflict && !recurringConflicts.has(recurringDate)) {
            recurringConflicts.set(recurringDate, {
              datetime: recurringDate,
              type: 'futureConflict',
            });
          }
        }
      });

      const sortedConflicts = Array.from(recurringConflicts.values()).sort(
        (a, b) => new Date(a.datetime).getTime() - new Date(b.datetime).getTime(),
      );

      setConflicts(sortedConflicts);
    };

    const handleOverride = () => {
      const isAvailable = availableTimesV2?.items?.some(isDateInAvailableTimes);
      const conflictKey = dateTime;
      const conflictMap = new Map<string, IConflict>();

      if (!isAvailable) {
        conflictMap.set(conflictKey, {
          datetime: dateTime,
          type: availableTimesV2 ? 'appointmentExists' : 'futureConflict',
        });
      }

      setConflicts(Array.from(conflictMap.values()));

      if (appointmentRepeats) {
        checkFutureConflicts(Array.from(conflictMap.values()));
      }
    };

    const handleNonOverride = () => {
      const isAvailable =
        availableTimesV2?.items?.length === 0 || availableTimesV2?.items?.some(isDateInAvailableTimes);
      const conflictKey = dateTime;
      const conflictMap = new Map<string, IConflict>();

      if (!isAvailable) {
        conflictMap.set(conflictKey, {
          datetime: dateTime,
          type: availableTimesV2 ? 'appointmentExists' : 'futureConflict',
        });
      }

      const selectedItem = availableTimesV2?.items?.find(isDateInAvailableTimes);
      selectedItem?.conflicts?.forEach((conflict: { datetime: string; type: string }) => {
        conflictMap.set(conflict?.datetime, {
          datetime: conflict?.datetime,
          type: availableTimesV2 ? conflict?.type : 'futureConflict',
        });
      });

      setConflicts(Array.from(conflictMap.values()));

      if (appointmentRepeats) {
        checkFutureConflicts(Array.from(conflictMap.values()));
      }
    };

    if (!availableTimesV2) handleOverride();
    else handleNonOverride();
  }, [isLoading, dateTime, availableTimesV2, displayTimezone, appointmentRepeats, recurringTimes]);

  return {
    isLoading,
    conflicts,
  };
};

export default useConflicts;
