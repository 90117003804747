import { ModalHeader } from '@expressable/ui-library';
import React from 'react';
import 'twin.macro';

type ModalHeadlineProps = {
  text: string;
};

export default function ModalHeadline({ text }: ModalHeadlineProps) {
  return (
    <ModalHeader tw="text-lg text-center font-medium text-gray-900 leading-6 mb-3" id="modal-headline">
      {text}
    </ModalHeader>
  );
}
