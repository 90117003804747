/* eslint-disable react/prop-types */
import { months, years } from '@expressable/utils';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { SelectOption } from 'types';
import Select from 'react-select';

import cx from 'classnames';

import 'twin.macro';

type Props = {
  onChange?: (value?: Dayjs) => void;
  value?: Dayjs | null;
  error?: { message?: string };
};

export const DOBInput: React.FC<Props> = ({ onChange, value, error }) => {
  const [day, setDay] = useState<SelectOption<number> | null>(null);
  const [month, setMonth] = useState<SelectOption | null>(null);
  const [year, setYear] = useState<SelectOption | null>(null);

  const dob = useMemo(() => {
    if (!day || !month || !year) {
      return undefined;
    } else {
      return dayjs(`${year.value}-${month.value}-${day.value}`);
    }
  }, [day, month, year]);

  useEffect(() => {
    onChange?.(dob);
  }, [dob]);

  useEffect(() => {
    if (value?.isValid() && !dob?.isSame(value)) {
      setYear(years.find(d => parseInt(d.value) === value.year()) ?? null);
      setMonth(months.find(d => parseInt(d.value) === value.month() + 1) ?? null);
      setDay({
        label: '' + value.date(),
        value: value.date(),
      });
    }
  }, [value]);

  const daysOptions = useMemo(() => {
    // note(juan): some years have different amount of days in february but in order to simplify the UX we always use 2022 which contains 29 days
    const daysInMonth = dayjs(`2020-${month?.value}-01`).daysInMonth();
    const options: SelectOption<number>[] = [];

    for (let i = 0; i < daysInMonth; i++) {
      options.push({
        label: `${i + 1}`,
        value: i + 1,
      });
    }

    return options;
  }, [month]);

  useEffect(() => {
    // Newly selected month might have less days than what was previously selected, clear the value
    if (day && day.value > daysOptions.length) {
      setDay(null);
      onChange?.(undefined);
    }
  }, [daysOptions]);

  return (
    <div className="flex flex-col">
      <div className="flex gap-2">
        <Select
          tw="flex-grow-[4] min-w-[128px]"
          className={cx({
            'border border-red-500 rounded': error && !month,
          })}
          onChange={setMonth}
          value={month}
          options={months}
          placeholder="Month"
        />
        <Select
          tw="flex-grow-[2] min-w-[96px]"
          className={cx({
            'border border-red-500 rounded': error && !day,
          })}
          onChange={setDay}
          value={day}
          options={daysOptions}
          placeholder="Day"
        />
        <Select
          tw={'flex-grow-[3] min-w-[96px]'}
          className={cx({
            'border border-red-500 rounded': error && !year,
          })}
          onChange={setYear}
          value={year}
          options={years}
          placeholder="Year"
        />
      </div>

      {error && <p className="text-red-500 text-xs italic">{error.message}</p>}
    </div>
  );
};
