import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { dynamicHttp } from '@expressable/utils';
import { presentMutationError, useToast } from '@expressable/ui-library';

const httpHealthRecordProxy = dynamicHttp('health-record-proxy');

const getAdaptiveEquipment = async (clientId: string) => {
  const { data } = await httpHealthRecordProxy.get(`/care-plans/equipment-devices/${clientId}`);
  return data;
};

export function useGetAdaptiveEquipment(
  { clientId }: { clientId: string },
  options?: UseQueryOptions<string[], unknown, string[]>,
) {
  return useQuery<string[]>(['equipment-devices', clientId], () => getAdaptiveEquipment(clientId), options);
}

export interface SetEquipmentDevicesPayload {
  clientId: string;
  equipmentDevices: string[];
  shouldLogEvent: boolean;
}

const setAdaptiveEquipment = async (payload: SetEquipmentDevicesPayload) => {
  const { clientId, ...rest } = payload;
  return httpHealthRecordProxy.post(`/care-plans/equipment-devices/${clientId}`, rest).then(res => res.data);
};

export function useSetAdaptiveEquipment() {
  const queryClient = useQueryClient();
  const { successToast } = useToast();

  return useMutation(setAdaptiveEquipment, {
    onSuccess: (_, payload) => {
      successToast('Equipment Devices successfully updated.');
      queryClient.invalidateQueries(['equipment-devices', payload.clientId]);
    },
    onError: presentMutationError,
  });
}
