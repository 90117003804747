import { useInfiniteQuery } from 'react-query';
import { http } from '@expressable/utils';
import { Event } from 'types';

type ActivityResponse = {
  items: Event[];
  lastEvaluatedKey: {
    pk: string;
    sk: string;
  } | null;
};

const getClientActivity = async (id: string, pageParam?: { pk: string; sk: string }): Promise<ActivityResponse> => {
  const { data } = await http.get<Record<string, any>>(`/clients/${id}/activity`, {
    params: {
      startKeyPK: pageParam?.pk ?? null,
      startKeySK: pageParam?.sk ?? null,
    },
  });

  return {
    ...data,
    items: [...data.items],
  } as ActivityResponse;
};

export default function useClientActivity(id: string) {
  return useInfiniteQuery(['client-activity', id], ({ pageParam }) => getClientActivity(id, pageParam), {
    getNextPageParam: lastPage => lastPage.lastEvaluatedKey,
  });
}
