/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import React, { useMemo, useState } from 'react';
import {
  Card,
  Link,
  Spacer,
  useDisclosure,
  Badge,
  BadgeProps,
  FormInline,
  Label,
  Select,
  FormGroup,
  Button,
  Input,
  Textarea,
} from '@expressable/ui-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetDiagnoses, useRemoveDiagnosis } from 'hooks/use-care-plan-diagnoses';
import { useGetMedicalDiagnoses, useRemoveMedicalDiagnosis } from 'hooks/use-care-plan-medical-diagnoses';
import { useGetAreasOfFunction, useSetAreasOfFunction } from 'hooks/use-care-plan-areas-of-function';
import { useGetApplicableConditions, useSetApplicableConditions } from 'hooks/use-care-plan-applicable-conditions';
import { useGetSkilledInterventions, useSetSkilledInterventions } from 'hooks/use-care-plan-skilled-interventions';
import { useGetVisitFrequency, VisitFrequencyPayload } from 'hooks/use-care-plan-visit-frequency';
import { useGetGoals, Goal } from 'hooks/use-care-plan-goals';
import tw from 'twin.macro';
import { ClientDiagnosesModal } from './client-diagnoses-modal';
import { ClientGenerateAuthorizationModal } from './client-generate-authorization-message';
import { ClientMedicalDiagnosesModal } from './client-medical-diagnoses-modal';
import { ClientAreasOfFunctionModal } from './client-areas-of-function-modal';
import { ClientApplicableConditionsModal } from './client-applicable-conditions-modal';
import { ClientSkilledInterventionsModal } from './client-skilled-interventions-modal';
import { ClientGoalsModal } from './client-goals-modal';
import { ClientVisitFrequencyModal } from './client-visit-frequency-modal';
import { getDiagnosisCodeDisplayName } from 'utils/diagnoses';
import { FILTER_BY_GOAL_SELECT_OPTIONS, SelectOption } from 'types';
import { filterGoalsByStatus } from 'utils/helpers';
import dayjs from 'dayjs';
import { PlanOfCareDurationVisitsModal } from './plan-of-care-duration-visits-modal';
import { PlanOfCareEndDateModal } from './plan-of-care-end-date-modal';
import { PlanOfCareStartDateModal } from './plan-of-care-start-date-modal';
import { PlanOfCareDischargePlanModal } from './plan-of-care-discharge-plan-modal';
import { useGetDischargePlan } from 'hooks/use-care-plan-discharge-plan';
import { useGetPlanOfCareStartDate } from 'hooks/use-care-plan-start-date';
import { useGetPlanOfCareEndDate } from 'hooks/use-care-plan-end-date';
import { EvaluationEntriesIds } from 'hooks/use-evaluation-contentful-provider';
import { EntryId, useContentfulEntry } from 'hooks/use-contentful';
import { useGetPlanOfCareVisitDuration } from 'hooks/use-care-plan-duration-visits';
import { useGetPrimaryPurpose } from 'hooks/use-plan-of-care-therapist-purpose';
import { PlanOfCarePrimaryPurposeModal } from './plan-of-care-primary-purpose-modal';
import { DevelopmentalDelaysModal } from './plan-of-care-developmental-delays-modal';
import { GetAdditionalGoalsPayload, useGetAdditionalGoal } from 'hooks/use-care-plan-additional-goals';
import { useGetDevelopmentalDelays } from 'hooks/use-care-plan-developmental-delays';
import AdditionalGoalsModal from './plan-of-care-additional-goals-modal';
import { useGetAdaptiveEquipment, useSetAdaptiveEquipment } from 'hooks/use-care-plan-adaptive-equipment';
import { AdaptiveEquipmentModal } from './client-adaptive-equipment-modal';
import { useGetPlanOfCareTotalVisits } from 'hooks/use-care-plan-total-visits';
import { PlanOfCareTotalVisitsModal } from './plan-of-care-total-visits-modal ';
import { getGoalDetails } from 'utils/care-plans';
import { Controller, useForm, useWatch } from 'react-hook-form';
import {
  useCreateDischargeQualityReview,
  useCreateEvaluationQualityReview,
  useGetDischargeQualityReview,
  useGetEvaluationQualityReview,
} from 'hooks/use-care-plan-quality-reviews';
import { useToasts } from 'react-toast-notifications';
import { useFlags } from 'launchdarkly-react-client-sdk';

// eslint-disable-next-line
type ReactRef = React.RefObject<any>;

interface DiagnosesProps {
  clientId: string;
  validation?: {
    error?: { message?: string };
    ref?: ReactRef;
  };
  shouldLogEvent: boolean;
  error?: string;
}

export function Diagnoses(props: DiagnosesProps) {
  const { clientId, validation, error, shouldLogEvent } = props;
  const { data: diagnoses } = useGetDiagnoses(
    { clientId },
    {
      onSuccess: () => {
        if (diagnosisToRemove !== null) {
          setDiagnosisToRemove(null);
        }
      },
    },
  );
  const {
    isOpen: ClientDiagnosesModalIsOpen,
    onClose: ClientDiagnosesModalOnClose,
    onOpen: ClientDiagnosesModalOnOpen,
  } = useDisclosure();

  const { mutate: removeDiagnosis, isLoading } = useRemoveDiagnosis();
  const [diagnosisToRemove, setDiagnosisToRemove] = useState<string | null>(null);

  const onRemoveDiagnosisClick = (diagnosisCode: string) => {
    if (diagnosisToRemove === null) {
      setDiagnosisToRemove(diagnosisCode);
      removeDiagnosis({ clientId, diagnosisCode, shouldLogEvent });
    }
  };

  //This will add the isPrimaryDiagnosis key to the first diagnosis from the array
  const newDiagnosesArray = diagnoses?.map(diagnosis => ({ ...diagnosis }));
  newDiagnosesArray?.slice(0, 1).forEach(object => {
    object.isPrimaryDiagnosis = true;
  });

  return (
    <div tw="mb-8">
      <div tw="mb-5">
        <div>
          <span tw="text-lg mt-4 mb-9 font-semibold">Diagnoses</span>
          <Link to="#" tw="text-indigo-700 ml-5" data-testid="diagnosis-add" onClick={ClientDiagnosesModalOnOpen}>
            <FontAwesomeIcon tw="text-sm mr-2" icon="plus-circle" />
            Add diagnosis
          </Link>
        </div>
        <div tabIndex={0} ref={validation?.ref} tw="text-red-500 text-xs italic">
          {validation?.error?.message}
        </div>
        {error && (
          <div tabIndex={0} tw="text-red-500 text-xs italic">
            {error}
          </div>
        )}
      </div>

      {!newDiagnosesArray || newDiagnosesArray.length == 0 ? (
        <div tw="text-sm text-gray-400 mb-4" data-testid="client-plan-of-care-form-no-diagnoses">
          No diagnoses have been added
        </div>
      ) : (
        newDiagnosesArray.map(({ code, description, isPrimaryDiagnosis }) => (
          <div tw="mb-4" key={code}>
            <Link
              to="#"
              tw="text-indigo-700 ml-5 float-right"
              onClick={() => onRemoveDiagnosisClick(code)}
              loading={code === diagnosisToRemove && isLoading}
            >
              <FontAwesomeIcon tw="text-xl mr-2" icon={['far', 'trash']} />
            </Link>
            <div>
              {description}&nbsp;
              {isPrimaryDiagnosis && (
                <Badge tw="text-xs font-medium" variant={'success'}>
                  PRIMARY DIAGNOSIS
                </Badge>
              )}
            </div>

            <div tw="text-gray-500">{getDiagnosisCodeDisplayName(code)}</div>
          </div>
        ))
      )}

      {ClientDiagnosesModalIsOpen && (
        <ClientDiagnosesModal
          isOpen={ClientDiagnosesModalIsOpen}
          onClose={ClientDiagnosesModalOnClose}
          clientId={clientId}
          diagnoses={diagnoses ?? []}
          shouldLogEvent={shouldLogEvent}
        />
      )}
    </div>
  );
}

interface MedicalDiagnosesProps {
  clientId: string;
  error?: string;
  shouldLogEvent: boolean;
}

export function MedicalDiagnoses(props: MedicalDiagnosesProps) {
  const { clientId, error, shouldLogEvent } = props;
  const [medicalDiagnosisToRemove, setMedicalDiagnosisToRemove] = useState<string | null>(null);

  const { data: medicalDiagnoses = [] } = useGetMedicalDiagnoses(
    { clientId },
    {
      onSuccess: () => {
        if (medicalDiagnosisToRemove) {
          setMedicalDiagnosisToRemove(null);
        }
      },
    },
  );

  const {
    isOpen: ClientMedicalDiagnosesModalIsOpen,
    onClose: ClientMedicalDiagnosesModalOnClose,
    onOpen: ClientMedicalDiagnosesModalOnOpen,
  } = useDisclosure();

  const { mutate: removeMedicalDiagnosis, isLoading } = useRemoveMedicalDiagnosis();

  const onRemoveMedicalDiagnosisClick = (code: string) => {
    if (!medicalDiagnosisToRemove) {
      setMedicalDiagnosisToRemove(code);
      removeMedicalDiagnosis({ clientId, code, shouldLogEvent });
    }
  };

  return (
    <div tw="mb-8">
      <div tw="mb-5">
        <div>
          <span tw="text-lg mt-4 mb-9 font-semibold">Medical Diagnoses</span>
          <span className="ml-1 text-xxs text-gray-400 leading-none">OPTIONAL</span>
          <Link
            to="#"
            tw="text-indigo-700 ml-5"
            data-testid="medical-diagnoses-add"
            onClick={ClientMedicalDiagnosesModalOnOpen}
          >
            <FontAwesomeIcon tw="text-sm mr-2" icon="plus-circle" />
            Add diagnosis
          </Link>
        </div>
        <div tabIndex={0} tw="text-red-500 text-xs italic">
          {error}
        </div>
      </div>

      {!medicalDiagnoses?.length ? (
        <div tw="text-sm text-gray-400 mb-4" data-testid="client-plan-of-care-form-no-medical-diagnoses">
          No medical diagnoses have been added
        </div>
      ) : (
        medicalDiagnoses.map(({ code, description }) => (
          <div tw="mb-4" key={code}>
            <Link
              to="#"
              tw="text-indigo-700 ml-5 float-right"
              onClick={() => onRemoveMedicalDiagnosisClick(code)}
              loading={code === medicalDiagnosisToRemove && isLoading}
            >
              <FontAwesomeIcon tw="text-xl mr-2" icon={['far', 'trash']} />
            </Link>
            <div>{description}</div>
            <div tw="text-gray-500">{getDiagnosisCodeDisplayName(code)}</div>
          </div>
        ))
      )}

      {ClientMedicalDiagnosesModalIsOpen && (
        <ClientMedicalDiagnosesModal
          isOpen={ClientMedicalDiagnosesModalIsOpen}
          onClose={ClientMedicalDiagnosesModalOnClose}
          clientId={clientId}
          medicalDiagnoses={medicalDiagnoses}
          shouldLogEvent={shouldLogEvent}
        />
      )}
    </div>
  );
}

interface AreasOfFunctionProps {
  clientId: string;
  validation?: {
    error?: { message?: string };
    ref?: ReactRef;
  };
  error?: string;
  shouldLogEvent: boolean;
}

export function AreasOfFunction(props: AreasOfFunctionProps) {
  const { clientId, validation, shouldLogEvent, error } = props;
  const [areaOfFunctionToRemove, setAreaOfFunctionToRemove] = useState<string | null>(null);

  const { data: areasOfFunction = [] } = useGetAreasOfFunction(
    { clientId },
    {
      onSuccess: () => {
        if (areaOfFunctionToRemove) {
          setAreaOfFunctionToRemove(null);
        }
      },
    },
  );

  const {
    isOpen: ClientAreasOfFunctionModalIsOpen,
    onClose: ClientAreasOfFunctionModalOnClose,
    onOpen: ClientAreasOfFunctionModalOnOpen,
  } = useDisclosure();

  const { mutate: setAreasOfFunction, isLoading } = useSetAreasOfFunction();

  const onRemoveAreaOfFunctionClick = (areaOfFunction: string) => {
    if (!areaOfFunctionToRemove) {
      setAreaOfFunctionToRemove(areaOfFunction);
      const areasOfFunctionToSet = areasOfFunction.filter(item => item.trim() !== areaOfFunction.trim()) ?? [];
      setAreasOfFunction({ clientId, areasOfFunction: areasOfFunctionToSet, shouldLogEvent });
    }
  };

  return (
    <div tw="mb-8">
      <div tw="mb-5">
        <div>
          <span tw="text-lg mt-4 mb-9 font-semibold">Treatment Focused on Areas of Function</span>
          <Link
            to="#"
            tw="text-indigo-700 ml-5"
            data-testid="areas-of-function-add"
            onClick={ClientAreasOfFunctionModalOnOpen}
          >
            <FontAwesomeIcon tw="text-sm mr-2" icon="plus-circle" />
            Add Area of Function
          </Link>
        </div>
        <div tabIndex={0} ref={validation?.ref} tw="text-red-500 text-xs italic">
          {validation?.error?.message}
        </div>
        {error && (
          <div tabIndex={0} tw="text-red-500 text-xs italic">
            {error}
          </div>
        )}
      </div>

      {!areasOfFunction.length ? (
        <div tw="text-sm text-gray-400 mb-4" data-testid="client-plan-of-care-form-no-areas-of-function">
          No areas of function have been added
        </div>
      ) : (
        areasOfFunction.map(area => (
          <div tw="mb-4" key={area}>
            <Link
              to="#"
              tw="text-indigo-700 ml-5 float-right"
              onClick={() => onRemoveAreaOfFunctionClick(area)}
              loading={area === areaOfFunctionToRemove && isLoading}
            >
              <FontAwesomeIcon tw="text-xl mr-2" icon={['far', 'trash']} />
            </Link>
            <div>{area}</div>
          </div>
        ))
      )}

      {ClientAreasOfFunctionModalIsOpen && (
        <ClientAreasOfFunctionModal
          isOpen={ClientAreasOfFunctionModalIsOpen}
          onClose={ClientAreasOfFunctionModalOnClose}
          clientId={clientId}
          areasOfFunction={areasOfFunction}
          shouldLogEvent={shouldLogEvent}
        />
      )}
    </div>
  );
}

interface ApplicableConditionsProps {
  clientId: string;
  validation?: {
    error?: { message?: string };
    ref?: ReactRef;
  };
  error?: string;
  shouldLogEvent: boolean;
}

export function ApplicableConditions(props: ApplicableConditionsProps) {
  const { clientId, validation, shouldLogEvent, error } = props;
  const [applicableConditionToRemove, setApplicableConditionToRemove] = useState<string | null>(null);

  const { data: applicableConditions = [] } = useGetApplicableConditions(
    { clientId },
    {
      onSuccess: () => {
        if (applicableConditionToRemove) {
          setApplicableConditionToRemove(null);
        }
      },
    },
  );

  const {
    isOpen: ClientApplicableConditionsModalIsOpen,
    onClose: ClientApplicableConditionsModalOnClose,
    onOpen: ClientApplicableConditionsModalOnOpen,
  } = useDisclosure();

  const { mutate: setApplicableConditions, isLoading } = useSetApplicableConditions();

  const onRemoveApplicableConditionsClick = (applicableCondition: string) => {
    if (!applicableConditionToRemove) {
      setApplicableConditionToRemove(applicableCondition);
      const applicableConditionsToSet =
        applicableConditions.filter(item => item.trim() !== applicableCondition.trim()) ?? [];
      setApplicableConditions({ clientId, applicableConditions: applicableConditionsToSet, shouldLogEvent });
    }
  };

  return (
    <div tw="mb-8">
      <div tw="mb-5">
        <div>
          <span tw="text-lg mt-4 mb-9 font-semibold">Barriers to Care</span>
          <Link
            to="#"
            tw="text-indigo-700 ml-5"
            data-testid="applicable-conditions-add"
            onClick={ClientApplicableConditionsModalOnOpen}
          >
            <FontAwesomeIcon tw="text-sm mr-2" icon="plus-circle" />
            Add Barrier
          </Link>
        </div>
        <div tabIndex={0} ref={validation?.ref} tw="text-red-500 text-xs italic">
          {validation?.error?.message}
        </div>
        {error && (
          <div tabIndex={0} tw="text-red-500 text-xs italic">
            {error}
          </div>
        )}
      </div>

      {!applicableConditions.length ? (
        <div tw="text-sm text-gray-400 mb-4" data-testid="client-plan-of-care-form-no-applicable-conditions">
          No barriers have been added
        </div>
      ) : (
        applicableConditions.map(condition => (
          <div tw="mb-4" key={condition}>
            <Link
              to="#"
              tw="text-indigo-700 ml-5 float-right"
              onClick={() => onRemoveApplicableConditionsClick(condition)}
              loading={condition === applicableConditionToRemove && isLoading}
            >
              <FontAwesomeIcon tw="text-xl mr-2" icon={['far', 'trash']} />
            </Link>
            <div>{condition}</div>
          </div>
        ))
      )}

      {ClientApplicableConditionsModalIsOpen && (
        <ClientApplicableConditionsModal
          isOpen={ClientApplicableConditionsModalIsOpen}
          onClose={ClientApplicableConditionsModalOnClose}
          clientId={clientId}
          applicableConditions={applicableConditions}
          shouldLogEvent={shouldLogEvent}
        />
      )}
    </div>
  );
}

interface AdaptiveEquipmentProps {
  clientId: string;
  validation?: {
    error?: { message?: string };
    ref?: ReactRef;
  };
  error?: string;
  shouldLogEvent: boolean;
}

export function AdaptiveEquipment(props: AdaptiveEquipmentProps) {
  const { clientId, validation, shouldLogEvent, error } = props;
  const [adaptiveEquipmentToRemove, setAdaptiveEquipmentToRemove] = useState<string | null>(null);

  const { data: adaptiveEquipment = [] } = useGetAdaptiveEquipment(
    { clientId },
    {
      onSuccess: () => {
        if (adaptiveEquipmentToRemove) {
          setAdaptiveEquipmentToRemove(null);
        }
      },
    },
  );

  const {
    isOpen: isAdaptiveEquipmentModalOpen,
    onClose: adaptiveEquipmentModalOnClose,
    onOpen: adaptiveEquipmentModalOnOpen,
  } = useDisclosure();

  const { mutate: setAdaptiveEquipment, isLoading } = useSetAdaptiveEquipment();

  const onRemoveAdaptiveEquipmentClick = (ad: string) => {
    if (!adaptiveEquipmentToRemove) {
      setAdaptiveEquipmentToRemove(ad);
      const adaptiveEquipmentToSet = adaptiveEquipment.filter(item => item.trim() !== ad.trim()) ?? [];
      setAdaptiveEquipment({ clientId, equipmentDevices: adaptiveEquipmentToSet, shouldLogEvent });
    }
  };

  return (
    <div tw="mb-8">
      <div tw="mb-5">
        <div>
          <span tw="text-lg mt-4 mb-9 font-semibold">Adaptive Equipment Or Assistive Devices</span>
          <Link
            to="#"
            tw="text-indigo-700 ml-5"
            data-testid="applicable-conditions-add"
            onClick={adaptiveEquipmentModalOnOpen}
          >
            <FontAwesomeIcon tw="text-sm mr-2" icon="plus-circle" />
            Add Equipment
          </Link>
        </div>
        <div tabIndex={0} ref={validation?.ref} tw="text-red-500 text-xs italic">
          {validation?.error?.message}
        </div>
        {error && (
          <div tabIndex={0} tw="text-red-500 text-xs italic">
            {error}
          </div>
        )}
      </div>

      {!adaptiveEquipment.length ? (
        <div tw="text-sm text-gray-400 mb-4" data-testid="client-plan-of-care-form-no-applicable-conditions">
          No adaptive equipment or assistive device have been added
        </div>
      ) : (
        adaptiveEquipment.map(condition => (
          <div tw="mb-4" key={condition}>
            <Link
              to="#"
              tw="text-indigo-700 ml-5 float-right"
              onClick={() => onRemoveAdaptiveEquipmentClick(condition)}
              loading={condition === adaptiveEquipmentToRemove && isLoading}
            >
              <FontAwesomeIcon tw="text-xl mr-2" icon={['far', 'trash']} />
            </Link>
            <div>{condition}</div>
          </div>
        ))
      )}

      {isAdaptiveEquipmentModalOpen && (
        <AdaptiveEquipmentModal
          isOpen={isAdaptiveEquipmentModalOpen}
          onClose={adaptiveEquipmentModalOnClose}
          clientId={clientId}
          adaptiveEquipment={adaptiveEquipment}
          shouldLogEvent={shouldLogEvent}
        />
      )}
    </div>
  );
}

interface SkilledInterventionsProps {
  clientId: string;
  validation?: {
    error?: { message?: string };
    ref?: ReactRef;
  };
  error?: string;
  shouldLogEvent: boolean;
}

export function SkilledInterventions(props: SkilledInterventionsProps) {
  const { clientId, validation, shouldLogEvent, error } = props;
  const [skilledInterventionToRemove, setSkilledInterventionToRemove] = useState<string | null>(null);

  const { data: skilledInterventions = [] } = useGetSkilledInterventions(
    { clientId },
    {
      onSuccess: () => {
        if (skilledInterventionToRemove) {
          setSkilledInterventionToRemove(null);
        }
      },
    },
  );

  const {
    isOpen: ClientSkilledInterventionsModalIsOpen,
    onClose: ClientSkilledInterventionsModalOnClose,
    onOpen: ClientSkilledInterventionsModalOnOpen,
  } = useDisclosure();

  const { mutate: setSkilledInterventions, isLoading } = useSetSkilledInterventions();

  const onRemoveSkilledInterventionsClick = (skilledIntervention: string) => {
    if (!skilledInterventionToRemove) {
      setSkilledInterventionToRemove(skilledIntervention);
      const skilledInterventionsToSet =
        skilledInterventions.filter(item => item.trim() !== skilledIntervention.trim()) ?? [];
      setSkilledInterventions({ clientId, skilledInterventions: skilledInterventionsToSet, shouldLogEvent });
    }
  };

  return (
    <div tw="mb-8">
      <div tw="mb-5">
        <div>
          <span tw="text-lg mt-4 mb-9 font-semibold">Skilled Interventions that may be used</span>
          <Link
            to="#"
            tw="text-indigo-700 ml-5"
            data-testid="skilled-interventions-add"
            onClick={ClientSkilledInterventionsModalOnOpen}
          >
            <FontAwesomeIcon tw="text-sm mr-2" icon="plus-circle" />
            Add Skilled Intervention
          </Link>
        </div>
        <div tabIndex={0} ref={validation?.ref} tw="text-red-500 text-xs italic">
          {validation?.error?.message}
        </div>
        {error && (
          <div tabIndex={0} tw="text-red-500 text-xs italic">
            {error}
          </div>
        )}
      </div>

      {!skilledInterventions.length ? (
        <div tw="text-sm text-gray-400 mb-4" data-testid="client-plan-of-care-form-no-skilled-interventions">
          No skilled interventions have been added
        </div>
      ) : (
        skilledInterventions.map(intervention => (
          <div tw="mb-4" key={intervention}>
            <Link
              to="#"
              tw="text-indigo-700 ml-5 float-right"
              onClick={() => onRemoveSkilledInterventionsClick(intervention)}
              loading={intervention === skilledInterventionToRemove && isLoading}
            >
              <FontAwesomeIcon tw="text-xl mr-2" icon={['far', 'trash']} />
            </Link>
            <div>{intervention}</div>
          </div>
        ))
      )}

      {ClientSkilledInterventionsModalIsOpen && (
        <ClientSkilledInterventionsModal
          isOpen={ClientSkilledInterventionsModalIsOpen}
          onClose={ClientSkilledInterventionsModalOnClose}
          clientId={clientId}
          skilledInterventions={skilledInterventions}
          shouldLogEvent={shouldLogEvent}
        />
      )}
    </div>
  );
}

export function fromStatusToBadgeVariant(currentLtgStatus: string): BadgeProps {
  const variationProps: BadgeProps = {
    variant: 'success',
  };
  if (currentLtgStatus == 'progressing') {
    variationProps.variant = 'success';
  } else if (currentLtgStatus == 'met') {
    variationProps.variant = 'waiting';
  } else if (currentLtgStatus == 'modified') {
    variationProps.variant = 'warning';
  } else if (currentLtgStatus == 'discontinued') {
    variationProps.variant = 'disabled';
  }
  return variationProps;
}

interface GoalsProps {
  clientId: string;
  withGoalsFilters: boolean;
  validation?: {
    error?: { message?: string };
    ref?: ReactRef;
  };
  error?: string;
  shouldLogEvent: boolean;
}

export function Goals(props: GoalsProps) {
  const { clientId, validation, error, shouldLogEvent } = props;
  const { data: allGoals } = useGetGoals({ clientId });
  const [currentGoal, setCurrentGoal] = useState<Goal | undefined>();
  const [currentAction, setCurrentAction] = useState<'createLtg' | 'createStg' | 'updateLtg' | 'updateStg'>();
  const [selectedGoalFilter, setSelectedGoalFilter] = useState<SelectOption>({
    label: 'Active Goals',
    value: 'active',
  });

  const {
    isOpen: ClientGoalsModalIsOpen,
    onClose: ClientGoalsModalOnClose,
    onOpen: ClientGoalsModalOnOpen,
  } = useDisclosure();

  const goals = filterGoalsByStatus(allGoals, selectedGoalFilter.value);

  return (
    <div tw="mb-8">
      <div tw="mb-5">
        <div>
          <span tw="text-lg mt-4 mb-9 font-semibold">Goals</span>
          <Link
            to="#"
            tw="text-indigo-700 ml-5"
            data-testid="diagnosis-goals"
            onClick={() => {
              setCurrentGoal(undefined);
              setCurrentAction('createLtg');
              ClientGoalsModalOnOpen();
            }}
          >
            <FontAwesomeIcon tw="text-sm mr-2" icon="plus-circle" />
            Add goal
          </Link>
        </div>
        <div tabIndex={0} ref={validation?.ref} tw="text-red-500 text-xs italic">
          {validation?.error?.message}
        </div>
        {error && (
          <div tabIndex={0} tw="text-red-500 text-xs italic">
            {error}
          </div>
        )}
      </div>

      {props.withGoalsFilters && (
        <div tw="mb-5 ">
          <FormInline tw="w-full">
            <Label tw="mr-2 flex items-center" htmlFor="goal-filter">
              View
            </Label>
            <Select
              tw="min-w-[200px]"
              spacing="tight"
              id="selected-goal-filter"
              value={selectedGoalFilter}
              onChange={setSelectedGoalFilter}
              options={FILTER_BY_GOAL_SELECT_OPTIONS}
            />
          </FormInline>
        </div>
      )}

      {!goals || goals.length === 0 ? (
        <div tw="text-sm text-gray-400 mb-4">No goals have been added</div>
      ) : (
        goals
          .filter(goal => goal.goalType === 'ltg')
          .map(currentLtg => {
            const {
              goalOpportunityUnit: currentLtgOpportunityUnit,
              goalOpportunityUnitDivider: currentLtgOpportunityUnitDivider,
              goalDescription: currentLtgDescription,
            } = getGoalDetails(currentLtg);
            const currentLtgStatus = currentLtg.status;
            const currentLtgStatusVariation = fromStatusToBadgeVariant(currentLtgStatus);

            const currentShortTermGoals = filterGoalsByStatus(
              allGoals?.filter(goal => goal.goalType === 'stg' && currentLtg.ltgID === goal.ltgID),
              selectedGoalFilter.value,
            );

            const ltgDueDate = currentLtg?.dueAt && dayjs(currentLtg?.dueAt).format('MM/DD/YYYY');
            const pastToday = dayjs(currentLtg?.dueAt).isBefore(dayjs().format('MM/DD/YYYY'));
            const status = currentLtg?.status === 'progressing' || currentLtg?.status === 'modified';

            return (
              <div tw="mb-4" key={currentLtg.ltgID}>
                <div>
                  <Link
                    to="#"
                    tw="text-indigo-700 font-medium"
                    onClick={() => {
                      setCurrentGoal(currentLtg);
                      setCurrentAction('updateLtg');
                      ClientGoalsModalOnOpen();
                    }}
                  >
                    <div data-testid="client-plan-of-care-form-current-ltg">{currentLtgDescription}</div>
                  </Link>
                  <div tw="text-gray-400">
                    {ltgDueDate && (
                      <div tw="inline-block" data-testid="sessionNote__LtgGoals__dueDate">
                        Due Date: {''}
                        <span css={[pastToday && status ? tw`text-red-500` : tw`text-gray-400`]}>{ltgDueDate}</span>
                      </div>
                    )}
                    &nbsp; &nbsp;
                    {`Baseline: ${currentLtg.detail.goalBaselinePerformance}${currentLtgOpportunityUnitDivider}${currentLtgOpportunityUnit}`}
                  </div>
                  <Badge tw="text-xs font-medium" {...currentLtgStatusVariation}>
                    {currentLtgStatus.toUpperCase()}
                  </Badge>
                </div>
                <div tw="mt-4">
                  {currentShortTermGoals?.map(currentStg => {
                    const {
                      goalOpportunityUnit: currentStgOpportunityUnit,
                      goalOpportunityUnitDivider: currentStgOpportunityUnitDivider,
                      goalDescription: currentStgDescription,
                    } = getGoalDetails(currentStg);
                    const currentStgStatus = currentStg.status;
                    const currentStgStatusVariation = fromStatusToBadgeVariant(currentStgStatus);
                    const stgDueDate = currentStg?.dueAt && dayjs(currentStg?.dueAt).format('MM/DD/YYYY');
                    const status = currentStg?.status === 'progressing' || currentStg?.status === 'modified';
                    const pastToday = dayjs(currentStg?.dueAt).isBefore(dayjs().format('MM/DD/YYYY'));
                    return (
                      <div tw="mb-4 border-l-4 border-gray-DEFAULT" key={currentStg.stgID}>
                        <Link
                          data-testid="client-plan-of-care-form-current-stg"
                          onClick={() => {
                            setCurrentGoal(currentStg);
                            setCurrentAction('updateStg');
                            ClientGoalsModalOnOpen();
                          }}
                          to="#"
                          tw="font-medium text-base ml-4 block "
                        >
                          {currentStgDescription}
                        </Link>
                        <div tw="ml-4 text-gray-400">
                          {stgDueDate && (
                            <div tw="inline-block" data-testid="sessionNote__LtgGoals__dueDate">
                              Due Date: {''}
                              <span css={[pastToday && status ? tw`text-red-500` : tw`text-gray-400`]}>
                                {stgDueDate}
                              </span>
                            </div>
                          )}
                          &nbsp; &nbsp;
                          {`Baseline: ${currentStg.detail.goalBaselinePerformance}${currentStgOpportunityUnitDivider}${currentStgOpportunityUnit}`}
                        </div>
                        <Badge tw="text-xs ml-4 font-medium" {...currentStgStatusVariation}>
                          {currentStgStatus.toUpperCase()}
                        </Badge>
                      </div>
                    );
                  })}
                </div>
                <Link
                  data-testid="client-plan-of-care-form-add-stg"
                  to="#"
                  onClick={() => {
                    setCurrentGoal(currentLtg);
                    setCurrentAction('createStg');
                    ClientGoalsModalOnOpen();
                  }}
                >
                  <FontAwesomeIcon tw="text-sm mr-2" icon="plus-circle" />
                  Add short term goal
                </Link>
              </div>
            );
          })
      )}

      {ClientGoalsModalIsOpen && (
        <ClientGoalsModal
          isOpen={ClientGoalsModalIsOpen}
          onClose={ClientGoalsModalOnClose}
          clientId={clientId}
          goal={currentGoal}
          action={currentAction}
          setCurrentAction={setCurrentAction}
          shouldLogEvent={shouldLogEvent}
        />
      )}
    </div>
  );
}

export function formatFrequency(frequencyInfo: VisitFrequencyPayload) {
  const frequencyToUnit = {
    weekly: 'week',
    'two-weeks': 'two weeks',
    'four-weeks': 'four weeks',
    monthly: 'month',
    yearly: 'year',
  };

  return `${frequencyInfo?.sessionsCount} session${frequencyInfo?.sessionsCount > 1 ? 's' : ''} every ${
    frequencyToUnit[frequencyInfo?.frequency!]
  }`;
}

interface FrequencyProps {
  clientId: string;
  validation?: {
    error?: { message?: string };
    ref?: ReactRef;
  };
  error?: string;
  shouldLogEvent: boolean;
}

export function Frequency(props: FrequencyProps) {
  const { clientId, shouldLogEvent, validation, error } = props;
  const { data: frequencyInfo } = useGetVisitFrequency({ clientId });
  const {
    isOpen: ClientVisitFrequencyModalIsOpen,
    onClose: ClientVisitFrequencyModalOnClose,
    onOpen: ClientVisitFrequencyModalOnOpen,
  } = useDisclosure();

  return (
    <div tw="mb-8">
      <div tw="mb-5">
        <div>
          <span tw="text-lg mt-4 mb-9 font-semibold">Visit Frequency</span>

          {!frequencyInfo && (
            <Link
              to="#"
              tw="text-indigo-700 ml-5"
              data-testid="visit-frequency-add"
              onClick={ClientVisitFrequencyModalOnOpen}
            >
              <FontAwesomeIcon tw="text-sm mr-2" icon="plus-circle" />
              Add frequency
            </Link>
          )}
        </div>

        <div tabIndex={0} ref={validation?.ref} tw="text-red-500 text-xs italic">
          {validation?.error?.message}
        </div>
        {error && (
          <div tabIndex={0} tw="text-red-500 text-xs italic">
            {error}
          </div>
        )}
      </div>

      {!frequencyInfo || frequencyInfo.frequency === null ? (
        <div tw="text-sm text-gray-400 mb-4">No visit frequency has been added</div>
      ) : (
        <Link
          data-testid="client-plan-of-care-form-current-visit-frequency"
          to="#"
          tw="text-indigo-700 mb-4"
          onClick={ClientVisitFrequencyModalOnOpen}
        >
          {formatFrequency(frequencyInfo)}
        </Link>
      )}

      {ClientVisitFrequencyModalIsOpen && (
        <ClientVisitFrequencyModal
          isOpen={ClientVisitFrequencyModalIsOpen}
          onClose={ClientVisitFrequencyModalOnClose}
          clientId={clientId}
          sessionsCount={frequencyInfo?.sessionsCount ?? 2}
          frequency={frequencyInfo?.frequency ?? 'weekly'}
          shouldLogEvent={shouldLogEvent}
        />
      )}
    </div>
  );
}

export function PlanOfCareDates(props: {
  clientId: string;
  shouldLogEvent: boolean;
  startDateError?: string;
  endDateError?: string;
}) {
  const { clientId, shouldLogEvent, startDateError, endDateError } = props;
  const { isOpen: isStartDateModalOpen, onClose: closeStartDateModal, onOpen: openStartDateModal } = useDisclosure();
  const { isOpen: isEndDateModalOpen, onClose: closeEndDateModal, onOpen: openEndDateModal } = useDisclosure();

  const { data: startDate } = useGetPlanOfCareStartDate({ clientId });
  const { data: endDate } = useGetPlanOfCareEndDate({ clientId });

  const duration = useMemo(() => {
    if (startDate && endDate) {
      const startDateMoment = dayjs(startDate.date).startOf('day');
      const endDateMoment = dayjs(endDate.date).startOf('day');
      const days = endDateMoment.diff(startDateMoment, 'day');
      const months = Math.round(days / 30.4167);
      return `${months} month${months !== 1 ? 's' : ''}`;
    } else {
      return 'N/A';
    }
  }, [startDate, endDate, isStartDateModalOpen, isEndDateModalOpen]);

  return (
    <div className="flex space-x-12 mb-8">
      <div className="flex-col">
        <Label className="text-lg mb-2">Start Date</Label>
        <span onClick={openStartDateModal} className="text-indigo-700 cursor-pointer">
          {startDate ? dayjs(startDate?.date).format('MM/DD/YYYY') : 'Add Start Date'}
        </span>
        {startDateError && (
          <div tabIndex={0} tw="text-red-500 text-xs italic">
            {startDateError}
          </div>
        )}
      </div>
      <div className="flex-col">
        <Label className="text-lg mb-2">End Date</Label>
        <span onClick={openEndDateModal} className="text-indigo-700 cursor-pointer">
          {endDate ? dayjs(endDate?.date).format('MM/DD/YYYY') : 'Add End Date'}
        </span>
        {endDateError && (
          <div tabIndex={0} tw="text-red-500 text-xs italic">
            {endDateError}
          </div>
        )}
      </div>
      <div className="flex-col mb-2">
        <Label className="text-lg mb-2">Recommended Treatment Duration</Label>
        <span> {duration} </span>
      </div>
      {isStartDateModalOpen && (
        <PlanOfCareStartDateModal
          isOpen={isStartDateModalOpen}
          onClose={closeStartDateModal}
          date={startDate?.date ? new Date(startDate?.date) : undefined}
          clientId={clientId}
          shouldLogEvent={shouldLogEvent}
        />
      )}

      {isEndDateModalOpen && (
        <PlanOfCareEndDateModal
          isOpen={isEndDateModalOpen}
          onClose={closeEndDateModal}
          date={endDate?.date ? new Date(endDate?.date) : undefined}
          clientId={clientId}
          shouldLogEvent={shouldLogEvent}
        />
      )}
    </div>
  );
}

export function DurationOfVisits(props: { clientId: string; shouldLogEvent: boolean; durationVisitsError?: string }) {
  const { clientId, shouldLogEvent, durationVisitsError } = props;
  const {
    isOpen: isDurationOfVisitsModalOpen,
    onClose: closeDurationVisits,
    onOpen: openDurationVisits,
  } = useDisclosure();

  const durationOfVisits = useContentfulEntry({
    entryId: EvaluationEntriesIds.DurationOfVisits,
  });

  const { data: durationData } = useGetPlanOfCareVisitDuration({ clientId });
  return (
    <div className="flex space-x-12 mb-8">
      <div className="flex-col">
        <Label className="text-lg mb-2">Duration of Visits</Label>
        <span onClick={openDurationVisits} className="text-indigo-700 cursor-pointer">
          {durationData?.duration ?? 'Add Duration of Visits'}
        </span>
        {durationVisitsError && (
          <div tabIndex={0} tw="text-red-500 text-xs italic">
            {durationVisitsError}
          </div>
        )}
      </div>
      {isDurationOfVisitsModalOpen && (
        <PlanOfCareDurationVisitsModal
          isOpen={isDurationOfVisitsModalOpen}
          onClose={closeDurationVisits}
          clientId={clientId}
          shouldLogEvent={shouldLogEvent}
          duration={durationData?.duration}
          durationOfVisits={durationOfVisits}
        />
      )}
    </div>
  );
}

export function TotalVisitsRequested(props: { clientId: string; shouldLogEvent: boolean; totalVisitsError?: string }) {
  const { clientId, shouldLogEvent, totalVisitsError } = props;

  const { isOpen: isTotalVisitsModalOpen, onClose: closeTotalVisits, onOpen: openTotalVisits } = useDisclosure();

  const { data: totalVisitsData } = useGetPlanOfCareTotalVisits({ clientId });
  return (
    <div className="flex space-x-12 mb-8">
      <div className="flex-col mb-2">
        <Label className="text-lg mb-2">Total Visits Requested</Label>
        <span className="text-indigo-700 cursor-pointer" onClick={openTotalVisits}>
          {totalVisitsData?.totalVisits ?? 'N/A'}
        </span>
        {totalVisitsError && (
          <div tabIndex={0} tw="text-red-500 text-xs italic">
            {totalVisitsError}
          </div>
        )}
      </div>
      {isTotalVisitsModalOpen && (
        <PlanOfCareTotalVisitsModal
          isOpen={isTotalVisitsModalOpen}
          onClose={closeTotalVisits}
          clientId={clientId}
          shouldLogEvent={shouldLogEvent}
          totalVisits={totalVisitsData?.totalVisits}
        />
      )}
    </div>
  );
}

export function PrimaryTherapyPurpose(props: { clientId: string; shouldLogEvent: boolean; error?: string }) {
  const { clientId, shouldLogEvent, error } = props;
  const {
    isOpen: isPrimaryTherapyPurposeModalOpen,
    onClose: closePrimaryTherapyPurpose,
    onOpen: openPrimaryTherapyPurpose,
  } = useDisclosure();

  const contentfulPurposes = useContentfulEntry({
    entryId: EvaluationEntriesIds.PrimaryTherapyPurpose,
  });

  const { data } = useGetPrimaryPurpose({ clientId });

  return (
    <div className="flex space-x-12 mb-8">
      <div className="flex-col">
        <div className="flex-row mb-5">
          <span className="text-lg font-semibold mb-2">Primary Purpose of Therapy</span>
          <Link
            to="#"
            tw="text-indigo-700 ml-5"
            data-testid="areas-of-function-add"
            onClick={openPrimaryTherapyPurpose}
          >
            <FontAwesomeIcon tw="text-sm mr-2 font-normal" icon="plus-circle" />
            {data?.details ? 'Update Primary Purpose' : 'Add Primary Purpose'}
          </Link>
          <div tabIndex={0} tw="text-red-500 text-xs italic">
            {error}
          </div>
        </div>
        {!data?.details ? (
          <div tw="text-sm text-gray-400 mb-4" data-testid="client-plan-of-care-form-no-areas-of-function">
            No primary purpose has been added
          </div>
        ) : (
          <div className="mt-4">{data.details}</div>
        )}
      </div>

      {isPrimaryTherapyPurposeModalOpen && (
        <PlanOfCarePrimaryPurposeModal
          isOpen={isPrimaryTherapyPurposeModalOpen}
          onClose={closePrimaryTherapyPurpose}
          clientId={clientId}
          shouldLogEvent={shouldLogEvent}
          purpose={data?.details}
          options={contentfulPurposes?.data?.dropdownContent}
        />
      )}
    </div>
  );
}

export function AdditionalGoals(props: { clientId: string; shouldLogEvent: boolean; error?: string }) {
  const { clientId, shouldLogEvent, error } = props;
  const {
    isOpen: isAdditionalGoalsModalOpen,
    onClose: closeAdditionalGoals,
    onOpen: openAdditionalGoals,
  } = useDisclosure();

  const [action, setAction] = useState<'add' | 'update' | ''>('');
  const [selectedGoal, setSelectedGoal] = useState<GetAdditionalGoalsPayload | null>(null);

  const { data } = useGetAdditionalGoal({ clientId });
  return (
    <div className="flex space-x-12 mb-8">
      <div className="flex-col">
        <div className="flex-row mb-5">
          <span className="text-lg font-semibold mb-2">Additional Goals</span>
          <span className="ml-1 text-xxs text-gray-400 leading-none">OPTIONAL</span>
          <Link
            to="#"
            tw="text-indigo-700 ml-5"
            data-testid="areas-of-function-add"
            onClick={() => {
              setAction('add');
              openAdditionalGoals();
            }}
          >
            <FontAwesomeIcon tw="text-sm mr-2 font-normal" icon="plus-circle" />
            Add additional goal
          </Link>
          <div tabIndex={0} tw="text-red-500 text-xs italic">
            {error}
          </div>
        </div>
        {!data?.length ? (
          <div tw="text-sm text-gray-400 mb-4" data-testid="client-plan-of-care-form-no-areas-of-function">
            No additional goal has been added
          </div>
        ) : (
          <div className="mt-4">
            {data.map((item, index) => {
              const status = fromStatusToBadgeVariant(item.status);
              return (
                <div tw="mb-4" key={item.goalID}>
                  <Link
                    to="#"
                    tw="text-indigo-700 font-medium"
                    onClick={() => {
                      setSelectedGoal(item);
                      setAction('update');
                      openAdditionalGoals();
                    }}
                  >
                    <div data-testid="client-plan-of-care-form-current-ltg">{item.detail.goalDescription}</div>
                  </Link>
                  <Badge tw="text-xs font-medium" {...status}>
                    {`${item.goalType.toUpperCase()} - `}
                    {item.status.toUpperCase()}
                  </Badge>
                </div>
              );
            })}
          </div>
        )}
      </div>

      {isAdditionalGoalsModalOpen && (
        <AdditionalGoalsModal
          isOpen={isAdditionalGoalsModalOpen}
          onClose={closeAdditionalGoals}
          action={action}
          clientId={clientId}
          selectedGoal={selectedGoal}
          shouldLogEvent={shouldLogEvent}
        />
      )}
    </div>
  );
}

export function DischargePlan(props: { clientId: string; shouldLogEvent: boolean; error?: string }) {
  const { clientId, shouldLogEvent, error } = props;
  const { data: dischargePlan } = useGetDischargePlan({ clientId });

  const {
    isOpen: isDischargePlanModalOpen,
    onClose: closeDurationVisits,
    onOpen: openDurationVisits,
  } = useDisclosure();

  return (
    <>
      <div className="flex space-x-12">
        <div className="flex-col">
          <Label className="text-lg mb-2">Discharge Plan</Label>
          <p onClick={openDurationVisits} className="text-indigo-700 cursor-pointer">
            {!dischargePlan ? 'Add discharge plan' : dischargePlan.details}
          </p>
          {error && (
            <div tabIndex={0} tw="text-red-500 text-xs italic">
              {error}
            </div>
          )}
        </div>

        {isDischargePlanModalOpen && (
          <PlanOfCareDischargePlanModal
            isOpen={isDischargePlanModalOpen}
            onClose={closeDurationVisits}
            clientId={clientId}
            dischargePlan={dischargePlan}
            shouldLogEvent={shouldLogEvent}
          />
        )}
      </div>
    </>
  );
}

export function DevelopmentalDelays(props: { clientId: string; shouldLogEvent: boolean; error?: string }) {
  const { clientId, shouldLogEvent, error } = props;
  const { data: developmentalDelays } = useGetDevelopmentalDelays({ clientId });

  const {
    isOpen: isDevelopmentalDelaysModalOpen,
    onClose: closeDevelopmentalDelays,
    onOpen: openDevelopmentalDelays,
  } = useDisclosure();

  const { data: contentfulSeverityOptions } = useContentfulEntry({ entryId: '3kbuAuHz1gxRM6EyNoKQqL' });
  return (
    <div className="flex space-x-12 mb-8 ">
      <div className="flex-col">
        <span className="text-lg mb-2 font-semibold">Developmental Delays or Chronic Disabilities</span>
        {!developmentalDelays && (
          <Link
            to="#"
            tw="text-indigo-700 ml-5"
            data-testid="areas-of-function-add"
            onClick={() => {
              openDevelopmentalDelays();
            }}
          >
            <FontAwesomeIcon tw="text-sm mr-2 font-normal" icon="plus-circle" />
            Add Delay or Disabilities
          </Link>
        )}

        {!developmentalDelays && (
          <div tw="text-sm text-gray-400 mb-4 mt-5" data-testid="client-plan-of-care-form-no-delay-disability">
            No developmental delays or chronic disabilities have been added
          </div>
        )}
        {error && (
          <div tabIndex={0} tw="text-red-500 text-xs italic">
            {error}
          </div>
        )}
        {developmentalDelays && (
          <p onClick={openDevelopmentalDelays} className="text-indigo-700 cursor-pointer">
            {`${developmentalDelays.present ? 'Yes' : 'No'}${
              developmentalDelays.severity ? `, ${developmentalDelays.severity}` : ``
            }`}
          </p>
        )}
      </div>
      {isDevelopmentalDelaysModalOpen && (
        <DevelopmentalDelaysModal
          isOpen={isDevelopmentalDelaysModalOpen}
          onClose={closeDevelopmentalDelays}
          clientId={clientId}
          severityOptions={contentfulSeverityOptions?.dropdownContent.map((item: string) => ({
            label: item,
            value: item,
          }))}
          delaysPayload={developmentalDelays}
          shouldLogEvent={shouldLogEvent}
        />
      )}
    </div>
  );
}

export type ContentfulEntry = {
  label: string;
  dropdownContent: string[];
};

export const mapToOption = (option: string): SelectOption<string> => {
  return {
    value: option,
    label: option,
  };
};

export const evaluationQualityReviewOptions = [
  { label: 'Pending Locked Evaluation Note', value: 'pending' },
  { label: 'In Review', value: 'in_review' },
  { label: 'Review Complete', value: 'reviewed' },
];

export const dischargeQualityReviewOptions = [
  { label: 'Pending Locked Discharge Note', value: 'pending' },
  { label: 'In Review', value: 'in_review' },
  { label: 'Review Complete', value: 'reviewed' },
];

export function ClinicalQualityReview(props: { clientId: string }) {
  const { clientId } = props;
  const { addToast } = useToasts();
  const [evaluationQualityReviewGuidelines, setEvaluationQualityReviewGuidelines] = useState([]);
  const [dischargeQualityReviewGuidelines, setDischargeQualityReviewGuidelines] = useState([]);
  const [submitEvaluationQualityReviewSuccess, setSubmitEvaluationQualityReviewSuccess] = useState<boolean>(false);
  const [submitDischargeQualityReviewSuccess, setSubmitDischargeQualityReviewSuccess] = useState<boolean>(false);

  const {
    data: evaluationQualityReviewData,
    isLoading: isLoadingEvaluationQualityReview,
    isFetching: isFetchingEvaluations,
    refetch: refetchEvaluationQualityReview,
  } = useGetEvaluationQualityReview(clientId);
  const {
    data: dischargeQualityReviewData,
    isLoading: isLoadingDischargeQualityReview,
    isFetching: isFetchingDischarges,
    refetch: refetchDischargeQualityReview,
  } = useGetDischargeQualityReview(clientId);

  const createEvaluationQualityReviewMutation = useCreateEvaluationQualityReview();
  const { mutateAsync: createEvaluationQualityReview } = createEvaluationQualityReviewMutation;
  const createDishcargeQualityReviewMutation = useCreateDischargeQualityReview();
  const { mutateAsync: createDischargeQualityReview } = createDishcargeQualityReviewMutation;

  const { data: { dropdownContent: evaluationDropdownContent = [] } = {} } = useContentfulEntry<ContentfulEntry>({
    entryId: EntryId.EvaluationQualityReview,
  });
  const { data: { dropdownContent: dischargeDropdownContent = [] } = {} } = useContentfulEntry<ContentfulEntry>({
    entryId: EntryId.DischargeQualityReview,
  });

  const evaluationGuidelinesControls = evaluationDropdownContent.filter(item => item)?.map(eqp => mapToOption(eqp));
  const dischargeGuidelinesControls = dischargeDropdownContent.filter(item => item)?.map(eqp => mapToOption(eqp));

  const {
    formState: { errors },
    control,
    register,
    handleSubmit,
    watch,
    setValue,
  } = useForm({
    mode: 'all',
  });

  const watchEvaluationQualityReviewStatus = useWatch({
    control,
    name: 'clinicalQualityReview.evaluationQualityReview.status',
    defaultValue: 'pending',
  });
  const watchEvaluationQualityReviewIssueLevel = watch('clinicalQualityReview.evaluationQualityReview.issuesLevel');
  const watchEvaluationQualityReviewReviewerNote = watch('clinicalQualityReview.evaluationQualityReview.reviewerNote');

  const watchDischargeQualityReviewStatus = useWatch({
    control,
    name: 'clinicalQualityReview.dischargeQualityReview.status',
    defaultValue: 'pending',
  });
  const watchDischargeQualityReviewIssueLevel = watch('clinicalQualityReview.dischargeQualityReview.issuesLevel');
  const watchDischargeQualityReviewReviewerNote = watch('clinicalQualityReview.dischargeQualityReview.reviewerNote');

  const submitQualityReviews = async () => {
    const evaluationQualityReviewPayload = {
      clientId,
      status:
        watchEvaluationQualityReviewStatus.value === 'in_review'
          ? 'reviewed'
          : watchEvaluationQualityReviewStatus.value,
      issuesLevel:
        watchEvaluationQualityReviewStatus.value === 'in_review' ? watchEvaluationQualityReviewIssueLevel : null,
      checklist: watchEvaluationQualityReviewStatus.value === 'in_review' ? evaluationQualityReviewGuidelines : null,
      reviewerNote:
        watchEvaluationQualityReviewStatus.value === 'in_review' ? watchEvaluationQualityReviewReviewerNote : null,
    };
    const dischargeQualityReviewPayload = {
      clientId,
      status:
        watchDischargeQualityReviewStatus.value === 'in_review' ? 'reviewed' : watchDischargeQualityReviewStatus.value,
      issuesLevel:
        watchDischargeQualityReviewStatus.value === 'in_review' ? watchDischargeQualityReviewIssueLevel : null,
      checklist: watchDischargeQualityReviewStatus.value === 'in_review' ? dischargeQualityReviewGuidelines : null,
      reviewerNote:
        watchDischargeQualityReviewStatus.value === 'in_review' ? watchDischargeQualityReviewReviewerNote : null,
    };

    try {
      await createEvaluationQualityReview(evaluationQualityReviewPayload, {
        onSuccess: () => {
          setSubmitEvaluationQualityReviewSuccess(true);
        },
      });
      await createDischargeQualityReview(dischargeQualityReviewPayload, {
        onSuccess: () => {
          setSubmitDischargeQualityReviewSuccess(true);
        },
      });
    } catch (error) {
      addToast('Error saving Clinical Quality Review', { appearance: 'error', autoDismiss: true });
    }

    setSubmitEvaluationQualityReviewSuccess(false);
    setSubmitDischargeQualityReviewSuccess(false);
    refetchEvaluationQualityReview();
    refetchDischargeQualityReview();
  };

  React.useEffect(() => {
    if (submitEvaluationQualityReviewSuccess && submitDischargeQualityReviewSuccess) {
      addToast('Clinical Quality Review saved', { appearance: 'success', autoDismiss: true });
    }
  }, [submitEvaluationQualityReviewSuccess, submitDischargeQualityReviewSuccess]);

  React.useEffect(() => {
    if (
      !isLoadingEvaluationQualityReview ||
      !isLoadingDischargeQualityReview ||
      isFetchingEvaluations ||
      isFetchingDischarges
    ) {
      setValue(
        'clinicalQualityReview.evaluationQualityReview.status',
        (evaluationQualityReviewOptions.find(
          ({ label, value }) => value === evaluationQualityReviewData?.status,
        ) as SelectOption) ?? evaluationQualityReviewOptions[0],
      );
      setValue('clinicalQualityReview.evaluationQualityReview.issuesLevel', evaluationQualityReviewData?.issuesLevel);
      setValue('clinicalQualityReview.evaluationQualityReview.reviewerNote', evaluationQualityReviewData?.reviewerNote);
      if (evaluationQualityReviewData?.checklist) {
        evaluationQualityReviewData.checklist.forEach(value => {
          setValue(`evalCheckbox ${value}`, true);
        });
        setEvaluationQualityReviewGuidelines(evaluationQualityReviewData?.checklist as any);
      }

      setValue(
        'clinicalQualityReview.dischargeQualityReview.status',
        (dischargeQualityReviewOptions.find(
          ({ label, value }) => value === dischargeQualityReviewData?.status,
        ) as SelectOption) ?? dischargeQualityReviewOptions[0],
      );
      setValue('clinicalQualityReview.dischargeQualityReview.issuesLevel', dischargeQualityReviewData?.issuesLevel);
      setValue('clinicalQualityReview.dischargeQualityReview.reviewerNote', dischargeQualityReviewData?.reviewerNote);
      if (dischargeQualityReviewData?.checklist) {
        dischargeQualityReviewData.checklist.forEach(value => {
          setValue(`dischargeCheckbox ${value}`, true);
        });
        setDischargeQualityReviewGuidelines(dischargeQualityReviewData?.checklist as any);
      }
    }
  }, [isLoadingEvaluationQualityReview, isLoadingDischargeQualityReview, isFetchingEvaluations, isFetchingDischarges]);

  React.useEffect(() => {
    if (
      evaluationQualityReviewData?.status === 'reviewed' ||
      dischargeQualityReviewData?.status === 'reviewed' ||
      isFetchingEvaluations ||
      isFetchingDischarges
    ) {
      setValue('clinicalQualityReview.evaluationQualityReview.reviewerNote', null);
      setValue('clinicalQualityReview.evaluationQualityReview.issuesLevel', null);
      if (evaluationQualityReviewData?.checklist) {
        evaluationQualityReviewData.checklist.forEach(value => {
          setValue(`evalCheckbox ${value}`, false);
        });
        setEvaluationQualityReviewGuidelines([]);
      }

      setValue('clinicalQualityReview.dischargeQualityReview.reviewerNote', null);
      setValue('clinicalQualityReview.dischargeQualityReview.issuesLevel', null);
      if (dischargeQualityReviewData?.checklist) {
        dischargeQualityReviewData.checklist.forEach(value => {
          setValue(`dischargeCheckbox ${value}`, false);
        });
        setDischargeQualityReviewGuidelines([]);
      }
    }
  }, [evaluationQualityReviewData, dischargeQualityReviewData, isFetchingEvaluations, isFetchingDischarges]);

  const handleGuidelinesCheckboxes = (
    value: string,
    isChecked: boolean,
    setGuidelinesSelected: (prev: any) => void,
  ) => {
    setGuidelinesSelected((prev: string[]) => {
      if (isChecked) {
        return [...prev, value];
      } else {
        return prev.filter((item: string) => item !== value);
      }
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit(submitQualityReviews)}>
        <div>
          <div>
            <div tw="text-2xl mt-4 mb-9 font-semibold">Clinical Quality Review</div>
            <div tw="mt-8">
              <FormGroup>
                <h4 tw="mb-2 text-sm font-semibold">Evaluation Quality Review</h4>
                <div data-testid="clinicalQualityReview.evaluationQualityReview.status">
                  <Controller
                    name="clinicalQualityReview.evaluationQualityReview.status"
                    data-testid="clinicalQualityReview.evaluationQualityReview.status"
                    control={control}
                    defaultValue={evaluationQualityReviewData?.status}
                    render={({ field }) => (
                      <Select
                        {...field}
                        tw="w-full sm:w-96"
                        options={evaluationQualityReviewOptions}
                        placeholder="Select..."
                      />
                    )}
                  />
                </div>
              </FormGroup>
              {watchEvaluationQualityReviewStatus.value === 'in_review' && (
                <>
                  <FormGroup tw="mb-8 sm:mb-5">
                    <h4 tw="mb-2 text-sm font-semibold">Quality Review</h4>
                    <div tw="flex flex-row max-h-[20px]">
                      <FormInline tw="flex items-baseline mr-7">
                        <Input
                          type="radio"
                          id="clinicalQualityReview.evaluationQualityReview-issuesLevel.no_issues"
                          data-testid="clinicalQualityReview.evaluationQualityReview-issuesLevel.no_issues"
                          {...register('clinicalQualityReview.evaluationQualityReview.issuesLevel')}
                          value="no_issues"
                        />
                        <Label
                          font="normal"
                          htmlFor="clinicalQualityReview.evaluationQualityReview-issuesLevel.no_issues"
                          tw="ml-2"
                        >
                          No issues
                        </Label>
                      </FormInline>
                      <FormInline tw="flex items-baseline mr-7">
                        <Input
                          type="radio"
                          id="clinicalQualityReview.evaluationQualityReview-issuesLevel.some_issues"
                          data-testid="clinicalQualityReview.evaluationQualityReview-issuesLevel.some_issues"
                          {...register('clinicalQualityReview.evaluationQualityReview.issuesLevel')}
                          value="some_issues"
                        />
                        <Label
                          font="normal"
                          htmlFor="clinicalQualityReview.evaluationQualityReview-issuesLevel.some_issues"
                          tw="ml-2"
                        >
                          Some issues
                        </Label>
                      </FormInline>
                      <FormInline tw="flex items-baseline">
                        <Input
                          type="radio"
                          id="clinicalQualityReview.evaluationQualityReview-issuesLevel.severe_issues"
                          data-testid="clinicalQualityReview.evaluationQualityReview-issuesLevel.severe_issues"
                          {...register('clinicalQualityReview.evaluationQualityReview.issuesLevel')}
                          value="severe_issues"
                        />
                        <Label
                          font="normal"
                          htmlFor="clinicalQualityReview.evaluationQualityReview-issuesLevel.severe_issues"
                          tw="ml-2"
                        >
                          Severe issues
                        </Label>
                      </FormInline>
                    </div>
                  </FormGroup>
                  <h4 tw="mb-2 text-sm font-semibold">Evaluation Guidelines</h4>
                  {evaluationGuidelinesControls?.map(({ value, label }) => {
                    return (
                      <div key={value}>
                        <Controller
                          name={`evalCheckbox ${value}`}
                          control={control}
                          render={({ field }) => (
                            <FormGroup type="inline" tw="items-baseline">
                              <Input
                                {...field}
                                type="checkbox"
                                id={value}
                                name={value}
                                tw="mb-0"
                                spacing="tight"
                                onChange={e =>
                                  handleGuidelinesCheckboxes(
                                    label,
                                    e.target.checked,
                                    setEvaluationQualityReviewGuidelines,
                                  )
                                }
                              />

                              <Label tw="font-normal mb-0 ml-2 text-sm" htmlFor={value}>
                                {label}
                              </Label>
                            </FormGroup>
                          )}
                        />
                      </div>
                    );
                  })}
                  <FormGroup tw="w-full mt-5">
                    <Label htmlFor="clinicalQualityReview.evaluationQualityReview.reviewerNote">Reviewer Notes</Label>
                    <Textarea
                      id="clinicalQualityReview.evaluationQualityReview.reviewerNote"
                      tw="w-full sm:w-3/4 mt-3"
                      rows={3}
                      maxLength={1000}
                      {...register('clinicalQualityReview.evaluationQualityReview.reviewerNote', { required: true })}
                      error={errors?.clinicalQualityReview?.evaluationQualityReview?.reviewerNote}
                      defaultValue={evaluationQualityReviewData?.reviewerNote}
                    />
                  </FormGroup>
                </>
              )}
            </div>
            <div tw="mt-2">
              <FormGroup>
                <h4 tw="mb-2 text-sm font-semibold">Discharge Quality Review</h4>
                <div data-testid="clinicalQualityReview.dischargeQualityReview.status">
                  <Controller
                    name="clinicalQualityReview.dischargeQualityReview.status"
                    data-testid="clinicalQualityReview.dischargeQualityReview.status"
                    control={control}
                    defaultValue={dischargeQualityReviewData?.status}
                    render={({ field }) => (
                      <Select
                        {...field}
                        tw="w-full sm:w-96"
                        options={dischargeQualityReviewOptions}
                        placeholder="Select..."
                      />
                    )}
                  />
                </div>
              </FormGroup>
              {watchDischargeQualityReviewStatus.value === 'in_review' && (
                <>
                  <FormGroup tw="mb-8 sm:mb-5">
                    <h4 tw="mb-2 text-sm font-semibold">Quality Review</h4>
                    <div tw="flex flex-row max-h-[20px]">
                      <FormInline tw="flex items-baseline mr-7">
                        <Input
                          type="radio"
                          id="clinicalQualityReview.dischargeQualityReview-issuesLevel.no_issues"
                          data-testid="clinicalQualityReview.dischargeQualityReview-issuesLevel.no_issues"
                          {...register('clinicalQualityReview.dischargeQualityReview.issuesLevel')}
                          value="no_issues"
                        />
                        <Label
                          font="normal"
                          htmlFor="clinicalQualityReview.dischargeQualityReview-issuesLevel.no_issues"
                          tw="ml-2"
                        >
                          No issues
                        </Label>
                      </FormInline>
                      <FormInline tw="flex items-baseline mr-7">
                        <Input
                          type="radio"
                          id="clinicalQualityReview.dischargeQualityReview-issuesLevel.some_issues"
                          data-testid="clinicalQualityReview.dischargeQualityReview-issuesLevel.some_issues"
                          {...register('clinicalQualityReview.dischargeQualityReview.issuesLevel')}
                          value="some_issues"
                        />
                        <Label
                          font="normal"
                          htmlFor="clinicalQualityReview.dischargeQualityReview-issuesLevel.some_issues"
                          tw="ml-2"
                        >
                          Some issues
                        </Label>
                      </FormInline>
                      <FormInline tw="flex items-baseline">
                        <Input
                          type="radio"
                          id="clinicalQualityReview.dischargeQualityReview-issuesLevel.severe_issues"
                          data-testid="clinicalQualityReview.dischargeQualityReview-issuesLevel.severe_issues"
                          {...register('clinicalQualityReview.dischargeQualityReview.issuesLevel')}
                          value="severe_issues"
                        />
                        <Label
                          font="normal"
                          htmlFor="clinicalQualityReview.dischargeQualityReview-issuesLevel.severe_issues"
                          tw="ml-2"
                        >
                          Severe issues
                        </Label>
                      </FormInline>
                    </div>
                  </FormGroup>
                  <h4 tw="mb-2 text-sm font-semibold">Discharge Guidelines</h4>
                  {dischargeGuidelinesControls?.map(({ value, label }) => {
                    return (
                      <div key={value}>
                        <Controller
                          name={`dischargeCheckbox ${value}`}
                          control={control}
                          render={({ field }) => (
                            <FormGroup type="inline" tw="items-baseline">
                              <Input
                                {...field}
                                type="checkbox"
                                id={value}
                                name={value}
                                tw="mb-0"
                                spacing="tight"
                                onChange={e =>
                                  handleGuidelinesCheckboxes(
                                    label,
                                    e.target.checked,
                                    setDischargeQualityReviewGuidelines,
                                  )
                                }
                              />
                              <Label tw="font-normal mb-0 ml-2 text-sm" htmlFor={value}>
                                {label}
                              </Label>
                            </FormGroup>
                          )}
                        />
                      </div>
                    );
                  })}
                  <FormGroup tw="w-full mt-5">
                    <Label htmlFor="clinicalQualityReview.dischargeQualityReview.reviewerNote">Reviewer Notes</Label>
                    <Textarea
                      id="clinicalQualityReview.dischargeQualityReview.reviewerNote"
                      tw="w-full sm:w-3/4 mt-3"
                      rows={3}
                      maxLength={1000}
                      {...register('clinicalQualityReview.dischargeQualityReview.reviewerNote', { required: true })}
                      error={errors?.clinicalQualityReview?.dischargeQualityReview?.reviewerNote}
                      defaultValue={dischargeQualityReviewData?.reviewerNote}
                    />
                  </FormGroup>
                </>
              )}
            </div>
            <div tw="mt-2">
              <Button
                tw="mr-5"
                variant="primary"
                type="submit"
                loading={
                  createEvaluationQualityReviewMutation.isLoading || createDishcargeQualityReviewMutation.isLoading
                }
                data-testid="clinicalQualityReview-save"
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export const getQualityReviewStatusLabel = (statusOption: string, optionsArray: SelectOption[]) => {
  const statusLabel = optionsArray.find(option => option.value === statusOption);
  return statusLabel?.label;
};

export function ClinicalQualityReviewReadOnly(props: { clientId: string }) {
  const { clientId } = props;

  const { data: evaluationQualityReviewData } = useGetEvaluationQualityReview(clientId);
  const { data: dischargeQualityReviewData } = useGetDischargeQualityReview(clientId);

  return (
    <>
      <div className="flex space-x-12">
        <div className="flex-col">
          <div tw="text-2xl mt-4 mb-9 font-semibold">Clinical Quality Review</div>
          {evaluationQualityReviewData?.status && (
            <div tw="mt-8">
              <p tw="font-medium text-sm leading-4" data-testid="clinical-quality-review-evaluation-quality-review">
                Evaluation Quality Review
              </p>
              <p tw="font-normal text-sm pt-2 leading-6">
                {getQualityReviewStatusLabel(evaluationQualityReviewData?.status, evaluationQualityReviewOptions)}
              </p>
            </div>
          )}
          {dischargeQualityReviewData?.status && (
            <div tw="mt-8">
              <p tw="font-medium text-sm leading-4" data-testid="clinical-quality-review-discharge-quality-review">
                Discharge Quality Review
              </p>
              <p tw="font-normal text-sm pt-2 leading-6">
                {getQualityReviewStatusLabel(dischargeQualityReviewData?.status, dischargeQualityReviewOptions)}
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export interface ClientPlanOfCareFormProps {
  clientId: string;
}

export default function ClientPlanOfCareForm(props: ClientPlanOfCareFormProps) {
  const {
    cueDischargeReview: iscueDischargeReviewEnabled,
    cueGenerateAuthorizationMessage: isCueGenerateAuthorizationMessage,
  } = useFlags();
  const { clientId } = props;

  const {
    isOpen: ClientGenerateAuthorizationModalIsOpen,
    onClose: ClientGenerateAuthorizationModalOnClose,
    onOpen: ClientGenerateAuthorizationModalOnOpen,
  } = useDisclosure();

  return (
    <div data-testid="client-plan-of-care-form">
      <Spacer size="md" />
      <Card tw="px-8 mb-5">
        <div className="flex items-center">
          <div tw="text-2xl mt-4 mb-9 font-semibold">Plan of Care</div>
          {isCueGenerateAuthorizationMessage && (
            <>
              <div tw="mb-5">
                <div>
                  <Link
                    to="#"
                    tw="text-indigo-700 ml-5"
                    data-testid="generate-authorization-message"
                    onClick={ClientGenerateAuthorizationModalOnOpen}
                  >
                    <FontAwesomeIcon tw="text-sm mr-2" icon="plus-circle" />
                    Generate Authorization Message
                  </Link>
                </div>

                {ClientGenerateAuthorizationModalIsOpen && (
                  <ClientGenerateAuthorizationModal
                    isOpen={ClientGenerateAuthorizationModalIsOpen}
                    onClose={ClientGenerateAuthorizationModalOnClose}
                    clientId={clientId}
                  />
                )}
              </div>
            </>
          )}
        </div>
        <Diagnoses clientId={props.clientId} shouldLogEvent />

        <MedicalDiagnoses clientId={props.clientId} shouldLogEvent />

        <PlanOfCareDates clientId={props.clientId} shouldLogEvent />

        <DurationOfVisits clientId={props.clientId} shouldLogEvent />

        <Frequency clientId={props.clientId} shouldLogEvent />

        <TotalVisitsRequested clientId={props.clientId} shouldLogEvent />

        <Goals clientId={props.clientId} shouldLogEvent withGoalsFilters={true} />

        <AdditionalGoals clientId={props.clientId} shouldLogEvent />

        <PrimaryTherapyPurpose clientId={props.clientId} shouldLogEvent />

        <AreasOfFunction clientId={props.clientId} shouldLogEvent />

        <DevelopmentalDelays clientId={props.clientId} shouldLogEvent />

        <ApplicableConditions clientId={props.clientId} shouldLogEvent />

        <SkilledInterventions clientId={props.clientId} shouldLogEvent />

        <AdaptiveEquipment clientId={props.clientId} shouldLogEvent />

        <DischargePlan clientId={props.clientId} shouldLogEvent />
      </Card>

      <Spacer size="md" />
      {iscueDischargeReviewEnabled ? (
        <Card tw="px-8 mb-5">
          <ClinicalQualityReview clientId={props.clientId} />
        </Card>
      ) : (
        <Card tw="px-8 mb-5">
          <ClinicalQualityReviewReadOnly clientId={props.clientId} />
        </Card>
      )}
    </div>
  );
}
