import 'twin.macro';

import { useGetMfaStatus } from 'hooks/use-mfa';
import usePermissions from 'hooks/use-permissions';
import { useEffect, useState } from 'react';
import { getUserEmail } from 'utils/get-access-token';

import { useDisclosure } from '@expressable/ui-library';

import MfaDisableModal from './mfa-disable-modal';
import MfaEnableModal from './mfa-enable-modal';
import { MfaEvent } from 'domain/therapist/constants';

import { useFlags } from 'launchdarkly-react-client-sdk';

const Mfa = ({ therapistFullName, therapistEmail }: { therapistFullName: string; therapistEmail: string }) => {
  const { data, isLoading } = useGetMfaStatus(therapistEmail);

  const [currentUserEmail, setCurrentUserEmail] = useState('');
  const { isAdminOrClinicalManager } = usePermissions();

  const {
    isOpen: MfaEnableModalIsOpen,
    onClose: MfaEnableModalOnClose,
    onOpen: MfaEnableModalOnOpen,
  } = useDisclosure();
  const { isOpen: MfaDisableIsOpen, onClose: MfaDisableOnClose, onOpen: MfaDisableOnOpen } = useDisclosure();

  const { cueMfaDisableButton: canDisableMFA } = useFlags();

  useEffect(() => {
    const fetchCurrentUserEmail = async () => {
      const email = await getUserEmail();
      setCurrentUserEmail(email);
    };

    fetchCurrentUserEmail();
  }, []);

  const isCurrentCognitoUserOwner = therapistEmail === currentUserEmail;

  const onClick = async () => {
    try {
      if (!data?.mfa.isActive) {
        MfaEnableModalOnOpen();
      } else {
        MfaDisableOnOpen();
      }
    } catch (error) {
      console.error('MFA Error:', error);
    }
  };

  return (
    <div tw="mb-8">
      <div tw="flex space-x-2">
        <div tw="mt-5 text-sm leading-none capitalize font-semibold">MFA</div>
        {data?.error === undefined && (
          <button onClick={onClick} tw="mt-5 text-xs font-medium text-indigo-700">
            {!isLoading && data?.mfa.isActive && isAdminOrClinicalManager && canDisableMFA ? 'Disable' : ''}
            {!isLoading && !data?.mfa.isActive && isCurrentCognitoUserOwner ? 'Enable' : ''}
          </button>
        )}
      </div>
      {data && !isLoading ? (
        <>
          {!data.mfa.isActive && data.error === MfaEvent.USR_NOT_FOUND ? (
            <div tw="text-red-500">Therapist e-mail is not registered on Cognito.</div>
          ) : (
            <>
              <ul>
                <li>{data.mfa.isActive ? 'Enabled' : 'Disabled'}</li>
                {data.mfa.activeMethod && (
                  <>
                    <li>
                      {data.mfa.activeMethod === 'app'
                        ? 'Authenticator app'
                        : `SMS (${data.phone?.phoneNumber || 'Not Available'})`}
                    </li>
                  </>
                )}
              </ul>
              <MfaEnableModal
                isOpen={MfaEnableModalIsOpen}
                onClose={MfaEnableModalOnClose}
                therapistEmail={therapistEmail}
              />
              <MfaDisableModal
                isOpen={MfaDisableIsOpen}
                onClose={MfaDisableOnClose}
                therapistFullName={therapistFullName}
                therapistEmail={therapistEmail}
                method={data.mfa.activeMethod!}
              />
            </>
          )}
        </>
      ) : (
        <div tw="mt-1 text-xs bg-gray-300 rounded animate-pulse w-16 h-3" />
      )}
    </div>
  );
};

export default Mfa;
