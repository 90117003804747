import { useMemo, useRef, memo } from 'react';
import { Fragment, useEffect } from 'react';
import { styled } from 'twin.macro';

import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useViewport } from '@expressable/utils';
import { faSlidersV } from '@fortawesome/pro-solid-svg-icons';
import { ResourceSelectFilter } from './resource-select-filter';
import { SelectOption } from 'types';

import { Button, FormGroup, FormInline, Input, Label, Loader, useDisclosure } from '@expressable/ui-library';

import {
  ageRangesOptions,
  complexityOptions,
  homeworkType,
  timeRequiredOptions,
} from '../activity-modals/add-academy-activity';

import { ResourceCard } from './resource-card';
import { CLIENT_RESOURCES_CONFIG, resultContainerStyles } from 'domain/client-resources/constants';
import useClientResourcesData from 'domain/client-resources/hooks';
import { useTreatmentAreas } from 'hooks/use-treatment-areas';
import { usePagination } from 'hooks/use-pagination';
import { Pagination } from './pagination';
import { ClientResourcesFilter } from 'domain/client-resources/types';
import 'react-dates/initialize';
import { useClinicalPhonemes } from 'domain/clinical-pathways/use-clinical-phonemes';

const ResultContainer = memo(styled.div<{ isMobile: boolean }>`
  ${({ isMobile }) => resultContainerStyles[isMobile ? 'mobile' : 'desktop']}
`);

const ClientResources = () => {
  const { data: treatmentAreasEntries } = useTreatmentAreas();
  const { viewportWidth } = useViewport();
  const {
    isOpen: isFilterMobileOverlayOpen,
    onOpen: onFilterMobileOverlayOpen,
    onClose: onFilterMobileOverlayClose,
  } = useDisclosure();

  const isMobile = useMemo(() => viewportWidth <= CLIENT_RESOURCES_CONFIG.MOBILE_BREAKPOINT, [viewportWidth]);
  const searchTermRef = useRef<HTMLInputElement>(null);

  const treatmentAreasSelectOptions = treatmentAreasEntries
    ?.map(area => ({
      label: area.treatmentAreaCategory,
      value: area.id,
    }))
    .sort((a: SelectOption, b: SelectOption) => a.label?.localeCompare(b.label));

  const { register, control, handleSubmit, watch, reset, setValue } = useForm<ClientResourcesFilter>({
    defaultValues: CLIENT_RESOURCES_CONFIG.FILTERS_DEFAULT_VALUES,
  });

  const watchFilterState = watch('filterState');
  const keywordState = watchFilterState?.keywords;

  const { phonemeOptions } = useClinicalPhonemes(watchFilterState.phoneme ?? undefined);

  const { data: entries, isLoading, availableFilters } = useClientResourcesData({ ...watchFilterState, isMobile });
  const {
    items: data,
    totalPages,
    page,
    next,
    prev,
    totalItems,
    jumpTo,
  } = usePagination(entries, { perPage: CLIENT_RESOURCES_CONFIG.PAGINATION_SIZE, resetOnContentChange: false });

  const shouldShowPagination = data.length > 0;
  const shouldDisplayNextButton = Boolean(data.length && page + 1 <= totalPages);
  const shouldDisplayBackButton = Boolean(data.length && page >= 1);

  const onApplyFilterClick = () => {
    setValue('filterState.keywords', searchTermRef.current?.value ?? '');
    jumpTo(0);
    onFilterMobileOverlayClose();
  };
  const onClearFilterHandler = () => reset();
  const onSubmitFilters = () => setValue('filterState.keywords', searchTermRef.current?.value ?? '');

  const handlePaginationClick = (direction: 'next' | 'back') => {
    const paginationMapping = {
      next: next,
      back: prev,
    };

    paginationMapping[direction]();
  };

  // if we have the overlay opened and the viewport change, we want to close the overlay
  const handleCloseMobileFilterOverlay = () => {
    if (!isMobile) onFilterMobileOverlayClose();
  };

  useEffect(handleCloseMobileFilterOverlay, [isMobile]);

  // reset phoneme when contentType changes
  useEffect(() => {
    if (watchFilterState.contentType !== 'structured-activity') {
      setValue('filterState.phoneme', undefined);
      setValue('filterState.treatmentAreas', []);
    }

    if (watchFilterState.contentType === 'all') {
      reset();
    }

    if (watchFilterState.contentType === 'authored-by-you') {
      setValue('filterState.treatmentAreas', []);
      setValue('filterState.hasMaterials', '');
      setValue('filterState.caregiverRequired', '');
    }

    if (watchFilterState.contentType === 'learning-path') {
      setValue('filterState.hasMaterials', '');
      setValue('filterState.caregiverRequired', '');
      setValue('filterState.ageRange', []);
      setValue('filterState.complexity', []);
      setValue('filterState.timeRequired', []);
      setValue('filterState.type', []);
    }
  }, [watchFilterState.contentType]);

  // when content type changes to any of that, we reset the page to 0
  useEffect(() => {
    jumpTo(0);
  }, [watchFilterState.contentType]);

  return (
    <>
      <div tw="w-full space-y-5" data-testid="client-resources">
        {!isFilterMobileOverlayOpen && (
          <div data-testid="client-resources__title">
            <h2 tw="text-2xl font-semibold mb-3">Clinical Care Experience Content Library</h2>
          </div>
        )}
        {isFilterMobileOverlayOpen && (
          <div tw="flex flex-col w-full h-full">
            <div tw="flex">
              <span tw="font-semibold flex items-center text-xl">Filters</span>
              <span
                tw=" flex items-center justify-end text-gray-400 cursor-pointer w-full text-right"
                onClick={() => onFilterMobileOverlayClose()}
              >
                <svg
                  tw="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </span>
            </div>
            <form onSubmit={handleSubmit(onSubmitFilters)}>
              <div tw="text-sm  space-y-5">
                <div data-testid="filters__wrapper">
                  <div tw="flex justify-between mt-6 mb-7">
                    <h3 tw="text-base font-semibold mb-6">{`${(totalItems ?? 0).toLocaleString()} results`}</h3>
                    <span
                      tw="text-sm text-indigo-700 cursor-pointer font-semibold"
                      data-testid="client-resources__clear-filters"
                      onClick={onClearFilterHandler}
                    >
                      Clear Filters
                    </span>
                  </div>
                  <FormGroup>
                    <h4 tw="mb-2 text-sm font-semibold">Content Type</h4>
                    <FormInline>
                      <Input
                        type="radio"
                        data-testid="content-type-all"
                        id="content-type-all"
                        {...register('filterState.contentType')}
                        value="all"
                      />
                      <Label htmlFor="content-type-all" tw="ml-1 font-normal mr-4">
                        All
                      </Label>
                    </FormInline>
                    <FormInline>
                      <Input
                        type="radio"
                        data-testid="content-type-learning-path"
                        id="content-type-lp"
                        {...register('filterState.contentType')}
                        value="learning-path"
                      />
                      <Label htmlFor="content-type-lp" tw="ml-1 font-normal">
                        Learning Path (Therapy Strategy)
                      </Label>
                    </FormInline>
                    <FormInline>
                      <Input
                        type="radio"
                        data-testid="content-type-sa"
                        id="content-type-sa"
                        {...register('filterState.contentType')}
                        value="structured-activity"
                      />
                      <Label htmlFor="content-type-sa" tw="ml-1 font-normal">
                        Structured Activity
                      </Label>
                    </FormInline>

                    <FormInline>
                      <Input
                        type="radio"
                        data-testid="authored-by-you"
                        id="authored-by-you"
                        {...register('filterState.contentType')}
                        value="authored-by-you"
                      />
                      <Label htmlFor="authored-by-you" tw="ml-1 font-normal">
                        Authored By You
                      </Label>
                    </FormInline>
                  </FormGroup>

                  <ResourceSelectFilter
                    options={treatmentAreasSelectOptions}
                    control={control}
                    inputControlName="filterState.treatmentAreas"
                    title="Treatment Area"
                    disabled={availableFilters?.includes('treatmentAreas')}
                    placeholder="Select..."
                  />
                  <ResourceSelectFilter
                    options={ageRangesOptions}
                    control={control}
                    inputControlName="filterState.ageRange"
                    title="Age Range"
                    disabled={availableFilters?.includes('ageRange')}
                    placeholder="Select..."
                  />
                  <ResourceSelectFilter
                    control={control}
                    options={complexityOptions}
                    disabled={availableFilters?.includes('complexity')}
                    inputControlName="filterState.complexity"
                    title="Complexity"
                    placeholder="Select..."
                  />
                  <ResourceSelectFilter
                    options={timeRequiredOptions}
                    control={control}
                    inputControlName="filterState.timeRequired"
                    title="Time Required"
                    disabled={availableFilters?.includes('timeRequired')}
                    placeholder="Select..."
                  />
                  <ResourceSelectFilter
                    options={homeworkType}
                    control={control}
                    inputControlName="filterState.type"
                    title="Type"
                    disabled={availableFilters?.includes('type')}
                    placeholder="Select..."
                  />
                  <FormGroup>
                    <h4 tw="mb-2 text-sm font-semibold">Has materials?</h4>
                    <FormInline tw="items-baseline">
                      <Input
                        type="radio"
                        data-testid="has-materials-yes"
                        id="has-materials-yes"
                        {...register('filterState.hasMaterials')}
                        disabled={availableFilters?.includes('hasMaterials')}
                        value="yes"
                      />
                      <Label htmlFor="has-materials-yes" tw="ml-2 font-normal mr-4">
                        Yes
                      </Label>
                      <Input
                        type="radio"
                        data-testid="has-materials-no"
                        id="has-materials-no"
                        disabled={availableFilters?.includes('hasMaterials')}
                        {...register('filterState.hasMaterials')}
                        value="no"
                      />
                      <Label htmlFor="has-materials-no" tw="ml-2 font-normal">
                        No
                      </Label>
                    </FormInline>
                  </FormGroup>
                  <FormGroup>
                    <h4 tw="mb-2 text-sm font-semibold">Caregiver Required?</h4>
                    <FormInline tw="items-baseline">
                      <Input
                        type="radio"
                        data-testid="caregiver-required-yes"
                        id="caregiver-required-yes"
                        {...register('filterState.caregiverRequired')}
                        disabled={availableFilters?.includes('caregiverRequired')}
                        value="yes"
                      />
                      <Label htmlFor="caregiver-required-yes" tw="ml-2 font-normal mr-4">
                        Yes
                      </Label>
                      <Input
                        type="radio"
                        data-testid="caregiver-required-no"
                        id="caregiver-required-no"
                        disabled={availableFilters?.includes('hasMaterials')}
                        {...register('filterState.caregiverRequired')}
                        value="no"
                      />
                      <Label htmlFor="caregiver-required-no" tw="ml-2 font-normal">
                        No
                      </Label>
                    </FormInline>
                  </FormGroup>
                </div>
                <Button variant="secondary" type="submit" fullWidth tw="w-full mb-4" onClick={onApplyFilterClick}>
                  Apply Filters
                </Button>
                <hr />
              </div>
            </form>
            <hr />
          </div>
        )}
        <div data-testid="client-resources__content">
          <p>
            Browse all available Clinical Care Experience content: Learning Paths and Structured Activities, including
            custom activities you’ve created. At this time, this library is only a preview tool. You can make
            assignments within your client’s session documentation.
          </p>
        </div>
        {isMobile && (
          <Fragment>
            <div data-testid="search-by-keyword__wrapper" tw="space-y-4 mt-0">
              <h1 tw="mb-6 mt-7 text-base leading-none capitalize font-semibold">Search</h1>
              <FormGroup>
                <h4 tw="mb-2 text-sm font-semibold">Search by Keyword(s)</h4>
                <Input data-testid="client-resources__search-by-keyword" ref={searchTermRef} tw="w-full " />
              </FormGroup>
              <Button tw="mt-0" variant="secondary" onClick={onApplyFilterClick} fullWidth={isMobile}>
                Search
              </Button>
            </div>
            <hr />
          </Fragment>
        )}
        <div tw="flex flex-row w-full">
          {!isMobile && (
            <aside tw="min-w-[340px] max-w-[340px]">
              <form onSubmit={handleSubmit(onSubmitFilters)}>
                <div tw="text-sm pl-0 pr-6 space-y-5 border-b">
                  <div data-testid="search-by-keyword__wrapper" tw="space-y-4">
                    <h1 tw="mb-6 mt-5 text-base leading-none capitalize font-semibold">Search</h1>

                    <FormGroup>
                      <h4 tw="mb-2 text-sm font-semibold">Search by Keyword(s)</h4>
                      <Input data-testid="client-resources__search-by-keyword" ref={searchTermRef} tw="w-full" />
                    </FormGroup>
                    <Button variant="secondary" type="submit">
                      Search
                    </Button>
                  </div>
                  <hr />
                  <div data-testid="filters__wrapper">
                    <div tw="flex justify-between">
                      <h3 tw="text-base font-semibold mb-6">Filters</h3>
                      <span
                        tw="text-sm text-indigo-700 cursor-pointer font-semibold"
                        data-testid="client-resources__clear-filters"
                        onClick={onClearFilterHandler}
                      >
                        Clear Filters
                      </span>
                    </div>

                    <FormGroup>
                      <h4 tw="mb-2 text-sm font-semibold">Content Type</h4>
                      <FormInline>
                        <Input
                          type="radio"
                          data-testid="content-type-all"
                          id="content-type-all"
                          {...register('filterState.contentType')}
                          value="all"
                        />
                        <Label htmlFor="content-type-all" tw="ml-1 font-normal mr-4">
                          All
                        </Label>
                      </FormInline>
                      <FormInline>
                        <Input
                          type="radio"
                          data-testid="content-type-learning-path"
                          id="content-type-lp"
                          {...register('filterState.contentType')}
                          value="learning-path"
                        />
                        <Label htmlFor="content-type-lp" tw="ml-1 font-normal">
                          Learning Path (Therapy Strategy)
                        </Label>
                      </FormInline>
                      <FormInline>
                        <Input
                          type="radio"
                          data-testid="content-type-sa"
                          id="content-type-sa"
                          {...register('filterState.contentType')}
                          value="structured-activity"
                        />
                        <Label htmlFor="content-type-sa" tw="ml-1 font-normal">
                          Structured Activity
                        </Label>
                      </FormInline>

                      <FormInline>
                        <Input
                          type="radio"
                          data-testid="authored-by-you"
                          id="authored-by-you"
                          {...register('filterState.contentType')}
                          value="authored-by-you"
                        />
                        <Label htmlFor="authored-by-you" tw="ml-1 font-normal">
                          Authored By You
                        </Label>
                      </FormInline>
                    </FormGroup>

                    <ResourceSelectFilter
                      options={treatmentAreasSelectOptions}
                      control={control}
                      inputControlName="filterState.treatmentAreas"
                      title="Treatment Area"
                      disabled={availableFilters?.includes('treatmentAreas')}
                      placeholder="Select..."
                    />
                    {watchFilterState?.treatmentAreas?.find(
                      area => area.value === CLIENT_RESOURCES_CONFIG.ARTICULATION_ENTRY_ID,
                    ) &&
                      watchFilterState.contentType === 'structured-activity' && (
                        <ResourceSelectFilter
                          options={phonemeOptions as unknown as SelectOption[]}
                          control={control}
                          inputControlName="filterState.phoneme"
                          title="Speech Sounds"
                          placeholder="Select..."
                          isMulti={false}
                        />
                      )}
                    <ResourceSelectFilter
                      options={ageRangesOptions}
                      control={control}
                      inputControlName="filterState.ageRange"
                      //defaultValue={ageRangeDefaultValues}
                      title="Age Range"
                      disabled={availableFilters?.includes('ageRange')}
                      placeholder="Select..."
                    />
                    <ResourceSelectFilter
                      control={control}
                      options={complexityOptions}
                      disabled={availableFilters?.includes('complexity')}
                      inputControlName="filterState.complexity"
                      title="Complexity"
                      placeholder="Select..."
                    />
                    <ResourceSelectFilter
                      options={timeRequiredOptions}
                      control={control}
                      inputControlName="filterState.timeRequired"
                      disabled={availableFilters?.includes('timeRequired')}
                      title="Time Required"
                      placeholder="Select..."
                    />
                    <ResourceSelectFilter
                      options={homeworkType}
                      control={control}
                      inputControlName="filterState.type"
                      title="Type"
                      disabled={availableFilters?.includes('type')}
                      placeholder="Select..."
                    />
                    <FormGroup>
                      <h4 tw="mb-2 text-sm font-semibold">Has materials?</h4>
                      <FormInline tw="items-baseline">
                        <Input
                          type="radio"
                          data-testid="has-materials-yes"
                          id="has-materials-yes"
                          {...register('filterState.hasMaterials')}
                          value="yes"
                          disabled={availableFilters?.includes('hasMaterials')}
                        />
                        <Label htmlFor="has-materials-yes" tw="ml-2 font-normal mr-4">
                          Yes
                        </Label>
                        <Input
                          type="radio"
                          data-testid="has-materials-no"
                          id="has-materials-no"
                          {...register('filterState.hasMaterials')}
                          disabled={availableFilters?.includes('hasMaterials')}
                          value="no"
                        />
                        <Label htmlFor="has-materials-no" tw="ml-2 font-normal">
                          No
                        </Label>
                      </FormInline>
                    </FormGroup>
                    <FormGroup>
                      <h4 tw="mb-2 text-sm font-semibold">Caregiver Required?</h4>
                      <FormInline tw="items-baseline">
                        <Input
                          type="radio"
                          data-testid="caregiver-required-yes"
                          id="caregiver-required-yes"
                          {...register('filterState.caregiverRequired')}
                          disabled={availableFilters?.includes('caregiverRequired')}
                          value="yes"
                        />
                        <Label htmlFor="caregiver-required-yes" tw="ml-2 font-normal mr-4">
                          Yes
                        </Label>
                        <Input
                          type="radio"
                          data-testid="caregiver-required-no"
                          id="caregiver-required-no"
                          {...register('filterState.caregiverRequired')}
                          disabled={availableFilters?.includes('hasMaterials')}
                          value="no"
                        />
                        <Label htmlFor="caregiver-required-no" tw="ml-2 font-normal">
                          No
                        </Label>
                      </FormInline>
                    </FormGroup>
                  </div>
                </div>
              </form>
            </aside>
          )}
          <ResultContainer isMobile={isMobile}>
            <div data-testid="client-resources__results-wrapper" tw="font-semibold flex justify-between space-y-3">
              {Boolean(data.length) && (
                <div
                  data-testid="client-resources__results__total-results"
                  tw="flex items-center mt-4 sm:mt-0 sm:items-end ml-4 w-full"
                  title="Total results"
                >
                  {!isLoading && `${totalItems.toLocaleString()} Result${totalItems > 1 ? 's' : ''}`}
                  {!isLoading && Boolean(keywordState.length) && ` with "${keywordState}"`}
                </div>
              )}

              {isMobile && (
                <div tw="flex items-center w-full justify-end">
                  <Fragment>
                    <Button
                      data-testid="client-resources__results__filter-overlay-cta"
                      variant="secondary"
                      onClick={() => {
                        window.scrollTo(0, 0);
                        onFilterMobileOverlayOpen();
                      }}
                      type="submit"
                    >
                      <FontAwesomeIcon
                        title="Filter mobile button"
                        data-testid="filter-button__cta"
                        tw="text-indigo-700 mr-2"
                        icon={faSlidersV}
                      />
                      Filter
                    </Button>
                  </Fragment>
                </div>
              )}
              {!isMobile && (
                <div tw="flex justify-end w-full  font-normal text-xs items-center mb-4 text-[12px] md:text-base">
                  {!isLoading && (
                    <Pagination
                      shouldDisplayBackButton={shouldDisplayBackButton}
                      shouldDisplayNextButton={shouldDisplayNextButton}
                      shouldShowPagination={shouldShowPagination}
                      handlePaginationNext={() => handlePaginationClick('next')}
                      handlePaginationBack={() => handlePaginationClick('back')}
                      totalItems={totalItems}
                      totalPages={totalPages}
                      pages={page}
                      data={data}
                    />
                  )}
                </div>
              )}
            </div>
            {isMobile && (
              <div tw="flex mt-6 justify-end mr-12 w-full  font-normal text-xs items-center mb-4 text-[12px] md:text-base">
                {!isLoading && (
                  <Pagination
                    shouldDisplayBackButton={shouldDisplayBackButton}
                    shouldDisplayNextButton={shouldDisplayNextButton}
                    shouldShowPagination={shouldShowPagination}
                    handlePaginationNext={() => handlePaginationClick('next')}
                    handlePaginationBack={() => handlePaginationClick('back')}
                    totalItems={totalItems}
                    totalPages={totalPages}
                    pages={page}
                    data={data}
                  />
                )}
              </div>
            )}

            <div tw="w-full my-6">
              <div tw="flex flex-wrap w-full">
                {isLoading && (
                  <div
                    data-testid="client-resources__loader"
                    tw="flex justify-center items-center w-full space-y-4 flex-col"
                  >
                    <Loader type="ring" />
                  </div>
                )}
                {!isLoading && data?.length === 0 && (
                  <div tw="mt-[10%] ml-auto mr-auto w-full">
                    <div tw="flex justify-center items-center w-full space-y-4 flex-col">
                      <h2 tw="text-center font-semibold text-xl ">We’re sorry, no results were found!</h2>
                      <p tw="text-center w-[60%] ">
                        {watchFilterState?.contentType === 'authored-by-you'
                          ? `LocoOnce you’ve created and assigned a custom Structured Activity within your session documentation, it will appear here. Please try different filters or search different keywords to find results.`
                          : `Our team is working hard to add more content for all core clinical areas. Please try different
                        filters or search different keywords to find results or you create a `}
                        {watchFilterState?.contentType !== 'authored-by-you' && <b>content request</b>}
                      </p>
                    </div>
                  </div>
                )}
                {!isLoading && data?.map(program => <ResourceCard program={program} key={program.id} />)}
              </div>
              <div tw="flex justify-between">
                <div tw="flex justify-end mt-6  w-full font-normal text-xs items-center mb-[64px] text-[12px] md:text-base">
                  {!isLoading && (
                    <Pagination
                      shouldDisplayBackButton={shouldDisplayBackButton}
                      shouldDisplayNextButton={shouldDisplayNextButton}
                      shouldShowPagination={shouldShowPagination}
                      handlePaginationNext={() => handlePaginationClick('next')}
                      handlePaginationBack={() => handlePaginationClick('back')}
                      totalItems={totalItems}
                      totalPages={totalPages}
                      pages={page}
                      data={data}
                    />
                  )}
                </div>
              </div>
            </div>
          </ResultContainer>
        </div>
      </div>
    </>
  );
};

export default ClientResources;
