import { Badge, Card, FormGroup, Label } from '@expressable/ui-library';
import { FC, Fragment } from 'react';

import 'twin.macro';
import { useEligibilityData } from './hooks/use-eligibility-data';
import { Fieldset } from '.';
import { ServicesTypes } from './services-types';
import { Estimates } from './estimates';

interface Props {
  data: any;
}

export const EligibilityResults: FC<Props> = ({ data }) => {
  const eligibility = useEligibilityData(data);

  return (
    <Card>
      <h2 className="text-2xl font-semibold">Eligibility Results</h2>
      <div className="space-y-4">
        <Fieldset legend="" isSection>
          <div className="grid grid-cols-3 gap-4">
            <FormGroup>
              <Label className="text-lg">Status</Label>
              <Badge
                className="text-base max-w-max mt-1"
                variant={eligibility?.insuranceEligibility === 'active' ? 'success' : 'warning'}
              >
                {eligibility.insuranceEligibility ? 'Active' : 'Not Eligible'}
              </Badge>
            </FormGroup>
          </div>
        </Fieldset>

        {/* Plan Details */}
        <Fieldset legend="Plan Details" isSection>
          <div className="grid grid-cols-3 gap-4">
            <FormGroup>
              <Label className="text-base">Plan start</Label>
              <p>{eligibility.planDates.start}</p>
            </FormGroup>
            <FormGroup>
              <Label className="text-base">Plan end</Label>
              <p>{eligibility.planDates.end}</p>
            </FormGroup>
            <FormGroup>
              <Label className="text-base">Eligibility begin</Label>
              <p>{eligibility.planDates.eligibilityBegin}</p>
            </FormGroup>
          </div>
        </Fieldset>

        <FormGroup>
          <Label className="text-base">Is speech therapy covered?</Label>
          <p>
            {eligibility.planStatus.flatMap((statusItem: any) => statusItem.serviceTypeCodes).includes('AF')
              ? 'Yes'
              : 'No'}
          </p>
        </FormGroup>
        <hr className="mt-7" />

        {/* Coverage Basis */}
        <Fieldset legend="Coverage Basis" isSection>
          <div className="grid grid-cols-3 gap-4">
            <FormGroup>
              <Label className="text-base">Code</Label>
              <p>{eligibility.benefits.coverageBasis.code}</p>
            </FormGroup>
            <FormGroup>
              <Label className="text-base">Name</Label>
              <p>{eligibility.benefits.coverageBasis.name}</p>
            </FormGroup>
            <FormGroup>
              <Label className="text-base">In-Plan Network Indicator</Label>
              <p>{eligibility.benefits.coverageBasis.inPlanNetworkIndicator}</p>
            </FormGroup>
          </div>
          <FormGroup className="my-4">
            <Label className="text-base">Service Types</Label>
            <ServicesTypes services={eligibility.benefits.coverageBasis.serviceTypes} />
          </FormGroup>
        </Fieldset>
        <hr />

        <Fieldset legend="Speech Therapy Benefits" isSection>
          <div className="flex flex-col">
            {eligibility.benefits.speechTherapy.map((item: any, index: number) => (
              <Fragment key={index}>
                <div className="grid grid-cols-3 gap-4">
                  <FormGroup>
                    <Label className="text-base">Code</Label>
                    <p>{item.code}</p>
                  </FormGroup>
                  <FormGroup>
                    <Label className="text-base">Name</Label>
                    <p>{item.name} test</p>
                  </FormGroup>
                  <FormGroup>
                    <Label className="text-base">Coverage Level Code</Label>
                    <p>{item.coverageLevelCode ? item.coverageLevelCode : 'N/A'}</p>
                  </FormGroup>
                  <FormGroup>
                    <Label className="text-base">Coverage Level</Label>
                    <p>{item.coverageLevel ? item.coverageLevel : 'N/A'}</p>
                  </FormGroup>
                  <FormGroup>
                    <Label className="text-base">Is Speech Therapy Covered?</Label>
                    <p>{item.serviceTypeCodes.includes('AF') ? 'Yes' : 'No'}</p>
                  </FormGroup>
                  <FormGroup>
                    <Label className="text-base">Service Types</Label>
                    <ServicesTypes services={item.serviceTypes} />
                  </FormGroup>
                  {item.benefitAmount && (
                    <FormGroup>
                      <Label className="text-base">Benefit Amount</Label>
                      <p>{item.benefitAmount}</p>
                    </FormGroup>
                  )}
                  {item.benefitPercent && (
                    <FormGroup>
                      <Label className="text-base">Benefit Percent</Label>
                      <p>{item.benefitPercent}</p>
                    </FormGroup>
                  )}
                  <FormGroup>
                    <Label className="text-base">In-Plan Network Indicator</Label>
                    <p>{item.inPlanNetworkIndicator}</p>
                  </FormGroup>
                  {item.additionalInformation && (
                    <FormGroup>
                      <Label className="text-base">Additional Information</Label>
                      <p>
                        {item.additionalInformation.map((info: { description: string }) => info.description).join(', ')}
                      </p>
                    </FormGroup>
                  )}
                  {item.eligibilityAdditionalInformation && (
                    <FormGroup>
                      <Label className="text-base">Eligibility Additional Information</Label>
                      <p>Code List Qualifier: {item.eligibilityAdditionalInformation.codeListQualifierCode}</p>
                      <p>Industry Code: {item.eligibilityAdditionalInformation.industryCode}</p>
                    </FormGroup>
                  )}
                </div>
                <hr className="my-4" />
              </Fragment>
            ))}
            <Estimates estimates={eligibility?.estimates} />
          </div>
        </Fieldset>
      </div>
    </Card>
  );
};
