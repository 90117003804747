import {
  SingleDatePicker,
  SingleDatePickerShape,
  isInclusivelyBeforeDay as momentIsInclusivelyBeforeDay,
} from 'react-dates';
import dayjs, { Dayjs } from 'dayjs';
import moment from 'moment';
import { useMemo } from 'react';

export type DatePickerProps = Omit<
  SingleDatePickerShape,
  'date' | 'onDateChange' | 'renderMonthElement' | 'isOutsideRange'
> & {
  date: Dayjs | null;
  onDateChange: (date: Dayjs | null) => void;
  isOutsideRange?: ((day: Dayjs) => boolean);
};

const DatePicker = ({ date, onDateChange, isOutsideRange, ...props }: DatePickerProps) => {
  const momentDate = date === null ? null : moment(date.toISOString());
  const momentOnDateChange = useMemo(() => {
    return (momentDate: moment.Moment | null) => {
      const dayjsDate = momentDate === null ? null : dayjs(momentDate.toISOString());
      onDateChange(dayjsDate);
    };
  }, [onDateChange]);
  const momentIsOutsideRange = useMemo(() => {
    if (!isOutsideRange) {
      return isOutsideRange;
    }

    return (day: moment.Moment) => isOutsideRange(dayjs(day.toISOString()));
  }, [isOutsideRange]);

  return (
    <SingleDatePicker
      date={momentDate}
      onDateChange={momentOnDateChange}
      isOutsideRange={momentIsOutsideRange}
      {...props}
    />
  );
};

export default DatePicker;

export const isInclusivelyBeforeDay = (a: Dayjs, b: Dayjs) =>
  momentIsInclusivelyBeforeDay(moment(a.toISOString()), moment(b.toISOString()));
