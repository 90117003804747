import { Card } from '@expressable/ui-library';
import { Form } from 'components/Forms';
import { Unvalidated } from 'domain/form-validation';
import { useCallback, useState } from 'react';
import { DefaultValues } from 'react-hook-form';
import { BillingInformationFormData, billingInformationSchema } from './schema';
import { BillingInformationFormUi } from 'pages/new-client-create/BillingInformation/ui';
import 'twin.macro';
import { FormChangeWatcher } from 'components/Forms/FormChangeWatcher';
import { IBillingInfoInsuranceForm } from 'types';
import { ClientInformationFormData } from '../ClientInformation';
import { StepId } from 'components/therapistMatcher/data/useTherapistMatcher';

type Props = {
  onSubmit: (data: BillingInformationFormData) => void;
  defaultValues?: DefaultValues<Unvalidated<BillingInformationFormData>>;
  onInvalidateStep: () => void;
  isStepCompleted: boolean;
  isPreviousStepCompleted: boolean;
  clientState?: ClientInformationFormData;
  onNavigateToStep: (stepIndex: number) => void;
};

export const BillingInformationForm = ({
  defaultValues,
  onSubmit,
  isStepCompleted,
  isPreviousStepCompleted,
  onInvalidateStep,
  clientState,
  onNavigateToStep,
}: Props): JSX.Element => {
  const [insuranceForm, setInsuranceForm] = useState<IBillingInfoInsuranceForm>();

  const onChangeInvalidate = useCallback(() => {
    if (isStepCompleted) {
      onInvalidateStep();
    }
  }, [isStepCompleted]);

  function handleSubmit(data: BillingInformationFormData): any {
    return onSubmit({
      ...data,
      insuranceContent: { ...insuranceForm } as IBillingInfoInsuranceForm,
    });
  }

  return (
    <Card className="p-8 flex flex-col gap-4">
      <h2 className="font-semibold text-xl">Billing Information</h2>

      {!isStepCompleted && !isPreviousStepCompleted ? (
        <div>
          Please{' '}
          <a
            className="underline hover:no-underline cursor-pointer"
            onClick={() => onNavigateToStep(StepId.clientInfo)}
          >
            confirm client information
          </a>
        </div>
      ) : (
        <Form
          schema={billingInformationSchema}
          defaultValues={defaultValues}
          onSubmit={handleSubmit}
          className="flex flex-col gap-5 items-start"
        >
          <FormChangeWatcher onChange={onChangeInvalidate} />
          <BillingInformationFormUi
            onInsuranceFormChange={result => setInsuranceForm(result)}
            onInvalidateStep={onInvalidateStep}
            clientState={clientState}
          />
        </Form>
      )}
    </Card>
  );
};
