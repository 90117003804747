import React, { useCallback, useEffect } from 'react';
import {
  Button,
  CloseButton,
  FormGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  Textarea,
  presentMutationError,
} from '@expressable/ui-library';
import ModalHeadline from 'components/modal-headline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormGroupTitle from 'components/form-group-title';
import { faFileLines } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import RHFSelect from 'components/RHFSelectBase';
import { ClinicalCareExperienceEntryId, useContentfulEntry } from 'hooks/use-contentful';
import { convertStringArrayToSelectOptions } from 'domain/notes/mapHelpers';
import { useRemoveClientAssignment } from 'hooks/use-client-assignments';
import {
  UnassignAssignmentModalsProps,
  UnassignAssignmentPayload,
  UnassignReasonsContentful,
} from 'domain/clinical-pathways/types';
import 'twin.macro';

export function UnassignLearningPathModal(props: UnassignAssignmentModalsProps) {
  const { isOpen, onClose, clientId, currentAssignment } = props;
  const { data: unassignmentReasonsContentful } = useContentfulEntry<UnassignReasonsContentful>({
    entryId: ClinicalCareExperienceEntryId.UnassignmentReasons,
  });

  const {
    register,
    handleSubmit,
    watch,
    control,
    reset,
    formState: { errors },
  } = useForm<UnassignAssignmentPayload>({
    defaultValues: {
      discontinuationReason: '',
      additionalInformation: '',
    },
  });
  const unassignLearningPathMutation = useRemoveClientAssignment();
  const { mutate: unassignLearningPath } = unassignLearningPathMutation;
  const watchAdditionalText = watch('additionalInformation');
  const selectedCancelationReasonValue = watch('discontinuationReason');

  const onSubmitUnassignment = async () => {
    unassignLearningPath(
      {
        clientId: clientId,
        assignmentId: currentAssignment.assignmentID,
        discontinuationReason: selectedCancelationReasonValue,
        additionalInformation: watchAdditionalText,
      },
      {
        onSuccess: () => {
          {
            onClose();
          }
        },
        onError: presentMutationError,
      },
    );
  };
  const resetModalState = useCallback(() => reset(), []);
  useEffect(resetModalState, [isOpen, unassignLearningPathMutation.isLoading]);

  return (
    <Modal isOpen={isOpen} tw="max-w-lg">
      <ModalContent>
        <ModalHeader>
          <div tw="absolute top-0 right-0 pt-4 pr-4">
            <CloseButton testId="close-unassign-learning-path-modal" onClick={onClose} />
          </div>
        </ModalHeader>
        <ModalBody>
          <React.Fragment>
            <div tw="flex items-center justify-center w-12 h-12 mx-auto bg-indigo-100 rounded-full">
              <FontAwesomeIcon tw="text-2xl text-indigo-700" icon={faFileLines} />
            </div>
            <div tw="my-3 sm:mt-5">
              <ModalHeadline text={`Unassign Learning Path for ${currentAssignment?.assignmentTitle}`} />
              <p tw="text-center text-gray-900">
                This action will unassign the Learning Path from your client&apos;s portal and they will not be able to
                access it. You can always add it back in your session note.
              </p>
              <form tw="mt-5" data-testid="unassign-learning-path-form" onSubmit={handleSubmit(onSubmitUnassignment)}>
                <FormGroup data-testid="reason-for-unassign" tw="mt-8">
                  <FormGroupTitle title="Reason for Unassigning" fontSize="small" fontWeight="semi" spacing="normal" />
                  <RHFSelect
                    name="discontinuationReason"
                    control={control}
                    required
                    options={convertStringArrayToSelectOptions(unassignmentReasonsContentful?.dropdownContent) ?? []}
                    error={errors?.discontinuationReason}
                  />
                </FormGroup>
                <FormGroup tw="mt-2">
                  <div tw="flex flex-row">
                    <FormGroupTitle
                      title="Please add any additional information"
                      fontSize="small"
                      fontWeight="semi"
                      spacing="normal"
                    />
                    <span className="mt-2 ml-2 text-xxs text-gray-400 leading-none">OPTIONAL</span>
                  </div>
                  <Textarea
                    tw="w-full"
                    spacing="normal"
                    rows={3}
                    data-testid="aditional-information"
                    {...register('additionalInformation')}
                    error={errors.additionalInformation}
                  />
                </FormGroup>
                <FormGroup tw="mt-4">
                  <Button
                    tw="mt-3"
                    data-testid="unassign-learning-path-button"
                    variant="primary"
                    fullWidth
                    loading={unassignLearningPathMutation.isLoading}
                  >
                    Unassign Learning Path
                  </Button>
                  <Button
                    tw="mt-3"
                    data-testid="unassign-learning-path-onclose"
                    variant="secondary"
                    fullWidth
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                </FormGroup>
              </form>
            </div>
          </React.Fragment>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
