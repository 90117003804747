import { UseCompletableWizard } from 'hooks/common/useCompletableWizard/types';
import { useCallback, useState } from 'react';

export const useCompletableWizard: UseCompletableWizard = (stepsCount, initialStep = 0) => {
  const [completed, setCompleted] = useState<boolean[]>(new Array(stepsCount).fill(false));

  const [current, setCurrent] = useState(initialStep);

  const onCompleteStep = useCallback((stepIndex: number) => {
    setCompleted(completed => completed.map((v, index) => (index === stepIndex ? true : v)));
  }, []);
  const onInvalidateStep = useCallback((stepIndex: number) => {
    setCompleted(completed => completed.map((v, index) => (index === stepIndex ? false : v)));
  }, []);
  const onNavigateToStep = useCallback(
    (toStepIndex: number, completeCurrent?: boolean) => {
      if (completeCurrent) {
        onCompleteStep(current);
      }
      setCurrent(toStepIndex);
    },
    [current],
  );

  return {
    currentStep: current,
    onCompleteStep,
    onInvalidateStep,
    onNavigateToStep,
    stepsState: completed,
  };
};
