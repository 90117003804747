import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import moment from 'moment';
import { Button, FormGroup, FormInline, Input } from '@expressable/ui-library';
import { CheckEligibilityPayload, useCheckEligibility } from 'hooks/use-clients-eligibility';
import { Form, FormField } from 'components/Forms';
import { ControlledFormField } from 'components/Forms/FormFieldControlled';
import DayJsDatePicker from 'components/Forms/DayjsPicker';
import { DependentInitialState } from 'domain/eligibility/constants';
import { ManualEligibilityForm } from 'domain/eligibility/types';
import { Fieldset, Dependents } from './components';
import { EligibilityFormSchema } from './components/schema';
import { useSetDependents } from './components/hooks/use-set-dependents';

import 'react-datepicker/dist/react-datepicker.css';
import 'twin.macro';
import { EligibilityResults } from './components/eligibility-results';

const convertEligibilityFormToPayload = (data: ManualEligibilityForm): CheckEligibilityPayload => {
  return {
    npi: data.npi,
    payerID: data.payerID,
    dateOfService: data.dateOfService,
    subscriber: {
      memberID: data.memberID,
      firstName: data.firstName,
      lastName: data.lastName,
      middleName: data.middleName,
      dateOfBirth: data.dateOfBirth,
    },
    dependents: data.dependents?.length ? data.dependents : undefined,
  };
};

const mapSearchParams = (searchParams: URLSearchParams): Partial<ManualEligibilityForm> => {
  const getParam = (key: string): string | undefined => searchParams.get(key) || undefined;

  const dependents: Partial<{
    memberID: string;
    dateOfBirth?: string;
    firstName: string;
    lastName: string;
    middleName?: string | null;
  }>[] = [];

  // Iterate over all search parameters to extract dependents
  searchParams.forEach((value, key) => {
    if (key.startsWith('dependent')) {
      const parts = key.split('.');
      const index = parts[0]; // "dependent1", "dependent2", etc.
      const field = parts[1]; // "memberID", "dateOfBirth", etc.

      // Initialize the dependent object if it doesn't exist
      if (!dependents[parseInt(index.replace('dependent', '')) - 1]) {
        dependents[parseInt(index.replace('dependent', '')) - 1] = {};
      }

      // Assign the value to the corresponding field
      dependents[parseInt(index.replace('dependent', '')) - 1][field as keyof typeof dependents[0]] = value;
    }
  });

  return {
    npi: getParam('npi'),
    payerID: getParam('payerID'),
    memberID: getParam('memberID'),
    firstName: getParam('firstName'),
    lastName: getParam('lastName'),
    middleName: getParam('middleName'),
    dateOfBirth: searchParams.has('dateOfBirth') ? moment(getParam('dateOfBirth')).toDate() : undefined,
    dateOfService: searchParams.has('dateOfService')
      ? moment(getParam('dateOfService')).toDate()
      : moment().add(1, 'day').toDate(),
    //@ts-ignore - dateOfBirth is a string, but we need to convert it to a Date object
    dependents: dependents.filter(Boolean).map(e => ({ ...e, dateOfBirth: new Date(e.dateOfBirth) })), // Filter out any undefined objects (if any)
  };
};

const EligibilityForm: React.FC = () => {
  const [response, setResponse] = React.useState<any>(null);
  const [responseErrors, setResponseErrors] = React.useState<string | null>(null);
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const defaultValues = useMemo(
    () => ({
      ...EligibilityFormSchema.getDefault(),
      ...{
        dependents: [{ ...DependentInitialState }],
        dateOfService: moment(dayjs().add(1, 'day').toDate()),
      },
      ...mapSearchParams(params),
    }),
    [params],
  );

  const form = useForm<ManualEligibilityForm>({
    defaultValues: defaultValues,
    resolver: yupResolver(EligibilityFormSchema),
  });

  const { mutateAsync: checkEligibility, isLoading,  } = useCheckEligibility();

  const onSubmit: SubmitHandler<FieldValues> = async data => {
    try {
      setResponseErrors(null);
      clearErrors();
      const response = await checkEligibility(convertEligibilityFormToPayload(data as ManualEligibilityForm));

      setResponse(response);
    } catch (error: any) {
      setResponseErrors(error?.response?.data?.message ?? 'An error occurred while processing the eligibility check. Please try again.');
    }
  };

  const { reset, clearErrors } = form;
  const { errors } = form.formState;
  useSetDependents(form, defaultValues);

  return (
    <div className="px-8 my-10">
      <Form form={form as any} onSubmit={onSubmit} schema={EligibilityFormSchema} className="space-y-4">
        <Fieldset legend="Payer & Subscriber Information" isSection>
          <FormInline className="space-x-4">
            <FormField
              containerClass="w-full"
              className="w-full"
              id="payerID"
              name="payerID"
              label="Payer ID"
              component={Input}
            />
            <FormField
              id="npi"
              name="npi"
              label="Provider NPI"
              component={Input}
              required
              containerClass="w-full"
              className="w-full"
            />
            <ControlledFormField
              containerClass="w-full"
              id="dateOfService"
              name="dateOfService"
              label="Date of Service"
              component={DayJsDatePicker}
              optional={false}
              allowPastDates={false}
              placeholder="MM/DD/YYYY"
            />
            <FormField
              id="memberID"
              name="memberID"
              label="Member ID"
              containerClass="w-full"
              component={Input}
              optional={false}
              className="w-full"
            />
          </FormInline>
          <FormInline className="space-x-4">
            <FormField
              containerClass="w-full"
              className="w-full"
              id="firstName"
              name="firstName"
              label="Subscriber First Name"
              component={Input}
            />
            <FormField
              id="middleName"
              containerClass="w-full"
              className="w-full"
              name="middleName"
              label="Subscriber Middle Name"
              component={Input}
              optional={true}
            />
            <FormField
              containerClass="w-full"
              className="w-full"
              id="lastName"
              label="Subscriber Last Name"
              name="lastName"
              component={Input}
            />
            <ControlledFormField
              containerClass="w-full"
              id="dateOfBirth"
              name="dateOfBirth"
              label="Subscriber Date of Birth"
              component={DayJsDatePicker}
              optional
              allowPastDates
              placeholder="MM/DD/YYYY"
            />

          </FormInline>
        </Fieldset>

        <Fieldset legend="Dependent Information" isSection>
          <Dependents />
          <div className="my-2">
            {errors?.dependents && (
              <div className="text-red-500 italic text-xs">
                {
                  // dev-note: seems that react hook form is not able to handle the error message from the nested fields
                  // @ts-ignore
                  errors.dependents?.message
                }
              </div>
            )}
          </div>
        </Fieldset>

        <FormGroup>
          <FormInline className="space-x-2 my-2">
            <Button
              loading={isLoading}
              disabled={Boolean(isLoading)}
              variant="primary"
              type="submit"
              className="rounded-md"
            >
              Check Eligibility
            </Button>
            <Button
              variant="secondary"
              type="button"
              onClick={() => {
                reset({
                  ...EligibilityFormSchema.getDefault(),
                  dependents: [],
                  dateOfService: moment(dayjs().add(1, 'day').toDate()),
                }, {keepErrors: false});

                setResponse(null);
                clearErrors();
                setResponseErrors(null);
              }}
              className="rounded-md"
            >
              Clear Form
            </Button>
          </FormInline>
        </FormGroup>
      </Form>

      {responseErrors && (
        <div className="text-red-500 italic text-xs">
          {responseErrors ?? 'An error occurred while processing the eligibility check. Please try again.'}
        </div>
      )}

      <hr className="my-10" />
      {!response && (
        <div className="mt-10">
          <span className="text-gray-400">
            <strong>Complete the eligibility form to see results</strong>
          </span>
        </div>
      )}
      {response && (
        <div className="space-y-4">
          <EligibilityResults data={response} />
        </div>
      )}
    </div>
  );
};

export default EligibilityForm;
