/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import React, { useEffect, useState } from 'react';
import { Card, Container, Input, Label, Select, Button, Link, Loader, useDisclosure, Textarea } from '@expressable/ui-library';
import { Controller, useForm, useWatch } from 'react-hook-form';
import tw from 'twin.macro';
import {
  IBillingInfoForm,
  BillingTypeEnum,
  MembershipStatusEnum,
  PromptPayEnum,
  MembershipStatus,
  BillingEntityType,
  ICompleteClientInformation,
} from 'types';
import { IBillingInfo, useBillingInfo, useCreateBillingInfo } from 'hooks/use-billing-information';
import { onlyLettersSpecialVowelsWhiteSpacesValidator, isValidStripeId } from 'utils/helpers';
import pricingFeeForServiceOptions from 'utils/pricing-fee-for-service-options';
import stripeAccountTypesOptions from 'utils/stripe-account-type-options';
import pricingMonthlyMembershipOptions from 'utils/pricing-monthly-membership-options';
import 'react-dates/initialize';
import usePermissions from 'hooks/use-permissions';
import BillingInfoReadOnly from './billing-info-read-only';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { AddCreditModal } from 'components/add-credit-modal';
import { RemoveCreditModal } from 'components/delete-credit-modal';
import { GetCreditsPayload, useAddCredits, useDeleteCredits, useGetCredits } from 'hooks/use-credits';
import { handleArrowKeyDown } from './client-notes/note-form-content/session-note-form-content';
import { InputNumberStyled } from 'components/edit-therapist-info-form';
import { DeleteReferringProviderModal } from './delete-referring-provider-modal';
import { ReferringProviderTypeEnum, REFERRING_PROVIDER_INITIAL_STATE } from 'domain/billing-information/constants';
import { useFlags } from 'launchdarkly-react-client-sdk';
import BillingInfoInsuranceForm, {
  InsuranceFormResult,
  convertInsuranceFieldsToInsurance,
} from './billing-info-insurance-form';

const FormGroup = tw.div`mb-0`;
const FormInline = tw.div`md:flex`;

// eslint-disable-next-line
const findAutomaticPaymentPricingOptionMonthlySubscription = (data: any) => {
  if (data?.selfPay?.automaticPayments?.pricingMonthlyMembership) {
    const resultOption = pricingMonthlyMembershipOptions.find(pricingMonthlyMembershipOption => {
      if (data?.selfPay?.automaticPayments?.pricingMonthlyMembership) {
        return (
          parseInt(pricingMonthlyMembershipOption.value) ===
          data?.selfPay?.automaticPayments?.pricingMonthlyMembership?.price
        );
      }
    });

    return resultOption ?? pricingMonthlyMembershipOptions[0];
  }

  return pricingFeeForServiceOptions[0];
};

const convertBillingInfoToFormFields = (data: any): IBillingInfoForm => {
  const membershipStatus: MembershipStatus =
    (Boolean(data?.selfPay?.promptPay === 'automatic-payments') &&
      data?.selfPay?.automaticPayments?.membershipStatus) ||
    '';

  const formFields: IBillingInfoForm = {
    ...data,
    billingNotes: data?.billingNotes ?? '',
    automaticBillingEnabled: data?.automaticBillingEnabled ? 'yes' : 'no',
    stripeCustomerID: data?.stripeCustomerID ?? '',
    stripeAccountID: data?.stripeAccountID ?? '',
    billingType: data?.billingType ?? '',
    referringProvider: {
      ...data?.referringProvider!,
      firstName: data?.referringProvider?.firstName,
      lastName: data?.referringProvider?.lastName,
      npi: data?.referringProvider?.npi,
      taxonomyCode: data?.referringProvider?.taxonomyCode,
      organizationName: data?.referringProvider?.organizationName,
      referringType: data?.referringProvider?.referringType,
    },
    promptPay: data?.selfPay?.promptPay ?? '',
    membershipStatus: membershipStatus,
    automaticPaymentsMonthlyMembership: findAutomaticPaymentPricingOptionMonthlySubscription(data),
    automaticPaymentsFeeForService: membershipStatus === 'fee-for-service' && {
      evaluationPrice: data?.selfPay?.automaticPayments?.pricingForService?.evaluationPrice,
      sessionPrice: data?.selfPay?.automaticPayments?.pricingForService?.sessionPrice,
    },
    exemptFromPatientResponsibility: data?.exemptFromPatientResponsibility ? 'yes' : 'no' ?? 'no',
  };
  return formFields;
};

const convertPricingMonthlyForServiceOptionsToBillingInformation = (data: IBillingInfoForm) => {
  const result = pricingMonthlyMembershipOptions.find(item => {
    return Number(item.value) === Number(data.automaticPaymentsMonthlyMembership.value);
  });

  return { price: Number(result?.value), isLegacyPrice: result?.isLegacyPrice };
};

export const convertFormFieldsToBillingInfo = (data: IBillingInfoForm, savedBillingInfoData?: IBillingInfo) => {
  let billingInfo: IBillingInfo;
  if (data.billingType === BillingTypeEnum.selfPay) {
    billingInfo = {
      billingNotes: data.billingNotes,
      automaticBillingEnabled: data.automaticBillingEnabled === 'yes' ? true : false,
      exemptFromPatientResponsibility: data.exemptFromPatientResponsibility === 'yes' ? true : false,
      automaticCoInsuranceCollection: true,
      billingType: data.billingType,
      stripeCustomerID: data.exemptFromPatientResponsibility === 'yes' ? null : data.stripeCustomerID,
      stripeAccountID: data.stripeAccountID.value,
      promptPay: data.promptPay,
      membershipStatus: data.membershipStatus,
      referringProvider: data?.referringProvider
        ? {
            firstName: data.referringProvider.firstName,
            lastName: data.referringProvider.lastName,
            npi: data.referringProvider.npi,
            taxonomyCode: data?.referringProvider?.taxonomyCode ? data?.referringProvider?.taxonomyCode : undefined,
            organizationName: data.referringProvider.organizationName,

            referringType: data.referringProvider.referringType,
          }
        : undefined,
    };

    if (data.promptPay === 'automatic-payments') {
      if (data.membershipStatus === 'fee-for-service') {
        billingInfo.selfPay = {
          promptPay: data.promptPay,
          automaticPayments: {
            membershipStatus: data.membershipStatus,
            pricingForService: {
              evaluationPrice: data.automaticPaymentsFeeForService?.evaluationPrice,
              sessionPrice: data.automaticPaymentsFeeForService?.sessionPrice,
            },
          },
        };
      }
      if (data.membershipStatus === 'monthly-membership') {
        billingInfo.selfPay = {
          promptPay: data.promptPay,
          automaticPayments: {
            membershipStatus: data.membershipStatus,
            pricingMonthlyMembership: {
              ...convertPricingMonthlyForServiceOptionsToBillingInformation(data),
            },
          },
        };
      }
    }
  } else {
    const primaryInsurance = convertInsuranceFieldsToInsurance(
      data.primaryInsurance,
      savedBillingInfoData?.primaryInsurance,
    );
    const secondaryInsurance = data.secondaryInsurance
      ? convertInsuranceFieldsToInsurance(data.secondaryInsurance, savedBillingInfoData?.secondaryInsurance)
      : undefined;
    billingInfo = {
      ...data,
      networkStatus: data?.networkStatus?.value ?? 'In-Network',
      stripeCustomerID: data.exemptFromPatientResponsibility === 'yes' ? null : data.stripeCustomerID,
      billingType: data?.exemptFromPatientResponsibility === 'yes' ? BillingTypeEnum.insurance : data?.billingType,
      automaticBillingEnabled: data.automaticBillingEnabled === 'yes' ? true : false,
      exemptFromPatientResponsibility: data.exemptFromPatientResponsibility === 'yes' ? true : false,
      automaticCoInsuranceCollection: data.automaticCoInsuranceCollection === 'yes' ? true : false,
      stripeAccountID: data.stripeAccountID?.value,
      primaryInsurance,
      secondaryInsurance,
      insuranceAuthorizations: {
        evaluationAuthStatus: data?.insuranceAuthorizations?.evaluationAuthStatus.value,
        sessionAuthStatus: data?.insuranceAuthorizations?.sessionAuthStatus.value,
        visitLimits: data?.insuranceAuthorizations?.visitLimits === 'yes' ? true : false,
        dateLimits: data?.insuranceAuthorizations?.dateLimits === 'yes' ? true : false,
        reEvaluationRequired: data?.insuranceAuthorizations?.reEvaluationRequired === 'yes' ? true : false,
        reEvaluationDueBy:
          data?.insuranceAuthorizations?.reEvaluationRequired === 'yes'
            ? moment(data?.insuranceAuthorizations?.reEvaluationDueBy).format('YYYY-MM-DD')
            : undefined,
        remainingVisitsLeft:
          data?.insuranceAuthorizations?.visitLimits === 'yes'
            ? Number(data?.insuranceAuthorizations?.remainingVisitsLeft)
            : undefined,
        visitsCompletedBy:
          data?.insuranceAuthorizations?.dateLimits === 'yes'
            ? moment(data?.insuranceAuthorizations?.visitsCompletedBy).format('YYYY-MM-DD')
            : undefined,
      },
      referringProvider: data?.referringProvider
        ? {
            firstName: data.referringProvider.firstName,
            lastName: data.referringProvider.lastName,
            npi: data.referringProvider.npi,
            taxonomyCode: data?.referringProvider?.taxonomyCode ? data?.referringProvider?.taxonomyCode : undefined,
            organizationName: data.referringProvider.organizationName,
            referringType: data.referringProvider.referringType,
          }
        : undefined,
    };
  }

  return billingInfo;
};

export interface BillingInfoFormProps {
  clientId: string;
  clientData: ICompleteClientInformation;
}

export type BillingInfoCreditsProps = {
  isCreditsLoading: boolean;
  isErrorFromCredits: boolean;
  creditsData?: GetCreditsPayload;
  isAdminOrClinicalManager: boolean;
  clientId: string;
};

const BillingInfoCredits = ({
  clientId,
  isCreditsLoading,
  isErrorFromCredits,
  creditsData,
  isAdminOrClinicalManager,
}: BillingInfoCreditsProps) => {
  const { mutate: addCreditsToClient, isLoading: loadingAddCredits } = useAddCredits();
  const { mutate: removeCreditsToClient, isLoading: loadingRemoveCredits } = useDeleteCredits();

  const onAddCreditHandler = async () => {
    await addCreditsToClient(
      { clientId, creditsDto: { credits: 1 } },
      {
        onSuccess: () => onCreditsModalClose(),
      },
    );
  };
  const onRemoveCreditHandler = async () => {
    await removeCreditsToClient(
      { clientId, creditsDto: { credits: 1 } },
      {
        onSuccess: () => onRemoveCreditsModalClose(),
      },
    );
  };
  const { isOpen: isCreditsModalOpen, onClose: onCreditsModalClose, onOpen: onCreditsModalOpen } = useDisclosure();
  const {
    isOpen: isRemoveCreditsModalOpen,
    onClose: onRemoveCreditsModalClose,
    onOpen: onRemoveCreditsModalOpen,
  } = useDisclosure();

  return (
    <>
      <AddCreditModal
        isLoading={loadingAddCredits}
        isOpen={isCreditsModalOpen}
        onClose={onCreditsModalClose}
        onSave={onAddCreditHandler}
      />
      <RemoveCreditModal
        isLoading={loadingRemoveCredits}
        isOpen={isRemoveCreditsModalOpen}
        onClose={onRemoveCreditsModalClose}
        onSave={onRemoveCreditHandler}
      />

      <Container tw="relative pl-0" size="large">
        <Card tw="mb-4 mt-10 px-8">
          <div tw="text-2xl font-semibold mb-7 mt-4">Credits</div>
          {isCreditsLoading && <Loader type="ring" />}

          {isErrorFromCredits && (
            <FormGroup tw="w-full sm:w-1/2 md:w-1/3">
              <Label htmlFor="stripeCustomerID">
                <FontAwesomeIcon icon={faExclamationTriangle} tw="text-red-500 mr-2" />
                There was an error while loading credits
              </Label>
            </FormGroup>
          )}
          {!isCreditsLoading && !isErrorFromCredits && (
            <FormGroup tw="mb-4 mt-2">
              <h4 tw="mb-2 text-sm font-semibold">Credits Remaining</h4>
              <Label data-testid="billing-info-form-remaining-credits" font="normal" htmlFor="creditsRemaining">
                {creditsData?.credits}
              </Label>
              {isAdminOrClinicalManager && (
                <div tw="flex flex-row mt-6">
                  <span
                    data-testid="billing-info-form-add-credit"
                    tw="flex items-baseline items-center text-sm text-indigo-600 cursor-pointer mr-4"
                    onClick={() => onCreditsModalOpen()}
                  >
                    <FontAwesomeIcon tw="text-base mr-2" icon="plus-circle" />
                    Add Credit
                  </span>
                  {creditsData && creditsData?.credits > 0 ? (
                    <span
                      data-testid="billing-info-form-delete-credit"
                      tw="flex items-baseline items-center text-sm text-red-600 cursor-pointer"
                      onClick={() => onRemoveCreditsModalOpen()}
                    >
                      <FontAwesomeIcon tw="text-base mr-2" icon={['far', 'trash']} />
                      Remove Credit
                    </span>
                  ) : (
                    <span
                      data-testid="billing-info-form-delete-credit"
                      tw="flex items-baseline items-center text-sm text-red-300"
                    >
                      <FontAwesomeIcon tw="text-base mr-2" icon={['far', 'trash']} />
                      Remove Credit
                    </span>
                  )}
                </div>
              )}
            </FormGroup>
          )}
        </Card>
      </Container>
    </>
  );
};

const BillingInfoForm = (props: BillingInfoFormProps) => {
  const { clientId, clientData } = props;
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    getValues,
    setValue,
    watch,
    reset,
    clearErrors,
  } = useForm<IBillingInfoForm>({
    mode: 'all',
    defaultValues: {
      automaticBillingEnabled: 'yes',
      exemptFromPatientResponsibility: 'no',
      billingType: BillingTypeEnum.selfPay,
    },
  });

  const { data: billingInfoData, isLoading, isError, refetch } = useBillingInfo(clientId);
  const { mutate: createBillingInfo } = useCreateBillingInfo();
  const { isAdminOrClinicalManager, isAdmin } = usePermissions();

  const { data: creditsData, isLoading: isCreditsLoading, isError: isErrorFromCredits } = useGetCredits(clientId);

  const {
    isOpen: DeleteReferringProviderModalIsOpen,
    onClose: DeleteReferringProviderOnClose,
    onOpen: DeleteReferringProviderOnOpen,
  } = useDisclosure();

  const [billingInfoForm, setBillingInfoForm] = useState<IBillingInfoForm | null>(null);
  const [insuranceForm, setInsuranceForm] = useState<InsuranceFormResult>();

  const [showReferringProvider, setShowReferringProvider] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [deleteReferringProvider, setDeleteReferringProvider] = useState<boolean>(false);
  const { cueAutoBilling } = useFlags();

  const watchExemptFromPatientResponsibility = watch('exemptFromPatientResponsibility');

  const watchBillingType = useWatch({ control, name: 'billingType', defaultValue: BillingTypeEnum.selfPay });
  const watchPromptPay = useWatch({ control, name: 'promptPay', defaultValue: PromptPayEnum.automaticPayments });

  const watchMembershipStatus = useWatch({
    control,
    name: 'membershipStatus',
    defaultValue: MembershipStatusEnum.feeForService,
  });

  const watchStripeCustomerID = useWatch({ control, name: 'stripeCustomerID', defaultValue: '' });
  const watchAccountId: BillingEntityType = useWatch({ control, name: 'stripeAccountID.label' });
  const watchReferringProviderType = useWatch({
    control,
    name: 'referringProvider.referringType',
  });

  useWatch({ control, name: ['automaticPaymentsMonthlyMembership'] });
  const monthlyPriceValueSelected = getValues('automaticPaymentsMonthlyMembership')?.value;

  const submitBillingInfo = (data: IBillingInfoForm) => {
    const payload = {
      clientId,
      billingInfo: convertFormFieldsToBillingInfo({ ...data, ...insuranceForm?.fields }, billingInfoData),
    };

    if (payload.billingInfo.referringProvider && deleteReferringProvider) {
      payload.billingInfo.referringProvider = payload.billingInfo.referringProvider
        ? undefined
        : payload.billingInfo.referringProvider;
      reset(
        {
          ...getValues(),
          referringProvider: REFERRING_PROVIDER_INITIAL_STATE,
        },
        {
          keepErrors: false,
        },
      );
      setShowReferringProvider(false);
    }
    if (!showReferringProvider) {
      payload.billingInfo.referringProvider = undefined;
    }

    clearErrors();

    setIsSubmitting(true);
    createBillingInfo(payload, {
      onSuccess: () => {
        setIsSubmitting(false);
        DeleteReferringProviderOnClose();
        refetch();
      },
      onError: () => {
        setIsSubmitting(false);
      },
    });
  };

  useEffect(() => {
    if (!isLoading) {
      setBillingInfoForm(convertBillingInfoToFormFields(billingInfoData));
    }
  }, [isLoading]);

  useEffect(() => {
    if (billingInfoForm) {
      setValue('billingNotes', billingInfoForm?.billingNotes ?? '');
      if (billingInfoForm?.stripeAccountID) {
        const foundOptions = stripeAccountTypesOptions.find(accountID => {
          return String(accountID.value) === String(billingInfoForm?.stripeAccountID);
        });
        setValue('stripeAccountID', foundOptions ?? undefined);
      }

      // if exempt from patient responsibility is true
      // stripe customer id is not required and we set it to null
      if (billingInfoForm?.exemptFromPatientResponsibility === 'yes') setValue('stripeCustomerID', '');
      else setValue('stripeCustomerID', billingInfoForm?.stripeCustomerID ?? '');

      setValue('billingType', billingInfoForm?.billingType ?? BillingTypeEnum.selfPay);

      setValue('promptPay', billingInfoForm?.promptPay ?? PromptPayEnum.none);
      setValue('membershipStatus', billingInfoForm?.membershipStatus ?? MembershipStatusEnum.feeForService);
      setValue('automaticBillingEnabled', billingInfoData?.automaticBillingEnabled ?? 'yes' ? 'yes' : 'no');

      setValue('exemptFromPatientResponsibility', billingInfoData?.exemptFromPatientResponsibility ? 'yes' : 'no');

      if (billingInfoForm?.exemptFromPatientResponsibility) {
        setValue('billingType', BillingTypeEnum.insurance);
      }

      setShowReferringProvider(!!billingInfoForm?.referringProvider.npi);

      const currentValues = getValues();
      reset(currentValues, { keepValues: true, keepDefaultValues: true });
    }
  }, [billingInfoForm]); // if billingInfoForm changed, billingInfoData also did previously.

  useEffect(() => {
    if (billingInfoForm) {
      if (watchExemptFromPatientResponsibility === 'yes') {
        setValue('stripeCustomerID', '');
        setValue('billingType', BillingTypeEnum.insurance);
        setValue('promptPay', billingInfoForm?.promptPay ?? PromptPayEnum.none);
      } else {
        setValue('billingType', billingInfoForm?.billingType ?? BillingTypeEnum.selfPay);
        setValue('stripeCustomerID', billingInfoForm?.stripeCustomerID ?? '');
      }
    }
  }, [billingInfoForm, watchExemptFromPatientResponsibility]);

  useEffect(() => {
    if (billingInfoForm && showReferringProvider) {
      if (billingInfoForm?.referringProvider.referringType)
        setValue('referringProvider.referringType', billingInfoForm?.referringProvider.referringType);
      else setValue('referringProvider.referringType', ReferringProviderTypeEnum.individual);
    }
  }, [billingInfoForm, showReferringProvider]);

  useEffect(() => {
    if (watchMembershipStatus === 'fee-for-service') {
      if (billingInfoForm?.automaticPaymentsFeeForService) {
        setValue('automaticPaymentsFeeForService', billingInfoForm.automaticPaymentsFeeForService);
      }
    }
    if (watchMembershipStatus === 'monthly-membership') {
      if (billingInfoForm?.automaticPaymentsMonthlyMembership) {
        const foundOptions = pricingMonthlyMembershipOptions.find(item => {
          if (billingInfoForm?.automaticPaymentsMonthlyMembership.value === item.value) {
            return Number(item.value) === Number(billingInfoForm?.automaticPaymentsMonthlyMembership.value);
          }
        });
        setValue('automaticPaymentsMonthlyMembership', foundOptions ?? pricingMonthlyMembershipOptions[0]);
      }
    }
  }, [billingInfoForm, watchMembershipStatus]);

  const isInsurance = ['STC'].includes(watchAccountId);

  useEffect(() => {
    if (watchExemptFromPatientResponsibility === 'no') {
      if (isInsurance) {
        setValue('billingType', BillingTypeEnum.insurance);
      } else {
        setValue('billingType', billingInfoForm?.billingType ?? '');
        setValue('promptPay', billingInfoForm?.promptPay ?? '');
        setValue('membershipStatus', billingInfoForm?.membershipStatus ?? '');
      }
    }
  }, [billingInfoForm, watchAccountId, watchExemptFromPatientResponsibility]);

  useEffect(() => {
    if (!DeleteReferringProviderModalIsOpen) {
      setDeleteReferringProvider(false);
    }
  }, [DeleteReferringProviderModalIsOpen]);

  if (isLoading) {
    return (
      <div tw="flex-1 text-center py-20">
        <Loader type="ring" />
      </div>
    );
  }

  if (isError) {
    return (
      <Container tw="relative pl-0" size="large">
        <Card tw="mb-4 px-8">
          <div tw="text-2xl font-semibold mb-7 mt-4">Billing Information</div>
          <FormGroup tw="w-full sm:w-1/2 md:w-1/3">
            <Label htmlFor="stripeCustomerID">
              <FontAwesomeIcon icon={faExclamationTriangle} tw="text-red-500 mr-2" />
              There was an error while loading this billing information
            </Label>
          </FormGroup>
        </Card>
      </Container>
    );
  }

  const onConfirmDeleteProvider = () => {
    if (billingInfoData?.referringProvider?.npi) {
      DeleteReferringProviderOnOpen();
      setDeleteReferringProvider(true);
    } else {
      reset(
        {
          ...getValues(),
          referringProvider: REFERRING_PROVIDER_INITIAL_STATE,
        },
        { keepErrors: false },
      );
      setShowReferringProvider(false);
    }
  };

  if (isAdminOrClinicalManager && !isLoading && !isError) {
    return (
      <React.Fragment>
        <div data-testid="billing-info">
          <Container tw="relative pl-0" size="large">
            <Card tw="mb-4 px-8">
              <div tw="text-2xl font-semibold mb-7 mt-4">Billing Information</div>
              <form onSubmit={handleSubmit(submitBillingInfo)}>
                <div tw="space-y-6">
                  <FormGroup>
                    <h4 tw="mb-2 text-sm font-semibold">Billing Notes</h4>
                    <FormInline tw="flex items-baseline mr-7 mb-[-16px]">
                      <Textarea
                        id="billing-notes"
                        data-testid="billing-notes"
                        {...register('billingNotes')}
                        tw="w-1/3 mb-0"
                        readOnly={!isAdmin}
                      />
                    </FormInline>
                  </FormGroup>
                  {cueAutoBilling && (
                    <FormGroup>
                      <h4 tw="mb-2 text-sm font-semibold">Automatic Billing</h4>
                      <div tw="flex flex-row max-h-[20px]">
                        <FormInline tw="flex items-baseline mr-7">
                          <Input
                            type="radio"
                            id="automatic-billing-yes"
                            data-testid="automatic-billing-yes"
                            {...register('automaticBillingEnabled')}
                            value="yes"
                          />
                          <Label font="normal" htmlFor="automatic-billing-yes" tw="ml-2">
                            Yes
                          </Label>
                        </FormInline>

                        <FormInline tw="flex items-baseline">
                          <Input
                            type="radio"
                            id="automatic-billing-no"
                            data-testid="automatic-billing-no"
                            {...register('automaticBillingEnabled')}
                            value="no"
                          />
                          <Label font="normal" htmlFor="automatic-billing-no" tw="ml-2">
                            No
                          </Label>
                        </FormInline>
                      </div>
                    </FormGroup>
                  )}
                  <FormGroup tw="mb-5 mt-5">
                    <h4 tw="mb-2 text-sm font-semibold">
                      Exempt from Patient Responsibility
                      <span tw="text-gray-400 ml-3 font-normal hidden md:inline-block">
                        Clients exempt from patient responsibility will not be charged copays, coinsurance, or
                        attendance-related fees.
                      </span>
                    </h4>
                    <div tw="flex flex-row max-h-[20px]">
                      <FormInline tw="flex items-baseline mr-7">
                        <Input
                          type="radio"
                          id="exemptFromPatientResponsibility-yes"
                          data-testid="exemptFromPatientResponsibility-yes"
                          {...register('exemptFromPatientResponsibility')}
                          value="yes"
                        />
                        <Label font="normal" htmlFor="exemptFromPatientResponsibility-yes" tw="ml-2">
                          Yes
                        </Label>
                      </FormInline>
                      <FormInline tw="flex items-baseline">
                        <Input
                          type="radio"
                          id="exemptFromPatientResponsibility-no"
                          data-testid="exemptFromPatientResponsibility-no"
                          {...register('exemptFromPatientResponsibility')}
                          value="no"
                        />
                        <Label font="normal" htmlFor="exemptFromPatientResponsibility-no" tw="ml-2">
                          No
                        </Label>
                      </FormInline>
                    </div>
                  </FormGroup>
                  <FormGroup tw="mb-4 mt-5 w-full sm:w-80 md:w-80">
                    <h4 tw="mb-2 text-sm font-semibold">Entity</h4>
                    <div data-testid="stripeAccountID">
                      <Controller
                        name="stripeAccountID"
                        data-testid="stripeAccountID"
                        control={control}
                        defaultValue={billingInfoForm?.stripeAccountID}
                        render={({ field }) => (
                          <Select {...field} options={stripeAccountTypesOptions} placeholder="Select..." />
                        )}
                        rules={{ required: true }}
                      />
                    </div>
                    {errors.stripeAccountID && (
                      <span tw="text-red-500 text-xs italic flex -mt-5">Please fill out this field</span>
                    )}
                  </FormGroup>
                  {!watchExemptFromPatientResponsibility ||
                    (watchExemptFromPatientResponsibility === 'no' && (
                      <FormGroup tw="w-full sm:w-1/2 md:w-1/3">
                        <Label htmlFor="stripeCustomerID">Stripe Customer ID</Label>
                        <Input
                          type="text"
                          data-testid="billing-info-form-stripe-customer-id"
                          id="stripeCustomerID"
                          tw="w-full mt-3"
                          {...register('stripeCustomerID', {
                            required: true,
                            validate: { isValidStripeId },
                          })}
                          placeholder="cus_LK5N0lG7pf3XhB"
                          defaultValue={billingInfoForm?.stripeCustomerID}
                          error={errors.stripeCustomerID}
                          spacing="small"
                        />
                        {!Boolean(errors.stripeCustomerID) && watchStripeCustomerID && (
                          <Link
                            to={{ pathname: `https://dashboard.stripe.com/customers/${watchStripeCustomerID}` }}
                            target="_blank"
                            tw="text-sm"
                          >
                            View in Stripe
                          </Link>
                        )}
                      </FormGroup>
                    ))}
                  {isAdminOrClinicalManager && (
                    <FormGroup tw="mb-5 mt-5">
                      <h4 tw="mb-2 text-sm font-semibold">Billing Type</h4>
                      <div tw="flex flex-row max-h-[20px]">
                        <FormInline tw="flex items-baseline mr-7">
                          <Input
                            type="radio"
                            id="billingTypeSelfPay"
                            data-testid="billingTypeSelfPay"
                            {...register('billingType', {
                              required: watchExemptFromPatientResponsibility === 'yes' ? false : true,
                              shouldUnregister: false,
                            })}
                            value={BillingTypeEnum.selfPay}
                            css={[
                              watchExemptFromPatientResponsibility === 'yes'
                                ? tw`text-gray-800 opacity-50 pointer-events-none`
                                : tw``,
                            ]}
                            readOnly={Boolean(watchExemptFromPatientResponsibility === 'yes')}
                          />
                          <Label
                            font="normal"
                            htmlFor="billingTypeSelfPay"
                            className={`ml-2 ${
                              watchExemptFromPatientResponsibility === 'yes' ? 'pointer-events-none' : ''
                            }`}
                          >
                            Self-pay
                          </Label>
                        </FormInline>

                        <FormInline tw="flex items-baseline">
                          <Input
                            type="radio"
                            id="billingTypeInsurance"
                            data-testid="billingTypeInsurance"
                            {...register('billingType', {
                              required: watchExemptFromPatientResponsibility === 'yes' ? false : true,
                              shouldUnregister: false,
                            })}
                            readOnly={watchExemptFromPatientResponsibility === 'yes'}
                            css={[
                              watchExemptFromPatientResponsibility === 'yes'
                                ? tw`opacity-50 pointer-events-none`
                                : tw``,
                            ]}
                            value={BillingTypeEnum.insurance}
                          />
                          <Label
                            font="normal"
                            htmlFor="billingTypeInsurance"
                            className={`ml-2 ${
                              watchExemptFromPatientResponsibility === 'yes' ? 'pointer-events-none' : ''
                            }`}
                          >
                            Insurance
                          </Label>
                        </FormInline>
                      </div>
                      {errors.billingType && <span tw="text-red-500 text-xs italic">Please fill out this field</span>}
                    </FormGroup>
                  )}
                  {Boolean(watchBillingType === 'self-pay' && watchExemptFromPatientResponsibility === 'no') && (
                    <>
                      <FormGroup tw="mb-4 mt-5">
                        <h4 tw="mb-2 text-sm font-semibold">Prompt Pay</h4>
                        <div tw="flex flex-row max-h-[20px]">
                          <FormInline tw="flex items-baseline mr-5">
                            <Input
                              type="radio"
                              id="automaticPayments"
                              data-testid="automaticPayments"
                              {...register('promptPay', { required: true })}
                              value={PromptPayEnum.automaticPayments}
                            />
                            <Label font="normal" htmlFor="automaticPayments" tw="ml-2">
                              Automatic Payments
                            </Label>
                          </FormInline>
                        </div>
                        {errors.promptPay && <span tw="text-red-500 text-xs italic">Please fill out this field</span>}
                      </FormGroup>
                    </>
                  )}
                  {watchBillingType === 'self-pay' && (
                    <>
                      <FormGroup tw="mb-4 mt-5">
                        <h4 tw="mb-2 text-sm font-semibold">Membership Status</h4>
                        <div tw="flex flex-row">
                          <FormInline tw="flex items-baseline mr-5">
                            <Input
                              type="radio"
                              id="feeForService"
                              data-testid="feeForService"
                              {...register('membershipStatus', { required: true })}
                              value={MembershipStatusEnum.feeForService}
                            />
                            <Label font="normal" htmlFor="feeForService" tw="ml-2">
                              Fee-for-service
                            </Label>
                          </FormInline>
                          <FormInline tw="flex items-baseline">
                            <Input
                              type="radio"
                              id="monthlyMembership"
                              disabled={watchAccountId === 'TNJ'}
                              data-testid="monthlyMembership"
                              {...register('membershipStatus', { required: true })}
                              value={MembershipStatusEnum.monthlyMembership}
                            />
                            <Label font="normal" htmlFor="monthlyMembership" tw="ml-2">
                              Monthly Membership
                            </Label>
                          </FormInline>
                        </div>
                        {errors.membershipStatus && (
                          <span tw="text-red-500 text-xs italic">Please fill out this field</span>
                        )}
                      </FormGroup>
                    </>
                  )}
                  {Boolean(watchBillingType === 'insurance') && (
                    <BillingInfoInsuranceForm
                      billingInfoData={billingInfoData}
                      clientData={clientData}
                      exemptFromPatientResponsibility={watchExemptFromPatientResponsibility === 'yes'}
                      onChange={result => setInsuranceForm(result)}
                    />
                  )}
                  {watchBillingType === 'self-pay' &&
                    watchPromptPay === 'automatic-payments' &&
                    watchMembershipStatus === 'fee-for-service' && (
                      <>
                        <FormGroup>
                          <Label tw="mt--5" htmlFor="feeForServicePricing">
                            Evaluation Pricing
                          </Label>
                          <FormInline tw="flex">
                            <div tw="mr-3">
                              <Label htmlFor="dollarSimbol" tw="mt-5">
                                $
                              </Label>
                            </div>
                            <div>
                              <InputNumberStyled>
                                <Input
                                  type="number"
                                  id="evaluationPrice"
                                  onWheel={event => event.currentTarget.blur()}
                                  onKeyDown={handleArrowKeyDown}
                                  min={60}
                                  max={200}
                                  defaultValue={billingInfoForm?.automaticPaymentsFeeForService?.evaluationPrice}
                                  {...register('automaticPaymentsFeeForService.evaluationPrice', {
                                    required: 'Evaluation Price is required',
                                    valueAsNumber: true,
                                  })}
                                  tw="mr-3 mt-3 w-16"
                                  error={errors?.automaticPaymentsFeeForService?.evaluationPrice}
                                />
                              </InputNumberStyled>
                            </div>
                          </FormInline>
                        </FormGroup>
                        <FormGroup>
                          <Label tw="mt--5" htmlFor="feeForServicePricing">
                            Session Pricing
                          </Label>
                          <FormInline tw="flex">
                            <div tw="mr-3">
                              <Label htmlFor="dollarSimbol" tw="mt-5">
                                $
                              </Label>
                            </div>
                            <div>
                              <InputNumberStyled>
                                <Input
                                  type="number"
                                  id="sessionPrice"
                                  onWheel={event => event.currentTarget.blur()}
                                  onKeyDown={handleArrowKeyDown}
                                  min={40}
                                  max={200}
                                  defaultValue={billingInfoForm?.automaticPaymentsFeeForService?.sessionPrice}
                                  {...register('automaticPaymentsFeeForService.sessionPrice', {
                                    required: 'Session Price is required',
                                    valueAsNumber: true,
                                  })}
                                  tw="mr-3 mt-3 w-16"
                                  error={errors?.automaticPaymentsFeeForService?.sessionPrice}
                                />
                              </InputNumberStyled>
                            </div>
                          </FormInline>
                        </FormGroup>
                      </>
                    )}
                  {watchBillingType === 'self-pay' &&
                    watchPromptPay === 'automatic-payments' &&
                    watchMembershipStatus === 'monthly-membership' && (
                      <>
                        <FormGroup tw="mb-4 mt-4 w-full sm:w-80 md:w-80">
                          <h4 tw="mb-2 -mt-5 text-sm font-semibold">Pricing</h4>
                          <div data-testid="pricingMonthlyMembership">
                            <Controller
                              name="automaticPaymentsMonthlyMembership"
                              data-testid="automaticPaymentsMonthlyMembership"
                              control={control}
                              defaultValue={billingInfoForm?.automaticPaymentsMonthlyMembership}
                              render={({ field }) => (
                                <Select {...field} options={pricingMonthlyMembershipOptions} placeholder="-" />
                              )}
                              rules={{ required: true }}
                            />
                            {/* <Select options={pricingMonthlyMembershipOptions} placeholder="–" /> */}
                          </div>
                        </FormGroup>
                        <FormGroup tw="mb-4 mt-4">
                          <h4 tw="mb-2 text-sm font-semibold">Price for Extra Sessions</h4>
                          {monthlyPriceValueSelected === '229' ? (
                            <Label
                              data-testid="billing-info-form-extra-session-price"
                              font="normal"
                              htmlFor="priceForExtraSessions"
                            >
                              $0
                            </Label>
                          ) : (
                            <Label
                              data-testid="billing-info-form-extra-session-price"
                              font="normal"
                              htmlFor="priceForExtraSessions"
                            >
                              $60
                            </Label>
                          )}
                        </FormGroup>
                      </>
                    )}
                </div>

                <div data-testid="referring-provider-information">
                  <div tw="text-xl font-semibold mt-10 mb-4" data-testid="referring-provider-information-label">
                    Referring Provider Information
                  </div>
                  {!showReferringProvider ? (
                    <Link
                      data-testid="add-referring-provider"
                      to="#"
                      tw="text-sm"
                      onClick={() => setShowReferringProvider(true)}
                    >
                      Add referring provider
                    </Link>
                  ) : (
                    <>
                      <FormGroup tw="mb-4 mt-5">
                        <h4 tw="mb-2 text-sm font-semibold">Type</h4>
                        <div tw="flex flex-row">
                          <FormInline tw="flex items-baseline mr-5">
                            <Input
                              type="radio"
                              id="individual"
                              data-testid="individual"
                              {...register('referringProvider.referringType', { required: true })}
                              value={ReferringProviderTypeEnum.individual}
                            />
                            <Label font="normal" htmlFor="individual" tw="ml-2">
                              Individual
                            </Label>
                          </FormInline>
                          <FormInline tw="flex items-baseline">
                            <Input
                              type="radio"
                              id="organization"
                              data-testid="organization"
                              {...register('referringProvider.referringType', { required: true })}
                              value={ReferringProviderTypeEnum.organization}
                            />
                            <Label font="normal" htmlFor="organization" tw="ml-2">
                              Organization
                            </Label>
                          </FormInline>
                        </div>
                      </FormGroup>
                      {watchReferringProviderType === 'individual' && (
                        <FormInline tw="flex-wrap">
                          <FormGroup>
                            <Label htmlFor="referringProviderFirstName">First Name</Label>
                            <Input
                              type="text"
                              id="referringProviderFirstName"
                              tw="mr-3 mt-3"
                              {...register('referringProvider.firstName', {
                                required: 'First Name is required',
                                validate: { onlyLettersSpecialVowelsWhiteSpacesValidator },
                              })}
                              defaultValue={billingInfoForm?.referringProvider.firstName}
                              error={errors.referringProvider?.firstName}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label htmlFor="referringProviderLastName">Last Name</Label>
                            <Input
                              type="text"
                              id="referringProviderLastName"
                              tw="mr-3 mt-3"
                              {...register('referringProvider.lastName', {
                                required: 'Last Name is required',
                                validate: { onlyLettersSpecialVowelsWhiteSpacesValidator },
                              })}
                              defaultValue={billingInfoForm?.referringProvider.lastName}
                              error={errors.referringProvider?.lastName}
                            />
                          </FormGroup>
                        </FormInline>
                      )}
                      <FormGroup>
                        <Label htmlFor="referringProviderPriorAuthorizationNumber">NPI</Label>
                        <Input
                          type="text"
                          id="referringProviderPriorAuthorizationNumber"
                          pattern="^[a-zA-Z0-9]+$"
                          tw="mr-3 mt-3"
                          {...register('referringProvider.npi', {
                            validate: val => {
                              const validator = /^[0-9]{10}$/;
                              return !val || validator.test(val) || '10-digit number is allowed';
                            },
                            required: 'NPI is required',
                          })}
                          defaultValue={billingInfoForm?.referringProvider.npi}
                          error={errors.referringProvider?.npi}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="referringProviderTaxonomyCode">Taxonomy Code</Label>
                        <Input
                          type="text"
                          id="referringProviderTaxonomyCode"
                          tw="mr-3 mt-3"
                          {...register('referringProvider.taxonomyCode')}
                          defaultValue={billingInfoForm?.referringProvider.taxonomyCode}
                          error={errors.referringProvider?.taxonomyCode}
                        />
                      </FormGroup>

                      {watchReferringProviderType === 'organization' && (
                        <FormGroup>
                          <Label htmlFor="referringProviderOrganizationName">Organization Name</Label>
                          <Input
                            type="text"
                            id="referringProviderOrganizationName"
                            tw="mr-3 mt-3"
                            {...register('referringProvider.organizationName', {
                              required: 'Organization is required',
                            })}
                            defaultValue={billingInfoForm?.referringProvider.organizationName}
                            error={errors.referringProvider?.organizationName}
                          />
                        </FormGroup>
                      )}
                      <span
                        data-testid="delete-referring-provider-information"
                        onClick={onConfirmDeleteProvider}
                        className="contents py-10  font-normal cursor-pointer text-red-700"
                      >
                        <FontAwesomeIcon tw="text-base mr-2" icon={['far', 'trash']} />
                        Remove Referring Provider Information
                      </span>
                    </>
                  )}
                </div>

                <div tw="mt-10">
                  <Button
                    tw="mr-5"
                    variant="primary"
                    type="submit"
                    loading={isSubmitting}
                    data-testid="billing-info-save"
                    disabled={watchBillingType === BillingTypeEnum.insurance && !insuranceForm?.isValid}
                  >
                    Save
                  </Button>
                </div>
              </form>
            </Card>
          </Container>
          <DeleteReferringProviderModal
            isOpen={DeleteReferringProviderModalIsOpen}
            onClose={() => {
              DeleteReferringProviderOnClose();
            }}
            isSubmitting={isSubmitting}
            submitBillingInfo={handleSubmit(submitBillingInfo)}
          />
          <BillingInfoCredits
            isCreditsLoading={isCreditsLoading}
            isAdminOrClinicalManager={isAdminOrClinicalManager}
            isErrorFromCredits={isErrorFromCredits}
            creditsData={creditsData}
            clientId={clientId}
          />
        </div>
      </React.Fragment>
    );
  }

  return (
    <>
      <BillingInfoReadOnly isLoading={isLoading} isError={isError} clientId={clientId} />
      <BillingInfoCredits
        isCreditsLoading={isCreditsLoading}
        isAdminOrClinicalManager={isAdminOrClinicalManager}
        isErrorFromCredits={isErrorFromCredits}
        creditsData={creditsData}
        clientId={clientId}
      />
    </>
  );
};

export default BillingInfoForm;
