import React from 'react';
import 'twin.macro';
import { Badge } from '@expressable/ui-library';
import { UpcomingAppointmentItem, UpcomingAppointments } from 'types';

export interface UpcomingAppointmentProps {
  appointmentStats: UpcomingAppointments;
}

const InitialEvaluationBadge = () => (
  <Badge variant="warning" tw="uppercase">
    evaluations
  </Badge>
);

const TherapySessionBadge = () => (
  <Badge variant="success" tw="uppercase ">
    therapy sessions
  </Badge>
);

const FreeConsultationBadge = () => (
  <Badge variant="waiting" tw="uppercase ">
    consults
  </Badge>
);

const AppointmentStats = (props: UpcomingAppointmentProps) => {
  const { appointmentStats } = props;
  const sevenDaysArray: UpcomingAppointmentItem[] = [];

  const upcomingAppointmentsForWeek = sevenDaysArray.concat(
    appointmentStats.today,
    appointmentStats.tomorrow,
    appointmentStats.dayThree,
    appointmentStats.dayFour,
    appointmentStats.dayFive,
    appointmentStats.daySix,
    appointmentStats.daySeven,
  );

  const filterByAppointmentType = (
    fifteenMinutesSession?: string,
    ThirtyMinutesSession?: string,
    FortyFiveMinutesSession?: string,
    SixtyMinutesSession?: string,
  ) =>
    upcomingAppointmentsForWeek.filter(
      item =>
        item?.appointmentType === fifteenMinutesSession ||
        item?.appointmentType === ThirtyMinutesSession ||
        item?.appointmentType === FortyFiveMinutesSession ||
        item?.appointmentType === SixtyMinutesSession,
    );

  const fifteenMinutesSessionType =
    process.env.REACT_APP_NODE_ENV === 'production'
      ? 'Speech Therapy Session + Speech Therapy Session -15 Min'
      : 'Speech Therapy Session + Speech Therapy Session -15min ';
  const fortyFiveMinutesSessionType =
    process.env.REACT_APP_NODE_ENV === 'production'
      ? 'Speech Therapy Session + Speech Therapy Session +15 Min'
      : 'Speech Therapy Session + Speech Therapy Session +15 min ';
  const sixtyMinutesSessionType =
    process.env.REACT_APP_NODE_ENV === 'production'
      ? 'Speech Therapy Session + Speech Therapy Session +30 Min'
      : 'Speech Therapy Session + Speech Therapy Session +30min';

  const sixtyMinutesEvaluationType =
    process.env.REACT_APP_NODE_ENV === 'production'
      ? 'Initial Evaluation + Initial Evaluation +15 Min'
      : 'Initial Evaluation + Initial Evaluation +15 min';

  //Speech Therapy Sessions Filtered By Appointment Types
  const speechTherapySessionFiltered = filterByAppointmentType(
    fifteenMinutesSessionType,
    'Speech Therapy Session', //30 Min Session Type
    fortyFiveMinutesSessionType,
    sixtyMinutesSessionType,
  );

  //Evaluations Filtered By Appointment Types
  const initialEvaluationFiltered = filterByAppointmentType('Initial Evaluation', sixtyMinutesEvaluationType);

  const freeConsultationFiltered = filterByAppointmentType('Free Consultation');

  return (
    <div tw="mb-8" data-testid="appointment__stats">
      <div tw="mb-2 text-base font-semibold text-lg">Next 7 Days</div>
      {upcomingAppointmentsForWeek.length > 0 ? (
        <>
          {initialEvaluationFiltered?.length > 0 && (
            <div>
              <InitialEvaluationBadge />
              <span tw="text-xs font-bold ml-1">{initialEvaluationFiltered.length}</span>
            </div>
          )}
          {speechTherapySessionFiltered?.length > 0 && (
            <div>
              <TherapySessionBadge />
              <span tw="text-xs font-bold ml-1">{speechTherapySessionFiltered.length}</span>
            </div>
          )}
          {freeConsultationFiltered?.length > 0 && (
            <div>
              <FreeConsultationBadge />
              <span tw="text-xs font-bold ml-1">{freeConsultationFiltered.length}</span>
            </div>
          )}
        </>
      ) : (
        <span tw="text-gray-500">No upcoming Appointments</span>
      )}
    </div>
  );
};

export default AppointmentStats;
