import React, { useState } from 'react';
import {
  useUpcomingAppointments,
  useOpenAppointments,
  useUnlockedAppointments,
  useTodayAppointments,
} from 'hooks/use-appointments';
import usePermissions from 'hooks/use-permissions';
import { Container, Spacer, TitleBar, Loader, useDisclosure, Link } from '@expressable/ui-library';
import List from 'components/list';
import UnlockedAppointments from 'components/appointments/unlocked-appointments';
import OpenAppointments from 'components/appointments/open-appointments';
import NoAppointments from 'components/appointments/no-appointments';
import { LogAttendanceModal } from 'components/client-attendance-modals/log-attendance-modal';
import LogRescheduleModal from 'components/client-attendance-modals/log-reschedule-modal';
import { LogCancelationModal } from 'components/client-attendance-modals/log-cancelation-modal';
import { LogNoShowModal } from 'components/client-attendance-modals/log-no-show-modal';
import { LogDismissAttendanceModal } from 'components/client-attendance-modals/log-dismiss-attendance-modal';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCurrentUserContext } from '@expressable/utils';
import { Helmet } from 'react-helmet';
import AppointmentItemCard from './appointment-item-card';
import { AppointmentDetail, UpcomingAppointmentItem } from 'types';
import AppointmentStats from 'components/appointments/appointment-stats';
import useTherapists from 'hooks/use-therapists';
import OpenCases from 'components/appointments/open-cases';
import * as Sentry from '@sentry/react';
import { useFlags } from 'launchdarkly-react-client-sdk';

const Dashboard = () => {
  const currentUser = useCurrentUserContext();
  const { viewClientCases: isClientCasesEnabled } = useFlags();
  const { isAdminOrClinicalManager } = usePermissions();
  const { sessionNotes, evaluationNotes, dischargeNotes } = useUnlockedAppointments();
  const [selectedAppointmentDetail, setSelectedAppointmentDetail] = useState<AppointmentDetail | null>(null);
  const { data: therapists, isLoading: isLoadingTherapists } = useTherapists();
  const therapist = therapists?.find(therapist => therapist.therapistEmail === currentUser?.email);
  const isTherapist = therapist !== undefined;
  const { data: openAppointments } = useOpenAppointments(isTherapist);
  const { data: upcomingAppointments, isLoading: isLoadingUpcomingAppts } = useUpcomingAppointments(
    isTherapist,
    currentUser?.email,
  );
  const { data: todayAppointments, isLoading: isLoadingTodayAppts } = useTodayAppointments(isTherapist);

  const {
    isOpen: isLogAttendanceModalOpen,
    onClose: onLogAttendanceModalClose,
    onOpen: onLogAttendanceModalOpen,
  } = useDisclosure();

  const {
    isOpen: isRescheduleAppointmentModalOpen,
    onClose: onCloseRescheduleAppointmentModal,
    onOpen: onOpenRescheduleAppointmentModal,
  } = useDisclosure();

  const {
    isOpen: isLogCancelationModalOpen,
    onClose: onCloseLogCancelationModal,
    onOpen: onOpenLogCancelationModal,
  } = useDisclosure();

  const {
    isOpen: isLogNoShowModalOpen,
    onClose: onCloseLogNoShowModal,
    onOpen: onOpenLogNoShowModal,
  } = useDisclosure();

  const {
    isOpen: isLogDeleteSessionModalOpen,
    onClose: onCloseLogDeleteSessionModal,
    onOpen: onOpenLogDeleteSessionModal,
  } = useDisclosure();

  const onClientAttendedAppointment = (appointmentDetail: AppointmentDetail) => {
    onLogAttendanceModalOpen();
    setSelectedAppointmentDetail(appointmentDetail);
  };

  const onRescheduleAppointmentDetail = (appointmentDetail: AppointmentDetail) => {
    onOpenRescheduleAppointmentModal();
    setSelectedAppointmentDetail(appointmentDetail);
  };

  const onCancelationAppointmentDetail = (appointmentDetail: AppointmentDetail) => {
    onOpenLogCancelationModal();
    setSelectedAppointmentDetail(appointmentDetail);
  };

  const onNoShowAppointmentDetail = (appointmentDetail: AppointmentDetail) => {
    onOpenLogNoShowModal();
    setSelectedAppointmentDetail(appointmentDetail);
  };

  const onDismissAppointmentDetail = (appointmentDetail: AppointmentDetail) => {
    onOpenLogDeleteSessionModal();
    setSelectedAppointmentDetail(appointmentDetail);
  };

  const onSwitchToRescheduleModal = () => {
    onCloseLogCancelationModal();
    onOpenRescheduleAppointmentModal();
  };

  const HeadingListLink = ({
    heading,
    list,
    link,
    showAttendanceActions = false,
    canDismissAppointment = false,
  }: {
    heading: string;
    list: UpcomingAppointmentItem[];
    link?: { text: string; href: string };
    showAttendanceActions?: boolean;
    canDismissAppointment?: boolean;
  }) => (
    <div className="mb-12">
      <div className="flex items-end mb-4">
        <h2 className="font-medium text-2xl mr-4">{heading}</h2>
        {link && (
          <a
            href={link.href}
            target="_blank"
            rel="noopener noreferrer"
            className="mb-1 text-indigo-700 text-xs text-right"
          >
            {link.text} <FontAwesomeIcon className="text-sm" icon="external-link-alt" />
          </a>
        )}
      </div>

      {!Array.isArray(list) ? (
        <div className="bg-gray-200 p-4">
          <span className="text-sm text-gray-400">There was an error loading this content</span>
        </div>
      ) : list.length ? (
        <List
          data={list}
          render={appointment => {
            const appointmentDetail = todayAppointments?.find(
              item => item.acuityAppointmentID == appointment.acuityID,
            ) as AppointmentDetail;
            return (
              <AppointmentItemCard
                key={appointment.acuityID}
                appointment={appointment}
                appointmentDetail={appointmentDetail}
                showAttendanceActions={appointmentDetail && showAttendanceActions}
                canDismissAppointment={canDismissAppointment}
                onClientAttended={() => onClientAttendedAppointment(appointmentDetail)}
                onReschedule={() => onRescheduleAppointmentDetail(appointmentDetail)}
                onCancel={() => onCancelationAppointmentDetail(appointmentDetail)}
                onNoShow={() => onNoShowAppointmentDetail(appointmentDetail)}
                onDismiss={() => onDismissAppointmentDetail(appointmentDetail)}
              />
            );
          }}
        />
      ) : (
        <span className="text-gray-500">No clients on this day</span>
      )}
    </div>
  );

  const isLoading = isLoadingUpcomingAppts || isLoadingTodayAppts || isLoadingTherapists;

  return (
    <div data-testid="dashboard" className="mb-7">
      <Helmet title="Dashboard" />
      <TitleBar title="Dashboard" />
      <Spacer size="md" />
      <Container size="large" className="md:flex">
        {isLoading ? (
          <div className="flex-1 text-center py-20">
            <Loader type="ring" />
          </div>
        ) : upcomingAppointments && todayAppointments ? (
          <div className="flex-1">
            <div data-testid="today-appointments">
              <HeadingListLink
                heading={`Today, ${dayjs().format('MMMM D')}`}
                list={upcomingAppointments.today}
                link={{
                  text: 'Calendar',
                  href: `https://calendar.google.com/?authuser=${currentUser?.email}`,
                }}
                showAttendanceActions={true}
                canDismissAppointment={isAdminOrClinicalManager}
              />
            </div>
            <HeadingListLink
              heading={dayjs().add(1, 'day').format('dddd, MMMM D')}
              list={upcomingAppointments.tomorrow}
            />
            <HeadingListLink
              heading={dayjs().add(2, 'day').format('dddd, MMMM D')}
              list={upcomingAppointments.dayThree}
            />
            <HeadingListLink
              heading={dayjs().add(3, 'day').format('dddd, MMMM D')}
              list={upcomingAppointments.dayFour}
            />
            <HeadingListLink
              heading={dayjs().add(4, 'day').format('dddd, MMMM D')}
              list={upcomingAppointments.dayFive}
            />
            <HeadingListLink
              heading={dayjs().add(5, 'day').format('dddd, MMMM D')}
              list={upcomingAppointments.daySix}
            />
            <HeadingListLink
              heading={dayjs().add(6, 'day').format('dddd, MMMM D')}
              list={upcomingAppointments.daySeven}
            />
          </div>
        ) : (
          <>
            <div className="flex-1 text-center py-20">
              <div className="flex bg-gray-200 text-gray-400 font-semibold text-xl py-36 justify-center items-center">
                <h3>You have no associated clients</h3>
              </div>
            </div>
          </>
        )}
        <div className="md:ml-8 w-80">
          {openAppointments && <OpenAppointments openAppointments={openAppointments} />}
          {evaluationNotes && <UnlockedAppointments unlockedAppointments={evaluationNotes} type="evaluations" />}
          {sessionNotes && <UnlockedAppointments unlockedAppointments={sessionNotes} type="sessions" />}
          {dischargeNotes && <UnlockedAppointments unlockedAppointments={dischargeNotes} type="discharge" />}

          {isClientCasesEnabled && (
            <Sentry.ErrorBoundary
              fallback={
                <>
                  <div className="mb-2 leading-none font-semibold text-lg">Open Cases</div>
                  <div className="bg-gray-200 p-4 my-2">
                    <span className="text-sm text-gray-400">There was an error while loading your open cases.</span>
                  </div>
                </>
              }
            >
              <OpenCases />
            </Sentry.ErrorBoundary>
          )}
          <NoAppointments therapist={therapist} />
          {upcomingAppointments && <AppointmentStats appointmentStats={upcomingAppointments} />}
          {/* This component will be hidden, the BE must fix a bug on the No-show and Late-cancel events */}
          {/* <AttendanceAnalytics />  */}
          <div data-testid="slp-tools">
            <div className="mb-2 leading-none font-semibold text-lg">SLP Tools</div>

            <div className="flex flex-col space-y-2">
              <Link
                className="text-indigo-700 text-sm font-semibold"
                to={{ pathname: `https://drive.google.com/drive/u/0/folders/0AC4sFDXQgIe2Uk9PVA` }}
                target="_blank"
              >
                Evaluation Templates
              </Link>

              <Link
                className="text-indigo-700 text-sm font-semibold"
                to={{ pathname: `https://form.typeform.com/to/reCtHIHf#useremail=${currentUser?.email}` }}
                target="_blank"
              >
                Cue Content Requests
              </Link>

              <Link
                className="text-indigo-700 text-sm font-semibold"
                to={{ pathname: `https://drive.google.com/drive/u/1/folders/1mXLDEjB3LXa8tpQGPllTPT3yIht7zgvL` }}
                target="_blank"
              >
                SLP Resources
              </Link>

              <Link
                className="text-indigo-700 text-sm font-semibold"
                to={{ pathname: `https://airtable.com/shr2zT3M4PUYwpbX4/tblyRpEZVIImS6AjN` }}
                target="_blank"
              >
                View All Goals
              </Link>

              {isTherapist && (
                <Link
                  className="text-indigo-700 text-sm font-semibold"
                  to={{
                    pathname: `/therapists/${currentUser?.email}`,
                    hash: '#therapist-time-hub',
                  }}
                >
                  Hours Reporting Hub
                </Link>
              )}

              <Link
                className="text-indigo-700 text-sm font-semibold"
                to={{ pathname: `http://help.expressable.io` }}
                target="_blank"
              >
                Help Center
              </Link>
            </div>
          </div>
          <div data-testid="hr-and-training">
            <div className="mb-2 mt-8 leading-none font-semibold text-lg">HR and Training</div>
            <div className="flex flex-col space-y-2">
              <Link
                className="text-indigo-700 text-sm font-semibold"
                to={{ pathname: `https://seq-ep.prismhr.com/` }}
                target="_blank"
              >
                Sequoia Worklife Portal
              </Link>
              <Link
                className="text-indigo-700 text-sm font-semibold"
                to={{ pathname: `https://www.cultureamp.com/` }}
                target="_blank"
              >
                Culture Amp
              </Link>

              <Link
                className="text-indigo-700 text-sm font-semibold"
                to={{ pathname: `https://expressablelearns.reach360.com/learn/my-learning` }}
                target="_blank"
              >
                Reach
              </Link>

              <Link
                className="text-indigo-700 text-sm font-semibold"
                to={{ pathname: `https://airtable.com/shrXvHXMWusCM5jcB` }}
                target="_blank"
              >
                Overtime Request Form
              </Link>

              <Link
                className="text-indigo-700 text-sm font-semibold"
                to={{ pathname: `https://airtable.com/shrtHIHOzsiYzLwbA` }}
                target="_blank"
              >
                PTO/Sick Leave/Availability Block Request
              </Link>
            </div>
          </div>
        </div>
      </Container>
      {selectedAppointmentDetail && (
        <>
          {isLogAttendanceModalOpen && (
            <LogAttendanceModal
              isOpen={isLogAttendanceModalOpen}
              onClose={onLogAttendanceModalClose}
              activity={selectedAppointmentDetail}
              clientId={selectedAppointmentDetail.clientID}
            />
          )}

          {isRescheduleAppointmentModalOpen && (
            <LogRescheduleModal
              isOpen={isRescheduleAppointmentModalOpen}
              onClose={onCloseRescheduleAppointmentModal}
              activity={selectedAppointmentDetail}
            />
          )}

          {isLogCancelationModalOpen && (
            <LogCancelationModal
              isOpen={isLogCancelationModalOpen}
              onClose={onCloseLogCancelationModal}
              activity={selectedAppointmentDetail}
              onRescheduleSwitch={onSwitchToRescheduleModal}
              clientId={selectedAppointmentDetail.clientID}
              isSidebarAppointment={false}
            />
          )}

          {isLogNoShowModalOpen && (
            <LogNoShowModal
              isOpen={isLogNoShowModalOpen}
              onClose={onCloseLogNoShowModal}
              activity={selectedAppointmentDetail}
              clientId={selectedAppointmentDetail.clientID}
            />
          )}

          {isLogDeleteSessionModalOpen && (
            <LogDismissAttendanceModal
              isOpen={isLogDeleteSessionModalOpen}
              onClose={onCloseLogDeleteSessionModal}
              activity={selectedAppointmentDetail}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Dashboard;
