import { createContext } from 'react';
import { FieldValues } from 'react-hook-form';
import { Schema } from 'yup';

export type ContextType<T extends Schema<unknown>> = {
  schema?: T;
  disabled: boolean;
  serverErrors: Record<string, string | undefined | null>;
  hideOptional: boolean;
};

export const FormValidationContext = createContext<ContextType<Schema<FieldValues>> | null>(null);
