/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Input, Label, FormGroup, Textarea, Select, Link, FormInline, useDisclosure } from '@expressable/ui-library';
import {
  Appointment,
  SessionNoteContent,
  ICompleteClientInformation,
  GoalProgress,
  StgProgress,
  LtgProgress,
  HomeProgramSelection,
  SubjectiveComplaint,
  FILTER_BY_GOAL_SELECT_OPTIONS,
  SelectOption,
  Note,
  HomePracticeNote,
} from 'types';
import { enableMapSet, produce } from 'immer';
import { useGetGoals, Goal, GetStgProgressHistoryPayload, getStgProgressHistory } from 'hooks/use-care-plan-goals';
import { ClientGoalsModal, fromFieldsToSelectOptions } from 'pages/client/components/client-goals-modal';
import { useContentfulData, getCollectionFields } from 'hooks/use-ContentfulData';
import { InputNumberStyled } from 'components/edit-therapist-info-form';
import dayjs from 'dayjs';
import tw from 'twin.macro';
import { buildAddressString, filterGoalsByStatus } from 'utils/helpers';
import { SeeMoreGoalProgressCollapsible } from 'utils/activity-collapse';
import * as Sentry from '@sentry/react';
import { getGoalDetails } from 'utils/care-plans';
import { TherapyStrategies } from './session-note/therapy-strategies';
import { useGetNote } from 'hooks/use-notes';
import { HomePractices } from './session-note/home-practice';
import { ClientAssignment } from 'domain/clinical-pathways/types';
import { MAX_SMS_CHARACTERS } from 'utils/sms-params';
import { getPrimaryContact } from '../../client-sidebar';
import { isNull } from 'lodash';

const whoAttendSessionOptions = [
  {
    label: 'Attended all of the session',
    value: 'Attended all of the session',
  },
  {
    label: 'Attended part of the session',
    value: 'Attended part of the session',
  },
  {
    label: 'Attended only a few minutes of the session',
    value: 'Attended only a few minutes of the session',
  },
  {
    label: 'Did not attend',
    value: 'Did not attend',
  },
  {
    label: 'Not Applicable',
    value: 'Not Applicable',
  },
];

export const noteDuration = [
  {
    label: '15 Minutes',
    value: 15,
  },
  {
    label: '30 Minutes',
    value: 30,
  },
  {
    label: '45 Minutes',
    value: 45,
  },
  {
    label: '60 Minutes',
    value: 60,
  },
];

// immer plugin for enabling map set
enableMapSet();
const subjectiveComplaintControls = [
  { label: 'Alert', value: 'alert' },
  { label: 'Engaged', value: 'engaged' },
  { label: 'Cooperative', value: 'cooperative' },
  { label: 'Tired', value: 'tired' },
  { label: 'Required redirection to tasks', value: 'required redirection to tasks' },
  { label: 'Other', value: 'other' },
];

export interface SessionNoteFormContentProps {
  clientData: ICompleteClientInformation | undefined;
  formContent: SessionNoteContent;
  setActivity: React.Dispatch<React.SetStateAction<Appointment>>;
  clientId: string;
  attendError?: { message: string } | null;
  onChange?: () => void;
  noteID?: string | null;
  // eslint-disable-next-line
  registerField: (instancePath: string) => { ref: any; error: { message: string | undefined } | undefined };
}

type ContentfulField = { [key: string]: string };

export const handleArrowKeyDown = (e: { which: number; preventDefault: () => void }) => {
  if (e.which === 38 || e.which === 40) {
    e.preventDefault();
  }
};
function isStgProgress(goal: GoalProgress): goal is StgProgress {
  return goal.goalType === 'stg';
}

function isLtgProgress(goal: GoalProgress): goal is LtgProgress {
  return goal.goalType === 'ltg';
}

export const SessionNoteFormContent = (props: SessionNoteFormContentProps) => {
  const { formContent, setActivity, clientData, clientId, onChange, noteID, registerField } = props;
  const { data: allGoals } = useGetGoals({ clientId });
  const { data: sessionNote, isLoading: isSessionNoteLoading } = useGetNote<Note<SessionNoteContent>>(
    clientId,
    noteID!,
  );

  const [subjectiveComplaintOtherDisplay, setSubjectiveComplaintOtherDisplay] = useState(false);
  const [locationVisitIsPrimaryAddress, setLocationVisitIsPrimaryAddress] = useState<boolean | null>(null);
  const assignmentRegister = registerField('/note/content/assignment');
  const subjectiveComplaintRegister = registerField('/note/content/subjectiveComplaint');

  const [cueLevels] = useContentfulData<ContentfulField>('cueLevels');
  const [currentGoal, setCurrentGoal] = useState<Goal | undefined>();
  const [goalsToProgressMap, setGoalsToProgressMap] = useState<Map<string, GoalProgress>>(new Map());
  const [homeProgramsMap, setHomeProgramsMap] = useState<Map<string, HomeProgramSelection>>(new Map());
  const [goalProgressHistoryMap, setGoalProgressHistoryMap] = useState(new Map());
  const [selectedGoalFilter, setSelectedGoalFilter] = useState<SelectOption>({
    label: 'Active Goals',
    value: 'active',
  });
  const [therapyStrategies, setTherapyStrategies] = useState<ClientAssignment[]>([]);

  const [selectedSTG, setSelectedSTG] = useState<Map<string, string>>(new Map());

  const [currentAction, setCurrentAction] = useState<'createLtg' | 'createStg' | 'updateLtg' | 'updateStg'>();

  const [clientCommunicationLength, setClientCommunicationLength] = useState<number>(0);
  const [isTextLimitExceeded, setTextLimitExceeded] = useState<boolean>(false);

  // fill up homeProgramsMap with homeProgramsList on mount
  // this will show all home programs in the session note (if any is defined)
  useEffect(() => {
    if (formContent?.homePrograms?.length) {
      formContent.homePrograms.forEach(homeProgram => {
        setHomeProgramsMap(
          produce(homeProgramsMap => {
            homeProgramsMap.set(homeProgram.id, homeProgram);
          }),
        );
      });
    }
  }, []);

  // On edit we will have to watch activity
  useEffect(() => {
    // Loop through the goals progress and populate the goalsToProgressMap
    if (formContent.carePlanGoalsProgress) {
      formContent.carePlanGoalsProgress.forEach(goalProgress => {
        setGoalsToProgressMap(
          produce(goalsToProgressMap => {
            if (isStgProgress(goalProgress)) {
              goalsToProgressMap?.set(goalProgress.stgID, goalProgress);
              setSelectedSTG(
                produce(selectedSTG => {
                  if (isStgProgress(goalProgress)) {
                    if (selectedSTG.get(goalProgress.stgID) === undefined)
                      selectedSTG.set(goalProgress.stgID, goalProgress.goalDescription);
                  }
                }),
              );
            } else if (isLtgProgress(goalProgress)) {
              goalsToProgressMap?.set(goalProgress.ltgID, goalProgress);
            }
          }),
        );
      });
    }
  }, [formContent]);

  // every time the home program list is updated (adding, removing), we have to update the homePrograms array in
  // the activity note content
  useEffect(() => {
    setActivity(
      produce(activity => {
        const content = activity.note.content as SessionNoteContent;
        content.homePrograms = Array.from(homeProgramsMap.values());
      }),
    );
  }, [homeProgramsMap]);

  //every time the session note form its opened we set the duration time defaultValue equal to 30 minutes
  useEffect(() => {
    if (!formContent.duration) {
      setActivity(
        produce(activity => {
          const content = activity.note.content as SessionNoteContent;
          content.duration = 30;
        }),
      );
    }
  }, []);

  //every time the session note form its opened and the relationshipToClient its equal to "Client" we set the parentOrCaregiverAttendSession to Not Applicable
  useEffect(() => {
    const hasAnyRelationShipToClient = clientData?.contactInformation?.some(
      ({ relationshipToClient }) => relationshipToClient == 'Client',
    );
    if (!formContent.parentOrCaregiverAttendSession && hasAnyRelationShipToClient) {
      setActivity(
        produce(activity => {
          const content = activity.note.content as SessionNoteContent;
          content.parentOrCaregiverAttendSession = 'Not Applicable';
        }),
      );
    }
  }, [clientData?.contactInformation?.length, clientData?.contactInformation]);

  useEffect(() => {
    // for each stg we will get the progress history and save into an object with the stgID as key
    const fetchProgress = async () => {
      if (allGoals?.length) {
        allGoals
          .filter(goal => goal.goalType === 'stg')
          .forEach(async goal => {
            const findGoalProgress = goalProgressHistoryMap.get(goal.stgID);
            if (!findGoalProgress) {
              try {
                const stgProgressResponse = await getStgProgressHistory(clientId, goal.ltgID, goal.stgID);
                // save the response into the goalProgressHiistoryMap
                setGoalProgressHistoryMap(goalProgressHistoryMap.set(goal.stgID, stgProgressResponse));
              } catch (e) {
                setGoalProgressHistoryMap(goalProgressHistoryMap.set(goal.stgID, []));
                Sentry.captureException(e);
              }
            }
          });
      }
    };

    fetchProgress();
  }, []);

  useEffect(() => {
    if (goalsToProgressMap) {
      setActivity(
        produce(activity => {
          const content = activity.note.content as SessionNoteContent;
          content.carePlanGoalsProgress = Array.from(goalsToProgressMap.values());
        }),
      );
    }
  }, [goalsToProgressMap]);

  useEffect(() => {
    if (!isSessionNoteLoading) {
      setTherapyStrategies(sessionNote?.detail?.therapyStrategies ?? []);
    }
  }, [isSessionNoteLoading]);

  useEffect(() => {
    const initialLength = formContent.feedbackForClient.length;
    setClientCommunicationLength(initialLength);
    setTextLimitExceeded(initialLength > MAX_SMS_CHARACTERS);
  }, [formContent.feedbackForClient]);

  const handleSubjectiveComplaintChange = (event: React.ChangeEvent<HTMLInputElement>, other = false) => {
    const { name, checked, value } = event.currentTarget;
    setActivity(
      produce(activity => {
        const content = activity.note.content as SessionNoteContent;
        if (other) {
          content.subjectiveComplaint['other'] = value;
        } else {
          content.subjectiveComplaint[name as keyof Omit<SubjectiveComplaint, 'other'>] = checked;
        }
      }),
    );

    if (onChange) {
      onChange();
    }
  };

  const {
    isOpen: ClientGoalsModalIsOpen,
    onClose: ClientGoalsModalOnClose,
    onOpen: ClientGoalsModalOnOpen,
  } = useDisclosure();

  // dev-note: we need this to re-render everytime to update the goals instantly
  const goals = filterGoalsByStatus(allGoals, selectedGoalFilter.value);

  const handleTherapyStrategiesChange = (updatedTherapyStrategies: ClientAssignment[]) => {
    // we follow the standard for the other sections and set the activity in the note
    setActivity(
      produce(activity => {
        if (activity?.note) {
          const content = activity.note.content as SessionNoteContent;
          content.therapyStrategies = [...updatedTherapyStrategies];
        }
      }),
    );

    setTherapyStrategies([...updatedTherapyStrategies]);

    // we follow the standard for the session-note and trigger the auto-save every time something changes
    onChange?.();
  };

  const handleDailyRoutineActivitiesChange = ({
    dailyRoutineActivities,
    homePractice,
    skipAutosave,
  }: {
    dailyRoutineActivities: ClientAssignment[];
    homePractice: HomePracticeNote;
    skipAutosave?: boolean;
  }) => {
    // we follow the standard for the other sections and set the activity in the note
    setActivity(
      produce(activity => {
        if (activity?.note) {
          const content = activity.note.content as SessionNoteContent;
          content.homePractice = { ...homePractice };
          content.dailyRoutineActivities = [...dailyRoutineActivities];
        }
      }),
    );

    if (!skipAutosave) {
      // we follow the standard for the session-note and trigger the auto-save every time something changes
      onChange?.();
    }
  };

  const handleStructuredActivitiesChange = ({
    structuredActivities,
    homePractice,
    skipAutosave,
  }: {
    structuredActivities: ClientAssignment[];
    homePractice: HomePracticeNote;
    skipAutosave?: boolean;
  }) => {
    // we follow the standard for the other sections and set the activity in the note
    setActivity(
      produce(activity => {
        if (activity?.note) {
          const content = activity.note.content as SessionNoteContent;
          content.homePractice = { ...homePractice };
          content.structuredActivities = [...structuredActivities];
        }
      }),
    );

    if (!skipAutosave) {
      // we follow the standard for the session-note and trigger the auto-save every time something changes
      onChange?.();
    }
  };

  useEffect(() => {
    if (!formContent?.locationVisitAddress) {
      setLocationVisitIsPrimaryAddress(null);
      return;
    }

    setLocationVisitIsPrimaryAddress(
      formContent.locationVisitAddress?.trim()?.toLowerCase() ===
        buildAddressString(getPrimaryContact(clientData?.contactInformation, clientId)?.address)?.trim()?.toLowerCase(),
    );
  }, [formContent?.locationVisitAddress, ]);
  return (
    <div data-testid="session-note">
      <div tw="w-full" data-testid="sessionDuration">
        <Label htmlFor="sessionDuration" tw="font-semibold text-sm">
          Duration
        </Label>
        <div tw="w-40 mt-2" data-testid="sessionDurationTime">{`${formContent.duration} minutes`}</div>
      </div>
      <h4 tw="mb-2 text-sm font-semibold mt-9">Subjective Report</h4>
      <div ref={subjectiveComplaintRegister.ref} tabIndex={0} tw="text-red-500 text-xs italic">
        {subjectiveComplaintRegister.error?.message ?? ''}
      </div>
      {subjectiveComplaintControls?.map(({ value, label }) => {
        if (value === 'other') {
          return (
            <div key={value}>
              <FormGroup type="inline" tw="items-baseline">
                <Input
                  type="checkbox"
                  id="other"
                  name="other"
                  checked={subjectiveComplaintOtherDisplay || Boolean(formContent?.subjectiveComplaint?.other)}
                  onChange={e => {
                    if (!e.currentTarget.checked) {
                      setActivity(
                        produce(activity => {
                          const content = activity.note.content as SessionNoteContent;
                          content.subjectiveComplaint['other'] = '';
                        }),
                      );

                      if (onChange) {
                        onChange();
                      }
                    }
                    setSubjectiveComplaintOtherDisplay(e.target.checked);
                  }}
                  tw="mb-0"
                  spacing="tight"
                />
                <Label htmlFor="other" tw="font-normal mb-0 ml-2 text-sm">
                  {label}
                </Label>
              </FormGroup>
              {(subjectiveComplaintOtherDisplay || formContent?.subjectiveComplaint?.other?.length > 0) && (
                <Input
                  data-testid="session-note-form-content-other"
                  type="text"
                  tw="mt-2 w-80"
                  value={formContent.subjectiveComplaint['other']}
                  onChange={e => {
                    handleSubjectiveComplaintChange(e, true);
                  }}
                />
              )}
            </div>
          );
        } else {
          return (
            <div key={value}>
              <FormGroup type="inline" tw="items-baseline">
                <Input
                  type="checkbox"
                  id={value}
                  name={value}
                  checked={
                    formContent?.subjectiveComplaint &&
                    formContent?.subjectiveComplaint[(value as keyof Omit<SubjectiveComplaint, 'other'>) ?? '']
                  }
                  onChange={handleSubjectiveComplaintChange}
                  tw="mb-0"
                  spacing="tight"
                />
                <Label tw="font-normal mb-0 ml-2 text-sm" htmlFor={value}>
                  {label}
                </Label>
              </FormGroup>
            </div>
          );
        }
      })}
      <div tw="w-full mt-8">
        <Label htmlFor="locationVisit" tw="font-semibold text-sm" optional>
          Is the visit occurring at the client&apos;s primary address?
        </Label>
        <div tw="w-96 mt-2" data-testid="session-note-form-content-location-visit">
          <FormGroup>
            <FormInline tw="gap-2">
              <Input
                type="radio"
                id="locationVisit-yes"
                name="locationVisit-locationVisit"
                checked={Boolean(locationVisitIsPrimaryAddress)}
                onChange={e => {
                  setLocationVisitIsPrimaryAddress(true);
                  setActivity(
                    produce(activity => {
                      const content = activity.note.content as SessionNoteContent;
                      content.locationVisitAddress = buildAddressString(
                        getPrimaryContact(clientData?.contactInformation, clientId)?.address,
                      );
                    }),
                  );

                  if (onChange) {
                    onChange();
                  }
                }}
              />
              <Label htmlFor="locationVisit-yes" tw="font-normal text-sm mt-1">
                Yes
              </Label>

              <Input
                type="radio"
                id="locationVisit-no"
                name="locationVisit-locationVisit"
                className="ml-2"
                checked={!locationVisitIsPrimaryAddress && formContent.locationVisitAddress !== null}
                onChange={e => {
                  setLocationVisitIsPrimaryAddress(false);
                  setActivity(
                    produce(activity => {
                      const content = activity.note.content as SessionNoteContent;
                      content.locationVisitAddress = '';
                    }),
                  );

                  if (onChange) {
                    onChange();
                  }
                }}
              />
              <Label htmlFor="locationVisit-no" tw="font-normal text-sm mt-1">
                No
              </Label>
            </FormInline>
          </FormGroup>

          {!locationVisitIsPrimaryAddress && !isNull(formContent.locationVisitAddress) && (
            <FormGroup tw="mt-2">
              <Label htmlFor="locationVisitAddressTextArea" tw="font-semibold text-sm">
                What is the location where the client is currently?
              </Label>
              <Textarea
                id="locationVisitAddressTextArea"
                tw="w-96 mt-2"
                name="locationVisitAddressTextArea"
                value={formContent.locationVisitAddress!}
                onChange={e => {
                  setActivity(
                    produce(activity => {
                      const content = activity.note.content as SessionNoteContent;
                      content.locationVisitAddress = e.target.value;
                    }),
                  );
                }}
              />
            </FormGroup>
          )}
        </div>
      </div>

      {clientData ? (
        <div tw="w-full mt-8">
          <Label htmlFor="attendsession" tw="font-semibold text-sm">
            Did parent or caregiver attend the session?
          </Label>
          <div tw="w-96 mt-2" data-testid="session-note-form-content-attend-session">
            <Select
              id="attendsession"
              isSearchable
              data-testid="attendsession"
              options={whoAttendSessionOptions}
              value={{
                label: formContent.parentOrCaregiverAttendSession,
                value: formContent.parentOrCaregiverAttendSession,
              }}
              onChange={(option: { label: string; value: string }) => {
                setActivity(
                  produce(activity => {
                    const content = activity.note.content as SessionNoteContent;
                    content.parentOrCaregiverAttendSession = option.value;
                  }),
                );

                if (onChange) {
                  onChange();
                }
              }}
              {...registerField('/note/content/parentOrCaregiverAttendSession')}
            />
          </div>
        </div>
      ) : null}
      <div tw="flex mt-9 mb-4">
        <h4 tw="text-sm font-semibold">Objective Findings</h4>
        <h4 tw="text-sm text-gray-400 ml-2">Updates will be shared with Client </h4>
      </div>
      <div tw="flex mt-6 mb-6">
        <FormInline tw="w-full">
          <Label tw="mr-2 flex items-center" htmlFor="goal-filter">
            View
          </Label>
          <Select
            tw="min-w-[200px]"
            spacing="tight"
            id="selected-goal-filter"
            value={selectedGoalFilter}
            onChange={setSelectedGoalFilter}
            options={FILTER_BY_GOAL_SELECT_OPTIONS}
          />
        </FormInline>
      </div>
      {!goals?.length ? (
        <div tw="text-sm text-gray-400 mb-4" data-testid="session-note-form-content-no-goals-msg">
          No goals have been added
        </div>
      ) : (
        goals
          .filter(goal => goal.goalType === 'ltg')
          .map(currentLtg => {
            const {
              goalOpportunityUnit: currentLtgOpportunityUnit,
              goalOpportunityUnitDivider: currentLtgOpportunityUnitDivider,
              goalDescription: currentLtgDescription,
            } = getGoalDetails(currentLtg);

            const ltgDueDate = currentLtg?.dueAt && dayjs(currentLtg?.dueAt).format('MM/DD/YYYY');
            const pastToday = dayjs(currentLtg?.dueAt).isBefore(dayjs().format('MM/DD/YYYY'));
            const currentShortTermGoals = filterGoalsByStatus(
              allGoals?.filter(goal => goal.goalType === 'stg' && currentLtg.ltgID === goal.ltgID),
              selectedGoalFilter.value,
            );
            const currentLtgProgress = goalsToProgressMap?.get(currentLtg.ltgID);

            return (
              <div tw="mb-4" key={currentLtg.ltgID} data-testid="care-plan-goals">
                <div>
                  <div tw="flex">
                    <span>
                      <Label htmlFor={`checkbox-${currentLtg.ltgID}`} tw="font-normal text-base">
                        {currentLtgDescription}
                        <Link
                          tw="ml-2 text-xs"
                          to="#"
                          onClick={e => {
                            setCurrentGoal(currentLtg);
                            setCurrentAction('updateLtg');
                            ClientGoalsModalOnOpen();
                          }}
                        >
                          Edit
                        </Link>
                      </Label>
                    </span>
                    {!!currentLtgProgress?.progressComparison ? (
                      <div tw="mt-4">
                        <Label> Level in Session </Label>
                        <FormInline tw="w-full gap-3 mt-1 items-center">
                          <InputNumberStyled>
                            <Input
                              type="number"
                              id={`level-in-session-${currentLtg.ltgID}`}
                              tw="w-16"
                              onWheel={event => event.currentTarget.blur()}
                              onKeyDown={handleArrowKeyDown}
                              value={currentLtgProgress?.progressLevel ?? undefined}
                              onChange={e => {
                                setGoalsToProgressMap(
                                  produce(goalsToProgressMap => {
                                    goalsToProgressMap?.set(currentLtg.ltgID, {
                                      ...currentLtgProgress,
                                      progressLevel: Number(e.target.value),
                                    });
                                  }),
                                );
                                onChange?.();
                              }}
                              min={0}
                              max={100}
                              required
                            />
                          </InputNumberStyled>
                          <div tw="mb-5">{` ${currentLtgOpportunityUnit} given `}</div>
                          <Select
                            tw="w-60"
                            name="goalCues"
                            defaultValue={{ label: currentLtg.detail.goalCues, value: currentLtg.detail.goalCues }}
                            options={fromFieldsToSelectOptions(getCollectionFields(cueLevels), true)}
                            onChange={(selectOption: { label: string; value: string }) => {
                              setGoalsToProgressMap(
                                produce(goalsToProgressMap => {
                                  goalsToProgressMap?.set(currentLtg.ltgID, {
                                    ...currentLtgProgress,
                                    progressComparison: selectOption.value,
                                  });
                                }),
                              );
                              onChange?.();
                            }}
                            isSearchable={false}
                          />
                        </FormInline>
                        <div tw="text-gray-400 text-sm">
                          {`Baseline: ${currentLtg.detail.goalBaselinePerformance}${currentLtgOpportunityUnitDivider}${currentLtgOpportunityUnit}`}
                          &nbsp; &nbsp;
                          {ltgDueDate && (
                            <div tw="inline-block" data-testid="sessionNote__LtgGoals__dueDate">
                              Due Date: {''}
                              <span css={[pastToday ? tw`text-red-500` : tw`text-gray-400`]}>{ltgDueDate}</span>
                            </div>
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div tw="mt-4">
                  {currentShortTermGoals?.map((currentStg, index) => {
                    const {
                      goalOpportunityUnit: currentStgOpportunityUnit,
                      goalOpportunityUnitDivider: currentStgOpportunityUnitDivider,
                      goalDescription: currentStgDescription,
                    } = getGoalDetails(currentStg);

                    const currentStgProgress = goalsToProgressMap?.get(currentStg.stgID) as StgProgress;
                    const isCurrentStgGoalSelected = selectedSTG.get(currentStg.stgID);
                    const stgDueDate = currentStg?.dueAt && dayjs(currentStg?.dueAt).format('MM/DD/YYYY');
                    const pastToday = dayjs(currentStg?.dueAt).isBefore(dayjs().format('MM/DD/YYYY'));
                    const status = currentStg?.status === 'progressing' || currentStg?.status === 'modified';
                    const goalCuesOptions = currentStgProgress
                      ? currentStgProgress.progressComparison
                      : currentStg.detail.goalCues;

                    return (
                      <div tw="mb-4 border-l-4 border-gray-DEFAULT" key={currentStg.stgID}>
                        <div tw="ml-4 flex">
                          <Input
                            type="checkbox"
                            id={`checkbox-${currentStg.stgID}`}
                            checked={Boolean(isCurrentStgGoalSelected)}
                            onChange={e => {
                              setGoalsToProgressMap(
                                produce(goalsToProgressMap => {
                                  const newStgProgress: StgProgress = {
                                    goalDescription: currentStgDescription,
                                    ltgID: currentLtg.ltgID,
                                    progressLevel: null,
                                    progressUnit: currentStgOpportunityUnit,
                                    progressComparison: currentStg.detail.goalCues,
                                    stgID: currentStg.stgID,
                                    goalType: 'stg',
                                  };
                                  if (e.target.checked) {
                                    // Every time we select a STG in the session note we set the values to fetch the progressHistory
                                    // If the associated LTG already exists in the map, then we don't need to add it
                                    setSelectedSTG(
                                      produce(selectedSTG => {
                                        selectedSTG.set(currentStg.stgID, currentStg.detail.goalDescription);
                                      }),
                                    );

                                    if (goalsToProgressMap.has(currentLtg.ltgID)) {
                                      goalsToProgressMap?.set(currentStg.stgID, newStgProgress);
                                    } else {
                                      // We need to create the associated ltg first
                                      goalsToProgressMap?.set(currentLtg.ltgID, {
                                        goalDescription: currentLtgDescription,
                                        progressLevel: null,
                                        progressUnit: null,
                                        progressComparison: null,
                                        ltgID: currentLtg.ltgID,
                                        goalType: 'ltg',
                                      });
                                      goalsToProgressMap?.set(currentStg.stgID, newStgProgress);
                                    }
                                  } else {
                                    goalsToProgressMap?.delete(currentStg.stgID);
                                    setSelectedSTG(
                                      produce(selectedSTG => {
                                        selectedSTG.set(currentStg.stgID, '');
                                      }),
                                    );

                                    const hasChildren = Array.from(goalsToProgressMap.values()).some(
                                      item => item.goalType === 'stg' && item.ltgID === currentLtg.ltgID,
                                    );
                                    if (!hasChildren) {
                                      goalsToProgressMap?.delete(currentLtg.ltgID);
                                    }
                                  }
                                }),
                              );
                            }}
                          ></Input>
                          <div tw="ml-4">
                            <span>
                              <Label htmlFor={`checkbox-${currentStg.stgID}`} tw="font-normal text-base">
                                {currentStgDescription}
                                <Link
                                  tw="ml-2 text-xs"
                                  to="#"
                                  onClick={() => {
                                    setCurrentGoal(currentStg);
                                    setCurrentAction('updateStg');
                                    ClientGoalsModalOnOpen();
                                  }}
                                >
                                  Edit
                                </Link>
                              </Label>
                            </span>
                            {selectedSTG.get(currentStg.stgID) ? (
                              <div tw="mt-4">
                                {/* We need to validate that we found a STG Match and that progressHistory contain progress "the BE sents the data sorted" */}
                                {goalProgressHistoryMap
                                  .get(currentStg.stgID)
                                  ?.filter((progress: any) => Boolean(progress?.appointmentOn)).length > 0 ? (
                                  <div tw="mt-6">
                                    <SeeMoreGoalProgressCollapsible>
                                      <Label tw="mb-2"> Session Progress History </Label>
                                      {goalProgressHistoryMap
                                        .get(currentStg.stgID)
                                        .filter((progress: any) => Boolean(progress?.appointmentOn))
                                        .map((progress: GetStgProgressHistoryPayload) => {
                                          const appointmentOn = progress?.appointmentOn
                                            ? dayjs(progress?.appointmentOn).format('MM/DD/YYYY')
                                            : '';
                                          const progressUnit = progress?.progressUnit ?? '%';
                                          const roundProgressLevel = Math.round(progress?.progressLevel ?? 0);
                                          return appointmentOn ? (
                                            <div tw="text-sm text-gray-400 mb-2" key={progress.gpID}>
                                              {`${appointmentOn} - ${roundProgressLevel}${
                                                progressUnit === '%' ? '%' : ` ${progressUnit}`
                                              }
                                              given ${progress.progressComparison}`}
                                            </div>
                                          ) : null;
                                        })}
                                    </SeeMoreGoalProgressCollapsible>
                                  </div>
                                ) : null}
                                <Label tw="mt-5"> Level in Session </Label>
                                <FormInline tw="w-full gap-3 mt-1 items-center">
                                  <InputNumberStyled>
                                    <Input
                                      type="number"
                                      id={`level-in-session-${currentStg.stgID}`}
                                      tw="w-20"
                                      onWheel={event => event.currentTarget.blur()}
                                      onKeyDown={handleArrowKeyDown}
                                      value={currentStgProgress?.progressLevel ?? undefined}
                                      onChange={e => {
                                        setGoalsToProgressMap(
                                          produce(goalsToProgressMap => {
                                            goalsToProgressMap?.set(currentStg.stgID, {
                                              ...currentStgProgress,
                                              progressLevel: Number(e.target.value),
                                            });
                                          }),
                                        );
                                        onChange?.();
                                      }}
                                      min={0}
                                      max={100}
                                      required
                                    />
                                  </InputNumberStyled>
                                  <div tw="mb-5">{` ${currentStgOpportunityUnit} given `}</div>
                                  <Select
                                    tw="w-60"
                                    name="goalCues"
                                    options={fromFieldsToSelectOptions(getCollectionFields(cueLevels), true)}
                                    defaultValue={{
                                      label: goalCuesOptions,
                                      value: goalCuesOptions,
                                    }}
                                    onChange={(selectOption: { label: string; value: string }) => {
                                      setGoalsToProgressMap(
                                        produce(goalsToProgressMap => {
                                          goalsToProgressMap?.set(currentStg.stgID, {
                                            ...currentStgProgress,
                                            progressComparison: selectOption.value,
                                          });
                                        }),
                                      );
                                      onChange?.();
                                    }}
                                    isSearchable={false}
                                  />
                                </FormInline>
                                <div tw="text-gray-400 text-sm">
                                  {`Baseline: ${currentStg.detail.goalBaselinePerformance}${currentStgOpportunityUnitDivider}${currentStgOpportunityUnit} `}
                                  &nbsp; &nbsp;
                                  {stgDueDate && (
                                    <div tw="inline-block" data-testid="sessionNote__StgGoals__dueDate">
                                      Due Date: {''}
                                      <span css={[pastToday && status ? tw`text-red-500` : tw`text-gray-400`]}>
                                        {stgDueDate}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })
      )}
      <>
        <TherapyStrategies
          clientId={clientId}
          noteID={noteID!}
          isNoteLoading={isSessionNoteLoading}
          currentTherapyStrategies={sessionNote?.detail?.therapyStrategies ?? []}
          onChange={handleTherapyStrategiesChange}
        />
        <div ref={assignmentRegister.ref} tabIndex={0} tw="text-red-500 text-xs italic">
          {assignmentRegister.error?.message ?? ''}
        </div>
      </>
      <>
        <HomePractices
          clientData={clientData}
          clientId={clientId}
          noteID={noteID!}
          isNoteLoading={isSessionNoteLoading}
          currentTherapyStrategies={therapyStrategies ?? []}
          currentHomePractice={sessionNote?.detail?.homePractice as HomePracticeNote}
          onChangeDailyRoutineActivity={handleDailyRoutineActivitiesChange}
          onChangeStructuredActivity={handleStructuredActivitiesChange}
        />
        <div ref={assignmentRegister.ref} tabIndex={0} tw="text-red-500 text-xs italic">
          {assignmentRegister.error?.message ?? ''}
        </div>
      </>
      <div tw="flex mt-10 mb-2">
        <h4 tw="text-sm font-semibold">Feedback for Client or Caregiver</h4>
        <h4 tw="text-sm text-gray-400 ml-2">Content will be shared with Client in PDFs</h4>
      </div>
      <div>
        <Textarea
          onChange={e => {
            const newValue = e.target.value;
            const newLength = newValue.length;

            setClientCommunicationLength(newLength);
            setTextLimitExceeded(newLength > MAX_SMS_CHARACTERS);

            setActivity(
              produce(activity => {
                const content = activity.note.content as SessionNoteContent;
                content.feedbackForClient = e.target.value;
              }),
            );

            if (onChange) {
              onChange();
            }
          }}
          value={formContent.feedbackForClient}
          name="feedbackForClient"
          id="feedbackForClient"
          data-testid="feedbackForClient"
          tw="w-full mb-0"
          rows={4}
          {...registerField('/note/content/feedbackForClient')}
        />
        <div className={`text-right text-sm ${isTextLimitExceeded ? 'text-red-500' : 'text-gray-500'}`}>
          {clientCommunicationLength}/{MAX_SMS_CHARACTERS} characters
        </div>
      </div>
      <div tw="flex mt-8 mb-2">
        <h4 tw="text-sm font-semibold">Response to Treatment / Assessment</h4>
        <h4 tw="text-sm text-gray-400 ml-2">Content will be shared with Client in PDFs</h4>
      </div>
      <Textarea
        name="responseForTreatment"
        id="responseForTreatment"
        data-testid="session-note-form-content-response-to-treatment-assessment"
        tw="w-full"
        rows={4}
        value={formContent.responseToTreatment}
        onChange={e => {
          setActivity(
            produce(activity => {
              const content = activity.note.content as SessionNoteContent;
              content.responseToTreatment = e.target.value;
            }),
          );

          if (onChange) {
            onChange();
          }
        }}
        {...registerField('/note/content/responseToTreatment')}
      />
      <div tw="flex mt-8 mb-2">
        <h4 tw="text-sm font-semibold">Plans for Next Session</h4>
      </div>
      <Textarea
        name="plansForNextSession"
        id="plansForNextSession"
        data-testid="session-note-form-content-response-plans-for-next-session"
        tw="w-full"
        rows={4}
        value={formContent.plansForNextSession}
        onChange={e => {
          setActivity(
            produce(activity => {
              const content = activity.note.content as SessionNoteContent;
              content.plansForNextSession = e.target.value;
            }),
          );

          if (onChange) {
            onChange();
          }
        }}
        {...registerField('/note/content/plansForNextSession')}
      />
      {ClientGoalsModalIsOpen && (
        <ClientGoalsModal
          isOpen={ClientGoalsModalIsOpen}
          onClose={ClientGoalsModalOnClose}
          clientId={clientId}
          goal={currentGoal}
          action={currentAction}
          setCurrentAction={setCurrentAction}
          shouldLogEvent={false}
        />
      )}
    </div>
  );
};
