/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react';
import { Container, Card } from '@expressable/ui-library';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Sentry from '@sentry/react';
import 'twin.macro';

type ErrorBoundaryProps = {
  children: React.ReactNode;
};

type ErrorBoundaryState = {
  hasError: boolean;
};

export class AppointmentNoteFormErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    Sentry.captureException(error, { data: errorInfo });
    console.error('ErrorBoundary caught an error', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Container data-testid="error-boundary" tw="relative pl-0" size="large">
          <Card tw="mb-4">
            <FontAwesomeIcon icon={faExclamationTriangle} tw="text-red-500 mr-2" />
            There was an error while trying to load this appointment.
          </Card>
        </Container>
      );
    }

    return this.props.children;
  }
}
