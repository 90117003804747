import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { dynamicHttp } from '@expressable/utils';
import { useToasts } from 'react-toast-notifications';
import { presentMutationError } from '@expressable/ui-library';
import { GoalType } from 'types';

const httpHealthRecordProxy = dynamicHttp('health-record-proxy');

interface GoalProgress {
  createdAt: string;
  createdByDisplayName: string;
  createdByUsername: string;
  progressComparison: string | null;
  progressLevel: number | null;
  updatedAt: string;
}

export interface Goal {
  createdAt: string;
  updatedAt: string;
  dueAt?: string;
  version: string;
  treatmentArea: string;
  detail: ShortTermGoalDetail | LongTermGoalDetail;
  ltgID: string;
  stgID: string;
  status: string;
  goalType: GoalType;
  hasSTGs: boolean;
  progress: GoalProgress;
}

export function isGoal(x: any): x is Goal {
  return x && 'ltgID' in x;
}

export interface LongTermGoalDetail {
  goalTime: string;
  goalsTimeUnit: string;
  goalDescription: string;
  goalCues: string;
  goalOpportunity: string;
  goalOpportunityUnit: string;
  goalPeriod: string;
  goalPeriodUnit: string;
  goalOutcome: string;
  goalTreatmentArea: string;
  goalBaselinePerformance: string;
}

export interface LongTermGoal {
  version: '1.0';
  goalType: 'ltg';
  status: string;
  detail: LongTermGoalDetail;
  shouldLogEvent: boolean;
}

export interface CreateLongTermGoalPayload {
  clientId: string;
  goal: LongTermGoal;
}

export interface DeleteLongTermGoal {
  clientId: string;
  ltgId?: string;
}

const createLongTermGoal = async (payload: CreateLongTermGoalPayload) => {
  const { clientId, ...rest } = payload;
  return httpHealthRecordProxy.post(`/care-plans/goals/${clientId}`, rest.goal).then(res => res.data);
};

export function useCreateLongTermGoal() {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(createLongTermGoal, {
    onSuccess: (_, payload) => {
      addToast('Long Term Goal Successfully Created', { appearance: 'success', autoDismiss: true });
      queryClient.invalidateQueries(['care-plan-goals', payload.clientId]);
    },
    onError: presentMutationError,
  });
}

export interface UpdateLongTermGoalPayload {
  clientId: string;
  ltgId?: string;
  goal: LongTermGoal;
}

const updateLongTermGoal = async ({ clientId, ltgId, goal }: UpdateLongTermGoalPayload) => {
  return httpHealthRecordProxy.put(`/care-plans/goals/${clientId}/${ltgId}`, goal).then(res => res.data);
};

export function useUpdateLongTermGoal() {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(updateLongTermGoal, {
    onSuccess: (_, payload) => {
      addToast('Long Term Goal Successfully Updated', { appearance: 'success', autoDismiss: true });
      queryClient.invalidateQueries(['care-plan-goals', payload.clientId]);
    },
    onError: presentMutationError,
  });
}

const removeLongTermGoal = async (payload: DeleteLongTermGoal) => {
  const { clientId, ltgId } = payload;
  return httpHealthRecordProxy.delete(`/care-plans/goals/${clientId}/${ltgId}`).then(res => res.data);
};

export function useRemoveLongTermGoal() {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(removeLongTermGoal, {
    onSuccess: (_, payload) => {
      addToast('Goal Successfully Removed', { appearance: 'success', autoDismiss: true });
      queryClient.invalidateQueries(['care-plan-goals', payload.clientId]);
    },
    onError: presentMutationError,
  });
}

export interface ShortTermGoalDetail {
  goalTime: string;
  goalsTimeUnit: string;
  goalDescription: string;
  goalCues: string;
  goalOpportunity: string;
  goalOpportunityUnit: string;
  goalPeriod: string;
  goalPeriodUnit: string;
  goalOutcome: string;
  goalTreatmentArea: string;
  goalBaselinePerformance: string;
}

export interface ShortTermGoal {
  version: '1.0';
  goalType: 'stg';
  status: string;
  detail: ShortTermGoalDetail;
  shouldLogEvent: boolean;
}

export interface CreateShortTermGoalPayload {
  clientId: string;
  ltgId: string;
  goal: ShortTermGoal;
}

export interface DeleteShortTermGoal {
  clientId: string;
  ltgId?: string;
  stgId?: string;
}

const createShortTermGoal = async (payload: CreateShortTermGoalPayload) => {
  const { clientId, ltgId, ...rest } = payload;
  return httpHealthRecordProxy.post(`/care-plans/goals/${clientId}/${ltgId}`, rest.goal).then(res => res.data);
};

export function useCreateShortTermGoal() {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(createShortTermGoal, {
    onSuccess: (_, payload) => {
      addToast('Short Term Goal Successfully Created', { appearance: 'success', autoDismiss: true });
      queryClient.invalidateQueries(['care-plan-goals', payload.clientId]);
    },
    onError: presentMutationError,
  });
}

export interface UpdateShortTermGoalPayload {
  clientId: string;
  ltgId: string;
  stgId?: string;
  goal: ShortTermGoal;
}

const updateShortTermGoal = async ({ clientId, ltgId, stgId, goal }: UpdateShortTermGoalPayload) => {
  return httpHealthRecordProxy.put(`/care-plans/goals/${clientId}/${ltgId}/${stgId}`, goal).then(res => res.data);
};

export function useUpdateShortTermGoal() {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(updateShortTermGoal, {
    onSuccess: (_, payload) => {
      addToast('Short Term Goal Successfully Updated', { appearance: 'success', autoDismiss: true });
      queryClient.invalidateQueries(['care-plan-goals', payload.clientId]);
    },
    onError: presentMutationError,
  });
}

const removeShortTermGoal = async (payload: DeleteShortTermGoal) => {
  const { clientId, ltgId, stgId } = payload;
  return httpHealthRecordProxy.delete(`/care-plans/goals/${clientId}/${ltgId}/${stgId}`).then(res => res.data);
};

export function useRemoveShortTermGoal() {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(removeShortTermGoal, {
    onSuccess: (_, payload) => {
      addToast('Short Term Goal Successfully Removed', { appearance: 'success', autoDismiss: true });
      queryClient.invalidateQueries(['care-plan-goals', payload.clientId]);
    },
    onError: presentMutationError,
  });
}

const getGoals = async (clientId: string) => {
  const { data } = await httpHealthRecordProxy.get(`/care-plans/goals/${clientId}`);
  return data;
};

export function useGetGoals({ clientId }: { clientId: string }, options?: UseQueryOptions<Goal[], unknown, Goal[]>) {
  return useQuery<Goal[]>(['care-plan-goals', clientId], () => getGoals(clientId), options);
}

export interface GetStgProgressHistoryPayload {
  clientID: string;
  noteID: string;
  ltgID: string;
  createdAt: string;
  progressLevel: number | null;
  goalDescription: string;
  progressComparison: string | null;
  createdByDisplayName: string;
  updatedAt: string;
  appointmentOn: string;
  gpID: string;
  createdByUsername: string;
  stgID: string;
  progressUnit: string;
}

export const getStgProgressHistory = async (clientID: string, ltgID?: string, stgID?: string) => {
  const { data } = await httpHealthRecordProxy.get(`/care-plans/goals/${clientID}/${ltgID}/${stgID}/progress`);
  return data;
};
