import { useFormChangeWatch } from 'hooks/common/useFormChangeWatch';

// This is an empty component used to watch for ANY form changes without triggering re-renders higher in the tree

type Props = {
  onChange: () => void;
  throttleDuration?: number;
  blocker?: boolean;
};

export const FormChangeWatcher = ({ onChange, throttleDuration = 1000, blocker = false }: Props) => {
  useFormChangeWatch(onChange, throttleDuration, blocker);
  return null;
};
