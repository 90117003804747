import { string, object, ObjectSchema } from 'yup';

export type ClientMatchingSchema = {
  appointmentType: string;
  sessionType?: string;
};

export const clientMatchingSchema: ObjectSchema<ClientMatchingSchema> = object({
  appointmentType: string().required(),
  sessionType: string().when('appointmentType', {
    is: 'session',
    then: ({ required }) => required(),
  }),
});
