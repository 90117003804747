/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Card } from '@expressable/ui-library';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { formClientWizardPayload } from 'components/therapistMatcher/data/submit';
import { ClientWizardStepsData, StepId } from 'components/therapistMatcher/data/useTherapistMatcher';
import { useCreateClient } from 'hooks/use-client';
import { BILLING_LINK_TYPES } from 'pages/new-client-create/BillingInformation/options';
import { getBillingIntakeURL, getInsuranceInfo } from 'pages/new-client-create/ReviewSummary/data';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import 'twin.macro';
import ReadOnlyField from '../ReadOnlyField';
import ScheduleSummary from '../ScheduleSummary';
import ReadOnlyFieldWithValue from '../ReadOnlyFieldWithValue';
import { useClientContractedBusiness } from 'hooks/use-client-contracted-business';
import { toStateAbbrev } from 'utils/states';
import { CueError, getCueError } from 'utils/error';

interface ReviewSummaryProps {
  stepsData: Partial<ClientWizardStepsData>;
  onNavigateToStep: (stepIndex: number) => void;
  stepsState: boolean[];
  onSubmitError: (error: CueError) => void;
}

const getIntakeMethodLabel = (intakeTypeLabel?: string, intakeTypeValue?: string) => {
  if (!intakeTypeLabel || !intakeTypeValue) {
    return '-';
  }

  return intakeTypeValue === 'no_intake' ? intakeTypeLabel : `Send Intake email/SMS for ${intakeTypeLabel}`;
};

const ReviewSummary = ({ stepsData, onNavigateToStep, stepsState, onSubmitError }: ReviewSummaryProps) => {
  const scheduledSessionsInfo = stepsData[StepId.scheduleSessions];
  const clientInfo = stepsData[StepId.clientInfo];
  const billingInfo = stepsData[StepId.billingInfo];
  const firstRecurringSessionInfo =
    scheduledSessionsInfo && !scheduledSessionsInfo.skipped ? scheduledSessionsInfo?.firstRecurringSessionInfo : null;
  const isEvaluationOnly = firstRecurringSessionInfo?.recurringOption === 'EvaluationOnly' ? true : false;
  const evaluationOnlyTherapistName = scheduledSessionsInfo?.evaluationTime?.therapist?.therapistName;

  const intakeMethodLabel = getIntakeMethodLabel(clientInfo?.intakeType.label, clientInfo?.intakeType.value);
  const { mutate: createClient, isLoading: isLoadingCreateClient } = useCreateClient();
  const { data: contractedBusiness, isLoading: isLoadingContractedBusiness } = useClientContractedBusiness({
    state: toStateAbbrev(clientInfo?.state?.value),
    payerID: billingInfo?.insuranceContent?.primaryInsurance?.insurancePayer?.value,
  });

  const history = useHistory();

  const billingIntakeURL = useMemo(
    () =>
      billingInfo &&
      !billingInfo.skipped &&
      clientInfo &&
      clientInfo.contactID &&
      billingInfo.billingLinkType?.value !== BILLING_LINK_TYPES.EXEMPT_FROM_PATIENT_RESPONSIBILITY &&
      getBillingIntakeURL(
        BILLING_LINK_TYPES[billingInfo!.billingLinkType!.value as keyof typeof BILLING_LINK_TYPES],
        clientInfo.state.value,
        clientInfo.contactID,
        contractedBusiness?.stripeEntity,
        billingInfo!.billingPriceTier!.value,
      ),
    [billingInfo, clientInfo, contractedBusiness],
  );

  const isDataComplete = useMemo(() => {
    return (
      true &&
      clientInfo &&
      billingInfo &&
      scheduledSessionsInfo &&
      stepsState[StepId.scheduleSessions] &&
      stepsState[StepId.billingInfo] &&
      stepsState[StepId.clientInfo]
    );
  }, [stepsData, stepsState]);

  const onSubmit = () => {
    if (!isDataComplete) {
      throw 'Incomplete data';
    }
    createClient(formClientWizardPayload(stepsData as ClientWizardStepsData), {
      onSuccess: (clientID: string) => {
        history.push(`/clients/${clientID}#overview`);
      },
      onError: err => {
        const error = getCueError(err);
        onSubmitError(error);
      },
    });
  };

  const sessionTherapistName = scheduledSessionsInfo?.skipped
    ? scheduledSessionsInfo.therapist
    : firstRecurringSessionInfo?.timestamp?.therapist;

  const insuranceInfo = getInsuranceInfo(billingInfo?.insuranceContent);

  const isLoading = isLoadingCreateClient || isLoadingContractedBusiness;

  return (
    <Card className="p-8 flex flex-col gap-16">
      <section>
        <h2 className="font-semibold text-xl mb-6">
          Client Summary{' '}
          {clientInfo && (
            <a
              className="text-indigo-700 hover:text-indigo-500 cursor-pointer text-sm font-semibold ml-2"
              onClick={() => onNavigateToStep(StepId.clientInfo)}
            >
              Edit
            </a>
          )}
        </h2>
        {clientInfo && stepsState[StepId.clientInfo] ? (
          <div tw="grid grid-rows-3 md:grid-flow-col grid-flow-row gap-6 max-w-[850px]">
            <ReadOnlyField label="Client Name">
              {`${clientInfo.clientFirstName} ${clientInfo.clientMiddleName} ${clientInfo.clientLastName} ${
                clientInfo.clientNickname ? `(${clientInfo.clientNickname})` : ''
              }`}
            </ReadOnlyField>
            <ReadOnlyField label="Primary Contact">
              {clientInfo.isClientSelf
                ? `${clientInfo.clientFirstName} ${clientInfo.clientMiddleName} ${clientInfo.clientLastName}`
                : `${clientInfo.contactName} ${clientInfo.contactMiddleName} ${clientInfo.contactLastName}`}
            </ReadOnlyField>
            <ReadOnlyField label="Date of Birth">
              {clientInfo.dateOfBirth?.isValid() ? clientInfo.dateOfBirth.format('MM/DD/YYYY') : '-'}
            </ReadOnlyField>

            <ReadOnlyField label="Primary Therapist">
              {isEvaluationOnly ? evaluationOnlyTherapistName : sessionTherapistName?.therapistName ?? '-'}
            </ReadOnlyField>

            <ReadOnlyField label="Primary Contact Phone">{clientInfo.phone}</ReadOnlyField>
            <ReadOnlyField label="Primary Contact Email">{clientInfo.email}</ReadOnlyField>
          </div>
        ) : (
          <div>
            Please{' '}
            <a
              className="underline hover:no-underline cursor-pointer"
              onClick={() => onNavigateToStep(StepId.clientInfo)}
            >
              confirm client information
            </a>
          </div>
        )}
      </section>

      <section>
        <h2 className="font-semibold text-xl mb-6">
          Scheduled Sessions{' '}
          {scheduledSessionsInfo && (
            <a
              className="text-indigo-700 hover:text-indigo-500 cursor-pointer text-sm font-semibold ml-2"
              onClick={() => onNavigateToStep(StepId.scheduleSessions)}
            >
              Edit
            </a>
          )}
        </h2>
        {scheduledSessionsInfo && stepsState[StepId.scheduleSessions] ? (
          <ScheduleSummary scheduledSessionsInfo={scheduledSessionsInfo} />
        ) : (
          <div>
            Please{' '}
            <a
              className="underline hover:no-underline cursor-pointer"
              onClick={() => onNavigateToStep(StepId.scheduleSessions)}
            >
              schedule sessions
            </a>
          </div>
        )}
      </section>

      <section>
        <h2 className="font-semibold text-xl mb-6">
          Billing{' '}
          {billingInfo && (
            <a
              className="text-indigo-700 hover:text-indigo-500 cursor-pointer text-sm font-semibold ml-2"
              onClick={() => onNavigateToStep(StepId.billingInfo)}
            >
              Edit
            </a>
          )}
        </h2>
        {billingInfo && stepsState[StepId.billingInfo] ? (
          <div className="flex flex-col gap-6">
            {billingInfo && (
              <>
                <ReadOnlyField label="Intake Method">{intakeMethodLabel}</ReadOnlyField>
                <ReadOnlyField label="Billing Intake Link">
                  {billingIntakeURL &&
                  billingInfo.billingLinkType?.value !== BILLING_LINK_TYPES.EXEMPT_FROM_PATIENT_RESPONSIBILITY ? (
                    <>
                      {billingInfo!.billingLinkType!.label}:{' '}
                      <a
                        className="text-indigo-700 hover:text-indigo-500 cursor-pointer"
                        href={billingIntakeURL}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {billingIntakeURL}
                      </a>
                    </>
                  ) : billingInfo.billingLinkType?.value === BILLING_LINK_TYPES.EXEMPT_FROM_PATIENT_RESPONSIBILITY ? (
                    'Exempt from patient responsibility'
                  ) : (
                    'Please complete the remaining steps get the billing intake link'
                  )}
                </ReadOnlyField>

                {insuranceInfo &&
                  !billingInfo.skipped &&
                  (billingInfo.billingLinkType?.value === BILLING_LINK_TYPES.DEPOSIT_ONLY ||
                    billingInfo.billingLinkType?.value === BILLING_LINK_TYPES.EXEMPT_FROM_PATIENT_RESPONSIBILITY) && (
                    <>
                      <h2 className="font-semibold text-md">Insurance Details</h2>
                      <div tw="grid grid-flow-row md:grid-cols-2 xl:grid-cols-4 gap-6">
                        <ReadOnlyFieldWithValue label="Network Status">
                          {insuranceInfo.networkStatus}
                        </ReadOnlyFieldWithValue>
                        <ReadOnlyFieldWithValue label="Automatic co-insurance collections">
                          {insuranceInfo.automaticCoInsuranceCollection}
                        </ReadOnlyFieldWithValue>
                        <ReadOnlyFieldWithValue label="Co-pay">{insuranceInfo.coPay}</ReadOnlyFieldWithValue>
                      </div>
                      {insuranceInfo.primaryInsurance && insuranceInfo.hasPrimaryInsuranceInfo && (
                        <>
                          <h2 className="font-semibold text-md">Primary Insurance Information</h2>
                          <div tw="grid grid-flow-row md:grid-cols-2 xl:grid-cols-4 gap-6">
                            <ReadOnlyFieldWithValue label="Payer Type">
                              {insuranceInfo.primaryInsurance.insuredPayerType}
                            </ReadOnlyFieldWithValue>
                            <ReadOnlyFieldWithValue label="Client's Relationship to Insured">
                              {insuranceInfo.primaryInsurance.insuredRelationshipToClient}
                            </ReadOnlyFieldWithValue>
                            <ReadOnlyFieldWithValue label="Insured's First Name">
                              {insuranceInfo.primaryInsurance.insuredFirstName}
                            </ReadOnlyFieldWithValue>
                            <ReadOnlyFieldWithValue label="Insured's Middle Name">
                              {insuranceInfo.primaryInsurance.insuredMiddleName}
                            </ReadOnlyFieldWithValue>
                            <ReadOnlyFieldWithValue label="Insured's Last Name">
                              {insuranceInfo.primaryInsurance.insuredLastName}
                            </ReadOnlyFieldWithValue>
                            <ReadOnlyFieldWithValue label="Insured's Date of Birth">
                              {insuranceInfo.primaryInsurance.insuredDob}
                            </ReadOnlyFieldWithValue>
                            <ReadOnlyFieldWithValue label="Insured's Gender">
                              {insuranceInfo.primaryInsurance.insuredGender}
                            </ReadOnlyFieldWithValue>
                            <ReadOnlyFieldWithValue label="Insured's Address 1">
                              {insuranceInfo.primaryInsurance.insuredAddress1}
                            </ReadOnlyFieldWithValue>
                            <ReadOnlyFieldWithValue label="Insured's Address 2">
                              {insuranceInfo.primaryInsurance.insuredAddress2}
                            </ReadOnlyFieldWithValue>
                            <ReadOnlyFieldWithValue label="Insured's City">
                              {insuranceInfo.primaryInsurance.city}
                            </ReadOnlyFieldWithValue>
                            <ReadOnlyFieldWithValue label="Insured's State">
                              {insuranceInfo.primaryInsurance.state}
                            </ReadOnlyFieldWithValue>
                            <ReadOnlyFieldWithValue label="Insured's Zip Code">
                              {insuranceInfo.primaryInsurance.zipCode}
                            </ReadOnlyFieldWithValue>
                            <ReadOnlyFieldWithValue label="Insurance Payer for Claims">
                              {insuranceInfo.primaryInsurance.insurancePayer}
                            </ReadOnlyFieldWithValue>
                            <ReadOnlyFieldWithValue label="Insurance Plan">
                              {insuranceInfo.primaryInsurance.insurancePlanEligibility}
                            </ReadOnlyFieldWithValue>
                            <ReadOnlyFieldWithValue label="Member ID">
                              {insuranceInfo.primaryInsurance.insuredMemberID}
                            </ReadOnlyFieldWithValue>
                            <ReadOnlyFieldWithValue label="Group ID">
                              {insuranceInfo.primaryInsurance.insuredGroupID}
                            </ReadOnlyFieldWithValue>
                            {billingInfo.billingLinkType?.value ===
                              BILLING_LINK_TYPES.EXEMPT_FROM_PATIENT_RESPONSIBILITY && (
                              <ReadOnlyField label="Exempt from patient responsibility?">Yes</ReadOnlyField>
                            )}
                          </div>
                        </>
                      )}
                      {insuranceInfo.secondaryInsurance && insuranceInfo.hasSecondaryInsuranceInfo && (
                        <>
                          <h2 className="font-semibold text-md">Secondary Insurance Information</h2>
                          <div tw="grid grid-flow-row md:grid-cols-2 xl:grid-cols-4 gap-6">
                            <ReadOnlyFieldWithValue label="Payer Type">
                              {insuranceInfo.secondaryInsurance.insuredPayerType}
                            </ReadOnlyFieldWithValue>
                            <ReadOnlyFieldWithValue label="Client's Relationship to Insured">
                              {insuranceInfo.secondaryInsurance.insuredRelationshipToClient}
                            </ReadOnlyFieldWithValue>
                            <ReadOnlyFieldWithValue label="Insured's First Name">
                              {insuranceInfo.secondaryInsurance.insuredFirstName}
                            </ReadOnlyFieldWithValue>
                            <ReadOnlyFieldWithValue label="Insured's Middle Name">
                              {insuranceInfo.secondaryInsurance.insuredMiddleName}
                            </ReadOnlyFieldWithValue>
                            <ReadOnlyFieldWithValue label="Insured's Last Name">
                              {insuranceInfo.secondaryInsurance.insuredLastName}
                            </ReadOnlyFieldWithValue>
                            <ReadOnlyFieldWithValue label="Insured's Date of Birth">
                              {insuranceInfo.secondaryInsurance.insuredDob}
                            </ReadOnlyFieldWithValue>
                            <ReadOnlyFieldWithValue label="Insured's Gender">
                              {insuranceInfo.secondaryInsurance.insuredGender}
                            </ReadOnlyFieldWithValue>
                            <ReadOnlyFieldWithValue label="Insured's Address 1">
                              {insuranceInfo.secondaryInsurance.insuredAddress1}
                            </ReadOnlyFieldWithValue>
                            <ReadOnlyFieldWithValue label="Insured's Address 2">
                              {insuranceInfo.secondaryInsurance.insuredAddress2}
                            </ReadOnlyFieldWithValue>
                            <ReadOnlyFieldWithValue label="Insured's City">
                              {insuranceInfo.secondaryInsurance.city}
                            </ReadOnlyFieldWithValue>
                            <ReadOnlyFieldWithValue label="Insured's State">
                              {insuranceInfo.secondaryInsurance.state}
                            </ReadOnlyFieldWithValue>
                            <ReadOnlyFieldWithValue label="Insured's Zip Code">
                              {insuranceInfo.secondaryInsurance.zipCode}
                            </ReadOnlyFieldWithValue>
                            <ReadOnlyFieldWithValue label="Insurance Payer for Claims">
                              {insuranceInfo.secondaryInsurance.insurancePayer}
                            </ReadOnlyFieldWithValue>
                            <ReadOnlyFieldWithValue label="Insurance Plan">
                              {insuranceInfo.secondaryInsurance.insurancePlanEligibility}
                            </ReadOnlyFieldWithValue>
                            <ReadOnlyFieldWithValue label="Member ID">
                              {insuranceInfo.secondaryInsurance.insuredMemberID}
                            </ReadOnlyFieldWithValue>
                            <ReadOnlyFieldWithValue label="Group ID">
                              {insuranceInfo.secondaryInsurance.insuredGroupID}
                            </ReadOnlyFieldWithValue>
                          </div>
                        </>
                      )}
                      {insuranceInfo.insuranceAuthorizations && insuranceInfo.hasInsuranceAuthInfo && (
                        <>
                          <h2 className="font-semibold text-md">Authorizations</h2>
                          <div tw="grid grid-flow-row md:grid-cols-2 xl:grid-cols-4 gap-6">
                            <ReadOnlyFieldWithValue label="Prior Authorization Number">
                              {insuranceInfo.insuranceAuthorizations.priorAuthorizationNumber}
                            </ReadOnlyFieldWithValue>
                            <ReadOnlyFieldWithValue label="Evaluation Authorization Status">
                              {insuranceInfo.insuranceAuthorizations.evaluationAuthStatus}
                            </ReadOnlyFieldWithValue>
                            <ReadOnlyFieldWithValue label="Session Authorization Status">
                              {insuranceInfo.insuranceAuthorizations.sessionAuthStatus}
                            </ReadOnlyFieldWithValue>
                            <ReadOnlyFieldWithValue label="Are there any visit limits?">
                              {insuranceInfo.insuranceAuthorizations.visitLimits}
                            </ReadOnlyFieldWithValue>
                            <ReadOnlyFieldWithValue label="Remaining Visits Left">
                              {insuranceInfo.insuranceAuthorizations.remainingVisitsLeft}
                            </ReadOnlyFieldWithValue>
                            <ReadOnlyFieldWithValue label="Are there any date limits?">
                              {insuranceInfo.insuranceAuthorizations.dateLimits}
                            </ReadOnlyFieldWithValue>
                            <ReadOnlyFieldWithValue label="Visits Completed By">
                              {insuranceInfo.insuranceAuthorizations.visitsCompletedBy}
                            </ReadOnlyFieldWithValue>
                            <ReadOnlyFieldWithValue label="Is a re-evaluation required for this plan?">
                              {insuranceInfo.insuranceAuthorizations.reEvaluationRequired}
                            </ReadOnlyFieldWithValue>
                            <ReadOnlyFieldWithValue label="Re-evaluation Due By">
                              {insuranceInfo.insuranceAuthorizations.reEvaluationDueBy}
                            </ReadOnlyFieldWithValue>
                          </div>
                        </>
                      )}
                    </>
                  )}
              </>
            )}
          </div>
        ) : (
          <div>
            Please{' '}
            <a
              className="underline hover:no-underline cursor-pointer"
              onClick={() => onNavigateToStep(StepId.billingInfo)}
            >
              confirm billing information
            </a>
          </div>
        )}
      </section>

      <div className="flex items-center gap-2 -mt-9">
        <Button
          className={classNames({ 'cursor-not-allowed': !isDataComplete || isLoading })}
          variant="primary"
          disabled={!isDataComplete || isLoading}
          onClick={onSubmit}
          data-testid="confirm-new-client"
        >
          Confirm New Client
        </Button>

        {isLoading && <FontAwesomeIcon icon={faCircleNotch} spin />}
      </div>
    </Card>
  );
};

export default ReviewSummary;
