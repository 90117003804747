import { SelectOption } from 'types';
import { BadgeVariants } from './types';

export const AssignmentStatusOptions = [
  { value: '', label: 'All Assignments' },
  { value: 'complete', label: 'Complete' },
  { value: 'incomplete', label: 'Incomplete' },
  { value: 'unassigned', label: 'Unassigned' },
];

export const BADGE_VARIANTS: BadgeVariants = {
  complete: {
    variant: 'success',
    color: 'text-green-900',
    text: 'COMPLETE',
  },
  incomplete: {
    variant: 'warning',
    color: 'text-red-900',
    text: 'INCOMPLETE',
  },
  unassigned: {
    variant: 'disabled',
    color: '',
    text: 'UNASSIGNED',
  },
};

/**
 * @note(juan): When filtering by phonemes we search for a specific keyword in the SA's title. These phonemes are written in a technical way like /r/, [er], etc
 * Contentful allows you to query the titles but it ignores many special characters like [ and /. Which we need to properly find only the titles that we want
 * Because of this we're forced to do this filtering on the client side
 *
 * The property contentfulFilter is used to reduce the number of entries we get from contentful in the first place. This is a more relaxed filter
 * that doesn't do all the filtering we need. Its simply used to try to reduce the response from contentful so that we don't hit the limit
 */

export type ClinicalPhoneme = {
  label: string;
  values: string[];
  /** Used to do some trimming on the contentful side to reduce the number of responses previous to the client-side filter */
  contentfulFilter?: string;
};

export const CLINICAL_PHONEMES: ClinicalPhoneme[] = [
  { label: `/r/`, values: [`/r/`] },
  { label: `/s/`, values: [`/s/`] },
  { label: `/l/`, values: [`/l/`] },
  { label: `/k/`, values: [`/k/`] },
  { label: `/g/`, values: [`/g/`] },
  { label: `/d/`, values: [`/d/`] },
  { label: `/h/`, values: [`/h/`] },
  { label: `/t/`, values: [`/t/`] },
  { label: `/p/`, values: [`/p/`] },
  { label: `/b/`, values: [`/b/`] },
  { label: `/m/`, values: [`/m/`] },
  { label: `/w/`, values: [`/w/`] },
  { label: `/n/`, values: [`/n/`] },
  { label: `/f/`, values: [`/f/`] },
  { label: `/v/`, values: [`/v/`] },
  { label: `/rl/`, values: [`/rl/`] },
  { label: `/ñ/`, values: [`/ñ/`] },
  { label: `flapped /t/`, values: [`flapped /t/`] },
  { label: `/j/ or "Y"`, values: [`/j/`] },
  { label: `/s/-blends`, values: [`/s/-blends`] },
  { label: `/l/-blends`, values: [`/l/-blends`] },
  { label: `/r/-blends`, values: [`/r/-blends`] },
  { label: `vocalic /r/`, values: [`Vocalic /r/`] },
  { label: `[th]`, values: [`[th]`, ' TH '], contentfulFilter: `th` },
  { label: `[er]`, values: [`[er]`, ' ER '], contentfulFilter: `er` },
  { label: `[or]`, values: [`[or]`, ' OR '], contentfulFilter: `or` },
  { label: `[air]`, values: [`[air]`, ' AIR '], contentfulFilter: `air` },
  { label: `[ar]`, values: [`[ar]`, ' AR '], contentfulFilter: `ar` },
  { label: `[ear]`, values: [`[ear]`, ' EAR '], contentfulFilter: `ear` },
  { label: `[ire]`, values: [`[ire]`, ' IRE '], contentfulFilter: `ire` },
  { label: `[zh]`, values: [`[zh]`, ' ZH '], contentfulFilter: `zh` },
  { label: `[dz] or "J"`, values: [`"J"`] },
  { label: `[ng]`, values: [`[ng]`, ' NG '], contentfulFilter: `ng` },
  { label: `[sh]`, values: [`[sh]`, ' SH '], contentfulFilter: `sh` },
  { label: `[ch]`, values: [`[ch]`, ' CH '], contentfulFilter: `ch` },
  { label: `long A (as in bait)`, values: [`Long A`] },
  { label: `short A (as in had)`, values: [`Short A`] },
  { label: `long E (as in beat)`, values: [`Long E`] },
  { label: `short E (as in bet)`, values: [`Short E`] },
  { label: `long I (as in hide)`, values: [`Long I`] },
  { label: `short I (as in bit)`, values: [`Short I`] },
  { label: `long O (as in boat)`, values: [`Long O`] },
  { label: `short O (as in not)`, values: [`Short O`] },
  { label: `long U (as in boot)`, values: [`Long U`] },
  { label: `short U (as in sun)`, values: [`Short U`] },
  { label: `“oo” (as in book)`, values: [`oo`] },
  { label: `“ow” (as in cow)`, values: [`ow`] },
  { label: `“awe” (as in dawn)`, values: [`awe`] },
  { label: `“oi” (as in boy)`, values: [`oi`] },
].map(phoneme => {
  if (phoneme.contentfulFilter) {
    return phoneme;
  } else {
    return {
      ...phoneme,
      contentfulFilter: phoneme.values[0],
    };
  }
});

const portalClientAppBaseUrl = process.env.REACT_APP_PORTAL_CLIENT_APP_URL || 'https://app.expressable.io';

export const getLearningPathLink = (learningPathId: string) => {
  return `${portalClientAppBaseUrl}/assignments/preview/learningPath/${learningPathId}`;
};

export const getAdlLink = (learningPathId: string, adlId: string) => {
  return `${portalClientAppBaseUrl}/assignments/preview/adl/${learningPathId}/${adlId}`;
};

export const getStructuredActivityLink = (structuredActivityId: string) => {
  return `${portalClientAppBaseUrl}/assignments/preview/structuredActivity/${structuredActivityId}`;
};

export const getCustomStructuredActivityLink = (customStructuredActivityId: string) => {
  return `${portalClientAppBaseUrl}/assignments/preview/customStructuredActivity/${customStructuredActivityId}`;
};

export const ageRangeOptions: SelectOption<string>[] = [
  { label: '0-3', value: '0-3' },
  { label: '3-6', value: '3-6' },
  { label: '7-10', value: '7-10' },
  { label: '11-13', value: '11-13' },
  { label: '14+', value: '14+' },
  { label: 'Adult', value: 'Adult' },
];

export const timeRequiredOptions: SelectOption<string>[] = [
  { label: '1-5 Minutes', value: '1-5 minutes' },
  { label: '5-10 Minutes', value: '5-10 minutes' },
  { label: '15+ Minutes', value: '15+ minutes' },
];

export const complexityOptions: SelectOption<string>[] = [
  { label: 'Beginner', value: 'Beginner' },
  { label: 'Intermediate', value: 'Intermediate' },
  { label: 'Advanced', value: 'Advanced' },
];

export const homeworkTypeOptions: SelectOption<string>[] = [
  { label: 'Daily Routine (Generalization)', value: 'Daily Routine (Generalization)' },
  { label: 'Game', value: 'Game' },
  { label: 'Movement Activity', value: 'Movement Activity' },
  { label: 'On the Go', value: 'On the Go' },
  { label: 'Speech Sound Practice', value: 'Speech Sound Practice' },
  { label: 'Structured Worksheet', value: 'Structured Worksheet' },
  { label: 'Toy Play Time', value: 'Toy Play Time' },
  { label: 'Word Play Time', value: 'Word Play Time' },
];
