import { useState } from 'react';
import { Input, Label, Select } from '@expressable/ui-library';
import {  useWatch } from 'react-hook-form';
import { SubmitButton } from 'components/Forms';

import { ControlledFormField } from 'components/Forms/FormFieldControlled';
import {
  billingLinkTypeOptions,
  billingPriceTierOptions,
  BILLING_LINK_TYPES,
} from 'pages/new-client-create/BillingInformation/options';
import 'twin.macro';
import { IBillingInfoInsuranceForm, ICompleteClientInformation, SelectOption } from 'types';
import BillingInfoInsuranceForm, { InsuranceFormResult } from 'pages/client/components/billing-info-insurance-form';
import { ClientInformationFormData } from '../ClientInformation';

export interface BillingInformationFormUiProps {
  onInsuranceFormChange: (result: IBillingInfoInsuranceForm) => void;
  onInvalidateStep: () => void;
  clientState?: ClientInformationFormData;
}

export const BillingInformationFormUi = ({ 
  onInsuranceFormChange, 
  onInvalidateStep, 
  clientState,
}: BillingInformationFormUiProps): JSX.Element => {
  const [isInsuranceFormValid, setIsInsuranceFormValid] = useState<boolean>();

  const skipped: boolean = useWatch({ name: 'skipped' });
  const billingLinkType: SelectOption<BILLING_LINK_TYPES> = useWatch({ name: 'billingLinkType' });

  const handleInsuranceFormChange = (result: InsuranceFormResult) => {
    setIsInsuranceFormValid(result.isValid);
    onInsuranceFormChange(result.fields);
    onInvalidateStep();
  }

  return (
    <>
      <div tw="mb-1 flex gap-2" data-testid="opt-out-of-billing">
        <ControlledFormField component={Input} name="skipped" label="" type={'checkbox'} spacing="tight" />
        <Label htmlFor="skipped" className="font-normal text-base" spacing="tight">
          Opt out of completing billing information
        </Label>
      </div>
      {!skipped && (
        <>
          <ControlledFormField
            component={Select}
            tw={'min-w-[420px]'}
            name="billingLinkType"
            spacing="tight"
            label="Services"
            options={billingLinkTypeOptions}
          />

          {billingLinkType?.value === BILLING_LINK_TYPES.EVAL_ONLY && (
            <ControlledFormField
              component={Select}
              tw={'min-w-[420px]'}
              name="billingPriceTier"
              spacing="tight"
              label="Pricing"
              options={billingPriceTierOptions}
            />
          )}

          {(billingLinkType?.value === BILLING_LINK_TYPES.DEPOSIT_ONLY || billingLinkType?.value === BILLING_LINK_TYPES.EXEMPT_FROM_PATIENT_RESPONSIBILITY) && (
            <BillingInfoInsuranceForm 
              clientData={mapClientStateToClient(clientState)}
              onChange={(result) => handleInsuranceFormChange(result)}
              exemptFromPatientResponsibility={billingLinkType?.value === BILLING_LINK_TYPES.EXEMPT_FROM_PATIENT_RESPONSIBILITY}
              isClientCreate
            />
          )}
        </>
      )}
      <SubmitButton tw="mt-5" 
        willRedirect={false} 
        disabled={Boolean(skipped === false && (billingLinkType?.value === BILLING_LINK_TYPES.DEPOSIT_ONLY || billingLinkType?.value === BILLING_LINK_TYPES.EXEMPT_FROM_PATIENT_RESPONSIBILITY) && !isInsuranceFormValid)}
        data-testid="confirm-billing-info"
        >
        {skipped ? 'Skip Billing Information' : 'Confirm Billing Information'}
      </SubmitButton>
    </>
  );
};

// as therapist matcher has a different structure from the standard client form, mapping these fields were necessary
const mapClientStateToClient = (clientState?: ClientInformationFormData) => {
  if (!clientState) {
    return undefined;
  }
  return {
    clientFirstName: clientState.clientFirstName,
    clientLastName: clientState.clientLastName,
    clientMiddleName: clientState.clientMiddleName,
    dob: clientState.dateOfBirth?.toISOString(),
    sex: clientState.sex?.value,
  } as ICompleteClientInformation;
}
