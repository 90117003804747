import { useQuery } from 'react-query';
import { dynamicHttp } from '@expressable/utils';
import { ContractedBusiness } from 'domain/insurance/types';
import { StateAbbrev } from 'utils/states';

const httpHealthRecord = dynamicHttp('health-record');

interface ClientContractedBusiness {
  state?: StateAbbrev;
  payerID?: string;
}

const getClientContractedBusiness = async (
  payload: ClientContractedBusiness,
): Promise<ContractedBusiness | undefined> => {
  if (!payload.state || !payload.payerID) {
    return undefined;
  }
  const { data } = await httpHealthRecord.get('/clients/contracted-business', {
    params: {
      state: payload.state,
      payerID: payload.payerID,
    },
  });

  return data;
};

export function useClientContractedBusiness(payload: ClientContractedBusiness) {
  return useQuery(
    ['clients-contracted-business', payload.state, payload.payerID],
    () => getClientContractedBusiness(payload),
    { refetchOnWindowFocus: false },
  );
}
