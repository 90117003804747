import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Button, Card, Select, Spacer, useDisclosure } from '@expressable/ui-library';
import { faMessageLines } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useClientCases, {
  CasesByClient,
  ClientCaseActivitiesField,
  ClientCaseActivitiesType,
  ClientCasesStatus,
  getSalesforceCaseURL,
} from 'hooks/use-client-cases';
import ClientCasesAddComments from './client-cases-add-comments';
import ClientCasesCreateModal from './client-cases-create-modal';
import { ICompleteClientInformation } from 'types';
import { useFlags } from 'launchdarkly-react-client-sdk';

const ClientCasesStatusOptions = {
  Open: {
    label: 'Open Cases',
    value: ClientCasesStatus.Open,
  },
  Closed: {
    label: 'Closed Cases',
    value: ClientCasesStatus.Closed,
  },
  All: {
    label: 'All Cases',
    value: ClientCasesStatus.All,
  },
};

const stripHTMLTags = (html?: string | null) => {
  if (!html) return '';

  // Create a new div element
  const temporalDivElement = document.createElement("div");

  // Set the HTML content with the providen
  temporalDivElement.innerHTML = html;

  // Retrieve the text property of the element
  return temporalDivElement.textContent || temporalDivElement.innerText || "";
}

const CardSuspense = () => (
  <Card className="animate-pulse px-8 mb-5">
    <div className="mt-6">
      <div className="overflow-hidden rounded-full bg-gray-300">
        <div className="h-2 bg-gray-100 rounded-full"></div>
      </div>
      <div className="grid grid-cols-4 mt-6">
        <div className="w-3/6 h-6 bg-gray-100"></div>
        <div className="w-3/6 h-6 bg-gray-100"></div>
        <div className="w-3/6 h-6 bg-gray-100"></div>
        <div className="w-3/6 h-6 bg-gray-100"></div>
      </div>
    </div>

    <div className="mt-10">
      <h3 className="bg-gray-300"></h3>
      <div className="grid grid-cols-2 mt-8 gap-6">
        <div className="grid grid-row-2">
          <div className="w-3/6 h-8 bg-gray-100"></div>
          <div className="w-3/6 h-8 bg-gray-100"></div>
        </div>
        <div className="grid grid-row-2">
          <div className="w-3/6 h-8 bg-gray-100"></div>
          <div className="w-3/6 h-8 bg-gray-100"></div>
        </div>
        <div className="grid grid-row-2">
          <div className="w-3/6 h-8 bg-gray-100"></div>
          <div className="w-3/6 h-8 bg-gray-100"></div>
        </div>
        <div className="grid grid-row-2">
          <div className="w-3/6 h-8 bg-gray-100"></div>
          <div className="w-3/6 h-8 bg-gray-100"></div>
        </div>
        <div className="grid grid-row-2">
          <div className="w-3/6 h-8 bg-gray-100"></div>
          <div className="w-3/6 h-8 bg-gray-100"></div>
        </div>
        <div className="grid grid-row-2">
          <div className="w-3/6 h-8 bg-gray-100"></div>
          <div className="w-3/6 h-8 bg-gray-100"></div>
        </div>
      </div>
      <div className="grid grid-row-2 mt-8">
        <div className="w-full h-24 bg-gray-100"></div>
      </div>
    </div>
  </Card>
);

interface ShowingCasesProps {
  cases: CasesByClient[];
  casesStatus: ClientCasesStatus;
  totalCases?: number;
}

const ShowingCases = ({ cases, casesStatus, totalCases }: ShowingCasesProps) => {
  if (!totalCases) return null;

  const caseOrCases = totalCases > 1 ? 'cases' : 'case';

  return (
    <span className="mb-2 text-sm">
      {casesStatus === ClientCasesStatus.All
        ? `Showing ${totalCases} ${caseOrCases}`
        : `Showing ${cases.length} out of ${totalCases} ${caseOrCases}`
      }
    </span>
  );
};

const ShowingCasesSuspense = () => (
  <span className="animate-pulse w-1/3 md:w-1/6 h-6 mb-2 bg-gray-200"></span>
);

const Unknown = () => <span className="text-gray-400">-</span>;

interface ClientCasesProps {
  clientId: string;
  isEnabled: boolean;
  totalCases?: number;
  clientData: ICompleteClientInformation;
}

export default function ClientCases(props: ClientCasesProps) {
  const { clientId, isEnabled, totalCases, clientData } = props;

  const [selectedCaseStatusFilter, setSelectedCaseStatusFilter] = useState(ClientCasesStatusOptions.Open);
  const {
    useClientCasesRecords,
  } = useClientCases(isEnabled);
  const {
    data: clientCasesRecordsData,
    isLoading: isClientCasesRecordsLoading,
    isRefetching: isClientCasesRecordsRefetching,
    refetch,
  } = useClientCasesRecords({ clientId, status: selectedCaseStatusFilter.value });
  const { casesByClient: cases = [] } = clientCasesRecordsData ?? {};
  const { cueAddClientCase } = useFlags();

  const handleOnChangeCaseStatus = (option: { label: string; value: ClientCasesStatus }) => {
    setSelectedCaseStatusFilter(option);
  };

  useEffect(() => {
    refetch();
  }, [selectedCaseStatusFilter]);

  const isLoading = isClientCasesRecordsLoading || isClientCasesRecordsRefetching;
  const isCasesNonEmpty = Array.isArray(cases) && cases?.length > 0;

  const {
    isOpen: createCaseModalIsOpen,
    onClose: createCaseModalOnClose,
    onOpen: createCaseModalOnOpen,
  } = useDisclosure();

  return (
    <div>
      <Spacer size="md" />
      <div className="grid grid-rows-1fr-auto">
        <div className="grid md:grid-flow-col md:grid-cols-auto-auto-1fr gap-6 mb-10">
          <h1 className="text-2xl font-semibold">Client Cases</h1>
          <div className="flex items-center">
            <span className="text-sm font-semibold mr-2 md:mx-2">View</span>
            <Select
              className="w-full md:w-80"
              value={selectedCaseStatusFilter}
              onChange={handleOnChangeCaseStatus}
              defaultValue={ClientCasesStatusOptions.Open}
              options={Object.values(ClientCasesStatusOptions)}
              placeholder="Select Case Status"
              spacing="tight"
            />
          </div>
          <div className="flex items-center">
            <span
              className={`text-sm text-indigo-${
                selectedCaseStatusFilter.value === ClientCasesStatus.All ? '400' : '600'
              } font-normal cursor-pointer`}
              onClick={() => setSelectedCaseStatusFilter(ClientCasesStatusOptions.All)}
            >
              View All Cases
            </span>
          </div>
         {cueAddClientCase && (
          <div className="flex items-center">
            <Button data-testid="create-new-case-modal-button" variant="secondary"  onClick={createCaseModalOnOpen}>
              Open New Case
            </Button>
          </div>
         )}
        </div>
        {isLoading ? (
          <ShowingCasesSuspense />
        ) : isCasesNonEmpty
            ? <ShowingCases
                cases={cases}
                casesStatus={selectedCaseStatusFilter.value}
                totalCases={totalCases}
              />
            : null
        }
      </div>
        {isLoading ? (
          <CardSuspense />
        ) : (
          isCasesNonEmpty
          ? cases
            .sort((a, b) => {
              const aDueDateUnix = dayjs(a.caseRecord.HealthCloudGA__DueDate__c).unix();
              const bDueDateUnix = dayjs(b.caseRecord.HealthCloudGA__DueDate__c).unix();

              if (!bDueDateUnix) {
                return -1;
              }

              return aDueDateUnix < bDueDateUnix ? -1 : 1;
            })
            .map(({ caseActivities, caseRecord = {}, caseStatusValues }) => (
              <Card key={caseRecord.Id} className="px-4 md:px-8 mb-5">
                <div className={`grid grid-cols-${caseStatusValues.length} mt-5`}>
                  {caseStatusValues.map((status, index, arr) => (
                    <div key={status} className="grid grid-row-2 mx-0.5">
                      <div
                        className={`${
                          index <= arr.indexOf(caseRecord.Status) ? 'bg-indigo-500' : 'bg-gray-300'
                        } ${
                          index == 0 ? 'rounded-l-full' : (index == arr.length -1 ? 'rounded-r-full' : '')
                        } w-full h-2`}
                      ></div>
                      <span className={`hidden md:block mt-2 text-sm text-center ${status === caseRecord.Status
                          ? 'font-semibold'
                          : 'font-medium text-gray-400'}`
                        }>
                          {status}
                      </span>
                    </div>
                  ))}
                  <span className={`block md:hidden mt-4 font-semibold text-lg text-center col-span-${caseStatusValues.length}`}>{caseRecord.Status}</span>
                </div>

                <div className="my-8 md:my-10">
                  <h3 className="text-xl font-semibold">Case Details</h3>
                  <div className="grid grid-cols-2 mt-8 gap-6 text-base">
                    <div className="grid grid-row-2">
                      <div className="font-semibold">Case ID</div>
                      <div className="text-sm">
                        <a className="text-indigo-700" href={getSalesforceCaseURL(caseRecord.Id)} target="_blank" rel="noreferrer">
                          {caseRecord.Id}
                        </a>
                      </div>
                    </div>
                    <div className="grid grid-row-2">
                      <div className="font-semibold">Case Number</div>
                      <div className="text-sm">{caseRecord.CaseNumber ?? <Unknown />}</div>
                    </div>
                    <div className="grid grid-row-2">
                      <div className="font-semibold">Case Owner</div>
                      <div className="text-sm">{caseRecord.Owner_Name__c ?? <Unknown />}</div>
                    </div>
                    <div className="grid grid-row-2">
                      <div className="font-semibold">Status</div>
                      <div className="text-sm">{caseRecord.Status}</div>
                    </div>
                    <div className="grid grid-row-2">
                      <div className="font-semibold">Type</div>
                      <div className="text-sm">{caseRecord.Type ?? <Unknown />}</div>
                    </div>
                    <div className="grid grid-row-2">
                      <div className="font-semibold">Date/Time Opened</div>
                      <div className="text-sm">
                        {caseRecord.CreatedDate
                          ? dayjs(caseRecord.CreatedDate).format('MM/DD/YYYY h:mm A')
                          : <Unknown />
                        }
                      </div>
                    </div>
                    <div className="grid grid-row-2">
                      <div className="font-semibold">Last Modified By</div>
                      <div className="text-sm">{caseRecord.LastModifiedBy ?? <Unknown />}</div>
                    </div>
                    {caseRecord.Status.toLowerCase() === ClientCasesStatus.Closed ? (
                      <div className="grid grid-row-2">
                        <div className="font-semibold">Date/Time Closed</div>
                        <div className="text-sm">
                          {caseRecord.ClosedDate
                            ? dayjs(caseRecord.ClosedDate).format('MM/DD/YYYY h:mm A')
                            : <Unknown />
                          }
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="grid grid-row-2 mt-8">
                    <div className="font-semibold">Description</div>
                    <div className="text-sm overflow-auto break-words">{caseRecord.Description ?? <Unknown />}</div>
                  </div>
                </div>

                {Array.isArray(caseActivities) && caseActivities.length > 0 ? (
                  <>
                    <hr />
                    <div className="grid gap-6 my-8 md:my-10">
                      <h3 className="text-xl font-semibold">Activity</h3>
                      {caseActivities
                        .filter((activity) => {
                          if ('Body' in activity) {
                            return activity.Body !== null;
                          }

                          return Object.values(ClientCaseActivitiesType).includes(activity.Type)
                        })
                        .sort((a, b) => dayjs(b.CreatedDate).unix() - dayjs(a.CreatedDate).unix())
                        .map((activity, index, arr) => (
                          <div key={activity.Id} className="grid grid-flow-col grid-cols-auto-1fr gap-6 text-base">
                            <div className="h-full">
                              <div className="flex items-center bg-gray-200 rounded-full h-6 w-6">
                                <FontAwesomeIcon icon={faMessageLines} className="mx-1.5 h-3 text-indigo-500" />
                              </div>
                              {index !== arr.length - 1 ? (
                                <span className="h-full w-0.5 block m-auto bg-gray-200"></span>
                              ) : null}
                            </div>
                            <div className="grid grid-row-2">
                              {activity.Type === ClientCaseActivitiesType.History ?
                                  activity.Field === ClientCaseActivitiesField.Created ? (
                                    <span className="text-base text-gray-500 font-semibold">
                                      {activity.CreatedBy} created the case at {dayjs(activity.CreatedDate).format('h:mm A, MM/DD/YY')}
                                    </span>
                                  ) : (
                                    <>
                                      <span className="text-base text-gray-500 font-semibold">
                                        {activity.CreatedBy} updated the case details at {dayjs(activity.CreatedDate).format('h:mm A, MM/DD/YY')}
                                      </span>
                                      <div className="grid mt-2 gap-2">
                                        <div className="grid grid-rows-2">
                                          <span className="text-sm font-semibold">{activity.Field}</span>
                                          {activity.OldValue ? <span className="text-sm line-through">{activity.OldValue}</span> : <Unknown />}
                                        </div>
                                        <div className="grid grid-rows-2">
                                          <span className="text-sm font-semibold">{activity.Field}</span>
                                          <span className="text-sm">{activity.NewValue ?? <Unknown />}</span>
                                        </div>
                                      </div>
                                    </>
                                  )
                              : ( // this is a comment (text post or comment)
                                <>
                                  <span className="text-base text-gray-500 font-semibold">
                                    {activity.CreatedBy} posted a comment at {dayjs(activity.CreatedDate).format('h:mm A, MM/DD/YY')}
                                  </span>
                                  <span className="text-sm overflow-auto break-words">
                                    {stripHTMLTags(activity.Body ?? activity.CommentBody)}
                                  </span>
                                </>
                              )}
                            </div>
                          </div>
                        ))}
                    </div>
                  </>
                ) : null}
               <ClientCasesAddComments clientId={clientId} caseNumber={caseRecord.CaseNumber} />
              </Card>
            )) : (
              <span>There are no {
                selectedCaseStatusFilter.value !== ClientCasesStatus.All ? selectedCaseStatusFilter.value : ''
                } cases for this client.
              </span>
            )
        )}
       {createCaseModalIsOpen && (
        <ClientCasesCreateModal
          isOpen={createCaseModalIsOpen}
          onClose={createCaseModalOnClose}
          clientId={clientId}
          clientData={clientData}
        />
      )}
    </div>
  );
}
