import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Document, MARKS } from '@contentful/rich-text-types';

type Props = {
  /** Expected to only contain one unordered-list */
  children: Document;
};

export const RichText: React.FC<Props> = ({ children: richTextDocument }) => {
  return (
    <>
      {documentToReactComponents(richTextDocument, {
        renderMark: {
          [MARKS.BOLD]: text => <b className="font-bold">{text}</b>,
          [MARKS.ITALIC]: text => <i className="italic">{text}</i>,
        },
        renderText: text => {
          return text;
        },
      })}
    </>
  );
};

export const jsonToRichTextDocument = (plainTextJson: string) => {
  return JSON.parse(plainTextJson) as Document;
}
