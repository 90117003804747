import { isEmpty } from "lodash";
import { FC } from "react";
import { ClientMatchingSummary } from "../client-matching-summary";

export const SummaryStep: FC<{
  timeSlot: any;
  selectedTimeSlot: any;
  isLoadingMutation: any;
  contactTimezone?: string;
}> = ({ timeSlot, selectedTimeSlot, isLoadingMutation, contactTimezone }) =>
  !isEmpty(timeSlot) ? (
    <ClientMatchingSummary
      timeSlot={selectedTimeSlot}
      isLoading={isLoadingMutation}
      contactTimezone={contactTimezone}
    />
  ) : null;
