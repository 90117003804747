import { FormGroup, FormInline, Input, Link } from '@expressable/ui-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormField } from 'components/Forms';
import DayJsDatePicker from 'components/Forms/DayjsPicker';
import { ControlledFormField } from 'components/Forms/FormFieldControlled';
import { DependentInitialState } from 'domain/eligibility/constants';
import { useFieldArray, useFormContext } from 'react-hook-form';
import 'twin.macro';

export const Fieldset: React.FC<{
  legend: React.ReactElement | string;
  children: React.ReactNode;
  isSection?: boolean;
}> = ({ legend, children, isSection }) => (
  <fieldset>
    <legend className={`font-semibold mb-4 ${isSection ? 'text-xl' : 'text-lg'}`}>{legend}</legend>
    {children}
  </fieldset>
);

export const DependantForm: React.FC<{ index: number; remove: (index?: number | number[]) => void }> = ({
  index,
  remove,
}) => {
  return (
    <div className="mb-6 mt-2">
      <Fieldset
        legend={
          <div className="flex space-x-4">
            <span>Dependent {index + 1}</span>
            <button type="button" onClick={() => {
              remove(index);

              }} className="text-red-500 text-sm">
              Remove Dependent
            </button>
          </div>
        }
      >
        <FormInline className="space-x-4">
          <FormField
            name={`dependents.${index}.memberID`}
            label="Member ID"
            component={Input}
            optional={false}
            className="w-full flex"
            containerClass="w-full"
          />

          <FormField
            name={`dependents.${index}.firstName`}
            label="First Name"
            component={Input}
            className="w-full flex"
            containerClass="w-full"
            optional={false}
          />
          <FormField
            name={`dependents.${index}.lastName`}
            label="Last Name"
            component={Input}
            className="w-full flex"
            containerClass="w-full"
            optional={false}
          />

          <FormField
            name={`dependents.${index}.middleName`}
            label="Middle Name"
            component={Input}
            className="w-full flex"
            containerClass="w-full"
          />
        </FormInline>

        <FormInline className="space-x-4">
        <FormGroup className="my-2 w-1/4">
          <ControlledFormField
            id={`dependents.${index}.dateOfBirth`}
            name={`dependents.${index}.dateOfBirth`}
            label="Date of Birth"
            component={DayJsDatePicker}
            allowPastDates={true}
            optional={false}
            containerClass="w-full"
            placeholder="MM/DD/YYYY"
          />
        </FormGroup>

        </FormInline>
      </Fieldset>
    </div>
  );
};

export const Dependents = () => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({ control, name: 'dependents' });

  return (
    <>
      {fields.map((item, index) => (
        <DependantForm key={item.id} index={index} remove={remove} />
      ))}

      <Link to="#" onClick={() => append({ ...DependentInitialState })} className="text-indigo-700">
        <FontAwesomeIcon tw="text-sm mr-2" icon="plus-circle" />
        Add Dependent
      </Link>
    </>
  );
};
