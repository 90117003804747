import { dynamicHttp } from '@expressable/utils';
import { PendingScheduleDTO } from 'domain/pending-schedule/types';
import { useQueryClient, useMutation } from 'react-query';
import { useToasts } from 'react-toast-notifications';
import * as Sentry from '@sentry/react';
import { invalidateAttendanceQueries } from './shared/attendance-utils';

const httpHealthRecordProxy = dynamicHttp('health-record-proxy');

const createPendingSchedule = async (pendingScheduleDTO: PendingScheduleDTO) => {
  const { clientID, ...payload } = pendingScheduleDTO;

  return httpHealthRecordProxy
    .post(`/client-attendances/pending-schedules/${clientID}`, payload)
    .then(res => res.data);
};

export function useCreatePendingSchedule() {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(createPendingSchedule, {
    onSuccess: (resp, payload) => {
      if (resp === '') {
        addToast('There are no therapists found under the filtered criteria', { appearance: 'error', autoDismiss: true });
      } else {
        addToast('Pending Evaluation Successfully Created', { appearance: 'success', autoDismiss: true });
        invalidateAttendanceQueries(queryClient);
      }
    },
    onError: (err) => {
      Sentry.captureException(err);
      addToast('Something Went Wrong', { appearance: 'error', autoDismiss: true });
      invalidateAttendanceQueries(queryClient);
    },
  });
}