import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

interface ListProps<D> {
  data: D[];
  render: (value: D, index: number, array: D[]) => React.ReactNode;
}

const Styles = styled.div`
  ul {
    ${tw`bg-white rounded-lg border border-gray-200 shadow-sm`}
    li {
      ${tw`border-b border-gray-200 text-indigo-700 font-medium `}
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
  }
`;

export default function List<D>({ data, render }: ListProps<D>) {
  return (
    <Styles>
      <ul>{data.map(render)}</ul>
    </Styles>
  );
}
