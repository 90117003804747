import { Container, Button } from '@expressable/ui-library';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import 'twin.macro';

const PageNotFound = (props: RouteComponentProps) => {
  return (
    <div tw="relative">
      <Container size="large" tw="pt-12">
        <div tw="bg-gray-200 m-auto p-28">
          <div tw="text-center">
            <p tw="text-3xl text-gray-400 mb-2">We&apos;re sorry, we couldn&apos;t find this page</p>
            <span tw="text-lg text-gray-400">This page may have moved, or the URL may be incorrect</span>
            <br />
            <Button tw="mt-4" variant="secondary" onClick={() => props.history.push(`/`)}>
              Back to Dashboard
            </Button>
          </div>
        </div>
      </Container>
    </div>
  );
};
export default PageNotFound;
