import 'twin.macro';

import { MFA_INPUT_LENGTH } from 'domain/therapist/constants';
import { MfaInputNullableRef } from 'domain/therapist/therapistTypes';
import { useGetMfaBarcode, useMfaConfirmBarcode } from 'hooks/use-mfa';
import React, { useEffect, useRef, useState } from 'react';
import { useToasts } from 'react-toast-notifications';

import { Button, ModalBody, ModalFooter } from '@expressable/ui-library';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface MfaEnableAppAuthModalProps {
  isOpen: boolean;
  onClose: () => void;
  therapistEmail: string;
}

const MfaEnableAppAuthModal: React.FC<MfaEnableAppAuthModalProps> = ({
  isOpen,
  onClose,
  therapistEmail,
}: MfaEnableAppAuthModalProps) => {
  const { data, isLoading: isContentLoading, error } = useGetMfaBarcode(therapistEmail);

  const [confirmationCode, setConfirmationCode] = useState('');
  const { mutateAsync: mutateMfaConfirmBarcode, isLoading: isContentPublishing } = useMfaConfirmBarcode();

  const inputRefs: MfaInputNullableRef[] = Array.from({ length: MFA_INPUT_LENGTH }).map(() =>
    useRef<HTMLInputElement>(null),
  );

  const { addToast } = useToasts();

  const isSubmitDisabled = isContentLoading || isContentPublishing || confirmationCode.length < MFA_INPUT_LENGTH;

  useEffect(() => {
    if (isOpen && !isContentLoading && !isContentPublishing) {
      inputRefs[0]?.current?.focus();
    } else {
      setConfirmationCode('');
    }
  }, [isOpen, isContentLoading, isContentPublishing]);

  useEffect(() => {
    let errorMessage = 'Something Went Wrong';
    if (error) {
      if (error?.message) {
        errorMessage = error.message;
      }

      addToast(errorMessage, { appearance: 'error', autoDismiss: true });
      onClose();
    }
  }, [error]);

  const onDigit = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const inputDigit = e.target.value;

    if (!/^\d*$/.test(inputDigit)) {
      return;
    }

    const updatedCode = confirmationCode.split('');
    updatedCode[index] = inputDigit;
    setConfirmationCode(updatedCode.join('').slice(0, MFA_INPUT_LENGTH));

    if (inputDigit && index < MFA_INPUT_LENGTH - 1 && inputRefs[index + 1]?.current) {
      inputRefs[index + 1]?.current?.focus();
    }
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Enter') {
      if (confirmationCode.length === MFA_INPUT_LENGTH && !isContentLoading && !isContentPublishing) {
        onSubmit();
      }
    }

    if ((e.key === 'Backspace' || e.key === 'Delete') && index >= 0) {
      if (confirmationCode[index]) {
        const updatedCode = confirmationCode.split('');
        updatedCode[index] = '';
        setConfirmationCode(updatedCode.join(''));
      } else if (index > 0 && inputRefs[index - 1]?.current) {
        inputRefs[index - 1]?.current?.focus();
      }
    }
  };

  const onPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedData = e.clipboardData.getData('text');

    if (/^\d{6}$/.test(pastedData)) {
      setConfirmationCode(pastedData);

      inputRefs[5]?.current?.focus();
    } else {
      setConfirmationCode('');
    }
  };

  const copyToClipboard = (content: string) => {
    navigator.clipboard
      .writeText(content)
      .then(() => {
        addToast('Key copied to clipboard.', { appearance: 'success', autoDismiss: true });
      })
      .catch(error => {
        addToast('Failed to copy key. Please try again.', { appearance: 'error', autoDismiss: true });
      });
  };
  const onSetupClick = () => {
    if (data?.secret) {
      copyToClipboard(data.secret);
    }
  };

  const onSubmit = async () => {
    try {
      await mutateMfaConfirmBarcode({ therapistEmail, confirmationCode });
      onClose();
    } catch (error) {
      inputRefs[0]?.current?.focus();
      setConfirmationCode('');
      console.error('MFA Error:', error);
    }
  };

  return (
    <>
      <ModalBody>
        <div tw="text-center p-4">
          {!data?.barcode || isContentLoading ? (
            <div tw="space-y-4">
              <div tw="bg-gray-300 mx-auto w-[184px] h-[184px] animate-pulse rounded" />
              <div tw="flex items-center justify-center space-x-2">
                <div tw="text-gray-400 text-lg">
                  <FontAwesomeIcon tw="text-lg text-gray-400" icon={faCircleNotch} spin />
                </div>
                <div tw="text-gray-600">Creating barcode...</div>
              </div>
            </div>
          ) : (
            <img
              src={data?.barcode}
              alt="QR Code"
              tw="mx-auto select-none max-w-[228px] max-h-[228px] p-2 rounded border-2 border-gray-100"
              draggable="false"
            />
          )}
        </div>

        <div tw="text-base font-normal mb-7 flex justify-center space-x-6">
          <div tw="text-center w-full border-r-2 border-gray-100 pr-6">
            <span tw="font-bold text-lg text-indigo-700">1.</span> Scan the image above with the two-factor
            authentication app on your phone.
          </div>
          <div tw="text-center w-full">
            <span tw="font-bold text-lg text-indigo-700">2.</span> After scanning the barcode image, the app will
            display a 6-digit code that you can enter below.
          </div>
        </div>

        <div tw="text-center w-full mb-7">
          Unable to scan? You can use the{' '}
          <button
            tw="hover:underline focus:outline-none focus:underline"
            className={isContentLoading ? 'cursor-wait text-gray=100' : 'cursor-pointer text-indigo-700'}
            onClick={onSetupClick}
            disabled={isContentLoading}
          >
            setup key
          </button>{' '}
          to manually configure your authenticator app.
        </div>

        <div tw="text-center mb-8">
          <div tw="flex items-center justify-center">
            {Array.from({ length: MFA_INPUT_LENGTH }).map((_, index) => (
              <input
                key={index}
                type="text"
                maxLength={1}
                inputMode="numeric"
                pattern="[0-9]*"
                value={confirmationCode[index] || ''}
                onChange={e => onDigit(e, index)}
                onKeyDown={e => onKeyDown(e, index)}
                onPaste={onPaste}
                ref={inputRefs[index]}
                tw="text-3xl w-12 h-12 mx-2 text-center border-[3px] border-gray-300 focus:border-indigo-700 focus:outline-none border-solid rounded"
                className={`${isContentLoading || isContentPublishing ? 'cursor-wait' : 'cursor-pointer'} ${
                  confirmationCode[index] ? 'bg-gray-50' : ''
                }`}
                disabled={isContentLoading || isContentPublishing}
              />
            ))}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div tw="mt-5 sm:mt-6">
          <span tw="flex w-full rounded-md shadow-sm">
            <Button
              data-testid="mfa-modal-enable"
              onClick={onSubmit}
              variant="primary"
              tw="inline-flex justify-center items-end text-base w-full font-medium leading-6 sm:text-sm sm:leading-5"
              className={`${isSubmitDisabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
              disabled={isSubmitDisabled}
            >
              {isContentPublishing ? (
                <FontAwesomeIcon tw="text-lg text-gray-500" icon={faCircleNotch} spin />
              ) : (
                'Enable'
              )}
            </Button>
          </span>
        </div>
      </ModalFooter>
    </>
  );
};

export default MfaEnableAppAuthModal;
