import React, { useMemo, useCallback } from 'react';
import { TitleBar, Loader, Card, Link } from '@expressable/ui-library';
import { useContentfulData } from 'hooks/use-ContentfulData';
import { useToasts } from 'react-toast-notifications';
import * as Sentry from '@sentry/react';
import 'twin.macro';

import { Entry } from 'contentful';

export interface IResource {
  title: string;
  description: string;
  url: string;
  category: string;
}

function resources() {
  const [resources, loadingResources] = useContentfulData<IResource>('resources');

  const { addToast } = useToasts();

  const copyToClipboard = useCallback(copyLink => {
    return async () => {
      try {
        await navigator.clipboard.writeText(copyLink);
        addToast('Successfully copied link to Clipboard', { appearance: 'success', autoDismiss: true });
      } catch (error) {
        console.error(error);
        Sentry.captureException(error);
        addToast('Something Went Wrong', { appearance: 'error', autoDismiss: true });
      }
    };
  }, []);

  const parsedResources = useMemo(() => {
    const categories: {
      [key: string]: { items: Array<Entry<IResource>>; category: string };
    } = {};

    if (!resources) return categories;

    resources.items?.forEach(categoryArticle => {
      if (!categories[categoryArticle.fields.category]) {
        categories[categoryArticle.fields.category] = {
          items: [],
          category: categoryArticle.fields.category,
        };
      }

      categories[categoryArticle.fields.category].items.push(categoryArticle);
    });

    return categories;
  }, [resources?.items]);

  if (loadingResources) {
    return (
      <div tw="flex-1 text-center py-20">
        <Loader type="ring" />
      </div>
    );
  }

  return (
    <div>
      <TitleBar title="Resources" />
      <div>
        <div tw="ml-9 pt-8 text-gray-700">
          <p>
            Establish expectations, provide answers to common questions about our service, and set your clients up for
            success!
          </p>
        </div>
        {Object.keys(parsedResources).map((key: string) => (
          <div key={key}>
            <div tw="font-medium text-2xl ml-9 pt-8 ">
              <h1>{parsedResources[key].category}</h1>
            </div>
            <div tw="ml-9 mr-9 mb-8 grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 grid gap-x-6 gap-y-4 ">
              {parsedResources[key].items.map(resources => {
                return (
                  <div key={resources.sys.id}>
                    <Card tw="mt-5 p-6 ">
                      <header tw="font-medium text-lg">{resources.fields.title}</header>
                      <p tw="pt-3 pb-5">{resources.fields.description}</p>
                      <div tw="flex space-x-4 ">
                        <Link
                          tw="text-indigo-700 font-medium "
                          to={{
                            pathname: `${resources.fields.url}`,
                          }}
                          target="_blank"
                        >
                          Learn More
                        </Link>
                        <Link tw="text-indigo-700 font-medium " to="#" onClick={copyToClipboard(resources.fields.url)}>
                          Copy Link
                        </Link>
                      </div>
                    </Card>
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
export default resources;
