import * as Sentry from '@sentry/react';
import { handleChunkLoadError } from '../chunk-error-boundary';

export default async function (version: string): Promise<Promise<unknown> | null> {
  let Renderer = Promise.resolve(null);
  try {
    const module = await import(
      /* webpackChunkName: "add-client-additional-goal-" */
      `./${version}`
    );
    Renderer = module.default;
  } catch (error) {
    const errorMessage = error;
    handleChunkLoadError(errorMessage);

    Sentry.captureException(error);
    console.error(`there was an error in the dynamic import ->`, error);
  }
  return Renderer;
}
