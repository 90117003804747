import { isEmpty, isString } from 'lodash';

enum RESCHEDULE_REASONS {
  outage = 'outage',
  school = 'school',
  work = 'work',
  vacation = 'vacation',
  sick = 'sick',
  other = 'other',
}

const rescheduleReasonDisplayMap: Partial<Record<RESCHEDULE_REASONS, string>> = {
  [RESCHEDULE_REASONS.outage]: 'Power/Internet Outage',
  [RESCHEDULE_REASONS.school]: 'School/Extracurricular Schedule',
  [RESCHEDULE_REASONS.work]: 'Work Schedule',
  [RESCHEDULE_REASONS.vacation]: 'Vacation/Holiday',
  [RESCHEDULE_REASONS.sick]: 'Sick/Medical Care',
  [RESCHEDULE_REASONS.other]: 'Other (unknown)',
};

/** Given an internal code for rescheduling reasons, returns the value to be displayed
 * Cases:
 * Valid code: returns the mapped string
 * Is code for "other": if user-typed reasonOther is present, will display that. Otherwise the fallback from the map
 * Invalid code: will display whatever string is saved for reason (legacy data has hard-coded user-facing text)
 */
export const getRescheduleReasonDisplay = (reason: RESCHEDULE_REASONS | string, reasonOther?: string): string => {
  if (reason === RESCHEDULE_REASONS.other && isString(reasonOther) && !isEmpty(reasonOther)) {
    return reasonOther;
  }

  return rescheduleReasonDisplayMap[reason as RESCHEDULE_REASONS] || reason;
};
