import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { dynamicHttp } from '@expressable/utils';
import { presentMutationError, useToast } from '@expressable/ui-library';

const httpHealthRecordProxy = dynamicHttp('health-record-proxy');

const getApplicableConditions = async (clientId: string) => {
  const { data } = await httpHealthRecordProxy.get(`/care-plans/applicable-conditions/${clientId}`);
  return data;
};

export function useGetApplicableConditions(
  { clientId }: { clientId: string },
  options?: UseQueryOptions<string[], unknown, string[]>,
) {
  return useQuery<string[]>(['applicable-conditions', clientId], () => getApplicableConditions(clientId), options);
}

export interface SetApplicableConditionsPayload {
  clientId: string;
  applicableConditions: string[];
  shouldLogEvent: boolean;
}

const setApplicableConditions = async (payload: SetApplicableConditionsPayload) => {
  const { clientId, ...rest } = payload;
  return httpHealthRecordProxy.post(`/care-plans/applicable-conditions/${clientId}`, rest).then(res => res.data);
};

export function useSetApplicableConditions() {
  const queryClient = useQueryClient();
  const { successToast } = useToast();

  return useMutation(setApplicableConditions, {
    onSuccess: (_, payload) => {
      successToast('Barriers to Care successfully updated.');
      queryClient.invalidateQueries(['applicable-conditions', payload.clientId]);
    },
    onError: presentMutationError,
  });
}
