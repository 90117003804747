import { Modal, ModalContent, ModalHeader, CloseButton, ModalBody, Button, ModalFooter } from '@expressable/ui-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import ModalHeadline from './modal-headline';
import 'twin.macro';
import { faEngineWarning } from '@fortawesome/pro-solid-svg-icons';
export interface LogNoShowModalProps {
  isOpen: boolean;
  onClose: () => void;
}
export function NewVersionModal(props: LogNoShowModalProps) {
  const { isOpen, onClose } = props;

  const onCloseHandler = () => {
    window.localStorage.setItem('user-has-been-alerted-for-new-build', 'false');
    onClose();
  };
  return (
    <Modal isOpen={isOpen} tw="max-w-lg">
      <ModalContent>
        <ModalHeader>
          <div tw="absolute top-0 right-0 pt-4 pr-4">
            <CloseButton testId="close-log-attendance-modal" onClick={onCloseHandler} />
          </div>
        </ModalHeader>
        <ModalBody>
          <React.Fragment>
            <div tw="flex items-center justify-center w-12 h-12 mx-auto bg-indigo-100 rounded-full">
              <FontAwesomeIcon tw="text-2xl text-indigo-700" icon={faEngineWarning} />
            </div>
            <div tw="my-4 sm:mt-3">
              <ModalHeadline text="New Version of Cue available" />
              <ModalFooter tw="mt-5">
                <Button
                  data-testid="add-appointment-submit"
                  onClick={() => {
                    window.localStorage.setItem('user-has-been-alerted-for-new-build', 'true');
                    window.location.reload();
                  }}
                  fullWidth
                  variant="primary"
                  tw="inline-flex justify-center w-full px-4 py-2 text-base font-medium leading-6 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                >
                  Refresh Page
                </Button>
              </ModalFooter>
            </div>
          </React.Fragment>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
