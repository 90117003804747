import React, { useEffect, useState } from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import tw from 'twin.macro';
import { Link, Loader, useDisclosure } from '@expressable/ui-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AddTherapyStrategyModal } from './add-therapy-strategy-modal';
import { RemoveTherapyStrategyModal } from './remove-therapy-strategy-modal';
import { TherapyStrategyCard } from './therapy-strategy-card';
import { ClinicalCareExperienceEntryId, useContentfulEntry } from 'hooks/use-contentful';
import {
  useAddClientAssignments,
  useGetClientAssignments,
  useRemoveClientAssignment,
} from 'hooks/use-client-assignments';
import { ClientAssignment, LearningPathContentful, TherapyStrategyRemove } from 'domain/clinical-pathways/types';

interface UnassignmentReasonsContentful {
  dropdownContent: string[];
}

type Props = {
  clientId: string;
  noteID: string | null;
  isNoteLoading: boolean;
  currentTherapyStrategies: ClientAssignment[];
  onChange: (therapyStrategies: ClientAssignment[]) => void;
};

export const TherapyStrategies = ({
  clientId,
  noteID,
  currentTherapyStrategies,
  isNoteLoading,
  onChange,
}: Props): JSX.Element => {
  const [assignedTherapyStrategies, setAssignedTherapyStrategies] = useState<ClientAssignment[]>([]);
  const [selectedTherapyStrategyIds, setSelectedTherapyStrategyIds] = useState<string[]>([]);
  const [therapyStrategyToRemove, setTherapyStrategyToRemove] = useState<ClientAssignment>();
  const [assignmentsVerified, setAssignmentsVerified] = useState<boolean>(false);

  // it gets unassignment reasons from contentful
  const { data: unassignmentReasonsContentful, isLoading: isUnassignmentReasonsContentfulLoading } =
    useContentfulEntry<UnassignmentReasonsContentful>({
      entryId: ClinicalCareExperienceEntryId.UnassignmentReasons,
    });

  // it gets client assignments for client
  const { data: clientAssignments, isLoading: isClientAssignmentsLoading } = useGetClientAssignments({
    clientId: clientId,
    assignmentTypes: ['learning-path'],
    status: ['incomplete', 'complete'],
  });

  // it removes client assignment
  const { mutate: removeClientAssignment } = useRemoveClientAssignment();

  // it adds a list of client assignments
  const { mutateAsync: addClientAssignments } = useAddClientAssignments();

  const {
    isOpen: AddTherapyStrategyModalIsOpen,
    onClose: AddTherapyStrategyModalOnClose,
    onOpen: AddTherapyStrategyModalOnOpen,
  } = useDisclosure();

  const {
    isOpen: RemoveTherapyStrategyModalIsOpen,
    onClose: RemoveTherapyStrategyModalOnClose,
    onOpen: RemoveTherapyStrategyModalOnOpen,
  } = useDisclosure();

  const handleCheckboxChange = (checked: boolean, therapyStrategy: ClientAssignment) => {
    let newSelectedTherapyStrategies: string[];

    if (checked) {
      // it adds the selected therapy strategy in the list
      newSelectedTherapyStrategies = [...selectedTherapyStrategyIds, therapyStrategy.assignmentID];
      setSelectedTherapyStrategyIds([...newSelectedTherapyStrategies]);
    } else {
      // it filters out from the list the unchecked therapy strategy
      newSelectedTherapyStrategies = selectedTherapyStrategyIds.filter(id => id !== therapyStrategy.assignmentID);
      setSelectedTherapyStrategyIds([...newSelectedTherapyStrategies]);
    }

    // we follow the standard for the session-note and trigger the auto-save every time something changes
    // we should only update the selected items in the session note
    const selectedTherapyStrategies = assignedTherapyStrategies.filter(item =>
      newSelectedTherapyStrategies.includes(item.assignmentID),
    );
    onChange([...selectedTherapyStrategies]);
  };

  const handleTherapyStrategyModalSave = async (savedLearningPaths: LearningPathContentful[]) => {
    // create client assignments that were selected from the modal
    const createdClientAssignments = await addClientAssignments({
      clientId: clientId,
      assignmentType: 'learning-path',
      contentfulEntries: savedLearningPaths?.map(item => ({
        learningPathID: item.id,
        learningPathTreatmentArea: item.category?.[0]?.treatmentAreaCategory,
        learningPathLevelInSession: 'not-mastered',
        learningPathShouldAssign: true,
        assignmentTitle: item.title,
      })),
      noteID: noteID,
    });

    // it generates new therapy strategies that were selected from the modal
    const newTherapyStrategies = createdClientAssignments?.map(item => mapToTherapyStrategy(item));

    // it merges the new items with existing ones
    const mergedTherapyStrategies = [...assignedTherapyStrategies, ...newTherapyStrategies];
    setAssignedTherapyStrategies([...mergedTherapyStrategies]);

    // it keeps the existing ones but adds the new ones as selected
    const newSelectedTherapyStrategies = [
      ...selectedTherapyStrategyIds,
      ...newTherapyStrategies.map(item => item.assignmentID),
    ];
    setSelectedTherapyStrategyIds([...newSelectedTherapyStrategies]);

    // we follow the standard for the session-note and trigger the auto-save every time something changes
    // we should only update the selected items in the session note
    const selectedTherapyStrategies = mergedTherapyStrategies.filter(item =>
      newSelectedTherapyStrategies.includes(item.assignmentID),
    );
    onChange([...selectedTherapyStrategies]);
  };

  const handleTherapyStrategyChange = (therapyStrategy: ClientAssignment) => {
    // change the target therapy strategy
    const updatedTherapyStrategies = assignedTherapyStrategies.map(item => {
      if (item.assignmentID === therapyStrategy.assignmentID) {
        const currentTherapyStrategy = assignedTherapyStrategies.find(
          item => item.assignmentID === therapyStrategy.assignmentID,
        );
        return {
          ...currentTherapyStrategy,
          learningPathContentfulLevelInSession: therapyStrategy.learningPathContentfulLevelInSession,
          learningPathContentfulShouldAssign: therapyStrategy.learningPathContentfulShouldAssign,
        } as ClientAssignment;
      }
      return item;
    });

    setAssignedTherapyStrategies([...updatedTherapyStrategies]);

    // we follow the standard for the session-note and trigger the auto-save every time something changes
    // we should only update the selected items in the session note
    const selectedTherapyStrategies = updatedTherapyStrategies.filter(item =>
      selectedTherapyStrategyIds.includes(item.assignmentID),
    );
    onChange([...selectedTherapyStrategies]);
  };

  const handleTherapyStrategyRemove = (removedTherapyStrategy: TherapyStrategyRemove) => {
    setTherapyStrategyToRemove(undefined);

    // it filters out from the list the removed therapy strategy
    const newTherapyStrategies = assignedTherapyStrategies.filter(
      item => item.assignmentID !== removedTherapyStrategy.assignmentId,
    );
    setAssignedTherapyStrategies([...newTherapyStrategies]);

    // it filters out from the list the removed therapy strategy
    const newSelectedTherapyStrategies = selectedTherapyStrategyIds.filter(
      id => id !== removedTherapyStrategy.assignmentId,
    );
    setSelectedTherapyStrategyIds([...newSelectedTherapyStrategies]);

    // remove assignment
    removeClientAssignment({
      clientId: clientId,
      assignmentId: removedTherapyStrategy.assignmentId,
      discontinuationReason: removedTherapyStrategy.discontinuationReason,
      additionalInformation: removedTherapyStrategy.additionalInformation,
    });

    // we follow the standard for the session-note and trigger the auto-save every time something changes
    // we should only update the selected items in the session note
    const selectedTherapyStrategies = newTherapyStrategies.filter(item =>
      newSelectedTherapyStrategies.includes(item.assignmentID),
    );
    onChange([...selectedTherapyStrategies]);
  };

  const isLoading = isUnassignmentReasonsContentfulLoading || isClientAssignmentsLoading || isNoteLoading;

  useEffect(() => {
    if (!isClientAssignmentsLoading && !isNoteLoading && !assignmentsVerified) {
      const existingAssignments =
        clientAssignments?.items
          // filter out items that were saved in the note
          ?.filter(
            existing => !currentTherapyStrategies.map(current => current.assignmentID).includes(existing.assignmentID),
          )
          // map to get rid off unnecessary fields (we don't want to save all fields in session-note)
          ?.map(assignment => mapToTherapyStrategy(assignment)) ?? [];

      // once strategies are added, they are carried over to every new session note until they are removed
      setAssignedTherapyStrategies([...existingAssignments, ...currentTherapyStrategies]);

      // if a note contains any therapy strategy saved, we display then selected
      setSelectedTherapyStrategyIds([...currentTherapyStrategies].map(item => item.assignmentID));

      // assignments will be verified once
      setAssignmentsVerified(true);
    }
  }, [clientAssignments, isNoteLoading]);

  // this is a business rule, we need to wait until the auto-saved is triggered for the first time
  // we are required to save therapy strategies with noteID
  if (!noteID) {
    return (
      <div tw="flex justify-center items-center">
        <h4 tw="text-sm text-gray-400 ml-2">Waiting for the note to be saved...</h4>
      </div>
    );
  }

  if (!noteID || isLoading) {
    return (
      <div tw="flex justify-center items-center">
        <Loader type="ring" />
      </div>
    );
  }

  return (
    <>
      <div tw="flex mt-10">
        <h4 tw="text-sm font-semibold">Active Therapy Strategies</h4>
        <h4 tw="text-sm text-gray-400 ml-2">
          Which strategies were used in this session? Only assignments will be shared with Client
        </h4>
      </div>

      <AddTherapyStrategyModal
        currentTherapyStrategyIds={assignedTherapyStrategies?.map(item => item.learningPathContentfulEntryID!) ?? []}
        onClose={AddTherapyStrategyModalOnClose}
        onSave={savedLearningPaths => {
          handleTherapyStrategyModalSave(savedLearningPaths);
        }}
        isOpen={AddTherapyStrategyModalIsOpen}
      />

      {therapyStrategyToRemove && (
        <RemoveTherapyStrategyModal
          onClose={RemoveTherapyStrategyModalOnClose}
          onSave={removedTherapyStrategy => {
            handleTherapyStrategyRemove(removedTherapyStrategy);
          }}
          isOpen={RemoveTherapyStrategyModalIsOpen}
          therapyStrategy={therapyStrategyToRemove}
          unassignmentReasons={unassignmentReasonsContentful?.dropdownContent ?? []}
        />
      )}

      {assignedTherapyStrategies?.length > 0 && (
        <div tw="mt-8">
          {assignedTherapyStrategies.map(therapyStrategy => {
            const isChecked = !!selectedTherapyStrategyIds?.find(id => id === therapyStrategy.assignmentID);
            const foundAssignment = clientAssignments?.items?.find(
              item => item.assignmentID === therapyStrategy.assignmentID && item.noteID === noteID,
            );
            return (
              <TherapyStrategyCard
                key={therapyStrategy.assignmentID}
                therapyStrategy={therapyStrategy}
                isChecked={isChecked}
                isNewAssignment={!!foundAssignment}
                onCheckboxChange={checked => handleCheckboxChange(checked, therapyStrategy)}
                onChange={changedTherapyStrategy => handleTherapyStrategyChange(changedTherapyStrategy)}
                onRemove={() => {
                  // open modal to remove therapy strategy
                  setTherapyStrategyToRemove(therapyStrategy);
                  RemoveTherapyStrategyModalOnOpen();
                }}
              />
            );
          })}
        </div>
      )}

      <div tw="flex mt-7">
        <span tw="text-xs font-semibold">
          <Link
            onClick={AddTherapyStrategyModalOnOpen}
            to="#"
            tw="text-indigo-700 ml-2"
            data-testid="add-therapy-strategy"
          >
            <FontAwesomeIcon tw="text-xs mr-2" icon="plus-circle" />
            Add Therapy Strategy
          </Link>
        </span>
      </div>
    </>
  );
};

const mapToTherapyStrategy = (assignment: ClientAssignment): ClientAssignment => {
  return {
    assignmentID: assignment.assignmentID,
    assignmentType: assignment.assignmentType,
    learningPathContentfulEntryID: assignment.learningPathContentfulEntryID,
    assignmentTitle: assignment.assignmentTitle,
    learningPathContentfulTreatmentArea: assignment.learningPathContentfulTreatmentArea,
    learningPathContentfulLevelInSession: assignment.learningPathContentfulLevelInSession,
    learningPathContentfulShouldAssign: assignment.learningPathContentfulShouldAssign,
    noteID: assignment.noteID,
    createdAt: assignment.createdAt || new Date().toISOString(),
  } as ClientAssignment;
};
