import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useToasts } from 'react-toast-notifications';
import { dynamicHttp } from '@expressable/utils';
import { BillingType, InsuranceAuthorizations, MembershipStatus, NetworkStatus, PromptPay } from 'types';
import { presentMutationError } from '@expressable/ui-library';
import { ReferringProviderInformation } from 'domain/billing-information/types';
const httpHealthRecordProxy = dynamicHttp('health-record-proxy');

export interface BillingRecordPayload {
  id: string;
  stripeAccount: string;
  intake: string;
}
const billingIntakeHttp = dynamicHttp('billing-intake');

const createBillingRecord = async (createBillingRecordPayload: BillingRecordPayload) => {
  return billingIntakeHttp.put(`/billing-information`, createBillingRecordPayload).then(res => res.data);
};

export function useCreateBillingRecord() {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(createBillingRecord, {
    onSuccess: (_, payload) => {
      // Query Invalidations
      addToast('Intake Successfully Created', { appearance: 'success', autoDismiss: true });
      queryClient.invalidateQueries(['client', payload.id]);
    },
    onError: presentMutationError,
  });
}

export const getBillingInfo = async (clientId: string) => {
  const { data } = await httpHealthRecordProxy.get(`/billing/${clientId}`);
  return data;
};

export function useBillingInfo(clientId: string) {
  return useQuery(['billingInfo', clientId], () => getBillingInfo(clientId));
}

interface IAddress {
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zipCode: string;
}

export interface IInsurance {
  priorAuthorizationNumber?: string;
  candidPayerUUID: string;
  candidPlanEligibilityUUID?: string;
  coPay?: number;
  insuredRelationshipToClient: string;
  insuredFirstName: string;
  insuredMiddleName?: string;
  insuredLastName: string;
  insuredDob: string;
  insuredGender: string;
  insuredAddress: IAddress;
  insurancePayer: string;
  insurancePayerName: string;
  insurancePlanEligibility?: string;
  insurancePlanEligibilityName?: string;
  insuredPayerType: string;
  insuredMemberID: string;
  insuredGroupID: string;
}

export interface AutomaticPayments {
  promptPay: 'automatic-payments';
  automaticPayments: {
    membershipStatus: 'fee-for-service' | 'monthly-membership';
    pricingForService?: {
      evaluationPrice?: number;
      sessionPrice?: number;
    };
    pricingMonthlyMembership?: {
      price: number;
    };
  };
  credits?: 0 | 1;
}

export interface IBillingInfo {
  billingNotes?: string;
  automaticBillingEnabled: boolean;
  exemptFromPatientResponsibility: boolean;
  automaticCoInsuranceCollection: boolean;
  networkStatus?: NetworkStatus | null;
  insuranceAuthorizations?: InsuranceAuthorizations;
  billingType: BillingType;
  stripeCustomerID: string | null;
  stripeAccountID: string;
  primaryInsurance?: IInsurance;
  secondaryInsurance?: IInsurance;
  promptPay: PromptPay;
  membershipStatus: MembershipStatus;
  selfPay?: AutomaticPayments;
  referringProvider?: ReferringProviderInformation;
}

export interface BillingInfoPayload {
  clientId: string;
  billingInfo: IBillingInfo;
}

const createBillingInfo = async (payload: BillingInfoPayload) => {
  const { clientId, billingInfo } = payload;
  return httpHealthRecordProxy.post(`/billing/${clientId}`, billingInfo).then(res => res.data);
};

export function useCreateBillingInfo() {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(createBillingInfo, {
    onSuccess: response => {
      addToast('Billing Information Successfully Saved', { appearance: 'success', autoDismiss: true });
      queryClient.invalidateQueries(['billingInfo', response]);
    },
    onError: presentMutationError,
  });
}
