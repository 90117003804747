export const authorizationStatusOptions = [
  { value: 'no-required', label: 'Not Required' },
  { value: 'awaiting', label: 'Awaiting Authorization' },
  { value: 'approved', label: 'Authorization Approved' },
  { value: 'denied', label: 'Authorization Denied' },
];

export const disallowedStatusOptions = [
  { value: 'awaiting', label: 'Awaiting Authorization' },
  { value: 'denied', label: 'Authorization Denied' },
];
