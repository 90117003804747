import { presentMutationError } from '@expressable/ui-library';
import { dynamicHttp } from '@expressable/utils';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useToasts } from 'react-toast-notifications';

const httpHealthRecordProxy = dynamicHttp('health-record-proxy');

export type AddCreditPayload = {
  creditsDto: { credits: number };
  clientId: string;
};

export type DeleteCreditPayload = {
  creditsDto: { credits: number };
  clientId: string;
};

export type GetCreditsPayload = {
  credits: number;
};

const getCredits = async (clientId: string): Promise<GetCreditsPayload> => {
  return httpHealthRecordProxy.get(`/billing/${clientId}/credits`).then(res => res.data);
};

export function useGetCredits(clientId: string) {
  return useQuery(['credits', clientId], () => getCredits(clientId));
}

const addCredit = async (payload: AddCreditPayload) => {
  const { clientId, creditsDto } = payload;
  return httpHealthRecordProxy.post(`/billing/${clientId}/credits`, creditsDto).then(res => res.data);
};

export function useAddCredits() {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(addCredit, {
    onSuccess: response => {
      addToast(response, { appearance: 'success', autoDismiss: true });
      queryClient.invalidateQueries();
    },
    onError: presentMutationError,
  });
}

const deleteCredit = async (payload: DeleteCreditPayload) => {
  const { clientId, creditsDto } = payload;
  return httpHealthRecordProxy.delete(`/billing/${clientId}/credits`, { data: creditsDto }).then(res => res.data);
};

export function useDeleteCredits() {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(deleteCredit, {
    onSuccess: response => {
      addToast(response, { appearance: 'success', autoDismiss: true });
      queryClient.invalidateQueries();
    },
    onError: presentMutationError,
  });
}
