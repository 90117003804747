import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { dynamicHttp } from '@expressable/utils';
import { useToasts } from 'react-toast-notifications';
import { presentMutationError } from '@expressable/ui-library';
import { AssignmentStatus, AssignmentType, ClientAssignment, ClientAssignments } from 'domain/clinical-pathways/types';

const httpHealthRecordProxy = dynamicHttp('health-record-proxy');

export interface AddAssignmentPayload {
  clientId: string;
  assignmentType: AssignmentType;
  contentfulEntries: {
    learningPathID?: string;
    learningPathTreatmentArea?: string;
    learningPathLevelInSession?: string;
    learningPathShouldAssign?: boolean;
    activityOfDailyLivingID?: string;
    structuredActivityID?: string;
    customStructuredActivityID?: string;
    structuredActivityMaterial?: string;
    activityOfDailyLivingType?: string;
    assignmentTitle: string;
    assignmentDescription?: string;
  }[];
  noteID?: string | null;
}

const addClientAssignments = async ({ clientId, ...rest }: AddAssignmentPayload) => {
  const { data } = await httpHealthRecordProxy.post<ClientAssignment[]>(`/client-assignment/${clientId}`, rest);
  return data;
};

export function useAddClientAssignments() {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(addClientAssignments, {
    onSuccess: (_, payload) => {
      addToast(`Assignments added with success.`, { appearance: 'success', autoDismiss: true });
      queryClient.invalidateQueries(['client-assignments', payload.clientId]);
    },
    onError: presentMutationError,
  });
}

export interface RemoveAssignmentPayload {
  clientId: string;
  assignmentId: string;
  discontinuationReason: string;
  additionalInformation?: string;
}

const removeClientAssignment = async ({ clientId, assignmentId, ...rest }: RemoveAssignmentPayload) => {
  const { data } = await httpHealthRecordProxy.delete(`/client-assignment/${clientId}/${assignmentId}`, { data: rest });
  return data;
};

export function useRemoveClientAssignment() {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(removeClientAssignment, {
    onSuccess: (_, payload) => {
      addToast(`Assignment removed with success.`, { appearance: 'success', autoDismiss: true });
      queryClient.invalidateQueries(['client-assignments', payload.clientId]);
    },
    onError: presentMutationError,
  });
}

export interface GetClientAssignmentsPayload {
  clientId: string;
  assignmentTypes: AssignmentType[];
  status?: AssignmentStatus[];
}

const getClientAssignments = async ({ clientId, assignmentTypes, status }: GetClientAssignmentsPayload) => {
  const params = new URLSearchParams();
  params.append('assignmentTypes', assignmentTypes.join(','));
  if (status?.length) {
    params.append('status', status.join(','));
  }

  const { data } = await httpHealthRecordProxy.get<ClientAssignments>(
    `/client-assignment/${clientId}?${params.toString()}`,
  );
  return data;
};

export function useGetClientAssignments(
  payload: GetClientAssignmentsPayload,
  options?: UseQueryOptions<ClientAssignments, unknown, ClientAssignments>,
) {
  return useQuery<ClientAssignments>(
    ['client-assignments', payload.clientId, payload.assignmentTypes, payload.status],
    () => getClientAssignments(payload),
    options,
  );
}
