import { Input, Select } from '@expressable/ui-library';

import { FormField } from 'components/Forms';
import { ControlledFormField } from 'components/Forms/FormFieldControlled';
import { RadioGroup } from 'components/Forms/RadioGroup';
import { EvaluationEntriesIds, useEvaluationContentfulSelectOptions } from 'hooks/use-evaluation-contentful-provider';
import { useEffect, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { SelectOption } from 'types';

export default function EvaluationLanguageSection() {
  const languagesOptions = useEvaluationContentfulSelectOptions(EvaluationEntriesIds.EvaluationLanguagesDropdown);
  const sourceOfLanguagesOptions = useEvaluationContentfulSelectOptions(EvaluationEntriesIds.SourceOfLanguages);

  const clientsPrimaryLanguage = useWatch({
    name: 'clientsPrimaryLanguage',
  });

  const watchEvaluationInPrimaryLanguage = useWatch({
    name: 'evaluationInPrimaryLanguage',
  });

  const secondaryLanguageOptions = useMemo(() => {
    return languagesOptions?.filter(opt => opt?.value !== clientsPrimaryLanguage?.value);
  }, [languagesOptions, clientsPrimaryLanguage]);
  const additionalLanguageExposures = useWatch({ name: 'additionalLanguageExposures' });
  const form = useFormContext();
  useEffect(() => {
    // Remove the selected Primary language from the list of selected Secondary Languages
    if (
      clientsPrimaryLanguage?.value &&
      additionalLanguageExposures?.find((lang: SelectOption) => lang.value === clientsPrimaryLanguage.value)
    ) {
      const newAdditionalLanguageExposures = additionalLanguageExposures?.filter(
        (lang: SelectOption) => lang.value !== clientsPrimaryLanguage.value,
      );
      form.setValue('additionalLanguageExposures', newAdditionalLanguageExposures);
    }
  }, [clientsPrimaryLanguage?.value, additionalLanguageExposures?.length, form]);

  useEffect(() => {
    if (!clientsPrimaryLanguage) {
      form.setValue('evaluationInPrimaryLanguage', 'yes');
      form.setValue(
        'clientsPrimaryLanguage',
        languagesOptions?.find(opt => opt.value === 'English'),
      );

      form.setValue('evaluationWithInterpreter', 'no');
    }
  }, [languagesOptions?.length]);

  const shouldDisplayJustificationQuestion = useMemo(() => {
    return watchEvaluationInPrimaryLanguage === 'no';
  }, [watchEvaluationInPrimaryLanguage]);

  return (
    <div className="flex-col mb-4">
      <h2 className="font-semibold text-xl ">Language</h2>
      <hr className="mt-1 mb-6 border-gray-200" />

      <div className="space-y-6">
        <div className="flex gap-6 w-full">
          <ControlledFormField
            containerClass="flex flex-col w-1/5 justify-end"
            component={Select}
            maxWidth="large"
            spacing="tight"
            tw={'min-w-[550px]'}
            name="clientsPrimaryLanguage"
            label="Primary Language"
            options={languagesOptions}
          />
        </div>
        <div className="flex gap-6 w-full">
          <ControlledFormField
            containerClass="flex flex-col justify-end w-1/5"
            component={Select}
            maxWidth="large"
            spacing="tight"
            tw={'min-w-[550px]'}
            name="additionalLanguageExposures"
            label="Secondary Language"
            options={secondaryLanguageOptions}
            isMulti
            isSearchable
            optional
          />
        </div>
        <div className="flex gap-y-6 w-full">
          <ControlledFormField
            containerClass="w-full"
            component={Select}
            className="w-full"
            name="sourceOfLanguages"
            label="Source of Languages"
            spacing="tight"
            options={sourceOfLanguagesOptions}
          />
        </div>
        <div className="flex gap-2 w-full flex-col">
          <RadioGroup
            containerClass="space-y-2"
            inputClass="w-full inline-flex space-x-6"
            name="evaluationInPrimaryLanguage"
            label={`Was the evaluation conducted in ${clientsPrimaryLanguage?.label ?? ''}?`}
            options={[
              { label: 'No', value: 'no' },
              { label: 'Yes', value: 'yes' },
            ]}
          />
        </div>
        <div className="flex gap-2 w-full flex-col">
          <RadioGroup
            containerClass="space-y-2"
            inputClass="w-full inline-flex space-x-6"
            name="evaluationWithInterpreter"
            label="Was an interpreter used for the evaluation?"
            options={[
              { label: 'No', value: 'no' },
              { label: 'Yes', value: 'yes' },
            ]}
          />
        </div>
        {shouldDisplayJustificationQuestion && (
          <div className="flex gap-6 w-full">
            <FormField
              containerClass="w-full"
              component={Input}
              className="w-full"
              name="evaluationLanguageJustification"
              label={`Justification for not conducting evaluation in ${clientsPrimaryLanguage?.label}`}
              spacing="tight"
            />
          </div>
        )}
      </div>
    </div>
  );
}
