import React, { useEffect, useState } from 'react';
import {
  Button,
  CloseButton,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
} from '@expressable/ui-library';
import 'twin.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNotesMedical } from '@fortawesome/free-solid-svg-icons';
import { Controller, useForm } from 'react-hook-form';
import { useSetApplicableConditions } from 'hooks/use-care-plan-applicable-conditions';
import { EntryId, useContentfulEntry } from 'hooks/use-contentful';
import { sortBy } from 'lodash';
import { SelectOption } from 'types';

interface ClientApplicableConditionsModalProps {
  isOpen: boolean;
  onClose: () => void;
  clientId: string;
  applicableConditions: string[];
  shouldLogEvent: boolean;
}

interface ClientApplicableConditionsForm {
  applicableConditions: SelectOption[];
  item: string;
}

type ContentfulEntry = {
  label: string;
  dropdownContent: string[];
};

const mapToOption = (condition: string): SelectOption<string> => {
  return {
    value: condition,
    label: condition,
  };
};

export function ClientApplicableConditionsModal(props: ClientApplicableConditionsModalProps) {
  const { isOpen, onClose, clientId, shouldLogEvent, applicableConditions } = props;
  const { data: contentfulEntries, isLoading: isLoadingContentful } = useContentfulEntry<ContentfulEntry>({
    entryId: EntryId.ApplicableConditions,
  });
  const [defaultApplicableConditions, setDefaultApplicableConditions] = useState<string[]>();

  const applicableConditionsSorted = sortBy(applicableConditions);

  const { mutateAsync: setApplicableConditions, isLoading } = useSetApplicableConditions();

  const applicableConditionsOptions = [...(contentfulEntries?.dropdownContent ?? [])].filter(item => item);

  const applicableConditionsOptionsSorted = sortBy(applicableConditionsOptions);

  const {
    formState: { errors },
    handleSubmit,
    control,
    reset,
  } = useForm<ClientApplicableConditionsForm>({
    defaultValues: {
      item: '',
    },
  });

  const onSubmit = async (data: ClientApplicableConditionsForm) => {
    const conditionsToSet = data.applicableConditions?.map(item => item.value);
    await setApplicableConditions({ clientId, applicableConditions: conditionsToSet, shouldLogEvent });
    onClose();
  };

  useEffect(() => {
    reset({ ...defaultApplicableConditions } as any); // eslint-disable-line
    setDefaultApplicableConditions(applicableConditionsSorted);
  }, [applicableConditions, isLoading]);

  return (
    <Modal portal isOpen={isOpen}>
      <ModalContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader>
            <CloseButton onClick={onClose} />
          </ModalHeader>
          <ModalBody>
            <div>
              <div tw="flex items-center justify-center w-12 h-12 mx-auto bg-indigo-100 rounded-full">
                <FontAwesomeIcon tw="text-2xl text-indigo-700" icon={faNotesMedical} data-testid="icon" />
              </div>
              <div tw="mt-3 sm:mt-5">
                <h3 tw="text-lg text-center font-medium text-gray-900 leading-6" id="modal-headline">
                  Barriers to Care
                </h3>
                <div tw="mt-10">
                  <FormGroup>
                    <Label htmlFor="applicable-conditions">Barriers</Label>

                    <div tw="w-full" data-testid="applicable-conditions">
                      <Controller
                        name="applicableConditions"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            isSearchable
                            isMulti
                            error={errors.applicableConditions}
                            options={applicableConditionsOptionsSorted?.map(condition => mapToOption(condition))}
                          />
                        )}
                        defaultValue={defaultApplicableConditions?.map(condition => mapToOption(condition))}
                        rules={{ required: true }}
                      />
                    </div>
                  </FormGroup>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div tw="mt-5 sm:mt-6 sm:grid sm:grid-cols-1 sm:gap-3 sm:grid-flow-row-dense">
              <span tw="flex w-full rounded-md shadow-sm">
                <Button
                  type="submit"
                  data-testid="applicable-conditions-submit"
                  variant="primary"
                  loading={isLoading || isLoadingContentful}
                  tw="inline-flex justify-center w-full px-4 py-2 text-base font-medium leading-6 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                >
                  Save
                </Button>
              </span>
            </div>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
