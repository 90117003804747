import { CptCode, SelectOption } from 'types';

export const isValidCpt = (cpt?: CptCode[]) => {
  if (!cpt || cpt.length === 0) {
    return false;
  } else {
    return true;
  }
};

export function formatCptCode({ code, modifier, description }: CptCode): string {
  const identifier = modifier ? `${code}.${modifier}` : code;
  return `${identifier} - ${description}`;
}

const cptCodes: CptCode[] = [
  {
    code: '92526',
    modifier: '95',
    description: 'Treatment of swallowing dysfunction and/or oral function for feeding',
  },
  {
    code: '92507',
    modifier: '95',
    description: 'Treatment of speech, language, voice, communication, and/or auditory processing disorder; individual',
  },
  {
    code: '92521',
    modifier: '95',
    description: 'Evaluation of speech fluency (eg, stuttering, cluttering)',
  },
  {
    code: '92522',
    modifier: '95',
    description: 'Evaluation of speech sound production (eg, articulation, phonological process, apraxia, dysarthria)',
  },
  {
    code: '92523',
    modifier: '95',
    description:
      'Evaluation of speech sound production (e.g., articulation, phonological process, apraxia, dysarthria); with evaluation of language comprehension and expression (e.g., receptive and expressive language)',
  },
  {
    code: '92524',
    modifier: '95',
    description: 'Behavioral and qualitative analysis of voice and resonance',
  },
  {
    code: '96105',
    modifier: '95',
    description: 'Assessment of aphasia with interpretation and report, per hour',
  },
  {
    code: '92605',
    modifier: '95',
    description:
      'Evaluation for prescription of non-speech generating augmentative and alternative communication device, face-to-face with the patient; first hour',
  },
  {
    code: '92610',
    modifier: '95',
    description: 'Evaluation of oral and pharyngeal swallowing function',
  },
  {
    code: '92607',
    modifier: '95',
    description:
      'Evaluation for prescription for speech-generating augmentative and alternative communication device; face-to-face with the patient; first hour',
  },
  {
    code: '92609',
    modifier: '95',
    description: 'Therapeutic services for the use of speech-generating device, including programming and modification',
  },
  {
    code: '97129',
    modifier: '95',
    description: 'Therapeutic interventions that focus on cognitive function, first 15 minutes',
  },
  {
    code: '97130',
    modifier: '95',
    description: 'Therapeutic interventions that focus on cognitive function, subsequent 15 minutes',
  },
];

export const defaultCptCodes = [cptCodes.find(x => x.code === '92507' && x.modifier === '95')].filter(
  Boolean,
) as CptCode[];

export default cptCodes;

/**
 * Array of select options for all CPT codes.
 *
 * Each item is formatted as `{ label: '92610.95 - Evaluation of...', value: { code: '92610', modifier: ... }}`.
 */
export const formattedCptCodes: SelectOption<CptCode>[] = cptCodes.map(cpt => ({
  value: cpt,
  label: formatCptCode(cpt),
}));

export const defaultFormattedCptCode = formattedCptCodes.find(cpt => cpt.value.code === '92507')!;
