import { useMemo } from 'react';
import { EvaluationEntriesIds, useEvaluationContentfulString } from './use-evaluation-contentful-provider';

export default function useClinicalAreaConclusions() {
  const receptiveLanguageConclusion = useEvaluationContentfulString(EvaluationEntriesIds.ReceptiveLanguageConclusion);
  const expressiveLanguageConclusion = useEvaluationContentfulString(EvaluationEntriesIds.ExpressiveLanguageConclusion);
  const fluencyConclusion = useEvaluationContentfulString(EvaluationEntriesIds.FluencyConclusion);
  const voiceResonanceConclusion = useEvaluationContentfulString(EvaluationEntriesIds.VoiceResonanceConclusion);
  const articulationConclusion = useEvaluationContentfulString(EvaluationEntriesIds.ArticulationPhonologyConclusion);
  const oralMotorAbilitiesConclusion = useEvaluationContentfulString(EvaluationEntriesIds.OralMotorAbilitiesConclusion);
  const feedingSwallowingConclusion = useEvaluationContentfulString(EvaluationEntriesIds.FeedingShallowingConclusion);
  const pragmaticsConclusion = useEvaluationContentfulString(EvaluationEntriesIds.PragmaticsConclusion);

  return useMemo(() => {
    return {
      receptiveLanguage: receptiveLanguageConclusion,
      expressiveLanguage: expressiveLanguageConclusion,
      fluency: fluencyConclusion,
      voiceResonance: voiceResonanceConclusion,
      articulationPhonology: articulationConclusion,
      oralMotorAbilities: oralMotorAbilitiesConclusion,
      feedingSwallowing: feedingSwallowingConclusion,
      pragmatics: pragmaticsConclusion,
    };
  }, [
    receptiveLanguageConclusion,
    expressiveLanguageConclusion,
    fluencyConclusion,
    voiceResonanceConclusion,
    articulationConclusion,
    oralMotorAbilitiesConclusion,
    feedingSwallowingConclusion,
    pragmaticsConclusion,
  ]);
}
