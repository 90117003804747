import React, { useEffect, useState } from 'react';
import { useNoShowPendingAppointment } from 'hooks/use-pending-appointments';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, CloseButton, Modal, ModalBody, ModalContent, ModalHeader } from '@expressable/ui-library';
import { faCalendarTimes } from '@fortawesome/free-solid-svg-icons';
import ModalHeadline from 'components/modal-headline';
import { AppointmentDetail } from 'types';
import { clientFullName } from 'utils/appointments-utils';
import 'twin.macro';
import { useBillingInfo } from 'hooks/use-billing-information';
import { getCueError } from 'utils/error';
import { shouldSkipAttendanceError } from 'hooks/shared/attendance-utils';

export interface LogNoShowModalProps {
  isOpen: boolean;
  onClose: () => void;
  activity: AppointmentDetail;
  clientId: string;
}

export function LogNoShowModal(props: LogNoShowModalProps) {
  const [errorMessage, setErrorMessage] = useState<string>();
  const { isOpen, onClose, activity, clientId } = props;
  const { data: billingInfoData } = useBillingInfo(clientId);

  const {
    mutateAsync: logNoShowAppointmentDetailMutation,
    isLoading,
  } = useNoShowPendingAppointment();

  const onSave = async (appointmentDetail: AppointmentDetail) => {
    await logNoShowAppointmentDetailMutation({
      clientId: appointmentDetail.clientID,
      acuityId: Number(appointmentDetail.acuityAppointmentID) ?? 0,
    }, {
      onError: (err) => {
        if (shouldSkipAttendanceError(err)) {
          onClose();
          return;
        }
        const error = getCueError(err);
        setErrorMessage(error.message);
        return;
      },
    });

    onClose();
  };

  const resetModalState = () => {
    setErrorMessage(undefined);
  };
  useEffect(resetModalState, [isOpen]);

  return (
    <Modal isOpen={isOpen} tw="max-w-lg">
      <ModalContent>
        <ModalHeader>
          <div tw="absolute top-0 right-0 pt-4 pr-4">
            <CloseButton testId="close-log-attendance-modal" onClick={onClose} />
          </div>
        </ModalHeader>
        <ModalBody>
          <React.Fragment>
            <div tw="flex items-center justify-center w-12 h-12 mx-auto bg-indigo-100 rounded-full">
              <FontAwesomeIcon tw="text-2xl text-indigo-700" icon={faCalendarTimes} />
            </div>
            <div tw="my-3 sm:mt-5">
              <ModalHeadline text={`Log No Show for ${clientFullName(activity)}`} />
              <div tw="m-auto mt-2">
                {errorMessage && (
                  <p tw="text-center text-red-600 font-semibold">{errorMessage}</p>
                )}
                {billingInfoData && !billingInfoData?.exemptFromPatientResponsibility && (
                  <p tw="text-center">
                    If your client did not attend the appointment and provided no advance notice, a no-show fee of $50
                    will be charged to the client’s saved payment method.
                  </p>
                )}
              </div>
              <Button
                tw="mt-10"
                data-testid="log-attendance-onsave"
                onClick={() => onSave(activity)}
                variant="primary"
                loading={isLoading}
                disabled={isLoading}
                fullWidth
              >
                {billingInfoData && !billingInfoData?.exemptFromPatientResponsibility
                  ? 'Log No-Show With $50 Charge'
                  : 'Log No-Show'}
              </Button>
              <Button tw="mt-3" data-testid="log-noshow-close-no-login" variant="secondary" fullWidth onClick={onClose}>
                Exit Without Logging
              </Button>
            </div>
          </React.Fragment>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
