import { TimeSlot } from 'components/therapistMatcher/data';
import { AppointmentType, SessionType } from 'domain/appointment/constants';
import SubStep from 'pages/new-client-create/sub-step';
import { FC, SetStateAction } from 'react';
import { Therapist } from 'types';
import ClientMatchingTherapistTimeSelector from '../client-matching-therapist-selector';

export const TherapySchedulingStep: FC<{
  appointmentType?: AppointmentType;
  sessionType?: SessionType;
  filteredTherapists: Therapist[];
  timeSlot: TimeSlot | null;
  setTimeSlot: React.Dispatch<SetStateAction<TimeSlot | null>>;
}> = ({ appointmentType, sessionType, filteredTherapists, timeSlot, setTimeSlot }) => {
  const title = `${appointmentType === AppointmentType.Therapy ? '4' : '5'}. Schedule ${sessionType === SessionType.Recurring ? 'recurring' : 'single'} session`;

  return appointmentType === AppointmentType.Therapy && sessionType ? (
    <SubStep title={title} expanded>
      <ClientMatchingTherapistTimeSelector
        therapists={filteredTherapists}
        value={timeSlot}
        onChange={setTimeSlot}
        type="session"
      />
    </SubStep>
  ) : null;
}