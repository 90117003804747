export interface CueError {
  keyword: string;
  message: string;
}

export const getCueError = (err: any): CueError => {
  // unfortunately, the BE doesn't return a consistent error response
  // it often returns an array of errors, but eventually, it returns an object like below
  const error = err?.response?.data?.[0] || err?.response?.data;

  // error keys are not consistent as well
  // it often returns as "keyword" but eventually it can be "code"
  if (error) {
    return {
      ...error,
      keyword: error.keyword || error.code,
    };
  }

  return {
    keyword: 'UNKNOWN_ERR_FORMAT',
    message: 'Something went wrong. Please try again later.',
  };
};
