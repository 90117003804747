import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { ClientContactsPayload } from 'hooks/use-client-contacts';
import {
  Button,
  CloseButton,
  FormGroup,
  FormInline,
  Label,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
} from '@expressable/ui-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import ModalHeadline from 'components/modal-headline';
import { PhoneRender } from './client-sidebar';
import { buildAddressString, getPhoneNumber } from 'utils/helpers';
import { Contact } from 'types';
import { useForm } from 'react-hook-form';

export interface DuplicatedContactsConfirmationModalProps {
  clientInformation: ClientContactsPayload | undefined;
  isOpen: boolean;
  onClose: () => void;
  confirmationModalHasDuplicates: boolean;
  duplicatedContacts?: Contact[];
  setDisplayConfirmationModal: Dispatch<SetStateAction<boolean>>;
  editContact: (clientInformation: ClientContactsPayload, options?: { onSuccess: () => void }) => void;
  editContactIsLoading: boolean;
  setShowEditForm: Dispatch<SetStateAction<boolean>>;
  contactFirstName: string;
  contactLastName: string;
  phoneNumberHasChanged: boolean | string;
  emailAddressHasChanged: boolean | string;
}

interface MessageParams {
  phoneNumber: string | undefined;
  emailAddress: string | undefined;
  phoneNumberHasChanged: boolean | string;
  emailAddressHasChanged: boolean | string;
  contactFirstName: string;
  contactLastName: string;
}

interface GetMessageParams {
  phoneNumberHasChanged: boolean | string;
  emailAddressHasChanged: boolean | string;
  clientInformation: ClientContactsPayload | undefined;
  contactFirstName: string;
  contactLastName: string;
  confirmationModalHasDuplicates: boolean;
}

const DuplicatedContactsConfirmationModal = (props: DuplicatedContactsConfirmationModalProps) => {
  const {
    clientInformation,
    isOpen,
    onClose,
    confirmationModalHasDuplicates,
    duplicatedContacts,
    setDisplayConfirmationModal,
    editContact,
    editContactIsLoading,
    setShowEditForm,
    contactFirstName,
    contactLastName,
    phoneNumberHasChanged,
    emailAddressHasChanged,
  } = props;
  const { handleSubmit } = useForm();

  const modalHeadlineMessage = useMemo(() => {
    return confirmationModalHasDuplicates
      ? `Duplicate ${phoneNumberHasChanged && !emailAddressHasChanged ? `phone number found` : ''} ${
          phoneNumberHasChanged && emailAddressHasChanged ? `phone number and/or email address found` : ''
        } ${emailAddressHasChanged && !phoneNumberHasChanged ? `email address found` : ''}`
      : `Confirm new ${phoneNumberHasChanged && !emailAddressHasChanged ? `phone number` : ''}  ${
          phoneNumberHasChanged && emailAddressHasChanged ? `phone number and email address` : ''
        }${emailAddressHasChanged && !phoneNumberHasChanged ? `email address` : ''}`;
  }, [confirmationModalHasDuplicates, phoneNumberHasChanged, emailAddressHasChanged]);

  const getUpdateFieldsMessage = ({
    phoneNumber,
    emailAddress,
    phoneNumberHasChanged,
    emailAddressHasChanged,
    contactFirstName,
    contactLastName,
  }: MessageParams): string => {
    let message = 'You have updated the ';

    if (phoneNumberHasChanged && emailAddressHasChanged) {
      message += `phone number (${phoneNumber}) and email address (${emailAddress}) for ${contactFirstName} ${contactLastName}.`;
    } else if (phoneNumberHasChanged) {
      message += `phone number (${phoneNumber}) for ${contactFirstName} ${contactLastName}.`;
    } else if (emailAddressHasChanged) {
      message += `email address (${emailAddress}) for ${contactFirstName} ${contactLastName}.`;
    }

    return message;
  };

  const getDuplicatesFieldsMessage = ({
    phoneNumber,
    emailAddress,
    phoneNumberHasChanged,
    emailAddressHasChanged,
  }: MessageParams): string => {
    let message = '';

    if (phoneNumberHasChanged && emailAddressHasChanged) {
      message += `The phone number (${phoneNumber}) and/or email address (${emailAddress}) were found in existing contact record(s). Return to edit contact and enter a different phone number and/or email address found.`;
    } else if (phoneNumberHasChanged) {
      message += `The phone number (${phoneNumber}) was found in existing contact record(s). Return to edit contact and enter a different phone number.`;
    } else if (emailAddressHasChanged) {
      message += `The email address (${emailAddress}) was found in existing contact record(s). Return to edit contact and enter a different email address.`;
    }

    return message;
  };

  const getDescriptionMessage = ({
    phoneNumberHasChanged,
    emailAddressHasChanged,
    clientInformation,
    contactFirstName,
    contactLastName,
    confirmationModalHasDuplicates,
  }: GetMessageParams): string => {
    const phoneNumber = getPhoneNumber(clientInformation?.phone as ClientContactsPayload['phone']);
    const emailAddress = clientInformation?.email;

    if (confirmationModalHasDuplicates) {
      return getDuplicatesFieldsMessage({
        phoneNumber,
        emailAddress,
        phoneNumberHasChanged,
        emailAddressHasChanged,
        contactFirstName,
        contactLastName,
      });
    }

    return getUpdateFieldsMessage({
      phoneNumber,
      emailAddress,
      phoneNumberHasChanged,
      emailAddressHasChanged,
      contactFirstName,
      contactLastName,
    });
  };

  const onSubmit = async () => {
    editContact(clientInformation as ClientContactsPayload, {
      onSuccess: () => {
        onClose();
        setDisplayConfirmationModal(false);
        setShowEditForm(false);
      },
    });
  };

  return (
    <Modal isOpen={isOpen} className="max-w-lg">
      <ModalContent>
        <ModalHeader>
          <div className="absolute top-0 right-0 pt-4 pr-4">
            <CloseButton
              data-testid="close-duplicated-contacts-confirmation-modal"
              onClick={() => {
                onClose();
                setDisplayConfirmationModal(false);
              }}
            />
          </div>
        </ModalHeader>
        <ModalBody>
          <React.Fragment>
            <div className="flex items-center justify-center w-12 h-12 mx-auto bg-indigo-100 rounded-full">
              <FontAwesomeIcon className="text-2xl text-indigo-700" icon={faUser} />
            </div>
            <div className="my-3 sm:mt-5">
              <ModalHeadline text={modalHeadlineMessage} />
              <div className="w-[70%] m-auto mt-2">
                <p className="text-center">
                  {getDescriptionMessage({
                    phoneNumberHasChanged,
                    emailAddressHasChanged,
                    clientInformation,
                    contactFirstName,
                    contactLastName,
                    confirmationModalHasDuplicates,
                  })}
                </p>
                <div className="flex flex-row bg-red-100 p-4 rounded mt-6">
                  {confirmationModalHasDuplicates ? (
                    <span>
                      If an existing contact needs to replace the contact you are editing, return to the previous
                      screen, exit from &quot;Edit Contact&quot; and select &quot;Add New Contact.&quot; Search for the
                      correct contact record to add. Then remove any outdated or incomplete contact records.
                    </span>
                  ) : (
                    <span>{`${
                      phoneNumberHasChanged && !emailAddressHasChanged
                        ? `Communication preferences will be removed for the previous phone number listed.`
                        : ''
                    } ${
                      phoneNumberHasChanged && emailAddressHasChanged
                        ? `Communication preferences will be removed for the previous phone number listed. Portal access and communication preferences will be removed for the previous email address listed`
                        : ''
                    }  ${
                      emailAddressHasChanged && !phoneNumberHasChanged
                        ? `Portal access and communication preferences will be removed for the previous email address listed.`
                        : ''
                    }`}</span>
                  )}
                </div>
                <div className="mt-4 overflow-y-auto max-h-64">
                  {duplicatedContacts?.map((duplicatedContact: Contact) => {
                    return (
                      <div className="mb-2 mt-2" key={duplicatedContact.contactID}>
                        <FormInline>
                          <Label className="text-base" htmlFor={duplicatedContact.contactID}>
                            {duplicatedContact.contactFirstName} {duplicatedContact.contactLastName}
                          </Label>
                        </FormInline>
                        <div>
                          <div className="text-gray-400">
                            {duplicatedContact.phone &&
                              (duplicatedContact.mobilePhone ||
                                duplicatedContact.workPhone ||
                                duplicatedContact.homePhone) && (
                                <>
                                  <PhoneRender phone={duplicatedContact.phone} />
                                </>
                              )}
                          </div>
                          <div className="text-gray-400">{duplicatedContact.email}</div>
                          {duplicatedContact.address && duplicatedContact.contactTimeZone && (
                            <div className="text-gray-400">
                              {buildAddressString(duplicatedContact.address)} ({duplicatedContact.contactTimeZone})
                            </div>
                          )}
                          {duplicatedContact.relatedClients?.map(client => {
                            return (
                              <div key={client.clientInfo.clientID} className="text-gray-400">
                                {client.relationshipToClient} to {client.clientInfo.clientFirstName}{' '}
                                {client.clientInfo.clientLastName}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <form
                className="mt-5"
                data-testid="duplicated-contacts-confirmation-modal"
                onSubmit={handleSubmit(onSubmit)}
              >
                <FormGroup className="mt-4">
                  {!confirmationModalHasDuplicates && (
                    <Button
                      loading={editContactIsLoading}
                      type="submit"
                      data-testid="duplicated-contacts-confirmation-verify-and-add-contact"
                      variant="primary"
                      fullWidth
                    >
                      Yes, confirm new{' '}
                      {`${phoneNumberHasChanged && !emailAddressHasChanged ? `phone number` : ''} ${
                        phoneNumberHasChanged && emailAddressHasChanged ? `phone and email` : ''
                      }  ${emailAddressHasChanged && !phoneNumberHasChanged ? `email address` : ''}`}
                    </Button>
                  )}
                  <Button
                    className="mt-3"
                    data-testid="duplicated-contacts-confirmation-modal-cancel"
                    variant="secondary"
                    fullWidth
                    onClick={() => {
                      onClose();
                      setDisplayConfirmationModal(false);
                    }}
                  >
                    Return to edit contact
                  </Button>
                </FormGroup>
              </form>
            </div>
          </React.Fragment>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DuplicatedContactsConfirmationModal;
