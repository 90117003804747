/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import { Input, Label, Select } from '@expressable/ui-library';
import { faCircleNotch, faExclamationCircle, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { areTimeslotsEqual, TimeSlot } from 'components/therapistMatcher/data';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useDisplayTimezone } from 'hooks/common/useDisplayTimezone';
import { AvailabilityType } from 'hooks/common/useTherapistSchedule';
import { useEffect, useMemo, useState } from 'react';
import 'twin.macro';
import { SelectOption, Therapist } from 'types';
import { convertOptionTimeToDayJsDate } from 'utils/helpers';
import { AvailableItem, AvailableTimesV2 } from 'domain/therapist-matcher/types';
import { ERROR_MESSAGES, getWeekSummaryStatusDescription } from 'domain/therapist/constants';
import { getApptTypeId } from 'domain/client/utils';

dayjs.extend(utc);
dayjs.extend(timezone);

export type TimeOption = {
  label: string;
  nonTzValue: number;
  value: number;
};

export type TherapistTimeSlotsCardProps = {
  date: Dayjs;
  therapist: Therapist;
  availableTimes?: AvailableTimesV2 | null;
  duration: number;
  value?: TimeSlot | null;
  isLoading?: boolean;
  isRefetching?: boolean;
  isError?: boolean;
  cardIndex?: number;
  type: AvailabilityType;
  variant?: 'therapist' | 'day';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any;
  onChange?: (value: TimeSlot | null) => void;
  isOverride?: boolean;
  setIsOverride?: React.Dispatch<React.SetStateAction<boolean>>;
  timeOptionsLowerCase?: TimeOption[];
};

const TherapistTimeSlotsCardUi: React.FC<TherapistTimeSlotsCardProps> = ({
  cardIndex,
  isOverride,
  setIsOverride,
  onChange,
  therapist,
  value,
  isLoading,
  isRefetching,
  isError,
  variant = 'therapist',
  availableTimes,
  duration,
  date,
  error,
  type,
  timeOptionsLowerCase,
}) => {
  const { formatTime, displayTimezone } = useDisplayTimezone();
  const [overrideTime, setOverrideTime] = useState<any>(undefined);

  useEffect(() => {
    if (value && !value.override) {
      setIsOverride?.(false);
    }
  }, [value, isOverride]);

  const errorMessage = useMemo(() => {
    if (error?.response?.data?.stack) {
      // This error message formatting comes from health-record-service,
      // function getTherapistAvailability in therapists/get-available-times/src/acuity.ts
      const acuityError = /\[Acuity\] ([^\n]+)/.exec(error.response.data.stack);
      if (acuityError) {
        return acuityError[1].replace(/\.$/, '');
      }
    }

    if (error?.response?.data?.message) {
      const { message } = error.response.data;

      if (message.includes(`appointment type "${getApptTypeId('evaluationTypeId')}"`)) {
        return ERROR_MESSAGES.EVALUATION_ERROR;
      } else if (message.includes(`appointment type "${getApptTypeId('sessionTypeId')}"`)) {
        return ERROR_MESSAGES.SESSION_ERROR;
      }
    }

    return 'Unexpected error loading therapist calendar';
  }, [error]);

  const isCalendarTypeError =
    errorMessage === ERROR_MESSAGES.EVALUATION_ERROR || errorMessage === ERROR_MESSAGES.SESSION_ERROR;

  // this is necessary to keep backward compatibility with (v1)
  // once we migrate all therapist available times to (v2), this mapping won't be necessary
  const mapToTimeSlot = (availableItem: AvailableItem): TimeSlot => {
    const { time, isAvailableNextWeek } = availableItem;
    return {
      timestamp: dayjs(time),
      isAvailableNextWeek: !!isAvailableNextWeek,
      therapist: therapist,
      duration: duration,
      cardIndex: cardIndex,
      override: false,
    };
  };

  return (
    <div
      tw="flex flex-col max-w-[210px] w-full bg-white p-4 rounded-lg shadow"
      data-testid={`eval-${therapist?.therapistEmail}-card-slot`}
    >
      <h3 tw="font-semibold">
        {variant === 'therapist' && therapist.therapistName}{' '}
        {variant === 'therapist' && therapist.isPRN && <span tw="opacity-50 text-xs font-semibold">(PRN)</span>}
        {variant === 'day' && date.format('dddd, M/D/YYYY')}
      </h3>

      {variant === 'therapist' && (
        <a
          className="text-indigo-700 text-sm font-semibold mt-4"
          target="_blank"
          rel="noopener noreferrer"
          href={therapist.therapistProfile}
        >
          <FontAwesomeIcon icon={faExternalLinkAlt} className="mr-2" />
          Profile
        </a>
      )}
      {isError && <h3 className={`${isCalendarTypeError ? 'text-gray-500' : 'text-red-600'} mt-4`}>{errorMessage}</h3>}
      {isLoading && <FontAwesomeIcon icon={faCircleNotch} spin className="self-center my-8 flex-grow" />}

      {availableTimes?.isRateLimited && (
        <h3 tw="text-gray-500 mt-4 mb-2" data-testid={`eval-${therapist?.therapistEmail}-card-slot-rate-limit`}>
          {getWeekSummaryStatusDescription(availableTimes.rateLimitStatus)}
        </h3>
      )}

      {availableTimes &&
        !availableTimes.isRateLimited &&
        (availableTimes?.items?.length > 0 ? (
          <div tw="mt-4 flex flex-col gap-3">
            {availableTimes.items.map(item => (
              <Label
                key={item.time}
                tw="flex items-center font-normal select-none cursor-pointer gap-1"
                data-testid={`eval-${therapist?.therapistEmail}-timeSlot-${formatTime(dayjs(item.time), 'h:mma')}`}
              >
                {onChange && (
                  <Input
                    type="radio"
                    spacing="tight"
                    checked={areTimeslotsEqual(mapToTimeSlot(item), value)}
                    onChange={event => {
                      if (event.target.checked) {
                        setIsOverride?.(false);
                        onChange?.(mapToTimeSlot(item));
                      }
                    }}
                    tw="m-1"
                    disabled={isRefetching}
                  />
                )}
                <span>{formatTime(dayjs(item.time), 'h:mm a')}</span>
                {!item.isAvailableNextWeek && (
                  <FontAwesomeIcon icon={faExclamationCircle} tw="text-yellow-400 ml-2 h-4" />
                )}
              </Label>
            ))}
          </div>
        ) : (
          <div tw="mt-4 text-gray-500">No times available</div>
        ))}

      {!isError && !isLoading && (
        <div>
          <Label
            tw="flex items-center font-normal select-none cursor-pointer gap-1 mt-2"
            data-testid={`eval-${therapist?.therapistEmail}-card-slot-override`}
          >
            <Input
              id="overrideRadio"
              type="radio"
              spacing="tight"
              tw="m-1"
              disabled={isRefetching}
              checked={isOverride && cardIndex === value?.cardIndex}
              onChange={event => {
                if (event.target.checked) {
                  setIsOverride?.(true);
                  onChange?.({
                    timestamp: value?.timestamp ? overrideTime : undefined, //This will add the newDate time to the object, we need this to pass the cardIndex to the value selected, if we dont have a newDate this will be undefined.
                    duration: type === 'evaluation' ? 60 : 30,
                    isAvailableNextWeek: true,
                    therapist,
                    override: true,
                    cardIndex: cardIndex,
                  });
                }
              }}
              data-testid={`eval-${therapist?.therapistEmail}-card-slot-override-radio`}
            />
            Schedule Override
          </Label>

          {isOverride && cardIndex === value?.cardIndex && (
            <Select
              id="overrideSelect"
              className="mt-2"
              onChange={(option: SelectOption) => {
                const newOverrideDate = convertOptionTimeToDayJsDate(date, option, displayTimezone);
                setOverrideTime(newOverrideDate);

                onChange?.({
                  timestamp: newOverrideDate,
                  duration: type === 'evaluation' ? 60 : 30,
                  isAvailableNextWeek: true,
                  therapist,
                  override: true,
                  cardIndex: cardIndex,
                });
              }}
              options={timeOptionsLowerCase}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default TherapistTimeSlotsCardUi;
