import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  CloseButton,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  Textarea,
} from '@expressable/ui-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarTimes } from '@fortawesome/free-solid-svg-icons';
import ModalHeadline from 'components/modal-headline';
import dayjs from 'dayjs';
import FormGroupTitle from 'components/form-group-title';
import { useForm } from 'react-hook-form';
import ErrorSpan from 'components/error-span';
import RHFSelect from 'components/RHFSelectBase';
import { SESSION_CANCELATION_REASONS_ENTRY_ID } from 'components/client-attendance-modals/log-cancelation-modal';
import { useContentfulEntry } from 'hooks/use-contentful';
import { CancelRecurringAppointmentPayload, useCancelRecurringAppointment } from 'hooks/use-appointments';
import { useQueryClient } from 'react-query';
import { getCueError } from 'utils/error';
import { CancelRecurringAppointmentsModalProps } from 'domain/recurring-appointments/types';
import {
  CANCELATION_REASONS_INITIAL_STATE,
  RecurringAppointmentsKeywordErrors,
} from 'domain/recurring-appointments/constants';
import { convertStringArrayToSelectOptions } from 'domain/notes/mapHelpers';
import 'twin.macro';

function CancelRecurringAppointmentsModal(props: CancelRecurringAppointmentsModalProps) {
  const queryClient = useQueryClient();
  const { isOpen, onClose, recurringAppointmentData, clientFirstName, clientLastName } = props;
  const [errorMessage, setErrorMessage] = useState<string>();
  const { mutateAsync: cancelRecurringAppointment, isLoading: isCancelRecurringAppointmentLoading } =
    useCancelRecurringAppointment();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    clearErrors,
    getValues,
    formState: { errors, isValid },
  } = useForm<CancelRecurringAppointmentPayload>({
    defaultValues: CANCELATION_REASONS_INITIAL_STATE,
    mode: 'onChange',
  });

  const selectedCancelationReasonValue = watch('cancelationReason');

  const { data: contentfulEntry = {}, isLoading: isContentfulLoading } = useContentfulEntry({
    entryId: SESSION_CANCELATION_REASONS_ENTRY_ID,
    unwrapArray: true,
  });

  const cancelationReasons = useMemo(() => {
    if (isContentfulLoading) return [];
    return convertStringArrayToSelectOptions(contentfulEntry?.dropdownContent);
  }, [contentfulEntry]);

  const isLoading = isContentfulLoading || isCancelRecurringAppointmentLoading;

  const resetModalState = () => {
    reset(CANCELATION_REASONS_INITIAL_STATE);
  };

  const cleanErrorsForCancelationReasonOther = () => {
    if (selectedCancelationReasonValue !== 'Other') {
      clearErrors('cancelationReasonOther');
      reset(
        {
          ...getValues(),
          cancelationReasonOther: '',
        },
        { keepIsValid: false, keepErrors: false },
      );
    }
  };

  useEffect(cleanErrorsForCancelationReasonOther, [selectedCancelationReasonValue]);
  useEffect(resetModalState, [isOpen]);

  const onSubmitCancelRecurringAppointment = async (data: CancelRecurringAppointmentPayload) => {
    await cancelRecurringAppointment(
      {
        clientID: recurringAppointmentData.clientID,
        recurringAppointmentID: recurringAppointmentData.recurringAppointmentID,
        canceledBy: data.canceledBy,
        cancelationReason: data.cancelationReason,
        cancelationReasonOther: selectedCancelationReasonValue === 'Other' ? data.cancelationReasonOther : undefined,
        additionalText: data.additionalText ? data.additionalText : undefined,
        rescheduleAttempted: false,
      },
      {
        onError: err => {
          const error = getCueError(err);
          const errorMessage =
            error.keyword === RecurringAppointmentsKeywordErrors.NotFound ? error.keyword : error.message;
          setErrorMessage(errorMessage);
          return;
        },
      },
    );

    queryClient.invalidateQueries(['recurring-appointments', recurringAppointmentData.clientID]);
    queryClient.invalidateQueries(['appointments', recurringAppointmentData.clientID]);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} tw="max-w-lg">
      <ModalContent>
        <ModalHeader>
          <div tw="absolute top-0 right-0 pt-4 pr-4">
            <CloseButton testId="close-log-cancelation-modal" onClick={onClose} />
          </div>
        </ModalHeader>
        <ModalBody>
          <React.Fragment>
            <div tw="flex items-center justify-center w-12 h-12 mx-auto bg-indigo-100 rounded-full">
              <FontAwesomeIcon tw="text-2xl text-indigo-700" icon={faCalendarTimes} />
            </div>
            <div tw="my-3 sm:mt-5">
              <ModalHeadline text={`Log Recurring Cancelation for ${clientFirstName} ${clientLastName}`} />
              <div tw="mt-2">
                <h4 tw="text-center leading-6">
                  {dayjs(recurringAppointmentData?.startDate).tz(dayjs.tz.guess()).format('dddd')}
                  {'s'} at&nbsp;
                  {dayjs(recurringAppointmentData?.startDate).tz(dayjs.tz.guess()).format('hh:mm A')}
                </h4>
                <h4 tw="text-center">
                  with {recurringAppointmentData.therapistFirstName} {recurringAppointmentData.therapistLastName}{' '}
                </h4>
              </div>
              <form
                tw="mt-5"
                data-testid="cancel-recurring-appointment-form"
                onSubmit={handleSubmit(onSubmitCancelRecurringAppointment)}
              >
                <div tw="space-y-2">
                  <FormGroupTitle title="Canceled by" fontSize="small" fontWeight="semi" spacing="normal" />
                  <FormGroup type="inline" tw="items-center">
                    <Input
                      type="radio"
                      id="canceledBy-client"
                      spacing="tight"
                      data-testid="recurring-appointment-canceled-by-client"
                      {...register('canceledBy', { required: 'Please fill out this field.' })}
                      value="client"
                    />
                    <Label font="normal" htmlFor="canceledBy-client" tw="ml-2">
                      Client
                    </Label>
                    <Input
                      id="canceledBy-therapist"
                      tw="ml-3"
                      spacing="tight"
                      type="radio"
                      data-testid="recurring-appointment-canceled-by-therapist"
                      {...register('canceledBy', { required: 'Please fill out this field.' })}
                      value="therapist"
                    />
                    <Label font="normal" htmlFor="canceledBy-therapist" tw="ml-2">
                      Therapist
                    </Label>
                  </FormGroup>
                </div>
                <ErrorSpan error={errors?.canceledBy?.message} />
                <FormGroup data-testid="cancelation-reason" tw="mt-4">
                  <FormGroupTitle title="Cancelation Reason" fontSize="small" fontWeight="semi" spacing="normal" />
                  <RHFSelect
                    name="cancelationReason"
                    control={control}
                    required
                    options={cancelationReasons ?? []}
                    error={errors?.cancelationReason}
                  />
                </FormGroup>
                {Boolean(selectedCancelationReasonValue === 'Other') && (
                  <FormGroup>
                    <div tw="flex flex-row">
                      <FormGroupTitle
                        title="What was the reason?"
                        fontSize="small"
                        fontWeight="semi"
                        spacing="normal"
                      />
                      <span className="mt-2 ml-2 text-xxs text-gray-400 leading-none">OPTIONAL</span>
                    </div>
                    <Input
                      tw="w-full"
                      data-testid="cancelation-reason-other"
                      {...register('cancelationReasonOther')}
                      error={errors.cancelationReasonOther}
                    />
                  </FormGroup>
                )}
                <FormGroup tw="mt-2">
                  <div tw="flex flex-row">
                    <FormGroupTitle
                      title="Please add any additional information"
                      fontSize="small"
                      fontWeight="semi"
                      spacing="normal"
                    />
                    <span className="mt-2 ml-2 text-xxs text-gray-400 leading-none">OPTIONAL</span>
                  </div>
                  <Textarea
                    tw="w-full"
                    spacing="normal"
                    rows={3}
                    data-testid="aditional-information"
                    {...register('additionalText')}
                    error={errors.additionalText}
                  />
                </FormGroup>
                {errorMessage === RecurringAppointmentsKeywordErrors.NotFound ? (
                  <p tw="text-center text-red-600 font-semibold mb-2">
                    This recurring appointment can no longer be found.
                  </p>
                ) : (
                  <p tw="text-center text-red-600 font-semibold mb-2">{errorMessage}</p>
                )}
                <FormGroup tw="mt-4">
                  <Button
                    loading={isLoading}
                    disabled={!isValid}
                    data-testid="cancel-recurring-appointment-onsave"
                    variant="primary"
                    fullWidth
                  >
                    Cancel Recurring Appointment
                  </Button>
                  <Button
                    tw="mt-3"
                    data-testid="cancel-recurring-appointment-close-no-login"
                    variant="secondary"
                    fullWidth
                    onClick={onClose}
                  >
                    Exit Without Logging
                  </Button>
                </FormGroup>
              </form>
            </div>
          </React.Fragment>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default CancelRecurringAppointmentsModal;
