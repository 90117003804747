import React, { useState } from 'react';
import tw from 'twin.macro';

export function useExpandableSubSteps(initialState: boolean[]) {
  const [expandedSubSteps, setExpandedSubSteps] = useState(initialState);

  const setSubStep = (index: number, value: boolean) => {
    setExpandedSubSteps([...expandedSubSteps.slice(0, index), value, ...expandedSubSteps.slice(index)]);
  };

  const expandSubStep = (index: number) => setSubStep(index, true);
  const collapseSubStep = (index: number) => setSubStep(index, false);

  return { expandedSubSteps, expandSubStep, collapseSubStep };
}

interface SubStepProps {
  title: React.ReactFragment;
  children: React.ReactNode;
  expanded: boolean;
}

const SubStep = (props: SubStepProps) => {
  return (
    <div tw="mb-14">
      <h3 css={[tw`text-lg font-semibold mb-4`, !props.expanded && tw`text-gray-400`]}>{props.title}</h3>
      {props.expanded && <div>{props.children}</div>}
    </div>
  );
};

export default SubStep;
