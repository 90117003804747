import React, { useCallback, useEffect } from 'react';
import {
  Button,
  CloseButton,
  FormGroup,
  FormInline,
  Label,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
  Input,
  Textarea,
  Loader,
  Link,
  useDisclosure,
} from '@expressable/ui-library';
import 'twin.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { Controller, useForm, useWatch, Control } from 'react-hook-form';
import {
  useCreateLongTermGoal,
  useCreateShortTermGoal,
  useUpdateLongTermGoal,
  useUpdateShortTermGoal,
  Goal,
  useGetGoals,
} from 'hooks/use-care-plan-goals';
import { useContentfulData, getCollectionFields } from 'hooks/use-ContentfulData';
import { capitalizeFirst, truncateZeroes } from 'utils/helpers';
import { GoalProgress, SelectOption } from 'types';
import { DeleteGoalModal } from './client-delete-goal-modal';
import useFetchContentfulStg from 'hooks/shared/use-fetch-contentful-stg';
import { EntryCollection } from 'contentful';
import { InputNumberStyled } from 'components/edit-therapist-info-form';
import { handleArrowKeyDown } from './client-notes/note-form-content/session-note-form-content';
import dayjs from 'dayjs';

type ClientModalActionType = 'createLtg' | 'createStg' | 'updateLtg' | 'updateStg' | undefined;

type selectOption = {
  label: string;
  value: string;
};

export function fromFieldsToSelectOptions<T>(
  fields: Array<T> | undefined,
  shouldLower = false,
  getValuesCallback?: (field: T) => string,
  getOptionsCallback?: (value: string) => { label: string; value: string },
): selectOption[] | undefined {
  if (fields) {
    return fields
      .map(field => {
        if (getValuesCallback) {
          return getValuesCallback(field);
        } else {
          return Object.values(field)[0];
        }
      })
      .sort()
      .map(fieldValue => {
        const currentFieldValue = shouldLower ? fieldValue.toLowerCase() : fieldValue;
        if (getOptionsCallback) {
          return getOptionsCallback(currentFieldValue);
        } else {
          return {
            label: currentFieldValue,
            value: currentFieldValue,
          };
        }
      });
  }
}

type ContentfulField = { [key: string]: string };

const numberSelectOptions = Array.from(Array(13).keys())
  .filter(index => index != 0)
  .map(index => ({ label: index, value: index }));

interface ClientGoalsForm {
  goalTime: SelectOption;
  goalsTimeUnit: SelectOption;
  goalDescription: string | SelectOption;
  goalCues: SelectOption;
  goalOpportunity: string;
  goalOpportunityUnit: SelectOption;
  goalPeriod: SelectOption;
  goalPeriodUnit: SelectOption;
  goalOutcome: SelectOption;
  goalStatus: SelectOption;
  goalTreatmentArea: SelectOption;
  goalBaselinePerformance: string;
  carePlanGoalsProgress: GoalProgress;
}

export interface ClientGoalsModalProps {
  isOpen: boolean;
  onClose: () => void;
  clientId: string;
  goal: Goal | undefined;
  action: ClientModalActionType;
  setCurrentAction: React.Dispatch<React.SetStateAction<ClientModalActionType>>;
  shouldLogEvent: boolean;
}

export const handleDecimalNumber = (event: { keyCode: number; which: number; preventDefault: () => void }) => {
  const keyCode = event.keyCode || event.which;
  const keyValue = String.fromCharCode(keyCode);
  if (!new RegExp('[0-9]').test(keyValue)) event.preventDefault();
  return;
};

export function ClientGoalsModal(props: ClientGoalsModalProps) {
  const { isOpen, onClose, clientId, goal, action, setCurrentAction, shouldLogEvent } = props;
  const { ltgID, status, detail, treatmentArea: goalTreatmentArea } = goal || {};
  const { data: goals } = useGetGoals({ clientId });
  const [cueLevels] = useContentfulData<ContentfulField>('cueLevels');
  const [objectiveOutcomes] = useContentfulData<ContentfulField>('objectiveOutcomes');
  const [functionalOutcomes] = useContentfulData<ContentfulField>('functionalOutcomes');
  const { expressableAcademyTreatmentAreas, shortTermGoals: shortTermGoalsEntries } =
    useFetchContentfulStg(goalTreatmentArea);
  const shortTermGoalsDescriptionOptions =
    goalTreatmentArea &&
    fromFieldsToSelectOptions(getCollectionFields(shortTermGoalsEntries as EntryCollection<unknown>));

  const dueDate = dayjs(goal?.dueAt).format('MM/DD/YYYY');
  const createdAt = dayjs(goal?.createdAt).format('MM/DD/YYYY');

  const {
    isOpen: DeleteGoalModalIsOpen,
    onClose: DeleteGoalModalOnClose,
    onOpen: DeleteGoalModalOnOpen,
  } = useDisclosure();

  const createLongTermGoalMutation = useCreateLongTermGoal();
  const { mutateAsync: createLongTermGoal } = createLongTermGoalMutation;

  const creatShortTermGoalMutation = useCreateShortTermGoal();
  const { mutateAsync: createShortTermGoal } = creatShortTermGoalMutation;

  const updateLongTermGoalMutation = useUpdateLongTermGoal();
  const { mutateAsync: updateLongTermGoal } = updateLongTermGoalMutation;

  const updateShortTermGoalMutation = useUpdateShortTermGoal();
  const { mutateAsync: updateShortTermGoal } = updateShortTermGoalMutation;

  let goalsFormDefaultValues = {};

  function GoalOpportunityUnitWatched({ control }: { control: Control<ClientGoalsForm> }) {
    const goalOpportunity = useWatch({
      control,
      name: 'goalOpportunityUnit', // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    });

    return <div tw="flex h-10 items-start">{goalOpportunity?.label ?? ''}</div>; // only re-render at the component level, when firstName changes
  }

  const actionToGoalOperationMapping = {
    createLtg: createLongTermGoal,
    updateLtg: updateLongTermGoal,
    createStg: createShortTermGoal,
    updateStg: updateShortTermGoal,
  };

  const shortTermGoalsArray: React.SetStateAction<Goal[] | undefined> = [];

  useEffect(() => {
    goals
      ?.filter(goal => goal.goalType === 'ltg')
      .map(currentLtg => {
        const currentShortTermGoals = goals.filter(goal => goal.goalType === 'stg' && currentLtg.ltgID === goal.ltgID);

        if (currentShortTermGoals.length > 0) {
          shortTermGoalsArray.push(...currentShortTermGoals);
        }
      });
  }, []);

  if (action === 'updateLtg' || action === 'updateStg') {
    if (typeof detail !== 'undefined') {
      goalsFormDefaultValues = {
        goalTime: { label: detail.goalTime, value: detail.goalTime },
        goalsTimeUnit: { label: detail.goalsTimeUnit, value: detail.goalsTimeUnit },
        goalCues: { label: detail.goalCues, value: detail.goalCues },
        goalOpportunity: detail.goalOpportunity,
        goalOpportunityUnit: { label: detail.goalOpportunityUnit, value: detail.goalOpportunityUnit },
        goalPeriod: { label: detail.goalPeriod, value: detail.goalPeriod },
        goalPeriodUnit: { label: 'sessions', value: 'sessions' },
        goalOutcome: { label: detail.goalOutcome, value: detail.goalOutcome },
        goalTreatmentArea: { label: goalTreatmentArea, value: goalTreatmentArea },
        goalStatus: { label: capitalizeFirst(status), value: status },
        goalBaselinePerformance: detail.goalBaselinePerformance,
      };
    }
  }
  if (action === 'createLtg' || action === 'createStg') {
    goalsFormDefaultValues = {
      goalsTimeUnit: { label: 'months', value: 'months' },
      goalOpportunityUnit: { label: '% of opportunities', value: '% of opportunities' },
      goalOutcome: {
        label: 'effectively communicate basic wants and needs',
        value: 'effectively communicate basic wants and needs',
      },
    };
  }

  const {
    formState: { errors },
    handleSubmit,
    control,
    register,
  } = useForm<ClientGoalsForm>({
    defaultValues: goalsFormDefaultValues,
  });

  const onSubmit = async (data: ClientGoalsForm) => {
    const { goalDescription } = data;

    if (
      (action === 'createStg' || action === 'updateStg') &&
      ltgID &&
      typeof goalDescription !== 'string' &&
      goalTreatmentArea
    ) {
      const stgGoalId = action === 'updateStg' ? goal?.stgID : undefined;
      await actionToGoalOperationMapping[action]({
        clientId,
        ltgId: ltgID,
        stgId: stgGoalId,
        goal: {
          version: '1.0',
          goalType: 'stg',
          status: data?.goalStatus?.value.toLowerCase() ?? 'progressing',
          detail: {
            goalTime: data.goalTime.value,
            goalsTimeUnit: data.goalsTimeUnit.value,
            goalDescription: goalDescription.value,
            goalCues: data.goalCues.value,
            goalOpportunity: truncateZeroes(data.goalOpportunity),
            goalOpportunityUnit: data.goalOpportunityUnit.value,
            goalPeriod: data.goalPeriod.value,
            goalPeriodUnit: 'sessions',
            goalOutcome: data.goalOutcome.value,
            goalTreatmentArea: goalTreatmentArea,
            goalBaselinePerformance: truncateZeroes(data.goalBaselinePerformance),
          },
          shouldLogEvent,
        },
      });
    } else if ((action === 'createLtg' || action === 'updateLtg') && typeof goalDescription === 'string') {
      const ltgGoalId = action === 'updateLtg' ? goal?.ltgID : undefined;
      await actionToGoalOperationMapping[action]({
        clientId,
        ltgId: ltgGoalId,
        goal: {
          version: '1.0',
          goalType: 'ltg',
          status: data?.goalStatus?.value?.toLowerCase() ?? 'progressing',
          detail: {
            goalTime: data.goalTime.value,
            goalsTimeUnit: data.goalsTimeUnit.value,
            goalDescription: goalDescription,
            goalCues: data.goalCues.value,
            goalOpportunity: truncateZeroes(data.goalOpportunity),
            goalOpportunityUnit: data.goalOpportunityUnit.value,
            goalPeriod: data.goalPeriod.value,
            goalPeriodUnit: 'sessions',
            goalOutcome: data.goalOutcome.value,
            goalTreatmentArea: data.goalTreatmentArea.value,
            goalBaselinePerformance: truncateZeroes(data.goalBaselinePerformance),
          },
          shouldLogEvent,
        },
      });
    }

    onClose();
  };

  const onClickDuplicate = useCallback(() => {
    if (action === 'updateLtg') setCurrentAction('createLtg');
    if (action === 'updateStg') setCurrentAction('createStg');
  }, []);

  const TooltipDeleteGoalWithStg = () => (
    <div tw="inline-block relative">
      <div className="group">
        <FontAwesomeIcon tw="text-gray-700 text-sm ml-2" icon="exclamation-circle" />
        <div tw="border border-solid bg-white rounded-lg absolute border-gray-200 left-0 shadow-md w-72 text-xs p-3 z-10 invisible group-hover:visible text-black">
          <p>This goal has connected short-term goals and can&apos;t be deleted. </p>
          <br />
          <p>To delete this goal, please delete the connected short-term goals first.</p>
          <br />
        </div>
      </div>
    </div>
  );

  const TooltipDeleteGoalWithProgress = () => (
    <div tw="inline-block relative">
      <div className="group">
        <FontAwesomeIcon tw="text-gray-700 text-sm ml-2" icon="exclamation-circle" />
        <div tw="border border-solid bg-white rounded-lg absolute border-gray-200 left-0 shadow-md w-72 text-xs p-3 z-10 invisible group-hover:visible text-black">
          <p>This goal has associated client progress and cannot be deleted. </p>
          <br />
          <p>Please discontinue this goal instead.</p>
          <br />
        </div>
      </div>
    </div>
  );

  return (
    <>
      {/* with this condition you can render only one modal at a time */}
      {DeleteGoalModalIsOpen === false ? (
        <Modal portal isOpen={isOpen}>
          <ModalContent>
            <ModalHeader>
              <CloseButton onClick={onClose} />
            </ModalHeader>
            {expressableAcademyTreatmentAreas ? (
              <form onSubmit={handleSubmit(onSubmit)} id="care-plan-goals-form" data-testid="care-plan-goals-form">
                <ModalBody>
                  <div>
                    <div tw="flex items-center justify-center w-12 h-12 mx-auto bg-indigo-100 rounded-full">
                      <FontAwesomeIcon tw="text-2xl text-indigo-700" icon={faFileAlt} />
                    </div>
                    <div tw="mt-3 sm:mt-5">
                      <h3 tw="text-lg text-center font-medium text-gray-900 leading-6" id="modal-headline">
                        {action === 'createLtg' && `New Long-Term Goal`}
                        {action === 'createStg' && `New Short-Term Goal`}
                        {action === 'updateLtg' && `Update Long-Term Goal`}
                        {action === 'updateStg' && `Update Short-Term Goal`}
                      </h3>

                      <div tw="flex justify-center space-x-3">
                        {(action == 'updateLtg' || action === 'updateStg') && (
                          <Link to="#" tw="mt-1 mb-9" data-testid="duplicate-goal" onClick={onClickDuplicate}>
                            Duplicate Goal
                          </Link>
                        )}
                        {action == 'updateLtg' && !goal?.hasSTGs && (
                          <Link
                            to="#"
                            tw="mt-1 mb-9 text-red-600"
                            data-testid="delete-ltg-goal"
                            onClick={() => {
                              DeleteGoalModalOnOpen();
                            }}
                          >
                            Delete Goal
                          </Link>
                        )}
                        {/* this will look if the STG does not have progress */}
                        {action == 'updateStg' && !goal?.progress && (
                          <Link
                            to="#"
                            tw="mt-1 mb-9 text-red-600"
                            data-testid="delete-stg-goal"
                            onClick={() => {
                              DeleteGoalModalOnOpen();
                            }}
                          >
                            Delete Goal
                          </Link>
                        )}
                        {action == 'updateLtg' && goal?.hasSTGs && (
                          <Link
                            to="#"
                            tw="mt-1 mb-9 text-gray-400"
                            id="delete-goal-tool-with-stg"
                            data-testid="delete-goal-tool-with-stg"
                          >
                            Delete Goal
                            <TooltipDeleteGoalWithStg />
                          </Link>
                        )}
                        {/* this will look if the STG have progress */}
                        {action == 'updateStg' && goal?.progress && (
                          <Link
                            to="#"
                            tw="mt-1 mb-9 text-gray-400"
                            id="delete-goal-tool-with-progress"
                            data-testid="delete-goal-tool-with-progress"
                          >
                            Delete Goal
                            <TooltipDeleteGoalWithProgress />
                          </Link>
                        )}
                      </div>
                      <div tw="mt-1">
                        <FormGroup>
                          <Label htmlFor="longTermGoal">Within</Label>
                          <FormInline tw="flex-col md:flex-row gap-2" data-testid="diagnoses">
                            <div tw="md:w-1/3" data-testid="client-goals-modal-within">
                              <Controller
                                name="goalTime"
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    {...field}
                                    options={numberSelectOptions}
                                    error={errors.goalTime}
                                    isSearchable={true}
                                  />
                                )}
                                rules={{ required: true }}
                              />
                            </div>
                            <div tw="md:w-2/3" data-testid="client-goals-modal-time">
                              <Controller
                                name="goalsTimeUnit"
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    {...field}
                                    options={[
                                      { label: 'days', value: 'days' },
                                      { label: 'weeks', value: 'weeks' },
                                      { label: 'months', value: 'months' },
                                    ]}
                                    error={errors.goalsTimeUnit}
                                    isSearchable={true}
                                  />
                                )}
                                rules={{ required: true }}
                              />
                            </div>
                          </FormInline>
                          {['updateStg', 'updateLtg'].includes(action!) && goal?.dueAt && (
                            <div tw="-mt-3">
                              <div tw="text-xs text-gray-400">{`Goal created: ${createdAt}, Due Date: ${dueDate}`}</div>
                            </div>
                          )}
                        </FormGroup>
                        <FormGroup tw="mt-2">
                          <Label htmlFor="diagnoses">Client Will</Label>
                          <div tw="w-full" data-testid="diagnoses">
                            {action === 'createLtg' || action === 'updateLtg' ? (
                              <Textarea
                                {...register('goalDescription', { required: true })}
                                tw="w-full resize-none"
                                data-testid="client-goals-modal-client-will-text"
                                rows={3}
                                spacing="small"
                                defaultValue={goal?.detail?.goalDescription}
                                error={errors.goalDescription}
                              />
                            ) : (
                              <FormInline tw="gap-2" data-testid="diagnoses">
                                <div tw="w-full" data-testid="client-goals-modal-client-will-dropdown">
                                  <Controller
                                    name="goalDescription"
                                    control={control}
                                    defaultValue={
                                      goal && action !== 'createStg'
                                        ? {
                                            label: goal.detail?.goalDescription,
                                            value: goal.detail?.goalDescription,
                                          }
                                        : undefined
                                    }
                                    render={({ field }) => (
                                      <Select
                                        {...field}
                                        options={shortTermGoalsDescriptionOptions}
                                        error={errors.goalCues}
                                        isSearchable={true}
                                        spacing="none"
                                      />
                                    )}
                                    rules={{ required: true }}
                                  />
                                  <div tw="text-xs text-gray-400 mt-2">{`Based on treatment area: ${goalTreatmentArea} `}</div>
                                </div>
                              </FormInline>
                            )}
                          </div>
                        </FormGroup>
                        <FormGroup tw="mt-2">
                          <Label htmlFor="longTermGoal">Given</Label>
                          <FormInline tw="gap-2" data-testid="diagnoses">
                            <div tw="w-full" data-testid="client-goals-modal-given">
                              <Controller
                                name="goalCues"
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    {...field}
                                    options={fromFieldsToSelectOptions(getCollectionFields(cueLevels), true)}
                                    error={errors.goalCues}
                                    isSearchable={true}
                                  />
                                )}
                                rules={{ required: true }}
                              />
                            </div>
                          </FormInline>
                        </FormGroup>
                        <FormGroup>
                          <Label htmlFor="longTermGoal">At</Label>
                          <FormInline tw="gap-2" data-testid="diagnoses">
                            <InputNumberStyled>
                              <Input
                                {...register('goalOpportunity', { required: true })}
                                type="number"
                                onWheel={event => event.currentTarget.blur()}
                                onKeyDown={handleArrowKeyDown}
                                data-testid="client-goals-modal-at"
                                onKeyPress={handleDecimalNumber}
                                min="0"
                                max="100"
                                tw="w-16"
                                style={{ height: '38px' }}
                                error={errors.goalOpportunity}
                                maxLength={3}
                              />
                            </InputNumberStyled>

                            <div tw="flex-grow" data-testid="client-goals-modal-opportunity">
                              <Controller
                                name="goalOpportunityUnit"
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    {...field}
                                    options={fromFieldsToSelectOptions(getCollectionFields(objectiveOutcomes))}
                                    error={errors.goalOpportunity}
                                    isSearchable={true}
                                  />
                                )}
                                rules={{ required: true }}
                              />
                            </div>
                          </FormInline>
                        </FormGroup>
                        <FormGroup>
                          <Label htmlFor="longTermGoal">Across</Label>
                          <FormInline tw="flex-col md:flex-row gap-2" data-testid="diagnoses">
                            <div tw="md:w-1/2" data-testid="client-goals-modal-across">
                              <Controller
                                name="goalPeriod"
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    {...field}
                                    options={numberSelectOptions}
                                    error={errors.goalPeriod}
                                    isSearchable={true}
                                  />
                                )}
                                rules={{ required: true }}
                              />
                            </div>
                            <div tw="md:w-1/2">
                              <div tw="flex h-10 items-center gap-2">sessions</div>
                            </div>
                          </FormInline>
                        </FormGroup>
                        <FormGroup>
                          <Label htmlFor="longTermGoal">In order to</Label>
                          <div tw="w-full" data-testid="client-goals-modal-in-order-to">
                            <Controller
                              name="goalOutcome"
                              control={control}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  options={fromFieldsToSelectOptions(getCollectionFields(functionalOutcomes), true)}
                                  error={errors.goalOutcome}
                                  isSearchable={true}
                                />
                              )}
                              rules={{ required: true }}
                            />
                          </div>
                        </FormGroup>
                        <hr tw="mb-4" />
                        {(action === 'updateLtg' || action === 'updateStg') && (
                          <FormGroup>
                            <Label htmlFor="goalStatus">Status</Label>
                            <div tw="w-full" data-testid="client-goals-modal-status">
                              <Controller
                                name="goalStatus"
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    {...field}
                                    defaultValue={{
                                      label: goal?.status,
                                      value: goal?.status,
                                    }}
                                    options={[
                                      { label: 'Progressing', value: 'Progressing' },
                                      { label: 'Met', value: 'Met' },
                                      { label: 'Modified', value: 'Modified' },
                                      { label: 'Discontinued', value: 'Discontinued' },
                                    ]}
                                    error={errors.goalStatus}
                                    isSearchable={true}
                                  />
                                )}
                                rules={{ required: true }}
                              />
                            </div>
                          </FormGroup>
                        )}

                        {(action === 'createLtg' || action === 'updateLtg') && (
                          <FormGroup>
                            <Label htmlFor="longTermGoal">Treatment Area</Label>
                            <FormInline tw="gap-2" data-testid="diagnoses">
                              <div tw="w-full" data-testid="client-goals-modal-treatment-area">
                                <Controller
                                  name="goalTreatmentArea"
                                  control={control}
                                  render={({ field }) => (
                                    <Select
                                      {...field}
                                      options={fromFieldsToSelectOptions(
                                        getCollectionFields(
                                          expressableAcademyTreatmentAreas as EntryCollection<unknown>,
                                        ),
                                      )}
                                      error={errors.goalCues}
                                      isSearchable={true}
                                    />
                                  )}
                                  rules={{ required: true }}
                                />
                              </div>
                            </FormInline>
                          </FormGroup>
                        )}
                        <FormGroup>
                          <Label htmlFor="longTermGoal">Baseline Performance for Goal</Label>
                          <FormInline tw="gap-2 items-center" data-testid="diagnoses">
                            <InputNumberStyled>
                              <Input
                                {...register('goalBaselinePerformance', { required: true })}
                                type="number"
                                onWheel={event => event.currentTarget.blur()}
                                onKeyDown={handleArrowKeyDown}
                                onKeyPress={handleDecimalNumber}
                                data-testid="client-goals-modal-baseline"
                                min="0"
                                max="100"
                                tw="w-16"
                                style={{ height: '38px' }}
                                error={errors.goalBaselinePerformance}
                                maxLength={3}
                              />
                            </InputNumberStyled>
                            <GoalOpportunityUnitWatched control={control} />
                          </FormInline>
                        </FormGroup>
                      </div>
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <div tw="mt-5 sm:mt-6 sm:grid sm:grid-cols-1 sm:gap-3 sm:grid-flow-row-dense">
                    <span tw="flex w-full rounded-md shadow-sm">
                      <Button
                        type="submit"
                        form="care-plan-goals-form"
                        data-testid="client-diagnoses-submit"
                        variant="primary"
                        tw="inline-flex justify-center w-full px-4 py-2 text-base font-medium leading-6 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                        loading={
                          createLongTermGoalMutation.isLoading ||
                          creatShortTermGoalMutation.isLoading ||
                          updateLongTermGoalMutation.isLoading ||
                          updateShortTermGoalMutation.isLoading
                        }
                      >
                        Save
                      </Button>
                    </span>
                  </div>
                </ModalFooter>
              </form>
            ) : (
              <div tw="flex content-center justify-center">
                <Loader type="ring" />
              </div>
            )}
          </ModalContent>
        </Modal>
      ) : (
        <DeleteGoalModal
          isOpen={DeleteGoalModalIsOpen}
          onClose={() => {
            DeleteGoalModalOnClose();
          }}
          clientId={clientId}
          ltgId={ltgID}
          stgId={goal?.stgID}
          onSubmitDeleteGoal={() => {
            onClose();
          }}
        />
      )}
    </>
  );
}
