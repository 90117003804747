import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { faTrophy } from '@fortawesome/pro-solid-svg-icons';
import { FirehoseEventCaregiverGoals } from 'domain/admin/types';
import { fromStatusToBadgeVariant } from 'pages/client/components/client-plan-of-care-form';
import { Badge } from '@expressable/ui-library';
import 'twin.macro';

const ClientAdditionalGoalModified = (event: FirehoseEventCaregiverGoals) => {
  const firehoseEvent = event?.event;
  const isLastEvent = event?.isLastEvent;
  const currentGoalStatusVariation = fromStatusToBadgeVariant(firehoseEvent.detail.status);

  return (
    <div data-testid="firehose-client-additional-goal-modified" tw="relative flex mt-7 px-2">
      {!isLastEvent && <span tw="absolute -z-1 top-7 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>}
      <div tw="flex items-baseline">
        <div tw="flex items-center bg-gray-200 rounded-full h-6 w-6">
          <FontAwesomeIcon icon={faTrophy} tw="mx-1.5 h-3 text-indigo-700" />
        </div>
        <div tw="pl-4 text-sm">
          <div tw="lg:flex font-semibold pb-1 text-indigo-700">
            <Link to={{ pathname: `/therapists/${firehoseEvent?.createdBy}` }}>
              {firehoseEvent?.createdByFirstName} {firehoseEvent?.createdByLastName}&nbsp;
            </Link>
            <span tw="text-gray-500">{`modified a "${firehoseEvent.detail.goalType}" for the client`}</span>
            &nbsp;
            <Link tw="text-indigo-700" to={{ pathname: `/clients/${firehoseEvent?.detail.clientID}` }}>
              {firehoseEvent?.clientFirstName} {firehoseEvent?.clientLastName}&nbsp;
            </Link>
            <span tw="text-gray-500">{`at ${dayjs(firehoseEvent?.createdAt as string).format('h:mm A')}, ${dayjs(
              firehoseEvent?.createdAt as string,
            ).format('MM/DD/YYYY')} `}</span>
          </div>
          {firehoseEvent?.detail.detail ? <div>{firehoseEvent.detail.detail.goalDescription} </div> : null}
          <div tw="mt-2">
            <Badge tw="text-xs font-medium" {...currentGoalStatusVariation}>
              MODIFIED
            </Badge>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientAdditionalGoalModified;
