import { useQuery } from 'react-query';

import { http } from '@expressable/utils';

import { ClientInfo } from 'types'

// How long until we re-fetch the client list, in milliseconds
const CLIENTS_CACHE_LIFETIME = 1000;

const getClients = async (email?: string, status?: string) => {
  if (!email && !status) {
    return false;
  }

  const params: any = {
    inactive: status
  }

  if(email !== 'all') {
    params.therapistID = email
  }

  // Active Clients
  const { data } = await http.get(`/clients`, {
    params
  });
  return data;
};

export default function useClients(email?: string, status?: string) {
  return useQuery(['clients', email, status], () => getClients(email, status), {
    staleTime: CLIENTS_CACHE_LIFETIME,
    refetchOnMount: 'always',
  });
}

const getClientsByTherapist = async (therapistID: string, params: any): Promise<(ClientInfo)[]> => {
  const { data } = await http.get(`/therapists/${therapistID}/clients`, { params });
  return data;
}

export function useClientsByTherapist(therapistID: string, params: any) {
  return useQuery(['clients', therapistID, params], () => getClientsByTherapist(therapistID, params), {
    staleTime: CLIENTS_CACHE_LIFETIME * 1000,
  });
}
