import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useToasts } from 'react-toast-notifications';

import { http } from '@expressable/utils';

import { SlackChannelInfo, CreateSlackChannel, CreateSlackChannelInvite } from '../types';

export const getSlackChannelInfo = async (contactId: string): Promise<SlackChannelInfo> => {
  const response = await http.get(`/contacts/${contactId}/slack-channel-info`);

  return response.data;
};

export function useSlackChannelInfo(contactId: string) {
  const { data, isLoading, error } = useQuery<SlackChannelInfo, Error>(['getSlackChannelInfo', contactId], () =>
    getSlackChannelInfo(contactId),
  );

  return {
    data,
    isLoading,
    error,
  };
}

const postCreateSlackChannel = async (createSlackInvitation: CreateSlackChannel) => {
  const { contactId } = createSlackInvitation;

  return http.post(`/contacts/${contactId}/create-slack-channel`).then(res => res.data);
};

export function useCreateSlackChannel() {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(postCreateSlackChannel, {
    onSuccess: async data => {
      await queryClient.invalidateQueries(['getSlackChannelInfo']);
    },
    onError: async (error: any) => {
      let errorMessage = 'Something Went Wrong';

      await queryClient.invalidateQueries(['getSlackChannelInfo']);

      if (error.response && error.response.data) {
        const responseMessage =
          typeof error.response.data === 'object' ? error.response.data.error.message : error.response.data;
        if (responseMessage) {
          errorMessage = responseMessage;
        }
      }

      addToast(errorMessage, { appearance: 'error', autoDismiss: true });
    },
  });
}

const postCreateSlackChannelInvite = async (createSlackInvitation: CreateSlackChannelInvite) => {
  const { contactId, channelId } = createSlackInvitation;

  return http.post(`/contacts/${contactId}/${channelId}/slack-invite`).then(res => res.data);
};

export function useCreateSlackChannelInvite() {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(postCreateSlackChannelInvite, {
    onSuccess: async (_, payload) => {
      await queryClient.invalidateQueries(['getSlackChannelInfo']);
    },
    onError: async (error: any, payload) => {
      let errorMessage = 'Something Went Wrong';

      await queryClient.invalidateQueries(['getSlackChannelInfo']);

      if (error.response && error.response.data) {
        const responseMessage =
          typeof error.response.data === 'object' ? error.response.data.error.message : error.response.data;
        if (responseMessage) {
          errorMessage = responseMessage;
        }
      }

      addToast(errorMessage, { appearance: 'error', autoDismiss: true });
    },
  });
}
