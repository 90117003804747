import { isUndefined } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

type PaginationOptions = {
  perPage: number;
  resetOnContentChange: boolean;
};

export type PaginationState<T> = {
  items: T[];
  page: number;
  perPage: number;
  pageStartIdx: number;
  pageEndIdx: number;
  totalPages: number;
  totalItems: number;
  next: () => void;
  prev: () => void;
  jumpTo: (page: number) => void;
};

const defaultOptions: PaginationOptions = {
  perPage: 5,
  resetOnContentChange: true,
};

export const usePagination = <T>(data: T[] = [], options?: Partial<PaginationOptions>): PaginationState<T> => {
  const perPage = options?.perPage || defaultOptions.perPage;
  const resetOnContentChange = isUndefined(options?.resetOnContentChange)
    ? defaultOptions.resetOnContentChange
    : options?.resetOnContentChange;

  const [page, setPage] = useState(0);
  useEffect(() => {
    if (resetOnContentChange) setPage(0);
  }, [data]);
  const totalPages = Math.ceil(data.length / perPage);
  const pageStartIdx = page * perPage;
  const pageEndIdx = Math.min(pageStartIdx + perPage, data.length);
  const items = useMemo(() => {
    if (Array.isArray(data)) return data?.slice(pageStartIdx, pageEndIdx);
    return [];
  }, [data, pageStartIdx, pageEndIdx]);
  return {
    items,
    page,
    perPage,
    pageStartIdx,
    pageEndIdx,
    totalPages,
    totalItems: data.length,
    next: () => setPage(p => Math.min(totalPages - 1, p + 1)),
    prev: () => setPage(p => Math.max(0, p - 1)),
    jumpTo: p => setPage(p),
  };
};
