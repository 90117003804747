import React from 'react';
import { Link } from '@expressable/ui-library';
import { Therapist } from 'types';
import { useClientsByTherapist } from 'hooks/use-clients';

interface NoAppointmentsProps {
  therapist?: Therapist;
}

function NoAppointments({ therapist }: NoAppointmentsProps) {
  if (!therapist) return null;

  const { isFetching, data: clients = [] } = useClientsByTherapist(therapist!.therapistEmail, { noAppointments: true });

  if (isFetching || clients.length === 0) return null;

  return (
    <div className="mb-8" data-testid="no-appointments">
      <div className="mb-4 text-lg leading-none font-semibold">No Appointments</div>
      <div className="flex flex-col gap-2">
        {clients.map((client) => (
          <div key={client.id}>
            <Link className="text-indigo-700 text-sm font-semibold" to={{ pathname: `/clients/${client.id}` }}>
              {`${client.clientFirstName} ${client.clientLastName}`}
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default NoAppointments;
