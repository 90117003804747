import { Link, UILinkProps } from '@expressable/ui-library';

export interface ToggleLinkProps extends Omit<UILinkProps, 'to' | 'onChange'> {
  onLabel: React.ReactFragment;
  offLabel: React.ReactFragment;
  value: boolean;
  onChange: (value: boolean) => void;
}

const ToggleLink = ({ onLabel, offLabel, value, onChange, ...linkProps }: ToggleLinkProps) => {
  const handleClick = () => {
    onChange?.(!value);
  };

  return (
    <Link {...linkProps} to="#" onClick={handleClick}>
      {value ? onLabel : offLabel}
    </Link>
  );
};

export default ToggleLink;
