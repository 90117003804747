export const ALLOWED_CONTENT_TYPES = [
  'image/jpeg',
  'image/png',
  'image/jpg',
  'audio/mpeg',
  'audio/aac',
  'audio/amr',
  'audio/x-m4a',
  'video/mp4',
  'video/mpeg',
  'video/quicktime',
  'video/x-msvideo',
  'video/x-ms-wmv',
  'video/mov',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
]

export const getFileNameWithoutExtension = (fileName: string) => fileName.slice(0, fileName.lastIndexOf('.'));

export const getFileExtension = (fileName: string) => fileName.slice(fileName.lastIndexOf('.') + 1);
