import React from 'react';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from '@expressable/ui-library';
import { faFilePen } from '@fortawesome/free-solid-svg-icons';
import { EventProps } from 'domain/admin/types';
import { removePrefixFromID } from 'utils/helpers';
import 'twin.macro';

const ClaimPaymentSuccessful = (event: EventProps) => {
  const firehoseEvent = event?.event;
  const isLastEvent = event?.isLastEvent;

  return (
    <div data-testid="claim-payment-successful" tw="relative flex mt-7 px-2">
      {!isLastEvent && <span tw="absolute -z-1 top-7 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>}
      <div tw="flex items-baseline">
        <div tw="flex items-center bg-gray-200 rounded-full h-6 w-6">
          <FontAwesomeIcon icon={faFilePen} tw="mx-1.5 h-3 text-indigo-700" />
        </div>
        <div tw="pl-4 text-sm">
          <div tw="lg:flex items-baseline font-semibold pb-1">
            <div tw="text-gray-500">
              {`A claim payment was successful for`}&nbsp;
              <Link
                tw="text-indigo-700"
                to={{ pathname: `/clients/${removePrefixFromID(firehoseEvent?.clientID)}` }}
              >
                {firehoseEvent?.clientFirstName} {firehoseEvent?.clientLastName}&nbsp;
              </Link>
              {`at ${dayjs(firehoseEvent.createdAt as string).format('h:mm A, MM/DD/YYYY')}`}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClaimPaymentSuccessful;
