import React, { useEffect } from 'react';
import { Button, FormGroup, Input, Modal, ModalBody, ModalContent, ModalFooter } from '@expressable/ui-library';
import 'twin.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserMinus } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import useEditClientStatus from 'hooks/use-clients-status';
import { useContentfulEntry } from 'hooks/use-ContentfulData';
import { mapEntryToSelectOption } from 'components/client-attendance-modals/log-cancelation-modal';
import FormGroupTitle from 'components/form-group-title';
import RHFSelect from 'components/RHFSelectBase';
import { ReasonsContentfulEntry } from 'types';
import { useCallDataOnTruthy } from 'hooks/use-call-data-on-truthy';

export interface ArchiveClientModalProps {
  isOpen: boolean;
  onClose: () => void;
  clientId: string;
}

const ARCHIVE_REASONS_ENTRY_ID = '2sIZBPjXe960XTf3eLaJB';

const ARCHIVE_REASONS_INITIAL_STATE = {
  status: 'Inactive',
  reason: '',
  reasonOther: '',
};

export function ArchiveClientModal(props: ArchiveClientModalProps) {
  const { isOpen, onClose, clientId } = props;
  const {
    formState: { errors },
    handleSubmit,
    watch,
    register,
    control,
    reset,
  } = useForm({
    defaultValues: ARCHIVE_REASONS_INITIAL_STATE,
    mode: 'onChange',
  });
  const { mutateAsync: editClientStatus, isLoading: loadingEditClientStatus } = useEditClientStatus();

  const [archiveReasonsEntry, , fetchContentfulData] =
    useContentfulEntry<ReasonsContentfulEntry>(ARCHIVE_REASONS_ENTRY_ID);
  const archiveReasons = archiveReasonsEntry?.fields?.dropdownContent.map(mapEntryToSelectOption);
  const selectedArchiveReasonValue = watch('reason');
  const archiveReasonOther = watch('reasonOther');

  const onSubmitArchiveClient = async () => {
    await editClientStatus({
      clientId,
      status: 'Inactive',
      reason: selectedArchiveReasonValue,
      reasonOther: selectedArchiveReasonValue === 'family request - other' ? archiveReasonOther : undefined,
    });
    onClose();
  };

  useCallDataOnTruthy(isOpen, fetchContentfulData);

  // reset modal state when open the modal
  const resetModalState = () => reset(ARCHIVE_REASONS_INITIAL_STATE);
  useEffect(resetModalState, [isOpen]);

  return (
    <Modal isOpen={isOpen}>
      <ModalContent>
        <form onSubmit={handleSubmit(onSubmitArchiveClient)}>
          <ModalBody>
            <div>
              <div tw="flex items-center justify-center w-12 h-12 mx-auto bg-indigo-100 rounded-full">
                <FontAwesomeIcon tw="text-2xl text-indigo-700" icon={faUserMinus} />
              </div>
              <div tw="mt-3 sm:mt-5">
                <h3 tw="text-lg text-center font-medium text-gray-900 leading-6" id="modal-headline">
                  Archive Client
                </h3>
                <div tw="mt-6">
                  <FormGroup data-testid="archive-reason" tw="mt-8">
                    <FormGroupTitle
                      title="Reason for Cancelation"
                      fontSize="small"
                      fontWeight="semi"
                      spacing="normal"
                    />
                    <RHFSelect
                      data-testid="reason"
                      name="reason"
                      control={control}
                      required
                      options={archiveReasons}
                      error={errors?.reason}
                    />
                  </FormGroup>
                  {Boolean(selectedArchiveReasonValue === 'family request - other') && (
                    <FormGroup>
                      <FormGroupTitle
                        title="What was the reason?"
                        fontSize="small"
                        fontWeight="semi"
                        spacing="normal"
                      />
                      <Input
                        tw="w-full"
                        data-testid="reason-other"
                        {...register('reasonOther', { required: true })}
                        error={errors?.reasonOther}
                      />
                    </FormGroup>
                  )}
                  <p tw="text-sm text-gray-900 leading-5 mb-10">
                    {/* If the client has any existing appointments, these will be cancelled immediately. */}
                  </p>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div tw="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
              <span tw="flex w-full rounded-md shadow-sm sm:col-start-2">
                <Button
                  type="submit"
                  data-testid="archive-submit"
                  variant="danger"
                  loading={loadingEditClientStatus}
                  disabled={loadingEditClientStatus}
                  tw="inline-flex justify-center w-full px-4 py-2 text-base font-medium leading-6 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                >
                  Archive
                </Button>
              </span>
              <span tw="flex w-full mt-3 rounded-md shadow-sm sm:mt-0 sm:col-start-1">
                <Button
                  type="button"
                  variant="secondary"
                  tw="inline-flex justify-center w-full px-4 py-2 text-base font-medium leading-6 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </span>
            </div>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
