import { useMutation } from 'react-query';
import { http } from '@expressable/utils';
import { useToasts } from 'react-toast-notifications';
import { presentMutationError } from '@expressable/ui-library';

export interface SendIntakeEmailPayload {
  clientId: string;
  evaluationDate?: Date;
  intakeLink: string;
  zoomLink: string;
  intake: string;
}

const sendIntakeEmail = async (sendIntakeEmailPayload: SendIntakeEmailPayload) => {
  const { clientId, ...data } = sendIntakeEmailPayload;
  return http.post(`/clients/${clientId}/intake-email`, data).then(res => res.data);
};

export default function useSendIntakeEmail() {
  const { addToast } = useToasts();

  return useMutation(sendIntakeEmail, {
    onSuccess: (_, payload) => {
      addToast('Intake email successfully sent', { appearance: 'success', autoDismiss: true });
    },
    onError: presentMutationError,
  });
}
