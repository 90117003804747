import React, { useEffect, useState } from 'react';
import {
  Card,
  Container,
  FormGroup,
  Label,
  Link,
  LoadingText,
  Select
} from '@expressable/ui-library';
import { faChevronLeft, faChevronRight, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Table from 'components/Table';
import { useClientsByTherapist } from 'hooks/use-clients';
import { usePagination } from 'hooks/use-pagination';
import usePermissions from 'hooks/use-permissions';
import useTherapists from 'hooks/use-therapists';
import { ClientInfo, SelectOption } from 'types';
import { getAmericanFormatDate } from 'utils/helpers';

const TherapistDefaultOption = {
  value: 'all',
  label: 'All Therapists',
};

const NoAppointments = () => {
  /** check permissions */
  const { isAdminOrClinicalManager } = usePermissions();

  if (!isAdminOrClinicalManager) {
    return (
      <Container data-testid="no-appointments-restricted-access-error" className="relative pl-0" size="large">
        <Card className="mb-4 px-8">
          <FormGroup className="w-full sm:w-1/2 md:w-1/3">
            <Label>
              <FontAwesomeIcon icon={faExclamationTriangle} className="text-red-500 mr-2" />
                Only users in Admin Groups can access this section.
            </Label>
          </FormGroup>
        </Card>
      </Container>
    );
  }

  /** state hooks */
  const [selectedClients, setSelectedClients] = useState<ClientInfo[]>([]);
  const [selectedTherapist, setSelectedTherapist] = useState<SelectOption>(TherapistDefaultOption);
  const [therapistsOptions, setTherapistsOptions] = useState<SelectOption[]>([TherapistDefaultOption]);

  const {
    data: therapists = [],
    isFetching: isFetchingTherapists,
    isError: isErrorTherapists,
  } = useTherapists('all', null, { refetchOnMount: false, staleTime: Infinity });
  const {
    data: clients = [],
    isFetching: isFetchingClients,
    isError: isErrorClients,
  } = useClientsByTherapist(TherapistDefaultOption.value, { noAppointments: true });

  useEffect(() => {
    setSelectedClients(clients);
  }, [clients]);

  useEffect(() => {
    if (therapists && therapists.length > 0) {
      setTherapistsOptions([
        TherapistDefaultOption,
        ...therapists.map((therapist) => ({
          value: therapist.therapistID,
          label: therapist.therapistName,
        })),
      ]);
    }
  }, [therapists]);

  useEffect(() => {
    if (selectedTherapist.value === TherapistDefaultOption.value) {
      setSelectedClients(clients);
      return;
    }

    const therapist = therapists.find((therapist) => therapist.therapistID === selectedTherapist.value);
    const therapistClients = clients.filter((client) => client.lastTherapistEmail === therapist?.therapistEmail);

    setSelectedClients(therapistClients);
  }, [selectedTherapist]);

  const paginatedEntries = usePagination(selectedClients, { perPage: 20 });

  return (
    <div className="md:pr-5" data-testid="no-appointments-container">
      <div className="flex justify-between items-center flex-wrap">
        <h2 className="text-2xl font-semibold mb-3">
          {selectedTherapist.value === TherapistDefaultOption.value ? 'No Appointments' : selectedTherapist.label}
        </h2>
      </div>
      <Card className="mt-2">
        <div className="mb-4 sm:mb-0">
          <div className="flex flex-col sm:flex-row mb-4">
            <>
              <span className="text-sm font-semibold mt-6 ml-2 hidden sm:block">Therapist</span>
              <div className="flex items-center sm:items-stretch justify-items-stretch mr-2 ml-2 mt-4 font-normal">
                <span className="text-sm font-semibold mr-2 block sm:hidden">Therapist</span>
                <Select
                  className="w-full sm:w-96"
                  id="no-appointments-select"
                  required
                  value={selectedTherapist}
                  options={therapistsOptions}
                  onChange={(option: SelectOption) => {
                    setSelectedTherapist(option);
                  }}
                  disabled={isFetchingTherapists || isFetchingClients}
                />
              </div>
              <span
                onClick={() => setSelectedTherapist(TherapistDefaultOption)}
                className={`text-sm ml-3 mt-6 text-indigo-700 font-normal hidden sm:block ${
                  selectedTherapist.value === TherapistDefaultOption.value ? 'opacity-50' : 'opacity-100 cursor-pointer'
                }`}
              >
                View All Therapists
              </span>
            </>
          </div>
        </div>
        <>
          <Table data-testid="no-appointments-table">
            <Table.Header>
              <Table.Row>
                {selectedTherapist.value === TherapistDefaultOption.value && <Table.Cell className="p-1 sm:p-2 sm:py-3">Therapist</Table.Cell>}
                <Table.Cell className="p-1 sm:p-2 sm:py-3">Client</Table.Cell>
                <Table.Cell className="p-1 sm:p-2 sm:py-3">Last Appointment Date</Table.Cell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {isFetchingTherapists || isFetchingClients ? (
                <Table.Row>
                  <Table.Cell colSpan={3} className="font-semibold text-center py-5">
                    <LoadingText />
                  </Table.Cell>
                </Table.Row>
              ) : (
                paginatedEntries.items.length === 0 ? (
                  <Table.Row>
                    <Table.Cell colSpan={3} className="font-semibold text-center py-5">
                      {isErrorTherapists || isErrorClients
                        ? 'Something happened. Please try again or contact support.'
                        : `No clients found for "${selectedTherapist.label}".`
                      }
                    </Table.Cell>
                  </Table.Row>
                ) : (
                  paginatedEntries.items.map((client) => {
                    return (<Table.Row key={client.clientID}>
                        {selectedTherapist.value === TherapistDefaultOption.value && (
                            <Table.Cell className="text-indigo-700 font-semibold text-sm md:text-base p-1 sm:p-2 sm:py-3">
                            <Link
                              target="_blank"
                              to={{ pathname: `/therapists/${client.lastTherapistEmail}` }}
                            >
                              {client.lastTherapistFirstName} {client.lastTherapistLastName}
                            </Link>
                          </Table.Cell>
                        )}
                        <Table.Cell className="text-indigo-700 font-semibold text-sm md:text-base p-1 sm:p-2 sm:py-3">
                          <Link
                            target="_blank"
                            to={{ pathname: `/clients/${client.clientID}` }}
                          >
                            {client.clientFirstName} {client.clientLastName}
                          </Link>
                        </Table.Cell>
                        <Table.Cell className="text-sm md:text-base">
                          {client.lastAttendedVisit && getAmericanFormatDate(client.lastAttendedVisit)}
                        </Table.Cell>
                      </Table.Row>
                    );
                  }))
              )}
            </Table.Body>
          </Table>
          <div className="font-medium flex mt-5 justify-center sm:justify-end">
            <span className="mr-2">{`Showing ${
              paginatedEntries.totalItems > 0 ? paginatedEntries.pageStartIdx + 1 : paginatedEntries.pageStartIdx
            } to ${ paginatedEntries.pageEndIdx} of ${paginatedEntries.totalItems} results`}</span>
            {paginatedEntries.page !== 0 && (
              <span data-testid="awaiting-intake-previous-page" onClick={paginatedEntries.prev}>
                <FontAwesomeIcon
                  className="text-indigo-700 ml-2 mr-2 cursor-pointer flex mt-2 text-xs"
                  icon={faChevronLeft}
                />
              </span>
            )}
            {paginatedEntries.totalPages > 0 &&
            paginatedEntries.page !== paginatedEntries.totalPages - 1 &&
            (
              <span data-testid="awaiting-intake-next-page" onClick={paginatedEntries.next}>
                <FontAwesomeIcon
                  className="text-indigo-700 ml-2 mr-2 cursor-pointer flex mt-2 text-xs"
                  icon={faChevronRight}
                />
              </span>
            )}
          </div>
        </>
      </Card>
    </div>
  );
};

export default NoAppointments;
