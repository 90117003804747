import { CLINICAL_PHONEMES, ClinicalPhoneme } from 'domain/clinical-pathways/contants';
import { isNumber } from 'lodash';
import { SelectOption } from 'types';

type UseClinicalPhonemes = (selectedIndex?: number) => {
  phonemes: ClinicalPhoneme[];
  phonemeOptions: SelectOption<number>[];
  selectedPhoneme?: ClinicalPhoneme;
};

export const phonemeOptions: SelectOption<number>[] = CLINICAL_PHONEMES.map((phoneme, index) => ({
  label: phoneme.label,
  value: index,
}));

export const useClinicalPhonemes: UseClinicalPhonemes = selectedIndex => {
  return {
    phonemeOptions,
    selectedPhoneme: isNumber(selectedIndex) ? CLINICAL_PHONEMES[selectedIndex] : undefined,
    phonemes: CLINICAL_PHONEMES,
  };
};
