import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { dynamicHttp } from '@expressable/utils';
import { useToasts } from 'react-toast-notifications';
import { presentMutationError } from '@expressable/ui-library';
import { Diagnosis } from 'types';

const httpHealthRecordProxy = dynamicHttp('health-record-proxy');

export interface SetDiagnosesPayload {
  clientId: string;
  diagnoses: Diagnosis[];
  shouldLogEvent: boolean;
}

const setDiagnoses = async ({ clientId, ...rest }: SetDiagnosesPayload) => {
  return httpHealthRecordProxy.post(`/care-plans/diagnoses/${clientId}`, rest).then(res => res.data);
};

export function useSetDiagnoses() {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(setDiagnoses, {
    onSuccess: (_, payload) => {
      addToast('Diagnoses Successfully Updated', { appearance: 'success', autoDismiss: true });
      queryClient.invalidateQueries(['care-plan-diagnosis', payload.clientId]);
    },
    onError: presentMutationError,
  });
}

export interface RemoveDiagnosisPayload {
  clientId: string;
  diagnosisCode: string;
  shouldLogEvent: boolean;
}

const removeDiagnosis = async ({ clientId, diagnosisCode, shouldLogEvent }: RemoveDiagnosisPayload) => {
  return httpHealthRecordProxy
    .delete(`/care-plans/diagnoses/${clientId}?diagnosisCode=${encodeURIComponent(diagnosisCode)}&shouldLogEvent=${shouldLogEvent}`)
    .then(res => res.data);
};

export function useRemoveDiagnosis() {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(removeDiagnosis, {
    onSuccess: (_, payload) => {
      addToast('Diagnosis Successfully Removed', { appearance: 'success', autoDismiss: true });
      queryClient.invalidateQueries(['care-plan-diagnosis', payload.clientId]);
    },
    onError: presentMutationError,
  });
}

const getDiagnoses = async (clientId: string) => {
  const { data } = await httpHealthRecordProxy.get(`/care-plans/diagnoses/${clientId}`);
  return data;
};

export function useGetDiagnoses(
  { clientId }: { clientId: string },
  options?: UseQueryOptions<Diagnosis[], unknown, Diagnosis[]>,
) {
  return useQuery<Diagnosis[]>(['care-plan-diagnosis', clientId], () => getDiagnoses(clientId), options);
}

export function compareByCode(a: Diagnosis, b: Diagnosis): number {
  return a.code.localeCompare(b.code);
}
