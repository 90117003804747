import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { dynamicHttp } from '@expressable/utils';
import { presentMutationError, useToast } from '@expressable/ui-library';

const httpHealthRecordProxy = dynamicHttp('health-record-proxy');

/*
  In the future, we may need to modify these - either change the values as they are, or add, or remove,
  as we learn more about clinical documentation requirements. It is not anticipated that they will change
  soon or often, but it is a real possibility. Solutions that maintain flexible control over these values
  being logged would be ideal.
*/
export const defaultSkilledInterventionsValues = [
  'Caregiver/Patient Training and Education',
  'Ongoing assessment and analysis of interventions',
  'Ongoing assessment and modification of home program',
  'Skilled cueing during Intervention',
];

const getSkilledInterventions = async (clientId: string) => {
  const { data } = await httpHealthRecordProxy.get(`/care-plans/skilled-interventions/${clientId}`);

  const res = data.filter((item: string) => !defaultSkilledInterventionsValues.includes(item));

  return res;
};

export function useGetSkilledInterventions(
  { clientId }: { clientId: string },
  options?: UseQueryOptions<string[], unknown, string[]>,
) {
  return useQuery<string[]>(['skilled-interventions', clientId], () => getSkilledInterventions(clientId), options);
}

export interface SetSkilledInterventionsPayload {
  clientId: string;
  skilledInterventions: string[];
  shouldLogEvent: boolean;
}

const setSkilledInterventions = async (payload: SetSkilledInterventionsPayload) => {
  const { clientId, ...rest } = payload;
  return httpHealthRecordProxy.post(`/care-plans/skilled-interventions/${clientId}`, rest).then(res => res.data);
};

export function useSetSkilledInterventions() {
  const queryClient = useQueryClient();
  const { successToast } = useToast();

  return useMutation(setSkilledInterventions, {
    onSuccess: (_, payload) => {
      successToast('Skilled Interventions successfully updated.');
      queryClient.invalidateQueries(['skilled-interventions', payload.clientId]);
    },
    onError: presentMutationError,
  });
}
