import { Link } from '@expressable/ui-library';
import { useState } from 'react';

const NUMBER_OF_SERVICE_TYPES_TO_SHOW = 5;
export const ServicesTypes: React.FC<{ services: string[] }> = ({ services = [] }) => {
  const [showAllServiceTypes, setShowAllServiceTypes] = useState(false);

  return (
    <>
      <ul>
        {services
          .slice(0, showAllServiceTypes ? services.length : NUMBER_OF_SERVICE_TYPES_TO_SHOW)
          .map((serviceType: string, index: number) => (
            <li className="list-disc ml-8" key={index}>
              {serviceType}
            </li>
          ))}
      </ul>
      {services.length > NUMBER_OF_SERVICE_TYPES_TO_SHOW && (
        <Link to="#" onClick={() => setShowAllServiceTypes(!showAllServiceTypes)} className="text-indigo-700 mt-2">
          {showAllServiceTypes ? 'View Less' : 'View More'}
        </Link>
      )}
    </>
  );
};
