import { useQuery } from 'react-query';
import { http } from '@expressable/utils';
import { CompleteTherapistInformation } from '../types';

const getTherapist = async (id: string): Promise<CompleteTherapistInformation> => {
  const { data } = await http.get(`/therapists/${id}`);
  return data;
};

export default function useTherapist(id: string) {
  return useQuery<CompleteTherapistInformation, Error>(['therapist', id], () => getTherapist(id), {
    enabled: !!id,
  });
}
