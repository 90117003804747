import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/pro-solid-svg-icons';
import { FirehoseEventJotformSubmission } from 'domain/admin/types';
import dayjs from 'dayjs';
import 'twin.macro';

const JotformSubmissionReceived = (event: FirehoseEventJotformSubmission) => {
  const firehoseEvent = event?.event;
  const isLastEvent = event?.isLastEvent;

  return (
    <div data-testid="firehose-jotform-submission-received" tw="relative flex mt-7 px-2">
      {!isLastEvent && <span tw="absolute -z-1 top-7 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>}
      <div tw="flex items-baseline">
        <div tw="flex items-center bg-gray-200 rounded-full h-6 w-6">
          <FontAwesomeIcon icon={faFile} tw="mx-2 h-3 text-indigo-700" />
        </div>
        <div tw="pl-4 text-sm">
          <div tw="xl:flex font-semibold pb-1">
            <span tw="text-gray-500">{`Jotform received a submission for the form`}</span>&nbsp;
            <span tw="text-gray-500">{firehoseEvent.jotformName}</span>&nbsp;
            <span tw="text-gray-500">{`at ${dayjs(firehoseEvent?.createdAt as string).format('h:mm A')}, ${dayjs(
              firehoseEvent?.createdAt as string,
            ).format('MM/DD/YYYY')} `}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JotformSubmissionReceived;
