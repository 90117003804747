import { LANGUAGE_OPTIONS } from 'hooks/common/useTherapistFilters/options';
import useTherapists from 'hooks/use-therapists';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { SelectOption, Therapist } from 'types';
import { TherapistSortBy } from 'domain/therapist/constants';
import { isNull } from 'lodash';

export interface TherapistSearchFilters {
  state: SelectOption | null;
  language: SelectOption;
  insurancePlan: SelectOption[];
  specialty: SelectOption[];
  acceptingClients: boolean;
  isPRN: boolean;
  timezone?: SelectOption | null;
}

const requiredFilters: Array<keyof TherapistSearchFilters> = ['state', 'language']; //@TODO(juan): validate this list

export const DEFAULT_SEARCH_FILTERS: TherapistSearchFilters = {
  language: LANGUAGE_OPTIONS.ENGLISH,
  specialty: [],
  acceptingClients: true,
  insurancePlan: [],
  state: null,
  isPRN: true,
  timezone: null,
};

type UseFilteredTherapists = () => {
  allTherapists?: Therapist[];
  filteredTherapists?: Therapist[] | null;
  filters: TherapistSearchFilters;
  timezone?: SelectOption | null;
  isLoading: boolean;
  onChangeFilters: Dispatch<SetStateAction<TherapistSearchFilters>>;
};

type ClientDefaultFilters = Partial<TherapistSearchFilters> & { insurancePlan?: SelectOption[] | null };

export const useFilteredTherapists = (clientDefaultFilters?: ClientDefaultFilters): ReturnType<UseFilteredTherapists> => {
  const [filters, setFilters] = useState<TherapistSearchFilters>({...DEFAULT_SEARCH_FILTERS, ...clientDefaultFilters});
  const { data: therapists, isLoading } = useTherapists('active', TherapistSortBy.Caseload);

  const filteredTherapists = useMemo(() => {
    if (therapists) {
      const filtered = filterTherapists(therapists, filters);

      return filtered;
    }
    return null;
  }, [therapists, filters]);

  useEffect(() => {
    if(!isNull(clientDefaultFilters?.insurancePlan)) 
      setFilters({...DEFAULT_SEARCH_FILTERS, ...clientDefaultFilters});
  }, [clientDefaultFilters?.insurancePlan]);

  return {
    filters,
    isLoading,
    allTherapists: therapists,
    filteredTherapists,
    onChangeFilters: setFilters,
  };
};

export default function filterTherapists(therapists: Therapist[], filters: TherapistSearchFilters) {
  if (requiredFilters.some(requiredFilter => !filters[requiredFilter])) {
    return null;
  }
  const filterByState = (therapist: Therapist) => {
    if (!filters.state) {
      return true;
    }
    return therapist.statesLicensed?.includes(filters.state.value) ?? false;
  };

  const filterByLanguage = (therapist: Therapist) => {
    /*
    Some therapists don't have a language list. If filtering by ENGLISH, include those
    without a language list. If filtering by a more specific language don't include them in the results
    */
    if (!filters.language || (!therapist.languages && filters.language === LANGUAGE_OPTIONS.ENGLISH)) {
      return true;
    }
    return therapist.languages?.some(({ code }) => code === filters.language.value);
  };

  const filterByInsurancePlan = (therapist: Therapist) => {
    if (!filters.insurancePlan || filters.insurancePlan.length === 0) {
      return true;
    }

    // true if all required specialties are present in the therapist
    return filters.insurancePlan.every(({ value }) => therapist.statesLicensed?.some(s => s === value));
  };

  const filterBySpecialty = (therapist: Therapist) => {
    if (!filters.specialty || filters.specialty.length === 0) {
      return true;
    }

    // true if all required specialties are present in the therapist
    return filters.specialty.every(({ value }) => therapist.specialties?.some(s => s === value));
  };

  const filterByAcceptingNewPatients = (therapist: Therapist) => {
    if (!filters.acceptingClients) {
      return true;
    }
    return therapist.acceptingNewPatients;
  };

  const filterByIsPRN = (therapist: Therapist) => {
    if (!filters.isPRN) {
      return !therapist.isPRN;
    }
    return true;
  };

  return therapists.filter(
    t =>
      filterByState(t) &&
      filterByLanguage(t) &&
      filterByInsurancePlan(t) &&
      filterBySpecialty(t) &&
      filterByAcceptingNewPatients(t) &&
      filterByIsPRN(t),
  );
}
