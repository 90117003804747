import { createContext, useState, useContext, ReactNode, Dispatch, SetStateAction } from 'react';

export type TherapistActivityStatus = 'active' | 'inactive' | 'all';

type TherapistStatusContextType = [TherapistActivityStatus, Dispatch<SetStateAction<TherapistActivityStatus>>];

export const TherapistsStatusContext = createContext<TherapistStatusContextType>({} as TherapistStatusContextType);

export const useTherapistsStatusContext = () => useContext(TherapistsStatusContext);

const TherapistsStatusProvider = ({ children }: { children: ReactNode }) => {
  const [status, setStatus] = useState<TherapistActivityStatus>('active');
  return <TherapistsStatusContext.Provider value={[status, setStatus]}>{children}</TherapistsStatusContext.Provider>;
};

type WithTherapistStatusContext = <P = Record<string, unknown>>(Component: React.FC<P>) => React.FC<P>;

export const withTherapistStatusContext: WithTherapistStatusContext = Component => {
  // eslint-disable-next-line react/display-name
  return props => {
    return (
      <TherapistsStatusProvider>
        <Component {...props} />
      </TherapistsStatusProvider>
    );
  };
};

export default TherapistsStatusProvider;
