import { useMutation,  } from 'react-query';
import { dynamicHttp } from '@expressable/utils';
import { useToasts } from 'react-toast-notifications';


interface GenerateCustomStructureActivityPayload {
  clientId: string,
  title: string,
  fileUrl?: string,
  fileContentType?: string,
  fileInstructions?: string,
  extraInstructions?: string,
  therapyStrategyId?: string,
  therapyStrategyName?: string,
}

interface GenerateCustomStructureActivityResponse {
  content: string;
}

const generateCustomStructuredActivity = async (data: GenerateCustomStructureActivityPayload) => {
  const http = dynamicHttp('health-record-proxy');

  const clientId = data.clientId;
  const payload = { title: data.title, fileUrl: data?.fileUrl, fileContentType: data?.fileContentType, fileInstructions: data?.fileInstructions, extraInstructions: data?.extraInstructions, therapyStrategyId: data?.therapyStrategyId, therapyStrategyName: data?.therapyStrategyName }

  return http.post<GenerateCustomStructureActivityResponse>(`/client-assignment/${clientId}/generate-structured-activity`, payload, { timeout: 60000 }).then(res => res.data);
};

export function useGenerateCustomStructuredActivity() {
  const { addToast } = useToasts();

  return useMutation(generateCustomStructuredActivity, {
    onError: async (error: any) => {
      let message = 'Something went wrong. Please try again.';
      if (error?.code === 'ERR_NETWORK' || error?.code === 'ERR_BAD_RESPONSE') {
        message = 'Looks like we are a bit overloaded. Please try again shortly!';
      } else {
        message = error?.response?.data || 'Please try again shortly.';
      }
      
      addToast(message, { appearance: 'error', autoDismiss: true });
    },
  });
}
