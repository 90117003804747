import { Container, Loader } from '@expressable/ui-library';
import React from 'react';
import Search from 'components/search';
import List from 'components/list';
import { Link } from 'react-router-dom';
import 'twin.macro';
import useClients from 'hooks/use-clients';

interface ClientsViewProps {
  searchTerm: string;
  setSearchTerm(searchTerm: string): void;
  filteredItems: [];
}

interface Client {
  id: string;
  clientFirstName: string;
  clientLastName: string;
}

const ClientsView = ({ searchTerm, setSearchTerm, filteredItems }: ClientsViewProps) => (
  <>
    <div tw="flex">
      <div tw="text-2xl font-semibold">Associated Clients</div>

      <div tw="max-w-sm relative">
        <input
          tw="p-1 ml-6 outline-none border focus:border-indigo-300"
          css={{ width: '100%', paddingLeft: 14 }}
          onChange={event => setSearchTerm(event.target.value)}
          type="text"
          placeholder={`Search ${filteredItems.length} Records`}
          aria-label="Search Clients"
          value={searchTerm}
          autoFocus
        />
        <div
          css={{
            position: 'absolute',
            right: 14,
            top: '50%',
            transform: 'translateY(-50%)',
            opacity: 0.6,
            fontSize: '0.8rem',
          }}
        >
          {filteredItems.length}
        </div>
      </div>
    </div>

    <div
      css={{
        marginTop: 20,
      }}
    >
      <List
        data={filteredItems}
        render={(element: Client, index) => (
          <li key={index} data-testid="therapist-client">
            <Link tw="block p-4 hover:bg-indigo-100" to={{ pathname: `/clients/${element.id}` }}>
              {`${element.clientFirstName} ${element.clientLastName}`}
            </Link>
          </li>
        )}
      />
    </div>
  </>
);

interface ClientsProps {
  therapistEmail: string;
}

const Clients = (props: ClientsProps) => {
  const { data = [], isLoading } = useClients(props.therapistEmail);

  if (isLoading) {
    return (
      <div tw="h-screen flex justify-center items-center" data-testid="therapist-clients">
        <Loader type="ring" />
      </div>
    );
  }

  if (data) {
    data?.sort((a: Client, b: Client) =>
      `${a.clientFirstName} ${a.clientLastName}`.localeCompare(`${b.clientFirstName} ${b.clientLastName}`),
    );
  }

  return (
    <div data-testid="therapist-clients">
      <Container size="large" tw="relative px-0">
        {data && data.length > 0 ? (
          <Search
            render={ClientsView}
            data={data.map((client: { [key: string]: string }) => ({
              ...client,
              clientFirstName: client.clientFirstName?.trim(),
              clientLastName: client.clientLastName?.trim(),
              clientFullName: `${client.clientFirstName?.trim()} ${client.clientLastName?.trim()}`,
            }))}
            options={{
              keys: ['clientFirstName', 'clientLastName', 'clientFullName'],
              threshold: 0.1,
            }}
          />
        ) : (
          'No clients'
        )}
      </Container>
    </div>
  );
};

export default Clients;
