import { FormYesNoDetails } from 'domain/shared/types';
import { selectOptionSchema } from 'pages/new-client-create/ClientInformation';
import { SelectOption } from 'types';
import * as yup from 'yup';

export interface DischargeNoteFormData {
  dischargeOn: string;
  dischargeReason?: SelectOption | null;
  mayResume?: string | null;
  anticipatedReturn?: Date | null;
  againstRecommendation?: FormYesNoDetails | null;
  treatmentSummary: string;
  dischargeMessage: string;
  goalsStatuses?: Record<string, string>;
  additionalGoalsStatuses?: Record<string, string>;
}

export const yesNoDetailSchema = yup.object().shape({
  relevant: yup.string().default('').nullable(),
  details: yup.string().default('').nullable(),
});

// @ts-ignore
export const dischargeFormContentSchema: yup.ObjectSchema<DischargeNoteFormData> = yup.object({
  dischargeOn: yup.string(),
  dischargeReason: selectOptionSchema.nullable().notRequired(),
  mayResume: yup.string().nullable().notRequired(),
  anticipatedReturn: yup.date().nullable().notRequired(),
  againstRecommendation: yesNoDetailSchema.nullable().notRequired(),
  treatmentSummary: yup.string().nullable().notRequired(),
  dischargeMessage: yup.string().nullable().notRequired(),
  goalsStatuses: yup.object().nullable().notRequired().default({}),
});
