/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react';
import { components } from 'react-select';
import 'twin.macro';
type Props = {
  children: React.ReactElement[];
};
export const ValueContainer = ({ children, ...props }: Props) => {
  // eslint-disable-next-line prefer-const
  let [values, input] = children;
  let valueComponent;

  if (Array.isArray(values)) {
    const plural = values.length === 1 ? '' : 's';
    valueComponent =
      values.length === input?.props.options.length
        ? 'All selected'
        : (`${values.length} item${plural} selected` as unknown as string);
  }

  return (
    <components.ValueContainer tw="font-normal bg-transparent" {...(props as unknown as any)}>
      {(Boolean(valueComponent) && <span tw="text-sm">{valueComponent}</span>) || (
        <span tw="text-gray-400 text-sm">Select Event Types</span>
      )}
      {input}
    </components.ValueContainer>
  );
};
