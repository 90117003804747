import { Button, Select } from '@expressable/ui-library';
import { RadioGroup } from 'components/Forms/RadioGroup';
import { PendingScheduleDTO } from 'domain/pending-schedule/types';
import { useCreatePendingSchedule } from 'hooks/use-pending-schedule';
import { AppointmentType, ScheduleMethod, scheduleMethodOptions } from 'domain/appointment/constants';
import { TherapistSearchFilters } from 'hooks/common/useTherapistFilters/use-filtered-therapists';
import { NoTherapistsMessage } from '../../components/no-therapist-message';
import { useCopyToClipboard } from 'hooks/common/useCopyToClipboard';
import SubStep from 'pages/new-client-create/sub-step';
import { FC, useEffect, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { SelectOption, Therapist } from 'types';
import { isAdult } from 'utils/is-adult';

const IntakeMethodOptions = {
  Child: {
    label: 'Child',
    value: 'child',
  },
  Adult: {
    label: 'Adult',
    value: 'adult',
  },
};

export const ScheduleMethodStep: FC<{
  appointmentType?: AppointmentType;
  scheduleType: string;
  phone: string | undefined;
  clientID: string;
  clientDOB?: string;
  filters: TherapistSearchFilters;
  filteredTherapists?: Therapist[] | null;
}> = ({ appointmentType, scheduleType, clientID, clientDOB, filters, filteredTherapists }) => {
  const [schedulelink, setScheduleLink] = useState<string | null>(null);
  const [linkGenerated, setLinkGenerated] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const createPendingScheduleMutation = useCreatePendingSchedule();
  const { mutateAsync: createPendingSchedule, isLoading: isCreatePendingScheduleLoading } =
    createPendingScheduleMutation;
  const { copied, copy } = useCopyToClipboard();
  const [selectedIntakeMethod, setSelectedIntakeMethod] = useState<SelectOption | null>(
    clientDOB ? (isAdult({ dateOfBirth: clientDOB }) ? IntakeMethodOptions.Adult : IntakeMethodOptions.Child) : null,
  );
  const { evalScheduleLink } = useFlags();
  const title = `${evalScheduleLink ? '3' : '4'}. Select schedule method`;
  const notAcceptingClients = filteredTherapists?.every((therapist) => !therapist.acceptingNewPatients);

  const handleSubmit = async () => {
    setScheduleLink(null);
    setIsButtonDisabled(true);

    const dto: PendingScheduleDTO = {
      clientID,
      intakeType: selectedIntakeMethod!.value,
      specialties: filters.specialty.map(s => s.value),
      insurancePlan: filters.insurancePlan.map(s => s.value),
      state: filters.state?.value,
    };

    const link = await createPendingSchedule(dto);
    if (link !== '') {
      setScheduleLink(link);
      setLinkGenerated(true);
    }
  };

  useEffect(() => {
    if (isButtonDisabled) {
      setIsButtonDisabled(false);
    }
    setScheduleLink(null);
    setLinkGenerated(false);
  }, [filters]);

  return appointmentType === AppointmentType.Evaluation ? (
    <SubStep title={title} expanded>
      <RadioGroup
        containerClass="space-y-2"
        inputClass="flex flex-col space-y-3"
        name="scheduleType"
        optional={false}
        options={scheduleMethodOptions}
      />
      {scheduleType === ScheduleMethod.Link &&
        appointmentType === AppointmentType.Evaluation &&
        (((Array.isArray(filteredTherapists) && !filteredTherapists.length) || (notAcceptingClients)) ? (
          <NoTherapistsMessage filteredTherapists={filteredTherapists} notAcceptingClients={notAcceptingClients} />
        ) : (
          <div>
            <div className="border-t border-gray-300 my-6"></div>
            <section className="bg-white p-6 rounded-md shadow mt-10">
              <h2 className="font-semibold text-lg mb-4">Schedule Summary</h2>

              <div>
                <p className="text-sm text-gray-600 mb-4 font-bold">Initial Evaluation (Pending)</p>
              </div>

              <div>
                <p className="text-sm text-gray-600 mb-4 font-bold">Select Intake Method</p>
                <Select
                  className="w-full md:w-1/3 mb-8 lg:mb-10"
                  options={Object.values(IntakeMethodOptions)}
                  value={selectedIntakeMethod}
                  onChange={(option: SelectOption) => setSelectedIntakeMethod(option)}
                  disabled={isButtonDisabled || isCreatePendingScheduleLoading}
                />
              </div>

              <Button
                data-testid="schedule-summary-send-link"
                variant="primary"
                type="button"
                loading={isCreatePendingScheduleLoading}
                onClick={handleSubmit}
                disabled={isButtonDisabled || isCreatePendingScheduleLoading || !selectedIntakeMethod}
                tw="w-full flex justify-center items-center"
              >
                Create Schedule Link
              </Button>
              {linkGenerated && schedulelink && (
                <div>
                  <p className="mt-4 bg-gray-100 p-4 rounded-lg text-sm text-gray-800 break-words">{schedulelink}</p>
                  <div className="mt-2 inline-block">
                    {copied ? (
                      <span
                        className="bg-green-500 text-white px-3 py-2 rounded h-10 flex items-center justify-center"
                        style={{ width: 'auto', minWidth: '80px' }}
                      >
                        Copied!
                      </span>
                    ) : (
                      <Button
                        variant="secondary"
                        type="button"
                        onClick={() => {
                          if (schedulelink) {
                            copy(schedulelink);
                          }
                        }}
                        tw="bg-blue-500 text-white px-3 py-2 rounded h-10 flex-shrink-0"
                      >
                        Copy
                      </Button>
                    )}
                  </div>
                </div>
              )}
            </section>
          </div>
        ))}
    </SubStep>
  ) : null;
};
