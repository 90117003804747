import React from 'react';
import { Heading, SubHeading } from './client-sidebar';
import { Diagnosis, ICompleteClientInformation } from 'types';
import { formatAge, getAmericanFormatDate } from 'utils/helpers';
import { useGetDiagnoses } from 'hooks/use-care-plan-diagnoses';
import tw from 'twin.macro';

export interface ClientManageAssignmentsSidebarProps {
  clientData: ICompleteClientInformation;
  clientId: string;
}

export default function ClientManageAssignmentsSidebar({ clientData, clientId }: ClientManageAssignmentsSidebarProps) {
  const age = formatAge(clientData?.dob);
  const americanFormatDob = getAmericanFormatDate(clientData?.dob);
  const { data: diagnoses } = useGetDiagnoses({ clientId });

  return (
    <div tw="md:w-1/4 px-5 mt-[90px]" data-testid="client-manage-assignments-sidebar">
      <div tw="text-sm">
        <Heading>Portal Content Tags</Heading>

        {clientData.dob ? (
          <>
            <SubHeading>Client DOB</SubHeading>
            <div tw="mt-2">{`${americanFormatDob} (${age} old)`}</div>
          </>
        ) : (
          <>
            <SubHeading>Client DOB</SubHeading>
            <div tw="text-sm text-gray-400 mt-2">No DOB have been added</div>
          </>
        )}

        {diagnoses?.length ? (
          <>
            <SubHeading>Diagnoses</SubHeading>
            {diagnoses.map((diagnoses: Diagnosis, index) => (
              <div key={index} css={[index === 0 ? tw`mt-2` : '']}>
                {diagnoses.description}
              </div>
            ))}
          </>
        ) : (
          <>
            <SubHeading>Diagnoses</SubHeading>
            <div tw="text-sm text-gray-400 mt-2">No diagnoses have been added</div>
          </>
        )}
      </div>
    </div>
  );
}
