import { Container, TitleBar, Spacer } from '@expressable/ui-library';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import 'twin.macro';
import { Helmet } from 'react-helmet';
import ReviewSummary from './ReviewSummary';
import { TIMEZONE_OPTIONS } from 'hooks/common/useDisplayTimezone/options';
import { DisplayTimezoneProvider } from 'hooks/common/useDisplayTimezone';
import { ClientInformationForm } from 'pages/new-client-create/ClientInformation';
import { Step, Stepper } from 'components/Stepper';
import cx from 'classnames';
import { StepId, useTherapistMatcher } from 'components/therapistMatcher/data/useTherapistMatcher';
import { BillingInformationForm } from 'pages/new-client-create/BillingInformation';
import ScheduleSessions from 'pages/new-client-create/SchedulingSessions/schedule-sessions';
import { useCallback, useEffect, useState } from 'react';
import { SelectOption } from 'types';
import { billingPriceTierOptions } from './BillingInformation/options';
import { TherapistErrorCode } from 'domain/therapist/constants';

const stepTitles = ['Schedule Sessions', 'Confirm Client Information', 'Confirm Billing Information', 'Review Summary'];

const ClientCreate = ({}: RouteComponentProps) => {
  const {
    currentStep,
    onNavigateToStep: originalOnNavigateToStep,
    stepsState,
    onSubmitStep,
    onInvalidateStep,
    stepsData,
  } = useTherapistMatcher();

  const scrollToTop = () => window.scrollTo(0, 0);

  const onNavigateToStep = (toStepIndex: number) => {
    originalOnNavigateToStep(toStepIndex);
    scrollToTop();
  };

  const [schedulingState, setSchedulingState] = useState<SelectOption | null>(null);
  const [resetScheduleSession, setResetScheduleSession] = useState(false);
  const isIntakePossible = !stepsData[StepId.scheduleSessions] || !stepsData[StepId.scheduleSessions]?.skipped;

  const { search } = useLocation();
  const params = new URLSearchParams(search);

  useEffect(() => {
    if (resetScheduleSession) {
      setResetScheduleSession(false);
    }
  }, [resetScheduleSession]);

  return (
    <DisplayTimezoneProvider defaultTimezone={TIMEZONE_OPTIONS.CENTRAL}>
      <div data-testid="client" tw="mb-7">
        <Helmet title="Create New Client" />
        <TitleBar title="Create a New Client" />
        <Spacer size="md" />
        <Container size="large">
          <Stepper currentStep={currentStep} allowNavigation onNavigate={onNavigateToStep} className="mb-10">
            {stepTitles.map((title, index) => (
              <Step index={index} key={index} isCompleted={stepsState[index]}>
                {title}
              </Step>
            ))}
          </Stepper>

          <StepperPanel hidden={currentStep !== StepId.scheduleSessions}>
            <ScheduleSessions
              onConfirm={value => {
                onSubmitStep(StepId.scheduleSessions, value);
                onNavigateToStep(StepId.clientInfo);
              }}
              onInvalidateStep={useCallback(() => {
                onInvalidateStep(StepId.scheduleSessions);
              }, [onInvalidateStep])}
              onChangeState={setSchedulingState}
              onResetForm={resetScheduleSession}
            />
          </StepperPanel>

          <StepperPanel hidden={currentStep !== StepId.clientInfo}>
            <ClientInformationForm
              onSubmit={value => {
                onSubmitStep(StepId.clientInfo, value);
                onNavigateToStep(StepId.billingInfo);
              }}
              onInvalidateStep={useCallback(() => {
                onInvalidateStep(StepId.clientInfo);
              }, [onInvalidateStep])}
              isStepCompleted={stepsState[StepId.clientInfo]}
              schedulingState={schedulingState}
              isIntakePossible={isIntakePossible}
              searchParams={params}
            />
          </StepperPanel>

          <StepperPanel hidden={currentStep !== StepId.billingInfo}>
            <BillingInformationForm
              onSubmit={value => {
                onSubmitStep(StepId.billingInfo, value);
                onNavigateToStep(StepId.reviewData);
              }}
              onInvalidateStep={useCallback(() => {
                onInvalidateStep(StepId.billingInfo);
              }, [onInvalidateStep])}
              isStepCompleted={stepsState[StepId.billingInfo]}
              isPreviousStepCompleted={stepsState[StepId.clientInfo]}
              defaultValues={{
                skipped: false,
                billingPriceTier: { ...billingPriceTierOptions[0] },
              }}
              clientState={stepsData[StepId.clientInfo]}
              onNavigateToStep={onNavigateToStep}
            />
          </StepperPanel>

          <StepperPanel hidden={currentStep !== StepId.reviewData}>
            <ReviewSummary
              stepsData={stepsData}
              onNavigateToStep={onNavigateToStep}
              stepsState={stepsState}
              onSubmitError={(error) => {
                // the Schedule Session must be invalidated when we get the rate-limiting error
                if (error.keyword === TherapistErrorCode.RateLimit) {
                  setResetScheduleSession(true);
                }
              }}
            />
          </StepperPanel>
        </Container>
      </div>
    </DisplayTimezoneProvider>
  );
};

export default ClientCreate;

// @note(juan): lets keep this here for now, I'd like to think about the possible use cases before moving it into a reusable component with its own file
const StepperPanel: React.FC<{ hidden: boolean }> = ({ hidden, children }) => {
  return (
    <div
      className={cx({
        hidden,
      })}
    >
      {children}
    </div>
  );
};
