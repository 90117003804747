import { Button } from '@expressable/ui-library';
import { captureException } from '@sentry/react';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';

export const ErrorFallback = ({ error }: { error?: Error }): JSX.Element => {
  useEffect(() => {
    if (error) {
      Sentry.captureException(error);
      captureException(error);
    }
  }, []);

  return (
    <main id="maincontent" tabIndex={-1} className="flex flex-col min-h-screen">
      <div className="flex items-center bg-indigo-700 h-16 px-8">
        <a href="https://secure.expressable.io/">
          <img className="w-32" src={process.env.PUBLIC_URL + '/whitelogo.svg'} alt="Expressable Logo" />
        </a>
      </div>

      <div className="flex flex-col items-start bg-white p-8 flex-grow">
        <h1 className="text-3xl font-bold">The app has encountered an error and it could not recover</h1>
        <p className="mb-4">The following text might be of use to the technical team:</p>
        <code className="p-4 bg-gray-200 mb-4">{error?.message ?? 'No information about the error'}</code>
        <p className="mb-8">URL where the error occurred: {window.location.href}</p>
        <p className="font-bold">Please share a screenshot of this screen with the technical team</p>
        <a href="https://secure.expressable.io/">
          <Button variant="primary" className="mt-4">
            Return to dashboard
          </Button>
        </a>
      </div>
    </main>
  );
};
