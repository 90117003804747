import { useEffect, useState } from 'react';
import { Container, Tab, TabList, TabPanel, TabPanels, Tabs, TitleBar } from '@expressable/ui-library';
import { Helmet } from 'react-helmet';
import UnlockedNotes from './components/unlocked-notes';
import EventLog from './components/event-log';
import Attendances from './components/attendances';
import Payroll from './components/payroll';
import Eligibility from './components/eligibility';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import PendingReschedules from './components/pending-reschedules';
import Authorizations from './components/authorizations';
import AwaitingIntake from './components/awaiting-intake';
import QualityReviews from './components/quality-reviews';
import NoAppointments from './components/no-appointments';
import 'twin.macro';
import ScheduleConflicts from './components/schedule-conflicts';

enum AdminTab {
  Documentation = 0,
  EventLog = 1,
  Attendance = 2,
  PendingReschedules = 3,
  AwaitingIntake = 4,
  Payroll = 5,
  Authorizations = 6,
  QualityReviews = 7,
  Eligibility = 8,
  NoAppointments = 9,
}

const Admin = (props: RouteComponentProps) => {
  const {
    location: { hash },
  } = props;

  // TODO: This feature flag might not be necessary in the near future.
  const { canDownloadPayrollReport: isDownloadPayrollReportEnabled, cueEnableScheduleConflicts } = useFlags();

  const tabHashes = [
    '#documentation',
    '#event-log',
    '#attendance',
    '#pending-reschedules',
    '#awaiting-intake',
    '#payroll',
    '#authorizations',
    '#quality-reviews',
    '#eligibility',
    '#no-appointments',
    '#schedule-conflicts',
  ];

  const tabindex = tabHashes.indexOf(hash.split('?')[0]);
  const [index, setIndex] = useState<AdminTab>(tabindex < 0 ? AdminTab.Documentation : tabindex);
  const hashParams = new URLSearchParams(hash.substring(hash.indexOf('?') + 1));
  const eventSearchParam = hashParams.get('events');
  const createdBySearchParam = hashParams.get('createdBy');
  const dateSearchParam = hashParams.get('date');
  const sessionTypeParam = hashParams.get('sessionType');
  const history = useHistory();

  useEffect(() => {
    if (eventSearchParam || createdBySearchParam || dateSearchParam || sessionTypeParam) {
      setIndex(AdminTab.EventLog);
    }
  }, []);

  useEffect(() => {
    if (isDownloadPayrollReportEnabled) {
      if (hash === '#payroll') {
        setIndex(tabHashes.indexOf(hash));
      }
    }
    if (cueEnableScheduleConflicts) {
      if (hash === '#schedule-conflicts') {
        setIndex(tabHashes.indexOf(hash));
      }
    }
    if (tabindex === -1) {
      setIndex(AdminTab.Documentation);
    } else {
      setIndex(tabindex);
    }
  }, [isDownloadPayrollReportEnabled, cueEnableScheduleConflicts]);

  return (
    <div data-testid="admin" css={{ marginBottom: 28 }}>
      <>
        <Helmet title="Admin" />
        <TitleBar title="Admin" />
        <Container size="large">
          <Tabs
            index={index}
            onChange={index => {
              setIndex(index);

              // hacky way to update the hash in the url on mobile
              if (window.innerWidth < 1305) history.push(`/admin${tabHashes[index]}`);
            }}
            defaultIndex={tabindex}
          >
            <TabList tw="mt-4 mb-10">
              <Tab id="documentation">Documentation</Tab>
              <Tab id="event-log">Event Log</Tab>
              <Tab id="attendance">Attendance</Tab>
              <Tab id="pending-reschedules">Pending Reschedules</Tab>
              <Tab id="awaiting-intake">Awaiting Intake</Tab>
              <Tab id="payroll">Payroll</Tab>
              <Tab id="authorizations">Authorizations</Tab>
              <Tab id="quality-reviews">Quality Reviews</Tab>
              <Tab id="eligibility">Eligibility</Tab>
              <Tab id="no-appointments">No Appointments</Tab>
              <Tab id="schedule-conflicts">Schedule Conflicts</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <UnlockedNotes />
              </TabPanel>
              <TabPanel>
                <EventLog
                  eventSearchParam={eventSearchParam}
                  createdBySearchParam={createdBySearchParam}
                  dateSearchParam={dateSearchParam}
                  sessionTypeParam={sessionTypeParam}
                />
              </TabPanel>
              <TabPanel>
                <Attendances />
              </TabPanel>
              <TabPanel>
                <PendingReschedules isAdminPage={true} isClientPage={false} isTherapistPage={false} />
              </TabPanel>
              <TabPanel>
                <AwaitingIntake />
              </TabPanel>
              <TabPanel>
                {isDownloadPayrollReportEnabled ? (
                  <Payroll />
                ) : (
                  <div>
                    Sorry, you don’t have access to this page. Please reach out to the help center if you need access.
                  </div>
                )}
              </TabPanel>
              <TabPanel>
                <Authorizations isAdminPage={true} isTherapistPage={false} />
              </TabPanel>
              <TabPanel>
                <QualityReviews />
              </TabPanel>
              <TabPanel>
                <Eligibility />
              </TabPanel>
              <TabPanel>
                <NoAppointments />
              </TabPanel>
              <TabPanel>
                {cueEnableScheduleConflicts ? (
                  <ScheduleConflicts />
                ) : (
                  <div>
                    Sorry, you don’t have access to this page. Please reach out to the help center if you need access.
                  </div>
                )}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Container>
      </>
    </div>
  );
};

export default Admin;
