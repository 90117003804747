import { throttle } from 'lodash';
import { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

type UseFormChangeWatch = (callback: () => void, throttleDuration?: number, blocker?: boolean) => void;

export const useFormChangeWatch: UseFormChangeWatch = (callback, throttleDuration, blocker) => {
  const { watch } = useFormContext();

  const onChangeInvalidate = useCallback(
    // note(juan): use throttle here since this will get called on every keystroke within an input

    throttle(() => {
      if (blocker) return;
      callback();
    }, throttleDuration),
    [callback],
  );

  // Subscribe to ANY changes on the form
  useEffect(() => {
    const subscription = watch(onChangeInvalidate);
    return () => subscription.unsubscribe();
  }, [watch, onChangeInvalidate]);
};
