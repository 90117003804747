import { useMutation, useQuery, useQueryClient } from 'react-query';
import { dynamicHttp } from '@expressable/utils';
import { useToasts } from 'react-toast-notifications';
import { presentMutationError } from '@expressable/ui-library';

const httpHealthRecordProxy = dynamicHttp('health-record-proxy');

export interface GetDischargePlanPayload {
  totalVisits: number;
}

export interface SetTotalVisitsPayload {
  clientId: string;
  totalVisits: number;
  shouldLogEvent: boolean;
}

const getTotalVisits = async (clientId: string) => {
  const { data } = await httpHealthRecordProxy.get(`/care-plans/total-visits/${clientId}`);
  return data;
};

export function useGetPlanOfCareTotalVisits({ clientId }: { clientId: string }) {
  return useQuery<GetDischargePlanPayload | null>(['care-plan-total-visits', clientId], () => getTotalVisits(clientId));
}

const setTotalVisits = async ({ clientId, ...rest }: SetTotalVisitsPayload) => {
  return httpHealthRecordProxy
    .post(`/care-plans/total-visits/${clientId}`, {
      totalVisits: rest.totalVisits,
      shouldLogEvent: rest.shouldLogEvent,
    })
    .then(res => res.data);
};

export function useSetTotalVisits() {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(setTotalVisits, {
    onSuccess: (_, payload) => {
      addToast('Total Visits Successfully Updated', { appearance: 'success', autoDismiss: true });
      queryClient.invalidateQueries(['care-plan-total-visits', payload.clientId]);
    },
    onError: presentMutationError,
  });
}
