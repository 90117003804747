import { useMutation, useQuery, useQueryClient } from 'react-query';
import { dynamicHttp } from '@expressable/utils';
import { useToasts } from 'react-toast-notifications';
import { presentMutationError } from '@expressable/ui-library';

const httpHealthRecordProxy = dynamicHttp('health-record-proxy');

export interface GetAdditionalGoalsPayload {
  version: string;
  goalType: string;
  goalID: string;
  status: string;
  detail: {
    goalDescription: string;
  };
}

export interface SetAdditionalGoalPayload {
  clientId: string;
  version: string;
  goalType: string;
  goalID: string;
  status: string;
  shouldLogEvent: boolean;
  detail: {
    goalDescription: string;
  };
}

const getAdditionalGoals = async (clientId: string) => {
  const { data } = await httpHealthRecordProxy.get(`/care-plans/additional-goals/${clientId}`);
  return data;
};

export function useGetAdditionalGoal({ clientId }: { clientId: string }) {
  return useQuery<GetAdditionalGoalsPayload[] | null>(['care-plan-additional-goals', clientId], () =>
    getAdditionalGoals(clientId),
  );
}

const setNewAdditionalGoals = async ({ clientId, ...rest }: Omit<SetAdditionalGoalPayload, 'goalID'>) => {
  return httpHealthRecordProxy.post(`/care-plans/additional-goals/${clientId}`, { ...rest }).then(res => res.data);
};

export function useSetNewAdditionalGoal() {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(setNewAdditionalGoals, {
    onSuccess: (_, payload) => {
      addToast('Additional Goal Successfully Created', { appearance: 'success', autoDismiss: true });
      queryClient.invalidateQueries(['care-plan-additional-goals', payload.clientId]);
    },
    onError: presentMutationError,
  });
}

const setAdditionalGoals = async ({ clientId, ...rest }: SetAdditionalGoalPayload) => {
  return httpHealthRecordProxy
    .put(`/care-plans/additional-goals/${clientId}/${rest.goalID}`, { ...rest })
    .then(res => res.data);
};

export function useSetAdditionalGoal() {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(setAdditionalGoals, {
    onSuccess: (_, payload) => {
      addToast('Additional Goal Successfully Updated', { appearance: 'success', autoDismiss: true });
      queryClient.invalidateQueries(['care-plan-additional-goals', payload.clientId]);
    },
    onError: presentMutationError,
  });
}

const deleteAdditionalGoals = async ({ clientId, goalID }: { clientId: string; goalID: string }) => {
  httpHealthRecordProxy.delete(`/care-plans/additional-goals/${clientId}/${goalID}`).then(res => res.data);
};

export function useDeleteAdditionalGoal() {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(deleteAdditionalGoals, {
    onSuccess: (_, payload) => {
      addToast('Additional Goal Successfully Deleted', { appearance: 'success', autoDismiss: true });
      queryClient.invalidateQueries(['care-plan-additional-goals', payload.clientId]);
    },
    onError: presentMutationError,
  });
}
