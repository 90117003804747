import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CLIENT_RESOURCES_CONFIG } from 'domain/client-resources/constants';
import { Fragment } from 'react';
import 'twin.macro';

type PaginationProps = {
  shouldDisplayBackButton?: boolean;
  shouldDisplayNextButton?: boolean;
  shouldShowPagination?: boolean;
  totalItems: number;
  totalPages: number;
  pages: number;
  data: Record<string, unknown>[];
  handlePaginationNext: () => void;
  handlePaginationBack: () => void;
};

export const Pagination = (props: PaginationProps) => {
  const {
    shouldDisplayBackButton,
    shouldDisplayNextButton,
    handlePaginationNext,
    handlePaginationBack,
    shouldShowPagination,
    totalItems,
    pages,
    totalPages,
    data,
  } = props;
  if (!shouldShowPagination) return null;

  return (
    <Fragment>
      <ComposedMessage totalItems={totalItems} page={pages} totalPages={totalPages} data={data} />
      <Fragment>
        {shouldDisplayBackButton && (
          <FontAwesomeIcon
            title="Previous Page"
            data-testid="client-resources__previous-page"
            onClick={handlePaginationBack}
            tw="text-indigo-700 ml-4 mr-4 cursor-pointer flex"
            icon={faChevronLeft}
          />
        )}
        {shouldDisplayNextButton && (
          <FontAwesomeIcon
            title="Next Page"
            data-testid="client-resources__next-page"
            onClick={handlePaginationNext}
            tw="text-indigo-700 cursor-pointer ml-3 mr-2 flex"
            icon={faChevronRight}
          />
        )}
      </Fragment>
    </Fragment>
  );
};

type ComposeMessageProps = {
  page: number;
  totalPages: number;
  totalItems: number;
  data: Record<string, unknown>[];
};

export const ComposedMessage = ({ page, totalPages, totalItems, data }: ComposeMessageProps) => {
  if (!data.length) return null;
  return (
    <Fragment>
      Showing&nbsp;
      <strong>{Number(page === 0 ? 1 : CLIENT_RESOURCES_CONFIG.PAGINATION_SIZE * page).toLocaleString()}</strong>
      &nbsp;to&nbsp;
      <strong>
        {
          // example showing 1 to 11 of 11 results
          Number(CLIENT_RESOURCES_CONFIG.PAGINATION_SIZE * (page + 1)) > totalItems
            ? totalItems.toLocaleString()
            : Number(CLIENT_RESOURCES_CONFIG.PAGINATION_SIZE * (page + 1)).toLocaleString()
        }
      </strong>
      &nbsp;of&nbsp;
      <strong>{totalItems.toLocaleString()}</strong>&nbsp;result
      {totalItems > 1 && 's'}
    </Fragment>
  );
};
