export enum ActionByEnum {
  therapist = 'therapist',
  client = 'client',
}

export enum SessionTypeEnum {
  evaluation = 'evaluation',
  session = 'session',
  chart = 'chart',
  admin = 'admin',
  screening = 'screening',
  appointment = 'appointment',
}
