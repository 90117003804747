import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { dynamicHttp } from '@expressable/utils';
import { presentMutationError, useToast } from '@expressable/ui-library';

const httpHealthRecordProxy = dynamicHttp('health-record-proxy');

const getAreasOfFunction = async (clientId: string) => {
  const { data } = await httpHealthRecordProxy.get(`/care-plans/areas-of-function/${clientId}`);
  return data;
};

export function useGetAreasOfFunction(
  { clientId }: { clientId: string },
  options?: UseQueryOptions<string[], unknown, string[]>,
) {
  return useQuery<string[]>(['areas-of-function', clientId], () => getAreasOfFunction(clientId), options);
}

export interface SetAreasOfFunctionPayload {
  clientId: string;
  areasOfFunction: string[];
  shouldLogEvent: boolean;
}

const setAreasOfFunction = async (payload: SetAreasOfFunctionPayload) => {
  const { clientId, ...rest } = payload;
  return httpHealthRecordProxy.post(`/care-plans/areas-of-function/${clientId}`, rest).then(res => res.data);
};

export function useSetAreasOfFunction() {
  const queryClient = useQueryClient();
  const { successToast } = useToast();

  return useMutation(setAreasOfFunction, {
    onSuccess: (_, payload) => {
      successToast('Areas of Function successfully updated.');
      queryClient.invalidateQueries(['areas-of-function', payload.clientId]);
    },
    onError: presentMutationError,
  });
}
