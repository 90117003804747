import { EntryCollection } from 'contentful';
import { client } from 'hooks/use-ContentfulData';
import * as Sentry from '@sentry/react';
import { useEffect, useState } from 'react';

type ContentfulField = { [key: string]: string };

export default function useFetchContentfulStg(goalTreatmentArea?: string) {
  const [isLoading, setIsLoading] = useState(true);

  const [expressableAcademyTreatmentAreas, setExpressableAcademyTreatmentAreas] = useState({});
  const [shortTermGoals, setShortTermGoals] = useState({});

  useEffect(() => {
    const initialize = async () => {
      try {
        const expressableAcademyTreatmentAreasEntries = (await client.getEntries({
          content_type: 'expressableAcademyTreatmentAreas',
          limit: 1000,
        })) as EntryCollection<ContentfulField>;
        setExpressableAcademyTreatmentAreas(expressableAcademyTreatmentAreasEntries);

        const expresableShortTermGoalsEntries = (await client.getEntries({
          content_type: 'shortTermGoals',
          limit: 1000,
          'fields.treatmentArea.sys.id[in]': expressableAcademyTreatmentAreasEntries?.items
            ?.filter(ta => ta.fields.treatmentAreas === goalTreatmentArea)
            .map(ta => ta.sys.id)
            .join(','),
        })) as EntryCollection<ContentfulField>;
        setShortTermGoals(expresableShortTermGoalsEntries);

        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        Sentry.captureException(err);
      }
    };
    initialize();
  }, []);

  return { expressableAcademyTreatmentAreas, shortTermGoals, isLoading };
}
