import { UseMutationResult, useMutation, useQuery } from 'react-query';
import { http } from '@expressable/utils';
import { Client, Contact } from 'types';

interface ContactMetaData {
  associatedClients: Client[];
  contactData: Contact;
}

// How long until we re-fetch the contacts list, in milliseconds
const CONTACTS_CACHE_LIFETIME = 1000;

const getContacts = async (email?: string) => {
  const url = email ? `/contacts?therapistEmail=${email}` : '/contacts';
  const { data } = await http.get(url);
  return data;
};

const getContactsByPhoneNumber = async (phoneNumber: string) => {
  const { data } = await http.get(`/contacts?mobilePhone=${phoneNumber}&includeRelatedClients=true`);
  return data;
};

export function useContacts(email?: string) {
  return useQuery(['contacts', email], () => getContacts(email), {
    staleTime: CONTACTS_CACHE_LIFETIME,
    refetchOnMount: 'always',
  });
}

export function useContactsByPhoneNumber(phoneNumber: string): UseMutationResult<Contact[], unknown> {
  return useMutation(() => getContactsByPhoneNumber(phoneNumber));
}

const getContactMeta = async (contactId: string) => {
  const { data } = await http.get(`/contact/${contactId}`);
  return data;
};

export function useContactMeta(contactId: string) {
  return useQuery<ContactMetaData, Error>(['contacts', contactId], () => getContactMeta(contactId));
}

const getDuplicatedContacts = async (
  isPhoneNumberNotProvided: boolean,
  isEmailAddressNotProvided: boolean,
  phoneNumber?: string,
  email?: string,
  phoneType?: string,
) => {
  const params: { [key: string]: string } = {};

  if (!isEmailAddressNotProvided && email) {
    params['email'] = email;
  }

  if (!isPhoneNumberNotProvided && phoneNumber && phoneType) {
    params[phoneType] = phoneNumber.trim();
  }

  params['includeRelatedClients'] = 'true';

  const { data } = await http.get(`/contacts`, { params });
  return data;
};

export function useDuplicatedContacts(
  isPhoneNumberNotProvided: boolean,
  isEmailAddressNotProvided: boolean,
  phoneNumber?: string,
  email?: string,
  phoneType?: string,
): UseMutationResult<Contact[], unknown> {
  return useMutation(() =>
    getDuplicatedContacts(isPhoneNumberNotProvided, isEmailAddressNotProvided, phoneNumber, email, phoneType),
  );
}
