import { useToast } from '@expressable/ui-library';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import mapDischargeDataToDischargeDTO from 'domain/notes/discharge/mapDischargeFormDataToDischargeDTO';
import { mapDischargeNoteDTOToFormData } from 'domain/notes/discharge/mapDischargeNoteDTOToFormData';
import { DischargeNoteDTO } from 'domain/notes/discharge/types';
import { convertStringArrayToSelectOptions } from 'domain/notes/mapHelpers';
import { useAppointments } from 'hooks/use-appointments';
import { useGetGoals, Goal } from 'hooks/use-care-plan-goals';
import { useClient } from 'hooks/use-client';
import { EntryId, useContentfulEntry } from 'hooks/use-contentful';
import { useCreateNote, useEditNote } from 'hooks/use-notes';
import { useDismissPendingAppointment } from 'hooks/use-pending-appointments';
import { produce } from 'immer';
import { isEqual } from 'lodash';
import React, { useState, useRef, useMemo, useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { serverValidationErrors, Appointment } from 'types';
import { onNoteSubmit } from '..';
import { createFormFieldValidationMap } from '../appointment-note-form';
import { dischargeFormContentSchema } from '../note-form-content/discharge/schema';
import { DischargeNoteFormProps } from './discharge-note-form';
import { GetAdditionalGoalsPayload, useGetAdditionalGoal } from 'hooks/use-care-plan-additional-goals';

const AUTOSAVE_INTERVAL = 4 * 1000;

export const useDischargeNote = (props: DischargeNoteFormProps) => {
  const [localValidationErrors, setLocalValidationErrors] = useState<serverValidationErrors | undefined>();
  const activity = props.activity as Appointment;
  const [isMounted, setIsMounted] = React.useState(false);
  const createNoteMutation = useCreateNote();
  const { mutateAsync: createNote } = createNoteMutation;
  const editNoteMutation = useEditNote();
  const { mutateAsync: editNote } = editNoteMutation;
  const dismissPendingAppointmentMutation = useDismissPendingAppointment();
  const { mutateAsync: dismissPendingAppointment } = dismissPendingAppointmentMutation;
  const validationRefsMap = useRef(new Map());
  const { data: upcomingAppointments, isLoading: areUpcomingAppointmentsLoading } = useAppointments(props.clientId);
  const { data: clientInformation } = useClient(props.clientId);

  const defaultValues = useMemo(() => {
    return activity
      ? mapDischargeNoteDTOToFormData(activity as unknown as DischargeNoteDTO)
      : dischargeFormContentSchema.getDefault();
  }, [activity?.id]);

  const form = useForm({
    defaultValues,
    resolver: yupResolver(dischargeFormContentSchema),
  });

  const mayResumeWatcher = useWatch({
    control: form.control,
    name: 'mayResume',
  });

  const dischargeReason = useWatch({ control: form.control, name: 'dischargeReason' });
  const isReasonGoalMet = dischargeReason?.value?.toLowerCase() === 'goals met';
  const formFieldValidationMap = React.useMemo(
    () => createFormFieldValidationMap(localValidationErrors),
    [localValidationErrors],
  );

  useEffect(() => {
    if (formFieldValidationMap && isMounted && validationRefsMap && validationRefsMap.current) {
      const refsMap = validationRefsMap.current;

      // Get the first element of the instancePath to validation messages map
      // that actually exists
      const firstValidationInstancePath = Array.from(formFieldValidationMap.keys()).find(k => refsMap.has(k));

      if (firstValidationInstancePath) {
        // Get the Ref using the instancePath index and focus the field
        const fieldRef = refsMap.get(firstValidationInstancePath).current;
        if (fieldRef) {
          fieldRef.focus();
        }
      }
    }

    setIsMounted(true);
  }, [formFieldValidationMap]);

  //Detect when server validations errors are ready and add them to the local state
  useEffect(() => {
    if (props.remoteValidationErrors) {
      setLocalValidationErrors(props.remoteValidationErrors);
    }
  }, [props.remoteValidationErrors]);

  const savedFormValuesRef = useRef({});
  const autoSaveTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const clearAutoSaveTimeout = () => {
    if (autoSaveTimeoutRef.current !== null) {
      clearTimeout(autoSaveTimeoutRef.current);
    }
  };

  useEffect(() => {
    if (!isMounted) {
      return;
    }

    const currentFormValues = form.watch();

    clearAutoSaveTimeout();

    autoSaveTimeoutRef.current = setTimeout(() => {
      if (
        !isEqual(currentFormValues, savedFormValuesRef.current) &&
        Object.keys(savedFormValuesRef.current).length > 0
      ) {
        save({ autoSaving: true, currentFormValues });
        savedFormValuesRef.current = currentFormValues;
      } else {
        savedFormValuesRef.current = currentFormValues;
      }
    }, AUTOSAVE_INTERVAL);

    return clearAutoSaveTimeout;
  }, [isMounted, form]);

  const [lastAutoSavedAt, setLastAutoSavedAt] = useState<dayjs.Dayjs | null>(null);

  const isSaving = useRef(false);
  const { errorToast } = useToast();

  const { data: goals } = useGetGoals({ clientId: props.clientId });
  const activeGoals: Goal[] = useMemo(() => {
    if (!goals) {
      return [];
    }

    return goals.filter(g => ['progressing', 'modified'].includes(g.status));
  }, [goals]);

  const { data: additionalGoals } = useGetAdditionalGoal({ clientId: props.clientId });
  const activeAdditionalGoals: GetAdditionalGoalsPayload[] = useMemo(() => {
    if (!additionalGoals) {
      return [];
    }

    return additionalGoals.filter(g => ['progressing', 'modified'].includes(g.status));
  }, [goals]);

  const save = async ({ autoSaving, currentFormValues }: { autoSaving: boolean; currentFormValues: any }) => {
    if (isSaving.current) {
      if (!autoSaving) {
        // User clicked the button when autosaving was ongoing, so we
        // inform the user why the button click was ignored
        errorToast('Please wait while the note is auto-saved');
      }
      return;
    }

    isSaving.current = true;
    try {
      const submissionAppointment = produce(activity, draft => {
        const dto = mapDischargeDataToDischargeDTO(
          currentFormValues,
          activeGoals,
          activeAdditionalGoals,
          props.clientId,
        );
        Object.assign(draft, dto);
      });

      await onNoteSubmit(createNote, editNote, submissionAppointment, props, dismissPendingAppointment, {
        autoSaving,
      });

      setLastAutoSavedAt(dayjs());
      if (!autoSaving) {
        // Reset validation errors as we're closing the form and if
        // it opens again for Edit it shouldn't show the Lock errors
        setLocalValidationErrors(undefined);
        props.setValidationErrors(undefined);

        props.setAutoSaveActivityId!(null);
      }
    } finally {
      isSaving.current = false;
    }
  };

  const serverErrors = useMemo(() => {
    const errors = props.remoteValidationErrors;
    if (!errors || !Array.isArray(errors)) {
      return;
    }

    return Object.fromEntries(
      errors.map(({ field, message }: any) => {
        const key = field?.split('.').at(-1) ?? 'code';
        return [key, message];
      }),
    );
  }, [props.remoteValidationErrors]);

  const dischargeOn = form.watch('dischargeOn');

  const { data: contentfulEntry = {} } = useContentfulEntry({
    entryId: EntryId.ArchiveReasons,
    unwrapArray: true,
  });

  const dischargeReasonOptions = useMemo(
    () => convertStringArrayToSelectOptions(contentfulEntry?.dropdownContent),
    [contentfulEntry],
  );

  const contactEmail = useMemo(() => {
    const primaryContact =
      clientInformation?.contactInformation?.find(c => c.primaryContact) || clientInformation?.contactInformation?.[0];
    return primaryContact?.email;
  }, [clientInformation]);

  useEffect(() => {
    if (mayResumeWatcher === 'no') {
      form.setValue('anticipatedReturn', null);
    }
  }, [mayResumeWatcher]);

  const againstRecommendationWatcher = useWatch({
    control: form.control,
    name: 'againstRecommendation.relevant',
  });

  useEffect(() => {
    if (againstRecommendationWatcher === 'no') {
      form.setValue('againstRecommendation.details', '');
    }
  }, [againstRecommendationWatcher]);

  return {
    form,
    dischargeReasonOptions,
    dischargeOn,
    isReasonGoalMet,
    mayResumeWatcher,
    isSaving,
    save,
    serverErrors,
    upcomingAppointments,
    areUpcomingAppointmentsLoading,
    contactEmail,
    lastAutoSavedAt,
    validationRefsMap,
    localValidationErrors,
    setLocalValidationErrors,
    defaultValues,
    isMounted,
    setIsMounted,
    createNoteMutation,
    editNoteMutation,
    activeGoals,
    activeAdditionalGoals,
    dismissPendingAppointment,
    dismissPendingAppointmentMutation,
  };
};
