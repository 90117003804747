export default [
  { value: '09', label: '09 - Self-pay' },
  { value: '11', label: '11 - Other Non-Federal Programs' },
  { value: '12', label: '12 - Preferred Provider Organization (PPO)' },
  { value: '13', label: '13 - Point of Service (POS)' },
  { value: '14', label: '14 - Exclusive Provider Organization (EPO)' },
  { value: '15', label: '15 - Indemnity Insurance' },
  { value: '16', label: '16 - Health Maintenance Organization (HMO) Medicare Risk' },
  { value: '17', label: '17 - Dental Maintenance Organization' },
  { value: 'AM', label: 'AM - Automobile Medical' },
  { value: 'BL', label: 'BL - Blue Cross/Blue Shield' },
  { value: 'CH', label: 'CH - CHAMPUS' },
  { value: 'CI', label: 'CI - Commercial Insurance Co.' },
  { value: 'DS', label: 'DS - Disability' },
  { value: 'FI', label: 'FI - Federal Employees Program' },
  { value: 'HM', label: 'HM - Health Maintenance Organization (HMO)' },
  { value: 'LM', label: 'LM - Liability Medical' },
  { value: 'MA', label: 'MA - a Part A' },
  { value: 'MB', label: 'MB - Medicare Part B' },
  { value: 'MC', label: 'MC - Medicaid' },
  { value: 'OF', label: 'OF - Other Federal Program' },
  { value: 'TV', label: 'TV - Title V' },
  { value: 'VA', label: 'VA - Veterans Affairs Plan' },
  { value: 'WC', label: "WC - Workers' Compensation Health Claim" },
  { value: 'ZZ', label: 'ZZ - Mutually Defined' },
];
