export default function formatZoomLink(therapistName: string): string {
  const words = therapistName
    .split(' ')
    .filter(w => w.length > 0)
    .map(w => w.toLowerCase());

  // Delete all words except the first and the last
  words.splice(1, words.length - 2);

  // Remove hyphen characters
  const zoomLinkName = words.join('.').split('-').join('');

  return `https://zoom.us/my/expressable.${zoomLinkName}`;
}
