import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import tw from 'twin.macro';
import { Container } from '@expressable/ui-library';
import { Auth } from 'aws-amplify';
import styled, { css } from 'styled-components';
import NavbarItem from 'components/navbar/navbar-item';
import usePermissions from 'hooks/use-permissions';
import * as Sentry from '@sentry/react';
import { useCurrentUserContext } from '@expressable/utils';
import { useLDClient } from 'launchdarkly-react-client-sdk';

async function signOut() {
  try {
    await Auth.signOut();
    window.Intercom('shutdown');
    // This is because of some weirdness with amplify. Signout works, and clears
    // their state/cookie but it doesn't automatically redirect back to the signin page.
    //  Refreshing the browser though works fine.
    window.location.reload();
  } catch (error) {
    Sentry.captureException(error);
    console.error('error signing out: ', error);
  }
}

function launchIntercomMessenger(user: { email: string }) {
  if (window.Intercom) {
    window.Intercom('boot', {
      app_id: process.env.REACT_APP_INTERCOM_APP_ID as string,
      name: user.email,
      email: user.email,
      user_id: user.email,
    });
  } else {
    console.error('INTERCOM APP NOT LOADED.');
  }
}

interface NavigationProps {
  expanded: boolean;
}

const Navigation = styled.div<NavigationProps>`
  transition: transform 0.2s ease-in-out;
  display: flex;
  width: 100%;
  @media (max-width: 1023px) {
    visibility: hidden;
    transform: scaleY(0);
    transform-origin: top;
    padding: 20px;
    ${tw`bg-indigo-700`}
    left: 0;
    top: 64px;
    flex-direction: column;
    position: fixed;
    height: calc(100vh - 64px);
    width: 100vw;
    z-index: 2;
    ${props =>
      props.expanded &&
      css`
        visibility: visible;
        transform: scaleY(1);
      `};
  }
`;

interface HamburgerProps {
  open: boolean;
}

const Hamburger = styled.div<HamburgerProps>`
  width: 26px;
  height: 17px;
  position: relative;
  transform: rotate(0deg);
  transition: 0.3s ease-in-out;
  cursor: pointer;
  span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    ${tw`bg-indigo-200`}
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.15s ease-in-out;
    :nth-child(1) {
      top: 0px;
      ${props =>
        props.open &&
        css`
          top: 6px;
          width: 0%;
          left: 50%;
        `};
    }
    :nth-child(2) {
      top: 6px;
      ${props =>
        props.open &&
        css`
          transform: rotate(45deg);
        `};
    }
    :nth-child(3) {
      top: 6px;
      ${props =>
        props.open &&
        css`
          transform: rotate(-45deg);
        `};
    }
    :nth-child(4) {
      top: 12px;
      ${props =>
        props.open &&
        css`
          top: 6px;
          width: 0%;
          left: 50%;
        `};
    }
  }
`;

const Navbar = () => {
  const currentUser = useCurrentUserContext();
  const { isAdmin, isClinicalManager } = usePermissions();
  const shouldDisplayAdminOption = isAdmin || isClinicalManager;

  const [expanded, setExpanded] = useState(false);

  const setExpandedToFalse = () => setExpanded(false);
  const toggleExpanded = () => setExpanded(expanded => !expanded);
  const launchDarkly = useLDClient();
  const onSignOut = (event: React.SyntheticEvent) => {
    event.preventDefault();
    signOut();
  };
  useEffect(() => {
    if (currentUser) {
      launchIntercomMessenger(currentUser);
      launchDarkly?.identify({
        email: currentUser.email,
        name: currentUser.email,
        key: currentUser.email,
      });
    }
  }, [currentUser]);

  return (
    <div>
      <nav tw="bg-indigo-700">
        <Container size="large">
          <div tw="flex items-center justify-between h-16">
            <div tw="flex items-center w-full">
              <div tw="flex w-full lg:w-auto justify-between">
                <Link tw="w-32" to="/">
                  <img tw="w-32" src={process.env.PUBLIC_URL + '/whitelogo.svg'} alt="Expressable Logo" />
                </Link>
                <div onClick={toggleExpanded} tw="lg:hidden flex items-center text-indigo-200">
                  <Hamburger open={expanded}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </Hamburger>
                </div>
              </div>
              <Navigation expanded={expanded} tw="items-center">
                <NavbarItem label="Dashboard" to="/" onClick={setExpandedToFalse} />
                <NavbarItem label="Clients" to="/clients" onClick={setExpandedToFalse} />
                <NavbarItem label="Contacts" to="/contacts" onClick={setExpandedToFalse} />
                <NavbarItem label="Therapists" to="/therapists" onClick={setExpandedToFalse} />
                <NavbarItem label="Resources" to="/academy/resources" onClick={setExpandedToFalse} />
                {currentUser && shouldDisplayAdminOption && (
                  <NavbarItem label="Admin" to="/admin" onClick={setExpandedToFalse} />
                )}
                <div tw="lg:ml-auto">
                  <NavbarItem label="Sign out" to="signout" onClick={onSignOut} />
                </div>
              </Navigation>
            </div>
          </div>
        </Container>
      </nav>
    </div>
  );
};

export default Navbar;
