import { useMemo } from 'react';
import { getSchemaFieldAttributes } from 'utils/fieldAttributes';
import { useFormValidation } from './useFormValidation';

export default function useOptional(field: string, manualOptional?: boolean) {
  const { schema, hideOptional } = useFormValidation();

  return useMemo(() => {
    // If something was specified for the `optional` attribute, skip
    // automatic detection of optional from schema
    if (manualOptional !== undefined) {
      return manualOptional;
    }

    const schemaFieldAttributes = getSchemaFieldAttributes(schema, field);
    return !hideOptional && !schemaFieldAttributes.required;
  }, [schema, hideOptional, field, manualOptional]);
}
