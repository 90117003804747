export const contactRelationshipOptions = [
  { label: 'Parent', value: 'Parent' },
  { label: 'Dependent', value: 'Dependent' },
  { label: 'Family Member', value: 'Family Member' },
  { label: 'Legal Guardian', value: 'Legal Guardian' },
  { label: 'Physician', value: 'Physician' },
  { label: 'Spouse', value: 'Spouse' },
  { label: 'Other', value: 'Other' },
];

export const phoneTypeOptions = [
  { value: 'home', label: 'Home' },
  { value: 'mobile', label: 'Mobile' },
  { value: 'work', label: 'Work' },
];
