import {
  Button,
  CloseButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
  Textarea,
} from '@expressable/ui-library';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faNotesMedical } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import { Form, FormField } from 'components/Forms';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ControlledFormField } from 'components/Forms/FormFieldControlled';
import { useMemo } from 'react';
import { selectOptionSchema } from 'pages/new-client-create/ClientInformation';
import {
  GetAdditionalGoalsPayload,
  useDeleteAdditionalGoal,
  useSetAdditionalGoal,
  useSetNewAdditionalGoal,
} from 'hooks/use-care-plan-additional-goals';
import { startCase } from 'lodash';
import { Link } from 'react-router-dom';

interface PlanOfCareAdditionalGoalsModalProps {
  isOpen: boolean;
  onClose: () => void;
  clientId: string;
  shouldLogEvent: boolean;
  action: 'add' | 'update' | '';
  selectedGoal?: GetAdditionalGoalsPayload | null;
}

const schema = yup.object({
  goalType: selectOptionSchema,
  version: yup.string().default('1.0'),
  status: selectOptionSchema.required('Status is required'),
  goalDescription: yup.string().required('Goal Description is required'),
});

export function AdditionalGoalsModal(props: PlanOfCareAdditionalGoalsModalProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isOpen, onClose, clientId, shouldLogEvent, action } = props;
  const defaultValues = useMemo(
    () =>
      action === 'add'
        ? {
            goalType: { label: 'Caregiver Goals', value: 'Caregiver Goals' },
            version: '1.0',
            status: null,
            goalDescription: '',
          }
        : {
            goalType:
              props.selectedGoal?.goalType === 'Personal Goals'
                ? { label: 'Personal Goals', value: 'Personal Goals' }
                : { label: 'Caregiver Goals', value: 'Caregiver Goals' },
            version: '1.0',
            status: { label: startCase(props.selectedGoal?.status), value: props.selectedGoal?.status.toLowerCase() },
            goalDescription: props.selectedGoal?.detail.goalDescription,
          },
    [],
  );
  const { mutateAsync: setNewAdditionalGoal, isLoading: isLoadingNew } = useSetNewAdditionalGoal();
  const { mutateAsync: setAdditionalGoal, isLoading: isLoadingUpdate } = useSetAdditionalGoal();
  const { mutateAsync: deleteGoal, isLoading: isLoadingDelete } = useDeleteAdditionalGoal();

  const form = useForm({
    //@ts-ignore
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: any) => {
    if (action === 'add') {
      await setNewAdditionalGoal({
        clientId,
        shouldLogEvent,
        ...{
          goalType: data.goalType.value,
          version: data.version,
          status: data.status.value,
          detail: {
            goalDescription: data.goalDescription,
          },
        },
      });
    }

    if (action === 'update') {
      await setAdditionalGoal({
        clientId,
        shouldLogEvent,
        ...{
          goalType: data.goalType.value,
          goalID: props.selectedGoal!.goalID,
          version: data.version,
          status: data.status.value,
          detail: {
            goalDescription: data.goalDescription,
          },
        },
      });
    }

    onClose();
  };

  return (
    <Modal portal isOpen={isOpen}>
      <ModalContent>
        <Form form={form as any} onSubmit={onSubmit} schema={schema} hideOptional>
          <ModalHeader>
            <CloseButton onClick={onClose} />
          </ModalHeader>
          <ModalBody>
            <div>
              <div className="flex items-center justify-center w-12 h-12 mx-auto bg-indigo-100 rounded-full">
                <FontAwesomeIcon className="text-2xl text-indigo-700" icon={faNotesMedical} data-testid="icon" />
              </div>
              <div className="mt-3 sm:mt-5">
                <h3 className="text-lg text-center font-medium text-gray-900 leading-6" id="modal-headline">
                  {action === 'add' ? 'New Goal' : 'Update Goal'}
                </h3>
                {action === 'update' && (
                  //delete button
                  <Link
                    to="#"
                    className="mt-1 mb-9 text-red-600 text-center flex justify-center"
                    data-testid="delete-ltg-goal"
                    onClick={async () => {
                      await deleteGoal({
                        clientId,
                        goalID: props.selectedGoal!.goalID,
                      });

                      onClose();
                    }}
                  >
                    Delete Goal
                  </Link>
                )}
                <div className="mt-10">
                  <ControlledFormField
                    containerClass="w-full"
                    id="goalType"
                    name="goalType"
                    label="Goal Type"
                    component={Select}
                    defaultValue={{ label: 'Caregiver Goals', value: 'Caregiver Goals' }}
                    options={[
                      { label: 'Caregiver Goals', value: 'Caregiver Goals' },
                      { label: 'Personal Goals', value: 'Personal Goals' },
                    ]}
                  />
                </div>
                <div className="mt-10">
                  <FormField component={Textarea} name="goalDescription" label="Goal" rows={5} />
                </div>
                <div className="mt-10">
                  <ControlledFormField
                    containerClass="w-full"
                    id="status"
                    name="status"
                    label="Status"
                    component={Select}
                    options={[
                      { label: 'Progressing', value: 'progressing' },
                      { label: 'Met', value: 'met' },
                      { label: 'Modified', value: 'modified' },
                      { label: 'Discontinued', value: 'discontinued' },
                    ]}
                  />
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-1 sm:gap-3 sm:grid-flow-row-dense">
              <span className="flex w-full rounded-md shadow-sm">
                <Button
                  type="submit"
                  variant="primary"
                  loading={isLoadingNew || isLoadingUpdate || isLoadingDelete}
                  disabled={isLoadingNew || isLoadingUpdate || isLoadingDelete}
                  className="inline-flex justify-center w-full px-4 py-2 text-base font-medium leading-6 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                >
                  Save
                </Button>
              </span>
            </div>
          </ModalFooter>
        </Form>
      </ModalContent>
    </Modal>
  );
}

export default AdditionalGoalsModal;
