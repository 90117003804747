import { GetAdditionalGoalsPayload } from 'hooks/use-care-plan-additional-goals';
import { Goal, isGoal } from 'hooks/use-care-plan-goals';
import { GoalStatus, GoalType } from 'types';
import { getGoalDetails } from 'utils/care-plans';

export function createGoalID(goal: Goal | GetAdditionalGoalsPayload) {
  if (isGoal(goal)) {
  return goal.goalType === 'ltg' ? `#LTG#${goal.ltgID}` : `#LTG#${goal.ltgID}#STG#${goal.stgID}`;
  } else {
    return `#AG#${goal.goalID}`;
  }
}

export function createGoalStatusName(goal: Goal | GetAdditionalGoalsPayload) {
  const goalID = createGoalID(goal);

  return isGoal(goal) ? `goalsStatuses.${goalID}` : `additionalGoalsStatuses.${goalID}`;
}

export function parseGoalID(name: string) {
  const goalMatch = /#LTG#([^#]+)(#STG#([^#]+))?/.exec(name);
  if (goalMatch) {
    const ltgID = goalMatch[1];
    const stgID = goalMatch[3];
    const goalType: GoalType = stgID ? 'stg' : 'ltg';
    return { goalType, ltgID, stgID };
  }

  const additionalGoalMatch = /#AG#([^#]+)/.exec(name);
  if (additionalGoalMatch) {
    const goalID = additionalGoalMatch[1];
    return { goalID };
  }

  return null;
}

export function convertGoalStatusesToFormData(
  goalStatuses: Record<string, GoalStatus> | undefined,
): Record<string, string> {
  return Object.fromEntries(Object.entries(goalStatuses ?? {}).map(([id, { status }]) => [id, status]));
}

export function convertGoalStatusesToDTO(
  goalStatuses: Record<string, string> | undefined,
  goals: (Goal | GetAdditionalGoalsPayload)[],
): Record<string, GoalStatus> {
  const idsToDescriptions = new Map(
    goals.map(x => {
      if (isGoal(x)) {
        const { goalDescription } = getGoalDetails(x);
        return [createGoalID(x), goalDescription];
      } else {
        return [createGoalID(x), x.detail.goalDescription];
      }
    }),
  );

  return Object.fromEntries(
    Object.entries(goalStatuses ?? {})
      .filter(([id, status]) => !!status)
      .map(([id, status]) => [id, { status, description: idsToDescriptions.get(id) ?? '' }]),
  );
}
