import React from 'react';
import { Input, Label, Textarea, Select, FormGroup, FormInline } from '@expressable/ui-library';
import { Appointment, ScreeningNoteContent } from 'types';
import specialties from 'utils/specialties.json';
import { produce } from 'immer';
import 'twin.macro';

const intelligibilityOptions = [
  { value: 'good', label: 'Good' },
  { value: 'fair', label: 'Fair' },
  { value: 'poor', label: 'Poor' },
];

const soundProductionOptions = [
  { value: 'no_errors', label: 'No Errors' },
  { value: 'errors_with_normal_limits', label: 'Errors with normal limits' },
  { value: 'errors_inappropriate_for_age', label: 'Errors inappropriate for age' },
];

const oralMotorFunctioningOptions = [
  { value: 'normal', label: 'Normal' },
  { value: 'suspect', label: 'Suspect' },
];

const normalInappropriateOptions = [
  { value: 'within_normal_limits', label: 'Within normal limits' },
  { value: 'inappropriate_for_age', label: 'Inappropriate for age' },
];

export const inappropriateFluencyOptions = [
  { value: 'repetitions', label: 'Repetitions' },
  { value: 'prolongations', label: 'Prolongations' },
  { value: 'hesitations', label: 'Hesitations' },
  { value: 'secondary_behaviors', label: 'Secondary Behaviors' },
  { value: 'others', label: 'Other' },
];

const overallImpressionsOptions = [
  { value: 'normal_limits_for_age', label: 'Appears to be within normal limits for age' },
  { value: 'possible_disorder_or_delay', label: 'Possible disorder or delay' },
  { value: 'difference_may_be_related_to_other_factors', label: 'Difference may be related to other factors' },
  { value: 'unable_to_make_a_judgement', label: 'Unable to make a judgement' },
];

const recommendationOptions = [
  { value: 'no_follow_up', label: 'No follow-up necessary' },
  { value: 'further_speech_language_assessment_recommended', label: 'Further speech-language assessment recommended' },
  { value: 'other', label: 'Other' },
];

interface ScreeningNoteFormContentProps {
  formContent: ScreeningNoteContent;
  setActivity: React.Dispatch<React.SetStateAction<Appointment>>;
  focusElementRef?: React.MutableRefObject<HTMLTextAreaElement | null>;
}

export const ScreeningNoteFormContent = (props: ScreeningNoteFormContentProps) => {
  const { formContent, setActivity, focusElementRef } = props;

  const handleScreeningContentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    type Keys = keyof Omit<
      ScreeningNoteContent,
      'articulation' | 'language' | 'inappropriateFluencyDetail' | 'recommendations'
    >;
    setActivity(
      produce(activity => {
        const content = activity!.note.content as ScreeningNoteContent;
        content[name as Keys] = value!;
        if (content.fluency === 'within_normal_limits') {
          content.inappropriateFluencyDetail = [];
        }
      }),
    );
  };

  const handleArticulationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    type Keys = keyof ScreeningNoteContent['articulation'];
    setActivity(
      produce(activity => {
        const content = activity!.note.content as ScreeningNoteContent;
        content.articulation[name as Keys] = value!;
      }),
    );
  };

  const handleLanguageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    type Keys = keyof ScreeningNoteContent['language'];
    setActivity(
      produce(activity => {
        const content = activity!.note.content as ScreeningNoteContent;
        content.language[name as Keys] = value!;
      }),
    );
  };

  const handleInappropriateFluencyDetailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setActivity(
      produce(activity => {
        const content = activity!.note.content as ScreeningNoteContent;
        if (checked) {
          content.inappropriateFluencyDetail.push(name);
        } else {
          content.inappropriateFluencyDetail = formContent.inappropriateFluencyDetail.filter(item => item !== name);
        }
      }),
    );
  };

  const handleRecommendationsChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    type Keys = keyof Omit<ScreeningNoteContent['recommendations'], 'specialtiesRequired'>;
    setActivity(
      produce(activity => {
        const content = activity!.note.content as ScreeningNoteContent;
        content.recommendations[name as Keys] = value;
      }),
    );
  };

  const handleSpecialtiesRequiredChange = (value: string[]) => {
    setActivity(
      produce(activity => {
        const content = activity!.note.content as ScreeningNoteContent;
        content.recommendations.specialtiesRequired = value;
      }),
    );
  };

  return (
    <>
      <div tw="mt-4 mb-4 font-semibold text-lg border-b-2 border-gray-100 pb-1">Articulation</div>
      <FormGroup tw="mt-4">
        <div tw="text-sm font-semibold my-2">Intelligibility</div>
        <FormGroup type="inline">
          {intelligibilityOptions.map(item => (
            <FormInline key={item.value} tw="mr-7 items-baseline">
              <Input
                id={`intelligibility-${item.value}`}
                type="radio"
                name="intelligibility"
                defaultChecked={formContent.articulation?.intelligibility === item.value}
                spacing="tight"
                value={item.value}
                onChange={handleArticulationChange}
              />
              <Label font="normal" htmlFor={`intelligibility-${item.value}`} tw="ml-2">
                {item.label}
              </Label>
            </FormInline>
          ))}
        </FormGroup>
      </FormGroup>
      <FormGroup tw="mt-4">
        <div tw="text-sm font-semibold my-2">Sound Production</div>
        <FormGroup type="inline">
          {soundProductionOptions.map(item => (
            <FormInline key={item.value} tw="mr-7 items-baseline">
              <Input
                id={`soundProduction-${item.value}`}
                type="radio"
                name="soundProduction"
                defaultChecked={formContent.articulation?.soundProduction === item.value}
                spacing="tight"
                value={item.value}
                onChange={handleArticulationChange}
              />
              <Label font="normal" htmlFor={`soundProduction-${item.value}`} tw="ml-2">
                {item.label}
              </Label>
            </FormInline>
          ))}
        </FormGroup>
      </FormGroup>
      <FormGroup tw="mt-4">
        <div tw="text-sm font-semibold my-2">Oral Motor Functioning</div>
        <FormGroup type="inline">
          {oralMotorFunctioningOptions.map(item => (
            <FormInline key={item.value} tw="mr-7 items-baseline">
              <Input
                id={`oralMotorFunctioning-${item.value}`}
                type="radio"
                name="oralMotorFunctioning"
                defaultChecked={formContent.articulation?.oralMotorFunctioning === item.value}
                spacing="tight"
                value={item.value}
                onChange={handleArticulationChange}
              />
              <Label font="normal" htmlFor={`oralMotorFunctioning-${item.value}`} tw="ml-2">
                {item.label}
              </Label>
            </FormInline>
          ))}
        </FormGroup>
      </FormGroup>
      <div tw="mt-8 mb-4 font-semibold text-lg border-b-2 border-gray-100 pb-1">Language</div>
      <FormGroup tw="mt-4">
        <div tw="text-sm font-semibold my-2">Receptive</div>
        <FormGroup type="inline">
          {normalInappropriateOptions.map(item => (
            <FormInline key={item.value} tw="mr-7 items-baseline">
              <Input
                id={`receptive-${item.value}`}
                type="radio"
                name="receptive"
                defaultChecked={formContent.language?.receptive === item.value}
                spacing="tight"
                value={item.value}
                onChange={handleLanguageChange}
              />
              <Label font="normal" htmlFor={`receptive-${item.value}`} tw="ml-2">
                {item.label}
              </Label>
            </FormInline>
          ))}
        </FormGroup>
      </FormGroup>
      <FormGroup tw="mt-4">
        <div tw="text-sm font-semibold my-2">Expressive</div>
        <FormGroup type="inline">
          {normalInappropriateOptions.map(item => (
            <FormInline key={item.value} tw="mr-7 items-baseline">
              <Input
                id={`expressive-${item.value}`}
                type="radio"
                name="expressive"
                defaultChecked={formContent.language?.expressive === item.value}
                spacing="tight"
                value={item.value}
                onChange={handleLanguageChange}
              />
              <Label font="normal" htmlFor={`expressive-${item.value}`} tw="ml-2">
                {item.label}
              </Label>
            </FormInline>
          ))}
        </FormGroup>
      </FormGroup>
      <div tw="mt-8 mb-4 font-semibold text-lg border-b-2 border-gray-100 pb-1">Fluency</div>
      <FormGroup tw="mt-4">
        {normalInappropriateOptions.map(item => (
          <FormInline key={item.value} tw="mb-3 items-baseline">
            <Input
              id={`fluency-${item.value}`}
              type="radio"
              name="fluency"
              defaultChecked={formContent.fluency === item.value}
              spacing="tight"
              value={item.value}
              onChange={handleScreeningContentChange}
            />
            <Label font="normal" htmlFor={`fluency-${item.value}`} tw="ml-2">
              {item.label}
            </Label>
          </FormInline>
        ))}
        {formContent.fluency === 'inappropriate_for_age' && (
          <FormGroup type="inline" tw="ml-6">
            {inappropriateFluencyOptions.map(item => (
              <FormInline key={item.value} tw="mr-7 items-baseline">
                <Input
                  id={item.value}
                  type="checkbox"
                  name={item.value}
                  spacing="tight"
                  checked={formContent.inappropriateFluencyDetail?.includes(item.value)}
                  onChange={handleInappropriateFluencyDetailChange}
                />
                <Label htmlFor={item.value} font="normal" tw="ml-2">
                  {item.label}
                </Label>
              </FormInline>
            ))}
          </FormGroup>
        )}
      </FormGroup>
      <div tw="mt-8 mb-4 font-semibold text-lg border-b-2 border-gray-100 pb-1">Voice</div>
      <FormGroup tw="mt-4">
        {normalInappropriateOptions.map(item => (
          <FormInline key={item.value} tw="mb-3 items-baseline">
            <Input
              id={`voice-${item.value}`}
              type="radio"
              name="voice"
              defaultChecked={formContent.voice === item.value}
              spacing="tight"
              value={item.value}
              onChange={handleScreeningContentChange}
            />
            <Label font="normal" htmlFor={`voice-${item.value}`} tw="ml-2">
              {item.label}
            </Label>
          </FormInline>
        ))}
      </FormGroup>
      <div tw="mt-8 mb-4 font-semibold text-lg border-b-2 border-gray-100 pb-1">Recommendations and Next Steps</div>
      <FormGroup tw="mt-4">
        <div tw="text-sm font-semibold my-2">Overall Impressions</div>
        <FormGroup type="inline">
          {overallImpressionsOptions.map(item => (
            <FormInline key={item.value} tw="mr-7 items-baseline">
              <Input
                id={`overallImpressions-${item.value}`}
                type="radio"
                name="overallImpressions"
                defaultChecked={formContent.recommendations?.overallImpressions === item.value}
                spacing="tight"
                value={item.value}
                onChange={handleRecommendationsChange}
              />
              <Label font="normal" htmlFor={`overallImpressions-${item.value}`} tw="ml-2">
                {item.label}
              </Label>
            </FormInline>
          ))}
        </FormGroup>
      </FormGroup>
      <FormGroup tw="mt-4">
        <div tw="text-sm font-semibold my-2">Recommendation</div>
        <FormGroup type="inline">
          {recommendationOptions.map(item => (
            <FormInline key={item.value} tw="mr-7 items-baseline">
              <Input
                id={`recommendation-${item.value}`}
                type="radio"
                name="recommendation"
                defaultChecked={formContent.recommendations?.recommendation === item.value}
                spacing="tight"
                value={item.value}
                onChange={handleRecommendationsChange}
              />
              <Label font="normal" htmlFor={`recommendation-${item.value}`} tw="ml-2">
                {item.label}
              </Label>
            </FormInline>
          ))}
        </FormGroup>
      </FormGroup>
      <FormGroup tw="mt-4">
        <div tw="text-sm font-semibold my-2">Clinical Rationale for Recommendations</div>
        <Textarea
          data-testid="clinicalRationaleForRecommendations"
          name="clinicalRationaleForRecommendations"
          value={formContent.recommendations?.clinicalRationaleForRecommendations}
          ref={focusElementRef}
          onChange={handleRecommendationsChange}
          tw="mt-2 w-full"
          rows={4}
        />
      </FormGroup>
      <FormGroup tw="mt-4">
        <div tw="text-sm font-semibold my-2">Additional Comments</div>
        <Textarea
          data-testid="additionalComments"
          name="additionalComments"
          value={formContent.recommendations?.additionalComments}
          ref={focusElementRef}
          onChange={handleRecommendationsChange}
          tw="mt-2 w-full"
          rows={4}
        />
      </FormGroup>
      <FormGroup tw="mt-4">
        <div tw="text-sm font-semibold my-2">Specialties Required</div>
        <div data-testid="specialtiesRequired">
          <Select
            // eslint-disable-next-line
            styles={{ menuPortal: (base: any) => ({ ...base, zIndex: 50 }) }}
            menuPortalTarget={document.body}
            name="specialtiesRequired"
            value={formContent.recommendations?.specialtiesRequired}
            options={specialties}
            isSearchable
            isMulti
            tw="w-full md:w-2/3 lg:w-1/2"
            getOptionLabel={(item: string) => item}
            getOptionValue={(item: string) => item}
            onChange={handleSpecialtiesRequiredChange}
          />
        </div>
      </FormGroup>
    </>
  );
};
