import { TimeSlot } from 'components/therapistMatcher/data';
import { AppointmentType, SessionType } from 'domain/appointment/constants';

import usStatesOptions from 'utils/us-states.json';
import statesWithMultipleTimezones from 'utils/us-states-with-multiple-timezones.json';
import { ClientMatchingState } from './client-matching-ui';

export const getSelectedTimeSlot = (
  appointmentType: string,
  timeSlot: TimeSlot | null,
  sessionType?: SessionType,
): ClientMatchingState => {
  if (appointmentType === AppointmentType.Therapy && sessionType && timeSlot) {
    return {
      appointmentType,
      sessionType,
      slot: {
        timeSlot,
      },
    };
  } else if (appointmentType === AppointmentType.Evaluation && timeSlot) {
    return {
      appointmentType,
      slot: {
        timeSlot,
      },
    };
  }
  return null;
};


export const usStatesOptionsWithMultipleTimezones = usStatesOptions.map(state => {
  if (statesWithMultipleTimezones.includes(state.value)) {
    return { ...state, hasMultipleTimeZone: true };
  }
  return state;
});
