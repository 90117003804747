import React from 'react';
import 'twin.macro';
import Sidebar from './therapist-sidebar';
import { CompleteTherapistInformation } from '../types';
import TherapistClients from './therapist-clients';

export interface TherapistOverviewProps {
  therapistId: string;
  therapistData: CompleteTherapistInformation;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
}

export default function TherapistOverview({ setIndex, therapistId, therapistData }: TherapistOverviewProps) {
  return (
    <>
      <div tw="md:flex">
        <div tw="md:w-3/4 md:pr-5">
          <TherapistClients therapistEmail={therapistData.therapistEmail} />
        </div>
        {therapistData && (
          <div tw="md:w-1/4 px-5">
            <Sidebar setIndex={setIndex} therapistData={therapistData} />
          </div>
        )}
      </div>
    </>
  );
}
