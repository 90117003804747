import { FormGroup, Label } from '@expressable/ui-library';
import TherapistsTimeSelector from 'components/therapistMatcher/TherapistTimeGrid';
import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';
import { Therapist } from 'types';
import 'twin.macro';
import DatePicker, { isInclusivelyBeforeDay } from 'components/Forms/DatePicker';

export interface SessionAvailabilityProps {
  therapists: Therapist[];
  onSkip?: () => void;
}

const SessionAvailability = ({ therapists, onSkip }: SessionAvailabilityProps) => {
  const [startDateFocused, setStartDateFocused] = useState(false);
  const twoDaysFromNow = dayjs().add(2, 'days');
  const [startDate, setStartDate] = useState<Dayjs | null>(null);

  return (
    <div tw="mb-8 flex flex-col gap-4">
      <div>
        <FormGroup tw="flex-grow md:flex-grow-0">
          <Label htmlFor="startDate" tw="font-semibold text-sm mb-2">
            Start Date
          </Label>
          <div tw="w-40">
            <DatePicker
              noBorder
              block
              openDirection="down"
              numberOfMonths={1}
              small
              date={startDate}
              onDateChange={dateValue => {
                if (dateValue) {
                  setStartDate(dateValue);
                }
              }}
              hideKeyboardShortcutsPanel
              focused={startDateFocused}
              onFocusChange={({ focused }) => setStartDateFocused(focused)}
              id="startDate"
              isOutsideRange={day => !isInclusivelyBeforeDay(twoDaysFromNow, day)}
            />
          </div>
        </FormGroup>
      </div>
      {startDate && (
        <div>
          <TherapistsTimeSelector type="session" duration={30} therapists={therapists} dateFilter={startDate} />
        </div>
      )}
    </div>
  );
};

export default SessionAvailability;
