import { useContext } from 'react';
import { FieldValues } from 'react-hook-form';
import { ObjectSchema } from 'yup';
import { ContextType, FormValidationContext } from './context';

export const useFormValidation = <T extends ObjectSchema<FieldValues>>(): ContextType<T> => {
  const ctx = useContext(FormValidationContext as unknown as React.Context<ContextType<T>>);

  /* istanbul ignore if */
  if (!ctx) {
    throw new Error(
      'useFormValidation must be called from a descendant of a <Form /> component that supplies the associated context provider',
    );
  }

  return ctx;
};
