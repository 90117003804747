import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { faFileSlash } from '@fortawesome/pro-solid-svg-icons';
import { EventProps } from 'domain/admin/types';
import { SessionTypeEnum } from 'domain/admin/constants';
import 'twin.macro';
import { sessionTypeLabelSwitch } from './note-created';

const NoteDeleted = (event: EventProps) => {
  const firehoseEvent = event?.event;
  const isLastEvent = event?.isLastEvent;

  return (
    <div data-testid="firehose-note-deleted" tw="relative flex mt-7 px-2">
      {!isLastEvent && <span tw="absolute -z-1 top-7 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>}
      <div tw="flex items-baseline">
        <div tw="flex items-center bg-red-100 rounded-full h-6 w-6">
          <FontAwesomeIcon icon={faFileSlash} tw="mx-1.5 h-3 text-red-700" />
        </div>
        <div tw="pl-4 text-sm">
          <div tw="lg:flex font-semibold pb-1 text-indigo-700">
            <Link to={{ pathname: `/therapists/${firehoseEvent?.therapistEmail}` }}>
              {firehoseEvent?.therapistFirstName} {firehoseEvent?.therapistLastName}&nbsp;
            </Link>
            <span tw="text-gray-500">{`deleted ${sessionTypeLabelSwitch(
              firehoseEvent.sessionType,
            )} note for the client`}</span>
            &nbsp;
            <Link tw="text-indigo-700" to={{ pathname: `/clients/${firehoseEvent?.detail.clientID}` }}>
              {firehoseEvent?.clientFirstName} {firehoseEvent?.clientLastName}&nbsp;
            </Link>
            <span tw="text-gray-500">{`at ${dayjs(firehoseEvent?.createdAt as string).format('h:mm A')}, ${dayjs(
              firehoseEvent?.createdAt as string,
            ).format('MM/DD/YYYY')} `}</span>
          </div>
          {firehoseEvent.sessionType === SessionTypeEnum.evaluation ||
            (firehoseEvent.sessionType === SessionTypeEnum.session ? (
              <div>{`Appointment: ${dayjs(firehoseEvent?.appointmentOn as string).format('MM/DD/YYYY - h:mm A')}`}</div>
            ) : null)}
        </div>
      </div>
    </div>
  );
};

export default NoteDeleted;
