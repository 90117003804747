import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHourglass3 } from '@fortawesome/pro-solid-svg-icons';
import { EventProps } from 'domain/admin/types';
import { Loader } from '@expressable/ui-library';
import 'twin.macro';

const EventFallback = (event: EventProps) => {
  const firehoseEvent = event?.event;
  const isLastEvent = event?.isLastEvent;

  if (firehoseEvent?.eventType === undefined) {
    return (
      <div data-testid="event-fallback-loading" tw="flex-1 text-center py-20">
        <Loader type="ring" />
      </div>
    );
  }

  return (
    <div data-testid="event-fallback" tw="relative flex mt-7 px-2">
      {!isLastEvent && <span tw="absolute -z-1 top-7 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>}
      <div tw="flex items-baseline">
        <div tw="flex items-center bg-gray-200 rounded-full h-6 w-6">
          <FontAwesomeIcon icon={faHourglass3} tw="mx-1.5 h-3 text-indigo-700 ml-2" />
        </div>
        <div tw="pl-4 text-sm">
          <div tw="lg:flex font-semibold pb-1">
            <div>
              <span tw="text-gray-500">{`Event display coming soon: ${firehoseEvent?.eventType}`}</span>
            </div>
            &nbsp;
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventFallback;
