import { Select } from '@expressable/ui-library';
import React from 'react';
import { Control, Controller, FieldError, FieldValues, Path } from 'react-hook-form';
import { SelectOption } from 'types';

//FIXME: move to @expressable/ui-library
export interface RHFSelectBaseProps<T> {
  name: string;
  control: Control<T>;
  options?: SelectOption[];
  error?: FieldError;
  placeholder?: string;
  defautValue?: SelectOption;
  isSearchable?: boolean;
  required?: boolean;
  isMulti?: boolean;
}
/**
 * @title RHFSelectBase
 * @desc A Select component controlled by a react-hook-form Controller instance
 */
export default function RHFSelect<T>({
  name,
  control,
  options,
  error,
  placeholder,
  defautValue,
  isSearchable,
  required,
  isMulti,
}: RHFSelectBaseProps<T>) {
  const [state, setState] = React.useState<SelectOption>();

  return (
    <Controller
      name={name}
      control={control as Control<FieldValues>}
      render={({ field }) => (
        <Select
          {...field}
          options={options}
          isSearchable={isSearchable}
          placeholder={placeholder}
          error={error}
          value={state}
          isMulti={isMulti}
          onChange={(selection: SelectOption) => {
            field.onChange(selection.value);
            setState(selection);
          }}
        />
      )}
      defaultValue={defautValue}
      rules={{ required: required }}
    />
  );
}

interface RHFSelectBaseMulti<T> {
  name: string;
  control: Control<T>;
  options?: SelectOption[];
  placeholder?: string;
  defaultValue?: SelectOption[];
  disabled?: boolean;
}

export function RHFSelectMultiple<T>({
  name,
  control,
  options,
  placeholder,
  defaultValue,
  disabled,
}: RHFSelectBaseMulti<T>) {
  return (
    <Controller
      name={name as Path<T>}
      control={control}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      defaultValue={defaultValue ?? ([] as any)}
      render={({ field }) => (
        <Select disabled={disabled} {...field} options={options} isMulti placeholder={placeholder ?? '-'} />
      )}
    />
  );
}
