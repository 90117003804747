export const stateToEntity: { [state: string]: string } = {
  alabama: 'tpc',
  alaska: 'tpc',
  arizona: 'tpc',
  arkansas: 'tpc',
  california: 'slp',
  colorado: 'tpc',
  connecticut: 'tpc',
  delaware: 'tpc',
  'district of columbia': 'tpc',
  florida: 'slp',
  georgia: 'tpc',
  hawaii: 'tpc',
  idaho: 'tpc',
  illinois: 'slp',
  indiana: 'tpc',
  iowa: 'tpc',
  kansas: 'tpc',
  kentucky: 'tpc',
  louisiana: 'tpc',
  maine: 'tpc',
  maryland: 'tpc',
  massachusetts: 'tpc',
  michigan: 'tpc',
  minnesota: 'tpc',
  mississippi: 'tpc',
  missouri: 'tpc',
  montana: 'tpc',
  nebraska: 'tpc',
  nevada: 'tpc',
  'new hampshire': 'tpc',
  'new jersey': 'tnj',
  'new mexico': 'tpc',
  'new york': 'tpc',
  'north carolina': 'slp',
  'north dakota': 'tpc',
  ohio: 'slp',
  oklahoma: 'tpc',
  oregon: 'tpc',
  pennsylvania: 'tpc',
  'rhode island': 'tpc',
  'south carolina': 'tpc',
  'south dakota': 'tpc',
  tennessee: 'tpc',
  texas: 'slp',
  utah: 'tpc',
  vermont: 'tpc',
  virginia: 'tpc',
  washington: 'tpc',
  'west virginia': 'tpc',
  wisconsin: 'tpc',
  wyoming: 'tpc',
};

export function getEntityFromState(state?: string) {
  return state ? stateToEntity[state?.toLowerCase()] : null;
}
