import React, { useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import useTherapist from '../hooks/use-therapist';
import useModifyTherapist from 'hooks/use-modify-therapist-info';
import { CompleteTherapistInformation } from 'types';
import TherapistOverview from '../components/therapist-overview';
import EditTherapistInfoForm from '../components/edit-therapist-info-form';
import { Container, Button, Tab, TabList, TabPanel, TabPanels, Tabs, TitleBar, Loader } from '@expressable/ui-library';
import { Helmet } from 'react-helmet';
import TherapistHoursReportingHub from 'components/therapist-hours-reporting-hub';
import { TherapistTypes } from 'domain/therapist/therapistTypes';
import usePermissions from 'hooks/use-permissions';
import { useCurrentUserContext } from '@expressable/utils';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PendingReschedules from './admin/components/pending-reschedules';
import Authorizations from './admin/components/authorizations';
import 'twin.macro';
import TherapistRateLimiting from 'components/therapist-rate-limiting';
import ScheduleConflicts from './therapist/components/schedule-conflicts';

enum TherapistTab {
  Overview = 0,
  TherapistInfo = 1,
  PendingReschedule = 2,
  HoursReportingHub = 3,
  Authorizations = 4,
  ScheduleConflicts = 5,
}

const Therapist = (props: RouteComponentProps<{ therapistId: string }>) => {
  const {
    match: {
      params: { therapistId },
    },
    location: { hash },
  } = props;

  const { data: therapistData, isLoading } = useTherapist(therapistId);
  const { mutate: modifyTherapist, isLoading: isModifyTherapistLoading } = useModifyTherapist();
  const { isAdminOrClinicalManager } = usePermissions();
  const user = useCurrentUserContext();
  const { cueHoursReportingHub, cueEnableScheduleConflicts } = useFlags();

  const canAccessToHoursHub = useMemo(() => {
    if (
      cueHoursReportingHub &&
      isAdminOrClinicalManager &&
      Boolean(
        !therapistData?.therapistType ||
          therapistData.therapistType === TherapistTypes.Salary ||
          therapistData.therapistType === TherapistTypes.Hourly,
      )
    )
      return true;

    if (therapistData?.therapistType) {
      if (
        Boolean(
          !therapistData.therapistType ||
            therapistData.therapistType === TherapistTypes.Salary ||
            therapistData.therapistType === TherapistTypes.Hourly,
        ) &&
        cueHoursReportingHub
      ) {
        if (user?.email === therapistId) return true;
        return false;
      }
      return false;
    }
  }, [
    isAdminOrClinicalManager,
    user?.email,
    therapistData?.therapistType,
    therapistId,
    cueHoursReportingHub,
    cueEnableScheduleConflicts,
  ]);

  const tabHashes = [
    '#overview',
    '#therapist-info',
    '#pending-reschedules',
    '#therapist-time-hub',
    '#authorizations',
    '#schedule-conflicts',
  ];

  const tabindex = tabHashes.indexOf(hash);
  const [index, setIndex] = useState<TherapistTab>(tabindex < 0 ? TherapistTab.Overview : tabindex);
  const history = useHistory();

  useEffect(() => {
    if (canAccessToHoursHub) {
      if (hash === '#therapist-time-hub') {
        setIndex(tabHashes.indexOf(hash));
      }
    }
    if (cueEnableScheduleConflicts) {
      if (hash === '#schedule-conflicts') {
        setIndex(tabHashes.indexOf(hash));
      }
    }
    if (tabindex === -1) {
      setIndex(TherapistTab.Overview);
    } else {
      setIndex(tabindex);
    }
  }, [canAccessToHoursHub]);

  if (isLoading) {
    return (
      <div tw="h-screen flex justify-center items-center">
        <Loader type="ring" />
      </div>
    );
  }

  const onSave = (therapistInformation: CompleteTherapistInformation) => {
    modifyTherapist({
      therapistInformation,
      therapistId,
    });
  };

  return (
    <div data-testid="therapist" css={{ marginBottom: 28 }}>
      {therapistData && (
        <>
          <Helmet title={`${therapistData.firstName} ${therapistData.lastName}'s Profile`} />
          <TitleBar title={`${therapistData.firstName} ${therapistData.lastName}`} />
          <Container size="large">
            <Tabs
              index={index}
              onChange={index => {
                setIndex(index);
                // hacky way to update the hash in the url on mobile
                if (window.innerWidth < 1305)
                  history.push(`/therapists/${therapistData.therapistEmail}${tabHashes[index]}`);
              }}
              defaultIndex={tabindex}
            >
              <TabList tw="mt-4 mb-10">
                <Tab id="overview">Overview</Tab>
                <Tab data-testid="therapistInfo" id="therapist-info">
                  Therapist Information
                </Tab>
                <Tab data-testid="pendingReschedules" id="pending-reschedules">
                  Pending Reschedules
                </Tab>
                <Tab id="therapist-time-hub">Hours Reporting Hub</Tab>
                <Tab id="authorizations">Authorizations</Tab>
                <Tab id="schedule-conflicts">Schedule Conflicts</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <TherapistOverview setIndex={setIndex} therapistId={therapistId} therapistData={therapistData} />
                </TabPanel>
                <TabPanel>
                  <EditTherapistInfoForm
                    therapistData={therapistData}
                    onSave={onSave}
                    isEmailDisabled
                    isLoading={isModifyTherapistLoading}
                  />
                  <TherapistRateLimiting therapistEmail={therapistData.therapistEmail} />
                </TabPanel>
                <TabPanel>
                  <PendingReschedules
                    isAdminPage={false}
                    isClientPage={false}
                    isTherapistPage={true}
                    therapistId={therapistId}
                  />
                </TabPanel>
                <TabPanel>
                  {canAccessToHoursHub ? (
                    <TherapistHoursReportingHub therapistData={therapistData} />
                  ) : (
                    <div>
                      Sorry, you don’t have access to this page. Please reach out to the help center if you need access.
                    </div>
                  )}
                </TabPanel>
                <TabPanel>
                  <Authorizations isAdminPage={false} isTherapistPage={true} therapistId={therapistId} />
                </TabPanel>
                <TabPanel>
                  {cueEnableScheduleConflicts ? (
                    <ScheduleConflicts therapistId={therapistId} />
                  ) : (
                    <div>
                      Sorry, you don’t have access to this page. Please reach out to the help center if you need access.
                    </div>
                  )}
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Container>
        </>
      )}
      {!therapistData && !isLoading && (
        <div tw="relative">
          <TitleBar title="Therapists" />
          <Container size="large" tw="pt-12">
            <div tw="bg-gray-200 m-auto p-28">
              <div tw="text-center">
                <p tw="text-3xl text-gray-400 mb-4">We cannot find that therapist</p>
                <Button variant="secondary" onClick={() => props.history.push(`/therapists`)}>
                  Back to Therapists
                </Button>
              </div>
            </div>
          </Container>
        </div>
      )}
    </div>
  );
};

export default Therapist;
