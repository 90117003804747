import React, { useEffect, useState } from 'react';
import { Card, Container, FormGroup, Label, Link, Loader, Select } from '@expressable/ui-library';
import useTherapists from 'hooks/use-therapists';
import { SelectOption } from 'types';
import Table from 'components/Table';
import { usePagination } from 'hooks/use-pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import {
  useAwaitingAuthorizations,
  useExpiringByVisitCountAuthorizations,
  useExpiringSoonAuthorizations,
} from 'hooks/use-authorizations';
import { AuthorizationsProps } from 'domain/authorizations/types';
import cx from 'classnames';
import dayjs from 'dayjs';
import 'twin.macro';

const Authorizations = (props: AuthorizationsProps) => {
  const { isAdminPage, isTherapistPage, therapistId } = props;
  const [awaitingTherapistSelected, setAwaitingTherapistSelected] = useState<{ [key: string]: string } | any>({
    value: 'all',
    label: 'All Therapists',
  });
  const [awaitingSessionsTherapistSelected, setAwaitingSessionsTherapistSelected] = React.useState<
    { [key: string]: string } | any
  >({
    value: 'all',
    label: 'All Therapists',
  });

  const [expiringSoonTherapistSelected, setExpiringSoonTherapistSelected] = React.useState<
    { [key: string]: string } | any
  >({
    value: 'all',
    label: 'All Therapists',
  });

  const [expiringByVisitCountTherapistSelected, setExpiringByVisitCountTherapistSelected] = React.useState<
    { [key: string]: string } | any
  >({
    value: 'all',
    label: 'All Therapists',
  });

  const {
    data: awaitingEvalAuthorizations,
    isLoading: isLoadingAwaitingEvalsAuthorizations,
    isError: isAwaitingAuthorizationsEvalsError,
  } = useAwaitingAuthorizations('evaluations', awaitingTherapistSelected.value);

  const {
    data: awaitingSessionsAuthorizations,
    isLoading: isLoadingAwaitingSessionsAuthorizations,
    isError: isAwaitingAuthorizationsSessionsError,
  } = useAwaitingAuthorizations('sessions', awaitingSessionsTherapistSelected.value);
  const {
    data: expiringSoonAuthorizations,
    isLoading: isLoadingExpiringSoonAuthorizations,
    isError: isExpiringSoonAuthorizationsError,
  } = useExpiringSoonAuthorizations(expiringSoonTherapistSelected.value);

  const {
    data: expiringByVisitCountAuthorizations,
    isLoading: isLoadingExpiringByVisitCountAuthorizations,
    isError: isExpiringByVisitCountAuthorizationsError,
  } = useExpiringByVisitCountAuthorizations(expiringByVisitCountTherapistSelected.value);

  const { data: therapistsList } = useTherapists();
  const therapistsSelectOptions: SelectOption[] | undefined = therapistsList?.map(therapist => ({
    label: therapist.therapistName,
    value: therapist.therapistID,
  }));

  const therapistsOptions = therapistsSelectOptions
    ? [{ value: 'all', label: 'All Therapists' }, ...therapistsSelectOptions]
    : [{ value: 'all', label: 'All Therapists' }];

  useEffect(() => {
    if (isTherapistPage && therapistId) {
      const therapistSelectedObject = {
        label: therapistId,
        value: therapistId,
      };
      setAwaitingTherapistSelected(therapistSelectedObject);
      setAwaitingSessionsTherapistSelected(therapistSelectedObject);
      setExpiringSoonTherapistSelected(therapistSelectedObject);
      setExpiringByVisitCountTherapistSelected(therapistSelectedObject);
    }
  }, [isTherapistPage, therapistId]);

  const awaitingEvalAuthorizationsPaginatedEntries = usePagination(awaitingEvalAuthorizations, {
    perPage: 10,
  });

  const awaitingSessionsAuthorizationsPaginatedEntries = usePagination(awaitingSessionsAuthorizations, {
    perPage: 10,
  });

  const expiringSoonAuthorizationsPaginatedEntries = usePagination(expiringSoonAuthorizations, {
    perPage: 10,
  });

  const expiringByVisitCountAuthorizationsPaginatedEntries = usePagination(expiringByVisitCountAuthorizations, {
    perPage: 10,
  });

  if (
    isAwaitingAuthorizationsSessionsError ||
    isExpiringSoonAuthorizationsError ||
    isExpiringByVisitCountAuthorizationsError ||
    isAwaitingAuthorizationsEvalsError
  ) {
    return (
      <Container data-testid="authorizations-error" tw="relative pl-0" size="large">
        <div data-testid="authorizations-error-title" tw="flex justify-between items-center flex-wrap">
          <h2 tw="text-2xl font-semibold mb-3">Authorizations</h2>
        </div>
        <Card tw="mb-4 px-8 mt-8">
          <FormGroup tw="w-full sm:w-1/2 md:w-1/3">
            <Label>
              <FontAwesomeIcon icon={faExclamationTriangle} tw="text-red-500 mr-2" />
              <span tw="text-red-500">Authorizations are not available at this time.</span>
            </Label>
          </FormGroup>
        </Card>
      </Container>
    );
  }

  return (
    <div data-testid="authorizations">
      <div tw="md:pr-5" data-testid="awaiting-authorizations-evals">
        <div tw="flex justify-between items-center flex-wrap">
          <h2 tw="text-2xl font-semibold mb-3">Awaiting Authorizations (Evaluations)</h2>
        </div>
        <Card tw="mt-2">
          {isLoadingAwaitingEvalsAuthorizations ? (
            <div data-testid="awaitingAuthorizations-loader" tw="flex-1 text-center py-20">
              <Loader type="ring" />
            </div>
          ) : (
            <>
              {isAdminPage && (
                <div tw="mb-4 sm:mb-0">
                  <div tw="flex flex-col sm:flex-row mb-4">
                    <>
                      <span tw="text-sm font-semibold mt-6 ml-2 hidden sm:block">View</span>
                      <div tw="flex items-center sm:items-stretch justify-items-stretch mr-2 ml-2 mt-4 font-normal">
                        <span tw="text-sm font-semibold mr-2 block sm:hidden">View</span>
                        <Select
                          tw="w-full sm:w-96"
                          id="awaitingAuthorizationTherapist"
                          placeholder=""
                          spacing="tight"
                          value={awaitingTherapistSelected}
                          options={therapistsOptions}
                          onChange={(selectOption: { [key: string]: string }) => {
                            setAwaitingTherapistSelected(selectOption);
                          }}
                        />
                      </div>
                      <span
                        onClick={() => {
                          const defaultOption = { value: 'all', label: 'All Therapists' };
                          setAwaitingTherapistSelected(defaultOption);
                        }}
                        className={cx('text-sm ml-3 mt-6 text-indigo-700 font-normal hidden sm:block', {
                          'opacity-100': awaitingTherapistSelected.value !== 'all',
                          'opacity-50': awaitingTherapistSelected.value === 'all',
                          'cursor-pointer': awaitingTherapistSelected.value !== 'all',
                        })}
                      >
                        View All Therapists
                      </span>
                    </>
                  </div>
                </div>
              )}
              {awaitingEvalAuthorizationsPaginatedEntries.items?.length ? (
                <>
                  <Table>
                    <Table.Header>
                      <Table.Row>
                        <Table.Cell>Client Name</Table.Cell>

                        <Table.Cell>Evaluation Date</Table.Cell>
                        {!isTherapistPage && <Table.Cell tw="hidden sm:table-cell">Therapist</Table.Cell>}
                        <Table.Cell tw="hidden sm:table-cell"></Table.Cell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {awaitingEvalAuthorizationsPaginatedEntries.items?.map((authorizations, index) => (
                        <Table.Row key={index}>
                          <Table.Cell tw="text-indigo-700 font-semibold text-sm md:text-base">
                            <Link target="_blank" to={{ pathname: `/clients/${authorizations.clientID}` }}>
                              {authorizations.clientName}&nbsp;
                            </Link>
                          </Table.Cell>
                          <Table.Cell tw="text-sm md:text-base">
                            {authorizations?.evaluationDate === '' ? (
                              <>-</>
                            ) : (
                              <>
                                {dayjs(authorizations?.evaluationDate).isSameOrBefore(dayjs(), 'day') ? (
                                  <span tw="text-red-500">
                                    {dayjs(authorizations.evaluationDate).format('MM/DD/YYYY')}
                                  </span>
                                ) : (
                                  <span>{dayjs(authorizations.evaluationDate).format('MM/DD/YYYY')}</span>
                                )}
                              </>
                            )}
                          </Table.Cell>
                          {!isTherapistPage && (
                            <Table.Cell tw="text-indigo-700 font-semibold text-sm md:text-base hidden sm:table-cell">
                              <Link
                                target="_blank"
                                to={{
                                  pathname: `/therapists/${authorizations.therapistEmail}/#authorizations`,
                                }}
                              >
                                {authorizations?.therapist}&nbsp;
                              </Link>
                            </Table.Cell>
                          )}
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                  <div tw="font-medium flex mt-5 justify-center sm:justify-end">
                    <span tw="mr-2">{`Showing ${awaitingEvalAuthorizationsPaginatedEntries.pageStartIdx + 1} to ${
                      awaitingEvalAuthorizationsPaginatedEntries.pageEndIdx
                    } of ${awaitingEvalAuthorizationsPaginatedEntries.totalItems}   ${
                      awaitingEvalAuthorizationsPaginatedEntries.totalItems === 1 ? 'result' : 'results'
                    }`}</span>
                    {awaitingEvalAuthorizationsPaginatedEntries.page !== 0 && (
                      <span data-testid="awaiting-authorization-pagination-left-arrow">
                        <FontAwesomeIcon
                          tw="text-indigo-700 ml-2 mr-2 cursor-pointer flex mt-2 text-xs"
                          icon={faChevronLeft}
                          onClick={awaitingEvalAuthorizationsPaginatedEntries.prev}
                        />
                      </span>
                    )}
                    {awaitingEvalAuthorizationsPaginatedEntries.page !==
                      awaitingEvalAuthorizationsPaginatedEntries.totalPages - 1 && (
                      <span data-testid="awaiting-authorization-pagination-right-arrow">
                        <FontAwesomeIcon
                          tw="text-indigo-700 ml-2 mr-2 cursor-pointer flex mt-2 text-xs"
                          icon={faChevronRight}
                          onClick={awaitingEvalAuthorizationsPaginatedEntries.next}
                        />
                      </span>
                    )}
                  </div>
                </>
              ) : (
                <span tw="ml-2">There are no awaiting authorizations (Evaluations) to show.</span>
              )}
            </>
          )}
        </Card>
      </div>

      <div tw="md:pr-5 mt-8" data-testid="awaiting-authorizations">
        <div tw="flex justify-between items-center flex-wrap">
          <h2 tw="text-2xl font-semibold mb-3">Awaiting Authorizations (Sessions)</h2>
        </div>
        <Card tw="mt-2">
          {isLoadingAwaitingSessionsAuthorizations ? (
            <div data-testid="awaitingAuthorizations-loader" tw="flex-1 text-center py-20">
              <Loader type="ring" />
            </div>
          ) : (
            <>
              {isAdminPage && (
                <div tw="mb-4 sm:mb-0">
                  <div tw="flex flex-col sm:flex-row mb-4">
                    <>
                      <span tw="text-sm font-semibold mt-6 ml-2 hidden sm:block">View</span>
                      <div tw="flex items-center sm:items-stretch justify-items-stretch mr-2 ml-2 mt-4 font-normal">
                        <span tw="text-sm font-semibold mr-2 block sm:hidden">View</span>
                        <Select
                          tw="w-full sm:w-96"
                          id="awaitingAuthorizationTherapist"
                          placeholder=""
                          spacing="tight"
                          value={awaitingSessionsTherapistSelected}
                          options={therapistsOptions}
                          onChange={(selectOption: { [key: string]: string }) => {
                            setAwaitingSessionsTherapistSelected(selectOption);
                          }}
                        />
                      </div>
                      <span
                        onClick={() => {
                          const defaultOption = { value: 'all', label: 'All Therapists' };
                          setAwaitingSessionsTherapistSelected(defaultOption);
                        }}
                        className={cx('text-sm ml-3 mt-6 text-indigo-700 font-normal hidden sm:block', {
                          'opacity-100': awaitingSessionsTherapistSelected.value !== 'all',
                          'opacity-50': awaitingSessionsTherapistSelected.value === 'all',
                          'cursor-pointer': awaitingSessionsTherapistSelected.value !== 'all',
                        })}
                      >
                        View All Therapists
                      </span>
                    </>
                  </div>
                </div>
              )}
              {awaitingSessionsAuthorizationsPaginatedEntries.items?.length ? (
                <>
                  <Table>
                    <Table.Header>
                      <Table.Row>
                        <Table.Cell>Client Name</Table.Cell>
                        <Table.Cell>Next Visit Date</Table.Cell>
                        {!isTherapistPage && <Table.Cell tw="hidden sm:table-cell">Therapist</Table.Cell>}
                        <Table.Cell tw="hidden sm:table-cell"></Table.Cell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {awaitingSessionsAuthorizationsPaginatedEntries.items?.map((authorizations, index) => (
                        <Table.Row key={index}>
                          <Table.Cell tw="text-indigo-700 font-semibold text-sm md:text-base">
                            <Link target="_blank" to={{ pathname: `/clients/${authorizations.clientID}` }}>
                              {authorizations.clientName}&nbsp;
                            </Link>
                          </Table.Cell>
                          <Table.Cell tw="text-sm md:text-base">
                            {authorizations.nextVisitDate === '' ? (
                              <>-</>
                            ) : (
                              <>
                                {dayjs(authorizations.nextVisitDate).isSameOrBefore(dayjs(), 'day') ? (
                                  <span tw="text-red-500">
                                    {dayjs(authorizations.nextVisitDate).format('MM/DD/YYYY')}
                                  </span>
                                ) : (
                                  <span>{dayjs(authorizations.nextVisitDate).format('MM/DD/YYYY')}</span>
                                )}
                              </>
                            )}
                          </Table.Cell>
                          {!isTherapistPage && (
                            <Table.Cell tw="text-indigo-700 font-semibold text-sm md:text-base hidden sm:table-cell">
                              <Link
                                target="_blank"
                                to={{
                                  pathname: `/therapists/${authorizations.therapistEmail}/#authorizations`,
                                }}
                              >
                                {authorizations?.therapist}&nbsp;
                              </Link>
                            </Table.Cell>
                          )}
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                  <div tw="font-medium flex mt-5 justify-center sm:justify-end">
                    <span tw="mr-2">{`Showing ${awaitingSessionsAuthorizationsPaginatedEntries.pageStartIdx + 1} to ${
                      awaitingSessionsAuthorizationsPaginatedEntries.pageEndIdx
                    } of ${awaitingSessionsAuthorizationsPaginatedEntries.totalItems}   ${
                      awaitingSessionsAuthorizationsPaginatedEntries.totalItems === 1 ? 'result' : 'results'
                    }`}</span>
                    {awaitingSessionsAuthorizationsPaginatedEntries.page !== 0 && (
                      <span data-testid="awaiting-authorization-pagination-left-arrow">
                        <FontAwesomeIcon
                          tw="text-indigo-700 ml-2 mr-2 cursor-pointer flex mt-2 text-xs"
                          icon={faChevronLeft}
                          onClick={awaitingSessionsAuthorizationsPaginatedEntries.prev}
                        />
                      </span>
                    )}
                    {awaitingSessionsAuthorizationsPaginatedEntries.page !==
                      awaitingSessionsAuthorizationsPaginatedEntries.totalPages - 1 && (
                      <span data-testid="awaiting-authorization-pagination-right-arrow">
                        <FontAwesomeIcon
                          tw="text-indigo-700 ml-2 mr-2 cursor-pointer flex mt-2 text-xs"
                          icon={faChevronRight}
                          onClick={awaitingSessionsAuthorizationsPaginatedEntries.next}
                        />
                      </span>
                    )}
                  </div>
                </>
              ) : (
                <span tw="ml-2">There are no awaiting authorizations (Sessions) to show.</span>
              )}
            </>
          )}
        </Card>
      </div>

      <div tw="md:pr-5 mt-8" data-testid="expiring-soon-authorizations">
        <div tw="flex justify-between items-center flex-wrap">
          <h2 tw="text-2xl font-semibold mb-3">Expiring Soon (Visit Date)</h2>
        </div>
        <Card tw="mt-2">
          {isLoadingExpiringSoonAuthorizations ? (
            <div data-testid="expiringSoonAuthorizations-loader" tw="flex-1 text-center py-20">
              <Loader type="ring" />
            </div>
          ) : (
            <>
              {isAdminPage && (
                <div tw="mb-4 sm:mb-0">
                  <div tw="flex flex-col sm:flex-row mb-4">
                    <>
                      <span tw="text-sm font-semibold mt-6 ml-2 hidden sm:block">View</span>
                      <div tw="flex items-center sm:items-stretch justify-items-stretch mr-2 ml-2 mt-4 font-normal">
                        <span tw="text-sm font-semibold mr-2 block sm:hidden">View</span>
                        <Select
                          tw="w-full sm:w-96"
                          id="expiringSoonAuthorizationTherapist"
                          placeholder=""
                          spacing="tight"
                          value={expiringSoonTherapistSelected}
                          options={therapistsOptions}
                          onChange={(selectOption: { [key: string]: string }) => {
                            setExpiringSoonTherapistSelected(selectOption);
                          }}
                        />
                      </div>
                      <span
                        onClick={() => {
                          const defaultOption = { value: 'all', label: 'All Therapists' };
                          setExpiringSoonTherapistSelected(defaultOption);
                        }}
                        className={cx('text-sm ml-3 mt-6 text-indigo-700 font-normal hidden sm:block', {
                          'opacity-100': expiringSoonTherapistSelected.value !== 'all',
                          'opacity-50': expiringSoonTherapistSelected.value === 'all',
                          'cursor-pointer': expiringSoonTherapistSelected.value !== 'all',
                        })}
                      >
                        View All Therapists
                      </span>
                    </>
                  </div>
                </div>
              )}
              {expiringSoonAuthorizationsPaginatedEntries.items?.length ? (
                <>
                  <Table>
                    <Table.Header>
                      <Table.Row>
                        <Table.Cell>Client Name</Table.Cell>
                        <Table.Cell>Expires</Table.Cell>
                        {!isTherapistPage && <Table.Cell>Therapist</Table.Cell>}
                        <Table.Cell tw="hidden sm:table-cell"></Table.Cell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {expiringSoonAuthorizationsPaginatedEntries.items?.map((expiresSoonAuthorization, index) => (
                        <Table.Row key={index}>
                          <Table.Cell tw="text-indigo-700 font-semibold text-sm md:text-base">
                            <Link target="_blank" to={{ pathname: `/clients/${expiresSoonAuthorization.clientID}` }}>
                              {expiresSoonAuthorization.clientName}&nbsp;
                            </Link>
                          </Table.Cell>
                          <Table.Cell tw="text-sm md:text-base">
                            <>
                              {dayjs(expiresSoonAuthorization.expiresOn).isBefore(dayjs()) ? (
                                <span tw="text-red-500">
                                  {dayjs(expiresSoonAuthorization.expiresOn).format('MM/DD/YYYY')}
                                </span>
                              ) : (
                                <span>{dayjs(expiresSoonAuthorization.expiresOn).format('MM/DD/YYYY')}</span>
                              )}
                            </>
                          </Table.Cell>
                          {!isTherapistPage && (
                            <Table.Cell tw="text-indigo-700 font-semibold text-sm md:text-base">
                              <Link
                                target="_blank"
                                to={{
                                  pathname: `/therapists/${expiresSoonAuthorization.therapistEmail}/#authorizations`,
                                }}
                              >
                                {expiresSoonAuthorization?.therapist}&nbsp;
                              </Link>
                            </Table.Cell>
                          )}
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                  <div tw="font-medium flex mt-5 justify-center sm:justify-end">
                    <span tw="mr-2">{`Showing ${expiringSoonAuthorizationsPaginatedEntries.pageStartIdx + 1} to ${
                      expiringSoonAuthorizationsPaginatedEntries.pageEndIdx
                    } of ${expiringSoonAuthorizationsPaginatedEntries.totalItems}  ${
                      expiringSoonAuthorizationsPaginatedEntries.totalItems === 1 ? 'result' : 'results'
                    }`}</span>
                    {expiringSoonAuthorizationsPaginatedEntries.page !== 0 && (
                      <span data-testid="expiring-soon-authorization-pagination-left-arrow">
                        <FontAwesomeIcon
                          tw="text-indigo-700 ml-2 mr-2 cursor-pointer flex mt-2 text-xs"
                          icon={faChevronLeft}
                          onClick={expiringSoonAuthorizationsPaginatedEntries.prev}
                        />
                      </span>
                    )}
                    {expiringSoonAuthorizationsPaginatedEntries.page !==
                      expiringSoonAuthorizationsPaginatedEntries.totalPages - 1 && (
                      <span data-testid="expiring-soon-authorization-pagination-right-arrow">
                        <FontAwesomeIcon
                          tw="text-indigo-700 ml-2 mr-2 cursor-pointer flex mt-2 text-xs"
                          icon={faChevronRight}
                          onClick={expiringSoonAuthorizationsPaginatedEntries.next}
                        />
                      </span>
                    )}
                  </div>
                </>
              ) : (
                <span tw="ml-2">There are no expiring soon authorizations to show.</span>
              )}
            </>
          )}
        </Card>
      </div>

      <div tw="md:pr-5 mt-8" data-testid="expiring-by-visit-count-authorizations">
        <div tw="flex justify-between items-center flex-wrap">
          <h2 tw="text-2xl font-semibold mb-3">Expiring Soon (Visit Limits)</h2>
        </div>
        <Card tw="mt-2">
          {isLoadingExpiringByVisitCountAuthorizations ? (
            <div data-testid="expiringByVisitCountAuthorizations-loader" tw="flex-1 text-center py-20">
              <Loader type="ring" />
            </div>
          ) : (
            <>
              {isAdminPage && (
                <div tw="mb-4 sm:mb-0">
                  <div tw="flex flex-col sm:flex-row mb-4">
                    <>
                      <span tw="text-sm font-semibold mt-6 ml-2 hidden sm:block">View</span>
                      <div tw="flex items-center sm:items-stretch justify-items-stretch mr-2 ml-2 mt-4 font-normal">
                        <span tw="text-sm font-semibold mr-2 block sm:hidden">View</span>
                        <Select
                          tw="w-full sm:w-96"
                          id="expiringByVisitCountAuthorizationTherapist"
                          placeholder=""
                          spacing="tight"
                          value={expiringByVisitCountTherapistSelected}
                          options={therapistsOptions}
                          onChange={(selectOption: { [key: string]: string }) => {
                            setExpiringByVisitCountTherapistSelected(selectOption);
                          }}
                        />
                      </div>
                      <span
                        onClick={() => {
                          const defaultOption = { value: 'all', label: 'All Therapists' };
                          setExpiringByVisitCountTherapistSelected(defaultOption);
                        }}
                        className={cx('text-sm ml-3 mt-6 text-indigo-700 font-normal hidden sm:block', {
                          'opacity-100': expiringByVisitCountTherapistSelected.value !== 'all',
                          'opacity-50': expiringByVisitCountTherapistSelected.value === 'all',
                          'cursor-pointer': expiringByVisitCountTherapistSelected.value !== 'all',
                        })}
                      >
                        View All Therapists
                      </span>
                    </>
                  </div>
                </div>
              )}
              {expiringByVisitCountAuthorizationsPaginatedEntries.items?.length ? (
                <>
                  <Table>
                    <Table.Header>
                      <Table.Row>
                        <Table.Cell>Client Name</Table.Cell>
                        <Table.Cell>Visits Remaining</Table.Cell>
                        {!isTherapistPage && <Table.Cell>Therapist</Table.Cell>}
                        <Table.Cell tw="hidden sm:table-cell"></Table.Cell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {expiringByVisitCountAuthorizationsPaginatedEntries.items?.map(
                        (expiresByVisitCountAuthorization, index) => (
                          <Table.Row key={index}>
                            <Table.Cell tw="text-indigo-700 font-semibold text-sm md:text-base">
                              <Link
                                target="_blank"
                                to={{ pathname: `/clients/${expiresByVisitCountAuthorization.clientID}` }}
                              >
                                {expiresByVisitCountAuthorization.clientName}&nbsp;
                              </Link>
                            </Table.Cell>
                            <Table.Cell tw="text-sm md:text-base">
                              <>
                                {expiresByVisitCountAuthorization.visitsRemaining === 0 ? (
                                  <span tw="text-red-500">{expiresByVisitCountAuthorization.visitsRemaining}</span>
                                ) : (
                                  <span>{expiresByVisitCountAuthorization.visitsRemaining}</span>
                                )}
                              </>
                            </Table.Cell>
                            {!isTherapistPage && (
                              <Table.Cell tw="text-indigo-700 font-semibold text-sm md:text-base">
                                <Link
                                  target="_blank"
                                  to={{
                                    pathname: `/therapists/${expiresByVisitCountAuthorization.therapistEmail}/#authorizations`,
                                  }}
                                >
                                  {expiresByVisitCountAuthorization?.therapist}&nbsp;
                                </Link>
                              </Table.Cell>
                            )}
                          </Table.Row>
                        ),
                      )}
                    </Table.Body>
                  </Table>
                  <div tw="font-medium flex mt-5 justify-center sm:justify-end">
                    <span tw="mr-2">{`Showing ${
                      expiringByVisitCountAuthorizationsPaginatedEntries.pageStartIdx + 1
                    } to ${expiringByVisitCountAuthorizationsPaginatedEntries.pageEndIdx} of ${
                      expiringByVisitCountAuthorizationsPaginatedEntries.totalItems
                    }    ${
                      expiringByVisitCountAuthorizationsPaginatedEntries.totalItems === 1 ? 'result' : 'results'
                    }  `}</span>
                    {expiringByVisitCountAuthorizationsPaginatedEntries.page !== 0 && (
                      <span data-testid="expiring-by-visit-count-authorization-pagination-left-arrow">
                        <FontAwesomeIcon
                          tw="text-indigo-700 ml-2 mr-2 cursor-pointer flex mt-2 text-xs"
                          icon={faChevronLeft}
                          onClick={expiringByVisitCountAuthorizationsPaginatedEntries.prev}
                        />
                      </span>
                    )}
                    {expiringByVisitCountAuthorizationsPaginatedEntries.page !==
                      expiringByVisitCountAuthorizationsPaginatedEntries.totalPages - 1 && (
                      <span data-testid="expiring-by-visit-count-authorization-pagination-right-arrow">
                        <FontAwesomeIcon
                          tw="text-indigo-700 ml-2 mr-2 cursor-pointer flex mt-2 text-xs"
                          icon={faChevronRight}
                          onClick={expiringByVisitCountAuthorizationsPaginatedEntries.next}
                        />
                      </span>
                    )}
                  </div>
                </>
              ) : (
                <span tw="ml-2">There are no expiring by visit count authorizations to show.</span>
              )}
            </>
          )}
        </Card>
      </div>
    </div>
  );
};

export default Authorizations;
