import React, { useEffect, useState } from 'react';
import { Card, Container, FormGroup, Label, Loader, TitleBar } from '@expressable/ui-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

interface LicenseInfo {
  name: string;
  licenseType: string;
}

const Licenses = () => {
  const [licenses, setLicenses] = useState<LicenseInfo[]>([]);
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchLicenses = async () => {
      try {
        const response = await fetch('/licenses.json');
        if (!response.ok) {
          throw new Error('Failed to load licenses data with status code ' + response.status);
        }
        const data = await response.json();
        const formattedLicenses = Object.keys(data)
          .map(key => {
            const { licenses } = data[key];
            let cleanName = key.split('@')[0] === '' ? key.split('@')[1] : key.split('@')[0];
            cleanName = cleanName.startsWith('@') ? cleanName.slice(1) : cleanName;

            return {
              name: cleanName,
              licenseType: licenses,
            };
          })
          .filter(license => license.licenseType.includes('Apache'));

        setLicenses(formattedLicenses);
        setIsLoading(false);
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
        setIsError(true);
        setIsLoading(false);
      }
    };

    fetchLicenses();
  }, []);

  if (isError) {
    return (
      <div>
        <TitleBar title="Licenses" />
        <Container data-testid="licenses-error" className="relative pl-0" size="large">
          <Card className="mb-4 px-8 mt-8">
            <FormGroup className="w-full sm:w-1/2 md:w-1/3">
              <Label>
                <FontAwesomeIcon icon={faExclamationTriangle} className="text-red-500 mr-2" />
                <span className="text-red-500">Licenses are not available at this time.</span>
              </Label>
            </FormGroup>
          </Card>
        </Container>
      </div>
    );
  }

  return (
    <div>
      <TitleBar title="Licenses" />
      <div className="ml-9">
        {isLoading ? (
          <div data-testid="licenses-loader" className="flex-1 text-center py-20">
            <Loader type="ring" />
          </div>
        ) : (
          <div className="mx-4 mt-4">
            <ul className="list-none pl-5 space-y-4">
              {licenses.length === 0 ? (
                <Card className="mb-4 px-8 mt-8">
                  <FormGroup className="w-full sm:w-1/2 md:w-1/3">
                    <Label>
                      <FontAwesomeIcon icon={faExclamationTriangle} className="text-red-500 mr-2" />
                      <span className="text-red-500">Licenses are not available at this time.</span>
                    </Label>
                  </FormGroup>
                </Card>
              ) : (
                <>
                  {licenses.map((license, index) => (
                    <li key={index} className="py-2">
                      <p className="font-semibold">{license.name}</p>
                      <p>{license.licenseType}</p>
                      <p>
                        <a
                          href={'https://www.apache.org/licenses/LICENSE-2.0'}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-indigo-700 text-sm"
                        >
                          License
                        </a>
                      </p>
                    </li>
                  ))}
                </>
              )}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default Licenses;
