import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  CloseButton,
  Label,
  Card,
  ModalFooter,
  Button,
  Select,
  Input,
  FormInline,
} from '@expressable/ui-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'twin.macro';
import { ICompleteClientInformation, SelectOption } from 'types';
import { getAdlLink } from 'domain/clinical-pathways/contants';
import { RichText } from 'components/RichText';
import { ActivityOfDailyLivingContentful, ClientAssignment } from 'domain/clinical-pathways/types';

interface AddDailyRoutineActivityForm {
  learningPath: SelectOption<string>;
  practiceType: SelectOption<string>;
  targetAgeRange: string;
}

export interface Props {
  clientData?: ICompleteClientInformation;
  currentTherapyStrategies: ClientAssignment[];
  currentDailyRoutineActivities: ClientAssignment[];
  activitiesOfDailyLivingContentful: ActivityOfDailyLivingContentful[];
  isOpen: boolean;
  onClose: () => void;
  onSave: ({
    therapyStrategy,
    activityOfDailyLiving,
  }: {
    therapyStrategy: ClientAssignment;
    activityOfDailyLiving: ActivityOfDailyLivingContentful;
  }) => void;
}

export function AddDailyRoutineActivityModal({
  clientData,
  currentTherapyStrategies = [],
  currentDailyRoutineActivities = [],
  activitiesOfDailyLivingContentful = [],
  isOpen,
  onClose,
  onSave,
}: Props) {
  const [selectedADL, setSelectedADL] = useState<ActivityOfDailyLivingContentful>();
  const [filteredADLsContentful, setFilteredADLsContentful] = useState<ActivityOfDailyLivingContentful[]>([]);
  const isClientMinor = clientData?.isClientMinor;
  const targetAgeRange = isClientMinor ? 'Pediatric' : 'Adult';

  const {
    handleSubmit,
    reset,
    formState: { errors },
    control,
    watch,
    register,
    setValue,
  } = useForm<AddDailyRoutineActivityForm>({
    defaultValues: {
      learningPath: undefined,
      practiceType: undefined,
      targetAgeRange: targetAgeRange,
    },
  });

  const watchedLearningPath = watch('learningPath');
  const watchedPracticeType = watch('practiceType');
  const watchedTargetAgeRange = watch('targetAgeRange');

  const onSubmitHandler = async (formContent: AddDailyRoutineActivityForm) => {
    const { learningPath } = formContent;

    const targetTherapyStrategy = currentTherapyStrategies.find(item => item.learningPathContentfulEntryID === learningPath.value);
    if (!targetTherapyStrategy) {
      // safe checker to make sure nobody makes unexpected changes
      throw Error('Something went wrong. Learning Path not found!');
    }

    onSave({
      therapyStrategy: { ...targetTherapyStrategy },
      activityOfDailyLiving: selectedADL!,
    });

    onClose();
  };

  useEffect(() => {
    setValue('targetAgeRange', targetAgeRange);
    reset({
      learningPath: undefined,
      practiceType: undefined,
    });
    setFilteredADLsContentful([]);
    setSelectedADL(undefined);
  }, [isOpen]);

  useEffect(() => {
    setValue('learningPath', { label: 'Select...', value: 'Select...' });
    setValue('practiceType', { label: 'Select...', value: 'Select...' });
    setSelectedADL(undefined);
    setFilteredADLsContentful([]);
  }, [watchedTargetAgeRange]);

  useEffect(() => {
    if (watchedLearningPath && watchedLearningPath.value !== 'Select...') {
      // to define dropdown list options for Activity of Daily Life
      const currentADLs = currentDailyRoutineActivities
        .filter(item => item.learningPathContentfulEntryID === watchedLearningPath.value)
        .map(item => item.activityOfDailyLivingContentfulEntryID);
      const filteredContent = activitiesOfDailyLivingContentful.filter(
        item => !currentADLs.includes(item.id) && item.targetAgeRange === watchedTargetAgeRange,
      );

      setFilteredADLsContentful([...filteredContent]);
    }

    if (watchedLearningPath && watchedPracticeType) {
      const targetADL = activitiesOfDailyLivingContentful?.find(item => item.setting === watchedPracticeType.value);
      setSelectedADL(targetADL);
    } else {
      setSelectedADL(undefined);
    }
  }, [watchedLearningPath, watchedPracticeType]);

  return (
    <Modal portal isOpen={isOpen}>
      <ModalContent>
        <form onSubmit={handleSubmit(onSubmitHandler)} data-testid="form-daily-routine-activity">
          <ModalHeader>
            <div tw="flex flex-col items-center justify-center mb-8">
              <div tw="flex items-center justify-center w-12 h-12 mx-auto bg-indigo-100 rounded-full mb-5">
                <FontAwesomeIcon tw="text-2xl text-indigo-700" icon="file-alt" />
              </div>
              <h3 tw="text-lg text-center font-medium text-gray-900 leading-6 mb-5" id="modal-headline">
                Add Daily Routine Activity
              </h3>
              <h4 tw="text-gray-900 text-center">
                Select a therapy strategy and activity of daily life that you want your client to practice.
              </h4>
            </div>
            <CloseButton onClick={() => onClose()} testId="close-daily-routine-activity-top" />
          </ModalHeader>
          <ModalBody>
            <Label htmlFor="targetAgeRange" tw="font-medium text-sm mt-5">
              Select one
            </Label>
            <div tw="mt-2 mb-5">
              <FormInline tw="items-baseline mb-3">
                <Input
                  {...register('targetAgeRange')}
                  type="radio"
                  id="targetAgeRangePediatric"
                  name="targetAgeRange"
                  spacing="tight"
                  value={'Pediatric'}
                  defaultChecked={isClientMinor}
                />
                <Label htmlFor="targetAgeRangePediatric" tw="ml-2 font-normal">
                  Child
                </Label>
              </FormInline>
              <FormInline tw="items-baseline">
                <Input
                  {...register('targetAgeRange')}
                  type="radio"
                  id="targetAgeRangeAdult"
                  name="targetAgeRange"
                  spacing="tight"
                  value={'Adult'}
                  defaultChecked={!isClientMinor}
                />
                <Label htmlFor="targetAgeRangeAdult" tw="ml-2 font-normal">
                  Adult
                </Label>
              </FormInline>
            </div>
            <Label htmlFor="learningPath" tw="font-medium text-sm">
              Therapy Strategy
            </Label>
            <Controller
              name="learningPath"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  inputId="learningPath"
                  options={currentTherapyStrategies.map(item => {
                    return {
                      value: item.learningPathContentfulEntryID,
                      label: `${item.assignmentTitle} (${item.learningPathContentfulTreatmentArea || 'MISSING CATEGORY'})`,
                    } as SelectOption;
                  })}
                  isSearchable
                  error={errors.learningPath}
                />
              )}
              rules={{ required: true }}
            />

            <Label htmlFor="practiceType" tw="font-medium text-sm">
              Activity of Daily Life
            </Label>
            <Controller
              name="practiceType"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  inputId="practiceType"
                  options={filteredADLsContentful.map(item => {
                    return { label: item.setting, value: item.setting } as SelectOption;
                  })}
                  isSearchable
                  error={errors.practiceType}
                />
              )}
              rules={{ required: true }}
            />

            <div tw="mb-5">
              {selectedADL && watchedLearningPath ? (
                <div tw="mb-5 last:mb-0">
                  <Card tw="p-6 border border-gray-300">
                    <div tw="flex flex-col mb-3">
                      <Label tw="font-medium text-sm">
                        {watchedLearningPath.label} {selectedADL.setting}
                      </Label>
                      <div tw="mt-3 text-sm">
                        <RichText>{selectedADL.introduction}</RichText>
                      </div>
                    </div>
                    <a
                      tw="text-indigo-700 font-medium"
                      href={getAdlLink(watchedLearningPath.value, selectedADL.id)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Preview
                    </a>
                  </Card>
                </div>
              ) : (
                <div tw="text-sm text-gray-400">No activity of daily living match your current filters</div>
              )}
            </div>
            <ModalFooter>
              <Button
                type="submit"
                variant="primary"
                className={`inline-flex justify-center w-full ${!selectedADL ? 'cursor-not-allowed' : ''}`}
                disabled={!selectedADL}
                data-testid="add-daily-routine-activity-submit"
              >
                Add Daily Routine Activity
              </Button>
            </ModalFooter>
          </ModalBody>
        </form>
      </ModalContent>
    </Modal>
  );
}
