/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { components } from 'react-select';
import 'twin.macro';

type Props = {
  isSelected: boolean;
  label: string;
};

//dev-note: react-select types are not correct
const CheckboxSelectOption = (props: Props) => {
  return (
    <div tw="flex justify-center font-normal text-sm">
      <components.Option {...(props as any)}>
        <input name={`checkbox__${props.label}`} type="checkbox" checked={props.isSelected} />{' '}
        <label tw="ml-2" htmlFor={`checkbox__${props.label}`}>
          {props.label}
        </label>
      </components.Option>
    </div>
  );
};

export default CheckboxSelectOption;
