import { ISingleAppointment } from "types";
import calendarGrayIcon from "assets/calendar-gray.svg";
import { buildAppointmentStatus } from "./upcoming-appointments";
import { evaluationTypeId } from "domain/client/constants";

export const UpcomingAppointmentStatus: React.FC<{
  appointment: ISingleAppointment;
  shouldShowRecurringInformation: boolean;
}> = ({ appointment, shouldShowRecurringInformation }) => {
  const status = buildAppointmentStatus(appointment);

  if (!shouldShowRecurringInformation || !status || appointment.appointmentTypeID === evaluationTypeId) return null;
  return (
    <div className="flex mt-2">
      <img src={calendarGrayIcon} alt="calendar icon" className="w-4 h-4 inline-block" />
      <span className="text-sm text-gray-600 ml-2 italic">{status}</span>
    </div>
  );
};