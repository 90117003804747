/* eslint-disable react/display-name */
import React from 'react';
import { Event } from 'types';
import 'twin.macro';
import SimpleEvent from 'utils/event-renderers/simple-event';
import { faCalendarEdit } from '@fortawesome/pro-solid-svg-icons';

export interface AddSingleValueRendererOptions {
  fieldName?: string;

  formatValue?: (value: any) => any;

  description: string;
}

export interface AddSingleValueProps<T> {
  event: Event<T>;
  isLastEvent: boolean;
}

export default function addSingleValueRenderer<T>(versionToOptions: Record<string, AddSingleValueRendererOptions>) {
  return {
    default: (version: string) => {
      const { fieldName, formatValue, description } = versionToOptions[version];

      return (props: AddSingleValueProps<T>) => {
        const value = fieldName ? (props.event.detail as any)[fieldName] : props.event.detail;

        return (
          <SimpleEvent {...props} description={`added ${description}`} icon={faCalendarEdit}>
            <div>{formatValue ? formatValue(value) : value}</div>
          </SimpleEvent>
        );
      };
    },
  };
}
