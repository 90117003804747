import axios from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useToasts } from 'react-toast-notifications';
import { getUserAccessToken } from 'utils/get-access-token';

import { getMicroserviceBaseUrl, http } from '@expressable/utils';

import {
  MfaBarcode,
  MfaConfirmBarcode,
  MfaConfirmSmsCode,
  MfaDisable,
  MfaPublicKey,
  MfaSendCode,
  MfaStatus,
} from '../types';

const getMfaStatus = async (therapistEmail: string): Promise<MfaStatus> => {
  const {
    data: { mfa, phone, error },
  } = await http.get(`/therapists/${encodeURIComponent(therapistEmail)}/mfa/status`);

  return {
    mfa: mfa || { isActive: false },
    phone: phone || {},
    ...(error && { error }),
  };
};

export function useGetMfaStatus(therapistEmail: string) {
  const { data, isLoading, error } = useQuery<MfaStatus, Error>(['getMfaStatus', therapistEmail], () =>
    getMfaStatus(therapistEmail),
  );

  return {
    data,
    isLoading,
    error,
  };
}

const getMfaBarcode = async (therapistEmail: string): Promise<MfaBarcode> => {
  const accessToken = await getUserAccessToken();
  const {
    data: { barcode, secret, error },
  } = await axios.get(
    `${getMicroserviceBaseUrl('health-record')}/therapists/${encodeURIComponent(therapistEmail)}/mfa/barcode`,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    },
  );

  return {
    barcode,
    secret,
    ...(error && { error }),
  };
};

export function useGetMfaBarcode(therapistEmail: string) {
  const { data, isLoading, error } = useQuery<MfaBarcode, Error>(['getMfaBarcode', therapistEmail], () =>
    getMfaBarcode(therapistEmail),
  );

  return {
    data,
    isLoading,
    error,
  };
}

const postMfaSendCode = async (mfaSendCodePayload: MfaSendCode) => {
  const { therapistEmail, countryCode, phoneNumber, signature } = mfaSendCodePayload;

  return http
    .post(
      `/therapists/${encodeURIComponent(therapistEmail)}/mfa/sms/${encodeURI(`${countryCode.value}${phoneNumber}`)}`,
      { signature },
    )
    .then(res => res.data);
};

export function useMfaSendCode() {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(postMfaSendCode, {
    onSuccess: data => {
      addToast('Authentication code sent.', { appearance: 'success', autoDismiss: true });
      return data;
    },
    onError: async (error: any, payload) => {
      let errorMessage = 'Something Went Wrong';

      await queryClient.invalidateQueries(['getMfaStatus', payload.therapistEmail]);

      if (error.response && error.response.data) {
        const responseMessage =
          typeof error.response.data === 'object' ? error.response.data.message : error.response.data;
        if (responseMessage) {
          errorMessage = responseMessage;
        }
      }

      addToast(errorMessage, { appearance: 'error', autoDismiss: true });
    },
  });
}

const postMfaConfirmSmsCode = async (mfaConfirmSmsCode: MfaConfirmSmsCode) => {
  const { therapistEmail, confirmationCode, session } = mfaConfirmSmsCode;

  return http
    .post(`/therapists/${encodeURIComponent(therapistEmail)}/mfa/status/sms/${confirmationCode}`, { session })
    .then(res => res.data);
};

export function useMfaConfirmSmsCode() {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(postMfaConfirmSmsCode, {
    onSuccess: async (_, payload) => {
      await queryClient.invalidateQueries(['getMfaStatus', payload.therapistEmail]);
      addToast('MFA has been enabled.', { appearance: 'success', autoDismiss: true });
    },
    onError: async (error: any, payload) => {
      let errorMessage = 'Something Went Wrong';

      await queryClient.invalidateQueries(['getMfaStatus', payload.therapistEmail]);

      if (error.response && error.response.data) {
        const responseMessage =
          typeof error.response.data === 'object' ? error.response.data.message : error.response.data;
        if (responseMessage) {
          errorMessage = responseMessage;
        }
      }

      addToast(errorMessage, { appearance: 'error', autoDismiss: true });
    },
  });
}

const postMfaConfirmBarcode = async (mfaConfirmBarcode: MfaConfirmBarcode) => {
  const { therapistEmail, confirmationCode } = mfaConfirmBarcode;

  const accessToken = await getUserAccessToken();
  const res = await axios.post(
    `${getMicroserviceBaseUrl('health-record')}/therapists/${encodeURIComponent(
      therapistEmail,
    )}/mfa/status/app/${confirmationCode}`,
    null,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    },
  );

  return res.data;
};

export function useMfaConfirmBarcode() {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(postMfaConfirmBarcode, {
    onSuccess: async (_, payload) => {
      await queryClient.invalidateQueries(['getMfaStatus', payload.therapistEmail]);
      queryClient.invalidateQueries(['getMfaStatus', payload.therapistEmail]);
      addToast('MFA has been enabled.', { appearance: 'success', autoDismiss: true });
    },
    onError: async (error: any, payload) => {
      let errorMessage = 'Something Went Wrong';

      await queryClient.invalidateQueries(['getMfaStatus', payload.therapistEmail]);

      if (error.response && error.response.data) {
        const responseMessage =
          typeof error.response.data === 'object' ? error.response.data.message : error.response.data;
        if (responseMessage) {
          errorMessage = responseMessage;
        }
      }

      addToast(errorMessage, { appearance: 'error', autoDismiss: true });
    },
  });
}

const postMfaDisable = async (mfaDisablePayload: MfaDisable) => {
  const { therapistEmail, method } = mfaDisablePayload;

  return http
    .post(`/therapists/${encodeURIComponent(therapistEmail)}/mfa/status/${method.toLocaleLowerCase()}/mfa-off`, {})
    .then(res => res.data);
};

export function useMfaDisable() {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(postMfaDisable, {
    onSuccess: async (_, payload) => {
      await queryClient.invalidateQueries(['getMfaStatus', payload.therapistEmail]);
      addToast('MFA has been disabled.', {
        appearance: 'success',
        autoDismiss: true,
      });
    },
    onError: async (error: any, payload) => {
      let errorMessage = 'Something Went Wrong';

      await queryClient.invalidateQueries(['getMfaStatus', payload.therapistEmail]);

      if (error.response && error.response.data) {
        const responseMessage =
          typeof error.response.data === 'object' ? error.response.data.message : error.response.data;
        if (responseMessage) {
          errorMessage = responseMessage;
        }
      }

      addToast(errorMessage, { appearance: 'error', autoDismiss: true });
    },
  });
}

const getMfaPublicKey = async (): Promise<MfaPublicKey> => {
  const { data } = await http.get(`/therapists/mfa/public-key`);

  const response: MfaPublicKey = {
    public_key: data.public_key,
  };

  return response;
};

export function useGetMfaPublicKey() {
  const { data, isLoading, error } = useQuery<MfaPublicKey, Error>(['getMfaPublicKey'], () => getMfaPublicKey());

  return {
    publicKey: data?.public_key || '',
    isLoading,
    error,
  };
}
