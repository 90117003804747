import React from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import tw from 'twin.macro';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { Input, Label, Select, Link, Card } from '@expressable/ui-library';
import { LevelInSession, SelectOption } from 'types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import { getLearningPathLink } from 'domain/clinical-pathways/contants';
import { ClientAssignment } from 'domain/clinical-pathways/types';

dayjs.extend(utc);
dayjs.extend(timezone);

const levelInSessionOptions: SelectOption<LevelInSession>[] = [
  { label: 'Not Mastered', value: 'not-mastered' },
  { label: 'Progressing', value: 'progressing' },
  { label: 'Mastered', value: 'mastered' },
];

const assignLearningPathOptions: SelectOption<boolean>[] = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

type Props = {
  therapyStrategy: ClientAssignment;
  isChecked: boolean;
  isNewAssignment: boolean;
  onCheckboxChange: (checked: boolean) => void;
  onChange: (changedTherapyStrategy: ClientAssignment) => void;
  onRemove: () => void;
};

export const TherapyStrategyCard = ({
  therapyStrategy,
  isChecked,
  isNewAssignment,
  onCheckboxChange,
  onChange,
  onRemove,
}: Props): JSX.Element => {
  return (
    <Card tw="sm:p-6 border border-gray-300 mb-5 last:mb-0">
      <div className={`flex justify-between ${!isChecked && 'items-center'}`}>
        <div tw="flex">
          <Input
            type="checkbox"
            id={`learning-path-${therapyStrategy.learningPathContentfulEntryID}`}
            onChange={event => onCheckboxChange(event.target.checked)}
            checked={isChecked}
            data-testid="therapy-strategy-card-checkbox"
            spacing="tight"
            tw="flex mt-0.5 cursor-pointer"
          />
          <div tw="flex flex-col ml-2">
            <Label htmlFor={`learning-path-${therapyStrategy.learningPathContentfulEntryID}`} tw="font-medium text-sm cursor-pointer">
              {therapyStrategy.assignmentTitle} ({therapyStrategy.learningPathContentfulTreatmentArea || 'MISSING CATEGORY'})
            </Label>
            {isChecked && (
              <div tw="mt-6 mb-4">
                <div tw="xl:flex md:items-center mb-2">
                  <Label htmlFor="level-in-session" tw="font-medium text-sm">
                    Level In Session
                  </Label>
                  {!isNewAssignment && (
                    <h4 tw="text-sm text-gray-400 xl:ml-2">
                      Last Documented:{' '}
                      {dayjs(therapyStrategy.createdAt).tz(dayjs.tz.guess()).format('MM/DD/YYYY (dddd)')}
                    </h4>
                  )}
                </div>
                <div tw="flex items-center mb-7">
                  <Select
                    tw="w-[150px] sm:min-w-[225px]"
                    spacing="tight"
                    id="level-in-session"
                    value={levelInSessionOptions.find(item => item.value === therapyStrategy.learningPathContentfulLevelInSession)}
                    onChange={(option: SelectOption<LevelInSession>) =>
                      onChange({
                        ...therapyStrategy,
                        learningPathContentfulLevelInSession: option.value,
                      })
                    }
                    options={levelInSessionOptions}
                  />
                </div>
                <div tw="xl:flex md:items-center mb-2">
                  <Label tw="font-medium text-sm">Assign Learning Path for Strategy</Label>
                  <h4 tw="text-sm text-gray-400 xl:ml-2">A Learning Path is an educational module shared with the client that focuses on teaching a specific therapy strategy.</h4>
                </div>
                <div tw="sm:flex sm:items-center">
                  <Select
                    tw="w-[150px] sm:min-w-[225px]"
                    spacing="tight"
                    id="should-assign-learning-path"
                    value={assignLearningPathOptions.find(
                      item => item.value === therapyStrategy.learningPathContentfulShouldAssign,
                    )}
                    onChange={(option: SelectOption<boolean>) =>
                      onChange({
                        ...therapyStrategy,
                        learningPathContentfulShouldAssign: option.value,
                      })
                    }
                    options={assignLearningPathOptions}
                  />
                  <a
                    tw="text-indigo-700 text-sm sm:ml-4"
                    href={getLearningPathLink(therapyStrategy.learningPathContentfulEntryID!)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Preview
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
        <div tw="flex">
          <Link to="#" data-testid="remove-therapy-strategy-link" onClick={onRemove}>
            <FontAwesomeIcon tw="text-lg" icon={faTrash} />
          </Link>
        </div>
      </div>
    </Card>
  );
};
