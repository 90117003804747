import React from 'react';
import {
  Button,
  CloseButton,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
} from '@expressable/ui-library';
import 'twin.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Controller, useForm } from 'react-hook-form';
import { useSetVisitFrequency } from 'hooks/use-care-plan-visit-frequency';
import { Frequency } from 'types';
import { faCalendarEdit } from '@fortawesome/pro-solid-svg-icons';

export interface ClientVisitFrequencyModalProps {
  isOpen: boolean;
  onClose: () => void;
  clientId: string;
  sessionsCount: number;
  frequency: Frequency;
  shouldLogEvent: boolean;
}

interface SelectOption<TValue = string> {
  label: string;
  value: TValue;
}

interface ClientVisitFrequencyForm {
  sessionsCount: SelectOption<number>;
  frequency: SelectOption<Frequency>;
}

const sessionsCountOptions = Array.from(Array(12).keys()).map(c => ({ label: (c + 1).toString(), value: c + 1 }));

const frequencyOptions: SelectOption<Frequency>[] = [
  { label: 'Week', value: 'weekly' },
  { label: 'Two Weeks', value: 'two-weeks' },
  { label: 'Four Weeks', value: 'four-weeks' },
  { label: 'Month', value: 'monthly' },
  { label: 'Year', value: 'yearly' },
];

export function ClientVisitFrequencyModal(props: ClientVisitFrequencyModalProps) {
  const { isOpen, onClose, clientId, sessionsCount, frequency, shouldLogEvent } = props;
  const { mutateAsync: setVisitFrequency, isLoading } = useSetVisitFrequency();

  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm<ClientVisitFrequencyForm>();

  const onSubmit = async ({ sessionsCount, frequency }: ClientVisitFrequencyForm) => {
    await setVisitFrequency({
      clientId,
      sessionsCount: sessionsCount.value,
      frequency: frequency.value,
      shouldLogEvent,
    });
    onClose();
  };

  return (
    <Modal portal isOpen={isOpen}>
      <ModalContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader>
            <CloseButton onClick={onClose} />
          </ModalHeader>
          <ModalBody>
            <div>
              <div tw="flex items-center justify-center w-12 h-12 mx-auto bg-indigo-100 rounded-full">
                <FontAwesomeIcon tw="text-2xl text-indigo-700" icon={faCalendarEdit} />
              </div>
              <div tw="mt-3 sm:mt-5">
                <h3 tw="text-lg text-center font-medium text-gray-900 leading-6" id="modal-headline">
                  Update Visit Frequency
                </h3>
                <div tw="mt-10">
                  <FormGroup>
                    <Label htmlFor="sessionsCount">Sessions</Label>
                    <div tw="flex-initial space-x-3">
                      <div tw="inline-block w-20" data-testid="sessionsCount">
                        <Controller
                          name="sessionsCount"
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              options={sessionsCountOptions}
                              error={errors.sessionsCount}
                              spacing="tight"
                            />
                          )}
                          defaultValue={sessionsCountOptions.find(x => x.value === sessionsCount)}
                          rules={{ required: true }}
                          tw="mb-0"
                        />
                      </div>
                      <div tw="inline-block">every</div>
                      <div tw="inline-block w-40" data-testid="frequency">
                        <Controller
                          name="frequency"
                          control={control}
                          render={({ field }) => (
                            <Select {...field} options={frequencyOptions} error={errors.frequency} spacing="tight" />
                          )}
                          defaultValue={frequencyOptions.find(x => x.value === frequency)}
                          rules={{ required: true }}
                        />
                      </div>
                    </div>
                  </FormGroup>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div tw="mt-5 sm:mt-6 sm:grid sm:grid-cols-1 sm:gap-3 sm:grid-flow-row-dense">
              <span tw="flex w-full rounded-md shadow-sm">
                <Button
                  type="submit"
                  data-testid="client-visit-frequency-submit"
                  variant="primary"
                  loading={isLoading}
                  tw="inline-flex justify-center w-full px-4 py-2 text-base font-medium leading-6 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                >
                  Save
                </Button>
              </span>
            </div>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
