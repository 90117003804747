import tw from 'twin.macro';
import { CompleteTherapistInformation, ReportingWeek, SelectOption } from '../types';
import { Button, Card, Label, Link, Loader, Select } from '@expressable/ui-library';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { SessionTypeEnum } from 'domain/admin/constants';
import { TherapistTypes } from 'domain/therapist/therapistTypes';
import { useTherapistHoursReportingHub } from 'hooks/use-therapist-hours-reporting-hub';
import { displayTimezoneOptions } from './appointment-modals';
import { useMemo } from 'react';
import { capitalize } from 'lodash';
import {
  formatHoursAndMinutes,
  splitNumberByDecimal,
} from 'utils/helpers';

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

export interface TherapistOverviewProps {
  therapistData: CompleteTherapistInformation;
}

export interface TherapistFormattedNotes {
  noteID: string;
  eventID: string;
  eventType: string;
  sessionType: string; // this would be 'session' or 'evaluation' but capitalized
  createdAt: string;
  clientID: string;
  clientName: string;
  appointmentOn: string;
  afternoonAppointment: boolean;
  weekendAppointment: string;
  duration: number;
  totalDirectHours: string;
}

const localToStandardTz = new Map(displayTimezoneOptions.map(({local, value}) => [local, value]));

function formatTime(time: string, tz: string) {
  return dayjs(time).tz(tz).format('MM/DD h:mm A');
}

const noteDeletedStyle = tw`line-through`;

function getNoteStyle(note: any) {
  return note.eventType === 'note_deleted' ? [noteDeletedStyle] : [];
}

export default function TherapistHoursReportingHub({ therapistData }: TherapistOverviewProps) {
  const {
    hourlyReporting,
    isFetching,
    onCompleteTimesheet,
    reportingWeeks,
    selectedReportingWeek,
    setSelectedReportingWeek,
    therapistNotes,
  } = useTherapistHoursReportingHub(therapistData);

  const {
    anticipatedHoursWorkedRange,
    differentialHoursAndMinutes,
    documentationHoursRange,
    isLegacyAnticipatedHours,
    legacyAnticipatedHours,
    otherIndirectHoursRange,
    totalDirectHoursAndMinutes,
  } = hourlyReporting;

  const formattedNotes = useMemo(() => {
    const standardTherapistTimeZone = localToStandardTz.get(therapistData.therapistTimeZone)!;

    const notes = [...therapistNotes ?? []];
    notes.sort((a, b) => dayjs(a.createdAt).valueOf() - dayjs(b.createdAt).valueOf());

    return notes
      .reduce((accNotes, currentNote) => {
        if (currentNote.sessionType !== SessionTypeEnum.session && currentNote.sessionType !== SessionTypeEnum.evaluation) {
          return accNotes;
        }

        if (currentNote.eventType === 'note_deleted') {
          const deletedNote = accNotes.find(n => n.noteID === currentNote.noteID);

          if (deletedNote !== undefined) {
            deletedNote.eventType = currentNote.eventType; // note_deleted
          }
        } else {
          const totalDirectHours = currentNote.adjustedDurationHours ?? 0;
          const totalDirectHoursAndMinutes = formatHoursAndMinutes(...splitNumberByDecimal(totalDirectHours));

          accNotes.push({
            noteID: currentNote.noteID,
            eventID: currentNote.eventID,
            eventType: currentNote.eventType,
            sessionType: capitalize(currentNote.sessionType),
            createdAt: formatTime(currentNote.createdAt, standardTherapistTimeZone),
            clientID: currentNote.clientID.replace('client_', ''),
            clientName: `${currentNote.clientFirstName} ${currentNote.clientLastName}`,
            appointmentOn: formatTime(currentNote.appointmentOn, standardTherapistTimeZone),
            afternoonAppointment: currentNote.afternoonAppointment,
            weekendAppointment: currentNote.weekendAppointment,
            duration: currentNote.duration,
            totalDirectHours: totalDirectHoursAndMinutes,
          });
        }

        return accNotes;
      }, [] as TherapistFormattedNotes[]);
  }, [therapistData, therapistNotes]);

  // We only show aggregated notes after 06/18/2023 (when the new Medicaid rule started
  // to be applied)
  const showNotes = formattedNotes.length > 0
    && selectedReportingWeek
    && selectedReportingWeek.value.start >= ReportingWeek.NewMedicaidRule;

  return (
    <div>
      <div tw="md:w-3/4 md:pr-5">
        <div tw="text-1xl font-semibold mb-6 lg:mb-10">Hours Reporting Hub</div>
      </div>
      <Label className="mb-4">Reporting Week</Label>
      <Select
        tw="w-full md:w-1/3 mb-8 lg:mb-10"
        options={reportingWeeks}
        value={selectedReportingWeek}
        onChange={(week: SelectOption<{ start: string; end: string }>) => setSelectedReportingWeek(week)}
      />
      {isFetching && <div tw="flex-1 text-center py-20">
        <Loader type="ring" />
      </div>}
      {!isFetching && therapistData?.therapistType === TherapistTypes.Hourly && <div className="grid grid-cols-1 gap-1">
        <Card className="py-0 px-0 mb-8 lg:mb-10">
          <div tw="flex flex-wrap flex-col lg:flex-row">
            <div tw="flex flex-col flex-grow leading-8">
              <div tw="flex flex-col px-5 py-12 gap-4 border-b border-[#DADADA]">
                <Label tw="font-semibold text-base">Anticipated Hours Worked</Label>
                <div tw="flex flex-col lg:flex-row gap-6 lg:gap-0">
                  <h1 tw="flex-grow text-3xl font-bold mb-auto" data-testid="anticipated-hours-worked">
                    {isLegacyAnticipatedHours ? legacyAnticipatedHours : anticipatedHoursWorkedRange}
                  </h1>
                  <Button variant="primary" tw="px-4 py-2" onClick={() => onCompleteTimesheet()}>
                    Complete Timesheet
                  </Button>
                </div>
              </div>
              <div tw="flex flex-col gap-2 lg:gap-0 px-5 py-12">
                <div tw="flex">
                  <div tw="flex items-start text-[#161E2E]">Total Direct Hours</div>
                  <div tw="flex flex-1 text-right justify-end" data-testid="total-direct-hours-1">{totalDirectHoursAndMinutes}</div>
                </div>
                <div tw="flex">
                  <div tw="flex items-start text-[#161E2E]">Documentation Time</div>
                  <div tw="flex flex-1 text-right justify-end" data-testid="documentation-time">{documentationHoursRange}</div>
                </div>
                <div tw="flex">
                  <div tw="flex items-start text-[#161E2E]">Other Indirect Time</div>
                  <div tw="flex flex-1 text-right justify-end" data-testid="other-indirect-time">{otherIndirectHoursRange}</div>
                </div>
              </div>
            </div>
            <div className="flex flex-col lg:flex-1 p-4 border-t lg:border-t-0 lg:border-l border-[#DADADA]">
              <p className="mb-4">
                Expressable would anticipate that you would have worked within the range given the quantity{' '}
                and type of visits you completed in this week. This range of hours is meant to be inclusive of your direct{' '}
                and indirect working time. You should always report exactly the number of hours you actually worked -{' '}
                even if you have worked fewer or more hours than the anticipated range.
              </p>
              <p className="mb-4 py-6">
                Learn more in the{' '}
                <a
                  className="text-indigo-700"
                  href="http://help.expressable.io/en/articles/6887798-non-exempt-timekeeping-payroll-guide"
                  target="_blank"
                  rel="noreferrer"
                >
                  Non-Exempt Timekeeping & Payroll Guide
                </a>
              </p>
            </div>
          </div>
        </Card>
      </div>}
      {!isFetching && <div className="grid lg:grid-rows-1 lg:grid-cols-2 gap-6 lg:p-0">
        <Card className="p-0">
          <div className="grid grid-rows-2 lg:grid-rows-1 lg:grid-cols-2 gap-0 lg:p-0 h-full">
            <div className="border-b lg:border-r lg:border-b-0 border-[#DADADA] px-6 py-2">
              <Label className="my-3 font-semibold mt-5 text-base">Total Direct Hours</Label>
              <h1 tw="text-3xl font-bold" data-testid="total-direct-hours-2">
                {totalDirectHoursAndMinutes}
              </h1>
              <p tw="my-4 text-[#161E2E]">
                Hours spent in evaluations and sessions during this week, calculated from attended appointments in Cue.
              </p>
            </div>
            <div className="border-b lg:border-r lg:border-b-0 border-[#DADADA] px-6 py-2">
              <Label className="my-3 font-semibold mt-5 text-base">Differential Hours</Label>
              <h1 tw="text-3xl font-bold " data-testid="differential-hours">
                {differentialHoursAndMinutes}{' '}
                <span tw="text-base">of {totalDirectHoursAndMinutes}</span>
              </h1>
              <p tw="my-4 text-[#161E2E]">
                Direct Hours worked after 5:00 pm in your local timezone and on weekends.
              </p>
            </div>
          </div>
        </Card>
        <Card tw="p-0">
          <div className="flex">
            <div tw="flex flex-col">
              <div tw="h-full flex flex-col px-6 py-2">
                <div className="flex flex-col">
                  <Label className="my-3 font-semibold text-base">How-To Review PTO Hours</Label>
                  <p className="mb-4">
                    The PTO hours accrued each pay date are updated and reconciled with Sequoia so you can find your
                    available balances in the Hub or the Sequoia worklife portal. Reminder: Do NOT submit PTO requests
                    in Sequoia.
                  </p>
                  <Button
                    variant="secondary"
                    className="px-2 mt-4 w-full lg:w-1/3 mb-6"
                    onClick={() => window.open('https://airtable.com/appk4FX2UQtSApEdP/pagoVyYnBc7qqxj7C', '_blank')}
                  >
                    Airtable link
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>}
      {!isFetching && showNotes && (
        <div className="col-span-12 mt-8 lg:mt-10">
          <Card>
            { /* @TODO: use the future table component */ }
            <table tw="w-full">
              <thead>
                <tr tw="border-b border-gray-200">
                  <th tw="text-left py-4 hidden md:table-cell">Type</th>
                  <th tw="text-left py-4">Attendance Logged</th>
                  <th tw="text-left py-4">Client Name</th>
                  <th tw="text-left py-4 hidden md:table-cell">Appointment At</th>
                  <th tw="text-center py-4 hidden md:table-cell">Evening/Weekend</th>
                  <th tw="text-right py-4">Total Direct Hours</th>
                </tr>
              </thead>
              <tbody data-testid="formatted-notes-table-tbody">
                {formattedNotes.map((note) => (
                  <tr
                    key={note.eventID}
                    tw="border-b border-gray-200"
                    css={getNoteStyle(note)}
                  >
                    <td tw="text-left py-4 hidden md:table-cell">{note.sessionType}</td>
                    <td tw="text-left py-4">{note.createdAt}</td>
                    <td tw="text-left py-4">
                      <Link to={{ pathname: `/clients/${note.clientID}`}}>
                        {note.clientName}
                      </Link>
                    </td>
                    <td tw="text-left py-4 hidden md:table-cell">{note.appointmentOn}</td>
                    <td tw="text-center py-4 hidden md:table-cell">
                      {note.afternoonAppointment || note.weekendAppointment ? 'Yes' : 'No'}
                    </td>
                    <td tw="text-right py-4">{note.totalDirectHours}</td>
                  </tr>
                ))}
              </tbody>
            </table>

            <p tw="text-sm text-gray-400 mt-6">
              All times displayed in {therapistData.therapistTimeZone} time
              (the timezone configured for {therapistData.firstName} {therapistData.lastName})
            </p>
          </Card>
        </div>
      )}
    </div>
  );
}
