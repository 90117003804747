import React, { Dispatch, SetStateAction, useState } from 'react';
import {
  Button,
  CloseButton,
  FormGroup,
  FormInline,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
} from '@expressable/ui-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import ModalHeadline from 'components/modal-headline';
import FormGroupTitle from 'components/form-group-title';
import RHFSelect from 'components/RHFSelectBase';
import { useForm } from 'react-hook-form';
import { relationshipsOptions } from 'domain/client/constants';
import { Contact, ICompleteClientInformation } from 'types';
import { buildAddressString } from 'utils/helpers';
import { PhoneRender } from './client-sidebar';
import { AddDuplicatedContactPayload, useAddDuplicatedContact } from 'hooks/use-client-contacts';

export interface DuplicateContactsModalProps {
  duplicatedContacts: Contact[];
  searchedPhone: string;
  searchedEmail: string;
  clientData: ICompleteClientInformation | undefined;
  isOpen: boolean;
  onClose: () => void;
  setShowEditForm: Dispatch<SetStateAction<boolean>>;
  setContacts: Dispatch<SetStateAction<Contact[]>>;
  setShowDuplicateFinder: Dispatch<SetStateAction<boolean>>;
  contacts: Contact[];
  clientId: string;
}

const DuplicatedContactsModal = (props: DuplicateContactsModalProps) => {
  const {
    duplicatedContacts,
    searchedPhone,
    searchedEmail,
    clientData,
    isOpen,
    onClose,
    setShowEditForm,
    setContacts,
    setShowDuplicateFinder,
    contacts,
    clientId,
  } = props;
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [selectedContact, setSelectedContact] = useState<Contact | null>(null);
  const addDuplicatedContactMutation = useAddDuplicatedContact();
  const { mutate: addDuplicatedContact, isLoading } = addDuplicatedContactMutation;

  const onSelectContact = (contact: Contact) => {
    setSelectedContact(contact);
  };

  const onSubmit = async (formData: AddDuplicatedContactPayload) => {
    const contactInformation: AddDuplicatedContactPayload = {
      clientID: clientId,
      contactID: selectedContact ? selectedContact?.contactID : '',
      relationshipToClient: formData.relationshipToClient,
    };

    addDuplicatedContact(contactInformation, {
      onSuccess: () => {
        setShowDuplicateFinder(false);
        setShowEditForm(false);
        onClose();
      },
    });
  };

  return (
    <Modal isOpen={isOpen} className="max-w-lg">
      <ModalContent>
        <ModalHeader>
          <div className="absolute top-0 right-0 pt-4 pr-4">
            <CloseButton
              data-testid="close-duplicated-contacts-modal"
              onClick={() => {
                setContacts([...contacts.slice(0, -1)]);
                setShowDuplicateFinder(true);
                onClose();
              }}
            />
          </div>
        </ModalHeader>
        <ModalBody>
          <React.Fragment>
            <div className="flex items-center justify-center w-12 h-12 mx-auto bg-indigo-100 rounded-full">
              <FontAwesomeIcon className="text-2xl text-indigo-700" icon={faUser} />
            </div>
            <div className="my-3 sm:mt-5">
              <ModalHeadline
                text={`Duplicate ${searchedPhone && !searchedEmail ? `phone number found` : ''} ${
                  searchedPhone && searchedEmail ? `phone number and/or email address found` : ''
                } ${searchedEmail && !searchedPhone ? `email address found` : ''}`}
              />
              <div className="w-[70%] m-auto mt-2">
                <p className="text-center">
                  {`${
                    searchedPhone && searchedEmail
                      ? `The phone number (${searchedPhone.trim()}) and/or email address (${searchedEmail}) were`
                      : ''
                  }  
                    ${searchedPhone && !searchedEmail ? `The phone number (${searchedPhone.trim()}) was` : ''} 
                    ${searchedEmail && !searchedPhone ? `The email address (${searchedEmail}) was` : ''} 
                 found in existing contact record(s). Verify which existing contact record you want to add for ${
                   clientData?.clientFirstName
                 } ${clientData?.clientLastName}.`}
                </p>
                <div className="flex flex-row bg-red-100 p-4 rounded mt-6">
                  If you are unable to confirm the correct information for this new contact, return to search different
                  contact information.
                </div>
                <div className="mt-4 overflow-y-auto max-h-64">
                  {duplicatedContacts.map(availableContact => {
                    return (
                      <div className="mb-2 mt-2" key={availableContact.contactID}>
                        <FormInline>
                          <Input
                            onChange={() => onSelectContact(availableContact)}
                            className="mr-2 ml-1"
                            type="radio"
                            spacing="tight"
                            name={availableContact.contactID}
                            id={availableContact.contactID}
                            value={availableContact.mobilePhone}
                            checked={selectedContact?.contactID === availableContact.contactID}
                          />
                          <Label className="text-base cursor-pointer" htmlFor={availableContact.contactID}>
                            {availableContact.contactFirstName} {availableContact.contactLastName}
                          </Label>
                        </FormInline>
                        <div className="ml-6">
                          <div className="text-gray-400">
                            {availableContact.phone &&
                              (availableContact.mobilePhone ||
                                availableContact.workPhone ||
                                availableContact.homePhone) && (
                                <>
                                  <PhoneRender phone={availableContact.phone} />
                                </>
                              )}
                          </div>
                          <div className="text-gray-400">{availableContact.email}</div>
                          {availableContact.address && availableContact.contactTimeZone && (
                            <div className="text-gray-400">
                              {buildAddressString(availableContact.address)} ({availableContact.contactTimeZone})
                            </div>
                          )}
                          {availableContact.relatedClients?.map(client => {
                            return (
                              <div key={client.clientInfo.clientID} className="text-gray-400">
                                {client.relationshipToClient} to {client.clientInfo.clientFirstName}{' '}
                                {client.clientInfo.clientLastName}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <form className="mt-5" data-testid="duplicated-contacts-form" onSubmit={handleSubmit(onSubmit)}>
                {selectedContact && (
                  <FormGroup data-testid="duplicated-contacts-relationship-to-client" className="mt-8">
                    <FormGroupTitle
                      title="Relationship to Client"
                      fontSize="small"
                      fontWeight="semi"
                      spacing="normal"
                    />
                    <RHFSelect
                      name="relationshipToClient"
                      control={control}
                      required
                      options={relationshipsOptions}
                      error={errors.relationshipToClient}
                    />
                  </FormGroup>
                )}

                <FormGroup className="mt-4">
                  <Button
                    type="submit"
                    data-testid="duplicated-contacts-verify-and-add-contact"
                    variant="primary"
                    fullWidth
                    disabled={!selectedContact}
                    loading={isLoading}
                  >
                    Verify and add new contact
                  </Button>
                  <Button
                    className="mt-3"
                    data-testid="duplicated-contacts-cancel"
                    variant="secondary"
                    fullWidth
                    onClick={() => {
                      setContacts([...contacts.slice(0, -1)]);
                      setShowDuplicateFinder(true);
                      onClose();
                    }}
                  >
                    Return to search
                  </Button>
                </FormGroup>
              </form>
            </div>
          </React.Fragment>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DuplicatedContactsModal;
