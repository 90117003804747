import { Select, Textarea } from '@expressable/ui-library';
import { FormField } from 'components/Forms';
import { ControlledFormField } from 'components/Forms/FormFieldControlled';
import FormFieldRadioFreeText from 'components/Forms/RadioFreeText';
import useEvaluationConclusions from 'hooks/use-evaluation-conclusions';
import { autoSize } from 'utils/auto-h-size';

export default function ConclusionsSection() {
  const { isSpeechTherapyRecommended, teletherapyAppropiateOptions } = useEvaluationConclusions();

  return (
    <div className="flex-col mb-4">
      <h2 className="font-semibold text-xl ">Conclusions</h2>
      <hr className="mt-1 mb-6 border-gray-200" />
      <div className="flex flex-col gap-4 space-y-4">
        <FormFieldRadioFreeText
          name="areFurtherAssessmentsRecommended"
          label="Are further assessments recommended?"
          when="yes"
          relevantLabel="Recommended assessments"
        />
        <FormFieldRadioFreeText
          name="areSpeechTherapyServicesRecommended"
          label="Is speech therapy recommended?"
          when="nan" // dev-note(gian): added non-existent value to avoid showing the field and reuse the component
        />

        {isSpeechTherapyRecommended && (
          <>
            <ControlledFormField
              containerClass="flex flex-col w-1/3"
              component={Select}
              spacing="tight"
              tw={'min-w-[550px]'}
              name="isTeletherapyAppropriate"
              label="Is teletherapy appropriate for this client?"
              options={teletherapyAppropiateOptions}
            />
          </>
        )}

        <FormField component={Textarea} label="Conclusions" name="conclusions" rows={5} onChange={autoSize} />
      </div>
    </div>
  );
}
