import { WeekSummaryStatus } from './therapistTypes';

export const MFA_INPUT_LENGTH = 6;

export type MfaType = 'sms' | 'app';

export const DEFAULT_MFA_TYPE: MfaType = 'app';

export const MFA_COUNTRIES_AVAILABILITY = [
  { label: 'United States +1', value: '+1' },
  { label: 'Argentina +54', value: '+54' },
  { label: 'Brazil +55', value: '+55' },
  { label: 'Colombia +57', value: '+57' },
  { label: 'Dominican Republic +1', value: '_+1' },
  { label: 'Mexico +52', value: '+52' },
  { label: 'Philippines +63', value: '+63' },
  { label: 'Uruguay +598', value: '+598' },
];

export const MFA_PHONE_VALIDATION_REGEX: any = {
  '+1': /^(?!([0-9])\1+$)\d{10}$/,
  '+54': /^(?!([0-9])\1+$)\d{10,11}$/,
  '+55': /^(?!([0-9])\1+$)\d{10,11}$/,
  '+57': /^(?!([0-9])\1+$)\d{10,11}$/,
  '_+1': /^(?!([0-9])\1+$)\d{10}$/,
  '+52': /^(?!([0-9])\1+$)\d{10,11}$/,
  '+63': /^(?!([0-9])\1+$)\d{10}$/,
  '+598': /^(?!([0-9])\1+$)\d{10}$/,
};

export const MFA_PHONE_PATTERNS: { [key: string]: string } = {
  '+1': '(999) 999-9999',
  '+54': '(9999) 99-9999',
  '+55': '(99) 99999-9999',
  '+57': '(99) 999999999',
  '_+1': '(999) 999-9999',
  '+52': '(999) 9999-9999',
  '+63': '(999) 999-9999',
  '+598': '(9999) 99-9999',
};

export enum MfaEvent {
  SEND_SMS_CODE = 'SEND_SMS_CODE',
  CONFIRM_SMS_CODE = 'CONFIRM_SMS_CODE',
  USR_NOT_FOUND = 'userNotFound',
}

export const getWeekSummaryStatusDescription = (status: WeekSummaryStatus[]) => {
  if (status.includes(WeekSummaryStatus.RateLimitDirectHours)) {
    return 'Over direct hour rate limit for the week.';
  }
  if (status.includes(WeekSummaryStatus.RateLimitWeek)) {
    return 'Over rate limit for the week.';
  }
  if (status.includes(WeekSummaryStatus.RateLimitDay)) {
    return 'Over rate limit for the day.';
  }
  return 'Unhandled rate limit status.';
};

export const enum TherapistErrorCode {
  RateLimit = 'THERAPIST_OVER_RATE_LIMIT',
}

export const enum TherapistSortBy {
  Caseload = 'caseload',
}

export const ERROR_MESSAGES = {
  EVALUATION_ERROR: 'Therapist is not set up for evaluations',
  SESSION_ERROR: 'Therapist is not set up for sessions',
};
