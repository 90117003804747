import { useMutation } from 'react-query';
import { dynamicHttp } from '@expressable/utils';
import { useToasts } from 'react-toast-notifications';

const httpHealthRecordProxy = dynamicHttp('health-record-proxy');

export interface GenerateAuthorizationPayload {
  clientId: string;
}

const generateAuthorizationMessage = async ({ clientId }: GenerateAuthorizationPayload) => {
  return httpHealthRecordProxy.post(`/care-plans/generate-authorization-message/${clientId}`).then(res => res.data);
};

export function useGenerateAuthorizationMessage() {
  const { addToast } = useToasts();

  return useMutation(generateAuthorizationMessage, {
    onError: (error: any) => {
      let errorMessage = 'An unexpected error occurred.';
      
      if (error?.response && error?.response?.data?.errors) {
        const { errors } = error.response.data;
        errorMessage = errors;
      } else {
        errorMessage = 'An error occurred while processing the request.';
      }
      addToast(errorMessage, { appearance: 'error', autoDismiss: true });
    },
  });
}
