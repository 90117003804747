import { useMutation, useQueryClient } from 'react-query';
import { http } from '@expressable/utils';
import { useToasts } from 'react-toast-notifications';

export interface CreateEvaluationPayload {
  clientID: string;
  dateTime: string | moment.Moment;
  clientFirstName: string;
  preferredFirstName?: string;
  clientLastName: string;
  contactEmail: string;
  contactPhone: string;
  contactTimeZone: string;
  acuityCalendarID: number;
  duration: number;
}

const createEvaluation = async (createEvaluationPayload: CreateEvaluationPayload) => {
  const { clientID, ...evaluationData } = createEvaluationPayload;
  return http.post(`/clients/${clientID}/evaluations`, evaluationData).then(res => res.data);
};

export function useCreateEvaluation() {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(createEvaluation, {
    onSuccess: (_, payload) => {
      addToast('Evaluation Successfully Created', { appearance: 'success', autoDismiss: true });
      queryClient.invalidateQueries(['client', payload.clientID]);
    }
  });
}
