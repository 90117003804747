import dayjs, { Dayjs } from 'dayjs';

const ADULT_AGE = 18;

/**
 * Options for the `isAdult` function
 */
interface IsAdultOptions {
  /**
   * Date of birth in any format Day.js can parse
   */
  dateOfBirth: string | Date;
  /**
   * Age to be considered an adult (defaults to ADULT_AGE)
   */
  adultAge?: number;
  /**
   * Optional reference date for age calculation (defaults to current date)
   */
  referenceDate?: string | Date;
}

/**
 * Determines if a person is an adult based on their date of birth
 * @param {IsAdultOptions} options - Options for the age verification
 * @returns {boolean} True if the person is an adult, false otherwise
 */
export const isAdult = (options: IsAdultOptions): boolean => {
  // Convert inputs to Day.js objects
  const birth: Dayjs = dayjs(options.dateOfBirth);
  const reference: Dayjs = dayjs(options.referenceDate ?? new Date());

  // Validate inputs
  if (!birth.isValid() || !reference.isValid()) {
    return false;
  }

  // Calculate the difference in years
  const age = reference.diff(birth, 'year', true);

  return age >= (options.adultAge ?? ADULT_AGE);
};
