import React from 'react';
import { faCircleDollar } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { formatAmount, removePrefixFromID } from 'utils/helpers';
import dayjs from 'dayjs';
import { FirehoseEventStripe } from 'domain/admin/types';

const StripeChargeSucceeded = (event: FirehoseEventStripe) => {
  const isLastEvent = event.isLastEvent;
  const firehoseEvent = event.event;

  return (
    <div data-testid="firehose-stripe-charge-succeeded" className="relative flex mt-7 px-2">
      {!isLastEvent && (
        <span className="absolute -z-1 top-7 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
      )}
      <div className="flex items-baseline">
        <div className="flex items-center bg-gray-200 rounded-full h-6 w-6">
          <FontAwesomeIcon icon={faCircleDollar} className="mx-1.5 h-3 text-indigo-700" />
        </div>
        <div className="pl-4 text-sm">
          <div className="lg:flex font-semibold pb-1">
            <span className="text-gray-500">A charge for</span>&nbsp;
            <Link
              className="text-indigo-700"
              to={{ pathname: `/contacts/${removePrefixFromID(firehoseEvent?.primaryContactID)}` }}
            >
              {firehoseEvent?.contactFirstName} {firehoseEvent?.contactLastName}&nbsp;
            </Link>
            <span className="text-gray-500">for the amount of {formatAmount(firehoseEvent?.amount)}</span>&nbsp;
            <span className="text-gray-500">{`has succeeded at ${dayjs(firehoseEvent?.createdAt as string).format(
              'h:mm A',
            )}, ${dayjs(firehoseEvent?.createdAt as string).format('MM/DD/YYYY')} `}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StripeChargeSucceeded;
