import { Select } from '@expressable/ui-library';
import { ControlledFormField } from 'components/Forms/FormFieldControlled';
import FormFieldRadioFreeText from 'components/Forms/RadioFreeText';
import { convertStringArrayToSelectOptions } from 'domain/notes/mapHelpers';
import {
  EvaluationEntriesIds,
  useEvaluationContentfulEntry,
  useEvaluationContentfulSelectOptions,
} from 'hooks/use-evaluation-contentful-provider';
import { useRadioFreeTextEvaluationDefaultAnswer } from 'hooks/use-radio-free-text-default-answer';
import { useEffect, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { ContentfulDropdownContent } from 'types';

function useEducationStatusOptions() {
  const { data } = useEvaluationContentfulEntry<ContentfulDropdownContent>(EvaluationEntriesIds.CurrentEducationStatus);

  return useMemo(() => {
    const options = convertStringArrayToSelectOptions(data?.dropdownContent);
    if (!options) {
      return null;
    }

    const inSchoolOptions: string[] = Array.isArray(data?.customLogic?.inSchool) ? data?.customLogic?.inSchool : [];

    return options.map(x => ({ ...x, inSchool: inSchoolOptions.includes(x.value) }));
  }, [data]);
}

export default function SocialHistorySection() {
  const educationStatusOptions = useEducationStatusOptions();
  const gradeLevelOptions = useEvaluationContentfulSelectOptions(EvaluationEntriesIds.CurrentGradeLevel);
  const livingWithOptions = useEvaluationContentfulSelectOptions(EvaluationEntriesIds.ClientCurrentlyLives);

  useRadioFreeTextEvaluationDefaultAnswer('academicConcerns', EvaluationEntriesIds.AcademicConcerns);

  const educationStatus = useWatch({ name: 'educationStatus' });
  const inSchool = educationStatusOptions?.find(x => x.value === educationStatus?.value)?.inSchool;

  const { setValue } = useFormContext();
  useEffect(() => {
    if (!inSchool) {
      setValue('gradeLevel', null);
    }
  }, [inSchool]);

  return (
    <div className="mb-4">
      <h2 className="font-semibold text-xl">Social History</h2>
      <hr className="mt-1 mb-6 border-gray-200" />
      <div className="space-y-6 flex flex-col">
        <div className="flex gap-6 w-full">
          <ControlledFormField
            component={Select}
            containerClass="flex flex-col w-1/3"
            spacing="tight"
            name="educationStatus"
            label="Current Education Status"
            options={educationStatusOptions}
          />
        </div>

        {inSchool && (
          <div className="flex gap-6 w-full">
            <ControlledFormField
              component={Select}
              containerClass="flex flex-col w-1/3"
              spacing="tight"
              name="gradeLevel"
              label="Current Grade Level"
              options={gradeLevelOptions}
            />
          </div>
        )}

        <FormFieldRadioFreeText
          label="Are there any academic concerns worth noting?"
          name="academicConcerns"
          relevantLabel="Academic Concerns"
          when="yes"
        />

        <div className="flex gap-6 w-full">
          <ControlledFormField
            component={Select}
            containerClass="flex flex-col w-1/3"
            isMulti
            spacing="tight"
            name="livingWith"
            label="Client currently lives with"
            options={livingWithOptions}
          />
        </div>
      </div>
    </div>
  );
}
