import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  CloseButton,
  ModalFooter,
  Button,
  Label,
  Select,
  Textarea,
} from '@expressable/ui-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'twin.macro';
import { SelectOption } from 'types';
import { ClientAssignment, TherapyStrategyRemove } from 'domain/clinical-pathways/types';
interface TherapyStrategyRemoveForm {
  assignmentId: string;
  discontinuationReason: SelectOption<string>;
  additionalInformation?: string;
}

export interface AddTherapyStrategyModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (removedTherapyStrategy: TherapyStrategyRemove) => void;
  therapyStrategy: ClientAssignment;
  unassignmentReasons: string[];
}

export function RemoveTherapyStrategyModal(props: AddTherapyStrategyModalProps) {
  const { isOpen, onClose, onSave, therapyStrategy, unassignmentReasons } = props;

  const onCloseHandler = () => {
    reset();
    onClose();
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm<TherapyStrategyRemoveForm>({
    defaultValues: {
      assignmentId: therapyStrategy.assignmentID,
      discontinuationReason: undefined,
      additionalInformation: '',
    },
  });

  const onSubmitHandler = async (removedTherapyStrategy: TherapyStrategyRemoveForm) => {
    onSave({
      ...removedTherapyStrategy,
      discontinuationReason: removedTherapyStrategy.discontinuationReason.value,
    } as TherapyStrategyRemove);
    onClose();
  };

  useEffect(() => {
    reset({
      assignmentId: therapyStrategy.assignmentID,
    });
  }, [isOpen]);

  return (
    <Modal portal isOpen={isOpen}>
      <ModalContent>
        <form onSubmit={handleSubmit(onSubmitHandler)} data-testid="form-remove-therapy-strategy">
          <ModalHeader>
            <div tw="flex flex-col items-center justify-center mb-8">
              <div tw="flex items-center justify-center w-12 h-12 mx-auto bg-indigo-100 rounded-full mb-5">
                <FontAwesomeIcon tw="text-2xl text-indigo-700" icon="file-alt" />
              </div>
              <h3 tw="text-lg text-center font-medium text-gray-900 leading-6 mb-5" id="modal-headline">
                Remove {therapyStrategy.assignmentTitle} ({therapyStrategy.learningPathContentfulTreatmentArea})
              </h3>
              <h4 tw="text-gray-900 text-center">
                Discontinue this therapy strategy from your client’s session documentation if you are no longer
                practicing it in your sessions. You can always add it back in the future.
              </h4>
            </div>
            <CloseButton onClick={() => onCloseHandler()} testId="close-therapy-strategy-top" />
          </ModalHeader>
          <ModalBody>
            <Label htmlFor="discontinuationReason" tw="font-medium text-sm">
              Discontinuation Reason
            </Label>
            <Controller
              name="discontinuationReason"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  inputId="discontinuationReason"
                  options={unassignmentReasons.map(reason => {
                    return { label: reason, value: reason } as SelectOption;
                  })}
                  isSearchable
                  error={errors.discontinuationReason}
                />
              )}
              rules={{ required: true }}
            />

            <div tw="flex items-baseline mt-7">
              <Label tw="font-medium text-sm">Please add any additional information</Label>
              <h4 tw="text-xxs text-gray-400 ml-2">OPTIONAL</h4>
            </div>
            <Textarea
              tw="mt-2 w-full mt-2"
              rows={5}
              {...register('additionalInformation')}
              data-testid="additional-information"
            />

            <ModalFooter className="flex flex-col mt-7">
              <Button
                type="submit"
                variant="primary"
                tw="inline-flex justify-center w-full"
                data-testid="remove-therapy-strategy-submit"
              >
                Remove Therapy Strategy
              </Button>
              <Button
                type="button"
                variant="secondary"
                tw="inline-flex justify-center w-full mt-4"
                onClick={onCloseHandler}
                data-testid="close-therapy-strategy-bottom"
              >
                Close
              </Button>
            </ModalFooter>
          </ModalBody>
        </form>
      </ModalContent>
    </Modal>
  );
}
