import * as Sentry from '@sentry/react';
import { isUndefined } from 'lodash';

export const initializeSentry = () => {
  const sandboxId = process.env.REACT_APP_SANDBOX_ID;

  if (isUndefined(sandboxId) || sandboxId === 'local-machine') {
    console.warn('Disabling sentry for local or unknown environment');
    return;
  }

  const isProd = sandboxId === 'production';

  Sentry.init({
    dsn: 'https://9e89f10f288decbfce248b6d991cde42@o4506723807526912.ingest.us.sentry.io/4506870506651648',
    environment: sandboxId,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        networkDetailAllowUrls: [''],
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: isProd ? 1.0 : 0.0, // Capture 100% of sessions in prod, none in lower envs
    replaysOnErrorSampleRate: 1.0, // Capture 100% of error sessions in all envs
  });
};
