// behavior: if the radio option is truthy, then show the free text input
// if the radio option is falsy, then hide the free text input

import { Input, Label, Textarea } from '@expressable/ui-library';
import { useFormValidation } from 'domain/form-validation';
import useOptional from 'domain/form-validation/useOptional';
import { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { getSchemaFieldAttributes } from 'utils/fieldAttributes';
import { HelperText } from '../HelperText';

type Props = {
  label: string;
  name: string;
  when: string;
  containerClass?: string;
  optional?: boolean;
  customOptionsGroup?: { value: string; label: string }[];
  componentContainerClass?: string;
  inputClass?: string;
  isLongText?: boolean;
  relevantLabel?: string;
  hint?: string;
  detailsHint?: string;
  onChange?: any;
};

const DEFAULT_RADIO_OPTIONS = [
  { value: 'no', label: 'No' },
  { value: 'yes', label: 'Yes' },
];

export default function FormFieldRadioFreeText({
  label,
  name,
  when,
  isLongText = false,
  customOptionsGroup,
  containerClass,
  componentContainerClass,
  inputClass,
  optional: manualOptional,
  relevantLabel,
  hint,
  detailsHint,
  onChange
}: Props) {
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext();

  const watchRelevant = useWatch({ control, name: name });
  const { schema, serverErrors } = useFormValidation();
  const schemaFieldAttributes = useMemo(() => getSchemaFieldAttributes(schema, name), [schema, name]);
  const serverError = serverErrors[name]; 

  const optional = useOptional(name, manualOptional);

  return (
    <div className={containerClass}>
      <div className={componentContainerClass}>
        <Label optional={optional} className="mb-2">
          {label}
        </Label>

        <div className="flex">
          {DEFAULT_RADIO_OPTIONS.map(option => {
            const id = `${name}-${option.value}`;
            return (
              <div className="flex gap-2 mr-6" key={option.value}>
                <Input
                  id={id}
                  type="radio"
                  spacing="tight"
                  {...schemaFieldAttributes}
                  {...register(`${name}.relevant`, { required: false })}
                  key={option.value}
                  value={option.value}
                />
                <label htmlFor={id}>{option.label}</label>
              </div>
            );
          })}
        </div>
        {watchRelevant?.relevant === when && (
          <div className={containerClass}>
            {relevantLabel && <Label className="mt-4 mb'2">{relevantLabel}</Label>}
            <div className={componentContainerClass}>
              {isLongText ? (
                <Textarea className="mt-4" {...schemaFieldAttributes} {...register(`${name}.details`)} rows={4} onChange={onChange}/>
              ) : (
                <Input
                  className={`w-full ${relevantLabel ? 'mt-0' : 'mt-4'} text-sm`}
                  spacing="tight"
                  {...schemaFieldAttributes}
                  {...register(`${name}.details`, {
                    shouldUnregister: false,
                    required: false,
                  })}
                  id={`${name}-details`}
                />
              )}
            </div>

            {detailsHint && <HelperText>{detailsHint}</HelperText>}
          </div>
        )}
        {errors[name] && <span className="text-red-500 text-xs italic">{errors[name].message}</span>}
      </div>

      {hint && <HelperText>{hint}</HelperText>}

      {errors[name] && <span className="flex text-xs text-red-500 italic mt-1">{errors[name].message}</span>}
      {serverError && <span className={'flex text-xs text-red-500 italic mt-1'}>{serverError}</span>}
    </div>
  );
}
