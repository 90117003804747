import { Button, FormGroup, FormInline, Input, Label, Select } from '@expressable/ui-library';
import { Dispatch, MutableRefObject, SetStateAction, useEffect } from 'react';
import { buildAddressString, getPhoneNumber, getPhoneType } from 'utils/helpers';
import { Contact, SelectOption } from 'types';
import 'twin.macro';
import { faCircleNotch, faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { intakeTypeOptions } from 'pages/new-client-create/BillingInformation/options';
import { useFormContext, useWatch } from 'react-hook-form';
import usStatesOptions from 'utils/us-states.json';
import { timezoneOptions } from 'hooks/common/useDisplayTimezone/options';
import { isAdult } from '../form';
import dayjs from 'dayjs';
import { contactRelationshipOptions, phoneTypeOptions } from 'pages/new-client-create/ClientInformation/options';
import { parsePhoneNumber } from 'libphonenumber-js';
import { UseMutationResult } from 'react-query';

export const DuplicateContactFinder = ({
  setSelectedContact,
  setIsDuplicatedContactsSettled,
  isDuplicatedContactsSettled,
  previousPhoneNumber,
  contact,
  currentPhoneNumber,
  phoneQuery,
}: {
  setSelectedContact: Dispatch<SetStateAction<Contact | null>>;
  setIsDuplicatedContactsSettled: Dispatch<SetStateAction<boolean>>;
  isDuplicatedContactsSettled: boolean;
  previousPhoneNumber: MutableRefObject<string | null>;
  contact: Contact | null;
  currentPhoneNumber?: string;
  phoneQuery: UseMutationResult<Contact[], unknown>;
}) => {
  const { setValue: setFormValue, reset, getValues } = useFormContext();
  const { data: availablePhoneNumbers = [], mutate: findContacts, isLoading, isSuccess, isError } = phoneQuery;

  const watchDateOfBirth = useWatch({ name: 'dateOfBirth' });
  const watchPhoneType = useWatch({ name: 'phoneType' });
  const watchPhone = useWatch({ name: 'phone' });
  const watchIntakeType = useWatch({ name: 'intakeType' });

  const phone = watchPhone ?? currentPhoneNumber ?? '';

  const onChangePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValue('phone', e.target.value);
  };

  const onChangePhoneType = (option: SelectOption) => {
    setFormValue('phoneType', option);
  };

  const findDuplicates = (e: React.FormEvent) => {
    e.preventDefault();
    findContacts(phone);
  };

  const onChangeIntakeType = (option: SelectOption) => {
    setFormValue('intakeType', option);
  };

  const onChangeRelationshipToClient = (option: SelectOption) => {
    setFormValue('relationshipToClient', option);
  };

  const onSelectContact = (contact: Contact) => {
    setFormValue('crmID', contact.contactID);
    setFormValue('contactName', contact.contactFirstName);
    setFormValue('contactLastName', contact.contactLastName);
    setFormValue('contactMiddleName', contact.contactMiddleName);
    setFormValue('phone', contact.mobilePhone?.replace(/^\+?1/, ''));
    setFormValue('email', contact.email);
    setFormValue('address1', contact.address?.street);
    setFormValue('address2', contact.address?.line2);
    setFormValue('city', contact.address?.city);
    setFormValue(
      'state',
      usStatesOptions.find(option => option.value === contact.address?.state),
    );
    setFormValue('zipCode', contact.address?.postalCode ?? '');
    setFormValue('contactMiddleName', '');
    setFormValue(
      'contactTimezone',
      timezoneOptions.find(option => option.local.toLowerCase() === contact.contactTimeZone.toLowerCase()),
    );

    previousPhoneNumber.current = contact.mobilePhone?.replace(/^\+?1/, '') ?? '';

    if (watchDateOfBirth)
      setFormValue('intakeType', isAdult(dayjs(watchDateOfBirth)) ? intakeTypeOptions[1] : intakeTypeOptions[0]);

    reset({ ...getValues() });
    setSelectedContact(contact);
  };

  const setFormDefault = () => {
    setFormValue('phone', phone);
    setFormValue('phoneType', watchPhoneType);
    setIsDuplicatedContactsSettled(true);

    previousPhoneNumber.current = phone;
    reset({ ...getValues() });
  };

  useEffect(() => {
    if (isSuccess && !availablePhoneNumbers.length && !isDuplicatedContactsSettled) setFormDefault();
  }, [isSuccess, availablePhoneNumbers.length, isDuplicatedContactsSettled]);

  return (
    <>
      <FormInline className="w-4/5">
        <FormGroup>
          <Label htmlFor="phone" className="text-md mr-2">
            Phone
          </Label>
          <Input
            type="tel"
            onChange={onChangePhone}
            value={phone}
            spacing="tight"
            placeholder="(512) 234-5890"
            className="w-1/7"
          />
        </FormGroup>
        <FormGroup tw="w-[250px] ml-4 mr-2">
          <Label htmlFor="phoneType" className="text-md">
            Type
          </Label>
          <Select
            spacing="tight"
            className="w-full"
            name="phoneType"
            options={phoneTypeOptions}
            defaultValue={phoneTypeOptions[1]}
            value={watchPhoneType}
            onChange={onChangePhoneType}
          />
        </FormGroup>
        <FormGroup className="justify-end">
          <Button
            onClick={findDuplicates}
            disabled={isLoading || !phone}
            tw="ml-4 mr-2 h-[38px]"
            loading={isLoading}
            variant="secondary"
            data-testid="search-contacts"
          >
            Search for Contacts
          </Button>
        </FormGroup>
      </FormInline>

      {Boolean(!availablePhoneNumbers.length) && isLoading && (
        <FormInline className="text-gray-400">
          <div tw="text-[1em] mr-2">
            <FontAwesomeIcon icon={faCircleNotch} spin />
          </div>
          Checking contacts for duplicates...
        </FormInline>
      )}

      {isError && !isLoading && (
        <FormInline className="text-gray-400">
          <div tw="text-[1em] mr-2">
            <FontAwesomeIcon icon={faClose} tw="text-red-500" />
          </div>
          Invalid phone number. Please enter a valid phone number.
        </FormInline>
      )}

      {Boolean(availablePhoneNumbers.length) && !isLoading && (
        <>
          <div className="w-2/6 space-y-4">
            <div className="flex gap-6">
              <h2 className="font-semibold text-base">
                Duplicate contacts found. Verify email address and other contact information to confirm:
              </h2>
            </div>
            <div className="flex flex-row bg-red-100 p-4 rounded">
              If you are unable to confirm correct contact information for your new client, use a different phone
              number.
            </div>
          </div>
          <div>
            {availablePhoneNumbers.map(availableContact => {
              const phoneNumber = getPhoneNumber(availableContact.phone);
              const phoneType = getPhoneType(availableContact);

              return (
                <div className="mb-2" key={availableContact.contactID}>
                  <FormInline>
                    <Input
                      onChange={() => onSelectContact(availableContact)}
                      className="mr-2"
                      type="radio"
                      spacing="tight"
                      name={availableContact.contactID}
                      id={availableContact.contactID}
                      value={availableContact.mobilePhone}
                      checked={contact?.contactID === availableContact.contactID}
                    />
                    <Label className="text-base cursor-pointer" htmlFor={availableContact.contactID}>
                      {availableContact.contactFirstName} {availableContact.contactLastName}{' '}
                      {availableContact.email && <>({availableContact.email})</>}
                    </Label>
                  </FormInline>
                  <div className="ml-4">
                    {phoneNumber && (
                      <p className="text-gray-400">
                        {parsePhoneNumber(phoneNumber, 'US').formatNational()} ({phoneType})
                      </p>
                    )}
                    <p className="text-gray-400">
                      {buildAddressString(availableContact.address)} ({availableContact.contactTimeZone})
                    </p>
                    {availableContact.relatedClients?.map(client => {
                      return (
                        <p key={client.clientInfo.clientID} className="text-gray-400">
                          {client.relationshipToClient} to {client.clientInfo.clientFirstName}{' '}
                          {client.clientInfo.clientLastName}
                        </p>
                      );
                    })}
                  </div>
                </div>
              );
            })}

            {contact && (
              <div className="mt-8">
                <FormGroup>
                  <Label className="text-base">Relationship to Client</Label>
                  <div tw="flex gap-6">
                    <Select
                      tw={'min-w-[250px] max-w-[250px] mb-4'}
                      name="intakeType"
                      label="Select Intake Email/SMS"
                      options={contactRelationshipOptions}
                      onChange={onChangeRelationshipToClient}
                    />
                  </div>
                </FormGroup>

                <FormGroup>
                  <Label className="text-base">Select Intake Method</Label>
                  <div className="flex gap-6">
                    <Select
                      tw={'min-w-[250px] max-w-[250px] mb-4'}
                      name="intakeType"
                      label="Select Intake Email/SMS"
                      options={intakeTypeOptions}
                      onChange={onChangeIntakeType}
                      value={watchIntakeType}
                    />
                  </div>
                </FormGroup>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};
