import React, { useEffect, useState } from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import tw from 'twin.macro';
import { Link, Loader, useDisclosure } from '@expressable/ui-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AddDailyRoutineActivityModal } from './daily-routine-activities/add-modal';
import { DailyRoutineActivityCard } from './daily-routine-activities/card-item';
import { HomePracticeNote, ICompleteClientInformation } from 'types';
import {
  ClinicalCareExperienceEntryId,
  ContentfulSpaces,
  useContentfulEntries,
  useContentfulEntry,
} from 'hooks/use-contentful';
import {
  useAddClientAssignments,
  useGetClientAssignments,
  useRemoveClientAssignment,
} from 'hooks/use-client-assignments';
import {
  ActivityOfDailyLivingContentful,
  ClientAssignment,
  CustomAcademyHomeworkContentful,
  CustomStructuredActivityContentful,
  DailyRoutineActivityRemove,
  StructuredActivityRemove,
} from 'domain/clinical-pathways/types';
import { RemoveDailyRoutineActivityModal } from './daily-routine-activities/remove-modal';
import { HomePracticeSection } from './home-practice-section';
import { AddStructuredActivityModal } from './structured-activities/add-modal';
import { StructuredActivityCard } from './structured-activities/card-item';
import { RemoveStructuredActivityModal } from './structured-activities/remove-modal';
import { AddCustomStructuredActivityModal } from './structured-activities/add-custom-modal';

interface UnassignmentReasonsContentful {
  dropdownContent: string[];
}

type Props = {
  clientData?: ICompleteClientInformation;
  clientId: string;
  noteID: string | null;
  isNoteLoading: boolean;
  currentTherapyStrategies: ClientAssignment[];
  currentHomePractice: HomePracticeNote;
  onChangeDailyRoutineActivity: ({
    dailyRoutineActivities,
    homePractice,
    skipAutosave,
  }: {
    dailyRoutineActivities: ClientAssignment[];
    homePractice: HomePracticeNote;
    skipAutosave?: boolean;
  }) => void;
  onChangeStructuredActivity: ({
    structuredActivities,
    homePractice,
    skipAutosave,
  }: {
    structuredActivities: ClientAssignment[];
    homePractice: HomePracticeNote;
    skipAutosave?: boolean;
  }) => void;
};

export const HomePractices = ({
  clientData,
  clientId,
  noteID,
  currentTherapyStrategies,
  currentHomePractice,
  isNoteLoading,
  onChangeDailyRoutineActivity,
  onChangeStructuredActivity,
}: Props): JSX.Element => {
  const [dailyRoutineActivityToRemove, setDailyRoutineActivityToRemove] = useState<ClientAssignment>();
  const [structuredActivityToRemove, setStructuredActivityToRemove] = useState<ClientAssignment>();
  const [assignmentsVerified, setAssignmentsVerified] = useState<boolean>(false);
  const [homePractice, setHomePractice] = useState<HomePracticeNote>({
    shouldAssignNewPractice: false,
    shouldFocusIncompleteAssignments: false,
  });

  // it gets unassignment reasons from contentful
  const { data: unassignmentReasonsContentful, isLoading: isUnassignmentReasonsContentfulLoading } =
    useContentfulEntry<UnassignmentReasonsContentful>({
      entryId: ClinicalCareExperienceEntryId.UnassignmentReasons,
    });

  // it gets activities of daily living from contentful
  const { data: activitiesOfDailyLivingContentful, isLoading: isActivitiesOfDailyLivingContentfulLoading } =
    useContentfulEntries<ActivityOfDailyLivingContentful[]>({
      contentType: 'activityOfDailyLiving',
      space: ContentfulSpaces.ClinicalCareExperience,
    });

  // it gets client assignments for ADLs
  const { data: clientAssignmentADLs, isLoading: isClientAssignmentADLsLoading } = useGetClientAssignments({
    clientId: clientId,
    assignmentTypes: ['activity-of-daily-living'],
    status: ['incomplete', 'complete'],
  });

  // it gets client assignments for STAs
  const { data: clientAssignmentSTAs, isLoading: isClientAssignmentSTALoading } = useGetClientAssignments({
    clientId: clientId,
    assignmentTypes: ['structured-activity', 'custom-structured-activity'],
    status: ['incomplete', 'complete'],
  });

  // it removes client assignment
  const { mutate: removeClientAssignment } = useRemoveClientAssignment();

  // it adds a list of client assignments
  const { mutateAsync: addClientAssignments } = useAddClientAssignments();

  const {
    isOpen: AddDailyRoutineActivityModalIsOpen,
    onClose: AddDailyRoutineActivityModalOnClose,
    onOpen: AddDailyRoutineActivityModalOnOpen,
  } = useDisclosure();

  const {
    isOpen: AddStructuredActivityModalIsOpen,
    onClose: AddStructuredActivityModalOnClose,
    onOpen: AddStructuredActivityModalOnOpen,
  } = useDisclosure();

  const {
    isOpen: AddCustomStructuredActivityModalIsOpen,
    onClose: AddCustomStructuredActivityModalOnClose,
    onOpen: AddCustomStructuredActivityModalOnOpen,
  } = useDisclosure();

  const {
    isOpen: RemoveDailyRoutineActivityModalIsOpen,
    onClose: RemoveDailyRoutineActivityModalOnClose,
    onOpen: RemoveDailyRoutineActivityModalOnOpen,
  } = useDisclosure();

  const {
    isOpen: RemoveStructuredActivityModalIsOpen,
    onClose: RemoveStructuredActivityModalOnClose,
    onOpen: RemoveStructuredActivityModalOnOpen,
  } = useDisclosure();

  // activity of daily living assignments
  const newAssignmentADLs = clientAssignmentADLs?.items?.filter(item => item.noteID === noteID) || [];
  const incompleteAssignmentADLs =
    clientAssignmentADLs?.items?.filter(item => item.noteID !== noteID && item.status == 'incomplete') || [];

  // structured activity assignments
  const newAssignmentSTAs = clientAssignmentSTAs?.items?.filter(item => item.noteID === noteID) || [];
  const incompleteAssignmentSTAs =
    clientAssignmentSTAs?.items?.filter(item => item.noteID !== noteID && item.status === 'incomplete') || [];

  const getDailyRoutineActivitiesToSave = ({
    homePracticeUpdated,
    newAssignmentsUpdated,
    incompleteAssignmentsUpdated,
  }: {
    homePracticeUpdated: HomePracticeNote;
    newAssignmentsUpdated: ClientAssignment[];
    incompleteAssignmentsUpdated: ClientAssignment[];
  }) => {
    let itemsToSave: ClientAssignment[] = [];
    if (homePracticeUpdated.shouldAssignNewPractice) {
      itemsToSave = [...newAssignmentsUpdated?.map(item => mapToDailyRoutineActivityNote(item))];
    }
    if (homePracticeUpdated.shouldFocusIncompleteAssignments) {
      itemsToSave = [...itemsToSave, ...incompleteAssignmentsUpdated?.map(item => mapToDailyRoutineActivityNote(item))];
    }
    return itemsToSave;
  };

  const getStructuredActivitiesToSave = ({
    homePracticeUpdated,
    newAssignmentsUpdated,
    incompleteAssignmentsUpdated,
  }: {
    homePracticeUpdated: HomePracticeNote;
    newAssignmentsUpdated: ClientAssignment[];
    incompleteAssignmentsUpdated: ClientAssignment[];
  }) => {
    let itemsToSave: ClientAssignment[] = [];
    if (homePracticeUpdated.shouldAssignNewPractice) {
      itemsToSave = [...newAssignmentsUpdated?.map(item => mapToStructuredActivityNote(item))];
    }
    if (homePracticeUpdated.shouldFocusIncompleteAssignments) {
      itemsToSave = [...itemsToSave, ...incompleteAssignmentsUpdated?.map(item => mapToStructuredActivityNote(item))];
    }
    return itemsToSave;
  };

  const handleDailyRoutineActivityModalSave = async ({
    therapyStrategy,
    activityOfDailyLiving,
  }: {
    therapyStrategy: ClientAssignment;
    activityOfDailyLiving: ActivityOfDailyLivingContentful;
  }) => {
    // create client assignments that was sent from the modal
    const createdClientAssignments = await addClientAssignments({
      clientId: clientId,
      assignmentType: 'activity-of-daily-living',
      contentfulEntries: [
        {
          learningPathID: therapyStrategy.learningPathContentfulEntryID,
          activityOfDailyLivingID: activityOfDailyLiving.id,
          activityOfDailyLivingType: activityOfDailyLiving.setting,
          assignmentTitle: `${therapyStrategy.assignmentTitle} ${activityOfDailyLiving.setting}`,
          // contentful returns this data in rich text format, that's why we have to store as a json plain text
          // it will be necessary for a later display rendering format
          assignmentDescription: JSON.stringify(activityOfDailyLiving.introduction),
        },
      ],
      noteID: noteID,
    });

    // we follow the standard for the session-note and trigger the auto-save every time something changes
    const dailyRoutineActivitiesToSave = getDailyRoutineActivitiesToSave({
      homePracticeUpdated: { ...homePractice },
      newAssignmentsUpdated: [...newAssignmentADLs, ...createdClientAssignments],
      incompleteAssignmentsUpdated: [...incompleteAssignmentADLs],
    });
    onChangeDailyRoutineActivity({
      dailyRoutineActivities: [...dailyRoutineActivitiesToSave],
      homePractice: { ...homePractice },
    });
  };

  const handleStructuredActivityModalSave = async ({
    academyHomeworks,
  }: {
    academyHomeworks: CustomAcademyHomeworkContentful[];
  }) => {
    // create client assignments that was sent from the modal
    const createdClientAssignments = await addClientAssignments({
      clientId: clientId,
      assignmentType: 'structured-activity',
      contentfulEntries: academyHomeworks.map(homework => {
        return {
          structuredActivityID: homework.id,
          assignmentTitle: homework.title,
          assignmentDescription: homework.aboutThisActivity,
          materialId: homework.selectedMaterial?.materialId,
          materialTitle: homework.selectedMaterial?.materialTitle,
          materialUrl: homework.selectedMaterial?.materialUrl,
        };
      }),
      noteID: noteID,
    });

    // we follow the standard for the session-note and trigger the auto-save every time something changes
    const structuredActivitiesToSave = getStructuredActivitiesToSave({
      homePracticeUpdated: { ...homePractice },
      newAssignmentsUpdated: [...newAssignmentSTAs, ...createdClientAssignments],
      incompleteAssignmentsUpdated: [...incompleteAssignmentSTAs],
    });
    onChangeStructuredActivity({
      structuredActivities: [...structuredActivitiesToSave],
      homePractice: { ...homePractice },
    });
  };

  const handleCustomSAModalSave = async ({
    customSAs,
  }: {
    customSAs: Partial<CustomStructuredActivityContentful>[];
  }) => {
    // create client assignments that was sent from the modal
    const createdClientAssignments = await addClientAssignments({
      clientId: clientId,
      assignmentType: 'custom-structured-activity',
      contentfulEntries: customSAs.map(custom => {
        return {
          customStructuredActivityID: custom.id,
          assignmentTitle: custom.title!,
          assignmentDescription: custom.practiceOverview,
          materialId: custom.selectedMaterial?.materialId,
          materialTitle: custom.selectedMaterial?.materialTitle,
          materialUrl: custom.selectedMaterial?.materialUrl,
        };
      }),
      noteID: noteID,
    });

    // we follow the standard for the session-note and trigger the auto-save every time something changes
    const customStructuredActivitiesToSave = getStructuredActivitiesToSave({
      homePracticeUpdated: { ...homePractice },
      newAssignmentsUpdated: [...newAssignmentSTAs, ...createdClientAssignments],
      incompleteAssignmentsUpdated: [...incompleteAssignmentSTAs],
    });
    onChangeStructuredActivity({
      structuredActivities: [...customStructuredActivitiesToSave],
      homePractice: { ...homePractice },
    });
  };

  const handleDailyRoutineActivityRemove = (removedDailyRoutineActivity: DailyRoutineActivityRemove) => {
    setDailyRoutineActivityToRemove(undefined);

    // remove assignment
    removeClientAssignment({
      clientId: clientId,
      assignmentId: removedDailyRoutineActivity.assignmentId,
      discontinuationReason: removedDailyRoutineActivity.discontinuationReason,
      additionalInformation: removedDailyRoutineActivity.additionalInformation,
    });

    // it filters out from the list the removed daily routine activity
    const newAssignmentsUpdated = newAssignmentADLs.filter(
      item => item.assignmentID !== removedDailyRoutineActivity.assignmentId,
    );
    const incompleteAssignmentsUpdated = incompleteAssignmentADLs.filter(
      item => item.assignmentID !== removedDailyRoutineActivity.assignmentId,
    );

    // we follow the standard for the session-note and trigger the auto-save every time something changes
    const dailyRoutineActivitiesToSave = getDailyRoutineActivitiesToSave({
      homePracticeUpdated: { ...homePractice },
      newAssignmentsUpdated: [...newAssignmentsUpdated],
      incompleteAssignmentsUpdated: [...incompleteAssignmentsUpdated],
    });
    onChangeDailyRoutineActivity({
      dailyRoutineActivities: [...dailyRoutineActivitiesToSave],
      homePractice: { ...homePractice },
    });
  };

  const handleStructuredActivityRemove = (removedStructuredActivity: StructuredActivityRemove) => {
    setStructuredActivityToRemove(undefined);

    // remove assignment
    removeClientAssignment({
      clientId: clientId,
      assignmentId: removedStructuredActivity.assignmentId,
      discontinuationReason: removedStructuredActivity.discontinuationReason,
      additionalInformation: removedStructuredActivity.additionalInformation,
    });

    // it filters out from the list the removed structured activity
    const newAssignmentsUpdated = newAssignmentSTAs.filter(
      item => item.assignmentID !== removedStructuredActivity.assignmentId,
    );
    const incompleteAssignmentsUpdated = incompleteAssignmentSTAs.filter(
      item => item.assignmentID !== removedStructuredActivity.assignmentId,
    );

    // we follow the standard for the session-note and trigger the auto-save every time something changes
    const structuredActivitiesToSave = getStructuredActivitiesToSave({
      homePracticeUpdated: { ...homePractice },
      newAssignmentsUpdated: [...newAssignmentsUpdated],
      incompleteAssignmentsUpdated: [...incompleteAssignmentsUpdated],
    });
    onChangeStructuredActivity({
      structuredActivities: [...structuredActivitiesToSave],
      homePractice: { ...homePractice },
    });
  };

  const handleCheckboxChangeForNewPractice = () => {
    const homePracticeUpdated = { ...homePractice, shouldAssignNewPractice: !homePractice.shouldAssignNewPractice };
    setHomePractice({ ...homePracticeUpdated });

    // we follow the standard for the session-note and trigger the auto-save every time something changes
    const dailyRoutineActivitiesToSave = getDailyRoutineActivitiesToSave({
      homePracticeUpdated: { ...homePracticeUpdated },
      newAssignmentsUpdated: [...newAssignmentADLs],
      incompleteAssignmentsUpdated: [...incompleteAssignmentADLs],
    });
    onChangeDailyRoutineActivity({
      dailyRoutineActivities: [...dailyRoutineActivitiesToSave],
      homePractice: { ...homePracticeUpdated },
    });

    // we follow the standard for the session-note and trigger the auto-save every time something changes
    const structuredActivitiesToSave = getStructuredActivitiesToSave({
      homePracticeUpdated: { ...homePracticeUpdated },
      newAssignmentsUpdated: [...newAssignmentSTAs],
      incompleteAssignmentsUpdated: [...incompleteAssignmentSTAs],
    });
    onChangeStructuredActivity({
      structuredActivities: [...structuredActivitiesToSave],
      homePractice: { ...homePracticeUpdated },
    });
  };

  const handleCheckboxChangeForIncompleteAssignments = () => {
    const homePracticeUpdated = {
      ...homePractice,
      shouldFocusIncompleteAssignments: !homePractice.shouldFocusIncompleteAssignments,
    };
    setHomePractice({ ...homePracticeUpdated });

    // we follow the standard for the session-note and trigger the auto-save every time something changes
    const dailyRoutineActivitiesToSave = getDailyRoutineActivitiesToSave({
      homePracticeUpdated: { ...homePracticeUpdated },
      newAssignmentsUpdated: [...newAssignmentADLs],
      incompleteAssignmentsUpdated: [...incompleteAssignmentADLs],
    });
    onChangeDailyRoutineActivity({
      dailyRoutineActivities: [...dailyRoutineActivitiesToSave],
      homePractice: { ...homePracticeUpdated },
    });

    // we follow the standard for the session-note and trigger the auto-save every time something changes
    const structuredActivitiesToSave = getStructuredActivitiesToSave({
      homePracticeUpdated: { ...homePracticeUpdated },
      newAssignmentsUpdated: [...newAssignmentSTAs],
      incompleteAssignmentsUpdated: [...incompleteAssignmentSTAs],
    });
    onChangeStructuredActivity({
      structuredActivities: [...structuredActivitiesToSave],
      homePractice: { ...homePracticeUpdated },
    });
  };

  const isLoading =
    isClientAssignmentADLsLoading ||
    isClientAssignmentSTALoading ||
    isUnassignmentReasonsContentfulLoading ||
    isActivitiesOfDailyLivingContentfulLoading ||
    isNoteLoading;

  useEffect(() => {
    if (!isClientAssignmentADLsLoading && !isClientAssignmentSTALoading && !isNoteLoading && !assignmentsVerified) {
      const defaultHomePractice = currentHomePractice ?? {
        shouldAssignNewPractice: false,
        shouldFocusIncompleteAssignments: false,
      };

      // once ADLs are added, they are carried over to every new session note until they are removed
      const dailyRoutineActivitiesToSave = getDailyRoutineActivitiesToSave({
        homePracticeUpdated: { ...defaultHomePractice },
        newAssignmentsUpdated: [...newAssignmentADLs],
        incompleteAssignmentsUpdated: [...incompleteAssignmentADLs],
      });
      // update the note but skip the auto-save. We don't want to trigger the auto-save when it is loaded for the first time. Otherwise, the client will be charged automatically.
      onChangeDailyRoutineActivity({
        dailyRoutineActivities: [...dailyRoutineActivitiesToSave],
        homePractice: { ...defaultHomePractice },
        skipAutosave: true,
      });

      // once STAs are added, they are carried over to every new session note until they are removed
      const structuredActivitiesToSave = getStructuredActivitiesToSave({
        homePracticeUpdated: { ...defaultHomePractice },
        newAssignmentsUpdated: [...newAssignmentSTAs],
        incompleteAssignmentsUpdated: [...incompleteAssignmentSTAs],
      });
      // update the note but skip the auto-save. We don't want to trigger the auto-save when it is loaded for the first time. Otherwise, the client will be charged automatically.
      onChangeStructuredActivity({
        structuredActivities: [...structuredActivitiesToSave],
        homePractice: { ...defaultHomePractice },
        skipAutosave: true,
      });

      // update the home practice from session note
      setHomePractice({ ...defaultHomePractice });

      // assignments will be verified once
      setAssignmentsVerified(true);
    }
  }, [isClientAssignmentADLsLoading, isClientAssignmentSTALoading, isNoteLoading]);

  // this is a business rule, we need to wait until the auto-saved is triggered for the first time
  // we are required to save therapy strategies with noteID
  if (!noteID) {
    return (
      <div tw="flex justify-center items-center">
        <h4 tw="text-sm text-gray-400 ml-2">Waiting for the note to be saved...</h4>
      </div>
    );
  }

  if (!noteID || isLoading) {
    return (
      <div tw="flex justify-center items-center">
        <Loader type="ring" />
      </div>
    );
  }

  return (
    <>
      <div tw="flex mt-10">
        <h4 tw="text-sm font-semibold">Home Practice</h4>
        <h4 tw="text-sm text-gray-400 ml-2">
          What should your client work on between sessions? Content will be shared with Client
        </h4>
      </div>

      <AddDailyRoutineActivityModal
        clientData={clientData}
        currentTherapyStrategies={currentTherapyStrategies}
        currentDailyRoutineActivities={[...newAssignmentADLs, ...incompleteAssignmentADLs].filter(
          item => item.assignmentType === 'activity-of-daily-living',
        )}
        activitiesOfDailyLivingContentful={activitiesOfDailyLivingContentful || []}
        onClose={AddDailyRoutineActivityModalOnClose}
        onSave={savedTherapyStrategy => {
          handleDailyRoutineActivityModalSave(savedTherapyStrategy);
        }}
        isOpen={AddDailyRoutineActivityModalIsOpen}
      />

      <AddStructuredActivityModal
        currentTherapyStrategies={currentTherapyStrategies}
        currentStructuredActivities={[...newAssignmentSTAs, ...incompleteAssignmentSTAs].filter(
          item => item.assignmentType === 'structured-activity',
        )}
        onClose={AddStructuredActivityModalOnClose}
        onSave={savedHomeworks => {
          handleStructuredActivityModalSave(savedHomeworks);
        }}
        isOpen={AddStructuredActivityModalIsOpen}
        onAddCustomActivity={() => {
          AddStructuredActivityModalOnClose();
          AddCustomStructuredActivityModalOnOpen();
        }}
      />

      <AddCustomStructuredActivityModal
        clientId={clientId}
        currentCustomSAs={[...newAssignmentSTAs, ...incompleteAssignmentSTAs].filter(
          item => item.assignmentType === 'custom-structured-activity',
        )}
        onClose={AddCustomStructuredActivityModalOnClose}
        onSave={savedCustomStructuredActivities => {
          handleCustomSAModalSave(savedCustomStructuredActivities);
        }}
        isOpen={AddCustomStructuredActivityModalIsOpen}
        onBrowseStructuredActivity={() => {
          AddCustomStructuredActivityModalOnClose();
          AddStructuredActivityModalOnOpen();
        }}
      />

      {dailyRoutineActivityToRemove && (
        <RemoveDailyRoutineActivityModal
          onClose={RemoveDailyRoutineActivityModalOnClose}
          onSave={removedDailyRoutineActivity => {
            handleDailyRoutineActivityRemove(removedDailyRoutineActivity);
          }}
          isOpen={RemoveDailyRoutineActivityModalIsOpen}
          clientAssignment={dailyRoutineActivityToRemove}
          unassignmentReasons={unassignmentReasonsContentful?.dropdownContent || []}
        />
      )}

      {structuredActivityToRemove && (
        <RemoveStructuredActivityModal
          onClose={RemoveStructuredActivityModalOnClose}
          onSave={removedStructuredActivity => {
            handleStructuredActivityRemove(removedStructuredActivity);
          }}
          isOpen={RemoveStructuredActivityModalIsOpen}
          clientAssignment={structuredActivityToRemove}
          unassignmentReasons={unassignmentReasonsContentful?.dropdownContent || []}
        />
      )}

      <div tw="mt-8">
        <HomePracticeSection
          id="assign-new-practice-activity"
          title="Assign a new practice activity"
          isChecked={!!homePractice?.shouldAssignNewPractice}
          onCheckboxChange={handleCheckboxChangeForNewPractice}
        >
          <div tw="flex items-start justify-between">
            <div id="daily-routine-activities" tw="w-1/2">
              <div>
                <h4 tw="text-sm font-semibold">Daily Routine Activity</h4>
                <h4 tw="text-sm text-gray-400 mt-2">
                  Incorporate a therapy strategy into your client’s everyday routine and generalize what they’re
                  learning. Your client is likely to practice more often because activities of daily living (ADLs) occur
                  every day or multiple times a week.
                </h4>
              </div>

              <div tw="flex mt-8">
                <span tw="text-xs font-semibold">
                  <Link onClick={AddDailyRoutineActivityModalOnOpen} to="#" tw="text-indigo-700">
                    <FontAwesomeIcon tw="text-xs mr-2" icon="plus-circle" />
                    Add Daily Routine Activity ({activitiesOfDailyLivingContentful?.length || 0} Available)
                  </Link>
                </span>
              </div>
            </div>

            <div tw="h-32 ml-5 mr-12 border border-solid border-gray-100"></div>

            <div id="structured-activities" tw="w-1/2">
              <div>
                <h4 tw="text-sm font-semibold">Structured Activity</h4>
                <h4 tw="text-sm text-gray-400 mt-2">
                  A structured activity is a way to practice speech and language that includes elements such as
                  worksheets, games, and other materials, either provided as a download or found around the home.
                </h4>
              </div>

              <div tw="flex mt-8">
                <span tw="text-xs font-semibold">
                  <Link onClick={AddStructuredActivityModalOnOpen} to="#" tw="text-indigo-700">
                    <FontAwesomeIcon tw="text-xs mr-2" icon="plus-circle" />
                    Add Structured Activity (+400 Available)
                  </Link>
                </span>
              </div>
            </div>
          </div>

          {(newAssignmentADLs?.length > 0 || newAssignmentSTAs?.length > 0) && (
            <div tw="flex flex-row flex-wrap mt-8">
              {newAssignmentADLs.map(item => {
                return (
                  <DailyRoutineActivityCard
                    key={item.assignmentID}
                    clientAssignment={item}
                    onRemove={() => {
                      // open modal to remove daily routine activity
                      setDailyRoutineActivityToRemove(item);
                      RemoveDailyRoutineActivityModalOnOpen();
                    }}
                  />
                );
              })}
              {newAssignmentSTAs.map(item => {
                return (
                  <StructuredActivityCard
                    key={item.assignmentID}
                    clientAssignment={item}
                    onRemove={() => {
                      // open modal to remove structured activity
                      setStructuredActivityToRemove(item);
                      RemoveStructuredActivityModalOnOpen();
                    }}
                  />
                );
              })}
            </div>
          )}
        </HomePracticeSection>

        <HomePracticeSection
          id="focus-incomplete-assignments"
          title="Client should focus on incomplete assignments"
          isChecked={
            !!homePractice?.shouldFocusIncompleteAssignments &&
            (incompleteAssignmentADLs?.length > 0 || incompleteAssignmentSTAs?.length > 0)
          }
          isDisabled={incompleteAssignmentADLs?.length <= 0 && incompleteAssignmentSTAs?.length <= 0}
          onCheckboxChange={handleCheckboxChangeForIncompleteAssignments}
        >
          {(incompleteAssignmentADLs?.length > 0 || incompleteAssignmentSTAs?.length > 0) && (
            <div tw="flex flex-row flex-wrap">
              {incompleteAssignmentADLs.map(item => {
                return (
                  <DailyRoutineActivityCard
                    key={item.assignmentID}
                    clientAssignment={item}
                    shouldDisplayDate={true}
                    onRemove={() => {
                      // open modal to remove daily routine activity
                      setDailyRoutineActivityToRemove(item);
                      RemoveDailyRoutineActivityModalOnOpen();
                    }}
                  />
                );
              })}
              {incompleteAssignmentSTAs.map(item => {
                return (
                  <StructuredActivityCard
                    key={item.assignmentID}
                    clientAssignment={item}
                    shouldDisplayDate={true}
                    onRemove={() => {
                      // open modal to remove structured activity
                      setStructuredActivityToRemove(item);
                      RemoveStructuredActivityModalOnOpen();
                    }}
                  />
                );
              })}
            </div>
          )}
        </HomePracticeSection>
      </div>
    </>
  );
};

const mapToDailyRoutineActivityNote = (assignment: ClientAssignment): ClientAssignment => {
  return {
    assignmentID: assignment.assignmentID,
    assignmentType: assignment.assignmentType,
    learningPathContentfulEntryID: assignment.learningPathContentfulEntryID!,
    activityOfDailyLivingContentfulEntryID: assignment.activityOfDailyLivingContentfulEntryID!,
    assignmentTitle: assignment.assignmentTitle,
    assignmentDescription: assignment.assignmentDescription!,
    activityOfDailyLivingType: assignment.activityOfDailyLivingType!,
    noteID: assignment.noteID,
    createdAt: assignment.createdAt,
  } as ClientAssignment;
};

const mapToStructuredActivityNote = (assignment: ClientAssignment): ClientAssignment => {
  return {
    assignmentID: assignment.assignmentID,
    assignmentType: assignment.assignmentType,
    structuredActivityContentfulEntryID: assignment.structuredActivityContentfulEntryID,
    customStructuredActivityContentfulEntryID: assignment.customStructuredActivityContentfulEntryID,
    assignmentTitle: assignment.assignmentTitle,
    assignmentDescription: assignment.assignmentDescription,
    materialContentfulEntryID: assignment.materialContentfulEntryID,
    materialContentfulTitle: assignment.materialContentfulTitle,
    materialContentfulUrl: assignment.materialContentfulUrl,
    noteID: assignment.noteID,
    createdAt: assignment.createdAt,
  } as ClientAssignment;
};
