import { CancelRecurringAppointmentPayload } from 'hooks/use-appointments';

export const RECURRING_FOREVER = 'Forever';

export const DAY_SELECT_OPTIONS = [
  { label: 'Mondays', value: '1' },
  { label: 'Tuesdays', value: '2' },
  { label: 'Wednesdays', value: '3' },
  { label: 'Thursdays', value: '4' },
  { label: 'Fridays', value: '5' },
  { label: 'Saturdays', value: '6' },
  { label: 'Sundays', value: '0' },
];

export const CANCELATION_REASONS_INITIAL_STATE: CancelRecurringAppointmentPayload = {
  canceledBy: '',
  cancelationReason: '',
  cancelationReasonOther: '',
  rescheduleAttempted: false,
  additionalText: '',
};

export const enum RecurringAppointmentsActionType {
  Reassign = 'reassign',
  Reschedule = 'reschedule',
}

export const enum RecurringAppointmentsKeywordErrors {
  NotFound = 'ModelNotFoundError',
}
