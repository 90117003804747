import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { useToasts } from 'react-toast-notifications';
import { Button, Modal, ModalBody, ModalContent, ModalHeader, ModalFooter } from '@expressable/ui-library';
import * as Sentry from '@sentry/react';
import 'twin.macro';

export interface ShareLinkModalProps {
  isOpen: boolean;
  onClose: () => void;
  sharableLink: string;
  isError: boolean;
  isLoading: boolean;
  shareFile: (s3Link: string) => Promise<void>;
}

export function ShareLinkModal(props: ShareLinkModalProps) {
  const { isOpen, onClose, sharableLink, isError, isLoading, shareFile } = props;
  const { addToast } = useToasts();

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(sharableLink)
      .then(() => {
        addToast('Sharable link successfully copied', { appearance: 'success', autoDismiss: true }),
          setTimeout(() => {
            onClose();
          }, 500);
      })
      .catch(err => {
        Sentry.captureException(err);
        addToast('Something Went Wrong', { appearance: 'error', autoDismiss: true });
      });
  };

  const reGenerateUrl = () => {
    shareFile(sharableLink);
  };

  return (
    <Modal isOpen={isOpen} tw="max-w-lg	">
      <ModalContent>
        <ModalHeader>
          <div tw="absolute top-0 right-0 pt-4 pr-4">
            <button
              data-testid="share-link-modal-close"
              type="button"
              tw="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={onClose}
            >
              <span tw="sr-only">Close</span>
              <svg
                tw="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </ModalHeader>
        <ModalBody>
          <div>
            <div tw="flex items-center justify-center w-12 h-12 mx-auto bg-indigo-100 rounded-full">
              {/* <!-- Heroicon name: check --> */}
              <FontAwesomeIcon tw="text-2xl text-indigo-700" icon="file-alt" />
            </div>
            <div tw="my-3 sm:mt-5">
              <h3 tw="text-lg text-center font-medium text-gray-900 leading-6" id="modal-headline">
                Generate Shareable Link
              </h3>
              <div tw="mt-6 mb-1">
                <span>
                  {sharableLink && !isLoading && !isError ? (
                    <div>
                      <a
                        href={sharableLink}
                        tw="text-indigo-700 text-sm font-medium break-all"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {sharableLink}
                      </a>
                      <div tw="flex items-center justify-center mb-10 mt-5">
                        This sharable link will expire in 7 days
                      </div>
                    </div>
                  ) : (
                    <div tw="flex space-x-2 items-center justify-center text-gray-400 -ml-2 text-sm leading-3">
                      {isError && !isLoading ? (
                        <>
                          <div tw="flex space-x-2 items-center justify-center mb-5 text-red-500 font-bold text-base">
                            <span>
                              <FontAwesomeIcon icon={faExclamationCircle} />
                            </span>
                            <div> Sorry, Something Went Wrong</div>
                          </div>
                        </>
                      ) : (
                        <>
                          <span>
                            <FontAwesomeIcon tw="text-xs ml-2 text-gray-400 text-center" icon={faCircleNotch} spin />
                          </span>
                          <div> Generating</div>
                        </>
                      )}
                    </div>
                  )}
                </span>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div tw="mt-5 sm:mt-6">
            <span tw="flex w-full rounded-md shadow-sm">
              <Button
                data-testid="share-link-modal-copy-link"
                type="submit"
                variant="primary"
                onClick={isError ? reGenerateUrl : copyToClipboard}
                disabled={isLoading}
                tw="inline-flex justify-center w-full px-4 py-2 text-base font-medium leading-6 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
              >
                {`${isError ? 'Try Again' : 'Copy Shareable Link to Clipboard'}`}
              </Button>
            </span>
          </div>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
