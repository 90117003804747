import { useFormContext } from 'react-hook-form';
import { useFormValidation } from 'domain/form-validation';
import { Button, ButtonProps } from '@expressable/ui-library';

type Props = {
  /**
   * If the submit handler will redirect after a successful submission, the
   * button will retain its loading state while the route transition happens.
   *
   * Set this to `false` if there's no redirect expected after submission.
   */
  willRedirect?: boolean;
} & Partial<ButtonProps>;

export const SubmitButton = ({ willRedirect = true, loading = false, ...props }: Props): JSX.Element => {
  const { formState } = useFormContext();
  const { disabled } = useFormValidation();
  const { isSubmitting, isValidating, isSubmitSuccessful } = formState;
  const isLoading = loading || isValidating || isSubmitting || (willRedirect && isSubmitSuccessful);

  return <Button disabled={disabled} variant="primary" type="submit" loading={isLoading} {...props} />;
};
