import React from 'react';
import tw, { TwStyle } from 'twin.macro';

type Props = {
  title: string;
  spacing: string;
  fontWeight: string;
  fontSize: string;
};

export default function FormGroupTitle({ title, spacing, fontWeight, fontSize }: Props) {
  const spacingStyle: { [key: string]: TwStyle } = {
    large: tw`mb-3`,
    normal: tw`mb-2`,
    small: tw`mb-1`,
    tight: tw`mb-0`,
  };

  const sizeStyle: { [key: string]: TwStyle } = {
    small: tw`text-sm`,
    normal: tw`text-base`,
    large: tw`text-lg`,
    extraLarge: tw`text-xl`,
  };

  const fontWeightStyle: { [key: string]: TwStyle } = {
    semi: tw`font-semibold`,
    bold: tw`font-bold`,
    normal: tw`font-normal`,
  };

  return <h4 css={[spacingStyle[spacing], sizeStyle[fontSize], fontWeightStyle[fontWeight]]}>{title}</h4>;
}
