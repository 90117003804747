import React from 'react';
import { Badge, Button, Menu, MenuButton, MenuList, MenuItem } from '@expressable/ui-library';
import dayjs from 'dayjs';
import tw from 'twin.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleNotch,
  faCheckCircle,
  faCalendarTimes,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import {
  NoShowPendingAppointmentPayload,
  ReschedulePendingAppointmentPayload,
  useNoShowPendingAppointment,
  useReschedulePendingAppointment,
} from 'hooks/use-pending-appointments';
import { AppointmentDetail, UpcomingAppointmentItem } from 'types';

interface AppointmentItemCardProps {
  appointment: UpcomingAppointmentItem;
  appointmentDetail?: AppointmentDetail;
  showAttendanceActions?: boolean;
  canDismissAppointment?: boolean;
  onClientAttended: () => void;
  onReschedule: (
    reschedule: (payload: ReschedulePendingAppointmentPayload) => Promise<void>,
    acuityId: string,
    appointmentDateTime: string,
  ) => void;
  onCancel: () => void;
  onNoShow: (noShow: (payload: NoShowPendingAppointmentPayload) => Promise<void>, acuityId: string) => void;
  onDismiss: () => void;
}

type BadgeVariants = 'warning' | 'success' | 'disabled' | 'waiting' | 'screening';
const AppointmentBadge = ({ variant, text }: { variant: BadgeVariants; text: string }) => (
  <Badge variant={variant} tw="ml-4 inline-block uppercase">
    {text}
  </Badge>
);

const TooltipNoUrl = () => (
  <div tw="inline-block relative">
    <FontAwesomeIcon tw="text-gray-700 text-sm ml-2" icon="exclamation-circle" />
    <div tw="border border-solid bg-white rounded-lg absolute border-gray-200 left-0 shadow-md w-96 text-xs p-3 z-10 hidden group-hover:block">
      <p>We cannot find a CRM link for this prospect.</p>
      <br />
      <p>The most likely reason is that the prospect has changed their email when rescheduling an appointment.</p>
      <br />

      <p tw="pointer-events-auto">
        To find this prospect, try searching their name or phone number at&nbsp;
        <a
          href="http://crm.expressable.io/prospects"
          target="_blank"
          rel="noopener noreferrer"
          tw="mb-1 text-indigo-700 text-xs text-right"
        >
          crm.expressable.io/prospects
        </a>
      </p>
      <br />
      <p tw="pointer-events-auto">
        If you cannot find them, you can create a new record for them at&nbsp;
        <a
          href="http://crm.expressable.io/prospects/manual/create"
          target="_blank"
          rel="noopener noreferrer"
          tw="mb-1 text-indigo-700 text-xs text-right"
        >
          crm.expressable.io/prospects/manual/create
        </a>
      </p>
    </div>
  </div>
);

const AuthorizationsTooltip = ({
  evaluationStatus,
  sessionStatus,
}: {
  evaluationStatus?: string;
  sessionStatus?: string;
}) => {
  return (
    <div tw="inline-block relative" data-testid="dashboard-authorizations-tooltip">
      <FontAwesomeIcon tw="text-indigo-700 text-sm ml-2" icon="exclamation-circle" />
      <div tw="border border-solid bg-white rounded-lg absolute border-gray-200 left-0 shadow-md w-96 text-xs p-3 z-10 hidden group-hover:block">
        <>
          {(evaluationStatus === 'awaiting' || sessionStatus === 'awaiting') && (
            <p tw="text-black">
              Authorization is pending submission or being reviewed by the insurance provider. Updates will be added to
              admin notes as available.
            </p>
          )}
          {(evaluationStatus === 'denied' || sessionStatus === 'denied') && (
            <p tw="text-black">
              Authorization has been denied. Additional detail, if available, will be available in the client admin
              notes.
            </p>
          )}
        </>
      </div>
    </div>
  );
};

const AppointmentItemCard = ({
  appointment,
  appointmentDetail,
  showAttendanceActions,
  canDismissAppointment,
  onClientAttended,
  onReschedule,
  onCancel,
  onNoShow,
  onDismiss,
}: AppointmentItemCardProps) => {
  const { mutateAsync: reschedulePendingAppointment, isLoading: isRescheduleLoading } =
    useReschedulePendingAppointment();
  const { mutateAsync: noShowPendingAppointment, isLoading: isNoShowLoading } = useNoShowPendingAppointment();

  const disconnected = !(appointment.url && appointment.id);
  const noConsultationUrl = !appointment.url;

  const sessionAuthStatus = appointment?.insuranceAuthorizations?.sessionAuthStatus;
  const evaluationAuthStatus = appointment?.insuranceAuthorizations?.evaluationAuthStatus;

  const authorizationVisitLimit = appointment?.insuranceAuthorizations?.remainingVisitsLeft;

  const authorizationDateLimit =
    appointment?.insuranceAuthorizations?.visitsCompletedBy &&
    dayjs(appointment?.insuranceAuthorizations?.visitsCompletedBy).isSameOrBefore(
      dayjs(appointment?.appointmentDateTime).format('MM/DD/YYYY'),
    );

  const isLoading = () => {
    return isNoShowLoading || isRescheduleLoading;
  };

  const shouldDisplayActions = () => {
    return !isLoading() && appointmentDetail && showAttendanceActions && ['pending'].includes(appointmentDetail.status);
  };

  const AttendanceActionsButton = (appointmentDetail: AppointmentDetail) => {
    const { acuityAppointmentID, appointmentDateTime } = appointmentDetail;
    return (
      <Menu tw="ml-4 text-sm" data-testid="dashboard-appointment-action">
        <MenuButton
          tw="flex items-center justify-center border-gray-200"
          variant="secondary"
          data-testid="dashboard-appointment-action-btn"
        >
          Actions
          <FontAwesomeIcon tw="text-gray-700 text-sm ml-2" icon="caret-down" />
        </MenuButton>
        <MenuList>
          <MenuItem
            data-testid="dashboard-appointment-reschedule-button"
            onClick={() => onReschedule(reschedulePendingAppointment, acuityAppointmentID, appointmentDateTime)}
          >
            Rescheduled
          </MenuItem>
          <MenuItem data-testid="dashboard-appointment-canceled-button" onClick={() => onCancel()}>
            Canceled
          </MenuItem>
          <MenuItem
            data-testid="dashboard-appointment-noshowed-button"
            onClick={() => onNoShow(noShowPendingAppointment, acuityAppointmentID)}
          >
            No-Showed
          </MenuItem>
          {canDismissAppointment && <MenuItem onClick={() => onDismiss()}>Dismiss</MenuItem>}
        </MenuList>
      </Menu>
    );
  };

  return (
    <li data-testid="dashboard-appointment-item">
      {appointment.appointmentType.includes('Consultation') ? (
        noConsultationUrl ? (
          <div tw="p-4" className="group">
            <div tw="text-sm text-gray-700">
              {JSON.stringify(appointment.firstName) === '{}' ? '' : appointment.firstName}{' '}
              {JSON.stringify(appointment.lastName) === '{}' ? '' : appointment.lastName}
              <TooltipNoUrl />
            </div>
            <span tw="inline-block text-sm text-black">
              {appointment.appointmentDateTime !== undefined
                ? dayjs(appointment.appointmentDateTime).format('hh:mm A')
                : 'Missing date and time'}
            </span>
            <AppointmentBadge variant="waiting" text="Consult" />
          </div>
        ) : (
          <Link tw="block p-4 hover:bg-indigo-100" to={{ pathname: appointment.url }} target="_blank">
            <div tw="text-sm text-indigo-700">
              {JSON.stringify(appointment.firstName) === '{}' ? '' : appointment.firstName}{' '}
              {JSON.stringify(appointment.lastName) === '{}' ? '' : appointment.lastName}
            </div>
            <span tw="inline-block text-sm text-black">
              {appointment.appointmentDateTime !== undefined
                ? dayjs(appointment.appointmentDateTime).format('hh:mm A')
                : 'Missing date and time'}
            </span>
            <AppointmentBadge variant="waiting" text="Consult" />
          </Link>
        )
      ) : appointment.appointmentType.includes('Speech Therapy Screening') ? (
        noConsultationUrl ? (
          <div tw="p-4">
            <div tw="text-sm text-gray-700">
              {JSON.stringify(appointment.firstName) === '{}' ? '' : appointment.firstName}{' '}
              {JSON.stringify(appointment.lastName) === '{}' ? '' : appointment.lastName}
              <TooltipNoUrl />
            </div>
            <span tw="inline-block text-sm text-black">
              {appointment.appointmentDateTime !== undefined
                ? dayjs(appointment.appointmentDateTime).format('hh:mm A')
                : 'Missing date and time'}
            </span>
            <AppointmentBadge variant="screening" text="Screening" />
          </div>
        ) : (
          <Link tw="block p-4 hover:bg-indigo-100" to={{ pathname: appointment.url }} target="_blank">
            <div tw="text-sm text-indigo-700">
              {JSON.stringify(appointment.firstName) === '{}' ? '' : appointment.firstName}{' '}
              {JSON.stringify(appointment.lastName) === '{}' ? '' : appointment.lastName}
            </div>
            <span tw="inline-block text-sm text-black">
              {appointment.appointmentDateTime !== undefined
                ? dayjs(appointment.appointmentDateTime).format('hh:mm A')
                : 'Missing date and time'}
            </span>
            <AppointmentBadge variant="screening" text="Screening" />
          </Link>
        )
      ) : (
        <Link
          css={[tw`block p-4 hover:bg-indigo-100`, disconnected && tw`pointer-events-none`]}
          to={{ pathname: `/clients/${appointment.id}` }}
          tw="flex place-content-between"
        >
          <div>
            <div css={[tw`text-sm`, disconnected ? tw`text-gray-700` : tw`text-indigo-700`]}>
              {JSON.stringify(appointment.firstName) === '{}' ? '' : appointment.firstName}{' '}
              {JSON.stringify(appointment.lastName) === '{}' ? '' : appointment.lastName}
              {appointment.appointmentType.includes('Evaluation') &&
                (evaluationAuthStatus === 'awaiting' || evaluationAuthStatus === 'denied') && (
                  <div className="group" tw="inline">
                    <AuthorizationsTooltip evaluationStatus={evaluationAuthStatus} />
                  </div>
                )}
              {appointment.appointmentType.includes('Speech Therapy Session') &&
                (sessionAuthStatus === 'awaiting' || sessionAuthStatus === 'denied') && (
                  <div className="group" tw="inline">
                    <AuthorizationsTooltip sessionStatus={sessionAuthStatus} />
                  </div>
                )}
              {disconnected && <FontAwesomeIcon tw="text-gray-700 text-sm ml-2" icon="exclamation-circle" />}
            </div>
            <span tw="inline-block text-sm text-black">
              {appointment.appointmentDateTime !== undefined
                ? dayjs(appointment.appointmentDateTime).format('hh:mm A')
                : 'Missing date and time'}
            </span>
            {appointment.appointmentType.includes('Evaluation') && (
              <>
                <AppointmentBadge variant="warning" text="Evaluation" />
                {evaluationAuthStatus === 'awaiting' && (
                  <div tw="inline-block sm:flex-auto">
                    <FontAwesomeIcon icon={faExclamationTriangle} tw="text-yellow-700 ml-0 sm:ml-1 lg:ml-4" />
                    <span tw="text-xs text-yellow-700 font-semibold ml-2 uppercase">awaiting authorization</span>
                  </div>
                )}
                {evaluationAuthStatus === 'denied' && (
                  <div tw="inline-block sm:flex-auto">
                    <FontAwesomeIcon icon={faExclamationTriangle} tw="text-red-500 ml-0 sm:ml-1 lg:ml-4" />
                    <span tw="text-xs text-red-500 font-semibold ml-2 uppercase">authorization denied</span>
                  </div>
                )}
                {evaluationAuthStatus !== 'awaiting' && evaluationAuthStatus !== 'denied' && (
                  <div tw="block sm:inline">
                    {authorizationVisitLimit === 0 && (
                      <div tw="inline-block sm:flex-auto">
                        <FontAwesomeIcon icon={faExclamationTriangle} tw="text-red-500 ml-0 sm:ml-1 lg:ml-4" />
                        <span tw="text-xs text-red-500 font-semibold ml-2 uppercase">
                          authorization visit limit reached
                        </span>
                      </div>
                    )}
                    {authorizationDateLimit && (
                      <div tw="inline-block sm:flex-auto">
                        <FontAwesomeIcon icon={faExclamationTriangle} tw="text-red-500 ml-0 sm:ml-1 lg:ml-4" />
                        <span tw="text-xs text-red-500 font-semibold ml-2 uppercase">
                          authorization date limit reached
                        </span>
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
            {appointment.appointmentType.includes('Speech Therapy Session') && (
              <>
                <AppointmentBadge variant="success" text="Therapy" />
                {sessionAuthStatus === 'awaiting' && (
                  <div tw="inline-block sm:flex-auto">
                    <FontAwesomeIcon icon={faExclamationTriangle} tw="text-yellow-700 ml-0 sm:ml-1 lg:ml-4" />
                    <span tw="text-xs text-yellow-700 font-semibold ml-2 uppercase">awaiting authorization</span>
                  </div>
                )}
                {sessionAuthStatus === 'denied' && (
                  <div tw="inline-block sm:flex-auto">
                    <FontAwesomeIcon icon={faExclamationTriangle} tw="text-red-500 ml-0 sm:ml-1 lg:ml-4" />
                    <span tw="text-xs text-red-500 font-semibold ml-2 uppercase">authorization denied</span>
                  </div>
                )}
                {sessionAuthStatus !== 'awaiting' && sessionAuthStatus !== 'denied' && (
                  <div tw="block sm:inline">
                    {authorizationVisitLimit === 0 && (
                      <div tw="inline-block sm:flex-auto">
                        <FontAwesomeIcon icon={faExclamationTriangle} tw="text-red-500 ml-0 sm:ml-1 lg:ml-4" />
                        <span tw="text-xs text-red-500 font-semibold ml-2 uppercase">
                          authorization visit limit reached
                        </span>
                      </div>
                    )}
                    {authorizationDateLimit && (
                      <div tw="inline-block sm:flex-auto">
                        <FontAwesomeIcon icon={faExclamationTriangle} tw="text-red-500 ml-0 sm:ml-1 lg:ml-4" />
                        <span tw="text-xs text-red-500 font-semibold ml-2 uppercase">
                          authorization date limit reached
                        </span>
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
          {isLoading() && (
            <div tw="absolute inset-0 bg-white opacity-50 flex justify-center items-center">
              <FontAwesomeIcon tw="text-2xl text-gray-800" icon={faCircleNotch} spin />
            </div>
          )}
          {shouldDisplayActions() && (
            <div tw="flex items-center" onClick={e => e.preventDefault()}>
              <Button
                variant="primary"
                tw="text-sm"
                data-testid="dashboard-client-attended-button"
                onClick={() => onClientAttended()}
              >
                Client Attended
              </Button>
              <AttendanceActionsButton {...appointmentDetail!} />
            </div>
          )}
          {!shouldDisplayActions() && (
            <div tw="flex items-center" onClick={e => e.preventDefault()}>
              {appointmentDetail?.status === 'no-showed' && (
                <>
                  <div tw="flex items-center bg-red-100 rounded-full h-6 w-6">
                    <FontAwesomeIcon icon={faCalendarTimes} tw="mx-1.5 h-3 text-red-500" />
                  </div>
                  <span tw="ml-3 text-sm text-gray-400">Client No-Showed</span>
                </>
              )}
              {appointmentDetail?.status === 'client-attended' && (
                <>
                  <FontAwesomeIcon tw="text-xl text-green-500" icon={faCheckCircle} />
                  <span tw="ml-3 text-sm text-gray-400">Client Attended</span>
                </>
              )}
            </div>
          )}
        </Link>
      )}
    </li>
  );
};

export default AppointmentItemCard;
