import React from 'react';
import { UnlockedNoteInfo } from 'types';
import { BadgeDays } from 'components/appointments/badge-appointment';
import 'twin.macro';
import { Link } from '@expressable/ui-library';

const UnlockedAppointments = ({
  unlockedAppointments,
  type,
}: {
  unlockedAppointments: UnlockedNoteInfo[];
  type: 'evaluations' | 'sessions' | 'discharge';
}) => {
  return (
    <div tw="mb-8" data-testid="unlocked-appointment-notes">
      <div tw="mb-2 text-base font-semibold text-lg capitalize">Unlocked {type}</div>
      {unlockedAppointments.length > 0 ? (
        unlockedAppointments.map((appointment, index) => (
          <div key={`${appointment.clientID}-${appointment.overdueDays}-${index}`}>
            <BadgeDays count={appointment.overdueDays} />
            <Link tw="text-indigo-700 text-sm font-semibold ml-2" to={{ pathname: `/clients/${appointment.clientID}` }}>
              {appointment.clientFirstName ? `${appointment.clientFirstName} ${appointment.clientLastName}` : 'Client'}
            </Link>
          </div>
        ))
      ) : (
        <span tw="text-gray-500">No unlocked notes, nice job!</span>
      )}
    </div>
  );
};

export default UnlockedAppointments;
