import { useMutation } from 'react-query';

import { dynamicHttp } from '@expressable/utils';

export interface ShareLinkResponse {
  key: string;
  s3Link: string;
  createdAt: string;
  expiresAt: string;
}

const shareLink = async (s3Link: string): Promise<ShareLinkResponse> => {
  const http = dynamicHttp('health-record-proxy');
  const { data } = await http.post('/link-sharing/share', { s3Link });
  return data;
};

export default function useShareLink() {
  return useMutation(shareLink);
}
