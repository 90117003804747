import { useIdleTimer } from 'react-idle-timer';
import { Auth } from 'aws-amplify';
import * as Sentry from '@sentry/react';

interface IdleTimerContainerProps {
  minutes: number;
}

const handleIdle = async () => {
  try {
    await Auth.signOut();

    if (caches) {
      const names = await caches.keys();
      await Promise.all(names.map(name => caches.delete(name)));
    }

    window.location.reload();
  } catch (error) {
    console.error('Error signing out: ', error);
    Sentry.captureException(error);
  }
};

export const IdleTimerContainer = ({ minutes }: IdleTimerContainerProps) => {
  const handleOnIdle = async () => {
    handleIdle();
  };

  useIdleTimer({
    timeout: minutes * 60 * 1000,
    onIdle: handleOnIdle,
    crossTab: { emitOnAllTabs: true },
    debounce: 5000,
  });

  return null;
};
