import { Input } from '@expressable/ui-library';
import { FormField } from 'components/Forms';
import { useClient } from 'hooks/use-client';
import { useParams } from 'react-router-dom';
import { formatAge } from 'utils/helpers';

export default function EvaluationOverviewSection() {
  const { clientId } = useParams<{ clientId: string }>();
  const client = useClient(clientId ?? '');
  return (
    <div className="flex-col">
      <h2 className="font-semibold text-xl ">Overview</h2>
      <hr className="mt-1 mb-6 border-gray-200" />

      <div className="flex gap-6 text-sm space-x-4">
        <div className="flex flex-col">
          <h4 className="font-semibold">Chronological Age</h4>
          <p className="mt-2">{client.data?.dob ? formatAge(client.data?.dob) : 'No DOB set'} </p>
        </div>

        <FormField component={Input} label="Adjusted Age" optional name="adjustedAge" />
      </div>
    </div>
  );
}
