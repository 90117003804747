import { useQuery, useMutation, useQueryClient } from 'react-query';
import { dynamicHttp } from '@expressable/utils';
import { useToasts } from 'react-toast-notifications';
import axios from 'axios';
import { presentMutationError } from '@expressable/ui-library';

const http = dynamicHttp('s3-storage');

export interface IFile {
  file: string;
  link: string;
  key: string;
  contentType: string;
}

const getFiles = async (id: string): Promise<IFile[]> => {
  const { data } = await http.get<IFile[]>(`/files/${id}`);
  return data;
};

export function useFiles(id: string) {
  return useQuery<IFile[], Error>(['client-files', id], () => getFiles(id));
}

//TODO adds file name to payload  once we fix permissions errors in back
const getFileByFileName = async (id: string): Promise<IFile[]> => {
  const { data } = await http.get<IFile[]>(`/files/${id}`);
  return data;
};

export function useFileByFileName(id: string) {
  return useQuery<IFile[], Error>(['client-files', id], () => getFileByFileName(id));
}

interface GenerateUploadUrlPayload {
  clientID: string;
  mime: string;
  fileName: string;
  fileExtension: string;
}

const generateUploadUrl = (payload: GenerateUploadUrlPayload) => {
  const { clientID, mime, fileName, fileExtension } = payload;
  return http
    .put(`/files/${clientID}`, {
      mime,
      fileName,
      fileExtension,
    })
    .then(res => res.data);
};

export function useGenerateUploadUrl() {
  return useMutation(generateUploadUrl);
}

interface UploadFilePayload {
  url: string;
  mime: string;
  buffer: Buffer;
  clientID: string;
}

const uploadFile = async (payload: UploadFilePayload) => {
  const { url, mime, buffer } = payload;
  return await axios.put(url, buffer, {
    headers: {
      'Content-Type': mime,
      'Content-Length': buffer.length,
    },
  });
};

export function useUploadFile() {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(uploadFile, {
    onSuccess: (_, payload) => {
      const { clientID } = payload;
      addToast('File Successfully Uploaded', { appearance: 'success', autoDismiss: true });
      queryClient.invalidateQueries(['client-files', clientID]);
    },
    onError: presentMutationError,
  });
}

export interface DeleteFilePayload {
  clientID: string;
  key: string;
}

const deleteFile = async (payload: DeleteFilePayload) => {
  const { clientID, key } = payload;
  return http.delete(`/files/${clientID}/${key}`).then(res => res.data);
};

export function useDeleteFile() {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(deleteFile, {
    onSuccess: (_, payload) => {
      addToast('File Successfully Deleted', { appearance: 'success', autoDismiss: true });
      queryClient.invalidateQueries(['client-files', payload.clientID]);
    },
    onError: presentMutationError,
  });
}
