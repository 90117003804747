import { SelectOption } from 'types';

export interface TimezoneOption extends SelectOption {
  /**
   * Readable label for UI
   */
  label: string;
  /**
   * Standardized zoneinfo value from the iana tz database
   */
  value: string;

  /**
   * Local timezone name as used in the backend.
   */
  local: string;
}

export const TIMEZONE_OPTIONS = {
  HAWAII: {
    label: 'Hawaiian Time Zone',
    value: 'Pacific/Honolulu',
    local: 'Hawaiian',
  },
  ALASKA: {
    label: 'Alaskan Time Zone',
    value: 'America/Anchorage',
    local: 'Alaskan',
  },
  PACIFIC: {
    label: 'Pacific Time Zone',
    value: 'US/Pacific',
    local: 'Pacific',
  },
  MOUNTAIN: {
    label: 'Mountain Time Zone',
    value: 'US/Mountain',
    local: 'Mountain',
  },
  MOUNTAIN_PHOENIX: {
    label: 'Mountain (AZ)',
    value: 'America/Phoenix',
    local: 'Mountain (Arizona / No DST Observed)',
  },
  CENTRAL: {
    label: 'Central Time Zone',
    value: 'US/Central',
    local: 'Central',
  },
  EASTERN: {
    label: 'Eastern Time Zone',
    value: 'US/Eastern',
    local: 'Eastern',
  },
};

export const timezoneOptions: TimezoneOption[] = Object.values(TIMEZONE_OPTIONS);

export const findTimezoneOptionByLabel = (label: string): TimezoneOption | null => {
  const matchedOption = timezoneOptions.find(({ label: l }) => l === label) ?? null;

  return matchedOption;
};
