export enum BILLING_LINK_TYPES {
  EVAL_ONLY = 'EVAL_ONLY',
  EVAL_PLUS_SUBSCRIPTION = 'EVAL_PLUS_SUBSCRIPTION',
  DEPOSIT_ONLY = 'DEPOSIT_ONLY',
  EXEMPT_FROM_PATIENT_RESPONSIBILITY = 'EXEMPT_FROM_PATIENT_RESPONSIBILITY',
}

export const billingLinkTypeOptions = [
  { value: BILLING_LINK_TYPES.EVAL_ONLY, label: 'Self-Pay' },
  { value: BILLING_LINK_TYPES.DEPOSIT_ONLY, label: 'Insurance: $0.50 Deposit' },
  { value: BILLING_LINK_TYPES.EXEMPT_FROM_PATIENT_RESPONSIBILITY, label: 'Insurance: Exempt from patient responsibility' },
];

export const billingPriceTierOptions = [
  { value: 'omckfw', label: 'Default Price: $132 Evaluation / $88 Session' },
  { value: 'czacgv', label: 'Tier 1: $114 Evaluation / $76 Session' },
  { value: 'twlfii', label: 'Tier 2: $96 Evaluation / $64 Session' },
  { value: 'guboxk', label: 'Tier 3: $78 Evaluation / $52 Session' },
  { value: 'zkrefm', label: 'Tier 4: $60 Evaluation / $40 Session' },
];

export const intakeTypeOptions = [
  { value: 'child', label: 'Child' },
  { value: 'adult', label: 'Adult' },
  { value: 'no_intake', label: 'No intake email/SMS' },
];
