import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Button,
  FormGroup,
  FormInline,
  Input,
  Label,
  Link,
  LoadingText,
  Menu,
  MenuItem,
  MenuLink,
  MenuList,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
} from '@expressable/ui-library';
import useCheckboxesState from 'hooks/use-checkboxes-state';
import { ISingleAppointment, ReasonsContentfulEntry, RescheduledBy, SelectOption, Therapist } from 'types';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'twin.macro';
import { capitalize, intersectionWith, isEqual, isUndefined, uniqBy } from 'lodash';
import { APPOINTMENT_TIME_10_AM, displayTimezoneOptions } from './add-appointments';
import { getSelectedTimeDayJs, timeOptions } from 'utils/time-options';
import moment, { Moment } from 'moment';
import { useBulkRescheduleAppointments } from 'hooks/use-appointments';
import { EntryId, useContentfulEntry } from 'hooks/use-contentful';
import { mapEntryToSelectOption } from 'components/client-attendance-modals/log-cancelation-modal';
import FormGroupTitle from 'components/form-group-title';
import { useTherapistAvailableTimesV2 } from 'components/therapistMatcher/data';
import { getDateByWeekDay, moveToNextDay } from 'utils/helpers';
import useTherapists from 'hooks/use-therapists';

dayjs.extend(utc);
dayjs.extend(timezone);

export interface RescheduleMultipleAppointmentsModalProps {
  upcomingAppointments: ISingleAppointment[];
  isOpen: boolean;
  therapistData: Record<string, any>;
  onClose: () => void;
  onAddDischarge: () => void;
  clientId: string;
}

const DAY_SELECT_OPTIONS = [
  { label: 'Monday', value: 'monday' },
  { label: 'Tuesday', value: 'tuesday' },
  { label: 'Wednesday', value: 'wednesday' },
  { label: 'Thursday', value: 'thursday' },
  { label: 'Friday', value: 'friday' },
  { label: 'Saturday', value: 'saturday' },
  { label: 'Sunday', value: 'sunday' },
];

export function RescheduleMultipleAppointmentsModal(props: RescheduleMultipleAppointmentsModalProps) {
  const { isOpen, onClose, clientId, upcomingAppointments: appointments, onAddDischarge, therapistData } = props;
  const [date, setDate] = useState<Moment | null>(moment());

  const [isGuessTimezoneReady, setIsGuessTimezoneReady] = useState<boolean>(false);
  const [day, setDay] = useState<SelectOption>(DAY_SELECT_OPTIONS[0]);
  const [time, setTime] = useState<{ label: string; value: number; nonTzValue: number }>(APPOINTMENT_TIME_10_AM);
  const [isAvailableTimes, setIsAvailableTimes] = useState<boolean>(true);
  const [selectedTherapist, setSelectedTherapist] = useState<SelectOption<Therapist>>({
    label: `${therapistData.firstName} ${therapistData.lastName}`,
    value: therapistData as unknown as Therapist,
  });

  const [rescheduledBy, setRescheduledBy] = useState<RescheduledBy | ''>('');
  const { data: cancelationReasonsEntry } = useContentfulEntry<ReasonsContentfulEntry>({
    entryId: EntryId.RescheduleReasons,
    unwrapArray: true,
  });
  const [rescheduledReason, setRescheduledReason] = useState<string>('');
  const [rescheduledReasonOther, setRescheduledReasonOther] = useState<string | undefined>();

  const [displayTimezone, setDisplayTimezone] = useState<Record<string, string>>(displayTimezoneOptions[0]);

  const [selectedAppointments, setSelectedAppointment, setSelectedAppointments] =
    useCheckboxesState<ISingleAppointment>([]);

  const { data: therapists } = useTherapists('active');
  const { mutateAsync: bulkRescheduleAppointments, isLoading: isBulkRescheduling } = useBulkRescheduleAppointments();
  const { data: availableTimesV2, isLoading } = useTherapistAvailableTimesV2({
    calendarID: selectedTherapist.value?.acuityCalendarID,
    date: dayjs(getDateByWeekDay(day.value, false))?.format('YYYY-MM-DD'),
    type: 'session',
    duration: 30,
  });

  const { current: daysStatus } = useRef<Record<string, boolean | undefined>>({
    monday: undefined,
    tuesday: undefined,
    wednesday: undefined,
    thursday: undefined,
    friday: undefined,
    saturday: undefined,
    sunday: undefined,
  });

  const cancelationReasons = cancelationReasonsEntry?.dropdownContent.map(mapEntryToSelectOption);
  const isSameCalendar = appointments.every(appointment => appointment.calendarName === appointments[0].calendarName);

  const upcomingAppointments = useMemo(() => {
    if (isSameCalendar) return appointments;
    const { value: therapist } = selectedTherapist;
    return appointments.filter(
      appointment =>
        appointment.calendarName === `${therapist?.firstName.toLowerCase()}.${therapist?.lastName.toLowerCase()}`,
    );
  }, [appointments.length, selectedTherapist]);

  const therapistSelectOptions = useMemo(() => {
    return uniqBy(
      therapists
        ?.filter(therapist => {
          if (isSameCalendar)
            return (
              `${therapist.firstName.toLowerCase()}.${therapist.lastName.toLowerCase()}` ===
              upcomingAppointments[0].calendarName
            );

          return appointments.some(appointment =>
            appointment?.calendarName?.includes(
              `${therapist?.firstName.toLowerCase()}.${therapist?.lastName.toLowerCase()}`,
            ),
          );
        })
        .map(therapist => ({
          label: `${therapist.firstName} ${therapist.lastName}`,
          value: therapist,
        })),
      'label',
    );
  }, [therapists?.length, appointments.length]);

  const availableTimesOptions = useMemo(() => {
    return availableTimesV2?.items?.map(availableTime => ({
      label: dayjs(availableTime.time).tz(displayTimezone.value).format('h:mm A'),
      value: getSelectedTimeDayJs(dayjs(availableTime.time).tz(displayTimezone.value)).value,
      nonTzValue: getSelectedTimeDayJs(dayjs(availableTime.time)).value,
    }));
  }, [availableTimesV2, displayTimezone.value]);

  // A simple group of the appointments by day
  // This will help us with the select all appointments by day action
  const appointmentsFilteredByDay = useMemo(() => {
    const appointmentsByDay = upcomingAppointments.reduce((acc: any, appointment) => {
      const day = dayjs(appointment.appointmentDateTime).tz(dayjs.tz.guess()).format('dddd').toLowerCase();
      acc[day] = acc[day] || [];
      acc[day].push(appointment);
      return acc;
    }, []);

    return appointmentsByDay;
  }, [upcomingAppointments.length]);

  const daysSelected = useMemo(() => {
    return Array.from(
      new Set(
        selectedAppointments.map(appointment => {
          return dayjs(appointment.appointmentDateTime).tz(dayjs.tz.guess()).format('dddd').toLowerCase();
        }),
      ),
    );
  }, [selectedAppointments.length]);

  useEffect(() => {
    setDisplayTimezone(() => {
      const guessTimezone =
        displayTimezoneOptions.find(timezone => {
          // for some reason mutating the moment to use the tz makes dst detection effective
          const timezoneArrayOffset = moment.tz(date, timezone.value).utcOffset();
          const appointmentTimeOffset = date?.utcOffset();
          return appointmentTimeOffset === timezoneArrayOffset;
        }) ?? displayTimezoneOptions[1];
      if (guessTimezone) {
        setIsGuessTimezoneReady(true);
      }
      return guessTimezone;
    });
  }, []);

  useEffect(() => {
    const newTime = availableTimesOptions?.find(availableTime => {
      return availableTime.nonTzValue === time?.nonTzValue;
    });

    if (newTime && isAvailableTimes) {
      setTime(newTime);
    }
  }, [displayTimezone.value, isAvailableTimes]);

  useEffect(() => {
    if (availableTimesOptions?.length && isAvailableTimes) setTime(availableTimesOptions[0]);
    else setTime(APPOINTMENT_TIME_10_AM);
  }, [availableTimesOptions?.length, isAvailableTimes]);

  useEffect(() => {
    if (date && isGuessTimezoneReady) {
      date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).tz(displayTimezone.value);
      date.add(time?.value, 'hours');
    }
  }, [time, date, isGuessTimezoneReady, day.value]);

  useEffect(() => {
    //clean up selected appointments if the therapist changes
    setSelectedAppointments([]);
  }, [selectedTherapist.value?.therapistID]);

  const onCheckDay = (checked: boolean, appointment: ISingleAppointment) => {
    setSelectedAppointment(appointment, checked);

    const day = dayjs(appointment.appointmentDateTime).tz(dayjs.tz.guess()).format('dddd').toLowerCase();
    const commons = intersectionWith(appointmentsFilteredByDay[day], selectedAppointments, isEqual);

    if (checked) {
      if (commons.length + 1 === appointmentsFilteredByDay[day].length) {
        daysStatus[day] = true;
      }
    } else {
      if (commons.length - 1 !== appointmentsFilteredByDay[day].length) {
        daysStatus[day] = false;
      }
    }
  };

  const handleOnChangeRescheduledBy = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setRescheduledBy(e.target.value as RescheduledBy);
  }, []);

  const handleSelectAllByDay = (day: string) => {
    const appointments = appointmentsFilteredByDay[day];
    const shouldCheck = daysStatus[day];
    const newSelectedAppointments = !shouldCheck
      ? uniqBy([...selectedAppointments, ...appointments], 'acuityAppointmentID')
      : uniqBy(
          selectedAppointments.filter(appointment => !appointments.includes(appointment)),
          'acuityAppointmentID',
        );

    setSelectedAppointments(newSelectedAppointments);
    setDay(DAY_SELECT_OPTIONS.find(currentDay => currentDay.value === day) ?? DAY_SELECT_OPTIONS[0]);

    daysStatus[day] = !daysStatus[day];
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const selectedAppointmentsWithNewDate = selectedAppointments.map(appointment => {
      if (daysSelected.length === 1) {
        return {
          appointmentID: Number(appointment.acuityAppointmentID),
          dateTime: dayjs(moveToNextDay(appointment.appointmentDateTime, day.value, time?.label))
            .tz(displayTimezone.value)
            .toDate(),
        };
      }

      return {
        appointmentID: Number(appointment.acuityAppointmentID),
        dateTime: dayjs(appointment.appointmentDateTime)
          .tz(displayTimezone.value)
          .set('hour', time?.value)
          .set('minute', Number(time.label.split(':')[1].replace('AM', '').replace('PM', '')))
          .set('second', 0)
          .toDate(),
      };
    });

    await bulkRescheduleAppointments({
      clientID: clientId,
      therapistEmail: selectedTherapist?.value?.therapistID,
      contactTimeZone: displayTimezone.value,
      appointments: selectedAppointmentsWithNewDate,
      rescheduleDetail: {
        rescheduledBy,
        rescheduledReason,
        rescheduledReasonOther,
      },
      override: !isAvailableTimes,
    });

    onClose();
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalContent>
        <form onSubmit={onSubmit}>
          <ModalHeader>
            <div tw="text-right">
              <Link data-testid="edit-appointment-close" to="#" onClick={onClose}>
                <FontAwesomeIcon tw="text-2xl text-gray-600" icon={['far', 'times']} />
              </Link>
            </div>
          </ModalHeader>
          <ModalBody>
            <>
              <div tw="flex items-center justify-center w-12 h-12 mx-auto bg-indigo-100 rounded-full">
                <FontAwesomeIcon tw="text-2xl text-indigo-700" icon="calendar-check" />
              </div>
              <div tw="mt-3 sm:mt-5">
                <h3 tw="text-lg text-center font-medium text-gray-900 leading-6" id="modal-headline">
                  Upcoming Appointments
                </h3>

                <h5
                  tw="text-sm text-center mt-2 text-red-600 leading-6 cursor-pointer"
                  onClick={() => {
                    onClose();
                    onAddDischarge();
                    window.scrollTo(0, 0);
                  }}
                >
                  Discharge Client
                </h5>

                {!isSameCalendar && (
                  <>
                    <div tw="mt-6 mb-1">View appointments with</div>
                    <Select
                      aria-label="appointment-therapist-dropdown"
                      name="appointment-therapist-dropdown"
                      data-testid="add-appointment-therapist"
                      id="appointment-therapist-dropdown"
                      value={selectedTherapist}
                      options={therapistSelectOptions}
                      onChange={(e: SelectOption<Therapist>) => setSelectedTherapist(e)}
                      tw="mb-2"
                    />
                  </>
                )}
                <div className="flex flex-col mt-6 max-h-80">
                  <div className="group flex flex-col">
                    {Object.keys(appointmentsFilteredByDay).map((day, index) => {
                      return (
                        <Link
                          key={day}
                          to="#"
                          tw="text-sm mb-1 ml-1 last:mb-4"
                          onClick={() => handleSelectAllByDay(day)}
                        >
                          {!isUndefined(daysStatus[day]) && daysStatus[day] ? 'De-select ' : 'Select '}
                          All {capitalize(day)} Appointments ({appointmentsFilteredByDay[day].length})
                        </Link>
                      );
                    })}
                  </div>
                  <div className="overflow-y-auto">
                    {upcomingAppointments.map((appointment, index) => (
                      <FormInline key={index} tw=" ml-1 mb-0.5 last:mb-2">
                        <Input
                          checked={selectedAppointments.includes(appointment)}
                          onChange={e => onCheckDay(e.currentTarget.checked, appointment)}
                          id={`appointment-${index}`}
                          data-testid={`appointment-${index}`}
                          type="checkbox"
                          spacing="tight"
                          name="appointments"
                          value={appointment.acuityAppointmentID}
                        />
                        <Label font="normal" htmlFor={`appointment-${index}`} tw="ml-2">
                          {dayjs(appointment.appointmentDateTime)
                            .tz(dayjs.tz.guess())
                            .format('MM/DD/YYYY - ddd - hh:mm A')}
                        </Label>
                      </FormInline>
                    ))}
                  </div>
                </div>
              </div>
            </>
            {selectedAppointments.length > 0 && (
              <div className="mt-6 ml-1">
                <h3 tw="font-medium leading-6" id="modal-headline">
                  Reschedule Appointments
                </h3>
                <div tw="mt-2">
                  <div tw="text-sm  text-gray-500 leading-5">
                    Times are shown in -
                    <Menu
                      onChange={MenuItem => {
                        const selectedMenuItem = MenuItem as Record<string, string>;
                        setDisplayTimezone(selectedMenuItem);
                        if (date && selectedMenuItem) {
                          setDate(date.tz(selectedMenuItem.value, true));
                        }
                      }}
                      tw="px-1 inline"
                    >
                      <MenuLink to="#" data-testid="add-appointments-time-zone">
                        {displayTimezone?.label} Time Zone
                        <FontAwesomeIcon tw="text-sm ml-1" icon="caret-down" />
                      </MenuLink>
                      <MenuList tw="w-full">
                        {displayTimezoneOptions.map((option, index) => (
                          <MenuItem value={option} key={index}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </Menu>
                  </div>
                </div>
                <div tw="w-full">
                  {daysSelected.length === 1 && (
                    <>
                      <div tw="mt-3 flex">
                        <Input
                          data-testid="add-appointment-available-times"
                          name="available-times"
                          id="available-times"
                          type="checkbox"
                          tw="mr-2"
                          checked={isAvailableTimes}
                          onChange={e => setIsAvailableTimes(e.currentTarget.checked)}
                        />
                        <Label font="normal" htmlFor="available-times">
                          Only Show Available Times
                        </Label>
                      </div>
                      {!isAvailableTimes && (
                        <div tw="bg-[#FEE2E2] p-3 rounded-md mb-4">
                          Please note that viewing unavailable times removes all calendar protections and may result in
                          a schedule conflict.
                        </div>
                      )}
                    </>
                  )}

                  {daysSelected.length > 1 ? (
                    <p tw="text-gray-400 mb-6 mt-4">
                      Your current selection has multiple days of the week selected (
                      {daysSelected.map(d => capitalize(d)).join(', ')}). Limit to one day of the week in order to
                      modify the day.
                    </p>
                  ) : (
                    <div tw="w-full mt-2">
                      <div data-testid="add-appointment-time">
                        <Select
                          aria-label="time-dropdown"
                          name="time-dropdown"
                          spacing="small"
                          value={day}
                          options={DAY_SELECT_OPTIONS}
                          onChange={(day: SelectOption) => {
                            setDay(day);
                          }}
                        />
                      </div>
                    </div>
                  )}
                  <div data-testid="add-appointment-time">
                    {isLoading ? (
                      <div data-testid="add-appointment-loading" tw="flex-1 text-center mt-3">
                        <LoadingText />
                      </div>
                    ) : (
                      <Select
                        aria-label="time-dropdown"
                        name="time-dropdown"
                        spacing="small"
                        value={time}
                        options={isAvailableTimes && daysSelected.length === 1 ? availableTimesOptions : timeOptions}
                        onChange={(timeOption: { label: string; value: number; nonTzValue: number }) => {
                          setTime(timeOption);
                        }}
                      />
                    )}
                  </div>
                </div>
                <div tw="mt-6">
                  <FormGroupTitle title="Reschedule initiated by" fontSize="small" fontWeight="semi" spacing="large" />
                  <FormGroup type="inline" tw="items-center">
                    <Input
                      type="radio"
                      id="rescheduledBy-client"
                      name="rescheduledBy"
                      spacing="tight"
                      data-testid="reschedule-by-client"
                      value={RescheduledBy.Client}
                      required
                      onChange={handleOnChangeRescheduledBy}
                      checked={rescheduledBy === RescheduledBy.Client}
                    />
                    <Label font="normal" htmlFor="rescheduledBy-client" tw="ml-2">
                      Client
                    </Label>
                    <Input
                      id="rescheduledBy-therapist"
                      name="rescheduledBy"
                      tw="ml-4"
                      spacing="tight"
                      type="radio"
                      data-testid="reschedule-by-therapist"
                      value={RescheduledBy.Therapist}
                      required
                      onChange={handleOnChangeRescheduledBy}
                      checked={rescheduledBy === RescheduledBy.Therapist}
                    />
                    <Label font="normal" htmlFor="rescheduledBy-therapist" tw="ml-2">
                      Therapist
                    </Label>
                  </FormGroup>
                  <FormGroup tw="mt-6" data-testid="reschedule-reason">
                    <FormGroupTitle title="Reschedule Reason" fontSize="small" fontWeight="semi" spacing="large" />
                    <Select
                      required
                      aria-label="reschedule-reason"
                      name="reschedule-reason"
                      data-testid="reschedule-reason"
                      options={cancelationReasons}
                      onChange={(selectedOption: SelectOption) => {
                        setRescheduledReason(selectedOption.value);
                      }}
                      value={cancelationReasons?.find(reason => reason.value === rescheduledReason)}
                    />
                  </FormGroup>
                  {rescheduledReason === 'other' && (
                    <FormGroup>
                      <FormGroupTitle
                        title="What was the reason?"
                        fontSize="small"
                        fontWeight="semi"
                        spacing="normal"
                      />
                      <Input
                        type="text"
                        tw="w-full"
                        data-testid="reschedule-reason-other"
                        required
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          setRescheduledReasonOther(event.target.value);
                        }}
                        value={rescheduledReasonOther}
                      />
                    </FormGroup>
                  )}
                </div>
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <div tw="mt-5 sm:mt-6">
              <span tw="flex w-full rounded-md shadow-sm sm:col-start-2">
                <Button
                  data-testid="edit-appointment-submit"
                  type="submit"
                  variant="primary"
                  loading={isBulkRescheduling}
                  disabled={
                    isBulkRescheduling ||
                    selectedAppointments.length === 0 ||
                    !time ||
                    !day ||
                    (isAvailableTimes && !availableTimesOptions?.length) ||
                    !rescheduledBy ||
                    !rescheduledReason
                  }
                  tw="inline-flex items-baseline justify-center w-full px-4 py-2  font-medium leading-6 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                >
                  Reschedule {selectedAppointments.length} Appointments
                </Button>
              </span>
            </div>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
