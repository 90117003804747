import { Goal } from 'hooks/use-care-plan-goals';
import {
  DischargeNoteFormData,
} from 'pages/client/components/client-notes/note-form-content/discharge/schema';
import { convertGoalStatusesToDTO } from './goals';
import { DischargeNoteDTO } from './types';
import { GetAdditionalGoalsPayload } from 'hooks/use-care-plan-additional-goals';
import { convertFreeTextRadioToYesOrNoDetails, convertYesOrNoToBoolean } from '../mapHelpers';

export default function mapDischargeDataToDischargeDTO(
  formData: DischargeNoteFormData,
  goals: Goal[],
  additionalGoals: GetAdditionalGoalsPayload[],
  clientID: string,
): DischargeNoteDTO {
  const againstRecommendation = formData.againstRecommendation
    ? convertFreeTextRadioToYesOrNoDetails(formData.againstRecommendation)
    : null;

  const result: DischargeNoteDTO = {
    clientID,
    noteType: 'discharge',
    version: '1.0',
    dischargeOn: formData.dischargeOn,

    note: {
      content: {
        dischargeReason: formData.dischargeReason?.value ?? '',
        mayResume: convertYesOrNoToBoolean(formData.mayResume) ?? undefined,
        anticipatedReturn: formData.anticipatedReturn?.toISOString(),
        againstRecommendation: againstRecommendation?.relevant ?? undefined,
        againstRecommendationDetails: againstRecommendation?.details,
        treatmentSummary: formData.treatmentSummary,
        dischargeMessage: formData.dischargeMessage,
        goalsStatuses: convertGoalStatusesToDTO(formData.goalsStatuses, goals),
        additionalGoalsStatuses: convertGoalStatusesToDTO(formData.additionalGoalsStatuses, additionalGoals),
      },
    },
  };

  return result;
}
