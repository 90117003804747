import { FormGroup, Label } from '@expressable/ui-library';
import TherapistsTimeSelector from 'components/therapistMatcher/TherapistTimeGrid';
import dayjs, { Dayjs } from 'dayjs';
import moment from 'moment';
import { useState } from 'react';
import { SingleDatePicker } from 'react-dates';
import 'twin.macro';
import { TimeSlot } from 'components/therapistMatcher/data';
import { Therapist } from 'types';
import { MAX_DAYS_FOR_RECURRING_SESSION } from 'domain/therapist-matcher/scheduling';

// @TODO(juan): refactor this, its used in way too many different ways

export interface SameTherapistSessionProps {
  value?: TimeSlot | null;
  onChange?: (value: TimeSlot | null) => void;
  onSkip?: () => void;

  therapist: Therapist;
  minDateAllowed: Dayjs;
  allowStartDateChange?: boolean;
  dateCount?: number;
}

const SameTherapistSession = ({
  value,
  onChange,
  onSkip,
  therapist,
  minDateAllowed,
  allowStartDateChange = true,
  dateCount = 7,
}: SameTherapistSessionProps) => {
  const [startDateFocused, setStartDateFocused] = useState(false);
  const [startDate, setStartDate] = useState<Dayjs | null>(minDateAllowed);

  return (
    <div tw="mb-8 flex flex-col gap-4">
      {allowStartDateChange && (
        <div>
          <FormGroup tw="flex-grow md:flex-grow-0">
            <Label htmlFor="startDate" tw="font-semibold text-sm mb-2">
              Start Date
            </Label>
            <div tw="w-40">
              <SingleDatePicker
                noBorder
                block
                openDirection="down"
                numberOfMonths={1}
                small
                date={startDate ? moment(startDate.toISOString()) : null}
                onDateChange={dateValue => {
                  if (dateValue) {
                    setStartDate(dayjs(dateValue.toISOString()));
                  }
                }}
                hideKeyboardShortcutsPanel
                focused={startDateFocused}
                onFocusChange={({ focused }) => setStartDateFocused(focused)}
                id="startDate"
                isOutsideRange={day =>
                  day.isBefore(minDateAllowed.toISOString(), 'days') ||
                  day.isAfter(moment().add(MAX_DAYS_FOR_RECURRING_SESSION, 'days'))
                }
              />
            </div>
          </FormGroup>
        </div>
      )}
      {startDate && (
        <div>
          <TherapistsTimeSelector
            type="session"
            duration={30}
            therapist={therapist}
            dateFilter={startDate}
            dateCount={dateCount}
            value={value}
            onChange={onChange}
          />
        </div>
      )}
    </div>
  );
};

export default SameTherapistSession;
