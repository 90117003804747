import React, { useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  CloseButton,
  FormGroup,
  Input,
  Label,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  Textarea,
} from '@expressable/ui-library';
import { SelectOption, PendingAppointment } from 'types';
import { faCalendarTimes, faChevronRight } from '@fortawesome/free-solid-svg-icons';

import ModalHeadline from 'components/modal-headline';

import 'twin.macro';
import { useForm } from 'react-hook-form';
import RHFSelect from 'components/RHFSelectBase';
import FormGroupTitle from 'components/form-group-title';
import ErrorSpan from 'components/error-span';
import usePendingAppointments, { useCancelPendingAppointment } from 'hooks/use-pending-appointments';
import { clientFullName } from 'utils/appointments-utils';
import { useBillingInfo } from 'hooks/use-billing-information';
import { useDeleteAppointment } from 'hooks/use-appointments';
import { getPrimaryContact } from 'pages/client/components/client-sidebar';
import useTherapist from 'hooks/use-therapist';
import { useClient } from 'hooks/use-client';
import { envValues, fieldIDforPatientID, scheduleUrlDomain, sessionTypeId } from 'domain/client/constants';
import { useToasts } from 'react-toast-notifications';
import { useCreatePendingReschedule } from 'hooks/use-pending-reschedule';
import { PendingRescheduleDTO } from 'domain/pending-reschedule/types';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { getCueError } from 'utils/error';
import { AttendanceErrorKey } from 'domain/log-attedances/constants';
import { shouldSkipAttendanceError } from 'hooks/shared/attendance-utils';
import { EntryId, useContentfulEntry } from 'hooks/use-contentful';
import { convertStringArrayToSelectOptions } from 'domain/notes/mapHelpers';

dayjs.extend(utc);
dayjs.extend(timezone);

export type CancelDetail = {
  canceledBy: 'client' | 'therapist' | '';
  wasLateCancel: '' | 'yes' | 'no';
  chargeCancelationFee: boolean;
  cancelationReason: string;
  cancelationReasonOther: string;
  rescheduleAttempted: string;
  additionalText?: string;
};

export type CancelationReasonPayload = {
  status: 'cancelled';
  cancelDetail: CancelDetail;
};

export interface LogCancellationModalProps {
  isOpen: boolean;
  onClose: () => void;
  activity: PendingAppointment;
  onRescheduleSwitch: () => void;
  clientId: string;
  isSidebarAppointment: boolean;
}

export const mapEntryToSelectOption = (str: string) =>
  ({
    label: str,
    value: str.toLocaleLowerCase(),
  } as SelectOption);

export const SESSION_CANCELATION_REASONS_ENTRY_ID = '7x8x3GBpr4XIaNwgtdQWW3';

const DEFAULT_SKIPPABLE_ERRORS = [AttendanceErrorKey.ExistingPendingReschedule, AttendanceErrorKey.AlreadyLogged];

export function LogCancelationModal(props: LogCancellationModalProps) {
  const [errorMessage, setErrorMessage] = useState<string>();
  const { isOpen, onClose, activity, onRescheduleSwitch, clientId, isSidebarAppointment } = props;
  const {
    mutateAsync: logCancelationAppointmentMutation,
    isLoading: isCancelationAppointmentLoading,
    reset: resetResponse,
  } = useCancelPendingAppointment();

  const isSessionTypeID =
    activity.appointmentTypeID === 'speech-therapy-session' ||
    activity.appointmentTypeID === (envValues.development.sessionTypeId as unknown) ||
    activity.appointmentTypeID === (envValues.production.sessionTypeId as unknown);

  const CANCELATION_REASONS_INITIAL_STATE: CancelationReasonPayload = {
    status: 'cancelled',
    cancelDetail: {
      canceledBy: '',
      wasLateCancel: '',
      chargeCancelationFee: false,
      cancelationReason: '',
      cancelationReasonOther: '',
      rescheduleAttempted: isSessionTypeID ? '1' : '0',
      additionalText: '',
    },
  };

  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    clearErrors,
    getValues,
    setValue,
    formState: { errors, isValid },
  } = useForm<CancelationReasonPayload>({
    defaultValues: CANCELATION_REASONS_INITIAL_STATE,
    mode: 'onChange',
  });

  const { addToast } = useToasts();

  const { data: clientData } = useClient(clientId);
  const therapistEmail = clientData?.careTeam?.primary?.therapistEmail;
  const { data: therapistData } = useTherapist(therapistEmail as string);
  const { data: billingInfoData } = useBillingInfo(clientId);
  const selectedCancelationReasonValue = watch('cancelDetail.cancelationReason');
  const watchCanceledBy = watch('cancelDetail.canceledBy');
  const watchLateCancel = watch('cancelDetail.wasLateCancel');
  const watchRescheduleLink = watch('cancelDetail.rescheduleAttempted');
  const watchAdditionalText = watch('cancelDetail.additionalText');
  const cancelationReasonOther = watch('cancelDetail.cancelationReasonOther');
  const { mutate: deleteAppointment, isLoading: isDeleteAppointmentLoading } = useDeleteAppointment();
  const { data: logAttendanceAppointments } = usePendingAppointments(clientId);
  const contactInformation = clientData?.contactInformation;
  const primaryContact = getPrimaryContact(contactInformation, clientId);
  const { mutateAsync: createPendingReschedule, isLoading: isCreatePendingRescheduleLoading } =
    useCreatePendingReschedule();

  let contactEmail!: string;
  let contactPhone!: string;

  if (primaryContact) {
    contactEmail = primaryContact.email;
    contactPhone = Object.values(primaryContact.phone ?? [])[0];
  }

  const isCanceledByClient = watchCanceledBy === 'client';

  const shouldShowLateCancelBanner =
    isCanceledByClient && watchLateCancel === 'yes' && !billingInfoData?.exemptFromPatientResponsibility;

  const { data: contentfulEntry = {}, isLoading: isContentfulLoading } = useContentfulEntry({
    entryId: isSessionTypeID ? EntryId.CancelationReasons : EntryId.EvaluationCancelationReasons,
    unwrapArray: true,
  });

  const cancelationReasons = useMemo(() => {
    if (isContentfulLoading) return [];
    return convertStringArrayToSelectOptions(contentfulEntry?.dropdownContent);
  }, [contentfulEntry]);

  const clientAttendance = useMemo(
    () =>
      logAttendanceAppointments?.find(
        (clientAttendance: { acuityAppointmentID: string }) =>
          Number(clientAttendance?.acuityAppointmentID) === Number(activity?.acuityAppointmentID),
      ),
    [logAttendanceAppointments?.length, activity?.acuityAppointmentID],
  );

  // This will look if the selected appointment has log attendance
  const hasLogAttendance = useMemo(
    () => logAttendanceAppointments?.includes(clientAttendance as PendingAppointment),
    [clientAttendance],
  );

  const onSubmitCancellation = async (data: CancelationReasonPayload) => {
    // we see if the appointment we want to cancel is coming from the client sidebar or from the log attendance component in order to trigger the endpoint
    if (isSidebarAppointment && !hasLogAttendance) {
      if (Boolean(parseInt(watchRescheduleLink))) {
        const dto: PendingRescheduleDTO = {
          acuityAppointmentID: activity?.acuityAppointmentID,
          rescheduledBy: watchCanceledBy,
          rescheduleReason: selectedCancelationReasonValue,
          clientID: clientId,
          rescheduleSourceType: 'cancellation',
        };

        await createPendingReschedule(dto, {
          onSuccess: () => {
            onClose();
          },
          onError: err => {
            if (shouldSkipAttendanceError(err, DEFAULT_SKIPPABLE_ERRORS)) {
              onClose();
              return;
            }
            const error = getCueError(err);
            setErrorMessage(error.message);
            return;
          },
        });
      }

      await deleteAppointment(
        {
          appointmentData: {
            acuityId: activity?.acuityAppointmentID,
            appointmentDate: activity?.appointmentDateTime,
          },
          clientID: clientId,
          cancelDetail: {
            canceledBy: watchCanceledBy,
            cancelationReason: selectedCancelationReasonValue,
            cancelationReasonOther: selectedCancelationReasonValue === 'other' ? cancelationReasonOther : undefined,
            //Note this will allow us to know when the reschedule link is true or false
            rescheduleAttempted: Boolean(parseInt(watchRescheduleLink)),
            additionalText: watchAdditionalText ? watchAdditionalText : undefined,
            wasLateCancel: watchLateCancel === 'yes',
          },
        },
        {
          onSuccess: () => {
            onClose();
          },
          onError: err => {
            const error = getCueError(err);
            setErrorMessage(error.message);
            return;
          },
        },
      );
    } else {
      if (Boolean(parseInt(watchRescheduleLink))) {
        const dto: PendingRescheduleDTO = {
          acuityAppointmentID: activity?.acuityAppointmentID,
          rescheduledBy: watchCanceledBy,
          rescheduleReason: selectedCancelationReasonValue,
          clientID: clientId,
          rescheduleSourceType: 'cancellation',
        };

        if (hasLogAttendance) {
          dto.cancelDetail = {
            wasLateCancel: watchLateCancel === 'yes',
            canceledBy: watchCanceledBy,
            cancelationReason: selectedCancelationReasonValue,
            cancelationReasonOther: selectedCancelationReasonValue === 'other' ? cancelationReasonOther : undefined,
            rescheduleAttempted: Boolean(parseInt(watchRescheduleLink)),
            additionalText: watchAdditionalText,
          };
        }

        await createPendingReschedule(dto, {
          onSuccess: () => {
            onClose();
          },
          onError: err => {
            if (shouldSkipAttendanceError(err, DEFAULT_SKIPPABLE_ERRORS)) {
              onClose();
              return;
            }
            const error = getCueError(err);
            setErrorMessage(error.message);
            return;
          },
        });
      } else {
        await logCancelationAppointmentMutation(
          {
            data: data,
            clientId: activity.clientID,
            acuityId: activity?.acuityAppointmentID ?? '',
          },
          {
            onSuccess: () => {
              onClose();
            },
            onError: err => {
              if (shouldSkipAttendanceError(err, DEFAULT_SKIPPABLE_ERRORS)) {
                onClose();
                return;
              }
              const error = getCueError(err);
              setErrorMessage(error.message);
              return;
            },
          },
        );
      }
    }
  };

  // reset modal state when close open or close the modal or after cancelation reasons are loaded
  const resetModalState = () => {
    reset(CANCELATION_REASONS_INITIAL_STATE);
    resetResponse();
    setErrorMessage(undefined);
  };

  // resets the form before the modal is closed, then open the reschedule modal
  const onSwitchModalHandler = () => {
    resetModalState();
    onRescheduleSwitch();
  };

  const cleanErrorsForCancelationReasonOther = () => {
    if (selectedCancelationReasonValue !== 'other') {
      clearErrors('cancelDetail.cancelationReasonOther');
      reset(
        {
          ...getValues(),
          cancelDetail: {
            ...getValues().cancelDetail,
            cancelationReasonOther: '',
          },
        },
        { keepIsValid: false, keepErrors: false },
      );
    }
  };

  useEffect(cleanErrorsForCancelationReasonOther, [selectedCancelationReasonValue]);
  useEffect(resetModalState, [isOpen]);

  useEffect(() => {
    if (watchLateCancel === 'yes' || !isSessionTypeID) {
      setValue('cancelDetail.rescheduleAttempted', '0');
    } else {
      setValue('cancelDetail.rescheduleAttempted', '1');
    }
  }, [watchLateCancel, isOpen]);

  const onClickCopyScheduleLink = () => {
    navigator.clipboard
      .writeText(
        `https://${scheduleUrlDomain}/schedule.php?calendarID=${
          therapistData?.acuityCalendarID
        }&appointmentType=${sessionTypeId}&field:${fieldIDforPatientID}=${clientId}&firstName=${
          activity.clientFirstName
        }&lastName=${activity.clientLastName}&phone=${contactPhone}&email=${encodeURIComponent(
          contactEmail as string,
        )}`,
      )
      .then(() =>
        addToast('Schedule link successfully copied', {
          appearance: 'success',
          autoDismiss: true,
        }),
      )
      .catch(() =>
        addToast('Something Went Wrong when copying to clipboard', {
          appearance: 'error',
          autoDismiss: true,
        }),
      );
  };

  const isLoading =
    isCancelationAppointmentLoading ||
    isContentfulLoading ||
    isDeleteAppointmentLoading ||
    isCreatePendingRescheduleLoading;

  return (
    <Modal isOpen={isOpen} tw="max-w-lg">
      <ModalContent>
        <ModalHeader>
          <div tw="absolute top-0 right-0 pt-4 pr-4">
            <CloseButton testId="close-log-cancelation-modal" onClick={onClose} />
          </div>
        </ModalHeader>
        <ModalBody>
          <React.Fragment>
            <div tw="flex items-center justify-center w-12 h-12 mx-auto bg-indigo-100 rounded-full">
              <FontAwesomeIcon tw="text-2xl text-indigo-700" icon={faCalendarTimes} />
            </div>
            <div tw="my-3 sm:mt-5">
              <ModalHeadline text={`Log Cancelation for ${clientFullName(activity)}`} />
              <h4 tw="text-center leading-6 -mt-3">
                {dayjs(activity?.appointmentDateTime).tz(dayjs.tz.guess()).format('MM/DD/YYYY')} at&nbsp;
                {dayjs(activity?.appointmentDateTime).tz(dayjs.tz.guess()).format('hh:mm A')}
              </h4>
              <div tw="w-[70%] m-auto mt-2">
                <p tw="text-center">
                  Cancel your client’s session if it cannot be rescheduled. All fields are required to log a
                  Cancelation.
                </p>

                <p
                  tw="cursor-pointer font-semibold text-indigo-700 text-center mt-6 mb-7"
                  onClick={onSwitchModalHandler}
                >
                  Reschedule Instead of Cancel <FontAwesomeIcon tw="ml-2 text-indigo-700" icon={faChevronRight} />
                </p>
              </div>
              <form tw="mt-5" data-testid="cancelation-form" onSubmit={handleSubmit(onSubmitCancellation)}>
                <div tw="space-y-2">
                  <FormGroupTitle title="Canceled by" fontSize="small" fontWeight="semi" spacing="normal" />
                  <FormGroup type="inline" tw="items-center">
                    <Input
                      type="radio"
                      id="cancelationDetail.canceledBy-client"
                      spacing="tight"
                      data-testid="canceled-by-client"
                      {...register('cancelDetail.canceledBy', { required: 'Please fill out this field.' })}
                      value="client"
                    />
                    <Label font="normal" htmlFor="cancelationDetail.canceledBy-client" tw="ml-2">
                      Client
                    </Label>
                    <Input
                      id="cancelationDetail.canceledBy-therapist"
                      tw="ml-3"
                      spacing="tight"
                      type="radio"
                      data-testid="canceled-by-therapist"
                      {...register('cancelDetail.canceledBy', { required: 'Please fill out this field.' })}
                      value="therapist"
                    />
                    <Label font="normal" htmlFor="cancelationDetail.canceledBy-therapist" tw="ml-2">
                      Therapist
                    </Label>
                  </FormGroup>
                </div>
                <ErrorSpan error={errors?.cancelDetail?.canceledBy?.message} />
                <div tw="mt-6">
                  <FormGroupTitle
                    title="Was this Cancelation made with at least 24 hours notice?"
                    fontSize="small"
                    fontWeight="semi"
                    spacing="normal"
                  />
                  <FormGroup type="inline">
                    <Input
                      type="radio"
                      value="no"
                      spacing="tight"
                      data-testid="late-cancelled-checkbox"
                      id="cancelationDetail.wasLateCancel-yes"
                      {...register('cancelDetail.wasLateCancel', { required: 'Please fill out this field.' })}
                    />
                    <Label font="normal" htmlFor="cancelationDetail.wasLateCancel-yes" tw="ml-2">
                      Yes
                    </Label>
                    <Input
                      tw="ml-3"
                      type="radio"
                      spacing="tight"
                      data-testid="late-cancelled-checkbox"
                      id="cancelationDetail.wasLateCancel-no"
                      value="yes"
                      {...register('cancelDetail.wasLateCancel', { required: 'Please fill out this field.' })}
                    />
                    <Label font="normal" htmlFor="cancelationDetail.wasLateCancel-no" tw="ml-2">
                      No
                    </Label>
                    <Input type="hidden" {...register('status')} />
                  </FormGroup>
                  {shouldShowLateCancelBanner && (
                    <div tw="mt-4 bg-[#FEE2E2] p-3 rounded-md">
                      A late cancel fee of $50 will be charged to the client’s saved payment method. If you believe this
                      fee should be excused, please submit a billing request after the client is charged.
                    </div>
                  )}
                </div>
                <ErrorSpan error={errors?.cancelDetail?.wasLateCancel?.message} />
                {/* This is the reschedule link form group */}
                {watchLateCancel !== 'yes' && isSessionTypeID && (
                  <div tw="mt-6">
                    <FormGroup type="inline">
                      <Input
                        type="checkbox"
                        spacing="tight"
                        data-testid="reschedule-attempted-checkbox"
                        id="cancelDetail.rescheduleAttempted"
                        value="1"
                        {...register('cancelDetail.rescheduleAttempted')}
                        checked={Boolean(parseInt(watchRescheduleLink))}
                      />
                      <Label font="semibold" htmlFor="cancelDetail.rescheduleAttempted" tw="ml-2">
                        Send your client a reschedule link
                      </Label>
                    </FormGroup>
                  </div>
                )}
                {Boolean(parseInt(watchRescheduleLink)) && (
                  <p tw="mt-8">
                    Your client will receive a reschedule link via SMS and select a new appointment date and time based
                    on your availability.
                  </p>
                )}
                {/* <div tw="mt-6"> */}
                <Link
                  tw="text-xs"
                  data-testid="scheduling-link"
                  to={{
                    pathname: `https://${scheduleUrlDomain}/schedule.php?calendarID=${
                      therapistData?.acuityCalendarID
                    }&appointmentType=${sessionTypeId}&field:${fieldIDforPatientID}=${clientId}&firstName=${
                      activity.clientFirstName
                    }&lastName=${activity.clientLastName}&phone=${contactPhone}&email=${encodeURIComponent(
                      contactEmail as string,
                    )}`,
                  }}
                  target="_blank"
                >
                  {/* {`Scheduling Link for ${clientFullName(activity)}`} */}
                </Link>
                <span
                  tw="ml-2 text-indigo-700 font-semibold cursor-pointer text-xs"
                  onClick={() => onClickCopyScheduleLink()}
                >
                  {/* Copy Link */}
                </span>
                {/* </div> */}
                <FormGroup data-testid="cancelation-reason" tw="mt-8">
                  <FormGroupTitle title="Cancelation Reason" fontSize="small" fontWeight="semi" spacing="normal" />
                  <RHFSelect
                    name="cancelDetail.cancelationReason"
                    control={control}
                    required
                    options={cancelationReasons ?? []}
                    error={errors?.cancelDetail?.cancelationReason}
                  />
                </FormGroup>
                {Boolean(selectedCancelationReasonValue === 'other') && (
                  <FormGroup>
                    <FormGroupTitle title="What was the reason?" fontSize="small" fontWeight="semi" spacing="normal" />
                    <Input
                      tw="w-full"
                      data-testid="cancelation-reason-other"
                      {...register('cancelDetail.cancelationReasonOther', { required: true })}
                      error={errors.cancelDetail?.cancelationReasonOther}
                    />
                  </FormGroup>
                )}
                {errorMessage && <p tw="text-center text-red-600 font-semibold mb-2">{errorMessage}</p>}
                <FormGroup tw="mt-2">
                  <div tw="flex flex-row">
                    <FormGroupTitle
                      title="Please add any additional information"
                      fontSize="small"
                      fontWeight="semi"
                      spacing="normal"
                    />
                    <span className="mt-2 ml-2 text-xxs text-gray-400 leading-none">OPTIONAL</span>
                  </div>
                  <Textarea
                    tw="w-full"
                    spacing="normal"
                    rows={3}
                    data-testid="aditional-information"
                    {...register('cancelDetail.additionalText')}
                    error={errors.cancelDetail?.additionalText}
                  />
                </FormGroup>
                <FormGroup tw="mt-4">
                  <Button
                    loading={isLoading}
                    data-testid="log-cancelation-onsave"
                    disabled={!isValid}
                    variant="primary"
                    fullWidth
                  >
                    {shouldShowLateCancelBanner && billingInfoData && hasLogAttendance
                      ? 'Cancel Session With $50 Charge'
                      : 'Cancel Session'}
                  </Button>
                  <Button
                    tw="mt-3"
                    data-testid="log-cancelation-close-no-login"
                    variant="secondary"
                    fullWidth
                    onClick={onClose}
                  >
                    Exit Without Logging
                  </Button>
                </FormGroup>
              </form>
            </div>
          </React.Fragment>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
