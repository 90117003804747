import React from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import tw from 'twin.macro';
import { Input, Label, Card } from '@expressable/ui-library';

type Props = {
  id: string;
  title: string;
  isChecked: boolean;
  isDisabled?: boolean;
  onCheckboxChange: (checked: boolean) => void;
  children: React.ReactNode;
};

export const HomePracticeSection = ({
  id,
  title,
  isChecked,
  isDisabled,
  onCheckboxChange,
  children,
}: Props): JSX.Element => {
  return (
    <Card tw="sm:p-6 border border-gray-300 mb-5 last:mb-2">
      <div className={`flex ${!isChecked && 'items-center'}`}>
        <div tw="flex">
          <Input
            type="checkbox"
            id={id}
            onChange={event => onCheckboxChange(event.target.checked)}
            checked={isChecked}
            disabled={isDisabled}
            data-testid="therapy-strategy-card-checkbox"
            spacing="tight"
            className={`flex mt-0.5 ${!isDisabled ? 'cursor-pointer' : ''}`}
          />
          <div tw="flex flex-col ml-3 mr-7">
            <Label htmlFor={id} className={`font-semibold text-sm ${!isDisabled && 'cursor-pointer'}`}>
              {title}
            </Label>
            {isChecked && <div tw="mt-6">{children}</div>}
          </div>
        </div>
      </div>
    </Card>
  );
};
