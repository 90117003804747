import { evaluationTypeId } from 'domain/client/constants';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ISingleAppointment } from 'types';
import { useState } from 'react';
import { UseMutateFunction } from 'react-query';
import { Link } from '@expressable/ui-library';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

import 'twin.macro';
import UpcomingAppointmentItem from './upcoming-appointment-item';
import { useClient } from 'hooks/use-client';

const INITIAL_APPOINTMENTS_TO_SHOW = 5;

type UpcomingAppointmentsProps = {
  clientId: string;
  appointments: ISingleAppointment[];
  isResetCacheLoading: boolean;
  setEditAppointmentData: React.Dispatch<React.SetStateAction<ISingleAppointment | undefined>>;
  editAppointment: () => void;
  resetCache: UseMutateFunction<any, any, string, unknown>;
};

export enum AppointmentTag {
  Recurring = 'RECURRING',
  Rescheduled = 'RESCHEDULED',
  RescheduledByClient = 'RESCHEDULED - CLIENT',
  RescheduledByTherapist = 'RESCHEDULED - THERAPIST',
  NonRecurring = 'NON-RECURRING',
}

export const APPOINTMENT_TAG_MESSAGE = {
  [AppointmentTag.Recurring]: '',
  [AppointmentTag.Rescheduled]: 'Rescheduled',
  [AppointmentTag.RescheduledByClient]: 'Rescheduled by client',
  [AppointmentTag.RescheduledByTherapist]: 'Rescheduled by therapist',
  [AppointmentTag.NonRecurring]: 'Non-recurring',
};
export const buildAppointmentStatus = (appointment: ISingleAppointment) => {
  if (!appointment.tag) {
    return APPOINTMENT_TAG_MESSAGE[AppointmentTag.NonRecurring];
  } else {
    return APPOINTMENT_TAG_MESSAGE[appointment.tag as AppointmentTag];
  }
};

export const UpcomingAppointments: React.FC<UpcomingAppointmentsProps> = ({
  appointments,
  clientId,
  isResetCacheLoading,
  setEditAppointmentData,
  editAppointment,
  resetCache,
}) => {
  const {
    cueRecurringRescheduleBadge: isRecurringAppointmentsBadgesEnabled,
    resetAcuityCache,
    cueSchedulingControl: displayBookingCapability,
  } = useFlags();
  const [shouldViewAllAppointments, setShouldViewAllAppointments] = useState(false);
  const { data: client } = useClient(clientId);
  return (
    <ul tw="mt-4 mr-[-1rem] ml-[-1rem]">
      {appointments
        .slice(0, shouldViewAllAppointments ? undefined : INITIAL_APPOINTMENTS_TO_SHOW)
        .map((appointment, index) => (
          <UpcomingAppointmentItem
            key={index}
            shouldShowRecurringInformation={isRecurringAppointmentsBadgesEnabled}
            isRecurringAppointmentsBadgesEnabled={isRecurringAppointmentsBadgesEnabled}
            appointment={appointment}
            index={index}
            displayBookingCapability={displayBookingCapability}
            evaluationTypeId={evaluationTypeId}
            clientId={clientId}
            onClickAppointment={({ currentTarget }) => {
              setEditAppointmentData({
                acuityAppointmentID: currentTarget?.dataset?.id ?? '',
                appointmentDateTime: currentTarget.dataset.date ?? '',
                appointmentDuration: currentTarget.dataset.duration,
                appointmentTypeID: Number(currentTarget.dataset.typeId),
                clientID: clientId,
                clientFirstName: client?.clientFirstName ?? '',
                clientLastName: client?.clientLastName ?? '',
                tag: currentTarget?.dataset.tag ?? '',
              });
              if (
                displayBookingCapability ||
                (!displayBookingCapability && appointment.appointmentTypeID !== evaluationTypeId)
              ) {
                editAppointment();
              }
            }}
          />
        ))}

      <div className="flex gap-x-8 mt-4 pl-4">
        {appointments.length > INITIAL_APPOINTMENTS_TO_SHOW && (
          <Link
            to="#"
            className="text-sm font-medium"
            onClick={() => {
              setShouldViewAllAppointments(previousState => {
                if (previousState) {
                  document.getElementById('upcoming-appointments-container')?.scrollIntoView({
                    behavior: 'smooth',
                  });
                }
                return !previousState;
              });
            }}
          >
            <FontAwesomeIcon
              icon={!shouldViewAllAppointments ? faChevronDown : faChevronUp}
              className="text-indigo-700 font-bold mr-4"
            />
            View {shouldViewAllAppointments ? 'less' : 'all'} appointments
          </Link>
        )}
        {resetAcuityCache && (
          <Link to="#" tw="text-sm font-medium" onClick={() => resetCache(clientId)} loading={isResetCacheLoading}>
            Reset Cache
          </Link>
        )}
      </div>
    </ul>
  );
};
