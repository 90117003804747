import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  Button,
  Input,
  Label,
  Link,
  useDisclosure,
  Select as ExpressableSelect,
  Loader,
} from '@expressable/ui-library';
import { Contact, ICompleteClientInformation } from 'types';
import { Controller, useForm, useWatch } from 'react-hook-form';
import Select from 'react-select';
import { useCreateClientContact, useEditClientContact, ClientContactsPayload } from 'hooks/use-client-contacts';
import usStatesOptions from 'utils/us-states.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import timeZones from 'utils/us-time-zones.json';
import {
  phoneInputHandle,
  validateZipcode,
  onlyLettersSpecialVowelsWhiteSpacesValidator,
  removeNonNumericCharacters,
} from 'utils/helpers';
import { RemoveContactModal } from './client-contact-modal';
import { emailValidationRegex, phoneSelectOptions, relationshipsOptions } from 'domain/client/constants';
import ClientContactFormReadOnly from './client-contact-form-read-only';
import DuplicateContactsFinder from './duplicate-contacts-finder';
import { useDuplicatedContacts } from 'hooks/use-contacts';
import DuplicatedContactsModal from './duplicated-contacts-modal';
import { useForceUpdate } from '@expressable/utils';
import DuplicatedContactsConfirmationModal from './duplicated-contacts-confirmation-modal';
import * as Sentry from '@sentry/react';
import { useToasts } from 'react-toast-notifications';
import tw from 'twin.macro';
import dayjs from 'dayjs';

import { ContactCommunicationPreferences } from './contact-communication-preferences';
import { useQueryClient } from 'react-query';
import { GrantPortalAccessModal } from './grant-portal-access-modal';
import usePermissions from 'hooks/use-permissions';

export interface ClientContactFormProps {
  clientId: string;
  contact: Contact;
  primaryContact?: Contact;
  index: number;
  setContacts: Dispatch<SetStateAction<Contact[]>>;
  setShowDuplicateFinder: Dispatch<SetStateAction<boolean>>;
  clientData: ICompleteClientInformation | undefined;
  contacts: Contact[];
  duplicatedContactModalIsOpen: boolean;
  duplicatedContactModalOnClose: () => void;
  duplicatedContactModalOnOpen: () => void;
  showDuplicateFinder: boolean;
  duplicatedContactsConfirmationModalIsOpen: boolean;
  duplicatedContactsConfirmationModalOnClose: () => void;
  duplicatedContactsConfirmationModalOnOpen: () => void;
}

export interface ContactFormInputs {
  contactTimeZone: { [value: string]: string };
  relationshipToClient: { [key: string]: string };
  contactFirstName: string;
  contactMiddleName: string;
  contactLastName: string;
  email: string;
  phone: string;
  phoneKey: { [value: string]: string };
  clientId: string;
  contactID: string;
  city?: string;
  street?: string;
  line2?: string;
  postalCode?: string;
  faxPhone?: string | null;
  state?: { [value: string]: string };
  enableEmailNotifications: boolean;
  enableSmsNotifications: boolean;
  enableEmailApptReminderNotifications: boolean;
  enableSMSApptReminderNotifications: boolean;
}

const FormGroup = tw.div`mb-0`;
const FormInline = tw.div`md:flex`;

export type SelectOption = { [key: string]: string };

const findSelectOption = (options: SelectOption[], value: string | undefined) => {
  return options.find(option => option.value === value);
};

export default function ClientContactForm(props: ClientContactFormProps) {
  const { addToast } = useToasts();
  const queryClient = useQueryClient();
  const {
    index,
    setContacts,
    contact,
    primaryContact,
    clientId,
    setShowDuplicateFinder,
    clientData,
    contacts,
    duplicatedContactModalIsOpen,
    duplicatedContactModalOnClose,
    duplicatedContactModalOnOpen,
    showDuplicateFinder,
    duplicatedContactsConfirmationModalIsOpen,
    duplicatedContactsConfirmationModalOnClose,
    duplicatedContactsConfirmationModalOnOpen,
  } = props;
  const {
    contactFirstName,
    contactMiddleName,
    contactLastName,
    contactTimeZone,
    address,
    contactID,
    email,
    phone,
    relationshipToClient,
  } = contact;
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    setValue,
    reset,
  } = useForm<ContactFormInputs>();
  const createContactMutation = useCreateClientContact();
  const { mutate: createContact } = createContactMutation;
  const editContactMutation = useEditClientContact();
  const { mutate: editContact } = editContactMutation;
  const [isPhoneNumberNotProvided, setIsPhoneNumberNotProvided] = useState(
    contact.phone.mobile === '' || contact.phone.work === '' || contact.phone.home === '',
  );
  const [isEmailAddressNotProvided, setIsEmailAddressNotProvided] = useState(!email);
  const [showEditForm, setShowEditForm] = useState(false);
  const [finderDefaultPhone, setFinderDefaultPhone] = useState('');
  const [finderDefaultEmail, setFinderDefaultEmail] = useState('');
  const [finderDefaultPhoneType, setFinderDefaultPhoneType] = useState<SelectOption>();
  const [isPhoneSearch, setIsPhoneSearch] = useState(false);
  const [isEmailSearch, setIsEmailSearch] = useState(false);
  const [showPhoneField, setShowPhoneField] = useState(false);
  const [showEmailField, setShowEmailField] = useState(false);
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState<boolean>(false);
  const [confirmationModalHasDuplicates, setConfirmationModalHasDuplicates] = useState<boolean>(false);
  const [clientInformation, setClientInformation] = useState<ClientContactsPayload>();
  const { isAdmin } = usePermissions();

  const {
    isOpen: isGrantPortalAccessModalOpen,
    onOpen: onGrantPortalAccessModalOpen,
    onClose: onGrantPortalAccessModalClose,
  } = useDisclosure();
  const watchPhone = useWatch({ control, name: 'phone' });
  const watchEmail = useWatch({ control, name: 'email' });
  const watchPhoneType = useWatch({ control, name: 'phoneKey' });
  const isContactPhysician = useWatch({ control, name: 'relationshipToClient' })?.value === 'Physician';

  const forceUpdate = useForceUpdate();

  const phoneNumber = removeNonNumericCharacters(watchPhone);
  const mobilePhoneNumber = removeNonNumericCharacters(phone?.mobile);
  const homePhoneNumber = removeNonNumericCharacters(phone?.home);
  const workPhoneNumber = removeNonNumericCharacters(phone?.work);

  const phoneNumberHasChanged =
    phoneNumber &&
    phoneNumber !== mobilePhoneNumber &&
    phoneNumber !== homePhoneNumber &&
    phoneNumber !== workPhoneNumber;

  const emailAddressHasChanged = watchEmail && watchEmail !== email;

  const duplicatedContactsQuery = useDuplicatedContacts(
    isPhoneNumberNotProvided,
    isEmailAddressNotProvided,
    phoneNumberHasChanged ? watchPhone : undefined,
    emailAddressHasChanged ? (watchEmail ? watchEmail : finderDefaultEmail) : undefined,
    phoneNumberHasChanged ? watchPhoneType?.value && `${watchPhoneType.value}Phone` : undefined,
  );

  const {
    data: duplicatedContacts,
    mutate: findDuplicatedContacts,
    isLoading: isLoadingDuplicatedContacts,
  } = duplicatedContactsQuery;

  const {
    isOpen: RemoveContactModalIsOpen,
    onClose: RemoveContactModalOnClose,
    onOpen: RemoveContactModalOnOpen,
  } = useDisclosure();

  const relationshipToClientDefaultValue = findSelectOption(relationshipsOptions, relationshipToClient);

  const phoneKey = Object.keys(phone ?? {}).reduce((_, current) => current, '');
  const phoneKeyDefaultValue = findSelectOption(phoneSelectOptions, phoneKey);

  const timezoneDefaultValue = findSelectOption(timeZones, contactTimeZone);

  const addressState = address?.state;
  const stateDefaultValue = findSelectOption(usStatesOptions, addressState);

  let isContactPrimaryContact = false;
  if (typeof primaryContact === 'undefined' && index === 0) {
    isContactPrimaryContact = true;
  } else if (contactID === primaryContact?.contactID) {
    isContactPrimaryContact = true;
  }

  const onSubmit = async (formData: ContactFormInputs) => {
    const clientInformation: ClientContactsPayload = {
      clientID: formData.clientId,
      contactID: formData.contactID ? formData.contactID : contactID,
      relationshipToClient: formData.relationshipToClient.label,
      contactFirstName: formData.contactFirstName,
      contactMiddleName: formData.contactMiddleName,
      contactLastName: formData.contactLastName,
      primaryContact: isContactPrimaryContact,
      contactTimeZone: formData.contactTimeZone.value,
      faxPhone: formData.relationshipToClient.label === 'Physician' ? formData.faxPhone : undefined,
      email: formData.email,
      phone: { [formData.phoneKey?.value]: formData.phone },
      address: {
        city: formData.city,
        line2: formData.line2,
        postalCode: formData.postalCode,
        state: formData.state?.value ?? undefined,
        street: formData.street,
      },
      enableEmailNotifications: formData.enableEmailNotifications,
      enableSmsNotifications: formData.enableSmsNotifications,
      enableEmailApptReminderNotifications: formData.enableEmailApptReminderNotifications,
      enableSMSApptReminderNotifications: formData.enableSMSApptReminderNotifications,
    };

    if (contactID) {
      if (phoneNumberHasChanged || emailAddressHasChanged) {
        try {
          const duplicatedContactsResponse: Contact[] = await new Promise(resolve => {
            findDuplicatedContacts(
              {
                phoneNumber: phoneNumberHasChanged ? watchPhone : undefined,
                email: emailAddressHasChanged ? watchEmail : undefined,
                phoneType: phoneNumberHasChanged ? watchPhoneType : undefined,
              },
              {
                onSuccess: (duplicatedContacts: Contact[]) => {
                  resolve(duplicatedContacts);
                  setDisplayConfirmationModal(true);
                  setClientInformation(clientInformation);
                },
              },
            );
          });

          if (duplicatedContactsResponse.length === 0) {
            setConfirmationModalHasDuplicates(false);
          } else {
            setConfirmationModalHasDuplicates(true);
          }
        } catch (error) {
          Sentry.captureException(error);
          addToast('Something Went Wrong', { appearance: 'error', autoDismiss: true });
        }
      } else {
        editContact(clientInformation, {
          onSuccess: () => {
            forceUpdate();
            setShowEditForm(false);
            queryClient.invalidateQueries('contacts');
            queryClient.invalidateQueries('client');
            queryClient.invalidateQueries(['client-communication-preferences', clientId]);
          },
        });
      }
    } else {
      if (!isPhoneNumberNotProvided || !isEmailAddressNotProvided) {
        try {
          const duplicatedContactsResponse: Contact[] = await new Promise(resolve => {
            findDuplicatedContacts(
              {
                phoneNumber: watchPhone,
                email: watchEmail,
                phoneType: watchPhoneType,
              },
              {
                onSuccess: (duplicatedContacts: Contact[]) => {
                  resolve(duplicatedContacts);
                },
              },
            );
          });

          if (duplicatedContactsResponse.length === 0) {
            createContact(clientInformation, {
              onSuccess: () => {
                setShowEditForm(false);
                setShowDuplicateFinder(false);
              },
            });
          }
        } catch (error) {
          Sentry.captureException(error);
          addToast('Something Went Wrong', { appearance: 'error', autoDismiss: true });
        }
      } else {
        createContact(clientInformation, {
          onSuccess: () => {
            forceUpdate();
            setShowEditForm(false);
            setShowDuplicateFinder(false);
          },
        });
      }
    }
  };

  useEffect(() => {
    if (!showEditForm) {
      setIsPhoneSearch(false);
      setIsEmailSearch(false);
    }

    if (!contactID) {
      reset(
        {
          phone: finderDefaultPhone ? finderDefaultPhone : phone[phoneKey],
          phoneKey: finderDefaultPhoneType ? finderDefaultPhoneType : phoneKeyDefaultValue,
          email: finderDefaultEmail ? finderDefaultEmail : email,
        },
        { keepIsValid: false, keepErrors: false },
      );
    }
  }, [showEditForm, contactID, finderDefaultPhone, finderDefaultEmail, finderDefaultEmail]);

  useEffect(() => {
    reset({
      contactFirstName,
      contactMiddleName,
      contactLastName,
      contactTimeZone: timezoneDefaultValue ?? undefined,
      relationshipToClient: relationshipToClientDefaultValue ?? undefined,
      email,
      phone: phone[phoneKey],
      phoneKey: phoneKeyDefaultValue ?? undefined,
      clientId,
      contactID,
      city: address?.city,
      street: address?.street,
      line2: address?.line2,
      postalCode: address?.postalCode,
      state: stateDefaultValue ?? undefined,
      faxPhone: contact?.faxPhone,
      enableEmailApptReminderNotifications: contact.enableEmailApptReminderNotifications,
      enableEmailNotifications: contact.enableEmailNotifications,
      enableSmsNotifications: contact.enableSmsNotifications,
      enableSMSApptReminderNotifications: contact.enableSMSApptReminderNotifications,
    });

    setIsEmailAddressNotProvided(!email);
    setIsPhoneNumberNotProvided(!phone[phoneKey]);
  }, [contact, reset]);

  useEffect(() => {
    if (duplicatedContacts && duplicatedContacts.length > 0 && !displayConfirmationModal) {
      duplicatedContactModalOnOpen();
    }
  }, [duplicatedContacts]);

  useEffect(() => {
    if (displayConfirmationModal) {
      duplicatedContactsConfirmationModalOnOpen();
    }
  }, [displayConfirmationModal]);

  useEffect(() => {
    if (!duplicatedContactModalIsOpen) {
      forceUpdate();
      duplicatedContacts?.splice(0, duplicatedContacts.length);
    }
  }, [duplicatedContactModalIsOpen]);

  useEffect(() => {
    if (!duplicatedContactsConfirmationModalIsOpen) {
      forceUpdate();
      duplicatedContacts?.splice(0, duplicatedContacts.length);
    }
  }, [duplicatedContactsConfirmationModalIsOpen]);

  useEffect(() => {
    if (finderDefaultPhone && finderDefaultPhoneType) {
      setIsPhoneNumberNotProvided(false);
    }
    if (finderDefaultEmail) {
      setIsEmailAddressNotProvided(false);
    }
  }, [finderDefaultPhone, finderDefaultPhoneType, finderDefaultEmail]);

  if (editContactMutation.isLoading || createContactMutation.isLoading || isLoadingDuplicatedContacts) {
    return (
      <div className="flex justify-center items-center mx-auto" data-testid="client-contact-form-loadear">
        <Loader type="ring" />
      </div>
    );
  }

  if (duplicatedContacts && duplicatedContacts.length > 0 && !displayConfirmationModal) {
    return (
      <DuplicatedContactsModal
        duplicatedContacts={duplicatedContacts}
        searchedPhone={watchPhone}
        searchedEmail={watchEmail}
        clientData={clientData}
        isOpen={duplicatedContactModalIsOpen}
        onClose={duplicatedContactModalOnClose}
        setShowEditForm={setShowEditForm}
        setContacts={setContacts}
        setShowDuplicateFinder={setShowDuplicateFinder}
        contacts={contacts}
        clientId={clientId}
      />
    );
  }

  if (displayConfirmationModal) {
    return (
      <DuplicatedContactsConfirmationModal
        clientInformation={clientInformation}
        isOpen={duplicatedContactsConfirmationModalIsOpen}
        onClose={duplicatedContactsConfirmationModalOnClose}
        confirmationModalHasDuplicates={confirmationModalHasDuplicates}
        duplicatedContacts={duplicatedContacts}
        setDisplayConfirmationModal={setDisplayConfirmationModal}
        editContact={editContact}
        editContactIsLoading={editContactMutation.isLoading}
        setShowEditForm={setShowEditForm}
        contactFirstName={contactFirstName}
        contactLastName={contactLastName}
        phoneNumberHasChanged={phoneNumberHasChanged}
        emailAddressHasChanged={emailAddressHasChanged}
      />
    );
  }

  if (showEditForm) {
    return (
      <>
        <form data-testid="client-contact-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="text-2xl mt-4 mb-9 font-semibold">
            {`${showEditForm && contactID ? 'Editing:' : 'Add New'} ${
              isContactPrimaryContact ? 'Primary Contact' : 'Contact'
            }`}
          </div>
          <div className="mb-9 font-normal">
            {isPhoneSearch &&
              !isEmailSearch &&
              'No duplicate phone number found. Please continue the new contact form.'}
            {isEmailSearch &&
              !isPhoneSearch &&
              'No duplicate email address found. Please continue the new contact form.'}
            {isPhoneSearch &&
              isEmailSearch &&
              'No duplicate phone number found. No duplicate email address found. Please continue the new contact form.'}
            {!isPhoneSearch && !isEmailSearch && !contactID && 'Please continue the new contact form.'}
          </div>

          <input {...register('contactID')} defaultValue={contactID} type="hidden" />
          <input {...register('clientId')} defaultValue={clientId} type="hidden" />

          {(isPhoneSearch || showPhoneField) && (
            <FormInline className="flex-wrap items-end">
              <div>
                <Label optional={!isContactPrimaryContact} htmlFor="phone">
                  Phone
                </Label>
                <Input
                  type="tel"
                  id="phone"
                  data-testid="client-contact-form-phone"
                  className={`${
                    isPhoneNumberNotProvided ? 'text-gray-800 opacity-50 pointer-events-none mr-3' : 'mr-3'
                  }`}
                  readOnly={isPhoneNumberNotProvided}
                  {...register('phone', {
                    required: isContactPrimaryContact,
                    validate: {
                      phoneValidator: val => {
                        const { flag, newValue } = phoneInputHandle(val);
                        setValue('phone', newValue);
                        return flag;
                      },
                    },
                  })}
                  defaultValue={finderDefaultPhone ? finderDefaultPhone : phone[phoneKey]}
                  placeholder={isPhoneNumberNotProvided ? '' : '(512) 234-5890'}
                  error={errors.phone}
                />
              </div>
              <div className="mb-5 w-36" data-testid="client-contact-form-phone-option">
                <Label htmlFor="phone-type">Type</Label>
                <Controller
                  name="phoneKey"
                  control={control}
                  render={({ field }) => (
                    <Select
                      className={`${errors.phone ? 'mb-4' : ''} ${
                        isPhoneNumberNotProvided ? 'text-gray-800 opacity-50 pointer-events-none' : ''
                      }`}
                      readOnly={isPhoneNumberNotProvided}
                      {...field}
                      options={phoneSelectOptions}
                      isSearchable
                    />
                  )}
                  defaultValue={finderDefaultPhoneType ? finderDefaultPhoneType : phoneKeyDefaultValue}
                />
              </div>
              <div className={`${errors.phone ? 'mb-4' : ''}`}>
                {!isContactPrimaryContact &&
                  (contact.phone.mobile === '' || contact.phone.work === '' || contact.phone.home === '') && (
                    <div className="flex items-baseline mb-7 ml-0 md:ml-6">
                      <Input
                        data-testid="client-contact-form-phone-no-number"
                        name="no-phone-number-provided"
                        id="no-phone-number-provided"
                        type="checkbox"
                        spacing="tight"
                        checked={isPhoneNumberNotProvided}
                        onChange={e => {
                          setIsPhoneNumberNotProvided(e.currentTarget.checked);
                          setValue('phone', '');
                        }}
                        className="mr-2"
                      />

                      <Label className="font-normal text-base">No phone number provided</Label>
                    </div>
                  )}
              </div>
            </FormInline>
          )}

          {(isEmailSearch || showEmailField) && (
            <div className="md:flex items-end block">
              <div>
                <Label optional={!isContactPrimaryContact} htmlFor="email">
                  Email
                </Label>
                <Input
                  type="email"
                  id="email"
                  data-testid="client-contact-form-email"
                  {...register('email', {
                    required: isContactPrimaryContact,
                    pattern: {
                      value: emailValidationRegex,
                      message: 'please enter a valid email address.',
                    },
                  })}
                  defaultValue={finderDefaultEmail ? finderDefaultEmail : email}
                  error={errors.email}
                  size={41}
                  className={isEmailAddressNotProvided ? 'text-gray-800 opacity-50 pointer-events-none' : ''}
                  readOnly={isEmailAddressNotProvided}
                />
              </div>

              {!isContactPrimaryContact && !email && (
                <div className="flex items-baseline mb-7 ml-0 md:ml-6">
                  <Input
                    data-testid="client-contact-form-email-no-address"
                    name="no-email-address-provided"
                    id="no-email-address-provided"
                    type="checkbox"
                    spacing="tight"
                    checked={isEmailAddressNotProvided}
                    onChange={e => {
                      setIsEmailAddressNotProvided(e.currentTarget.checked);
                      setValue('email', '');
                    }}
                    className="mr-2"
                  />

                  <Label className="font-normal text-base">No email address provided</Label>
                </div>
              )}
              {contact.cognitoCreatedAt && (
                <div className={`${errors.email ? 'mb-4' : ''}`}>
                  <h5 className="md:mb-7 md:ml-3 text-sm -mt-4 italic">
                    Portal account created on {dayjs(contact.cognitoCreatedAt).format('MM/DD/YYYY')}
                  </h5>
                </div>
              )}
              {!contact.cognitoCreatedAt &&
                contact?.email &&
                isAdmin && (
                  <span
                    className="md:mb-7 md:ml-6 text-sm -mt-4 font-medium text-indigo-600 cursor-pointer"
                    onClick={onGrantPortalAccessModalOpen}
                  >
                    Grant Portal access
                  </span>
                )}
            </div>
          )}
          <FormInline>
            <FormGroup>
              <Label>First Name</Label>
              <Input
                type="text"
                id="contactFirstName"
                data-testid="client-contact-form-first-name"
                className="mr-3"
                {...register('contactFirstName', {
                  required: 'first name is required',
                  validate: { onlyLettersSpecialVowelsWhiteSpacesValidator },
                })}
                defaultValue={contactFirstName}
                error={errors.contactFirstName}
              />
            </FormGroup>

            <FormGroup>
              <Label optional>Middle Name</Label>
              <Input
                type="text"
                id="contactMiddleName"
                data-testid="client-contact-form-middle-name"
                className="mr-3"
                {...register('contactMiddleName', { validate: { onlyLettersSpecialVowelsWhiteSpacesValidator } })}
                defaultValue={contactMiddleName}
                error={errors.contactMiddleName}
              />
            </FormGroup>

            <FormGroup>
              <Label>Last Name</Label>
              <Input
                type="text"
                id="contactLastName"
                data-testid="client-contact-form-last-name"
                className="mr-3"
                {...register('contactLastName', {
                  required: 'last name is required',
                  validate: { onlyLettersSpecialVowelsWhiteSpacesValidator },
                })}
                defaultValue={contactLastName}
                error={errors.contactLastName}
              />
            </FormGroup>
          </FormInline>
          <FormGroup>
            <Label htmlFor="relationshipToClient">Relationship to Client</Label>
            <div className="mb-5 w-48" data-testid="relationshipToClient">
              <Controller
                name="relationshipToClient"
                control={control}
                render={({ field }) => (
                  <ExpressableSelect
                    {...field}
                    options={relationshipsOptions}
                    isSearchable
                    size={20}
                    error={errors.relationshipToClient}
                  />
                )}
                defaultValue={relationshipToClientDefaultValue}
                rules={{
                  required: 'relationship to client is required',
                }}
              />
            </div>
          </FormGroup>
          {isContactPhysician && (
            <FormGroup>
              <Label htmlFor="faxPhone" optional>
                Fax
              </Label>
              <div className="mb-5 w-48" data-testid="relationshipToClient">
                <Input
                  type="tel"
                  id="phone"
                  data-testid="client-contact-form-faxphone"
                  {...register('faxPhone', {
                    validate: {
                      phoneValidator: val => {
                        if (!val) return true;
                        const { flag, newValue } = phoneInputHandle(val ?? '');
                        setValue('faxPhone', newValue);
                        return !flag ? 'Please enter a valid Fax number' : true;
                      },
                    },
                  })}
                  defaultValue={contact?.faxPhone ?? ''}
                  placeholder={isPhoneNumberNotProvided ? '' : '(512) 234-5890'}
                  error={errors.faxPhone}
                />
              </div>
            </FormGroup>
          )}
          <FormInline className="mb-5">
            <FormGroup className="w-80">
              <Label htmlFor="timezone">Contact Time Zone</Label>
              <div data-testid="contactTimezone">
                <Controller
                  name="contactTimeZone"
                  control={control}
                  render={({ field }) => (
                    <ExpressableSelect
                      {...field}
                      options={timeZones}
                      isSearchable
                      size={20}
                      error={errors.contactTimeZone}
                    />
                  )}
                  defaultValue={timezoneDefaultValue}
                  rules={{ required: 'contact time zone is required' }}
                />
              </div>
            </FormGroup>
          </FormInline>
          <FormGroup className="mt-4 md:mt-0">
            <Label optional htmlFor="street">
              Address
            </Label>
            <Input
              type="address"
              id="street"
              data-testid="client-contact-form-address-first-line"
              className="block"
              {...register('street')}
              defaultValue={address?.street}
              error={errors.street}
              size={41}
            />
            <Label optional htmlFor="line2">
              Apt/Suite/Other
            </Label>
            <Input
              type="address"
              id="line2"
              data-testid="client-contact-form-address-second-line"
              className="block mt-2"
              {...register('line2')}
              defaultValue={address?.line2 ?? ''}
              error={errors.line2}
              size={41}
            />
          </FormGroup>
          <FormInline className="md:flex">
            <FormGroup className="block md:inline-block">
              <Label optional htmlFor="city">
                City
              </Label>
              <Input
                type="text"
                id="city"
                data-testid="client-contact-form-city"
                className="block mr-3"
                {...register('city', { validate: { onlyLettersSpecialVowelsWhiteSpacesValidator } })}
                defaultValue={address?.city ?? ''}
                error={errors.city}
              />
            </FormGroup>
            <FormGroup className="block md:inline-block">
              <Label optional htmlFor="state">
                State
              </Label>
              <div className="w-40 mr-3" data-testid="state">
                <Controller
                  name="state"
                  control={control}
                  render={({ field }) => <Select {...field} options={usStatesOptions} isSearchable />}
                  defaultValue={stateDefaultValue}
                />
              </div>
            </FormGroup>
            <FormGroup className="block md:inline-block mt-4 md:mt-0">
              <Label optional htmlFor="zip-code">
                Zip Code
              </Label>
              <Input
                type="text"
                id="zip-code"
                data-testid="client-contact-form-zip-code"
                className="block"
                {...register('postalCode', { validate: { validateZipcode } })}
                defaultValue={address?.postalCode}
                error={errors.postalCode}
              />
            </FormGroup>
          </FormInline>
          {contactID && <ContactCommunicationPreferences  clientId={clientId} contact={contact} register={register}/>}

          <div className="flex justify-between items-center">
            <div>
              <Button
                type="submit"
                variant="primary"
                loading={
                  editContactMutation.isLoading || createContactMutation.isLoading || isLoadingDuplicatedContacts
                }
                data-testid="contact-save"
              >
                {`${contactID ? 'Save changes' : 'Save new contact'}`}
              </Button>
              <Button
                className="ml-6"
                onClick={() => {
                  setShowEditForm(false);

                  if (!contactID) {
                    setShowDuplicateFinder(true);
                  }
                }}
                type="button"
                variant="secondary"
                data-testid="contact-cancel"
              >
                Cancel
              </Button>
            </div>
            {!isContactPrimaryContact && contactID && (
              <Link data-testid="contact-remove" className="text-indigo-700" to="#" onClick={RemoveContactModalOnOpen}>
                <FontAwesomeIcon className="text-sm mr-2" icon={['far', 'trash']} />
                Remove Contact
              </Link>
            )}
          </div>
        </form>
        {isGrantPortalAccessModalOpen && (
          <GrantPortalAccessModal
            isOpen={isGrantPortalAccessModalOpen}
            onClose={onGrantPortalAccessModalClose}
            clientId={clientId}
            contact={contact}
          />
        )}
        <RemoveContactModal
          isOpen={RemoveContactModalIsOpen}
          onClose={RemoveContactModalOnClose}
          clientId={clientId}
          contactId={contactID}
          setShowEditForm={setShowEditForm}
        />
      </>
    );
  }

  if (!contactID && showDuplicateFinder) {
    return (
      <DuplicateContactsFinder
        setContacts={setContacts}
        setShowEditForm={setShowEditForm}
        setShowDuplicateFinder={setShowDuplicateFinder}
        index={index}
        clientData={clientData}
        contacts={contacts}
        isOpen={duplicatedContactModalIsOpen}
        onClose={duplicatedContactModalOnClose}
        onOpen={duplicatedContactModalOnOpen}
        setFinderDefaultPhone={setFinderDefaultPhone}
        setFinderDefaultEmail={setFinderDefaultEmail}
        setFinderDefaultPhoneType={setFinderDefaultPhoneType}
        clientId={clientId}
        setIsPhoneSearch={setIsPhoneSearch}
        setIsEmailSearch={setIsEmailSearch}
      />
    );
  }

  return (
    <ClientContactFormReadOnly
      contact={contact}
      isContactPrimaryContact={isContactPrimaryContact}
      setShowEditForm={setShowEditForm}
      isLoading={createContactMutation.isLoading}
      setShowPhoneField={setShowPhoneField}
      setShowEmailField={setShowEmailField}
    />
  );
}
