import { useMutation, useQueryClient } from 'react-query';
import { http } from '@expressable/utils';
import { CompleteTherapistInformation } from '../types';
import { useToasts } from 'react-toast-notifications';
import { presentMutationError } from '@expressable/ui-library';

export interface CreateTherapistPayload {
  therapistInformation: CompleteTherapistInformation;
  onSuccess: (therapistID: string) => void;
}

const createTherapist = async (payload: CreateTherapistPayload) => {
  const { therapistInformation } = payload;
  return http.post(`/therapists`, therapistInformation).then(res => res.data);
};

export default function useCreateTherapist() {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(createTherapist, {
    onSuccess: (response, payload) => {
      // Query Invalidations
      addToast('Therapist Information Successfully Created', { appearance: 'success', autoDismiss: true });
      queryClient.invalidateQueries('therapists');
      payload.onSuccess(response.therapistEmail);
    },
    onError: presentMutationError,
  });
}
