import * as forge from 'node-forge';

export async function encodeCognitoPassword(password: string, key: string) {
  try {
    const publicKey = forge.pki.publicKeyFromPem(key);
    const encoded = publicKey.encrypt(password);
    return forge.util.encode64(encoded);
  } catch (error) {
    console.error('Error encoding password:', error);
    throw error;
  }
}
