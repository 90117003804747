import {
  Button,
  FormGroup,
  Input,
  Label,
  Link,
  LoadingText,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
} from '@expressable/ui-library';
import { useState } from 'react';

import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { SingleDatePicker } from 'react-dates';

import { ISingleAppointment, PendingAppointment, SelectOption, Therapist } from 'types';
import { timeOptions as appointmentTimeOptions } from 'utils/time-options';
import 'twin.macro';
import { LogCancelationModal } from 'components/client-attendance-modals/log-cancelation-modal';
import FormGroupTitle from 'components/form-group-title';
import TimezoneMenu from 'components/timezone-menu';
import useEditAppointmentModal from './use-edit-appointment-modal';
import { isOtherReasonValid } from 'utils/helpers';
import { useEffect } from 'react';
import { uniqBy } from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Conflicts from './conflicts';
import { IConflict } from 'utils/appointments/conflicts';
import { getWeekSummaryStatusDescription } from 'domain/therapist/constants';

dayjs.extend(utc);
dayjs.extend(timezone);

interface EditAppointmentsModalProps {
  isOpen: boolean;
  clientId: string;
  appointmentData: ISingleAppointment | undefined;
  contactTimeZone?: string;
  onClose: () => void;
  onOpen: () => void;
  therapistData: Therapist;
  contactState?: string;
}

export function EditAppointmentsModal(props: EditAppointmentsModalProps) {
  const {
    isOpen,
    onClose,
    onOpen,
    appointmentData,
    clientId,
    contactTimeZone = '',
    therapistData,
    contactState,
  } = props;
  const {
    date,
    setDate,
    time,
    setTime,
    datePickerFocused,
    setDatePickerFocused,
    isSidebarAppointment,
    setIsSidebarAppointment,
    displayTimezone,
    setDisplayTimezone,
    cancelationReasons,
    setRescheduleReasonOther,
    rescheduleReasonOther,
    rescheduleReason,
    setRescheduleReason,
    availableTimes,
    setAvailableTimes,
    editAppointmentMutation,
    appointmentLength,
    setAppointmentLength,
    initialEvaluationLength,
    setInitialEvaluationLength,
    isCurrentApptEvaluation,
    availableTimesV2,
    isLoading: isLoadingTimes,
    availableTimesOptions,
    isLogCancelationModalOpen,
    onCloseLogCancelationModal,
    onOpenLogCancelationModal,
    onSwitchToEditModal,
    submit,
    initialEvaluationLengthOptions,
    handleOnChangeRescheduledBy,
    appointmentLengthOptions,
    setRescheduleType,
    rescheduleType,
    handleOnChangeRescheduleType,
    createPendingRescheduleMutation,
    selectedTherapist,
    setSelectedTherapist,
    therapistSelectOptions,
  } = useEditAppointmentModal({
    isOpen,
    onClose,
    onOpen,
    appointmentData,
    clientId,
    contactTimeZone,
    therapistData,
    contactState,
  });
  const [conflicts, setConflicts] = useState<IConflict[]>([]);
  const { cueSchedulingControl: displayBookingCapability } = useFlags();

  useEffect(() => {
    if (isCurrentApptEvaluation && isOpen) {
      setRescheduleType('self-reschedule');
    }
  }, [isCurrentApptEvaluation, setRescheduleType, isOpen]);

  useEffect(() => {
    if (isLoadingTimes || !date) {
      setConflicts([]);
      return;
    }

    const selectedDateFormatted = date?.toISOString();

    const isDateInAvailableTimes = (item: any) => {
      const itemTime = moment(item.time).tz(displayTimezone.value).toISOString();
      return itemTime === selectedDateFormatted;
    };

    const handleOverride = () => {
      const isAvailable = availableTimesV2?.items?.some(isDateInAvailableTimes);
      const conflictKey = selectedDateFormatted;
      const conflictMap = new Map<string, IConflict>();

      if (!isAvailable) {
        conflictMap.set(conflictKey, {
          datetime: selectedDateFormatted,
          type: availableTimes ? 'appointmentExists' : 'futureConflict',
        });
      }

      setConflicts(Array.from(conflictMap.values()));
    };

    const handleNonOverride = () => {
      const isAvailable =
        availableTimesV2?.items?.length === 0 || availableTimesV2?.items?.some(isDateInAvailableTimes);
      const conflictKey = selectedDateFormatted;
      const conflictMap = new Map<string, IConflict>();

      if (!isAvailable) {
        conflictMap.set(conflictKey, {
          datetime: selectedDateFormatted,
          type: availableTimes ? 'appointmentExists' : 'futureConflict',
        });
      }

      const selectedItem = availableTimesV2?.items?.find(isDateInAvailableTimes);
      selectedItem?.conflicts?.forEach((conflict: { datetime: string; type: string }) => {
        conflictMap.set(conflict?.datetime, {
          datetime: conflict?.datetime,
          type: availableTimes ? conflict?.type : 'futureConflict',
        });
      });

      setConflicts(Array.from(conflictMap.values()));
    };

    if (!availableTimes) handleOverride();
    else handleNonOverride();
  }, [isLoadingTimes, availableTimes, availableTimesV2, date, time, displayTimezone, setConflicts]);

  return (
    <>
      {!isLogCancelationModalOpen ? (
        <Modal isOpen={isOpen}>
          <ModalContent>
            <form onSubmit={submit}>
              <ModalHeader>
                <div tw="text-right">
                  <Link data-testid="edit-appointment-close" to="#" onClick={onClose}>
                    <FontAwesomeIcon tw="text-2xl text-gray-600" icon={['far', 'times']} />
                  </Link>
                </div>
              </ModalHeader>
              <ModalBody>
                <div>
                  <div tw="flex items-center justify-center w-12 h-12 mx-auto bg-indigo-100 rounded-full">
                    <FontAwesomeIcon tw="text-2xl text-indigo-700" icon="calendar-check" />
                  </div>
                  <div tw="mt-3 sm:mt-5">
                    <h3 tw="text-lg text-center font-medium text-gray-900 leading-6" id="modal-headline">
                      Reschedule Appointment
                    </h3>
                    <h4 tw="text-center leading-6">
                      {dayjs(appointmentData?.appointmentDateTime).tz(dayjs.tz.guess()).format('MM/DD/YYYY')} at&nbsp;
                      {dayjs(appointmentData?.appointmentDateTime).tz(dayjs.tz.guess()).format('hh:mm A')}
                    </h4>
                    {displayBookingCapability && (
                      <Link
                        data-testid="edit-appointment-cancel"
                        to="#"
                        tw="block text-red-500 text-center mt-2"
                        onClick={() => {
                          onClose();
                          onOpenLogCancelationModal();
                          setIsSidebarAppointment(true);
                        }}
                      >
                        <FontAwesomeIcon tw="text-base mr-2" icon={['far', 'trash']} />
                        Cancel Appointment
                      </Link>
                    )}
                    <TimezoneMenu
                      displayTimezone={displayTimezone}
                      setDisplayTimezone={setDisplayTimezone}
                      date={date}
                      setDate={setDate}
                      disabled={true}
                    />
                  </div>
                  <div tw="mt-6">
                    <FormGroupTitle
                      title="Reschedule initiated by"
                      fontSize="small"
                      fontWeight="semi"
                      spacing="large"
                    />
                    <FormGroup type="inline" tw="items-center">
                      <Input
                        type="radio"
                        id="rescheduleBy-client"
                        name="rescheduleBy"
                        spacing="tight"
                        data-testid="reschedule-by-client"
                        value="client"
                        required
                        onChange={handleOnChangeRescheduledBy}
                      />
                      <Label font="normal" htmlFor="rescheduleBy-client" tw="ml-2">
                        Client
                      </Label>
                      <Input
                        id="rescheduleBy-therapist"
                        name="rescheduleBy"
                        tw="ml-4"
                        spacing="tight"
                        type="radio"
                        data-testid="reschedule-by-therapist"
                        value="therapist"
                        required
                        onChange={handleOnChangeRescheduledBy}
                      />
                      <Label font="normal" htmlFor="rescheduleBy-therapist" tw="ml-2">
                        Therapist
                      </Label>
                    </FormGroup>
                    <FormGroup tw="mt-6" data-testid="reschedule-reason">
                      <FormGroupTitle title="Reschedule Reason" fontSize="small" fontWeight="semi" spacing="large" />
                      <Select
                        required
                        aria-label="reschedule-reason"
                        name="reschedule-reason"
                        options={cancelationReasons}
                        onChange={(event: { value: string }) => {
                          setRescheduleReason(event.value);
                        }}
                      />
                    </FormGroup>
                    {rescheduleReason === 'other' && (
                      <FormGroup>
                        <FormGroupTitle
                          title="What was the reason?"
                          fontSize="small"
                          fontWeight="semi"
                          spacing="normal"
                        />
                        <Input
                          type="text"
                          tw="w-full"
                          data-testid="reschedule-reason"
                          required
                          onChange={event => setRescheduleReasonOther(event.target.value)}
                        />
                      </FormGroup>
                    )}
                  </div>
                  {!isCurrentApptEvaluation && (
                    <div tw="mt-2">
                      <FormGroupTitle title="Select one" fontSize="small" fontWeight="semi" spacing="large" />
                      <FormGroup type="inline">
                        <Input
                          type="radio"
                          id="reschedule-link"
                          name="scheduleType"
                          spacing="tight"
                          data-testid="reschedule-link"
                          value="link"
                          required
                          onChange={e => handleOnChangeRescheduleType(e.target.value as 'link' | 'self-reschedule')}
                        />
                        <Label font="normal" htmlFor="reschedule-link" tw="ml-2">
                          Send your client a reschedule link
                        </Label>
                      </FormGroup>

                      <FormGroup type="inline" tw="mt-2">
                        <Input
                          id="self-reschedule"
                          name="scheduleType"
                          spacing="tight"
                          type="radio"
                          data-testid="self-reschedule"
                          defaultChecked={isCurrentApptEvaluation}
                          value="self-reschedule"
                          required
                          onChange={e => handleOnChangeRescheduleType(e.target.value as 'link' | 'self-reschedule')}
                        />
                        <Label font="normal" htmlFor="self-reschedule" tw="ml-2">
                          Reschedule for your client
                        </Label>
                      </FormGroup>
                    </div>
                  )}

                  {rescheduleType === 'self-reschedule' && (
                    <>
                      <FormGroup tw="mt-6">
                        <FormGroupTitle title="Therapist" fontSize="small" fontWeight="semi" spacing="normal" />
                        <Select
                          aria-label="appointment-therapist-dropdown"
                          name="appointment-therapist-dropdown"
                          data-testid="add-appointment-therapist"
                          id="appointment-therapist-dropdown"
                          value={selectedTherapist}
                          disabled={!contactState}
                          options={uniqBy(
                            [
                              ...(therapistSelectOptions ?? []),
                              {
                                label: `${therapistData.firstName} ${therapistData.lastName}`,
                                value: therapistData?.therapistID,
                              },
                            ],
                            'value',
                          )}
                          onChange={(e: SelectOption) => setSelectedTherapist(e)}
                          tw="mb-2"
                        />
                        <div className="text-xs text-gray-500">
                          {contactState
                            ? `Only therapists with a state licensure of ${contactState} are displayed`
                            : 'Please add a state to the primary contact to be able to add to another therapist'}
                        </div>
                      </FormGroup>
                      <div className={`flex ${isCurrentApptEvaluation ? 'mt-4' : 'mt-8'}`}>
                        <Input
                          data-testid="edit-appointment-available-times"
                          name="available-times"
                          id="available-times"
                          type="checkbox"
                          tw="mr-2"
                          checked={availableTimes}
                          onChange={e => setAvailableTimes(e.currentTarget.checked)}
                        />
                        <Label font="normal" htmlFor="available-times">
                          Only Show Available Times
                        </Label>
                      </div>
                      {!availableTimes && (
                        <div tw="bg-[#FEE2E2] p-3 rounded-md mb-4">
                          Please note that viewing unavailable times removes all calendar protections and may result in
                          a schedule conflict.
                        </div>
                      )}
                      <div tw="flex">
                        <div tw="w-1/2 mr-2">
                          <div data-testid="edit-appointment-date">
                            <Label tw="mb-2">New Date</Label>
                            <SingleDatePicker
                              id="edit-appointment-date"
                              block
                              small
                              noBorder
                              openDirection="up"
                              numberOfMonths={1}
                              hideKeyboardShortcutsPanel
                              focused={datePickerFocused}
                              onFocusChange={({ focused }) => setDatePickerFocused(focused)}
                              date={date}
                              onDateChange={date => {
                                setDate(date);
                              }}
                            />
                          </div>
                        </div>
                        {availableTimes ? (
                          <div tw="w-1/2 ml-2">
                            {isLoadingTimes ? (
                              <div data-testid="edit-appointments-loading" tw="flex-1 text-center mt-8">
                                <LoadingText />
                              </div>
                            ) : (
                              <div>
                                {!availableTimesV2?.isRateLimited && availableTimesV2?.items?.length ? (
                                  <>
                                    <Label tw="mb-2">New Time</Label>
                                    <div data-testid="edit-appointments-available-time">
                                      <Select
                                        aria-label="time-dropdown"
                                        name="time-dropdown"
                                        spacing="small"
                                        options={availableTimesOptions}
                                        value={time}
                                        onChange={(timeOption: { label: string; value: number }) => {
                                          setTime(timeOption);
                                        }}
                                      />
                                    </div>
                                  </>
                                ) : (
                                  <div
                                    tw="flex items-center justify-center text-gray-500 mt-6 ml-4"
                                    data-testid="not-available-times"
                                  >
                                    {availableTimesV2?.isRateLimited ? (
                                      getWeekSummaryStatusDescription(availableTimesV2.rateLimitStatus)
                                    ) : (
                                      <>
                                        <span className="mt-8 ml-4">No times available today</span>
                                      </>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        ) : (
                          <div tw="w-1/2 ml-2">
                            <div data-testid="edit-appointment-time">
                              <Label tw="mb-2">New Time</Label>
                              <Select
                                aria-label="time-dropdown"
                                name="time-dropdown"
                                spacing="small"
                                value={time}
                                options={appointmentTimeOptions}
                                onChange={(timeOption: { label: string; value: number }) => {
                                  setTime(timeOption);
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </div>

                      {/* Speech Therapy Session Duration */}
                      {!isCurrentApptEvaluation && (
                        <div tw="flex mt-5">
                          <div tw="w-full">
                            <div data-testid="edit-appointment-duration">
                              <Label tw="mb-2">Duration</Label>
                              <Select
                                tw="mb-2"
                                aria-label="appointment-type-dropdown"
                                name="appointment-type-dropdown"
                                value={appointmentLength}
                                options={appointmentLengthOptions}
                                onChange={(selectedOption: SelectOption) => setAppointmentLength(selectedOption)}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      {/* Initial Evaluation Duration */}
                      {isCurrentApptEvaluation && (
                        <div tw="flex mt-5">
                          <div tw="w-full">
                            <div data-testid="edit-initial-evaluation-duration">
                              <Label tw="mb-2">Duration</Label>
                              <Select
                                tw="mb-2"
                                aria-label="initial-evaluation-type-dropdown"
                                name="initial-evaluation-type-dropdown"
                                value={initialEvaluationLength}
                                options={initialEvaluationLengthOptions}
                                onChange={(selectedOption: SelectOption) => setInitialEvaluationLength(selectedOption)}
                              />
                            </div>
                          </div>
                        </div>
                      )}

                      {!isLoadingTimes && conflicts.length > 0 && (
                        <div className="mt-3">
                          <Conflicts
                            conflicts={conflicts}
                            availableTimes={availableTimes}
                            displayTimezone={displayTimezone.value}
                          />
                        </div>
                      )}
                    </>
                  )}

                  {rescheduleType === 'link' && (
                    <p tw="mt-6 text-[16px]">
                      Your client will receive a reschedule link via SMS and select a new appointment date and time
                      based on your availability.
                    </p>
                  )}
                </div>
              </ModalBody>
              <ModalFooter>
                <div tw="mt-4 sm:mt-5">
                  <span tw="flex w-full rounded-md shadow-sm sm:col-start-2">
                    <Button
                      data-testid="edit-appointment-submit"
                      type="submit"
                      variant="primary"
                      loading={editAppointmentMutation.isLoading || createPendingRescheduleMutation.isLoading}
                      disabled={
                        rescheduleType === 'link'
                          ? Boolean(
                              editAppointmentMutation.isLoading ||
                                createPendingRescheduleMutation.isLoading ||
                                !rescheduleType ||
                                !rescheduleReason ||
                                isOtherReasonValid(rescheduleReason, rescheduleReasonOther),
                            )
                          : Boolean(
                              editAppointmentMutation.isLoading ||
                                (availableTimes && availableTimesV2?.isRateLimited) ||
                                createPendingRescheduleMutation.isLoading ||
                                !rescheduleType ||
                                !rescheduleReason ||
                                isOtherReasonValid(rescheduleReason, rescheduleReasonOther),
                            ) ||
                            (availableTimes && !availableTimesOptions?.length)
                      }
                      tw="inline-flex justify-center w-full px-4 py-2 text-base font-medium leading-6 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    >
                      {rescheduleType === 'link' ? 'Send Reschedule Link' : 'Reschedule Appointment'}
                    </Button>
                  </span>
                </div>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>
      ) : (
        <LogCancelationModal
          isOpen={isLogCancelationModalOpen}
          onClose={onCloseLogCancelationModal}
          activity={appointmentData as unknown as PendingAppointment}
          onRescheduleSwitch={onSwitchToEditModal}
          clientId={clientId}
          isSidebarAppointment={isSidebarAppointment}
        />
      )}
    </>
  );
}
