import { Dayjs } from 'dayjs';
import { EvaluationOption, EVALUATION_OPTIONS } from 'hooks/common/useTherapistFilters/options';
import moment from 'moment';

/* Minimum amount of days between the consultation and the first evaluation */
const MIN_DAYS_BEFORE_SHORT_EVALUATION = 3;
const MIN_DAYS_BEFORE_STANDARD_EVALUATION = 7;

/* Maximum amount of days between the consultation and the first evaluation */
export const MAX_DAYS_FOR_FIRST_EVALUATION = 21;

/* Minimum amount of days between the evaluation and the first recurring session */
const MIN_DAYS_BEFORE_FIRST_RECURRING_SESSION = 7;

/* Minimum amount of days between the first recurring session and the second recurring session */
const MIN_DAYS_BEFORE_SECOND_RECURRING_SESSION = 1;

// Maximum amount of days for schedule a recurring session
export const MAX_DAYS_FOR_RECURRING_SESSION = 36;

export const getMinDateForEvaluation = (evaluationType: EvaluationOption) => {
  switch (evaluationType) {
    case EVALUATION_OPTIONS.SHORT:
      return moment().add(MIN_DAYS_BEFORE_SHORT_EVALUATION, 'day');
    case EVALUATION_OPTIONS.STANDARD:
      return moment().add(MIN_DAYS_BEFORE_STANDARD_EVALUATION, 'day');
  }
  throw `Unknown evaluation type ${JSON.stringify(evaluationType)}`;
};

export const getAWeekAfterEvaluation = (evaluationTime: Dayjs) => evaluationTime?.add(7, 'day');

export const getMinDateForFirstRecurring = (evaluationDate: Dayjs) => {
  return evaluationDate.add(MIN_DAYS_BEFORE_FIRST_RECURRING_SESSION, 'days');
};

export const getMinDateForSecondRecurring = (firstRecurringDate: Dayjs) => {
  return firstRecurringDate.add(MIN_DAYS_BEFORE_SECOND_RECURRING_SESSION, 'days');
};

/* Get a list of timestamps to show in the grid for a given therapist */
export const getTherapistWeekScheduleDays = (date: Dayjs, count: number) => {
  const week: Dayjs[] = [];

  for (let i = 0; i < count; i++) {
    week.push(date.add(i, 'day'));
  }

  return week;
};
