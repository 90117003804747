import dayjs from 'dayjs';
import { getEntityFromState } from 'pages/new-client-create/ReviewSummary/stripe-entities';
import { BILLING_LINK_TYPES } from 'pages/new-client-create/BillingInformation/options';
import { getBillingIntakeUrl } from 'utils/billing-intake';
import { IBillingInfoInsuranceForm, IInsuranceForm } from 'types';
import { capitalizeFirst } from 'utils/helpers';

export const getBillingIntakeURL = (
  billingType: BILLING_LINK_TYPES,
  state: string,
  crmId: string,
  insuranceStripeEntity: string | null | undefined,
  tier: string,
) => {
  const URLPrefix = getBillingIntakeUrl();

  if (billingType === BILLING_LINK_TYPES.DEPOSIT_ONLY) {
    const entity = insuranceStripeEntity?.toLowerCase() || 'not_found';
    return `${URLPrefix}/${entity}/${crmId}/deposit`;
  } else {
    const entity = getEntityFromState(state.toLowerCase());
    const tierParam = tier && tier !== 'full_price' ? `tier=${tier}` : '';
    return `${URLPrefix}/${entity}/${crmId}/eval?${tierParam}`;
  }
};

const getPrimaryOrSecondaryInfo = (primaryInsurance: IInsuranceForm) => {
  if (!primaryInsurance) {
    return undefined;
  }

  return {
    insuredPayerType: primaryInsurance.insuredPayerType?.label,
    insuredRelationshipToClient: primaryInsurance.insuredRelationshipToClient?.label,
    insuredFirstName: primaryInsurance.insuredFirstName,
    insuredMiddleName: primaryInsurance.insuredMiddleName,
    insuredLastName: primaryInsurance.insuredLastName,
    insuredGender: primaryInsurance.insuredGender?.label,
    insuredAddress1: primaryInsurance.insuredAddress?.address1,
    insuredAddress2: primaryInsurance.insuredAddress?.address2,
    city: primaryInsurance.insuredAddress?.city,
    state: primaryInsurance.insuredAddress?.state?.label,
    zipCode: primaryInsurance.insuredAddress?.zipCode,
    insurancePayer: primaryInsurance.insurancePayer?.label,
    insurancePlanEligibility: primaryInsurance.insurancePlanEligibility?.label,
    insuredMemberID: primaryInsurance.insuredMemberID,
    insuredGroupID: primaryInsurance.insuredGroupID,
    insuredDob:
      primaryInsurance.monthBirth && primaryInsurance.dayBirth && primaryInsurance.yearBirth
        ? `${primaryInsurance.monthBirth.value}/${primaryInsurance.dayBirth.value}/${primaryInsurance.yearBirth.value}`
        : undefined,
  };
};

export const getInsuranceInfo = (insuranceContent?: IBillingInfoInsuranceForm) => {
  if (!insuranceContent) {
    return undefined;
  }

  const primaryInsurance = insuranceContent.primaryInsurance;
  const secondaryInsurance = insuranceContent.secondaryInsurance;
  const insuranceAuthorizations = insuranceContent.insuranceAuthorizations;
  const content = {
    networkStatus: insuranceContent.networkStatus?.label,
    automaticCoInsuranceCollection: capitalizeFirst(insuranceContent.automaticCoInsuranceCollection as string),
    coPay: primaryInsurance?.coPay === '0' ? undefined : primaryInsurance?.coPay,
    primaryInsurance: getPrimaryOrSecondaryInfo(primaryInsurance),
    secondaryInsurance: getPrimaryOrSecondaryInfo(secondaryInsurance),
    insuranceAuthorizations: {
      priorAuthorizationNumber: primaryInsurance?.priorAuthorizationNumber,
      evaluationAuthStatus: insuranceAuthorizations?.evaluationAuthStatus?.label,
      sessionAuthStatus: insuranceAuthorizations?.sessionAuthStatus?.label,
      visitLimits: capitalizeFirst(insuranceAuthorizations?.visitLimits as string),
      remainingVisitsLeft:
        insuranceAuthorizations?.visitLimits === 'yes' ? insuranceAuthorizations?.remainingVisitsLeft : undefined,
      dateLimits: capitalizeFirst(insuranceAuthorizations?.dateLimits as string),
      visitsCompletedBy:
        insuranceAuthorizations?.dateLimits === 'yes'
          ? dayjs(insuranceAuthorizations?.visitsCompletedBy).format('MM/DD/YYYY')
          : undefined,
      reEvaluationRequired: capitalizeFirst(insuranceAuthorizations?.reEvaluationRequired as string),
      reEvaluationDueBy:
        insuranceAuthorizations?.reEvaluationRequired === 'yes'
          ? dayjs(insuranceAuthorizations?.reEvaluationDueBy).format('MM/DD/YYYY')
          : undefined,
    },
  };

  return {
    ...content,
    hasPrimaryInsuranceInfo: content.primaryInsurance
      ? !!Object.values(content.primaryInsurance).find(item => !!item)
      : false,
    hasSecondaryInsuranceInfo: content.secondaryInsurance
      ? !!Object.values(content.secondaryInsurance).find(item => !!item)
      : false,
    hasInsuranceAuthInfo: content.insuranceAuthorizations
      ? !!Object.values(content.insuranceAuthorizations).find(item => !!item)
      : false,
  };
};
