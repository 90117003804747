import { Card, Button, Spacer, useDisclosure } from '@expressable/ui-library';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { Contact } from 'types';
import ClientContactForm from './client-contact-form';
import { useClient } from 'hooks/use-client';
import 'twin.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty } from 'lodash';

export const emptyContact: Contact = {
  address: {
    city: '',
    line2: '',
    postalCode: '',
    state: '',
    street: '',
  },
  primaryContact: false,
  contactFirstName: '',
  contactMiddleName: '',
  contactLastName: '',
  contactTimeZone: '',
  contactID: '',
  email: '',
  phone: {
    mobile: '',
  },
  relationshipToClient: '',
};

export interface ClientContactsFormProps {
  clientId: string;
}
interface NoContactsMessageProps {
  setContacts: Dispatch<SetStateAction<Contact[]>>;
  emptyContact: Contact;
  setShowDuplicateFinder: Dispatch<SetStateAction<boolean>>;
}

const NoContactsMessage = ({ setContacts, emptyContact, setShowDuplicateFinder }: NoContactsMessageProps) => (
  <div tw="flex flex-col items-center justify-center h-64 min-h-full bg-gray-200">
    <div tw="text-gray-600 text-lg mb-5">There are currently no contacts for this client</div>
    <Button
      onClick={() => {
        setContacts([emptyContact]);
        setShowDuplicateFinder(true);
      }}
      variant="secondary"
    >
      Add a Contact
    </Button>
  </div>
);

export default function ClientContactsForm(props: ClientContactsFormProps) {
  const { clientId } = props;
  const { data: clientData } = useClient(clientId);
  const clientContacts = clientData?.contactInformation ?? [];
  let primaryContact = clientContacts.find(contact => contact.primaryContact);
  const [showDuplicateFinder, setShowDuplicateFinder] = useState(false);

  if (isEmpty(primaryContact)) {
    if (clientContacts.length > 0) {
      primaryContact = clientContacts[0];
    }
  }

  const [contacts, setContacts] = useState(clientContacts);
  const [prevContactsLength, setPrevContactsLength] = useState(contacts.length);
  const [currentContactID, setCurrentContactID] = useState<string | null>(null);

  React.useEffect(() => {
    setContacts(clientContacts);
  }, [clientContacts]);

  React.useEffect(() => {
    if (contacts.length < prevContactsLength && showDuplicateFinder) {
      setContacts([...contacts, emptyContact]);
    }

    setPrevContactsLength(contacts.length);
  }, [contacts, prevContactsLength]);

  const {
    isOpen: duplicatedContactModalIsOpen,
    onClose: duplicatedContactModalOnClose,
    onOpen: duplicatedContactModalOnOpen,
  } = useDisclosure();

  const {
    isOpen: duplicatedContactsConfirmationModalIsOpen,
    onClose: duplicatedContactsConfirmationModalOnClose,
    onOpen: duplicatedContactsConfirmationModalOnOpen,
  } = useDisclosure();

  return (
    <div data-testid="client-contacts-form">
      <Spacer size="md" />
      {contacts && contacts.length == 0 ? (
        <NoContactsMessage
          emptyContact={emptyContact}
          setContacts={setContacts}
          setShowDuplicateFinder={setShowDuplicateFinder}
        />
      ) : (
        contacts?.map((contact, index) => (
          <Card
            key={index}
            tw="px-8 mb-5"
            className={`${
              index === contacts.length - 1 && !contact.contactID && duplicatedContactModalIsOpen
                ? 'rounded-none bg-inherit shadow-none'
                : ''
            } ${
              contact.contactID === currentContactID && duplicatedContactsConfirmationModalIsOpen
                ? 'rounded-none bg-inherit shadow-none'
                : ''
            }`}
            onClick={() => setCurrentContactID(contact.contactID)}
          >
            <ClientContactForm
              index={index}
              setContacts={setContacts}
              primaryContact={primaryContact}
              clientId={clientId}
              contact={contact}
              setShowDuplicateFinder={setShowDuplicateFinder}
              clientData={clientData}
              contacts={contacts}
              duplicatedContactModalIsOpen={duplicatedContactModalIsOpen}
              duplicatedContactModalOnClose={duplicatedContactModalOnClose}
              duplicatedContactModalOnOpen={duplicatedContactModalOnOpen}
              showDuplicateFinder={showDuplicateFinder}
              duplicatedContactsConfirmationModalIsOpen={duplicatedContactsConfirmationModalIsOpen}
              duplicatedContactsConfirmationModalOnClose={duplicatedContactsConfirmationModalOnClose}
              duplicatedContactsConfirmationModalOnOpen={duplicatedContactsConfirmationModalOnOpen}
            />
          </Card>
        ))
      )}
      {!showDuplicateFinder && !duplicatedContactModalIsOpen && !duplicatedContactsConfirmationModalIsOpen && (
        <span
          onClick={() => {
            setContacts([...contacts, emptyContact]);
            setShowDuplicateFinder(true);
          }}
          tw="text-indigo-700 cursor-pointer"
          data-testid="contact-add"
        >
          <FontAwesomeIcon tw="text-sm mr-2" icon="plus-circle" />
          Add New Contact
        </span>
      )}
    </div>
  );
}
