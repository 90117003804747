import React from 'react';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from '@expressable/ui-library';
import { faFilePen } from '@fortawesome/free-solid-svg-icons';
import { EventProps } from 'domain/admin/types';
import { EntryId, useContentfulEntry } from 'hooks/use-contentful';
import {
  ContentfulEntry,
  evaluationQualityReviewOptions,
  getQualityReviewStatusLabel,
  mapToOption,
} from 'pages/client/components/client-plan-of-care-form';
import tw from 'twin.macro';

export default function QualityReviewEvaluationStatusUpdated(event: EventProps) {
  const firehoseEvent = event?.event;
  const isLastEvent = event?.isLastEvent;

  const { data: { dropdownContent = [] } = {} } = useContentfulEntry<ContentfulEntry>({
    entryId: EntryId.EvaluationQualityReview,
  });

  const evaluationGuidelinesControls = dropdownContent.filter(item => item)?.map(eqp => mapToOption(eqp));

  return (
    <div data-testid="firehose-quality-review-evaluation-status-updated" tw="relative flex mt-7 px-2">
      {!isLastEvent && <span tw="absolute -z-1 top-7 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>}
      <div tw="flex items-baseline">
        <div tw="flex items-center bg-gray-200 rounded-full h-6 w-6">
          <FontAwesomeIcon icon={faFilePen} tw="mx-1.5 h-3 text-indigo-700" />
        </div>
        <div tw="pl-4 text-sm">
          <div tw="lg:flex items-baseline font-semibold pb-1">
            <Link to={{ pathname: `/therapists/${firehoseEvent.createdBy}` }}>
              {firehoseEvent.createdByFirstName && firehoseEvent.createdByLastName ? (
                <span>
                  {firehoseEvent.createdByFirstName} {firehoseEvent.createdByLastName}
                </span>
              ) : (
                <span>{firehoseEvent.createdBy}</span>
              )}
              &nbsp;
            </Link>
            <div tw="text-gray-500">
              {`updated the clinical quality review status for the client`}&nbsp;
              <Link to={{ pathname: `/clients/${firehoseEvent.clientID}` }}>
                <span>
                  {firehoseEvent.clientFirstName} {firehoseEvent.clientLastName}
                </span>
                &nbsp;
              </Link>
              {`at ${dayjs(firehoseEvent.createdAt as string).format('h:mm A, MM/DD/YYYY')}`}
            </div>
          </div>
          <div tw="mt-2 mb-1 text-sm">Evaluation Quality Review</div>
          <span tw="inline-flex">
            {getQualityReviewStatusLabel(firehoseEvent.previousStatus, evaluationQualityReviewOptions)}&nbsp;&nbsp;
            <img src={process.env.PUBLIC_URL + '/rightarrow.svg'} alt="Right Arrow" />
            {getQualityReviewStatusLabel(firehoseEvent.newStatus, evaluationQualityReviewOptions)}
            &nbsp;
          </span>
          {firehoseEvent.issuesLevel && (
            <>
              <div tw="mt-4 mb-1 text-sm">Evaluation Overall Review</div>
              <span tw="capitalize">{firehoseEvent.issuesLevel.replace('_', ' ')}</span>
            </>
          )}
          {(firehoseEvent.checklist || firehoseEvent.reviewerNote) && (
            <>
              <div tw="mt-4 mb-1 text-sm">Reviewer Notes</div>
              {firehoseEvent.checklist && (
                <span>
                  {evaluationGuidelinesControls.map((option, index) => {
                    const isLineThrough = firehoseEvent.checklist.some(isSame => isSame === option.value);
                    return (
                      <li key={index} css={[isLineThrough ? tw`text-sm mt-2 line-through` : tw`text-sm mt-2`]}>
                        {option.label}
                      </li>
                    );
                  })}
                </span>
              )}
              {firehoseEvent.reviewerNote && (
                <div tw="mt-4">
                  <span>{firehoseEvent.reviewerNote}</span>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
