import { FormYesNoDetails, YesNoDetails } from 'domain/shared/types';
import { SelectOption } from 'types';

export function convertYesOrNoToBoolean(value?: string | null): boolean | null {
  if (!value) return null;
  return value === 'yes';
}

export function convertFreeTextRadioToYesOrNoDetails(value: FormYesNoDetails): YesNoDetails | null {
  if (!value?.relevant) return null;
  return {
    relevant: convertYesOrNoToBoolean(value.relevant)!,
    details: value.details || '',
  };
}

export function transformYesOrNoDetailToYesOrNoFormData(value?: YesNoDetails | null): FormYesNoDetails {
  if (!value) return { relevant: '', details: '' };
  return {
    relevant: convertBooleanToYesOrNo(value.relevant),
    details: value.details,
  };
}

export function convertStringToSelectOption(option?: string | null): SelectOption | null {
  if (!option) return null;
  return {
    label: option,
    value: option,
  };
}

export function convertBooleanToYesOrNo(value?: boolean | null) {
  if (value === null || value === undefined) return '';
  return value ? 'yes' : 'no';
}

export function convertStringArrayToSelectOptions(sList: string[] | null | undefined): SelectOption<string>[] | null {
  return sList ? sList.map(s => ({ label: s, value: s })) : null;
}
