import { TitleBar } from '@expressable/ui-library';
import EligibilityForm from 'pages/client/components/eligibility-form/eligibility-form';

export const ManualEligibility = () => {

  return (
    <>
      <TitleBar title="Check Eligibility" />
      <EligibilityForm />
    </>
  );
};
