import React, { useState } from 'react';
import { useDischargeQualityReviews, useEvaluationQualityReviews } from 'hooks/use-admin-quality-reviews';
import { usePagination } from 'hooks/use-pagination';
import { Card, Container, FormGroup, Label, Link, Loader, Select } from '@expressable/ui-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import Table from 'components/Table';
import dayjs from 'dayjs';
import { dischargeAdminTableOptions, evaluationsAdminTableOptions } from 'domain/admin/quality-reviews/constants';
import { SelectOption } from 'types';
import 'twin.macro';

const QualityReviews = () => {
  const [evaluationStatusSelected, setEvaluationStatusSelected] = useState<SelectOption>(
    evaluationsAdminTableOptions[0],
  );

  const [dischargeStatusSelected, setDischargeStatusSelected] = useState<SelectOption>(dischargeAdminTableOptions[0]);

  const {
    data: evaluationQualityReviews,
    isLoading: isLoadingEvaluationQualityReviews,
    isError: isEvaluationQualityReviewsError,
  } = useEvaluationQualityReviews(evaluationStatusSelected.value);

  const {
    data: dischargeQualityReviews,
    isLoading: isLoadingDischargeQualityReviews,
    isError: isDischargeQualityReviewsError,
  } = useDischargeQualityReviews(dischargeStatusSelected.value);

  const evaluationQualityReviewsPaginatedEntries = usePagination(evaluationQualityReviews, {
    perPage: 10,
  });

  const dischargeQualityReviewsPaginatedEntries = usePagination(dischargeQualityReviews, {
    perPage: 10,
  });

  if (isEvaluationQualityReviewsError || isDischargeQualityReviewsError) {
    return (
      <Container data-testid="quality-reviews-error" tw="relative pl-0" size="large">
        <div data-testid="quality-reviews-error-title" tw="flex justify-between items-center flex-wrap">
          <h2 tw="text-2xl font-semibold mb-3">Quality Reviews</h2>
        </div>
        <Card tw="mb-4 px-8 mt-8">
          <FormGroup tw="w-full sm:w-1/2 md:w-1/3">
            <Label>
              <FontAwesomeIcon icon={faExclamationTriangle} tw="text-red-500 mr-2" />
              <span tw="text-red-500">Quality Reviews are not available at this time.</span>
            </Label>
          </FormGroup>
        </Card>
      </Container>
    );
  }

  return (
    <div data-testid="quality-reviews">
      <div tw="md:pr-5" data-testid="evaluation-quality-reviews">
        <div tw="flex justify-between items-center flex-wrap">
          <h2 tw="text-2xl font-semibold mb-3">Evaluations</h2>
        </div>
        <Card tw="mt-2">
          {isLoadingEvaluationQualityReviews ? (
            <div data-testid="evaluation-quality-reviews-loader" tw="flex-1 text-center py-20">
              <Loader type="ring" />
            </div>
          ) : (
            <>
              <div tw="mb-4 sm:mb-0">
                <div tw="flex flex-col sm:flex-row mb-4">
                  <>
                    <span tw="text-sm font-semibold mt-6 ml-2 hidden sm:block">View</span>
                    <div tw="flex items-center sm:items-stretch justify-items-stretch mr-2 ml-2 mt-4 font-normal">
                      <span tw="text-sm font-semibold mr-2 block sm:hidden">View</span>
                      <Select
                        tw="w-full sm:w-96"
                        id="evaluationQualityReviewsOptionSelect"
                        placeholder=""
                        spacing="tight"
                        value={evaluationStatusSelected}
                        options={evaluationsAdminTableOptions}
                        onChange={(selectOption: SelectOption) => {
                          setEvaluationStatusSelected(selectOption);
                        }}
                      />
                    </div>
                  </>
                </div>
              </div>

              {evaluationQualityReviewsPaginatedEntries.items?.length ? (
                <>
                  <Table>
                    <Table.Header>
                      <Table.Row>
                        <Table.Cell>Status Date</Table.Cell>
                        <Table.Cell>Client Name</Table.Cell>
                        <Table.Cell>Evaluation Date</Table.Cell>
                        <Table.Cell tw="hidden sm:table-cell">Frequency at Consult</Table.Cell>
                        <Table.Cell tw="hidden sm:table-cell">Frequency after Evaluation</Table.Cell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {evaluationQualityReviewsPaginatedEntries.items?.map((evaluationQualityReview, index) => (
                        <Table.Row key={index}>
                          <Table.Cell tw="text-sm md:text-base">
                            {evaluationStatusSelected.value === 'in_review' ? (
                              <>
                                {dayjs(evaluationQualityReview.inReviewAt).format('MM/DD/YYYY')}
                                <span tw="ml-0 md:ml-2 hidden lg:inline">-</span>
                                <span tw="ml-0 md:ml-2 hidden lg:inline">
                                  {dayjs(evaluationQualityReview.inReviewAt).format('h:mma')}
                                </span>
                              </>
                            ) : (
                              <>
                                {dayjs(evaluationQualityReview.reviewedAt).format('MM/DD/YYYY')}
                                <span tw="ml-0 md:ml-2 hidden lg:inline">-</span>
                                <span tw="ml-0 md:ml-2 hidden lg:inline">
                                  {dayjs(evaluationQualityReview.reviewedAt).format('h:mma')}
                                </span>
                              </>
                            )}
                          </Table.Cell>
                          <Table.Cell tw="text-indigo-700 font-semibold text-sm md:text-base">
                            <Link target="_blank" to={{ pathname: `/clients/${evaluationQualityReview.clientID}` }}>
                              {evaluationQualityReview.clientFirstName} {evaluationQualityReview.clientLastName} &nbsp;
                            </Link>
                          </Table.Cell>
                          <Table.Cell tw="text-sm md:text-base">
                            {evaluationQualityReview.evaluationDate ? (
                              <>
                                {dayjs(evaluationQualityReview.evaluationDate).format('MM/DD/YYYY')}
                                <span tw="ml-0 md:ml-2 hidden lg:inline">-</span>
                                <span tw="ml-0 md:ml-2 hidden lg:inline">
                                  {dayjs(evaluationQualityReview.evaluationDate).format('h:mma')}
                                </span>
                              </>
                            ) : (
                              <span tw="ml-0 md:ml-2">-</span>
                            )}
                          </Table.Cell>
                          <Table.Cell tw="text-sm md:text-base hidden sm:table-cell">
                            {evaluationQualityReview.frequencyAtConsult &&
                            evaluationQualityReview.sessionsCountAtConsult ? (
                              <span>
                                {evaluationQualityReview.sessionsCountAtConsult}&nbsp;
                                {evaluationQualityReview.sessionsCountAtConsult === 1 ? 'session' : 'sessions'}
                                &nbsp;every&nbsp;
                                {evaluationQualityReview.frequencyAtConsult === 'weekly'
                                  ? 'week'
                                  : evaluationQualityReview.frequencyAtConsult}
                              </span>
                            ) : (
                              <span tw="ml-0 md:ml-2">-</span>
                            )}
                          </Table.Cell>
                          <Table.Cell tw="text-sm md:text-base hidden sm:table-cell">
                            {evaluationQualityReview.frequencyAfterEvaluation &&
                            evaluationQualityReview.sessionsCountAfterEvaluation ? (
                              <span>
                                {evaluationQualityReview.sessionsCountAfterEvaluation}&nbsp;
                                {evaluationQualityReview.sessionsCountAfterEvaluation === 1 ? 'session' : 'sessions'}
                                &nbsp;every&nbsp;
                                {evaluationQualityReview.frequencyAfterEvaluation === 'weekly'
                                  ? 'week'
                                  : evaluationQualityReview.frequencyAfterEvaluation}
                              </span>
                            ) : (
                              <span tw="ml-0 md:ml-2">-</span>
                            )}
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                  <div tw="font-medium flex mt-5 justify-center sm:justify-end">
                    <span tw="mr-2">{`Showing ${evaluationQualityReviewsPaginatedEntries.pageStartIdx + 1} to ${
                      evaluationQualityReviewsPaginatedEntries.pageEndIdx
                    } of ${evaluationQualityReviewsPaginatedEntries.totalItems}   ${
                      evaluationQualityReviewsPaginatedEntries.totalItems === 1 ? 'result' : 'results'
                    }`}</span>
                    {evaluationQualityReviewsPaginatedEntries.page !== 0 && (
                      <span data-testid="evaluation-quality-reviews-pagination-left-arrow">
                        <FontAwesomeIcon
                          tw="text-indigo-700 ml-2 mr-2 cursor-pointer flex mt-2 text-xs"
                          icon={faChevronLeft}
                          onClick={evaluationQualityReviewsPaginatedEntries.prev}
                        />
                      </span>
                    )}
                    {evaluationQualityReviewsPaginatedEntries.page !==
                      evaluationQualityReviewsPaginatedEntries.totalPages - 1 && (
                      <span data-testid="evaluation-quality-reviews-pagination-right-arrow">
                        <FontAwesomeIcon
                          tw="text-indigo-700 ml-2 mr-2 cursor-pointer flex mt-2 text-xs"
                          icon={faChevronRight}
                          onClick={evaluationQualityReviewsPaginatedEntries.next}
                        />
                      </span>
                    )}
                  </div>
                </>
              ) : (
                <span tw="ml-2">There are no evaluation quality reviews to show.</span>
              )}
            </>
          )}
        </Card>
      </div>

      <div tw="md:pr-5 mt-8" data-testid="discharge-quality-reviews">
        <div tw="flex justify-between items-center flex-wrap">
          <h2 tw="text-2xl font-semibold mb-3">Discharges</h2>
        </div>
        <Card tw="mt-2">
          {isLoadingDischargeQualityReviews ? (
            <div data-testid="discharges-quality-reviews-loader" tw="flex-1 text-center py-20">
              <Loader type="ring" />
            </div>
          ) : (
            <>
              <div tw="mb-4 sm:mb-0">
                <div tw="flex flex-col sm:flex-row mb-4">
                  <>
                    <span tw="text-sm font-semibold mt-6 ml-2 hidden sm:block">View</span>
                    <div tw="flex items-center sm:items-stretch justify-items-stretch mr-2 ml-2 mt-4 font-normal">
                      <span tw="text-sm font-semibold mr-2 block sm:hidden">View</span>
                      <Select
                        tw="w-full sm:w-96"
                        id="dischargeQualityReviewsOptionSelect"
                        placeholder=""
                        spacing="tight"
                        value={dischargeStatusSelected}
                        options={dischargeAdminTableOptions}
                        onChange={(selectOption: SelectOption) => {
                          setDischargeStatusSelected(selectOption);
                        }}
                      />
                    </div>
                  </>
                </div>
              </div>

              {dischargeQualityReviewsPaginatedEntries.items?.length ? (
                <>
                  <Table>
                    <Table.Header>
                      <Table.Row>
                        <Table.Cell>Status Date</Table.Cell>
                        <Table.Cell>Client Name</Table.Cell>
                        <Table.Cell>Discharge Date</Table.Cell>
                        <Table.Cell tw="hidden sm:table-cell">Frequency before Discharge</Table.Cell>
                        <Table.Cell tw="hidden sm:table-cell">Discharge Reason</Table.Cell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {dischargeQualityReviewsPaginatedEntries.items?.map((dischargeQualityReview, index) => (
                        <Table.Row key={index}>
                          <Table.Cell tw="text-sm md:text-base">
                            {dischargeStatusSelected.value === 'in_review' ? (
                              <>
                                {dayjs(dischargeQualityReview.inReviewAt).format('MM/DD/YYYY')}
                                <span tw="ml-0 md:ml-2 hidden lg:inline">-</span>
                                <span tw="ml-0 md:ml-2 hidden lg:inline">
                                  {dayjs(dischargeQualityReview.inReviewAt).format('h:mma')}
                                </span>
                              </>
                            ) : (
                              <>
                                {dayjs(dischargeQualityReview.reviewedAt).format('MM/DD/YYYY')}
                                <span tw="ml-0 md:ml-2 hidden lg:inline">-</span>
                                <span tw="ml-0 md:ml-2 hidden lg:inline">
                                  {dayjs(dischargeQualityReview.reviewedAt).format('h:mma')}
                                </span>
                              </>
                            )}
                          </Table.Cell>
                          <Table.Cell tw="text-indigo-700 font-semibold text-sm md:text-base">
                            <Link target="_blank" to={{ pathname: `/clients/${dischargeQualityReview.clientID}` }}>
                              {dischargeQualityReview.clientFirstName} {dischargeQualityReview.clientLastName} &nbsp;
                            </Link>
                          </Table.Cell>
                          <Table.Cell tw="text-sm md:text-base">
                            {dischargeQualityReview.dischargeDate ? (
                              <>
                                {dayjs(dischargeQualityReview.dischargeDate).format('MM/DD/YYYY')}
                                <span tw="ml-0 md:ml-2 hidden lg:inline">-</span>
                                <span tw="ml-0 md:ml-2 hidden lg:inline">
                                  {dayjs(dischargeQualityReview.dischargeDate).format('h:mma')}
                                </span>
                              </>
                            ) : (
                              <span tw="ml-0 md:ml-2">-</span>
                            )}
                          </Table.Cell>
                          <Table.Cell tw="text-sm md:text-base hidden sm:table-cell">
                            {dischargeQualityReview.frequency && dischargeQualityReview.sessionsCount ? (
                              <span>
                                {dischargeQualityReview.sessionsCount}&nbsp;
                                {dischargeQualityReview.sessionsCount === 1 ? 'session' : 'sessions'}
                                &nbsp;every&nbsp;
                                {dischargeQualityReview.frequency === 'weekly'
                                  ? 'week'
                                  : dischargeQualityReview.frequency}
                              </span>
                            ) : (
                              <span tw="ml-0 md:ml-2">-</span>
                            )}
                          </Table.Cell>
                          <Table.Cell tw="text-sm md:text-base hidden sm:table-cell">
                            {dischargeQualityReview.dischargeReason ? (
                              <span>{dischargeQualityReview.dischargeReason}</span>
                            ) : (
                              <span tw="ml-0 md:ml-2">-</span>
                            )}
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                  <div tw="font-medium flex mt-5 justify-center sm:justify-end">
                    <span tw="mr-2">{`Showing ${dischargeQualityReviewsPaginatedEntries.pageStartIdx + 1} to ${
                      dischargeQualityReviewsPaginatedEntries.pageEndIdx
                    } of ${dischargeQualityReviewsPaginatedEntries.totalItems}   ${
                      dischargeQualityReviewsPaginatedEntries.totalItems === 1 ? 'result' : 'results'
                    }`}</span>
                    {dischargeQualityReviewsPaginatedEntries.page !== 0 && (
                      <span data-testid="discharge-quality-reviews-pagination-left-arrow">
                        <FontAwesomeIcon
                          tw="text-indigo-700 ml-2 mr-2 cursor-pointer flex mt-2 text-xs"
                          icon={faChevronLeft}
                          onClick={dischargeQualityReviewsPaginatedEntries.prev}
                        />
                      </span>
                    )}
                    {dischargeQualityReviewsPaginatedEntries.page !==
                      dischargeQualityReviewsPaginatedEntries.totalPages - 1 && (
                      <span data-testid="discharge-quality-reviews-pagination-right-arrow">
                        <FontAwesomeIcon
                          tw="text-indigo-700 ml-2 mr-2 cursor-pointer flex mt-2 text-xs"
                          icon={faChevronRight}
                          onClick={dischargeQualityReviewsPaginatedEntries.next}
                        />
                      </span>
                    )}
                  </div>
                </>
              ) : (
                <span tw="ml-2">There are no discharge quality reviews to show.</span>
              )}
            </>
          )}
        </Card>
      </div>
    </div>
  );
};

export default QualityReviews;
