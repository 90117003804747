import { useQuery, useMutation, useQueryClient } from 'react-query';
import { http, dynamicHttp } from '@expressable/utils';
import { CancelationReasonPayload } from 'components/client-attendance-modals/log-cancelation-modal';
import { ClientAttendedPayload } from 'components/client-attendance-modals/log-attendance-modal';
import { invalidateAttendanceQueries } from './shared/attendance-utils';
import { PendingAppointment } from 'types';

const httpHealthRecordProxy = dynamicHttp('health-record-proxy');

const getPendingAppointments = async (clientId: string): Promise<PendingAppointment[]> => {
  const { data } = await httpHealthRecordProxy.get(`/client-attendances/${clientId}`);
  return data;
};

export default function usePendingAppointments(clientId: string) {
  return useQuery(['pending-appointments', clientId], () => getPendingAppointments(clientId));
}

export interface DismissPendingAppointmentPayload {
  clientId: string;
  acuityId: string;
}

const dismissPendingAppointment = async (payload: DismissPendingAppointmentPayload) => {
  const { clientId, acuityId } = payload;
  return httpHealthRecordProxy.delete(`/client-attendances/${clientId}/${acuityId}`).then(res => res.data);
};

export function useDismissPendingAppointment() {
  const queryClient = useQueryClient();
  return useMutation(dismissPendingAppointment, {
    onSuccess: (_, payload) => {
      queryClient.invalidateQueries(['pending-appointments', payload.clientId]);
    },
    onError: (err, payload) => {
      const queryKey = ['pending-appointments', payload.clientId].join('');

      console.error(`there was an error in the following query ${queryKey} ${err}`);
    },
  });
}

export interface ReschedulePendingAppointmentPayload {
  clientId: string;
  acuityId: string;
}

const reschedulePendingAppointment = async (payload: ReschedulePendingAppointmentPayload) => {
  const { clientId, acuityId } = payload;
  return http
    .put(`/clients/${clientId}/activity`, {
      eventType: 'appointment-rescheduled',
      version: '1.0',
      detail: {
        acuityAppointmentID: acuityId.toString(),
      },
    })
    .then(res => res.data);
};

export function useReschedulePendingAppointment() {
  const queryClient = useQueryClient();
  return useMutation(reschedulePendingAppointment, {
    onSuccess: (_, payload) => {
      const { clientId } = payload;
      queryClient.invalidateQueries(['pending-appointments', clientId]);
    },
    onError: (err, payload) => {
      const queryKey = ['pending-appointments', payload.clientId].join('');

      console.error(`there was an error in the following query ${queryKey} ${err}`);
    },
  });
}

export interface CancelPendingAppointmentPayload {
  clientId: string;
  acuityId: string;
}

const cancelPendingAppointment = async (payload: {
  data: CancelationReasonPayload;
  clientId: string;
  acuityId: string;
}) => {
  return httpHealthRecordProxy
    .put(`/client-attendances/${payload.clientId}/${payload.acuityId}/status`, {
      ...payload.data,
      cancelDetail: {
        ...payload.data.cancelDetail,
        wasLateCancel: payload.data.cancelDetail.wasLateCancel === 'yes',
        rescheduleAttempted: Boolean(parseInt(payload.data.cancelDetail.rescheduleAttempted)),
      },
    })
    .then(res => res.data);
};

export function useCancelPendingAppointment() {
  const queryClient = useQueryClient();
  return useMutation(cancelPendingAppointment, {
    onSuccess: async (_, payload) => {
      setTimeout(() => {
        invalidateAttendanceQueries(queryClient);
      }, 1000);
    },
    onError: () => {
      invalidateAttendanceQueries(queryClient);
    },
  });
}

export interface ClientAttendedAppointmentPayload {
  clientId: string;
  acuityId: string;
}

const clientAttended = async (payload: { data: ClientAttendedPayload; clientId: string; acuityId: string }) => {
  return httpHealthRecordProxy
    .put(`/client-attendances/${payload.clientId}/${payload.acuityId}/status`, payload.data)
    .then(res => res.data);
};

export function useClientAttended() {
  const queryClient = useQueryClient();
  return useMutation(clientAttended, {
    onSuccess: (_, payload) => {
      setTimeout(() => {
        invalidateAttendanceQueries(queryClient);
      }, 1000);
    },
    onError: () => {
      invalidateAttendanceQueries(queryClient);
    },
  });
}

export interface NoShowPendingAppointmentPayload {
  clientId: string;
  acuityId: number;
}

const noShowPendingAppointment = async (payload: NoShowPendingAppointmentPayload) => {
  const { clientId, acuityId } = payload;
  return httpHealthRecordProxy
    .put(`/client-attendances/${clientId}/${acuityId}/status`, {
      status: 'no-showed',
      noShowDetail: {
        chargeNoShowFee: false,
      },
    })
    .then(res => res.data);
};
/** #region test */
export function useNoShowPendingAppointment() {
  const queryClient = useQueryClient();
  return useMutation(noShowPendingAppointment, {
    onSuccess: async (_, payload) => {
      setTimeout(() => {
        invalidateAttendanceQueries(queryClient);
      }, 1000);
    },
    onError: () => {
      invalidateAttendanceQueries(queryClient);
    },
  });
}
/** #endregion */
