import { RadioGroup } from "components/Forms/RadioGroup";
import { AppointmentType, sessionTypeOptions } from "domain/appointment/constants";
import SubStep from "pages/new-client-create/sub-step";
import { FC } from "react";

export const SessionTypeStep: FC<{ appointmentType?: AppointmentType }> = ({ appointmentType }) => (
  appointmentType === AppointmentType.Therapy ? (
    <SubStep title="2. Select session type" expanded>
      <RadioGroup
        containerClass="space-y-2"
        inputClass="w-full inline-flex space-x-6"
        name="sessionType"
        optional={false}
        options={sessionTypeOptions}
      />
    </SubStep>
  ) : null
);