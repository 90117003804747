import 'twin.macro';

import {
  MFA_COUNTRIES_AVAILABILITY,
  MFA_INPUT_LENGTH,
  MFA_PHONE_PATTERNS,
  MFA_PHONE_VALIDATION_REGEX,
  MfaEvent,
} from 'domain/therapist/constants';
import { MfaInputNullableRef } from 'domain/therapist/therapistTypes';
import { useGetMfaPublicKey, useMfaConfirmSmsCode, useMfaSendCode } from 'hooks/use-mfa';
import React, { useEffect, useRef, useState } from 'react';
import InputMask from 'react-input-mask';
import { SelectOption } from 'types';
import { encodeCognitoPassword } from 'utils/mfa';

import { Button, Input, Label, ModalBody, ModalFooter, Select } from '@expressable/ui-library';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface MfaEnableSmsAuthModalProps {
  isOpen: boolean;
  onClose: () => void;
  therapistEmail: string;
}

const MfaEnableSmsAuthModal: React.FC<MfaEnableSmsAuthModalProps> = ({
  isOpen,
  onClose,
  therapistEmail,
}: MfaEnableSmsAuthModalProps) => {
  const initState = {
    passwordDetails: {
      value: '',
      isVisible: false,
      isValid: false,
    },
    phoneDetails: {
      number: '',
      isValid: false,
      mask: MFA_PHONE_PATTERNS['+1'],
      countryCode: MFA_COUNTRIES_AVAILABILITY[0],
    },
    smsAuthDetails: {
      confirmationCode: '',
      session: '',
      isConfirmationScreenActive: false,
    },
  };

  const [state, setState] = useState(initState);

  const { publicKey, isLoading: isContentLoading } = useGetMfaPublicKey();

  const { mutateAsync: mutateMfaSendCode, isLoading: isSendCodeLoading } = useMfaSendCode();
  const { mutateAsync: mutateMfaConfirmCode, isLoading: isConfirmCodeLoading } = useMfaConfirmSmsCode();

  const inputRefs: MfaInputNullableRef[] = Array.from({ length: MFA_INPUT_LENGTH }).map(() =>
    useRef<HTMLInputElement>(null),
  );

  const isSubmitDisabled =
    isContentLoading || isSendCodeLoading || !state.phoneDetails.isValid || !state.passwordDetails.isValid;

  useEffect(() => {
    if (isOpen) {
      const initInput = document.getElementById('mfaEnableSmsAuthModalAccountPassword');
      if (initInput) {
        initInput.focus();
      }
    } else if (isContentLoading || isSendCodeLoading) {
      setState(prevState => ({
        ...prevState,
        passwordDetails: { ...prevState.passwordDetails, value: '' },
        phoneDetails: {
          ...prevState.phoneDetails,
          number: '',
          countryCode: MFA_COUNTRIES_AVAILABILITY[0],
        },
        smsAuthDetails: {
          ...prevState.smsAuthDetails,
          confirmationCode: '',
        },
      }));
    }
  }, [isOpen, isContentLoading, isSendCodeLoading]);

  const togglePasswordVisibility = () => {
    setState(prevState => ({
      ...prevState,
      passwordDetails: {
        ...prevState.passwordDetails,
        isVisible: !prevState.passwordDetails.isVisible,
      },
    }));
  };

  const onGoBack = () => {
    setState(prevState => ({
      ...prevState,
      smsAuthDetails: {
        ...prevState.smsAuthDetails,
        isConfirmationScreenActive: !prevState.smsAuthDetails.isConfirmationScreenActive,
      },
    }));
  };

  const onPasswordChange = (e: any) => {
    const password = e.target.value;
    setState(prevState => ({
      ...prevState,
      passwordDetails: {
        ...prevState.passwordDetails,
        value: password,
        isValid: password.length >= 8,
      },
    }));
  };

  const onCountryCodeChange = (e: any) => {
    const newCountryCode = e.value.replace(/_/g, '');
    setState(prevState => {
      const newPhoneNumber = prevState.phoneDetails.number;
      const isValidPhoneNumber = onPhoneNumberValidation(newPhoneNumber, newCountryCode);

      return {
        ...prevState,
        phoneDetails: {
          ...prevState.phoneDetails,
          countryCode: {
            label: e.label,
            value: newCountryCode,
          },
          isValid: isValidPhoneNumber,
        },
      };
    });

    onPhoneNumberMaskUpdate(newCountryCode);

    const nextFocus = document.getElementById('mfaEnableSmsAuthModalPhoneNumber');
    if (nextFocus) {
      setState(prevState => ({
        ...prevState,
        phoneDetails: {
          ...prevState.phoneDetails,
          number: '',
        },
      }));
      nextFocus.focus();
    }
  };

  const onPhoneNumberMaskUpdate = (countryCode: string) => {
    setState(prevState => ({
      ...prevState,
      phoneDetails: {
        ...prevState.phoneDetails,
        mask: MFA_PHONE_PATTERNS[countryCode] || '',
      },
    }));
  };

  const extractPhoneDigits = (formattedPhoneNumber: string) => {
    return formattedPhoneNumber.replace(/\D/g, '');
  };

  const onPhoneNumberValidation = (phoneNumber: string, countryCode: SelectOption) => {
    const sanitizedPhoneNumber = phoneNumber.replace(/\D/g, '');

    return MFA_PHONE_VALIDATION_REGEX[countryCode.value]?.test(sanitizedPhoneNumber) || false;
  };

  const onPhoneNumberChange = (e: any) => {
    const phoneNumber = e.target.value;
    setState(prevState => ({
      ...prevState,
      phoneDetails: {
        ...prevState.phoneDetails,
        number: phoneNumber,
        isValid: onPhoneNumberValidation(phoneNumber, prevState.phoneDetails.countryCode),
      },
    }));
  };

  const onDigit = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const inputDigit = e.target.value;

    if (!/^\d*$/.test(inputDigit)) {
      return;
    }

    setState(prevState => {
      const updatedCode = prevState.smsAuthDetails.confirmationCode.split('');
      updatedCode[index] = inputDigit;
      return {
        ...prevState,
        smsAuthDetails: {
          ...prevState.smsAuthDetails,
          confirmationCode: updatedCode.join('').slice(0, MFA_INPUT_LENGTH),
        },
      };
    });

    if (inputDigit && index < MFA_INPUT_LENGTH - 1 && inputRefs[index + 1]?.current) {
      inputRefs[index + 1]?.current?.focus();
    }
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Enter') {
      const { confirmationCode, isConfirmationScreenActive } = state.smsAuthDetails;
      if (confirmationCode.length === MFA_INPUT_LENGTH && isConfirmationScreenActive) {
        onSubmit(MfaEvent.CONFIRM_SMS_CODE);
      }
    }

    if ((e.key === 'Backspace' || e.key === 'Delete') && index >= 0) {
      setState(prevState => {
        const updatedCode = prevState.smsAuthDetails.confirmationCode.split('');
        if (updatedCode[index]) {
          updatedCode[index] = '';
        } else if (index > 0 && inputRefs[index - 1]?.current) {
          inputRefs[index - 1]?.current?.focus();
        }
        return {
          ...prevState,
          smsAuthDetails: {
            ...prevState.smsAuthDetails,
            confirmationCode: updatedCode.join(''),
          },
        };
      });
    }
  };

  const onPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedData = e.clipboardData.getData('text');

    if (/^\d{6}$/.test(pastedData)) {
      setState(prevState => ({
        ...prevState,
        smsAuthDetails: {
          ...prevState.smsAuthDetails,
          confirmationCode: pastedData,
        },
      }));
      inputRefs[5]?.current?.focus();
    } else {
      setState(prevState => ({
        ...prevState,
        smsAuthDetails: {
          ...prevState.smsAuthDetails,
          confirmationCode: '',
        },
      }));
    }
  };

  const sendSmsCode = async () => {
    try {
      const target = extractPhoneDigits(state.phoneDetails.number);
      const signature = await encodeCognitoPassword(state.passwordDetails.value, publicKey);

      const res = await mutateMfaSendCode({
        therapistEmail,
        countryCode: state.phoneDetails.countryCode,
        phoneNumber: target,
        signature,
      });

      setState(prevState => ({
        ...prevState,
        smsAuthDetails: {
          ...prevState.smsAuthDetails,
          session: res.session,
          isConfirmationScreenActive: true,
        },
      }));
      inputRefs[0]?.current?.focus();
    } catch (error) {
      console.error('MFA Error:', error);
    }
  };

  const confirmSmsCode = async () => {
    try {
      const { confirmationCode, session } = state.smsAuthDetails;
      await mutateMfaConfirmCode({ therapistEmail, confirmationCode, session });
      onClose();
    } catch (error) {
      setState(prevState => ({
        ...prevState,
        smsAuthDetails: {
          ...prevState.smsAuthDetails,
          confirmationCode: '',
        },
      }));
      inputRefs[0]?.current?.focus();
      console.error('MFA Error:', error);
    }
  };

  const onSubmit = async (event: MfaEvent.SEND_SMS_CODE | MfaEvent.CONFIRM_SMS_CODE) => {
    if (event === MfaEvent.SEND_SMS_CODE) {
      await sendSmsCode();
    } else if (event === MfaEvent.CONFIRM_SMS_CODE) {
      await confirmSmsCode();
    }
  };

  return (
    <>
      {state.smsAuthDetails.isConfirmationScreenActive ? (
        <>
          <ModalBody>
            <div tw="mt-8 space-y-4">
              <Label tw="flex items-center justify-center">
                Enter the code sent to your phone {state.phoneDetails.countryCode.value} {state.phoneDetails.number}
              </Label>
              <div tw="text-center mb-8">
                <div tw="flex items-center justify-center">
                  {Array.from({ length: MFA_INPUT_LENGTH }).map((_, index) => (
                    <input
                      key={index}
                      type="text"
                      maxLength={1}
                      inputMode="numeric"
                      pattern="[0-9]*"
                      value={state.smsAuthDetails.confirmationCode[index] || ''}
                      onChange={e => onDigit(e, index)}
                      onKeyDown={e => onKeyDown(e, index)}
                      onPaste={onPaste}
                      ref={inputRefs[index]}
                      tw="text-3xl w-12 h-12 mx-2 text-center border-[3px] border-gray-300 focus:border-indigo-700 focus:outline-none border-solid rounded"
                      className={`${isConfirmCodeLoading ? 'cursor-wait' : 'cursor-pointer'} ${
                        state.smsAuthDetails.confirmationCode[index] ? 'bg-gray-50' : ''
                      }`}
                      disabled={isConfirmCodeLoading}
                    />
                  ))}
                </div>
              </div>
            </div>
            <div tw="flex items-center justify-center text-xs leading-none mt-4 p-4">
              It may take a minute to arrive.
            </div>
          </ModalBody>
          <ModalFooter>
            <div tw="mt-5 sm:mt-6">
              <span tw="flex flex-col space-y-2 w-full rounded-md shadow-sm">
                <Button
                  data-testid="mfa-modal-enable"
                  onClick={() => onSubmit(MfaEvent.CONFIRM_SMS_CODE)}
                  variant="primary"
                  tw="inline-flex justify-center items-end text-base border-[1px] w-full font-medium leading-6 sm:text-sm sm:leading-5"
                  className={`${
                    isConfirmCodeLoading || state.smsAuthDetails.confirmationCode.length < MFA_INPUT_LENGTH
                      ? 'cursor-not-allowed'
                      : 'cursor-pointer'
                  }`}
                  disabled={isConfirmCodeLoading || state.smsAuthDetails.confirmationCode.length < MFA_INPUT_LENGTH}
                >
                  {isConfirmCodeLoading ? (
                    <FontAwesomeIcon tw="text-lg text-gray-500" icon={faCircleNotch} spin />
                  ) : (
                    'Enable'
                  )}
                </Button>
                <Button
                  data-testid="mfa-modal-enable"
                  onClick={onGoBack}
                  variant="secondary"
                  tw="inline-flex justify-center items-end text-base border-[1px] w-full font-medium leading-6 sm:text-sm sm:leading-5"
                  tabIndex={3}
                  className={`${isConfirmCodeLoading ? 'cursor-wait' : 'cursor-pointer'}`}
                  disabled={isConfirmCodeLoading}
                >
                  Go Back
                </Button>
              </span>
            </div>
          </ModalFooter>
        </>
      ) : (
        <>
          <ModalBody>
            <div tw="mt-8">
              <div tw="space-y-1">
                <Label>Your Cue Password</Label>
                <div tw="relative">
                  <Input
                    type={state.passwordDetails.isVisible ? 'text' : 'password'}
                    placeholder="•••••••"
                    id="mfaEnableSmsAuthModalAccountPassword"
                    data-testid="mfa-enable-sms-auth-modal-account-password"
                    tw="px-3 py-2 w-full leading-tight text-gray-700 border border-gray-300 rounded appearance-none focus:outline-none focus:border-purple-500"
                    value={state.passwordDetails.value}
                    onChange={e => onPasswordChange(e)}
                    tabIndex={1}
                    className={`${isSendCodeLoading ? 'cursor-wait' : 'cursor-auto'}`}
                    disabled={isSendCodeLoading}
                  />
                  <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    tw="absolute inset-y-0 right-0 flex items-center px-3 text-gray-500 focus:outline-none"
                  >
                    {state.passwordDetails.value !== '' && (state.passwordDetails.isVisible ? 'Hide' : 'Show')}
                  </button>
                </div>
              </div>
              <div tw="space-y-1">
                <Label>Country or region code</Label>
                <Select
                  aria-label="mfa-enable-sms-auth-modal-country-code"
                  name="mfa-enable-sms-auth-modal-country-code"
                  spacing="small"
                  value={state.phoneDetails.countryCode}
                  options={MFA_COUNTRIES_AVAILABILITY}
                  onChange={(e: SelectOption) => onCountryCodeChange(e)}
                  tabIndex={2}
                  className={`${isSendCodeLoading ? 'cursor-wait' : 'cursor-auto'}`}
                  disabled={isSendCodeLoading}
                />
              </div>
              <div tw="mt-5 space-y-1">
                <Label>Your phone number</Label>
                <InputMask
                  mask={state.phoneDetails.mask}
                  maskChar="_"
                  disabled={isSendCodeLoading}
                  value={state.phoneDetails.number}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => onPhoneNumberChange(e)}
                >
                  {(inputProps: any) => (
                    <input
                      {...inputProps}
                      type="tel"
                      placeholder="(512) 768-0319"
                      id="mfaEnableSmsAuthModalPhoneNumber"
                      data-testid="mfa-enable-sms-auth-modal-phone-number"
                      tw="px-3 py-2 w-full leading-tight text-gray-700 border border-gray-300 rounded appearance-none focus:outline-none focus:border-purple-500"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      tabIndex={3}
                      className={`${isSendCodeLoading ? 'cursor-wait' : 'cursor-auto'}`}
                    />
                  )}
                </InputMask>
              </div>
            </div>
            <div tw="mt-2 text-xs leading-none">Authentication codes will be sent here.</div>
          </ModalBody>
          <ModalFooter>
            <div tw="mt-5 sm:mt-6">
              <span tw="flex w-full rounded-md shadow-sm">
                <Button
                  data-testid="mfa-modal-enable"
                  onClick={() => onSubmit(MfaEvent.SEND_SMS_CODE)}
                  variant="primary"
                  tw="inline-flex justify-center items-end text-base w-full font-medium leading-6 sm:text-sm sm:leading-5"
                  tabIndex={3}
                  className={`${isSubmitDisabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                  disabled={isSubmitDisabled}
                >
                  {isContentLoading || isSendCodeLoading ? (
                    <FontAwesomeIcon tw="text-lg text-gray-500" icon={faCircleNotch} spin />
                  ) : (
                    'Send Authentication Code'
                  )}
                </Button>
              </span>
            </div>
          </ModalFooter>
        </>
      )}
    </>
  );
};

export default MfaEnableSmsAuthModal;
