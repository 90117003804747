import { FormGroup, Label } from '@expressable/ui-library';
import { Fieldset } from '.';

import { formatCurrencyCents } from 'utils/currency';
import { isNull } from 'lodash';
import { EligibilityEstimate, EligibilityEstimateProcedureCode } from 'domain/eligibility/types';
import Table from 'components/Table';
import { AppointmentTypesNames } from 'types';

export const Estimates: React.FC<{ estimates: { payerEstimates: EligibilityEstimate, errorMessages?: string } }> = ({ estimates }) => {
  const { payerEstimates } = estimates;
  return (
    <Fieldset legend="Estimates" isSection>
      {estimates?.errorMessages && (
          <FormGroup>
            <p>{estimates?.errorMessages}</p>
          </FormGroup>
      )}

      {!estimates.errorMessages && (
        <>
          <div className="grid grid-cols-2 gap-4">
            <FormGroup>
              <Label className="text-base">Payer Name</Label>
              <p>{payerEstimates?.payerName}</p>
            </FormGroup>
            <FormGroup>
              <Label className="text-base">Payer ID</Label>
              <p>{payerEstimates?.payerID}</p>
            </FormGroup>
          </div>
          <FormGroup className="mt-4">
            <Label className="text-lg">Procedure Codes</Label>
            <Table>
              <Table.Header>
                <Table.Cell>Appointment Type</Table.Cell>
                <Table.Cell>CPT</Table.Cell>
                <Table.Cell>Price</Table.Cell>
              </Table.Header>
              <Table.Body>
                {payerEstimates?.procedureCodes.map((estimate: EligibilityEstimateProcedureCode, index: number) => (
                  <Table.Row key={index}>
                    <Table.Cell>
                      {AppointmentTypesNames[estimate.appointmentType as keyof typeof AppointmentTypesNames]}
                    </Table.Cell>
                    <Table.Cell>{estimate.cpt}</Table.Cell>
                    <Table.Cell>{!isNull(estimate.price) ? formatCurrencyCents(estimate.price) : '-'}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </FormGroup>
        </>
      )}
    </Fieldset>
  );
};
