import 'twin.macro';

import ModalHeadline from 'components/modal-headline';
import { useMfaDisable } from 'hooks/use-mfa';
import React from 'react';

import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader } from '@expressable/ui-library';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface MfaDisableProps {
  isOpen: boolean;
  onClose: () => void;
  therapistFullName: string;
  therapistEmail: string;
  method: string;
}

const MfaDisableModal: React.FC<MfaDisableProps> = ({
  isOpen,
  onClose,
  therapistFullName,
  therapistEmail,
  method,
}: MfaDisableProps) => {
  const { mutateAsync: mutateMfaDisable, isLoading } = useMfaDisable();

  const onSubmit = async () => {
    try {
      await mutateMfaDisable({ therapistEmail, method });
      onClose();
    } catch (error) {
      console.error('MFA Error:', error);
    }
  };

  return (
    <Modal isOpen={isOpen} tw="max-w-lg">
      <ModalContent>
        <ModalHeader>
          <div tw="absolute top-0 right-0 pt-4 pr-4">
            <button
              data-testid="mfa-modal-close"
              type="button"
              tw="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={onClose}
            >
              <span tw="sr-only">Close</span>
              <svg
                tw="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </ModalHeader>
        <ModalBody>
          <ModalHeadline text="Multi-Factor Authentication" />
          <div tw="text-center w-full">
            Would you like to deactivate multi-factor authentication for{' '}
            <span tw="text-red-500 font-medium">{therapistFullName}</span>?
          </div>
        </ModalBody>
        <ModalFooter>
          <div tw="mt-5 sm:mt-6">
            <span tw="flex w-full rounded-md shadow-sm">
              <Button
                data-testid="mfa-modal-disable"
                onClick={onSubmit}
                variant="primary"
                tw="inline-flex justify-center items-end text-base w-full font-medium leading-6 sm:text-sm sm:leading-5"
                className={isLoading ? 'cursor-not-allowed' : 'cursor-pointer'}
                disabled={isLoading}
              >
                {isLoading ? <FontAwesomeIcon tw="text-lg text-gray-500" icon={faCircleNotch} spin /> : 'Yes, please'}
              </Button>
            </span>
          </div>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default MfaDisableModal;
