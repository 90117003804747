import React, { useEffect } from 'react';
import 'twin.macro';
import { useContentfulEntry } from 'hooks/use-ContentfulData';
import { ReasonsContentfulEntry, SelectOption } from 'types';
import { FormGroup, Select, Textarea } from '@expressable/ui-library';
import FormGroupTitle from 'components/form-group-title';

const mapEntryToSelectOption = (str: string) =>
  ({
    label: str,
    value: str,
  } as SelectOption);

export interface NotSchedulingReasonInfo {
  notSchedulingReason?: string | null;
  notSchedulingDescription?: string | null;
}

export interface NotSchedulingReasonProps {
  value: NotSchedulingReasonInfo;
  onChange: (value: NotSchedulingReasonInfo) => void;

  onValidationStatus?: ({ valid }: { valid: boolean }) => void;
  submitted?: boolean;
}

const NotSchedulingReason = ({ value, onChange, onValidationStatus, submitted }: NotSchedulingReasonProps) => {
  const [notSchedulingReasonsEntry, , fetchSchedulingReasons] =
    useContentfulEntry<ReasonsContentfulEntry>('3M3uhuPBk3RHJjEnOdXtFR');
  const notSchedulingReasons = notSchedulingReasonsEntry?.fields?.dropdownContent.map(mapEntryToSelectOption);

  //@TODO (gian): needs to be revisited. This is a temporary fix to make sure the insurance options are fetched before the component is rendered.
  useEffect(fetchSchedulingReasons, []);

  const notSchedulingReasonValid = !!value.notSchedulingReason;
  const notSchedulingDescriptionValid = !!value.notSchedulingDescription;
  const valid = notSchedulingReasonValid && notSchedulingDescriptionValid;
  useEffect(() => {
    onValidationStatus?.({ valid });
  }, [valid]);

  return (
    <div tw="flex flex-col gap-4">
      <FormGroup>
        <FormGroupTitle
          title="What is the reason for not scheduling a second recurring session?"
          fontSize="small"
          fontWeight="semi"
          spacing="large"
        />
        <Select
          required
          aria-label="reschedule-reason-other"
          name="reschedule-reason-other"
          options={notSchedulingReasons}
          value={notSchedulingReasons?.find(x => x.value === value.notSchedulingReason)}
          onChange={(option: { value: string }) => {
            onChange({ ...value, notSchedulingReason: option.value });
          }}
          error={!notSchedulingReasonValid && submitted ? { message: 'Please fill out this field.' } : undefined}
        />
      </FormGroup>
      <FormGroup>
        <FormGroupTitle title="Please describe the reason" fontSize="small" fontWeight="semi" spacing="large" />
        <Textarea
          name="notSchedulingDescription"
          tw="w-full"
          value={value.notSchedulingDescription ?? ''}
          onChange={event => {
            onChange({ ...value, notSchedulingDescription: event.target.value });
          }}
          error={!notSchedulingDescriptionValid && submitted ? { message: 'Please fill out this field.' } : undefined}
        />
      </FormGroup>
    </div>
  );
};

export default NotSchedulingReason;
