/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import * as Sentry from '@sentry/react';

export const handleChunkLoadError = (errorMessage: any) => {
  if (errorMessage?.name?.includes('ChunkLoadError') || errorMessage?.includes('ChunkLoadError'))
    alertUserForNewVersion();
};

export const alertUserForNewVersion = () => {
  const userHasBeenAlerted = JSON.parse(window.localStorage.getItem('user-has-been-alerted-for-new-build') || 'false');
  if (!userHasBeenAlerted) {
    window.localStorage.setItem('user-has-been-alerted-for-new-build', 'true');
  }
};

interface ChunkErrorBoundaryProps {
  children: React.ReactNode;
}
export class ChunkErrorBoundary extends React.Component<ChunkErrorBoundaryProps, { hasError: boolean }> {
  constructor(props: Readonly<ChunkErrorBoundaryProps>) {
    super(props);
    this.state = { hasError: false };
  }
  componentDidCatch(error: unknown, info: unknown) {
    this.setState({ hasError: true });
    console.error(error, info);
    Sentry.captureException(error as Error);
  }

  render() {
    return this.props.children;
  }
}
