export enum ConflictType {
  APPOINTMENT_EXISTS = "appointmentExists",
  OVER_DIRECT_HOURS_LIMIT = "overDirectHoursLimit",
  OVER_WEEKLY_LIMIT = "overWeeklyLimit",
  OVER_DAILY_LIMIT = "overDailyLimit",
  CONSECUTIVE_APPOINTMENTS_LIMIT = "consecutiveApptsLimit",
  FUTURE_CONFLICT = "futureConflict",
}

export interface IConflict {
  datetime: string;
  type: string;
}
