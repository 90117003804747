import { ClinicalPhoneme } from 'domain/clinical-pathways/contants';
import { AcademyHomeworkContentful, CustomStructuredActivityContentful } from 'domain/clinical-pathways/types';
import { ContentfulSpaces, useContentfulEntries } from 'hooks/use-contentful';
import { isBoolean, isEmpty, isString, isUndefined, omitBy } from 'lodash';
import { useCurrentUserContext } from '@expressable/utils';

export type Filters = Partial<{
  textSearch: string;
  ageRanges: string[];
  complexities: string[];
  timesRequired: string[];
  homeworkTypes: string[];
  caregiverRequired: boolean | string;
  hasMaterials: boolean | string;
  relatedTreatmentAreaId: string | string[];

  // client-side filters
  phoneme: ClinicalPhoneme;
}>;

export const buildContentfulHomeworksQuery = ({
  textSearch,
  phoneme,
  ageRanges,
  complexities,
  timesRequired,
  homeworkTypes,
  caregiverRequired,
  hasMaterials,
  relatedTreatmentAreaId,
}: Filters) => {
  const queries: Record<string, string | boolean | undefined> = {
    ['query']: textSearch,
    ['fields.ageRange[in]']: ageRanges?.join(','),
    ['fields.timeRequired[in]']: timesRequired?.join(','),
    ['fields.complexity[in]']: complexities?.join(','),
    ['fields.hwType[in]']: homeworkTypes?.join(','),
    ['fields.caregiverRequired']: caregiverRequired,
    ['fields.associatedMaterialsIfRequired[exists]']: hasMaterials,
    ['fields.treatmentArea.sys.id[in]']: isString(relatedTreatmentAreaId)
      ? relatedTreatmentAreaId
      : relatedTreatmentAreaId?.join(','),
    ['fields.title[match]']: phoneme?.contentfulFilter,
  };

  return omitBy(queries, value => {
    if (isBoolean(value)) return false;
    return isUndefined(value) || isEmpty(value);
  });
};

const filterByPhoneme = (activities: AcademyHomeworkContentful[], phonemes?: ClinicalPhoneme) => {
  if (!phonemes) {
    return activities;
  }

  return activities.filter(activity => {
    return phonemes.values.some(phoneme => activity.title.includes(phoneme));
  });
};

export const useFilteredStructuredActivities = (filterOptions: Filters, limit?: number) => {
  const config = {
    space: ContentfulSpaces.ClinicalCareExperience,
    contentType: 'expressableAcademyHomeworks',
    order: ['fields.title'],
    params: buildContentfulHomeworksQuery(filterOptions),
    limit,
  };

  // clean null keys from config
  Object.keys(config.params).forEach(key => config.params[key] === null && delete config.params[key]);

  return useContentfulEntries<AcademyHomeworkContentful[]>(config, activities =>
    filterByPhoneme(activities, filterOptions.phoneme),
  );
};

export const useFilteredCustomStructuredActivities = (searchTerm = '', timeRequired: string[]) => {
  const currentUser = useCurrentUserContext();
  const parameters = {
    ['query']: searchTerm ?? '',
    ['fields.ownerTherapistId']: currentUser?.email ?? '',
    ['fields.timeRequired[in]']: timeRequired.join(','),
  };

  const query = useContentfulEntries<CustomStructuredActivityContentful[]>({
    space: ContentfulSpaces.ClinicalCareExperience,
    contentType: 'customStructuredActivity',
    order: ['fields.title'],
    params: omitBy(parameters, value => {
      if (isBoolean(value)) return false;
      return isUndefined(value) || isEmpty(value);
    }),
  });

  return query;
};
