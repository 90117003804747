import React from 'react';
import { Button, Modal, ModalBody, ModalContent, ModalFooter } from '@expressable/ui-library';
import 'twin.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDeleteClientContact, DeleteClientContactPayload } from 'hooks/use-client-contacts';

export interface RemoveContactModalProps {
  isOpen: boolean;
  onClose: () => void;
  clientId: string;
  contactId: string;
  setShowEditForm: React.Dispatch<React.SetStateAction<boolean>>;
}

export function RemoveContactModal(props: RemoveContactModalProps) {
  const { isOpen, onClose, clientId, contactId, setShowEditForm } = props;
  const deleteClientContactMutation = useDeleteClientContact();
  const { mutate: deleteClientContact } = deleteClientContactMutation;

  return (
    <Modal isOpen={isOpen}>
      <ModalContent>
        <form
          onSubmit={e => {
            e.preventDefault();
            const deleteClientContactPayload: DeleteClientContactPayload = {
              contactID: contactId,
              clientID: clientId,
            };
            deleteClientContact(deleteClientContactPayload, {
              onSuccess: () => {
                setShowEditForm(false);
                onClose();
              },
            });
          }}
        >
          <ModalBody>
            <div>
              <div tw="flex items-center justify-center w-12 h-12 mx-auto bg-indigo-100 rounded-full">
                {/* <!-- Heroicon name: check --> */}
                <FontAwesomeIcon tw="text-2xl text-indigo-700" icon="users" />
              </div>
              <div tw="mt-3 sm:mt-5">
                <h3 tw="text-lg text-center font-medium text-gray-900 leading-6" id="modal-headline">
                  Remove Contact
                </h3>
                <div tw="mt-2">
                  <p tw="text-sm text-center text-gray-500 leading-5">
                    Are you sure you want to remove this
                    <br />
                    contact from this client?
                  </p>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div tw="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
              <span tw="flex w-full rounded-md shadow-sm sm:col-start-2">
                <Button
                  type="submit"
                  data-testid="contact-removal-submit"
                  variant="danger"
                  loading={deleteClientContactMutation.isLoading}
                  tw="inline-flex justify-center w-full px-4 py-2 text-base font-medium leading-6 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                >
                  Remove
                </Button>
              </span>
              <span tw="flex w-full mt-3 rounded-md shadow-sm sm:mt-0 sm:col-start-1">
                <Button
                  type="button"
                  data-testid="contact-removal-cancel"
                  variant="secondary"
                  tw="inline-flex justify-center w-full px-4 py-2 text-base font-medium leading-6 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </span>
            </div>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
