import { useMemo, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { Button, Card, Container, FormGroup, Label, Select } from '@expressable/ui-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { PayrollPeriod, PayrollResponseData } from 'domain/payroll/types';
import {
  DefaultSequoiaID,
  DefaultTherapistName,
  PayrollHourTypes,
  PayrollToastMessages
} from 'domain/payroll/constants';
import usePermissions from 'hooks/use-permissions';
import { generateWeekPeriods } from 'utils/generate-week-periods';
import { getPayrollData } from 'utils/get-payroll-data';
import { downloadCSV } from 'utils/download-csv';
import 'twin.macro';

const TWO_WEEK_PERIOD_ORIGIN_DATE = '2023-07-30';

const Payroll = () => {
  const { isAdminOrClinicalManager } = usePermissions();

  if (!isAdminOrClinicalManager) {
    return (
      <Container data-testid="payroll-restricted-access-error" tw="relative pl-0" size="large">
        <Card tw="mb-4 px-8">
          <FormGroup tw="w-full sm:w-1/2 md:w-1/3">
            <Label>
              <FontAwesomeIcon icon={faExclamationTriangle} tw="text-red-500 mr-2" />
                Only users in Admin Groups can access this section.
            </Label>
          </FormGroup>
        </Card>
      </Container>
    );
  }

  const [payrollIndex, setPayrollIndex] = useState<number | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { addToast } = useToasts();

  const twoWeekPeriods = useMemo(() => generateWeekPeriods(TWO_WEEK_PERIOD_ORIGIN_DATE, 2), []);

  const payrollSelectOptions = useMemo(
    () =>
      twoWeekPeriods.map((period, index) => {
        const [startDate, endDate] = period;
        const formattedStartDate = startDate.format('MM/DD/YYYY');
        const formattedEndDate = endDate.format('MM/DD/YYYY');
        const formattedPeriod = `${formattedStartDate} - ${formattedEndDate}`;

        return {
          label: formattedPeriod,
          value: index,
        };
      }),
    [twoWeekPeriods],
  );

  const selectedPayrollPeriod = useMemo(
    () => twoWeekPeriods[payrollIndex!],
    [payrollIndex, twoWeekPeriods],
  );

  return (
    <div data-testid="payroll-select-container" className="group">
      <div tw="flex flex-wrap md:flex-nowrap gap-6">
        <FormGroup tw="w-full md:w-[320px]">
          <Label htmlFor="payroll-select" tw="font-semibold text-sm mb-2" data-testid="payroll-select-label">
            Payroll Period
          </Label>
          <Select
            id="payroll-select"
            spacing="tight"
            isSearchable
            options={payrollSelectOptions}
            onChange={(selectedOption: { value: number; label: string }) => {
              setPayrollIndex(selectedOption ? selectedOption.value : undefined);
            }}
          />
        </FormGroup>
        <FormGroup tw="w-full md:w-auto justify-end">
          <Button
            data-testid="payroll-button"
            variant="primary"
            loading={isLoading}
            onClick={async () => {
              setIsLoading(true);

              const [startDate, endDate] = selectedPayrollPeriod ?? [];

              if (startDate && endDate) {
                try {
                  const payrollPeriod: PayrollPeriod = {
                    startDate: startDate.format('YYYY-MM-DD'),
                    endDate: endDate.format('YYYY-MM-DD'),
                  };
                  // get payroll data from API
                  const payrollData = await getPayrollData(payrollPeriod);

                  // build the csv data
                  const csvFilename = `payroll-${payrollPeriod.startDate}-${payrollPeriod.endDate}.csv`;
                  const csvData = payrollData.reduce((previousValue: string, currentValue: PayrollResponseData, index) => {
                    const {
                      adjustedDurationHours,
                      differentialHours,
                      sequoiaID = DefaultSequoiaID,
                      therapistFirstName,
                      therapistLastName,
                      committedHours = 0,
                    } = currentValue;
                    const directHours = adjustedDurationHours - differentialHours;
                    const therapistName = (therapistFirstName || therapistLastName)
                      ? `${therapistFirstName ?? ''} ${therapistLastName ?? ''}`.trim()
                      : DefaultTherapistName;
                    const therapistState = currentValue.therapistState ?? 'Unknown';

                    const csvRowsArr = [];
                    if(index === 0) {
                      csvRowsArr.push('Name,Sequoia ID,State,Hour Type,Hours,Guaranteed Hours');

                    }
                    if (directHours > 0) {
                      const directHoursStr = String(directHours);
                      const directHoursStrArr = directHoursStr.split('.');
                      const directHoursFormatted = `${directHoursStrArr[0]}.${directHoursStrArr[1]?.padEnd(2, '0') ?? '00'}`;

                      csvRowsArr.push(`${therapistName},${sequoiaID},${therapistState},${PayrollHourTypes.RegOne},${directHoursFormatted},${committedHours}`);

                    }

                    if (differentialHours > 0) {
                      const differentialHoursStr = String(differentialHours);
                      const differentialHoursStrArr = differentialHoursStr.split('.');
                      const differentialHoursFormatted = `${differentialHoursStrArr[0]}.${differentialHoursStrArr[1]?.padEnd(2, '0') ?? '00'}`;


                      csvRowsArr.push(`${therapistName},${sequoiaID},${therapistState},${PayrollHourTypes.RegTwo},${differentialHoursFormatted},${committedHours}`);
                    }

                    const csvRowsStr = csvRowsArr.join('\n');

                    if (previousValue === '') {
                      return csvRowsStr;
                    } else if (csvRowsStr === '') {
                      return previousValue;
                    } else {
                      return `${previousValue}\n${csvRowsStr}`;
                    }
                  }, '');

                  // download the csv file
                  downloadCSV(csvData, csvFilename);

                  // show success message to user
                  addToast(PayrollToastMessages.Success, { appearance: 'success', autoDismiss: true });
                } catch (err) {
                  console.error(err);
                  addToast(PayrollToastMessages.Error, { appearance: 'error', autoDismiss: true });
                }
              } else {
                addToast(PayrollToastMessages.Warning, { appearance: 'warning', autoDismiss: true });
              }

              setIsLoading(false);
            }}>
            Generate CSV
          </Button>
        </FormGroup>
      </div>
    </div>
  );
};

export default Payroll;
