import { get } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { SelectOption } from 'types';
import useClinicalAreaConclusions from './use-clinical-area-conclusions';
import {
  EvaluationEntriesIds,
  useEvaluationContentfulSelectOptions,
  useEvaluationContentfulEntry,
} from './use-evaluation-contentful-provider';

interface Assessment {
  assessmentName: string;
  assessmentDescription: string;
  id: string;
}

interface AssessmentsUsed {
  data: { assessment: Assessment[] };
  isLoading: boolean;
  isError: boolean;
}

export default function useClinicalArea(name: string) {
  const { setValue } = useFormContext();
  const howWasAssessed = useEvaluationContentfulSelectOptions(EvaluationEntriesIds.AssessmentTypes);
  const assessmentsList = useEvaluationContentfulEntry(EvaluationEntriesIds.Assessments) as AssessmentsUsed;
  const clinicalAreaConclusions = useClinicalAreaConclusions();

  const assessments = assessmentsList?.data?.assessment ?? [];
  const assessmentName = assessments.map(assessment => ({
    name: assessment?.assessmentName,
    desc: assessment?.assessmentDescription,
  }));

  const assessmentsUsed = assessments.map(assessment => ({
    label: assessment?.assessmentName,
    value: assessment?.assessmentName,
  }));

  const watchSelectedAssessedQuestion = useWatch({ name: `${name}HowWasAssessed` });
  const watchAssessmentUsed = useWatch({ name: `${name}AssessmentUsed` });

  const watchConclusions = useWatch({ name: `${name}Conclusions` });
  const isStandardOrNonStandard = useMemo(() => {
    return watchSelectedAssessedQuestion?.some(
      (option: SelectOption) =>
        option?.value === 'Standardized Assessment' || option?.value === 'Norm-Referenced Assessment Tool',
    );
  }, [watchSelectedAssessedQuestion?.length]);

  useEffect(() => {
    if (watchSelectedAssessedQuestion?.value === 'Observed and judged to be age appropriate') {
      setValue(`${name}Conclusions`, get(clinicalAreaConclusions, name));
    }

    if (watchSelectedAssessedQuestion?.find((item: SelectOption) => item.value === 'Not Assessed')) {
      if (!watchConclusions)
        setValue(`${name}Conclusions`, 'This clinical area was not assessed during this evaluation.');
    }
  }, [watchSelectedAssessedQuestion, watchConclusions]);

  useEffect(() => {
    const matchingAssessments = assessmentName.filter(assessment =>
      watchAssessmentUsed.some((item: any) => item.value === assessment.name),
    );
    const matchingDescriptions = matchingAssessments.map(assessment => assessment.desc);
    const concatenatedDescriptions = matchingDescriptions.join('\n\n');
    setValue(`${name}AssessmentDescription`, concatenatedDescriptions);
  }, [watchAssessmentUsed]);

  return {
    howWasAssessed,
    assessmentsUsed,
    isStandardOrNonStandard,
  };
}
