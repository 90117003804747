import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { ISingleAppointment } from 'types';
import dayjsAdvancedFormat from 'dayjs/plugin/advancedFormat';
import dayjsUTC from 'dayjs/plugin/utc';

dayjs.extend(timezone);
dayjs.extend(dayjsUTC);
dayjs.extend(dayjsAdvancedFormat);
const DEFAULT_DURATION_TIME_MINUTES = 30;
const DATE_DISPLAY_FORMAT = 'MM/DD/YY ddd [at] hh:mm A z';

/**
 * @desc builds the displayed message for an appointment's schedule time that shows in the sidebar
 * @example Speech Therapy Session - 60 min Wed, 08/21/2024 at 02:30 PM AST
 */
export const buildAppointmentScheduleTimeMessage = (appointment: ISingleAppointment): string => {
  return `
    ${`${dayjs(appointment.appointmentDateTime).format(DATE_DISPLAY_FORMAT)}`}
  ${
    Number(appointment.appointmentDuration || appointment.duration) !== DEFAULT_DURATION_TIME_MINUTES
      ? ` - ${appointment.appointmentDuration ?? appointment.duration} min`
      : ''
  }

  `;
};
