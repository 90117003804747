import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { faCalendarXmark } from '@fortawesome/pro-solid-svg-icons';
import { EventProps } from 'domain/admin/types';
import { removePrefixFromID } from 'utils/helpers';
import 'twin.macro';

const AppointmentNoShow = (event: EventProps) => {
  const firehoseEvent = event?.event;
  const isLastEvent = event?.isLastEvent;

  return (
    <div data-testid="firehose-appointment-no-show" tw="relative flex mt-7 px-2">
      {!isLastEvent && <span tw="absolute -z-1 top-7 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>}
      <div tw="flex items-baseline">
        <div tw="flex items-center bg-red-100 rounded-full h-6 w-6">
          <FontAwesomeIcon icon={faCalendarXmark} tw="mx-1.5 h-3  text-red-700" />
        </div>
        <div tw="pl-4 text-sm">
          <div tw="lg:flex font-semibold pb-1 text-indigo-700">
            <Link to={{ pathname: `/therapists/${firehoseEvent?.therapistEmail}` }}>
              {firehoseEvent?.therapistFirstName} {firehoseEvent?.therapistLastName}&nbsp;
            </Link>
            <span tw="text-gray-500">{`logged a`}</span>&nbsp;
            <span tw="text-red-700 bg-red-100 rounded-full px-1 mr-1"> no-show </span>
            <span tw="text-gray-500">{`for the client`}</span>
            &nbsp;
            <Link
              tw="text-indigo-700"
              to={{ pathname: `/clients/${removePrefixFromID(firehoseEvent?.clientID)}` }}
            >
              {firehoseEvent?.clientFirstName} {firehoseEvent?.clientLastName}&nbsp;
            </Link>
            <span tw="text-gray-500">{`at ${dayjs(firehoseEvent?.createdAt as string).format('h:mm A')}, ${dayjs(
              firehoseEvent?.createdAt as string,
            ).format('MM/DD/YYYY')} `}</span>
          </div>

          <div tw="line-through">{`Appointment: ${dayjs(firehoseEvent?.appointmentOn as string).format(
            'MM/DD/YYYY - h:mm A',
          )}`}</div>
        </div>
      </div>
    </div>
  );
};

export default AppointmentNoShow;
