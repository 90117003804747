import { Dayjs, isDayjs } from 'dayjs';
import { TimezoneOption } from 'hooks/common/useDisplayTimezone/options';
import KSUID from 'ksuid';
import { isUndefined } from 'lodash';
import { SelectOption } from 'types';
import * as yup from 'yup';

export type ClientInformationFormData = {
  // Client information

  clientFirstName: string;
  clientMiddleName?: string;
  clientLastName: string;
  clientNameSuffix?: string;
  clientNickname?: string;

  dateOfBirth?: Dayjs;

  sex?: SelectOption;
  genderIdentity?: string;

  // // Primary contact

  isClientSelf: boolean;

  crmID?: string;
  contactID?: string;

  contactTimezone: TimezoneOption;
  relationshipToClient?: SelectOption;
  contactName?: string;
  contactMiddleName?: string;
  contactLastName?: string;
  phone: string;
  phoneType: SelectOption;
  email: string;
  address1?: string;
  address2?: string;
  city?: string;
  state: SelectOption;
  zipCode?: string;
  intakeType: SelectOption;
};

const REQUIRED_FIELD_FEEDBACK_TEXT = 'This field is required';
const INVALID_NAME_FIELD_FEEDBACK_TEXT = 'Invalid name';
const INVALID_LASTNAME_FIELD_FEEDBACK_TEXT = 'Invalid last name';
const INVALID_PHONENUMBER_FIELD_FEEDBACK_TEXT = 'Invalid phone number';
const INVALID_ZIPCODE_FIELD_FEEDBACK_TEXT = 'Invalid ZIP code';

// @TODO(juan): extract this
export const selectOptionSchema = yup
  .object()
  .shape({
    value: yup.string().required(),
    label: yup.string().required(),
  })
  .default(undefined)
  .optional();

const timezoneOptionSchema = yup
  .object()
  .shape({
    value: yup.string().required(),
    label: yup.string().required(),
    local: yup.string().required(),
  })
  .default(undefined)
  .optional();

export const phoneNumberPattern = /^(\(\d{3}\)|\d{3})\s?(\d{3})\s?-?(\d{4})$/;
export const onlyLettersAndEmptySpacePattern = /^[a-zA-Z\s']*$/;
export const zipCodePattern = /^\d*$/;

export const onlyLettersSpecialVowelsWhiteSpacesPattern = /^(?!$)[\p{L}\s'.-]*$|^$/u;

const KSUIDSchema = yup
  .string()
  .ensure()
  .test('is-valid-ksuid', 'Please enter in a valid ID', v => {
    if (!v) {
      return true;
    } else {
      try {
        KSUID.parse(v);
        return true;
      } catch (err) {
        return false;
      }
    }
  });

const optionalContactField = yup
  .string()
  .matches(onlyLettersSpecialVowelsWhiteSpacesPattern, INVALID_NAME_FIELD_FEEDBACK_TEXT)
  .required(REQUIRED_FIELD_FEEDBACK_TEXT)
  .when('isClientSelf', {
    is: true,
    then: schema => schema.notRequired(),
  })
  .ensure();

const emptyStringToUndefined = (value: string) => (value === '' ? undefined : value);
export const clientInformationSchema: yup.ObjectSchema<ClientInformationFormData> = yup
  .object({
    clientFirstName: yup
      .string()
      .trim()
      .matches(onlyLettersSpecialVowelsWhiteSpacesPattern, INVALID_NAME_FIELD_FEEDBACK_TEXT)
      .required(REQUIRED_FIELD_FEEDBACK_TEXT),
    clientMiddleName: yup
      .string()
      .trim()
      .matches(onlyLettersSpecialVowelsWhiteSpacesPattern, INVALID_NAME_FIELD_FEEDBACK_TEXT),
    clientLastName: yup
      .string()
      .trim()
      .matches(onlyLettersSpecialVowelsWhiteSpacesPattern, INVALID_LASTNAME_FIELD_FEEDBACK_TEXT)
      .required(REQUIRED_FIELD_FEEDBACK_TEXT),
    clientNameSuffix: yup.string().transform(emptyStringToUndefined),
    clientNickname: yup.string().transform(emptyStringToUndefined),

    dateOfBirth: yup
      .mixed<Dayjs>()
      .test(v => isDayjs(v) || isUndefined(v))
      .required(REQUIRED_FIELD_FEEDBACK_TEXT),
    sex: selectOptionSchema.required(REQUIRED_FIELD_FEEDBACK_TEXT),
    genderIdentity: yup.string().transform(emptyStringToUndefined),

    isClientSelf: yup.boolean().default(false).required(REQUIRED_FIELD_FEEDBACK_TEXT),

    crmID: KSUIDSchema,
    contactTimezone: timezoneOptionSchema.required(REQUIRED_FIELD_FEEDBACK_TEXT),
    relationshipToClient: selectOptionSchema.required(REQUIRED_FIELD_FEEDBACK_TEXT).when('isClientSelf', {
      is: true,
      then: schema => schema.notRequired(),
    }),
    contactName: optionalContactField,
    contactMiddleName: yup
      .string()
      .matches(onlyLettersSpecialVowelsWhiteSpacesPattern, INVALID_NAME_FIELD_FEEDBACK_TEXT),
    contactLastName: optionalContactField,
    phone: yup
      .string()
      .ensure()
      .matches(phoneNumberPattern, INVALID_PHONENUMBER_FIELD_FEEDBACK_TEXT)
      .required(REQUIRED_FIELD_FEEDBACK_TEXT),
    phoneType: selectOptionSchema.required(REQUIRED_FIELD_FEEDBACK_TEXT),
    email: yup.string().email().required(REQUIRED_FIELD_FEEDBACK_TEXT),
    address1: yup.string(),
    address2: yup.string(),
    city: yup.string(),
    state: selectOptionSchema.required(REQUIRED_FIELD_FEEDBACK_TEXT),
    zipCode: yup.string().matches(zipCodePattern, INVALID_ZIPCODE_FIELD_FEEDBACK_TEXT),
    intakeType: selectOptionSchema.required(REQUIRED_FIELD_FEEDBACK_TEXT),
  })
  .required();
