import React from 'react';
import Fuse from 'fuse.js';
import PropTypes from 'prop-types';

import 'twin.macro';

function useQueryParamState(searchParamName) {
  const [value, setValue] = React.useState(() => {
    if (typeof window === 'undefined') {
      return '';
    }
    const searchParams = new URL(window.location).searchParams;
    if (searchParams.has(searchParamName)) {
      return searchParams.get(searchParamName);
    } else {
      return '';
    }
  });

  React.useEffect(() => {
    const newUrl = new URL(window.location);
    newUrl.searchParams.set(searchParamName, value);
    if (value) {
      window.history.replaceState(window.history.state, '', newUrl);
    } else {
      newUrl.searchParams.delete(searchParamName);
      window.history.replaceState(window.history.state, '', newUrl);
    }
  }, [searchParamName, value]);

  return [value, setValue];
}

function Search(props) {
  const items = props.data;
  const Render = props.render;
  const options = props.options;
  const shouldbeExact = props.shouldbeExact;

  const [searchTerm, setSearchTerm] = useQueryParamState('q');

  const [filteredItems, setFilteredItems] = React.useState(searchTerm ? [] : items);

  const fuseWorker = new Fuse(items, options);

  React.useEffect(() => {
    if (!searchTerm) {
      setFilteredItems(items);
    } else {
      const result = fuseWorker.search(shouldbeExact ? `="${searchTerm}"` : searchTerm).map(r => r.item);
      setFilteredItems(result);
    }
  }, [items, searchTerm]);

  return (
    <>
      <Render
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        filteredItems={filteredItems}
        shouldbeExact={shouldbeExact}
      />
    </>
  );
}

Search.propTypes = {
  data: PropTypes.array,
  render: PropTypes.func,
  options: PropTypes.object,
  shouldbeExact: PropTypes.bool,
};

export default Search;
