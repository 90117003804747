import { SelectOption } from 'types';
import { FilterState, NormalizedFilters } from './types';

export const normalizeFilters = (filter: FilterState): NormalizedFilters => {
  const { ageRange, complexity, type, timeRequired, caregiverRequired, treatmentAreas, keywords, hasMaterials } =
    filter;

  const getOptionValue = (option: SelectOption) => option.value;
  const handleRadioOption = (prop: string) => {
    if (!prop) return '';
    if (prop === 'yes') return true;
    if (prop === 'no') return false;

    return '';
  };
  const ageRangeValue = ageRange.map(getOptionValue);
  const complexityValue = complexity.map(getOptionValue);
  const homeworkTypeValue = type.map(getOptionValue);
  const timeRequiredValue = timeRequired.map(getOptionValue);
  const treatmentAreasValue = treatmentAreas.map(getOptionValue);
  const caregiverRequiredFilter = handleRadioOption(caregiverRequired);
  const hasMaterialsFilter = handleRadioOption(hasMaterials);

  return {
    keywords,
    ageFilter: ageRangeValue,
    complexityFilter: complexityValue,
    typeFilter: homeworkTypeValue,
    timeRequiredFilter: timeRequiredValue,
    caregiverFilter: filter.contentType === 'all' ? '' : caregiverRequiredFilter,
    treatmentAreaFilter: treatmentAreasValue,
    hasMaterials: filter.contentType === 'all' ? '' : hasMaterialsFilter,
    contentType: filter.contentType,
  };
};
