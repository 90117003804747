import { TimeSlot } from 'components/therapistMatcher/data';
import TherapistTimeSlotsCard from 'components/therapistMatcher/TherapistTimeSlotsCard';
import { Dayjs } from 'dayjs';
import { getTherapistWeekScheduleDays } from 'domain/therapist-matcher/scheduling';
import { AvailabilityType } from 'hooks/common/useTherapistSchedule';
import React from 'react';
import 'twin.macro';
import { Therapist } from 'types';

// Allows choosing a TimeSlot for a given therapist or list of therapists and a date filter
// Has the same internal API has a simple dropdown selector

// @TODO(juan): improve this, inherit from the other components to not repeat definitions
interface BaseProps {
  value?: TimeSlot | null;
  onChange?: (value: TimeSlot | null) => void;
  dateFilter: Dayjs;
  dateCount?: number;
  duration: number;
  type: AvailabilityType;
}

// For showing an entire week a given therapist
interface SingleTherapistProps {
  therapist: Therapist;
  therapists?: never;
}

// For showing a single day for multiple therapists
interface MultiTherapistProps {
  therapists: Therapist[];
  therapist?: never;
}

type Props = BaseProps & (SingleTherapistProps | MultiTherapistProps);

const TherapistsTimeSelector = React.memo(({ therapists, dateFilter, dateCount = 7, therapist, ...rest }: Props) => {
  return (
    <div>
      <div tw="flex flex-wrap gap-6 items-start">
        {therapists?.map((therapist, cardIndex) => (
          <TherapistTimeSlotsCard
            key={therapist.therapistID}
            therapist={therapist}
            date={dateFilter}
            cardIndex={cardIndex}
            {...rest}
          />
        ))}

        {therapist &&
          getTherapistWeekScheduleDays(dateFilter, dateCount).map((day, cardIndex) => (
            <TherapistTimeSlotsCard
              variant="day"
              key={day.toISOString()}
              cardIndex={cardIndex}
              therapist={therapist}
              date={day}
              {...rest}
            />
          ))}
      </div>
    </div>
  );
});

TherapistsTimeSelector.displayName = 'TherapistsTimeSelector';
export default TherapistsTimeSelector;
