import React from 'react';
import { faFilePlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { FirehoseEventClientAssignments } from 'domain/admin/types';
import { removePrefixFromID, removeHyphenFromStringChain } from 'utils/helpers';
import 'twin.macro';

const ClientAssignmentAssigned = (event: FirehoseEventClientAssignments) => {
  const firehoseEvent = event?.event;
  const isLastEvent = event?.isLastEvent;

  return (
    <div data-testid="firehose-client-assignment-assigned" tw="relative flex mt-7 px-2">
      {!isLastEvent && <span tw="absolute -z-1 top-7 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>}
      <div tw="flex items-baseline">
        <div tw="flex items-center bg-gray-200 rounded-full h-6 w-6">
          <FontAwesomeIcon icon={faFilePlus} tw="mx-[8px] h-3 text-indigo-700" />
        </div>
        <div tw="pl-4 text-sm">
          <div tw="lg:flex font-semibold pb-1 text-indigo-700">
            <Link to={{ pathname: `/therapists/${firehoseEvent?.createdBy}` }}>
              {firehoseEvent?.createdByFirstName} {firehoseEvent?.createdByLastName}&nbsp;
            </Link>
            <span tw="text-gray-500">{`assigned the ${removeHyphenFromStringChain(
              firehoseEvent.assignmentType,
            )}`}</span>
            &nbsp;
            <span tw="text-gray-500">{`${firehoseEvent.assignmentTitle}`}</span>
            &nbsp;
            <span tw="text-gray-500">{`to`}</span>
            &nbsp;
            <Link
              tw="text-indigo-700"
              to={{ pathname: `/clients/${removePrefixFromID(firehoseEvent.clientID)}` }}
            >
              {firehoseEvent?.clientFirstName} {firehoseEvent?.clientLastName}&nbsp;
            </Link>
            <span tw="text-gray-500">{`at ${dayjs(firehoseEvent?.createdAt as string).format('h:mm A')}, ${dayjs(
              firehoseEvent?.createdAt as string,
            ).format('MM/DD/YYYY')} `}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientAssignmentAssigned;
