import { useQuery, useMutation, useQueryClient } from 'react-query';
import { http } from '@expressable/utils';
import { ICompleteClientInformation } from '../types';
import { useToasts } from 'react-toast-notifications';
import { deleteEmptyOrUndefinedFromObject } from 'utils/helpers';
import { presentMutationError } from '@expressable/ui-library';
import { IBillingInfo } from './use-billing-information';
import { getCueError } from 'utils/error';
import { TherapistErrorCode } from 'domain/therapist/constants';

const getClient = async (id: string): Promise<ICompleteClientInformation> => {
  const { data } = await http.get(`/clients/${id}`);
  return data;
};

export function useClient(id: string) {
  return useQuery<ICompleteClientInformation, Error>(['client', id], () => getClient(id), {
    enabled: id !== undefined,
    staleTime: 300000,
  });
}

type AppointmentDate = {
  dateTime: string;
  override: boolean;
};

export interface CreateClientPayload {
  payload: {
    client: {
      clientFirstName: string;
      clientMiddleName: string;
      clientLastName: string;
      suffix?: string;
      preferredFirstName?: string;
      dob?: string;
      sex?: string;
      genderIdentity?: string;
      therapist: {
        therapistEmail: string;
        therapistName: string;
      };
      isClientMinor: boolean;
      clinicalCompetencies?: string[];
      insurancePlan?: string[];
    };
    primaryContact: {
      contactID: string;
      contactFirstName: string;
      contactMiddleName: string;
      contactLastName: string;
      contactTimeZone: string;
      relationshipToClient: string;
      email: string;
      phone: {
        [k: string]: string;
      };
      address?: {
        street?: string;
        line2?: string;
        state?: string;
        city?: string;
        postalCode?: string;
      };
    };
    sendIntake?: {
      zoomLink: string;
      billingIntakeUrl: string;
      intake: string;
    };
    initialEvaluation?: {
      dateTime: string;
      acuityCalendarID: number;
      duration: number;
      override?: boolean;
    };
    appointments?: {
      notSchedulingReason?: string | null;
      notSchedulingDescription?: string | null;
      dates: AppointmentDate[];
      acuityCalendarID?: number;
      recurring: boolean;
      recurringForever: boolean;
    };
    billing?: IBillingInfo;
  };
}

const createClient = async (createClientPayload: CreateClientPayload) => {
  const { payload } = createClientPayload;
  let validAddress;
  if (payload.primaryContact.address) {
    validAddress = deleteEmptyOrUndefinedFromObject(payload.primaryContact.address);
    if (Object.values(validAddress).length == 0) {
      delete payload.primaryContact.address;
    } else {
      payload.primaryContact.address = validAddress;
    }
  }

  return http.put(`/clients`, payload).then(res => res.data);
};

export function useCreateClient() {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(createClient, {
    onSuccess: async (response, payload) => {
      addToast('Client Successfully Created', { appearance: 'success', autoDismiss: true });

      await Promise.all([
        queryClient.invalidateQueries(['client', response]),
        queryClient.invalidateQueries(['contacts', payload.payload.primaryContact.contactID]),
        queryClient.invalidateQueries(['getSlackChannelInfo']),
      ]);
    },
    onError: (err, payload) => {
      const error = getCueError(err);

      // if rate limit error, we should invalidate the queries to update the therapist time slots
      // the user must be able to pick the next time slot available
      if (error.keyword === TherapistErrorCode.RateLimit) {
        const evalCalendarID = payload.payload.initialEvaluation?.acuityCalendarID?.toString();
        queryClient.invalidateQueries(['therapist-slot-v2', evalCalendarID]);

        const speechSessionCalendarID = payload.payload.appointments?.acuityCalendarID?.toString();
        if (evalCalendarID !== speechSessionCalendarID) {
          queryClient.invalidateQueries(['therapist-slot-v2', speechSessionCalendarID]);
        }
      }

      presentMutationError(err, payload);
    },
  });
}
