import { Label } from '@expressable/ui-library';

type ReadyOnlyFieldProps = {
  label: string;
};

const ReadOnlyField: React.FC<ReadyOnlyFieldProps> = ({ label, children }) => (
  <div>
    <Label className="mb-2">{label}</Label>
    <span className="text-sm">{children}</span>
  </div>
);

export default ReadOnlyField;
