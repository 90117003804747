import React, { useState } from 'react';
import { Button, Textarea } from '@expressable/ui-library';
import { useForm } from 'react-hook-form';
import { useAddClientCaseComment } from 'hooks/use-client-cases';

interface ClientCasesAddCommentsProps {
  clientId: string;
  caseNumber: string;
}

interface FormValues {
  Body: string;
}

export default function ClientCasesAddComments(props: ClientCasesAddCommentsProps) {
  const { clientId, caseNumber } = props;
  const [isCommentsTextAreaOpen, setIsCommentsTextAreaOpen] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<FormValues>({ mode: 'onChange' });
  const addClientCaseCommentMutation = useAddClientCaseComment();
  const { mutate: addClientCaseComment } = addClientCaseCommentMutation;

  const onSubmitClientCaseComment = async (data: FormValues) => {
    addClientCaseComment(
      {
        clientId: clientId,
        caseNumber: caseNumber,
        Body: data.Body,
      },
      {
        onSuccess: () => {
          setIsCommentsTextAreaOpen(false);
          reset();
        },
      },
    );
  };

  return (
    <>
      {!isCommentsTextAreaOpen ? (
        <Button data-testid="add-comment-button" variant="secondary" onClick={() => setIsCommentsTextAreaOpen(true)}>
          Add Comment
        </Button>
      ) : (
        <form onSubmit={handleSubmit(onSubmitClientCaseComment)}>
          <div data-testid="client-cases-comment-text-area">
            <span className="font-semibold">New Comment</span>
            <Textarea className="w-full mt-2" rows={4} {...register('Body', { required: true })} error={errors.Body} />
          </div>
          <div className="py-3 w-full flex gap-4">
            <Button
              data-testid="client-cases-comment-save"
              variant="primary"
              type="submit"
              loading={addClientCaseCommentMutation.isLoading}
              disabled={!isValid}
            >
              Post comment
            </Button>

            <Button
              data-testid="client-cases-comment-cancel"
              variant="secondary"
              type="button"
              onClick={() => {
                setIsCommentsTextAreaOpen(false);
                reset();
              }}
            >
              Cancel
            </Button>
          </div>
        </form>
      )}
    </>
  );
}
