import React, { useEffect, useState } from 'react';
import { SingleDatePicker, isInclusivelyAfterDay } from 'react-dates';
import { Control, Controller, UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import 'twin.macro';
import moment from 'moment';

interface IControlledDatePickerProps {
  control: Control<any>; //eslint-disable-line
  getValues: UseFormGetValues<any>; //eslint-disable-line
  setValue: UseFormSetValue<any>; //eslint-disable-line
  name: string;
  disabled?: boolean;
  defaultValue: string | undefined;
  needsOutsideRange?: boolean;
}

export const ControlledDatePicker = ({
  control,
  getValues,
  setValue,
  name,
  disabled,
  defaultValue,
  needsOutsideRange,
}: IControlledDatePickerProps) => {
  const currentValue = getValues(name);
  const [state, setState] = useState<any>({ date: moment(currentValue || defaultValue) }); //eslint-disable-line

  useEffect(() => {
    setValue(name, state.date);
  }, [state]);

  return (
    <Controller
      render={({ field }) => {
        return (
          <SingleDatePicker
            date={state.date}
            noBorder
            block
            openDirection="down"
            small
            placeholder=""
            numberOfMonths={1}
            focused={state.focused}
            onDateChange={date => {
              setState((p: any) => ({ ...p, date })); //eslint-disable-line
              field.onChange(date);
            }}
            onFocusChange={
              ({ focused }) => setState((p: any) => ({ ...p, focused })) //eslint-disable-line
            }
            isOutsideRange={needsOutsideRange ? day => !isInclusivelyAfterDay(day, moment()) : () => false}
            id={name}
            disabled={disabled}
          />
        );
      }}
      control={control}
      name={name}
    />
  );
};
