import { Select, Textarea } from '@expressable/ui-library';
import { FormField } from 'components/Forms';
import { ControlledFormField } from 'components/Forms/FormFieldControlled';
import useClinicalArea from 'hooks/use-clinical-area';
import { autoSize } from 'utils/auto-h-size';

type Props = {
  name: string;
  sectionName: string;
  assessedQuestion: string;
};

export default function ClinicalArea({ name, sectionName, assessedQuestion }: Props) {
  const { howWasAssessed, assessmentsUsed, isStandardOrNonStandard } = useClinicalArea(name);

  return (
    <div className="flex-col mb-4">
      <h2 className="font-semibold text-xl ">Clinical Area: {sectionName}</h2>
      <hr className="mt-1 mb-6 border-gray-200" />
      <div className="flex flex-col gap-5">
        <ControlledFormField
          containerClass="flex flex-col w-1/3"
          component={Select}
          isMulti
          isSearchable
          maxWidth="large"
          spacing="tight"
          tw={'min-w-[550px]'}
          name={`${name}HowWasAssessed`}
          label={assessedQuestion}
          options={howWasAssessed}
        />
        {isStandardOrNonStandard && (
          <>
            <ControlledFormField
              containerClass="flex flex-col w-1/3"
              component={Select}
              isMulti
              isSearchable
              maxWidth="large"
              spacing="tight"
              tw={'min-w-[550px]'}
              name={`${name}AssessmentUsed`}
              label="Assessment Used"
              options={assessmentsUsed}
            />
          </>
        )}
        <FormField
          component={Textarea}
          rows={4}
          className="w-full"
          spacing="tight"
          name={`${name}Conclusions`}
          label="Results and Interpretation"
          onChange={autoSize}
        />
      </div>
    </div>
  );
}
