export const envValues = {
  production: {
    evaluationTypeId: 16092532,
    sessionTypeId: 19424447,
    fieldIDforPatientID: 8410298,
    scheduleUrlDomain: 'expressable.as.me',
  },
  development: {
    evaluationTypeId: 23269482,
    sessionTypeId: 23269463,
    fieldIDforPatientID: 9847150,
    scheduleUrlDomain: 'expressable-dev.as.me',
  },
};

export const { evaluationTypeId, sessionTypeId, fieldIDforPatientID, scheduleUrlDomain } =
  process.env.REACT_APP_NODE_ENV === 'production' ? envValues.production : envValues.development;

export const ACTIVITY_FILTER_OPTIONS = [
  {
    value: ['session-note', 'soap-note', 'appointment-note', 'evaluation-note', 'screening-note'],
    label: 'Appointment Notes',
  },
  {
    value: ['evaluation-note'],
    label: 'Evaluation Notes',
  },
  {
    value: ['session-note', 'soap-note', 'appointment-note'],
    label: 'Session Notes',
  },
  { value: ['add-chart-note', 'chart-note'], label: 'Chart Notes' },
  { value: ['add-admin-note', 'admin-note'], label: 'Admin Notes' },
  { value: ['discharge-note'], label: 'Discharge Notes' },
  { value: ['home-program-submission', 'client-assignment-completed'], label: 'Home Program Submissions' },
  { value: ['intake-adult', 'intake-child', 'intake-email-sent'], label: 'Intake' },
  { value: ['release-of-information-form-completed'], label: 'ROI Form Completed' },
  { value: ['files-uploaded-client', 'files-deleted-client'], label: 'File Updates' },
  {
    value: [
      'add-client-diagnoses',
      'add-medical-diagnoses',
      'add-areas-of-function',
      'add-applicable-conditions',
      'add-skilled-interventions',
      'remove-client-diagnoses',
      'remove-medical-diagnoses',
      'remove-areas-of-function',
      'remove-applicable-conditions',
      'remove-skilled-interventions',
      'add-client-visit-frequency',
      'remove-client-visit-frequency',
      'update-client-goal',
      'add-client-goal',
      'screening-intake-child',
      'update-client-visit-frequency',
      'add-client-discharge-plan',
      'remove-client-discharge-plan',
      'update-client-discharge-plan',
      'add-client-start-date',
      'remove-client-start-date',
      'update-client-start-date',
      'add-client-end-date',
      'remove-client-end-date',
      'update-client-end-date',
      'add-client-visit-duration',
      'remove-client-visit-duration',
      'update-client-visit-duration',
      'add-client-therapy-purpose',
      'remove-client-therapy-purpose',
      'update-client-therapy-purpose',
      'add-client-delays-disabilities',
      'remove-client-delays-disabilities',
      'update-client-delays-disabilities',
      'add-client-total-visits',
      'remove-client-total-visits',
      'update-client-total-visits',
      'add-equipment-devices',
      'remove-equipment-devices',
      'add-client-additional-goal',
      'update-client-additional-goal',
      'remove-client-additional-goal',
      'change-care-plan-quality-review-evaluation-status',
      'change-care-plan-quality-review-discharge-status',
    ],
    label: 'Care Plan Updates',
  },
  {
    value: ['add-billing-credits', 'remove-billing-credits', 'refund-billing-charge', 'update-billing-info-copay'],
    label: 'Billing',
  },
  {
    value: [
      'cancel-acuity-appointment',
      'reschedule-acuity-appointment',
      'appointment-rescheduled',
      'appointment-cancelled',
      'appointment-late-cancelled',
      'appointment-no-show',
      'add-acuity-appointment',
      'reschedule-pending-reschedule',
      'add-pending-reschedule',
    ],
    label: 'Appointment Updates',
  },
  { value: ['create-client'], label: 'Misc' },
  { value: ['twilio-message'], label: 'Text Messages' },
  { value: ['inactivate-client'], label: 'Archive' },
  { value: ['reactivate-client'], label: 'Unarchive' },
];

export const emailValidationRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

export const relationshipsOptions = [
  { label: 'Parent', value: 'Parent' },
  { label: 'Client', value: 'Client' },
  { label: 'Dependent', value: 'Dependent' },
  { label: 'Family Member', value: 'Family Member' },
  { label: 'Legal Guardian', value: 'Legal Guardian' },
  { label: 'Physician', value: 'Physician' },
  { label: 'Spouse', value: 'Spouse' },
  { label: 'Other', value: 'Other' },
];

export const phoneSelectOptions = [
  { value: 'home', label: 'home' },
  { value: 'mobile', label: 'mobile' },
  { value: 'work', label: 'work' },
];

export const phoneTypeSelectOptions = [
  { value: 'homePhone', label: 'home' },
  { value: 'mobilePhone', label: 'mobile' },
  { value: 'workPhone', label: 'work' },
];
