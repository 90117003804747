import React, { useState } from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import tw from 'twin.macro';
import { Card, Link, Spacer, useDisclosure } from '@expressable/ui-library';
import { Contact, ICompleteClientInformation } from 'types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import registerIcons from 'utils/register-fa';
import { GrantPortalAccessModal } from './grant-portal-access-modal';
import usePermissions from 'hooks/use-permissions';

registerIcons();

interface ClientPortalProps {
  clientId: string;
  clientData: ICompleteClientInformation;
}

const portalClientAppBaseUrl = process.env.REACT_APP_PORTAL_CLIENT_APP_URL ?? 'https://app.expressable.io';

export function ClientPortal({ clientId, clientData }: ClientPortalProps) {
  const {
    isOpen: GrantPortalAccessModalIsOpen,
    onClose: GrantPortalAccessModalOnClose,
    onOpen: GrantPortalAccessModalOnOpen,
  } = useDisclosure();
  const { isAdmin } = usePermissions();
  const [currentContact, setCurrentContact] = useState<Contact | null>(null);

  return (
    <>
      <Spacer size="md" />
      <Card tw="px-8 mb-5">
        <div tw="mt-4 mb-12">
          <div tw="text-2xl font-semibold">Client Portal</div>
          <a tw="text-sm" href={portalClientAppBaseUrl!} className="text-indigo-700" target="_blank" rel="noreferrer">
            {portalClientAppBaseUrl!}
          </a>
        </div>

        {clientData.contactInformation?.map(contact => {
          const contactFullName = `${contact?.contactFirstName} ${contact?.contactLastName}`;
          const contactLink = `/contacts/${contact?.contactID}#contacts`;
          const hasCognito = !!contact.cognitoUserName;
          const title = contact.primaryContact ? 'Primary Contact' : 'Additional Contact';

          return (
            <div key={contact.contactID}>
              {contact.email && (
                <div>
                  <div tw="mb-2 mt-3 text-base leading-none capitalize font-semibold">{title}</div>
                  <div tw="mb-5">
                    <Link to={contactLink} target="_blank">
                      {contactFullName}
                    </Link>
                  </div>

                  {hasCognito ? (
                    <>
                      <div tw="mb-2 mt-5 text-base leading-none capitalize font-semibold">
                        Portal Account Created On
                      </div>
                      <div>{dayjs(contact.cognitoCreatedAt).format('MM/DD/YYYY')}</div>
                    </>
                  ) : (
                    <div tw="flex flex-row">
                      <FontAwesomeIcon icon="exclamation-circle" size="lg" color="#ffd04e" />
                      <div tw="ml-3">
                        <div tw="mb-1">{title} doesn&apos;t have Portal access.</div>
                        {isAdmin && (
                          <span
                            tw="text-indigo-700 cursor-pointer"
                            onClick={() => {
                              setCurrentContact(contact);
                              GrantPortalAccessModalOnOpen();
                            }}
                          >
                            Grant access
                          </span>
                        )}
                      </div>
                    </div>
                  )}

                  <Spacer size="lg" />
                </div>
              )}
            </div>
          );
        })}
      </Card>

      {currentContact && (
        <GrantPortalAccessModal
          clientId={clientId}
          contact={currentContact!}
          isOpen={GrantPortalAccessModalIsOpen}
          onClose={GrantPortalAccessModalOnClose}
        />
      )}
    </>
  );
}
