import { FC } from 'react';
import dayjs from 'dayjs';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Link } from 'react-router-dom';
import { RecurringAppointment } from 'types';
import calendarIcon from 'assets/calendar.svg';

import 'twin.macro';
import {
  buildRecurringAppointmentScheduleTimeMessage,
  useRecurringAppointmentsData,
} from './hooks/use-recurring-appointments-data';

export type RecurringAppointmentsProps = {
  appointments?: RecurringAppointment[];
  onEditRecurringAppointment: React.Dispatch<React.SetStateAction<RecurringAppointment | undefined>>;
  onOpenRecurringAppointmentsModal: () => void;
};
export const RecurringAppointments: FC<RecurringAppointmentsProps> = ({
  appointments,
  onEditRecurringAppointment,
  onOpenRecurringAppointmentsModal,
}) => {
  const { appointments: groupedAppointments, timezone } = useRecurringAppointmentsData(appointments);
  const { cueSchedulingControl: displayBookingCapability } = useFlags();

  if (!appointments || !appointments.length) return null;

  return (
    <div>
      <ul className='flex-col'>
        {groupedAppointments?.map((recurringAppointment: RecurringAppointment, index: number) => (
          <li tw="mt-3" key={index}>
            {displayBookingCapability ? (
              <Link
                to="#"
                data-testid="recurring-appointment"
                onClick={() => {
                  onEditRecurringAppointment({
                    calendarID: recurringAppointment.calendarID,
                    clientID: recurringAppointment.clientID,
                    createdAt: recurringAppointment.createdAt,
                    createdBy: recurringAppointment.createdBy,
                    duration: recurringAppointment.duration,
                    farestFutureScheduledAppointmentDateTime:
                      recurringAppointment.farestFutureScheduledAppointmentDateTime,
                    recordType: recurringAppointment.recordType,
                    recurringAppointmentID: recurringAppointment.recurringAppointmentID,
                    startDate: recurringAppointment.startDate,
                    endDate: recurringAppointment.endDate,
                    therapistFirstName: recurringAppointment.therapistFirstName,
                    therapistID: recurringAppointment.therapistID,
                    therapistLastName: recurringAppointment.therapistLastName,
                    updatedAt: recurringAppointment.updatedAt,
                    updatedBy: recurringAppointment.updatedBy,
                  });
                  onOpenRecurringAppointmentsModal();
                }}
              >
                <div className="flex items-center justify-start gap-x-3 mb-8">
                  <img src={calendarIcon} alt="calendar" tw="w-[34px] h-[38px]" />
                  <div className="flex-col">
                    <p className="text-indigo-600 font-semibold text-sm">
                      {dayjs(recurringAppointment.startDate).tz(dayjs.tz.guess()).format('dddd')}s at&nbsp;
                      {dayjs(recurringAppointment.startDate).tz(dayjs.tz.guess()).format('hh:mm A')}&nbsp;
                      {timezone}&nbsp;
                      {recurringAppointment.duration !== 30 && ` - ${recurringAppointment.duration} min`}
                    </p>
                    <div className="flex-col">
                      <p className="text-xs">
                        {`with ${recurringAppointment.therapistFirstName} ${recurringAppointment.therapistLastName}`}{' '}
                        {buildRecurringAppointmentScheduleTimeMessage(recurringAppointment)}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            ) : (
              <div className="flex item-center justify-start gap-x-3 cursor-not-allowed">
                <img src={calendarIcon} alt="calendar" tw="w-[34px] h-[38px]" />
                <div className="flex-col">
                  <p className="font-semibold text-xs">
                    {dayjs(recurringAppointment.startDate).tz(dayjs.tz.guess()).format('dddd')}s at&nbsp;
                    {dayjs(recurringAppointment.startDate).tz(dayjs.tz.guess()).format('hh:mm A')}&nbsp;
                    {recurringAppointment.duration !== 30 && ` - ${recurringAppointment.duration} MIN`}
                  </p>
                  <div className="flex-col">
                    <p className="text-xs">
                      {`with ${recurringAppointment.therapistFirstName} ${recurringAppointment.therapistLastName}`}{' '}
                      {buildRecurringAppointmentScheduleTimeMessage(recurringAppointment)}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};
