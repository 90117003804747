/* eslint-disable react/react-in-jsx-scope */
import dayjs from 'dayjs';
import { useToast } from '@expressable/ui-library';
import { areTimeslotsEqual, TimeSlot, useTherapistAvailableTimesV2 } from 'components/therapistMatcher/data';
import TherapistTimeSlotsCardUi, {
  TherapistTimeSlotsCardProps,
} from 'components/therapistMatcher/TherapistTimeSlotsCard/ui';
import { useDisplayTimezone } from 'hooks/common/useDisplayTimezone';
import { AvailabilityType } from 'hooks/common/useTherapistSchedule';
import { useRef, useState } from 'react';
import { timeOptions } from 'utils/time-options';
import 'twin.macro';
import { AvailableItem } from 'domain/therapist-matcher/types';

type ContainerProps = {
  duration: number;
  type: AvailabilityType;
} & Omit<TherapistTimeSlotsCardProps, 'options' | 'isLoading' | 'error'>;

// Divide the component into a stateful container and a dumb presentational component to allow simpler testing
// Test the implementation while simply mocking the container
const TherapistTimeSlotsCard = ({ date, therapist, duration, type, onChange, cardIndex, ...rest }: ContainerProps) => {
  const refreshCache = useRef(false);

  const {
    data: availableTimes,
    isLoading,
    isError,
    error,
    isRefetching,
    refetch,
  } = useTherapistAvailableTimesV2({
    calendarID: therapist?.acuityCalendarID,
    date: date?.format('YYYY-MM-DD'),
    duration,
    type,
    refreshCache,
  });

  const { errorToast } = useToast();
  const { formatTime } = useDisplayTimezone();
  const [isOverride, setIsOverride] = useState<boolean>(false);

  const timeOptionsLowerCase = timeOptions.map(option => {
    const updatedLabel = option.label.replace(/AM|PM/g, match => match.toLowerCase());

    return {
      ...option,
      label: updatedLabel,
    };
  });

  // this is necessary to keep backward compatibility with (v1)
  // once we migrate all therapist available times to (v2), this mapping won't be necessary
  const mapToTimeSlot = (availableItem: AvailableItem): TimeSlot => {
    const { time, isAvailableNextWeek } = availableItem;
    return {
      timestamp: dayjs(time),
      isAvailableNextWeek: !!isAvailableNextWeek,
      therapist: therapist,
      duration: duration,
      cardIndex: cardIndex,
      override: isOverride,
    };
  };

  const confirmedOnChange = async (value: TimeSlot | null) => {
    refreshCache.current = true;
    let response = null;

    if (!value) {
      onChange?.(null);
      return;
    }

    onChange?.(value);

    if (!isOverride) {
      response = await refetch();
    }

    const availabilities = response?.data?.items || [];
    if (
      value &&
      !availabilities.find(availability => areTimeslotsEqual(mapToTimeSlot(availability), value)) &&
      !isOverride &&
      !value.override
    ) {
      const time = formatTime(value.timestamp, 'h:mm a');
      errorToast(`${value.therapist.therapistName} is not available at ${time} anymore`);
      return;
    }
  };

  return (
    <TherapistTimeSlotsCardUi
      {...rest}
      therapist={therapist}
      duration={duration}
      availableTimes={availableTimes}
      isLoading={isLoading}
      isRefetching={isRefetching}
      isError={isError}
      date={date}
      error={error}
      onChange={confirmedOnChange}
      type={type}
      isOverride={isOverride}
      setIsOverride={setIsOverride}
      timeOptionsLowerCase={timeOptionsLowerCase}
      cardIndex={cardIndex}
    />
  );
};

export default TherapistTimeSlotsCard;
