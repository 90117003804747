import { useMutation, useQuery, useQueryClient } from 'react-query';
import { dynamicHttp } from '@expressable/utils';
import { useToasts } from 'react-toast-notifications';
import { presentMutationError } from '@expressable/ui-library';
import { SelectOption } from 'types';

const httpHealthRecordProxy = dynamicHttp('health-record-proxy');

export interface GetDischargePlanPayload {
  duration: string;
}

export interface SetVisitDurationPayload {
  clientId: string;
  duration: SelectOption;
  shouldLogEvent: boolean;
}

const getVisitDuration = async (clientId: string) => {
  const { data } = await httpHealthRecordProxy.get(`/care-plans/visit-duration/${clientId}`);
  return data;
};

export function useGetPlanOfCareVisitDuration({ clientId }: { clientId: string }) {
  return useQuery<GetDischargePlanPayload | null>(['care-plan-visit-duration', clientId], () =>
    getVisitDuration(clientId),
  );
}

const setVisitDuration = async ({ clientId, ...rest }: SetVisitDurationPayload) => {
  return httpHealthRecordProxy
    .post(`/care-plans/visit-duration/${clientId}`, {
      duration: parseInt(rest.duration.value),
      shouldLogEvent: rest.shouldLogEvent,
    })
    .then(res => res.data);
};

export function useSetVisitDuration() {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(setVisitDuration, {
    onSuccess: (_, payload) => {
      addToast('Visit Duration Successfully Updated', { appearance: 'success', autoDismiss: true });
      queryClient.invalidateQueries(['care-plan-visit-duration', payload.clientId]);
    },
    onError: presentMutationError,
  });
}
