import React, { useEffect, useState, useRef } from 'react';
import { Label, Link, Textarea, Button, Select, FormGroup } from '@expressable/ui-library';
import {
  isAppointmentNoteSoap,
  isAppointmentNoteFreeText,
  isAppointmentNoteEvaluation,
  isScreeningNote,
  isAppointmentNoteSession,
} from 'guards';
import {
  Event,
  Appointment,
  SoapNoteContent,
  CptCode,
  ICompleteClientInformation,
  EvaluationNoteContent,
  serverValidationErrors,
  SessionNoteContent,
  AppointmentNoteSession,
} from 'types';
import {
  BasicNoteFormProps,
  onNoteSubmit,
  getInitialFreeTextNote,
  getInitialEvaluationNote,
  getInitialSessionNote,
  getInitialScreeningNote,
} from '.';
import { useCreateNote, useEditNote } from 'hooks/use-notes';
import { useDismissPendingAppointment } from 'hooks/use-pending-appointments';
import { useGetDiagnoses } from 'hooks/use-care-plan-diagnoses';
import { useGetGoals } from 'hooks/use-care-plan-goals';
import { useGetVisitFrequency } from 'hooks/use-care-plan-visit-frequency';
import { SoapNoteFormContent } from 'pages/client/components/client-notes/note-form-content/soap-note-form-content';
import { EvaluationNoteFormContent } from 'pages/client/components/client-notes/note-form-content/evaluation-note-form-content';
import { ScreeningNoteFormContent } from 'pages/client/components/client-notes/note-form-content/screening-note-form-content';
import { SessionNoteFormContent } from 'pages/client/components/client-notes/note-form-content/session-note-form-content';
import { getSelectedTime, timeOptions as appointmentTimeOptions } from 'utils/time-options';
import { SingleDatePicker } from 'react-dates';
import { fromEventToSessionActivity, fromEventToSoapActivity } from './converters';
import moment, { Moment } from 'moment';
import cptCodes, { formatCptCode, isValidCpt } from 'pages/client/components/cpt-codes';
import { produce } from 'immer';
import 'twin.macro';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import useInterval from './use-interval';
import { AppointmentNoteFormErrorBoundary } from 'components/appointment-note-form-error-boundary';
import * as Sentry from '@sentry/react';
import { getGoalDetails } from 'utils/care-plans';
import { GoalProgress } from 'types';
import { getActiveGoals } from 'utils/get-active-goals';

const noteTypeOptions = [
  { label: 'Progress Note', value: 'free-text' },
  { label: 'Evaluation Note', value: 'evaluation-note' },
  { label: 'Session Note', value: 'session-note' },
  { label: 'Screening Note', value: undefined },
];

// Sort CPT codes by code
cptCodes.sort((a, b) => a.code.localeCompare(b.code));

dayjs.extend(utc);
dayjs.extend(timezone);

export interface SaveToolbarProps {
  loading: boolean;
  isAutoSave: boolean;
  lastAutoSavedAt: dayjs.Dayjs | null;
}

export const SaveToolbar = (props: SaveToolbarProps) => {
  const { loading, isAutoSave, lastAutoSavedAt } = props;
  return (
    <div tw="sticky py-3 px-4 bottom-0 bg-white border-t border-gray-200 flex gap-4 items-center">
      <Button data-testid="appointmentNoteFormSave" variant="primary" loading={loading} type="submit">
        {isAutoSave ? 'Finish Editing' : 'Save'}
      </Button>
      {isAutoSave && (
        <>
          {lastAutoSavedAt && (
            <span tw="text-gray-400 text-xs">
              Last auto-saved at {lastAutoSavedAt.tz(dayjs.tz.guess()).format('hh:mm A')}
            </span>
          )}
        </>
      )}
    </div>
  );
};

export interface AppointmentNoteFormProps extends BasicNoteFormProps {
  lastAppointmentNote: Event | undefined;
  lastSessionNote?: Event;
  canLoadPrevious: boolean;
  clientData: ICompleteClientInformation | undefined;
  remoteValidationErrors?: serverValidationErrors;
  setValidationErrors: React.Dispatch<React.SetStateAction<serverValidationErrors | undefined>>;
}

export const createFormFieldValidationMap = (localValidationErrors: serverValidationErrors | undefined) => {
  if (!localValidationErrors) return new Map<string, string>();
  if (!Array.isArray(localValidationErrors)) {
    Sentry.captureException(new Error('localvalidation is not an array, unknown error from BE'), {
      data: {
        localValidationErrors,
      },
    });
    return new Map<string, string>().set('UNKNOWN_ERROR', JSON.stringify(localValidationErrors));
  }

  return localValidationErrors?.reduce((previousValidationError, currentValidationError) => {
    const { keyword, message, params } = currentValidationError;

    if (keyword === 'errorMessage' && params && params.errors) {
      const subfieldsMap = createFormFieldValidationMap(params.errors as serverValidationErrors);
      subfieldsMap?.forEach((_subfieldMessage, instancePath) => previousValidationError.set(instancePath, message));
    }

    const instancePath =
      keyword === 'required'
        ? `${currentValidationError.instancePath}/${params?.missingProperty}`
        : currentValidationError.instancePath;

    previousValidationError.set(instancePath, message);

    return previousValidationError;
  }, new Map<string, string>());
};

export const AppointmentNoteForm = (props: AppointmentNoteFormProps) => {
  const {
    setActivity,
    activityId,
    focusElementRef,
    lastAppointmentNote,
    lastSessionNote,
    clientData,
    clientId,
    remoteValidationErrors,
    setValidationErrors,
    setAutoSaveActivityId,
    canLoadPrevious,
  } = props;
  const [localValidationErrors, setLocalValidationErrors] = useState<serverValidationErrors | undefined>();
  const activity = props.activity as Appointment;
  const [datePickerFocused, setDatePickerFocused] = React.useState(false);
  const [isMounted, setIsMounted] = React.useState(false);
  const createNoteMutation = useCreateNote();
  const { mutateAsync: createNote } = createNoteMutation;
  const editNoteMutation = useEditNote();
  const { mutateAsync: editNote } = editNoteMutation;
  const dismissPendingAppointmentMutation = useDismissPendingAppointment();
  const { mutateAsync: dismissPendingAppointment } = dismissPendingAppointmentMutation;
  const [cptError, setCptError] = useState<{ message: string } | null>(null);
  const { data: diagnoses } = useGetDiagnoses({ clientId });
  const { data: goals } = useGetGoals({ clientId });
  const { data: visitFrequency } = useGetVisitFrequency({ clientId });
  const validationRefsMap = useRef(new Map());

  const formFieldValidationMap = React.useMemo(
    () => createFormFieldValidationMap(localValidationErrors),
    [localValidationErrors],
  );



  useEffect(() => {
    if (formFieldValidationMap && isMounted && validationRefsMap && validationRefsMap.current) {
      const refsMap = validationRefsMap.current;

      // Get the first element of the instancePath to validation messages map
      // that actually exists
      const firstValidationInstancePath = Array.from(formFieldValidationMap.keys()).find(k => refsMap.has(k));

      if (firstValidationInstancePath) {
        // Get the Ref using the instancePath index and focus the field
        const fieldRef = refsMap.get(firstValidationInstancePath).current;
        if (fieldRef) {
          fieldRef.focus();
        }
      }
    }

    setIsMounted(true);
  }, [formFieldValidationMap]);

  function shouldShowError(formFieldValidationMap: Map<string, string> | undefined, instancePath: string) {
    if (formFieldValidationMap?.has(instancePath)) {
      return { message: formFieldValidationMap.get(instancePath) };
    } else {
      return undefined;
    }
  }

  function registerField(instancePath: string) {
    validationRefsMap.current.set(instancePath, React.createRef());

    return {
      ref: validationRefsMap.current.get(instancePath),
      error: shouldShowError(formFieldValidationMap, instancePath),
    };
  }

  //Detect when server validations errors are ready and add them to the local state
  useEffect(() => {
    if (remoteValidationErrors) {
      setLocalValidationErrors(remoteValidationErrors);
    }
  }, [remoteValidationErrors]);

  const handleDateTimeChange = (date: Moment | null, time?: number) => {
    const activityDate = moment((activity as Appointment).appointmentOn);
    const soapNoteContent = activity!.note.content as SoapNoteContent;

    if (time) {
      activityDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      activityDate.add(time, 'hours');
    } else {
      activityDate.set({ year: date?.year(), month: date?.month(), date: date?.date() });
    }

    setActivity({
      ...activity,
      note: {
        ...activity!.note,
        content: isAppointmentNoteSoap(activity.note)
          ? {
              ...soapNoteContent,
              subjectiveComplaint: {
                ...soapNoteContent.subjectiveComplaint,
              },
            }
          : activity!.note.content,
      },
      appointmentOn: activityDate,
    } as Appointment);
  };

  const cptValidationsRegister = registerField('/cpt');
  let noteContentSection = null;
  if (isAppointmentNoteFreeText(activity.note)) {
    const handleFreeTextContentChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setActivity(
        produce(activity => {
          activity!.note.content = event.target.value;
        }),
      );
    };

    noteContentSection = (
      <Textarea
        data-testid="freeTextContent"
        ref={focusElementRef}
        tw="w-full"
        value={activity.note.content}
        onChange={handleFreeTextContentChange}
        placeholder="Enter your note here"
        rows={4}
        required
      />
    );
  } else if (isAppointmentNoteSoap(activity.note)) {
    noteContentSection = (
      <SoapNoteFormContent
        formContent={activity.note.content}
        setActivity={setActivity as React.Dispatch<React.SetStateAction<Appointment>>}
        focusElementRef={focusElementRef}
      />
    );
  } else if (isAppointmentNoteEvaluation(activity.note)) {
    noteContentSection = (
      <EvaluationNoteFormContent
        formContent={activity.note.content}
        setActivity={setActivity as React.Dispatch<React.SetStateAction<Appointment>>}
        clientID={clientId}
        registerField={registerField}
        onChange={() => setDirty(true)}
      />
    );
  } else if (isAppointmentNoteSession(activity.note)) {
    noteContentSection = (
      <SessionNoteFormContent
        clientData={clientData}
        formContent={activity.note.content}
        setActivity={setActivity as React.Dispatch<React.SetStateAction<Appointment>>}
        clientId={clientId}
        onChange={() => setDirty(true)}
        noteID={activityId}
        registerField={registerField}
      />
    );
  } else if (isScreeningNote(activity.note)) {
    noteContentSection = (
      <ScreeningNoteFormContent
        formContent={activity.note.content}
        setActivity={setActivity as React.Dispatch<React.SetStateAction<Appointment>>}
        focusElementRef={focusElementRef}
      />
    );
  }

  const isAutoSave = isAppointmentNoteEvaluation(activity.note) || isAppointmentNoteSession(activity.note);
  const [dirty, setDirty] = useState(false);
  const [lastAutoSavedAt, setLastAutoSavedAt] = useState<dayjs.Dayjs | null>(null);

  const save = async ({ autoSaving }: { autoSaving: boolean }) => {
    let submissionAppointment = activity;
    const { note } = submissionAppointment;
    if (
      !isAppointmentNoteEvaluation(note) &&
      !isAppointmentNoteSession(note) &&
      !isScreeningNote(note) &&
      !isValidCpt(submissionAppointment.cpt)
    ) {
      setCptError({ message: 'CPT is required' });
      return;
    }

    // Add Care Plan Goals data if it is an Evaluation Note to be submitted
    if (isAppointmentNoteEvaluation(note)) {
      submissionAppointment = produce(submissionAppointment, submissionAppointment => {
        const content = submissionAppointment.note.content as EvaluationNoteContent;
        content.diagnoses.items = diagnoses ?? [];
        content.carePlanGoalsProgress = getActiveGoals(goals ?? []).map(
          goal =>
            ({
              goalType: goal.goalType,
              status: goal.status,
              detail: goal.detail,
              ltgID: goal.ltgID,
              stgID: goal.stgID,
              goalDescription: getGoalDetails(goal).goalDescription,
              progressLevel: Number(goal.detail.goalBaselinePerformance),
              progressUnit: goal.detail.goalOpportunityUnit,
              progressComparison: goal.detail.goalCues,
            } as GoalProgress),
        );
        content.visitFrequency.frequency = visitFrequency?.frequency ?? null;
        content.visitFrequency.sessionsCount = visitFrequency?.sessionsCount ?? 0;
      });
    }

    await onNoteSubmit(createNote, editNote, submissionAppointment, props, dismissPendingAppointment, { autoSaving });

    setDirty(false);
    setLastAutoSavedAt(dayjs());
    if (!autoSaving) {
      // Reset validation errors as we're closing the form and if
      // it opens again for Edit it shouldn't show the Lock errors
      setLocalValidationErrors(undefined);
      setValidationErrors(undefined);

      setAutoSaveActivityId!(null);
    }
  };

  useInterval(() => {
    // Only some kinds of notes are auto-saved
    if (!isAutoSave) {
      return;
    }

    if (dirty) {
      save({ autoSaving: true });
    }
  }, 10000);

  return (
    <AppointmentNoteFormErrorBoundary>
      <form
        onSubmit={event => {
          event.preventDefault();
          // fix: fast return when the form that triggers this submit are not the same
          if (event.currentTarget !== event.target) {
            return;
          }

          save({ autoSaving: false });
        }}
        tw="mt-6"
        data-testid="appointment-note-form"
      >
        <div tw="px-4">
          <div tw="flex items-center" data-testid="appointment-note-form-note-type">
            <FormGroup>
              <Label htmlFor="notetype" tw="font-semibold text-sm">
                Note
              </Label>
              <div data-testid="noteTypeDropdown" tw="w-44 mt-2 mb-4">
                {!activityId ? (
                  <Select
                    inputId="notetype"
                    isSearchable
                    defaultValue={noteTypeOptions.find(opt => opt.value === activity.note.appointmentNoteType)}
                    options={noteTypeOptions}
                    onChange={(event: { [key: string]: string }) => {
                      let appointment: Appointment;
                      switch (event.value) {
                        case 'free-text':
                          appointment = getInitialFreeTextNote();
                          break;
                        case 'evaluation-note':
                          appointment = getInitialEvaluationNote();
                          break;
                        case 'session-note':
                          appointment = getInitialSessionNote();
                          break;
                        case undefined:
                          appointment = getInitialScreeningNote();
                          break;
                        default:
                          return;
                      }

                      appointment.appointmentOn = activity.appointmentOn;
                      setActivity(appointment);
                    }}
                  />
                ) : (
                  <div tw="text-sm">
                    {noteTypeOptions.find(act => act.value === activity.note.appointmentNoteType)?.label ?? 'SOAP note'}
                  </div>
                )}
              </div>
            </FormGroup>
            {!activityId && lastAppointmentNote && isAppointmentNoteSoap(activity.note) ? (
              <Link
                onClick={() => {
                  const lastSoapActivity = fromEventToSoapActivity(lastAppointmentNote);
                  setActivity({
                    ...lastSoapActivity,
                    appointmentOn: activity.appointmentOn,
                  });
                  return false;
                }}
                to="#"
                tw="ml-3 font-semibold"
              >
                Load Previous Note
              </Link>
            ) : null}
          </div>
          <div tw="flex items-center">
            <FormGroup tw="w-full">
              <Label htmlFor="notetype" tw="font-semibold text-sm">
                Appointment On
              </Label>
              <div tw="flex mt-2 mb-4">
                <div tw="w-1/2 sm:w-[12%] mr-2">
                  <div data-testid="appointmentOn">
                    <SingleDatePicker
                      id="appointmentOn"
                      block
                      small
                      noBorder
                      isOutsideRange={() => false}
                      openDirection="up"
                      numberOfMonths={1}
                      hideKeyboardShortcutsPanel
                      focused={datePickerFocused}
                      onFocusChange={({ focused }) => setDatePickerFocused(focused)}
                      date={moment(activity.appointmentOn)}
                      onDateChange={date => {
                        handleDateTimeChange(date);

                        if (isAutoSave) {
                          setDirty(true);
                        }
                      }}
                    />
                  </div>
                </div>
                <div tw="w-1/2 sm:w-[12%] ml-2">
                  <div data-testid="appointmentTimeDropdown">
                    <Select
                      name="appointment-time-dropdown"
                      value={getSelectedTime(moment(activity.appointmentOn))}
                      options={appointmentTimeOptions}
                      onChange={(e: { [key: string]: number }) => {
                        handleDateTimeChange(null, e.value);

                        if (isAutoSave) {
                          setDirty(true);
                        }
                      }}
                    />
                  </div>
                </div>
                <div tw="flex pt-2 ml-3">
                  {(!activityId && lastSessionNote && isAppointmentNoteSession(activity.note)) ||
                  (lastSessionNote &&
                    Boolean(
                      canLoadPrevious && isAppointmentNoteSession(activity.note) && lastSessionNote.clientNoteID,
                    )) ? (
                    <Link
                      data-testid="appointment-note-form-load-previous-session-note"
                      tw="text-center text-sm"
                      onClick={() => {
                        const lastSessionActivity = fromEventToSessionActivity(lastSessionNote);
                        const currentSessionNoteContent = activity.note.content as SessionNoteContent;
                        const currentDuration = currentSessionNoteContent?.duration;
                        const ageNumberOnly = dayjs().diff(clientData?.dob, 'years');

                        setActivity({
                          ...lastSessionActivity,
                          note: {
                            ...lastSessionActivity.note,
                            content: {
                              ...(lastSessionActivity.note.content as SessionNoteContent),
                              duration: currentDuration ? currentDuration : 30,
                              parentOrCaregiverAttendSession:
                                Number(ageNumberOnly) >= 18 ? 'Not Applicable' : undefined,
                            },
                          } as AppointmentNoteSession,
                          appointmentOn: activity.appointmentOn,
                          cpt: lastSessionActivity.cpt,
                        });
                        return false;
                      }}
                      to="#"
                    >
                      Load Previous Session Note
                    </Link>
                  ) : null}
                </div>
              </div>
            </FormGroup>
          </div>
          {!isScreeningNote(activity.note) && (
            <div tw="flex items-center">
              <FormGroup tw="w-full">
                <Label htmlFor="cpt-dropdown" tw="font-semibold text-sm">
                  CPT
                </Label>
                <div tw="mt-2 mb-4">
                  <div data-testid="cptDropdown">
                    <Select
                      // eslint-disable-next-line
                      styles={{ menuPortal: (base: any) => ({ ...base, zIndex: 50 }) }}
                      menuPortalTarget={document.body}
                      name="cpt-dropdown"
                      value={activity.cpt}
                      options={cptCodes}
                      isSearchable
                      isMulti={isAppointmentNoteSession(activity.note)}
                      tw="w-full"
                      getOptionLabel={formatCptCode}
                      getOptionValue={(x: CptCode) => x.code}
                      onChange={(value: CptCode) => {
                        if (!isAppointmentNoteSession(activity.note)) {
                          setActivity({ ...activity, cpt: [value] });
                        } else {
                          setActivity({ ...activity, cpt: value as unknown as CptCode[] });
                        }
                        if (isAutoSave) {
                          setDirty(true);
                        }
                      }}
                      ref={cptValidationsRegister.ref}
                      error={cptValidationsRegister.error || cptError}
                    />
                  </div>
                </div>
              </FormGroup>
            </div>
          )}
          {noteContentSection}
        </div>
        <SaveToolbar
          loading={
            dismissPendingAppointmentMutation.isLoading || editNoteMutation.isLoading || createNoteMutation.isLoading
          }
          isAutoSave={isAutoSave}
          lastAutoSavedAt={lastAutoSavedAt}
        />
      </form>
    </AppointmentNoteFormErrorBoundary>
  );
};
