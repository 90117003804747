import React, { useCallback, useEffect, useMemo } from 'react';
import {
  Button,
  CloseButton,
  FormGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  presentMutationError,
} from '@expressable/ui-library';
import ModalHeadline from 'components/modal-headline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormGroupTitle from 'components/form-group-title';
import { faCalendarTimes } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import RHFSelect from 'components/RHFSelectBase';
import { useCancelPendingReschedule } from 'hooks/use-pending-reschedule';
import { EntryId, useContentfulEntry } from 'hooks/use-contentful';
import { ContentfulDropdownContent } from 'types';
import { convertStringArrayToSelectOptions } from 'domain/notes/mapHelpers';
import { CancelPendingRescheduleProps } from 'domain/pending-reschedule/types';
import 'twin.macro';

export function CancelPendingRescheduleModal(props: CancelPendingRescheduleProps) {
  const { isOpen, onClose, currentPendingReschedule } = props;

  const cancellationReasonEntry = useContentfulEntry<ContentfulDropdownContent>({
    entryId: EntryId.PendingRescheduleCancelationReasons,
  });
  const cancellationReasonOptions = useMemo(() => {
    if (!cancellationReasonEntry?.data?.dropdownContent) return [];
    else return convertStringArrayToSelectOptions(cancellationReasonEntry?.data?.dropdownContent);
  }, [cancellationReasonEntry]);

  const {
    handleSubmit,
    watch,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      cancellationReason: '',
    },
  });
  const cancelPendingRescheduleMutation = useCancelPendingReschedule();
  const { mutate: cancelPendingReschedule } = cancelPendingRescheduleMutation;
  const selectedCancelationReason = watch('cancellationReason');

  const onSubmitUnassignment = async () => {
    cancelPendingReschedule(
      {
        displayToast: true,
        clientID: currentPendingReschedule?.clientID,
        pendingRescheduleID: currentPendingReschedule?.pendingRescheduleID,
        cancellationReason: selectedCancelationReason,
      },
      {
        onSuccess: () => {
          {
            onClose();
          }
        },
        onError: presentMutationError,
      },
    );
  };
  const resetModalState = useCallback(() => reset(), []);
  useEffect(resetModalState, [isOpen, cancelPendingRescheduleMutation.isLoading]);

  return (
    <Modal isOpen={isOpen} tw="max-w-lg">
      <ModalContent>
        <ModalHeader>
          <div tw="absolute top-0 right-0 pt-4 pr-4">
            <CloseButton testId="close-cancel-pending-reschedule-modal" onClick={onClose} />
          </div>
        </ModalHeader>
        <ModalBody>
          <React.Fragment>
            <div tw="flex items-center justify-center w-12 h-12 mx-auto bg-indigo-100 rounded-full">
              <FontAwesomeIcon tw="text-2xl text-indigo-700" icon={faCalendarTimes} />
            </div>
            <div tw="my-3 sm:mt-5">
              <ModalHeadline
                text={`Cancel Pending Reschedule for ${currentPendingReschedule?.clientFirstName} ${currentPendingReschedule?.clientLastName}`}
              />
              <form
                tw="mt-5"
                data-testid="cancel-pending-reschedule-form"
                onSubmit={handleSubmit(onSubmitUnassignment)}
              >
                <FormGroup data-testid="reason-for-unassign" tw="mt-8">
                  <FormGroupTitle title="Cancelation Reason" fontSize="small" fontWeight="semi" spacing="normal" />
                  <RHFSelect
                    name="cancellationReason"
                    control={control}
                    required
                    options={cancellationReasonOptions ?? []}
                    error={errors?.cancellationReason}
                  />
                </FormGroup>
                <FormGroup tw="mt-4">
                  <Button
                    tw="mt-3"
                    data-testid="cancel-pending-reschedule-button"
                    variant="primary"
                    fullWidth
                    loading={cancelPendingRescheduleMutation.isLoading}
                  >
                    Cancel Pending Reschedule
                  </Button>
                  <Button
                    tw="mt-3"
                    data-testid="cancel-pending-reschedule-onclose"
                    variant="secondary"
                    fullWidth
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                </FormGroup>
              </form>
            </div>
          </React.Fragment>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
