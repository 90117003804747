/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  Link,
  Loader,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  useDisclosure,
} from '@expressable/ui-library';
import { Portal, useViewport } from '@expressable/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useClient } from 'hooks/use-client';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Spring, animated } from 'react-spring/renderprops';
import tw from 'twin.macro';
import {
  Activity,
  Event,
  EventConverterType,
  ICompleteClientInformation,
  NoteConverterType,
  PendingAppointment,
  SelectOption,
  serverValidationErrors,
} from 'types';
import Sidebar from './client-sidebar';

import { LogAttendanceModal } from 'components/client-attendance-modals/log-attendance-modal';
import { LogCancelationModal } from 'components/client-attendance-modals/log-cancelation-modal';
import { LogDismissAttendanceModal } from 'components/client-attendance-modals/log-dismiss-attendance-modal';
import { LogNoShowModal } from 'components/client-attendance-modals/log-no-show-modal';
import LogRescheduleModal from 'components/client-attendance-modals/log-reschedule-modal';
import {
  NoteFormProps,
  areAdminNoteFormProps,
  areAppointmentNoteFormProps,
  areChartNoteFormProps,
  areDischargeNoteFormProps,
  isAdminNote,
  isAppointmentNote,
  isAppointmentNoteEvaluation,
  isAppointmentNoteSession,
  isChartNote,
  isDischargeNote,
} from 'guards';
import useLocalStorage from 'hooks/common/use-local-storage';
import useClientActivity from 'hooks/use-client-activity';
import { getNote } from 'hooks/use-notes';
import usePendingAppointments from 'hooks/use-pending-appointments';
import usePermissions from 'hooks/use-permissions';
import { useFlags } from 'launchdarkly-react-client-sdk';
import moment from 'moment';
import eventRender, { isEventSupported } from 'utils/event-render';
import {
  AdminNoteForm,
  AppointmentNoteForm,
  ChartNoteForm,
  getInitialAdminNote,
  getInitialChartNote,
  getInitialDischargeNote,
  getInitialEvaluationNote,
  getInitialEvaluationNote3,
  getInitialSessionNote,
} from '../components/client-notes';
import {
  fromEventToAdminActivity,
  fromEventToChartActivity,
  fromEventToEvaluationActivity,
  fromEventToFreeTextActivity,
  fromEventToScreeningActivity,
  fromEventToSoapActivity,
  fromNoteToDischargeActivity,
  fromNoteToEvaluationActivity,
  fromNoteToSessionActivity,
} from './client-notes/converters';
import { DischargeNoteForm } from './client-notes/discharge-note/discharge-note-form';
import { EvaluationNote3Form } from './client-notes/evaluation-note-3-form';
import PendingAppointmentCard from './client-pending-appointment';
import CheckboxSelectOption from './common/CheckboxSelectOption';
import { ValueContainer } from './common/SelectValueContainer';
import { ClientTab } from '../client';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { ACTIVITY_FILTER_OPTIONS } from 'domain/client/constants';
import { uniqBy } from 'lodash';

export interface ClientOverviewProps {
  clientId: string;
  setIndex: React.Dispatch<React.SetStateAction<ClientTab>>;
}

const renderNoteComponent = (
  props: NoteFormProps,
  lastAppointmentNote: Event | undefined,
  clientData: ICompleteClientInformation | undefined,
  lastSessionNote?: Event,
  canLoadPrevious?: boolean,
) => {
  if (areChartNoteFormProps(props)) {
    return <ChartNoteForm {...props} />;
  }

  if (areAdminNoteFormProps(props)) {
    // @ts-ignore @TODO fix implicit types calculated for props
    return <AdminNoteForm props={{ ...props }} clientData={clientData} />;
  }

  if (areAppointmentNoteFormProps(props)) {
    if ((props as any).activity.version === '3.0') {
      return (
        <EvaluationNote3Form
          // @ts-ignore @TODO fix implicit types calculated for props
          {...props}
          lastAppointmentNote={lastAppointmentNote}
          lastSessionNote={lastSessionNote}
          clientData={clientData}
          canLoadPrevious={canLoadPrevious}
        />
      );
    } else {
      return (
        <AppointmentNoteForm
          // @ts-ignore @TODO fix implicit types calculated for props
          {...props}
          lastAppointmentNote={lastAppointmentNote}
          lastSessionNote={lastSessionNote}
          clientData={clientData}
          canLoadPrevious={canLoadPrevious}
        />
      );
    }
  }

  if (areDischargeNoteFormProps(props)) {
    return (
      <DischargeNoteForm
        // @ts-ignore @TODO fix implicit types calculated for props
        {...props}
        lastAppointmentNote={lastAppointmentNote}
        lastSessionNote={lastSessionNote}
        clientData={clientData}
        canLoadPrevious={canLoadPrevious}
      />
    );
  }
};

const FilterCounting = ({
  filteredActivityFeed,
  activityFeed,
  filters,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filteredActivityFeed?: Event<{ [key: string]: any }>[];
  activityFeed?: Event<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  }>[];
  filters: {
    value: string[];
    label: string;
    selected: boolean;
  }[];
}) => {
  const areFiltersIncluded = (a: Event<{ [key: string]: unknown }> | undefined) => {
    // filter the activities that are part of the ACTIVITY_FILTER_OPTIONS
    const filterValues = ACTIVITY_FILTER_OPTIONS.map((option: SelectOption<string[]>) => option.value).flat();
    return filterValues.includes(a?.eventType as unknown as string);
  };

  const feedSet = new Set(activityFeed?.map(e => e.eventID));

  // count events that are supported
  const filteredActivityFeedLength = filteredActivityFeed?.filter(e => feedSet.has(e.eventID) && isEventSupported(e.eventType))?.length ?? 0;
  const supportedActivityFeedEventsLength = activityFeed?.filter(e => isEventSupported(e?.eventType))?.length ?? 0;
  const activitiesFilterIncludedAndEventsSupportedLength =
    filteredActivityFeed?.filter(e => areFiltersIncluded(e) && isEventSupported(e.eventType))?.length ?? 0;

  return (
    <span tw="text-sm">
      {`Showing ${
        !filteredActivityFeedLength && filters.length <= 0
          ? supportedActivityFeedEventsLength
          : filteredActivityFeedLength
      }`}
      {`${activitiesFilterIncludedAndEventsSupportedLength <= 0 ? ' events' : ' '}`}

      {Boolean(activitiesFilterIncludedAndEventsSupportedLength) &&
        `out of ${supportedActivityFeedEventsLength} events`}
    </span>
  );
};

const ACTIVITY_FEED_INITIAL_STATE = {
  items: [],
};

export default function ClientOverview({ setIndex, clientId }: ClientOverviewProps) {
  const breakpoint = 768;
  const [activity, setActivity] = useState<Activity | null>(null);
  const [activityId, setActivityId] = useState<string | null>(null);
  const [acuityId, setAcuityId] = useState<string | null>(null);
  const [showNoteForm, setShowNoteForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [canLoadPrevious, setCanLoadPrevious] = useState(false);
  const [validationErrors, setValidationErrors] = useState<serverValidationErrors | undefined>();
  const [portalContainer, setPortalContainer] = useState<HTMLDivElement | null>(null);
  const [eventRenderersArray, setEventRenderersArray] = useState<Array<unknown>>([]);

  const { data: feedData, isLoading, hasNextPage, fetchNextPage } = useClientActivity(clientId);

  const activityFeed = useMemo(() => {
    if (!isLoading) {
      return {
        items: uniqBy([...ACTIVITY_FEED_INITIAL_STATE.items, ...(feedData?.pages.flatMap( page => page.items) ?? [])], 'eventID'),
      };
    }
    return ACTIVITY_FEED_INITIAL_STATE;
  }, [feedData, isLoading]);

  const { data: pendingAppointmentData = [] } = usePendingAppointments(clientId);
  const lastAppointmentNote = activityFeed?.items?.find(event => event.eventType === 'soap-note');
  const [lsOverviewFilter, setLsOverviewFilters] = useLocalStorage('client_overview_filters', []);
  const [activityFilters, setActivityFilters] = useState<SelectOption<string[]>[]>(lsOverviewFilter);
  const {
    addAppointmentNote: isAddAppointmentNoteEnabled,
    dischargeButton: isDischargeButtonEnabled,
  } = useFlags();

  const onLoadMoreResultsHandler = () => {
    if (hasNextPage) {
        fetchNextPage();
    }
  };

  const onChangeActivityFilter = (v: SelectOption<string[]>[]) => {
    setActivityFilters(v);
    setLsOverviewFilters(v);
  };

  const filtersWithLenghtOptions = ACTIVITY_FILTER_OPTIONS.map(option => {
    const activities = activityFeed?.items.filter(
      event => isEventSupported(event.eventType) && option.value.includes(event.eventType),
    );
    return {
      value: option.value,
      label: `${option.label} (${activities?.length || 0})`,
      selected: lsOverviewFilter?.some((filter: SelectOption<string[]>) => {
        return filter.value.length === option.value.length && filter.value.every(value => option.value.includes(value));
      }),
    };
  }).sort((e, a) => e.label.localeCompare(a.label));

  const selectedFilterValues = filtersWithLenghtOptions.filter(filter => filter.selected);

  const filteredActivityFeed = activityFeed?.items.filter(a => {
    const filterValues = activityFilters.map(f => f.value).flat();
    return filterValues.includes(a.eventType) && isEventSupported(a.eventType);
  });

  // handler for select all options in the filters
  const onSelectAllActivityFilters = () => {
    onChangeActivityFilter(ACTIVITY_FILTER_OPTIONS);
  };

  const isAllFiltersSelectedClasses =
    activityFilters.length === ACTIVITY_FILTER_OPTIONS.length
      ? tw`text-sm text-indigo-400  font-normal cursor-pointer`
      : tw`text-sm text-indigo-600  font-normal cursor-pointer`;

  const lastSessionNote = activityFeed?.items
    ?.filter(
      evt =>
        evt.eventType === 'session-note' &&
        evt.eventID !== activityId && // don't show the current session note
        evt.detail?.isEmptyState !== undefined &&
        evt.detail?.isEmptyState === false, // only the session note that are not an empty state (undocummented)
    )
    .reduce((latest, event) => {
      // find the most recent createdOn session note
      if (!latest?.createdOn) return event;
      if (moment(event.createdOn).isAfter(moment(latest.createdOn))) return event;

      return latest;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }, {} as any);

  const { data: clientData } = useClient(clientId);
  const [autoSaveActivityId, setAutoSaveActivityId] = useState<string | null>(null);
  const { isAdminOrClinicalManager } = usePermissions();
  const [selectedPendingAppointment, setSelectedPendingAppointment] = useState<PendingAppointment | null>(null);

  const {
    isOpen: isLogNoShowModalOpen,
    onClose: onCloseLogNoShowModal,
    onOpen: onOpenLogNoShowModal,
  } = useDisclosure();

  const {
    isOpen: isLogDeleteSessionModalOpen,
    onClose: onCloseLogDeleteSessionModal,
    onOpen: onOpenLogDeleteSessionModal,
  } = useDisclosure();
  const {
    isOpen: isLogCancelationModalOpen,
    onClose: onCloseLogCancelationModal,
    onOpen: onOpenLogCancelationModal,
  } = useDisclosure();

  const {
    isOpen: isRescheduleAppointmentModalOpen,
    onClose: onCloseRescheduleAppointmentModal,
    onOpen: onOpenRescheduleAppointmentModal,
  } = useDisclosure();

  const {
    isOpen: isLogAttendanceModalOpen,
    onClose: onLogAttendanceModalClose,
    onOpen: onLogAttendanceModalOpen,
  } = useDisclosure();

  const onAddNote = (note: Activity, acuityId?: string) => {
    setActivity(note);
    setActivityId(null);
    setShowNoteForm(true);
    setShowEditForm(false);
    acuityId && setAcuityId(acuityId);
    focusElementRef.current?.focus();
  };

  type EvaluationNoteType = {
    version: string;
    detail: {
      duration: number;
    };
  };

  const getInitialNote = (eventType: string, note?: unknown) => {
    if (eventType === 'session-note') {
      return getInitialSessionNote(note);
    } else if (eventType === 'evaluation-note' && (note as EvaluationNoteType).version == '2.0') {
      return getInitialEvaluationNote(note);
    } else if (eventType === 'evaluation-note' && (note as EvaluationNoteType).version == '3.0') {
      return getInitialEvaluationNote3(note);
    }
    return null;
  };

  const onEditNote = async (id: string, ref: HTMLDivElement, validationErrors?: serverValidationErrors) => {
    const event = activityFeed!.items.find(({ eventID }) => eventID === id)!;
    const eventConverter = {
      'chart-note': fromEventToChartActivity,
      'admin-note': fromEventToAdminActivity,
      'appointment-note': fromEventToFreeTextActivity,
      'soap-note': fromEventToSoapActivity,
      'evaluation-note': fromEventToEvaluationActivity,
      'screening-note': fromEventToScreeningActivity,
    };
    // We are going to move gradually towards using note converters for all note types
    const noteConverter = {
      'evaluation-note': fromNoteToEvaluationActivity,
      'session-note': fromNoteToSessionActivity,
      'discharge-note': fromNoteToDischargeActivity,
    };

    // If both converters are defined for an eventType, we will try to load
    // the note by clientID + eventID and convert it using its noteConverter.
    let note;
    if (noteConverter[event.eventType as NoteConverterType] && eventConverter[event.eventType as EventConverterType]) {
      if (event.clientNoteID) {
        note = await getNote(event.clientID, event.eventID);
      }
    } else if (noteConverter[event.eventType as NoteConverterType]) {
      note = await getNote(event.clientID, event.eventID);
    }
    const activityToEdit = note
      ? noteConverter[event.eventType as NoteConverterType](note)
      : eventConverter[event.eventType as EventConverterType](event);
    const emptyNote = getInitialNote(event.eventType as NoteConverterType, note);

    setCanLoadPrevious(Boolean(note?.detail?.isEmptyState));
    setActivity(note?.detail?.isEmptyState ? emptyNote : activityToEdit);
    setActivityId(id);
    setPortalContainer(ref);
    setShowEditForm(true);
    setValidationErrors(validationErrors);
    setShowNoteForm(false);
  };

  const onNoShowPendingAppointment = (acuityId: string, pendingAppointment: PendingAppointment) => {
    onOpenLogNoShowModal();
    setSelectedPendingAppointment(pendingAppointment);
  };

  const onCancelationPendingAppointment = (acuityId: string, pendingAppointment: PendingAppointment) => {
    onOpenLogCancelationModal();
    setSelectedPendingAppointment(pendingAppointment);
  };

  const onClientAttendedAppointment = (acuityId: string, pendingAppointment: PendingAppointment) => {
    onLogAttendanceModalOpen();
    setSelectedPendingAppointment(pendingAppointment);
  };

  const onReschedulePendingAppointment = (acuityId: string, pendingAppointment: PendingAppointment) => {
    onOpenRescheduleAppointmentModal();
    setSelectedPendingAppointment(pendingAppointment);
  };

  const onDismissPendingAppointment = (acuityId: string, pendingAppointment: PendingAppointment) => {
    onOpenLogDeleteSessionModal();
    setSelectedPendingAppointment(pendingAppointment);
  };

  const onSwitchToRescheduleModal = () => {
    onCloseLogCancelationModal();
    onOpenRescheduleAppointmentModal();
  };

  const getRenderComponents = (activityFeed: Array<Event>) => {
    const isBeingEdited = (event: Event) => event.eventID === activityId;
    const isNotAutoSaveNote = (event: Event) => event.eventID !== autoSaveActivityId;

    const callEventRender = async (event: Event, index: number): Promise<React.ReactNode> => {
      return await eventRender(event, onEditNote, isBeingEdited(event), index === activityFeed.length - 1, index);
    };

    return Promise.all(activityFeed.filter(isNotAutoSaveNote).map(callEventRender));
  };

  useEffect(() => {
    if (filteredActivityFeed) {
      let activities = !activityFilters.length ? activityFeed.items : filteredActivityFeed;

      if (selectedFilterValues.length <= 0) activities = activityFeed.items;
      else activities = filteredActivityFeed;

      getRenderComponents(
        (activities as Event[]).sort((a, b) => {
          return new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime();
        }),
      ).then(data => {
        setEventRenderersArray(data);
      });
    }
  }, [
    filteredActivityFeed?.length,
    activityId,
    selectedFilterValues.length,
    activityFeed.items,
  ]);

  const focusElementRef = useRef<HTMLTextAreaElement | null>(null);
  const { viewportWidth } = useViewport();

  const isAutoSave =
    activity?.noteType === 'appointment' &&
    (isAppointmentNoteEvaluation(activity?.note) || isAppointmentNoteSession(activity?.note));

  const MobileBtnMenu = () => (
    <Menu tw="ml-4">
      <MenuButton tw="border-gray-200" variant="secondary">
        Add Note
        <FontAwesomeIcon tw="text-gray-700 text-sm ml-2" icon="caret-down" />
      </MenuButton>
      <MenuList tw="z-10">
        <MenuItem onClick={() => onAddNote(getInitialChartNote())}>Chart Note</MenuItem>
        <MenuItem onClick={() => onAddNote(getInitialAdminNote())}>Admin Note</MenuItem>
        {isAddAppointmentNoteEnabled && (
          <>
            <MenuItem onClick={() => onAddNote(getInitialSessionNote())}>Appointment Note</MenuItem>
            <MenuItem onClick={() => onAddNote(getInitialEvaluationNote3())}>Standardized Evaluation</MenuItem>
          </>
        )}
        {isDischargeButtonEnabled && clientData?.status === 'Active' && (
          <MenuItem onClick={() => onAddNote(getInitialDischargeNote())}>Discharge</MenuItem>
        )}
      </MenuList>
    </Menu>
  );

  const getAddFormTitle = (activity: Activity | null) => {
    if (isChartNote(activity)) {
      return 'Add Chart Note';
    }

    if (isAdminNote(activity)) {
      return 'Add Admin Note';
    }

    if (
      isAppointmentNote(activity) &&
      activity.note.appointmentNoteType === 'evaluation-note' &&
      activity.version === '3.0'
    ) {
      return 'New Evaluation Note';
    }

    if (isDischargeNote(activity)) {
      return 'New Discharge Note';
    }

    return 'New Appointment Note';
  };

  const getEditFormTitle = (activity: Activity | null) => {
    if (isChartNote(activity)) {
      return 'Edit Chart Note';
    }

    if (isAdminNote(activity)) {
      return 'Edit Admin Note';
    }

    if (
      isAppointmentNote(activity) &&
      activity.note.appointmentNoteType === 'evaluation-note' &&
      activity.version === '3.0'
    ) {
      return 'Edit Evaluation Note';
    }

    if (isDischargeNote(activity)) {
      return 'Edit Discharge Note';
    }

    return 'Edit Appointment Note';
  };

  return (
    <div data-testid="client-overview-component">
      {selectedPendingAppointment && (
        <>
          {isLogNoShowModalOpen && (
            <LogNoShowModal
              isOpen={isLogNoShowModalOpen}
              onClose={onCloseLogNoShowModal}
              activity={selectedPendingAppointment}
              clientId={clientId}
            />
          )}

          {isLogDeleteSessionModalOpen && (
            <LogDismissAttendanceModal
              isOpen={isLogDeleteSessionModalOpen}
              onClose={onCloseLogDeleteSessionModal}
              activity={selectedPendingAppointment}
            />
          )}

          {isLogCancelationModalOpen && (
            <LogCancelationModal
              isOpen={isLogCancelationModalOpen}
              onClose={onCloseLogCancelationModal}
              activity={selectedPendingAppointment}
              onRescheduleSwitch={onSwitchToRescheduleModal}
              clientId={clientId}
              isSidebarAppointment={false}
            />
          )}

          {isRescheduleAppointmentModalOpen && (
            <LogRescheduleModal
              isOpen={isRescheduleAppointmentModalOpen}
              onClose={onCloseRescheduleAppointmentModal}
              activity={selectedPendingAppointment}
            />
          )}

          {isLogAttendanceModalOpen && (
            <LogAttendanceModal
              isOpen={isLogAttendanceModalOpen}
              onClose={onLogAttendanceModalClose}
              activity={selectedPendingAppointment}
              clientId={clientId}
            />
          )}
        </>
      )}
      <div tw="md:flex">
        <div tw="md:w-3/4 md:pr-5">
          {pendingAppointmentData?.length > 0 && (
            <div data-testid="pending-appointment-card" tw="mb-12">
              {pendingAppointmentData
                .sort((a: PendingAppointment, b: PendingAppointment) => {
                  const aDate = new Date(a.appointmentDateTime);
                  const bDate = new Date(b.appointmentDateTime);
                  return bDate.getTime() - aDate.getTime();
                })
                .map((pendingAppointment: PendingAppointment) => (
                  <PendingAppointmentCard
                    key={pendingAppointment.acuityAppointmentID}
                    pendingAppointment={pendingAppointment}
                    onReschedule={() =>
                      onReschedulePendingAppointment(pendingAppointment.acuityAppointmentID, pendingAppointment)
                    }
                    onCancel={() =>
                      onCancelationPendingAppointment(pendingAppointment.acuityAppointmentID, pendingAppointment)
                    }
                    onNoShow={() =>
                      onNoShowPendingAppointment(pendingAppointment.acuityAppointmentID, pendingAppointment)
                    }
                    onDismiss={() => {
                      onDismissPendingAppointment(pendingAppointment.acuityAppointmentID, pendingAppointment);
                    }}
                    onClientAttended={() => {
                      onClientAttendedAppointment(pendingAppointment.acuityAppointmentID, pendingAppointment);
                    }}
                    canDismissAppointment={isAdminOrClinicalManager}
                  />
                ))}
            </div>
          )}
          <div data-testid="client-overview" tw="flex justify-between items-center flex-wrap">
            <span tw="font-semibold mr-4 text-2xl text-center order-1 md:order-none mb-0 md:mb-2">Activity</span>
            <div tw="order-2 md:order-none inline-flex flex-auto items-center flex-col md:flex-row">
              <div tw="flex items-center w-full justify-items-stretch mt-11 md:mt-0">
                <span tw="text-sm font-semibold flex-none mr-2 ml-2 sm:ml-3">View only</span>
                <Select
                  tw="w-full md:w-80"
                  components={{
                    Option: CheckboxSelectOption,
                    ValueContainer: ValueContainer,
                  }}
                  isMulti
                  value={selectedFilterValues}
                  onChange={onChangeActivityFilter}
                  defaultValue={selectedFilterValues}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  options={filtersWithLenghtOptions}
                  placeholder="Select Event Types"
                  spacing="tight"
                />
              </div>
              <div tw="flex-none w-full mt-7 md:mt-0 md:ml-4 ml-5">
                <span onClick={() => onSelectAllActivityFilters()} css={[isAllFiltersSelectedClasses]}>
                  View all activities
                </span>
                {hasNextPage&& (
                  <span tw="ml-4 text-indigo-600 text-sm cursor-pointer" onClick={() => onLoadMoreResultsHandler()}>
                    Load more results
                  </span>
                )}
              </div>
            </div>
            <div tw="order-1 md:order-none">
              {!showNoteForm && <div>{viewportWidth < breakpoint && <MobileBtnMenu />}</div>}
            </div>
          </div>
          <div>
            {!showNoteForm && viewportWidth > breakpoint && (
              <>
                <div tw="mt-6 flex gap-4 flex-wrap items-center">
                  <Button
                    data-testid="client-overview-add-chart-note"
                    variant="secondary"
                    onClick={() => onAddNote(getInitialChartNote())}
                  >
                    Add Chart Note
                  </Button>
                  <Button
                    data-testid="client-overview-add-admin-note"
                    variant="secondary"
                    onClick={() => onAddNote(getInitialAdminNote())}
                  >
                    Add Admin Note
                  </Button>
                  {isAddAppointmentNoteEnabled && (
                    <>
                      <Button
                        data-testid="addAppointmentNote"
                        variant="secondary"
                        onClick={() => onAddNote(getInitialSessionNote())}
                      >
                        Add Appointment Note
                      </Button>
                      <Button
                        data-testid="addEvaluationNote3"
                        variant="secondary"
                        onClick={() => onAddNote(getInitialEvaluationNote3())}
                      >
                        Add Standardized Evaluation
                      </Button>
                    </>
                  )}
                  {isDischargeButtonEnabled && clientData?.status === 'Active' && (
                    <Button
                      data-testid="addDischargeNote"
                      variant="secondary"
                      onClick={() => onAddNote(getInitialDischargeNote())}
                    >
                      Add Discharge Note
                    </Button>
                  )}
                </div>
              </>
            )}
          </div>
          <Spring
            config={{ friction: 60, tension: 700 }}
            native
            to={{
              opacity: showNoteForm ? 1 : 0,
              transform: showNoteForm ? 'scaleY(1)' : 'scaleY(0)',
              height: showNoteForm ? 'auto' : 0,
            }}
          >
            {animatedProps => (
              //don't hide the overflow on an expanded form
              <animated.div style={{ ...animatedProps }}>
                <div tw="mt-6 bg-white pt-6 rounded-lg shadow">
                  <div tw="px-4">
                    <span tw="font-semibold text-lg">{getAddFormTitle(activity)}</span>
                    {!isAutoSave && (
                      <Link
                        tw="ml-6 cursor-pointer"
                        onClick={async () => {
                          setShowNoteForm(false);
                          setActivity(null);
                          setActivityId(null);
                        }}
                        to="#"
                        href="#!"
                      >
                        Cancel Note
                      </Link>
                    )}
                  </div>
                  {showNoteForm &&
                    renderNoteComponent(
                      {
                        activity,
                        activityId,
                        clientId,
                        focusElementRef,
                        setActivity,
                        setActivityId,
                        setShowNoteForm,
                        acuityId,
                        setAcuityId,
                        setAutoSaveActivityId,
                        setValidationErrors,
                      },
                      lastAppointmentNote,
                      clientData,
                      lastSessionNote,
                      canLoadPrevious,
                    )}
                </div>
              </animated.div>
            )}
          </Spring>
          <div tw="mt-2 mb-2">
            <FilterCounting
              filteredActivityFeed={filteredActivityFeed}
              activityFeed={activityFeed?.items}
              filters={selectedFilterValues}
            />
          </div>
          {isLoading && (
            <div>
              <Loader type="ring" />
            </div>
          )}
          {!isLoading && eventRenderersArray}
        </div>
        {clientData && (
          <div tw="md:w-1/4 px-5">
            <Sidebar
              setIndex={setIndex}
              clientId={clientId}
              {...clientData}
              onAddDischarge={() => onAddNote(getInitialDischargeNote())}
            />
          </div>
        )}
      </div>
      {showEditForm && (
        <Portal portalContainer={portalContainer}>
          <div tw="w-full">
            <div tw="px-4">
              <span tw="font-semibold text-lg">{getEditFormTitle(activity)}</span>
              {!isAutoSave && (
                <Link
                  data-testid="appointmentNoteCancelEdit"
                  tw="ml-6 cursor-pointer"
                  onClick={() => {
                    setShowEditForm(false);
                    setPortalContainer(null);
                    setActivity(null);
                    setActivityId(null);
                    setValidationErrors(undefined);
                  }}
                  to="#"
                  href="#!"
                >
                  Cancel Edit
                </Link>
              )}
            </div>
            {isChartNote(activity) && (
              <ChartNoteForm
                {...{
                  activity,
                  activityId,
                  clientId,
                  setActivity,
                  setActivityId,
                  setShowEditForm,
                  setAutoSaveActivityId,
                }}
              />
            )}
            {isAdminNote(activity) && (
              <AdminNoteForm
                props={{
                  ...{
                    activity,
                    activityId,
                    clientId,
                    setActivity,
                    setActivityId,
                    setShowEditForm,
                    setAutoSaveActivityId,
                  },
                }}
                clientData={clientData!}
              />
            )}
            {isAppointmentNote(activity) &&
              (activity.version === '3.0' ? (
                <EvaluationNote3Form
                  {...{
                    activity,
                    activityId,
                    clientId,
                    setActivity,
                    setActivityId,
                    setShowEditForm,
                    setAutoSaveActivityId,
                  }}
                  remoteValidationErrors={validationErrors}
                  setValidationErrors={setValidationErrors}
                  lastAppointmentNote={lastAppointmentNote}
                  clientData={clientData}
                  lastSessionNote={lastSessionNote}
                  canLoadPrevious={canLoadPrevious}
                />
              ) : (
                <AppointmentNoteForm
                  {...{
                    activity,
                    activityId,
                    clientId,
                    setActivity,
                    setActivityId,
                    setShowEditForm,
                    setAutoSaveActivityId,
                  }}
                  remoteValidationErrors={validationErrors}
                  setValidationErrors={setValidationErrors}
                  lastAppointmentNote={lastAppointmentNote}
                  clientData={clientData}
                  lastSessionNote={lastSessionNote}
                  canLoadPrevious={canLoadPrevious}
                />
              ))}
            {isDischargeNote(activity) && (
              <DischargeNoteForm
                {...{
                  activity,
                  activityId,
                  clientId,
                  setActivity,
                  setActivityId,
                  setShowEditForm,
                  setAutoSaveActivityId,
                }}
                remoteValidationErrors={validationErrors}
                setValidationErrors={setValidationErrors}
                lastAppointmentNote={lastAppointmentNote}
                clientData={clientData}
                lastSessionNote={lastSessionNote}
                canLoadPrevious={canLoadPrevious}
              />
            )}
          </div>
        </Portal>
      )}

      {hasNextPage && (
        <div tw="mt-10 text-center">
          <FontAwesomeIcon icon={faAngleDown} tw="text-base mr-2 text-indigo-700" />
          <span onClick={() => onLoadMoreResultsHandler()} tw="font-semibold text-indigo-700 cursor-pointer">
            Load more results
          </span>
        </div>
      )}
    </div>
  );
}
