import { DisplayTimezoneProvider } from "hooks/common/useDisplayTimezone";
import { TIMEZONE_OPTIONS } from "hooks/common/useDisplayTimezone/options";
import {  ClientMatchingUI } from "./client-matching-ui";
import { ClientTab } from "pages/client/client";


type Props = {
  clientId: string;
  setTab: React.Dispatch<React.SetStateAction<ClientTab>>;
};

export const ClientMatchingPage = (props: Props) => (
  <DisplayTimezoneProvider defaultTimezone={TIMEZONE_OPTIONS.CENTRAL}>
    <ClientMatchingUI {...props} />
  </DisplayTimezoneProvider>
);
