import { Menu, MenuItem, MenuLink, MenuList } from '@expressable/ui-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Moment } from 'moment';
import { displayTimezoneOptions } from './appointment-modals';
import React from 'react';
import 'twin.macro';

export type Props = {
  displayTimezone: Record<string, string>;
  setDisplayTimezone: React.Dispatch<React.SetStateAction<Record<string, string>>>;
  date: Moment | null;
  setDate: React.Dispatch<React.SetStateAction<Moment | null>>;
  disabled?: boolean;
};
export default function TimezoneMenu({ disabled, date, setDate, displayTimezone, setDisplayTimezone }: Props) {
  if (disabled) {
    return (
      <div tw="text-sm text-center text-gray-500 leading-5 mt-2">
        Times are shown in - <span tw="font-semibold">{displayTimezone?.label} Time Zone</span>
      </div>
    );
  }
  return (
    <div tw="mt-2" data-testid="timezone-menu">
      <div tw="text-sm text-center text-gray-500 leading-5">
        Times are shown in -
        <Menu
          onChange={menuItem => {
            const selectedMenuItem = menuItem as Record<string, string>;
            setDisplayTimezone(selectedMenuItem);
            if (date && selectedMenuItem) {
              //true at the end of tz preserve the time already picked up
              setDate(date.tz(selectedMenuItem.value, true));
            }
          }}
          tw="px-1 inline"
        >
          <MenuLink to="#" data-testid="add-appointments-time-zone">
            {displayTimezone?.label} Time Zone
            <FontAwesomeIcon tw="text-sm ml-1" icon="caret-down" />
          </MenuLink>
          <MenuList tw="w-full" data-testid="time-zone-options">
            {displayTimezoneOptions.map((option, index) => (
              <MenuItem value={option} key={index}>
                {option.label}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </div>
    </div>
  );
}
