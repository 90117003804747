import 'twin.macro';

import ModalHeadline from 'components/modal-headline';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useEffect, useState } from 'react';

import { Label, Modal, ModalContent, ModalHeader } from '@expressable/ui-library';

import MfaEnableAppAuthModal from './mfa-enable-app-auth';
import MfaEnableSmsAuthModal from './mfa-enable-sms-auth';

import { MfaType, DEFAULT_MFA_TYPE } from 'domain/therapist/constants';

export interface MfaEnableModalProps {
  isOpen: boolean;
  onClose: () => void;
  therapistEmail: string;
}

const AuthenticationMethodDropdown: React.FC<{
  value: MfaType;
  onChange: React.Dispatch<React.SetStateAction<MfaType>>;
}> = ({ value, onChange }) => {
  const { cueMfaSms: isSmsAvailable } = useFlags();

  useEffect(() => {
    if (!isSmsAvailable && value === 'sms') {
      onChange('app');
    }
  }, [isSmsAvailable, value, onChange]);

  return (
    <div tw="flex justify-center mt-3 mb-1 space-x-2">
      <Label tw="font-medium">Method:</Label>
      <select
        value={value}
        onChange={e => onChange(e.target.value as MfaType)}
        disabled={!isSmsAvailable}
        className={`${isSmsAvailable ? 'cursor-auto' : 'cursor-not-allowed opacity-50'}`}
      >
        <option value="app">Authenticator app</option>
        {isSmsAvailable && <option value="sms">SMS/Text message</option>}
      </select>
    </div>
  );
};

const MfaEnableModal: React.FC<MfaEnableModalProps> = ({ isOpen, onClose, therapistEmail }: MfaEnableModalProps) => {
  const [selectedMethod, setSelectedMethod] = useState<MfaType>(DEFAULT_MFA_TYPE);
  const SelectedCompMethod = selectedMethod === 'app' ? MfaEnableAppAuthModal : MfaEnableSmsAuthModal;

  useEffect(() => {
    if (isOpen) {
      setSelectedMethod(DEFAULT_MFA_TYPE);
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} tw="max-w-lg">
      <ModalContent>
        <ModalHeader>
          <div tw="absolute top-0 right-0 pt-4 pr-4">
            <button
              data-testid="mfa-modal-close"
              type="button"
              tw="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={onClose}
            >
              <span tw="sr-only">Close</span>
              <svg
                tw="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>

          <ModalHeadline text="Multi-Factor Authentication" />
          <div tw="text-base font-normal leading-5 flex justify-center">
            <div tw="text-center w-[75%]">
              Two-factor authentication adds an extra layer of security to your account.
            </div>
          </div>

          <AuthenticationMethodDropdown value={selectedMethod} onChange={setSelectedMethod} />
        </ModalHeader>
        <SelectedCompMethod isOpen={isOpen} onClose={onClose} therapistEmail={therapistEmail} />
      </ModalContent>
    </Modal>
  );
};

export default MfaEnableModal;
