import { FormGroup, Input, Label, Select } from '@expressable/ui-library';
import moment, { Moment } from 'moment';
import { useState } from 'react';
import { SingleDatePicker } from 'react-dates';
import 'twin.macro';

import dayjs, { Dayjs } from 'dayjs';
import { getMinDateForEvaluation, MAX_DAYS_FOR_FIRST_EVALUATION } from 'domain/therapist-matcher/scheduling';
import { EvaluationOption, EVALUATION_OPTIONS } from 'hooks/common/useTherapistFilters/options';

export interface IInitialEvaluationOptions {
  availableDate: Dayjs | null;
  evaluationType: EvaluationOption | null;
  requiresStandardizedEval: boolean;
}

interface InitialEvaluationOptionsProps {
  value: IInitialEvaluationOptions;
  onChange: (value: IInitialEvaluationOptions) => void;
}

export function createEmptyInitialEvaluationOptions(): IInitialEvaluationOptions {
  return {
    availableDate: dayjs(getMinDateForEvaluation(EVALUATION_OPTIONS.SHORT).toISOString()),
    evaluationType: EVALUATION_OPTIONS.STANDARD,
    requiresStandardizedEval: false,
  };
}

const isDateAvailable = (date: Moment, evaluationType: EvaluationOption, requiresStandardizedEval?: boolean) => {
  const _evaluationType = requiresStandardizedEval !== undefined
    ? (requiresStandardizedEval ? EVALUATION_OPTIONS.STANDARD : EVALUATION_OPTIONS.SHORT)
    : evaluationType;

  const datesRange =
    date.isAfter(moment().add(MAX_DAYS_FOR_FIRST_EVALUATION, 'days')) ||
    date.isBefore(getMinDateForEvaluation(_evaluationType), 'days');

  return datesRange;
};

const InitialEvaluationOptions = ({ value, onChange }: InitialEvaluationOptionsProps) => {
  const [availableDateFocused, setAvailableDateFocused] = useState(false);

  return (
    <div tw="flex items-stretch flex-col md:flex-row mb-4 gap-6">
      <FormGroup tw="flex-grow md:flex-grow-0">
        <Label htmlFor="availableDate" tw="font-semibold text-sm mb-2">
          Available Date
        </Label>
        <div tw="w-40">
          <SingleDatePicker
            noBorder
            block
            openDirection="down"
            numberOfMonths={1}
            small
            date={value.availableDate ? moment(value.availableDate.toISOString()) : null}
            onDateChange={dateValue => {
              if (dateValue) {
                onChange({ ...value, availableDate: dayjs(dateValue.toISOString()) });
              }
            }}
            hideKeyboardShortcutsPanel
            focused={availableDateFocused}
            onFocusChange={({ focused }) => setAvailableDateFocused(focused)}
            id="availableDate"
            isOutsideRange={d => isDateAvailable(d, value.evaluationType as EvaluationOption, value.requiresStandardizedEval)}
          />
        </div>
      </FormGroup>

      <FormGroup type="inline" tw="flex-grow md:flex-grow-0 flex-row h-[38px] self-end items-center">
        <Input
          type="checkbox"
          id="requires-standardized-eval"
          data-testid="specialties"
          tw="mb-0 flex"
          value="requires-standardized-eval"
          checked={value.requiresStandardizedEval}
          onChange={e => {
            const requiresStandardizedEval = e.currentTarget.checked;
            const evaluationType = requiresStandardizedEval ? EVALUATION_OPTIONS.STANDARD : EVALUATION_OPTIONS.SHORT;
            const minDate = getMinDateForEvaluation(evaluationType);
            const newDate = dayjs().isBefore(minDate.toISOString())
              ? dayjs(minDate.toISOString())
              : value.availableDate;

            onChange({ ...value, availableDate: newDate, requiresStandardizedEval: e.currentTarget.checked });
          }}
          spacing="tight"
        />
        <Label tw="font-normal text-sm mb-0 ml-2" htmlFor="requires-standardized-eval">
          Requires Standardized Evaluation
        </Label>
      </FormGroup>

      {/* Hiding this FormGroup. Requested by https://github.com/expressable/cue/issues/881 */}
      <FormGroup tw="hidden flex-grow md:flex-grow-0">
        <Label htmlFor="evaluationType" tw="font-semibold text-sm mb-2">
          Evaluation Type
        </Label>
        {/* @TODO(juan): make so that changing this triggers the confirmation modal and resets the selection */}
        <div tw="w-full sm:w-full md:w-72">
          <Select
            spacing="tight"
            id="evaluationType"
            isSearchable
            value={value.evaluationType}
            options={[EVALUATION_OPTIONS.STANDARD]}
            onChange={(selectedOption: EvaluationOption) => {
              const minDate = getMinDateForEvaluation(selectedOption);
              const newDate = value.availableDate?.isBefore(minDate.toISOString()) // !! this prevents the user from switching back to a SHORT available date
                ? dayjs(minDate.toISOString())
                : value.availableDate;

              onChange({ ...value, availableDate: newDate, evaluationType: selectedOption });
            }}
          />
        </div>
      </FormGroup>
    </div>
  );
};

export default InitialEvaluationOptions;
