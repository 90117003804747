import React, { useEffect, useState } from 'react';
import { Card, Container, FormGroup, Label, Loader, Select } from '@expressable/ui-library';
import useTherapists from 'hooks/use-therapists';
import { toSelectOptions } from 'pages/contacts';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import dayjs, { Dayjs } from 'dayjs';
import { useFirehoseEvents } from 'hooks/use-firehose-events';
import { firehoseEventsArray } from 'utils/firehose-events-data';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import usePermissions from 'hooks/use-permissions';
import timezone from 'dayjs/plugin/timezone';
import { eventLogRenderSwitch } from './firehose-events/event-log-render-switch';
import utc from 'dayjs/plugin/utc';
import { FirehoseEvent } from 'domain/admin/types';
import 'twin.macro';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.guess();

export interface EventLogProps {
  eventSearchParam?: string | null;
  createdBySearchParam?: string | null;
  dateSearchParam?: string | null;
  sessionTypeParam?: string | null;
}

const EventLog = (props: EventLogProps) => {
  const { eventSearchParam, createdBySearchParam, dateSearchParam, sessionTypeParam } = props;
  const history = useHistory();
  const { data: therapistData, refetch } = useTherapists('all');
  const [userEmail, setUserEmail] = useState<string[]>(['All']);
  const [firehoseEvent, setFirehoseEvent] = useState<string[]>(['All']);
  const [sessionType, setSessionType] = useState<string[]>(['All']);
  const [date, setDate] = useState<Dayjs | null>(dayjs());
  const [datePickerFocused, setDatePickerFocused] = useState<boolean>(false);
  const [startKeyPK, setStartKeyPK] = useState<string | undefined>(undefined);
  const [startKeySK, setStartKeySK] = useState<string | undefined>(undefined);
  const [paginatedEvents, setPaginatedEvents] = useState<any | undefined>([]);
  const dateFormated = dayjs(date).format('YYYY/MM/DD');
  const fromDate = dayjs(dateFormated).toISOString();
  const addDayToCurrentDay = dayjs(dateFormated).add(1, 'day');
  const toDate = addDayToCurrentDay.toISOString();
  const {
    data: firehoseEvents,
    isLoading,
    isError,
  } = useFirehoseEvents(
    fromDate as string,
    toDate as string,
    firehoseEvent.toString(),
    userEmail.toString(),
    startKeyPK,
    startKeySK,
  );
  const { isAdminOrClinicalManager } = usePermissions();

  const allUsersOption = { label: 'All Users', value: 'All' };
  const allEventsOption = { label: 'All Events', value: 'All' };

  const sessionTypeOptions = [
    { value: 'All', label: 'All' },
    { value: 'evaluation', label: 'Evaluation' },
    { value: 'session', label: 'Session' },
  ];

  let eventsOptions = firehoseEventsArray.sort((a, b) => a.label.localeCompare(b.label));
  eventsOptions = [{ ...allEventsOption }, ...eventsOptions];

  let usersOptions = (therapistData && therapistData.map(toSelectOptions)) ?? [];
  usersOptions = [{ ...allUsersOption }, ...usersOptions];

  const eventLogHash = history.location.hash === '#event-log';

  useEffect(() => {
    if (dateSearchParam) {
      setDate(dayjs(dateSearchParam));
    }
  }, [dateSearchParam]);

  useEffect(() => {
    if (eventSearchParam) {
      setFirehoseEvent([eventSearchParam]);
    }
  }, [eventSearchParam]);

  useEffect(() => {
    if (createdBySearchParam) {
      setUserEmail([createdBySearchParam]);
    }
  }, [createdBySearchParam]);

  useEffect(() => {
    if (sessionTypeParam) {
      setSessionType([sessionTypeParam]);
    }
  }, [sessionTypeParam]);

  useEffect(() => {
    refetch();
  }, [firehoseEvents, isLoading, therapistData, eventLogHash]);

  if (isLoading && !paginatedEvents) {
    return (
      <div data-testid="event-log-loading" tw="flex-1 text-center py-20">
        <Loader type="ring" />
      </div>
    );
  }

  if (isError) {
    return (
      <Container data-testid="event-log-error" tw="relative pl-0" size="large">
        <Card tw="mb-4 px-8">
          <FormGroup tw="w-full sm:w-1/2 md:w-1/3">
            <Label>
              <FontAwesomeIcon icon={faExclamationTriangle} tw="text-red-500 mr-2" />
              {isAdminOrClinicalManager
                ? 'There was an error while loading the Event Logs'
                : 'Only users in Admin Groups can perform this action.'}
            </Label>
          </FormGroup>
        </Card>
      </Container>
    );
  }

  const onLoadMoreResultsHandler = () => {
    if (firehoseEvents?.lastEvaluatedKey?.pk && firehoseEvents?.lastEvaluatedKey?.sk) {
      setStartKeyPK(firehoseEvents.lastEvaluatedKey.pk);
      setStartKeySK(firehoseEvents.lastEvaluatedKey.sk);
      setPaginatedEvents(paginatedEvents ? paginatedEvents.concat(firehoseEvents.items) : firehoseEvents.items);
    }
  };

  const firehoseEventsData = paginatedEvents ? paginatedEvents.concat(firehoseEvents?.items) : firehoseEvents?.items;

  const allFirehoseEventsPlusNoteLockedFiltered =
    sessionType[0] === 'All' || firehoseEvent[0] !== 'note_locked'
      ? firehoseEventsData
      : firehoseEventsData?.filter((event: FirehoseEvent) => event?.sessionType === sessionType.toString());

  return (
    <div data-testid="event-log" className="group">
      <div tw="flex flex-wrap gap-6">
        <FormGroup tw="w-128">
          <Label htmlFor="events" tw="font-semibold text-sm mb-2" data-testid="event-log-events">
            Events
          </Label>
          <Select
            spacing="tight"
            id="events"
            isSearchable
            options={eventsOptions}
            defaultValue={eventsOptions.find(x => x.value === eventSearchParam) ?? allEventsOption}
            // eslint-disable-next-line
            onChange={(selectedOption: any) => {
              setStartKeyPK(undefined);
              setStartKeySK(undefined);
              setPaginatedEvents(undefined);
              const optionsArray = [];
              optionsArray.push(selectedOption);
              setFirehoseEvent(optionsArray.length > 0 ? optionsArray.map(option => option.value) : ['']);
              history.push(
                `/admin#event-log?events=${optionsArray.map(option => option.value)}&createdBy=${
                  userEmail ? userEmail : 'All'
                }&date=${date ? date.format('MM/DD/YYYY') : dayjs().format('MM/DD/YYYY')}`,
              );
            }}
          />
        </FormGroup>
        {firehoseEvent[0] === 'note_locked' && (
          <FormGroup tw="w-128 lg:order-last">
            <Label htmlFor="sessionType" tw="font-semibold text-sm mb-2" data-testid="session-type-events">
              Session Type
            </Label>
            <Select
              spacing="tight"
              id="sessionType"
              isSearchable
              options={sessionTypeOptions}
              defaultValue={sessionTypeOptions.find(x => x.value === sessionTypeParam) ?? sessionTypeOptions[0]}
              onChange={(selectedOption: any) => {
                const optionsArray = [];
                optionsArray.push(selectedOption);
                setSessionType(optionsArray.length > 0 ? optionsArray.map(option => option.value) : ['']);
                history.push(
                  `/admin#event-log?events=${firehoseEvent ? firehoseEvent : 'All'}&sessionType=${optionsArray.map(
                    option => option.value,
                  )}&createdBy=${userEmail ? userEmail : 'All'}&date=${
                    date ? date.format('MM/DD/YYYY') : dayjs().format('MM/DD/YYYY')
                  }`,
                );
              }}
            />
          </FormGroup>
        )}
        <FormGroup tw="w-128">
          <Label htmlFor="users" tw="font-semibold text-sm mb-2" data-testid="event-log-createdBy">
            Created By
          </Label>
          <Select
            spacing="tight"
            id="users"
            isSearchable
            options={usersOptions}
            value={usersOptions.find(x => x.value === createdBySearchParam) ?? allUsersOption}
            // eslint-disable-next-line
            onChange={(selectedOption: any) => {
              setStartKeyPK(undefined);
              setStartKeySK(undefined);
              setPaginatedEvents(undefined);
              const optionsArray = [];
              optionsArray.push(selectedOption);
              setUserEmail(optionsArray.length > 0 ? optionsArray.map(option => option.value) : ['']);
              history.push(
                `/admin#event-log?events=${firehoseEvent ? firehoseEvent : 'All'}&createdBy=${optionsArray.map(
                  option => option.value,
                )}&date=${date ? date.format('MM/DD/YYYY') : dayjs().format('MM/DD/YYYY')}`,
              );
            }}
          />
        </FormGroup>
        <FormGroup tw="w-128">
          <Label htmlFor="date" tw="font-semibold text-sm mb-2" data-testid="event-log-date">
            Date
          </Label>
          <SingleDatePicker
            id="date"
            noBorder
            block
            openDirection="down"
            numberOfMonths={1}
            small
            date={date ? moment(date.toISOString()) : null}
            onDateChange={date => {
              setStartKeyPK(undefined);
              setStartKeySK(undefined);
              setPaginatedEvents(undefined);
              if (date) {
                setDate(dayjs(date.toISOString()));
              }
              history.push(
                `/admin#event-log?events=${firehoseEvent ? firehoseEvent : 'All'}&createdBy=${
                  userEmail ? userEmail : 'All'
                }&date=${date ? date.format('MM/DD/YYYY') : dayjs().format('MM/DD/YYYY')}`,
              );
            }}
            focused={datePickerFocused}
            hideKeyboardShortcutsPanel
            onFocusChange={({ focused }) => setDatePickerFocused(focused)}
            isOutsideRange={() => false}
          />
        </FormGroup>
      </div>
      {allFirehoseEventsPlusNoteLockedFiltered?.length ? (
        <>
          <div tw="mt-8">
            <span tw="text-sm font-semibold">
              Viewing{' '}
              {allFirehoseEventsPlusNoteLockedFiltered[0] === undefined
                ? 0
                : allFirehoseEventsPlusNoteLockedFiltered?.length}{' '}
              events
            </span>
          </div>
          <div tw="mt-10">
            {allFirehoseEventsPlusNoteLockedFiltered?.map((event: FirehoseEvent, index: number) => {
              const isLastEvent = index === allFirehoseEventsPlusNoteLockedFiltered?.length - 1;
              return <div key={index}>{eventLogRenderSwitch(event?.eventType, event, isLastEvent)}</div>;
            })}
          </div>
        </>
      ) : (
        <div tw="mt-8">
          <span tw="text-sm font-semibold">No events to show!</span>
        </div>
      )}
      {firehoseEvents?.lastEvaluatedKey?.pk && firehoseEvents?.lastEvaluatedKey?.sk && (
        <div tw="mt-10 text-center">
          <FontAwesomeIcon icon={faAngleDown} tw="text-base mr-2 text-indigo-700" />
          <span onClick={() => onLoadMoreResultsHandler()} tw="font-semibold text-indigo-700 cursor-pointer">
            Load more results
          </span>
        </div>
      )}
    </div>
  );
};

export default EventLog;
