import { StepperCtx } from 'components/Stepper/types';
import { createContext, useContext } from 'react';

export const StepperContext = createContext<StepperCtx | null>(null);

export const useStepperContext = () => {
  const ctx = useContext(StepperContext);

  if (!ctx) {
    throw new Error(`You must wrap this component in a StepperContext provider`);
  }

  return ctx;
};
