import { Input, Select, Textarea } from '@expressable/ui-library';

import { FormField } from 'components/Forms';
import { ControlledFormField } from 'components/Forms/FormFieldControlled';
import FormFieldRadioFreeText from 'components/Forms/RadioFreeText';
import useEvaluationMedicalHistory from 'hooks/use-evaluation-medical-history';
import { SelectCreatable } from 'components/Forms/CreatableSelect';
import { autoSize } from 'utils/auto-h-size';

export default function EvaluationMedicalHistorySection({ clientId }: { clientId: string }) {
  const {
    personsPresentForEvaluationOptions,
    referrals,
    nursingOptions,
    includeOtherNursingDetails,
    sourceOfMedicalHistory,
  } = useEvaluationMedicalHistory(clientId);

  return (
    <div className="flex-col">
      <h2 className="font-semibold text-xl ">Medical History</h2>
      <hr className="mt-1 mb-6 border-gray-200" />

      <div className="flex gap-x-8 w-full">
        <ControlledFormField
          containerClass="flex flex-col w-1/5"
          component={Select}
          spacing="tight"
          tw={'min-w-[550px]'}
          name="referralType"
          label="Referral Type"
          options={referrals?.dropdownContent?.map(option => ({
            label: option,
            value: option,
          }))}
        />
        <FormField name="referredBy" containerClass="w-1/2" className="w-1/2" label="Referred By" component={Input} />
      </div>
      <FormField
        className="w-full"
        spacing="tight"
        component={Textarea}
        rows={4}
        name="referralReason"
        label="Reason for Referral"
        onChange={autoSize}
      />

      <div className="space-y-6 mt-5 w-full">
        <ControlledFormField
          containerClass="flex flex-col w-full"
          component={SelectCreatable}
          maxWidth="full"
          className="w-full"
          spacing="tight"
          tw={'min-w-[550px]'}
          isMulti
          name="personsPresent"
          label="Additional Persons Present for Evaluation"
          optional
          options={personsPresentForEvaluationOptions}
        />

        <ControlledFormField
          containerClass="flex flex-col w-full"
          component={SelectCreatable}
          maxWidth="full"
          className="w-full"
          spacing="tight"
          tw={'min-w-[550px]'}
          isMulti
          name="medicalHistorySource"
          options={sourceOfMedicalHistory}
          label="Source of Medical History"
        />

        <FormFieldRadioFreeText
          when="yes"
          name="birthHistory"
          label="Is there relevant history with pregnancy and birth?"
          isLongText
          onChange={autoSize}
        />

        <FormFieldRadioFreeText
          when="yes"
          name="hospitalizationHistory"
          label="Is there relevant history of hospitalizations?"
          isLongText
          onChange={autoSize}
        />

        <FormFieldRadioFreeText when="yes" name="allergiesHistory" label="Is there relevant history for allergies?" isLongText onChange={autoSize} />

        <FormFieldRadioFreeText
          when="yes"
          name="medicalDiagnosesHistory"
          label="Are there relevant medical diagnoses?"
          isLongText
          onChange={autoSize}
        />

        <FormFieldRadioFreeText when="yes" name="medications" label="Are there relevant medications?" isLongText onChange={autoSize} />

        <FormFieldRadioFreeText when="yes" name="hearingHistory" label="Is there relevant history for hearing?" isLongText onChange={autoSize} />

        <FormFieldRadioFreeText when="yes" name="visionHistory" label="Is there relevant history for vision?" isLongText onChange={autoSize} />

        <FormFieldRadioFreeText
          when="yes"
          name="previousSpeechTreatment"
          label="Was there any previous speech therapy treatment?"
          isLongText
          onChange={autoSize}
        />

        <FormFieldRadioFreeText
          when="yes"
          name="otherHistory"
          label="Is there any relevant history not previously mentioned?"
          isLongText
          onChange={autoSize}
        />

        <FormFieldRadioFreeText
          when="yes"
          name="developmentalHistory"
          label="Is there any developmental history not previously mentioned?"
          isLongText
          onChange={autoSize}
        />

        <FormFieldRadioFreeText when="yes" name="pain" label="Is there any pain history not previously mentioned?" isLongText onChange={autoSize} />

        <FormFieldRadioFreeText
          when="no"
          name="mentalStatus"
          label="Was the client oriented to person, place, situation, etc?"
          isLongText
          onChange={autoSize}
        />

        <FormFieldRadioFreeText
          when="yes"
          name="functionalLimitations"
          label="Is there any functional limitations status not previously mentioned?"
          isLongText
          onChange={autoSize}
        />

        <ControlledFormField
          containerClass="flex flex-col w-1/2"
          component={Select}
          spacing="tight"
          tw={'min-w-[550px]'}
          name="nursingOtherSupportsType"
          label="Nursing / Other Supports"
          isMulti
          options={
            nursingOptions?.dropdownContent?.length
              ? nursingOptions?.dropdownContent?.map(option => ({
                  label: option,
                  value: option,
                }))
              : []
          }
        />
        {includeOtherNursingDetails && (
          <FormField
            containerClass="mt-2"
            className="w-full"
            component={Input}
            name="nursingOtherSupportsDetails"
            label="Other Supports"
          />
        )}
      </div>
    </div>
  );
}
