import { yupResolver } from '@hookform/resolvers/yup';
import { FormValidationContext, Unvalidated } from 'domain/form-validation';
import React from 'react';
import { DefaultValues, FieldValues, FormProvider, SubmitHandler, useForm, UseFormReturn } from 'react-hook-form';
import { Schema } from 'yup';

type ServerErrors<T> = { [K in keyof T]?: string } & { nonFieldErrors?: string[] };

export type Props<T extends Record<string, unknown>> = {
  schema?: Schema<T>;
  className?: string;
  disabled?: boolean;
  children?: React.ReactNode;
  defaultValues?: DefaultValues<Unvalidated<T>>;
  serverErrors?: ServerErrors<T>;
  hideOptional?: boolean;
  onSubmit: SubmitHandler<T>;

  form?: UseFormReturn<Unvalidated<T>>;
};

export const Form = <T extends FieldValues>({
  schema,
  className,
  disabled = false,
  children,
  defaultValues,
  serverErrors = {},
  hideOptional = false,
  onSubmit,
  form,
}: Props<T>): JSX.Element => {
  const schemaDefaults = schema ? schema.getDefault() : {};

  const formResult =
    form ??
    useForm<Unvalidated<T>>({
      defaultValues: { ...schemaDefaults, ...defaultValues },
      resolver: schema ? yupResolver(schema) : undefined,
      shouldFocusError: false,
    });

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormProvider {...formResult}>
      <FormValidationContext.Provider value={{ schema, disabled, serverErrors, hideOptional }}>
        <form
          noValidate
          className={className}
          onSubmit={event => {
            return formResult.handleSubmit(onSubmit as SubmitHandler<Unvalidated<T>>)(event);
          }}
        >
          {children}
        </form>
      </FormValidationContext.Provider>
    </FormProvider>
  );
};
