import React, { Dispatch, FC, SetStateAction } from 'react';
import { Contact } from 'types';
import { AddressRender, PhoneRender } from './client-sidebar';
import dayjs from 'dayjs';
import { Loader } from '@expressable/ui-library';
import PhoneNumber from 'awesome-phonenumber';

export interface ClientContactFormReadOnlyProps {
  contact: Contact;
  isContactPrimaryContact: boolean;
  setShowEditForm: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
  setShowPhoneField: Dispatch<SetStateAction<boolean>>;
  setShowEmailField: Dispatch<SetStateAction<boolean>>;
}

type SelectedPreferencesProps = {
  preferences: {
    enableSMSApptReminderNotifications?: boolean;
    enableSmsNotifications?: boolean;
    enableEmailNotifications?: boolean;
    enableEmailApptReminderNotifications?: boolean;
  };
};

const SelectedPreferences: FC<SelectedPreferencesProps> = ({ preferences }) => {
  const enabledPreferences = Object.entries(preferences).filter(([, value]) => value);

  const stringMap = {
    enableSMSApptReminderNotifications: 'SMS Appointment reminders',
    enableSmsNotifications: 'SMS session and evaluation notes',
    enableEmailNotifications: 'Email session and evaluation notes',
    enableEmailApptReminderNotifications: 'Email appointment reminders',
  };

  if (enabledPreferences.length === 0) return null;

  const enabledPreferencesPhrase = enabledPreferences
    .map(([key]) => stringMap[key as keyof typeof stringMap])
    .join(', ');
  return <span className="text-sm text-gray-400">{enabledPreferencesPhrase}</span>;
};
const ClientContactFormReadOnly = (props: ClientContactFormReadOnlyProps) => {
  const { isContactPrimaryContact, contact, setShowEditForm, isLoading, setShowPhoneField, setShowEmailField } = props;
  const {
    contactFirstName,
    contactLastName,
    contactTimeZone,
    address,
    email,
    phone,
    relationshipToClient,
    cognitoCreatedAt,
    contactID,
    faxPhone,
    enableEmailApptReminderNotifications,
    enableSMSApptReminderNotifications,
    enableSmsNotifications,
    enableEmailNotifications,
  } = contact;

  if (isLoading) {
    return (
      <div className="flex justify-center items-center mx-auto" data-testid="client-contact-form-read-only-loader">
        <Loader type="ring" />
      </div>
    );
  }

  return (
    <>
      {contactID && (
        <div>
          <div className="flex justify-between items-center">
            <div className="text-2xl font-semibold mb-7 mt-4">
              {isContactPrimaryContact ? 'Primary Contact' : 'Contact'}
            </div>
            <span
              data-testid="client-contact-for-read-only-edit-button"
              className="text-indigo-700 cursor-pointer"
              onClick={() => {
                setShowEditForm(true);
                setShowPhoneField(true);
                setShowEmailField(true);
              }}
            >
              Edit Contact
            </span>
          </div>

          <div className="grid grid-cols-1 gap-5 md:grid-cols-3">
            {contactFirstName && contactLastName && (
              <div>
                <p className="font-semibold">Name</p>
                <p className="font-normal">
                  {contactFirstName} {contactLastName}
                </p>
              </div>
            )}
            {relationshipToClient && (
              <div>
                <p className="font-semibold">Relationship to Client</p>
                <p className="font-normal">{relationshipToClient}</p>
              </div>
            )}
            {contactTimeZone && (
              <div>
                <p className="font-semibold">Contact Time Zone</p>
                <p className="font-normal">{contactTimeZone}</p>
              </div>
            )}
            {phone && (phone.mobile || phone.work || phone.home) && (
              <>
                <div>
                  <p className="font-semibold">Phone</p>
                  <PhoneRender phone={phone} />
                  <SelectedPreferences
                    preferences={{
                      enableSMSApptReminderNotifications,
                      enableSmsNotifications,
                    }}
                  />
                </div>
              </>
            )}
            {faxPhone && (
              <div>
                <p className="font-semibold">Fax</p>
                <div> {new PhoneNumber(faxPhone).toJSON().number.national}</div>
              </div>
            )}
            {email && (
              <div>
                <p className="font-semibold">Email</p>
                <p className="font-normal">{email}</p>
                {cognitoCreatedAt && (
                  <p className="text-sm text-gray-400">
                    Portal account created in {dayjs(cognitoCreatedAt).format('MM/DD/YYYY')}
                  </p>
                )}
                <SelectedPreferences
                  preferences={{
                    enableEmailApptReminderNotifications,
                    enableEmailNotifications,
                  }}
                />
              </div>
            )}

            {address && (
              <div>
                <p className="font-semibold text-sm">Address</p>
                <AddressRender {...address} />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ClientContactFormReadOnly;
