import { Button, FormGroup, Input, Label, Select } from '@expressable/ui-library';
import { FormField, SubmitButton } from 'components/Forms';

import { DOBInput } from 'components/DOBInput';
import { ControlledFormField } from 'components/Forms/FormFieldControlled';
import { findTimezoneOptionByLabel, timezoneOptions } from 'hooks/common/useDisplayTimezone/options';
import { contactRelationshipOptions, phoneTypeOptions } from 'pages/new-client-create/ClientInformation/options';
import 'twin.macro';
import genderOptions from 'utils/gender-options';
import { intakeTypeOptions } from '../BillingInformation/options';
import { useWatch } from 'react-hook-form';
import { useEffect, useMemo, useRef, useState } from 'react';
import { isAdult } from './form';
import { DuplicateContactFinder } from './blocks/DuplicateContactFinder';
import { Contact, SelectOption } from 'types';
import { useContactsByPhoneNumber } from 'hooks/use-contacts';

export interface ClientInformationFormUiProps {
  isIntakePossible: boolean;
  setValue: (name: string, value: { label: string; value: string } | boolean | undefined) => void;
  usStatesOptionsWithMultipleTimezones: SelectOption[];
}

export const ClientInformationFormUi = ({
  isIntakePossible,
  setValue,
  usStatesOptionsWithMultipleTimezones,
}: ClientInformationFormUiProps): JSX.Element => {
  const isClientSelf = useWatch({ name: 'isClientSelf' });
  const contactEmail = useWatch({ name: 'email' });
  const contactPhone = useWatch({ name: 'phone' });
  const relationshipToClient = useWatch({ name: 'relationshipToClient' });
  const watchDateOfBirth = useWatch({ name: 'dateOfBirth' });
  const previousPhoneNumber = useRef<string | null>(null);
  const isMinor = !isAdult(watchDateOfBirth);
  const intakeType = useWatch({ name: 'intakeType' });
  const phone = useWatch({ name: 'phone' });
  const state = useWatch({ name: 'state' });

  const phoneQuery = useContactsByPhoneNumber(phone ?? previousPhoneNumber.current);

  const [isDuplicatedContactsSettled, setIsDuplicatedContactsSettled] = useState(false);
  const [selectedContact, setSelectedContact] = useState<Contact | null>(null);

  const intakeHint = useMemo(() => {
    if (!intakeType) {
      return '';
    }

    if (!isIntakePossible) {
      return 'No intake email/SMS may be sent without a scheduled evaluation';
    }

    if (intakeType.value === 'no_intake') {
      return 'No intake email/SMS will be sent';
    } else {
      return `An intake email/SMS will be sent to ${contactEmail || 'the primary contact email'} and (${
        contactPhone || 'the primary contact phone'
      })`;
    }
  }, [intakeType, isIntakePossible, contactEmail, contactPhone]);

  useEffect(() => {
    if (watchDateOfBirth && isIntakePossible) {
      const isClientAdult = isAdult(watchDateOfBirth);
      if (isClientAdult) setValue('intakeType', intakeTypeOptions[1]);
      else setValue('intakeType', intakeTypeOptions[0]);
    }
  }, [watchDateOfBirth]);

  useEffect(() => {
    if (!state?.hasMultipleTimeZone) {
      setValue('contactTimezone', findTimezoneOptionByLabel(state?.timezone) as any);
    } else {
      setValue('contactTimezone', null as any);
    }
  }, [state]);

  const restartSearch = () => {
    phoneQuery.reset();
    setSelectedContact(null);
    setIsDuplicatedContactsSettled(false);

    previousPhoneNumber.current = contactPhone;
  };

  return (
    <>
      <div className="flex gap-6">
        <FormField component={Input} name="clientFirstName" label="First Name" spacing="tight" />
        <FormField component={Input} name="clientMiddleName" label="Middle Name" spacing="tight" />
        <FormField component={Input} name="clientLastName" label="Last Name" spacing="tight" />
        <FormField component={Input} name="clientNameSuffix" label="Suffix" spacing="tight" />
      </div>
      <FormField component={Input} name="clientNickname" label="Preferred First Name" spacing="tight" />
      <ControlledFormField component={DOBInput} name="dateOfBirth" label="Date of Birth" />
      <ControlledFormField
        component={Select}
        spacing="tight"
        tw={'min-w-[227px]'}
        name="sex"
        label="Sex"
        options={genderOptions}
      />
      <FormField
        component={Input}
        name="genderIdentity"
        label="Gender Identity"
        spacing="tight"
        hint={`Add client's gender identity, pronouns, etc.`}
        containerClass="mb-20"
      />
      <h2 className="font-semibold text-xl">Primary Contact Information</h2>
      <h5 className="text-sm">
        Primary contact information is required to confirm client information. Please enter a phone number for the
        primary contact first so that it can be checked across existing contacts.
      </h5>
      {!isDuplicatedContactsSettled && (
        <DuplicateContactFinder
          contact={selectedContact}
          isDuplicatedContactsSettled={isDuplicatedContactsSettled}
          setSelectedContact={setSelectedContact}
          setIsDuplicatedContactsSettled={setIsDuplicatedContactsSettled}
          previousPhoneNumber={previousPhoneNumber}
          currentPhoneNumber={contactPhone}
          phoneQuery={phoneQuery}
        />
      )}

      {isDuplicatedContactsSettled && (
        <>
          <div className="flex gap-6">
            <FormField
              component={Input}
              type="tel"
              name="phone"
              label="Phone"
              spacing="tight"
              placeholder="(512) 234-5890"
            />
            <ControlledFormField
              component={Select}
              spacing="tight"
              tw={'min-w-[227px]'}
              name="phoneType"
              label="Type"
              options={phoneTypeOptions}
              defaultValue={phoneTypeOptions[1]}
            />

            {contactPhone !== previousPhoneNumber.current && (
              <FormGroup className="justify-end">
                <Button onClick={restartSearch} tw="ml-4 mr-2 h-[38px]" variant="secondary">
                  Restart Search
                </Button>
              </FormGroup>
            )}
          </div>
          {!isMinor && (
            <FormField
              component={Input}
              componentContainerClass="flex items-end gap-1"
              type="checkbox"
              name="isClientSelf"
              label="Is the client the primary contact?"
              spacing="tight"
            >
              <Label htmlFor="isClientSelf" tw="ml-2 font-normal">
                Yes
              </Label>
            </FormField>
          )}
          <FormField component={Input} name="crmID" label="CRM ID" spacing="tight" />
          {!isClientSelf && (
            <>
              <ControlledFormField
                component={Select}
                spacing="tight"
                tw={'min-w-[227px]'}
                name="relationshipToClient"
                label="Relationship to Client"
                options={contactRelationshipOptions}
              />
              <div className="flex gap-6">
                <FormField component={Input} name="contactName" label="Contact Name" spacing="tight" />
                <FormField component={Input} name="contactMiddleName" label="Contact Middle Name" spacing="tight" />
                <FormField component={Input} name="contactLastName" label="Contact Last Name" spacing="tight" />
              </div>
            </>
          )}
          <div className="flex gap-6" tw="w-1/3">
            <FormField
              component={Input}
              name="email"
              label="Email"
              spacing="tight"
              containerClass="w-full"
              className="w-full"
            />
          </div>
          <div className="flex gap-6" tw="w-1/3">
            <FormField
              component={Input}
              name="address1"
              label="Primary Address"
              spacing="tight"
              containerClass="w-full"
              className="w-full"
            />
          </div>
          <div className="flex gap-6" tw="w-1/3">
            <FormField
              component={Input}
              name="address2"
              label="Apt/Suite/Other"
              spacing="tight"
              containerClass="w-full"
              className="w-full"
            />
          </div>
          <div className="flex gap-6">
            <FormField component={Input} name="city" label="City" spacing="tight" />
            <ControlledFormField
              component={Select}
              spacing="tight"
              tw={'min-w-[227px]'}
              name="state"
              label="State"
              options={usStatesOptionsWithMultipleTimezones}
            />
            <ControlledFormField
              component={Select}
              spacing="tight"
              tw={'min-w-[227px]'}
              name="contactTimezone"
              label="Contact Time Zone"
              options={timezoneOptions}
              hint={state?.hasMultipleTimeZone ? 'State may have multiple time zones' : ''}
            />
            <FormField component={Input} name="zipCode" label="Zip Code" spacing="tight" />
          </div>
          <div tw="flex gap-6">
            <ControlledFormField
              component={Select}
              spacing="tight"
              tw={'min-w-[250px] max-w-[250px] mb-4'}
              name="intakeType"
              label="Select Intake Email/SMS"
              options={intakeTypeOptions}
              hint={intakeHint}
              readOnly={!isIntakePossible}
            />
          </div>
        </>
      )}
      <SubmitButton
        disabled={
          Boolean(phoneQuery.isSuccess && phoneQuery.data.length && !isDuplicatedContactsSettled)
            ? selectedContact === null || !intakeType || !relationshipToClient
            : !isDuplicatedContactsSettled
        }
        onClick={() => setIsDuplicatedContactsSettled(true)}
        className="mt-3"
        willRedirect={false}
        data-testid="confirm-client-info"
      >
        Confirm Client Information
      </SubmitButton>
    </>
  );
};
