export default [
  { value: '22', label: '22 - Increased Procedural Services' },
  { value: '23', label: '23 - Unusual Anesthesia' },
  {
    value: '24',
    label:
      '24 - Unrelated Evaluation and Management Service by the Same Physician or Other Qualified Health Care Professional During a Postoperative Period',
  },
  {
    value: '25',
    label:
      '25 - Significant, Separately Identifiable Evaluation and Management Service by the Same Physician or Other Qualified Health Care Professional on the Same Day of the Procedure or Other Service',
  },
  { value: '26', label: '26 - Professional Component' },
  { value: '27', label: '27 - Multiple Outpatient Hospital E/M Encounters on the Same Date' },
  { value: '32', label: '32 - Mandated Services' },
  { value: '33', label: '33 - Preventive Services' },
  { value: '47', label: '47 - Anesthesia by Surgeon' },
  { value: '50', label: '50 - Bilateral Procedure' },
  { value: '51', label: '51 - Multiple Procedures' },
  { value: '52', label: '52 - Reduced Services' },
  { value: '53', label: '53 - Discontinued Procedure' },
  { value: '54', label: '54 - Surgical Care Only' },
  { value: '55', label: '55 - Postoperative Management Only' },
  { value: '56', label: '56 - Preoperative Management Only' },
  { value: '57', label: '57 - Decision for Surgery' },
  {
    value: '58',
    label:
      '58 - Staged or Related Procedure or Service by the Same Physician or Other Qualified Health Care Professional During the Postoperative Period',
  },
  { value: '59', label: '59 - Distinct Procedural Service' },
  { value: '62', label: '62 - Two Surgeons' },
  { value: '63', label: '63 - Procedure Performed on Infants less than 4 kg' },
  { value: '66', label: '66 - Surgical Team' },
  {
    value: '76',
    label: '76 - Repeat Procedure or Service by Same Physician or Other Qualified Health Care Professional',
  },
  { value: '77', label: '77 - Repeat Procedure by Another Physician or Other Qualified Health Care Professional' },
  {
    value: '78',
    label:
      '78 - Unplanned Return to the Operating/Procedure Room by the Same Physician or Other Qualified Health Care Professional Following Initial Procedure for a Related Procedure During the Postoperative Period',
  },
  {
    value: '79',
    label:
      '79 - Unrelated Procedure or Service by the Same Physician or Other Qualified Health Care Professional During the Postoperative Period',
  },
  { value: '80', label: '80 - Assistant Surgeon' },
  { value: '81', label: '81 - Minimum Assistant Surgeon' },
  { value: '82', label: '82 - Assistant Surgeon (when qualified resident surgeon not available)' },
  { value: '90', label: '90 - Reference (Outside) Laboratory' },
  { value: '91', label: '91 - Repeat Clinical Diagnostic Laboratory Test' },
  { value: '92', label: '92 - Alternative Laboratory Platform Testing' },
  {
    value: '95',
    label:
      '95 - Synchronous Telemedicine Service Rendered via a Real-Time Interactive Audio and Video Telecommunications System',
  },
  { value: '96', label: '96 - Habilitative Services' },
  { value: '97', label: '97 - Rehabilitative Services' },
  { value: '99', label: '99 - Multiple Modifiers' },
  { value: 'AF', label: 'AF - Specialty physician' },
  { value: 'AG', label: 'AG - Primary physician' },
  { value: 'AK', label: 'AK - Non participating physician' },
  { value: 'AQ', label: 'AQ - Physician providing a service in an unlisted health professional shortage area (hpsa)' },
  {
    value: 'AS',
    label:
      'AS - Physician assistant, nurse practitioner, or clinical nurse specialist services for assistant at surgery',
  },
  { value: 'CR', label: 'CR - Catastrophe/disaster related' },
  { value: 'CS', label: 'CS - Cost sharing for COVID-19 testing' },
  {
    value: 'EP',
    label: 'EP - Service provided as part of medicaid early periodic screening diagnosis and treatment (epsdt) program',
  },
  { value: 'ET', label: 'ET - Emergency services' },
  { value: 'FP', label: 'FP - Service provided as part of family planning program' },
  {
    value: 'G0',
    label: 'G0 - Telehealth Services For Diagnosis, Evaluation, Or Treatment, Of Symptoms Of An Acute Stroke',
  },
  { value: 'GA', label: 'GA - Waiver of liability statement issued as required by payer policy, individual case' },
  {
    value: 'GC',
    label: 'GC - This service has been performed in part by a resident under the direction of a teaching physician',
  },
  {
    value: 'GE',
    label:
      'GE - This service has been performed by a resident without the presence of a teaching physician under the primary care exception',
  },
  {
    value: 'GF',
    label:
      'GF - Non-physician (e.g. nurse practitioner (np), certified registered nurse anesthetist (crna), certified registered nurse (crn), clinical nurse specialist (cns), physician assistant (pa)) services in a critical access hospital',
  },
  { value: 'GJ', label: "GJ - 'opt out' physician or practitioner emergency or urgent service" },
  {
    value: 'GR',
    label:
      'GR - This service was performed in whole or in part by a resident in a department of veterans affairs medical center or clinic, supervised in accordance with va policy',
  },
  {
    value: 'GV',
    label: "GV - Attending physician not employed or paid under arrangement by the patient's hospice provider",
  },
  { value: 'GW', label: "GW - Service not related to the hospice patient's terminal condition" },
  { value: 'HA', label: 'HA - Child/adolescent program' },
  { value: 'HB', label: 'HB - Adult program, non geriatric' },
  { value: 'HC', label: 'HC - Adult program, geriatric' },
  { value: 'HD', label: "HD - Pregnant/parenting women's program" },
  { value: 'HU', label: 'HU - Funded by child welfare agency' },
  { value: 'KX', label: 'KX - Requirements specified in the medical policy have been met' },
  {
    value: 'PD',
    label:
      'PD - Diagnostic or related non diagnostic item or service provided in a wholly owned or operated entity to a patient who is admitted as an inpatient within 3 days',
  },
  {
    value: 'Q0',
    label:
      'Q0 - Investigational clinical service provided in a clinical research study that is in an approved clinical research study',
  },
  {
    value: 'Q1',
    label:
      'Q1 - Routine clinical service provided in a clinical research study that is in an approved clinical research study',
  },
  {
    value: 'Q5',
    label:
      'Q5 - Service furnished under a reciprocal billing arrangement by a substitute physician; or by a substitute physical therapist furnishing outpatient physical therapy services in a health professional shortage area, a medically underserved area, or a rural area',
  },
  {
    value: 'Q6',
    label:
      'Q6 - Service furnished under a fee-for-time compensation arrangement by a substitute physician; or by a substitute physical therapist furnishing outpatient physical therapy services in a health professional shortage area, a medically underserved area, or a rural area',
  },
  {
    value: 'QJ',
    label:
      'QJ - Services/items provided to a prisoner or patient in state or local custody, however the state or local government, as applicable, meets the requirements in 42 cfr 411.4 (b)',
  },
  { value: 'QW', label: 'QW - CLIA waived' },
  { value: 'TC', label: 'TC - Technical Component' },
  { value: 'TH', label: 'TH - Obstetrical treatment/services, prenatal or postpart' },
];
