import { Label } from '@expressable/ui-library';
import { HelperText } from 'components/Forms/HelperText';
import { useFormValidation } from 'domain/form-validation';
import { useEffect } from 'react';
import { Controller, useFormContext, useFormState } from 'react-hook-form';
import { FormFieldProps } from 'components/Forms/types/form-field';
import useOptional from 'domain/form-validation/useOptional';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ControlledFormField = <C extends React.ComponentType<any>>({
  name,
  component: Component,
  disabled,
  onChange,
  onBlur,
  label,
  optional: manualOptional,
  hint,
  containerClass,
  componentContainerClass,
  ...props
}: FormFieldProps<C>): JSX.Element => {
  const { control, setError } = useFormContext();

  const { errors, isSubmitting } = useFormState({ name });
  const { disabled: formDisabled, serverErrors } = useFormValidation();
  const serverError = serverErrors[name];
  const optional = useOptional(name, manualOptional);

  useEffect(() => {
    if (serverError) {
      setError(name, { type: 'server', message: serverError });
    }
  }, [serverError, name, setError]);

  return (
    <div className={containerClass}>
      <Label optional={optional}>{label}</Label>
      <div className={componentContainerClass}>
        <Controller
          name={name}
          control={control}
          render={({ field: { ref, ...field } }) => {
            return (
              <Component
                {...props}
                {...field}
                id={name}
                disabled={formDisabled || isSubmitting || disabled || false}
                error={
                  errors[name] && {
                    message: errors[name]?.message || '',
                  }
                }
              />
            );
          }}
        />
      </div>

      {hint && <HelperText error={Boolean(errors[name])}>{hint}</HelperText>}
    </div>
  );
};
