import { presentMutationError } from '@expressable/ui-library';
import { dynamicHttp } from '@expressable/utils';
import {
  CancelPendingReschedulePayload,
  PendingReschedule,
  PendingRescheduleDTO,
  ReschedulePendingReschedulePayload,
} from 'domain/pending-reschedule/types';
import { useQueryClient, useMutation, useQuery } from 'react-query';
import { useToasts } from 'react-toast-notifications';
import { invalidateAttendanceQueries } from './shared/attendance-utils';

const httpHealthRecordProxy = dynamicHttp('health-record-proxy');

const createPendingReschedule = async (pendingRescheduleDTO: PendingRescheduleDTO) => {
  const { clientID, ...payload } = pendingRescheduleDTO;

  return httpHealthRecordProxy
    .post(`/client-attendances/pending-reschedules/${clientID}`, payload)
    .then(res => res.data);
};

export function useCreatePendingReschedule() {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(createPendingReschedule, {
    onSuccess: (_, payload) => {
      addToast('Appointment Successfully Updated', { appearance: 'success', autoDismiss: true });
      invalidateAttendanceQueries(queryClient);
    },
    onError: () => {
      invalidateAttendanceQueries(queryClient);
    },
  });
}

export const getAdminPendingReschedules = async (therapistID?: string) => {
  const therapistUrl = therapistID === 'all' ? '' : `?therapistID=${therapistID}`;

  const { data } = await httpHealthRecordProxy.get<PendingReschedule[]>(
    `/client-attendances/pending-reschedules/admin${therapistUrl}`,
  );
  return data;
};

export function useAdminPendingReschedules(isAdminPage: boolean, therapistID?: string) {
  return useQuery(['adminPendingReschedules', therapistID], () => getAdminPendingReschedules(therapistID), {
    enabled: isAdminPage,
  });
}

export const getClientPendingReschedules = async (clientID: string) => {
  const { data } = await httpHealthRecordProxy.get<PendingReschedule[]>(
    `/client-attendances/pending-reschedules/${clientID}`,
  );
  return data;
};

export function useClientPendingReschedules(clientID: string) {
  return useQuery(['clientPendingReschedules', clientID], () => getClientPendingReschedules(clientID), {
    enabled: Boolean(clientID),
  });
}

export const getTherapistPendingReschedules = async (therapistID: string) => {
  const { data } = await httpHealthRecordProxy.get<PendingReschedule[]>(
    `/client-attendances/pending-reschedules/therapist/${therapistID}`,
  );
  return data;
};

export function useTherapistPendingReschedules(therapistID: string) {
  return useQuery(['therapistPendingReschedules', therapistID], () => getTherapistPendingReschedules(therapistID), {
    enabled: Boolean(therapistID),
  });
}

const cancelPendingReschedule = async (pendingReschedulePayload: CancelPendingReschedulePayload) => {
  const { pendingRescheduleID, clientID } = pendingReschedulePayload;
  const { data } = await httpHealthRecordProxy.delete(
    `/client-attendances/pending-reschedules/${clientID}/${pendingRescheduleID}`,
    {
      data: {
        cancellationReason: pendingReschedulePayload.cancellationReason,
      },
    },
  );
  return data;
};

export function useCancelPendingReschedule() {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(cancelPendingReschedule, {
    onSuccess: (_, pendingRescheduleID) => {
      if (pendingRescheduleID.displayToast) {
        addToast('Pending Reschedule Successfully Removed', { appearance: 'success', autoDismiss: true });
      }
      queryClient.invalidateQueries(['cancelPendingReschedule', pendingRescheduleID]);
      setTimeout(() => {
        queryClient.invalidateQueries();
      }, 1000);
    },
    onError: presentMutationError,
  });
}

const reschedulePendingReschedule = async (reschedulePendingReschedulePayload: ReschedulePendingReschedulePayload) => {
  const { clientID, pendingRescheduleID, ...payload } = reschedulePendingReschedulePayload;

  return httpHealthRecordProxy
    .post(`/client-attendances/pending-reschedules/${clientID}/${pendingRescheduleID}/reschedule`, payload)
    .then(res => res.data);
};

export function useReschedulePendingReschedule() {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(reschedulePendingReschedule, {
    onSuccess: (_, payload) => {
      addToast('Appointment Successfully Updated', { appearance: 'success', autoDismiss: true });
      queryClient.invalidateQueries(['cancelPendingReschedule', payload]);
      queryClient.invalidateQueries(['appointments']);
      queryClient.invalidateQueries(['pending-reschedules', payload.clientID]);
      queryClient.invalidateQueries(['client-activity', payload.clientID]);
      queryClient.invalidateQueries(['upcoming-appointments']);
      queryClient.invalidateQueries(['today-appointments']);
      queryClient.invalidateQueries(['clientPendingReschedules']);
      queryClient.invalidateQueries(['adminPendingReschedules']);
      queryClient.invalidateQueries(['therapistPendingReschedules']);
    },
  });
}
