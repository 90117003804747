import { Goal } from '../../src/hooks/use-care-plan-goals'

// TODO: This function will be deprecated soon and should be removed as part of the evaluation 2.0 deprecation process.
export const getActiveGoals = (goals: Goal[]): Goal[] => {
    const activeGoals = goals.filter((goal) => ['progressing', 'modified'].includes(goal?.status));
  
    const activeLTGs = activeGoals.filter((goal) => goal?.goalType === 'ltg');
    const activeSTGs = activeGoals.filter((goal) => goal?.goalType === 'stg');
  
    activeLTGs.sort((z, x) => Number(z?.detail?.goalTime) - Number(x?.detail?.goalTime));
  
    const result: Goal[] = [];
  
    activeLTGs.forEach((ltg) => {
      result.push({ ...ltg });
  
      const associatedSTGs = activeSTGs.filter((stg) => stg.ltgID === ltg.ltgID);
  
      associatedSTGs.sort((z, x) => Number(z?.detail?.goalTime) - Number(x?.detail?.goalTime));
      associatedSTGs.forEach((stg) => {
        result.push({ ...stg });
      });
    });
  
    return result;
  };