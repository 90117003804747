import { Dispatch, SetStateAction, useCallback, useState } from 'react';

export default function useInvalidateOnChange(onInvalidate: () => void) {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const wrapSetter = <T>(setter: Dispatch<SetStateAction<T>>) =>
    useCallback<Dispatch<SetStateAction<T>>>(
      (value: SetStateAction<T>) => {
        setter(value);

        if (isSubmitted) {
          onInvalidate();
        }
      },
      [setter, isSubmitted, onInvalidate],
    );

  const setSubmitted = useCallback(() => setIsSubmitted(true), []);

  return {
    submitted: isSubmitted,
    setSubmitted,
    wrapSetter,
  };
}
