import { Dayjs } from 'dayjs';
import { TimezoneOption } from 'hooks/common/useDisplayTimezone/options';
import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';

export interface DisplayTimezoneContextValue {
  displayTimezone: TimezoneOption;
  setDisplayTimezone: React.Dispatch<React.SetStateAction<TimezoneOption>>;
  formatTime: (time: Dayjs, template?: string) => string;
}

const DisplayTimezoneContext = createContext<DisplayTimezoneContextValue | undefined>(undefined);

interface DisplayTimezoneProviderProps {
  children: React.ReactFragment;
  defaultTimezone: TimezoneOption;
}

export function DisplayTimezoneProvider({ children, defaultTimezone }: DisplayTimezoneProviderProps) {
  const [displayTimezone, setDisplayTimezone] = useState(defaultTimezone);

  const formatTime = useCallback(
    (time: Dayjs, template = 'h:mm A') => time?.tz(displayTimezone.value).format(template),
    [displayTimezone],
  );

  const value = useMemo<DisplayTimezoneContextValue>(() => {
    return {
      displayTimezone,
      setDisplayTimezone,
      formatTime,
    };
  }, [displayTimezone, setDisplayTimezone]);

  return <DisplayTimezoneContext.Provider value={value}>{children}</DisplayTimezoneContext.Provider>;
}

export function useDisplayTimezone() {
  return useContext(DisplayTimezoneContext)!;
}
