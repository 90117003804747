import { FieldValues } from 'react-hook-form';
import { ObjectSchema } from 'yup';
import get from 'lodash/get';

/**
 * Returns a list of attributes to pass to form field components based on
 * the rules outlined in the schema (e.g. `required`, `min`, `max`).
 */
export const getSchemaFieldAttributes = (
  schema: ObjectSchema<FieldValues> | undefined,
  field: string,
): Record<string, string | number | boolean> => {
  if (!schema) {
    return {};
  }

  /**
   * Ideally we could narrow this `fieldSchema` to not include `Reference<unknown>`
   * but the `yup` library types don't make that easy. Instead we have to use a
   * combination of property checking and "soft" accesses like the `get(..)`
   * below to work around the limitation.
   */

  // check if the field has a dot notation

  const fieldSchema = !field.includes('.') 
  ? schema.fields[field] 
  : schema.fields[field.split('.')[0]];

  console.debug('trying to get schema field attributes for', field, fieldSchema);
  const description = fieldSchema.describe();

  if (!fieldSchema) {
    throw new Error(`Could not find schema field for "${field}"`);
  }


  if (description.type === 'ref' || !('tests' in description)) {
    return {};
  }

  const attributes: Record<string, string | number | boolean> = {};

  for (const test of description.tests) {
    if (test.name === 'required') {
      attributes.required = true;
    }

    if (description.type === 'number') {
      if (test.name === 'min' && test.params?.min !== undefined) {
        attributes.min = test.params.min as number;
      }

      if (test.name === 'max' && test.params?.min !== undefined) {
        attributes.max = test.params?.max as number;
      }
    }

    if ('email' in get(fieldSchema, 'exclusiveTests', {})) {
      attributes.type = 'email';
      attributes.inputMode = 'email';
    }
  }

  return attributes;
};
