import {
  Button,
  Card,
  CloseButton,
  Input,
  Label,
  Link,
  Loader,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@expressable/ui-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CardList from 'components/card-list';
import {
  ageRangeOptions,
  complexityOptions,
  getStructuredActivityLink,
  homeworkTypeOptions,
  timeRequiredOptions,
} from 'domain/clinical-pathways/contants';
import { ClientAssignment, CustomAcademyHomeworkContentful, MaterialContentful } from 'domain/clinical-pathways/types';
import { useClinicalPhonemes } from 'domain/clinical-pathways/use-clinical-phonemes';
import { useFilteredStructuredActivities } from 'hooks/use-structured-activities';
import { useTreatmentAreas } from 'hooks/use-treatment-areas';
import { isEmpty } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import 'twin.macro';
import { SelectOption } from 'types';
import { useDebouncedCallback } from 'use-debounce';

const PHONEME_TREATMENT_AREAS = ['Articulation'];
/** Treatment areas which make use of phonemes for filtering */

enum ModalTab {
  Filters = 0,
  Results = 1,
}

interface FormContent {
  treatmentArea?: SelectOption<string>;
  ageRange?: SelectOption<string>[];
  complexity?: SelectOption<string>[];
  timeRequired?: SelectOption<string>[];
  homeworkType?: SelectOption<string>[];
  caregiverRequired?: string;
  hasMaterials?: string;
  phoneme?: SelectOption<number> | null;
}

export interface Props {
  currentTherapyStrategies: ClientAssignment[];
  currentStructuredActivities: ClientAssignment[];
  isOpen: boolean;
  onAddCustomActivity: () => void;
  onClose: () => void;
  onSave: ({ academyHomeworks }: { academyHomeworks: CustomAcademyHomeworkContentful[] }) => void;
}

export function AddStructuredActivityModal({
  currentStructuredActivities = [],
  isOpen,
  onAddCustomActivity,
  onClose,
  onSave,
}: Props) {
  const [currentTab, setCurrentTab] = useState<ModalTab>(ModalTab.Filters);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedItems, setSelectedItems] = useState<CustomAcademyHomeworkContentful[]>([]);
  const [selectedMaterials, setSelectedMaterials] = useState<MaterialContentful[]>([]);

  const {
    handleSubmit,
    reset,
    formState: { errors },
    control,
    register,
    watch,
    setValue,
  } = useForm<FormContent>({
    defaultValues: {
      ageRange: undefined,
      complexity: undefined,
      timeRequired: undefined,
      homeworkType: undefined,
      caregiverRequired: undefined,
      hasMaterials: undefined,
      treatmentArea: undefined,
    },
  });

  const watchedTreatmentArea = watch('treatmentArea');
  const watchedAgeRange = watch('ageRange');
  const watchedComplexity = watch('complexity');
  const watchedTimeRequired = watch('timeRequired');
  const watchedHomeworkType = watch('homeworkType');
  const watchedCaregiverRequired = watch('caregiverRequired');
  const watchedHasMaterials = watch('hasMaterials');
  const watchedPhoneme = watch('phoneme');

  useEffect(() => {
    setValue('phoneme', null);
  }, [watchedTreatmentArea?.value]);

  const resetContent = () => {
    reset({
      ageRange: undefined,
      complexity: undefined,
      timeRequired: undefined,
      homeworkType: undefined,
      caregiverRequired: undefined,
      hasMaterials: undefined,
      treatmentArea: undefined,
    });

    setCurrentTab(ModalTab.Filters);
    setSearchTerm('');
    setSelectedItems([]);
    setSelectedMaterials([]);
  };

  const onCloseHandler = () => {
    resetContent();
    onClose();
  };

  const { data: treatmentAreasData } = useTreatmentAreas();

  const { phonemeOptions, selectedPhoneme } = useClinicalPhonemes(watchedPhoneme?.value);

  const showPhonemesFilter = useMemo(
    () => PHONEME_TREATMENT_AREAS.includes(watchedTreatmentArea?.label + ''),
    [watchedTreatmentArea],
  );

  const treatmentAreaOptions: SelectOption[] | undefined = useMemo(() => {
    if (!treatmentAreasData) {
      return undefined;
    } else {
      return treatmentAreasData.map(({ id, treatmentAreaCategory }) => ({
        label: treatmentAreaCategory,
        value: id,
      }));
    }
  }, [treatmentAreasData]);

  // it filters academy homeworks from Contentful
  const {
    data: academyHomeworksContentful,
    isLoading: isAcademyHomeworksContentfulLoading,
    refetch: refetchAcademyHomeworksContentful,
    isRefetching,
  } = useFilteredStructuredActivities(
    {
      textSearch: !isEmpty(searchTerm) ? searchTerm : undefined,
      ageRanges: watchedAgeRange?.map(item => item.value),
      complexities: watchedComplexity?.map(item => item.value),
      timesRequired: watchedTimeRequired?.map(item => item.value),
      homeworkTypes: watchedHomeworkType?.map(item => item.value),
      caregiverRequired: !watchedCaregiverRequired ? undefined : watchedCaregiverRequired === 'yes',
      hasMaterials: !watchedHasMaterials ? undefined : watchedHasMaterials === 'yes',
      relatedTreatmentAreaId: watchedTreatmentArea?.value,
      phoneme: selectedPhoneme,
    },
    400,
  );

  const onSubmitHandler = () => {
    const academyHomeworksToSave = selectedItems.map(homework => {
      const targetMaterial = selectedMaterials?.find(item => item.homeworkId === homework.id);
      return {
        ...homework,
        selectedMaterial: targetMaterial,
      } as CustomAcademyHomeworkContentful;
    });

    onSave({
      academyHomeworks: [...academyHomeworksToSave],
    });
    onCloseHandler();
  };

  useEffect(() => {
    resetContent();
  }, [isOpen]);

  useEffect(() => {
    refetchAcademyHomeworksContentful();
  }, [
    searchTerm,
    watchedAgeRange,
    watchedComplexity,
    watchedTimeRequired,
    watchedHomeworkType,
    watchedCaregiverRequired,
    watchedHasMaterials,
    watchedTreatmentArea,
    watchedPhoneme,
  ]);

  const setSearchTermDebounced = useDebouncedCallback(value => {
    setSearchTerm(value);
  }, 500);

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    homework: CustomAcademyHomeworkContentful,
  ) => {
    if (event.target.checked) {
      setSelectedItems([...selectedItems, { ...homework }]);

      const containsMaterial = !!homework.materials?.length;
      if (containsMaterial) {
        const wasMaterialSelected = selectedMaterials?.find(item => item.homeworkId === homework.id);
        if (!wasMaterialSelected) {
          // when the document was not selected, it means we need to pick the first option available in the dropdown
          setSelectedMaterials([
            ...selectedMaterials,
            {
              homeworkId: homework.id,
              materialId: homework.materials[0].id,
              materialTitle: homework.materials[0].title,
              materialUrl: homework.materials[0].file?.url,
            },
          ]);
        }
      }
    } else {
      setSelectedItems([...selectedItems.filter(item => item.id !== homework.id)]);
    }
  };

  const handleMaterialChange = (selectedMaterial: SelectOption<string>, homework: CustomAcademyHomeworkContentful) => {
    const targetUploadedMaterial = homework.materials?.find(material => material.id === selectedMaterial.value);
    if (!targetUploadedMaterial) {
      // safe checker to make sure nobody makes unexpected changes
      console.error('Something went wrong. Selected Material not found!');
    }

    // we filter out the homework id from the list (if that exists). We don't want to include duplicated items
    const filteredMaterials = selectedMaterials.filter(item => item.homeworkId !== homework.id);

    setSelectedMaterials([
      ...filteredMaterials,
      {
        homeworkId: homework.id,
        materialId: targetUploadedMaterial!.id,
        materialTitle: targetUploadedMaterial!.title,
        materialUrl: targetUploadedMaterial!.file?.url,
      },
    ]);
  };

  if (isAcademyHomeworksContentfulLoading) {
    return (
      <div tw="flex justify-center items-center">
        <Loader type="ring" />
      </div>
    );
  }

  const totalSelected = selectedItems?.length || 0;

  const currentHomeworkIds = currentStructuredActivities.map(item => item.structuredActivityContentfulEntryID) || [];
  const customAcademyHomeworksContentful = academyHomeworksContentful
    ?.filter(homework => !currentHomeworkIds.includes(homework.id))
    ?.map(homework => {
      return {
        ...homework,
        materials: homework.associatedMaterialsIfRequired?.map(associated => associated.uploadMaterial)?.flat() || [],
      } as CustomAcademyHomeworkContentful;
    });

  return (
    <Modal portal isOpen={isOpen}>
      <ModalContent>
        <form onSubmit={handleSubmit(onSubmitHandler)} data-testid="form-structured-activity">
          <ModalHeader>
            <div tw="flex flex-col items-center justify-center">
              <div tw="flex items-center justify-center w-12 h-12 mx-auto bg-indigo-100 rounded-full mb-5">
                <FontAwesomeIcon tw="text-2xl text-indigo-700" icon="file-alt" />
              </div>
              <h3 tw="text-lg text-center font-medium text-gray-900 leading-6 mb-5" id="modal-headline">
                Add Structured Activity
              </h3>
              <h4 tw="text-gray-900 text-center mb-5">
                Find a structured activity that aligns with the therapy strategies practiced in your session.
              </h4>
              <Link onClick={onAddCustomActivity} to="#" tw="text-indigo-700" data-testid="create-custom-sa">
                Create a custom structured activity
                <FontAwesomeIcon tw="text-xs ml-2" icon="chevron-right" />
              </Link>
            </div>
            <CloseButton onClick={() => onCloseHandler()} testId="close-structured-activity-top" />
          </ModalHeader>
          <ModalBody>
            <Tabs index={currentTab} onChange={setCurrentTab} defaultIndex={ModalTab.Filters} tw="mt-4">
              <TabList>
                <Tab id="filters">Filters</Tab>
                <Tab id="results">
                  Results {`${isRefetching ? '' : `(${customAcademyHomeworksContentful?.length || 0})`}`}
                </Tab>
              </TabList>
              <TabPanels tw="mt-7">
                <TabPanel>
                  <Label htmlFor="treatmentArea" tw="font-medium text-sm">
                    Treatment Area
                  </Label>
                  <Controller
                    name="treatmentArea"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        inputId="treatmentArea"
                        options={treatmentAreaOptions}
                        isSearchable
                        error={errors.treatmentArea}
                        tw="mt-2"
                        disabled={!treatmentAreaOptions}
                      />
                    )}
                  />

                  {showPhonemesFilter && (
                    <>
                      <Label htmlFor="phoneme" tw="font-medium text-sm">
                        Speech sound
                      </Label>
                      <Controller
                        name="phoneme"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            inputId="phoneme"
                            options={phonemeOptions}
                            isSearchable
                            error={errors.phoneme}
                            tw="mt-2"
                          />
                        )}
                      />
                    </>
                  )}

                  <Label htmlFor="ageRange" tw="font-medium text-sm">
                    Age Range
                  </Label>
                  <Controller
                    name="ageRange"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        inputId="ageRange"
                        options={ageRangeOptions}
                        isSearchable
                        isMulti
                        error={errors.ageRange}
                        tw="mt-2"
                      />
                    )}
                  />

                  <Label htmlFor="complexity" tw="font-medium text-sm">
                    Complexity
                  </Label>
                  <Controller
                    name="complexity"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        inputId="complexity"
                        options={complexityOptions}
                        isSearchable
                        isMulti
                        error={errors.complexity}
                        tw="mt-2"
                      />
                    )}
                  />

                  <Label htmlFor="timeRequired" tw="font-medium text-sm">
                    Time Required
                  </Label>
                  <Controller
                    name="timeRequired"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        inputId="timeRequired"
                        options={timeRequiredOptions}
                        isSearchable
                        isMulti
                        error={errors.timeRequired}
                        tw="mt-2"
                      />
                    )}
                  />

                  <Label htmlFor="homeworkType" tw="font-medium text-sm">
                    Type
                  </Label>
                  <Controller
                    name="homeworkType"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        inputId="homeworkType"
                        options={homeworkTypeOptions}
                        isSearchable
                        isMulti
                        error={errors.homeworkType}
                        tw="mt-2"
                      />
                    )}
                  />

                  <Label htmlFor="caregiverRequired" tw="font-medium text-sm mt-5">
                    Caregiver Required?
                  </Label>
                  <div tw="flex mt-2">
                    <Input
                      {...register('caregiverRequired')}
                      type="radio"
                      id="caregiverRequiredYes"
                      name="caregiverRequired"
                      spacing="tight"
                      value={'yes'}
                    />
                    <Label htmlFor="caregiverRequiredYes" tw="ml-2 font-normal">
                      Yes
                    </Label>
                    <Input
                      {...register('caregiverRequired')}
                      type="radio"
                      id="caregiverRequiredNo"
                      name="caregiverRequired"
                      spacing="tight"
                      value={'no'}
                      tw="ml-5"
                    />
                    <Label htmlFor="caregiverRequiredNo" tw="ml-2 font-normal">
                      No
                    </Label>
                  </div>

                  <Label htmlFor="hasMaterials" tw="font-medium text-sm mt-5">
                    Has Materials?
                  </Label>
                  <div tw="flex mt-2">
                    <Input
                      {...register('hasMaterials')}
                      type="radio"
                      id="hasMaterialsYes"
                      name="hasMaterials"
                      spacing="tight"
                      value={'yes'}
                    />
                    <Label htmlFor="hasMaterialsYes" tw="ml-2 font-normal">
                      Yes
                    </Label>
                    <Input
                      {...register('hasMaterials')}
                      type="radio"
                      id="hasMaterialsNo"
                      name="hasMaterials"
                      spacing="tight"
                      value={'no'}
                      tw="ml-5"
                    />
                    <Label htmlFor="hasMaterialsNo" tw="ml-2 font-normal">
                      No
                    </Label>
                  </div>

                  <ModalFooter>
                    <Button
                      type="button"
                      variant="secondary"
                      className="inline-flex justify-center w-full"
                      tw="mt-8"
                      data-testid="view-structured-activity"
                      onClick={() => setCurrentTab(ModalTab.Results)}
                    >
                      View Results {`${isRefetching ? '' : `(${customAcademyHomeworksContentful?.length || 0})`}`}
                    </Button>
                  </ModalFooter>
                </TabPanel>
                <TabPanel>
                  <div tw="mb-5">
                    <div tw="ml-1 mr-1 mb-5">
                      <input
                        tw="p-3 outline-none w-full border border-gray-300 rounded-md focus:border-indigo-300"
                        onChange={event => setSearchTermDebounced(event.target.value)}
                        type="text"
                        placeholder="Search"
                        aria-label="Search Academy Homework"
                        autoFocus
                        defaultValue={searchTerm}
                        data-testid="search-bar-input"
                      />
                    </div>
                    {customAcademyHomeworksContentful?.length ? (
                      <div tw="w-full overflow-auto h-96 border-none pb-2" data-testid="academy-homework-search">
                        <CardList
                          data={customAcademyHomeworksContentful ?? []}
                          render={homework => {
                            const foundMaterial = selectedMaterials?.find(item => item.homeworkId === homework.id);
                            return (
                              <div key={homework.id} tw="ml-1 mr-1 mb-5 last:mb-0">
                                <Card tw="p-6 border border-gray-300">
                                  <div tw="flex">
                                    <Input
                                      type="checkbox"
                                      id={`academy-homework-${homework.id}`}
                                      onChange={e => handleCheckboxChange(e, homework)}
                                      checked={!!selectedItems?.find(item => item.id === homework.id)}
                                      data-testid="academy-homework-card"
                                      spacing="tight"
                                      tw="flex mr-2 mt-0.5 cursor-pointer"
                                    />
                                    <Label
                                      htmlFor={`academy-homework-${homework.id}`}
                                      tw="font-medium text-sm cursor-pointer"
                                    >
                                      {homework.title}
                                    </Label>
                                  </div>
                                  <div className="mt-3 mb-3">{homework.aboutThisActivity}</div>
                                  <div>
                                    {homework.materials?.length > 0 && (
                                      <div>
                                        <div tw="xl:flex md:items-center mb-2">
                                          <Label tw="font-medium text-sm">Materials</Label>
                                        </div>
                                        <div tw="sm:flex sm:items-center mb-3">
                                          <Select
                                            tw="w-full"
                                            spacing="tight"
                                            id="structured-activity-material"
                                            options={homework.materials?.map(material => {
                                              return {
                                                value: material?.id,
                                                label: material?.title,
                                              } as SelectOption<string>;
                                            })}
                                            value={
                                              {
                                                value: foundMaterial?.materialId || homework.materials[0]?.id,
                                                label: foundMaterial?.materialTitle || homework.materials[0]?.title,
                                              } as SelectOption<string>
                                            }
                                            onChange={(selectedMaterial: SelectOption<string>) =>
                                              handleMaterialChange(selectedMaterial, homework)
                                            }
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                  <div className="w-full flex">
                                    <a
                                      className="text-indigo-700 font-medium mr-4"
                                      href={getStructuredActivityLink(homework.id)}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      View Activity
                                    </a>

                                    {homework.materials?.length > 0 && (
                                      <a
                                        className="text-indigo-700 font-medium"
                                        href={foundMaterial?.materialUrl || homework.materials[0]?.file?.url}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        View Material
                                      </a>
                                    )}
                                  </div>
                                </Card>
                              </div>
                            );
                          }}
                        />
                      </div>
                    ) : (
                      <div tw="text-sm text-gray-400">No academy homework match your current filters</div>
                    )}
                  </div>

                  <ModalFooter>
                    <Button
                      type="submit"
                      variant="primary"
                      className={`inline-flex justify-center w-full ${!totalSelected ? 'cursor-not-allowed' : ''}`}
                      disabled={!totalSelected}
                      data-testid="add-structured-activity-submit"
                    >
                      {totalSelected
                        ? `Add ${totalSelected} Structured ${totalSelected > 1 ? 'Activities' : 'Activity'}`
                        : 'Please select at least one'}
                    </Button>
                  </ModalFooter>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>
        </form>
      </ModalContent>
    </Modal>
  );
}
