import useEvaluationConclusions from 'hooks/use-evaluation-conclusions';
import {
  AdaptiveEquipment,
  AdditionalGoals,
  ApplicableConditions,
  AreasOfFunction,
  DevelopmentalDelays,
  Diagnoses,
  DischargePlan,
  DurationOfVisits,
  TotalVisitsRequested,
  Frequency,
  Goals,
  MedicalDiagnoses,
  PlanOfCareDates,
  PrimaryTherapyPurpose,
  SkilledInterventions,
} from 'pages/client/components/client-plan-of-care-form';
import { useMemo } from 'react';

type Props = {
  clientId: string;
  errors: any;
};
export default function EvaluationPlanOfCare({ clientId, errors }: Props) {
  const { isSpeechTherapyRecommended, isTeletherapyTherapyRecommended } = useEvaluationConclusions();

  const planOfCareErrors = useMemo(() => {
    const findErrorByName = (name: string) =>
      errors?.find((error: { field: string; message: string }) => error.field === name);

    return {
      diagnoses: findErrorByName('diagnoses')?.message ?? null,
      medicalDiagnoses: findErrorByName('medicalDiagnoses')?.message ?? null,
      startDate: findErrorByName('startDate')?.message ?? null,
      endDate: findErrorByName('endDate')?.message ?? null,
      visitFrequency: findErrorByName('visitFrequency')?.message ?? null,
      visitDuration: findErrorByName('visitDuration')?.message ?? null,
      totalVisits: findErrorByName('totalVisits')?.message ?? null,
      goals: findErrorByName('goals')?.message ?? null,
      additionalGoals: findErrorByName('additionalGoals')?.message ?? null,
      areasOfFunction: findErrorByName('areasOfFunction')?.message ?? null,
      delaysDisabilities: findErrorByName('delaysDisabilities')?.message ?? null,
      therapyPurpose: findErrorByName('therapyPurpose')?.message ?? null,
      applicableConditions: findErrorByName('applicableConditions')?.message ?? null,
      skilledInterventions: findErrorByName('skilledInterventions')?.message ?? null,
      equipmentDevices: findErrorByName('equipmentDevices')?.message ?? null,
      dischargePlan: findErrorByName('dischargePlan')?.message ?? null,
    };
  }, [errors?.length]);

  if (!isSpeechTherapyRecommended || !isTeletherapyTherapyRecommended) return null;

  return (
    <div className="flex-col mb-4">
      <h2 className="font-semibold text-xl">Plan Of Care</h2>
      <hr className="mt-1 mb-6 border-gray-200" />
      <div className="flex flex-col gap-5">
        <Diagnoses clientId={clientId} shouldLogEvent={false} error={planOfCareErrors?.diagnoses} />
        
        <MedicalDiagnoses clientId={clientId} shouldLogEvent={false} error={planOfCareErrors?.medicalDiagnoses} />
        
        <PlanOfCareDates
          clientId={clientId}
          shouldLogEvent={false}
          startDateError={planOfCareErrors.startDate}
          endDateError={planOfCareErrors.endDate}
        />
        
        <DurationOfVisits
          clientId={clientId}
          shouldLogEvent={false}
          durationVisitsError={planOfCareErrors.visitDuration}
        />
        
        <Frequency clientId={clientId} shouldLogEvent={false} error={planOfCareErrors.visitFrequency} />
        
        <TotalVisitsRequested
          clientId={clientId}
          shouldLogEvent={false}
          totalVisitsError={planOfCareErrors.totalVisits}
        />
        
        <Goals clientId={clientId} shouldLogEvent={false} withGoalsFilters={true} error={planOfCareErrors?.goals} />
        
        <AdditionalGoals clientId={clientId} shouldLogEvent={false} error={planOfCareErrors?.additionalGoals} />
        
        <PrimaryTherapyPurpose clientId={clientId} shouldLogEvent={false} error={planOfCareErrors?.therapyPurpose} />

        <AreasOfFunction clientId={clientId} shouldLogEvent={false} error={planOfCareErrors.areasOfFunction} />
        
        <DevelopmentalDelays clientId={clientId} shouldLogEvent={false} error={planOfCareErrors.delaysDisabilities} />
        
        <ApplicableConditions
          clientId={clientId}
          shouldLogEvent={false}
          error={planOfCareErrors.applicableConditions}
        />

        <SkilledInterventions
          clientId={clientId}
          shouldLogEvent={false}
          error={planOfCareErrors.skilledInterventions}
        />        
        
        <AdaptiveEquipment clientId={clientId} shouldLogEvent={false} error={planOfCareErrors.equipmentDevices} />
        
        <DischargePlan clientId={clientId} shouldLogEvent={false} error={planOfCareErrors.dischargePlan} />
      </div>
    </div>
  );
}
