import { presentMutationError } from '@expressable/ui-library';
import { dynamicHttp } from '@expressable/utils';
import { QualityReview } from 'domain/quality-review/types';
import { useMutation, useQuery, useQueryClient } from 'react-query';

const httpHealthRecordProxy = dynamicHttp('health-record-proxy');

const getEvaluationQualityReview = async (clientId: string) => {
  const { data } = await httpHealthRecordProxy.get(`/care-plans/quality-reviews/evaluation/${clientId}`);
  return data;
};

export function useGetEvaluationQualityReview(clientId: string) {
  return useQuery<QualityReview>(['care-plan-evaluation-quality-review', clientId], () =>
    getEvaluationQualityReview(clientId),
  );
}

const getDischargeQualityReview = async (clientId: string) => {
  const { data } = await httpHealthRecordProxy.get(`/care-plans/quality-reviews/discharge/${clientId}`);
  return data;
};

export function useGetDischargeQualityReview(clientId: string) {
  return useQuery<QualityReview>(['care-plan-discharge-quality-review', clientId], () =>
    getDischargeQualityReview(clientId),
  );
}

const createEvaluationQualityReview = async (payload: QualityReview) => {
  const { clientId, ...evaluationQualityReview } = payload;
  return httpHealthRecordProxy
    .post(`/care-plans/quality-reviews/evaluation/${clientId}`, evaluationQualityReview)
    .then(res => res.data);
};

export function useCreateEvaluationQualityReview() {
  const queryClient = useQueryClient();

  return useMutation(createEvaluationQualityReview, {
    onSuccess: response => {
      queryClient.invalidateQueries(['evaluationQualityReview', response]);
    },
    onError: presentMutationError,
  });
}

const createDischargeQualityReview = async (payload: QualityReview) => {
  const { clientId, ...dischargeQualityReview } = payload;
  return httpHealthRecordProxy
    .post(`/care-plans/quality-reviews/discharge/${clientId}`, dischargeQualityReview)
    .then(res => res.data);
};

export function useCreateDischargeQualityReview() {
  const queryClient = useQueryClient();

  return useMutation(createDischargeQualityReview, {
    onSuccess: response => {
      queryClient.invalidateQueries(['dischargeQualityReview', response]);
    },
    onError: presentMutationError,
  });
}
