import { animated, Spring } from 'react-spring/renderprops';
import React, { useState, useRef, useEffect } from 'react';
import tw from 'twin.macro';

export default function SeeMoreCollapsible({ children }: { children: React.ReactNode }) {
  const [expanded, setExpanded] = useState(false);
  const [shouldCollpase, setShouldCollapse] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (ref.current != null) {
      setShouldCollapse(ref.current.offsetHeight > 160);
    }
  }, [ref.current]);
  return (
    <Spring
      config={{ friction: 60, tension: 700 }}
      native
      from={{ maxHeight: 170 }}
      to={{ maxHeight: expanded ? 'none' : 170 }}
    >
      {animatedProps => (
        <animated.div style={{ ...animatedProps, overflow: 'hidden', position: 'relative' }}>
          <div css={shouldCollpase ? tw`pb-12` : ``} ref={ref}>
            {children}
          </div>
          {shouldCollpase &&
            (expanded ? (
              <span onClick={() => setExpanded(false)} tw="text-sm absolute bottom-3.5 text-indigo-700 cursor-pointer">
                View Less
              </span>
            ) : (
              <div
                style={{
                  width: '100%',
                  height: '150px',
                  bottom: 0,
                  left: 0,
                  padding: '0',
                  position: 'absolute',
                  display: 'flex',
                  backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0), rgb(255, 255, 255) 87.39%)',
                }}
              >
                <span
                  onClick={() => setExpanded(true)}
                  tw="text-sm self-end w-full pb-2 text-indigo-700 cursor-pointer"
                  style={{ backgroundColor: '#fff' }}
                >
                  View More
                </span>
              </div>
            ))}
        </animated.div>
      )}
    </Spring>
  );
}

export function SeeMoreGoalProgressCollapsible({ children }: { children: React.ReactNode }) {
  const [expanded, setExpanded] = useState(false);
  const [shouldCollpase, setShouldCollapse] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (ref.current) {
      setShouldCollapse(ref.current.offsetHeight > 60);
    }
  }, [ref.current]);
  return (
    <Spring
      config={{ friction: 60, tension: 700 }}
      native
      from={{ maxHeight: 70 }}
      to={{ maxHeight: expanded ? 'none' : 70 }}
    >
      {animatedProps => (
        <animated.div style={{ ...animatedProps, overflow: 'hidden', position: 'relative' }}>
          <div css={shouldCollpase ? tw`pb-12` : ``} ref={ref}>
            {children}
          </div>
          {shouldCollpase &&
            (expanded ? (
              <span onClick={() => setExpanded(false)} tw="text-sm absolute bottom-3.5 text-indigo-700 cursor-pointer">
                View Less
              </span>
            ) : (
              <div
                style={{
                  width: '100%',
                  bottom: 0,
                  left: 0,
                  padding: '0',
                  position: 'absolute',
                  display: 'flex',
                  backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0), rgb(255, 255, 255) 57.39%)',
                }}
              >
                <span onClick={() => setExpanded(true)} tw="text-sm self-end w-full text-indigo-700 cursor-pointer">
                  View All
                </span>
              </div>
            ))}
        </animated.div>
      )}
    </Spring>
  );
}
