import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faExternalLinkAlt,
  faBars,
  faExclamationCircle,
  faCaretDown,
  faPlusCircle,
  faCalendarCheck,
  faLock,
  faFileAlt,
  faUsers,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

import {
  faTrashUndoAlt,
  faCalendarEdit,
  faCalendarTimes,
  faUserPlus,
  faFileExcel,
  faEnvelope,
} from '@fortawesome/pro-solid-svg-icons';
import { faTrash, faTimes, faPlus } from '@fortawesome/pro-regular-svg-icons';
export default function registerIcons() {
  library.add(
    faPlus,
    faTrash,
    faTrashUndoAlt,
    faExternalLinkAlt,
    faBars,
    faTimes,
    faExclamationCircle,
    faCaretDown,
    faPlusCircle,
    faTrash,
    faCalendarCheck,
    faLock,
    faFileAlt,
    faUsers,
    faCalendarEdit,
    faCalendarTimes,
    faUserPlus,
    faFileExcel,
    faEnvelope,
    faChevronRight,
  );
}
