import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import { Badge } from '@expressable/ui-library';
import { ConflictType, IConflict } from 'utils/appointments/conflicts';

interface ConflictsProps {
  conflicts: IConflict[];
  availableTimes: boolean;
  displayTimezone: string;
}

const Conflicts: React.FC<ConflictsProps> = ({ conflicts, availableTimes, displayTimezone }) => {
  const sessionWord = conflicts.length === 1 ? 'session' : 'sessions';
  const scheduleConflictMessage = availableTimes
    ? `The following ${sessionWord} will not be reserved because of schedule conflicts:`
    : `The following ${sessionWord} will be reserved and create schedule conflicts due to overrides:`;

  const getConflictDesc = (type: string) => {
    switch (type) {
      case ConflictType.APPOINTMENT_EXISTS:
        return 'CONFLICT';
      case ConflictType.OVER_DIRECT_HOURS_LIMIT:
      case ConflictType.OVER_WEEKLY_LIMIT:
      case ConflictType.OVER_DAILY_LIMIT:
        return 'RATE LIMIT';
      case ConflictType.CONSECUTIVE_APPOINTMENTS_LIMIT:
        return 'CONSECUTIVE LIMIT';
      case ConflictType.FUTURE_CONFLICT:
        return '';
      default:
        return 'UNKNOWN';
    }
  };

  return (
    <div className="text-sm flex flex-col gap-4 border-2 border-gray-200 rounded-md p-4">
      <span
        className={`text-red-700 bg-red-100 py-0.5 px-2 rounded-full self-start text-xs font-semibold uppercase`}
        data-testid="conflict-header"
      >
        {`${conflicts.length} ${conflicts.length === 1 ? 'CONFLICT' : 'CONFLICTS'}${availableTimes ? '' : ' OVERRIDE'}`}
      </span>
      <div data-testid="conflict-detail">{scheduleConflictMessage}</div>
      <ul data-testid="conflict-list">
        {conflicts.map(conflict => (
          <li className="flex gap-1 items-center my-1" key={conflict?.datetime}>
            <FontAwesomeIcon icon={faExclamationCircle} color="#CC3B37" className="w-4 h-4" />
            <span className="truncate flex flex-row items-center justify-center gap-2">
              {dayjs(conflict?.datetime).tz(displayTimezone).format('MM/DD/YYYY - ddd - hh:mm A')}
              <Badge variant="disabled" tw="uppercase">
                {getConflictDesc(conflict?.type)}
              </Badge>
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Conflicts;
