import { ICD10 } from 'domain/shared/types';
import moment from 'moment';
import { EvaluationNoteFormData } from 'pages/client/components/client-notes/note-form-content/evaluation/schema';
import { SelectOption } from 'types';
import { EvaluationNoteDTO } from './types';
import { convertFreeTextRadioToYesOrNoDetails, convertYesOrNoToBoolean } from 'domain/notes/mapHelpers';

export default function mapEvaluationDataToEvaluationDTO(
  evaluationFormData: EvaluationNoteFormData,
  activity: any,
  clientID: string,
): EvaluationNoteDTO {
  const hasEvaluationCPT = Object.values(evaluationFormData?.cpt ?? {}).every(Boolean);
  const cpts = hasEvaluationCPT ? convertSelectOptionToCPTDTO(evaluationFormData.cpt)?.filter(Boolean) : [];
  const result: EvaluationNoteDTO = {
    clientID,
    noteType: 'appointment',
    version: '3.0',
    appointmentOn: joinDateAndTime(
      evaluationFormData.appointmentOnDate.toISOString(),
      evaluationFormData.appointmentOnHour.value,
    ),

    note: {
      appointmentNoteType: 'evaluation-note',
      content: {
        duration: activity.note.content.duration,
        evaluationDiagnosis: convertSelectOptionToICD10DTO(evaluationFormData?.evaluationDiagnosis ?? null),
        adjustedAge: evaluationFormData.adjustedAge || '',
        // languages
        clientsPrimaryLanguage: evaluationFormData?.clientsPrimaryLanguage?.value ?? '',
        additionalLanguageExposures:
          evaluationFormData?.additionalLanguageExposures?.map(convertSecondaryLanguageToLanguageExposure) ?? [],
        sourceOfLanguages: evaluationFormData?.sourceOfLanguages?.value,
        evaluationInPrimaryLanguage: convertYesOrNoToBoolean(evaluationFormData?.evaluationInPrimaryLanguage),
        evaluationWithInterpreter: convertYesOrNoToBoolean(evaluationFormData.evaluationWithInterpreter),
        evaluationLanguageJustification: evaluationFormData.evaluationLanguageJustification || '',

        // medical history
        referralType: evaluationFormData.referralType?.value || '',
        referredBy: evaluationFormData.referredBy || '',
        referralReason: evaluationFormData.referralReason || '',
        personsPresent:
          evaluationFormData?.personsPresent?.map((personPresent: SelectOption) => personPresent.value) ?? null,
        medicalHistorySource:
          evaluationFormData.medicalHistorySource?.map(
            (medicalHistorySource: SelectOption) => medicalHistorySource.value,
          ) ?? null,
        birthHistory: convertFreeTextRadioToYesOrNoDetails(evaluationFormData.birthHistory),
        hospitalizationHistory: convertFreeTextRadioToYesOrNoDetails(evaluationFormData.hospitalizationHistory),
        allergiesHistory: convertFreeTextRadioToYesOrNoDetails(evaluationFormData.allergiesHistory),
        medicalDiagnosesHistory: convertFreeTextRadioToYesOrNoDetails(evaluationFormData.medicalDiagnosesHistory),
        medications: convertFreeTextRadioToYesOrNoDetails(evaluationFormData.medications),
        hearingHistory: convertFreeTextRadioToYesOrNoDetails(evaluationFormData.hearingHistory),
        visionHistory: convertFreeTextRadioToYesOrNoDetails(evaluationFormData.visionHistory),
        otherHistory: convertFreeTextRadioToYesOrNoDetails(evaluationFormData.otherHistory),
        developmentalHistory: convertFreeTextRadioToYesOrNoDetails(evaluationFormData.developmentalHistory),
        pain: convertFreeTextRadioToYesOrNoDetails(evaluationFormData.pain),
        mentalStatus: convertFreeTextRadioToYesOrNoDetails(evaluationFormData.mentalStatus),
        functionalLimitations: convertFreeTextRadioToYesOrNoDetails(evaluationFormData.functionalLimitations),
        previousSpeechTreatment: convertFreeTextRadioToYesOrNoDetails(evaluationFormData.previousSpeechTreatment),
        nursingOtherSupportsDetails: evaluationFormData.nursingOtherSupportsDetails || '',
        nursingOtherSupportsType: evaluationFormData?.nursingOtherSupportsType?.map(v => v.value) ?? [],

        // social history
        educationStatus: evaluationFormData?.educationStatus?.value,
        gradeLevel: evaluationFormData?.gradeLevel?.value,
        academicConcerns: convertFreeTextRadioToYesOrNoDetails(evaluationFormData.academicConcerns),
        livingWith: evaluationFormData?.livingWith?.map(({ value }) => value),

        // behavioral observations
        acknowledgesSpeaker: evaluationFormData.acknowledgesSpeaker?.value ?? null,
        attentionToTasks: evaluationFormData.attentionToTasks?.value ?? null,
        topicMaintenance: evaluationFormData.topicMaintenance?.value ?? null,
        returnsGreeting: evaluationFormData.returnsGreeting?.value ?? null,
        transitionsIntoTreatment: evaluationFormData.transitionsIntoTreatment?.value ?? null,
        transitionsBetweenTasks: evaluationFormData.transitionsBetweenTasks?.value ?? null,
        awarenessOthersEnvironment: evaluationFormData.awarenessOthersEnvironment?.value ?? null,
        cooperation: evaluationFormData.cooperation?.value ?? null,
        responseRate: evaluationFormData.responseRate?.value ?? null,

        // #region clinical areas
        // receptive language
        receptiveLanguageHowWasAssessed:
          evaluationFormData.receptiveLanguageHowWasAssessed?.map(({ value }) => value) ?? null,
        receptiveLanguageAssessmentDescription: evaluationFormData.receptiveLanguageAssessmentDescription || null,
        receptiveLanguageAssessmentUsed:
          evaluationFormData.receptiveLanguageAssessmentUsed?.map(({ value }) => value) || null,
        receptiveLanguageConclusions: evaluationFormData.receptiveLanguageConclusions || null,

        // expressive language
        expressiveLanguageHowWasAssessed:
          evaluationFormData.expressiveLanguageHowWasAssessed?.map(({ value }) => value) ?? null,
        expressiveLanguageAssessmentDescription: evaluationFormData.expressiveLanguageAssessmentDescription || null,
        expressiveLanguageAssessmentUsed:
          evaluationFormData.expressiveLanguageAssessmentUsed?.map(({ value }) => value) || null,
        expressiveLanguageConclusions: evaluationFormData.expressiveLanguageConclusions || null,

        // fluency
        fluencyHowWasAssessed: evaluationFormData.fluencyHowWasAssessed?.map(({ value }) => value) ?? null,
        fluencyAssessmentDescription: evaluationFormData.fluencyAssessmentDescription || null,
        fluencyAssessmentUsed: evaluationFormData.fluencyAssessmentUsed?.map(({ value }) => value) || null,
        fluencyConclusions: evaluationFormData.fluencyConclusions || null,

        // voice/resonance
        voiceResonanceHowWasAssessed:
          evaluationFormData.voiceResonanceHowWasAssessed?.map(({ value }) => value) ?? null,
        voiceResonanceAssessmentDescription: evaluationFormData.voiceResonanceAssessmentDescription || null,
        voiceResonanceAssessmentUsed:
          evaluationFormData.voiceResonanceAssessmentUsed?.map(({ value }) => value) ?? null,
        voiceResonanceConclusions: evaluationFormData.voiceResonanceConclusions || null,

        // articulation/phonology
        articulationPhonologyHowWasAssessed:
          evaluationFormData.articulationPhonologyHowWasAssessed?.map(({ value }) => value) ?? null,
        articulationPhonologyAssessmentDescription:
          evaluationFormData.articulationPhonologyAssessmentDescription || null,
        articulationPhonologyAssessmentUsed:
          evaluationFormData.articulationPhonologyAssessmentUsed?.map(({ value }) => value) ?? null,
        articulationPhonologyConclusions: evaluationFormData.articulationPhonologyConclusions || null,

        // oral motor abilities
        oralMotorAbilitiesHowWasAssessed:
          evaluationFormData.oralMotorAbilitiesHowWasAssessed?.map(({ value }) => value) ?? null,
        oralMotorAbilitiesAssessmentDescription: evaluationFormData.oralMotorAbilitiesAssessmentDescription || null,
        oralMotorAbilitiesAssessmentUsed:
          evaluationFormData.oralMotorAbilitiesAssessmentUsed?.map(({ value }) => value) ?? null,
        oralMotorAbilitiesConclusions: evaluationFormData.oralMotorAbilitiesConclusions || null,

        // feeding/swallowing
        feedingSwallowingHowWasAssessed:
          evaluationFormData.feedingSwallowingHowWasAssessed?.map(({ value }) => value) ?? null,
        feedingSwallowingAssessmentDescription: evaluationFormData.feedingSwallowingAssessmentDescription || null,
        feedingSwallowingAssessmentUsed:
          evaluationFormData.feedingSwallowingAssessmentUsed?.map(({ value }) => value) ?? null,
        feedingSwallowingConclusions: evaluationFormData.feedingSwallowingConclusions || null,

        // pragmatics
        pragmaticsHowWasAssessed: evaluationFormData.pragmaticsHowWasAssessed?.map(({ value }) => value) ?? null,
        pragmaticsAssessmentDescription: evaluationFormData.pragmaticsAssessmentDescription || null,
        pragmaticsAssessmentUsed: evaluationFormData.pragmaticsAssessmentUsed?.map(({ value }) => value) ?? null,
        pragmaticsConclusions: evaluationFormData.pragmaticsConclusions || null,

        // #endregion clinical areas
        // conclusions
        areFurtherAssessmentsRecommended: convertFreeTextRadioToYesOrNoDetails(
          evaluationFormData.areFurtherAssessmentsRecommended,
        )?.relevant,
        recommendedAssessments: convertFreeTextRadioToYesOrNoDetails(
          evaluationFormData.areFurtherAssessmentsRecommended,
        )?.details,
        areSpeechTherapyServicesRecommended: convertFreeTextRadioToYesOrNoDetails(
          evaluationFormData.areSpeechTherapyServicesRecommended,
        )?.relevant,
        isTeletherapyAppropriate: evaluationFormData.isTeletherapyAppropriate?.value ?? null,
        clinicalRecommendations: evaluationFormData.clinicalRecommendations || null,
        conclusions: evaluationFormData.conclusions || null,
      },
    },
    cpt: cpts || null,
  };

  return result;
}

function convertSecondaryLanguageToLanguageExposure(selectOption: SelectOption): string {
  return selectOption.label;
}

function convertSelectOptionToCPTDTO(
  selectOptions?: SelectOption<{ code: string; description: string }>[] | null,
): ICD10[] | null {
  if (selectOptions?.length) {
    return selectOptions.map(option => ({
      code: option.value.code,
      description: option.value.description,
    }));
  }
  return null;
}
function convertSelectOptionToICD10DTO(selectOptions: SelectOption[] | null): ICD10[] | null {
  if (!selectOptions?.length) return null;
  return selectOptions.map(selectedOptions => {
    return {
      code: selectedOptions.value,
      description: selectedOptions.label,
    };
  });
}

function joinDateAndTime(date: string, time: string): string {
  const joinedDate = moment(date).set({
    hour: 0,
    minute: 0,
    second: 0,
  });

  const [hour, minute] = time.split(':');

  joinedDate.add(hour, 'hours');
  joinedDate.add(minute, 'minutes');

  return joinedDate.format();
}
