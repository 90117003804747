import { FirehoseEvent } from 'domain/admin/types';
import AcuityAppointmentAdded from './acuity-appointment-added';
import AcuityAppointmentCancelled from './acuity-appointment-cancelled';
import AcuityAppointmentRescheduled from './acuity-appointment-rescheduled';
import AppointmentCancelled from './appointment-cancelled';
import AppointmentCreated from './appointment-created';
import AppointmentLateCancelled from './appointment-late-cancelled';
import AppointmentNoShow from './appointment-no-show';
import AppointmentRescheduled from './appointment-rescheduled';
import ClientArchived from './client-archived';
import ClientBillingInformationAdded from './client-billing-information-added';
import ClientBillingInformationModified from './client-billing-information-modified';
import ClientCreated from './client-created';
import ClientInformationModified from './client-information-modified';
import ClientReassigned from './client-reassigned';
import ClientUnarchived from './client-unarchived';
import EvaluationReviewSent from './evaluation-review-sent';
import EventFallback from './event-fallback';
import NoteCreated from './note-created';
import NoteDeleted from './note-deleted';
import NoteLocked from './note-locked';
import ProviderAdded from './provider-added';
import SessionReviewSent from './session-review-sent';
import ClaimFinalized from './claim-finalized';
import ClaimPaymentSuccessful from './claim-payment-successful';
import ClaimFinalizedEmailSent from './claim-finalized-email-sent';
import ClaimPaymentFailed from './claim-payment-failed';
import EvaluationReviewReceived from './evaluation-review-received';
import SessionReviewReceived from './session-review-received';
import DischargeReviewReceived from './discharge-review-received';
import MessageSent from './message-sent';
import MessageReceived from './message-received';
import PendingRescheduleCreated from './pending-reschedule-created';
import PendingRescheduleRescheduled from './pending-reschedule-rescheduled';
import PendingRescheduleReminderSent from './pending-reschedule-reminder-sent';
import PendingRescheduleCancelled from './pending-reschedule-cancelled';
import ClientAssignmentAssigned from './client-assignment-assigned';
import ClientAssignmentUnassigned from './client-assignment-unassigned';
import ClientAssignmentCompleted from './client-assignment-completed';
import ClientAssignmentReassigned from './client-assignment-reassigned';
import JotformSubmissionReceived from './jotform-submission-received';
import QualityReviewEvaluationStatusUpdated from './quality-review-evaluation-status-updated';
import QualityReviewDischargeStatusUpdated from './quality-review-discharge-status-updated';
import VisitFrequencyAdded from './visit-frequency-added';
import VisitFrequencyModified from './visit-frequency-modified';
import ClientAdditionalGoalAdded from './client-additional-goal-added';
import ClientAdditionalGoalModified from './client-additional-goal-modified';
import ClientAdditionalGoalMet from './client-additional-goal-met';
import ClientAdditionalGoalDiscontinued from './client-additional-goal-discontinued';
import ClientAdditionalGoalRemoved from './client-additional-goal-removed';
import ClaimPreCollectionAdjustment from './claim-pre-collection-adjustment';
import FilesUploaded from './files-uploaded';
import FilesDeleted from './files-deleted';
import StripeInvoiceCreated from './stripe-invoice-created';
import StripeChargeSucceeded from './stripe-charge-succeeded';
import StripeInvoiceFinalized from './stripe-invoice-finalized';
import StripePaymentMethodUpdated from './stripe-payment-method-updated';
import StripeInvoiceDeleted from './stripe-invoice-deleted';
import StripeChargeFailed from './stripe-charge-failed';
import StripeInvoicePaymentFailed from './stripe-invoice-payment-failed';
import StripePaymentMethodAttached from './stripe-payment-method-attached';
import StripeInvoiceMarkedUncollectible from './stripe-invoice-marked-uncollectible';
import StripeInvoicePaid from './stripe-invoice-paid';
import StripeInvoiceOverdue from './stripe-invoice-overdue';
import StripeRefundCreated from './stripe-refund-created';

export const eventLogRenderSwitch = (eventType: string, event: FirehoseEvent, isLastEvent?: boolean) => {
  switch (eventType) {
    case 'note_created':
      return <NoteCreated event={event} isLastEvent={isLastEvent} />;
    case 'note_locked':
      return <NoteLocked event={event} isLastEvent={isLastEvent} />;
    case 'note_deleted':
      return <NoteDeleted event={event} isLastEvent={isLastEvent} />;
    case 'appointment_created':
      return <AppointmentCreated event={event} isLastEvent={isLastEvent} />;
    case 'appointment_cancelled':
      return <AppointmentCancelled event={event} isLastEvent={isLastEvent} />;
    case 'appointment_late_cancelled':
      return <AppointmentLateCancelled event={event} isLastEvent={isLastEvent} />;
    case 'appointment_no_showed':
      return <AppointmentNoShow event={event} isLastEvent={isLastEvent} />;
    case 'appointment_rescheduled':
      return <AppointmentRescheduled event={event} isLastEvent={isLastEvent} />;
    case 'acuity_appointment_added':
      return <AcuityAppointmentAdded event={event} isLastEvent={isLastEvent} />;
    case 'acuity_appointment_cancelled':
      return <AcuityAppointmentCancelled event={event} isLastEvent={isLastEvent} />;
    case 'acuity_appointment_rescheduled':
      return <AcuityAppointmentRescheduled event={event} isLastEvent={isLastEvent} />;
    case 'client_created':
      return <ClientCreated event={event} isLastEvent={isLastEvent} />;
    case 'client_archived':
      return <ClientArchived event={event} isLastEvent={isLastEvent} />;
    case 'client_information_modified':
      return <ClientInformationModified event={event} isLastEvent={isLastEvent} />;
    case 'client_reassigned':
      return <ClientReassigned event={event} isLastEvent={isLastEvent} />;
    case 'client_billing_information_added':
      return <ClientBillingInformationAdded event={event} isLastEvent={isLastEvent} />;
    case 'client_billing_information_modified':
      return <ClientBillingInformationModified event={event} isLastEvent={isLastEvent} />;
    case 'client_unarchived':
      return <ClientUnarchived event={event} isLastEvent={isLastEvent} />;
    case 'evaluation_review_sent':
      return <EvaluationReviewSent event={event} isLastEvent={isLastEvent} />;
    case 'session_review_sent':
      return <SessionReviewSent event={event} isLastEvent={isLastEvent} />;
    case 'evaluation_review_received':
      return <EvaluationReviewReceived event={event} isLastEvent={isLastEvent} />;
    case 'session_review_received':
      return <SessionReviewReceived event={event} isLastEvent={isLastEvent} />;
    case 'discharge_review_received':
      return <DischargeReviewReceived event={event} isLastEvent={isLastEvent} />;
    case 'provider_added':
      return <ProviderAdded event={event} isLastEvent={isLastEvent} />;
    case 'claim_finalized':
      return <ClaimFinalized event={event} isLastEvent={isLastEvent} />;
    case 'claim_finalized_email_sent':
      return <ClaimFinalizedEmailSent event={event} isLastEvent={isLastEvent} />;
    case 'claim_payment_successful':
      return <ClaimPaymentSuccessful event={event} isLastEvent={isLastEvent} />;
    case 'claim_payment_failed':
      return <ClaimPaymentFailed event={event} isLastEvent={isLastEvent} />;
    case 'message_sent':
      return <MessageSent event={event} isLastEvent={isLastEvent} />;
    case 'message_received':
      return <MessageReceived event={event} isLastEvent={isLastEvent} />;
    case 'pending_reschedule_created':
      return <PendingRescheduleCreated event={event as unknown as any} isLastEvent={isLastEvent} />;
    case 'pending_reschedule_rescheduled':
      return <PendingRescheduleRescheduled event={event as unknown as any} isLastEvent={isLastEvent} />;
    case 'pending_reschedule_cancelled':
      return <PendingRescheduleCancelled event={event as unknown as any} isLastEvent={isLastEvent} />;
    case 'pending_reschedule_reminder_sent':
      return <PendingRescheduleReminderSent event={event as unknown as any} isLastEvent={isLastEvent} />;
    case 'client_assignment_assigned':
      return <ClientAssignmentAssigned event={event} isLastEvent={isLastEvent} />;
    case 'client_assignment_reassigned':
      return <ClientAssignmentReassigned event={event} isLastEvent={isLastEvent} />;
    case 'client_assignment_completed':
      return <ClientAssignmentCompleted event={event} isLastEvent={isLastEvent} />;
    case 'client_assignment_unassigned':
      return <ClientAssignmentUnassigned event={event} isLastEvent={isLastEvent} />;
    case 'jotform_submission_received':
      return <JotformSubmissionReceived event={event} isLastEvent={isLastEvent} />;
    case 'quality_review_evaluation_status_updated':
      return <QualityReviewEvaluationStatusUpdated event={event} isLastEvent={isLastEvent} />;
    case 'quality_review_discharge_status_updated':
      return <QualityReviewDischargeStatusUpdated event={event} isLastEvent={isLastEvent} />;
    case 'visit_frequency_added':
      return <VisitFrequencyAdded event={event} isLastEvent={isLastEvent} />;
    case 'visit_frequency_modified':
      return <VisitFrequencyModified event={event} isLastEvent={isLastEvent} />;
    case 'caregiver_goal_added':
    case 'personal_goal_added':
      return <ClientAdditionalGoalAdded event={event} isLastEvent={isLastEvent} />;
    case 'caregiver_goal_modified':
    case 'personal_goal_modified':
      return <ClientAdditionalGoalModified event={event} isLastEvent={isLastEvent} />;
    case 'caregiver_goal_met':
    case 'personal_goal_met':
      return <ClientAdditionalGoalMet event={event} isLastEvent={isLastEvent} />;
    case 'caregiver_goal_discontinued':
    case 'personal_goal_discontinued':
      return <ClientAdditionalGoalDiscontinued event={event} isLastEvent={isLastEvent} />;
    case 'caregiver_goal_removed':
    case 'personal_goal_removed':
      return <ClientAdditionalGoalRemoved event={event} isLastEvent={isLastEvent} />;
    case 'client_files_uploaded':
      return <FilesUploaded event={event as unknown as any} isLastEvent={isLastEvent} />;
    case 'client_files_deleted':
      return <FilesDeleted event={event as unknown as any} isLastEvent={isLastEvent} />;
    case 'claim_pre_collection_adjustment':
      return <ClaimPreCollectionAdjustment event={event} isLastEvent={isLastEvent} />;
    case 'stripe_invoice_created':
      return <StripeInvoiceCreated event={event} isLastEvent={isLastEvent} />;
    case 'stripe_charge_succeeded':
      return <StripeChargeSucceeded event={event} isLastEvent={isLastEvent} />;
    case 'stripe_invoice_finalized':
      return <StripeInvoiceFinalized event={event} isLastEvent={isLastEvent} />;
    case 'stripe_payment_method_updated':
      return <StripePaymentMethodUpdated event={event} isLastEvent={isLastEvent} />;
    case 'stripe_invoice_deleted':
      return <StripeInvoiceDeleted event={event} isLastEvent={isLastEvent} />;
    case 'stripe_charge_failed':
      return <StripeChargeFailed event={event} isLastEvent={isLastEvent} />;
    case 'stripe_invoice_payment_failed':
      return <StripeInvoicePaymentFailed event={event} isLastEvent={isLastEvent} />;
    case 'stripe_payment_method_attached':
      return <StripePaymentMethodAttached event={event} isLastEvent={isLastEvent} />;
    case 'stripe_invoice_marked_uncollectible':
      return <StripeInvoiceMarkedUncollectible event={event} isLastEvent={isLastEvent} />;
    case 'stripe_invoice_overdue':
      return <StripeInvoiceOverdue event={event} isLastEvent={isLastEvent} />;
    case 'stripe_invoice_paid':
      return <StripeInvoicePaid event={event} isLastEvent={isLastEvent} />;
    case 'stripe_refund_created':
      return <StripeRefundCreated event={event} isLastEvent={isLastEvent} />;

    default:
      return <EventFallback event={event} isLastEvent={isLastEvent} />;
  }
};
