import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { dynamicHttp } from '@expressable/utils';
import { presentMutationError, useToast } from '@expressable/ui-library';

const httpHealthRecordProxy = dynamicHttp('health-record-proxy');

const getPrimaryPurpose = async (clientId: string) => {
  const { data } = await httpHealthRecordProxy.get(`/care-plans/therapy-purpose/${clientId}`);
  return data;
};

type GetDischargePlanPayload = {
  clientId: string;
  details: string;
};

export function useGetPrimaryPurpose(
  { clientId }: { clientId: string },
  options?: UseQueryOptions<string, unknown, string>,
) {
  return useQuery<GetDischargePlanPayload | null>(['therapy-purpose', clientId], () => getPrimaryPurpose(clientId));
}

export interface SetPrimaryPurposePayload {
  clientId: string;
  details: string;
  shouldLogEvent: boolean;
}

const setPrimaryPurpose = async (payload: SetPrimaryPurposePayload) => {
  const { clientId, ...rest } = payload;
  return httpHealthRecordProxy.post(`/care-plans/therapy-purpose/${clientId}`, rest).then(res => res.data);
};

export function useSetPrimaryPurpose() {
  const queryClient = useQueryClient();
  const { successToast } = useToast();

  return useMutation(setPrimaryPurpose, {
    onSuccess: (_, payload) => {
      successToast('Primary Purpose successfully updated.');
      queryClient.invalidateQueries(['therapy-purpose', payload.clientId]);
    },
    onError: presentMutationError,
  });
}
