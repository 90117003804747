import React from 'react';
import { Button, Modal, ModalBody, ModalContent, ModalFooter } from '@expressable/ui-library';
import 'twin.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/pro-solid-svg-icons';
import { DeleteShortTermGoal, useRemoveShortTermGoal } from 'hooks/use-care-plan-goals';
import { DeleteLongTermGoal, useRemoveLongTermGoal } from 'hooks/use-care-plan-goals';

export interface DeleteGoalModalProps {
  isOpen: boolean;
  onClose: () => void;
  clientId: string;
  ltgId?: string;
  stgId?: string;
  onSubmitDeleteGoal: () => void;
}

export function DeleteGoalModal(props: DeleteGoalModalProps) {
  const { isOpen, onClose, clientId, ltgId, stgId, onSubmitDeleteGoal } = props;
  const deleteStgMutation = useRemoveShortTermGoal();
  const { mutate: deleteStg } = deleteStgMutation;
  const deleteLtgMutation = useRemoveLongTermGoal();
  const { mutate: deleteLtg } = deleteLtgMutation;

  const handleSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    // Depending on these conditions the Ltg or Stg payload will be sent
    if (stgId && ltgId) {
      const payload: DeleteShortTermGoal = {
        clientId: clientId,
        ltgId,
        stgId,
      };
      deleteStg(payload, {
        onSuccess: () => {
          onClose();
          onSubmitDeleteGoal();
        },
      });
    } else if (ltgId) {
      const payload: DeleteLongTermGoal = {
        clientId: clientId,
        ltgId,
      };
      deleteLtg(payload, {
        onSuccess: () => {
          onClose();
          onSubmitDeleteGoal();
        },
      });
    }
  };

  return (
    <Modal portal isOpen={isOpen}>
      <ModalContent>
        <form onSubmit={handleSubmit} id="client-delete-goal-modal" data-testid="client-delete-goal-modal">
          <ModalBody>
            <div>
              <div tw="flex items-center justify-center w-12 h-12 mx-auto bg-indigo-100 rounded-full">
                <FontAwesomeIcon tw="text-2xl text-indigo-700" icon={faFileAlt} />
              </div>
              <div tw="mt-3">
                <h3 tw="text-lg text-center font-medium">Delete Goal</h3>
                <div tw="mt-2">
                  <p tw="text-sm text-center">Are you sure you want to delete this goal?</p>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div tw="mt-8 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
              <span tw="flex rounded-md shadow-sm sm:col-start-2">
                <Button
                  type="submit"
                  data-testid="delete-goal-submit"
                  variant="danger"
                  loading={deleteLtgMutation.isLoading || deleteStgMutation.isLoading}
                  tw="w-full py-2 sm:text-sm"
                >
                  Delete
                </Button>
              </span>
              <span tw="flex mt-3 rounded-md shadow-sm sm:mt-0 sm:col-start-1">
                <Button
                  data-testid="delete-goal-close"
                  type="button"
                  variant="secondary"
                  tw="w-full py-2 sm:text-sm"
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </span>
            </div>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
