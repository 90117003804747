import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  CloseButton,
  Label,
  Input,
  Card,
  ModalFooter,
  Button,
  Loader,
  Select,
} from '@expressable/ui-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ContentfulSpaces, useContentfulEntries } from 'hooks/use-contentful';
import 'twin.macro';
import CardList from 'components/card-list';
import { useDebouncedCallback } from 'use-debounce';
import { getLearningPathLink } from 'domain/clinical-pathways/contants';
import { LearningPathContentful, TreatmentAreaCategoryContentful } from 'domain/clinical-pathways/types';
import { SelectOption } from 'types';

// builder function that creates a learning path params object based on filters
export const buildContentfulLearningPathsQuery = ({
  searchKey,
  treatmentArea,
}: {
  searchKey: string;
  treatmentArea?: string | string[];
}) => {
  return {
    ['query']: searchKey ?? '',
    [Array.isArray(treatmentArea) && treatmentArea.length > 1
      ? 'fields.category.sys.id[in]'
      : 'fields.category.sys.id']: Array.isArray(treatmentArea) ? treatmentArea.join(',') : treatmentArea,
  };
};

interface Props {
  currentTherapyStrategyIds: string[];
  isOpen: boolean;
  onClose: () => void;
  onSave: (learningPaths: LearningPathContentful[]) => void;
}

export function AddTherapyStrategyModal({ currentTherapyStrategyIds = [], isOpen, onClose, onSave }: Props) {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTreatmentArea, setSelectedTreatmentArea] = useState<SelectOption>();
  const [selectedItems, setSelectedItems] = useState<LearningPathContentful[]>([]);

  // it gets learning paths from contentful
  const {
    data: learningPathsContentful = [],
    isLoading: isContentfulLoading,
    refetch: refetchLearningPathsContentful,
  } = useContentfulEntries<LearningPathContentful[]>({
    contentType: 'learningPaths',
    space: ContentfulSpaces.ClinicalCareExperience,
    limit: 400,
    order: ['fields.title'],
    params: buildContentfulLearningPathsQuery({ searchKey: searchTerm, treatmentArea: selectedTreatmentArea?.value }),
  });

  // it gets treatment areas from contentful
  const { data: treatmentAreasContentful = [], isLoading: isTreatmentAreasContentfulLoading } = useContentfulEntries<
    TreatmentAreaCategoryContentful[]
  >({
    contentType: 'treatmentArea',
    space: ContentfulSpaces.ClinicalCareExperience,
    limit: 400,
    order: ['fields.treatmentAreaCategory'],
  });

  const onCloseHandler = () => {
    setSearchTerm('');
    setSelectedTreatmentArea(undefined);
    setSelectedItems([]);
    onClose();
  };

  const submit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    onCloseHandler();
    onSave([...selectedItems]);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, learningPath: LearningPathContentful) => {
    if (event.target.checked) {
      setSelectedItems([...selectedItems, { ...learningPath }]);
    } else {
      setSelectedItems([...selectedItems.filter(item => item.id !== learningPath.id)]);
    }
  };

  const learningPathsNotSelected = learningPathsContentful.filter(item => !currentTherapyStrategyIds.includes(item.id));

  useEffect(() => {
    refetchLearningPathsContentful();
  }, [searchTerm, selectedTreatmentArea]);

  const setSearchTermDebounced = useDebouncedCallback(value => {
    setSearchTerm(value);
  }, 500);

  useEffect(() => {
    setSearchTerm('');
    setSelectedTreatmentArea(undefined);
    setSelectedItems([]);
  }, [isOpen]);

  const totalSelected = selectedItems?.length ?? 0;

  if (isContentfulLoading || isTreatmentAreasContentfulLoading) {
    return (
      <div tw="flex justify-center items-center">
        <Loader type="ring" />
      </div>
    );
  }

  return (
    <Modal portal isOpen={isOpen}>
      <ModalContent>
        <form onSubmit={submit} data-testid="form">
          <ModalHeader>
            <div tw="flex flex-col items-center justify-center mb-8">
              <div tw="flex items-center justify-center w-12 h-12 mx-auto bg-indigo-100 rounded-full mb-5">
                <FontAwesomeIcon tw="text-2xl text-indigo-700" icon="file-alt" />
              </div>
              <h3 tw="text-lg text-center font-medium text-gray-900 leading-6 mb-5" id="modal-headline">
                Add Therapy Strategy
              </h3>
              <h4 tw="text-gray-900 text-center">
                Therapy strategies are techniques used to build skills and support progress toward speech therapy goals.
              </h4>
            </div>
            <CloseButton onClick={() => onCloseHandler()} />
          </ModalHeader>
          <ModalBody>
            <div tw="mb-5">
              <div tw="ml-1 mr-1 mb-5">
                <input
                  tw="p-3 outline-none w-full border border-gray-300 rounded-md focus:border-indigo-300"
                  onChange={event => setSearchTermDebounced(event.target.value)}
                  type="text"
                  placeholder={'Search'}
                  aria-label="Search Therapy Strategy"
                  autoFocus
                  defaultValue={searchTerm}
                  data-testid="search-bar-input"
                />
              </div>

              <div tw="ml-1 mr-1 mb-7">
                <Label htmlFor="treatmentArea" tw="font-medium text-sm">
                  Treatment Area
                </Label>
                <Select
                  inputId="treatmentArea"
                  options={treatmentAreasContentful.map(item => {
                    return {
                      value: item.id,
                      label: item.treatmentAreaCategory,
                    } as SelectOption;
                  })}
                  onChange={(e: SelectOption) => setSelectedTreatmentArea(e)}
                  isSearchable
                  isClearable
                />
              </div>

              {learningPathsNotSelected?.length ? (
                <div tw="w-full overflow-auto h-96 border-none pb-2" data-testid="learning-path-search">
                  <CardList
                    data={learningPathsNotSelected ?? []}
                    render={(learningPath: LearningPathContentful) => (
                      <div key={learningPath.id} tw="ml-1 mr-1 mb-5 last:mb-0">
                        <Card tw="p-6 border border-gray-300">
                          <div tw="flex mb-3">
                            <Input
                              type="checkbox"
                              id={`learning-path-${learningPath.id}`}
                              onChange={e => handleCheckboxChange(e, learningPath)}
                              checked={!!selectedItems?.find(item => item.id === learningPath.id)}
                              data-testid="therapy-strategy-card"
                              spacing="tight"
                              tw="flex mr-2 mt-0.5 cursor-pointer"
                            />
                            <Label htmlFor={`learning-path-${learningPath.id}`} tw="font-medium text-sm cursor-pointer">
                              {learningPath.title} (
                              {learningPath.category?.[0]?.treatmentAreaCategory || 'MISSING CATEGORY'})
                            </Label>
                          </div>
                          <a
                            tw="text-indigo-700 font-medium"
                            href={getLearningPathLink(learningPath.id)}
                            target="_blank"
                            rel="noreferrer"
                          >
                            View Learning Path
                          </a>
                        </Card>
                      </div>
                    )}
                  />
                </div>
              ) : (
                <div tw="text-sm text-gray-400">No learning paths match your current filters</div>
              )}
            </div>
            <ModalFooter>
              <Button
                type="submit"
                data-testid="add-therapy-strategy-submit"
                variant="primary"
                className={`inline-flex justify-center w-full ${!totalSelected && 'cursor-not-allowed'}`}
                disabled={totalSelected <= 0}
              >
                {totalSelected
                  ? `Add ${totalSelected} Therapy ${totalSelected > 1 ? 'Strategies' : 'Strategy'}`
                  : 'Please Select At Least One'}
              </Button>
            </ModalFooter>
          </ModalBody>
        </form>
      </ModalContent>
    </Modal>
  );
}
