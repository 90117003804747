import { DischargeNoteFormProps } from 'pages/client/components/client-notes/discharge-note/discharge-note-form';
import {
  Appointment,
  Chart,
  Admin,
  AppointmentNoteFreeText,
  AppointmentNoteSoap,
  AppointmentNoteEvaluation,
  AppointmentNoteSession,
  Activity,
  AppointmentNoteType,
  ScreeningNote,
  Discharge,
} from './types';

import { ChartNoteFormProps, AdminNoteFormProps, AppointmentNoteFormProps } from 'pages/client/components/client-notes';

export function isChartNote(note: Activity | null): note is Chart {
  return note?.noteType === 'chart';
}

export function isAdminNote(note: Activity | null): note is Admin {
  return note?.noteType === 'admin';
}

export function isAppointmentNote(note: Activity | null): note is Appointment {
  return note?.noteType === 'appointment' || note?.noteType === 'screening';
}

export function isDischargeNote(note: Activity | null): note is Discharge {
  return note?.noteType === 'discharge';
}

export type NoteFormProps = ChartNoteFormProps | AdminNoteFormProps | AppointmentNoteFormProps | DischargeNoteFormProps;

export function areChartNoteFormProps(props: NoteFormProps): props is ChartNoteFormProps {
  return isChartNote(props.activity);
}

export function areAdminNoteFormProps(props: NoteFormProps): props is AdminNoteFormProps {
  return isAdminNote(props.activity);
}

export function areAppointmentNoteFormProps(props: NoteFormProps): props is AppointmentNoteFormProps {
  return isAppointmentNote(props.activity);
}

export function areDischargeNoteFormProps(props: NoteFormProps): props is DischargeNoteFormProps {
  return isDischargeNote(props.activity);
}

export function isAppointmentNoteFreeText(note: AppointmentNoteType): note is AppointmentNoteFreeText {
  return note.appointmentNoteType === 'free-text';
}

export function isAppointmentNoteSoap(note: AppointmentNoteType): note is AppointmentNoteSoap {
  return note.appointmentNoteType === 'SOAP';
}

export function isAppointmentNoteSession(note: AppointmentNoteType): note is AppointmentNoteSession {
  return note.appointmentNoteType === 'session-note';
}

export function isAppointmentNoteEvaluation(note: AppointmentNoteType): note is AppointmentNoteEvaluation {
  return note.appointmentNoteType === 'evaluation-note';
}

export function isScreeningNote(note: AppointmentNoteType): note is ScreeningNote {
  return note.appointmentNoteType === undefined;
}
