import React, { useEffect, useRef } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import useCreateTherapist from 'hooks/use-create-therapist-info';
import { CompleteTherapistInformation } from 'types';
import TherapistInfoForm from '../components/edit-therapist-info-form';
import { Container, Spacer, TitleBar } from '@expressable/ui-library';
import 'twin.macro';
import { Helmet } from 'react-helmet';
import usePermissions from 'hooks/use-permissions';

const TherapistCreate = (props: RouteComponentProps) => {
  const { history } = props;
  const createTherapistMutation = useCreateTherapist();
  const { mutate: createTherapist } = createTherapistMutation;
  const { isAdminOrClinicalManager } = usePermissions();

  const therapistData = {
    therapistEmail: '',
    sequoiaID: '',
    acuityCalendarID: '',
    firstName: '',
    preferredFirstName: '',
    lastName: '',
    therapistTimeZone: '',
    specialties: [],
    acceptingNewPatients: true,
    statesLicensed: [],
    languages: [{ code: 'en', name: 'English' }],
    therapistProfile: '',
    zoomLink: '',
    consultingTherapist: true,
    status: 'Active',
    committedHours: 0,
    therapistType: '',
    isPRN: false,
    slackUserId: '',
    useSlackConversations: true,
    aiTranscriptProcessing: true,
  };

  const onSave = (therapistInformation: CompleteTherapistInformation) => {
    createTherapist({
      therapistInformation,
      onSuccess: therapistID => {
        history.push(`/therapists/${therapistID}`);
      },
    });
  };

  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    if (!isAdminOrClinicalManager) history.push('/');
  });

  return (
    <div data-testid="therapist-create" css={{ marginBottom: 28 }}>
      <Helmet title="Create New Therapist" />
      <div tw="relative">
        <TitleBar title="Create a New Therapist" />
      </div>
      <Spacer size="md" />
      <Container tw="relative" size="large">
        <TherapistInfoForm
          isLoading={createTherapistMutation.isLoading}
          therapistData={therapistData}
          onSave={onSave}
          onCancel={() => history.push('/therapists')}
        />
      </Container>
    </div>
  );
};

export default TherapistCreate;
