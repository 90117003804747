import dayjs, { Dayjs } from 'dayjs';
import moment, { Moment } from 'moment';
import { SelectOption } from 'types';

export const timeOptions = getTimeOptions();

function getTimeOptions() {
  const items: { label: string; value: number; nonTzValue: number }[] = [];
  new Array(24).fill('').forEach((_, index) => {
    items.push({ label: moment({ hour: index }).format('h:mm A'), value: index, nonTzValue: index });
    items.push({
      label: moment({ hour: index, minute: 30 }).format('h:mm A'),
      value: index + 0.5,
      nonTzValue: index + 0.5,
    });
  });
  return items;
}

export function getSelectedTime(time: Moment): { label: string; value: number } {
  return timeOptions.find(option => option.value === time.hours() + time.minutes() / 60)!;
}

export function getSelectedTimeDayJs(time: Dayjs | string): { label: string; value: number } {
  return timeOptions.find(option => option.value === dayjs(time).hour() + dayjs(time).minute() / 60)!;
}

/**
 * Array of select options for all hours in increments of 15 minutes.
 *
 * Each item is formatted as `{ label: '8:00 PM', value: '20:00' }`.
 */
export const hhmmTimeOptions: SelectOption[] = Array.from(Array(24), (_, hour) => {
  return [0, 15, 30, 45].map(minute => moment({ hour, minute }));
})
  .flat()
  .map(m => ({ label: m.format('h:mm A'), value: m.format('HH:mm') }));
