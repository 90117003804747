import { useQuery } from 'react-query';
import { dynamicHttp } from '@expressable/utils';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const httpHealthRecord = dynamicHttp('health-record');

export interface HourlyReporting {
  directTimeMinutes: number;
  anticipatedMinutesWorkedLow: number;
  anticipatedMinutesWorkedHigh: number;
  documentationMinutesLow: number;
  documentationMinutesHigh: number;
  otherIndirectMinutesLow: number;
  otherIndirectMinutesHigh: number;
  evalsCompletedCount: number;
  sessionsCompletedCount: number;
}

export interface TherapistPayroll {
  differentialHours: number;
  afternoonAppointment: number;
  medicaidDocumentationHours: 0;
  updatedAt: string;
  weekendAppointment: number;
  sk: string;
  end: string;
  pk: string;
  adjustedDurationHours: number;
  beginning: string;
  hourlyReporting?: HourlyReporting;
}

export interface TherapistPayrollNote {
  noteID: string;
  createdAt: string;
  sessionType: string;
  eventID: string;
  eventType: string;
  adjustedDurationHours: number;
  differentialHours: number;
  clientID: string;
  clientFirstName: string;
  clientLastName: string;
  appointmentOn: string;
  afternoonAppointment: boolean;
  weekendAppointment: string;
  duration: number;
}

const getTherapistPayrollAggregates = async (therapistId: string) => {
  const { data } = await httpHealthRecord.get(`/therapists/${therapistId}/reporting/payroll`, {
    params: { type: 'aggregates' },
  });

  return data;
};

export const useGetTherapistPayrollAggregates = (therapistId: string) =>
  useQuery<TherapistPayroll[]>(['therapist-payroll-aggregates', therapistId], () =>
    getTherapistPayrollAggregates(therapistId),
  );

const getTherapistPayrollWeek = async (therapistId: string, week: string | undefined) => {
  if (!week) {
    return [];
  }

  const { data } = await httpHealthRecord.get(`/therapists/${therapistId}/reporting/payroll`, {
    params: { type: 'week', week },
  });

  return data;
};

export const useGetTherapistPayrollWeek = (therapistId: string, week: string | undefined) =>
  useQuery<TherapistPayrollNote[]>(['therapist-payroll-aggregates', therapistId, week || ''], () =>
    getTherapistPayrollWeek(therapistId, week),
  { placeholderData: [] });
