import { Button, Card } from '@expressable/ui-library';
import 'twin.macro';
import ScheduleSummary from '../ScheduleSummary';
import {
  NotSkippedScheduledSessions,
  ScheduledSessions,
  SkippedScheduledSessions,
} from '../SchedulingSessions/schedule-sessions';

interface ScheduleSummaryFormProps {
  scheduledSessionsInfo: ScheduledSessions;
  onConfirm: () => void;
}

function hasTherapist(scheduledSessionsInfo: SkippedScheduledSessions) {
  return Boolean(!!scheduledSessionsInfo.therapist);
}

function hasRecurringSessions(scheduledSessionsInfo: NotSkippedScheduledSessions) {
  if (scheduledSessionsInfo?.firstRecurringSessionInfo?.recurringOption === 'EvaluationOnly') {
    return true;
  }

  if (scheduledSessionsInfo?.firstRecurringSessionInfo?.timestamp) {
    if (
      scheduledSessionsInfo &&
      scheduledSessionsInfo?.secondRecurringSessionInfo?.recurringOption === 'NotSchedulingSession'
    ) {
      return (
        scheduledSessionsInfo.secondRecurringSessionInfo.notSchedulingDescription &&
        scheduledSessionsInfo.secondRecurringSessionInfo.notSchedulingReason
      );
    } else {
      return (
        scheduledSessionsInfo?.firstRecurringSessionInfo?.recurringOption &&
        scheduledSessionsInfo?.secondRecurringSessionInfo?.recurringOption &&
        scheduledSessionsInfo?.firstRecurringSessionInfo?.timestamp?.timestamp &&
        scheduledSessionsInfo?.secondRecurringSessionInfo?.timestamp?.timestamp
      );
    }
  }
}

function canConfirmSession(scheduledSessionsInfo: ScheduledSessions) {
  if (scheduledSessionsInfo.skipped) {
    return hasTherapist(scheduledSessionsInfo);
  } else {
    return scheduledSessionsInfo?.evaluationTime && hasRecurringSessions(scheduledSessionsInfo);
  }
}

const ScheduleSummaryForm = ({ scheduledSessionsInfo, onConfirm }: ScheduleSummaryFormProps) => {
  const canConfirm = canConfirmSession(scheduledSessionsInfo);
  return (
    <Card tw="flex flex-col gap-5">
      <div tw="font-semibold text-lg">Schedule Summary</div>

      <ScheduleSummary scheduledSessionsInfo={scheduledSessionsInfo} />

      <div tw="mt-1.5">
        <Button variant="primary" onClick={() => onConfirm()} disabled={!canConfirm} data-testid="confirm-schedule-button">
          {scheduledSessionsInfo.skipped ? 'Skip Scheduling' : 'Schedule Sessions'}
        </Button>
      </div>
    </Card>
  );
};

export default ScheduleSummaryForm;
