import { useMutation, useQuery, useQueryClient } from 'react-query';
import { dynamicHttp } from '@expressable/utils';
import { useToasts } from 'react-toast-notifications';
import { presentMutationError } from '@expressable/ui-library';

const httpHealthRecordProxy = dynamicHttp('health-record-proxy');

export interface GetEndDatePayload {
  date: string;
}

export interface SetEndDatePayload {
  clientId: string;
  details: string;
  shouldLogEvent: boolean;
}

const getEndDate = async (clientId: string) => {
  const { data } = await httpHealthRecordProxy.get(`/care-plans/end-date/${clientId}`);
  return data;
};

export function useGetPlanOfCareEndDate({ clientId }: { clientId: string }) {
  return useQuery<GetEndDatePayload | null>(['care-plan-end-date', clientId], () => getEndDate(clientId));
}

const setEndDate = async ({ clientId, ...rest }: SetEndDatePayload) => {
  return httpHealthRecordProxy
    .post(`/care-plans/end-date/${clientId}`, { date: rest.details, shouldLogEvent: rest.shouldLogEvent })
    .then(res => res.data);
};

export function useSetEndDate() {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(setEndDate, {
    onSuccess: (_, payload) => {
      addToast('End date Successfully Updated', { appearance: 'success', autoDismiss: true });
      queryClient.invalidateQueries(['care-plan-end-date', payload.clientId]);
    },
    onError: presentMutationError,
  });
}
