export const downloadCSV = (csvData: string, filename = 'data.csv'): void => {
  const blob = new Blob([csvData], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = url;
  link.download = filename;
  document.body.appendChild(link);

  link.click();
  document.body.removeChild(link);
};
