import { useQuery, useMutation, useQueryClient } from 'react-query';
import { dynamicHttp } from '@expressable/utils';
import { useToasts } from 'react-toast-notifications';
import * as Sentry from '@sentry/react';

const httpHealthRecordProxy = dynamicHttp('health-record-proxy');

const getClaim = async (claimId: string | null) => {
  const { data } = await httpHealthRecordProxy.get(`/claims?id=${claimId}`);
  return data;
};

export function useClaim(claimId: string | null) {
  return useQuery(['claim', claimId], () => getClaim(claimId));
}

interface IClaimAddress {
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zip_code: string;
}

interface IClaimClient {
  clientID: string;
  firstName: string;
  lastName: string;
  dob: string;
  gender: string;
  address: IClaimAddress;
}

interface IClaimSubscriber extends Omit<IClaimClient, 'clientID'> {
  relationshipToClient: string;
}

interface IDiagnosis {
  name: string | undefined;
  code: string;
  codeType: string;
}

interface IServiceLine {
  procedureCode: string;
  quantity: string;
  units: string;
  modifiers: string[];
  chargeAmountCents: number;
  diagnosisPointers: number[];
}

export interface CreateClaimPayload {
  claimID: string;
  encounterID: string;
  dos: string;
  renderingProvider: {
    firstName: string;
    lastName: string;
    npi: string;
  };
  client: IClaimClient;
  subscriberPrimary: IClaimSubscriber;
  insuranceInformation: {
    memberID: string;
    groupNumber: string;
    planName: string;
    planType: string;
    payerName: string;
    payerID: string;
  };
  diagnoses: IDiagnosis[];
  serviceLines: IServiceLine[];
  billingNotes:
    | [
        {
          text: string;
        },
      ]
    | [];
}

interface IHttpError {
  response: {
    data: Array<{
      keyword: number;
      message: { code: string; description: string } | Array<{ loc: Array<string>; msg: string }>;
    }>;
  };
}

const createClaim = async (payload: CreateClaimPayload) => {
  return httpHealthRecordProxy.post(`/claims/create`, payload).then(res => res.data);
};

export function useCreateClaim() {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(createClaim, {
    onSuccess: response => {
      addToast('Claim Successfully Submited', { appearance: 'success', autoDismiss: true });
      queryClient.invalidateQueries(['claim', response]);
    },
    onError: (err, payload) => {
      let errorMessage = 'Something Went Wrong';
      if (Array.isArray((err as IHttpError).response.data[0].message)) {
        const error = ((err as IHttpError).response.data[0].message as Array<{ loc: string[]; msg: string }>)[0];
        errorMessage = error === undefined ? errorMessage : `${error.loc[2]}: ${error.msg}`;
      } else {
        const error = ((err as IHttpError).response.data[0].message as { code: string; description: string })
          .description;
        errorMessage = error === undefined ? errorMessage : error;
      }

      addToast(errorMessage, {
        appearance: 'error',
        autoDismiss: true,
      });

      Sentry.captureException(err as Error, {
        extra: {
          payload: JSON.stringify(payload),
        },
      });

      console.error(`there was an error in the following query  ${err}`);
    },
  });
}
