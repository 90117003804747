import { Card, Container, FormGroup, Label, Link, Loader, Select, Input } from '@expressable/ui-library';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import DatePicker from 'components/Forms/DatePicker';
import Table from 'components/Table';
import dayjs, { Dayjs } from 'dayjs';
import { getAdminScheduleConflictsParams, ScheduleConflict, useAdminScheduleConflicts } from 'hooks/use-admin-schedule-conflicts';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { InfinitePaginationButton } from 'pages/client/components/infinite-pagination-button';
import { useEffect, useMemo, useState } from 'react';
import 'twin.macro';
import { SelectOption } from 'types';

const ScheduleConflicts = () => {
  const [selectedStartDate, setSelectedStartDate] = useState<Dayjs>(dayjs().startOf('day'));
  const [selectedStartDateFocused, setSelectedStartDateFocused] = useState(false);
  const [onlySessions, setOnlySessions] = useState(false);

  const [selectedTherapist, setSelectedTherapist] = useState<SelectOption>(DEFAULT_THERAPIST_FILTER);
  const [selectedDate, setSelectedDate] = useState<SelectOption>(DEFAULT_DATE_FILTER);

  const {
    data: scheduleConflictsPages,
    isLoading,
    isError,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
    isRefetching,
  } = useAdminScheduleConflicts({
    startDate: selectedStartDate,
    includeTypes: onlySessions
      ? ['speech-therapy-session', 'evaluation', 'free-consultation', 'initial-consult']
      : undefined,
  });
  const scheduleConflicts = useMemo(
    () => scheduleConflictsPages?.pages?.flat() ?? [],
    [scheduleConflictsPages],
  );
  const { cueEnableScheduleConflicts } = useFlags();

  const resetFilters = () => {
    setSelectedTherapist(DEFAULT_THERAPIST_FILTER);
    setSelectedDate(DEFAULT_DATE_FILTER);
    setOnlySessions(false);
  };

  useEffect(() => {
    // Force reload anytime "Hide conflicts" change
    refetch();
  }, [onlySessions]);

  const filteredResults = useMemo(() => {
    const results = [...scheduleConflicts].filter(
      item =>
        item.therapistID === (selectedTherapist.value || item.therapistID) &&
        (!selectedDate.value ||
          dayjs(item.appointmentOn).startOf('day').valueOf() === dayjs(selectedDate.value).startOf('day').valueOf()),
    );

    results.sort((a, b) => {
      const dateComparison = dayjs(a.appointmentOn).diff(dayjs(b.appointmentOn));
      if (dateComparison !== 0) {
        return dateComparison;
      }

      return a.therapistName.localeCompare(b.therapistName);
    });

    return results;
  }, [scheduleConflicts, selectedTherapist, selectedDate]);

  const therapistsOptions = useMemo(() => [DEFAULT_THERAPIST_FILTER, ...getUniqueTherapists(scheduleConflicts)], [scheduleConflicts]);
  const datesOptions = useMemo(() => [DEFAULT_DATE_FILTER, ...getUniqueDates(scheduleConflicts, selectedTherapist)], [scheduleConflicts, selectedTherapist]);
  const hasAnyFilter = !!selectedTherapist.value || !!selectedDate.value || onlySessions;

  if (!cueEnableScheduleConflicts) {
    return <div>This feature is not allowed for this user.</div>;
  }

  if (isError) {
    return (
      <Container data-testid="schedule-conflicts-error" className="relative pl-0" size="large">
        <div data-testid="schedule-conflicts-error-title" className="flex justify-between items-center flex-wrap">
          <h2 className="text-2xl font-semibold mb-3">Schedule Conflicts</h2>
        </div>
        <Card className="mb-4 px-8 mt-8">
          <FormGroup className="w-full sm:w-1/2 md:w-1/3">
            <Label>
              <FontAwesomeIcon icon={faExclamationTriangle} className="text-red-500 mr-2" />
              <span className="text-red-500">Schedule conflicts are not available at this time.</span>
            </Label>
          </FormGroup>
        </Card>
      </Container>
    );
  }

  let dateRange: string;
  if (selectedDate && selectedDate.value) {
    dateRange = `Displaying ${filteredResults.length} conflicts on ${dayjs(selectedDate.value).format('MM/DD/YYYY')}`;
  } else {
    dateRange = `Displaying ${filteredResults.length} conflicts from ${selectedStartDate.format('MM/DD/YYYY')} to
      ${getAdminScheduleConflictsParams({
        startDate: selectedStartDate,
        pageParam: (scheduleConflictsPages?.pages.length ?? 1) - 1,
      }).toDate.format('MM/DD/YYYY')}`;
  }

  return (
    <div>
      <div className="md:pr-5" data-testid="schedule-conflicts">
        <div className="flex justify-between items-center flex-wrap">
          <h2 className="text-2xl font-semibold mb-3">Schedule Conflicts</h2>
        </div>
        <Card className="mt-2">
          <div className="mb-4 sm:mb-0">
            <div className="flex flex-col lg:flex-row mb-4 gap-8">
              <div className="flex flex-col items-start mr-2 mt-4 font-normal">
                <Label htmlFor="selectedStartDate" className="font-semibold text-sm mb-2">
                  Load Conflicts Starting On
                </Label>
                <DatePicker
                  noBorder
                  block
                  openDirection="down"
                  numberOfMonths={1}
                  small
                  id="selectedStartDate"
                  placeholder=""
                  date={selectedStartDate}
                  onDateChange={date => {
                    setSelectedStartDate(date ?? dayjs());
                  }}
                  focused={selectedStartDateFocused}
                  onFocusChange={({ focused }) => setSelectedStartDateFocused(focused)}
                  disabled={isLoading || isFetchingNextPage || isRefetching}
                />
              </div>
            </div>
          </div>

          <div className="mb-4 sm:mb-0">
            <div className="flex flex-col lg:flex-row mb-4">
              <div className="flex items-center mr-2 mt-4 font-normal">
                <Select
                  className="w-full sm:w-96"
                  id="selectedTherapist"
                  placeholder=""
                  spacing="tight"
                  value={selectedTherapist}
                  options={therapistsOptions}
                  onChange={(selectOption: SelectOption) => {
                    setSelectedTherapist(selectOption);
                  }}
                  disabled={isLoading || isFetchingNextPage || isRefetching}
                />
              </div>
              <div className="flex items-center mr-2 lg:ml-2 mt-4 font-normal">
                <Select
                  className="w-full sm:w-96"
                  id="selectedDate"
                  placeholder=""
                  spacing="tight"
                  value={selectedDate}
                  options={datesOptions}
                  onChange={(selectOption: SelectOption) => {
                    setSelectedDate(selectOption);
                  }}
                  disabled={isLoading || isFetchingNextPage || isRefetching}
                />
              </div>
              <div className="flex gap-2 font-normal items-center lg:ml-3 mt-3">
                <Input
                  data-testid="only-sessions"
                  name="only-sessions"
                  id="only-sessions"
                  type="checkbox"
                  spacing="tight"
                  checked={onlySessions}
                  onChange={e => setOnlySessions(e.currentTarget.checked)}
                  disabled={isLoading || isFetchingNextPage || isRefetching}
                  tw="mb-1"
                />
                <Label htmlFor="only-sessions" className="font-semibold text-sm mb-0">
                  Hide conflicts due to admin blocks/PTO
                </Label>
              </div>

              <span
                onClick={() => resetFilters()}
                className={cx('text-sm lg:ml-6 mt-4 lg:mt-6 text-indigo-700 font-normal', {
                  'opacity-100': hasAnyFilter,
                  'opacity-50': !hasAnyFilter,
                  'cursor-pointer': hasAnyFilter,
                })}
              >
                Clear Filters
              </span>
            </div>
          </div>

          {((isLoading || isRefetching) && !isFetchingNextPage) ? (
            <div data-testid="schedule-conflicts-loader" className="flex-1 text-center py-20">
              <Loader type="ring" />
            </div>
          ) : (
            filteredResults.length ? (
              <>
                <div tw="mt-10 mb-10 text-center">
                  {dateRange}
                </div>

                <Table className="text-xs sm:text-sm md:text-base">
                  <Table.Header>
                    <Table.Row>
                      <Table.Cell className="hidden sm:table-cell">Date</Table.Cell>
                      <Table.Cell>Time</Table.Cell>
                      <Table.Cell>Conflicts</Table.Cell>
                      <Table.Cell>Therapist</Table.Cell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {filteredResults.map((item, index) => (
                      <Table.Row key={index}>
                        <Table.Cell className="hidden sm:table-cell">
                          {dayjs(item.appointmentOn).format('MM/DD/YYYY')}
                        </Table.Cell>
                        <Table.Cell>{dayjs(item.appointmentOn).format('hh:mm A')}</Table.Cell>
                        <Table.Cell>
                          <TotalConflicts value={item.totalConflicts} />
                        </Table.Cell>
                        <Table.Cell className="text-indigo-700 font-semibold">
                          <Link
                            target="_blank"
                            to={{ pathname: `/therapists/${item.therapistID}#schedule-conflicts` }}
                          >
                            {item.therapistName}
                          </Link>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>

                <InfinitePaginationButton isFetchingNextPage={isFetchingNextPage} fetchNextPage={fetchNextPage} />
              </>
            ) : (
              <span className="ml-2">There are no schedule conflicts to show.</span>
            )
          )}
        </Card>
      </div>
    </div>
  );
};

export default ScheduleConflicts;

const DEFAULT_THERAPIST_FILTER: SelectOption = {
  value: '',
  label: 'All Therapists',
};

const DEFAULT_DATE_FILTER: SelectOption = {
  value: '',
  label: 'All Dates',
};

export const TotalConflicts = ({ value }: { value: number }) => {
  return (
    <div
      className={`${
        value <= 1 ? 'bg-yellow-200 text-yellow-900' : 'bg-red-100 text-red-700'
      } flex text-xxs sm:text-xs font-semibold justify-center items-center h-4 w-max pl-1.5 pr-1.5 rounded-full uppercase whitespace-nowrap`}
    >
      {`${value} ${value > 1 ? 'conflicts' : 'conflict'}`}
    </div>
  );
};

const getUniqueTherapists = (scheduleConflicts: ScheduleConflict[]): SelectOption[] => {
  const availableTherapistsMap = [
    ...new Map(scheduleConflicts.map(item => [item.therapistID, item.therapistName])).entries(),
  ];

  const availableTherapists = availableTherapistsMap.map(([therapistID, therapistName]) => {
    return {
      label: therapistName,
      value: therapistID,
    };
  });

  availableTherapists.sort((a, b) => a.label.localeCompare(b.label));

  return availableTherapists;
};

const getUniqueDates = (scheduleConflicts: ScheduleConflict[], selectedTherapist: SelectOption): SelectOption[] => {
  const availableDates = [
    ...new Set(
      scheduleConflicts
        .filter(item => item.therapistID === (selectedTherapist.value || item.therapistID))
        .map(item => {
          return dayjs(item.appointmentOn).startOf('day').toISOString();
        }),
    ).values(),
  ];

  availableDates.sort((a, b) => dayjs(a).diff(dayjs(b)));

  return availableDates.map(date => ({
    label: dayjs(date).format('MM/DD/YYYY'),
    value: date,
  }));
};
