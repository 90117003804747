import { FormGroup } from '@expressable/ui-library';
import RHFSelect, { RHFSelectMultiple } from 'components/RHFSelectBase';
import { SelectFilterProps } from 'domain/client-resources/types';

import 'twin.macro';

export const ResourceSelectFilter = ({
  control,
  options,
  title,
  inputControlName,
  placeholder,
  defaultValue,
  disabled,
  isMulti = true,
}: SelectFilterProps) => {
  return (
    <FormGroup>
      <h4 tw="mb-2 text-sm font-semibold" data-testid="resource-select-filter__title">
        {title}
      </h4>
      {isMulti ? (
        <RHFSelectMultiple
          name={inputControlName}
          control={control}
          options={options}
          placeholder={placeholder}
          defaultValue={defaultValue}
          disabled={disabled}
        />
      ) : (
        <RHFSelect name={inputControlName} control={control} options={options} placeholder={placeholder} />
      )}
    </FormGroup>
  );
};
