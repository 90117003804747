import React from 'react';
import { Button, Card, Input, Label, Spacer, Select } from '@expressable/ui-library';
import { useForm, Controller, useWatch } from 'react-hook-form';
import tw from 'twin.macro';
import useModifyClientInfo, { ModifyClientPayload } from 'hooks/use-modify-client-info';
import { useClient } from 'hooks/use-client';
import { months, getDays, getYears, getLastDayDate } from '@expressable/utils';
import { onlyLettersSpecialVowelsWhiteSpacesValidator, alphabeticAndParenthesesValidator } from 'utils/helpers';
import { isNumber } from 'lodash';

export interface EditClientInfoFormProps {
  clientId: string;
}

export interface EditClientInfoForm {
  clientFirstName: string;
  clientMiddleName: string;
  clientLastName: string;
  isClientMinor: boolean;
  suffix: string;
  preferredFirstName: string;
  monthBirth: { [value: string]: string };
  dayBirth: { [value: string]: string };
  yearBirth: { [value: string]: string };
  genderIdentity: string;
  sex: { [value: string]: string };
  height: number;
  weight: number;
}
const FormGroup = tw.div`mb-0`;
const FormInline = tw.div`md:flex`;

export default function ClientInfoForm(props: EditClientInfoFormProps) {
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    getValues,
  } = useForm<EditClientInfoForm>();
  const { clientId } = props;
  const { data: clientData } = useClient(clientId);
  useWatch({
    control,
    name: ['yearBirth', 'monthBirth'],
  });
  const modifyClientMutation = useModifyClientInfo();
  const { mutate: modifyClient } = modifyClientMutation;

  // eslint-disable-next-line
  const onSubmit =
    (clientId: string, useModifyClient: (payload: ModifyClientPayload) => void) => (data: EditClientInfoForm) => {
      const dob =
        data?.yearBirth?.value && data?.monthBirth?.value && data?.dayBirth?.value
          ? `${data.yearBirth.value}-${data.monthBirth.value}-${data.dayBirth.value}`
          : undefined;

      const clientInformation = {
        clientFirstName: data.clientFirstName,
        clientMiddleName: data.clientMiddleName,
        clientLastName: data.clientLastName,
        isClientMinor: data.isClientMinor,

        suffix: data?.suffix,
        preferredFirstName: data?.preferredFirstName,
        dob: dob,
        sex: data?.sex?.value || undefined,
        genderIdentity: data?.genderIdentity,
        height: data?.height,
        weight: data?.weight,
      };
      useModifyClient({ clientId, clientInformation });
    };

  const year = clientData?.dob?.split('-')[0];
  const month = clientData?.dob?.split('-')[1];
  const day = clientData?.dob?.split('-')[2];

  const selectedYear = parseInt(getValues('yearBirth')?.value);
  const selectedMonth = parseInt(getValues('monthBirth')?.value);

  const endDay = getLastDayDate(selectedYear, selectedMonth);

  const days = getDays(endDay);
  const years = getYears();
  const isPositive = (value: number) => value > 0;
  return (
    <>
      <Spacer size="md" />
      <Card tw="mb-4 px-8">
        <div tw="text-2xl font-semibold mt-4 mb-9">Client Information</div>
        <form onSubmit={handleSubmit(onSubmit(clientId, modifyClient))}>
          <FormInline tw="flex-wrap">
            <FormGroup>
              <Label htmlFor="clientFirstName">First Name</Label>
              <Input
                type="text"
                id="clientFirstName"
                data-testid="edit-client-info-form-first-name"
                tw="mr-3"
                {...register('clientFirstName', {
                  required: 'first name is required',
                  validate: { onlyLettersSpecialVowelsWhiteSpacesValidator },
                })}
                defaultValue={clientData?.clientFirstName}
                error={errors.clientFirstName}
              />
            </FormGroup>
            <FormGroup>
              <Label optional htmlFor="clientMiddleName">
                Middle Name
              </Label>
              <Input
                type="text"
                id="clientMiddleName"
                data-testid="edit-client-info-form-middle-name"
                tw="mr-3"
                {...register('clientMiddleName', { validate: { onlyLettersSpecialVowelsWhiteSpacesValidator } })}
                defaultValue={clientData?.clientMiddleName}
                error={errors.clientMiddleName}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="clientLastName">Last Name</Label>
              <Input
                type="text"
                id="clientLastName"
                data-testid="edit-client-info-form-last-name"
                tw="mr-3"
                {...register('clientLastName', {
                  required: true,
                  validate: { onlyLettersSpecialVowelsWhiteSpacesValidator },
                })}
                defaultValue={clientData?.clientLastName}
                error={errors.clientLastName}
              />
            </FormGroup>
            <FormGroup>
              <Label optional htmlFor="suffix">
                Suffix
              </Label>

              <Input
                type="text"
                id="suffix"
                data-testid="edit-client-info-form-suffix"
                tw="mr-3"
                {...register('suffix', { validate: { onlyLettersSpecialVowelsWhiteSpacesValidator } })}
                defaultValue={clientData?.suffix || ''}
                error={errors.suffix}
              />
            </FormGroup>
          </FormInline>
          <FormGroup>
            <Label optional htmlFor="preferredFirstName">
              Preferred First Name
            </Label>
            <Input
              type="text"
              id="preferredFirstName"
              data-testid="edit-client-info-form-preferred-first-name"
              {...register('preferredFirstName', { validate: { onlyLettersSpecialVowelsWhiteSpacesValidator } })}
              defaultValue={clientData?.preferredFirstName || ''}
              error={errors.preferredFirstName}
            />
          </FormGroup>
          <FormGroup>
            <Label optional htmlFor="dateOfBirth">
              Date of Birth
            </Label>
            <FormInline tw="flex">
              <div tw="w-32 mr-3" data-testid="edit-client-info-form-month-birth">
                <Controller
                  name="monthBirth"
                  control={control}
                  render={({ field }) => <Select {...field} options={months} placeholder="–" />}
                  defaultValue={
                    month
                      ? {
                          label: months.filter(item => parseInt(month) == parseInt(item.value))[0]?.label,
                          value: month,
                        }
                      : { label: '', value: '' }
                  }
                />
              </div>
              <div tw="w-20 mr-3" data-testid="edit-client-info-form-day-birth">
                <Controller
                  name="dayBirth"
                  control={control}
                  render={({ field }) => <Select {...field} options={days} placeholder="–" />}
                  defaultValue={
                    day
                      ? {
                          label: days.filter(item => day == item.value)[0]?.label as string,
                          value: day,
                        }
                      : { label: '', value: '' }
                  }
                />
              </div>
              <div tw="w-24" data-testid="edit-client-info-form-year-birth">
                <Controller
                  name="yearBirth"
                  control={control}
                  render={({ field }) => <Select {...field} options={years} placeholder="–" />}
                  defaultValue={year ? { label: year, value: year } : { label: '', value: '' }}
                />
              </div>
            </FormInline>
          </FormGroup>
          <FormGroup tw="mb-5">
            <div tw="font-semibold mb-2">Is the client a minor?</div>
            <FormInline tw="items-baseline">
              <Input
                id="isClientMinor"
                data-testid="isTheClientMinor"
                type="checkbox"
                {...register('isClientMinor')}
                defaultChecked={clientData?.isClientMinor}
                spacing="tight"
              />
              <Label htmlFor="isClientMinor" tw="ml-2 font-normal">
                Yes
              </Label>
            </FormInline>
          </FormGroup>
          <FormGroup>
            <Label htmlFor="sex" optional>
              Sex
            </Label>
            <div tw="w-32" data-testid="edit-client-info-form-sex">
              <Controller
                name="sex"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={[
                      { value: 'M', label: 'Male' },
                      { value: 'F', label: 'Female' },
                    ]}
                    placeholder="–"
                  />
                )}
                defaultValue={
                  clientData?.sex
                    ? { label: clientData.sex == 'M' ? 'Male' : 'Female', value: clientData.sex }
                    : { label: '', value: '' }
                }
              />
            </div>
          </FormGroup>
          <FormGroup tw="mb-5">
            <Label optional htmlFor="genderIdentity">
              Gender Identity
            </Label>
            <Input
              type="text"
              id="genderIdentity"
              data-testid="edit-client-info-form-gender-identify"
              {...register('genderIdentity', { validate: { alphabeticAndParenthesesValidator } })}
              defaultValue={clientData?.genderIdentity || ''}
              error={errors.genderIdentity}
            />
          </FormGroup>
          <FormInline>
            <FormGroup>
              <Label htmlFor="height" optional>
                Height (inches)
              </Label>
              <Input
                type="number"
                id="height"
                data-testid="edit-client-info-form-height"
                tw="mr-3"
                {...register('height',  {
                  valueAsNumber: true,
                  validate: e => {
                    if(!e) return true;
                    return isNumber(e) && !isPositive(e) ? 'Height must be a positive number' : true;
                  }
                })}
                defaultValue={clientData?.height}
                error={errors.height}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="weight" optional>
                Weight (lbs)
              </Label>
              <Input
                type="number"
                id="weight"
                data-testid="edit-client-info-form-weight"
                tw="mr-3"
                {...register('weight', {
                  valueAsNumber: true,
                  validate: e => {
                    if(!e) return true;
                    return isNumber(e) && !isPositive(e) ? 'Weight must be a positive number' : true;
                  }
                })}
                defaultValue={clientData?.weight}
                error={errors.weight}
              />
            </FormGroup>
          </FormInline>
          <Button
            data-testid="edit-client-info-form-save"
            variant="primary"
            loading={modifyClientMutation.isLoading}
            type="submit"
          >
            Save
          </Button>
        </form>
      </Card>
    </>
  );
}
