import { RescheduleDetailFormPayload } from './types';

export const RESCHEDULE_DETAIL_INITIAL_STATE: RescheduleDetailFormPayload = {
  status: 'rescheduled',
  therapistEmail: '',
  rescheduleDetail: {
    userTimezone: { label: '', value: '' },
    appointmentDateTime: '',
    duration: { label: '', value: '45' },
    rescheduledBy: '',
    rescheduledReason: '',
    time: { label: '', value: '' },
    rescheduleReasonOther: '',
  },
  availableTimes: true,
};

export const enum AttendanceErrorKey {
  AlreadyLogged = 'CLIENT_ATTENDANCE_ACTION_ALREADY_LOGGED',
  NotFound = 'CLIENT_ATTENDANCE_NOT_FOUND',
  ExistingPendingReschedule = 'EXISTING_PENDING_RESCHEDULE',
}
