import { UseQueryOptions, useMutation, useQuery, useQueryClient } from 'react-query';
import { http } from '@expressable/utils';
import { Activity, CptCode, EmptyNote } from '../types';
import * as Sentry from '@sentry/react';
import { presentMutationError } from '@expressable/ui-library';
import { useToasts } from 'react-toast-notifications';
import {
  CLIENT_DIAGNOSES_ERROR_CODES,
  DEFAULT_AUTODISMISS_TIMEOUT,
  ERROR_KEYWORDS_WITH_MESSAGE,
  INSURANCE_ERROR_CODES,
  INSURANCE_ERROR_AUTODISMISS_TIMEOUT,
  LOCK_ERROR_CODES_MESSSAGES,
} from 'domain/notes/constants';
import { EvaluationNoteDTO } from 'domain/notes/evaluation/3.0/types';

export const getNote = async (clientId: string, noteId: string) => {
  const { data } = await http.get(`/clients/${clientId}/note/${noteId}`);
  return data[0];
};

export function useGetNote<T>(clientId: string, noteId: string, options?: UseQueryOptions<T, unknown, T>) {
  return useQuery<T>(['client-note', noteId], () => getNote(clientId, noteId), options);
}

export interface EvaluationV3Payload {
  clientId: string;
  note: EvaluationNoteDTO;
}

export interface CreateNotePayload {
  note: Activity;
  clientId: string;
}

const createNewNote = async (payload: CreateNotePayload | EvaluationV3Payload) => {
  const { clientId, note } = payload;
  return http.post(`/clients/${clientId}/notes`, note).then(res => res.data);
};

export function useCreateNote() {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();
  return useMutation(createNewNote, {
    onSuccess: (_, newNote) => {
      const content: any = newNote.note;
      addToast('Note Successfully created', { appearance: 'success', autoDismiss: true });
      // Query Invalidations
      setTimeout(() => {
        queryClient.invalidateQueries(['credits']);
      }, 1000);
      queryClient.invalidateQueries(['client-activity', newNote.clientId]);
      queryClient.invalidateQueries(['client-note', _.noteID]);
      if (content?.note?.appointmentNoteType === "evaluation-note" && content?.version === "3.0") {
        queryClient.invalidateQueries(['care-plan-start-date', newNote?.clientId]);
        queryClient.invalidateQueries(['care-plan-end-date', newNote?.clientId]);
        queryClient.invalidateQueries(['care-plan-visit-duration', newNote?.clientId]);
      }
    },
    onError: (err, payload) => {
      const queryKey = ['client-activity', payload.clientId].join('');
      console.error(`there was an error in the following query ${queryKey} ${err}`);
    },
  });
}

export interface EmptyNotePayload {
  appointmentOn: string | undefined;
  noteType: string;
  note: EmptyNote;
  isEmptyState: boolean;
  acuityAppointmentID?: string;
  cpt?: CptCode[];
  version?: string;
}

export interface CreateEmptyNotePayload {
  clientId: string;
  data: EmptyNotePayload;
}
const createNewEmpty = async (payload: CreateEmptyNotePayload) => {
  const { clientId, data } = payload;
  return http.post(`/clients/${clientId}/notes`, data).then(res => res.data);
};

export function useCreateEmptyNote() {
  const queryClient = useQueryClient();
  return useMutation(createNewEmpty, {
    onSuccess: (_, newNote) => {
      // Query Invalidations
      setTimeout(() => {
        queryClient.invalidateQueries(['credits']);
      }, 1000);
      queryClient.invalidateQueries(['client-activity', newNote.clientId]);
      queryClient.invalidateQueries(['client-note', _.noteID]);
    },
    onError: (err, payload) => {
      const queryKey = ['client-activity', payload.clientId].join('');
      console.error(`there was an error in the following query ${queryKey} ${err}`);
    },
  });
}

export interface EditNotePayload {
  note: Activity | EvaluationNoteDTO;
  activityId: string | null;
  clientId: string;
}

const editNote = async (payload: EditNotePayload) => {
  const { clientId, activityId, note } = payload;
  return http.post(`/clients/${clientId}/activity/${activityId}`, note).then(res => res.data);
};

export function useEditNote() {
  const queryClient = useQueryClient();
  return useMutation(editNote, {
    onSuccess: (_, payload) => {
      // Query Invalidations
      queryClient.invalidateQueries(['client-activity', payload.clientId]);
      queryClient.invalidateQueries(['client-note', payload.activityId]);
    },
    onError: (err, NewNote) => {
      const queryKey = ['client-activity', NewNote.clientId].join('');
      console.error(`there was an error in the following query ${queryKey} ${err}`);
    },
  });
}

export interface LockNotePayload {
  clientId: string;
  activityId: string;
}

const lockNote = async (lockNote: LockNotePayload) => {
  const { clientId, activityId } = lockNote;
  return http.post(`/clients/${clientId}/activity/${activityId}`, { detail: { locked: true } }).then(res => {
    res.data;
  });
};

type ERROR_RESPONSE_DATA = {
  keyword: string;
  code: string;
  message: string;
};

const getLockValidationErrors = (errors: ERROR_RESPONSE_DATA[]): [string, string[]] => {
  if (Array.isArray(errors)) {
    // get from the list the possible errors and create a message based on each error code
    const formatter = new Intl.ListFormat('en', { style: 'long', type: 'conjunction' });
    const errorCodes: string[] = errors.map(error => error?.code || error?.keyword);
    const errorMessages: string[] = errors.map(error => {
      if (ERROR_KEYWORDS_WITH_MESSAGE.includes(error?.keyword) &&
        (INSURANCE_ERROR_CODES.includes(error?.code) || CLIENT_DIAGNOSES_ERROR_CODES.includes(error?.code))
      ) {
        return error.message;
      }

      return (
        LOCK_ERROR_CODES_MESSSAGES[error?.code as keyof typeof LOCK_ERROR_CODES_MESSSAGES] ||
        LOCK_ERROR_CODES_MESSSAGES[error?.keyword as keyof typeof LOCK_ERROR_CODES_MESSSAGES] ||
        'Please complete all required fields before locking'
      );
    });
    const formattedErrorMessages = formatter.format(Array.from(new Set(errorMessages)));

    return [formattedErrorMessages, errorCodes];
  }

  return ['Unknown error', []];
};

export function useLockNote() {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(lockNote, {
    onSuccess: (_, payload) => {
      // Query Invalidations
      setTimeout(() => {
        queryClient.invalidateQueries();
      }, 1000);
    },
    onError: (err, payload) => {
      // note(gcastillo): had to convert to any since AxiosError is not exportable
      const errorResponse = err as unknown as { [key: string]: any };
      const errorResponseData = errorResponse?.response?.data as unknown as ERROR_RESPONSE_DATA[];
      const [errorMessages, errorCodes] = getLockValidationErrors(errorResponseData);
      const isLongErrorMessage = errorCodes.some(errorCode =>
        INSURANCE_ERROR_CODES.includes(errorCode) || CLIENT_DIAGNOSES_ERROR_CODES.includes(errorCode)
      );

      // make the toast larger and increase dismiss timeout to 10 secs if it is a long error message
      const [autoDismissTimeout, size] = isLongErrorMessage
        ? [INSURANCE_ERROR_AUTODISMISS_TIMEOUT, 'large']
        : [DEFAULT_AUTODISMISS_TIMEOUT, undefined];

      addToast(errorMessages, { appearance: 'error', autoDismiss: true, autoDismissTimeout, size });

      Sentry.captureException(err, {
        data: {
          ...payload,
          naturalErrors: errorMessages,
        },
      });
      return new Promise(resolve => resolve(err));
    },
  });
}

export interface UnlockNotePayload {
  clientId: string;
  activityId: string;
  unlockReason?: string;
}

const unlockNote = async (unlockNote: UnlockNotePayload) => {
  const { clientId, activityId, unlockReason } = unlockNote;
  return http.post(`/clients/${clientId}/activity/${activityId}`, { detail: { locked: false, unlockReason } }).then(res => res.data);
};

export function useUnlockNote() {
  const queryClient = useQueryClient();
  return useMutation(unlockNote, {
    onSuccess: (_, payload) => {
      // Query Invalidations
      queryClient.invalidateQueries(['client-activity', payload.clientId]);
      queryClient.invalidateQueries(['client-note', payload.activityId]);
    },
    onError: (err, payload) => {
      const queryKey = ['client-activity', payload.clientId].join('');

      Sentry.captureException(err as Error, {
        data: {
          payload: JSON.stringify(payload),
        },
      });
      console.error(`there was an error in the following query ${queryKey} ${err}`);
    },
  });
}
export interface DeleteNotePayload {
  clientId: string;
  activityId: string;
}

const deleteNote = async (deleteNote: DeleteNotePayload) => {
  const { clientId, activityId } = deleteNote;
  return http.delete(`/clients/${clientId}/activity/${activityId}`).then(res => res.data);
};

export function useDeleteNote() {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation(deleteNote, {
    onSuccess: (_, payload) => {
      addToast('Note Successfully Removed', { appearance: 'success', autoDismiss: true });
      // Query Invalidations
      setTimeout(() => {
        queryClient.invalidateQueries(['credits']);
      }, 1000);
      queryClient.invalidateQueries(['client-activity', payload.clientId]);
      queryClient.invalidateQueries(['client-note', payload.activityId]);
      queryClient.invalidateQueries(['billingInfo', payload.clientId]);
    },
    onError: presentMutationError,
  });
}
export interface CreateNotePdfPayload {
  clientId: string;
  activityId: string;
}

export interface CreateNotePdfResponse {
  fileName: string;
}

const createNotePdf = async (createNotePdf: CreateNotePdfPayload) => {
  const { clientId, activityId } = createNotePdf;
  return http.post<CreateNotePdfResponse>(`/clients/${clientId}/note/${activityId}/pdf`).then(res => res.data);
};

export function useCreateNotePdf() {
  const queryClient = useQueryClient();
  return useMutation(createNotePdf, {
    onSuccess: (_, payload) => {
      queryClient.invalidateQueries(['client-files', payload.clientId]);
    },
    onError: presentMutationError,
  });
}
