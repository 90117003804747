import { dynamicHttp } from '@expressable/utils';
import { useInfiniteQuery } from 'react-query';
import dayjs, { Dayjs } from 'dayjs';

export type ScheduleConflict = {
  appointmentOn: string;
  totalConflicts: number;
  therapistID: string;
  therapistName: string;
};

const httpHealthRecordApi = dynamicHttp('health-record');

export type ScheduleConflictsType = 'speech-therapy-session' | 'evaluation' | 'free-consultation' | 'initial-consult';

const getAdminScheduleConflicts = async ({
  fromDate,
  toDate,
  includeTypes,
}: {
  fromDate: Dayjs;
  toDate: Dayjs;
  includeTypes?: ScheduleConflictsType[];
}) => {
  const { data } = await httpHealthRecordApi.get<ScheduleConflict[]>(`/admin/schedule-conflicts`, {
    params: {
      fromDate: fromDate.format('YYYY-MM-DD'),
      toDate: toDate.format('YYYY-MM-DD'),
      includeTypes: includeTypes ? includeTypes.join(',') : undefined,
    },
  });

  return data;
};

export const ADMIN_SCHEDULE_CONFLICTS_PAGE_SIZE = 7;

export const getAdminScheduleConflictsParams = ({ startDate, pageParam }: { startDate: Dayjs; pageParam: number }) => {
  const fromDate = startDate.startOf('day').add(pageParam * ADMIN_SCHEDULE_CONFLICTS_PAGE_SIZE, 'days');
  const toDate = dayjs(fromDate).add(ADMIN_SCHEDULE_CONFLICTS_PAGE_SIZE, 'days');

  return { fromDate, toDate };
};

export function useAdminScheduleConflicts({
  startDate,
  includeTypes,
}: {
  startDate: Dayjs;
  includeTypes?: ScheduleConflictsType[];
}) {
  return useInfiniteQuery(
    ['admin-schedule-conflicts', startDate.startOf('day').toISOString(), includeTypes],

    ({ pageParam = 0 }) => {
      const { fromDate, toDate } = getAdminScheduleConflictsParams({ startDate, pageParam });
      return getAdminScheduleConflicts({
        fromDate,
        toDate,
        includeTypes,
      });
    },

    {
      getNextPageParam: (_lastPage, pages) => pages.length,
    },
  );
}
