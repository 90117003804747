import 'twin.macro';

import { useEffect, useState } from 'react';
import { useSlackChannelInfo, useCreateSlackChannel, useCreateSlackChannelInvite } from 'hooks/use-slack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faClock } from '@fortawesome/free-solid-svg-icons';
import * as Sentry from '@sentry/react';
import { SlackErrorCode, getSlackError } from 'utils/slack-conversation';

interface SlackClientSidebarProps {
  contactId: string;
  useSlackConversations: boolean;
}

export default function SlackClientSidebar(props: SlackClientSidebarProps) {
  const { data, isLoading } = useSlackChannelInfo(props.contactId);
  const { mutateAsync: mutateSlackChannelCreation, isLoading: isSlackChannelCreationLoading } = useCreateSlackChannel();
  const { mutateAsync: mutateSlackChannelInvite, isLoading: isSlackChannelInviteLoading } =
    useCreateSlackChannelInvite();
  const [error, setError] = useState<string>('');

  const handleChannelCreation = async () => {
    try {
      await mutateSlackChannelCreation({ contactId: props.contactId });
    } catch (error) {
      console.error('Error:', error);
      Sentry.captureException(error as Error);
    }
  };

  const handleChannelInvite = async () => {
    try {
      if (props.contactId && data?.channel.id)
        await mutateSlackChannelInvite({ contactId: props.contactId, channelId: data?.channel.id });
    } catch (error) {
      console.error('Error:', error);
      Sentry.captureException(error as Error);
    }
  };

  useEffect(() => {
    if (data && data.error) {
      setError(getSlackError(data.error.code));
    } else {
      setError('');
    }
  }, [data]);

  if (!props.useSlackConversations) return null;

  return (
    <div data-testid="slack-sidebar" tw="text-sm">
      <div tw="flex space-x-1">
        <div tw="mt-5 text-sm leading-none capitalize font-semibold">Slack Conversation</div>
        {isLoading ? (
          <div tw="mt-4 text-xs bg-gray-300 rounded animate-pulse w-10 h-3" />
        ) : (
          <>
            {data && data.error && data.error.code === SlackErrorCode.SLACK_CHANNEL_NOT_FOUND ? (
              <button
                onClick={data && data?.error && data?.error?.isNewRequestAvailable ? handleChannelCreation : undefined}
                tw="mt-5 text-xs font-medium"
                className={`
                ${
                  data && data?.error && !data?.error?.isNewRequestAvailable
                    ? 'cursor-auto select-none text-black opacity-60'
                    : 'text-indigo-700'
                }
                ${isSlackChannelInviteLoading ? 'cursor-wait' : ''}
              `}
              >
                {isSlackChannelCreationLoading ? (
                  <FontAwesomeIcon tw="text-xs text-gray-500" icon={faCircleNotch} spin />
                ) : data?.error && data?.error?.isNewRequestAvailable ? (
                  'Create Channel'
                ) : (
                  <>
                    <FontAwesomeIcon tw="mr-1 text-xs text-gray-500" icon={faClock} />
                    Creating Channel
                  </>
                )}
              </button>
            ) : data && !data.error ? (
              <button
                onClick={
                  data && !data?.error && data?.channel?.isNewRequestAvailable && !data?.channel?.member
                    ? handleChannelInvite
                    : undefined
                }
                tw="mt-5 text-xs font-medium"
                className={`
                ${
                  (data && (data?.channel?.member || data?.error)) ||
                  (!data?.channel?.isNewRequestAvailable && !data?.error)
                    ? 'cursor-auto select-none text-black opacity-60'
                    : 'text-indigo-700'
                }
                ${isSlackChannelInviteLoading ? 'cursor-wait' : ''}
              `}
              >
                {isSlackChannelInviteLoading ? (
                  <FontAwesomeIcon tw="text-xs text-gray-500" icon={faCircleNotch} spin />
                ) : data && !data?.error && data?.channel?.member ? (
                  'Joined'
                ) : data?.channel?.isNewRequestAvailable ? (
                  'Join'
                ) : (
                  <>
                    <FontAwesomeIcon tw="mr-1 text-xs text-gray-500" icon={faClock} />
                    Joining Channel
                  </>
                )}
              </button>
            ) : null}
          </>
        )}
      </div>
      {data && !isLoading ? (
        <>
          {data.error ? (
            <div
              className={`text-${
                data.error.code === SlackErrorCode.SLACK_CHANNEL_NOT_FOUND ? 'text-black' : 'red-500'
              }`}
            >
              {error}
            </div>
          ) : (
            <div>
              <ul>
                <li>
                  {data.channel.name}
                  {data.channel.archived && (
                    <span className="px-2 ml-2 text-xs font-semibold rounded-full text-gray-700 bg-gray-200">
                      Archived
                    </span>
                  )}
                </li>
                {data && !data.error && data.channel.member ? (
                  <li>
                    <a
                      href={`https://slack.com/app_redirect?channel=${data.channel.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      tw="text-indigo-700"
                    >
                      Open in Slack
                    </a>
                  </li>
                ) : null}
              </ul>
            </div>
          )}
        </>
      ) : (
        <>
          <div tw="mt-3 text-xs bg-gray-300 rounded animate-pulse w-48 h-3" />
        </>
      )}
    </div>
  );
}
