export const enum PayrollToastMessages {
  Success = 'The payroll information has been successfully downloaded.',
  Error = 'Oops. Something happened. Please try again or contact support.',
  Warning = 'Please select a valid Payroll Period.',
}

export const enum PayrollHourTypes {
  RegOne = 'REG1',
  RegTwo = 'REG2',
  RegThree = 'REG3',
  Bonuses = 'BONSS',
}

export const DefaultSequoiaID = 'Unknown'; // for CSV use

export const DefaultTherapistName = 'Unknown'; // for CSV use
