/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import React, { useEffect, useState } from 'react';
import { Input, Label, Select, Link, useDisclosure, Loader } from '@expressable/ui-library';
import { Controller, useForm, useWatch } from 'react-hook-form';
import tw from 'twin.macro';
import usStatesOptions from 'utils/us-states.json';
import {
  IBillingInfoInsuranceForm,
  SelectOption,
  ICompleteClientInformation,
  IInsuranceForm,
  InsurancePayerOption,
} from 'types';
import { useClientInsurancePayers } from 'hooks/use-client-insurance-payers';
import { months, getDaysByEndDay, getDaysByYearAndMonth, years } from '@expressable/utils';
import { validateZipcode, onlyLettersSpecialVowelsWhiteSpacesValidator } from 'utils/helpers';
import relationshipToClientOptions from 'utils/relationship-to-client-options';
import genderOptions from 'utils/gender-options';
import planTypeOptions from 'utils/plan-type-options';
import defaultInsurancePayerOptions from 'utils/insurance-payer-options';
import 'react-dates/initialize';
import { ControlledDatePicker } from 'components/controlled-date-picker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { InputNumberStyled } from 'components/edit-therapist-info-form';
import { DeleteSecondaryInsuranceModal } from './delete-secondary-insurance-modal';
import { disallowedStatusOptions, authorizationStatusOptions } from 'utils/authorization-status-options';
import { IInsurance } from 'hooks/use-billing-information';
import { useLocation } from 'react-router-dom';
import { StateAbbrev, toStateFull } from 'utils/states';

const parseToUndefinedIfEmpty = (value: string | undefined) => (value === '' ? undefined : value);

const FormGroup = tw.div`mb-0`;
const FormInline = tw.div`md:flex`;
const MEDICAID_CODE = 'MC';
const MEDICAID_RELATIONSHIP_TO_INSURED_NUMBER = '18';
const COPAY_MAX_VALUE = 160;
const COPAY_MIN_VALUE = 0;

const convertCopayWithFractionalDigits = (copay?: number) => {
  if (!copay) return '0';
  if (copay.toString().includes('.')) return copay.toFixed(2);
  return copay.toString();
};

const convertBillingInfoToFormFields = (
  data: any,
  insurancePayerOptions: SelectOption[],
): IBillingInfoInsuranceForm => {
  const formFields: IBillingInfoInsuranceForm = {
    ...data,
    networkStatus: {
      label: data?.networkStatus || 'In-Network',
      value: data?.networkStatus || 'In-Network',
    } as SelectOption,
    primaryInsurance: {
      ...data?.primaryInsurance!,
      coPay: convertCopayWithFractionalDigits(data?.primaryInsurance?.coPay),
      priorAuthorizationNumber: data?.primaryInsurance?.priorAuthorizationNumber,
      insuredRelationshipToClient: relationshipToClientOptions.find(
        ({ value }) => value === data?.primaryInsurance?.insuredRelationshipToClient,
      ) as SelectOption,
      monthBirth: months.find(({ value }) => value === data?.primaryInsurance?.insuredDob?.slice(5, 7)) as SelectOption,
      dayBirth: getDaysByEndDay(31).find(
        ({ value }) => value === data?.primaryInsurance?.insuredDob?.slice(8, 10),
      ) as SelectOption,
      yearBirth: years.find(({ value }) => value === data?.primaryInsurance?.insuredDob?.slice(0, 4)) as SelectOption,
      insuredGender:
        (genderOptions.find(({ value }) => value === data?.primaryInsurance?.insuredGender) as SelectOption) ?? '',
      insuredAddress: {
        ...(data?.primaryInsurance?.insuredAddress ?? { city: '', state: '', zipCode: '', address1: '', address2: '' }),
        state:
          (usStatesOptions.find(
            ({ value }) => value === data?.primaryInsurance?.insuredAddress?.state,
          ) as SelectOption) ?? '',
      },
      // we always tend filter things by unique ids, but in this case insurance payer options repeat the values
      // we must filter insurance payer options by name instead of value
      insurancePayer: insurancePayerOptions?.find(
        ({ label }) => label === data?.primaryInsurance?.insurancePayerName,
      ) as SelectOption,
      insurancePlanEligibility: insurancePayerOptions?.find(
        ({ label }) => label === data?.primaryInsurance?.insurancePlanEligibilityName,
      ) as SelectOption,
      insuredPayerType: planTypeOptions.find(
        ({ value }) => value === data?.primaryInsurance?.insuredPayerType,
      ) as SelectOption,
    },
    secondaryInsurance: {
      ...data?.secondaryInsurance!,
      insuredRelationshipToClient: relationshipToClientOptions.find(
        ({ value }) => value === data?.secondaryInsurance?.insuredRelationshipToClient,
      ) as SelectOption,
      monthBirth: months.find(
        ({ value }) => value === data?.secondaryInsurance?.insuredDob?.slice(5, 7),
      ) as SelectOption,
      dayBirth: getDaysByEndDay(31).find(
        ({ value }) => value === data?.secondaryInsurance?.insuredDob?.slice(8, 10),
      ) as SelectOption,
      yearBirth: years.find(({ value }) => value === data?.secondaryInsurance?.insuredDob?.slice(0, 4)) as SelectOption,
      insuredGender:
        (genderOptions.find(({ value }) => value === data?.secondaryInsurance?.insuredGender) as SelectOption) ?? '',
      insuredAddress: {
        ...(data?.secondaryInsurance?.insuredAddress ?? {
          city: '',
          state: '',
          zipCode: '',
          address1: '',
          address2: '',
        }),
        state:
          (usStatesOptions.find(
            ({ value }) => value === data?.secondaryInsurance?.insuredAddress?.state,
          ) as SelectOption) ?? '',
      },
      // we always tend filter things by unique ids, but in this case insurance payer options repeat the values
      // we must filter insurance payer options by name instead of value
      insurancePayer: insurancePayerOptions.find(
        ({ label }) => label === data?.secondaryInsurance?.insurancePayerName,
      ) as SelectOption,
      insuredPayerType: planTypeOptions.find(
        ({ value }) => value === data?.secondaryInsurance?.insuredPayerType,
      ) as SelectOption,
    },
    insuranceAuthorizations: {
      evaluationAuthStatus:
        (authorizationStatusOptions.find(
          ({ value }) => value === data?.insuranceAuthorizations?.evaluationAuthStatus,
        ) as SelectOption) ?? authorizationStatusOptions[0],
      sessionAuthStatus:
        (authorizationStatusOptions.find(
          ({ value }) => value === data?.insuranceAuthorizations?.sessionAuthStatus,
        ) as SelectOption) ?? authorizationStatusOptions[0],
      visitLimits: data?.insuranceAuthorizations?.visitLimits ? 'yes' : 'no' ?? 'no',
      dateLimits: data?.insuranceAuthorizations?.dateLimits ? 'yes' : 'no' ?? 'no',
      reEvaluationDueBy: data?.insuranceAuthorizations?.reEvaluationDueBy
        ? moment(data?.insuranceAuthorizations?.reEvaluationDueBy)
        : undefined,
      reEvaluationRequired: data?.insuranceAuthorizations?.reEvaluationRequired ? 'yes' : 'no' ?? 'no',
      remainingVisitsLeft: data?.insuranceAuthorizations?.remainingVisitsLeft,
      visitsCompletedBy: data?.insuranceAuthorizations?.visitsCompletedBy
        ? moment(data?.insuranceAuthorizations?.visitsCompletedBy)
        : undefined,
    },
    automaticCoInsuranceCollection: data?.automaticCoInsuranceCollection === false ? 'no' : 'yes',
  };

  return formFields;
};

export const convertInsuranceFieldsToInsurance = (
  insuranceData: IInsuranceForm,
  savedInsuranceData?: IInsurance,
): IInsurance | undefined => {
  // check if at least there is one value that is truthy,
  // if not then the object can be considered empty.
  if (!Object.values(insuranceData).some(data => !!data)) return undefined;

  const { coPay, priorAuthorizationNumber, yearBirth, monthBirth, dayBirth, ...insurance } =
    insuranceData as IInsuranceForm;
  insurance.insuredDob = new Date(`${yearBirth.value}-${monthBirth.value}-${dayBirth.value}T00:00:00`).toISOString();

  // get candid payer uuid and candid plan eligibility uuid from previous saved data
  // if it is not present in insurancePayer (select component).
  // e.g. when coming from saved data, the default value removes the `id` from the select option
  const candidPayerUUID = savedInsuranceData?.candidPayerUUID;
  const candidPlanEligibilityUUID = savedInsuranceData?.candidPlanEligibilityUUID;

  return {
    ...insurance,
    candidPayerUUID: insurance.insurancePayer.id ?? candidPayerUUID,
    candidPlanEligibilityUUID: insurance.insurancePlanEligibility?.id ?? candidPlanEligibilityUUID,
    coPay: !coPay ? 0 : parseFloat(coPay),
    priorAuthorizationNumber: !priorAuthorizationNumber ? '' : priorAuthorizationNumber,
    insuredRelationshipToClient: insurance.insuredRelationshipToClient.value,
    insuredMiddleName: parseToUndefinedIfEmpty(insurance.insuredMiddleName),
    insuredGender: insurance.insuredGender.value,
    insuredAddress: {
      ...insurance.insuredAddress,
      state: insurance.insuredAddress?.state?.value,
    },
    insurancePayer: insurance.insurancePayer.value,
    insurancePayerName: insurance.insurancePayer.label,
    insurancePlanEligibility: insurance.insurancePlanEligibility?.value,
    insurancePlanEligibilityName: insurance.insurancePlanEligibility?.label,
    insuredPayerType: insurance.insuredPayerType.value,
  };
};

const getSearchParam = (searchParams: URLSearchParams) => (name: string) => searchParams.get(name) ?? undefined;

const mapSearchParams = (searchParams: URLSearchParams) => {
  const getParam = getSearchParam(searchParams);
  return {
    primaryInsurance: {
      coPay: getParam('leadCoPay'),
      insuredPayerType: getParam('leadInsuredPayerType'),
      insuredRelationshipToClient: getParam("leadClient'sRelationshipToInsured"),
      insuredFirstName: getParam('leadInsuredFirstName'),
      insuredLastName: getParam('leadInsuredLastName'),
      insuredDOB: getParam('leadInsuredDOB'),
      insuredGender: getParam('leadInsuredGender'),
      insuredStreet: getParam('leadInsuredStreet'),
      insuredCity: getParam('leadInsuredCity'),
      insuredState: getParam('leadInsuredState'),
      insuredZipCode: getParam('leadInsuredZipCode'),
      insurancePayerForClaims: getParam('leadInsurancePayerforClaims'),
      memberID: getParam('leadMemberID'),
      groupID: getParam('leadGroupID'),
    },
    insuranceAuthorizations: {
      evalAuthStatus: getParam('leadEvalAuthStatus'),
      sessionAuthStatus: getParam('leadSessionAuthStatus'),
      areThereAnyVisitLimits: getParam('leadArethereanyvisitlimits')?.toLocaleLowerCase(),
      remainingVisitsLeft: getParam('leadRemainingVisitsLeft'),
      areDateLimits: getParam('leadAreDateLimits')?.toLowerCase(),
      dateLimit: getParam('leadDateLimit'),
    },
  };
};

export interface InsuranceFormResult {
  isValid: boolean;
  fields: IBillingInfoInsuranceForm;
}

export interface BillingInfoInsuranceFormProps {
  billingInfoData?: any;
  clientData?: ICompleteClientInformation;
  exemptFromPatientResponsibility?: boolean;
  isClientCreate?: boolean;
  onChange: (result: InsuranceFormResult) => void;
}

const BillingInfoInsuranceForm = ({
  billingInfoData,
  clientData,
  exemptFromPatientResponsibility,
  isClientCreate = false,
  onChange,
}: BillingInfoInsuranceFormProps) => {
  const {
    register,
    formState: { errors, isValid },
    control,
    getValues,
    setValue,
    watch,
    trigger,
  } = useForm<IBillingInfoInsuranceForm>({
    mode: 'all',
    defaultValues: {
      insuranceAuthorizations: {
        dateLimits: 'no',
        visitLimits: 'no',
        reEvaluationRequired: 'no',
      },
    },
  });

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const [exemptHasManuallyChanged, setExemptHasManuallyChanged] = useState<boolean>(false);

  const { data: insurancePayerOptions = defaultInsurancePayerOptions, isLoading: isClientInsurancePayersLoading } =
    useClientInsurancePayers();

  const {
    isOpen: DeleteSecondaryInsuranceModalIsOpen,
    onClose: DeleteSecondaryInsuranceModalOnClose,
    onOpen: DeleteSecondaryInsuranceModalOnOpen,
  } = useDisclosure();

  const [showSecondary, setShowSecondary] = useState<boolean>(false);

  const watchPrimaryPayerType = watch('primaryInsurance.insuredPayerType');
  const watchPrimaryInsuredRelationshipToClient = watch('primaryInsurance.insuredRelationshipToClient');
  const watchPrimaryInsuredMemberID = watch('primaryInsurance.insuredMemberID');

  const watchSecondaryInsuredMemberID = watch('secondaryInsurance.insuredMemberID');
  const watchSecondaryInsuredRelationshipToClient = watch('secondaryInsurance.insuredRelationshipToClient');
  const watchSecondaryPayerType = watch('secondaryInsurance.insuredPayerType');

  const watchEvaluationAuthStatus = watch('insuranceAuthorizations.evaluationAuthStatus');
  const watchSessionAuthStatus = watch('insuranceAuthorizations.sessionAuthStatus');
  const watchVisitLimits = watch('insuranceAuthorizations.visitLimits');
  const watchDateLimits = watch('insuranceAuthorizations.dateLimits');
  const watchReEvaluationRequired = watch('insuranceAuthorizations.reEvaluationRequired');

  const watchFormChange = useWatch({ control });

  const primaryInsuranceIsClient =
    watchPrimaryInsuredRelationshipToClient?.value === MEDICAID_RELATIONSHIP_TO_INSURED_NUMBER;

  const secondaryInsuranceIsClient =
    watchSecondaryInsuredRelationshipToClient?.value === MEDICAID_RELATIONSHIP_TO_INSURED_NUMBER;

  useWatch({ control, name: ['primaryInsurance.yearBirth', 'primaryInsurance.monthBirth'] });
  const primaryDays = getDaysByYearAndMonth(
    parseInt(getValues('primaryInsurance.yearBirth')?.value),
    parseInt(getValues('primaryInsurance.monthBirth')?.value),
  );

  useWatch({ control, name: ['secondaryInsurance.yearBirth', 'secondaryInsurance.monthBirth'] });
  const secondaryDays = getDaysByYearAndMonth(
    parseInt(getValues('secondaryInsurance.yearBirth')?.value),
    parseInt(getValues('secondaryInsurance.monthBirth')?.value),
  );

  const billingInfoForm = convertBillingInfoToFormFields(billingInfoData, insurancePayerOptions);

  const handleQueryStringParams = () => {
    const params = mapSearchParams(searchParams);

    setValue('primaryInsurance.coPay', convertCopayWithFractionalDigits(Number(params.primaryInsurance.coPay)));
    setValue(
      'primaryInsurance.insuredPayerType',
      planTypeOptions?.find(({ value }) => value === params.primaryInsurance.insuredPayerType) as SelectOption,
    );

    const relationshipToPrimaryInsured = relationshipToClientOptions.find(
      ({ label }) => label === params.primaryInsurance.insuredRelationshipToClient,
    ) as SelectOption;

    if(!relationshipToPrimaryInsured && primaryInsuranceIsClient) {
      setValue('primaryInsurance.insuredRelationshipToClient', relationshipToClientOptions.find(({ value }) => value === MEDICAID_RELATIONSHIP_TO_INSURED_NUMBER)!);
    }


    if (relationshipToPrimaryInsured?.value !== MEDICAID_RELATIONSHIP_TO_INSURED_NUMBER) {
      setValue('primaryInsurance.insuredFirstName', params.primaryInsurance.insuredFirstName ?? '');
      setValue('primaryInsurance.insuredLastName', params.primaryInsurance.insuredLastName ?? '');

      if (params.primaryInsurance.insuredDOB) {
        setValue(
          'primaryInsurance.monthBirth',
          months.find(({ value }) => value === params.primaryInsurance.insuredDOB?.slice(5, 7)) as SelectOption,
        );
        setValue(
          'primaryInsurance.dayBirth',
          getDaysByEndDay(31).find(
            ({ value }) => value === params.primaryInsurance.insuredDOB?.slice(8, 10),
          ) as SelectOption,
        );
        setValue(
          'primaryInsurance.yearBirth',
          years.find(({ value }) => value === params.primaryInsurance.insuredDOB?.slice(0, 4)) as SelectOption,
        );
      }

      setValue(
        'primaryInsurance.insuredGender',
        (genderOptions.find(({ label }) => label === params.primaryInsurance.insuredGender) as SelectOption) ?? '',
      );
    }

    setValue('primaryInsurance.insuredAddress.address1', params.primaryInsurance.insuredStreet ?? '');
    setValue('primaryInsurance.insuredAddress.city', params.primaryInsurance.insuredCity ?? '');
    setValue(
      'primaryInsurance.insuredAddress.state',
      (usStatesOptions.find(
        ({ value }) => value === toStateFull(params.primaryInsurance.insuredState as StateAbbrev),
      ) as SelectOption) ?? '',
    );
    setValue('primaryInsurance.insuredAddress.zipCode', params.primaryInsurance.insuredZipCode ?? '');
    setValue(
      'primaryInsurance.insurancePayer',
      insurancePayerOptions?.find(
        ({ label }) => label === params.primaryInsurance.insurancePayerForClaims,
      ) as InsurancePayerOption,
    );
    setValue('primaryInsurance.insuredMemberID', params.primaryInsurance.memberID ?? '');
    setValue('primaryInsurance.insuredGroupID', params.primaryInsurance.groupID ?? '');

    // authorization fields
    const evaluationAuthStatus = authorizationStatusOptions.find(
      item => item.label === params.insuranceAuthorizations.evalAuthStatus,
    );
    setValue('insuranceAuthorizations.evaluationAuthStatus', evaluationAuthStatus ?? authorizationStatusOptions[0]);
    const sessionAuthStatus = authorizationStatusOptions.find(
      item => item.label === params.insuranceAuthorizations.sessionAuthStatus,
    );
    setValue('insuranceAuthorizations.sessionAuthStatus', sessionAuthStatus ?? authorizationStatusOptions[0]);
    setValue('insuranceAuthorizations.visitLimits', params.insuranceAuthorizations.areThereAnyVisitLimits ?? 'no');
    const remainingVisitsLeft = params.insuranceAuthorizations.remainingVisitsLeft;
    setValue(
      'insuranceAuthorizations.remainingVisitsLeft',
      remainingVisitsLeft ? Number(remainingVisitsLeft) : undefined,
    );
    setValue('insuranceAuthorizations.dateLimits', params.insuranceAuthorizations.areDateLimits ?? 'no');
    setValue('insuranceAuthorizations.visitsCompletedBy', params.insuranceAuthorizations.dateLimit);
  };

  useEffect(() => {
    if (billingInfoData) {
      if (exemptFromPatientResponsibility) {
        setValue('primaryInsurance.coPay', '');
        setValue('automaticCoInsuranceCollection', 'no');
      }

      setShowSecondary(!!billingInfoData?.secondaryInsurance?.insuredFirstName);
    }
  }, []);

  useEffect(() => {
    if (watchEvaluationAuthStatus?.value === 'awaiting' || watchEvaluationAuthStatus?.value === 'denied') {
      setValue('insuranceAuthorizations.sessionAuthStatus', disallowedStatusOptions[0]);
    }
  }, [watchEvaluationAuthStatus]);

  useEffect(() => {
    if (billingInfoForm) {
      setExemptHasManuallyChanged(true);
      if (exemptFromPatientResponsibility) {
        setValue('primaryInsurance.coPay', '');
        setValue('automaticCoInsuranceCollection', 'no');
      }

      if (!exemptFromPatientResponsibility) {
        setValue(
          'primaryInsurance.coPay',
          billingInfoForm?.primaryInsurance?.coPay &&
            billingInfoForm?.primaryInsurance?.coPay !== String(COPAY_MIN_VALUE)
            ? billingInfoForm?.primaryInsurance?.coPay
            : '',
        );
        setValue(
          'automaticCoInsuranceCollection',
          exemptHasManuallyChanged ? 'yes' : billingInfoForm?.automaticCoInsuranceCollection,
        );
      }
    }
  }, [exemptFromPatientResponsibility]);

  useEffect(() => {
    if (watchPrimaryInsuredRelationshipToClient?.value === MEDICAID_RELATIONSHIP_TO_INSURED_NUMBER) {
      setValue('primaryInsurance.insuredFirstName', clientData?.clientFirstName!);
      setValue('primaryInsurance.insuredMiddleName', clientData?.clientMiddleName ?? '');
      setValue('primaryInsurance.insuredLastName', clientData?.clientLastName!);
      setValue(
        'primaryInsurance.monthBirth',
        months.find(({ value }) => value === clientData?.dob?.slice(5, 7)) as SelectOption,
      );
      setValue(
        'primaryInsurance.dayBirth',
        getDaysByEndDay(31).find(({ value }) => value === clientData?.dob?.slice(8, 10)) as SelectOption,
      );
      setValue(
        'primaryInsurance.yearBirth',
        years.find(({ value }) => value === clientData?.dob?.slice(0, 4)) as SelectOption,
      );
      setValue(
        'primaryInsurance.insuredGender',
        (genderOptions.find(({ value }) => value === clientData?.sex) as SelectOption) ?? '',
      );
    }

    if (watchSecondaryInsuredRelationshipToClient?.value === MEDICAID_RELATIONSHIP_TO_INSURED_NUMBER) {
      setValue('secondaryInsurance.insuredFirstName', clientData?.clientFirstName);
      setValue('secondaryInsurance.insuredMiddleName', clientData?.clientMiddleName ?? '');
      setValue('secondaryInsurance.insuredLastName', clientData?.clientLastName);
      setValue(
        'secondaryInsurance.monthBirth',
        months.find(({ value }) => value === clientData?.dob?.slice(5, 7)) as SelectOption,
      );
      setValue(
        'secondaryInsurance.dayBirth',
        getDaysByEndDay(31).find(({ value }) => value === clientData?.dob?.slice(8, 10)) as SelectOption,
      );
      setValue(
        'secondaryInsurance.yearBirth',
        years.find(({ value }) => value === clientData?.dob?.slice(0, 4)) as SelectOption,
      );
      setValue(
        'secondaryInsurance.insuredGender',
        (genderOptions.find(({ value }) => value === clientData?.sex) as SelectOption) ?? '',
      );
    }
  }, [watchPrimaryInsuredRelationshipToClient, watchSecondaryInsuredRelationshipToClient]);

  useEffect(() => {
    if (watchPrimaryPayerType?.value === MEDICAID_CODE) {
      setValue(
        'primaryInsurance.insuredRelationshipToClient',
        relationshipToClientOptions.find(({ value }) => value === MEDICAID_RELATIONSHIP_TO_INSURED_NUMBER)!,
      );
    }
  }, [watchPrimaryPayerType]);

  useEffect(() => {
    if (watchSecondaryPayerType?.value === MEDICAID_CODE) {
      setValue(
        'secondaryInsurance.insuredRelationshipToClient',
        relationshipToClientOptions.find(({ value }) => value === MEDICAID_RELATIONSHIP_TO_INSURED_NUMBER)!,
      );
    }
  }, [watchSecondaryPayerType]);

  useEffect(() => {
    // only handle query-string params if any value is provided
    if (!billingInfoData && insurancePayerOptions.length && search?.length) {
      handleQueryStringParams();
      checkForm();
    }
  }, [insurancePayerOptions]);

  useEffect(() => {
    onChange({
      isValid: !isClientInsurancePayersLoading && isValid,
      fields: { ...getValues() } as IBillingInfoInsuranceForm,
    });
    checkForm();
  }, [watchFormChange, isValid, isClientInsurancePayersLoading]);

  const checkForm = () => {
    if (watchPrimaryInsuredMemberID || watchSecondaryInsuredMemberID) {
      trigger();
    }
  };

  const resetSecondaryInsurance = () => {
    setValue('secondaryInsurance', undefined);
    setValue('secondaryInsurance.priorAuthorizationNumber', '');
    setValue('secondaryInsurance.coPay', '');
    setValue('secondaryInsurance.insuredRelationshipToClient', '');
    setValue('secondaryInsurance.insuredFirstName', '');
    setValue('secondaryInsurance.insuredMiddleName', '');
    setValue('secondaryInsurance.insuredLastName', '');
    setValue('secondaryInsurance.monthBirth', '');
    setValue('secondaryInsurance.dayBirth', '');
    setValue('secondaryInsurance.yearBirth', '');
    setValue('secondaryInsurance.insuredDob', '');
    setValue('secondaryInsurance.insuredGender', '');
    setValue('secondaryInsurance.insuredAddress', undefined);
    setValue('secondaryInsurance.insuredAddress.address1', '');
    setValue('secondaryInsurance.insuredAddress.address2', '');
    setValue('secondaryInsurance.insuredAddress.city', '');
    setValue('secondaryInsurance.insuredAddress.state', '');
    setValue('secondaryInsurance.insuredAddress.zipCode', '');
    setValue('secondaryInsurance.insurancePayer', '');
    setValue('secondaryInsurance.insurancePlanEligibility', '');
    setValue('secondaryInsurance.insuredPayerType', '');
    setValue('secondaryInsurance.insuredMemberID', '');
    setValue('secondaryInsurance.insuredGroupID', '');
  };

  const onDeleteSecondaryInsuranceClose = () => {
    resetSecondaryInsurance();
    setShowSecondary(false);
    DeleteSecondaryInsuranceModalOnClose();
  };

  const onConfirmDeleteHandler = () => {
    if (billingInfoData?.secondaryInsurance?.insuredFirstName) {
      DeleteSecondaryInsuranceModalOnOpen();
    } else {
      resetSecondaryInsurance();
      setShowSecondary(false);
    }
  };

  const handleKeyDown = (e: any) => {
    const dotIndex = e.currentTarget.value.indexOf('.');

    // if key pressed is a number and the cursor is before the dot, do nothing
    if (e.which >= 48 && e.which <= 57 && e.currentTarget.selectionStart <= dotIndex) return;

    // if key is arrow right and back keys, do nothing
    if (e.which === 37 || e.which === 39) return;

    if (e.which === 38 || e.which === 40) e.preventDefault();

    // check if the key pressed is a number, or dot or backspace. if not, prevent the default behavior
    if (
      (e.which < 48 || e.which > 57) &&
      (e.which < 96 || e.which > 105) &&
      e.which !== 8 &&
      e.which !== 190 &&
      e.which !== 110
    ) {
      e.preventDefault();
    }
    // prevent default for this special keys (!@#%# and so on)
    if (e.ctrlKey || e.metaKey || e.shiftKey) {
      e.preventDefault();
    }
    // if the key pressed is a dot, check if the input value already has a dot
    if ((e.which === 190 || e.which === 110) && e.currentTarget.value.includes('.')) {
      e.preventDefault();
    }
    // if the key pressed is a dot and the input value already has 2 digits after the dot, prevent the default behavior
    if ((e.which === 190 || e.which === 110) && e.currentTarget.value.split('.')[1]?.length >= 2) {
      e.preventDefault();
    }
    // only allow two digits after the dot and allow backscape
    if (e.currentTarget.value.split('.')[1]?.length >= 2 && e.which !== 8) {
      e.preventDefault();
    }
  };

  const validateCoPay = (value: any) => {
    const isValid = value <= COPAY_MAX_VALUE;
    return isValid || 'Co-pay must be between $0 and ' + `$${COPAY_MAX_VALUE}`;
  };

  if (isClientInsurancePayersLoading) {
    return <Loader type="ring" />;
  }

  return (
    <div data-testid="billing-info-insurance">
      <div tw="md:min-w-[850px] max-w-[850px]">
        <FormGroup>
          <Label tw="mt-5" htmlFor="coPays">
            Network Status
          </Label>

          <Controller
            name="networkStatus"
            data-testid="networkStatus"
            control={control}
            defaultValue={billingInfoForm?.networkStatus}
            render={({ field }) => (
              <Select
                {...field}
                tw="max-w-[210px]"
                options={[
                  {
                    label: 'In-Network',
                    value: 'In-Network',
                  },
                  {
                    label: 'Out-Of-Network',
                    value: 'Out-Of-Network',
                  },
                ]}
                placeholder="-"
              />
            )}
            rules={{ required: true }}
          />
        </FormGroup>

        <FormGroup tw="mb-5 mt-5" data-testid="automaticCoInsuranceCollections">
          <h4 tw="mb-2 text-sm font-semibold">Automatic co-insurance collections</h4>
          <div tw="flex flex-row max-h-[20px]">
            <FormInline tw="flex items-baseline mr-7">
              <Input
                type="radio"
                id="automaticCoInsuranceCollection-yes"
                data-testid="automaticCoInsuranceCollection-yes"
                {...register('automaticCoInsuranceCollection', {
                  required: exemptFromPatientResponsibility ? false : true,
                  shouldUnregister: false,
                })}
                readOnly={exemptFromPatientResponsibility}
                value="yes"
                css={[exemptFromPatientResponsibility ? tw`opacity-50 pointer-events-none` : tw``]}
                defaultChecked={
                  billingInfoForm?.automaticCoInsuranceCollection === 'yes' && !exemptFromPatientResponsibility
                }
              />
              <Label font="normal" htmlFor="automaticCoInsuranceCollection-yes" tw="ml-2">
                Yes
              </Label>
            </FormInline>
            <FormInline tw="flex items-baseline">
              <Input
                type="radio"
                id="automaticCoInsuranceCollection-no"
                data-testid="automaticCoInsuranceCollection-no"
                {...register('automaticCoInsuranceCollection', {
                  required: exemptFromPatientResponsibility ? false : true,
                  shouldUnregister: false,
                })}
                readOnly={exemptFromPatientResponsibility}
                value="no"
                css={[exemptFromPatientResponsibility ? tw`opacity-50 pointer-events-none` : tw``]}
                defaultChecked={billingInfoForm?.automaticCoInsuranceCollection === 'no'}
              />
              <Label font="normal" htmlFor="automaticCoInsuranceCollection-no" tw="ml-2">
                No
              </Label>
            </FormInline>
          </div>
        </FormGroup>

        <FormGroup>
          <Label tw="mt-5" htmlFor="coPays">
            Co-pay
          </Label>
          <FormInline tw="flex">
            <div tw="mr-3">
              <Label htmlFor="dollarSimbol" tw="mt-5">
                $
              </Label>
            </div>
            <div>
              <InputNumberStyled>
                <Input
                  type="text"
                  id="coPay"
                  disabled={exemptFromPatientResponsibility}
                  onWheel={event => event.currentTarget.blur()}
                  onKeyDown={handleKeyDown}
                  defaultValue={billingInfoForm?.primaryInsurance?.coPay}
                  {...register('primaryInsurance.coPay', {
                    validate: validateCoPay,
                  })}
                  tw="mr-3 mt-3 w-24"
                  autoComplete="off"
                  error={errors.primaryInsurance && errors.primaryInsurance.coPay}
                />
              </InputNumberStyled>
            </div>
          </FormInline>
        </FormGroup>

        <div data-testid="primary-insurance-information">
          <div tw="text-xl font-semibold mt-3 mb-7" data-testid="primary-insurance-information-label">
            Primary Insurance Information
          </div>
          <FormGroup tw="w-full lg:w-2/5 md:w-2/3">
            <Label htmlFor="primaryInsuredPayerType">Payer Type</Label>
            <Controller
              name="primaryInsurance.insuredPayerType"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  id="primaryInsuredPayerType"
                  options={planTypeOptions}
                  isSearchable
                  placeholder="–"
                  error={errors.primaryInsurance?.insuredPayerType}
                  tw="mt-3"
                />
              )}
              defaultValue={billingInfoForm?.primaryInsurance.insuredPayerType}
              rules={{ required: true }}
            />
          </FormGroup>
          <FormGroup tw="w-full sm:w-1/2 md:w-1/3">
            <Label htmlFor="primaryInsuredRelationshipToClient">Client&apos;s Relationship to Insured</Label>
            <div data-testid="primaryInsuredRelationshipToClient" tw="mt-3">
              <Controller
                name="primaryInsurance.insuredRelationshipToClient"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    id="primaryInsuredRelationshipToClient"
                    options={relationshipToClientOptions}
                    isSearchable
                    placeholder="–"
                    error={errors.primaryInsurance?.insuredRelationshipToClient}
                    disabled={isClientCreate ? false : watchPrimaryPayerType?.value === MEDICAID_CODE}
                    inputId="primaryInsuredRelationshipToClientID"
                  />
                )}
                defaultValue={billingInfoForm?.primaryInsurance.insuredRelationshipToClient}
                rules={{ required: true }}
              />
            </div>
          </FormGroup>
          <FormInline tw="flex-wrap">
            <FormGroup>
              <Label htmlFor="primaryInsuredFirstName">Insured&apos;s First Name</Label>
              <Input
                type="text"
                id="primaryInsuredFirstName"
                tw="mr-3 mt-3"
                {...register('primaryInsurance.insuredFirstName', {
                  required: true,
                  validate: { onlyLettersSpecialVowelsWhiteSpacesValidator },
                })}
                defaultValue={billingInfoForm?.primaryInsurance.insuredFirstName}
                autoComplete="off"
                error={errors.primaryInsurance?.insuredFirstName}
                readOnly={primaryInsuranceIsClient}
                css={[primaryInsuranceIsClient ? tw`opacity-50 bg-gray-200 pointer-events-none` : tw``]}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="primaryInsuredMiddleName" optional>
                Insured&apos;s Middle Name
              </Label>
              <Input
                type="text"
                id="primaryInsuredMiddleName"
                tw="mr-3 mt-3"
                {...register('primaryInsurance.insuredMiddleName')}
                defaultValue={billingInfoForm?.primaryInsurance.insuredMiddleName}
                readOnly={primaryInsuranceIsClient}
                css={[primaryInsuranceIsClient ? tw`opacity-50 bg-gray-200 pointer-events-none` : tw``]}
                autoComplete="off"
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="primaryInsuredLastName">Insured&apos;s Last Name</Label>
              <Input
                type="text"
                id="primaryInsuredLastName"
                tw="mr-3 mt-3"
                {...register('primaryInsurance.insuredLastName', {
                  required: true,
                  validate: { onlyLettersSpecialVowelsWhiteSpacesValidator },
                })}
                defaultValue={billingInfoForm?.primaryInsurance.insuredLastName}
                error={errors.primaryInsurance?.insuredLastName}
                readOnly={primaryInsuranceIsClient}
                css={[primaryInsuranceIsClient ? tw`opacity-50 bg-gray-200 pointer-events-none` : tw``]}
                autoComplete="off"
              />
            </FormGroup>
          </FormInline>
          <FormGroup tw="mb-3">
            <Label htmlFor="primaryMonthBirth">Insured&apos;s Date of Birth</Label>
            <FormInline tw="flex">
              <div tw="w-32 mr-3 mt-3">
                <Controller
                  name="primaryInsurance.monthBirth"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      id="primaryMonthBirth"
                      options={months}
                      placeholder="–"
                      error={errors.primaryInsurance?.monthBirth}
                      disabled={primaryInsuranceIsClient}
                    />
                  )}
                  defaultValue={billingInfoForm?.primaryInsurance.monthBirth}
                  rules={{ required: true }}
                />
              </div>
              <div tw="w-20 mr-3 mt-3">
                <Controller
                  name="primaryInsurance.dayBirth"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={primaryDays}
                      placeholder="–"
                      error={errors.primaryInsurance?.dayBirth}
                      disabled={primaryInsuranceIsClient}
                    />
                  )}
                  defaultValue={billingInfoForm?.primaryInsurance.dayBirth}
                  rules={{ required: true }}
                />
              </div>
              <div tw="w-24 mt-3">
                <Controller
                  name="primaryInsurance.yearBirth"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={years}
                      placeholder="–"
                      error={errors.primaryInsurance?.yearBirth}
                      disabled={primaryInsuranceIsClient}
                    />
                  )}
                  defaultValue={billingInfoForm?.primaryInsurance.yearBirth}
                  rules={{ required: true }}
                />
              </div>
            </FormInline>
          </FormGroup>
          <FormGroup tw="w-full sm:w-1/2 md:w-1/4">
            <Label htmlFor="primaryInsuredGender">Insured&apos;s Gender</Label>
            <Controller
              name="primaryInsurance.insuredGender"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  id="primaryInsuredGender"
                  options={genderOptions}
                  placeholder="–"
                  error={errors.primaryInsurance?.insuredGender}
                  tw="mt-3"
                  disabled={primaryInsuranceIsClient}
                />
              )}
              defaultValue={billingInfoForm?.primaryInsurance.insuredGender}
              rules={{
                required:
                  !clientData?.sex && primaryInsuranceIsClient
                    ? 'Gender is missing on client’s information, please update the client record.'
                    : true,
              }}
            />
          </FormGroup>
          <FormGroup tw="w-full sm:w-1/2 md:w-2/3 mt-8 sm:mt-10 md:mt-12">
            <Label htmlFor="primaryAddress1" optional>
              Insured&apos;s Address
            </Label>
            <Input
              type="address"
              tw="w-full mt-3"
              id="primaryAddress1"
              {...register('primaryInsurance.insuredAddress.address1')}
              defaultValue={billingInfoForm?.primaryInsurance.insuredAddress.address1}
              autoComplete="off"
              error={errors.primaryInsurance?.insuredAddress?.address1}
            />
            <Input
              type="address"
              tw="w-full mt-2"
              id="primaryAddress2"
              {...register('primaryInsurance.insuredAddress.address2')}
              defaultValue={billingInfoForm?.primaryInsurance.insuredAddress.address2}
              autoComplete="off"
              error={errors.primaryInsurance?.insuredAddress?.address2}
            />
          </FormGroup>
          <FormInline tw="md:flex">
            <FormGroup tw="block md:inline-block">
              <Label htmlFor="primaryCity" optional>
                Insured&apos;s City
              </Label>
              <Input
                type="text"
                id="primaryCity"
                tw="block mr-3 mt-3"
                {...register('primaryInsurance.insuredAddress.city', {
                  validate: { onlyLettersSpecialVowelsWhiteSpacesValidator },
                })}
                defaultValue={billingInfoForm?.primaryInsurance.insuredAddress.city}
                autoComplete="off"
                error={errors.primaryInsurance?.insuredAddress?.city}
              />
            </FormGroup>
            <FormGroup tw="block md:inline-block">
              <Label htmlFor="primaryState" optional>
                Insured&apos;s State
              </Label>
              <div tw="w-40 mr-3 mt-3">
                <Controller
                  name="primaryInsurance.insuredAddress.state"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      id="primaryState"
                      options={usStatesOptions}
                      isSearchable
                      placeholder="–"
                      error={errors.primaryInsurance?.insuredAddress?.state}
                    />
                  )}
                  defaultValue={billingInfoForm?.primaryInsurance.insuredAddress.state}
                />
              </div>
            </FormGroup>
            <FormGroup tw="block md:inline-block">
              <Label htmlFor="primaryZipCode" optional>
                Insured&apos;s Zip Code
              </Label>
              <Input
                type="text"
                id="primaryZipCode"
                tw="block mt-3"
                {...register('primaryInsurance.insuredAddress.zipCode', {
                  validate: { validateZipcode },
                })}
                defaultValue={billingInfoForm?.primaryInsurance.insuredAddress.zipCode}
                autoComplete="off"
                error={errors.primaryInsurance?.insuredAddress?.zipCode}
              />
            </FormGroup>
          </FormInline>
          <FormGroup tw="w-full lg:w-2/5 md:w-2/3">
            <Label htmlFor="primaryInsurancePayer">Insurance Payer for Claims</Label>
            <Controller
              name="primaryInsurance.insurancePayer"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  id="primaryInsurancePayer"
                  options={insurancePayerOptions}
                  isSearchable
                  placeholder="–"
                  error={errors.primaryInsurance?.insurancePayer}
                  tw="mt-3"
                />
              )}
              defaultValue={billingInfoForm?.primaryInsurance.insurancePayer}
              rules={{ required: true }}
            />
          </FormGroup>
          <FormGroup tw="w-full lg:w-2/5 md:w-2/3">
            <Label optional htmlFor="primaryInsurancePlanEligibility">
              Insurance Plan
            </Label>
            <Controller
              name="primaryInsurance.insurancePlanEligibility"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  id="primaryInsurancePlanEligibility"
                  options={insurancePayerOptions}
                  isSearchable
                  placeholder="–"
                  error={errors.primaryInsurance?.insurancePlanEligibility}
                  tw="mt-3"
                />
              )}
              defaultValue={billingInfoForm?.primaryInsurance.insurancePlanEligibility}
            />
          </FormGroup>
          <FormGroup tw="w-full sm:w-1/2 md:w-1/3">
            <Label htmlFor="primaryInsuredMemberID">Member ID</Label>
            <Input
              type="text"
              id="primaryInsuredMemberID"
              tw="w-full mt-3"
              {...register('primaryInsurance.insuredMemberID', { required: true })}
              defaultValue={billingInfoForm?.primaryInsurance.insuredMemberID}
              autoComplete="off"
              error={errors.primaryInsurance?.insuredMemberID}
            />
          </FormGroup>
          <FormGroup tw="w-full sm:w-1/2 md:w-1/3">
            <Label optional htmlFor="primaryInsuredGroupID">
              Group ID
            </Label>
            <Input
              type="text"
              id="primaryInsuredGroupID"
              tw="w-full mt-3"
              {...register('primaryInsurance.insuredGroupID')}
              defaultValue={billingInfoForm?.primaryInsurance.insuredGroupID}
              autoComplete="off"
              error={errors.primaryInsurance?.insuredGroupID}
            />
          </FormGroup>
        </div>

        <div data-testid="secondary-insurance-information">
          <div tw="text-xl font-semibold mt-10 mb-4" data-testid="secondary-insurance-information-label">
            Secondary Insurance Information
          </div>
          {!showSecondary ? (
            <Link
              data-testid="add-secondary-insurance"
              to="#"
              tw="text-sm"
              onClick={() => {
                setShowSecondary(true);
                setValue('secondaryInsurance', {});
                setValue('secondaryInsurance.insuredAddress', {});
              }}
            >
              Add secondary insurance
            </Link>
          ) : (
            <>
              <FormGroup tw="w-full lg:w-2/5 md:w-2/3">
                <Label htmlFor="secondaryInsuredPayerType">Payer Type</Label>
                <Controller
                  name="secondaryInsurance.insuredPayerType"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      id="secondaryInsuredPayerType"
                      options={planTypeOptions}
                      isSearchable
                      placeholder="–"
                      error={errors.secondaryInsurance?.insuredPayerType}
                      tw="mt-3"
                    />
                  )}
                  defaultValue={billingInfoForm?.secondaryInsurance.insuredPayerType}
                  rules={{ required: true }}
                />
              </FormGroup>
              <FormGroup tw="w-full sm:w-1/2 md:w-1/3">
                <Label htmlFor="secondaryInsuredRelationshipToClient">Client&apos;s Relationship to Insured</Label>
                <div data-testid="secondaryInsuredRelationshipToClient" tw="mt-3">
                  <Controller
                    name="secondaryInsurance.insuredRelationshipToClient"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={relationshipToClientOptions}
                        isSearchable
                        placeholder="–"
                        error={errors.secondaryInsurance?.insuredRelationshipToClient}
                        disabled={isClientCreate ? false : watchSecondaryPayerType?.value === MEDICAID_CODE}
                      />
                    )}
                    defaultValue={billingInfoForm?.secondaryInsurance.insuredRelationshipToClient}
                    rules={{ required: true }}
                  />
                </div>
              </FormGroup>
              <FormInline tw="flex-wrap">
                <FormGroup>
                  <Label htmlFor="secondaryInsuredFirstName">Insured&apos;s First Name</Label>
                  <Input
                    type="text"
                    id="secondaryInsuredFirstName"
                    tw="mr-3 mt-3"
                    {...register('secondaryInsurance.insuredFirstName', {
                      required: true,
                      validate: { onlyLettersSpecialVowelsWhiteSpacesValidator },
                    })}
                    defaultValue={billingInfoForm?.secondaryInsurance.insuredFirstName}
                    error={errors.secondaryInsurance?.insuredFirstName}
                    readOnly={secondaryInsuranceIsClient}
                    css={[secondaryInsuranceIsClient ? tw`opacity-50 bg-gray-200 pointer-events-none` : tw``]}
                    autoComplete="off"
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="secondaryInsuredMiddleName" optional>
                    Insured&apos;s Middle Name
                  </Label>
                  <Input
                    type="text"
                    id="secondaryInsuredMiddleName"
                    tw="mr-3 mt-3"
                    {...register('secondaryInsurance.insuredMiddleName')}
                    defaultValue={billingInfoForm?.secondaryInsurance.insuredMiddleName}
                    readOnly={secondaryInsuranceIsClient}
                    css={[secondaryInsuranceIsClient ? tw`opacity-50 bg-gray-200 pointer-events-none` : tw``]}
                    autoComplete="off"
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="secondaryInsuredLastName">Insured&apos;s Last Name</Label>
                  <Input
                    type="text"
                    id="secondaryInsuredLastName"
                    tw="mr-3 mt-3"
                    {...register('secondaryInsurance.insuredLastName', {
                      required: true,
                      validate: { onlyLettersSpecialVowelsWhiteSpacesValidator },
                    })}
                    defaultValue={billingInfoForm?.secondaryInsurance.insuredLastName}
                    error={errors.secondaryInsurance?.insuredLastName}
                    readOnly={secondaryInsuranceIsClient}
                    css={[secondaryInsuranceIsClient ? tw`opacity-50 bg-gray-200 pointer-events-none` : tw``]}
                    autoComplete="off"
                  />
                </FormGroup>
              </FormInline>
              <FormGroup tw="mb-3">
                <Label htmlFor="secondaryMonthBirth">Insured&apos;s Date of Birth</Label>
                <FormInline tw="flex">
                  <div tw="w-32 mr-3 mt-3">
                    <Controller
                      name="secondaryInsurance.monthBirth"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          id="secondaryMonthBirth"
                          options={months}
                          placeholder="–"
                          error={errors.secondaryInsurance?.monthBirth}
                          disabled={secondaryInsuranceIsClient}
                        />
                      )}
                      defaultValue={billingInfoForm?.secondaryInsurance.monthBirth}
                      rules={{ required: true }}
                    />
                  </div>
                  <div tw="w-20 mr-3 mt-3">
                    <Controller
                      name="secondaryInsurance.dayBirth"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={secondaryDays}
                          placeholder="–"
                          error={errors.secondaryInsurance?.dayBirth}
                          disabled={secondaryInsuranceIsClient}
                        />
                      )}
                      defaultValue={billingInfoForm?.secondaryInsurance.dayBirth}
                      rules={{ required: true }}
                    />
                  </div>
                  <div tw="w-24 mt-3">
                    <Controller
                      name="secondaryInsurance.yearBirth"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={years}
                          placeholder="–"
                          error={errors.secondaryInsurance?.yearBirth}
                          disabled={secondaryInsuranceIsClient}
                        />
                      )}
                      defaultValue={billingInfoForm?.secondaryInsurance.yearBirth}
                      rules={{ required: true }}
                    />
                  </div>
                </FormInline>
              </FormGroup>
              <FormGroup tw="w-full sm:w-1/2 md:w-1/4">
                <Label htmlFor="secondaryInsuredGender">Insured&apos;s Gender</Label>
                <Controller
                  name="secondaryInsurance.insuredGender"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      id="secondaryInsuredGender"
                      options={genderOptions}
                      placeholder="–"
                      error={errors.secondaryInsurance?.insuredGender}
                      tw="mt-3"
                      disabled={secondaryInsuranceIsClient}
                    />
                  )}
                  defaultValue={billingInfoForm?.secondaryInsurance.insuredGender}
                  rules={{
                    required:
                      !clientData?.sex && secondaryInsuranceIsClient
                        ? 'Gender is missing on client’s information, please update the client record.'
                        : true,
                  }}
                />
              </FormGroup>
              <FormGroup tw="w-full sm:w-1/2 md:w-2/3 mt-8 sm:mt-10 md:mt-12">
                <Label htmlFor="secondaryAddress1" optional>
                  Insured&apos;s Address
                </Label>
                <Input
                  type="address"
                  tw="w-full mt-3"
                  id="secondaryAddress1"
                  {...register('secondaryInsurance.insuredAddress.address1')}
                  defaultValue={billingInfoForm?.secondaryInsurance.insuredAddress.address1}
                  autoComplete="off"
                  error={errors.secondaryInsurance?.insuredAddress?.address1}
                />
                <Input
                  type="address"
                  tw="w-full mt-2"
                  id="secondaryAddress2"
                  {...register('secondaryInsurance.insuredAddress.address2')}
                  defaultValue={billingInfoForm?.secondaryInsurance.insuredAddress.address2}
                  autoComplete="off"
                  error={errors.secondaryInsurance?.insuredAddress?.address2}
                />
              </FormGroup>
              <FormInline tw="md:flex">
                <FormGroup tw="block md:inline-block">
                  <Label htmlFor="secondaryCity" optional>
                    Insured&apos;s City
                  </Label>
                  <Input
                    type="text"
                    id="secondaryCity"
                    tw="block mr-3 mt-3"
                    {...register('secondaryInsurance.insuredAddress.city', {
                      validate: { onlyLettersSpecialVowelsWhiteSpacesValidator },
                    })}
                    defaultValue={billingInfoForm?.secondaryInsurance.insuredAddress.city}
                    autoComplete="off"
                    error={errors.secondaryInsurance?.insuredAddress?.city}
                  />
                </FormGroup>
                <FormGroup tw="block md:inline-block">
                  <Label htmlFor="secondaryState" optional>
                    Insured&apos;s State
                  </Label>
                  <div tw="w-40 mr-3 mt-3">
                    <Controller
                      name="secondaryInsurance.insuredAddress.state"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          id="secondaryState"
                          options={usStatesOptions}
                          isSearchable
                          placeholder="–"
                          error={errors.secondaryInsurance?.insuredAddress?.state}
                        />
                      )}
                      defaultValue={billingInfoForm?.secondaryInsurance.insuredAddress.state}
                    />
                  </div>
                </FormGroup>
                <FormGroup tw="block md:inline-block">
                  <Label htmlFor="secondaryZipCode" optional>
                    Insured&apos;s Zip Code
                  </Label>
                  <Input
                    type="text"
                    id="secondaryZipCode"
                    tw="block mt-3"
                    {...register('secondaryInsurance.insuredAddress.zipCode', {
                      validate: { validateZipcode },
                    })}
                    defaultValue={billingInfoForm?.secondaryInsurance.insuredAddress.zipCode}
                    autoComplete="off"
                    error={errors.secondaryInsurance?.insuredAddress?.zipCode}
                  />
                </FormGroup>
              </FormInline>
              <FormGroup tw="w-full lg:w-2/5 md:w-2/3">
                <Label htmlFor="secondaryInsurancePayer">Insurance Payer</Label>
                <Controller
                  name="secondaryInsurance.insurancePayer"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      id="secondaryInsurancePayer"
                      options={insurancePayerOptions}
                      isSearchable
                      placeholder="–"
                      error={errors.secondaryInsurance?.insurancePayer}
                      tw="mt-3"
                    />
                  )}
                  defaultValue={billingInfoForm?.secondaryInsurance.insurancePayer}
                  rules={{ required: true }}
                />
              </FormGroup>
              <FormGroup tw="w-full sm:w-1/2 md:w-1/3">
                <Label htmlFor="secondaryInsuredMemberID">Member ID</Label>
                <Input
                  type="text"
                  id="secondaryInsuredMemberID"
                  tw="w-full mt-3"
                  {...register('secondaryInsurance.insuredMemberID', { required: true })}
                  defaultValue={billingInfoForm?.secondaryInsurance.insuredMemberID}
                  autoComplete="off"
                  error={errors.secondaryInsurance?.insuredMemberID}
                />
              </FormGroup>
              <FormGroup tw="w-full sm:w-1/2 md:w-1/3">
                <Label optional htmlFor="secondaryInsuredGroupID">
                  Group ID
                </Label>
                <Input
                  type="text"
                  id="secondaryInsuredGroupID"
                  tw="w-full mt-3"
                  {...register('secondaryInsurance.insuredGroupID')}
                  defaultValue={billingInfoForm?.secondaryInsurance.insuredGroupID}
                  autoComplete="off"
                  error={errors.secondaryInsurance?.insuredGroupID}
                />
              </FormGroup>
              <span
                data-testid="delete-secondary-insurance-information"
                onClick={onConfirmDeleteHandler}
                tw="contents py-10 text-red-700 font-normal cursor-pointer"
              >
                <FontAwesomeIcon tw="text-base mr-2" icon={['far', 'trash']} />
                Remove Secondary Insurance
              </span>
            </>
          )}
        </div>

        <div data-testid="authorizations">
          <div tw="text-xl font-semibold mt-10 mb-4" data-testid="authorizations-label">
            Authorizations
          </div>
          <FormGroup>
            <Label
              htmlFor="primaryInsuredPriorAuthorizationNumber"
              optional={watchEvaluationAuthStatus?.value !== 'approved' && watchSessionAuthStatus?.value !== 'approved'}
            >
              Prior Authorization Number
            </Label>
            <Input
              type="text"
              id="primaryInsuredPriorAuthorizationNumber"
              pattern="^[a-zA-Z0-9]+$"
              tw="mr-3 mt-3"
              {...register('primaryInsurance.priorAuthorizationNumber', {
                required:
                  watchEvaluationAuthStatus?.value === 'approved' || watchSessionAuthStatus?.value === 'approved'
                    ? true
                    : false,
              })}
              defaultValue={billingInfoForm?.primaryInsurance.priorAuthorizationNumber}
              autoComplete="off"
              error={errors.primaryInsurance?.priorAuthorizationNumber}
            />
          </FormGroup>
          <FormGroup tw="mb-4 mt-5 w-full sm:w-1/2 md:w-1/3">
            <h4 tw="mb-2 text-sm font-semibold">Evaluation Authorization Status</h4>
            <div data-testid="authorizations-evaluation">
              <Controller
                name="insuranceAuthorizations.evaluationAuthStatus"
                data-testid="authorizations-evaluation"
                control={control}
                defaultValue={billingInfoForm?.insuranceAuthorizations?.evaluationAuthStatus}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={authorizationStatusOptions}
                    placeholder="Select..."
                    error={errors?.insuranceAuthorizations?.evaluationAuthStatus}
                  />
                )}
                rules={{ required: true }}
              />
            </div>
          </FormGroup>
          <FormGroup tw="mb-4 mt-5 w-full sm:w-1/2 md:w-1/3">
            <h4 tw="mb-2 text-sm font-semibold">Session Authorization Status</h4>
            <div data-testid="authorizations-session">
              <Controller
                name="insuranceAuthorizations.sessionAuthStatus"
                data-testid="authorizations-session"
                control={control}
                defaultValue={billingInfoForm?.insuranceAuthorizations?.sessionAuthStatus}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={
                      watchEvaluationAuthStatus?.value === 'denied' || watchEvaluationAuthStatus?.value === 'awaiting'
                        ? disallowedStatusOptions
                        : authorizationStatusOptions
                    }
                    placeholder="Select..."
                    error={errors?.insuranceAuthorizations?.sessionAuthStatus}
                  />
                )}
                rules={{ required: true }}
              />
            </div>
          </FormGroup>

          <FormGroup tw="mb-5 mt-5">
            <h4 tw="mb-2 text-sm font-semibold">Are there any visit limits?</h4>
            <div tw="flex flex-row max-h-[20px]">
              <FormInline tw="flex items-baseline mr-7">
                <Input
                  type="radio"
                  id="insuranceAuthorizations.visitLimits-yes"
                  data-testid="insuranceAuthorizations.visitLimits-yes"
                  {...register('insuranceAuthorizations.visitLimits')}
                  value="yes"
                  defaultChecked={billingInfoForm?.insuranceAuthorizations.visitLimits === 'yes'}
                />
                <Label font="normal" htmlFor="insuranceAuthorizations.visitLimits-yes" tw="ml-2">
                  Yes
                </Label>
              </FormInline>
              <FormInline tw="flex items-baseline">
                <Input
                  type="radio"
                  id="insuranceAuthorizations.visitLimits-no"
                  data-testid="insuranceAuthorizations.visitLimits-no"
                  {...register('insuranceAuthorizations.visitLimits')}
                  value="no"
                  defaultChecked={billingInfoForm?.insuranceAuthorizations.visitLimits === 'no'}
                />
                <Label font="normal" htmlFor="insuranceAuthorizations.visitLimits-no" tw="ml-2">
                  No
                </Label>
              </FormInline>
            </div>
          </FormGroup>
          {watchVisitLimits === 'yes' && (
            <FormGroup>
              <Label tw="mt-5" htmlFor="authorizationVisitsLeft">
                Remaining Visits Left
              </Label>
              <FormInline tw="flex">
                <div>
                  <InputNumberStyled>
                    <Input
                      type="text"
                      id="authorizationVisitsLeft"
                      onWheel={event => event.currentTarget.blur()}
                      onKeyDown={handleKeyDown}
                      defaultValue={billingInfoForm?.insuranceAuthorizations.remainingVisitsLeft}
                      {...register('insuranceAuthorizations.remainingVisitsLeft', {
                        required: true,
                      })}
                      tw="mr-3 mt-3 w-12"
                      autoComplete="off"
                      error={errors.insuranceAuthorizations && errors.insuranceAuthorizations.remainingVisitsLeft}
                    />
                  </InputNumberStyled>
                </div>
              </FormInline>
            </FormGroup>
          )}
          <FormGroup tw="mb-5">
            <h4 tw="mb-2 text-sm font-semibold">Are there any date limits?</h4>
            <div tw="flex flex-row max-h-[20px]">
              <FormInline tw="flex items-baseline mr-7">
                <Input
                  type="radio"
                  id="insuranceAuthorizations.dateLimits-yes"
                  data-testid="insuranceAuthorizations.dateLimits-yes"
                  {...register('insuranceAuthorizations.dateLimits')}
                  value="yes"
                  defaultChecked={billingInfoForm?.insuranceAuthorizations.dateLimits === 'yes'}
                />
                <Label font="normal" htmlFor="insuranceAuthorizations.dateLimits-yes" tw="ml-2">
                  Yes
                </Label>
              </FormInline>
              <FormInline tw="flex items-baseline">
                <Input
                  type="radio"
                  id="insuranceAuthorizations.dateLimits-no"
                  data-testid="insuranceAuthorizations.dateLimits-no"
                  {...register('insuranceAuthorizations.dateLimits')}
                  value="no"
                  defaultChecked={billingInfoForm?.insuranceAuthorizations.dateLimits === 'no'}
                />
                <Label font="normal" htmlFor="insuranceAuthorizations.dateLimits-no" tw="ml-2">
                  No
                </Label>
              </FormInline>
            </div>
          </FormGroup>
          {watchDateLimits === 'yes' && (
            <FormGroup tw="flex-grow md:flex-grow-0">
              <Label htmlFor="visitsCompletedBy" tw="font-semibold text-sm mb-2">
                Visits Completed By
              </Label>
              <div tw="w-28">
                <ControlledDatePicker
                  control={control}
                  getValues={getValues}
                  setValue={setValue}
                  name={'insuranceAuthorizations.visitsCompletedBy'}
                  defaultValue={billingInfoForm?.insuranceAuthorizations.visitsCompletedBy}
                  needsOutsideRange={true}
                />
              </div>
            </FormGroup>
          )}
          <FormGroup tw="mb-5 mt-5">
            <h4 tw="mb-2 text-sm font-semibold">Is a re-evaluation required for this plan?</h4>
            <div tw="flex flex-row max-h-[20px]">
              <FormInline tw="flex items-baseline mr-7">
                <Input
                  type="radio"
                  id="insuranceAuthorizations.reEvaluationRequired-yes"
                  data-testid="insuranceAuthorizations.reEvaluationRequired-yes"
                  {...register('insuranceAuthorizations.reEvaluationRequired')}
                  value="yes"
                  defaultChecked={billingInfoForm?.insuranceAuthorizations.reEvaluationRequired === 'yes'}
                />
                <Label font="normal" htmlFor="insuranceAuthorizations.reEvaluationRequired-yes" tw="ml-2">
                  Yes
                </Label>
              </FormInline>
              <FormInline tw="flex items-baseline">
                <Input
                  type="radio"
                  id="insuranceAuthorizations.reEvaluationRequired-no"
                  data-testid="insuranceAuthorizations.reEvaluationRequired-no"
                  {...register('insuranceAuthorizations.reEvaluationRequired')}
                  value="no"
                  defaultChecked={billingInfoForm?.insuranceAuthorizations.reEvaluationRequired === 'no'}
                />
                <Label font="normal" htmlFor="insuranceAuthorizations.reEvaluationRequired-no" tw="ml-2">
                  No
                </Label>
              </FormInline>
            </div>
          </FormGroup>
          {watchReEvaluationRequired === 'yes' && (
            <FormGroup>
              <Label tw="mt-5" htmlFor="reEvaluationDueBy">
                Re-evaluation Due By
              </Label>
              <FormInline tw="flex">
                <div tw="w-28">
                  <ControlledDatePicker
                    control={control}
                    getValues={getValues}
                    setValue={setValue}
                    name={'insuranceAuthorizations.reEvaluationDueBy'}
                    defaultValue={billingInfoForm?.insuranceAuthorizations.reEvaluationDueBy}
                    needsOutsideRange={true}
                  />
                </div>
              </FormInline>
            </FormGroup>
          )}
        </div>
      </div>
      <DeleteSecondaryInsuranceModal
        isOpen={DeleteSecondaryInsuranceModalIsOpen}
        onClose={DeleteSecondaryInsuranceModalOnClose}
        onDelete={onDeleteSecondaryInsuranceClose}
      />
    </div>
  );
};

export default BillingInfoInsuranceForm;
