/* eslint-disable @typescript-eslint/no-explicit-any */
import { ContentfulClientApi, createClient } from 'contentful';
import { useQuery } from 'react-query';

export enum ContentfulSpaces {
  Expressable = 'o4loakmn752b',
  ClinicalCareExperience = 'pfwvlu9n7n1g',
}

// Read-only access to content-delivery API. Not sensitive
const tokens = new Map<string, string>();
tokens.set(ContentfulSpaces.Expressable, '4reeeSiwbJltOiU0S6y6hgcj64DSZpL21FjNAxKwOr8');
tokens.set(ContentfulSpaces.ClinicalCareExperience, 'PLhf4xoL8nTYbH8DhRbi-l8cjGDY53S8MJWCXRr7Zdo');

export enum EntryId {
  ArchiveReasons = '2sIZBPjXe960XTf3eLaJB',
  CancelationReasons = '7x8x3GBpr4XIaNwgtdQWW3',
  DeletionReasons = '3pFetCQw5hkJp3g6uq3SY4',
  EvaluationCancelationReasons = '6mdJIDfUUM6TYEtsUZ2Njb',
  InsurancesAccepted = '7aE5pWzzG0d7ef9ZVsa01R',
  NotSchedulingReasons = '3M3uhuPBk3RHJjEnOdXtFR',
  RescheduleReasons = '2hzCbjApggNjUQ6rlcAreH',
  EvaluationV3 = 'bJYGhUqotNhLRqy6fJAOm',
  MedicalDiagnoses = '6RaWDinJ0WDpuzzUYccHwH',
  AreasOfFunction = '7exL5SsVTfHuPEyqqEp2fJ',
  ApplicableConditions = 'DoWzi9sWwQm1z6wUuWHOr',
  SkilledInterventions = '1sbJLYEZRunpt4wPEVEm9H',
  AssistiveDevices = '1Nj21sxWW3U7Vl2MwKOZrA',
  PendingRescheduleCancelationReasons = '2FORSlU5h3kdB38AGkooRl',
  UnlockReasons = '2EFAY9EY3owcInwvbOh9iX',
  EvaluationQualityReview = '2mqttqu25YfhIs6wsNwegG',
  DischargeQualityReview = 'SodMDStXLkbfkgdQQOUPZ',
}

export enum ClinicalCareExperienceEntryId {
  UnassignmentReasons = '7mgLUrjhIyoORobkMlwz8B',
}

const _clients = new Map<string, ContentfulClientApi>();

function client(spaceId = ContentfulSpaces.Expressable) {
  const token = tokens.get(spaceId);
  if (!token) {
    throw Error('Contentful token not provided in the "tokens" dictionary.');
  }

  if (!_clients.get(spaceId)) {
    // So far, only ClinicalCareExperience space support "develop" environment. That is why we handle that way!
    // If in the future we support "develop" for Expressable space, we can change the condition below
    let environment = 'master';
    if (spaceId === ContentfulSpaces.ClinicalCareExperience) {
      environment = process.env.REACT_APP_CONTENTFUL_CLINICAL_ENVIRONMENT_ID || 'develop';
    }

    const createdClientSpace = createClient({
      space: spaceId,
      accessToken: token,
      environment: environment,
    });

    _clients.set(spaceId, createdClientSpace);
  }

  return _clients.get(spaceId)!;
}

interface CommonContentfulOptions {
  /**
   * If true, arrays of items will be unwrapped to a single object whose keys are each item's Contentful ID and values are the corresponding item.
   *
   * @default false
   */
  unwrapArray?: boolean;

  /**
   * The space to be loaded (default is "Expressable").
   */
  space?: ContentfulSpaces;
}

export interface ContentfulByContentType extends CommonContentfulOptions {
  /**
   * The content type to be loaded.
   */
  contentType: string;

  entryId?: never;
}

export interface ContentfulByEntryId extends CommonContentfulOptions {
  contentType?: never;

  /**
   * The entry ID to be loaded.
   */
  entryId: string;
}

export type ContentfulQuery = ContentfulByContentType | ContentfulByEntryId;

type QueryParam = { [key: string]: any };

export interface ContentfulEntriesOptions {
  /**
   * The maximum count of items to load.
   *
   * If not specified, load all items of this content type.
   */
  limit?: number;

  /**
   * The params to query the content.
   *
   * If not specified, load all items of this content type.
   */
  params?: QueryParam;

  /**
   * To sort the fields.
   *
   * If not specified, load all items of this content type.
   */
  order?: string[];
}

const CONTENTFUL_MAX_DEEP_LEVEL = 10;

async function getAllItems<T>(options: ContentfulByContentType & ContentfulEntriesOptions) {
  const limit = options?.limit ?? Infinity;
  const pageSize = Math.min(1000, limit);

  let result = await client(options.space).getEntries<T>({
    content_type: options.contentType,
    limit: pageSize,
    include: CONTENTFUL_MAX_DEEP_LEVEL,
    order: options.order?.join(),
    ...options.params,
  });
  let items = result.items;
  let skip = result.items.length;
  const expectedTotal = Math.min(result.total, limit);

  while (items.length < expectedTotal) {
    result = await client(options.space).getEntries<T>({
      content_type: options.contentType,
      limit: Math.min(pageSize, expectedTotal - items.length),
      skip,
      order: options.order?.join(),
      ...options.params,
    });

    items = [...items, ...result.items];
    skip += result.items.length;
  }

  return items;
}

async function getItem<T>(options: ContentfulByEntryId) {
  const result = await client(options.space).getEntry<T>(options.entryId, {
    include: CONTENTFUL_MAX_DEEP_LEVEL,
  });
  return result;
}

export function extractFields(item: any): any {
  if (!item) {
    return item;
  }

  if (item.fields) {
    const extractedFields = extractFields(item.fields);

    // If the value extracted from the inner `fields` attribute is an array,
    // then the array should be kept as an array (and not forced into an object)
    return Array.isArray(extractedFields)
      ? extractedFields
      : {
          ...extractedFields,
          id: item.sys?.id,
        };
  }

  if (Array.isArray(item)) {
    return item.map(extractFields);
  }

  if (typeof item === 'object') {
    return Object.fromEntries(Object.entries(item).map(([attr, value]) => [attr, extractFields(value)]));
  }

  return item;
}

const invalidOptionsErrorMessage = 'Options must define contentType or entryId';

function contentfulQueryKey(options: ContentfulQuery) {
  if (options.contentType) {
    return ['contentful', options.contentType];
  }
  if (options.entryId) {
    return ['contentful-entry', options.entryId];
  }

  throw new Error(invalidOptionsErrorMessage);
}

async function getContentfulEntry<T = any>(options: ContentfulQuery) {
  if (options.contentType) {
    const items = await getAllItems<T>({ ...options, limit: 1 });
    const extractedItems = extractFields(items) as T[];
    return extractedItems?.[0];
  }

  if (options.entryId) {
    const item = await getItem<T>(options);
    return extractFields(item) as T;
  }

  throw new Error(invalidOptionsErrorMessage);
}

async function getContentfulEntries<T = any>(options: ContentfulByContentType & ContentfulEntriesOptions) {
  const items = await getAllItems<T>(options);
  return extractFields([...items]) as T[];
}

function unwrapArray<T>(item: any, { unwrapArray = false }: CommonContentfulOptions): T {
  if (!unwrapArray || !Array.isArray(item)) {
    return item;
  }

  return item.reduce((obj, arrayItem) => ({ ...obj, [arrayItem.id]: arrayItem }), {});
}

/**
 * Load a single entry from Contentful using React Query.
 *
 * Notice that Contentful entry objects are simplified before being returned. This
 * means it's possible to access data directly without using the `fields` attribute.
 *
 * @example
 * ```ts
 * const { data: cancelationReasons } = useContentfulEntry({
 *   entryId: EntryId.CancelationReasons
 * });
 *
 * // cancelationReasons is { dropdownContent: ['Other', 'Client sick', ...], ... }
 * ```
 *
 * @param options An object containing either `contentType` or `entryId` and, optionally, `unwrapArray`
 * @returns A React Query object containing `data`, `isLoading`, `refetch`, etc.
 */
export function useContentfulEntry<T = any>(options: ContentfulQuery) {
  return useQuery(contentfulQueryKey(options), async () =>
    unwrapArray<T>(await getContentfulEntry<T>(options), options),
  );
}

/**
 * Load a list of entries from Contentful using React Query.
 *
 * Notice that Contentful entry objects are simplified before being returned. This
 * means it's possible to access data directly without using the `fields` attribute.
 *
 * @example
 * ```ts
 * const { data: cueLevels } = useContentfulEntries({
 *   contentType: 'cueLevels'
 * });
 *
 * // cueLevel is [{ cueLevelLabel: 'wait time' }, { cueLevelLabel: '...' }, ...]
 * ```
 *
 * @param options An object containing `contentType` and, optionally, `limit` and `unwrapArray`
 * @returns A React Query object containing `data`, `isLoading`, `refetch`, etc.
 */
export function useContentfulEntries<T = any>(
  options: ContentfulByContentType & ContentfulEntriesOptions,
  select?: (data: T) => T,
) {
  return useQuery(
    contentfulQueryKey(options),
    async () => unwrapArray<T>(await getContentfulEntries<T>(options), options),
    {
      select,
    },
  );
}
