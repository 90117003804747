import { faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'twin.macro';

export interface EventRenderFallbackProps {
  isLastEvent: boolean;
}

export default function EventRenderFallback({ isLastEvent }: EventRenderFallbackProps) {
  return (
    <div data-testid="event-render-fallback" tw="relative flex flex-col mt-7 px-2">
      {!isLastEvent && <span tw="absolute -z-1 top-7 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>}
      <div tw="flex items-baseline">
        <div tw="flex items-center bg-gray-200 rounded-full h-6 w-6">
          <FontAwesomeIcon icon={faWarning} tw="mx-1.5 h-3 text-red-700" />
        </div>
        <div tw="pl-4 text-sm">
          <div tw="text-gray-500">An error ocurred while displaying this event.</div>
        </div>
      </div>
    </div>
  );
}
