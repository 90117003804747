import {
  AcademyHomeworkContentful,
  CustomStructuredActivityContentful,
  LearningPathContentful,
} from 'domain/clinical-pathways/types';
import { ContentfulSpaces, useContentfulEntries } from 'hooks/use-contentful';
import {
  useFilteredCustomStructuredActivities,
  useFilteredStructuredActivities,
} from 'hooks/use-structured-activities';

import { useEffect, useMemo } from 'react';
import { buildContentfulLearningPathsQuery } from 'pages/client/components/client-notes/note-form-content/session-note/therapy-strategies/add-therapy-strategy-modal';
import { normalizeFilters } from './helpers';
import { ClientResourcesProgram, FilterState, NormalizedFilters } from './types';
import { isString, omit } from 'lodash';
import { useClinicalPhonemes } from 'domain/clinical-pathways/use-clinical-phonemes';

const useClientResourcesLearningPaths = (filters: NormalizedFilters) => {
  return useContentfulEntries<LearningPathContentful[]>({
    contentType: 'learningPaths',
    space: ContentfulSpaces.ClinicalCareExperience,
    order: ['fields.title'],

    params: buildContentfulLearningPathsQuery({
      searchKey: filters.keywords,
      treatmentArea: filters.treatmentAreaFilter,
    }),
  });
};

export default function useClientResourcesData(filters: FilterState & { keywords?: string; isMobile: boolean }) {
  const parameters = normalizeFilters(filters);
  const { selectedPhoneme } = useClinicalPhonemes(filters?.phoneme ?? undefined);

  const {
    data: learningPathsData,
    isFetching: isLoadingLearningPaths,
    refetch: refetchLearningPaths,
  } = useClientResourcesLearningPaths(parameters);

  const {
    data: academyHomeworksContentful,
    isFetching: isAcademyHomeworksContentfulLoading,
    refetch: refetchAcademyHomeworksContentful,
  } = useFilteredStructuredActivities({
    textSearch: parameters.keywords,
    ageRanges: parameters.ageFilter,
    homeworkTypes: parameters.typeFilter,
    complexities: parameters.complexityFilter,
    caregiverRequired: isString(parameters.caregiverFilter) ? '' : parameters.caregiverFilter,
    hasMaterials: isString(parameters.hasMaterials) ? '' : parameters.hasMaterials,
    timesRequired: parameters.timeRequiredFilter,
    relatedTreatmentAreaId: parameters.treatmentAreaFilter,
    phoneme: selectedPhoneme,
  });

  const {
    data: customStructureActivities,
    isFetching: isLoadingCustomStructureActivities,
    refetch: refetchFilteredCustomStructuredActivities,
  } = useFilteredCustomStructuredActivities(parameters.keywords, parameters.timeRequiredFilter);

  const isLoading = useMemo(() => {
    return isLoadingLearningPaths || isAcademyHomeworksContentfulLoading || isLoadingCustomStructureActivities;
  }, [isLoadingLearningPaths, isAcademyHomeworksContentfulLoading, isLoadingCustomStructureActivities]);

  useEffect(() => {
    omit(filters, ['isMobile', 'contentType']);
    if (parameters?.contentType === 'authored-by-you') {
      refetchFilteredCustomStructuredActivities();
      return;
    } else if (parameters?.contentType === 'structured-activity') {
      refetchAcademyHomeworksContentful();
      return;
    } else if (parameters?.contentType === 'learning-path') {
      refetchLearningPaths();
      return;
    } else if (parameters?.contentType === 'all') {
      refetchAcademyHomeworksContentful();
      refetchLearningPaths();
      refetchFilteredCustomStructuredActivities();
    }
  }, [
    parameters?.keywords,
    parameters?.contentType,
    parameters?.ageFilter.length,
    parameters.caregiverFilter,
    parameters.complexityFilter.length,
    parameters.timeRequiredFilter.length,
    parameters.treatmentAreaFilter.length,
    parameters.typeFilter.length,
    parameters.hasMaterials,
    filters?.phoneme,
  ]);

  const dataVariants: {
    all: (LearningPathContentful | AcademyHomeworkContentful | CustomStructuredActivityContentful)[];
    'learning-path': LearningPathContentful[];
    'structured-activity': AcademyHomeworkContentful[];
    'authored-by-you': CustomStructuredActivityContentful[];
  } = {
    all: [...(customStructureActivities ?? []), ...(learningPathsData ?? []), ...(academyHomeworksContentful ?? [])],
    ['learning-path']: learningPathsData ?? [],
    ['structured-activity']: academyHomeworksContentful ?? [],
    ['authored-by-you']: customStructureActivities ?? [],
  };

  return {
    isLoading,
    data: dataVariants[filters?.contentType as unknown as keyof typeof dataVariants].map(toCardDetails),
    availableFilters: getFilterAvailability(
      filters?.contentType as 'all' | 'learning-path' | 'structured-activity' | 'authored-by-you',
    ),
  };
}

const getFilterAvailability = (
  selectedContentType: 'all' | 'learning-path' | 'structured-activity' | 'authored-by-you',
) => {
  const variants = {
    all: ['ageRange', 'type', 'complexity', 'caregiverRequired', 'hasMaterials', 'timeRequired'],
    'learning-path': ['ageRange', 'type', 'complexity', 'caregiverRequired', 'hasMaterials', 'timeRequired'],
    'structured-activity': [] as Array<any>,
    'authored-by-you': ['ageRange', 'type', 'complexity', 'caregiverRequired', 'hasMaterials', 'treatmentAreas'],
  };

  return variants[selectedContentType];
};

export const isAcademyHomework = (
  item: LearningPathContentful | AcademyHomeworkContentful,
): item is AcademyHomeworkContentful => {
  return 'aboutThisActivity' in item;
};

export const isCustomStructuredActivity = (
  item: LearningPathContentful | AcademyHomeworkContentful | CustomStructuredActivityContentful,
): item is CustomStructuredActivityContentful => {
  return 'practiceOverview' in item;
};

const toCardDetails = (
  item: LearningPathContentful | AcademyHomeworkContentful | CustomStructuredActivityContentful,
): ClientResourcesProgram => {
  if (isAcademyHomework(item as AcademyHomeworkContentful)) {
    const current = item as AcademyHomeworkContentful; // fixing a TS false positive
    return {
      id: current.id,
      title: current.title,
      description: current.aboutThisActivity,
      associatedMaterialsIfRequired: current.associatedMaterialsIfRequired,
      type: 'academy-homework',
    };
  } else if (isCustomStructuredActivity(item)) {
    return {
      id: item.id,
      title: item.title,
      description: item.practiceOverview,
      type: 'custom-structured-activity',
      associatedMaterialsIfRequired: item.associatedMaterials?.map(m => ({
        // note: for the sake of easiness, we transform the material to the same shape as the learning path. we dont share the same file model
        // for custom structured activities

        // note: is safe to assume that custom structure activities only have one material.
        // only one material is allowed to be associated with a custom structured activity
        id: m.id,
        nameOfMaterial: m.title,
        uploadMaterial: [
          {
            id: m.id,
            title: m.title,
            file: {
              url: m.file.url,
              fileName: m.title,
              contentType: m.file.contentType,
            },
          },
        ],
      })),
    };
  }

  const current = item as LearningPathContentful; // fixing a TS false positive
  return {
    id: current.id,
    title: current.title,
    category: current.category,
    description: current.strategyOverviewSection.overview ?? '',
    type: 'learning-path',
  };
};
