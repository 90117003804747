import React from 'react';
import {
  Button,
  CloseButton,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Textarea,
} from '@expressable/ui-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderPlus } from '@fortawesome/free-solid-svg-icons';
import ModalHeadline from 'components/modal-headline';
import { useForm } from 'react-hook-form';
import { CreateClientCasePayload, useCreateClientCase } from 'hooks/use-client-cases';
import { ICompleteClientInformation } from 'types';
import { CaseTypeOptions } from 'domain/client-cases/case-type-options';
import RHFSelect from 'components/RHFSelectBase';

export interface ClientCasesCreateModalProps {
  isOpen: boolean;
  onClose: () => void;
  clientId: string;
  clientData: ICompleteClientInformation;
}

interface ClientCasesCreateFormValues {
  RecordTypeId: string;
  Subject: string;
  Description?: string;
}

const ClientCasesCreateModal = (props: ClientCasesCreateModalProps) => {
  const { isOpen, onClose, clientId, clientData } = props;

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<ClientCasesCreateFormValues>({
    defaultValues: {
      RecordTypeId: '',
      Subject: '',
      Description: '',
    },
    mode: 'onChange',
  });

  const clientCasesCreateMutation = useCreateClientCase();
  const { mutate: clientCasesCreate } = clientCasesCreateMutation;

  const onSubmitCase = async (data: CreateClientCasePayload) => {
    clientCasesCreate(
      {
        Cue_Client_ID__c: clientId,
        Description: data.Description,
        RecordTypeId: data.RecordTypeId,
        Subject: data.Subject,
      },
      {
        onSuccess: () => {
          onClose();
        },
      },
    );
  };

  return (
    <Modal isOpen={isOpen} className="max-w-lg">
      <ModalContent>
        <form onSubmit={handleSubmit(onSubmitCase)}>
          <ModalHeader>
            <div className="absolute top-0 right-0 pt-4 pr-4">
              <CloseButton data-testid="close-client-cases-create-modal" onClick={onClose} />
            </div>
          </ModalHeader>
          <ModalBody>
            <React.Fragment>
              <div className="flex items-center justify-center w-12 h-12 mx-auto bg-indigo-100 rounded-full">
                <FontAwesomeIcon className="text-2xl text-indigo-700" icon={faFolderPlus} />
              </div>
              <div className="my-3 sm:mt-5">
                <ModalHeadline text={`Open New Case for ${clientData.clientFirstName} ${clientData.clientLastName}`} />
              </div>
              <div className="mt-6 mb-1 font-semibold">Type of Case</div>
              <div className="flex">
                <div className="w-full">
                  <div data-testid="client-cases-create-record-type-id">
                    <RHFSelect
                      name="RecordTypeId"
                      control={control}
                      required
                      options={CaseTypeOptions}
                      error={errors.RecordTypeId}
                    />
                  </div>
                </div>
              </div>
              <div className="mb-1 font-semibold">Case Subject</div>
              <div className="flex">
                <div className="w-full" data-testid="client-cases-create-subject">
                  <Input
                    className="w-full"
                    aria-label="client-cases-create-subject"
                    {...register('Subject', { required: true })}
                    error={errors.Subject}
                  />
                </div>
              </div>
              <div className="font-semibold mb-1">Provide a short summary of the case</div>
              <div className="flex">
                <div className="w-full" data-testid="client-cases-create-description">
                  <Textarea
                    className="w-full"
                    aria-label="client-cases-create-description"
                    {...register('Description')}
                    rows={4}
                  />
                </div>
              </div>
            </React.Fragment>
          </ModalBody>
          <ModalFooter>
            <div className="mt-4 sm:mt-5 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
              <span className="flex w-full rounded-md shadow-sm sm:col-start-2">
                <Button
                  type="submit"
                  className="mt-3"
                  data-testid="client-cases-create-modal-save-button"
                  variant="primary"
                  fullWidth
                  loading={clientCasesCreateMutation.isLoading}
                  disabled={!isValid}
                >
                  Open New Case
                </Button>
              </span>
              <span className="flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1">
                <Button
                  type="button"
                  className="mt-3"
                  data-testid="client-cases-create-modal-onclose-button"
                  variant="secondary"
                  fullWidth
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </span>
            </div>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default ClientCasesCreateModal;
