import {
  Button,
  CloseButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
} from '@expressable/ui-library';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faNotesMedical } from '@fortawesome/free-solid-svg-icons';
import { useForm, useWatch } from 'react-hook-form';
import { Form } from 'components/Forms';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ControlledFormField } from 'components/Forms/FormFieldControlled';
import { useMemo } from 'react';
import { selectOptionSchema } from 'pages/new-client-create/ClientInformation';
import { SelectOption } from 'types';

import 'twin.macro';
import { nullableSelectOptionSchema } from './client-notes/note-form-content/evaluation/schema';
import { GetDevelopmentalDelaysPayload, useSetDevelopmentalDelays } from 'hooks/use-care-plan-developmental-delays';

interface DevelopmentalDelayProps {
  isOpen: boolean;
  onClose: () => void;
  clientId: string;
  shouldLogEvent: boolean;
  delaysPayload?: GetDevelopmentalDelaysPayload | null;
  severityOptions: SelectOption[];
}

const schema = yup.object({
  present: selectOptionSchema.required('Please fill out this field.'),
  severity: nullableSelectOptionSchema.when('present', {
    is: (present: SelectOption) => present?.value === 'Yes',
    then: schema => schema.required('Severity is required'),
  }),
});

export function DevelopmentalDelaysModal(props: DevelopmentalDelayProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isOpen, onClose, clientId, shouldLogEvent, delaysPayload } = props;
  const defaultValues = useMemo(
    () =>
      !delaysPayload
        ? {
            present: null,
            severity: null,
          }
        : {
            present: {
              label: delaysPayload.present ? 'Yes' : 'No',
              value: delaysPayload.present ? 'Yes' : 'No',
            },
            severity: delaysPayload.severity
              ? {
                  label: delaysPayload.severity,
                  value: delaysPayload.severity,
                }
              : null,
          },
    [],
  );
  const { mutateAsync: setDevelopmentalDelay, isLoading: isLoadingUpdate } = useSetDevelopmentalDelays();

  const form = useForm({
    // @ts-ignore
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: any) => {
    await setDevelopmentalDelay({
      clientId,
      present: data.present.value === 'Yes',
      severity: data.present.value === 'Yes' ? data.severity?.value : '',
      shouldLogEvent,
    });
    onClose();
  };

  const watchPresent: any = useWatch({
    name: 'present',
    control: form.control,
  });

  return (
    <Modal portal isOpen={isOpen}>
      <ModalContent>
        <Form form={form as any} onSubmit={onSubmit} schema={schema} hideOptional>
          <ModalHeader>
            <CloseButton onClick={onClose} />
          </ModalHeader>
          <ModalBody>
            <div>
              <div tw="flex items-center justify-center w-12 h-12 mx-auto bg-indigo-100 rounded-full">
                <FontAwesomeIcon tw="text-2xl text-indigo-700" icon={faNotesMedical} data-testid="icon" />
              </div>
              <div tw="mt-3 sm:mt-5">
                <h3 tw="text-lg text-center font-medium text-gray-900 leading-6" id="modal-headline">
                  Developmental Delays or Chronic Disabilities
                </h3>
                <div tw="mt-10">
                  <ControlledFormField
                    component={Select}
                    name="present"
                    label="Does the client have any developmental delays or chronic disabilities?"
                    options={[
                      { label: 'Yes', value: 'Yes' },
                      { label: 'No', value: 'No' },
                    ]}
                    placeholder="Select an option"
                  />
                </div>
                {watchPresent?.value === 'Yes' && (
                  <div tw="mt-10">
                    <ControlledFormField
                      component={Select}
                      name="severity"
                      label="What is the severity?"
                      options={props.severityOptions}
                    />
                  </div>
                )}
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-1 sm:gap-3 sm:grid-flow-row-dense">
              <span className="flex w-full rounded-md shadow-sm">
                <Button
                  type="submit"
                  variant="primary"
                  loading={isLoadingUpdate}
                  disabled={isLoadingUpdate}
                  className="inline-flex justify-center w-full px-4 py-2 text-base font-medium leading-6 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                >
                  Save
                </Button>
              </span>
            </div>
          </ModalFooter>
        </Form>
      </ModalContent>
    </Modal>
  );
}

export default DevelopmentalDelaysModal;
