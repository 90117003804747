import { useQuery } from 'react-query';
import { http } from '@expressable/utils';
import { Therapist } from 'types';
import { ScheduleConflict, WeekSummary } from 'domain/therapist/therapistTypes';
import { TherapistSortBy } from 'domain/therapist/constants';
import { ScheduleConflictsType } from './use-admin-schedule-conflicts';

// How long until we re-fetch the therapist list, in milliseconds
const THERAPISTS_CACHE_LIFETIME = 100000;

const getTherapists = async (status?: string, sortBy?: TherapistSortBy | null): Promise<Array<Therapist>> => {
  const { data } = await http.get<Array<Therapist>>(`/therapists`, {
    params: {
      status,
      sortBy,
    },
  });
  return data;
};

interface UseTherapistOptions {
  refetchOnMount?: 'always' | boolean,
  staleTime?: number,
}

export default function useTherapists(
  status?: string,
  sortBy?: TherapistSortBy | null,
  options: UseTherapistOptions = {
    refetchOnMount: 'always',
    staleTime: THERAPISTS_CACHE_LIFETIME,
  }) {
  return useQuery('therapists', () => getTherapists(status, sortBy), options);
}

const getTherapist = async (therapistID: string) => {
  const { data } = await http.get(`/therapists/${therapistID}`);
  return data;
};

export function useTherapist(therapistID: string) {
  return useQuery(['therapists', therapistID], () => getTherapist(therapistID), {
    enabled: Boolean(therapistID),
  });
}

const getTherapistWeeklySummaries = async (therapistID: string) => {
  const { data } = await http.get<WeekSummary[]>(`/therapists/${therapistID}/weekly-summaries`);
  return data;
};

export function useTherapistWeeklySummaries(therapistID: string) {
  return useQuery(['therapist-weekly-summary', therapistID], () => getTherapistWeeklySummaries(therapistID), {
    enabled: Boolean(therapistID),
  });
}

export const EMPTY_CLIENT_ID = '<NO CLIENT>';

export const EMPTY_CLIENT_NAME = 'No Client';

const getScheduleConflicts = async (
  therapistID: string,
  { includeTypes }: { includeTypes?: ScheduleConflictsType[] },
) => {
  const { data } = await http.get<ScheduleConflict[]>(`/therapists/${therapistID}/schedule-conflicts`, {
    params: { includeTypes: includeTypes ? includeTypes.join(',') : undefined },
  });

  return data.map(({details, ...conflict}) => ({
    ...conflict,
    details: details.map(({clientID, clientName, ...detail}) => ({
      ...detail,
      clientID: clientID ?? EMPTY_CLIENT_ID,
      clientName: clientName ?? EMPTY_CLIENT_NAME,
    })),
  }));
};

export function useScheduleConflicts(therapistID: string, options: { includeTypes?: ScheduleConflictsType[] }) {
  return useQuery(
    ['therapist-schedule-conflicts', therapistID, options.includeTypes],
    () => getScheduleConflicts(therapistID, options),
    {
      enabled: Boolean(therapistID),
    },
  );
}
