import { useCallback, useState } from 'react';

// Meant for scenarios where certain action needs to be confirmed by the user before taking place
// Use together with the ConfirmActionModal component

export interface UseConfirmModalResult<P = void> {
  isOpen: boolean;
  onTrigger: (payload: P) => void;
  onCancel: () => void;
  onConfirm: () => void;
}

export type UseConfirmModal = <P = void>(callback: (payload: P) => void) => UseConfirmModalResult<P>;

/**
 * Hook to confirm an action using {@link ConfirmActionModal} before actually executing the action.
 *
 * @param callback The operation that must be confirmed
 * @returns An object with the `onTrigger` function to start the confirmation process, and all other props required by `ConfirmActionModal`
 */
const useConfirmModal: UseConfirmModal = <P = void>(callback: (payload: P) => void) => {
  const [payload, setPayload] = useState<P>();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onCancel = useCallback(() => {
    setIsOpen(false);
    setPayload(undefined);
  }, []);

  const onConfirm = useCallback(() => {
    callback(payload as P);
    setIsOpen(false);
  }, [payload]);

  const onTrigger = useCallback(
    (payload: P) => {
      setIsOpen(true);
      setPayload(payload);
    },
    [payload],
  );

  return {
    isOpen,
    onCancel,
    onConfirm,
    onTrigger,
  };
};

export default useConfirmModal;
