import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Footer = () => {
  const location = useLocation();

  return (
    <footer className="text-gray-400 py-4 text-center mb-16">
      <div className="hidden sm:flex flex-wrap justify-center space-x-4">
        <p>&copy; {new Date().getFullYear()} Expressable</p>

        <Link
          className="text-indigo-700"
          to={{ pathname: `https://www.expressable.com/terms-and-conditions` }}
          target="_blank"
        >
          Terms & Conditions
        </Link>
        <Link
          className="text-indigo-700"
          to={{ pathname: `https://www.expressable.com/privacy-policy` }}
          target="_blank"
        >
          Privacy Policy
        </Link>
        <Link
          className={`text-indigo-700 ${location.pathname === '/licenses' ? 'underline' : ''}`}
          to={{ pathname: `/licenses` }}
          target="_blank"
        >
          Licenses
        </Link>
        <Link
          className="text-indigo-700"
          to={{ pathname: `https://www.expressable.com/accessibility-statement` }}
          target="_blank"
        >
          Accessibility Statement
        </Link>
      </div>

      <div className="block sm:hidden mt-10">
        <div className="flex flex-wrap justify-center space-x-4 mb-2">
          <Link
            className="text-indigo-700"
            to={{ pathname: `https://www.expressable.com/terms-and-conditions` }}
            target="_blank"
          >
            Terms & Conditions
          </Link>
          <Link
            className="text-indigo-700"
            to={{ pathname: `https://www.expressable.com/privacy-policy` }}
            target="_blank"
          >
            Privacy Policy
          </Link>
          <Link
            className={`text-indigo-700 ${location.pathname === '/licenses' ? 'underline' : ''}`}
            to={{ pathname: `/licenses` }}
            target="_blank"
          >
            Licenses
          </Link>
        </div>
        <div className="flex justify-center mb-2">
          <Link
            className="text-indigo-700"
            to={{ pathname: `https://www.expressable.com/accessibility-statement` }}
            target="_blank"
          >
            Accessibility Statement
          </Link>
        </div>
        <div className="flex justify-center mt-6">
          <p>&copy; {new Date().getFullYear()} Expressable</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
